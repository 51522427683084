import * as _ from 'lodash/fp';

import { CommonNode, FieldValue, isCommonNode } from '..';
import { ViewNode } from '../viewNode';
import { CommonResponse, Breadcrumb } from '.';

export interface GetCollectionResponse<Node extends CommonNode> extends CommonResponse {
  aggregations?: object[]; // Aggregations.
  breadcrumbs?: Breadcrumb[]; // Breadcrumbs.
  collection?: Collection<Node>; // Collection metadata.
  view: ViewNode; // View metadata.
  viewport: Viewport<Node>; // Viewport data.
}

export interface Collection<Node extends CommonNode> {
  activeCount?: number; // Total count of active nodes in the collection.
  customViewsSupported?: boolean; // Whether this collection supports custom views.
  deletedCount?: number; // Total count of deleted nodes in the collection.
  etag?: string; // ETag of the collection.
  fieldsCollectionName?: string; // Name of the fields collection, if it exists.
  icon?: string; // Icon for the collection.
  supportedViewTypes?: string[]; // Supported view types for the collection.
  title?: string; // Title of the collection.
  type?: string; // Type of the collection.

  // Only used when expanding nodes within a collection that have been expanded under a node.
  nodes?: Node[]; // Returned nodes.
}

export interface Viewport<Node extends CommonNode> {
  monitorID?: string; // ID of the monitoring connection for real-time changes in this viewport.
  returnedCount: number; // Number of returned nodes in this viewport.
  start: number; // Index of the starting row in this viewport.
  totalCount: number; // Total count of nodes in this viewport.
  groups?: Group<Node>[]; // Returned groups.
  nodes?: Node[]; // Returned nodes.
}

export interface Group<Node extends CommonNode> {
  count?: number; // Number of nodes in this group.
  value?: FieldValue; // Value that identifies the group
  label?: string;
  groups?: Group<Node>[]; // Returned groups.
  nodes?: Node[]; // Returned nodes.
  firstNodeIndex?: number; // need to have this in the node to be able to calculate row index in the result set
}

// Type Guards
// TODO: replace `any` with `unknown` https://hubsync.atlassian.net/browse/HUB-1027

export function isGroupNode(node: any): node is Group<CommonNode> {
  if (typeof node !== 'object') {
    return false;
  }

  if ('count' in node) {
    const { count } = node;

    if (count !== undefined && typeof count !== 'number') {
      return false;
    }
  }

  if ('label' in node) {
    const { label } = node;

    if (label !== undefined && typeof label !== 'string') {
      return false;
    }
  }

  if ('groups' in node) {
    const { groups } = node;

    if (groups !== undefined && Array.isArray(groups)) {
      if (!groups.every(isGroupNode)) {
        return false;
      }
    }
  }

  if ('nodes' in node) {
    const { nodes } = node;

    if (nodes !== undefined && Array.isArray(nodes)) {
      if (!nodes.every(isCommonNode)) {
        return false;
      }
    }
  }

  const groupKeys = ['count', 'value', 'label', 'groups', 'nodes', 'firstNodeIndex'];

  return !Object.keys(node).some(_.curry(doesNotInclude)(groupKeys));
}

export function areGroupNodes(node: any[]): node is Group<CommonNode>[] {
  if (!Array.isArray(node)) {
    return false;
  }

  return node.every(isGroupNode);
}

function doesNotInclude<T>(array: T[], value: T): boolean {
  return !array.includes(value);
}
