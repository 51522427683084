import React, { useState } from 'react';


import { WithStyles, withStyles } from '@material-ui/core';
import Button, { Variant as ButtonVariant } from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modals/Modal';
import Content from 'components/Modals/Modal/Content';
import Header from 'components/Modals/Modal/Header';
import Typography, {
  Variant as TypographyVariant,
} from 'components/Typography';
import { connect } from 'react-redux';

import { AppMessages } from '../../../constants';
import { State } from '../../../reducers';
import { styles } from './AddUser.styles';
import RolePicker, { Role } from './RolePicker';
import UserSuggestion from './UserSuggestion';

export enum UserEditorStates {
  BROWSE = 'browse',
  UPDATE = 'update',
  FETCHING = 'fetching',
  WITH_ERROR = 'with_error',
}

interface StateProps {
  tenant?: string | undefined;
}

type AddUserProps = WithStyles<typeof styles> & {
  onClose?: () => void;
  onDone: (result: { emails: string[]; role: string; message: string }) => void;
} & StateProps;

function AddUser({ onClose, onDone, tenant, classes }: AddUserProps) {
  const [emailValidationError, setEmailValidationError] = useState<
    string | null
  >(null);
  const [messageValidationError] = useState(false);
  const [userEditorStates] = useState(UserEditorStates.BROWSE);
  const [message, setMessage] = useState('');
  const [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClose = () => {
    if (onClose instanceof Function) onClose();
  };

  const handleEmailAdded = (emails: Array<string>) => {
    setSelectedEmails(emails);
  };

  const handleEmailValidation = (validationResult: string | null): void => {
    setEmailValidationError(validationResult);
  };

  const handleRoleChanged = (role: Role): void => {
    setSelectedRole(role);
  };

  const handleOnDone = async () => {
    setIsLoading(true);
    await onDone({
      emails: selectedEmails,
      role: selectedRole?.value || 'tenant-admin',
      message,
    });
    handleOnClose();
  };

  return (
    <div>
      <Modal onClose={handleOnClose}>
        {isLoading && <LoadingIndicator />}
        <div className={isLoading ? `${classes.hidden}` : ''}>
          <Header onClose={handleOnClose}>
            {`Invite collaborators to ${tenant}`}
          </Header>
          <Content>
            {userEditorStates === UserEditorStates.WITH_ERROR && (
              <div className="error-message-container">
                Failed to invite collaborator to HubSync
              </div>
            )}
            <div className={`${classes.row} ${classes.inviteInfo}`}>
              <Typography variant={TypographyVariant.ModalText}>
                {AppMessages.TENANT_ADMIN_INVITE_PARAGRAPH}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.field}>
                <div className={classes.fieldLabel}>
                  <Typography variant={TypographyVariant.FieldLabel}>
                    Invite collaborators
                  </Typography>
                </div>
                <UserSuggestion
                  onSelectionChange={handleEmailAdded}
                  onValidation={handleEmailValidation}
                />
                {emailValidationError && (
                  <Typography
                    variant={TypographyVariant.ModalText}
                    className={classes.errorMessage}
                  >
                    {`${AppMessages.WARNING_TITLE_LABEL} ${emailValidationError}`}
                  </Typography>
                )}
              </div>
              <RolePicker onRoleChanged={handleRoleChanged} />
            </div>
            <div className={classes.field}>
              <div className={classes.fieldLabel}>
                <Typography variant={TypographyVariant.FieldLabel}>
                  Message (optional)
                </Typography>
              </div>
              <div>
                <textarea
                  value={message}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMessage(event.target.value);
                  }}
                  className={classes.textarea}
                />
                <Typography
                  variant={TypographyVariant.ModalText}
                  className={classes.errorMessage}
                >
                  {messageValidationError
                    ? 'Message should be between 5 and 1000 characters'
                    : ' '}
                </Typography>
              </div>
            </div>
            <div className={classes.field}>
              <div
                className={`${classes.rowWithSpaceInBetween} ${classes.relative}`}
              >
                <Button
                  id="btnCancelInvite"
                  label="Cancel"
                  onClick={onClose}
                  variant={ButtonVariant.SecondaryLink}
                />
                <Button
                  id="btnSendInvite"
                  label={
                    !selectedRole || selectedRole?.value === 'tenant-admin'
                      ? 'Send Invite'
                      : 'Next'
                  }
                  onClick={handleOnDone}
                  variant={ButtonVariant.Primary}
                  disabled={
                    Boolean(emailValidationError) || selectedEmails.length === 0
                  }
                />
              </div>
            </div>
          </Content>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: State): StateProps => ({
  tenant: state?.app?.tenant?.title,
});

export default connect(mapStateToProps, null)(withStyles(styles)(AddUser));
