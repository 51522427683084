import { call, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { downloadFiles } from 'data/ui/fileDownload/fileDownload.actions';
import { downloadRepositoriesFiles } from 'utilities/downloadFiles';


function* download(action) {
  yield call(downloadRepositoriesFiles, action.payload);
}

export default function* fileDownloadSaga() {
  yield takeEvery(getType(downloadFiles), download);
}
