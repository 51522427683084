import * as React from 'react';

import { InputClassKey } from '@material-ui/core/Input';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import * as Colors from 'styles/colors';

interface OwnProps {
  value: string;
  lock?: boolean;
}

type Props
  = OwnProps
  & Omit<TextFieldProps, 'variant'>
  & WithStyles<ClassKey>
  ;

export function TextField(props: Props): JSX.Element {
  const { classes, lock, InputProps, InputLabelProps, ...textFieldProps } = props;

  return (
    <MuiTextField
      {...textFieldProps}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{ ...InputProps, classes, disableUnderline: true }}
      className={`${lock ? classes.locked: ''}`}
    />
  );
}

type RelevantInputClassKey
  = 'root'
  | 'error'
  | 'focused'
  | 'input'
  ;

type ClassKey = Extract<InputClassKey, RelevantInputClassKey> | 'locked';

const styles = withStyles<ClassKey>({
  root: {
    fontSize: '11px',
    backgroundColor: Colors.white,
    padding: '7px 9px',
    color: Colors.slate,
    border: `1px solid ${Colors.lightBlueGrey}`,
    borderRadius: 2,
  },
  error: { borderColor: Colors.tomato },
  focused: { borderColor: Colors.deprecatedButtonPrimary },
  input: { padding: 0 },
  locked: { paddingRight: '24px' },
});

export default styles(TextField);
