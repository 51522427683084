import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { datadogRum } from '@datadog/browser-rum';
import { getDataDogEnvironmentIdentifier, getDataDogApplicationIdentifier, getDataDogClientToken } from 'env';

datadogRum.init({
  applicationId: `${getDataDogApplicationIdentifier()}`,
  clientToken: `${getDataDogClientToken()}`,
  site: 'datadoghq.com',
  service: 'hubsync-platform-ui',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  env: `${getDataDogEnvironmentIdentifier()}`,
  allowedTracingOrigins: [/https:\/\/.*\.hubsyncdev-v2\.com/, /https:\/\/.*\.hubsync\.com/],
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
