import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { blueyGrey, slateTwo, hubsyncBlue, skyBlue, lightBlueSemiTransparent, iceBlue, brownishGrey, lightRed } from 'styles/colors';

type ClassKey =
  'row'
  | 'checklistsWrapper'
  | 'checklistWrapper'
  | 'checklist'
  | 'rowWithSpace'
  | 'textField'
  | 'textFieldRoot'
  | 'textFieldWrapper'
  | 'contextMenuWrapper'
  | 'checklistItemWrapper'
  | 'checklistIcon'
  | 'rowBrowse'
  | 'rowEdit'
  | 'addButtonWrapper'
  | 'checklistItemCheckboxWrapper'
  | 'marginBottom0'
  | 'lineThroughText'
  | 'fieldLabel'
  | 'itemContextMenuWrapper'
  | 'checklistFieldLabel'
  | 'dragIcon'
  | 'itemContextLarge'
  | 'checklistContextMenu'
  | 'activeItem'
  | 'fixedHeight'
  | 'percentageWrapper'
  | 'percentageLabel'
  | 'percentageLineWrapper'
  | 'percentageLine'
  | 'checklistItemDraggableWrapper'
  | 'checklistMaxLenth'
  | 'checklistAlert'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  checklistsWrapper: {
    margin: '0 30px 12px 0',
    display: 'flex',
    flexDirection: 'column',
  },

  marginBottom0: {
    marginBottom: 0,
  },

  checklistWrapper: {
    'marginBottom': '20px',
  },

  checklist: {
    '&:hover button': {
      visibility: 'visible',
    },
    '&:hover': {
      backgroundColor: lightBlueSemiTransparent,
    },
    'alignItems': 'center',
    'paddingTop': '6px',
    'paddingBottom': '4px',
    'paddingLeft': '5px',
    'minHeight': '40px',
  },

  row: {
    display: 'flex',
    flexGrow: 1,
  },

  rowWithSpace: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  rowBrowse: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },

  activeItem: {
    backgroundColor: lightBlueSemiTransparent,
  },

  rowEdit: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },

  textFieldWrapper: {
    'width': '65%',
    'marginLeft': '10px',
    'cursor': 'pointer',
    'flexGrow': 1,
    '& *': {
      fontSize: '14px',
    },
  },

  textFieldRoot: {
    'width': '100%',
    '& > div:before': {
      borderBottom: 'none!important',
    },
    '& > div:after': {
      borderBottom: 'none!important',
    },
  },

  textField: {
    height: '18px',
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
    color: slateTwo,
    fontWeight: 400,
  },

  contextMenuWrapper: {
    marginLeft: '10px',
  },

  itemContextLarge: {
    width: '58px',
  },

  itemContextMenuWrapper: {
    visibility: 'hidden',
  },

  checklistIcon: {
    width: '17px',
    height: '17px',
    color: slateTwo,
  },

  checklistItemWrapper: {
    'width': '100%',
    'paddingTop': '5px',
    'paddingBottom': '5px',
    '& > div:hover button': {
      'visibility': 'visible',
    },
    'paddingLeft': '5px',
    'alignItems': 'center',
    '& > div': {
      'minHeight': '30px',
      'alignItems': 'center',
    },
  },

  addButtonWrapper: {
    marginTop: '20px',
  },

  checklistItemCheckboxWrapper: {
    'marginRight': '5px',
    '& svg': {
      'width': '10px',
      'height': '10px',
    },
    'cursor': 'pointer',
  },

  lineThroughText: {
    textDecoration: 'line-through',
    opacity: .3,
  },

  fieldLabel: {
    textTransform: 'initial',
    fontSize: '14px',
    color: slateTwo,
    fontWeight: 400,
  },

  checklistFieldLabel: {
    color: blueyGrey,
    fontSize: '14px',
  },

  dragIcon: {
    cursor: 'drag',
  },

  checklistContextMenu: {
    '& button:hover': {
      color: hubsyncBlue,
      backgroundColor: skyBlue,
    },
  },

  fixedHeight: {
    height: '40px',
  },
  percentageWrapper: {
    display: 'flex',
    padding: '0px 4px',
  },
  percentageLabel: {
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '11px',
    letterSpacing: '0.22px',
    lineHeight: '13px',
    textAlign: 'center',
    color: brownishGrey,
  },
  percentageLineWrapper: {
    width: '100%',
    position: 'relative',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: iceBlue,
    overflow: 'hidden',
    marginLeft: '8px',
  },
  percentageLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: hubsyncBlue,
    transition: 'all .5s ease',
  },
  checklistItemDraggableWrapper: {
    '&:hover': {
      'backgroundColor': lightBlueSemiTransparent,
    },

    '& > svg': {
      marginRight: '5px',
    },
  },
  checklistMaxLenth: {
    border: `'1px solid' ${lightRed}`,
  },
  checklistAlert: {
    '& > span': {
      color: lightRed,
      fontSize: '11px',
    },

    '&': {
      marginTop: '.5rem',
    },

  },
};
