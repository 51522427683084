import * as React from 'react';

import LoadingIndicator from 'components/LoadingIndicator';
import { CARD_TOTAL_WIDTH } from 'components/TilesView/TileItem.style';
import TilesList from 'components/TilesView/TilesList';
import TilesViewBar from 'components/TilesView/TilesViewBar';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { bookmarks } from 'data/bookmarks/bookmarks.selectors';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import {
  getCollectionByIdForTileView,
  getCurrentNodeForTileView,
  getLoadingForTileView,
} from 'data/collections/collections.selectors';
import { setCurrentDatabase } from 'data/databases/databases.actions';
import LoadingState from 'data/LoadingState';
import { openCreateCollectionItemModal } from 'data/modals/actions';
import { setCurrentTaskDatabase } from 'data/taskdbs/taskdbs.actions';
import { setCurrentWorkspace } from 'data/workspaces/workspaces.actions';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { curry, reduce, isEqual, sortBy } from 'lodash/fp';
import { PARENT_MARGINS, Wrapper } from 'pages/TilesViewPage.style';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { Bookmark, CollectionTypes, NodeWithTitle } from 'types/schema';
import { getPluralCollectionName, getSingularCollectionName } from 'utilities/collections';
import { createDatabasesUrl, getCollectionTypeFromUrl } from 'utilities/createUrl';
import loadingAction from 'data/ui/loading/actions';


interface StateProps {
  collectionById: Record<string, NodeWithTitle>;
  bookmarks: Bookmark[];
  loadingState: LoadingState;
  current: string;
  permissions: string[];
  appPermissions: string[];
}

interface DispatchProps {
  fetchCollection: typeof fetchCollectionRequest;
  setCurrentWorkspace: typeof setCurrentWorkspace;
  setCurrentDatabase: typeof setCurrentDatabase;
  setCurrentTaskDatabase: typeof setCurrentTaskDatabase;
  openCreateCollectionItemModal: typeof openCreateCollectionItemModal;
  endLockingLoader: () => void;
}

interface InjectedProps {
  collectionType?: CollectionTypes;
}

interface NodeBundle {
  favorites: NodeWithTitle[];
  nonFavorites: NodeWithTitle[];
}

interface State {
  query?: string;
  originalNodes: NodeBundle;
  filteredNodes: NodeBundle;
  cardsPerRow: number;
}

type Props = StateProps & DispatchProps & InjectedProps & URLInjectedProps;

class TilesViewPage extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    const { bookmarks, collectionById } = props;
    const tileNodes = bundleNodes(bookmarks)(Object.values(collectionById));

    this.state = { originalNodes: tileNodes, filteredNodes: tileNodes, cardsPerRow: 1 };

    window.addEventListener('resize', this.updateCardsPerRow);
  }

  public componentDidMount(): void {
    this.updateCollection();
    this.updateCardsPerRow();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { bookmarks, collectionById } = this.props;

    if (!isEqual(bookmarks, prevProps.bookmarks) || !isEqual(collectionById, prevProps.collectionById)) {
      const tileNodes = bundleNodes(bookmarks)(Object.values(collectionById));

      const { query } = this.state;

      if (query) {
        this.setState({ originalNodes: tileNodes, filteredNodes: filterByQuery(query, tileNodes) });
      } else {
        this.setState({ originalNodes: tileNodes, filteredNodes: tileNodes });
      }
    }

    this.updateCollection();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateCardsPerRow);
    this.props.endLockingLoader();
  }

  public render(): JSX.Element | null {
    const { loadingState, collectionType, permissions, appPermissions } = this.props;
    const { filteredNodes, query, cardsPerRow } = this.state;

    if (!collectionType) {
      return null;
    }

    if (loadingState === LoadingState.Loading) {
      return (
        <LoadingIndicator />
      );
    }

    const singularCollectionName = getSingularCollectionName(collectionType);
    const pluralCollectionName = getPluralCollectionName(collectionType);
    const includeDescendantsCount = collectionType === CollectionTypes.databases || collectionType === CollectionTypes.taskdbs;
    // Optional relabeling for some of the collections that include descendants count:
    const descendantsLabel = collectionType === CollectionTypes.databases || collectionType === CollectionTypes.taskdbs ? 'record' : undefined;

    const favoritesOrdered = sortBy('modifiedAt')(filteredNodes.favorites).reverse();
    const nonFavoritesOrdered = sortBy('modifiedAt')(filteredNodes.nonFavorites).reverse();

    return (
      <Wrapper>
        <TilesViewBar
          collectionType={collectionType}
          collectionName={pluralCollectionName}
          itemName={singularCollectionName}
          query={query}
          onAddNew={this.onAddNew}
          onQueryChanges={this.onQueryChanges}
          permissions={collectionType === CollectionTypes.workspaces ? appPermissions : permissions}
        />
        <TilesList
          id="favoritesList"
          collectionType={collectionType}
          collectionName={pluralCollectionName}
          heading={`Favorite ${pluralCollectionName}`}
          nodes={favoritesOrdered}
          cardsPerRow={cardsPerRow}
          includeDescendantsCount={includeDescendantsCount}
          descendantsLabel={descendantsLabel}
          permissions={permissions}
          appPermissions={appPermissions}
        />
        <TilesList
          id="databasesList"
          collectionType={collectionType}
          collectionName={pluralCollectionName}
          heading={filteredNodes.favorites.length ? `All ${pluralCollectionName}` : ''}
          nodes={nonFavoritesOrdered}
          cardsPerRow={cardsPerRow}
          includeDescendantsCount={includeDescendantsCount}
          descendantsLabel={descendantsLabel}
          permissions={permissions}
          appPermissions={appPermissions}
        />
      </Wrapper>
    );
  }

  private onAddNew = (): void => {
    const { collectionType, openCreateCollectionItemModal } = this.props;
    if (collectionType) {
      openCreateCollectionItemModal(collectionType);
    }
  };

  private onQueryChanges = (query: string): void => {
    const { originalNodes } = this.state;

    if (query) {
      this.setState({ query, filteredNodes: filterByQuery(query, originalNodes) });
    } else {
      this.setState({ query: undefined, filteredNodes: originalNodes });
    }

    const timer = setTimeout( () => {
      this.props.endLockingLoader();
      clearTimeout(timer);
    }, 500);
  };

  private updateCardsPerRow = (): void => {
    const cardsPerRow: number = Math.floor((window.innerWidth - PARENT_MARGINS) / CARD_TOTAL_WIDTH);
    this.setState({ cardsPerRow });
  };

  private updateCollection = (): void => {
    const {
      collectionType,
      loadingState,
      fetchCollection,
      location,
      current,
      setCurrentDatabase,
      setCurrentTaskDatabase,
    } = this.props;

    if (collectionType && loadingState === LoadingState.Unloaded) {
      switch (collectionType) {
        case CollectionTypes.databases:
        case CollectionTypes.taskdbs:
          fetchCollection({ url: createDatabasesUrl(location) });
          break;
      }
    }

    if (current) {
      switch (collectionType) {
        case CollectionTypes.databases:
          setCurrentDatabase(null);
          break;
        case CollectionTypes.taskdbs:
          setCurrentTaskDatabase(null);
          break;
      }
    }
  };
}

const propsInterceptor = (props: URLInjectedProps): InjectedProps => ({
  collectionType: getCollectionTypeFromUrl(props.location.pathname),
});

const selectors = createStructuredSelector<ReduxState, URLInjectedProps, StateProps>({
  bookmarks,
  collectionById: getCollectionByIdForTileView,
  loadingState: getLoadingForTileView,
  current: getCurrentNodeForTileView,
  permissions: getWorkspacePermissions,
  appPermissions: (state: ReduxState) => state.users.user.permissions || [],
});

const mapDispatchToProps: DispatchProps = {
  fetchCollection: fetchCollectionRequest,
  setCurrentWorkspace,
  setCurrentDatabase,
  setCurrentTaskDatabase,
  openCreateCollectionItemModal,
  endLockingLoader: loadingAction.endLockingLoader,
};

const enhance = compose(
  withURLParams,
  withProps(propsInterceptor),
  connect(selectors, mapDispatchToProps),
);

export default enhance(TilesViewPage);

function bundleNodes(bookmarks: Bookmark[]): (nodes: NodeWithTitle[]) => NodeBundle {
  return reduce((tileNodes: NodeBundle, node: NodeWithTitle): NodeBundle => {
    const isBookmarked = bookmarks.some((bookmark) => {
      return bookmark.recordID === node.id && bookmark.model === node.bookmarkMeta?.model;
    });

    if (isBookmarked) {
      return {
        ...tileNodes,
        favorites: [...tileNodes.favorites, node],
      };
    }

    return {
      ...tileNodes,
      nonFavorites: [...tileNodes.nonFavorites, node],
    };
  }, { favorites: [], nonFavorites: [] });
}

function filterByQuery(query: string, nodes: NodeBundle): NodeBundle {
  const filterFn = curriedDoesTitleMatch(query);
  return {
    favorites: nodes.favorites.filter(filterFn),
    nonFavorites: nodes.nonFavorites.filter(filterFn),
  };
}

function doesTitleMatch(query: string, node: NodeWithTitle): boolean {
  return node.title.toLowerCase().includes(query.toLowerCase());
}

const curriedDoesTitleMatch = curry(doesTitleMatch);
