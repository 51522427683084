import * as React from 'react';

import { WithStyles } from '@material-ui/core';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import SelectFieldType, { SelectionState } from 'components/Fields/SelectFieldType';
import ModalHeader from 'components/Modals/Modal/Header';
import { Choice, FieldType as NodeFieldType } from 'types/response/fieldNode';

import { getColorByIndex } from '../../styles/colors';
import { CommonNode } from '../../types/response';
import { convertIdIntoUsername, convertUnixDate, transformValue } from '../../pages/ImportDatabase/ImportDatebase';
import { AccountNode } from '../../types/response/accountNode';
import Checkbox from '../Checkbox';
import Modal from '../Modals/Modal';
import ModalContent from 'components/Modals/Modal/Content';
import ModalActions from 'components/Modals/Modal/Actions';
import { flatten, uniq } from 'lodash';
import { Column } from 'types/schema';

import { User, UserSettings } from '../../data/users/users.types';
import ActionButtons from './ActionButtons';
import { getPrecision } from './createFieldPayload';
import {
  AttachmentData,
  CheckboxData,
  ChoiceOption,
  CurrencyData,
  DateData,
  EmailData,
  FieldData,
  FieldType,
  getDefaultFieldData,
  LongTextData,
  MultiSelectData,
  NumberData,
  NumberType,
  PercentData,
  PersonData,
  PhoneData,
  Precision,
  RatingData,
  SingleLineTextData,
  SingleSelectData,
  UrlData,
} from './data';
import * as Data from './data';
import FieldNameInput from './FieldNameInput';
import styles, { ClassKey } from './styles';
import { removeHTMLTagsFromText } from './../AgGrid/utils';

interface NodeChoice {
  [key: string]: Choice;
}

const defaultPopoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

interface OwnProps<Node extends CommonNode> {
  field?: Column;
  anchorEl: HTMLElement | null;
  errorMessage?: string;
  onClose: () => void;
  onSave: (title: string, data: FieldData, originalFieldData: FieldData) => void;
  rows: Node[];
  updateItems: (payload: any) => void;
  users: AccountNode[];
  user: User;
  removeFieldRevisions: (fieldId: string) => void;
  changeUserModifyFieldModalPreference: (settings: UserSettings) => void;
}

export interface State {
  name: string;
  fieldData: FieldData;
  originalFieldData: FieldData;
  allowMultipleCollaborators?: boolean;
  selectFieldState: SelectionState;
  fieldTypeFilterQuery: string;
  showSaveConfirm: boolean;
  hideModifyFieldChecked: boolean;
}

type Props<Node extends CommonNode> = OwnProps<Node> & WithStyles<ClassKey>;

export class ModifyField<Node extends CommonNode> extends React.Component<Props<Node>, State> {
  state = {
    name: '',
    allowMultipleCollaborators: undefined,
    originalFieldData: getDefaultFieldData(FieldType.SingleLineText),
    fieldData: getDefaultFieldData(FieldType.SingleLineText),
    selectFieldState: SelectionState.SELECTED_FIELD,
    fieldTypeFilterQuery: '',
    showSaveConfirm: false,
    hideModifyFieldChecked: false,
  };

  public componentDidMount(): void {
    const fieldData = this.getFieldData();
    if (fieldData) {
      this.setState({
        name: (this.props.field && this.props.field.name) || '',
        fieldData: { ...fieldData },
        originalFieldData: { ...fieldData },
      });
    }
  }

  public render(): JSX.Element {
    return (
      <Popover
        id="modifyField"
        {...defaultPopoverProps}
        open={true}
        anchorEl={this.props.anchorEl}
        onClose={this.props.onClose}
      >
        <div className={this.props.classes.wrapper}>
          <FieldNameInput
            fieldName={this.state.name}
            onChange={this.onChange}
            errorMessage={this.props.errorMessage}
          />
          <div className={`${this.state.fieldData.type !== this.state.originalFieldData.type && [Data.FieldType.SingleSelect, Data.FieldType.MultiSelect].includes(this.state.fieldData.type) ? 'modify-choice-field' : ''}`}>
            <SelectFieldType
              fieldTypes={Data.allFieldTypes.filter(fieldType => fieldType.toLocaleLowerCase().includes(this.state.fieldTypeFilterQuery.toLowerCase()))}
              onDataUpdate={this.onDataChange}
              onToggleSelectFieldType={this.onToggleSelectFieldType}
              selectedFieldData={this.state.fieldData}
              selectionState={this.state.selectFieldState}
              allowMultipleCollaborators
              fieldTypeFilterQuery={this.state.fieldTypeFilterQuery}
              onFieldTypeFilterQueryChange={this.onFieldTypeFilterQueryChange}
              onFieldTypeSelected={this.onFieldTypeSelected}
            />
          </div>
          <ActionButtons
            onCancel={this.props.onClose}
            onSave={this.onSaveConfirm}
          />
          {this.state.showSaveConfirm && (
            <Modal id="modalModifyFieldConfirm" onClose={this.onClose}>
              <ModalHeader onClose={this.onClose}>Are you sure you want to convert to this field type?</ModalHeader>
              <ModalContent>
                <p className={this.props.classes.confirmSaveLabel}>Converting to this field type may clear some cell
                  data from every record in the table.</p>
                <div className={this.props.classes.disableModifyModal} onClick={this.toggleModifyFieldModal}>
                  <Checkbox className={this.props.classes.disableModifyModalCheckbox}
                    selected={this.state.hideModifyFieldChecked} />
                  <p className={this.props.classes.confirmSaveLabel}>{'Don\'t show this message again'}</p>
                </div>
              </ModalContent>
              <ModalActions submitLabel="Convert" onCancel={this.onClose} onSubmit={this.onSave} />
            </Modal>
          )}
        </div>
      </Popover>
    );
  }

  private onFieldTypeSelected = (fieldType: Data.FieldType) => {
    this.setState({
      fieldData: { type: fieldType } as any, selectFieldState: SelectionState.SELECTED_FIELD,
    }, () => {
      if (fieldType === FieldType.SingleSelect || fieldType === FieldType.MultiSelect) {
        const choices = fieldType === FieldType.SingleSelect ? this.createSingleSelectChoices() : this.createMultiSelectChoices();
        this.setState({ fieldData: { ...this.state.fieldData, choices } as any });
      } else if (fieldType === FieldType.Currency) {
        this.setState({ fieldData: { ...this.state.fieldData, currencySymbol: '$', precision: '1' } as any });
      } else if (fieldType === FieldType.Date) {
        this.setState({ fieldData: { ...this.state.fieldData, dateFormat: Data.DateFormat.Local } as DateData });
      }
    });
  };

  private createChoices = (values) => {
    const uniqueValues = uniq(values);
    const convertedValues = convertIdIntoUsername(values.toString(), this.props.users);
    const choices = {};
    (convertedValues.length ? convertedValues : uniqueValues).forEach((value: any, index: number) => {
      if (value === true) {
        value = 'Checked';
      }
      value = `${value}`.trim();
      choices[value] = {
        id: value,
        value: {
          label: value,
          color: getColorByIndex(index),
        },
      };
    });
    return choices;
  };

  private createSingleSelectChoices = () => {
    // @ts-ignore
    const rowValues = this.props.rows.filter(row => row.fields[this.props.field._id]).map(row => {
      // @ts-ignore
      const value = removeHTMLTagsFromText(row.fields[this.props.field._id]);
      if (Array.isArray(value)) {
        return value[0];
      }
      return value;
    });
    return this.createChoices(rowValues);
  };

  private createMultiSelectChoices = () => {
    // @ts-ignore
    const rowValues = this.props.rows.filter(row => row.fields[this.props.field._id]).map(row => {
      // @ts-ignore
      let value: any = removeHTMLTagsFromText(row.fields[this.props.field._id]);

      if (this.props.field?.fieldType === NodeFieldType.Singlechoice) {
        value = this.props.field?.choices?.[value]?.label || value;
      }

      return typeof value === 'string' ? value.split(',') : value;
    });
    const uniqueValues = uniq(flatten(rowValues));
    return this.createChoices(uniqueValues);
  };

  private onFieldTypeFilterQueryChange = (value: string) => {
    this.setState({ fieldTypeFilterQuery: value });
  };

  private onToggleSelectFieldType = () => {
    this.setState({
      selectFieldState:
        this.state.selectFieldState === SelectionState.SELECTED_FIELD
          ? SelectionState.SELECT_FIELD
          : SelectionState.SELECTED_FIELD,
    });
  };

  private getFieldData = (): FieldData | null => {
    if (!this.props.field) return null;

    switch (this.props.field.fieldType) {
      case NodeFieldType.Singlelineoftext:
        return {
          type: FieldType.SingleLineText,
          defaultText: this.props.field.default || '',
        } as SingleLineTextData;
      case NodeFieldType.Multilinetext:
        return {
          type: FieldType.LongText,
        } as LongTextData;
      case NodeFieldType.Attachment:
        return {
          type: FieldType.Attachment,
          showPreview: this.props.field.showPreview || false,
        } as AttachmentData;
      case NodeFieldType.Boolean:
        return {
          type: FieldType.Checkbox,
          defaultValue: this.props.field.default || false,
        } as CheckboxData;
      case NodeFieldType.Multiplechoice:
        return {
          type: FieldType.MultiSelect,
          choices: this.props.field.choices ? this.parseChoices(this.props.field.choices) : [],
          choiceOrder: this.props.field.choiceOrder ? this.props.field.choiceOrder : [],
          allowGridAddingOptions: this.props.field.allowGridAddingOptions || false,
          defaultValue: this.props.field.default || '',
        } as MultiSelectData;
      case NodeFieldType.Singlechoice:
        return {
          type: FieldType.SingleSelect,
          choices: this.props.field.choices ? this.parseChoices(this.props.field.choices) : [],
          choiceOrder: this.props.field.choiceOrder ? this.props.field.choiceOrder : [],
          allowGridAddingOptions: this.props.field.allowGridAddingOptions || false,
          defaultValue: this.props.field.default || '',
        } as SingleSelectData;
      case NodeFieldType.Account:
        this.setState({ allowMultipleCollaborators: true });
        return {
          type: FieldType.Person,
          allowMultiple: this.props.field.allowMultiple,
        } as PersonData;
      case NodeFieldType.Date:
        return {
          type: FieldType.Date,
          dateFormat: this.props.field.dateFormat,
          timeFormat: this.props.field.timeFormat,
        } as DateData;
      case NodeFieldType.Integer:
      case NodeFieldType.Float:
        return {
          type: FieldType.Number,
          defaultNumber: this.props.field.default,
          allowNegative: this.props.field.allowNegative || false,
          precision: (this.props.field.precision && this.convertPrecision(this.props.field.precision)) || Precision.A,
          subtype: this.props.field.fieldType === NodeFieldType.Integer ? NumberType.Integer : NumberType.Float,
        } as NumberData;
      case NodeFieldType.Currency:
        return {
          type: FieldType.Currency,
          defaultNumber: this.props.field.default,
          allowNegative: this.props.field.allowNegative || false,
          precision: (this.props.field.precision && this.convertPrecision(this.props.field.precision)) || Precision.A,
          currencySymbol: this.props.field.currencySymbol,
        } as CurrencyData;
      case NodeFieldType.Percent:
        return {
          type: FieldType.Percent,
          defaultNumber: this.props.field.default,
          allowNegative: this.props.field.allowNegative || false,
          precision: (this.props.field.precision && this.convertPrecision(this.props.field.precision)) || Precision.A,
        } as PercentData;
      case NodeFieldType.Rating:
        return {
          type: FieldType.Rating,
        } as RatingData;
      case NodeFieldType.Url:
        return {
          type: FieldType.URL,
          defaultText: this.props.field.default || '',
        } as UrlData;
      case NodeFieldType.Email:
        return {
          type: FieldType.Email,
          defaultText: this.props.field.default || '',
        } as EmailData;
      case NodeFieldType.Phone:
        return {
          type: FieldType.Phone,
          defaultText: this.props.field.default || '',
        } as PhoneData;
      default:
        return null;
    }
  };

  private onChange = (value: string): void => {
    this.setState({ name: value });
  };

  private onDataChange = (data: FieldData): void => {
    this.setState({ fieldData: data });
  };

  private transformRows = (originalField, fieldData) => {
    const items = {};
    this.props.rows.forEach(item => {
      // @ts-ignore
      const fieldId = this.props.field._id as string;
      const fieldValue = removeHTMLTagsFromText(
        item.fields?.[fieldId] as string,
      );
      const value =
        originalField.type === FieldType.SingleSelect
          ? removeHTMLTagsFromText(this.props.field?.choices?.[fieldValue]?.label as string)
          : removeHTMLTagsFromText(item.fields?.[fieldId] as string);
      const transformedValue = transformValue(fieldData.type, value, {
        ...fieldData,
        originalField,
      }, this.props.users, fieldData.allowMultiple);
      items[item.id] = {
        fields: {
          // @ts-ignore
          [this.props.field._id]: transformedValue,
        },
      };
    });
    return items;
  };

  private toggleModifyFieldModal = () => {
    this.setState({ hideModifyFieldChecked: !this.state.hideModifyFieldChecked });
  };

  private onSaveConfirm = (): void => {
    if (this.props.user?.settings?.hideModifyFieldConfirmModal || this.state.originalFieldData.type === this.state.fieldData.type) {
      this.onSave();
      return;
    }

    this.setState({ showSaveConfirm: true });
  };

  private onClose = () => {
    this.setState({ showSaveConfirm: false });
  };

  private onSave = (): void => {
    const fieldName = this.props.field?.name;

    if (!this.state.name) {
      if (fieldName) {
        this.setState({ name: fieldName });
      } else {
        return;
      }
    }

    let { fieldData } = this.state;
    const typeChanged = this.state.originalFieldData.type !== this.state.fieldData.type;
    if (
      [FieldType.MultiSelect, FieldType.SingleSelect].includes(
        this.state.fieldData.type,
      )
    ) {
      fieldData = fieldData as MultiSelectData;
      fieldData = {
        ...fieldData,
        choices: Array.isArray(fieldData.choices)
          ? fieldData.choices.filter((choice) => choice.value.label.trim())
          : Object.values(fieldData.choices).map((choice: any) => {
            if (this.state.originalFieldData.type === FieldType.Date) {
              choice.value.label = convertUnixDate(
                choice.value.label,
                this.state.originalFieldData.dateFormat,
                false,
              );
            }
            return choice;
          }),
      };
    }

    if (this.state.hideModifyFieldChecked) {
      this.props.changeUserModifyFieldModalPreference({ ...this.props.user.settings, hideModifyFieldConfirmModal: true });
    }

    this.props.onSave(this.state.name, fieldData, this.state.originalFieldData);
    this.props.onClose();

    if (typeChanged) {
      const transformedRows = this.transformRows(this.state.originalFieldData, fieldData);
      this.props.updateItems({ items: transformedRows });
      // @ts-ignore
      this.props.removeFieldRevisions(this.props.field._id);
    }
  };

  private parseChoices = (choices: NodeChoice): ChoiceOption[] => {
    return Object.keys(choices).map((id: string): ChoiceOption => {
      const value = (this.props.field && choices && choices[id] ? choices[id] : {}) as Choice;
      if (this.state.originalFieldData.type === FieldType.Date) {
        // @ts-ignore
        value.label = convertUnixDate(value?.label, this.state.originalFieldData.dateFormat, false) || '';
      }
      return { id, value: value };
    });
  };

  private convertPrecision = (precision: number): Precision => {
    for (const item in Precision) {
      if (precision === getPrecision(Precision[item])) {
        return Precision[item];
      }
    }
    return Precision.A;
  };
}

export default styles(ModifyField);
