import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import * as Colors from 'styles/colors';
import { fontFamily } from 'styles/fonts';

export enum Variant {
  Label,
  Input,
  Title,
  Heading,
  SubHeading,
  FieldLabel,
  CardText,
  MenuItem,
  ModalText,
  NavigationTitle,
  Notes,
  ProfileInitials,
  ProfileName,
  Timestamp,
  CardInfo,
  CardInfoValue,
  ToolTipText,
  ItalicTitle,
  ListSubheader,
  GreyToolTipText,
}

interface ChildrenProps {
  className: string;
}

interface OwnProps {
  variant: Variant;
  ellipsisOverflow?: boolean;
  className?: string;
  children: string | React.ReactElement<ChildrenProps>;
  onClick?: () => void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Typography(props: Props): JSX.Element | null {
  const { classes, className = '', children, variant, onClick } = props;

  if (!children) return null;

  const newClassName = cn({
    [className]: className,
    [classes.label]: variant === Variant.Label,
    [classes.fieldLabel]: variant === Variant.FieldLabel,
    [classes.input]: variant === Variant.Input,
    [classes.title]: variant === Variant.Title,
    [classes.heading]: variant === Variant.Heading,
    [classes.subHeading]: variant === Variant.SubHeading,
    [classes.cardText]: variant === Variant.CardText,
    [classes.menuItem]: variant === Variant.MenuItem,
    [classes.modalText]: variant === Variant.ModalText,
    [classes.navigationTitle]: variant === Variant.NavigationTitle,
    [classes.notes]: variant === Variant.Notes,
    [classes.profileInitials]: variant === Variant.ProfileInitials,
    [classes.profileName]: variant === Variant.ProfileName,
    [classes.timestamp]: variant === Variant.Timestamp,
    [classes.ellipsisOverflow]: props.ellipsisOverflow,
    [classes.cardInfo]: variant === Variant.CardInfo,
    [classes.cardInfoValue]: variant === Variant.CardInfoValue,
    [classes.toolTipText]: variant === Variant.ToolTipText,
    [classes.greyToolTipText]: variant === Variant.GreyToolTipText,
    [classes.italicTitle]: variant === Variant.ItalicTitle,
    [classes.listSubheader]: variant === Variant.ListSubheader,
  });

  if (typeof children === 'string') {
    return <span className={newClassName} onClick={onClick}>{children}</span>;
  }

  return React.cloneElement(children, { className: newClassName });
}

type ClassKey
  = 'title'
  | 'heading'
  | 'subHeading'
  | 'label'
  | 'input'
  | 'fieldLabel'
  | 'cardText'
  | 'menuItem'
  | 'modalText'
  | 'navigationTitle'
  | 'notes'
  | 'profileInitials'
  | 'profileName'
  | 'ellipsisOverflow'
  | 'timestamp'
  | 'cardInfo'
  | 'cardInfoValue'
  | 'toolTipText'
  | 'italicTitle'
  | 'listSubheader'
  | 'greyToolTipText'
  ;

const styles = withStyles<ClassKey>({
  label: {
    color: Colors.brownishGrey,
    fontSize: '11px',
    fontFamily,
    letterSpacing: 0.18,
    lineHeight: '13px',
    marginRight: 5,
  },
  input: {
    color: Colors.slateTwo,
    fontSize: '11px',
    fontFamily,
    letterSpacing: 0.18,
    lineHeight: '11px',
  },
  title: {
    color: Colors.slateTwo,
    fontSize: '14px',
    fontFamily,
    fontWeight: 500,
    letterSpacing: 0.23,
    lineHeight: '20px',
  },
  italicTitle: {
    fontStyle: 'italic',
    fontSize: '14px',
    fontFamily,
    letterSpacing: 0.23,
    lineHeight: '20px',
  },
  heading: {
    color: Colors.charocalGrey,
    fontSize: '20px',
    fontFamily,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    lineHeight: '20px',
  },
  subHeading: {
    color: Colors.charocalGrey,
    fontSize: '16px',
    fontFamily,
    fontWeight: 500,
    lineHeight: '21px',
  },
  fieldLabel: {
    color: Colors.blueyGrey,
    fontSize: '11px',
    fontFamily,
    fontWeight: 500,
    letterSpacing: 0.22,
    textTransform: 'uppercase',
  },
  cardText: {
    color: Colors.dark,
    fontSize: '14px',
    fontFamily,
    letterSpacing: 0.28,
    lineHeight: '16px',
  },
  menuItem: {
    color: Colors.slateTwo,
    fontSize: '11px',
    fontFamily,
    letterSpacing: 0.18,
    lineHeight: '23px',
  },
  modalText: {
    color: Colors.brownishGrey,
    fontSize: '14px',
    fontFamily,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: '23px',
  },
  navigationTitle: {
    fontSize: '13px',
    fontFamily,
    fontWeight: 500,
    letterSpacing: 0.26,
    lineHeight: '15px',
  },
  notes: {
    color: Colors.blueyGrey,
    fontSize: '13px',
    fontFamily,
    letterSpacing: 0.22,
    lineHeight: '15px',
  },
  profileInitials: {
    color: Colors.white,
    fontSize: '11px',
    fontFamily,
    fontWeight: 'normal',
    letterSpacing: 0.23,
    lineHeight: '13px',
  },
  profileName: {
    color: Colors.white,
    fontSize: '13px',
    fontFamily,
    fontWeight: 500,
    letterSpacing: 0.22,
    lineHeight: '15px',
  },
  ellipsisOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  timestamp: {
    fontSize: '11px',
    color: Colors.charocalGrey,
  },
  cardInfo: {
    fontSize: '14px',
    color: Colors.blueGrey,
    fontFamily,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: '20px',
    display: 'block',
  },
  cardInfoValue: {
    fontSize: '14px',
    color: Colors.charocalGrey,
    fontFamily,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: '24px',
    display: 'block',
  },
  toolTipText: {
    fontSize: '11px',
    display: 'block',
    color: Colors.darkSlateBlue,
    fontFamily,
    letterSpacing: '0.18px',
    lineHeight: '15px',
  },
  greyToolTipText: {
    color: Colors.white,
    fontSize: '14px',
    fontFamily,
    fontWeight: 400,
  },
  listSubheader: {
    fontSize: '13px',
    color: Colors.blueGrey,
    fontFamily,
    letterSpacing: 0,
    lineHeight: '21px',
  },
});

export default styles(Typography);
