import { athensGrey, slateTwo } from 'styles/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'dropdownBox' | 'dropdownBoxText' | 'dropdownBoxIcon' | 'mainContainer';

export const styles: Record<ClassKey, CSSProperties> = {
  dropdownBox: {
    backgroundColor: athensGrey,
    borderRadius: '.25rem',
    width: '200px',
    height: '30px',
    cursor: 'pointer',
    marginRight: '2px',
  },
  dropdownBoxText: {
    width: '170px',
    padding: '5px 10px',
    borderRadius: '2px',
  },
  dropdownBoxIcon: {
    fontSize: '1.3em',
    marginLeft: '5px',
    marginTop: '4px',
    color: slateTwo,
  },
  mainContainer: {
    width: 'auto',
  },
};
