import _ from 'lodash';
import { getFileExtension } from 'utilities/files';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from 'utilities/httpRequests';
import { v4 as uuidv4 } from 'uuid';

import createTree from '../Utils/createTree';

export let files: any[] = [];
let cachedWorkspaceID = '';
let fileLinks = {};

export async function fetchFiles(workspaceID: string, parentID?: string) {
  if (files.length === 0 || cachedWorkspaceID !== workspaceID) {
    const url = `/workspaces/${workspaceID}/files?api_version=2`;
    const filesResult = await getRequest(url, window.appStore.getState());
    files = filesResult.body;
    await fetchFileLinks(workspaceID);

    cachedWorkspaceID = workspaceID;
  }
  return filterFiles(parentID);
}

export async function mapFileResponse() {
  files.map((file) => {
    file['shared'] = {};
    if (fileLinks) {
      if (fileLinks[file._id]) {
        file['shared']['view'] = fileLinks[file._id]['view'] || null;
        file['shared']['edit'] = fileLinks[file._id]['edit'] || null;
      }
    }

    if (file['isFolder']) {
      file['meta'] = {
        ...file['meta'],
        size: files.filter((filterFile) => {
          return filterFile.parentID === file._id;
        }).length,
      };
    }

    return file;
  });
}

export async function fetchFileLinks(workspaceID: string) {
  const url = `/workspaces/${workspaceID}/file-links?api_version=2`;
  const fileLinksResult = await getRequest(url, window.appStore.getState());
  const links = fileLinksResult.body;
  fileLinks = links.reduce((acml, obj) => {
    acml[obj.references.fileID] = acml[obj.references.fileID] || {};
    acml[obj.references.fileID][obj.type] = obj;
    return acml;
  }, {});
}

export async function getFile(workspaceID, fileID, preview = false) {
  const url = `/workspaces/${workspaceID}/files/get/${fileID}?api_version=2&${
    preview && 'preview=show'
  }`;
  const getFileResponse = await getRequest(url, window.appStore.getState());
  const fileResponse = _.get(getFileResponse, 'body');
  return fileResponse;
}

export async function patchFile(workspaceID, id, values) {
  const url = `/workspaces/${workspaceID}/files/${id}?api_version=2`;
  const patchFileResponse = await patchRequest(
    { url, data: values },
    window.appStore.getState(),
  );
  const fileResponse = _.get(patchFileResponse, 'body');
  // @ts-ignore
  files = files.map((file) => (file._id === id ? fileResponse : file));
  return fileResponse;
}

export async function patchFiles(workspaceID, requestPayload) {
  const url = `/workspaces/${workspaceID}/files?api_version=2`;
  const patchFileResponse = await patchRequest(
    { url, data: requestPayload },
    window.appStore.getState(),
  );
  const filesResponse = _.get(patchFileResponse, 'body');

  return filesResponse;
}

export async function patchBulk(workspaceID, values) {
  const url = `/workspaces/${workspaceID}/files/bulk-update?api_version=2`;
  await postRequest({ url, data: values }, window.appStore.getState());
  const map = values.ids.reduce((acm, id) => {
    acm[id] = true;
    return acm;
  }, {});
  // @ts-ignore
  files = files.map((file) =>
    map[file._id] ? { ...file, ...values.file } : file,
  );
}

export async function moveFiles(workspaceID, data) {
  const url = `/workspaces/${workspaceID}/files/move?api_version=2`;
  const moveFilesResponse = await postRequest(
    { url, data },
    window.appStore.getState(),
  );
  const filesResponse = _.get(moveFilesResponse, 'body');

  // @ts-ignore
  files = files.map((file) => {
    return filesResponse.find((f) => f._id === file._id) || file;
  });

  await mapFileResponse();
}

export async function createFile(workspaceID, fileReference) {
  const url = `/workspaces/${workspaceID}/files?api_version=2`;
  const createFileResponse = await postRequest(
    { url, data: fileReference },
    window.appStore.getState(),
  );
  const fileResponse = _.get(createFileResponse, 'body');
  if (!fileResponse?.shared) {
    fileResponse.shared = {};
  }
  files.push(fileResponse);
  return fileResponse;
}

export async function deleteFiles(workspaceID, ids) {
  const url = `/workspaces/${workspaceID}/files/bulk-delete?api_version=2`;
  await postRequest({ url, data: ids }, window.appStore.getState());
  const map = ids.reduce((acm, id) => {
    acm[id] = true;
    return acm;
  }, {});
  // @ts-ignore
  files = files.filter((file) => !map[file._id]);
  return files;
}

export async function sendUploadEmailAlert(files: any[], workspaceID: string) {
  const url = `/workspaces/${workspaceID}/files/notify_upload?api_version=2`;
  const notifyUploadResponse = await postRequest(
    { url, data: { files: files.map((file) => file._id) } },
    window.appStore.getState(),
  );
  const result = _.get(notifyUploadResponse, 'body');
  return result;
}

export async function signUrl(
  workspaceID: string,
  contentType: string,
  key: string,
) {
  const url = `/workspaces/${workspaceID}/files/sign-url?contentType=${encodeURIComponent(
    contentType,
  )}&key=${encodeURIComponent(key)}&api_version=2`;
  return await getRequest(url, window.appStore.getState());
}

export async function uploadFiles(
  files: any,
  workspaceID: string,
  parentID: string,
  onFileUploadComplete,
): Promise<any[]> {
  const filesCreated: any[] = [];
  for (let i = 0; i < files.length; i += 1) {
    // UPLOAD FILE
    const file = files[i];
    const upload = await uploadFile(file, workspaceID);

    // CREATE FILE REFERENCE
    const fileReference = {
      title: file.name,
      fileType: file.type,
      fileExtension: getFileExtension(file.name),
      parentID,
      fileKey: upload.fileName,
      meta: { size: file.size },
    };
    const createFileResponseData = await createFile(workspaceID, fileReference);
    filesCreated.push(createFileResponseData);
    onFileUploadComplete({
      ...createFileResponseData,
      uniqueKey: file.uniqueKey,
    });
  }

  await sendUploadEmailAlert(filesCreated, workspaceID);

  return filesCreated;
}

export async function uploadFile(file: any, workspaceID) {
  // 1. SIGN THE URL
  const fileType = file['type'];
  const key = `${workspaceID}/files/${uuidv4()}-${file['name']}`;
  const url = `/workspaces/${workspaceID}/files/sign-url?api_version=2&contentType=${encodeURIComponent(
    fileType,
  )}&key=${encodeURIComponent(key)}`;
  const signUrlResponse = await getRequest(url, window.appStore.getState());
  const { signedUrl, fileName } = _.get(signUrlResponse, 'body.data');

  // 2. UPLOAD THE FILE
  await fetch(
    new Request(signedUrl, {
      method: 'PUT',
      body: file,
      headers: new Headers({
        'Content-Type': fileType,
      }),
    }),
  );
  return { signUrl, fileName };
}

export async function downloadFiles(files: any[], workspaceID) {
  const url = `/workspaces/${workspaceID}/files/download?api_version=2`;
  const signUrlResponse = await postRequest(
    { url, data: files },
    window.appStore.getState(),
  );
  const signedUrl = _.get(signUrlResponse, 'body');
  return signedUrl;
}

export async function copyFiles(workspaceID, parentID, values) {
  const url = `/workspaces/${workspaceID}/files/copy?api_version=2`;
  const copyFilesResponse = await postRequest(
    { url, data: { files: values, parentID } },
    window.appStore.getState(),
  );
  const filesResponse = _.get(copyFilesResponse, 'body');
  files.push(...filesResponse);
  return files;
}

export async function copyAttachments(workspaceID, files) {
  const url = `/workspaces/${workspaceID}/files/copy?api_version=2`;
  const copyAttachmentsResponse = await postRequest(
    { url, data: { files, parentID: null } },
    window.appStore.getState(),
  );
  const filesResponse = _.get(copyAttachmentsResponse, 'body');
  return filesResponse;
}

export async function shareFileLink(workspaceID, payload) {
  const url = `/workspaces/${workspaceID}/file-links?api_version=2`;
  const shareLinkResponse = await postRequest(
    { url, data: payload },
    window.appStore.getState(),
  );
  const fileLink = _.get(shareLinkResponse, 'body');
  const fileIndex = files.findIndex(
    (file) => file._id === payload.references.fileID,
  );
  const file = files[fileIndex];
  const shared = file['shared'] || {};
  shared[payload.type] = fileLink;
  files[fileIndex] = { ...file, shared };
  fileLinks[file._id] = {
    ...fileLinks[file._id],
    [payload.type]: fileLink,
  };
  return [fileLink, files[fileIndex]];
}

export async function deleteFileLink(workspaceID, fileLink) {
  const fileLinkID = fileLink._id;
  const url = `/workspaces/${workspaceID}/file-links/${fileLinkID}?api_version=2`;
  const shareLinkResponse = await deleteRequest(
    url,
    window.appStore.getState(),
  );

  const fileIndex = files.findIndex(
    (file) => file._id === fileLink.references.fileID,
  );
  const file = files[fileIndex];
  const shared = file['shared'];
  const keyToDelete = fileLink.type;
  delete shared[keyToDelete];
  files[fileIndex] = { ...file, shared };
  fileLinks[file._id] = shared;

  return [_.get(shareLinkResponse, 'body'), files[fileIndex]];
}

export async function updateFileLink(
  workspaceID,
  payload,
  fileLinkID,
  references,
) {
  const url = `/workspaces/${workspaceID}/file-links/${fileLinkID}?api_version=2`;
  const shareLinkResponse = await patchRequest(
    { url, data: payload },
    window.appStore.getState(),
  );
  const fileLink = _.get(shareLinkResponse, 'body');

  const fileIndex = files.findIndex((file) => file._id === references.fileID);
  const file = files[fileIndex];
  const shared = file['shared'];
  shared[payload.type] = fileLink;
  files[fileIndex] = { ...file, shared };
  fileLinks[file._id] = shared;
  return [fileLink, files[fileIndex]];
}

export function filterFolders() {
  const list = files.filter((file) => file.isFolder);
  return [
    {
      _id: '',
      title: 'All Files',
      nodes: createTree(_.cloneDeep(list)),
      uri: '',
    },
  ];
}

export function findParentDirectory(parentID: string) {
  const list = files.find((file) => file._id === parentID);
  return (
    list || {
      title: 'All Files',
    }
  );
}

export function findItem(id: string) {
  return files?.find((file) => file._id === id);
}

export function filterFiles(parentID?: string) {
  mapFileResponse().then();
  // @ts-ignore
  let compare = (file) => !file.parentID;
  if (parentID) {
    compare = (file) => file.parentID === parentID;
  }
  return _.sortBy(files.filter(compare), [
    'isFolder',
    function(o) {
      return o.title && o.title.toLowerCase();
    },
  ]);
}
