import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@material-ui/core/Avatar';
import { createStyles } from '@material-ui/core/styles';
import * as _ from 'lodash/fp';
import * as React from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownWrapper as DefaultDropdownWrapper } from '../../TableHeaderTools.style';
import { deprecatedDefaultChipColor } from 'styles/colors';

import ChoiceItemComponent from '../../../NodeFieldData/choice/ChoiceItem/ChoiceItem';

export const filteringOptionStyles = () =>
  createStyles({
    popoverPaper: {
      overflow: 'unset !important',
    },
  });

export const DropdownWrapper = styled(DefaultDropdownWrapper)`
  min-width: 400px;
`;

export const OperatorDropdown = styled(Dropdown)`
  width: 110px;
  min-width: 110px;
  flex: 0;
  margin-left: 0;
`;

export const InputWrapper = styled.div`
  width: 150px;
  text-align: left;
`;

interface AvatarProps extends MuiAvatarProps {
  color: string;
  textColor: string;
}

export const Avatar = styled<AvatarProps>(
  ({ color, textColor, ...props }) => <MuiAvatar {...props}/>,
)`
  width: 20px;
  height: 20px;
  font-size: 11px;
  color: ${_.propOr('inherit', 'textColor')};
  background-color: ${_.propOr(deprecatedDefaultChipColor, 'color')};
`;

export const AccountWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  margin: 3px;

  ${Avatar} {
    margin-right: 4px;
  }
`;

export const ChoiceItem = styled(ChoiceItemComponent)`
  margin-right: 3px;
`;

export const MultipleChoiceItem = styled(ChoiceItemComponent)`
  margin-right: 3px;
  margin-left: 10px;
`;
