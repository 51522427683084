import { SvgIcon } from 'types/common';
import svgIcons from './svgIcons';

enum FileExtension {
  sevenZ = '7z',
  ai = 'ai',
  aif = 'aif',
  aifc = 'aifc',
  aiff = 'aiff',
  asf = 'asf',
  aspx = 'aspx',
  atom = 'atom',
  au = 'au',
  azw = 'azw',
  bat = 'bat',
  bmp = 'bmp',
  bz = 'bz',
  bz2 = 'bz2',
  cer = 'cer',
  chm = 'chm',
  cin = 'cin',
  cmd = 'cmd',
  config = 'config',
  crl = 'crl',
  crt = 'crt',
  cs = 'cs',
  csh = 'csh',
  csproj = 'csproj',
  css = 'css',
  csv = 'csv',
  dds = 'dds',
  der = 'der',
  dng = 'dng',
  doc = 'doc',
  docm = 'docm',
  docx = 'docx',
  dot = 'dot',
  dotm = 'dotm',
  dotx = 'dotx',
  dvi = 'dvi',
  emf = 'emf',
  eml = 'eml',
  eps = 'eps',
  epub = 'epub',
  exe = 'exe',
  exr = 'exr',
  flv = 'flv',
  gif = 'gif',
  go = 'go',
  gz = 'gz',
  hdr = 'hdr',
  htm = 'htm',
  html = 'html',
  ico = 'ico',
  ics = 'ics',
  ini = 'ini',
  jpeg = 'jpeg',
  jpg = 'jpg',
  js = 'js',
  json = 'json',
  latex = 'latex',
  less = 'less',
  lnk = 'lnk',
  log = 'log',
  man = 'man',
  md = 'md',
  mdb = 'mdb',
  mid = 'mid',
  midi = 'midi',
  mkv = 'mkv',
  mobi = 'mobi',
  mp2 = 'mp2',
  mp3 = 'mp3',
  mp4 = 'mp4',
  mpe = 'mpe',
  mpeg = 'mpeg',
  mpg = 'mpg',
  mpp = 'mpp',
  msg = 'msg',
  msi = 'msi',
  nfo = 'nfo',
  odp = 'odp',
  ods = 'ods',
  odt = 'odt',
  one = 'one',
  ost = 'ost',
  otf = 'otf',
  p7b = 'p7b',
  p7c = 'p7c',
  p7r = 'p7r',
  p12 = 'p12',
  pbm = 'pbm',
  pcx = 'pcx',
  pdf = 'pdf',
  pfx = 'pfx',
  php = 'php',
  pict = 'pict',
  pl = 'pl',
  png = 'png',
  pot = 'pot',
  potm = 'potm',
  potx = 'potx',
  ppa = 'ppa',
  ppam = 'ppam',
  pps = 'pps',
  ppsm = 'ppsm',
  ppsx = 'ppsx',
  ppt = 'ppt',
  pptm = 'pptm',
  pptx = 'pptx',
  prc = 'prc',
  ps = 'ps',
  ps1 = 'ps1',
  psb = 'psb',
  psd = 'psd',
  psp = 'psp',
  pst = 'pst',
  pub = 'pub',
  py = 'py',
  rar = 'rar',
  resx = 'resx',
  rss = 'rss',
  rtf = 'rtf',
  sass = 'sass',
  sh = 'sh',
  snd = 'snd',
  spc = 'spc',
  sql = 'sql',
  svg = 'svg',
  tab = 'tab',
  tar = 'tar',
  tex = 'tex',
  tga = 'tga',
  tif = 'tif',
  tiff = 'tiff',
  toml = 'toml',
  tsv = 'tsv',
  ttf = 'ttf',
  txt = 'txt',
  unknown = 'unknown',
  vbs = 'vbs',
  vcf = 'vcf',
  vcs = 'vcs',
  vsd = 'vsd',
  vsdx = 'vsdx',
  vst = 'vst',
  wav = 'wav',
  wbk = 'wbk',
  webp = 'webp',
  wmf = 'wmf',
  wmz = 'wmz',
  xaml = 'xaml',
  xht = 'xht',
  xhtml = 'xhtml',
  xla = 'xla',
  xlam = 'xlam',
  xls = 'xls',
  xlsb = 'xlsb',
  xlsm = 'xlsm',
  xlsx = 'xlsx',
  xlt = 'xlt',
  xltm = 'xltm',
  xltx = 'xltx',
  xml = 'xml',
  xps = 'xps',
  xsl = 'xsl',
  xslt = 'xslt',
  yaml = 'yaml',
  zip = 'zip',
}

export default function getFileIcon(extension?: string): SvgIcon {
  switch (extension?.toLowerCase()) {
    case FileExtension.sevenZ: {
      return svgIcons.sevenZ;
    }

    case FileExtension.ai: {
      return svgIcons.ai;
    }

    case FileExtension.aif: {
      return svgIcons.aif;
    }

    case FileExtension.aifc: {
      return svgIcons.aifc;
    }

    case FileExtension.aiff: {
      return svgIcons.aiff;
    }

    case FileExtension.asf: {
      return svgIcons.asf;
    }

    case FileExtension.aspx: {
      return svgIcons.aspx;
    }

    case FileExtension.atom: {
      return svgIcons.atom;
    }

    case FileExtension.au: {
      return svgIcons.au;
    }

    case FileExtension.azw: {
      return svgIcons.azw;
    }

    case FileExtension.bat: {
      return svgIcons.bat;
    }

    case FileExtension.bmp: {
      return svgIcons.bmp;
    }

    case FileExtension.bz: {
      return svgIcons.bz;
    }

    case FileExtension.bz2: {
      return svgIcons.bz2;
    }

    case FileExtension.cer: {
      return svgIcons.cer;
    }

    case FileExtension.chm: {
      return svgIcons.chm;
    }

    case FileExtension.cin: {
      return svgIcons.cin;
    }

    case FileExtension.cmd: {
      return svgIcons.cmd;
    }

    case FileExtension.config: {
      return svgIcons.config;
    }

    case FileExtension.crl: {
      return svgIcons.crl;
    }

    case FileExtension.crt: {
      return svgIcons.crt;
    }

    case FileExtension.cs: {
      return svgIcons.cs;
    }

    case FileExtension.csh: {
      return svgIcons.csh;
    }

    case FileExtension.csproj: {
      return svgIcons.csproj;
    }

    case FileExtension.css: {
      return svgIcons.css;
    }

    case FileExtension.csv: {
      return svgIcons.csv;
    }

    case FileExtension.dds: {
      return svgIcons.dds;
    }

    case FileExtension.der: {
      return svgIcons.der;
    }

    case FileExtension.dng: {
      return svgIcons.dng;
    }

    case FileExtension.doc: {
      return svgIcons.word;
    }

    case FileExtension.docm: {
      return svgIcons.word;
    }

    case FileExtension.docx: {
      return svgIcons.word;
    }

    case FileExtension.dot: {
      return svgIcons.word;
    }

    case FileExtension.dotm: {
      return svgIcons.word;
    }

    case FileExtension.dotx: {
      return svgIcons.word;
    }

    case FileExtension.dvi: {
      return svgIcons.dvi;
    }

    case FileExtension.emf: {
      return svgIcons.emf;
    }

    case FileExtension.eml: {
      return svgIcons.eml;
    }

    case FileExtension.eps: {
      return svgIcons.eps;
    }

    case FileExtension.epub: {
      return svgIcons.epub;
    }

    case FileExtension.exe: {
      return svgIcons.exe;
    }

    case FileExtension.exr: {
      return svgIcons.exr;
    }

    case FileExtension.flv: {
      return svgIcons.flv;
    }

    case FileExtension.gif: {
      return svgIcons.gif;
    }

    case FileExtension.go: {
      return svgIcons.go;
    }

    case FileExtension.gz: {
      return svgIcons.gz;
    }

    case FileExtension.hdr: {
      return svgIcons.hdr;
    }

    case FileExtension.htm: {
      return svgIcons.htm;
    }

    case FileExtension.html: {
      return svgIcons.html;
    }

    case FileExtension.ico: {
      return svgIcons.ico;
    }

    case FileExtension.ics: {
      return svgIcons.ics;
    }

    case FileExtension.ini: {
      return svgIcons.ini;
    }

    case FileExtension.jpeg: {
      return svgIcons.jpeg;
    }

    case FileExtension.jpg: {
      return svgIcons.jpg;
    }

    case FileExtension.js: {
      return svgIcons.js;
    }

    case FileExtension.json: {
      return svgIcons.json;
    }

    case FileExtension.latex: {
      return svgIcons.latex;
    }

    case FileExtension.less: {
      return svgIcons.less;
    }

    case FileExtension.lnk: {
      return svgIcons.lnk;
    }

    case FileExtension.log: {
      return svgIcons.log;
    }

    case FileExtension.man: {
      return svgIcons.man;
    }

    case FileExtension.md: {
      return svgIcons.md;
    }

    case FileExtension.mdb: {
      return svgIcons.mdb;
    }

    case FileExtension.mid: {
      return svgIcons.mid;
    }

    case FileExtension.midi: {
      return svgIcons.midi;
    }

    case FileExtension.mkv: {
      return svgIcons.mkv;
    }

    case FileExtension.mobi: {
      return svgIcons.mobi;
    }

    case FileExtension.mp2: {
      return svgIcons.mp2;
    }

    case FileExtension.mp3: {
      return svgIcons.mp3;
    }

    case FileExtension.mp4: {
      return svgIcons.mp4;
    }

    case FileExtension.mpe: {
      return svgIcons.mpe;
    }

    case FileExtension.mpeg: {
      return svgIcons.mpeg;
    }

    case FileExtension.mpg: {
      return svgIcons.mpg;
    }

    case FileExtension.mpp: {
      return svgIcons.mpp;
    }

    case FileExtension.msg: {
      return svgIcons.msg;
    }

    case FileExtension.msi: {
      return svgIcons.msi;
    }

    case FileExtension.nfo: {
      return svgIcons.nfo;
    }

    case FileExtension.odp: {
      return svgIcons.odp;
    }

    case FileExtension.ods: {
      return svgIcons.ods;
    }

    case FileExtension.odt: {
      return svgIcons.odt;
    }

    case FileExtension.one: {
      return svgIcons.one;
    }

    case FileExtension.ost: {
      return svgIcons.ost;
    }

    case FileExtension.otf: {
      return svgIcons.otf;
    }

    case FileExtension.p7b: {
      return svgIcons.p7b;
    }

    case FileExtension.p7c: {
      return svgIcons.p7c;
    }

    case FileExtension.p7r: {
      return svgIcons.p7r;
    }

    case FileExtension.p12: {
      return svgIcons.p12;
    }

    case FileExtension.pbm: {
      return svgIcons.pbm;
    }

    case FileExtension.pcx: {
      return svgIcons.pcx;
    }

    case FileExtension.pdf: {
      return svgIcons.pdf;
    }

    case FileExtension.pfx: {
      return svgIcons.pfx;
    }

    case FileExtension.php: {
      return svgIcons.php;
    }

    case FileExtension.pict: {
      return svgIcons.pict;
    }

    case FileExtension.pl: {
      return svgIcons.pl;
    }

    case FileExtension.png: {
      return svgIcons.png;
    }

    case FileExtension.pot: {
      return svgIcons.powerpoint;
    }

    case FileExtension.potm: {
      return svgIcons.powerpoint;
    }

    case FileExtension.potx: {
      return svgIcons.powerpoint;
    }

    case FileExtension.ppa: {
      return svgIcons.powerpoint;
    }

    case FileExtension.ppam: {
      return svgIcons.powerpoint;
    }

    case FileExtension.pps: {
      return svgIcons.powerpoint;
    }

    case FileExtension.ppsm: {
      return svgIcons.powerpoint;
    }

    case FileExtension.ppsx: {
      return svgIcons.powerpoint;
    }

    case FileExtension.ppt: {
      return svgIcons.powerpoint;
    }

    case FileExtension.pptm: {
      return svgIcons.powerpoint;
    }

    case FileExtension.pptx: {
      return svgIcons.powerpoint;
    }

    case FileExtension.prc: {
      return svgIcons.prc;
    }

    case FileExtension.ps: {
      return svgIcons.ps;
    }

    case FileExtension.ps1: {
      return svgIcons.ps1;
    }

    case FileExtension.psb: {
      return svgIcons.psb;
    }

    case FileExtension.psd: {
      return svgIcons.psd;
    }

    case FileExtension.psp: {
      return svgIcons.psp;
    }

    case FileExtension.pst: {
      return svgIcons.pst;
    }

    case FileExtension.pub: {
      return svgIcons.pub;
    }

    case FileExtension.py: {
      return svgIcons.py;
    }

    case FileExtension.rar: {
      return svgIcons.rar;
    }

    case FileExtension.resx: {
      return svgIcons.resx;
    }

    case FileExtension.rss: {
      return svgIcons.rss;
    }

    case FileExtension.rtf: {
      return svgIcons.txtRtf;
    }

    case FileExtension.sass: {
      return svgIcons.sass;
    }

    case FileExtension.sh: {
      return svgIcons.sh;
    }

    case FileExtension.snd: {
      return svgIcons.snd;
    }

    case FileExtension.spc: {
      return svgIcons.spc;
    }

    case FileExtension.sql: {
      return svgIcons.sql;
    }

    case FileExtension.svg: {
      return svgIcons.svg;
    }

    case FileExtension.tab: {
      return svgIcons.tab;
    }

    case FileExtension.tar: {
      return svgIcons.tar;
    }

    case FileExtension.tex: {
      return svgIcons.tex;
    }

    case FileExtension.tga: {
      return svgIcons.tga;
    }

    case FileExtension.tif: {
      return svgIcons.tif;
    }

    case FileExtension.tiff: {
      return svgIcons.tiff;
    }

    case FileExtension.toml: {
      return svgIcons.toml;
    }

    case FileExtension.tsv: {
      return svgIcons.tsv;
    }

    case FileExtension.ttf: {
      return svgIcons.ttf;
    }

    case FileExtension.txt: {
      return svgIcons.txtRtf;
    }

    case FileExtension.unknown: {
      return svgIcons.unknown;
    }

    case FileExtension.vbs: {
      return svgIcons.vbs;
    }

    case FileExtension.vcf: {
      return svgIcons.vcf;
    }

    case FileExtension.vcs: {
      return svgIcons.vcs;
    }

    case FileExtension.vsd: {
      return svgIcons.vsd;
    }

    case FileExtension.vsdx: {
      return svgIcons.vsdx;
    }

    case FileExtension.vst: {
      return svgIcons.vst;
    }

    case FileExtension.wav: {
      return svgIcons.wav;
    }

    case FileExtension.wbk: {
      return svgIcons.word;
    }

    case FileExtension.webp: {
      return svgIcons.webp;
    }

    case FileExtension.wmf: {
      return svgIcons.wmf;
    }

    case FileExtension.wmz: {
      return svgIcons.wmz;
    }

    case FileExtension.xaml: {
      return svgIcons.xaml;
    }

    case FileExtension.xht: {
      return svgIcons.xht;
    }

    case FileExtension.xhtml: {
      return svgIcons.xhtml;
    }

    case FileExtension.xla: {
      return svgIcons.excel;
    }

    case FileExtension.xlam: {
      return svgIcons.excel;
    }

    case FileExtension.xls: {
      return svgIcons.excel;
    }

    case FileExtension.xlsb: {
      return svgIcons.excel;
    }

    case FileExtension.xlsm: {
      return svgIcons.excel;
    }

    case FileExtension.xlsx: {
      return svgIcons.excel;
    }

    case FileExtension.xlt: {
      return svgIcons.excel;
    }

    case FileExtension.xltm: {
      return svgIcons.excel;
    }

    case FileExtension.xltx: {
      return svgIcons.excel;
    }

    case FileExtension.xml: {
      return svgIcons.xml;
    }

    case FileExtension.xps: {
      return svgIcons.xps;
    }

    case FileExtension.xsl: {
      return svgIcons.xsl;
    }

    case FileExtension.xslt: {
      return svgIcons.xslt;
    }

    case FileExtension.yaml: {
      return svgIcons.yaml;
    }

    case FileExtension.zip: {
      return svgIcons.zip;
    }

    default: {
      return svgIcons.unknown;
    }
  }
}
