import { Dictionary } from 'lodash';
import * as _ from 'lodash/fp';
import { parse, stringify } from 'qs';

import { constructFilter, mapColumnEditorToFilterType } from 'components/NodeFieldData/filters/helpers';
import {
  convertServerToClientOperator,
  getRegularFilterParamsFromModel,
  getSearchFilterParamsFromModel,
} from 'data/grid-options/gridOptions.selector';
import { CollectionViewTypes, Schema } from 'types/schema';

import { State as GridOptionsState } from '../grid-options/gridOptions.reducer';
import { ViewNode } from '../../types/response/viewNode';
import {
  ColumnsMapById,
  FilterModel,
  FilterType,
  isMultiValueFilterType,
  Operator,
  RegularFilter,
  SortModel,
  SortOperator,
} from '../../types/gridOptions';
import { Filter, Query, Sort } from '../../types/response/query';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import { getSchemaColumns } from 'data/collections/collections.selectors';
import { getUserDefinedFieldKey } from 'utilities/collections';
import { DateFormat, DEFAULT_DATE_FORMAT } from 'components/Fields/data';
import moment from 'moment';
import { AgGridFilterModel } from 'components/AgGrid/AgGridApi';
import { getColumnsForView } from 'data/collections/view-config/viewConfig.selectors';
import { GridColumnState, ViewConfig } from 'data/collections/collections.reducer';

export function replaceViewId(search: string, newViewId?: string): string {
  if (_.isEmpty(search)) {
    return newViewId ? `?viewId=${newViewId}` : '';
  }

  const { viewId: oldViewId, ...parsed } = parse(search, { ignoreQueryPrefix: true });

  return stringify({ ...parsed, viewId: newViewId }, { addQueryPrefix: true });
}

export function getViewIdFromSearch(search: string): string | undefined {
  const parsed = parse(search, { ignoreQueryPrefix: true });

  return parsed.viewId;
}

const extractColumnIds = (columns: GridColumnState[]): string[] => columns.map(column => column.colId);

interface PrepareViewInput {
  columns: GridColumnState[];
  gridOptions: GridOptionsState;
  groupByFieldIDs?: string[];
  height?: number;
  start?: number;
  categoryFieldOrder?: string[];
  viewType?: CollectionViewTypes;
  customRowOrder?: Record<string, number>;
}

export function prepareView(input: PrepareViewInput): Partial<ViewNode> {
  const {
    columns,
    gridOptions,
    groupByFieldIDs,
    height,
    viewType,
    start = 0,
    categoryFieldOrder,
    customRowOrder,
  } = input;

  return {
    ...prepareHeightField(height, viewType),
    groupByFieldIDs,
    query: prepareQuery(columns, gridOptions, start, viewType),
    bottomPinnedFieldIDs: prepareBottomPinnedFieldIDs(columns),
    leftPinnedFieldIDs: prepareLeftPinnedFieldIDs(columns),
    rightPinnedFieldIDs: prepareRightPinnedFieldIDs(columns),
    topPinnedFieldIDs: prepareTopPinnedFieldIDs(columns),
    columnWidths: prepareColumnWidths(columns),
    categoryFieldOrder,
    customRowOrder: _.mapValues((value: number) => value.toString(), customRowOrder),
  };
}

function prepareQuery(
  columns: GridColumnState[],
  gridOptions: GridOptionsState,
  start?: number,
  viewType?: CollectionViewTypes,
): Query {
  const fieldIDs: string[] = prepareFieldIDs(columns);
  const regularFilterParams = getRegularFilterParamsFromModel(gridOptions.filterModel.regularFilters);
  const searchFilterParams = getSearchFilterParamsFromModel(gridOptions.filterModel.searchFilters);
  return {
    ...prepareStartField(start, viewType),
    fieldIDs,
    filters: regularFilterParams,
    filtersInBar: searchFilterParams,
    sorts: Object.values(gridOptions.sortModel.byId).map(prepareSorts),
  };
}

function prepareStartField(start?: number, viewType?: CollectionViewTypes): Partial<Query> {
  switch (viewType) {
    case CollectionViewTypes.grid:
      return { start };

    default:
      return {};
  }
}

function prepareColumnWidths(columns: GridColumnState[]): Dictionary<number> {
  return columns.reduce<Dictionary<number>>((result, column) => {
    if (column.width) {
      result[getUserDefinedFieldKey(column.colId)] = Math.trunc(column.width);
    }
    return result;
  }, {});
}

export const prepareFieldIDs: (columns: GridColumnState[]) => string[] = _.pipe(
  _.filter<GridColumnState>(({ hide }) => !hide),
  extractColumnIds,
);

function prepareSorts(sort: SortModel): Sort {
  const baseSort: Sort = { fieldID: sort.colId };

  switch (sort.sort) {
    case SortOperator.Desc:
      return { ...baseSort, descending: true };

    default:
      return baseSort;
  }
}

function prepareHeightField(height?: number, viewType?: CollectionViewTypes): Partial<ViewNode> {
  switch (viewType) {
    case CollectionViewTypes.grid:
      return { rowHeight: height };

    case CollectionViewTypes.kanban:
      return { cardHeight: height };

    default:
      return {};
  }
}

function createPreparePinnedFieldIDs(pinnedTo: string): (columns: GridColumnState[]) => string[] {
  return _.pipe(
    _.filter<GridColumnState>(({ pinned }) => pinned === pinnedTo),
    extractColumnIds,
  );
}

const prepareBottomPinnedFieldIDs = createPreparePinnedFieldIDs('bottom');
const prepareLeftPinnedFieldIDs = createPreparePinnedFieldIDs('left');
const prepareRightPinnedFieldIDs = createPreparePinnedFieldIDs('right');
const prepareTopPinnedFieldIDs = createPreparePinnedFieldIDs('top');


function convertSorts(sorts: Sort[], columns: ColumnsMapById): SortModel[] {
  return sorts
    .filter(sort => columns[sort.fieldID] !== undefined)
    .map(sort => ({
      colId: sort.fieldID,
      sort: sort.descending ? SortOperator.Desc : SortOperator.Asc,
    }));
}

function convertFilters(columns: ColumnsMapById, filters?: Filter[], filtersInBar?: Filter[]): FilterModel {
  const regularFilters: RegularFilter[] = !filters
    ? []
    : filters.reduce((result: RegularFilter[], current: Filter) => {
      if (!current.fieldID) {
        return result;
      }

      const column = columns[current.fieldID];
      if (!column) {
        return result;
      }

      const type: FilterType = mapColumnEditorToFilterType(column.fieldType, column.allowMultiple);
      const value =
        type === FilterType.Date ? getDateValue(current, column.dateFormat || DEFAULT_DATE_FORMAT)
          : isMultiValueFilterType(type) ? current.childValues
            : current.value;
      const operator = current.operation
        ? convertServerToClientOperator(current.operation, type, value)
        : Operator.Equals;

      const regularFilter: RegularFilter = constructFilter(current.fieldID, type, operator, value, column.dateFormat);

      return [...result, regularFilter];
    }, []);

  const searchFilters: AgGridFilterModel = !filtersInBar
    ? {}
    : filtersInBar.reduce((result: AgGridFilterModel, current: Filter) => {
      if (!current.fieldID) {
        return result;
      }

      const column = columns[current.fieldID];
      if (!column) {
        return result;
      }

      return { ...result, [current.fieldID]: String(current.value) };
    }, {});

  return {
    regularFilters,
    searchFilters,
    quickSearch: '',
  };
}

const getDateValue = (filter: Filter, dateFormat: DateFormat): string | null => {
  const dateTime: number | undefined =
    typeof filter.value === 'number' ? filter.value
      : isNumberArray(filter.childValues) ? filter.childValues[0]
        : undefined;
  return dateTime ? moment(dateTime).format(dateFormat) : null;
};

const isNumberArray = (value: unknown): value is number[] =>
  Array.isArray(value) && value.every(item => typeof item === 'number');

function convertRowHeight(rowHeight: number | undefined): number {
  return (rowHeight && rowHeight > 0) ? rowHeight : GRID_ROW_HEIGHT.BASE;
}

export function convertApiViewConfigToUIViewConfig(
  view: ViewNode,
  schema: Schema,
  permissions: string[],
): ViewConfig {
  const { query, rowHeight, customRowOrder } = view;
  const columns: ColumnsMapById = getSchemaColumns(schema);

  const payload: ViewConfig = {
    columns: getColumnsForView(columns, view, permissions),
    rowHeight: convertRowHeight(rowHeight),
    categoryFieldOrder: view.categoryFieldOrder,
    customRowOrder: _.mapValues((value: string) => Number(value), customRowOrder),
  };

  if (query) {
    if (query.sorts) {
      payload.sorts = convertSorts(query.sorts, columns);
    }

    payload.filters = convertFilters(columns, query.filters, query.filtersInBar);
  }

  return payload;
}
