import { IDoesFilterPassParams } from 'ag-grid-community';
import { singleChoiceComparator } from '../../../../data/collections/filters';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function ChoiceFilter() {

}

ChoiceFilter.prototype.init = function(params) {
  this.valueGetter = params.valueGetter;
  this.value = '';
  this.model = null;
  this.field = params.colDef.field;
  this.choices = params.colDef.cellRendererParams.choices;
};

ChoiceFilter.prototype.getGui = function() {
  return document.createElement('div');
};

ChoiceFilter.prototype.doesFilterPass = function(params: IDoesFilterPassParams): boolean {
  if (this.isFloatingFilter) {
    return this.choices[params.data[this.field]]?.label?.toLowerCase().includes(this.value);
  }

  return singleChoiceComparator(params.data[this.field], this.filter);
};

ChoiceFilter.prototype.isFilterActive = function() {
  return this.value !== '';
};

ChoiceFilter.prototype.getModel = function() {
  return { value: this.value };
};

ChoiceFilter.prototype.setModel = function(model) {
  if (!model) return;
  this.value = model.value;
  this.filter = model.filter;
  this.isFloatingFilter = model.isFloatingFilter;
};

export default ChoiceFilter;
