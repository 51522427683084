export enum MediaTypeByExtension {
  csv = 'text/csv',
  json = 'application/json',
  tab = 'text/tab-separated-values',
  txt = 'text/tab-separated-values',
  toml = 'application/x-toml',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml = 'application/xml',
  yaml = 'application/x-yaml',
}

export const getFileExtension = (fileName: string) => {
  return fileName && fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1);
};

/**
 * Extract the file name from extension
 * i.e. 'landspace.jpg' => 'landscape'
 *
 * @param fileName - filename with extension
 * @returns - name without extension
 */
export const getFileNameNoExt = (fileName='') => {
  const nameSplit = fileName.split('.');

  if (nameSplit.length > 1) {
    nameSplit.pop();
  }

  return nameSplit.join('.');
};

export const readFile = (file: File) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => {
      res(reader.result || '');
    };
    reader.onerror = rej;
    reader.readAsText(file);
  });
};

const KB = 1000;
const MB = KB ** 2;
const GB = KB ** 3;

function getSize(unit: number, size: number): string {
  return (size / unit).toFixed(2).replace(/\.?0+$/, '');
}

function calculateSizePair(size: number): (string | number)[] {
  if (size > GB / 2) {
    return [getSize(GB, size), 'GB'];
  }

  if (size > MB / 2) {
    return [getSize(MB, size), 'MB'];
  }

  if (size > KB / 2) {
    return [getSize(KB, size), 'KB'];
  }

  return [size, 'B'];
}

export function getFormattedFileSize(size: number, spaceBetween = false): string {
  return calculateSizePair(size).join(spaceBetween ? ' ' : '');
}

export function openPdfFileWindow() {
  window.pdfFileWindow = window.open();
}

export function handleFileUrl(url: string): string {
  if (url?.includes('parentID') && !url?.includes('itemID')) {
    return url;
  }

  if (url?.includes('parentID') && url?.includes('itemID')) {
    return `${url}&from=bookmarks`;
  }

  const pathHierarchy = url.split('/');
  if (pathHierarchy[pathHierarchy.length - 1] === 'files') {
    return url;
  }

  return `${pathHierarchy.slice(0, pathHierarchy.indexOf('files') + 1).join('/')}?from=bookmarks&itemID=${pathHierarchy[pathHierarchy.indexOf('files') + 1]}`;
}
