import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  iceBlue,
  white,
  slateTwo,
  lightBlueGrey,
  greyTransparent,
  lightRed,
  blueyGrey,
  blueGrey,
  hubsyncBlue, vividAzure,
} from 'styles/colors';

type ClassKey =
  | 'field'
  | 'fieldLabel'
  | 'fieldIcon'
  | 'infoIcon'
  | 'rowWithSpaceInBetween'
  | 'textarea'
  | 'errorMessage'
  | 'copyLink'
  | 'accessLevelSelect'
  | 'radioButtonWrapper'
  | 'domainInputWrapper'
  | 'label'
  | 'infoText'
  | 'relative'
  | 'infoBoxWrapper'
  | 'infoBoxWrapperTop'
  | 'divider'
  | 'dividerText'
  | 'row'
  | 'customSelectWrapper'
  | 'customSelectButton'
  | 'customSelectMenu'
  | 'customSelectMenuItem'
  | 'customSelectMenuItemText'
  | 'checkedRoleIcon'
  | 'inviteSelect'
  | 'inviteSelectInput'
  | 'chip'
  | 'inviteUserSuggestionAdd'
  | 'invalidChip'
  | 'inviteUserTooltip'
  | 'activeItem'
  | 'inputFocused'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  fieldLabel: {
    'display': 'flex',
    'alignItems': 'flex-end',
    'marginBottom': '10px',
    '& > span': {
      'textTransform': 'initial',
      'fontSize': '12px',
    },
  },
  fieldIcon: {
    width: '15px',
    marginRight: '10px',
    color: slateTwo,
  },
  infoIcon: {
    width: '15px',
    color: slateTwo,
    marginLeft: '10px',
    marginTop: '7px',
    alignSelf: 'end',
  },
  rowWithSpaceInBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textarea: {
    'width': '100%',
    'height': '60px',
    'borderRadius': '2px',
    'backgroundColor': greyTransparent,
    'padding': '10px 12px',
    'fontSize': '14px',
    'color': slateTwo,
    'resize': 'none',
    'border': `1px solid ${lightBlueGrey}`,
    '&:focus': {
      'border': `1px solid ${vividAzure}`,
    },
  },
  errorMessage: {
    color: lightRed,
    fontSize: '12px',
  },
  copyLink: {
    height: '30px',
    marginTop: '5px',
    marginLeft: '20px',
  },
  accessLevelSelect: {
    marginLeft: '10px',
  },
  radioButtonWrapper: {
    padding: 0,
    fontSize: '14px',
    alignSelf: 'normal',
    marginRight: '10px',
  },
  domainInputWrapper: {
    'marginLeft': '10px',
    'marginRight': '10px',
    'minWidth': '50%',
    'position': 'relative',
    '& > span': {
      'position': 'absolute',
      'bottom': '-20px',
      'left': 0,
    },
  },
  label: {
    alignSelf: 'center',
  },
  infoText: {
    whiteSpace: 'nowrap',
    color: blueyGrey,
    fontSize: '14px',
    marginTop: '5px',
    display: 'inline-block',
  },
  relative: {
    position: 'relative',
  },
  infoBoxWrapper: {
    position: 'absolute',
    top: '27px',
    left: '160px',
    right: 0,
    zIndex: 100,
  },
  infoBoxWrapperTop: {
    top: 'auto',
    right: '155px',
    bottom: '27px',
    left: 0,
  },
  divider: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'position': 'relative',
    'marginBottom': '25px',
    '&:after': {
      top: 0,
      right: '-30px',
      bottom: 0,
      left: '-30px',
      content: '""',
      height: '1px',
      margin: 'auto',
      position: 'absolute',
      backgroundColor: iceBlue,
    },
  },

  dividerText: {
    zIndex: 1,
    position: 'relative',
    backgroundColor: white,
    padding: '0 10px',
    fontSize: '11px',
    color: blueyGrey,
  },

  row: {
    'display': 'flex',
    'justifyContent': 'space-between',
    '& > div:first-child': {
      'width': '63%',
      '& textarea': {
        height: '40px',
      },
    },
  },
  customSelectWrapper: {
    'margin': '0px',
    'border': '0px',
    'display': 'inline-flex',
    'padding': '0px',
    'minWidth': '0px',
    'flexDirection': 'column',
    'verticalAlign': 'top',
    'position': 'relative',
    '& > svg': {
      width: '16px',
      position: 'absolute',
      right: '8px',
      top: '8px',
      pointerEvents: 'none',
    },
  },
  customSelectButton: {
    height: '40px',
    color: '#505f79',
    border: '1px solid #cfd8dc',
    padding: '0 8px',
    minWidth: '150px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    marginRight: 0,
  },
  customSelectMenu: {
    '& ul': {
      padding: '0px !important',
    },
  },
  customSelectMenuItem: {
    height: '54px',
    padding: '0px !important',
    borderBottom: '1px solid #E9EDEF',
    position: 'relative',
  },
  customSelectMenuItemText: {
    'padding': '8px 24px 8px 0px',
    '& > p': {
      color: '#546E7A',
      fontFamily: 'Roboto',
      fontSize: '11px',
      letterSpacing: '0.22px',
      lineHeight: '13px',
    },
    '& > span': {
      color: '#37474F',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '21px',
      marginBottom: '4px',
    },
  },
  checkedRoleIcon: {
    padding: '8px',
    width: '32px',
  },
  inviteSelect: {
    'minHeight': '40px',
    'height': 'auto',
    'borderRadius': '2px',
    'backgroundColor': greyTransparent,
    'paddingTop': 7,
    'paddingLeft': 0,
    'fontSize': '14px',
    'color': slateTwo,
    'resize': 'none',
    'border': `1px solid ${lightBlueGrey}`,
    'display': 'flex',
    'flexWrap': 'wrap',
    'width': '320px',
    'marginRight': '16px',
    '& .account-chip-icon_wrapper': {
      'width': '30px',
      'height': '30px',
    },
    '& .account-chip-account_name': {
      'display': 'flex',
      'flex-direction': 'column',
      'align-self': 'flex-start',
    },
    '& .account-chip-account_email': {
      'font-size': '11px',
      'color': blueGrey,
    },
  },
  inviteSelectInput: {
    'width': '100%',
    'minWidth': '120px',
    'flex': 1,
    '& input': {
      paddingTop: 0,
    },
  },
  chip: {
    'height': '25px',
    'backgroundColor': '#ebeced',
    'borderRadius': 15,
    'padding': '6px 10px',
    'color': '#505F79',
    'fontFamily': 'Roboto',
    'fontSize': '11px',
    'letterSpacing': '0.22px',
    'lineHeight': '13px',
    'marginBottom': 6,
    'marginLeft': 6,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'paddingRight': '6px',
    '& button': {
      marginLeft: 4,
    },
  },
  invalidChip: {
    'position': 'relative',
    'border': '1px solid #E8384F',
    'backgroundColor': 'rgba(232,56,79,0.1)',
    '& > button': {
      'background': 'none',
    },
    '&:hover .invalidEmailAddress': {
      'display': 'block',
    },
  },
  activeItem: {
    border: `1px solid ${hubsyncBlue}`,
  },
  inviteUserSuggestionAdd: {
    'height': '50px',
    'cursor': 'pointer',
    'padding': '5px 10px',
    'alignItems': 'center',
    'display': 'flex',
    'color': hubsyncBlue,
    'fontSize': '13px',
    '& > button': {
      marginRight: '6px',
    },
  },
  inviteUserTooltip: {
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'center',
    width: '195px',
    height: '46px',
    alignItems: 'center',
    color: slateTwo,
  },
  inputFocused: {
    border: `1px solid ${vividAzure}`,
  },
};
