import * as request from 'superagent';

import {
  getWorkspaceCreateApiEndpoint,
  getAssureSignApiEndpoint,
  getTaxOrganizerApiEndpoint,
  getApiV2Url,
} from 'env';
import getAuthHeaders from 'utilities/getAuthHeaders';

import { CreateOrganizerPayload } from './types';

/**
 * Check if the client ID already exists inside workspace metadata
 * @param clientID
 * @returns
 */
export const validClientId = async (clientIDs: string[]) => {
  const queryIds = clientIDs.join(',');
  const endpoint = `${getWorkspaceCreateApiEndpoint()}/taxpayerid?ids=${queryIds}`;
  const response = await request.get(endpoint)
    .set(await getAuthHeaders());
  return response;
};

/**
 * Create Automatically workspaces from Master DB
 * @param data
 * @returns
 */
export const createWorkspacesByRecords = async (data) => {
  const endpoint = `${getWorkspaceCreateApiEndpoint()}/create`;
  const response = await request.post(endpoint)
    .set(await getAuthHeaders())
    .send(data);
  return response;
};

/**
 * Create Engagement Letters based on records data
 * @param data
 * @returns
 */
export const sendEngagementLettersByRecords = async (data, credentials) => {
  const { accountContextIdentifier, credentialSet } = credentials ?? {};
  if (!accountContextIdentifier && !credentialSet) {
    throw new Error('Not Credentials For Engagement Letters');
  }

  const endpoint = `${getAssureSignApiEndpoint()}/database/process`;
  const response = await request.post(endpoint)
    .set(await getAuthHeaders())
    .set({
      'x-as-context': accountContextIdentifier,
      'x-as-credential-set': credentialSet,
    })
    .send(data);

  return response;
};

/**
 *
 * @param clientData
 */
export const createTaxOrganizerByRecord = async (clientData: CreateOrganizerPayload[]) => {
  const url = `${getTaxOrganizerApiEndpoint()}/organizer/create`;
  const response = await request.post(url)
    .set(await getAuthHeaders())
    .send(clientData);
  return response;
};

/**
 *
 * @param workspaceId
 * @returns
 */
export const getOrganizerStatus = async (workspaceId: string) => {
  const url = `${getTaxOrganizerApiEndpoint()}/workspaces/${workspaceId}/organizer`;
  const response = await request.get(url)
    .set(await getAuthHeaders());
  return response;
};

/**
 *
 * @param organizerIds
 * @returns
 */
export const getOrganizerStatuses = async (organizerIds: string[]) => {
  const url = `${getTaxOrganizerApiEndpoint()}/organizer`;
  const response = await request.post(url)
    .set(await getAuthHeaders())
    .send(organizerIds);
  return response;
};


/**
 *
 * @param workspaceIds
 * @returns
 */
export const deleteOrganizers = async (workspaceIds: string[]) => {
  const url = `${getTaxOrganizerApiEndpoint()}/organizer/delete`;
  const response = await request.post(url)
    .set(await getAuthHeaders())
    .send(workspaceIds);
  return response;
};

/**
 *
 * @param workspaceId
 * @returns
 */
export const deleteWorkspace = async (workspaceId: string) => {
  const url = `${getApiV2Url()}/workspaces/${workspaceId}`;
  const response = await request.delete(url).set(await getAuthHeaders());
  return response;
};


/**
 *
 * @param workspaceId
 * @param newName
 */
export const renameWorkspace = async (workspacesId: string, newName: string) => {
  const url = `${getWorkspaceCreateApiEndpoint()}/renameWorkspace`;
  const response = await request.put(url)
    .set(await getAuthHeaders())
    .send({ workspacesId, newName });
  return response;
};
