import * as React from 'react';
import { ActivityFeedGroup as ActivityFeedGroupType } from 'data/activityFeed/types';
import { Wrapper } from './ActivityFeedGroups.style';
import ActivityFeedGroup from 'components/ActivityFeed/ActivityFeedGroup';

interface Props {
  groups: ActivityFeedGroupType[];
}

export default class ActivityFeedGroups extends React.PureComponent<Props> {
  render() {
    const { groups } = this.props;

    return (
      <Wrapper>
        {
          groups.map((group, index) => {
            return (
              <ActivityFeedGroup
                id={index}
                key={index}
                group={group}
              />
            );
          })
        }
      </Wrapper>
    );
  }
}
