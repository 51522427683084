import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import Typography, { Variant } from '../Typography';

export type LabelableProps = {
  id?: string;
  disabled?: boolean;
  label?: string;
  labelRight?: boolean;
  className?: string;
  selected?: boolean;
  onClick?(): void;
};

type OwnProps = LabelableProps & {
  Component: React.ComponentType<LabelableProps>;
};

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function UnstyledLabelable<InnerProps>(props: Props): JSX.Element {
  const {
    classes,
    label,
    labelRight,
    Component,
    onClick,
    className,
    ...innerProps
  } = props;

  const onClickHandler = !innerProps.disabled ? onClick : undefined;

  if (!label) {
    return (
      <Component
        {...innerProps}
        onClick={onClickHandler}
        className={className}
      />
    );
  }

  const wrapperClassName = cn({
    [classes.wrapper]: true,
    [className || '']: className,
    [classes.disabledWrapper]: innerProps.disabled,
  });

  if (labelRight) {
    return (
      <div className={wrapperClassName} onClick={onClickHandler}>
        <Component {...innerProps} />
        <Typography variant={Variant.Label} className={classes.labelRight}>
          {label}
        </Typography>
      </div>
    );
  }

  return (
    <div className={wrapperClassName} onClick={onClickHandler}>
      <Typography variant={Variant.Label} className={classes.labelLeft}>
        {label}
      </Typography>
      <Component {...innerProps} />
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'disabledWrapper'
  | 'labelLeft'
  | 'labelRight'
  ;

const styles = withStyles<ClassKey>({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabledWrapper: { cursor: 'not-allowed' },
  labelLeft: { marginRight: 5 },
  labelRight: { marginLeft: 5 },
});

export default styles(UnstyledLabelable);
