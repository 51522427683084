import styled from 'styled-components';

export const PARENT_MARGINS = 47;

const Heading = styled.h1`
  font-size: 16px;
  color: #546e7a;
  margin-bottom: 14px;
  margin-top: 14px;
`;

const Wrapper = styled.div`
  margin-bottom: 64px;
  position: relative;
`;

const PaginationContainer = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  left: 0;
`;

const DisplayInfo = styled.div`
  font-size: 14px;
  position: absolute;
  right: 100px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const NotFound = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 150px;
  flex-direction: column;
  height: calc(100vh - 200px);
`;

const NotFoundTitle = styled.div `
  font-size: 16px;
  font-weight: 500;
  color: #212121;
`;

const NotFoundText = styled.div `
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #212121;
`;

const SwitchContainer = styled.div`
  position: absolute;
  top: 89px;
  left: 156px;
  font-size: 13px;
  display: flex;
  align-items: center;
  & span.label-switch {
    margin-left: -8px;
    font-weight: 500;
  }
`;

export { Heading, Wrapper, PaginationContainer, DisplayInfo, NotFound, NotFoundTitle, NotFoundText, SwitchContainer };
