import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';

import * as fromActions from 'data/collections/collections.actions';
import { allColumnsNamesSelector } from 'data/collections/collections.selectors';
import { ColumnVM } from 'types/schema';
import { columnsVMSelector } from 'data/collections/view-config/viewConfig.selectors';
import { CreateFieldPayload } from 'data/collections/collections.reducer';

import CreateNewField from '../Fields/CreateNewField';
import createFieldPayload from '../Fields/createFieldPayload';
import * as Data from '../Fields/data';
import { flipSelectionState, SelectionState } from '../Fields/SelectFieldType';

interface OwnProps {
  anchorEl: HTMLElement | null;
  anchorOrigin?: PopoverOrigin;
  columnIndexInView?: number;
  onClosePopper(): void;
}

interface DispatchProps {
  createField(payload: CreateFieldPayload): void;
}

interface StateProps {
  allColumnsNamesSelector: (ignoreId: string) => string[];
  columnsVMSelector: ColumnVM[];
}

type Props
  = OwnProps
  & DispatchProps
  & StateProps;

interface State {
  fieldName?: string;
  fieldTypeFilterQuery?: string;
  fieldTypes: Data.FieldType[];
  errorMessage?: string;
  selectedFieldData: Data.FieldData;
  selectionState: SelectionState;
}

export class AddColumnHeaderModal extends React.PureComponent<Props, State> {
  private updatePositionPopover;
  public state: State = {
    fieldName: `Field ${this.props.columnsVMSelector.length + 1}`,
    fieldTypes: Data.allFieldTypes,
    selectedFieldData: Data.getDefaultFieldData(Data.FieldType.SingleLineText),
    selectionState: SelectionState.SELECTED_FIELD,
  };

  public render(): JSX.Element {
    const { anchorOrigin } = this.props;
    const transformOrigin: PopoverOrigin | undefined = anchorOrigin && {
      vertical: anchorOrigin.vertical === 'top'
        ? 'bottom'
        : anchorOrigin.vertical === 'bottom'
          ? 'top'
          : anchorOrigin.vertical,
      horizontal: anchorOrigin.horizontal === 'left'
        ? 'right'
        : anchorOrigin.horizontal === 'right'
          ? 'left'
          : anchorOrigin.horizontal,
    };

    const popOverId = `addColumn${ this.state.fieldName ? '_' + this.state.fieldName : ''}${ this.props.columnIndexInView ? '_' + this.props.columnIndexInView : ''}`;

    return (
      <Popover
        action={(actions)=>this.updatePositionPopover = actions.updatePosition}
        id={popOverId}
        open
        anchorEl={this.props.anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={this.closePopper}
        onClick={()=>this?.updatePositionPopover()}
      >
        <CreateNewField
          {...this.state}
          allowMultipleCollaborators
          onFieldNameChange={this.onFieldNameChange}
          onDataUpdate={this.onDataUpdate}
          onFieldTypeFilterQueryChange={this.onFieldTypeFilterQueryChange}
          onFieldTypeSelected={this.onFieldTypeSelected}
          onToggleSelectFieldType={this.onToggleSelectFieldType}
          onCancel={this.closePopper}
          onSave={this.onSave}
        />
      </Popover>
    );
  }

  private closePopper = (): void => {
    this.setState({});
    this.props.onClosePopper();
  };

  private onFieldNameChange = (fieldName: string): void => {
    this.setState({ fieldName, errorMessage: undefined });
  };

  private onDataUpdate = (selectedFieldData: Data.FieldData): void => {
    this.setState({ selectedFieldData });
  };

  private onFieldTypeFilterQueryChange = (fieldTypeFilterQuery: string): void => {
    if (!fieldTypeFilterQuery) {
      this.setState({
        fieldTypeFilterQuery: undefined,
        fieldTypes: Data.allFieldTypes,
      });
    } else {
      this.setState({
        fieldTypeFilterQuery,
        fieldTypes: filterFieldTypes(fieldTypeFilterQuery),
      });
    }
  };

  private onFieldTypeSelected = (fieldType: Data.FieldType): void => {
    this.setState({
      selectedFieldData: Data.getDefaultFieldData(fieldType),
      selectionState: flipSelectionState(this.state.selectionState),
    });
  };

  private onToggleSelectFieldType = (): void => {
    this.setState({ selectionState: flipSelectionState(this.state.selectionState) });
  };

  private onSave = (): void => {
    if (!this.state.fieldName) {
      this.setState({
        fieldName: undefined,
        errorMessage: 'Please provide a name.',
      });
      return;
    }

    if (this.doesFileNameExists(this.state.fieldName)) {
      this.setState({
        errorMessage: 'Name already exists. Please enter unique name.',
      });
      return;
    }

    let { selectedFieldData } = this.state;
    if ([Data.FieldType.MultiSelect, Data.FieldType.SingleSelect].includes(selectedFieldData.type)) {
      selectedFieldData = selectedFieldData as Data.MultiSelectData;
      selectedFieldData = {
        ...selectedFieldData,
        choices: selectedFieldData.choices.filter((choice) => choice.value.label.trim()),
      };
    }

    if (selectedFieldData.type === Data.FieldType.Button) {
      selectedFieldData = selectedFieldData as Data.ButtonData;
      if (!selectedFieldData.valid || !selectedFieldData.action) {
        this.setState({
          errorMessage: 'Please check the fields.',
        });
        return;
      }
    }

    const payload = createFieldPayload(
      this.state.fieldName,
      selectedFieldData,
      this.props.columnIndexInView,
    );

    this.props.createField(payload);
    this.closePopper();
  };

  private doesFileNameExists = (fileName: string): boolean => {
    const { allColumnsNamesSelector } = this.props;
    const columnNames = allColumnsNamesSelector('');
    return columnNames.some(name => name.toLowerCase() === fileName.toLowerCase());
  };
}

const selectors = createStructuredSelector({
  allColumnsNamesSelector,
  columnsVMSelector,
});

const mapDispatchToProps: DispatchProps = {
  createField: fromActions.Actions.createField,
};

export default connect<StateProps, DispatchProps, OwnProps>(selectors, mapDispatchToProps)(AddColumnHeaderModal);

function filterFieldTypes(filter: string): Data.FieldType[] {
  return Data.allFieldTypes.filter((type) => {
    return type.toLowerCase().includes(filter.toLowerCase());
  });
}
