import { FilterOperators, FilterType, Operator, RegularFilter } from 'types/gridOptions';
import { FieldType } from 'types/response/fieldNode';
import { FilterValue } from 'types/response/query';

import { DateFormat, DEFAULT_DATE_FORMAT } from '../../Fields/data';

// TODO replace with function declarations where needed. Replace with lodash functions
const makeWordCapitalized = (word: string): string => {
  return word
    .split('')
    .map((letter, i) => i === 0 ? letter.toUpperCase() : letter)
    .join('');
};

const changeWordsToBeCamelCase = (word: string, i: number): string => {
  return i === 0
    ? word.toLowerCase()
    : makeWordCapitalized(word);
};

export const mapFilterViewOperatorToOperator = (viewOperator: FilterOperators): Operator => {
  return viewOperator
    .split(' ')
    .map(changeWordsToBeCamelCase)
    .join('') as Operator;
};

export function isMultipleOperator(type: Operator): boolean {
  return type === Operator.IsAnyOf || type === Operator.IsNoneOf
    || type === Operator.HasAnyOf || type === Operator.HasAllOf
    || type === Operator.IsExactly || type === Operator.HasNoneOf;
}

export function isOperatorTypeHasChanged(prevOperator: Operator, newOperator: Operator) {
  return isMultipleOperator(prevOperator) !== isMultipleOperator(newOperator);
}

const operatorsByFilterType: Record<FilterType, FilterOperators[]> = {
  [FilterType.Text]: [
    FilterOperators.Equals,
    FilterOperators.NotEquals,
    FilterOperators.StartsWith,
    FilterOperators.EndsWith,
    FilterOperators.Contains,
    FilterOperators.NotContains,
    FilterOperators.Empty,
    FilterOperators.NotEmpty,
  ],
  [FilterType.Number]: [
    FilterOperators.Equals,
    FilterOperators.NotEquals,
    FilterOperators.LessThan,
    FilterOperators.LessThanOrEquals,
    FilterOperators.GreaterThan,
    FilterOperators.GreaterThanOrEquals,
  ],
  [FilterType.Date]: [
    FilterOperators.Equals,
    FilterOperators.GreaterThan,
    FilterOperators.LessThan,
    // FilterOperators.NotEquals,
  ],
  [FilterType.Boolean]: [
    FilterOperators.Equals,
  ],
  [FilterType.Singlechoice]: [
    FilterOperators.Is,
    FilterOperators.IsNot,
    FilterOperators.IsAnyOf,
    FilterOperators.IsNoneOf,
    FilterOperators.Empty,
    FilterOperators.NotEmpty,
  ],
  [FilterType.Multiplechoice]: [
    FilterOperators.HasAnyOf,
    FilterOperators.HasAllOf,
    FilterOperators.IsExactly,
    FilterOperators.HasNoneOf,
    FilterOperators.Empty,
    FilterOperators.NotEmpty,
  ],
};

export function getListOfOperatorsForFilterType(filterType: FilterType): FilterOperators[] {
  return operatorsByFilterType[filterType] || operatorsByFilterType[FilterType.Text];
}

export function isNullEmptyBlankOrUndefined(label: string): boolean {
  return !label || label === '' || label === ' ' || label === undefined;
}

const columnTypeToFilterType: { [columnType in FieldType]?: FilterType } = {
  [FieldType.Date]: FilterType.Date,
  [FieldType.Float]: FilterType.Number,
  [FieldType.Integer]: FilterType.Number,
  [FieldType.Currency]: FilterType.Number,
  [FieldType.Rating]: FilterType.Number,
  [FieldType.Percent]: FilterType.Number,
  [FieldType.Multilinetext]: FilterType.Text,
  [FieldType.Singlelineoftext]: FilterType.Text,
  [FieldType.Email]: FilterType.Text,
  [FieldType.Url]: FilterType.Text,
  [FieldType.Phone]: FilterType.Text,
  [FieldType.Boolean]: FilterType.Boolean,
  [FieldType.Singlechoice]: FilterType.Singlechoice,
  [FieldType.Account]: FilterType.Singlechoice,
  [FieldType.Multiplechoice]: FilterType.Multiplechoice,
  [FieldType.Tag]: FilterType.Multiplechoice,
  [FieldType.Checklist]: FilterType.Multiplechoice,
};

export function mapColumnEditorToFilterType(columnType: FieldType, allowMultiple?: boolean): FilterType {
  return allowMultiple ? FilterType.Multiplechoice : columnTypeToFilterType[columnType] || FilterType.Text;
}

function getDefaultFilterOperatorForFilterType(filterType: FilterType): FilterOperators {
  switch (filterType) {
    case FilterType.Text:
      return FilterOperators.Contains;
    case FilterType.Date:
      return FilterOperators.Equals;
    case FilterType.Number:
      return FilterOperators.Equals;
    case FilterType.Singlechoice:
      return FilterOperators.Is;
    case FilterType.Multiplechoice:
      return FilterOperators.HasAnyOf;
    default:
      return FilterOperators.Equals;
  }
}

export function getDefaultOperatorForFilterType(filterType: FilterType): Operator {
  return mapFilterViewOperatorToOperator(
    getDefaultFilterOperatorForFilterType(filterType),
  );
}

export const constructFilter = (
  columnId: string,
  type: FilterType,
  operator?: Operator,
  value?: FilterValue | FilterValue[] | null,
  dateFormat?: DateFormat,
): RegularFilter => {
  const baseResult = {
    columnId,
    operator: operator || getDefaultOperatorForFilterType(type),
  };

  switch (type) {
    case FilterType.Text:
      return { ...baseResult, type, filter: typeof value === 'string' ? value : '' };
    case FilterType.Number:
      return { ...baseResult, type, filter: typeof value === 'number' ? value : null };
    case FilterType.Boolean:
      return { ...baseResult, type, filter: typeof value === 'boolean' ? value : false };
    case FilterType.Singlechoice:
      return { ...baseResult, type, filter: isStringArray(value) ? value : [] };
    case FilterType.Multiplechoice:
      return { ...baseResult, type, filter: isStringArray(value) ? value : [] };
    case FilterType.Date:
      return {
        ...baseResult,
        type,
        filter: typeof value === 'string' ? value : null,
        dateFormat: dateFormat || DEFAULT_DATE_FORMAT,
      };
  }
};

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(item => typeof item === 'string');
