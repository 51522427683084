import { createAsyncAction } from 'typesafe-actions';
import { CommonNode } from 'types/response';
import { CollectionTypes } from 'types/schema';

export interface FetchNodeSuccessPayload {
  collectionType: CollectionTypes;
  node: CommonNode;
}

export const fetchNode = createAsyncAction(
  'nodes/FETCH_NODE_REQUEST',
  'nodes/FETCH_NODE_SUCCESS',
  'nodes/FETCH_NODE_FAILURE',
)<string, FetchNodeSuccessPayload, Error>();

export type FetchNodeRequest = ReturnType<typeof fetchNode.request>;
export type FetchNodeSuccess = ReturnType<typeof fetchNode.success>;
export type FetchNodeError = ReturnType<typeof fetchNode.failure>;

export type Action
  = FetchNodeRequest
  | FetchNodeSuccess
  | FetchNodeError
  ;
