import * as React from 'react';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { IFilterReactComp } from 'ag-grid-react/lib/interfaces';

import { fieldTypeWithMarkup } from 'utilities/collections';
import { removeHTMLTag } from 'utilities/format';

import { FilterComponentParams } from './types';
import { isEmpty } from 'lodash';

type Props = IFilterParams & FilterComponentParams;

class AgGridTextFilter extends React.PureComponent<Props> implements IFilterReactComp {
  private model: string;

  constructor(props: IFilterParams & FilterComponentParams) {
    super(props);
    this.model = '';
  }

  public isFilterActive = (): boolean => {
    return this.model !== '';
  };

  public doesFilterPass = (params: IDoesFilterPassParams): boolean => {
    const filterValue = this.getModel();
    let filtered = true;

    if (!isEmpty(filterValue)) {
      const { colDef } = this.props;
      const { refData } = colDef;
      const colType = refData?.fieldType ?? '';
      const colId = refData?._id ?? '';
      const fieldData = params.data.fields[colId];

      if (!isEmpty(fieldData) && fieldTypeWithMarkup(colType)) {
        // @TODO Rethink a better way to filter values without markup
        // this lines extracts the HTML tags using regexp
        // that's an expensive task for each row in the grid
        filtered = removeHTMLTag(fieldData.toString().toLowerCase()).includes(filterValue.toString().toLowerCase());
      }
    }

    return filtered;
  };

  public getModel = (): string => {
    return this.model;
  };

  public setModel = (model: string): void => {
    this.model = model;
  };

  public onFloatingFilterChanged = (model: string) => {
    this.model = model;
    this.props.filterChangedCallback();
  };

  public render(): React.ReactNode {
    return null;
  }
}

export default AgGridTextFilter;
