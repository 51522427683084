import * as React from 'react';

import { Variant } from 'components/Button';
import ModalContent from 'components/Modals/Modal/Content';
import ModalCustomActions from 'components/Modals/Modal/CustomActions';
import ModalHeader from 'components/Modals/Modal/Header';
import Typography, { Variant as TypographyVariant } from 'components/Typography';

interface Props {
  title: string;
  message?: string;
  close: () => void;
  onConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  cancelBtn?: boolean;
}

export class Confirm extends React.Component<Props> {
  public render = (): JSX.Element => {
    const {
      close,
      title,
      message,
      confirmLabel,
      cancelLabel,
      cancelBtn = true,
    } = this.props;

    return (
      <React.Fragment>
        <ModalHeader onClose={close}>{title}</ModalHeader>
        <ModalContent>
          {message &&
            <Typography variant={TypographyVariant.ModalText}>
              {message}
            </Typography>
          }
        </ModalContent>
        <ModalCustomActions
          leftButtons={cancelBtn ? [
            {
              label: cancelLabel || 'Cancel',
              variant: Variant.SecondaryLink,
              onClick: close,
            },
          ] : []}
          rightButtons={[
            {
              label: confirmLabel || 'Confirm',
              variant: Variant.Primary,
              onClick: this.handleConfirmClick,
            },
          ]}
        />
      </React.Fragment>
    );
  };

  private handleConfirmClick = (): void => {
    const { onConfirm, close } = this.props;
    onConfirm();
    close();
  };
}

export default Confirm;
