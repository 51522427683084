import * as React from 'react';

import { TenantNode } from 'types/response/tenantNode';

import { FieldData, FieldType } from '../../data';
import Attachment from './Attachment';
import ButtonField from './ButtonField';
import Checkbox from './Checkbox';
import Currency from './Currency';
import Date from './Date';
import Email from './Email';
import LongText from './LongText';
import Number from './Number';
import Percent from './Percent';
import Person from './Person';
import Phone from './Phone';
import Select from './Select';
import SingleLineText from './SingleLineText';
import Url from './Url';

interface OwnProps {
  selectedFieldData: FieldData;
  allowMultipleCollaborators?: boolean;
  onDataUpdate(data: FieldData): void;
  tenant?: TenantNode;
}

export default function FieldMetadata(props: OwnProps): JSX.Element | null {
  const { selectedFieldData } = props;

  switch (selectedFieldData.type) {
    case FieldType.SingleLineText: {
      return (
        <SingleLineText
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.LongText: {
      return <LongText />;
    }

    case FieldType.Attachment: {
      const { enable } = props.tenant?.thirdParty?.filestack || {};

      return (
        <Attachment
          data={selectedFieldData}
          allowShowPreview={!!enable}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Checkbox: {
      return (
        <Checkbox
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.MultiSelect: {
      return (
        <Select
          data={selectedFieldData}
          description="Multiple select allows you to select one or more predefined options listed below."
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.SingleSelect: {
      return (
        <Select
          data={selectedFieldData}
          description="Single select allows you to select a single option from predefined options in a dropdown."
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Person: {
      return (
        <Person
          data={selectedFieldData}
          allowMultipleCollaborators={props.allowMultipleCollaborators}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Date: {
      return (
        <Date
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Number: {
      return (
        <Number
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Currency: {
      return (
        <Currency
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.Percent: {
      return (
        <Percent
          data={selectedFieldData}
          onDataUpdate={props.onDataUpdate}
        />
      );
    }

    case FieldType.URL: {
      return (
        <Url data={selectedFieldData} onDataUpdate={props.onDataUpdate} />
      );
    }

    case FieldType.Email: {
      return (
        <Email data={selectedFieldData} onDataUpdate={props.onDataUpdate} />
      );
    }

    case FieldType.Phone: {
      return (
        <Phone data={selectedFieldData} onDataUpdate={props.onDataUpdate} />
      );
    }

    case FieldType.Button: {
      return (
        <ButtonField data={selectedFieldData} onDataUpdate={props.onDataUpdate} />
      );
    }

    case FieldType.Rating: {
      return null;
    }
  }
}
