import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import { ClassKey, styles } from './Boolean.style';
import { FieldRendererProps } from '../Fields.types';
import Checkbox from '../../Checkbox';

type Props
  = FieldRendererProps
  & WithStyles<ClassKey>
  ;

function BooleanRenderer(props: Props): JSX.Element {
  const isSelected = props.value === true || props.value === 'true';
  return (
    <div className={props.classes.wrapper}>
      <Checkbox selected={isSelected} />
    </div>
  );
}

export default styles(BooleanRenderer);
