import React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import svgIcons from 'styles/svgIcons';

import { styles } from './Arrows.styles';

export interface ArrowsProps extends WithStyles<typeof styles> {
  id: string;
  moveLeft: () => void;
  moveRight: () => void;
}

function Arrows({
  id,
  moveLeft,
  moveRight,
  classes,
}: ArrowsProps): React.ReactElement<ArrowsProps> {
  return (
    <div className={classes.arrowsContainer}>
      <button id={`btn-${id}-left`} className={classes.arrowBox} onClick={moveLeft}>
        <svgIcons.MoveArrowLeft className={classes.arrow} />
      </button>
      <button id={`btn-${id}-right`} className={classes.arrowBox} onClick={moveRight}>
        <svgIcons.MoveArrowRight className={classes.arrow} />
      </button>
    </div>
  );
}

export default withStyles(styles)(Arrows);
