import * as React from 'react';

import { Role } from 'data/accounts/types';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import { State as ReduxState } from 'reducers';
import { getTenantConfig } from 'env';

import Route from './Route';
import { UserDashboard } from 'pages/UserDashboard';

const tenantConfig = getTenantConfig() as any;

interface PrivateRouteProps extends RouteProps {
  roles: string | string[]; // Role(s) required to navigate to the specified route
  redirectPath: string; // redirect path if don't authenticate route
}

interface DashboardRouteProps extends RouteProps {
  redirectPath: string; // redirect path if don't authenticate route
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { roles, redirectPath } = props;
  const currentUser = useSelector((state: ReduxState) => state.users.user);
  // If there is no email that means the user is still loading so we don't want to redirect him anywhere
  if (!currentUser.email) return null;
  // If the user has a role in the claimed roles, user is allowed to navigate to the route
  // Otherwise, he will be redirected to "redirectPath"
  const hasRole = currentUser.roles ? (currentUser.roles as Role[]).some((role) => roles.includes(role.name)) : false;
  return hasRole ? (
    <Route {...props} component={props.component} render={undefined} />
  ) : (
    <Redirect to={{ pathname: redirectPath }} />
  );
};

export const DashboardRoute: React.FC<DashboardRouteProps> = (props) => {
  const { redirectPath } = props;
  const currentUser = useSelector((state: ReduxState) => state.users.user);
  const tenant = useSelector((state: ReduxState) => state.app.tenant);

  const enableFeature = tenantConfig.enabledFeatures.dashboard ? true : false;
  const trustedDomains = tenant && tenant.authProvider ?.identityProviders?.length
    ? tenant.authProvider?.identityProviders[0].trustedDomains
    : [];
  // if user email is not available, wait for it to proceed further.
  if (!currentUser.email) return null;
  let enable = false;
  if (trustedDomains && trustedDomains.length === 0) {
    enable = true;
  } else {
    const emailDomain = currentUser.email.split('@')[1];
    const matchDomain = trustedDomains.find((domain) => domain === emailDomain);
    enable = enableFeature && !!matchDomain;
  }

  return enable ? (<Route exact path="/dashboard/" component={UserDashboard}/>):(<Redirect to={{ pathname: redirectPath }} />);
};
