import { CSSProperties } from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import * as Colors from 'styles/colors';

type ClassKey = 'content' | 'label' | 'searchBar' | 'directories' | 'folderIcon' | 'collapseIcon' | 'expandIcon';

export const styles: Record<ClassKey, CSSProperties> = {
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '14px',
  },
  searchBar: {
    marginBottom: '15px',
  },
  directories: {
    padding: '8px 0px 56px 0px',
    background: Colors.greyTransparent,
    border: `1px solid ${Colors.lightBlueGrey}`,
    borderRadius: '2px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  folderIcon: {
    width: '20px',
    margin: '0 0.5rem',
  },
  collapseIcon: {
    width: '12px',
    height: '12px',
    marginLeft: '-10px',
    fill: Colors.blueyGrey,
  },
  expandIcon: {
    width: '12px',
    height: '12px',
    marginLeft: '-10px',
    fill: Colors.lightBlueGrey,
  },
};

export interface DirectoryWrapperProps {
  depth: number;
  opened?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

export const DirectoryWrapper = styled<DirectoryWrapperProps, 'div'>('div')`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${Colors.slate};
  background-color: ${({ selected }) => selected ? Colors.deprecatedFileChipBackground : 'transparent'};
  padding: 4px 0;
  padding-left: ${({ depth }) => depth + 1.5}rem;
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  font-size: 14px;
`;
