import React, { useState } from 'react';

import styled from 'styled-components';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import getFileIcon from 'styles/fileExtension';
import svgIcons from 'styles/svgIcons';

const getIconWrapperSize = (size: GRID_ROW_HEIGHT) => {
  return size === GRID_ROW_HEIGHT.BASE ? GRID_ROW_HEIGHT.BASE*1.5 : size;
};

const IconWrapper = styled.div<{ size: GRID_ROW_HEIGHT }>`
  width: ${({ size = GRID_ROW_HEIGHT.BASE }) => `CALC(${getIconWrapperSize(size)}px - 8px) !important`};
  height: ${({ size = GRID_ROW_HEIGHT.BASE }) => `CALC(${getIconWrapperSize(size)}px - 8px) !important`};
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 40px;
  overflow: hidden;
  background-color: #eaeef4;
  color: #546e7a;
  border-radius: 4px;
  user-select: none;
  padding-left: 6px;
  padding-right: 6px;
`;

const ThumbnailItem = styled.img<{ size: GRID_ROW_HEIGHT; isLoading: boolean }>`
  object-fit: cover;
  object-position: center;
  display: ${({ isLoading }) => isLoading ? 'none': 'inline-block'};
  width: auto;
  height: ${({ size = GRID_ROW_HEIGHT.BASE }) => `CALC(${getIconWrapperSize(size)}px - 8px) !important`};
`;

interface ThumbnailPlaceholderProps {
  size: GRID_ROW_HEIGHT;
  color: string;
  isLoading: boolean;
}

interface ThumbnailAttachmentItemProps {
  classes: any;
  onDelete(event: React.MouseEvent): void;
  onClick: () => void;
  permissions: string[];
  fileExtension: string | undefined;
  thumbnail: string | undefined;
  readOnly: boolean | undefined;
  hideDelete: boolean | undefined;
  rowHeight: GRID_ROW_HEIGHT;
}

const ThumbnailPlaceholder: React.FC<ThumbnailPlaceholderProps> = ({ size, color, isLoading }: ThumbnailPlaceholderProps) => {
  return isLoading ? (
    <div style={{ paddingTop: 4 }}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
        x="0px" y="0px" width={`${size}px`} height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
      >
        <rect x="0" y="0" width={size} height={size} fill={color}>
          <animate attributeName="opacity" attributeType="XML"
            values="1; .2; 1"
            begin="0s" dur="3s" repeatCount="indefinite" />
        </rect>
      </svg>
    </div>
  ) : null;
};

const ThumbnailAttachmentItem: React.FC<ThumbnailAttachmentItemProps> = (props: ThumbnailAttachmentItemProps) => {
  const { classes, hideDelete, rowHeight = 0, readOnly, permissions, fileExtension, thumbnail, onDelete, onClick } = props;
  const Icon = getFileIcon(fileExtension);
  // This variable will take care of show/hide the placeholder and the thumbnail
  // false -> The thumbnail url doesn't exists
  // true -> The thumbnail url is passed from the parent component
  const allowDelete = permissions.includes('files:delete');
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(!!thumbnail);
  const deleteIconLeft = getIconWrapperSize(rowHeight) / 2;

  return (
    <>
      <ThumbnailWrapper onClick={onClick}>
        {thumbnail ?
          <>
            <ThumbnailPlaceholder
              isLoading={isThumbnailLoading}
              size={rowHeight}
              color="#CCC"
            />
            <ThumbnailItem
              isLoading={isThumbnailLoading}
              src={thumbnail}
              size={rowHeight}
              onLoad={() => {
                setIsThumbnailLoading(false);
              }}
            />
          </>
          :
          <IconWrapper size={rowHeight}>
            <Icon className={classes.iconPreview} />
          </IconWrapper>
        }
      </ThumbnailWrapper>
      {!(hideDelete || isThumbnailLoading) && allowDelete && <svgIcons.CloseCircle
        className={classes.deleteIcon}
        style={{ left: `CALC(50% + ${deleteIconLeft - 4}px)` }}
        onClick={!readOnly && allowDelete ? onDelete : undefined}
      />}
    </>
  );
};

export { ThumbnailAttachmentItem };
