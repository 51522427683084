import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import Button, { Variant as ButtonVariants } from 'components/Button';
import svgIcons from '../../styles/svgIcons';
import Password from 'components/Password/Password';
import authService from '../../services/AuthService';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Colors from 'styles/colors';
import { COGNITO_EXCEPTIONS_MESSAGES, AppMessages } from '../../constants';


enum RESET_PASSWORD_MODES {
  INITIALLY = 'initially',
  WITH_ERROR = 'with_error',
}

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};

interface State {
  error: string;
  mode: RESET_PASSWORD_MODES;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = () => {
  const [state, setState] = useState<State>({
    error: '',
    mode: RESET_PASSWORD_MODES.INITIALLY,
    showPassword: false,
    showConfirmPassword: false,
  });

  const ERROR_MESSAGES = {
    MUST_MATCH: 'New password and Confirm password must match',
    INVALID_PASSWORD: 'The new password is invalid.',
    REQUIRED_PARAMETERS: 'Missing required parameters to change password.',
    PASSWORD_REQUIRED: 'The new password is required.',
    CONFIRMATION_REQUIRED: 'The confirmation password is required.',
  };

  const { control, handleSubmit, errors, formState, trigger } = useForm({ mode: 'onChange' });
  const { isDirty, isValid } = formState;
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBackendErrorMsgShown, setIsBackendErrorMsgShown] = useState(false);
  const [isRedirectingToLogin, setIsRedirectingToLogin] = useState(false);
  const [passwordFocuses, setPasswordFocuses] = useState({
    isNewPasswordFocused: false,
    isConfirmPasswordFocused: false,
  });
  const query = useQuery();
  const history = useHistory();

  const code = query.get('code') || '';
  const email = query.get('email') || '';

  const handleOnSubmit: SubmitHandler<Inputs> = async (data) => {
    const { newPassword, confirmPassword } = data;
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      setState({ ...state, error: ERROR_MESSAGES.MUST_MATCH, mode: RESET_PASSWORD_MODES.WITH_ERROR });
      return setIsLoading(false);
    }
    try {
      if (code && email) {
        await authService.forgotPasswordSubmit(code, email, newPassword);
        setIsRedirectingToLogin(true);
      } else throw new Error(ERROR_MESSAGES.REQUIRED_PARAMETERS);
    } catch (err) {
      if (err.code) {
        setState({ ...state, error: COGNITO_EXCEPTIONS_MESSAGES[err.code] || AppMessages.GENERIC_ERROR_MESSAGE });
      }
      setIsBackendErrorMsgShown(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isDirty) return;
    // This allows to manually trigger validation for newPassword input
    trigger('newPassword');
  }, [isPasswordValid]);

  useEffect(() => {
    if (!isRedirectingToLogin) return;
    history.push('/login');
  }, [isRedirectingToLogin]);

  const clearError = (): void => {
    if (state.error || state.mode === RESET_PASSWORD_MODES.WITH_ERROR) {
      setState({ ...state, error: '', mode: RESET_PASSWORD_MODES.INITIALLY });
      setIsBackendErrorMsgShown(false);
    }
  };


  return (
    <>
      <div className="auth-welcome-container">
        <h3>Password Reset</h3>
      </div>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="auth-input-container auth-input-container-hide-input-error">
          <label htmlFor="password">New Password</label>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: ERROR_MESSAGES.PASSWORD_REQUIRED,
              },
              validate: () => isPasswordValid || ERROR_MESSAGES.INVALID_PASSWORD,
            }}
            render={(props) => (
              <Password
                value={props.value}
                withReset={false}
                onInput={() => {
                  setPasswordFocuses({ ...passwordFocuses, isNewPasswordFocused: true });
                  clearError();
                }}
                onChange={props.onChange}
                onBlur={() =>{
                  setPasswordFocuses({ ...passwordFocuses, isNewPasswordFocused: false });
                }}
                passwordReset={() => null}
                setIsPasswordValid={setIsPasswordValid}
                errorMessage={
                  (errors.newPassword && !passwordFocuses.isNewPasswordFocused) && (
                    <div className="auth-input-error-container">
                      <svgIcons.Limited />{' '}
                      <span className="auth-input-error">
                        {errors.newPassword?.message}
                      </span>
                    </div>
                  )
                }
                InputProps={{
                  placeholder: 'Enter Password',
                  startAdornment: (
                    <InputAdornment position="start" variant="standard">
                      <svgIcons.LockInput
                        className="auth-input-start-icon"
                        color={Colors.inputIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        <div className="auth-input-container auth-input-container-hide-input-error">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: ERROR_MESSAGES.CONFIRMATION_REQUIRED,
              },
              validate: () => isPasswordValid || ERROR_MESSAGES.INVALID_PASSWORD,
            }}
            render={(props) => (
              <Password
                value={props.value}
                withReset={false}
                onInput={() => {
                  setPasswordFocuses({ ...passwordFocuses, isConfirmPasswordFocused: true });
                  clearError();
                }}
                onChange={props.onChange}
                onBlur={() => {
                  setPasswordFocuses({ ...passwordFocuses, isConfirmPasswordFocused: false });
                }}
                passwordReset={() => null}
                setIsPasswordValid={() => null}
                errorMessage={
                  (errors.confirmPassword && !passwordFocuses.isConfirmPasswordFocused) && (
                    <div className="auth-input-error-container">
                      <svgIcons.Limited />{' '}
                      <span className="auth-input-error">
                        {errors.confirmPassword?.message}
                      </span>
                    </div>
                  )
                }
                InputProps={{
                  placeholder: 'Confirm Password',
                  startAdornment: (
                    <InputAdornment position="start" variant="standard">
                      <svgIcons.LockInput
                        className="auth-input-start-icon"
                        color={Colors.inputIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        {(state.mode === RESET_PASSWORD_MODES.WITH_ERROR ||
          isBackendErrorMsgShown) && (
          <div className="auth-error-message auth-error-container">
            <span>
              <svgIcons.Limited />
            </span>
            {state.error}
          </div>
        )}

        <div className="auth-action-container no-margin">
          <Button
            type="submit"
            label="Log In"
            variant={ButtonVariants.Primary}
            fullWidth
            disabled={!isDirty || !isValid || isLoading}
            isLoading={isLoading}
          />
        </div>
      </form>
      <div className="auth-footer-container">
        <p>By clicking “Log In” you agree to HubSync’s</p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="//hubsync.com/privacy-policy">Privacy Policy</a>
          <span> and </span>
          <a target="_blank" rel="noopener noreferrer" href="//hubsync.com/terms-of-use">Terms of Use</a>
        </p>
      </div>
    </>
  );
};

export default ResetPassword;
