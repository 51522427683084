import { DispatchProp } from 'react-redux';
import { AnyAction } from 'redux';
import { Actions } from 'data/assureSign/assureSign.actions';
import { openLockPermissionsModal } from 'data/modals/actions';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import { CommonNode } from 'types/response';

import { FieldEditorProps, FieldRendererProps } from '..';
import { ButtonMetadata } from '../getFieldMetadata';


export interface RendererDispatchProps {
  preCancelEnvelope: typeof Actions.preCancelEnvelope;
  preResendEnvelope: typeof Actions.preResendEnvelope;
  updateWorkspaces: typeof fetchCollectionRequest;
  openLockPermissionsModal: typeof openLockPermissionsModal;
  dispatch: DispatchProp<AnyAction>;
}

export interface EditorStateProps {
  workspacePermissions: string[];
}

export interface EditorDispatchProps {
  preCancelEnvelope: typeof Actions.preCancelEnvelope;
  preResendEnvelope: typeof Actions.preResendEnvelope;
}

export type ButtonRendererFieldProps = FieldRendererProps<CommonNode> & ButtonMetadata & RendererDispatchProps & EditorStateProps;
export type ButtonEditorFieldProps = FieldEditorProps & ButtonMetadata & EditorDispatchProps;

export enum ButtonFieldActions {
  OPEN_URL = 'open_url',
  CANCEL_ENVELOPE = 'cancel_envelope',
  RESEND_ENVELOPE = 'resend_envelope',
  CREATE_WORKSPACE = 'workspace_create',
  CREATE_ENGAGEMENT_LETTER = 'engagement_letter_create',
  CREATE_TAX_ORGINIZER = 'tax_organizer_create'
}

export enum ProcessType {
  SUCCESS = 'successed',
  FAIL = 'failed',
}

export type DictionaryActions = { [key in ButtonFieldActions]?: (data: ButtonMetadata) => void };
