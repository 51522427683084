import styled from 'styled-components';
import { hubsyncBlue, selago, white } from 'styles/colors';
import { ReactComponent as ArrowSvg } from 'icons/arrow-sort.svg';
import { DropdownWrapper as DefaultDropdownWrapper } from '../../TableHeaderTools.style';

export const DropdownWrapper = styled(DefaultDropdownWrapper)`
  min-width: 400px;
`;

export const SortSwitchWrapper = styled.div`
  display: inline-flex;
  background-color: ${selago};
  border-radius: 2px;
`;

export const SortSwitchOption = styled<{ active?: boolean }, 'div'>('div')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 2px;
  padding: 5px 16px;
  border-radius: 1px;
  background-color: ${({ active }) => active ? hubsyncBlue : 'transparent'};
  ${({ active }) => active ? `color: ${white};` : ''}
`;

export const ArrowIcon = styled(ArrowSvg)`
  height: 6px;
  margin: 0 4px;
`;
