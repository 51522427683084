import * as React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import RouteErrorBoundary from 'components/Route/RouteErrorBoundary';

/**
 * Route component wraps component|render into error boundary to prevent app crashes
 */
export default class SafeRoute extends React.Component<RouteProps> {
  render() {
    const { component, render, ...props } = this.props;

    return (
      <Route {...props} render={this.renderChildren}/>
    );
  }

  private renderChildren = (routeProps: RouteComponentProps<any>) => {
    const { component: InnerComponent, render } = this.props;
    let content: React.ReactNode = null;

    if (InnerComponent) {
      content = <InnerComponent {...routeProps}/>;
    } else if (render) {
      content = render(routeProps);
    }

    return (
      <RouteErrorBoundary url={routeProps.match.url}>
        {content}
      </RouteErrorBoundary>
    );
  };
}
