import * as React from 'react';

import Popper from '@material-ui/core/Popper';
import {
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  GetContextMenuItemsParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import AddColumnHeaderModal from 'components/AddColumnHeader/AddColumnHeaderModal';
import ColumnSummaryGridApi from 'components/AgGrid/ColumnSummaryGridApi';
import { createIconWrapper } from 'components/CellRenderers/account/AccountRenderer';
import IdRenderer from 'components/CellRenderers/id/react/IdRenderer';
import { ROW_NUMBER_ID } from 'components/DataGrid/columns/constants';
import { ShowEditorsPopup, Wrapper } from 'components/DataGrid/DataGrid.style';
import { AG_GRID_STATES } from 'components/DataGrid/enums/agGridStates';
import {
  getCellContextMenuItems,
  getDocumentsCellContextMenu,
} from 'components/DataGrid/getCellContextMenu';
import getColumnMenuItems from 'components/DataGrid/getColumnMenuItems';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { setAttachmentStatus } from 'data/attachments/actions';
import { AttachmentsState } from 'data/attachments/reducer';
import { deleteBookmark, saveBookmark } from 'data/bookmarks/actions';
import { getBookmarkByUri } from 'data/bookmarks/bookmarks.selectors';
import * as fromActions from 'data/collections/collections.actions';
import { Actions } from 'data/collections/collections.actions';
import {
  setRowIndexMap,
  UpdateNodeRequestPayload,
  updatePrimaryField,
  UpdatePrimaryFieldOptions,
  updateRecords,
  UpdateRecords,
  updateItems,
  UpdateItemsPayload,
  redoItem,
  undoItem,
  removeFieldRevisions,
  LockColumnPayload,
  lockColumn,
} from 'data/collections/collections.actions.new';
import {
  CreateNodeRequestPayload,
  DuplicateNodeRequestPayload,
  GridColumnState,
  ItemHistory,
  UpdateFieldPayload,
  ViewConfig,
} from 'data/collections/collections.reducer';
import {
  getCurrentViewConfig,
  rowIndexMap,
  nodesInCreation,
} from 'data/collections/collections.selectors';
import { columnDefsSelector } from 'data/collections/columndefs.selectors';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { customRowOrder } from 'data/collections/view-config/viewConfig.selectors';
import {
  actions as gridEditorsActions,
  SendMessageInput,
} from 'data/grid-editors/index';
import * as filterActions from 'data/grid-options/filterModel.actions';
import { resetFilterModel } from 'data/grid-options/filterModel.actions';
import {
  quickFilters,
  filtersSelector,
} from 'data/grid-options/gridOptions.selector';
import {
  hideGroupPanel,
  showGroupPanel,
} from 'data/grid-options/groupBy/groupBy.actions';
import { rowGroupPanelShow } from 'data/grid-options/groupBy/groupBy.selectors';
import { setSelectedRowCount } from 'data/grid-options/selectedRow.actions';
import {
  Actions as sortActions,
  resetSortModel,
} from 'data/grid-options/sortModel.actions';
import LoadingState from 'data/LoadingState';
import { openLockPermissionsModal, openNodeModal } from 'data/modals/actions';
import * as ModalActions from 'data/modals/actions';
import {
  State as RemindersState,
  actions as remindersActions,
} from 'data/reminders/reminders';
import {
  actions as workspacesActions,
} from 'data/workspaces/workspaces.actions';

import { downloadFiles } from 'data/ui/fileDownload/fileDownload.actions';
import { copyAttachments } from 'data/ui/fileUpload/fileUpload.actions';
import { commitSelection } from 'data/ui/gridSelection/gridSelection.actions';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { patchView } from 'data/views/views.actions';
import { selectedView } from 'data/views/views.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { getTenantConfig } from 'env';
import * as keys from 'keycode-js';
import * as _ from 'lodash/fp';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import {
  FilterModel,
  SortModel,
  SortModelState,
  SortOperator,
} from 'types/gridOptions';
import { Fields } from 'types/response';
import { DocumentNode } from 'types/response/documentNode';
import { FieldType } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import {
  Bookmark,
  Breadcrumb,
  CollectionTypes,
  Column,
  Schema,
} from 'types/schema';
import {
  createPartialNodeFromDefaultSchemaValues,
  getUserDefinedFieldKey,
} from 'utilities/collections';
import { stripDomain, createModalUrl } from 'utilities/createUrl';
import { normalizeURL } from 'utilities/format';
import { isDB } from 'utilities/parseURI';

import { getAccounts } from '../../data/accounts/state';
import { changeUserModifyFieldModalPreference } from '../../data/users/users.actions';
import { User, UserSettings } from '../../data/users/users.types';
import { hubsyncBlue } from '../../styles/colors';
import { GRID_ROW_HEIGHT } from '../../styles/constants';
import { CommonNode } from '../../types/response';
import { AccountNode } from '../../types/response/accountNode';
import { parseURL } from '../../utilities/queryParams';
import AgGrid from '../AgGrid';
import AgGridApi, {
  AgGridFilterModel,
  FocusedCell,
  RowNode,
  GridContext,
} from '../AgGrid/AgGridApi';
import createFieldPayload from '../Fields/createFieldPayload';
import { FieldData } from '../Fields/data';
import ModifyField from '../Fields/ModifyField';
import Confirm from '../Modals/Confirm';
import Modal from '../Modals/Modal';
import BulkUpdate from './items/BulkUpdate';
import MoveToPosition from './items/MoveToPosition';
import RenameColumn from './RenameColumn';
import loadingAction from 'data/ui/loading/actions';

const tenantConfig = getTenantConfig() as any;

const createWrapperClassName = (
  rowGroupPanelShow: boolean,
  gridScrolled: boolean,
  hasScrollbars: boolean,
  rowHeight: number,
) => {
  const rowHeightClassname =
    rowHeight === GRID_ROW_HEIGHT.BASE
      ? 'ag-grid-base-row-height'
      : rowHeight === GRID_ROW_HEIGHT.MEDIUM
        ? 'ag-grid-medium-row-height'
        : 'ag-grid-tall-row-height';

  return `grid ag-theme-material ${rowHeightClassname} ${
    isDB(document.location.pathname) ? 'paddingBottom' : ''
  } row-group-panel-visible-${rowGroupPanelShow} grid-is-${
    gridScrolled ? '' : 'not-'
  }scrolled ${hasScrollbars ? 'has-scrollbars' : ''}`;
};

type MergedProps = URLInjectedProps & {
  createSelectionProps: (payload: any) => Partial<AgGridReactProps>;
};

interface OwnProps<Node extends CommonNode> {
  rowHeight: number;
  schema: Schema;
  collectionType: CollectionTypes;
  nodes: Node[];
  columnsState: GridColumnState[];
  onGridReady: (agGridApi?: AgGridApi<Node>) => void;
  filterModel: FilterModel;
  sortModel: SortModelState;
  onColumnsChange: (payload: GridColumnState[]) => void;
  onFilterMenuClick: (columnId: string) => void;
  onOpenNodeModal: (node: CommonNode) => void;
  createNode: (payload: CreateNodeRequestPayload) => void;
  duplicateNode: (payload: DuplicateNodeRequestPayload) => void;
  onUpdateNode: (payload: UpdateNodeRequestPayload) => void;
  rowSelectionEnabled?: boolean;
  nodeLoadingState: LoadingState;
  itemHistory: ItemHistory;
}

interface DispatchProps {
  commitSelection: (payload: any) => void;
  downloadFiles: (payload: DocumentNode[]) => void;
  saveBookmark: typeof saveBookmark;
  deleteBookmark: typeof deleteBookmark;
  openRenameDocumentModal: typeof ModalActions.openRenameDocumentModal;
  openMoveCopyDocumentModal: typeof ModalActions.openMoveCopyDocumentModal;
  duplicateField: (fieldId: string) => void;
  openDeleteRowsModal: (
    rowIds: string[],
    collectionType: CollectionTypes,
    agGridApi?: AgGridApi<CommonNode>
  ) => void;
  openDeleteFolderModal: (breadcrumb: Breadcrumb) => void;
  openDeleteColumnModal: (columnId: string) => void;
  setSearchFilters: (payload: AgGridFilterModel) => void;
  setSortModel: (payload: SortModel[]) => void;
  updateField: (data: UpdateFieldPayload) => void;
  setRowIndexMap: typeof setRowIndexMap;
  setCustomRowPosition: typeof viewConfigActions.setCustomRowPosition;
  setCustomRowOrder: typeof viewConfigActions.setCustomRowOrder;
  deleteCustomRowPosition: typeof viewConfigActions.deleteCustomRowPosition;
  updateRecords: (options: UpdateRecords) => void;
  displayTotalRowCount?: boolean;
  showGroupPanel: () => void;
  hideGroupPanel: () => void;
  updatePrimaryField: (options: UpdatePrimaryFieldOptions) => void;
  lockColumn: (payload: LockColumnPayload) => void;
  setSelectedRowCount: (count: number) => void;
  openNodeModal(
    node: Partial<CommonNode>,
    windowTitle?: string,
    enableSwitcher?: boolean
  ): void;
  resetSortModel: () => void;
  resetFilterModel: () => void;
  updateItems: (payload: UpdateItemsPayload) => void;
  bulkChangeReminders: (payload) => void;
  bulkCreateWorkspaces: typeof workspacesActions.createMultipleWorkspaces.request;
  copyAttachments: typeof copyAttachments;
  editorPositionInGridChanged: typeof gridEditorsActions.request;
  cellEditingStopped: typeof gridEditorsActions.cellEditingStopped;
  setAttachmentStatus: typeof setAttachmentStatus;
  redoItem: () => void;
  undoItem: () => void;
  patchViewRequest: typeof patchView.request;
  removeFieldRevisions: (fieldId: string) => void;
  openCancelEnvelopeModal: (envelopeId: string) => void;
  openResendEnvelopeModal: (envelopeId: string, signerEmail: string) => void;
  changeUserModifyFieldModalPreference: (settings: UserSettings) => void;
  openLockPermissionsModal: typeof openLockPermissionsModal;
  warningToast: typeof notifications.warn;
  startLockingLoader: () => void;
  endLockingLoader: () => void;
}

interface StateProps {
  permissions: string[];
  rowGroupPanelShow: boolean;
  quickFilters: boolean;
  quickSearch?: string;
  view: ViewNode;
  getBookmarkById: (bookmarkURI: string) => Bookmark | undefined;
  columnDefs: ColDef[];
  customRowOrder: Record<string, number>;
  selectedRowCount: number;
  quickFiltersBarEnabled: boolean;
  currentViewConfig: ViewConfig;
  rowIndexMap: Record<string, number>;
  reminders: RemindersState;
  currentSheetId: string;
  users: AccountNode[];
  user: User;
  attachments: AttachmentsState;
  selectedViewId: string;
  nodesInCreation: string[];
  gridEditors: any;
  nodesPatchProgress: any;
  lastUpdatedItem: {
    id: string;
    version: number;
  };
  itemsReady?: boolean;
}

type Props<Node extends CommonNode> = OwnProps<Node> &
  DispatchProps &
  StateProps &
  MergedProps;

interface State {
  showAddColumnHeaderModal?: boolean;
  columnHeaderModalField?: Column;
  columnHeaderModalAnchor: HTMLElement | null;
  columnIndex: number;
  newColumnIndex?: number;
  showModifyFieldType: boolean;
  HIDE_DEMO: boolean;
  showBulkEdit: boolean;
  showBulkWorkspaceCreation: boolean;
  selectedRows: RowNode<CommonNode>[];
  gridScrolled: boolean;
  hasScrollbars: boolean;
  showMoveToPosition: boolean;
  focusedCellWithEditors: HTMLElement | null;
  focusedCellId: string;
  cellActiveEditors: string[];
  agGridState: string;
  modalTitle: string;
  cellEditingVersion: number;
}

enum NewColumnPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export class DataGrid<Node extends CommonNode> extends React.PureComponent<
  Props<Node>,
  State
> {
  static defaultProps = {
    createSelectionProps: () => defaultSelectionProps,
  };

  private containerRef: HTMLDivElement | null;

  constructor(props: Props<Node>) {
    super(props);

    this.state = {
      columnHeaderModalAnchor: null,
      newColumnIndex: undefined,
      showModifyFieldType: false,
      columnIndex: 0,
      HIDE_DEMO: true,
      showBulkEdit: false,
      showBulkWorkspaceCreation: false,
      selectedRows: [],
      gridScrolled: false,
      hasScrollbars: false,
      showMoveToPosition: false,
      focusedCellWithEditors: null,
      cellActiveEditors: [],
      agGridState: AG_GRID_STATES.BROWSE,
      modalTitle: '',
      cellEditingVersion: 1,
      focusedCellId: '',
    };

    this.containerRef = null;
    this.onCustomRowOrderChange = this.onCustomRowOrderChange.bind(this);
    this.mouseLeaveCellWithActiveEditors = this.mouseLeaveCellWithActiveEditors.bind(
      this,
    );
    this.mouseOverCellWithActiveEditors = this.mouseOverCellWithActiveEditors.bind(
      this,
    );
    this.refreshGridCellNode = this.refreshGridCellNode.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  rowClassRules = {
    invalid: (params) => params.data && params.data.isValid === false,
  };

  private static agGridApi?: AgGridApi<any | CommonNode>;
  private columnSummaryGridApi?: ColumnSummaryGridApi;

  public componentDidMount(): void {
    this.handleGroupBar();
    this.handleCellEditingStarted = this.handleCellEditingStarted.bind(this);

    this.props.endLockingLoader();
  }

  public componentWillUnmount(): void {
    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.removePinnedBottomRow();
    }
    this.props.setSelectedRowCount(0);
  }

  public componentDidUpdate(prevProps: Props<Node>, prevState: State): void {
    if (
      !prevProps.quickFiltersBarEnabled &&
      this.props.quickFiltersBarEnabled &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi.filter(this.props.filterModel);
    }
    if (
      (!_.isEqual(
        _.cloneDeep(prevProps.columnsState).map((c) => {
          delete c.colId;
          delete c.width;
          return c;
        }),
        _.cloneDeep(this.props.columnsState).map((c) => {
          delete c.colId;
          delete c.width;
          return c;
        }),
      ) ||
        prevProps.selectedViewId !== this.props.selectedViewId) &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi.destroyAllActiveCharts();
      // this.columnSummaryGridApi?.gridApi.setColumnDefs(mapColumns(this.props.columnsState));
      DataGrid.agGridApi.setColumnState(this.props.columnsState);
    }

    if (
      this.props.collectionType === CollectionTypes.documents &&
      DataGrid.agGridApi &&
      _.isEqual(this.props.filterModel, prevProps.filterModel)
    ) {
      if (
        (this.props.nodes.length !== prevProps.nodes.length ||
          prevProps.nodeLoadingState !== this.props.nodeLoadingState) &&
        this.props.nodeLoadingState === LoadingState.Loaded
      ) {
        this.autoSizeColumns();
      }
    }

    if (
      !_.isEqual(this.props.reminders, prevProps.reminders) &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi.gridApiInstance().refreshCells({ force: true });
    }

    if (
      !_.isEqual(this.props.quickSearch, prevProps.quickSearch) &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi?.gridApiInstance().refreshCells({ force: true });
    }

    if (
      !_.isEqual(
        this.props.attachments.byItemId,
        prevProps.attachments.byItemId,
      ) &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi?.gridApiInstance().refreshCells({ force: true });
      this.setState({ cellEditingVersion: this.state.cellEditingVersion + 1 });
    }

    // hard do identify gird node changes must compare them with length and refresh number row id
    if (
      !_.isEqual(this.props.nodes.length, prevProps.nodes.length) &&
      DataGrid.agGridApi
    ) {
      DataGrid.agGridApi && DataGrid.agGridApi.refreshCells([ROW_NUMBER_ID]);
    }

    if (DataGrid.agGridApi && DataGrid.agGridApi.isGroupMode()) {
      if (!_.isEqual(this.props.nodesInCreation, prevProps.nodesInCreation)) {
        this.props.setRowIndexMap(DataGrid.agGridApi.getGroupingRowIndexMap());
      }
    }

    if (DataGrid.agGridApi) {
      if (!_.isEqual(this.props.filterModel, prevProps.filterModel)) {
        setTimeout(() => {
          DataGrid.agGridApi &&
            DataGrid.agGridApi.refreshCells([ROW_NUMBER_ID]);
        }, 50);
      }
    }

    if (DataGrid.agGridApi) {
      if (
        !_.isEqual(
          this.props.attachments.status,
          prevProps.attachments.status,
        ) &&
        this.props.attachments.status
      ) {
        if (
          ['delete_success', 'upload_success'].includes(
            this.props.attachments.status,
          )
        ) {
          this.refreshGridCellNode(this.props.attachments.rowId);
          this.props.setAttachmentStatus({ status: '', rowId: null });
        }
      }
    }

    if (DataGrid.agGridApi) {
      const prevEditorsPositions = prevProps.gridEditors.editorsPositions;
      const editorsPositions = this.props.gridEditors.editorsPositions;
      if (
        !_.isEqual(prevEditorsPositions, editorsPositions) ||
        prevState.cellEditingVersion !== this.state.cellEditingVersion
      ) {
        this.handleExistingMarks();
        this.addNewMarks();
        this.handleTooltip();
      }

      if (
        DataGrid.agGridApi &&
        prevProps.nodesPatchProgress &&
        Object.keys(prevProps.nodesPatchProgress).length
      ) {
        if (
          !_.isEqual(
            this.props.nodesPatchProgress,
            prevProps.nodesPatchProgress,
          )
        ) {
          Object.keys(prevProps.nodesPatchProgress).forEach((key: string) => {
            const row = prevProps.nodesPatchProgress[key];
            Object.keys(row).forEach((col) => {
              const prevCellProgress = row[col];
              const cellProgress = _.get(
                this.props.nodesPatchProgress,
                `${key}.${col}`,
              );
              if (!_.isEqual(prevCellProgress, cellProgress)) {
                const currentNode = DataGrid.agGridApi
                  ?.gridApiInstance()
                  .getRowNode(key.replace('row-', ''));
                if (currentNode) {
                  DataGrid.agGridApi?.gridApiInstance().refreshCells({
                    rowNodes: [currentNode],
                    columns: [`fields.${col.replace('col-', '')}`],
                    force: true,
                  });
                }
              }
            });
          });
          if (DataGrid.agGridApi?.isGroupMode()) {
            DataGrid.agGridApi?.gridApiInstance().refreshCells({
              force: true,
            });
          }
        }
      }

      if (
        DataGrid.agGridApi &&
        !_.isEqual(this.props.nodesInCreation, prevProps.nodesInCreation)
      ) {
        if (this.props.nodesInCreation.length) {
          DataGrid.agGridApi?.gridApiInstance().showLoadingOverlay();
        } else {
          DataGrid.agGridApi?.gridApiInstance().hideOverlay();
        }
      }
    }
  }

  private handleExistingMarks = () => {
    const existingMarks = document.querySelectorAll('.user-editor-mark');
    [].forEach.call(existingMarks, (element: HTMLDivElement) => {
      if (element && element.parentElement) {
        element.parentElement.style.border = '';
        element.classList.remove('user-editor-mark');
      }
    });

    const existingIcons = document.querySelectorAll('.user-editor-mark-icon');
    [].forEach.call(existingIcons, (element: HTMLDivElement) => {
      element.remove();
    });
  };

  private addNewMarks = () => {
    const cells = this.props.gridEditors.gridCellIndexUserEditorsMap;
    Object.keys(cells).forEach((key) => {
      const element = document.getElementById(key);
      if (element) {
        element.classList.add('user-editor-mark');
        const editors = cells[key].editors;
        const editor = editors[Object.keys(editors)[0]];
        const color = editor.color || hubsyncBlue;
        // @ts-ignore
        element.parentElement.style.border = '1px solid ' + color;
        const icon = createIconWrapper(color);
        icon.classList.add('user-editor-mark-icon');
        const id = `user-editor-mark${editor.userId}`;
        icon.id = id;
        const oldChild = document.getElementById(id);
        if (oldChild) {
          oldChild.remove();
          if (oldChild.parentElement) {
            oldChild.parentElement.style.border = '1px solid ' + color;
          }
        }
        element.parentElement?.append(icon);
        icon?.removeEventListener(
          'mouseenter',
          this.mouseLeaveCellWithActiveEditors
        );
        icon?.removeEventListener(
          'mouseleave',
          this.mouseOverCellWithActiveEditors
        );
        icon?.addEventListener(
          'mouseenter',
          this.mouseOverCellWithActiveEditors
        );
        icon?.addEventListener(
          'mouseleave',
          this.mouseLeaveCellWithActiveEditors
        );
      }
    });
  };

  private handleTooltip = () => {
    if (this.state.focusedCellId) {
      const field = document.getElementById(this.state.focusedCellId);
      if (field) {
        const mark = field.querySelector('.user-editor-mark-icon');
        if (!mark) {
          this.mouseLeaveCellWithActiveEditors();
        }
      }
    }
  };

  private mouseLeaveCellWithActiveEditors() {
    this.setState({
      focusedCellWithEditors: null,
      cellActiveEditors: [],
      focusedCellId: '',
    });
  }

  private mouseOverCellWithActiveEditors(e) {
    const cells = this.props.gridEditors.gridCellIndexUserEditorsMap;
    const parent = e.target.closest('.ag-cell');
    const id = parent?.firstElementChild?.id;
    if (!id) return;
    if (!cells[id]) return;
    // @ts-ignore
    const editors = cells[id].editors;
    const names: string[] = [];
    Object.keys(editors).forEach((key) => {
      const editor = editors[key];
      // const target = e.target;
      names.push(editor.userDisplayName);
    });
    if (
      !_.isEqual(this.state.cellActiveEditors, names) &&
      !_.isEqual(id, this.state.focusedCellWithEditors?.firstElementChild?.id)
    ) {
      this.setState({
        focusedCellWithEditors: parent,
        focusedCellId: id,
        cellActiveEditors: names,
      });
    }
  }

  public openModal(node: Partial<CommonNode>) {
    if (node && node.id) {
      const { history, location } = this.props;
      DataGrid.agGridApi?.clearSelection();
      history.push(createModalUrl(location.pathname, node.id || ''));
    }
  }

  public onRangeSelectionChanged(event) {
    if (tenantConfig.enabledFeatures.advancedContextMenu) {
      const selectedRowsCount = event.api.getSelectedNodes();
      if (selectedRowsCount.length > 0) {
        event.api.deselectAll();
      }
    }
  }

  public onCustomRowOrderChange(rowIdIndexMap: Record<string, number>) {
    this.props.setCustomRowOrder(rowIdIndexMap);
    this.props.patchViewRequest({
      customRowOrder: rowIdIndexMap,
      id: this.props.selectedViewId,
    });
  }

  private getCellEditingProps(
    event: CellEditingStartedEvent | CellEditingStoppedEvent,
  ): SendMessageInput {
    const {
      [CollectionTypes.workspaces]: workspaceId,
      [CollectionTypes.databases]: databaseId,
      [CollectionTypes.taskdbs]: taskDatabaseId,
    } = parseURL(window.location.pathname);
    return {
      itemId: parseInt(event.data.id, 10),
      columnKey: event.column.getColId(),
      references: {
        workspaceID: workspaceId,
        databaseID: databaseId,
        taskDatabaseID: taskDatabaseId,
        sheetID: this.props.currentSheetId,
        viewID: this.props.selectedViewId,
      },
    };
  }

  private handleCellEditingStarted(event: CellEditingStartedEvent): void {
    if (this.props.permissions.includes('items:update')) {
      this.setState({ cellEditingVersion: this.state.cellEditingVersion + 1 });
      this.props.editorPositionInGridChanged(this.getCellEditingProps(event));
    }
  }

  private handleCellEditingStopped = (event: CellEditingStoppedEvent): void => {
    if (this.props.permissions.includes('items:update')) {
      this.props.cellEditingStopped(this.getCellEditingProps(event));
      this.setState({ cellEditingVersion: this.state.cellEditingVersion + 1 });
    }
  };

  public render(): JSX.Element {
    const {
      permissions,
      rowHeight,
      quickFilters,
      rowGroupPanelShow,
      rowSelectionEnabled,
      view,
    } = this.props;

    const {
      columnHeaderModalAnchor,
      columnHeaderModalField,
      newColumnIndex,
      showAddColumnHeaderModal,
    } = this.state;

    const selectionProps = this.props.createSelectionProps(this);
    const gridContext: GridContext = {
      currentViewIsLocked: view.isLocked,
    };
    const mainMenuItems = getColumnMenuItems(
      {
        makeFieldPrimary: (columnId) => this.makeFieldPrimary(columnId),
        modifyFieldType: (columnId) => this.openModifyFieldType(columnId),
        deleteColumn: (columnId) =>
          this.props.openDeleteColumnModal(getUserDefinedFieldKey(columnId)),
        sortAsc: (columnId) =>
          this.props.setSortModel(
            [{ colId: columnId, sort: SortOperator.Asc }].concat(
              this.getFilteredSortModel(columnId),
            ),
          ),
        sortDesc: (columnId) =>
          this.props.setSortModel(
            [{ colId: columnId, sort: SortOperator.Desc }].concat(
              this.getFilteredSortModel(columnId),
            ),
          ),
        openFilterMenu: (columnId) => this.props.onFilterMenuClick(columnId),
        renameColumn: (columnId) => this.openRenameColumnHeaderModal(columnId),
        insertColumnRight: (columnId) =>
          this.openAddColumnHeaderModal(columnId, NewColumnPosition.RIGHT),
        insertColumnLeft: (columnId) =>
          this.openAddColumnHeaderModal(columnId, NewColumnPosition.LEFT),
        duplicate: (colId) =>
          this.props.duplicateField(getUserDefinedFieldKey(colId)),
        toggleLockColumn: (columnId) => this.toggleLockColumn(columnId),
      },
      permissions,
    );

    const agGridKey = permissions.join(',');

    if (this.state.HIDE_DEMO) {
      this.setState({ HIDE_DEMO: false });
      const timer = setTimeout( () => {
        this.props.startLockingLoader();
        clearTimeout(timer);
      }, 100);
    }

    let columnDefs = this.props.columnDefs;
    if (!quickFilters) {
      columnDefs = this.props.columnDefs.map((colDef) => ({
        ...colDef,
        filter: quickFilters,
      }));
    }
    const visibleColumnIds = DataGrid.agGridApi?.getAllVisibleColumnsIds();
    return (
      <Wrapper
        innerRef={this.setContainerRef}
        onKeyDown={this.handleKeyDown}
        className={createWrapperClassName(
          rowGroupPanelShow,
          this.state.gridScrolled,
          this.state.hasScrollbars,
          this.props.rowHeight,
        )}
        role="presentation"
      >
        <AgGrid
          key={agGridKey}
          rowIndexMap={this.props.rowIndexMap}
          viewId={this.props.selectedViewId}
          schema={this.props.schema}
          nodes={this.props.nodes}
          onNewColumnsLoaded={this.handleNewColumnsLoaded}
          columnDefs={columnDefs}
          columnsState={this.props.columnsState}
          getContextMenuItems={this.getContextMenuItems}
          rowHeight={rowHeight}
          rowClassRules={this.rowClassRules}
          rowSelectionEnabled={rowSelectionEnabled}
          onGridReady={this.onGridReady}
          itemsReady={this.props.itemsReady}
          columnSummaryGridOnReady={this.columnSummaryGridOnReady}
          onFilterChanged={this.handleFilterChanged}
          onSortChanged={this.handleSortChanged}
          onColumnsChange={this.props.onColumnsChange}
          getMainMenuItems={mainMenuItems}
          floatingFilter={quickFilters}
          agGridProps={selectionProps}
          filterModel={this.props.filterModel}
          openNodeModal={this.openModal}
          openLockPermissionsModal={this.props.openLockPermissionsModal}
          sortModel={this.props.sortModel}
          onUpdateNode={(node) => {
            this.props.onUpdateNode(node);
            setTimeout(() => {
              DataGrid.agGridApi?.refreshCells([ROW_NUMBER_ID]);
            }, 200);
          }}
          setRowIndexMap={this.props.setRowIndexMap}
          customRowOrder={this.props.customRowOrder}
          setCustomRowPosition={this.props.setCustomRowPosition}
          deleteCustomRowPosition={this.props.deleteCustomRowPosition}
          displayTotalRowCount={this.props.displayTotalRowCount}
          setSelectedRowCount={this.props.setSelectedRowCount}
          selectedRowCount={this.props.selectedRowCount}
          // suppressRowTransform={this.props.permissions.includes('items:create')}
          chart={this.props.view.chart}
          onRangeSelectionChanged={this.onRangeSelectionChanged}
          resetFilterAndSort={this.resetFilterAndSort}
          lastUpdatedItem={this.props.lastUpdatedItem}
          itemHistory={this.props.itemHistory}
          redoItem={this.props.redoItem}
          undoItem={this.props.undoItem}
          permissions={this.props.permissions}
          context={gridContext}
          // Cell copy
          processCellForClipboard={(params) => {
            if (
              params.column.getColDef().refData?.fieldType ===
                FieldType.Attachment &&
              params.node
            ) {
              const rowId = params.node.data.id;
              if (this.props.attachments.byItemId[rowId]) {
                const attachmentsById = this.props.attachments.byItemId[rowId]
                  .byId;
                const attachments: string[] = [];
                Object.keys(attachmentsById).forEach((key) => {
                  if (
                    attachmentsById[key].references.fieldID ===
                    params.column.getColId()?.replace('fields.', '')
                  ) {
                    attachments.push(key);
                  }
                });

                const data = {
                  columnType: params.column.getColDef()?.refData?.fieldType,
                  type: FieldType.Attachment,
                  files: attachments.length ? attachments : null,
                  source: params.node.data.id,
                };
                return JSON.stringify(data);
              }
            } else {
              return JSON.stringify({
                columnType: params.column.getColDef()?.refData?.fieldType,
                value: params.value,
              });
            }
          }}
          // Cell paste
          processCellFromClipboard={(params) => {
            const clipboardData = JSON.parse(params.value);
            if (
              params.column.getColDef().refData?.fieldType !==
              clipboardData.columnType
            ) {
              return;
            }

            if (
              params.column.getColDef().refData?.fieldType ===
                FieldType.Attachment &&
              params.node
            ) {
              try {
                if (
                  clipboardData.type === FieldType.Attachment &&
                  clipboardData.files
                ) {
                  this.props.copyAttachments({
                    source: clipboardData.source,
                    rowNode: params.node,
                    colId: params.column.getColId(),
                    attachedIds: clipboardData.files,
                    onComplete: this.refreshGridCellNode,
                  });
                }
              } catch (e) {
                // pass
              }
            } else {
              return clipboardData.value;
            }
          }}
          agGridScroll={(event) => {
            if (event.top > 0 !== this.state.gridScrolled) {
              let hasScrollbars = false;
              const scrollbars = document.getElementsByClassName(
                'ag-body-horizontal-scroll',
              );
              if (scrollbars && scrollbars[0]) {
                hasScrollbars =
                  document.getElementsByClassName(
                    'ag-body-horizontal-scroll',
                  )[0]['offsetHeight'] > 0;
              }

              this.setState({
                gridScrolled: event.top > 0,
                hasScrollbars: hasScrollbars,
              });
            }
          }}
          onCustomRowOrderChange={this.onCustomRowOrderChange}
          handleCellEditingStarted={this.handleCellEditingStarted}
          handleCellEditingStopped={this.handleCellEditingStopped}
        />
        {!this.state.showModifyFieldType && showAddColumnHeaderModal && (
          <AddColumnHeaderModal
            anchorEl={columnHeaderModalAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClosePopper={this.closeColumnHeaderModal}
            columnIndexInView={newColumnIndex}
          />
        )}
        {!this.state.showModifyFieldType && columnHeaderModalField && (
          <RenameColumn
            anchorEl={columnHeaderModalAnchor}
            onClosePopper={this.closeColumnHeaderModal}
            field={columnHeaderModalField}
          />
        )}

        {this.state.showModifyFieldType && (
          <ModifyField
            field={columnHeaderModalField}
            anchorEl={columnHeaderModalAnchor}
            onClose={this.closeModifyFieldType}
            onSave={this.onUpdateAttributes}
            rows={this.props.nodes}
            updateItems={this.props.updateItems}
            users={this.props.users}
            user={this.props.user}
            removeFieldRevisions={this.props.removeFieldRevisions}
            changeUserModifyFieldModalPreference={
              this.props.changeUserModifyFieldModalPreference
            }
          />
        )}

        {this.state.showBulkEdit && (
          <BulkUpdate
            schema={this.props.schema}
            onClose={this.closeBulkEdit}
            selectedRows={this.state.selectedRows}
            fieldIds={visibleColumnIds || []}
            updateRecords={this.props.updateRecords}
            reminders={this.props.reminders}
            currentSheetId={this.props.currentSheetId}
            bulkChangeReminders={this.props.bulkChangeReminders}
            bulkCreateWorkspaces={this.props.bulkCreateWorkspaces}
            permissions={this.props.permissions}
          />
        )}
        {this.state.focusedCellWithEditors && (
          <Popper
            id={'editorsgrid_live'}
            open={!!this.state.focusedCellWithEditors}
            anchorEl={this.state.focusedCellWithEditors}
          >
            <ShowEditorsPopup>
              {this.state.cellActiveEditors.map((editor, index) => (
                <React.Fragment key={index}>
                  <span>{editor}</span>
                  <br />
                </React.Fragment>
              ))}
            </ShowEditorsPopup>
          </Popper>
        )}

        {this.state.showMoveToPosition && (
          <MoveToPosition
            onClose={this.closeMoveToPosition}
            moveRowsToPosition={this.moveRowsToPosition}
          />
        )}
        <IdRenderer api={DataGrid.agGridApi} />
        {(this.state.agGridState === AG_GRID_STATES.DRAG_STARTED ||
          this.state.agGridState === AG_GRID_STATES.NEW_RECORD_STARTED ||
          this.state.agGridState === AG_GRID_STATES.DUPLICATE_STARTED) && (
          <Modal onClose={this.onCloseModal}>
            <Confirm
              title={this.state.modalTitle}
              close={this.onCloseModal}
              onConfirm={this.onConfirm}
              confirmLabel="Remove"
            />
          </Modal>
        )}
      </Wrapper>
    );
  }

  private refreshGridCellNode(id?: string | null) {
    if (!DataGrid.agGridApi || !id) return;
    const currentNode = DataGrid.agGridApi.gridApiInstance().getRowNode(id);
    if (currentNode) {
      DataGrid.agGridApi.gridApiInstance().refreshCells({
        rowNodes: [currentNode],
        force: true,
      });
    }
  }

  private resetFilterAndSort = () => {
    this.props.resetSortModel();
    const keys = {};
    Object.keys(this.props.filterModel.searchFilters).forEach((key) => {
      keys[key] = ' ';
    });

    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.setFilterModel(keys);
    }
    setTimeout(() => {
      this.props.resetFilterModel();
    }, 0);
  };

  private makeFieldPrimary = (fieldId: string): void => {
    const existingField = this.props.columnDefs.find(
      (column) => column.refData && column.refData.isPrimary,
    );
    const existingFieldId =
      (existingField &&
        existingField.field &&
        existingField.field.replace('fields.', '')) ||
      '';
    this.props.updatePrimaryField({
      newFieldId: fieldId.replace('fields.', ''),
      existingFieldId,
    });
  };

  private toggleLockColumn = (columnId: string): void => {
    const columnData = this.props.columnDefs.find(
      (column) => column.colId === columnId,
    );
    const columnRef = columnData?.refData;
    const isLock = columnRef?.lock ?? false;

    this.props.lockColumn({ lock: !isLock, fieldId: columnRef?._id ?? '' });
  };

  private handleGroupBar = (): void => {
    if (this.props.collectionType === CollectionTypes.documents) {
      this.props.hideGroupPanel();
      return;
    }
    this.props.showGroupPanel();
  };

  private autoSizeColumns = (): void => {
    const collectionTypes = [
      CollectionTypes.documents,
      CollectionTypes.accounts,
    ];
    if (!collectionTypes.includes(this.props.collectionType)) return;
    const columns =
      this.props.collectionType === CollectionTypes.accounts
        ? ['fields.displayName', 'fields.primaryEmail', 'fields.role']
        : ['fileName'];
    setTimeout(() => {
      if (DataGrid.agGridApi) {
        DataGrid.agGridApi.autoSizeColumns(columns);
      }
    }, 100);
  };

  private getFilteredSortModel = (columnId: string) => {
    return Object.values(this.props.sortModel.byId).filter(
      (sortModel) => sortModel.colId !== columnId,
    );
  };

  private getColumnDefById = (columnId: string): ColDef | undefined => {
    return this.props.columnDefs.find(
      (columnDef) => columnDef.field === columnId,
    );
  };

  private getColumnIndexById = (columnId: string): number | undefined => {
    const fieldIDs =
      this.props.currentViewConfig &&
      this.props.currentViewConfig.columns &&
      this.props.currentViewConfig.columns.map((col) => col.colId);
    return fieldIDs ? fieldIDs.findIndex((id) => id === columnId) : undefined;
  };

  private getColumnNode = (columnId: string): Element | null => {
    if (!this.containerRef) {
      return null;
    }

    return this.containerRef.querySelector(`[col-id="${columnId}"]`);
  };

  private openAddColumnHeaderModal = (
    columnId: string,
    newColumnPosition: NewColumnPosition,
  ): void => {
    const columnNode = this.getColumnNode(columnId);

    if (!columnNode) {
      return;
    }

    const columnIndex = this.getColumnIndexById(columnId);

    const newColumnIndex =
      typeof columnIndex === 'number'
        ? getNewColumnIndex(columnIndex, newColumnPosition)
        : undefined;

    this.setState({
      showAddColumnHeaderModal: true,
      columnHeaderModalAnchor: columnNode as HTMLElement,
      newColumnIndex,
    });
  };

  private openRenameColumnHeaderModal = (columnId: string): void => {
    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.clearFocusedCell();
    }

    const columnNode = this.getColumnNode(columnId);

    if (!columnNode) {
      return;
    }

    const columnDef = this.getColumnDefById(columnId);

    if (columnDef) {
      const columnHeaderModalField: Column = {
        ...columnDef.refData, // field data from AgGrid
        id: columnId,
      } as Column;

      this.setState({
        columnHeaderModalAnchor: columnNode as HTMLElement,
        columnHeaderModalField,
      });
    }
  };

  private openModifyFieldType = (columnId: string): void => {
    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.clearFocusedCell();
    }

    const columnNode = this.getColumnNode(columnId);

    if (!columnNode) {
      return;
    }

    const columnIndex = this.getColumnIndexById(columnId);
    const columnDef = this.getColumnDefById(columnId);

    if (columnDef) {
      const columnHeaderModalField: Column = {
        ...columnDef.refData,
        id: columnId,
      } as Column;

      this.setState({
        showModifyFieldType: true,
        columnHeaderModalField,
        columnIndex: columnIndex || 0,
        columnHeaderModalAnchor: columnNode as HTMLElement,
      });
    }
  };

  private onUpdateAttributes = (
    title: string,
    data: FieldData,
    originalFieldData: FieldData,
  ): void => {
    const columnId =
      (this.state.columnHeaderModalField &&
        this.state.columnHeaderModalField.id) ||
      '';
    const payload = createFieldPayload(title, data, this.state.columnIndex);
    // @ts-ignore
    this.props.updateField({ title,
      fieldId: columnId.replace('fields.', ''),
      ...payload,
      typeChanged: data.type !== originalFieldData.type,
      originalFieldType: originalFieldData.type,
    });
  };

  private closeModifyFieldType = (): void => {
    this.setState({
      showModifyFieldType: false,
      columnHeaderModalAnchor: null,
      columnHeaderModalField: undefined,
    });
  };

  private closeColumnHeaderModal = (): void => {
    this.setState({
      showAddColumnHeaderModal: false,
      columnHeaderModalAnchor: null,
      columnHeaderModalField: undefined,
    });
  };

  private setContainerRef = (e: HTMLDivElement) => {
    this.containerRef = e;
  };

  private getContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (!DataGrid.agGridApi || !params.node || params.node.group) {
      return;
    }

    const { collectionType, getBookmarkById } = this.props;
    const isGridSorted = this.props.sortModel.allIds.length > 0;
    const isViewLocked = !!this.props.view.isLocked;
    const isViewFiltered = Object.keys(this.props.filterModel.searchFilters).length > 0;

    const selectedRows = this.getSelectedRows(DataGrid.agGridApi, params.node);
    this.setState({ selectedRows });

    const selectedItems = selectedRows.map(_.get('data')) as any[];
    let items;
    switch (collectionType) {
      case CollectionTypes.documents:
        items = getDocumentsCellContextMenu({
          selectedItems,
          getBookmarkById,
          actions: {
            delete: (selected) => {
              if (selected.length === 1 && selected[0]['isFolder']) {
                return this.props.openDeleteFolderModal({
                  title: selected[0]['fileName'],
                  type: 'node',
                  uri: selected[0]['uri'] + '?folderID=' + selected[0]['id'],
                });
              } else {
                return this.props.openDeleteRowsModal(
                  selected.map((item) => item.id),
                  collectionType,
                  DataGrid.agGridApi,
                );
              }
            },
            download: this.props.downloadFiles,
            favorite: () => this.addToFavorite(selectedItems),
            unFavorite: () => this.removeFromFavorite(selectedItems),
            moveCopy: () =>
              this.props.openMoveCopyDocumentModal(
                selectedItems.map((item) => ({ apiURI: item.apiURI })),
              ),
            rename: () => this.props.openRenameDocumentModal(params.node.data),
            preview: () =>
              this.props.history.push(stripDomain(params.node.data.uri)),
            navigateToHistory: this.navigateToHistory,
          },
          permissions: this.props.permissions,
          showHistoryOption: false,
        });
        break;
      default:
        items = [];
        items = getCellContextMenuItems({
          selectedItems,
          params,
          rows: selectedRows,
          actions: {
            showBulkEdit: this.showBulkEdit,
            insertRecords: this.handleInsertRecords,
            duplicateRecords: this.handleDuplicateRecords,
            deleteRows: (ids: string[]) =>
              this.props.openDeleteRowsModal(
                ids,
                collectionType,
                DataGrid.agGridApi,
              ),
            navigateToHistory: this.navigateToHistory,
            showMoveToPosition: this.showMoveToPosition,
            expandRecord: this.openModal.bind(this),
          },
          permissions: this.props.permissions,
          showHistoryOption: false,
        }, isGridSorted, isViewFiltered, isViewLocked);
    }

    return items;
  };

  private showMoveToPosition = () => {
    const {
      quickSearch,
      regularFilters,
      searchFilters,
    } = this.props.filterModel;
    const isFiltered =
      quickSearch || regularFilters.length || Object.keys(searchFilters).length;
    const isSorted = this.props.sortModel.allIds.length > 0;
    if (isFiltered && isSorted) {
      return this.setState({
        agGridState: AG_GRID_STATES.DRAG_STARTED,
        modalTitle: 'Would you like to remove filters and sorting?',
      });
    }

    if (isSorted) {
      return this.setState({
        agGridState: AG_GRID_STATES.DRAG_STARTED,
        modalTitle: 'Would you like to remove sorting?',
      });
    }

    // if (isFiltered) {
    //   return this.setState({ agGridState: AG_GRID_STATES.DRAG_STARTED, modalTitle: 'Would you like to remove filters?' });
    // }

    this.setState({ showMoveToPosition: true });
  };

  private closeMoveToPosition = () => {
    this.setState({ showMoveToPosition: false });
    DataGrid.agGridApi?.clearSelection();
  };

  private moveRowsToPosition = (position: number) => {
    if (!DataGrid.agGridApi || position < 1) {
      return;
    }

    let desiredPosition = DataGrid.getGridApi()?.getDisplayedRowAtIndex(
      position-1
    )?.rowIndex;
    if (desiredPosition == undefined) {
      desiredPosition = (DataGrid.getGridApi()?.getLastDisplayedRowIndex() || -1) + 1;
    }

    // AgGrid gives us the selected nodes sorted by id, even if they are not displayed in that order
    // in the grid.  Reorder the selected nodes to the order in which they're displayed.
    const displayOrderedNodes = DataGrid.agGridApi.getSelectedNodes().sort((node1, node2) => node1.rowIndex - node2.rowIndex);

    this.props.setCustomRowPosition({
      nodeIds: displayOrderedNodes.map(node => node.id),
      position: desiredPosition,
    });

    setTimeout(() => {
      if (DataGrid.agGridApi && DataGrid.agGridApi.isGroupMode()) {
        this.props.setRowIndexMap(DataGrid.agGridApi.getGroupingRowIndexMap());
      }

      DataGrid.agGridApi?.refreshCells([ROW_NUMBER_ID]);
    }, 100);

    this.closeMoveToPosition();
  };

  private onCloseModal = () => {
    setTimeout(() => {
      if (this.state.agGridState !== AG_GRID_STATES.BROWSE) {
        let message = '';
        switch (this.state.agGridState) {
          case AG_GRID_STATES.DUPLICATE_STARTED:
            message =
              'You cannot duplicate row when sorting/filtering is applied';
            break;
          case AG_GRID_STATES.NEW_RECORD_STARTED:
            message =
              'You cannot create a new row when sorting/filtering is applied';
            break;
          case AG_GRID_STATES.DRAG_STARTED:
            message =
              'You cannot re-order row when sorting/filtering is applied';
            break;
          default:
            message =
              'You cannot perform this action when sorting/filtering is applied';
            break;
        }

        this.props.warningToast({ message });

        this.setState({ agGridState: AG_GRID_STATES.BROWSE });
      }
    }, 0);
  };

  private onConfirm = () => {
    this.setState({ agGridState: AG_GRID_STATES.BROWSE });
    this.resetFilterAndSort();
  };

  private showBulkEdit = (): void => {
    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.clearFocusedCell();
    }

    this.setState({ showBulkEdit: true });
  };

  private closeBulkEdit = (): void => {
    this.setState({ showBulkEdit: false });
    DataGrid.agGridApi?.clearSelection();
  };


  private showBulkWorkspaceCreation = (): void => {
    if (DataGrid.agGridApi) {
      DataGrid.agGridApi.clearFocusedCell();
    }

    this.setState({ showBulkWorkspaceCreation: true });
  };

  private closeBulkWorkspaceCreation = (): void => {
    this.setState({ showBulkWorkspaceCreation: false });
    DataGrid.agGridApi?.clearSelection();
  };

  private getSelectedRows = (
    api: AgGridApi<Node>,
    node: RowNode<Node>,
  ): RowNode<Node>[] => {
    const rows = api.getSelectedNodes();

    if (tenantConfig.enabledFeatures.advancedContextMenu) {
      return rows;
    }

    // If no selected rows, use selected node
    if (!rows.length) {
      return [node];
    }

    // If selected node is not among the selected rows, deselect rows and use selected node
    if (rows.every((row) => row.id !== node.id)) {
      api.clearSelection();
      return [node];
    }

    return rows;
  };

  private addRow = (focusedCell: FocusedCell): void => {
    const pathname = this.props.location && this.props.location.pathname;
    const rowPosition = focusedCell.rowIndex;
    const url = normalizeURL(`${pathname}?api_version=2`);
    const data: Partial<CommonNode> = createPartialNodeFromDefaultSchemaValues<Node>(
      this.props.schema,
    );

    this.props.createNode({
      url,
      data,
      rowPosition,
    });

    setTimeout(() => {
      if (DataGrid.agGridApi) {
        DataGrid.agGridApi.focusCell(
          focusedCell.rowIndex + 1,
          focusedCell.colId,
        );
      }
    }, 0);
  };

  private navigateToHistory = (id: string): void => {
    const {
      history,
      history: {
        location: { pathname },
      },
    } = this.props;
    history.push(`${pathname}/${id}/history`);
  };

  private addToFavorite = (items: Node[]): void => {
    items.forEach((item) =>
      this.props.saveBookmark({
        bookmarkMeta: item.bookmarkMeta,
      }),
    );
  };

  private removeFromFavorite = (items: Node[]): void => {
    items.forEach((item) => {
      const bookmark = this.props.getBookmarkById(item.uri);
      if (bookmark) {
        this.props.deleteBookmark({ bookmarkID: bookmark.id });
      }
    });
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (!DataGrid.agGridApi) {
      return;
    }

    const focusedCell:
      | FocusedCell
      | undefined = DataGrid.agGridApi.getFocusedCell();

    if (!focusedCell) {
      return;
    }

    if (!focusedCell.isEditing && e.keyCode === keys.KEY_SPACE) {
      // need to prevent default otherwise space will scroll the grid
      e.preventDefault();
      return;
    }

    switch (e.keyCode) {
      case keys.KEY_BACK_SPACE:
      case keys.KEY_DELETE:
        if (this.props.permissions.includes('items:delete')) {
          // If we're on the ID row and hit backspace or delete, delete selected rows.
          if (focusedCell.colId === ROW_NUMBER_ID) {
            const selectedNodes = DataGrid.agGridApi.getSelectedNodes();
            const ids = selectedNodes.map((row) => row.id);
            this.props.openDeleteRowsModal(
              ids,
              this.props.collectionType,
              DataGrid.agGridApi,
            );
          }
        }
        break;
      case keys.KEY_ENTER:
      case keys.KEY_RETURN:
        if (
          e.shiftKey &&
          !DataGrid.agGridApi.isGroupMode() &&
          DataGrid.agGridApi.getSortModel().length === 0
        ) {
          focusedCell.rowIndex++;
          this.addRow(focusedCell);
          DataGrid.agGridApi.clearSelection();
          setTimeout(() => {
            if (DataGrid.agGridApi) {
              DataGrid.agGridApi.focusCell(
                focusedCell.rowIndex,
                focusedCell.colId,
              );
            }
          }, 50);
        }
        break;
      default:
        break;
    }
  };

  // Allow header cells to be right-clicked to view their context menus.
  private handleNewColumnsLoaded(): void {
    const headerCells: any = document.querySelectorAll('.ag-header-cell');
    headerCells.forEach((headerCell) => {
      if (!headerCell.getAttribute('data-right-click-menu')) {
        const menu = headerCell.querySelector('.ag-icon-menu');
        if (menu) {
          headerCell.addEventListener('contextmenu', (e) => {
            menu.click();
            e.preventDefault();
          });
        }

        headerCell.setAttribute('data-right-click-menu', true);
      }
    });
  }

  private handleFilterChanged = (): void => {
    if (DataGrid.agGridApi) {
      this.props.setSearchFilters(DataGrid.agGridApi.getFilterModel());
    }
  };

  private handleSortChanged = (): void => {
    if (DataGrid.agGridApi) {
      const sortModel = DataGrid.agGridApi.getSortModel();
      this.props.setSortModel(sortModel);
    }
  };

  private handleInsertRecords = (
    rowPosition: number,
    count = 1,
    nodeData: any,
  ): void => {
    const {
      quickSearch,
      regularFilters,
      searchFilters,
    } = this.props.filterModel;
    const isSorted = this.props.sortModel.allIds.length > 0;
    const isFiltered =
      quickSearch || regularFilters.length || Object.keys(searchFilters).length;
    let modalTitle = 'Would you like to remove sorting?';
    if (isFiltered) {
      modalTitle = 'Would you like to remove filters?';
    }
    if (isFiltered && isSorted) {
      modalTitle = 'Would you like to remove filters and sorting?';
    }

    if (isSorted || isFiltered) {
      return this.setState({
        agGridState: AG_GRID_STATES.NEW_RECORD_STARTED,
        modalTitle,
      });
    }

    const fields = nodeData.fields;
    let initData = {};
    const activeGroups = DataGrid.agGridApi?.activeGroups();
    if (activeGroups?.length) {
      const activeGroupsColumnIds = activeGroups?.map((groupColumn) =>
        parseInt(groupColumn.getColId().replace('fields.', ''), 10)
      );
      initData = activeGroupsColumnIds?.reduce((result, columnId) => {
        result[columnId] = fields[columnId];
        return result;
      }, {});
    }

    const url = normalizeURL(this.props.location.pathname);
    const data: Partial<CommonNode> = createPartialNodeFromDefaultSchemaValues<Node>(
      this.props.schema,
    );
    for (let i = 0; i < count; i++) {
      this.props.createNode({
        url,
        data,
        rowPosition,
        initData,
      });
    }

    setTimeout(() => {
      if (DataGrid.agGridApi) {
        DataGrid.agGridApi.refreshCells([ROW_NUMBER_ID]);
      }
    }, 0);
  };

  private handleDuplicateRecords = (nodes: CommonNode[]): void => {
    const { columnDefs, permissions } = this.props;

    const isSorted = this.props.sortModel.allIds.length > 0;
    if (isSorted) {
      return this.setState({
        agGridState: AG_GRID_STATES.DUPLICATE_STARTED,
        modalTitle: 'Would you like to remove sorting?',
      });
    }

    const url = normalizeURL(this.props.location.pathname);
    const rows = nodes.reverse();
    const lastNode = rows[0];
    const lockPermission = permissions.includes('fields:lock');
    const rowIndex = this.props.nodes.findIndex(
      (item) => item.id === lastNode.id,
    );
    // Getting only the locked column ids in array to check it later
    const lockedColumnIds = !lockPermission
      ? columnDefs.reduce<string[]>((acc, { refData }) => {
        if (refData?.lock) {
          acc.push(refData?._id.toString());
        }

        return acc;
      }, [])
      : [];

    rows.forEach((data: CommonNode) => {
      if (!lockPermission) {
        // remove locked fields copies
        // only users with lock permissions can duplicate items/rows
        // with locked fields/columns
        const fields = data.fields;
        const fieldsCopy = Object.keys(fields || {})
          .reduce<Fields>((acc, colId) => {
            acc[colId] = '';
            if (!lockedColumnIds.includes(colId)) {
              acc[colId] = data.fields?.[colId] || '';
            }

            return acc;
          }, {});
        data.fields = fieldsCopy;
      }

      this.props.duplicateNode({
        url,
        data,
        rowPosition: rowIndex + 1,
        onComplete: () => {
          DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
        },
      });
    });
  };

  private onGridReady = (agGridApi?: AgGridApi<Node>): void => {
    DataGrid.agGridApi = agGridApi;
    this.props.onGridReady(agGridApi);
    this.autoSizeColumns();
    // Add pinned row to the bottom that will only contain a "plus" icon to add a new row
    // if (DataGrid.agGridApi && [CollectionTypes.items, CollectionTypes.tasks].includes(this.props.collectionType)) {
    //   DataGrid.agGridApi.addPinnedBottomRow();
    // }
  };

  private columnSummaryGridOnReady = (api?: ColumnSummaryGridApi): void => {
    this.columnSummaryGridApi = api;
  };

  public static getGridApi = () => {
    return DataGrid.agGridApi;
  };
}

const defaultSelectionProps = {
  rowSelection: 'multiple',
  enableRangeSelection: true,
};

const selectors = createStructuredSelector<ReduxState, StateProps>({
  permissions: getWorkspacePermissions,
  rowGroupPanelShow,
  quickFilters,
  view: selectedView,
  getBookmarkById: getBookmarkByUri,
  columnDefs: columnDefsSelector,
  customRowOrder,
  currentViewConfig: getCurrentViewConfig,
  selectedRowCount: (state: ReduxState) =>
    state.gridOptions.selectedRows.selectedRowCount,
  quickFiltersBarEnabled: (state: ReduxState) => state.gridOptions.quickFilters,
  rowIndexMap,
  reminders: (state: ReduxState) => state.reminders,
  currentSheetId: (state: ReduxState) => state.sheets.currentId || '',
  selectedViewId: (state: ReduxState) => state.collections.selectedViewId || '',
  users: (state: ReduxState) => getAccounts(state.app.accounts) || [],
  user: (state: ReduxState) => state.users.user,
  quickSearch: (state: ReduxState) => filtersSelector(state)?.quickSearch,
  attachments: (state: ReduxState) => state.attachments,
  nodesInCreation: (state: ReduxState) => nodesInCreation(state),
  gridEditors: (state: ReduxState) => state.gridEditors,
  nodesPatchProgress: (state: ReduxState) =>
    state.collections.nodesPatchProgress,
  lastUpdatedItem: (state: ReduxState) => state.collections.lastUpdatedItem,
});

// @ts-ignore
const mapDispatchToProps: DispatchProps = {
  updateRecords,
  updateField: Actions.updateField,
  commitSelection,
  downloadFiles,
  saveBookmark: saveBookmark,
  deleteBookmark: deleteBookmark,
  openRenameDocumentModal: ModalActions.openRenameDocumentModal,
  openMoveCopyDocumentModal: ModalActions.openMoveCopyDocumentModal,
  duplicateField: (fieldId) => fromActions.Actions.duplicateField({ fieldId }),
  openDeleteRowsModal: ModalActions.openDeleteItemsModal,
  openDeleteFolderModal: ModalActions.openDeleteFolderModal,
  openDeleteColumnModal: ModalActions.openDeleteGridColumnModal,
  setSearchFilters: filterActions.setSearchFilters,
  setSortModel: sortActions.setSortModel,
  setRowIndexMap,
  setCustomRowPosition: viewConfigActions.setCustomRowPosition,
  setCustomRowOrder: viewConfigActions.setCustomRowOrder,
  deleteCustomRowPosition: viewConfigActions.deleteCustomRowPosition,
  showGroupPanel,
  hideGroupPanel,
  updatePrimaryField,
  lockColumn,
  setSelectedRowCount,
  openNodeModal,
  resetSortModel,
  resetFilterModel,
  updateItems,
  bulkChangeReminders: remindersActions.requestBulkChange,
  bulkCreateWorkspaces: workspacesActions.createMultipleWorkspaces.request,
  copyAttachments,
  editorPositionInGridChanged: gridEditorsActions.request,
  cellEditingStopped: gridEditorsActions.cellEditingStopped,
  setAttachmentStatus,
  redoItem,
  undoItem,
  patchViewRequest: patchView.request,
  removeFieldRevisions,
  openCancelEnvelopeModal: ModalActions.openCancelEnvelopeModal,
  openResendEnvelopeModal: ModalActions.openResendEnvelopeModal,
  changeUserModifyFieldModalPreference:
    changeUserModifyFieldModalPreference.request,
  openLockPermissionsModal,
  warningToast: notifications.warn,
  startLockingLoader: loadingAction.startLockingLoader,
  endLockingLoader: loadingAction.endLockingLoader,
};

const mergedProps = (props: Props<CommonNode>) => {
  return {
    createSelectionProps: (): Partial<AgGridReactProps> => {
      // means we are working with document management
      if (!props.repositories) {
        return defaultSelectionProps;
      }

      return {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: false,
        onSelectionChanged: (e: SelectionChangedEvent) => {
          // It's odd that the API is not available during the SelectionChangedEvent
          // I posted a question in StackOverflow to track this concern
          // https://stackoverflow.com/questions/53838804
          if (e.api) {
            const items = e.api.getSelectedRows();
            props.commitSelection({ items });
          }
        },
        onCellContextMenu: (e: CellContextMenuEvent) => {
          // It's odd that the API is not available during the CellContextMenuEvent
          // I posted a question in StackOverflow to track this concern
          // https://stackoverflow.com/questions/53838804
          if (e.api) {
            const isContextMenuOpenedOnSelectedRow = e.api
              .getSelectedNodes()
              .some((node) => node.data.id === e.data.id);

            if (!isContextMenuOpenedOnSelectedRow) {
              e.api.getSelectedNodes().forEach((node) => {
                node.selectThisNode(false);
              });
              e.node.selectThisNode(true);

              const items = [e.data];
              props.commitSelection({ items });
            }
          }
        },
      };
    },
  };
};

const enhance = compose<Props<CommonNode>, OwnProps<CommonNode>>(
  withURLParams,
  connect<StateProps, DispatchProps, OwnProps<CommonNode>>(
    selectors,
    mapDispatchToProps,
  ),
  withProps(mergedProps),
);

export default enhance(DataGrid);

function getNewColumnIndex(
  columnIndex: number,
  newColumnPosition: NewColumnPosition,
): number {
  if (newColumnPosition === NewColumnPosition.LEFT) {
    return columnIndex;
  }

  return columnIndex + 1;
}
