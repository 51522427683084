/**
 * Filter dropdown based on a given text
 *
 * @template T - type of the dropdown data
 * @template K - Key that you want to filter (must be a string)
 * @param {T[]} obj - Dropdown array data
 * @param {K} key - Key that you want to filter (must be a string)
 * @param {string} filter - Text to filter
 * @return {*}  {T[]} - Dropdown data filtered
 */
export const filterDropdown = <T, K extends keyof T>(obj: T[], key: K, filter: string): T[] => {
  return obj.filter((item) => {
    if (typeof item[key] === 'string') {
      return (item[key] as unknown as string).toLowerCase().includes(filter.toLowerCase());
    } else {
      return obj;
    }
  });
};
