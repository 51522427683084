import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { aliceBlue, blueGrey, white } from 'styles/colors';

type ClassKey = 'arrowsContainer' | 'arrow' | 'arrowBox';

export const styles: Record<ClassKey, CSSProperties> = {
  arrowsContainer: {
    minWidth: 16,
    margin: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '0.25rem',
  },
  arrowBox: {
    'backgroundColor': aliceBlue,
    'borderRadius': 4,
    'padding': 0,
    'height': '16px',
    'width': '16px',
    '&:active': {
      backgroundColor: '#0065FF',
    },
  },
  arrow: {
    'height': '0.813rem',
    'width': '0.625rem',
    'fill': blueGrey,
    '&:active': {
      fill: white,
    },
  },
};
