import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography, { Variant } from 'components/Typography';
import Radio from '@material-ui/core/Radio';
import './NotificationPreferences.scss';
import Button, { Variant as ButtonVariant } from 'components/Button';
import {
  User,
  Notification,
  NotificationDistributionType,
} from '../../../data/users/users.types';
import { getTenantConfig } from 'env';
import { TenantConfig } from '../../../types/tenantConfig';


const tenantConfig: TenantConfig = getTenantConfig();

const RadioRow = (props) => {
  const { title, name, handleChange, selected, config } = props;
  return (
    <>
      <Grid
        item
        xs={9}
        className="account-general-notification-item-text-container"
      >
        <span>{title}</span>
      </Grid>
      <Grid item xs={3} className="account-general-notification-item-actions">
        <div className="account-general-notification-item-content account-general-notification-h-offset">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={4}
              className="account-general-notification-center-content"
            >
              {config.instantly === 'show' && (
                <Radio
                  checked={selected === 'instantly'}
                  onChange={handleChange}
                  value="instantly"
                  color="primary"
                  name={`${name}_instantly`}
                  inputProps={{ 'aria-label': 'B' }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={4}
              className="account-general-notification-center-content"
            >
              {config.daily === 'show' && (
                <Radio
                  checked={selected === 'daily'}
                  onChange={handleChange}
                  value="daily"
                  color="primary"
                  name={`${name}_daily`}
                  inputProps={{ 'aria-label': 'B' }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={4}
              className="account-general-notification-center-content"
            >
              {config.none === 'show' && (
                <Radio
                  checked={selected === 'none'}
                  onChange={handleChange}
                  value="none"
                  color="primary"
                  name={`${name}_none`}
                  inputProps={{ 'aria-label': 'B' }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

interface Props {
  user: User;
  updateUser: (payload: any) => void;
}

interface State {
  fields: Notification;
}

class NotificationPreferences extends React.Component<Props, State> {
  defaultFieldValues: Notification = {
    mentioned: NotificationDistributionType.NONE,
    addedInRecord: NotificationDistributionType.NONE,
    files: NotificationDistributionType.NONE,
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: this.defaultFieldValues,
    };
  }

  componentDidMount() {
    if (this.props.user.notificationPreferences) {
      this.setState({ fields: this.props.user.notificationPreferences });
    }
  }

  render() {
    return (
      <>
        <Grid container className="account-notification-container">
          <div className={'account-general-notification-header'}>
            <Grid item xs={9} />
            <Grid item xs={3} className="account-general-notification-actions">
              <Grid container>
                <Grid
                  item
                  xs={4}
                  className="account-general-notification-center-content"
                >
                  <Typography
                    variant={Variant.Notes}
                    className="account-notification-title"
                  >
                    Instantly
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className="account-general-notification-center-content"
                >
                  <Typography
                    variant={Variant.Notes}
                    className="account-notification-title"
                  >
                    Daily
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className="account-general-notification-center-content"
                >
                  <Typography
                    variant={Variant.Notes}
                    className="account-notification-title"
                  >
                    Never
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid xs={12}>
              <ul>
                <li className="account-general-notification-item">
                  <Grid container>
                    <RadioRow
                      name="mentioned"
                      title="Receive Notifications When You Are Mentioned"
                      selected={this.state.fields.mentioned}
                      handleChange={this.handleChange}
                      config={{
                        instantly: 'show',
                        daily: 'show',
                        none: 'show',
                      }}
                    />
                  </Grid>
                </li>
                <li className="account-general-notification-item">
                  <Grid container>
                    <RadioRow
                      name="addedInRecord"
                      title="Receive Notifications When You Are Added to Record or Task"
                      selected={this.state.fields.addedInRecord}
                      handleChange={this.handleChange}
                      config={{
                        instantly: 'show',
                        daily: 'show',
                        none: 'show',
                      }}
                    />
                  </Grid>
                </li>
                {tenantConfig.enabledFeatures.filesNotifications && (
                  <li className="account-general-notification-item">
                    <Grid container>
                      <RadioRow
                        name="files"
                        title="Receive Notifications When Files Are Added, Modified or Deleted"
                        selected={this.state.fields.files}
                        handleChange={this.handleChange}
                        config={{
                          instantly: 'show',
                          daily: 'show',
                          none: 'show',
                        }}
                      />
                    </Grid>
                  </li>
                )}
                {/* <li className="account-general-notification-item"> */}
                {/*  <Grid container> */}
                {/*    <RadioRow title="Receive Workspace Progress Report" name={'progress'} selected={this.state.fields.progress} handleChange={this.handleChange} /> */}
                {/*  </Grid> */}
                {/* </li> */}
              </ul>
            </Grid>
          </Grid>
          <Button
            className="account-notification-reset"
            label="Reset to default"
            variant={ButtonVariant.BackgroundLink}
            onClick={this.reset}
          />
        </Grid>
      </>
    );
  }

  private reset = () => {
    this.setState(
      { fields: this.defaultFieldValues },
      this.saveNotificationPreferences,
    );
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [name] = event.target.name.split('_');
    this.setState(
      { fields: { ...this.state.fields, [name]: event.target.value } },
      this.saveNotificationPreferences,
    );
  };

  private saveNotificationPreferences = () => {
    this.props.updateUser({
      data: {
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        notificationPreferences: this.state.fields,
      },
    });
  };
}

export default NotificationPreferences;
