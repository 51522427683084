import { Theme } from '@material-ui/core';
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles';

import * as Colors from 'styles/colors';
import { HEADER_HEIGHT, LEFTHAND_SIDEBAR_WIDTH } from 'styles/constants';
import { rgba } from 'styles/utils/colorUtils';

export type ClassKey
  = 'wrapper'
  | 'group'
  | 'iconWrapper'
  | 'iconWrapperPushed'
  | 'icon'
  | 'dashboardIcon'
  | 'dashboardIconWrapper'
  | 'login'
  | 'labelUserGuest'
  ;

export default withStyles((theme: Theme): Record<ClassKey, CSSProperties> => {
  const { tenantTheme = {} } = theme;
  const {
    headerBackgroundColor = Colors.hubsyncBlue,
    headerTextColor = Colors.white,
  } = tenantTheme;

  return {
    wrapper: {
      width: '100%',
      height: HEADER_HEIGHT,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      color: headerTextColor,
      backgroundColor: headerBackgroundColor,
    },
    group: { display: 'flex' },
    iconWrapper: {
      minWidth: '0',
      height: HEADER_HEIGHT,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderStyle: 'solid',
      borderColor: rgba(Colors.white, 0.1),
      cursor: 'pointer',
    },
    iconWrapperPushed: {
      minWidth: LEFTHAND_SIDEBAR_WIDTH,
      transition: 'min-width 0.3s',
    },
    icon: {
      width: '21px',
      height: '21px',
      margin: '0 16px',
      opacity: 0.5,
    },
    dashboardIcon: {
      width: '24px',
      height: '21px',
      margin: '0 16px',
    },
    dashboardIconWrapper: {
      minWidth: '0',
      height: HEADER_HEIGHT,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    login: { padding: '1.2rem 2rem' },
    labelUserGuest: {
      color: '#fff',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '15px',
      letterSpacing: '0.22px',
      padding: '0 12px',
    },
  };
});
