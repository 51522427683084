import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey =
  | 'searchBox'
  | 'field'
  | 'rowWithSpaceInBetween'
  | 'relative'
  | 'searchBoxContainer'
  | 'titleSearchBox'
  | 'buttonRemoveAll'
  | 'input'
  | 'title'
  | 'selectOffice'
  | 'container';

export const styles: Record<ClassKey, CSSProperties> = {
  title: {
    margin: 'auto 0 auto 0',
    fontWeight: 500,
    marginRight: 16,
  },
  input: {
    height: 30,
    padding: '0 0.25rem 0 0.50rem',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '20px',
    backgroundColor: 'rgba(233,237,239,0.3)',

  },
  searchBox: {
    width: 193,
    height: 30,
    paddingRight: 5,
    borderRadius: 2,
  },
  container: {
    display: 'flex',
    minHeight: '178px',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
  },
  rowWithSpaceInBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  relative: {
    position: 'relative',
  },
  titleSearchBox: {
    display: 'flex',
    borderRadius: 0,
    border: 'none',
  },
  searchBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '1rem',
    verticalAlign: 'middle',
    marginBottom: '0.75rem',
  },
  buttonRemoveAll: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  selectOffice: {
    'minWidth': '150px',
    'height': '38px',
    'marginTop': '-20px',
  },
};
