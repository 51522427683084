import * as React from 'react';
import { ValueGetterParams as AgGridValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import * as _ from 'lodash/fp';
import moment from 'moment';

import { CommonNode, FieldValue, Timestamp } from 'types/response';
import { DocumentNode, isDocumentNode } from 'types/response/documentNode';
import { Choice, FieldType } from 'types/response/fieldNode';
import { Group, isGroupNode } from 'types/response/http/getCollection';
import { getFormattedFileSize } from 'utilities/files';

import { EMPTY_TITLE } from '../AgGrid/utils';
import { ROW_NUMBER_ID } from 'components/DataGrid/columns/constants';
import idx from 'idx';
import { DEFAULT_DATE_FORMAT } from '../Fields/data';
import { removeHTMLTag } from 'utilities/format';

export interface ValueGetterParams<T extends CommonNode> extends AgGridValueGetterParams {
  data: T | Group<T>;
}

function pluralize(singular: string, count: number): string {
  if (count === 1) {
    return `1 ${singular}`;
  }

  return `${count} ${singular}s`;
}

export function getFileSizeValue(node: DocumentNode): string {
  const { isFolder, folderEntriesCount, size } = node;

  if (isFolder) {
    return pluralize('file', folderEntriesCount || 0);
  }

  if (size) {
    return getFormattedFileSize(size, true);
  }

  return '';
}

export function nodeValueGetter<T extends CommonNode>(node: T, colId: string, columnType: FieldType): FieldValue {
  switch (columnType) {
    case FieldType.FileSize:
      if (isDocumentNode(node)) {
        return getFileSizeValue(node);
      }
  }

  return get(node, colId);
}

function groupValueGetter<T extends CommonNode>(group: Group<T>): FieldValue {
  return (group.value === undefined) ? EMPTY_TITLE : group.value;
}

function commonValueGetter<T extends CommonNode>(columnType: FieldType, params: ValueGetterParams<T>): FieldValue {
  const { data, column, columnApi } = params;

  // If this column is a grouping column take the value from value key otherwise colDef.field
  if (isGroupNode(data)) {
    return groupValueGetter(data);
  }

  if (data && [FieldType.Date, FieldType.DateTime].includes(columnType) && columnApi && columnApi.getRowGroupColumns().length) {
    const value = nodeValueGetter(data, column.getColId(), columnType) as Timestamp;
    if (value) {
      const refData = column.getColDef().refData as { [key: string]: string };
      const formatDate = (refData && refData.formatDate) || DEFAULT_DATE_FORMAT;
      return moment(typeof value === 'object' ? value.timestamp : value).format(formatDate);
    }
    return value;
  }

  if (data && columnType === FieldType.Singlechoice) {
    const value = nodeValueGetter(data, column.getColId(), columnType) as string;
    const refData = column.getColDef().refData as { [key: string]: string };
    if (refData.choices) {
      const choice = refData.choices[value] as unknown as Choice;
      if (choice) return choice.label;
    }
  }

  if (data && columnType === FieldType.Account && columnApi && columnApi.getRowGroupColumns().length) {
    const value = nodeValueGetter(data, column.getColId(), columnType) as string;
    const refData: any = column.getColDef().refData;
    if (value && Array.isArray(value) && value.length > 0) {
      const result = [];
      for (const account of value) {
        if (account && refData.accountsById && refData.accountsById[account]) {
          // @ts-ignore
          result.push(refData.accountsById[account].displayName);
        }
      }
      return result;
    } else {
      if (value && refData.accountsById && refData.accountsById[value]) {
        return refData.accountsById[value].displayName;
      }
    }
  }

  // If this is the index column, get the value from the next column (first data column)
  // This value is only displayed when dragging a row
  if (column.getColId() === ROW_NUMBER_ID && columnApi) {
    const columns = columnApi.getAllDisplayedColumns();
    if (columns.length > 2) {
      const fieldType = idx(columns[1].getColDef().refData, _ => _.fieldType as FieldType) || FieldType.Singlelineoftext;
      return nodeValueGetter(data, columns[1].getColId(), fieldType);
    }
  }

  return nodeValueGetter(data, column.getColId(), columnType);
}

const validateTextTypeColumn = (params: { value: FieldValue }): FieldValue => {
  const label = removeHTMLTag(params.value?.toString());

  return label ? label: EMPTY_TITLE;
};

export const groupKeyCreator = (columnType: FieldType) => (params: { value: FieldValue }): FieldValue => {
  const isEmpty: boolean = _.isArray(params.value) ? _.isEmpty(params.value) : !params.value;

  return (!isEmpty || columnType === FieldType.Singlelineoftext || columnType === FieldType.Multilinetext)
    ? validateTextTypeColumn(params)
    : columnType === FieldType.Boolean ? false : ((columnType === FieldType.Currency || columnType === FieldType.Float || columnType === FieldType.Integer || columnType === FieldType.Percent) && params.value === 0) ? '0' : EMPTY_TITLE;
};

export default _.curry<FieldType, ValueGetterParams<CommonNode>, React.ReactNode>(commonValueGetter);
