import { initialState, State } from 'data/nodes/state';
import { Action, fetchNode } from 'data/nodes/actions';
import LoadingState from 'data/LoadingState';
import { getType } from 'typesafe-actions';

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case getType(fetchNode.request):
      return { loadingState: LoadingState.Loading };

    case getType(fetchNode.success):
      return {
        loadingState: LoadingState.Loaded,
        collectionType: action.payload.collectionType,
        node: action.payload.node,
      };

    case getType(fetchNode.failure):
      return { loadingState: LoadingState.Error, error: action.payload };
    default:
      return state;
  }
};
