import * as React from 'react';
import { styles } from './Message.styles';
import { withStyles, WithStyles } from '@material-ui/core';
import * as types from 'data/messages/types';
import AccountChip from 'components/AccountChip/AccountChip';
import Typography, { Variant } from 'components/Typography';
import ContextMenu, { MenuItem } from 'components/ContextMenu/ContextMenu';
import svgIcons from 'styles/svgIcons';
import DeleteMessageModal from 'components/Modals/DeleteConfirm/DeleteMessageModal';
import Button, { Variant as ButtonVariant } from 'components/Button';
import IconButton, { Color, Size } from 'components/IconButton';
import TextEditor from 'components/TextEditor/Editor';
import NewTextEditor from 'components/TextEditor/newEditor';
import TextReader from 'components/TextEditor/Reader';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { MAX_MESSAGE_LENGTH } from './constants';
import { removeHTMLTag } from 'utilities/format';

// eslint-disable-next-line @typescript-eslint/no-var-requires
let moment = require('moment');
if (moment.default) {
  moment = moment.default;
}

export enum MessageStates {
  BROWSE = 'browse',
  UPDATE = 'update',
  DELETE = 'delete',
}

interface State {
  editorState: EditorState;
  messageState: MessageStates;
  version: number;
  isActive: boolean;
  currentEditorState: MessageState;
}

export interface MessageState {
  html: string;
  mentions: Array<object>;
}

interface OwnProps {
  index?: number;
  message: types.Message;
  currentUserId?: string;
  messageState?: MessageStates;
  messageText?: string;
  suggestions?: types.Suggestions[];
  deleteMessage: (id: string) => void;
  updateMessage: (options: types.UpdateMessage) => void;
  onChange?: (messageState: MessageStates | MessageState) => void;
}

enum EditorStatus {
  NO_EDITOR = 'NO_EDITOR',
  NO_CONTENT = 'NO_CONTENT',
  MAX_LENGTH_EXCEEDED = 'MAX_LENGTH_EXCEEDED',
  VALID_CONTENT = 'VALID_CONTENT',
}
interface InvalidContent {
  result: boolean;
  reason: EditorStatus;
}

type Props = OwnProps & WithStyles<typeof styles>;

class Message extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const content = this.props.message.body.json ? convertFromRaw(this.props.message.body.json) : '';
    const editorState = content ? EditorState.createWithContent(content) : EditorState.createEmpty();
    this.state = {
      version: 1,
      editorState,
      messageState: this.props.messageState || MessageStates.BROWSE,
      isActive: false,
      currentEditorState: {
        html: this.props.message.body.html || '',
        mentions: [],
      },
    };
  }

  private hasInvalidContent(): InvalidContent {
    const { editorState } = this.state;
    if (!editorState) {
      return {
        result: false,
        reason: EditorStatus.NO_EDITOR,
      };
    }

    const content = editorState.getCurrentContent().getPlainText();
    const result =
      !content.trim().length || content.trim().length > MAX_MESSAGE_LENGTH;
    let reason = EditorStatus.VALID_CONTENT;
    result &&
      (reason = !content.trim().length
        ? EditorStatus.NO_CONTENT
        : EditorStatus.MAX_LENGTH_EXCEEDED);

    return {
      result,
      reason,
    };
  }

  private updateCurrentEditor = (currentEditorState: MessageState): void => {
    this.setState?.({ currentEditorState });
  };

  public render(): JSX.Element {
    const {
      index,
      classes,
      message,
      currentUserId,
      suggestions,
      deleteMessage,
    } = this.props;
    const { currentEditorState } = this.state;

    const menuItems: MenuItem[] = [
      {
        id: 'edit-message',
        title: 'Edit Message',
        Icon: svgIcons.Edit,
        action: this.updateMessage,
      },
      {
        id: 'delete-message',
        title: 'Delete Message',
        Icon: svgIcons.Delete,
        action: this.deleteMessage,
      },
    ];

    let messageClassnames = classes.wrapper;
    if (this.state.messageState === MessageStates.UPDATE) {
      messageClassnames += ` ${classes.messageEditMode}`;
    }

    if (this.state.isActive) {
      messageClassnames += ` ${classes.contextMenuActive}`;
    }

    const invalidContent: InvalidContent = this.hasInvalidContent();
    const hasChanges = message.body.html ? this.state.currentEditorState?.html !== message.body.html && removeHTMLTag(this.state.currentEditorState.html).trim().replace(/[\u00A0-\u9999<>&| ]/g, '').length : invalidContent.result;

    return (
      <React.Fragment>
        <div className={messageClassnames}>
          {this.state.messageState === MessageStates.BROWSE &&
            currentUserId === message.senderUserId && (
            <div className={`${classes.menuWrapper} message-context-menu`}>
              <ContextMenu
                id={`messageContextMenu_${index}`}
                menuItems={menuItems}
                onToggleMenu={this.onToggleMenu}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              />
            </div>
          )}
          {this.state.messageState === MessageStates.DELETE && (
            <DeleteMessageModal
              onClose={this.browseMessage}
              onConfirm={() => {
                deleteMessage(message.id);
              }}
            />
          )}
          {message.sender && (
            <AccountChip
              accountId={message.sender.id}
              initials={true}
              showName={false}
            />
          )}
          <div id={`message_${index}`} className={classes.message}>
            {this.state.messageState !== MessageStates.UPDATE && (
              <React.Fragment>
                <div className={classes.username}>
                  <div className={classes.usernameWrapper}>
                    <Typography
                      variant={Variant.CardText}
                      className={classes.usernameText}
                    >
                      {message.sender && message.sender.displayName}
                    </Typography>
                  </div>
                  <Typography
                    variant={Variant.Timestamp}
                    className={classes.time}
                  >
                    {message.createdOn &&
                      moment(message.createdOn).format('hh:mmA')}
                  </Typography>
                  {message.status === types.MessageStatuses.UPDATED && (
                    <IconButton
                      size={Size.Small}
                      icon={svgIcons.Edit}
                      color={Color.LightGray}
                      className={classes.updatedMesssage}
                    />
                  )}
                </div>
                <Typography
                  variant={Variant.CardText}
                  className={classes.messageText}
                >
                  {message.body.json &&
                    <TextReader id={`textReader_${index}`} editorState={message.body.json}/>
                    ||
                    <NewTextEditor value={message.body.html} mode={'reader'} />
                  }
                </Typography>
              </React.Fragment>
            )}
            {this.state.messageState === MessageStates.UPDATE && (
              <div>
                {message.body.json &&
                  <TextEditor
                    id={`txtEditorMessage_${index}`}
                    version={this.state.version}
                    onChange={this.onChange}
                    placeholder="Send a Message"
                    editorState={this.createEditorState()}
                    suggestions={suggestions}
                    suggestionPositionBottom={index !== undefined && index < 3}
                  />
                  ||
                  <NewTextEditor
                    onChange={this.updateCurrentEditor}
                    value={currentEditorState.html}
                    suggestions={suggestions}
                    showSendButton={false}
                    readOnly={false}
                    smallToolbarIcons={true}
                  />
                }
                <div id={`errorMessage_${index}`} className={classes.error}>
                  {invalidContent.result && invalidContent.reason === EditorStatus.MAX_LENGTH_EXCEEDED &&
                    `Maximum ${MAX_MESSAGE_LENGTH} characters are allowed.`}
                </div>
                <div className={classes.updateMessageActionBtnContainer}>
                  <Button
                    id={`btnTextEditorCancel_${index}`}
                    label="Cancel"
                    onClick={this.browseMessage}
                    variant={ButtonVariant.Secondary}
                    className={classes.updateMessageActionBtn}
                  />
                  <Button
                    label="Save Changes"
                    id={`btnTextEditorSave_${index}`}
                    onClick={this.saveMessage}
                    variant={ButtonVariant.Primary}
                    disabled={!hasChanges}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  private onToggleMenu = (isShowing: boolean): void => {
    this.setState({ isActive: isShowing });
  };

  private createEditorState = (): EditorState => {
    const content = this.props.message.body.json ? convertFromRaw(this.props.message.body.json) : '';
    const editorState = content ? EditorState.createWithContent(content) : EditorState.createEmpty();
    return editorState;
  };

  private browseMessage = (): void => {
    // Prevents to update the state if contains the same value
    if (this.state.messageState === MessageStates.BROWSE) return;

    this.setState({ messageState: MessageStates.BROWSE }, () => {
      this.props.onChange?.(this.state.messageState);
    });
  };

  private deleteMessage = (): void => {
    // Prevents to update the state if contains the same value
    if (this.state.messageState === MessageStates.DELETE) return;

    this.setState({ messageState: MessageStates.DELETE }, () => {
      this.props.onChange?.(this.state.messageState);
    });
  };

  private saveMessage = (): void => {
    const messageBody = this.props.message.body.html ? this.state.currentEditorState : {
      text: this.state.editorState.getCurrentContent().getPlainText(),
      json: convertToRaw(this.state.editorState.getCurrentContent()),
    };
    this.props.updateMessage({
      id: this.props.message.id,
      messageBody,
    });
    setTimeout(() => {
      this.browseMessage();
    }, 0);
  };

  private updateMessage = (): void => {
    // Prevents to update the state if contains the same value
    if (this.state.messageState === MessageStates.UPDATE) return;

    this.setState({ messageState: MessageStates.UPDATE }, () => {
      const state = this.props.message.body.html ? this.state.currentEditorState : this.state.messageState;
      this.props.onChange?.(state);
    });
  };

  private onChange = (editorState: EditorState): void => {
    this.setState({ editorState });
  };
}

export default withStyles(styles)(Message);
