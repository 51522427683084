import { Column, ColumnApi, GetMainMenuItems, MenuItemDef } from 'ag-grid-community';
import { getSvgIconNode } from 'components/DataGrid/utils';
import * as _ from 'lodash/fp';
import svgIcons from 'styles/svgIcons';
import { FieldType } from 'types/response/fieldNode';
import { isUserDefinedFieldKey } from 'utilities/collections';

import { GridContext } from '../AgGrid/AgGridApi';
import { ROW_NUMBER_ID } from './columns/constants';
export interface MenuItemActions {
  makeFieldPrimary(columnId: string): void;

  modifyFieldType(columnId: string): void;

  toggleLockColumn(columnId: string): void;

  deleteColumn(columnId: string): void;

  sortAsc(columnId: string): void;

  sortDesc(columnId: string): void;

  openFilterMenu(columnId: string): void;

  renameColumn(columnId: string): void;

  insertColumnRight(columnId: string): void;

  insertColumnLeft(columnId: string): void;

  duplicate(columnId: string): void;
}

function getColumnName(column: Column, columnApi: ColumnApi): string {
  return columnApi.getDisplayNameForColumn(column, 'header');
}

function getGroupingItem(column: Column, columnApi: ColumnApi | undefined | null): MenuItemDef | null {
  const allowRowGroup = column.isAllowRowGroup();
  const isPrimary = column.isPrimary();

  if (allowRowGroup && isPrimary && columnApi) {
    const groupedByThisColumn = columnApi.getRowGroupColumns().indexOf(column) >= 0;
    if (!groupedByThisColumn) {
      return {
        name: `Group by Column "${getColumnName(column, columnApi)}"`,
        action: () => columnApi.addRowGroupColumn(column),
        icon: getSvgIconNode(svgIcons.Group),
        cssClasses: ['hubsync-grid-column-menu-item'],
      };
    } else {
      return {
        name: `Un-Group by Column "${getColumnName(column, columnApi)}"`,
        action: () => columnApi.removeRowGroupColumn(column),
        icon: getSvgIconNode(svgIcons.Group),
        cssClasses: ['hubsync-grid-column-menu-item'],
      };
    }
  }
  return null;
}

export default (actions: MenuItemActions, permissions: string[]): GetMainMenuItems => ({ column, columnApi, context }) => {
  const id = column.getId();
  const colDef = column.getColDef();
  const modifyEnabled = isUserDefinedFieldKey(id);
  const fieldCouldUpdate = permissions.includes('fields:update');
  const canLockViews = permissions.includes('views:lock'); // 2020-03-10 - Currently only admin has views:lock permission
  const canCreateFields = permissions.includes('fields:create') && canLockViews;
  const isLock = colDef.refData?.lock;
  const lockPermission = permissions.includes('fields:lock');
  const blockEdit = isLock && !lockPermission;
  const lockedCSSClasses = [
    'hubsync-grid-column-menu-item',
    blockEdit ? 'disabled' : '',
  ];
  const gridContext: GridContext = context;
  const disableForEditor = !permissions.includes('views:lock') && gridContext?.currentViewIsLocked;

  // TODO find better way to pass column property to this method
  const suppressFilter = _.getOr(false, 'refData.grid.suppressFilterDropdown', colDef);

  const allMenuItems: (MenuItemDef | string)[] = [];

  if (fieldCouldUpdate && canCreateFields && modifyEnabled) {
    if (colDef.refData && (
      colDef.refData.fieldType === FieldType.Singlelineoftext)) {
      allMenuItems.push({
        name: 'Make Field Primary',
        action: !blockEdit ? () => actions.makeFieldPrimary(id) : undefined,
        icon: getSvgIconNode(svgIcons.PrivateKey),
        disabled: colDef.refData && colDef.refData.isPrimary || disableForEditor ? true : false,
        cssClasses: lockedCSSClasses,
      });
    }

    allMenuItems.push({
      name: 'Modify Field Type',
      action: !blockEdit ? () => actions.modifyFieldType(id) : undefined,
      icon: getSvgIconNode(svgIcons.ModifyField),
      disabled: colDef.refData && colDef.refData.isPrimary || disableForEditor ? true : false,
      cssClasses: lockedCSSClasses,
    });

    allMenuItems.push(
      {
        name: 'Rename',
        action: !blockEdit ? () => actions.renameColumn(id) : undefined,
        icon: getSvgIconNode(svgIcons.Edit),
        disabled: disableForEditor,
        cssClasses: lockedCSSClasses,
      },
    );
  }

  if (canCreateFields) {
    allMenuItems.push(
      {
        name: 'Insert Right',
        action: () => actions.insertColumnRight(id),
        icon: getSvgIconNode(svgIcons.ArrowRight),
        cssClasses: ['hubsync-grid-column-menu-item'],
        disabled: disableForEditor,
      },
      {
        name: 'Insert Left',
        action: () => actions.insertColumnLeft(id),
        icon: getSvgIconNode(svgIcons.ArrowLeft),
        cssClasses: ['hubsync-grid-column-menu-item'],
        disabled: disableForEditor,
      });
  }

  if (fieldCouldUpdate && modifyEnabled && canLockViews) {
    allMenuItems.push({
      name: isLock ? 'Unlock Column' : 'Lock Column',
      action: lockPermission ? () => actions.toggleLockColumn(id) : undefined,
      icon: isLock
        ? getSvgIconNode(svgIcons.LockOpen)
        : getSvgIconNode(svgIcons.LockFilled),
      cssClasses: [
        'hubsync-grid-column-menu-item',
        !lockPermission ? 'disabled' : '',
      ],
      disabled: disableForEditor,
    });
  }

  if (allMenuItems.length) {
    allMenuItems.push('separator');
  }

  const groupingItem = getGroupingItem(column, columnApi);
  if (groupingItem) {
    allMenuItems.push(groupingItem);
  }
  if (!suppressFilter) {
    allMenuItems.push({
      name: 'Filter',
      action: () => actions.openFilterMenu(id),
      icon: getSvgIconNode(svgIcons.Filter),
      cssClasses: ['hubsync-grid-column-menu-item'],
    });
  }
  allMenuItems.push(
    {
      name: 'Sort Ascending',
      action: () => actions.sortAsc(id),
      icon: getSvgIconNode(svgIcons.ArrowUp),
      cssClasses: ['hubsync-grid-column-menu-item'],
    },
    {
      name: 'Sort Descending',
      action: () => actions.sortDesc(id),
      icon: getSvgIconNode(svgIcons.ArrowDown),
      cssClasses: ['hubsync-grid-column-menu-item'],
    },
  );

  allMenuItems.push('separator');

  allMenuItems.push(
    {
      name: 'Pin Column',
      icon: getSvgIconNode(svgIcons.PinColumn),
      subMenu: ['pinLeft', 'pinRight', 'clearPinned'],
      cssClasses: ['hubsync-grid-column-menu-item'],
    },
  );

  if (columnApi) {
    allMenuItems.push(
      {
        name: 'Autosize This Column',
        action: () => columnApi.autoSizeColumn(column),
        icon: getSvgIconNode(svgIcons.Autosize),
        cssClasses: ['hubsync-grid-column-menu-item'],
      },
      {
        name: 'Autosize All Columns',
        action: () => {
          const columnIds: string[] = [];
          columnApi.getAllColumns()
            .forEach(column => {
              if (column.getColId() === ROW_NUMBER_ID) return;
              columnIds.push(column.getColId());
            });
          columnApi.autoSizeColumns(columnIds);
        },
        icon: getSvgIconNode(svgIcons.AutosizeAll),
        cssClasses: ['hubsync-grid-column-menu-item'],
      },
    );
  }

  allMenuItems.push('separator');
  // if (permissions.includes(PermissionTypes.create) && modifyEnabled) {
  //   allMenuItems.push({
  //     name: 'Duplicate Field',
  //     action: () => actions.duplicate(id),
  //     icon: getSvgIconNode(svgIcons.Duplicate),
  //     cssClasses: ['hubsync-grid-column-menu-item'],
  //     disabled: colDef.refData && colDef.refData.isPrimary ? true : false,
  //   });
  // }

  if (columnApi) {
    allMenuItems.push({
      name: 'Hide Field',
      action: () => columnApi.setColumnVisible(column, false),
      icon: getSvgIconNode(svgIcons.Hide),
      disabled: colDef.refData && colDef.refData.isPrimary ? true : false,
      cssClasses: ['hubsync-grid-column-menu-item'],
    });
  }

  if (permissions.includes('fields:delete') && modifyEnabled && canLockViews) {
    allMenuItems.push({
      name: 'Delete Field',
      action: !blockEdit ? () => actions.deleteColumn(id) : undefined,
      icon: getSvgIconNode(svgIcons.Delete),
      disabled: colDef.refData && colDef.refData.isPrimary || disableForEditor ? true : false,
      cssClasses: lockedCSSClasses,
    });
  }

  return allMenuItems;
};
