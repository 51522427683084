import { findDOMNode } from 'react-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { Dictionary } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getAllUsers } from 'data/accounts/actions';
import { accounts, isAccountsLoading } from 'data/accounts/selectors';
import { schemaColumnsMap } from 'data/collections/collections.selectors';
import { State as ReduxState } from 'reducers';
import { RegularFilter } from 'types/gridOptions';
import { AccountNode } from 'types/response/accountNode';
import { FieldType } from 'types/response/fieldNode';
import { Choice, Column } from 'types/schema';
import { getInitials } from 'utilities/collections';

import { AccountWrapper, Avatar, ChoiceItem, MultipleChoiceItem } from './FilteringOption.style';
import { Select } from '../../TableHeaderTools.style';
import { isMultipleOperator } from '../../../NodeFieldData/filters/helpers';
import Switch from '../../../Switch';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';

interface OwnProps {
  filter: RegularFilter;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>, key: string) => void;
}

interface StateProps {
  accounts: AccountNode[] | null;
  isLoading: boolean;
  schemaColumns: Dictionary<Column>;
}

interface DispatchProps {
  fetchAccountsRequest?(): void;
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
  searchValue: string;
  style: {
    [key: string]: string;
  };
}

class ChoiceFilterOptionInput extends React.Component<Props, State> {
  state = {
    searchValue: '',
    style: {},
  };

  public componentDidMount(): void {
    const { accounts, isLoading, filter, schemaColumns } = this.props;

    const column = schemaColumns[filter.columnId];

    const isAccountFieldType = column.fieldType === FieldType.Account;
    const isAccountsNotLoaded = accounts === null && !isLoading;

    if (isAccountFieldType && isAccountsNotLoaded) {
      this.props.fetchAccountsRequest?.();
    }

    this.calculateHeight();
  }

  public render(): JSX.Element | null {
    const { filter, schemaColumns } = this.props;

    const isMultiple = isMultipleOperator(filter.operator);
    const itemsInFilter = filter.filter as string[];

    const column = schemaColumns[filter.columnId];

    switch (column?.fieldType) {
      case FieldType.Account: {
        const accounts = (this.props.accounts === null) ? [] : this.props.accounts;

        return (
          <AccountOptionInput
            columnId={column.id}
            accounts={accounts}
            isMultiple={isMultiple}
            itemsInFilter={itemsInFilter}
            onChange={this.onChange}
            searchValue={this.state.searchValue}
            onSearchOption={this.onSearchOption}
            style={this.state.style}
          />
        );
      }

      default: {
        return (
          <OtherOptionInput
            key={column.id}
            column={column}
            isMultiple={isMultiple}
            itemsInFilter={itemsInFilter}
            onChange={this.onChange}
            searchValue={this.state.searchValue}
            onSearchOption={this.onSearchOption}
            style={this.state.style}
          />
        );
      }
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLSelectElement>, key: string): void => {
    this.props.onChange(event, key);
  };

  private onSearchOption = (value: string): void => {
    this.setState({ searchValue: value });
  };

  private calculateHeight = (): void => {
    setTimeout(() => {
      // eslint-disable-next-line
      const node = findDOMNode(this) as HTMLElement;
      if (node) {
        const clientRect = node.getBoundingClientRect();
        const maxHeight = `${window.innerHeight - clientRect.top}px`;
        this.setState({
          style: {
            maxHeight,
          },
        });
      }
    }, 500);
  };
}

interface OptionInputProps {
  isMultiple: boolean;
  itemsInFilter: string[];
  onChange(event: React.ChangeEvent<HTMLSelectElement>, key: string): void;
}

interface AccountOptionInputProps extends OptionInputProps {
  columnId: string;
  accounts: AccountNode[];
  searchValue: string;
  onSearchOption: (value: string) => void;
  style: {
    [key: string]: string;
  };
}

export function AccountOptionInput(props: AccountOptionInputProps): JSX.Element {
  const { accounts, isMultiple, itemsInFilter, onChange } = props;
  const filteredAccounts = accounts.filter(account => (
    account?.displayName?.toLowerCase().includes(props.searchValue.toLowerCase())
  ));
  const [isOpen, toggleSelect] = useState(false);

  return (
    <Select
      key={props.columnId}
      multiple={isMultiple}
      value={itemsInFilter}
      name="select"
      onChange={onChange}
      renderValue={renderSelectedAccounts(accounts)}
      disableUnderline
      fullWidth
      autoFocus
      open={isOpen}
      onOpen={() => {
        toggleSelect(true);
        props.onSearchOption('');
      }}
      onClose={() => toggleSelect(false)}
      MenuProps={{
        PaperProps: {
          style: props.style,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      <SearchBar
        className="hubsync-menu-searchbar"
        placeholder="Find an option"
        value={props.searchValue}
        size={SearchBarSize.Small}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSearchOption(event.target.value)}
        autoFocus
      />
      {renderAccountsSelectorOptions(filteredAccounts, itemsInFilter, isMultiple)}
    </Select>
  );
}

export function renderSelectedAccounts(accounts: AccountNode[]): (selected: string[]) => (JSX.Element | null)[] {
  return (selected: string[]) => selected.map(value => {
    const account = accounts.find(account => account.id === value);
    if (!account) return null;
    return (
      <React.Fragment key={value}>
        {renderAccount(account)}
      </React.Fragment>
    );
  });
}

function renderAccountsSelectorOptions(accounts: AccountNode[], itemsInFilter: string[], isMultiple: boolean): JSX.Element[] {
  return accounts.map(account => (
    <MenuItem
      key={account.id}
      value={account.id}
    >
      {isMultiple ?
        menuItemContentWithSwitcher(itemsInFilter, account.id, renderAccount(account)) :
        renderAccount(account)
      }
    </MenuItem>
  ));
}

function renderAccount({ backgroundColor = '', textColor, displayName }: AccountNode): JSX.Element {
  return (
    <AccountWrapper>
      <Avatar color={backgroundColor} textColor={textColor}>
        {getInitials(displayName)}
      </Avatar>
      {displayName}
    </AccountWrapper>
  );
}

interface OtherOptionInputProps extends OptionInputProps {
  column: Column;
  searchValue: string;
  onSearchOption: (value: string) => void;
  style: {
    [key: string]: string;
  };
}

function OtherOptionInput(props: OtherOptionInputProps): JSX.Element {
  const { column, isMultiple, itemsInFilter, onChange } = props;
  const filteredChoices = {};
  if (column.choices) {
    Object.entries(column.choices).forEach(([key, choice]) => {
      if (choice.label.toLowerCase().includes(props.searchValue.toLowerCase())) {
        filteredChoices[key] = choice;
      }
    });
  }
  const [isOpen, toggleSelect] = useState(false);

  return (
    <Select
      key={props.column.id}
      multiple={isMultiple}
      value={itemsInFilter}
      name="select"
      onChange={onChange}
      renderValue={renderSelectedItems(column.choices)}
      disableUnderline
      fullWidth
      autoFocus
      open={isOpen}
      onOpen={() => {
        toggleSelect(true);
        props.onSearchOption('');
      }}
      onClose={() => toggleSelect(false)}
      MenuProps={{
        PaperProps: {
          style: props.style,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      className="reminder-select"
    >
      <SearchBar
        className="hubsync-menu-searchbar"
        placeholder="Find an option"
        value={props.searchValue}
        size={SearchBarSize.Small}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSearchOption(event.target.value)}
        autoFocus
      />
      {renderChoiceSelectorOptions(filteredChoices, itemsInFilter, isMultiple)}
    </Select>
  );
}

function renderSelectedItems(choices: Dictionary<Choice> = {}): (selected: string[]) => JSX.Element[] {
  return (selected: string[]) => {
    const elements: JSX.Element[] = [];
    selected.forEach(value => {
      if (choices[value]) {
        elements.push(<ChoiceItem key={value} choice={choices[value]} />);
      }
    });
    return elements;
  };
}

function renderChoiceSelectorOptions(choices: { [key: string]: Choice } = {}, itemsInFilter: string[], isMultiple: boolean): JSX.Element[] {
  return Object.entries(choices).map(([key, value]) => (
    <MenuItem
      key={key}
      value={key}
    >
      {isMultiple ?
        menuItemContentWithSwitcher(itemsInFilter, key, <MultipleChoiceItem choice={value} />) :
        <ChoiceItem choice={value} />
      }
    </MenuItem>
  ));
}

export function menuItemContentWithSwitcher(itemsInFilter: string[], currentId: string, component?: React.ReactNode): JSX.Element {
  const selected = itemsInFilter.some(item => item === currentId);

  return (
    <React.Fragment>
      <Switch selected={selected} />
      {component}
    </React.Fragment>
  );
}

const selectors = createStructuredSelector<ReduxState, OwnProps, StateProps>({
  schemaColumns: schemaColumnsMap,
  accounts,
  isLoading: isAccountsLoading,
});

const mapDispatchToProps: DispatchProps = {
  fetchAccountsRequest: getAllUsers,
};

export const ChoiceFilterOptionInputComponent = ChoiceFilterOptionInput;

export default connect(selectors, mapDispatchToProps)(ChoiceFilterOptionInput);
