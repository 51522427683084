import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip/Chip';
import { isArray, compact } from 'lodash';

import { FieldRendererTypes } from '../../Fields.types.ts';
import { getColumnHeaderClass } from '../../../DataGrid/columns/helpers.ts';

const formatter = 'tags';

class TagsRenderer extends React.PureComponent {
  render() {
    const { type, value } = this.props;

    const chips = compact(isArray(value) ? value : [value]);

    return (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard)
      ? <span className={getColumnHeaderClass(formatter)} title={value} /> :
      chips.map((chip, i) => <Chip key={i} className="multilinetext__renderer" label={chip} />);
  }
}

TagsRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
};

export default TagsRenderer;
