import * as React from 'react';

import AccountProvider from 'components/AccountProvider';
import { ChipProps } from 'components/ui/Chip';
import styled from 'styled-components';
import svgIcons from 'styles/svgIcons';
import { getInitials } from 'utilities/collections';

import './AccountChip.style.scss';
import IconButton, { Color as IconButtonColor, Size } from '../IconButton';
import {
  IconWrapper,
  StyledAccountWrapper,
  StyledAvatar,
  StyledName,
  AccountChipSize,
  getSize,
} from './AccountChip.styles';
import { AccountNode } from '../../types/response/accountNode';

interface AccountChipProps extends ChipProps {
  accountId?: string;
  initials?: boolean;
  showName?: boolean;
  showEmail?: boolean;
  disable?: boolean;
  size?: AccountChipSize;
  noColor?: boolean;
  user?: AccountNode;
}

interface UserIconProps {
  size?: AccountChipSize;
}

const UserIcon = styled(svgIcons.UserIcon)<UserIconProps>`
  width: ${({ size = AccountChipSize.small }) => `${getSize(size)-3}px !important`};
  height: ${({ size = AccountChipSize.small }) => `${getSize(size)-3}px !important`};
`;

const AccountChip = ({
  initials,
  showName,
  accountId,
  innerRef,
  size = AccountChipSize.small,
  noColor = false,
  user,
  ...props
}: AccountChipProps) => {
  const noColorStyle = noColor ? { color: 'inherit' } : {};
  if (user) {
    return (
      <div className={`account-chip-account_wrapper ${props.className || ''}`}>
        <IconWrapper
          size={size}
          color={user.backgroundColor || 'lightGrey'}
          className="account-chip-icon_wrapper"
        >
          <UserIcon size={size} />
        </IconWrapper>
        <div className="account-chip-account_name" style={noColorStyle}>
          {user.displayName || user?.email?.split?.('@')?.[0] || 'System'}
          {props.showEmail && (
            <span className="account-chip-account_email">
              {user.email}
            </span>
          )}
        </div>
        {props.onDelete && (
          <IconButton
            icon={svgIcons.Remove}
            color={IconButtonColor.LightGray}
            size={Size.Small}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onDelete && props.onDelete(e);
            }}
          />
        )}
      </div>
    );
  }
  return (
    <AccountProvider accountId={accountId || ''}>
      {(account) =>
        initials ? (
          <StyledAccountWrapper>
            <StyledAvatar color={account.backgroundColor || 'lightGrey'}>
              {getInitials(account.displayName)}
            </StyledAvatar>
            {showName ? (
              <StyledName className="name">{account.displayName}</StyledName>
            ) : (
              ''
            )}
          </StyledAccountWrapper>
        ) : (
          <div
            className={`account-chip-account_wrapper ${props.className || ''}`}
          >
            <IconWrapper
              size={size}
              color={account.backgroundColor || 'lightGrey'}
              className="account-chip-icon_wrapper"
            >
              <UserIcon size={size} />
            </IconWrapper>
            <div className="account-chip-account_name" style={noColorStyle}>
              {account.displayName}
              {props.showEmail && (
                <span className="account-chip-account_email">
                  {account.email}
                </span>
              )}
            </div>
            {props.onDelete && (
              <IconButton
                icon={svgIcons.Remove}
                color={IconButtonColor.LightGray}
                size={Size.Small}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.onDelete && props.onDelete(e);
                }}
              />
            )}
          </div>
        )
      }
    </AccountProvider>
  );
};

export default AccountChip;
