import * as React from 'react';

import { Tooltip, withStyles, WithStyles } from '@material-ui/core';
import IconButton, { Color, Size } from 'components/IconButton';
import ModalHeader from 'components/Modals/Modal/Header';
import Typography, { Variant } from 'components/Typography';
import { closeModal } from 'data/modals/actions';
import {
  changePassword,
  updateUser,
  deleteUser,
  changeUserEmail,
  fetchUser,
} from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import svgIcons from 'styles/svgIcons';
import { validateEmail } from 'utilities/validation';

import { AppMessages } from '../../../constants';
import Button, { Variant as ButtonVariant } from '../../Button';
import ModalTextField from '../ModalTextField';
import { Avatar, AvatarSize } from './Avatar/Avatar';
import { styles } from './index.style';
import { List } from './List/List';
import NotificationPreferences from './NotificationPreferences';
import PhoneInput from 'react-phone-input-2';
interface DispatchProps {
  close: () => void;
  updateUser: (payload: any) => void;
  fetchUser: () => void;
  updatePassword: (payload: {
    userId: string;
    data: { currentPassword: string; newPassword: string };
  }) => void;
  deleteUser: () => void;
  changeUserEmail: (payload: {
    data: { currentPassword: string; email: string };
  }) => void;
}

// form fields
interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  currentPassword: string;
  oldPassword: string;
  newPassword: string;
}

interface State {
  tab: string;
  emailFormActive: boolean;
  passwordFormActive: boolean;
  showNewPassword: boolean;
  showCurrentPassword: boolean;
  fields: FormFields;
  isSSOUser: boolean;
  invalidPhone: boolean;
  formattedPhoneValue: any;
}

interface StateProps {
  user: User;
  modal: any;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
  modal: (state: ReduxState) => state.modals,
});

type Props = StateProps & DispatchProps & WithStyles<typeof styles>;

class AccountView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: props.modal.tab || 'general',
      emailFormActive: false,
      passwordFormActive: false,
      showNewPassword: false,
      showCurrentPassword: false,
      fields: {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        // TODO use user field for email
        currentPassword: '',
        oldPassword: '',
        newPassword: '',
      },
      formattedPhoneValue: props.user.phone || '',
      isSSOUser: this.isSSO(window.appStore.getState().app.tenant ?? null, props.user),
      invalidPhone: false,
    };
  }

  validUser = () => {
    return (
      this.state.fields.firstName &&
      this.state.fields.firstName.length > 1 &&
      this.state.fields.lastName &&
      this.state.fields.lastName.length > 1
    );
  };

  validEmail = (): boolean => {
    return validateEmail(this.state.fields.email);
  };

  emailValidationErrorText = (): string => {
    return this.state.fields.email
      ? AppMessages.CHANGE_EMAIL_INVALID
      : AppMessages.CHANGE_EMAIL_REQUIRED;
  };

   isInvalid = (formattedValue: string): boolean => {
     const splittedNumber = formattedValue.split(' ');
     const phoneWithoutCountryCode = splittedNumber[1];
     if (phoneWithoutCountryCode) {
       const onlyNumber = phoneWithoutCountryCode.replace(/-/g, '');
       return (onlyNumber && onlyNumber.length < 10) as boolean;
     } else {
       return false;
     }
   };

  handlePhoneChange = (value: string, _country, _e, formattedValue) => {
    this.setState({ formattedPhoneValue: value });
    this.setState({ invalidPhone: this.isInvalid(formattedValue) });
  };

  isSSO = (tenant, user): boolean => {
    const trustedDomains = tenant && tenant.authProvider?.identityProviders?.length
      ? tenant.authProvider?.identityProviders[0].trustedDomains
      : [];
    const emailDomain = user.email.split('@')[1];
    const matchDomain = trustedDomains.find((domain) => domain === emailDomain);
    const isSSOUser = matchDomain? true : false;

    return isSSOUser;
  };

  public render = (): JSX.Element => {
    const { classes, user } = this.props;
    const permissions: Array<string> | undefined = user.permissions;

    return (
      <div className={classes.wrapper}>
        <div className={classes.leftWrapper}>
          <div className={classes.profileCard}>
            <Typography variant={Variant.SubHeading}>My Profile</Typography>
            <div className={classes.avatarWrapper}>
              <Avatar user={user} size={AvatarSize.ExtraLarge} />
            </div>
            <Typography variant={Variant.SubHeading}>
              {user.displayName}
            </Typography>
          </div>
          <List permissions={permissions} navigateTo={this.setActiveTab} active={this.state.tab} />
        </div>
        <div className={classes.content}>
          <ModalHeader
            titleClassName={classes.titleClassName}
            onClose={this.onClose}
          >
            {this.state.tab === 'general' ? AppMessages.GENERAL
              : AppMessages.EMAIL_NOTIFICATION_PREFERENCES
            }
          </ModalHeader>
          <div className={classes.tabContent}>
            {this.state.tab === 'general' ? (
              this.renderGeneralLayout()
            ) : (
              <NotificationPreferences
                user={this.props.user}
                updateUser={this.props.updateUser}
              />
            )}
          </div>
          <div className={classes.divider} />
          {this.state.tab === 'general' && (
            <div className={classes.actionButtons}>
              <Button
                id="btnAccountCancel"
                label="Cancel"
                variant={ButtonVariant.SecondaryLink}
                onClick={this.onClose}
              />
              <Button
                id="btnAccountSave"
                className={classes.saveButton}
                label="Save changes"
                variant={ButtonVariant.Primary}
                type="submit"
                disabled={!this.validUser() || this.state.invalidPhone}
                onClick={this.saveUser}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  private saveUser = () => {
    const { firstName, lastName } = this.state.fields;
    const phone = this.state.formattedPhoneValue;
    const displayName = `${firstName} ${lastName}`;
    this.props.updateUser({
      userId: this.props.user.id,
      data: { firstName, lastName, phone, fullName: displayName, displayName },
    });
    this.props.fetchUser();
    this.props.close();
  };

  private renderGeneralLayout = () => {
    const { classes, user } = this.props;
    const { emailFormActive, passwordFormActive } = this.state;
    return (
      <>
        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <Typography
              className={classes.inputLabel}
              variant={Variant.FieldLabel}
            >
              First Name
            </Typography>
            <div className={classes.input}>
              <ModalTextField
                value={this.state.fields.firstName}
                onChange={this.handleInputChange}
                required={true}
                name="firstName"
                type="text"
              />
              {!this.state.fields.firstName && (
                <Typography
                  variant={Variant.ModalText}
                  className={classes.errorMessage}
                >
                  First name is mandatory
                </Typography>
              )}
              {this.state.fields.firstName &&
                this.state.fields.firstName.length < 2 && (
                <Typography
                  variant={Variant.ModalText}
                  className={classes.errorMessage}
                >
                    At least two characters are required
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.inputWrapper}>
            <Typography
              className={classes.inputLabel}
              variant={Variant.FieldLabel}
            >
              Last Name
            </Typography>
            <div className={classes.input}>
              <ModalTextField
                value={this.state.fields.lastName}
                onChange={this.handleInputChange}
                autoComplete="off"
                required={true}
                name="lastName"
                type="text"
              />
              {!this.state.fields.lastName && (
                <Typography
                  variant={Variant.ModalText}
                  className={classes.errorMessage}
                >
                  Last name is mandatory
                </Typography>
              )}
              {this.state.fields.lastName &&
                this.state.fields.lastName.length < 2 && (
                <Typography
                  variant={Variant.ModalText}
                  className={classes.errorMessage}
                >
                    At least two characters are required
                </Typography>
              )}
            </div>
          </div>
        </div>
        {/* Phone number component */}
        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <div style={{ display: 'flex', marginBottom: '3px', alignItems: 'center' }}>
              <Typography
                className={classes.inputLabel}
                variant={Variant.FieldLabel}
              >
              Phone Number
              </Typography>

              {!this.state.isSSOUser ? (
                <div style={{ marginLeft: '3px', width: '14px', height: '14px' }}>
                  <Tooltip title="This field can only be updated via MFA settings." placement="right">
                    <div>
                      <svgIcons.InfoFilled viewBox="0 0 16 16" fill="#000000" />{' '}
                    </div>
                  </Tooltip>
                </div>
              ) : null}


            </div>


            <div className={classes.phoneInputProfile}>
              <PhoneInput
                placeholder="Enter your phone number"
                country={'us'}
                copyNumbersOnly={true}
                value={this.state.formattedPhoneValue}
                onChange={(value, _country, _e, formattedValue) => this.handlePhoneChange(value, _country, _e, formattedValue)}
                autoFormat={true}
                countryCodeEditable={false}
                enableSearch={true}
                disableSearchIcon={true}
                searchPlaceholder={'Search country...'}
                defaultMask={'...-...-....'}
                alwaysDefaultMask={true}
                inputClass={'react-phone'}
                disabled={!this.state.isSSOUser}
              />
            </div>
            <div>
              {this.state.invalidPhone ? (
                <div className="auth-input-error-container">
                  <svgIcons.Limited />{' '}
                  <span
                    className="auth-error-message"
                    style={{ paddingTop: '11px' }}
                  >
                    &nbsp;&nbsp;{'Enter a valid 10-digit phone number.'}
                  </span>
                </div>
              ) : null}

            </div>

          </div>


        </div>

        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <Typography variant={Variant.SubHeading} className={classes.mb}>
              Email
            </Typography>
            {emailFormActive ? (
              <div className={classes.miniFormWrapper}>
                <div className={classes.inputRow}>
                  <div className={classes.inputWrapper}>
                    <Typography
                      className={classes.inputLabel}
                      variant={Variant.FieldLabel}
                    >
                      New email address
                    </Typography>
                    <div className={classes.input}>
                      <ModalTextField
                        autoFocus
                        autoSelect
                        value={this.state.fields.email}
                        onChange={this.handleInputChange}
                        name="email"
                        required={true}
                        type="text"
                      />
                      {!this.validEmail() && (
                        <div className={classes.changeEmailErrorContainer}>
                          <svgIcons.Limited
                            className={classes.changeEmailErrorIcon}
                          />
                          <span className={classes.changeEmailErrorMessage}>
                            {this.emailValidationErrorText()}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.inputWrapper}></div>
                </div>
                <div className={classes.inputRow}>
                  <div className={classes.inputWrapper}>
                    <Typography
                      className={classes.inputLabel}
                      variant={Variant.FieldLabel}
                    >
                      Current password
                    </Typography>
                    <div className={classes.input}>
                      <ModalTextField
                        value={this.state.fields.currentPassword}
                        onChange={this.handleInputChange}
                        name="currentPassword"
                        required={true}
                        type="password"
                      />
                    </div>
                    <div className={classes.miniFormActionButtons}>
                      <Button
                        id="btnAccountCancelEmail"
                        label="Cancel"
                        variant={ButtonVariant.SecondaryLink}
                        onClick={this.toggleEmailForm}
                      />
                      <Button
                        id="btnAccountSaveEmail"
                        className={classes.saveButton}
                        onClick={this.changeEmail}
                        label="Save email"
                        variant={ButtonVariant.Primary}
                        type="submit"
                        disabled={!this.validEmail()}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}></div>
                </div>
              </div>
            ) : (
              <>
                <span className={classes.emailValue}>{user.email}</span>
                <Button
                  id="btnAccountChangeEmail"
                  label="Change email"
                  icon={svgIcons.Email}
                  variant={ButtonVariant.BackgroundLink}
                  onClick={this.toggleEmailForm}
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <Typography variant={Variant.SubHeading} className={classes.mb}>
              Password
            </Typography>
            {passwordFormActive ? (
              <div className={classes.miniFormWrapper}>
                <div className={classes.inputRow}>
                  <div className={classes.inputWrapper}>
                    <Typography
                      className={classes.inputLabel}
                      variant={Variant.FieldLabel}
                    >
                      Current password
                    </Typography>
                    <div className={classes.input}>
                      <ModalTextField
                        value={this.state.fields.oldPassword}
                        onChange={this.handleInputChange}
                        name="oldPassword"
                        required={true}
                        type={
                          this.state.showCurrentPassword ? 'text' : 'password'
                        }
                        InputProps={{
                          endAdornment: (
                            <div className="show-password-toggle">
                              <IconButton
                                tabIndex={-1}
                                icon={
                                  !this.state.showCurrentPassword
                                    ? svgIcons.PasswordShow
                                    : svgIcons.Columns
                                }
                                color={Color.DarkGray}
                                size={Size.Medium}
                                onMouseDown={() =>
                                  this.setState({ showCurrentPassword: true })
                                }
                                onClick={() =>
                                  this.setState({ showCurrentPassword: false })
                                }
                              />
                            </div>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}></div>
                </div>
                <div className={classes.inputRow}>
                  <div className={classes.inputWrapper}>
                    <Typography
                      className={classes.inputLabel}
                      variant={Variant.FieldLabel}
                    >
                      New password
                    </Typography>
                    <div className={classes.input}>
                      <ModalTextField
                        value={this.state.fields.newPassword}
                        onChange={this.handleInputChange}
                        name="newPassword"
                        required={true}
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <div className="show-password-toggle">
                              <IconButton
                                tabIndex={-1}
                                icon={
                                  !this.state.showNewPassword
                                    ? svgIcons.PasswordShow
                                    : svgIcons.Columns
                                }
                                color={Color.DarkGray}
                                size={Size.Medium}
                                onMouseDown={() =>
                                  this.setState({ showNewPassword: true })
                                }
                                onClick={() =>
                                  this.setState({ showNewPassword: false })
                                }
                              />
                            </div>
                          ),
                        }}
                      />
                    </div>
                    <div className={classes.miniFormActionButtons}>
                      <Button
                        id="btnAccountCancelPassword"
                        label="Cancel"
                        variant={ButtonVariant.SecondaryLink}
                        onClick={this.togglePasswordForm}
                      />
                      <Button
                        id="btnAccountSavePassword"
                        className={classes.saveButton}
                        label="Save password"
                        variant={ButtonVariant.Primary}
                        type="submit"
                        onClick={this.savePassword}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}></div>
                </div>
              </div>
            ) : (
              <Button
                id="btnAccountChangePassword"
                label="Change password"
                icon={svgIcons.LockFilled}
                variant={ButtonVariant.BackgroundLink}
                onClick={this.togglePasswordForm}
              />
            )}
          </div>
        </div>
        <div className={classes.inputRow}>
          <div className={classes.inputWrapper}>
            <Typography variant={Variant.SubHeading} className={classes.mb}>
              Leave HubSync
            </Typography>
            <Button
              id="btnAccountDelete"
              label="Delete account"
              icon={svgIcons.Delete}
              variant={ButtonVariant.BackgroundLinkDanger}
              onClick={this.deleteUser}
            />
          </div>
        </div>
      </>
    );
  };

  private deleteUser = () => {
    this.props.deleteUser();
  };

  private changeEmail = () => {
    this.props.changeUserEmail({
      data: {
        currentPassword: this.state.fields.currentPassword,
        email: this.state.fields.email,
      },
    });
  };

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [event.target.name]: event.target.value.trim(),
      },
    });
  };

  private savePassword = () => {
    this.props.updatePassword({
      userId: this.props.user.id,
      data: {
        currentPassword: this.state.fields.oldPassword,
        newPassword: this.state.fields.newPassword,
      },
    });
  };

  private toggleEmailForm = () => {
    this.setState({
      emailFormActive: !this.state.emailFormActive,
      passwordFormActive: false,
      showNewPassword: false,
      showCurrentPassword: false,
      fields: {
        ...this.state.fields,
        email: this.props.user.email,
        currentPassword: '',
        oldPassword: '',
        newPassword: '',
      },
    });
  };

  private togglePasswordForm = () => {
    this.setState({
      emailFormActive: false,
      passwordFormActive: !this.state.passwordFormActive,
      showNewPassword: false,
      showCurrentPassword: false,
      fields: {
        ...this.state.fields,
        email: this.props.user.email,
        currentPassword: '',
        oldPassword: '',
        newPassword: '',
      },
    });
  };

  private setActiveTab = (tab: string) => {
    this.setState({ tab });
  };

  private onClose = () => {
    this.props.close();
  };
}

const mapDispatchToProps: DispatchProps = {
  close: closeModal,
  updateUser: updateUser.request,
  fetchUser: fetchUser.request,
  updatePassword: changePassword.request,
  deleteUser: deleteUser.request,
  changeUserEmail: changeUserEmail.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(AccountView));
