import * as React from 'react';
import svgIcons from 'styles/svgIcons';
import { formatDate } from 'utilities/dates';
import IconButton, { Color, Size } from 'components/IconButton';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import Button, { Variant as ButtonVariant } from 'components/Button';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from 'components/DocumentPreview/Header.style';
import { State as ReduxState } from 'reducers';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { downloadFiles } from 'pages/Files/Services';

interface OwnProps {
  document: any;
  onBack: () => void;
}

interface StateProps {
  permissions: string[];
}

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

class Header extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { document, onBack, classes } = this.props;
    const lastModifiedDate = formatDate(document.modifiedAt, 'MM/DD/YYYY');
    const lastModifiedTime = formatDate(document.modifiedAt, 'hh:mmA');

    return (
      <div className={classes.root}>
        <div className={classes.section}>
          <IconButton
            icon={svgIcons.Dropleft}
            color={Color.DarkGray}
            size={Size.Large}
            onClick={onBack}
            className={classes.backButton}
          />
          <Typography variant={TypographyVariant.Heading}>
            {document.title}
          </Typography>
          <Typography variant={TypographyVariant.Notes} className={classes.date}>
            {`Last Modified: ${lastModifiedDate}`}
          </Typography>
          <Typography variant={TypographyVariant.Notes} className={classes.time}>
            {lastModifiedTime}
          </Typography>
        </div>
        {this.props.permissions.includes('files:export') && (
          <div className={classes.section}>
            <Button
              label="Download"
              variant={ButtonVariant.Primary}
              icon={svgIcons.Download}
              onClick={this.handleDownload}
            />
          </div>
        )}
      </div>
    );
  };

  private handleDownload = (): void => {
    const { document } = this.props;
    downloadFiles([document], document.workspaceID).then(res => {
      if (res.type === 'download') {
        const a = window.document.createElement('a');
        a.href = res.downloadUrl;
        a.download = '';
        a.click();
        a.parentNode?.removeChild(a);
      }
    });
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    permissions: getWorkspacePermissions(state),
  };
};

export default withStyles(styles)(connect<StateProps>(mapStateToProps)(Header));
