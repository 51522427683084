import { ActionType, getType } from 'typesafe-actions';
import produce from 'immer';

import { actions as workspaceActions } from 'data/workspaces/workspaces.actions';
import { actions as databaseActions } from 'data/databases/databases.actions';
import { actions as taskDatabaseActions } from 'data/taskdbs/taskdbs.actions';
import { DatabasesState } from 'data/databases/databases.types';
import { groupById } from 'utilities/collections';
import { CollectionTypes } from 'types/schema';
import { actions as CollectionActions } from 'data/collections/collections.actions.new';
import { DatabaseNode } from 'types/response/databaseNode';
import { getCollectionTypeFromUrl } from 'utilities/createUrl';
import LoadingState from 'data/LoadingState';

export const initialState: DatabasesState = {
  current: null,
  loadingState: LoadingState.Unloaded,
  byId: {},
  ids: [],
  socket: null,
};

type Actions = ActionType<
  typeof databaseActions
  | typeof taskDatabaseActions
  | typeof workspaceActions.setCurrentWorkspace
  | typeof CollectionActions>;

const reducer = (state: DatabasesState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(CollectionActions.fetchCollectionRequest): {
      return produce(state, draft => {
        if (getCollectionTypeFromUrl(action.payload.url) === CollectionTypes.taskdbs) {
          draft.loadingState = LoadingState.Loading;
        }
      });
    }

    case getType(CollectionActions.fetchCollectionFailure): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.taskdbs) {
          draft.loadingState = LoadingState.Error;
        }
      });
    }
    case getType(CollectionActions.fetchCollectionSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.taskdbs) {
          draft.loadingState = LoadingState.Loaded;
          const { byId, ids } = groupById(action.payload.nodes.filter(node => node['type'] === 'task'));
          draft.byId = byId;
          draft.ids = ids;
        }
      });
    }
    case getType(CollectionActions.copyCollectionSuccess):
    case getType(CollectionActions.createCollectionItemImportSuccess):
    case getType(CollectionActions.createCollectionItemSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.taskdbs) {
          // @ts-ignore
          draft.byId[action.payload.id] = action.payload as DatabaseNode;
          // @ts-ignore
          draft.ids.push(action.payload.id);
        }
      });
    }
    case getType(databaseActions.updateDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.taskdbs) {
          // @ts-ignore
          draft.byId[action.payload.id] = {
            ...draft.byId[action.payload.id],
            ...action.payload,
          };
        }
      });
    }
    case getType(databaseActions.deleteDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.taskdbs) {
          delete draft.byId[action.payload.id];
          draft.ids.splice(draft.ids.findIndex(id => id === action.payload.id), 1);
        }
      });
    }
    case getType(taskDatabaseActions.setCurrentTaskDatabase): {
      return produce(state, draft => {
        draft.current = action.payload;
      });
    }
    case getType(workspaceActions.setCurrentWorkspace): {
      return produce(state, draft => {
        draft.current = null;
        draft.byId = {};
        draft.ids = [];
        if (draft.loadingState !== LoadingState.Loading) {
          draft.loadingState = LoadingState.Unloaded;
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
