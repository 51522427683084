import { getItemsApiEndpoint } from 'env';
import { State } from 'reducers';
import { delay } from 'redux-saga';
import { call, put, take, select } from 'redux-saga/effects';
import * as request from 'superagent';
import { parseURI } from 'utilities/parseURI';
import { CommonNode } from 'types/response';

import { Column } from '../../types/schema';
import { uuidv4 } from '../../utilities/common';
import getAuthHeaders from '../../utilities/getAuthHeaders';
import { getUrlsLastItem } from '../../utilities/queryParams';
import { UpdateNodeRequestPayload, UpdateRecords } from '../collections/collections.actions.new';
import { getAllNodes } from '../grid-options/gridOptions.selector';
import { CreateItemRequestPayload } from './items.types';

const WAIT_FOR_STATE_UPDATE = 10;
const DEFAULT_QUERIES = { offset: 0, limit: 1000 };

/**
 *
 * @param node
 */
const parseNode = (getURL: string, node: CommonNode & { createdAt: string }) => ({
  ...node,
  id: node.id.toString(),
  apiURI: `${getURL}/${node.id}`,
  createdDate: node.createdAt,
  modifiedDate: node.modifiedAt,
});

class ItemsService {
  * fetchItems(url: string, sheetId: string) {
    const {
      workspaceId,
      databaseId,
      dbSlugV2,
    } = parseURI(url);
    const getUrl = `${getItemsApiEndpoint()}/workspaces/${workspaceId}/${dbSlugV2}/${databaseId}/sheets/${sheetId}/items`;
    try {
      const headers = yield getAuthHeaders();
      const result = yield call(() => request.get(getUrl).set(headers));
      // eslint-disable-next-line no-console
      console.debug('Starting to convert to array', new Date());
      result.body = Object.values(result.body);
      // eslint-disable-next-line no-console
      console.debug('Finished converting to array', new Date());
      return result.body.map(parseNode.bind(null, getUrl));
    } catch (e) {
      console.error('Failed to fetch items', e.toString());
      return [];
    }
  }

  * fetchPartialItems(url: string, sheetId: string, opt = DEFAULT_QUERIES) {
    const {
      workspaceId,
      databaseId,
    } = parseURI(url);
    const queries = `offset=${opt.offset}&limit=${opt.limit}`;
    const path = `workspaces/${workspaceId}/databases/${databaseId}/sheets/${sheetId}`;
    const itemsUrl = `${getItemsApiEndpoint()}/${path}/items`;
    const getUrl = `${itemsUrl}/paginate?${queries}`;
    try {
      const headers = yield getAuthHeaders();
      const result = yield call(() => request.get(getUrl).set(headers));
      const { pageInfo } = result.body;
      const values = Object.values(result.body.data);

      return {
        pageInfo,
        items: values.map(parseNode.bind(null, itemsUrl)),
      };
    } catch (e) {
      console.error('Failed to fetch items', e.toString());
      return { error: e.toString() };
    }
  }

  * createItem(payload: CreateItemRequestPayload, state: State) {
    const {
      workspaceId,
      databaseId,
      dbSlugV2,
    } = parseURI(payload.url);
    const sheetId = state.sheets.ids[0];
    const postUrl = `${getItemsApiEndpoint()}/workspaces/${workspaceId}/${dbSlugV2}/${databaseId}/sheets/${sheetId}/items`;

    // @ts-ignore
    payload.data.fieldTypes = getFieldTypes(state, payload.data.fields);

    try {
      const headers = yield getAuthHeaders();
      const { body: node } = yield call(() => request.post(postUrl).set(headers).send({ ...payload.data, title: undefined }));
      const apiURI = `${postUrl}/${node.id}`;
      return {
        body: {
          node: {
            ...node,
            id: node.id.toString(),
            apiURI,
          },
        },
      };
    } catch (e) {
      console.error('Failed to create item', e.toString());
      return null;
    }
  }
  * putOrPatchNode(payload: UpdateNodeRequestPayload, state: State, version: number) {
    const { url } = payload;
    const lastRequestId = getLastRequestId(url);
    const currentRequestId = uuidv4();
    addRequestIntoTheQueue(payload.url, currentRequestId);

    try {
      if (lastRequestId) {
        yield take(lastRequestId);
        yield delay(WAIT_FOR_STATE_UPDATE);
      }
      const { app: { validCredentials } } = state;
      const { data, isFullObject } = payload;
      if (validCredentials) {
        const req = isFullObject ? request.put(url) : request.patch(url);
        const currentNode = yield getCurrentAllNodes(payload.url);
        data.version = currentNode?.version || version;
        // @ts-ignore
        data.fieldTypes = getFieldTypes(state, payload.data.fields);
        const primaryFieldID = getPrimaryField(state);
        if (primaryFieldID && data.fields?.[primaryFieldID]) {
          data.primaryFieldID = primaryFieldID;
        }

        const headers = yield getAuthHeaders();
        const { body: node } = yield call(() => req.set(headers).send(data));
        yield put({ type: currentRequestId });
        removeRequestFromTheQueue(payload.url, currentRequestId);
        return {
          body: {
            node: {
              ...node,
              id: node.id.toString(),
              apiURI: url,
            },
          },
        };
      }
    } catch (e) {
      yield put({ type: currentRequestId });
      removeRequestFromTheQueue(payload.url, currentRequestId);
      console.error('Failed to patch node', e.toString());

      throw e;
    }
  }
  * singleDelete(url: string, id: string, state: State) {
    try {
      const {
        workspaceId,
        databaseId,
        dbSlugV2,
      } = parseURI(url);
      const sheetId = state.sheets.ids[0];
      const { app: { validCredentials } } = state;
      if (validCredentials) {
        const headers = yield getAuthHeaders();
        const postUrl = `${getItemsApiEndpoint()}/workspaces/${workspaceId}/${dbSlugV2}/${databaseId}/sheets/${sheetId}/items/${id}`;
        yield call(() => request.delete(postUrl).set(headers).send());
        return [id];
      }
    } catch (e) {
      console.error('Failed to delete item', e.toString());
      return null;
    }
  }
  * bulkDelete(url: string, ids: string[], state: State) {
    try {
      const {
        workspaceId,
        databaseId,
        dbSlugV2,
      } = parseURI(url);
      const sheetId = state.sheets.ids[0];
      const { app: { validCredentials } } = state;
      if (validCredentials) {
        const headers = yield getAuthHeaders();
        const postUrl = `${getItemsApiEndpoint()}/workspaces/${workspaceId}/${dbSlugV2}/${databaseId}/sheets/${sheetId}/items/bulk-delete`;
        yield call(() => request.post(postUrl).set(headers).send(ids.map(id => parseInt(id))));
        return ids;
      }
    } catch (e) {
      console.error('Failed to delete items', e.toString());
      return null;
    }
  }
  * bulkUpdate(input: UpdateRecords, state: State) {
    try {
      const databaseId = state.databases.current || state.taskDatabases.current;
      const workspaceId = state.workspaces.current;
      const sheetId = state.sheets.ids[0];
      const { app: { validCredentials } } = state;
      if (validCredentials) {
        const itemsIdMap = input.items.reduce((obj: { [key: string]: boolean }, val: string) => {
          obj[val] = true;
          return obj;
        }, {});

        const items = state.collections.collections.viewport.nodes
          .filter(node => itemsIdMap[node.id])
          .map(node => ({
            id: parseInt(node.id),
            version: node.version,
          }));

        const payload = {
          fields: {
            [input.field.replace('fields.', '')]: input.payload,
          },
          items,
        };
        const primaryFieldID = getPrimaryField(state);
        const { dbSlugV2 } = parseURI(input.locationData.url);
        const headers = yield getAuthHeaders();
        const patchUrl = `${getItemsApiEndpoint()}/workspaces/${workspaceId}/${dbSlugV2}/${databaseId}/sheets/${sheetId}/items/bulk`;
        yield call(() => request.patch(patchUrl).set(headers).send({ ...payload, primaryFieldID }));
      }
    } catch (e) {
      console.error('Failed to batch update items', e.toString());
      return null;
    }
  }
}

const getPrimaryField = (state: State): string | undefined => {
  const fields = state.collections.collections?.schema?.properties?.fields?.properties;
  if (fields) {
    const primaryField = Object.values(fields).find((field: Column) => field.isPrimary);
    if (primaryField) {
      return primaryField?._id;
    }
  }
};

const getFieldTypes = (state, fields) => {
  const fieldTypes = {};
  const gridFields = state.collections.collections.schema.properties.fields.properties;
  for (const key in gridFields) {
    // @ts-ignore
    if (fields?.[key]) {
      // @ts-ignore
      const fieldValues = Object.values(gridFields);
      // @ts-ignore
      const field = fieldValues.find(item => item.id == key);
      // @ts-ignore
      if (field?.fieldType === 'account') {
        fieldTypes[key] = 'account';
      }
    }
  }
  return fieldTypes;
};

function* getCurrentAllNodes(url: string) {
  const state = yield select();
  const nodes = getAllNodes(state);
  const currentId = getUrlsLastItem(url);
  return nodes.find(node => node.id === currentId);
}

const itemRequest = {};

const addRequestIntoTheQueue = (url, request) => {
  if (!itemRequest[url]) {
    itemRequest[url] = [];
  }
  itemRequest[url].push(request);
};

const removeRequestFromTheQueue = (url: string, id: string) => {
  itemRequest[url] = itemRequest[url].filter(requestId => requestId !== id);
};

const getLastRequestId = (url: string) => {
  if (itemRequest[url]) {
    return itemRequest[url][itemRequest[url].length - 1];
  }
  return null;
};

export default new ItemsService();
