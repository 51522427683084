import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { getApiV2Url } from 'env';
import * as qs from 'qs';
import { useState } from 'react';
import { DefaultRootState } from 'react-redux';
import { getRequest } from 'utilities/httpRequests';

type SortModel = { colId: string; sort: 'asc' | 'desc' };
type FilterModel = {
  // TODO: use the beautiful generics to make field a key of a T
  [field: string]: {
    filterType: 'text' | 'number';
    // TODO: add all suported filter types
    // TODO: add possibility to extend to more filter types
    type: 'contains' | 'notContains' | 'equals';
    filter: string;
  };
};

function createSortParam(sortModel: Array<SortModel>) {
  return sortModel
    .map((sortObj) =>
      sortObj.sort === 'asc' ? sortObj.colId : '-' + sortObj.colId,
    )
    .join(',');
}

const operationRegistry = {
  contains: 'c',
  notContains: 'nc',
  equal: 'eq',
  // TODO: add all supported operations
  // TODO: make this more extensible (Open closed principle)
};
function mapOperationName(filterOperation: string) {
  return operationRegistry[filterOperation];
}

function createFilterParam(filterModel?: FilterModel) {
  if (!filterModel) return undefined;

  const filterObject = Object.keys(filterModel).reduce(
    (accumulator, currentKey) => {
      const filterObject = filterModel[currentKey];
      const operation = {};
      operation[mapOperationName(filterObject.type)] = filterObject.filter;
      accumulator[currentKey] = operation;

      return accumulator;
    },
    {},
  );

  return qs.stringify(filterObject, { delimiter: ';' });
}

export function objectToQueryParams(object: any): string {
  return Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&');
}

export function createPaginationQueryParams(
  limit: number,
  offset: number,
  sort: Array<SortModel>,
  filter?: FilterModel,
) {
  const sortString = createSortParam(sort);
  const filterString = createFilterParam(filter);
  let queryParams: {
    offset: number;
    limit: number;
    sort?: string;
    filter?: string;
  } = { offset, limit: limit - offset };
  if (sortString.length) {
    queryParams = { ...queryParams, sort: sortString };
  }
  if (filterString?.length) {
    queryParams = { ...queryParams, filter: filterString };
  }

  return queryParams;
}
// TODO: needs love.. The idea of this datasource creator is to be a fabric for datasources based on collections.
function createDatasource(
  collectionName: string,
  state: DefaultRootState,
): IDatasource {
  return {
    rowCount: 0,
    getRows: async (params: IGetRowsParams): Promise<void> => {
      const { startRow: offset, endRow, sortModel, filterModel } = params;

      try {
        const queryString = createPaginationQueryParams(
          endRow,
          offset,
          sortModel,
          filterModel,
        );

        const response = (
          await getRequest(
            `${getApiV2Url()}/users/query?${objectToQueryParams(queryString)}`,
            state,
          )
        ).body;

        params.successCallback(response.data, response.pageInfo.totalResults);
      } catch (err) {
        console.error(err);
        params.failCallback();
      }
    },
  };
}

function useGridDatasource(
  collectionName: string,
  state: DefaultRootState,
): IDatasource {
  const [datasource] = useState(createDatasource(collectionName, state));

  return datasource;
}

export default useGridDatasource;
