import { DatabasesState } from 'data/databases/databases.types';

import { State } from '../../reducers';
import { DatabaseNode } from 'types/response/databaseNode';
import LoadingState from 'data/LoadingState';

export const getDatabasesState = (state: State): DatabasesState => state.databases;
export const getCurrentDatabaseId = (state: State): string | null => getDatabasesState(state).current || null;
export const getAllDatabasesById = (state: State) => getDatabasesState(state).byId;
export const getDatabasesIds = (state: State): string[] => state.databases.ids;
export const getAllDatabases = (state: State): DatabaseNode[] => Object.values(getAllDatabasesById(state));
export const getCurrentDatabase = (state: State): DatabaseNode | undefined =>
  getAllDatabasesById(state)[getCurrentDatabaseId(state) || ''];

export function getLoading(state: State): LoadingState {
  return getDatabasesState(state).loadingState;
}
