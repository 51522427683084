import * as React from 'react';

import AgGridApi from 'components/AgGrid/AgGridApi';
import {
  AG_CELL_WRAPPER,
  EXPAND_RECORD,
  ROW_INDEX_VALUE,
  ROW_NUMBER_ID,
  SELECTABLE_ROW,
} from 'components/DataGrid/columns/constants';
import Confirm from 'components/Modals/Confirm';
import Modal from 'components/Modals/Modal';
import { location } from 'data/app/selectors';
import { Actions as collectionActions } from 'data/collections/collections.actions';
import { IdRendererParams } from 'data/collections/collections.reducer';
import {
  getIdRendererParams,
  nodeLoadingState,
  rowIndexMap,
  schemaSelector,
} from 'data/collections/collections.selectors';
import { resetFilterModel } from 'data/grid-options/filterModel.actions';
import { filtersSelector, sortedAndFilteredNodes, sortSelector } from 'data/grid-options/gridOptions.selector';
import { resetSortModel } from 'data/grid-options/sortModel.actions';
import { openNodeModal } from 'data/modals/actions';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { Location } from 'history';
import * as _ from 'lodash/fp';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { injectGlobal } from 'styled-components';
import { FilterModel, SortModelState } from 'types/gridOptions';
import { CommonNode } from 'types/response';
import { Schema } from 'types/schema';
import { createPartialNodeFromDefaultSchemaValues } from 'utilities/collections';
import { normalizeURL } from 'utilities/format';

enum RendererModes {
  BROWSE = 'browse',
  INSERT = 'insert'
}

export enum InsertPosition {
  ABOVE = 'above',
  BELOW = 'below'
}

interface StateProps {
  rowIndexMap: Record<string, number>;
  filterModel: FilterModel;  // Needed only to re-render when filters change
  sortModel: SortModelState; // Needed only to re-render when sorts change
  location: Location;
  schema: Schema;
  nodeLoadingState: string;
  permissions?: string[];
  idRendererParams: IdRendererParams | undefined | null;
  nodes: CommonNode[];
}

interface DispatchProps {
  createNode: typeof collectionActions.createNode;
  openNodeModal(node: Partial<CommonNode>, windowTitle?: string, enableSwitcher?: boolean): void;
  resetSortModel: () => void;
  resetFilterModel: () => void;
  warningToast: typeof notifications.warn;
}

export interface IdRendererProps {
  api?: AgGridApi<CommonNode>;
}

interface State {
  modalTitle: string;
  rendererMode: RendererModes;
}

export type Props
  = StateProps
  & DispatchProps
  & IdRendererProps
  ;

export class IndexRenderer extends React.Component<Props, State> {
  state = {
    modalTitle: '',
    rendererMode: RendererModes.BROWSE,
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!_.isEqual(prevProps.idRendererParams, this.props.idRendererParams)) {
      const insertPosition = this.props?.idRendererParams?.insertPosition;
      if (this.props.idRendererParams) {
        const { actionType } = this.props.idRendererParams;
        switch (actionType) {
          case 'validateInsertRecord':
            this.validateInsertRecord(insertPosition === 'above' ? InsertPosition.ABOVE : InsertPosition.BELOW);
            break;
          default:
            break;
        }
      }
    }
  }

  private onClose = (): void => {
    setTimeout(() => {
      if (this.state.rendererMode === RendererModes.INSERT) {
        this.setState({ rendererMode: RendererModes.BROWSE });
        this.props.warningToast({ message: 'You cannot create a new row when sorting/filtering is applied.' });
      }
    }, 0);
  };

  private onConfirm = (): void => {
    this.setState({ rendererMode: RendererModes.BROWSE });
    this.props.resetSortModel();

    const keys = {};
    Object.keys(this.props.filterModel.searchFilters)
      .forEach(key => {
        keys[key] = ' ';
      });

    this.props.api && this.props.api.setFilterModel(keys);
    setTimeout(() => {
      this.props.resetFilterModel();
      this.handleInsertRecord();
    }, 0);
  };

  private validateInsertRecord = (position: InsertPosition): void => {
    const { quickSearch, regularFilters, searchFilters } = this.props.filterModel;
    const isFiltered = quickSearch || regularFilters.length || Object.keys(searchFilters).length;
    const isSorted = this.props.sortModel.allIds.length > 0;

    if (isFiltered && isSorted) {
      this.setState({
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove filters and sorting?',
      });
      return;
    }

    if (isSorted) {
      this.setState({
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove sorting?',
      });
      return;
    }

    if (isFiltered) {
      this.setState({
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove filters?',
      });
      return;
    }

    this.handleInsertRecord();
  };

  private handleInsertRecord = (): void => {
    if (!this.props.idRendererParams) return;

    const fields = this.props.idRendererParams.node.data.fields;
    const activeGroups = this.props?.api?.getRowGroupColumns();
    const activeGroupsColumnIds = activeGroups?.map(groupColumn => parseInt(groupColumn.getColId().replace('fields.', ''), 10));
    let initData;
    if (activeGroupsColumnIds) {
      initData = activeGroupsColumnIds.reduce((result, columnId) => {
        result[columnId] = fields[columnId];
        return result;
      }, {});
    }

    const rowIndex = this.props.nodes.findIndex(item => item.id === this.props.idRendererParams?.node?.id);
    const insertPosition = this.props?.idRendererParams?.insertPosition;
    const rowPosition = insertPosition === InsertPosition.ABOVE ? rowIndex : rowIndex + 1;
    const url = normalizeURL(this.props.location.pathname);
    const data: Partial<CommonNode> = createPartialNodeFromDefaultSchemaValues<CommonNode>(this.props.schema);

    this.props.createNode({
      url,
      data,
      rowPosition,
      initData,
    });

    setTimeout(() => {
      this.props?.api?.refreshCells([ROW_NUMBER_ID]);
    }, 100);
  };

  public render = (): JSX.Element => {
    return (
      <div>
        {this.state.rendererMode === RendererModes.INSERT && (
          <Modal onClose={this.onClose}>
            <Confirm
              title={this.state.modalTitle}
              close={this.onClose}
              onConfirm={this.onConfirm}
              confirmLabel="Remove"
            />
          </Modal>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  rowIndexMap: rowIndexMap(state),
  filterModel: filtersSelector(state),
  sortModel: sortSelector(state),
  location: location(state),
  schema: schemaSelector(state),
  nodeLoadingState: nodeLoadingState(state),
  permissions: getWorkspacePermissions(state),
  idRendererParams: getIdRendererParams(state),
  nodes: sortedAndFilteredNodes(state),
});

const mapDispatchToProps: DispatchProps = {
  createNode: collectionActions.createNode,
  openNodeModal,
  resetSortModel,
  resetFilterModel,
  warningToast: notifications.warn,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexRenderer);

injectGlobal`
  .${SELECTABLE_ROW} {
    .${ROW_NUMBER_ID} {
      .${AG_CELL_WRAPPER} {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
      }

      .ag-selection-checkbox {
        display: none;
      }
    }

    &.ag-row-selected {
      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE} {
          display: none;
        }
      }
    }

    .${EXPAND_RECORD} {
      display: none;
    }

    &.ag-row-hover {
      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE} {
          display: none;
        }

        .${EXPAND_RECORD} {
          display: flex;
          width: 16px;
        }
      }
    }
  }

  .grid-is-scrolled {

    .${SELECTABLE_ROW} {
       &.ag-row-hover.ag-row-last {

        .${ROW_NUMBER_ID} .ag-cell-wrapper .expand-record > button:last-child  {
          position: fixed;
          right: auto;
          bottom: 24px;
          margin-left: 8px;
        }
       }
     }

    &.has-scrollbars {
      .${SELECTABLE_ROW}.ag-row-hover.ag-row-last  {
        .${ROW_NUMBER_ID} .ag-cell-wrapper .expand-record > button:last-child  {
          bottom: 41px;
        }
      }
    }
  }
`;
