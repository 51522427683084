import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { EmailData } from '../../data';
import Typography, { Variant } from '../../../Typography';

interface Props extends WithStyles<ClassKey> {
  data: EmailData;
  onDataUpdate(data: EmailData): void;
}

function Email(props: Props): JSX.Element {
  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={props.classes.description}>
        A valid email address (e.g. john@example.com).
      </Typography>
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'textField'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  textField: { marginTop: 8 },
});

export default styles(Email);
