import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

interface Props extends WithStyles<ClassKey> {
  id?: string;
  children: React.ReactNode;
  onClose(): void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
}

function Modal(props: Props): JSX.Element {
  const {
    id,
    children,
    classes,
    onClose,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
  } = props;
  return (
    <Dialog
      id={id}
      open={true}
      onClose={onClose}
      classes={classes}
      disableEnforceFocus
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth="md"
    >
      {children}
    </Dialog>
  );
}

type ClassKey = 'paper' | 'root';

const fluidModalStyles = withStyles<ClassKey>({
  paper: { minWidth: '510px', minHeight: '510px' },
  root: {},
});

export const FluidModal = fluidModalStyles(Modal);

const styles = withStyles<ClassKey>({
  paper: { width: 'auto' },
  root: {},
});

export default styles(Modal);
