import React, { useState, useEffect, useMemo } from 'react';
import { TextField, WithStyles, withStyles } from '@material-ui/core';
import { xorBy, unionBy, filter } from 'lodash';
import { Arrows, RoleBox } from '.';
import { styles } from './AssignRoles.styles';
import { BoxItem } from './RoleBox';
import Button, { Variant as ButtonVariant } from '../../../components/Button';
import SelectSearchBox from 'components/SelectSearchBox/SelectSearchBox';
import ChoiceItem from 'components/NodeFieldData/choice/ChoiceItem/ChoiceItem';
import * as Colors from 'styles/colors';


export type IAssigns = {
  [role: string]: BoxItem[];
};

interface Office {
  id: string;
  text: string;
}

function filterItems(items: BoxItem[], filterValue?: string, office?: string): BoxItem[] {
  let result: BoxItem[] = [];
  const regex = new RegExp(`${filterValue}`, 'i');
  result = filter(items, (item: BoxItem) => (!office || office === item?.meta?.office?.toLowerCase()) && regex.test(item?.meta?.clientName || item.title));
  return result;
}

export interface AssignRolesProps extends WithStyles<typeof styles> {
  title: string;
  onAssignChange: (assigns: IAssigns) => void;
  offices?: Office[];
  noAccessBoxItems?: BoxItem[];
  readerBoxItems?: BoxItem[];
  editorBoxItems?: BoxItem[];
  adminBoxItems?: BoxItem[];
}

function AssignRoles({
  title,
  onAssignChange,
  classes,
  noAccessBoxItems,
  readerBoxItems,
  editorBoxItems,
  adminBoxItems,
  offices,
}: AssignRolesProps): React.ReactElement<AssignRolesProps> {
  const [office, setOffice] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState<string>('');

  const [noAccessItems, setNoAccessItems] = useState<BoxItem[]>(noAccessBoxItems || []);
  const [noAccessSelected, setNoAccessSelected] = useState<BoxItem[]>([]);
  const [readerItems, setReaderItems] = useState<BoxItem[]>(readerBoxItems || []);
  const [readerSelected, setReaderSelected] = useState<BoxItem[]>([]);
  const [editorItems, setEditorItems] = useState<BoxItem[]>(
    editorBoxItems || [],
  );
  const [editorSelected, setEditorSelected] = useState<BoxItem[]>([]);
  const [adminItems, setAdminItems] = useState<BoxItem[]>(adminBoxItems || []);
  const [adminSelected, setAdminSelected] = useState<BoxItem[]>([]);

  useEffect(() => {
    onAssignChange({
      Reader: readerItems,
      Editor: editorItems,
      Administrator: adminItems,
    });
  }, [readerItems, editorItems, adminItems]);


  const getSelectedItemsByBox = (
    box: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator',
  ) => {
    switch (box) {
      case 'Reader':
        return readerSelected;
      case 'Editor':
        return editorSelected;
      case 'Administrator':
        return adminSelected;
      case 'NO_ACCESS':
      default:
        return noAccessSelected;
    }
  };

  const getItemsByBox = (
    box: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator',
  ) => {
    switch (box) {
      case 'Reader':
        return readerItems;
      case 'Editor':
        return editorItems;
      case 'Administrator':
        return adminItems;
      case 'NO_ACCESS':
      default:
        return noAccessItems;
    }
  };
  const getSelectedSetterByBox = (
    box: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator',
  ) => {
    switch (box) {
      case 'Reader':
        return setReaderSelected;
      case 'Editor':
        return setEditorSelected;
      case 'Administrator':
        return setAdminSelected;
      case 'NO_ACCESS':
      default:
        return setNoAccessSelected;
    }
  };

  const getSetterByBox = (
    box: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator',
  ) => {
    switch (box) {
      case 'Reader':
        return setReaderItems;
      case 'Editor':
        return setEditorItems;
      case 'Administrator':
        return setAdminItems;
      case 'NO_ACCESS':
      default:
        return setNoAccessItems;
    }
  };

  const handleMove = (operation: {
    from: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator';
    to: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator';
  }) => {
    // Peparing data
    const originBoxItems = getItemsByBox(operation.from);
    const originBoxSelectedItems = getSelectedItemsByBox(operation.from);
    const newOriginBoxItems = xorBy(
      originBoxItems,
      originBoxSelectedItems,
      'id',
    );

    const destinationBoxItems = getItemsByBox(operation.to);
    const newDestinationBoxItems = unionBy(
      originBoxSelectedItems,
      destinationBoxItems,
      'id',
    );

    // Executing operation
    const originSetter = getSetterByBox(operation.from);
    const destinationSetter = getSetterByBox(operation.to);

    originSetter(newOriginBoxItems);
    destinationSetter(newDestinationBoxItems);

    // Cleaning up after operation
    const originSelectedSetter = getSelectedSetterByBox(operation.from);
    const destinationSelectedSetter = getSelectedSetterByBox(operation.to);
    originSelectedSetter([]);
    destinationSelectedSetter(originBoxSelectedItems);
  };

  const handleMoveAll = (operation: {
    to: 'NO_ACCESS' | 'Reader' | 'Editor' | 'Administrator';
  }) => {
    const originReaderBoxItems = getItemsByBox('Reader');
    const originEditorBoxItems = getItemsByBox('Editor');
    const originAdministratorBoxItems = getItemsByBox('Administrator');

    const newReaderBoxItems = xorBy(
      originReaderBoxItems,
      originReaderBoxItems,
      'id',
    );

    const newEditorBoxItems = xorBy(
      originEditorBoxItems,
      originEditorBoxItems,
      'id',
    );

    const newAdministratorBoxItems = xorBy(
      originAdministratorBoxItems,
      originAdministratorBoxItems,
      'id',
    );

    const destinationBoxItems = getItemsByBox(operation.to);
    const newDestinationBoxItems = unionBy(
      destinationBoxItems,
      originReaderBoxItems,
      originEditorBoxItems,
      originAdministratorBoxItems,
      'id',
    );

    const originReaderSetter = getSetterByBox('Reader');
    const originEditorSetter = getSetterByBox('Editor');
    const originAdministratorSetter = getSetterByBox('Administrator');
    const destinationSetter = getSetterByBox(operation.to);

    originReaderSetter(newReaderBoxItems);
    originEditorSetter(newEditorBoxItems);
    originAdministratorSetter(newAdministratorBoxItems);
    destinationSetter(newDestinationBoxItems);

    const originReaderSelectedSetter = getSelectedSetterByBox('Reader');
    const originEditorSelectedSetter = getSelectedSetterByBox('Editor');
    const originAdministratorSelectedSetter = getSelectedSetterByBox('Administrator');
    const destinationSelectedSetter = getSelectedSetterByBox(operation.to);
    originReaderSelectedSetter([]);
    originEditorSelectedSetter([]);
    originAdministratorSelectedSetter([]);
    destinationSelectedSetter([]);
  };

  const handleNoAccessSelection = (items: BoxItem[]) => {
    setNoAccessSelected(items);
  };

  const handleReaderSelection = (items: BoxItem[]) => {
    setReaderSelected(items);
  };

  const handleEditorSelection = (items: BoxItem[]) => {
    setEditorSelected(items);
  };

  const handleAdminSelection = (items: BoxItem[]) => {
    setAdminSelected(items);
  };

  const handleOfficeSelection = ({ id }) => {
    setOffice(id);
  };

  const officesItems = useMemo(()=> {
    return offices ? offices.map(({ id, text }) => ({
      id: id,
      text: text,
      element: <ChoiceItem choice={{ label: text, color: id ? Colors.jungleGreen : Colors.blackGrey }} />,
    })): [];
  }, [offices]);

  return (
    <div className={classes.field}>
      <div className={classes.searchBoxContainer}>
        <div className={classes.titleSearchBox}>
          <h2 className={classes.title}>{title}</h2>
          <TextField
            id="txt-search-workspaces"
            className={classes.searchBox}
            style={{
              height: 30,
              padding: 0,
              margin: 0,
            }}
            inputProps={{
              className: classes.input,
              style: {
                borderRadius: 1,
                borderColor: '#CFD8DC',
              },
            }}
            margin="dense"
            variant="outlined"
            placeholder="Search for workspace"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchFieldValue(e.target.value);
            }}
            value={searchFieldValue}
          />
        </div>
        <SelectSearchBox
          label="Filter by office"
          classes={{ selectWrapper: classes.selectOffice }}
          items={officesItems}
          onChange={handleOfficeSelection}
          fullWidth
        />
        <Button
          id="btn-remove-all"
          label="Remove from workspaces"
          variant={ButtonVariant.Secondary}
          onClick={() => handleMoveAll({ to: 'NO_ACCESS' })}
          className={classes.buttonRemoveAll}
        />
      </div>
      <div className={classes.container}>
        <RoleBox
          onSelectionChanged={handleNoAccessSelection}
          items={filterItems(noAccessItems, searchFieldValue, office)}
          selectedItems={noAccessSelected}
          title="No access"
          id="NO_ACCESS"
        />
        <Arrows
          id="first"
          moveLeft={() => handleMove({ from: 'Reader', to: 'NO_ACCESS' })}
          moveRight={() => handleMove({ from: 'NO_ACCESS', to: 'Reader' })}
        />
        <RoleBox
          onSelectionChanged={handleReaderSelection}
          items={filterItems(readerItems, searchFieldValue, office)}
          selectedItems={readerSelected}
          title="With reader access"
          id="Reader"
        />
        <Arrows
          id="second"
          moveLeft={() => handleMove({ from: 'Editor', to: 'Reader' })}
          moveRight={() => handleMove({ from: 'Reader', to: 'Editor' })}
        />
        <RoleBox
          onSelectionChanged={handleEditorSelection}
          items={filterItems(editorItems, searchFieldValue, office)}
          selectedItems={editorSelected}
          title="With editor access"
          id="Editor"
        />
        <Arrows
          id="third"
          moveLeft={() => handleMove({ from: 'Administrator', to: 'Editor' })}
          moveRight={() => handleMove({ from: 'Editor', to: 'Administrator' })}
        />
        <RoleBox
          onSelectionChanged={handleAdminSelection}
          items={filterItems(adminItems, searchFieldValue, office)}
          selectedItems={adminSelected}
          title="With admin access"
          id="Administrator"
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(AssignRoles);
