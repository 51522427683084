import * as React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CardsSwitcherButton, Wrapper } from './CardsSwitcher.style';

export interface CardSwitcherProps {
  nextDisabled?: boolean;
  prevDisabled?: boolean;
  onNextClick(): void;
  onPrevClick(): void;
}

const CardSwitcher = ({ nextDisabled, prevDisabled, onNextClick, onPrevClick }: CardSwitcherProps) => {
  return (
    <Wrapper>
      <CardsSwitcherButton
        disabled={prevDisabled}
        onClick={onPrevClick}
      >
        <ExpandLessIcon />
      </CardsSwitcherButton>
      <CardsSwitcherButton
        disabled={nextDisabled}
        onClick={onNextClick}
      >
        <ExpandMoreIcon />
      </CardsSwitcherButton>
    </Wrapper>
  );
};

export default CardSwitcher;
