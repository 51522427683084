import * as React from 'react';
import { Redirect } from 'react-router-dom';

const TaskDBPage = () => {
  const redirectURL = `${document.location.pathname}/tasksheets/default/tasks`;
  return (
    <Redirect to={redirectURL} />
  );
};

export default TaskDBPage;
