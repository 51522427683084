import action from 'utilities/action-creator';
import produce from 'immer';
import { takeLatest, put } from 'redux-saga/effects';
import { get } from 'utilities/httpRequests';

/**
 * CONSTANTS
 */
const FETCH_REQUEST = 'NOTIFICATIONS_UNSEEN_COUNT/FETCH';
const SET_FETCH_ERROR = 'NOTIFICATIONS_UNSEEN_COUNT/SET_FETCH_ERROR';
const SET_COUNT = 'NOTIFICATIONS_UNSEEN_COUNT/SET_COUNT';
const SET_IS_LOADING = 'NOTIFICATIONS_UNSEEN_COUNT/SET_IS_LOADING';
const INC = 'NOTIFICATIONS_UNSEEN_COUNT/INC';

/**
 * TYPES
 */

export interface State {
  count: number;
  isLoading: boolean;
  fetchError: Error | undefined;
}

export const initialState: State = {
  isLoading: false,
  fetchError: undefined,
  count: 0,
};

/**
 * REDUCER
 */
export const reducer = (state = initialState, action): State => {
  switch (action.type) {
    case SET_COUNT: {
      return produce(state, draft => {
        draft.count = action.payload;
      });
    }
    case SET_FETCH_ERROR: {
      return produce(state, draft => {
        draft.fetchError = action.payload;
      });
    }
    case SET_IS_LOADING: {
      return produce(state, draft => {
        draft.isLoading = action.payload;
      });
    }
    case INC: {
      return produce(state, draft => {
        draft.count += action.payload;
      });
    }
    default:
      return state;
  }
};

/**
 * SELECTORS
 */


/**
 * ACTIONS
 */
export const actions = {
  fetch: () => action(FETCH_REQUEST),
  setFetchError: (error) => action(SET_FETCH_ERROR, { payload: error }),
  setCount: (count) => action(SET_COUNT, { payload: count }),
  setIsLoading: (isLoading) => action(SET_IS_LOADING, { payload: isLoading }),
  inc: (step) => action(INC, { payload: step }),
};

/**
 * SAGAS
*/
export const sagas = {
  * request(_action) {
    yield put(actions.setIsLoading(true));
    try {
      const response = yield (get('/notifications/unseen/count?api_version=2'));
      const { count } = response.body;
      yield put(actions.setCount(count));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
};

export const watcher = function* w() {
  yield takeLatest(FETCH_REQUEST, sagas.request);
};
