import { charocalGrey, blueyGrey, dark, slate, disabledGrey, white } from 'styles/colors';

export const headings = {
  h1: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: charocalGrey,
  },
  h2: {
    fontSize: '16px',
    fontWeight: 500,
    color: charocalGrey,
  },
  h4: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: blueyGrey,
  },
  h5: {
    fontSize: '13px',
    fontWeight: 'normal',
    color: blueyGrey,
  },
  h6: {
    fontSize: '11px',
    fontWeight: 500,
    color: blueyGrey,
  },
};

export const body = {
  text: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: dark,
  },
};

export const button = {
  grayText: {
    fontSize: '13px',
    fontWeight: 500,
    color: slate,
  },
  disabledText: {
    fontSize: '13px',
    fontWeight: 500,
    color: disabledGrey,
  },
  whiteText: {
    fontSize: '13px',
    fontWeight: 500,
    color: white,
  },
};

export const truncateText = {
  'overflow': 'hidden',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap',
  'width': '100%',
};
