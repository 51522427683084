import * as React from 'react';

import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';
import * as Colors from 'styles/colors';

export interface ChipProps extends Omit<MuiChipProps, 'color'> {
  color?: string;
  textColor?: string;
  outline?: boolean;
  disableShrink?: boolean;
  hideDelete?: boolean;
}

const hoverable = css`
  filter: brightness(1);
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

const ChipComponent = ({
  color,
  textColor,
  outline,
  disableShrink,
  hideDelete,
  ...props
}: ChipProps) => (
  <MuiChip deleteIcon={!hideDelete ? <DeleteIcon /> : <></>} {...props} />
);

const Chip = styled<ChipProps>(ChipComponent)`
  ${({ onClick }) => (onClick ? hoverable : '')}
  color: ${({ textColor }) => textColor || Colors.charocalGrey};
  cursor: inherit;
  border: 1px solid
    ${({ color, outline }) =>
    outline ? color || Colors.deprecatedDefaultChipColor : 'transparent'};

  &,
  &:hover {
    background-color: ${({ color, outline }) =>
    !outline ? color || Colors.deprecatedDefaultChipColor : 'transparent'};
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    display: inline-block;
  }

  max-width: 100%;
  min-width: 20px;

  overflow: hidden;
  justify-content: flex-start;

  ${({ disableShrink }) => (disableShrink ? 'flex-shrink: 0' : '')}
`;

export default Chip;

const DeleteIcon = styled(Close)`
  font-size: 1em;
  color: inherit;
  margin: 0 8px 0 -8px;
`;
