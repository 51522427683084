import React from 'react';
import PropTypes from 'prop-types';

import FieldEditorWrapper from '../../components/FieldEditorWrapper.tsx';
import wrapCellEditor from '../../components/wrapCellEditor.tsx';

const BarcodeEditor = ({ id, value, onChange }) => (
  <FieldEditorWrapper className="barcode__editor">
    <input
      id={`txt${id}`}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </FieldEditorWrapper>
);

BarcodeEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default wrapCellEditor()(BarcodeEditor);
