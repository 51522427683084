import { getAssureSignApiEndpoint } from 'env';
import * as request from 'superagent';
import { AssureSignCredentialsSet } from 'types/assureSign';

import getAuthHeaders from '../../utilities/getAuthHeaders';

class AssureSignService {
  async cancelEnvelope(envelopeId: string, remarks: string, assureSignCredentialsSet: AssureSignCredentialsSet) {
    const { accountContextIdentifier, credentialSet } = assureSignCredentialsSet;
    const assureSignApiEndpoint = getAssureSignApiEndpoint();
    if (!assureSignApiEndpoint) throw new Error('AssureSign API endpoint not found!');

    const putUrl = `${assureSignApiEndpoint}/envelopes/${envelopeId}/cancel`;
    const requestBody = { request: { remarks } };
    const authHeaders = await getAuthHeaders();
    const contextIdentifierHeader = accountContextIdentifier ? { 'x-as-context': accountContextIdentifier } : null;
    const credentialSetHeader = credentialSet ? { 'x-as-credential-set': credentialSet } : null;
    const assureSignAdditionalHeaders = { ...contextIdentifierHeader, ...credentialSetHeader };
    const headers = { ...authHeaders, ...assureSignAdditionalHeaders };
    return request
      .put(putUrl)
      .set(headers)
      .send(requestBody)
      .catch((error) => {
        console.error('Cancel envelope failed with error ', error);
        const genericError = 'It was not possible to cancel the envelope. Try again later';
        throw new Error(error.response?.statusCode === 400 ? error?.response?.text : genericError);
      });
  }

  async resendEnvelope(envelopeId: string, signerEmail: string, assureSignCredentialsSet: AssureSignCredentialsSet) {
    const { accountContextIdentifier, credentialSet } = assureSignCredentialsSet;
    const assureSignApiEndpoint = getAssureSignApiEndpoint();
    if (!assureSignApiEndpoint) throw new Error('AssureSign API endpoint not found!');

    const putUrl = `${assureSignApiEndpoint}/envelopes/${envelopeId}/resend/${signerEmail}`;
    const requestBody = { request: {
      'updateEnvelopeSigner': {
        'emailAddress': signerEmail,
        'resendLastEmail': true,
      },
    } };
    const authHeaders = await getAuthHeaders();
    const contextIdentifierHeader = accountContextIdentifier ? { 'x-as-context': accountContextIdentifier } : null;
    const credentialSetHeader = credentialSet ? { 'x-as-credential-set': credentialSet } : null;
    const assureSignAdditionalHeaders = { ...contextIdentifierHeader, ...credentialSetHeader };
    const headers = { ...authHeaders, ...assureSignAdditionalHeaders };
    return request
      .put(putUrl)
      .set(headers)
      .send(requestBody)
      .catch((error) => {
        console.error('Resend envelope failed with error ', error);
        const genericError = 'It was not possible to resend signature envelope. Try again later';
        throw new Error(error.response?.statusCode === 400 ? error?.response?.text : genericError);
      });
  }
}

export default new AssureSignService();
