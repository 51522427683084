import action from 'utilities/action-creator';
// import { groupBy } from 'lodash';
import produce from 'immer';
import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';
import { get, post, patch, deleteReq } from 'utilities/httpRequests';

/**
 * CONSTANTS
 */
const FETCH_REQUEST = 'ITEM_REMINDERS/FETCH';
const SET_FETCH_ERROR = 'ITEM_REMINDERS/SET_FETCH_ERROR';
const SET_REMINDERS = 'ITEM_REMINDERS/SET_REMINDERS';
const EXTEND_REMINDERS = 'ITEM_REMINDERS/EXTEND_REMINDERS';
const SET_REMINDER = 'ITEM_REMINDERS/SET_REMINDER';
const REMOVE_REMINDER_WITH_KEY = 'ITEM_REMINDERS/REMOVE_REMINDER_WITH_KEY';
const SET_IS_LOADING = 'ITEM_REMINDERS/SET_IS_LOADING';
const REQUEST_POST = 'ITEM_REMINDERS/REQUEST_POST';
const REQUEST_PATCH = 'ITEM_REMINDERS/REQUEST_PATCH';
const REQUEST_DELETE = 'ITEM_REMINDERS/REQUEST_DELETE';
const REQUEST_BULK_CHANGE = 'ITEM_REMINDERS/REQUEST_BULK_CHANGE';
const DELETE_BY_KEYS = 'ITEM_REMINDERS/DELETE_BY_KEYS';
const DELETE_REMINDER = 'ITEM_REMINDERS/DELETE_REMINDER';
/**
 * TYPES
 */

export interface References {
  workspaceID: string;
  databaseID: string;
  sheetID: string;
  rowID: string;
  fieldID: string;
  fieldName: string;
}

export interface Reminder {
  _id?: string;
  model: string;
  recordID: string;
  eventType: string;
  remindType?: string;
  remindUsers?: string[];
  isScheduled: boolean;
  qty: number;
  unit: string;
  references?: any;
  version?: number;
  createdBy?: string;
  modifiedBy?: string;
  message?: string;
  createdAt?: number;
  modifiedAt?: number;
  timestamp?: number;
}

export interface State {
  reminders: { [key: string]: Reminder[] };
  isLoading: boolean;
  fetchError: Error | undefined;
}

export const initialState: State = {
  isLoading: false,
  fetchError: undefined,
  reminders: {},
};

/**
 * REDUCER
 */
export const reducer = (state = initialState, action): State => {
  switch (action.type) {
    case SET_REMINDERS: {
      return produce(state, draft => {
        draft.reminders = action.payload;
      });
    }
    case EXTEND_REMINDERS: {
      return produce(state, draft => {
        draft.reminders = { ...draft.reminders, ...action.payload };
      });
    }
    case SET_REMINDER: {
      const reminders = state.reminders[buildKey(action.payload)] || [];
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [buildKey(action.payload)]: [...reminders.filter(reminder => reminder._id !== action.payload._id), action.payload],
        },
      };
    }
    case REMOVE_REMINDER_WITH_KEY: {
      return produce(state, draft => {
        delete draft.reminders[action.payload];
      });
    }
    case SET_FETCH_ERROR: {
      return produce(state, draft => {
        draft.fetchError = action.payload;
      });
    }
    case SET_IS_LOADING: {
      return produce(state, draft => {
        draft.isLoading = action.payload;
      });
    }
    case DELETE_BY_KEYS: {
      return produce(state, draft => {
        action.payload.forEach(key => {
          delete draft.reminders[key];
        });
      });
    }
    case DELETE_REMINDER: {
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [action.payload.key]: state.reminders[action.payload.key].filter(reminder => reminder._id !== action.payload.id),
        },
      };
    }
    default:
      return state;
  }
};

/**
 * ACTIONS
 */
export const actions = {
  fetch: (payload: { workspaceID: string; databaseID: string }) => action(FETCH_REQUEST, { payload }),
  setFetchError: (error) => action(SET_FETCH_ERROR, { payload: error }),
  setReminders: (reminders) => action(SET_REMINDERS, { payload: reminders }),
  extendReminders: (reminders) => action(EXTEND_REMINDERS, { payload: reminders }),
  setReminder: (reminder) => action(SET_REMINDER, { payload: reminder }),
  setIsLoading: (isLoading) => action(SET_IS_LOADING, { payload: isLoading }),
  requestPost: (data) => action(REQUEST_POST, { payload: data }),
  requestPatch: (payload) => action(REQUEST_PATCH, { payload }),
  requestDelete: (info) => action(REQUEST_DELETE, { payload: info }),
  removeReminderWithKey: (key) => action(REMOVE_REMINDER_WITH_KEY, { payload: key }),
  deleteReminder: (payload) => action(DELETE_REMINDER, { payload }),
  requestBulkChange: (payload) => action(REQUEST_BULK_CHANGE, { payload }),
  deleteByKeys: (payload) => action(DELETE_BY_KEYS, { payload }),
};

/**
 * SAGAS
 */
export const sagas = {
  * request(action) {
    const { payload: { workspaceID, databaseID } } = action;
    yield put(actions.setIsLoading(true));
    try {
      const response = yield (get(`/reminders?filter=workspace:${workspaceID},database:${databaseID}&api_version=2`));
      const { items } = response.body;
      yield put(actions.setReminders(mapReminderToKey(items)));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * requestPost(action) {
    const { payload } = action;
    yield put(actions.setIsLoading(true));
    try {
      const response = yield (post({ url: '/reminders?api_version=2', data: payload }));
      yield put(actions.setReminder(response.body));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * requestPatch(action) {
    const { payload } = action;
    yield put(actions.setIsLoading(true));
    try {
      const response = yield (patch({
        url: `/reminders/${payload.id}?api_version=2`,
        data: payload.data,
      }));
      yield put(actions.setReminder(response.body));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * requestDelete(action) {
    const { payload } = action;
    yield put(actions.setIsLoading(true));
    try {
      yield (deleteReq(`/reminders/${payload.id}?api_version=2`));
      yield put(actions.deleteReminder({ id: payload.id, key: payload.key }));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * requestBulkChange(action) {
    const { payload } = action;
    yield put(actions.setIsLoading(true));
    try {
      const calls: any[] = [];
      const { reminders, rows, ids: { keysToDelete, toDelete } = { keysToDelete: [], toDelete: [] } } = payload;

      calls.push(reminders?.length ? call(post, { url: '/reminders/bulk-create?api_version=2', data: {
        reminders,
        rows,
      } }): null);

      calls.push(toDelete?.length ? call(post, { url: '/reminders/bulk-delete?api_version=2', data: {
        ids: toDelete,
      } }) : null);

      const [insertedResult] = yield all(calls);

      const inserted = insertedResult && mapReminderToKey(insertedResult.body);

      if (reminders?.length) {
        yield put(actions.extendReminders({ ...inserted }));
      }

      yield put(actions.deleteByKeys(keysToDelete));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
};

export const watcher = function* w() {
  yield takeLatest(FETCH_REQUEST, sagas.request);
  yield takeLatest(REQUEST_POST, sagas.requestPost);
  yield takeLatest(REQUEST_DELETE, sagas.requestDelete);
  yield takeEvery(REQUEST_PATCH, sagas.requestPatch);
  yield takeLatest(REQUEST_BULK_CHANGE, sagas.requestBulkChange);
};


export function buildKey(reminder) {
  const { recordID, references: { fieldID } } = reminder;
  return `${recordID}_${fieldID}`;
}

function mapReminderToKey(items: Notification[]) {
  const reminders = {};
  items.forEach(reminder => {
    if (!reminders[buildKey(reminder)]) {
      reminders[buildKey(reminder)] = [];
    }
    reminders[buildKey(reminder)].push(reminder);
  });
  return reminders;
}
