import React, { useState } from 'react';
import ModalContent from 'components/Modals/Modal/Content';
import Modal from 'components/Modals/Modal';
import ModalHeader from 'components/Modals/Modal/Header';
import * as ModalActions from 'data/modals/actions';
import { useDispatch } from 'react-redux';
import { NodeWithTitle } from 'types/schema';
import { Input } from './styles';
import ModalCustomActions from './Modal/CustomActions';
import { ButtonProps, Variant } from 'components/Button';
import { renameWorkspace } from 'data/workspaceCreate/services';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { updateWorkspace } from 'data/workspaces/workspaces.actions';

interface Props {
    close?: () => void;
    currentNode: NodeWithTitle;
    title: string;
}

export const RenameWorkspaceWithMeta = ({ currentNode, title }: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(currentNode.meta?.clientName);

  const onClose = () =>
    dispatch(ModalActions.closeModal());

  const onChangeInput = (e: string | undefined) => {
    return setValue(e);
  };

  const onSubmit = async () => {
    try {
      if (value && value !== currentNode.meta?.clientName) {
        onClose();
        await renameWorkspace(currentNode.id, value.trim());
        dispatch(updateWorkspace(currentNode.id, { ...currentNode, title: value.trim(), meta: { ...currentNode.meta, clientName: value.trim() } }));
      }
    } catch (error) {
      onClose();
      dispatch(notifications.error({
        title: 'Rename Workspace.',
        message: `Workspace Rename Failed.`,
      }));
    }
  };
  const rightButtons: ButtonProps[] = [
    {
      label: 'Rename',
      variant: Variant.Primary,
      onClick: () => onSubmit(),
    },
  ];

  const leftButtons: ButtonProps[] = [
    {
      label: 'Cancel',
      variant: Variant.SecondaryLink,
      onClick: () => onClose(),
    },
  ];

  return (
    <Modal onClose={() => onClose()}>
      <ModalHeader onClose={() => onClose()}>
        {title}
      </ModalHeader>
      <ModalContent>
        <form>
          <div>
            <Input
              value={value}
              onChange={(e) => onChangeInput(e.target.value)}
            />
          </div>
        </form>
      </ModalContent>
      <ModalCustomActions
        leftButtons={leftButtons}
        rightButtons={rightButtons}
      />
    </Modal>
  );
};
