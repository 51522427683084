import React, { useCallback, useRef, useState } from 'react';

import {
  AgGridEvent,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
  MenuItemDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ExportType } from 'components/AgGrid/AgGridApi';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import { getColumnTypeIcon } from 'components/AddColumnHeader/FieldTypesIcons';
import GridToolbar from 'components/AgGrid/GridToolbar';
import { DateFormat } from 'components/Fields/data';
import { Role } from 'data/accounts/types';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { User } from 'data/users/users.types';
import { getApiV2Url } from 'env';
import ColumnHeader from 'pages/Files/Renderers/ColumnRenderer';
import ChoiceFloatingFilter from 'pages/People/grid-options/filters/ChoiceFloatingFilter';
import DateFilter from 'pages/People/grid-options/filters/DateFilter';
import DateFloatingFilter from 'pages/People/grid-options/filters/DateFloatingFilter';
import TextFilter from 'pages/People/grid-options/filters/TextFilter';
import TextFloatingFilter from 'pages/People/grid-options/filters/TextFloatingFilter';
import { getDateQuickFilterText } from 'pages/People/grid-options/quick-filter';
import { useSelector, useDispatch } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { FieldType } from 'types/response/fieldNode';
import { Column, ColumnVM } from 'types/schema';
import getAuthHeaders from 'utilities/getAuthHeaders';
import { deleteRequest, getRequest, postRequest } from 'utilities/httpRequests';

import Button, { Variant as ButtonVariant } from 'components/Button';
import * as ColID from '../../components/DataGrid/columns/constants';
import PageHeader from '../../components/PageHeader';
import { Title } from '../../components/PageHeader/PageHeader.styles';
import svgIcons from '../../styles/svgIcons';
import { getSvgIconNode } from 'components/DataGrid/utils';
import '../People/styles/People.scss';
import './OrganizationUsers.scss';
import StateUtils from '../../utilities/state-utils';
import { AddUser, ConfirmDialog } from './components';
import { IAssigns } from './components/AssignRoles';
import MultiUserAssign from './components/MultiUserAssign';
import { BoxItem } from './components/RoleBox';
import TenantUserEditor, {
  createUpgradeUser,
  CONSTANTS,
  isTenantAdmin,
} from './components/TenantUserEditor';
import { RoleChoiceFilter } from './filters';
import {
  RowNumberRenderer,
  DateTimeRenderer,
  RoleChoiceRenderer,
} from './renderers';
import { roleChoices, roleComparator, TenantAdminRoles } from './shared';
import { AppMessages, INCLUDES_STRINGS } from '../../constants';
import loadingAction from 'data/ui/loading/actions';
import moment from 'moment';

const ROW_HEIGHT = 30;
async function inviteUsersToTenant(
  emails: string[],
  state: ReduxState,
  message?: string,
  assigns?: IAssigns,
) {
  const targetRoles = assigns || {};
  const workspaces = Object.keys(assigns || {}).reduce((accumulator, role) => {
    const roleWorkspaces = targetRoles[role].map(({ id }) => ({ id, role }));
    return [...accumulator, ...roleWorkspaces];
  }, []);

  const payload = {
    emails,
    message,
    workspaces: assigns ? workspaces : undefined,
  };

  const cleanedPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value),
  );

  const endpoint = `${getApiV2Url()}/users/invite-users`;
  const headers = getAuthHeaders();
  const result = await postRequest(
    { url: endpoint, data: cleanedPayload },
    state,
    headers,
  );

  return result;
}

const OrganizationUsers: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const grid = useRef({ api: new GridApi(), columnApi: new ColumnApi() });
  const state = useSelector((state: ReduxState) => state);
  const [addingUsers, setAddingUsers] = React.useState(false);
  const [assigningRoles, setAssigningRoles] = React.useState(false);
  const [userToEdit, setUserToEdit] = useState<{ data: User; node: RowNode }>();
  const [isGridReady, setIsGridReady] = useState<boolean>(false);
  const [quickFiltersBarEnabled, toggleQuickFilterVisibility] = useState(true);

  const [removeUser, setRemoveUser] = useState<{ data: User; node: RowNode }>();
  const [removeUsers, setRemoveUsers] = useState<string[] | undefined>();
  const [upgradeUser, setUpgradeUser] =
    useState<{ data: User; node: RowNode }>();

  const handleOnRemoveDone = (userModel) => {
    setRemoveUser(undefined);
    removeSelectedUser(userModel);
    handleOnClose();
  };

  const handleOnRemoveUsersDone = (userIds) => {
    setRemoveUsers(undefined);
    removeSelectedUsers(userIds);
    grid.current.api.deselectAll();
    handleOnClose();
  };

  const handleOnClose = () => {
    setUserToEdit(undefined);
  };

  const handleOnRemoveCancel = () => {
    setRemoveUser(undefined);
    setRemoveUsers(undefined);
  };

  const removeSelectedUser = (userModel) => {
    const url = `${getApiV2Url()}/users/delete-permissions/${userModel.id}`;
    const headers = getAuthHeaders();
    grid.current.api.showLoadingOverlay();
    deleteRequest(url, state, headers)
      .then((resp) => {
        handleOnClose();
        handleOnRemoveUser();
        dispatch(
          notifications.success({
            title: 'User Removed!',
            message: `User(s) removed successfully.`,
          }),
        );
      })
      .catch((err) => {
        grid.current.api.hideOverlay();
        dispatch(
          notifications.error({
            title: 'Error!',
            message: `The user ${userModel.email} was not removed. Please try again later!`,
          }),
        );
      });
  };

  const removeSelectedUsers = (usersIds: string[]) => {
    const url = `${getApiV2Url()}/users`;
    const headers = getAuthHeaders();
    const data = { usersIds };
    grid.current.api.showLoadingOverlay();
    deleteRequest(url, state, headers, data)
      .then((resp) => {
        handleOnClose();
        handleOnRemoveUser();
        dispatch(
          notifications.success({
            title: 'Users Removed!',
            message: `User(s) removed successfully.`,
          }),
        );
      })
      .catch((err) => {
        grid.current.api.hideOverlay();
        dispatch(
          notifications.error({
            title: 'Error!',
            message: `The users were not removed. Please try again later!`,
          }),
        );
      });
  };

  const [emails, setEmails] = React.useState<string[]>([]);
  const [message, setMessage] = React.useState<string>();

  const tenantAdminRoleChoices = {
    choices: { ...roleChoices },
    choiceOrder: [
      TenantAdminRoles.TENANT_ADMIN.toString(),
      TenantAdminRoles.ORGANIZATION_USER.toString(),
    ],
  };

  const columns: Column[] = [
    {
      id: 'name',
      allowMultiple: false,
      fieldType: FieldType.Singlelineoftext,
      type: 'text',
      name: 'Name',
    },
    {
      id: 'email',
      allowMultiple: false,
      fieldType: FieldType.Singlelineoftext,
      type: 'text',
      name: 'Email',
    },
    {
      id: 'lastLogin',
      allowMultiple: false,
      fieldType: FieldType.Date,
      type: 'text',
      name: 'Last Login',
      dateFormat: DateFormat.US,
    },
    {
      id: 'role',
      allowMultiple: false,
      fieldType: FieldType.Singlechoice,
      type: 'text',
      name: 'Role',
      ...tenantAdminRoleChoices,
    },
  ];

  const columnSchema: { [key: string]: ColumnVM } = {
    name: { ...columns[0] },
    email: { ...columns[1] },
    lastLogin: { ...columns[2] },
    role: { ...columns[3] },
  };

  const fillGridData = (gridApi: GridApi) => {
    getRequest(
      `${getApiV2Url()}/users/query?offset=0&limit=100000&filter=email%5Bexists%5D`,
      state,
    ).then((response) => {
      // Filter data to not include the current user
      const dataGrid = response.body.data.map((user) => ({
        ...user,
        displayName:
          user?.status === 'pending'
            ? `${user.email} (pending)`
            : user.displayName,
      }));
      gridApi.setRowData(dataGrid);
      setIsGridReady(true);
    });
  };

  const onGridReady = (event: GridReadyEvent): void => {
    grid.current = { api: event.api, columnApi: event.columnApi };
    fillGridData(event.api);
  };

  const handleOnAddUsersDone = async (result: {
    emails: string[];
    role: string;
    message: string;
  }) => {
    setEmails(result.emails);
    setMessage(result.message);

    if (result.role === 'tenant-admin') {
      try {
        await inviteUsersToTenant(result.emails, state, result.message);
        setAddingUsers(false);
        fillGridData(grid.current.api);
        dispatch(
          notifications.success({
            title: AppMessages.INVITATION_SENT_LABEL,
            message: AppMessages.NOTIFICATION_USER_INVITATION_SENT,
          }),
        );
      } catch (error) {
        setAddingUsers(false);
        console.error(error.message);
        const responseMessage = error?.response?.body?.message;
        const message = responseMessage?.includes(
          INCLUDES_STRINGS.EMAIL_ALREADY_EXIST,
        )
          ? AppMessages.USER_ALREADY_EXISTS_LABEL
          : AppMessages.NOTIFICATION_USER_INVITATION_SENT_ERROR;
        dispatch(
          notifications.error({
            title: AppMessages.ERROR_TITLE_LABEL,
            message,
          }),
        );
      }
    } else {
      setAssigningRoles(true);
    }
  };

  const handleOnDone = async (assigns: { [role: string]: BoxItem[] }) => {
    setAssigningRoles(false);
    try {
      await inviteUsersToTenant(emails, state, message, assigns);
      dispatch(
        notifications.success({
          title: AppMessages.INVITATION_SENT_LABEL,
          message: AppMessages.NOTIFICATION_USERS_INVITATION_SENT,
        }),
      );
    } catch (error) {
      console.error(error.message);
      const responseMessage = error?.response?.body?.message;
      const message = responseMessage?.includes(
        INCLUDES_STRINGS.EMAIL_ALREADY_EXIST,
      )
        ? AppMessages.USER_ALREADY_EXISTS_LABEL
        : error.message;
      dispatch(
        notifications.error({
          title: AppMessages.ERROR_TITLE_LABEL,
          message,
        }),
      );
    }
  };

  const handleOnEditFinished = (newRoles: Role[], downgrade = false) => {
    userToEdit?.node.setDataValue('role', newRoles);
    if (downgrade && !newRoles.some((r) => r.name === 'HubSync Admin')) {
      setUserToEdit(userToEdit);
    } else {
      setUserToEdit(undefined);
    }
    dispatch(
      notifications.success({
        title: 'Action Executed!',
        message: 'User permissions updated.',
      }),
    );
  };

  const handleOnUpgradeFinished = (newRoles: Role[]) => {
    upgradeUser?.node.setDataValue('role', newRoles);
    setUpgradeUser(undefined);
    dispatch(
      notifications.success({
        title: 'Action Executed!',
        message: 'User permissions updated.',
      }),
    );
  };

  const handleOnRemoveUser = () => {
    fillGridData(grid.current.api);
  };

  // TODO: Create a user model type and replace this nasty any
  const handleOnExpandRow = (data: any, node: RowNode) => {
    setUserToEdit({ data, node });
  };

  const handleOnCloseMultiUserAssign = () => {
    setAssigningRoles(false);
    setAddingUsers(true);
  };

  const changeQuickFiltersVisibility = async () => {
    toggleQuickFilterVisibility(!quickFiltersBarEnabled);
    await StateUtils.wait(0);
    grid.current.api.refreshHeader();
  };

  const handleOnError = (error: Error) => {
    dispatch(
      notifications.error({
        title: 'Error!',
        message: 'The action executed has failed. Please try again later!',
      }),
    );
  };

  const refreshRowNumberId = (e: AgGridEvent) => {
    e.api.refreshCells({
      force: true,
      columns: [ColID.ROW_NUMBER_ID],
    });
    dispatch(loadingAction.endLockingLoader());
  };

  const handleOnUpgradeCancel = () => {
    setUpgradeUser(undefined);
  };

  const handleOnUpgradeDone = () => {
    setUpgradeUser(undefined);
    upgradeSelectedUser();
    handleOnClose();
  };

  const upgradeSelectedUser = useCallback(
    upgradeUser
      ? createUpgradeUser(
        getApiV2Url,
        postRequest,
        getAuthHeaders,
        state,
        upgradeUser.data,
        handleOnUpgradeFinished,
        handleOnError,
      )
      : handleOnUpgradeCancel,
    [upgradeUser],
  );

  const handleExport = (type: ExportType): void => {
    switch (type) {
      case ExportType.EXCEL:
        grid.current.api.exportDataAsExcel({
          fileName: 'Organization Users',
          processCellCallback: processCell,
        });
        break;
      case ExportType.CSV:
        grid.current.api.exportDataAsCsv({
          fileName: 'Organization Users',
          processCellCallback: processCell,
        });
        break;
    }
  };

  const processCell = (params) => {
    if (params.column.getColId() === 'lastLogin' && params.value) {
      return moment(params.value)
        .utc(true)
        .format('MM/DD/YYYY hh:mmA')
        .toString();
    }
    if (params.column.getColId() === 'role' && params.value?.length) {
      const array = [...params.value];
      const isAdmin = array.findIndex(
        (element) => element.name === 'HubSync Admin',
      );
      return isAdmin === -1 ? 'Organization User' : 'Tenant Administrator';
    }
    return params.value;
  };

  return (
    <>
      {userToEdit && (
        <TenantUserEditor
          userModel={userToEdit.data}
          onClose={() => setUserToEdit(undefined)}
          onDone={handleOnEditFinished}
          onRemoveUser={handleOnRemoveUser}
          onError={handleOnError}
        />
      )}
      {assigningRoles && (
        <MultiUserAssign
          onClose={handleOnCloseMultiUserAssign}
          onDone={handleOnDone}
        />
      )}
      {addingUsers && (
        <AddUser
          onClose={() => setAddingUsers(false)}
          onDone={handleOnAddUsersDone}
        />
      )}
      {removeUser && (
        <ConfirmDialog
          onDone={() => handleOnRemoveDone(removeUser.data)}
          onClose={handleOnRemoveCancel}
          title={CONSTANTS.removeConfirmTitle}
          labelAccept={CONSTANTS.removeLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.removeTextContent}
          showRoleSelection={false}
        />
      )}
      {removeUsers && (
        <ConfirmDialog
          onDone={() => handleOnRemoveUsersDone(removeUsers)}
          onClose={handleOnRemoveCancel}
          title={CONSTANTS.removeUsersConfirmTitle}
          labelAccept={CONSTANTS.removeUsersLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.removeUsersTextContent}
          showRoleSelection={false}
        />
      )}
      {upgradeUser && (
        <ConfirmDialog
          onDone={handleOnUpgradeDone}
          onClose={handleOnUpgradeCancel}
          title={CONSTANTS.upgradeConfirmTitle}
          labelAccept={CONSTANTS.upgradeLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.upgradeTextContent}
          showRoleSelection={false}
        />
      )}
      <div className={`collections__main__container users `}>
        <div className="ag-theme-material users">
          <div className="page-header-container">
            <PageHeader titleContent={<Title>{state.app.tenant?.title}</Title>}>
              <Button
                id="btn-add-user"
                label="Add User"
                variant={ButtonVariant.Primary}
                icon={svgIcons.Add}
                onClick={() => {
                  setAddingUsers(true);
                }}
              />
            </PageHeader>
          </div>

          {isGridReady && (
            <>
              <GridToolbar
                columns={columns}
                columnSchema={columnSchema}
                changeQuickFiltersVisibility={changeQuickFiltersVisibility}
                quickFiltersBarEnabled={quickFiltersBarEnabled}
                grid={grid.current}
                onExport={handleExport}
              />
            </>
          )}

          <AgGridReact
            defaultColDef={{
              resizable: true,
              filter: true,
              minWidth: 100,
              menuTabs: ['filterMenuTab'],
              sortable: true,
            }}
            getContextMenuItems={(params) => {
              const userRoles = params?.node?.data?.roles ?? [];
              const contextItems: MenuItemDef[] = [];
              const userIds: string[] = [];
              const selectedRows = grid.current.api.getSelectedNodes();
              for (const row of selectedRows) {
                userIds.push(row?.data?.id);
              }
              if (!isTenantAdmin(userRoles)) {
                contextItems.push(
                  {
                    name: 'Manage User Permissions',
                    action: () => {
                      setUserToEdit({
                        data: params.node.data,
                        node: params.node,
                      });
                    },
                    icon: getSvgIconNode(svgIcons.UserProfile),
                    tooltip: 'Edit the user permissions.',
                  },
                  {
                    name: 'Upgrade to Tenant Admin',
                    action: () => {
                      setUpgradeUser({
                        data: params.node.data,
                        node: params.node,
                      });
                    },
                    icon: getSvgIconNode(svgIcons.ArrowUp),
                    tooltip: 'Upgrade the user\'s account.',
                  },
                );
              }
              if (userIds.length) {
                contextItems.push({
                  name: 'Remove Users',
                  action: () => {
                    setRemoveUsers(userIds);
                  },
                  icon: getSvgIconNode(svgIcons.Delete),
                  tooltip: 'Delete the selected users.',
                });
              } else {
                contextItems.push({
                  name: 'Remove User',
                  action: () => {
                    setRemoveUser({
                      data: params.node.data,
                      node: params.node,
                    });
                  },
                  icon: getSvgIconNode(svgIcons.Delete),
                  tooltip: 'Delete the clicked user.',
                });
              }
              return [...(params?.node ? contextItems : [])];
            }}
            columnTypes={{
              number: { filter: 'agNumberColumnFilter' },
              text: { filter: 'agTextColumnFilter' },
            }}
            animateRows={true}
            rowBuffer={100}
            rowSelection="multiple"
            cacheOverflowSize={2}
            cacheBlockSize={20}
            maxConcurrentDatasourceRequests={3}
            infiniteInitialRowCount={1}
            maxBlocksInCache={10}
            headerHeight={ROW_HEIGHT}
            rowHeight={ROW_HEIGHT}
            onGridReady={onGridReady}
            getRowNodeId={(item) => item.id}
            floatingFilter={quickFiltersBarEnabled}
            floatingFiltersHeight={31}
            frameworkComponents={{
              rowNumberRenderer: RowNumberRenderer,
              dateTimeRenderer: DateTimeRenderer,
              roleChoiceRenderer: RoleChoiceRenderer,
              agColumnHeader: ColumnHeader,
            }}
            context={{
              handleOnExpandRow,
            }}
            onFilterChanged={refreshRowNumberId}
            onSortChanged={refreshRowNumberId}
          >
            <AgGridColumn
              colId={ColID.ROW_NUMBER_ID}
              headerName=""
              checkboxSelection={true}
              filter={false}
              headerCheckboxSelection={true}
              headerCheckboxSelectionFilteredOnly={true}
              lockPinned={true}
              lockPosition={true}
              minWidth={40}
              pinned="left"
              resizable={false}
              sortable={false}
              suppressMenu={true}
              width={75}
              cellRenderer="rowNumberRenderer"
              editable={true}
              valueGetter={(data) => {
                return data.node.rowIndex + 1;
              }}
            />
            <AgGridColumn
              colId="name"
              headerName="Name"
              field="displayName"
              sortable
              type={FieldType.Singlelineoftext}
              filter={TextFilter}
              floatingFilterComponentFramework={TextFloatingFilter}
              headerComponentParams={getColumnTypeIcon(
                FieldType.Singlelineoftext,
              )}
              width={350}
            />
            <AgGridColumn
              headerName="Email"
              field="email"
              sortable
              type={FieldType.Email}
              filter={TextFilter}
              floatingFilterComponentFramework={TextFloatingFilter}
              headerComponentParams={getColumnTypeIcon(FieldType.Email)}
              width={300}
            />
            <AgGridColumn
              headerName="Last Log In (local time)"
              field="lastLogin"
              type={FieldType.DateTime}
              sortable
              cellRenderer="dateTimeRenderer"
              filter={DateFilter}
              floatingFilterComponentFramework={DateFloatingFilter}
              getQuickFilterText={getDateQuickFilterText}
              headerComponentParams={getColumnTypeIcon(FieldType.DateTime)}
              width={240}
            />
            <AgGridColumn
              colId="role"
              headerName="Role"
              field="roles"
              type={FieldType.Singlechoice}
              sortable
              cellRenderer="roleChoiceRenderer"
              filter={RoleChoiceFilter}
              floatingFilterComponentFramework={ChoiceFloatingFilter}
              headerComponentParams={getColumnTypeIcon(FieldType.Singlechoice)}
              width={240}
              cellRendererParams={{ ...tenantAdminRoleChoices }}
              cellEditorParams={{
                ...tenantAdminRoleChoices,
                includeEmpty: false,
              }}
              comparator={roleComparator}
              accentedSort={true}
            />
          </AgGridReact>
        </div>
      </div>
    </>
  );
};

export default OrganizationUsers;
