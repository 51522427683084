import { ReactComponent as Add } from 'icons/add.svg';
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';
import { ReactComponent as ArrowToLeft } from 'icons/arrow-to-left.svg';
import { ReactComponent as ArrowToRight } from 'icons/arrow-to-right.svg';
import { ReactComponent as ArrowUp } from 'icons/arrow-up.svg';
import { ReactComponent as Attach } from 'icons/attach.svg';
import { ReactComponent as AutosizeAll } from 'icons/autosize-all.svg';
import { ReactComponent as Autosize } from 'icons/autosize.svg';
import {
  ReactComponent as BarChart,
  ReactComponent as Charts,
} from 'icons/bar-chart.svg';
import { ReactComponent as BubbleChart } from 'icons/bubblechart.svg';
import { ReactComponent as Check } from 'icons/check.svg';
import { ReactComponent as CheckboxChecked } from 'icons/checkbox-checked.svg';
import { ReactComponent as CheckboxForLabel } from 'icons/checkbox-for-label.svg';
import { ReactComponent as CheckboxUnchecked } from 'icons/checkbox-unchecked.svg';
import { ReactComponent as Clock } from 'icons/clock.svg';
import { ReactComponent as CloseCircle } from 'icons/close-circle.svg';
import { ReactComponent as Close } from 'icons/close.svg';
import { ReactComponent as CollapseFolder } from 'icons/collapse-folder.svg';
import { ReactComponent as CollapseSidebar } from 'icons/collapse-sidebar.svg';
import { ReactComponent as Collapse } from 'icons/collapse.svg';
import { ReactComponent as Columns } from 'icons/columns.svg';
import { ReactComponent as Csv } from 'icons/csv.svg';
import { ReactComponent as Database } from 'icons/database.svg';
import { ReactComponent as Dashboard } from 'icons/dashboard.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as DonutChart } from 'icons/donutchart.svg';
import { ReactComponent as Download } from 'icons/download.svg';
import { ReactComponent as Drag } from 'icons/drag.svg';
import { ReactComponent as Dropdown } from 'icons/dropdown.svg';
import { ReactComponent as Dropleft } from 'icons/dropleft.svg';
import { ReactComponent as DueDate } from 'icons/due-date.svg';
import { ReactComponent as Duplicate } from 'icons/duplicate.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Excel } from 'icons/excel.svg';
import { ReactComponent as ExpandFolder } from 'icons/expand-folder.svg';
import { ReactComponent as ExpandRecord } from 'icons/expand-record.svg';
import { ReactComponent as Expand } from 'icons/expand.svg';
import { ReactComponent as Expire } from 'icons/expire.svg';
import { ReactComponent as Export } from 'icons/export.svg';
import { ReactComponent as NeedHelp } from 'icons/need-help.svg';
import { ReactComponent as Account } from 'icons/field-types/account.svg';
import { ReactComponent as Attachment } from 'icons/field-types/attachment.svg';
import { ReactComponent as Barcode } from 'icons/field-types/barcode.svg';
import { ReactComponent as Boolean } from 'icons/field-types/boolean.svg';
import { ReactComponent as Button } from 'icons/field-types/button.svg';
import { ReactComponent as Checkbox } from 'icons/field-types/checkbox.svg';
import { ReactComponent as Checklist } from 'icons/field-types/checklist.svg';
import { ReactComponent as Currency } from 'icons/field-types/currency.svg';
import { ReactComponent as Date } from 'icons/field-types/date.svg';
import { ReactComponent as Email } from 'icons/field-types/email.svg';
import { ReactComponent as EmailSent } from 'icons/email-sent.svg';
import { ReactComponent as MultilineText } from 'icons/field-types/multiline-text.svg';
import { ReactComponent as MultipleChoice } from 'icons/field-types/multiple-choice.svg';
import { ReactComponent as Number } from 'icons/field-types/number.svg';
import { ReactComponent as Percent } from 'icons/field-types/percent.svg';
import { ReactComponent as Phone } from 'icons/field-types/phone.svg';
import { ReactComponent as Rating } from 'icons/field-types/rating.svg';
import { ReactComponent as SingleChoice } from 'icons/field-types/single-choice.svg';
import { ReactComponent as SingleLineOfText } from 'icons/field-types/single-line-of-text.svg';
import { ReactComponent as Tag } from 'icons/field-types/tag.svg';
import { ReactComponent as Url } from 'icons/field-types/url.svg';
import { ReactComponent as sevenZ } from 'icons/file-extensions/7z.svg';
import { ReactComponent as ai } from 'icons/file-extensions/ai.svg';
import { ReactComponent as aif } from 'icons/file-extensions/aif.svg';
import { ReactComponent as aifc } from 'icons/file-extensions/aifc.svg';
import { ReactComponent as aiff } from 'icons/file-extensions/aiff.svg';
import { ReactComponent as asf } from 'icons/file-extensions/asf.svg';
import { ReactComponent as aspx } from 'icons/file-extensions/aspx.svg';
import { ReactComponent as atom } from 'icons/file-extensions/atom.svg';
import { ReactComponent as au } from 'icons/file-extensions/au.svg';
import { ReactComponent as azw } from 'icons/file-extensions/azw.svg';
import { ReactComponent as bat } from 'icons/file-extensions/bat.svg';
import { ReactComponent as bmp } from 'icons/file-extensions/bmp.svg';
import { ReactComponent as bz } from 'icons/file-extensions/bz.svg';
import { ReactComponent as bz2 } from 'icons/file-extensions/bz2.svg';
import { ReactComponent as cer } from 'icons/file-extensions/cer.svg';
import { ReactComponent as chm } from 'icons/file-extensions/chm.svg';
import { ReactComponent as cin } from 'icons/file-extensions/cin.svg';
import { ReactComponent as cmd } from 'icons/file-extensions/cmd.svg';
import { ReactComponent as config } from 'icons/file-extensions/config.svg';
import { ReactComponent as crl } from 'icons/file-extensions/crl.svg';
import { ReactComponent as crt } from 'icons/file-extensions/crt.svg';
import { ReactComponent as cs } from 'icons/file-extensions/cs.svg';
import { ReactComponent as csh } from 'icons/file-extensions/csh.svg';
import { ReactComponent as csproj } from 'icons/file-extensions/csproj.svg';
import { ReactComponent as css } from 'icons/file-extensions/css.svg';
import { ReactComponent as csv } from 'icons/file-extensions/csv.svg';
import { ReactComponent as dds } from 'icons/file-extensions/dds.svg';
import { ReactComponent as der } from 'icons/file-extensions/der.svg';
import { ReactComponent as dng } from 'icons/file-extensions/dng.svg';
import { ReactComponent as dvi } from 'icons/file-extensions/dvi.svg';
import { ReactComponent as emf } from 'icons/file-extensions/emf.svg';
import { ReactComponent as eml } from 'icons/file-extensions/eml.svg';
import { ReactComponent as eps } from 'icons/file-extensions/eps.svg';
import { ReactComponent as epub } from 'icons/file-extensions/epub.svg';
import { ReactComponent as excel } from 'icons/file-extensions/excel.svg';
import { ReactComponent as exe } from 'icons/file-extensions/exe.svg';
import { ReactComponent as exr } from 'icons/file-extensions/exr.svg';
import { ReactComponent as flv } from 'icons/file-extensions/flv.svg';
import { ReactComponent as folder } from 'icons/file-extensions/folder.svg';
import { ReactComponent as gif } from 'icons/file-extensions/gif.svg';
import { ReactComponent as go } from 'icons/file-extensions/go.svg';
import { ReactComponent as gz } from 'icons/file-extensions/gz.svg';
import { ReactComponent as hdr } from 'icons/file-extensions/hdr.svg';
import { ReactComponent as htm } from 'icons/file-extensions/htm.svg';
import { ReactComponent as html } from 'icons/file-extensions/html.svg';
import { ReactComponent as ico } from 'icons/file-extensions/ico.svg';
import { ReactComponent as ics } from 'icons/file-extensions/ics.svg';
import { ReactComponent as ini } from 'icons/file-extensions/ini.svg';
import { ReactComponent as jpeg } from 'icons/file-extensions/jpeg.svg';
import { ReactComponent as jpg } from 'icons/file-extensions/jpg.svg';
import { ReactComponent as js } from 'icons/file-extensions/js.svg';
import { ReactComponent as json } from 'icons/file-extensions/json.svg';
import { ReactComponent as latex } from 'icons/file-extensions/latex.svg';
import { ReactComponent as less } from 'icons/file-extensions/less.svg';
import { ReactComponent as lnk } from 'icons/file-extensions/lnk.svg';
import { ReactComponent as log } from 'icons/file-extensions/log.svg';
import { ReactComponent as man } from 'icons/file-extensions/man.svg';
import { ReactComponent as md } from 'icons/file-extensions/md.svg';
import { ReactComponent as mdb } from 'icons/file-extensions/mdb.svg';
import { ReactComponent as mid } from 'icons/file-extensions/mid.svg';
import { ReactComponent as midi } from 'icons/file-extensions/midi.svg';
import { ReactComponent as mkv } from 'icons/file-extensions/mkv.svg';
import { ReactComponent as mobi } from 'icons/file-extensions/mobi.svg';
import { ReactComponent as mp2 } from 'icons/file-extensions/mp2.svg';
import { ReactComponent as mp3 } from 'icons/file-extensions/mp3.svg';
import { ReactComponent as mp4 } from 'icons/file-extensions/mp4.svg';
import { ReactComponent as mpe } from 'icons/file-extensions/mpe.svg';
import { ReactComponent as mpeg } from 'icons/file-extensions/mpeg.svg';
import { ReactComponent as mpg } from 'icons/file-extensions/mpg.svg';
import { ReactComponent as mpp } from 'icons/file-extensions/mpp.svg';
import { ReactComponent as msg } from 'icons/file-extensions/msg.svg';
import { ReactComponent as msi } from 'icons/file-extensions/msi.svg';
import { ReactComponent as nfo } from 'icons/file-extensions/nfo.svg';
import { ReactComponent as odp } from 'icons/file-extensions/odp.svg';
import { ReactComponent as ods } from 'icons/file-extensions/ods.svg';
import { ReactComponent as odt } from 'icons/file-extensions/odt.svg';
import { ReactComponent as one } from 'icons/file-extensions/one.svg';
import { ReactComponent as ost } from 'icons/file-extensions/ost.svg';
import { ReactComponent as otf } from 'icons/file-extensions/otf.svg';
import { ReactComponent as p12 } from 'icons/file-extensions/p12.svg';
import { ReactComponent as p7b } from 'icons/file-extensions/p7b.svg';
import { ReactComponent as p7c } from 'icons/file-extensions/p7c.svg';
import { ReactComponent as p7r } from 'icons/file-extensions/p7r.svg';
import { ReactComponent as pbm } from 'icons/file-extensions/pbm.svg';
import { ReactComponent as pcx } from 'icons/file-extensions/pcx.svg';
import { ReactComponent as pdf } from 'icons/file-extensions/pdf.svg';
import { ReactComponent as pfx } from 'icons/file-extensions/pfx.svg';
import { ReactComponent as php } from 'icons/file-extensions/php.svg';
import { ReactComponent as pict } from 'icons/file-extensions/pict.svg';
import { ReactComponent as pl } from 'icons/file-extensions/pl.svg';
import { ReactComponent as png } from 'icons/file-extensions/png.svg';
import { ReactComponent as powerpoint } from 'icons/file-extensions/powerpoint.svg';
import { ReactComponent as prc } from 'icons/file-extensions/prc.svg';
import { ReactComponent as ps } from 'icons/file-extensions/ps.svg';
import { ReactComponent as ps1 } from 'icons/file-extensions/ps1.svg';
import { ReactComponent as psb } from 'icons/file-extensions/psb.svg';
import { ReactComponent as psd } from 'icons/file-extensions/psd.svg';
import { ReactComponent as psp } from 'icons/file-extensions/psp.svg';
import { ReactComponent as pst } from 'icons/file-extensions/pst.svg';
import { ReactComponent as pub } from 'icons/file-extensions/pub.svg';
import { ReactComponent as py } from 'icons/file-extensions/py.svg';
import { ReactComponent as rar } from 'icons/file-extensions/rar.svg';
import { ReactComponent as resx } from 'icons/file-extensions/resx.svg';
import { ReactComponent as rss } from 'icons/file-extensions/rss.svg';
import { ReactComponent as sass } from 'icons/file-extensions/sass.svg';
import { ReactComponent as sh } from 'icons/file-extensions/sh.svg';
import { ReactComponent as snd } from 'icons/file-extensions/snd.svg';
import { ReactComponent as spc } from 'icons/file-extensions/spc.svg';
import { ReactComponent as sql } from 'icons/file-extensions/sql.svg';
import { ReactComponent as svg } from 'icons/file-extensions/svg.svg';
import { ReactComponent as tab } from 'icons/file-extensions/tab.svg';
import { ReactComponent as tar } from 'icons/file-extensions/tar.svg';
import { ReactComponent as tex } from 'icons/file-extensions/tex.svg';
import { ReactComponent as tga } from 'icons/file-extensions/tga.svg';
import { ReactComponent as tif } from 'icons/file-extensions/tif.svg';
import { ReactComponent as tiff } from 'icons/file-extensions/tiff.svg';
import { ReactComponent as toml } from 'icons/file-extensions/toml.svg';
import { ReactComponent as tsv } from 'icons/file-extensions/tsv.svg';
import { ReactComponent as ttf } from 'icons/file-extensions/ttf.svg';
import { ReactComponent as txtRtf } from 'icons/file-extensions/txt-rtf.svg';
import { ReactComponent as unknown } from 'icons/file-extensions/unknown.svg';
import { ReactComponent as vbs } from 'icons/file-extensions/vbs.svg';
import { ReactComponent as vcf } from 'icons/file-extensions/vcf.svg';
import { ReactComponent as vcs } from 'icons/file-extensions/vcs.svg';
import { ReactComponent as vsd } from 'icons/file-extensions/vsd.svg';
import { ReactComponent as vsdx } from 'icons/file-extensions/vsdx.svg';
import { ReactComponent as vst } from 'icons/file-extensions/vst.svg';
import { ReactComponent as wav } from 'icons/file-extensions/wav.svg';
import { ReactComponent as webp } from 'icons/file-extensions/webp.svg';
import { ReactComponent as wmf } from 'icons/file-extensions/wmf.svg';
import { ReactComponent as wmz } from 'icons/file-extensions/wmz.svg';
import { ReactComponent as word } from 'icons/file-extensions/word.svg';
import { ReactComponent as xaml } from 'icons/file-extensions/xaml.svg';
import { ReactComponent as xht } from 'icons/file-extensions/xht.svg';
import { ReactComponent as xhtml } from 'icons/file-extensions/xhtml.svg';
import { ReactComponent as xml } from 'icons/file-extensions/xml.svg';
import { ReactComponent as xps } from 'icons/file-extensions/xps.svg';
import { ReactComponent as xsl } from 'icons/file-extensions/xsl.svg';
import { ReactComponent as xslt } from 'icons/file-extensions/xslt.svg';
import { ReactComponent as yaml } from 'icons/file-extensions/yaml.svg';
import { ReactComponent as zip } from 'icons/file-extensions/zip.svg';
import { ReactComponent as Filter } from 'icons/filter.svg';
import { ReactComponent as FolderNew } from 'icons/folder-new.svg';
import { ReactComponent as Folder } from 'icons/folder.svg';
import { ReactComponent as FullscreenExit } from 'icons/fullscreen-exit.svg';
import { ReactComponent as Fullscreen } from 'icons/fullscreen.svg';
import { ReactComponent as Grid } from 'icons/grid.svg';
import { ReactComponent as Group } from 'icons/group.svg';
import { ReactComponent as HeaderMenu } from 'icons/header/menu.svg';
import { ReactComponent as HeaderNotifications } from 'icons/header/notifications.svg';
import { ReactComponent as HeaderSearch } from 'icons/header/search.svg';
import { ReactComponent as Hide } from 'icons/hide.svg';
import { ReactComponent as PrivateView } from 'icons/icon-private.svg';
import { ReactComponent as PublicView } from 'icons/icon-public-view.svg';
import { ReactComponent as InfoFilled } from 'icons/info-filled.svg';
import { ReactComponent as Info } from 'icons/info.svg';
import { ReactComponent as Kanban } from 'icons/kanban.svg';
import { ReactComponent as Limited } from 'icons/limited.svg';
import { ReactComponent as Limited2 } from 'icons/limited_2.svg';
import { ReactComponent as LineChart } from 'icons/linechart.svg';
import { ReactComponent as Link } from 'icons/link.svg';
import { ReactComponent as LockColumn } from 'icons/column-lock.svg';
import { ReactComponent as LockFilled } from 'icons/lock-filled.svg';
import { ReactComponent as LockLight } from 'icons/lock-light.svg';
import { ReactComponent as LockOpen } from 'icons/lock-open.svg';
import { ReactComponent as LockInput } from 'icons/lock-input.svg';
import { ReactComponent as Lock } from 'icons/lock.svg';
import { ReactComponent as LogOut } from 'icons/logout.svg';
import { ReactComponent as MenuDots } from 'icons/menu-dots.svg';
import { ReactComponent as Message } from 'icons/message.svg';
import { ReactComponent as ModifyField } from 'icons/modify-field.svg';
import { ReactComponent as MoveArrowLeft } from 'icons/move-arrow-left.svg';
import { ReactComponent as MoveArrowRight } from 'icons/move-arrow-right.svg';
import { ReactComponent as Organization } from 'icons/organization.svg';
import { ReactComponent as Pagination } from 'icons/pagination.svg';
import { ReactComponent as PasswordShow } from 'icons/password-input-hide.svg';
import { ReactComponent as PieChart } from 'icons/piechart.svg';
import { ReactComponent as PinColumnV2 } from 'icons/pin-column-v2.svg';
import { ReactComponent as PinColumn } from 'icons/pin-column.svg';
import { ReactComponent as Print } from 'icons/print.svg';
import { ReactComponent as PrivateKey } from 'icons/private-key.svg';
import { ReactComponent as PrimaryKey } from 'icons/primary-key.svg';
import { ReactComponent as Records } from 'icons/records.svg';
import { ReactComponent as Remove } from 'icons/remove.svg';
import { ReactComponent as RowHeightMedium } from 'icons/row-height-medium.svg';
import { ReactComponent as RowHeightShort } from 'icons/row-height-short.svg';
import { ReactComponent as RowHeightTall } from 'icons/row-height-tall.svg';
import { ReactComponent as RowHeight } from 'icons/row-height.svg';
import { ReactComponent as Rss } from 'icons/rss.svg';
import { ReactComponent as Save } from 'icons/save.svg';
import { ReactComponent as ScatterChart } from 'icons/scatterchart.svg';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Files } from 'icons/service/ic-service-files.svg';
import { ReactComponent as Settings } from 'icons/settings.svg';
import { ReactComponent as Share } from 'icons/share.svg';
import { ReactComponent as SmallPlus } from 'icons/small-plus.svg';
import { ReactComponent as Smaller } from 'icons/smaller.svg';
import { ReactComponent as Sort } from 'icons/sort.svg';
import { ReactComponent as StarFilled } from 'icons/star-filled.svg';
import { ReactComponent as StarLined } from 'icons/star-lined.svg';
import { ReactComponent as Support } from 'icons/support.svg';
import { ReactComponent as Tiles } from 'icons/tiles.svg';
import { ReactComponent as Upload } from 'icons/upload.svg';
import { ReactComponent as UserName } from 'icons/user-name.svg';
import { ReactComponent as UserIcon } from 'icons/user-icon.svg';
import { ReactComponent as UserProfile } from 'icons/user-profile-new-icon.svg';
import { ReactComponent as VersionHistory } from 'icons/version-history.svg';
import { ReactComponent as Workspace } from 'icons/workspace.svg';
import { ReactComponent as ZoomIn } from 'icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'icons/zoom-out.svg';

const svgIcons = {
  Add,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowToLeft,
  ArrowToRight,
  Attach,
  Autosize,
  AutosizeAll,
  BarChart,
  BubbleChart,
  Button,
  Charts,
  Check,
  CheckboxChecked,
  CheckboxForLabel,
  CheckboxUnchecked,
  Clock,
  Close,
  CloseCircle,
  Collapse,
  CollapseFolder,
  CollapseSidebar,
  Columns,
  Csv,
  Database,
  Dashboard,
  Delete,
  Download,
  DonutChart,
  Drag,
  Dropdown,
  Dropleft,
  DueDate,
  Duplicate,
  Edit,
  EmailSent,
  Excel,
  Expand,
  ExpandFolder,
  ExpandRecord,
  Expire,
  Export,
  Filter,
  Folder,
  Files,
  FolderNew,
  Fullscreen,
  FullscreenExit,
  Grid,
  Group,
  Hide,
  Info,
  InfoFilled,
  LogOut,
  Link,
  LineChart,
  Lock,
  LockColumn,
  LockFilled,
  LockLight,
  LockOpen,
  LockInput,
  Limited,
  Limited2,
  Kanban,
  MenuDots,
  Message,
  ModifyField,
  MoveArrowLeft,
  MoveArrowRight,
  NeedHelp,
  Organization,
  Pagination,
  PasswordShow,
  PinColumn,
  PinColumnV2,
  PieChart,
  Print,
  PrivateKey,
  PrimaryKey,
  PrivateView,
  PublicView,
  Records,
  Remove,
  RowHeight,
  RowHeightShort,
  RowHeightMedium,
  RowHeightTall,
  Rss,
  Search,
  Save,
  ScatterChart,
  Settings,
  Sort,
  Smaller,
  SmallPlus,
  StarFilled,
  StarLined,
  Support,
  Share,
  Tiles,
  UserName,
  UserIcon,
  UserProfile,
  Upload,
  VersionHistory,
  Workspace,
  ZoomIn,
  ZoomOut,

  // Field Types
  Account,
  Attachment,
  Barcode,
  Boolean,
  Checkbox,
  Checklist,
  Currency,
  Date,
  Email,
  MultilineText,
  MultipleChoice,
  Number,
  Percent,
  Phone,
  Rating,
  SingleChoice,
  SingleLineOfText,
  Tag,
  Url,

  // File Extensions
  sevenZ,
  ai,
  aif,
  aifc,
  aiff,
  asf,
  aspx,
  atom,
  au,
  azw,
  bat,
  bmp,
  bz,
  bz2,
  cer,
  chm,
  cin,
  cmd,
  config,
  crl,
  crt,
  cs,
  csh,
  csproj,
  css,
  csv,
  dds,
  der,
  dng,
  dvi,
  emf,
  eml,
  eps,
  epub,
  excel,
  exe,
  exr,
  flv,
  folder,
  gif,
  go,
  gz,
  hdr,
  htm,
  html,
  ico,
  ics,
  ini,
  jpeg,
  jpg,
  js,
  json,
  latex,
  less,
  lnk,
  log,
  man,
  md,
  mdb,
  mid,
  midi,
  mkv,
  mobi,
  mp2,
  mp3,
  mp4,
  mpe,
  mpeg,
  mpg,
  mpp,
  msg,
  msi,
  nfo,
  odp,
  ods,
  odt,
  one,
  ost,
  otf,
  p7b,
  p7c,
  p7r,
  p12,
  pbm,
  pcx,
  pdf,
  pfx,
  php,
  pict,
  pl,
  png,
  powerpoint,
  prc,
  ps,
  ps1,
  psb,
  psd,
  psp,
  pst,
  pub,
  py,
  rar,
  resx,
  rss,
  sass,
  sh,
  snd,
  spc,
  sql,
  svg,
  tab,
  tar,
  tex,
  tga,
  tif,
  tiff,
  toml,
  tsv,
  ttf,
  txtRtf,
  unknown,
  vbs,
  vcf,
  vcs,
  vsd,
  vsdx,
  vst,
  wav,
  webp,
  wmf,
  wmz,
  word,
  xaml,
  xht,
  xhtml,
  xml,
  xps,
  xsl,
  xslt,
  yaml,
  zip,

  // Header
  HeaderMenu,
  HeaderNotifications,
  HeaderSearch,
};

export default svgIcons;
