import * as constants from 'data/collections/constants';
import { action, ActionsUnion } from 'types/reduxHelpers';
import {
  CreateFieldPayload,
  CreateNodeErrorPayload,
  CreateNodeRequestPayload,
  CreateNodeSuccessPayload,
  DeletedNodesErrorPayload,
  DeletedNodesPayload,
  DuplicateFieldPayload,
  DuplicateNodeRequestPayload,
  ErrorPayload,
  IdRendererParams,
  SaveFieldSuccessPayload,
  SetFieldSummary,
  UpdateFieldPayload,
} from 'data/collections/collections.reducer';
import { CommonNode } from 'types/response';
import { createStandardAction } from 'typesafe-actions';

// ------------------------------------
// Actions
// ------------------------------------

export const Actions = {
  createField: action<typeof constants.CREATE_FIELD, CreateFieldPayload>(constants.CREATE_FIELD),
  duplicateField: action<typeof constants.DUPLICATE_FIELD, DuplicateFieldPayload>(constants.DUPLICATE_FIELD),
  updateField: action<typeof constants.UPDATE_FIELD, UpdateFieldPayload>(constants.UPDATE_FIELD),
  saveFieldError: action<typeof constants.SAVE_FIELD_ERROR, ErrorPayload>(constants.SAVE_FIELD_ERROR),
  saveFieldSuccess: action<typeof constants.SAVE_FIELD_SUCCESS, SaveFieldSuccessPayload>(constants.SAVE_FIELD_SUCCESS),
  deleteField: action<typeof constants.DELETE_FIELD, string>(constants.DELETE_FIELD),
  deleteFieldSuccess: action<typeof constants.DELETE_FIELD_SUCCESS, string>(constants.DELETE_FIELD_SUCCESS),
  setNodes: createStandardAction('collections/SET_NODES')<CommonNode[]>(),
  createNode: action<typeof constants.CREATE_NODE_REQUEST, CreateNodeRequestPayload>(constants.CREATE_NODE_REQUEST),
  createNodeSuccess: action<typeof constants.CREATE_NODE_SUCCESS, CreateNodeSuccessPayload>(constants.CREATE_NODE_SUCCESS),
  createNodeError: action<typeof constants.CREATE_NODE_ERROR, CreateNodeErrorPayload>(constants.CREATE_NODE_ERROR),
  duplicateNode: action<typeof constants.DUPLICATE_NODE, DuplicateNodeRequestPayload>(constants.DUPLICATE_NODE),
  addNodeInCreation: createStandardAction('collections/ADD_NODE_IN_CREATION')<CommonNode>(),
  deleteNodes: action<typeof constants.DELETE_NODE_REQUEST, DeletedNodesPayload>(constants.DELETE_NODE_REQUEST),
  deleteNodeError: action<typeof constants.DELETE_NODE_ERROR, DeletedNodesErrorPayload>(constants.DELETE_NODE_ERROR),
  deleteNodeSuccess: action<typeof constants.DELETE_NODE_SUCCESS, DeletedNodesPayload>(constants.DELETE_NODE_SUCCESS),
  cleanCollection: action<typeof constants.CLEAN_COLLECTION>(constants.CLEAN_COLLECTION),
  setFieldSummary: action<typeof constants.SET_FIELD_SUMMARY, SetFieldSummary>(constants.SET_FIELD_SUMMARY),
  setIdRendererParams: action<typeof constants.SET_ID_RENDERER_PARAMS, IdRendererParams>(constants.SET_ID_RENDERER_PARAMS),
  selectDragItem: action<typeof constants.START_DRAG_ITEM, CommonNode>(constants.START_DRAG_ITEM),
  stopDragItem: action<typeof constants.STOP_DRAG_ITEM>(constants.STOP_DRAG_ITEM),
  // patchFieldSummary: action<typeof constants.PATCH_FIELD_SUMMARY, PatchFieldSummary>(constants.PATCH_FIELD_SUMMARY),
};


export type ActionsType = ActionsUnion<typeof Actions>;
