import * as qs from 'qs';
const CONTENT_TYPE_APPLICATION_JSON = 'application/json';

export interface QueryParams {
  [param: string]: string | string[] | number | number[] | null | undefined;
}

interface BuildRequestParams {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  authToken: string;
  body?: string;
  queryParams?: QueryParams;
  shouldAbort?: boolean;
  url: string;
}

function createRequest(params: BuildRequestParams): Request {
  const {
    authToken,
    body,
    method,
    queryParams,
    shouldAbort = false,
    url,
  } = params;

  let requestUrl = url;
  if (queryParams) {
    const queryParamsString = qs.stringify(queryParams, { skipNulls: true });
    requestUrl = `${url}?${queryParamsString}`;
  }

  const controller = new AbortController();

  if (shouldAbort) {
    // Abort after 5 seconds
    setTimeout(() => controller.abort(), 5000);
  }

  const requestOptions: RequestInit = {
    method,
    headers: {
      'Accept': CONTENT_TYPE_APPLICATION_JSON,
      'Authorization': authToken,
      'Cache-Control': 'no-cache',
    },
    body,
    signal: controller.signal,
  };

  return new Request(requestUrl, requestOptions);
}

interface PostParams {
  url: string;
  body?: object;
  queryParams?: QueryParams;
  shouldAbort?: boolean;
}

export default class AjaxService {
  private authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  get(props: { url: string; queryParams?: QueryParams }) {
    const request = createRequest({
      method: 'GET',
      authToken: this.authToken,
      queryParams: props.queryParams,
      url: props.url,
    });

    return fetch(request);
  }

  public post(params: PostParams): Promise<Response> {
    const request = createRequest({
      ...params,
      method: 'POST',
      authToken: this.authToken,
      body: params.body ? JSON.stringify(params.body) : undefined,
    });

    return fetch(request);
  }

  patch({ url, body, queryParams }: {url: string; body?: object; queryParams?: QueryParams}) {
    const request = createRequest({
      method: 'PATCH',
      authToken: this.authToken,
      queryParams,
      url,
      body: body ? JSON.stringify(body) : undefined,
    });

    return fetch(request);
  }

  delete({ url, queryParams }: { url: string; queryParams?: QueryParams }) {
    const request = createRequest({
      method: 'DELETE',
      authToken: this.authToken,
      queryParams,
      url,
    });

    return fetch(request);
  }
}
