import React, { useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import Button, { Variant as ButtonVariant } from 'components/Button';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { SIGN_IN_MODES } from 'pages/Auth/SignIn';
import { Redirect } from 'react-router-dom';
import { styles } from './MFA.index.style';

interface StateProps {
  user: User;
  mode: SIGN_IN_MODES;
}
interface DispatchProps {
  updateUserMFAMethod: (payload: { data: string }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
  // @ts-ignore state
  mode: (state: ReduxState) => state.mode || '',
});

const FirstLogin = (props) => {
  const { classes, onSelectedMethod, isLoadingChallenge } = props;

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  const [email, setEmail] = useState(false);
  const [sms, setSMS] = useState(false);
  const tenant = window.appStore.getState().app.tenant;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isLoadingChallenge);
  }, [isLoadingChallenge]);

  const selectedMethod = () => {
    onSelectedMethod(email ? 'email' : sms ? 'sms' : '' );
  };

  if (props.mode === SIGN_IN_MODES.LOGGED_IN) {
    return <Redirect to="/workspaces" />;
  }

  return (
    <>
      <div className={classes.topContainer}>
        <div className="auth-form-mfa-container">
          <div className={classes.mfaFormContainer}>
            <div className={classes.topContent}>
              <h3>Multi-Factor Authentication</h3>
              <p>
                To provide an extra layer of protection to your account, choose
                one of the following multi-factor authentication methods.
              </p>
            </div>
            <div>
              <div className={classes.inputSelected}>
                <div className={classes.radioButtonContainer}>
                  <div className={'radio'+ (tenantConfig.clientName || '')}>
                    <Radio
                      disableRipple={true}
                      checked={sms}
                      onClick={() => {
                        setSMS(true);
                        setEmail(false);
                      }}
                      value={sms}
                      color="secondary"
                    />
                  </div>
                </div>
                <div className={classes.textContainer}>
                  <p className={classes.itemTitle}>Text Message (SMS)</p>
                  <p className={classes.itemSubtitle}>
                    We will send a security code to your phone on every log in
                    attempt.
                  </p>
                </div>
              </div>
              <div className={classes.inputSelected}>
                <div className={classes.radioButtonContainer}>
                  <Radio
                    disableRipple={true}
                    checked={email}
                    onClick={() => {
                      setSMS(false);
                      setEmail(true);
                    }}
                    value={email}
                    color="secondary"
                  />
                </div>
                <div className={classes.textContainer}>
                  <p className={classes.itemTitle}>Email</p>
                  <p className={classes.itemSubtitle}>
                    We will send a security code to your email address on every
                    log in attempt.
                  </p>
                </div>
              </div>
              <div>
                <Button
                  id="continueSetup"
                  className={ tenantConfig.clientName ? classes.continueActionButtonbakertilly : classes.continueActionButton }
                  label="Continue Setup"
                  isLoading={isLoading}
                  variant={tenantConfig.clientName ? ButtonVariant.Secondary :ButtonVariant.Primary}
                  onClick={() => {
                    setIsLoading(true);
                    selectedMethod();
                  }}
                  disabled={(!email && !sms) || isLoading}
                />
                <Button
                  id="needHelp"
                  className={classes.helpActionButton}
                  label="Need Help?"
                  variant={ButtonVariant.PrimaryLink}
                  onClick={() => {
                    window.open(tenant.apiEndpoints.zendeskTargetURL, '_blank');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(FirstLogin));
