import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import ActionButtons, { OwnProps as ButtonsProps } from './ActionButtons';
import { FieldData } from './data';
import FieldNameInput from './FieldNameInput';
import FieldMetadata from './SelectFieldType/FieldMetadata';
import styles, { ClassKey } from './styles';

interface OwnProps extends ButtonsProps {
  fieldName?: string;
  errorMessage?: string;
  selectedFieldData: FieldData;
  allowMultipleCollaborators?: boolean;
  onFieldNameChange(newFieldName: string): void;
  onDataUpdate(data: FieldData): void;
  onCancel(): void;
  onSave(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function SingleField(props: Props): JSX.Element {
  const {
    allowMultipleCollaborators,
    classes,
    selectedFieldData,
    fieldName,
    errorMessage,
  } = props;

  return (
    <div className={classes.wrapper}>
      <FieldNameInput
        fieldName={fieldName}
        errorMessage={errorMessage}
        onChange={props.onFieldNameChange}
      />
      <div className={classes.fieldMetadata}>
        <FieldMetadata
          selectedFieldData={selectedFieldData}
          allowMultipleCollaborators={allowMultipleCollaborators}
          onDataUpdate={props.onDataUpdate}
        />
      </div>
      <ActionButtons
        onCancel={props.onCancel}
        onSave={props.onSave}
      />
    </div>
  );
}

export default styles(SingleField);
