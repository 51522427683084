import * as React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { withStyles, WithStyles } from '@material-ui/core';

import { ghostGrey, white } from 'styles/colors';
import CardWrapper from 'components/CalendarView/ExpansionPanel/CardWrapper';
import { CalendarEvent } from 'types/gridOptions';

interface Props extends WithStyles<typeof styles> {
  event: CalendarEvent;
}

function CardWrapperDragPreview({ classes, event }: Props) {
  return (
    <CardWrapper
      className={classes.cardWrapper}
      event={event}
    />
  );
}

type ClassKey = 'cardWrapper';

const styles: Record<ClassKey, CSSProperties> = {
  cardWrapper: {
    backgroundColor: white,
    maxWidth: 240,
    padding: '1rem',
    boxShadow: `0 4px 8px 0 ${ghostGrey}`,
  },
};

export default withStyles(styles)(CardWrapperDragPreview);
