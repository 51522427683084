import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey = 'root'
  | 'uploader'
  | 'header'
  | 'footer'
  | 'actionButtons'
  | 'filesWrapper'
  | 'divider'
;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '4px',
    width: '719px',
    height: '496px',
    display: 'flex',
    overflow: 'hidden',
  },
  uploader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    flex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '19px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: colors.iceBlue,
    margin: '12px 0',
  },
  actionButtons: {
    display: 'flex',
    width: '150px',
    justifyContent: 'space-between',
  },
  filesWrapper: {
    height: '100%',
    overflow: 'auto',
  },
};
