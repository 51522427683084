import * as request from 'superagent';
import { normalizeURL } from './format';
import getAuthHeaders from './getAuthHeaders';

interface ApiHeaders {
  ['Cache-Control']: string;
}

export const createHeaders = async (headers) => {
  const authHeaders = await getAuthHeaders() as any;
  return {
    ...authHeaders,
    ...headers,
  };
};

export async function postRequest({ url, data }, state, headers?) {
  const { app: { validCredentials } } = state;

  if (validCredentials) {
    return request
      .post(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function postRequestWithoutNormalization({ url, data }, state, headers?) {
  const { app: { validCredentials } } = state;

  if (validCredentials) {
    return request
      .post(url)
      .set(await createHeaders(headers))
      .set('Accept', 'application/json')
      .send({ data })
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function getRequest(url: string, state: any, headers?) {
  const { app: { validCredentials } } = state;
  if (validCredentials) {
    return request
      .get(normalizeURL(url))
      .set(await createHeaders(headers))
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function downloadFile(url) {
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('_target', 'blank');

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export async function patchRequest({ url, data }, state, headers?) {
  const { app: { validCredentials } } = state;

  if (validCredentials) {
    return request.patch(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function deleteRequest(url: string, state, headers?: any, data?: any) {
  const { app: { validCredentials } } = state;

  if (validCredentials) {
    return request.delete(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function putRequest({ url, data }, state, headers?) {
  const { app: { validCredentials } } = state;

  if (validCredentials) {
    return request.put(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}


export async function get(url: string, headers?) {
  const { app: { validCredentials } } = window.appStore.getState();
  if (validCredentials) {
    return request
      .get(normalizeURL(url))
      .set(await createHeaders(headers))
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function post({ url, data }, headers?) {
  const { app: { validCredentials } } = window.appStore.getState();
  if (validCredentials) {
    return request
      .post(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function patch({ url, data }, headers?) {
  const { app: { validCredentials } } = window.appStore.getState();

  if (validCredentials) {
    return request.patch(normalizeURL(url))
      .set(await createHeaders(headers))
      .send(data)
      .catch((error) => {
        throw error;
      });
  }
  return null;
}

export async function deleteReq(url: string, headers?) {
  const { app: { validCredentials } } = window.appStore.getState();

  if (validCredentials) {
    return request.delete(normalizeURL(url))
      .set(await createHeaders(headers))
      .catch((error) => {
        throw error;
      });
  }
  return null;
}
