import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { notifications } from 'data/ui/notifications/notifications.actions';
import { CustomError } from 'utilities/errors';
import { State } from 'reducers';

import { MODULE } from './constants';
import * as types from './types';
import * as helpers from './helpers';
import * as services from './services';

export const appendMessage = createAction<types.Message>(`${MODULE}/APPEND_MESSAGE`);

export const prependMessage = createAction<types.Message[]>(`${MODULE}/PREPEND_MESSAGES`);

export const resetMessages = createAction(`${MODULE}/RESET_MESSAGES`);

export const setMessageCount = createAction<number>(`${MODULE}/SET_MESSAGES_COUNT`);

export const setCurrentChannel = createAction<string>(`${MODULE}/SET_CURRENT_CHANNEL`);

export const setMessages = createAction<types.Message[]>(`${MODULE}/SET_MESSAGES`);

export const setMessageError = createAction<string>(`${MODULE}/SET_MESSAGE_ERROR`);

export const getMessages = createAsyncThunk<
  void, { workspaceId: string; query: types.QueryParams }
>(
  `${MODULE}/FETCH_MESSAGES`,
  async ({ workspaceId, query }, { getState, dispatch }) => {
    const state = getState() as State;
    const { channels, currentChannelId } = state.messages;
    const currentChannel = channels[currentChannelId];

    const { body } = await services.fetchMessages(workspaceId, query);

    if (currentChannel?.messages.length === 0) {
      dispatch(setMessageCount(body.count));
    }
    dispatch(prependMessage(body.messages));
  },
);

export const sendMessage = createAsyncThunk<void, types.MessageBody>(
  `${MODULE}/SEND_MESSAGE`,
  async (messageBody, { getState, dispatch }) => {
    const state = getState() as State;
    const { workspaces, users } = state;
    const { current } = workspaces;
    const { user } = users;
    let message: types.Message | undefined;
    let channelMessages: types.Message[] = [];
    let messagesCount = 0;

    try {
      if (!user) {
        throw new CustomError('NotUserError', 'Empty user for sending message');
      }

      const messagePayload = helpers.createMessagePayload(messageBody, user, state);
      message = {
        ...messagePayload,
        id: new Date().toISOString(),
        body: { ...messageBody },
        workspaceId: messagePayload.workspaceID,
        senderUserId: user.id,
        sender: {
          id: user.id,
          displayName: user.displayName,
          thumbnailURL: user.thumbnailURL || '',
        },
        createdOn: new Date().toISOString(),
        status: types.MessageStatuses.CREATED,
      };
      dispatch(appendMessage(message));

      const { body } = await services.createMessage(current, messagePayload);
      const updatedState = getState() as State;
      const newMessages = updatedState.messages;
      const currentChannel = newMessages?.channels[newMessages?.currentChannelId];
      channelMessages = currentChannel.messages;
      messagesCount = currentChannel.messagesCount;

      const messageIndex = channelMessages
        .findIndex(msg => msg.id === message?.id);
      const updatedMessages = [
        ...channelMessages.slice(0, messageIndex),
        { ...channelMessages[messageIndex], id: body.id },
        ...channelMessages.slice(messageIndex + 1),
      ];
      dispatch(setMessages(updatedMessages));
    } catch (error) {
      if (message) {
        const filteredMessages = channelMessages
          .filter(msg => msg.id !== message?.id);
        dispatch(setMessages(filteredMessages));
        dispatch(setMessageCount(messagesCount - 1));
      }
      dispatch(setMessageError('Failed to send message'));
      dispatch(notifications.error(error));
    }
  },
);

export const deleteMessage = createAsyncThunk<void, string>(
  `${MODULE}/DELETE_MESSAGE`,
  async (id, { getState, dispatch }) => {
    const state = getState() as State;
    const { current } = state.workspaces;
    const { channels, currentChannelId } = state.messages;
    const { messages, messagesCount } = channels[currentChannelId];
    const message = messages.find(message => message.id === id);
    if (message !== undefined) {
      const filteredMessages = messages.filter(msg => msg.id !== id);
      dispatch(setMessages(filteredMessages));
      dispatch(setMessageCount(messagesCount - 1));

      try {
        await services.deleteMessage(current, id);
      } catch (error) {
        dispatch(appendMessage(message));
        dispatch(setMessageError('Failed to delete message'));
      }
    }
  },
);

export const updateMessage = createAsyncThunk<void, types.UpdateMessage>(
  `${MODULE}/DELETE_MESSAGE`,
  async ({ id, messageBody }, { getState, dispatch }) => {
    const state = getState() as State;
    const { current } = state.workspaces;
    const { channels, currentChannelId } = state.messages;
    const { messages } = channels[currentChannelId];
    const message = messages.find(msg => msg.id === id);
    if (message !== undefined) {
      const updatedMessages = helpers.filterMessages(id, messageBody, messages);
      dispatch(setMessages(updatedMessages));

      try {
        await services.patchMessage(current, id, messageBody);
      } catch (error) {
        const oldMessages = helpers.filterMessages(id, message.body, messages);
        dispatch(setMessages(oldMessages));
        dispatch(setMessageError('Failed to update message'));
      }
    }
  },
);
