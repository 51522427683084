import { ValueFormatterParams } from 'ag-grid-community';
import { ROW_NUMBER_ID } from 'components/DataGrid/columns/constants';
import { DEFAULT_DATE_FORMAT } from 'components/Fields/data';
import { isStringArray } from 'components/NodeFieldData/filters/helpers';
import { AttachmentsState } from 'data/attachments/reducer';
import moment from 'moment';
import { ColumnsMapById } from 'types/gridOptions';
import { CommonNode, FieldValue, Timestamp } from 'types/response';
import { AccountNode } from 'types/response/accountNode';
import { AttachmentNode } from 'types/response/attachmentNode';
import { FieldType } from 'types/response/fieldNode';
import { Column } from 'types/schema';

export const getStringValue = (
  value: FieldValue,
  column: Column,
  accountsById: Record<string, AccountNode>,
  attachmentsById: Record<string, AttachmentNode>,
): string => {
  if (!column) return '';

  switch (column.fieldType) {
    case FieldType.Singlelineoftext:
    case FieldType.Multilinetext:
    case FieldType.Email:
    case FieldType.FileName:
    case FieldType.FileExtension:
    case FieldType.FileSize:
    case FieldType.Url:
    case FieldType.Staticformula:
      return typeof value === 'string' ? value : '';
    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Percent:
    case FieldType.Rating:
    case FieldType.Currency:
      if (typeof value === 'string' && column.precision) {
        return `${value}.${'0'.repeat(column.precision)}`;
      }
      return typeof value === 'string' ? value.toString() : '';
    case FieldType.Boolean:
      return typeof value === 'boolean' ? value.toString() : 'false';
    case FieldType.Date:
      if (value && typeof value === 'object' && (value as Timestamp).timestamp) {
        value = new Date((value as Timestamp).timestamp).getTime();
      }
      return typeof value === 'number' ? moment(value).format(column.dateFormat || DEFAULT_DATE_FORMAT) : '';
    case FieldType.Singlechoice:
    case FieldType.Dropdown:
      return typeof value === 'string' && column.choices && column.choices[value] ? column.choices[value].label : '';
    case FieldType.Multiplechoice:
      return isStringArray(value)
        ? value
          .map(id => column.choices && column.choices[id] ? column.choices[id].label : '')
          .join(' ')
        : '';
    case FieldType.Account:
      if (typeof value === 'string' && !column.allowMultiple && accountsById[value]) {
        return accountsById[value].displayName;
      } else if (isStringArray(value) && column.allowMultiple) {
        return value.map(id => accountsById[id] ? accountsById[id].displayName : '').join(' ');
      } else {
        return '';
      }
    case FieldType.Attachment:
      if (attachmentsById && typeof attachmentsById === 'object') {
        const res: string[] = [];
        Object.keys(attachmentsById).forEach((id: string) => {
          const attachment = attachmentsById[id];
          const reference = attachment['references'] || {};
          const attachmentFieldID = `${reference['fieldID']}`;
          if (attachmentFieldID === `${column['_id']}`) {
            attachment['title'] && res.push(attachment['title']);
          }
        });
        return res.join(',').toLowerCase();
      }
      return '';
    case FieldType.Barcode:
    case FieldType.BLOBReference:
    case FieldType.Bookmarked:
    case FieldType.Checklist:
    case FieldType.Computed:
    case FieldType.DataReference:
    case FieldType.DateTime:
    case FieldType.Duration:
    case FieldType.Fraction:
    case FieldType.GUID:
    case FieldType.ID:
    case FieldType.Location:
    case FieldType.Lookup:
    case FieldType.Phone:
    case FieldType.SearchReference:
    case FieldType.Tag:
    case FieldType.Time:
    case FieldType.Timespan:
    default:
      return '';
  }
};

const getValueFormatter = (
  schemaColumns: ColumnsMapById,
  accountsById: Record<string, AccountNode>,
  attachments: AttachmentsState,
) => (params: ValueFormatterParams): string => {
  const { column, columnApi, data, value } = params;
  const node: CommonNode = data;
  let colId = column.getColId();

  // If this is the index column, the value is from the next column (first data column)
  if (column.getColId() === ROW_NUMBER_ID && columnApi) {
    const columns = columnApi.getAllDisplayedColumns();
    if (columns.length <= 2) {
      return '';
    } else {
      colId = columns[1].getColId();
    }
  }
  const schemaColumn = schemaColumns[colId];
  const attachmentsById = (node && attachments && attachments.byItemId[node.id] && attachments.byItemId[node.id].byId) || {};
  return getStringValue(value, schemaColumn, accountsById, attachmentsById);
};

export default getValueFormatter;
