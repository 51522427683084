import { all, fork, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { createWorkspaceUrl } from 'utilities/createUrl';
import { createAPIHandler, RequestType, successToast } from 'utilities/saga';

import { actions } from 'data/workspaces/workspaces.actions';
import { fetchNavigationTree } from 'data/app/actions';
import { updateDatabaseSuccess } from 'data/databases/databases.actions';
import { CollectionTypes } from 'types/schema';

import { mapWorkspaceResponse } from '../collections/collections.mapper';

function* fetchWorkspace() {
  yield createAPIHandler({
    actions: actions.fetchWorkspace,
    requestType: RequestType.Get,
    buildUrl: (action) => `${createWorkspaceUrl(action.payload.id)}`,
    * onSuccess(payload) {
      yield put(actions.fetchWorkspace.success(mapWorkspaceResponse({ body: payload }) ));
    },
  });
}

// we have archive/unarchive actions separated instead of toggle
// to keep them idempotent and state-independent
function* archiveWorkspaceSaga() {
  yield createAPIHandler({
    actions: actions.archiveWorkspace,
    requestType: RequestType.Post,
    buildUrl: (action) => `${createWorkspaceUrl(action.payload)}:freeze`,
    * onSuccess(payload) {
      yield put(updateDatabaseSuccess(payload, CollectionTypes.workspaces));
      yield call(successToast, 'Archived successfully!');
    },
  });
}

function* unarchiveWorkspaceSaga() {
  yield createAPIHandler({
    actions: actions.unarchiveWorkspace,
    requestType: RequestType.Post,
    buildUrl: (action) => `${createWorkspaceUrl(action.payload)}:unfreeze`,
    * onSuccess(payload) {
      yield put(updateDatabaseSuccess(payload, CollectionTypes.workspaces));
      yield call(successToast, 'Unarchived successfully!');
    },
  });
}

function* deleteWorkspaceSaga() {
  yield createAPIHandler({
    actions: actions.deleteWorkspace,
    requestType: RequestType.Delete,
    buildUrl: action => `${createWorkspaceUrl(action.payload)}?api_version=2`,
    * onSuccess() {
      yield all([
        put(push({ pathname: createWorkspaceUrl() })),
        put(fetchNavigationTree()),
      ]);
    },
  });
}

export default function* watchSaga() {
  yield all([
    fork(fetchWorkspace),
    fork(archiveWorkspaceSaga),
    fork(unarchiveWorkspaceSaga),
    fork(deleteWorkspaceSaga),
  ]);
}
