export interface CreateClientWorkspaceFormTypes {
    clientId: string;
    clientName: string;
    description: string;
    email: string;
    office: string;
    partnerEmail: string;
    region: string;
    returnType: string;
    spouseFirstName: string;
    spouseLastName: string;
    taxpayerEmail: string;
    taxpayerFirstName: string;
    taxpayerLastName: string;
}

// email regular expression
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Alpha numeric regular expression
export const alphaNumericRegex = /^[a-zA-Z0-9]{1,40}$/;

// alphanumeric with some special characters like hiphen and underscore
export const nameRegex = /^[a-zA-Z0-9,.'-]+$/i;
