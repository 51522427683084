import * as React from 'react';

import { Heading } from 'pages/TilesViewPage.style';

import TileItem, { EmptyTileItem } from './TileItem';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from 'components/TilesView/TilesList.style';
import { CollectionTypes, NodeWithTitle } from 'types/schema';

const rolesPermissions = {
  'HubSync Admin': [
    'workspaces:get',
    'workspaces:update',
    'workspaces:list',
    'workspaces:invite',
    'workspaces:delete',
    'databases:get',
    'databases:create',
    'databases:delete',
    'databases:update',
    'databases:export',
    'databases:link',
    'databases:list',
    'databases:lock',
    'databases:unlock',
    'databases:share',
    'databases:invite',
    'sheets:get',
    'sheets:create',
    'sheets:delete',
    'sheets:update',
    'sheets:export',
    'sheets:link',
    'sheets:list',
    'sheets:lock',
    'sheets:unlock',
    'sheets:share',
    'sheets:invite',
    'items:get',
    'items:create',
    'items:delete',
    'items:update',
    'items:export',
    'items:link',
    'items:list',
    'items:lock',
    'items:unlock',
    'items:share',
    'items:invite',
    'views:get',
    'views:create',
    'views:delete',
    'views:update',
    'views:export',
    'views:link',
    'views:list',
    'views:lock',
    'views:unlock',
    'views:share',
    'views:invite',
    'fields:get',
    'fields:create',
    'fields:delete',
    'fields:update',
    'fields:export',
    'fields:link',
    'fields:list',
    'fields:lock',
    'fields:unlock',
    'fields:share',
    'fields:invite',
    'files:get',
    'files:create',
    'files:delete',
    'files:update',
    'files:export',
    'files:link',
    'files:list',
    'files:lock',
    'files:unlock',
    'files:share',
    'files:invite',
    'tasks:get',
    'tasks:create',
    'tasks:delete',
    'tasks:update',
    'tasks:export',
    'tasks:link',
    'tasks:list',
    'tasks:lock',
    'tasks:unlock',
    'tasks:share',
    'tasks:invite',
    'checklists:get',
    'checklists:create',
    'checklists:delete',
    'checklists:update',
    'checklists:export',
    'checklists:link',
    'checklists:list',
    'checklists:lock',
    'checklists:unlock',
    'checklists:share',
    'checklists:invite',
    'analytics:get',
    'analytics:create',
    'analytics:delete',
    'analytics:update',
    'analytics:export',
    'analytics:link',
    'analytics:list',
    'analytics:lock',
    'analytics:unlock',
    'analytics:share',
    'analytics:invite',
    'messages:get',
    'messages:create',
    'messages:delete',
    'messages:update',
    'messages:export',
    'messages:link',
    'messages:list',
    'messages:lock',
    'messages:unlock',
    'messages:share',
    'messages:invite',
    'people:get',
    'people:create',
    'people:delete',
    'people:update',
    'people:export',
    'people:link',
    'people:list',
    'people:lock',
    'people:unlock',
    'people:share',
    'people:invite',
  ],
  'Administrator': [
    'workspaces:get',
    'workspaces:update',
    'workspaces:list',
    'workspaces:invite',
    'databases:get',
    'databases:create',
    'databases:delete',
    'databases:update',
    'databases:export',
    'databases:link',
    'databases:list',
    'databases:lock',
    'databases:unlock',
    'databases:share',
    'databases:invite',
    'sheets:get',
    'sheets:create',
    'sheets:delete',
    'sheets:update',
    'sheets:export',
    'sheets:link',
    'sheets:list',
    'sheets:lock',
    'sheets:unlock',
    'sheets:share',
    'sheets:invite',
    'items:get',
    'items:create',
    'items:delete',
    'items:update',
    'items:export',
    'items:link',
    'items:list',
    'items:lock',
    'items:unlock',
    'items:share',
    'items:invite',
    'views:get',
    'views:create',
    'views:delete',
    'views:update',
    'views:export',
    'views:link',
    'views:list',
    'views:lock',
    'views:unlock',
    'views:share',
    'views:invite',
    'fields:get',
    'fields:create',
    'fields:delete',
    'fields:update',
    'fields:export',
    'fields:link',
    'fields:list',
    'fields:lock',
    'fields:unlock',
    'fields:share',
    'fields:invite',
    'files:get',
    'files:create',
    'files:delete',
    'files:update',
    'files:export',
    'files:link',
    'files:list',
    'files:lock',
    'files:unlock',
    'files:share',
    'files:invite',
    'tasks:get',
    'tasks:create',
    'tasks:delete',
    'tasks:update',
    'tasks:export',
    'tasks:link',
    'tasks:list',
    'tasks:lock',
    'tasks:unlock',
    'tasks:share',
    'tasks:invite',
    'checklists:get',
    'checklists:create',
    'checklists:delete',
    'checklists:update',
    'checklists:export',
    'checklists:link',
    'checklists:list',
    'checklists:lock',
    'checklists:unlock',
    'checklists:share',
    'checklists:invite',
    'analytics:get',
    'analytics:create',
    'analytics:delete',
    'analytics:update',
    'analytics:export',
    'analytics:link',
    'analytics:list',
    'analytics:lock',
    'analytics:unlock',
    'analytics:share',
    'analytics:invite',
    'messages:get',
    'messages:create',
    'messages:delete',
    'messages:update',
    'messages:export',
    'messages:link',
    'messages:list',
    'messages:lock',
    'messages:unlock',
    'messages:share',
    'messages:invite',
    'people:get',
    'people:create',
    'people:delete',
    'people:update',
    'people:export',
    'people:link',
    'people:list',
    'people:lock',
    'people:unlock',
    'people:share',
    'people:invite',
  ],
  'Editor': [
    'workspaces:get',
    'workspaces:list',
    'databases:get',
    'databases:export',
    'databases:list',
    'sheets:get',
    'sheets:create',
    'sheets:delete',
    'sheets:update',
    'sheets:export',
    'sheets:list',
    'items:get',
    'items:create',
    'items:delete',
    'items:update',
    'items:export',
    'items:list',
    'views:get',
    'views:create',
    'views:delete',
    'views:update',
    'views:export',
    'views:list',
    'files:get',
    'files:create',
    'files:delete',
    'files:update',
    'files:export',
    'files:list',
    'files:share',
    'tasks:get',
    'tasks:export',
    'tasks:list',
    'checklists:get',
    'checklists:create',
    'checklists:delete',
    'checklists:update',
    'checklists:export',
    'checklists:list',
    'analytics:get',
    'analytics:create',
    'analytics:delete',
    'analytics:update',
    'analytics:export',
    'analytics:list',
    'messages:get',
    'messages:create',
    'messages:delete',
    'messages:update',
    'messages:export',
    'messages:list',
  ],
  'Reader': [
    'workspaces:get',
    'workspaces:list',
    'databases:get',
    'databases:list',
    'sheets:get',
    'sheets:list',
    'items:get',
    'items:list',
    'views:get',
    'views:list',
    'fields:get',
    'fields:list',
    'files:get',
    'files:export',
    'files:list',
    'tasks:get',
    'tasks:list',
    'checklists:get',
    'checklists:list',
    'analytics:get',
    'analytics:list',
    'messages:get',
    'messages:list',
  ],
  'Organizer editor': [
    'workspaces:get',
    'workspaces:list',
    'files:get',
    'files:create',
    'files:delete',
    'files:update',
    'files:export',
    'files:link',
    'files:list',
    'files:lock',
    'files:unlock',
    'files:share',
    'files:invite',
    'messages:get',
    'messages:create',
    'messages:delete',
    'messages:update',
    'messages:export',
    'messages:link',
    'messages:list',
    'messages:lock',
    'messages:unlock',
    'messages:share',
    'messages:invite',
  ],
  'Organizer reader': [
    'workspaces:get',
    'workspaces:list',
  ],
};

interface Props extends WithStyles<typeof styles> {
  id?: string;
  heading: string;
  nodes: NodeWithTitle[];
  cardsPerRow?: number;
  includeDescendantsCount?: boolean;
  descendantsLabel?: string;
  permissions: string[];
  appPermissions: string[];
  collectionType: CollectionTypes;
  collectionName: string;
}

export class TilesList extends React.Component<Props> {
  public render(): JSX.Element | null {
    const { id = 'tilesList', heading, nodes, classes, cardsPerRow } = this.props;
    const emptyTilesToRender: number = cardsPerRow ? cardsPerRow - nodes.length % cardsPerRow : 0;

    if (!nodes.length) {
      return null;
    }

    return (
      <>
        <Heading id={`${id}_heading`}>{heading}</Heading>
        <div id={`${id}_container`} className={classes.root}>
          {nodes.map((value, index) => this.renderTileItem(value, `${id}_item_${index}`))}
          {cardsPerRow &&
            Array.from(Array(emptyTilesToRender)).map((value, index) => <EmptyTileItem key={`empty-tile-${index}`}/>)
          }
        </div>
      </>
    );
  }

  private renderTileItem = (node: NodeWithTitle, id: string): JSX.Element => {
    let workspacePermissions: string[] = [];
    if ( !this.props.permissions.length && node.permissions ) {
      window?.appStore?.getState?.()?.users?.user?.roles?.forEach?.(role => {
        const roleName = role.name === 'HubSync Admin' ? 'HubSync Admin' : role.workspaceId === node._id ? role.name : '';
        if (roleName) {
          workspacePermissions = [...workspacePermissions, ...rolesPermissions[roleName]];
        }
      });
    }
    const permissions = workspacePermissions.length ? workspacePermissions : this.props.permissions.length === 0 ? node.permissions : this.props.permissions;
    const { includeDescendantsCount, descendantsLabel, collectionType, collectionName, appPermissions } = this.props;

    return (
      <TileItem
        id={id}
        key={node.id}
        node={node}
        includeDescendantsCount={includeDescendantsCount}
        descendantsLabel={descendantsLabel}
        permissions={permissions}
        collectionType={collectionType}
        collectionName={collectionName}
        appPermissions={appPermissions}
      />
    );
  };
}

export default withStyles(styles)(TilesList);
