import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey
  = 'header'
  | 'content'
  | 'field'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginRight: '24px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginBottom: '15px',
  },
};
