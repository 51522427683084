import * as Data from 'components/Fields/data';
import { FieldType } from 'types/response/fieldNode';

export const mapDataFieldTypeIntoDbFieldType = (type: Data.FieldType): FieldType => {
  switch (type) {
    case Data.FieldType.Attachment:
      return FieldType.Attachment;
    case Data.FieldType.LongText:
      return FieldType.Multilinetext;
    case Data.FieldType.Checkbox:
      return FieldType.Boolean;
    case Data.FieldType.MultiSelect:
      return FieldType.Multiplechoice;
    case Data.FieldType.SingleSelect:
      return FieldType.Singlechoice;
    case Data.FieldType.Person:
      return FieldType.Account;
    case Data.FieldType.Date:
      return FieldType.Date;
    case Data.FieldType.Number:
      return FieldType.Integer;
    case Data.FieldType.Currency:
      return FieldType.Currency;
    case Data.FieldType.Percent:
      return FieldType.Percent;
    case Data.FieldType.Rating:
      return FieldType.Rating;
    case Data.FieldType.URL:
      return FieldType.Url;
    case Data.FieldType.Email:
      return FieldType.Email;
    case Data.FieldType.Phone:
      return FieldType.Phone;
    default:
      return FieldType.Singlelineoftext;
  }
};
