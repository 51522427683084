import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

interface OwnProps {
  children: React.ReactNode;
  className?: string;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Content(props: Props): JSX.Element {
  const className = cn({
    [props.classes.wrapper]: true,
    [props.className || '']: props.className,
  });

  return (
    <div className={className}>
      {props.children}
    </div>
  );
}

type ClassKey = 'wrapper';

const styles = withStyles<ClassKey>({
  wrapper: {
    padding: '12px 30px',
    overflowY: 'auto',
  },
});

export default styles(Content);
