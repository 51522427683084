import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import { styles } from './ListSelectPopup.style';

interface ListSelectPopupProps extends WithStyles<typeof styles> {
  id?: string;
  items: any[];
  renderItem: (item: any, i: number) => React.ReactNode;
  matchItem: (query: string, item: any) => boolean;
  onSelect: (item: any) => void;
  mapItemToValue: (item: any) => any;
  includeEmpty?: boolean;
  isItemSelected: (item: any) => boolean;
}

interface ListSelectPopupState {
  query: string;
}

class ListSelectPopup extends React.PureComponent<ListSelectPopupProps, ListSelectPopupState> {
  state = {
    query: '',
  };

  getMatchedItems() {
    const { matchItem, items } = this.props;
    const { query } = this.state;
    return query
      ? items.filter(item => matchItem(query, item))
      : items;
  }

  handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: query } = e.target;
    this.setState({ query });
  };

  handleItemSelect = item => () => {
    const { onSelect, mapItemToValue } = this.props;
    const itemValue = mapItemToValue(item);
    onSelect(itemValue);
  };

  /**
   * Handle root ref set and scroll to selected item on ref set.
   *
   * @param ref - list ref
   */
  onRootRef = (ref) => {
    if (ref) {
      const selected = ref.querySelector('.selected');
      if (selected) {
        selected.scrollIntoView();
      }
    }
  };

  render() {
    const { renderItem, mapItemToValue, isItemSelected, classes } = this.props;
    const matchedItems = this.getMatchedItems();
    return (
      <RootRef rootRef={this.onRootRef}>
        <MenuList className={classes.list}>
          {matchedItems.map((item, i) => {
            const itemValue = mapItemToValue(item);
            const selected = isItemSelected(itemValue);
            return (
              <MenuItem
                id={`${this.props.id}_item_${i}`}
                key={`${itemValue}`}
                onClick={this.handleItemSelect(item)}
                selected={selected}
                className={classes.item}
              >
                {renderItem(item, i)}
              </MenuItem>
            );
          })}
        </MenuList>
      </RootRef>
    );
  }
}

export default withStyles(styles)(ListSelectPopup);
