import styled, { StyledFunction } from 'styled-components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ClassKey as DropdownRendererClassKey, styles as dropdownStyles } from './DropdownRenderer.style';
import { charocalGrey } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

type ClassKey = DropdownRendererClassKey;

export const styles: Record<ClassKey, CSSProperties> = {
  ...dropdownStyles,
};

interface DropdownIconProps {
    up: boolean;
}

const styledSVG: StyledFunction<DropdownIconProps & any> = styled(svgIcons.Dropdown);

export const DropdownIcon = styledSVG`
  color: ${charocalGrey};
  height: 14px;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: -15px;
  order: 2;
  ${(up: boolean) => up ? 'transform: rotate(180deg);' : ''}
`;
