import LoadingIndicator from 'components/LoadingIndicator';
import Route from 'components/Route/Route';
import * as _ from 'lodash/fp';
import WorkspaceBar from 'components/WorkspaceBar/WorkspaceBar';
import {
  getAllUsers,
  getWorkspaceUsers,
} from 'data/accounts/actions';
import { fetchWorkspace } from 'data/workspaces/workspaces.actions';
import LoadingState from 'data/LoadingState';
import { setCurrentWorkspace } from 'data/workspaces/workspaces.actions';
import {
  getAllWorkspacesById,
  getCurrentWorkspaceId,
  getLoading,
  getWorkspacePermissions,
} from 'data/workspaces/workspaces.selectors';
import { getTenantConfig } from 'env';
import ActivityFeedPage from 'pages/ActivityFeedPage';
import AnalyticsPage from 'pages/AnalyticsPage';
import { PageContent, PageWrapper } from 'pages/common/Styled';
import DatabasePage from 'pages/DatabasePage';
import FileManagerPage from 'pages/FileManager';
import ImportDatabase from 'pages/ImportDatabase/ImportDatebase';
import MessagesPage from 'pages/Messages/MessagesPage';
import RepositoryPage from 'pages/RepositoryPage';
import SheetPage from 'pages/SheetPage';
import TaskDBPage from 'pages/TaskDBPage';
import TilesViewPage from 'pages/TilesViewPage';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { State } from 'reducers';
import { WorkspaceNode } from 'types/response/workspaceNode';
import { CollectionTypes } from 'types/schema';
import { stripDomain } from 'utilities/createUrl';
import Collection from '../containers/Collection/Loadable';
import PagePage from './PagePage';
import People from './People/People';
import UserEditor from './People/UserEditor';

import TaxOrganizerPage from './TaxOrganizer';
const tenantConfig = getTenantConfig() as any;

type OwnProps = RouteComponentProps<string[]>;

interface StateProps {
  workspaces: Record<string, WorkspaceNode>;
  loadingState: LoadingState;
  currentWorkspaceId: string;
  permissions: string[];
  appPermissions: string[];
  databaseIds: string[];
}

interface DispatchProps {
  fetchWorkspace: typeof fetchWorkspace.request;
  setCurrentWorkspace: typeof setCurrentWorkspace;
  getAllUsers: () => void;
  getWorkspaceUsers: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class WorkspacePage extends React.Component<Props> {
  state = {
    redirectToLastDatabase: false,
  };

  public componentDidMount(): void {
    this.handleRedirect();

    const { fetchWorkspace } = this.props;

    // @ts-ignore
    fetchWorkspace({ id: this.getWorkspaceIdFromURL() });

    this.props.getAllUsers();

    this.updateCurrentWorkspace();

    if (this.props.location.pathname.endsWith('/databases')) {
      this.setState({ redirectToLastDatabase: true });
      this.loadLatestDatabase();
    }

    this.props.getWorkspaceUsers();
  }

  public shouldComponentUpdate = (prevProps) => {
    if (_.isEqual(prevProps, this.props)) {
      return false;
    }
    return true;
  };

  public componentDidUpdate = (prevProps): void => {
    this.handleRedirect();

    if (Object.keys(prevProps.workspaces).length && prevProps.workspaces[this.props.currentWorkspaceId] && !this.props.workspaces[this.props.currentWorkspaceId]) {
      this.props.history.replace('/workspaces');
    }

    if (prevProps.currentWorkspaceId && this.props.currentWorkspaceId && this.props.fetchWorkspace && this.props.currentWorkspaceId !== prevProps.currentWorkspaceId) {
      const { fetchWorkspace } = this.props;
      // @ts-ignore
      fetchWorkspace({ id: this.props.currentWorkspaceId });
    }

    this.updateCurrentWorkspace();

    if (this.props.match.url !== prevProps.match.url) {
      this.props.getWorkspaceUsers();
    }
  };

  public render(): JSX.Element | null {
    const {
      match: { path, url },
      workspaces,
      loadingState,
    } = this.props;

    const urlWorkspaceId = this.getWorkspaceIdFromURL();
    if (!urlWorkspaceId) {
      return null;
    }

    const workspace = workspaces[urlWorkspaceId];
    const homepageURI = workspace
      ? stripDomain(workspace.homepageURI)
      : `${url}/${CollectionTypes.databases}`;
    const Node = Collection;

    if (loadingState !== LoadingState.Loaded ) return <></>;

    return (
      <PageWrapper>
        <WorkspaceBar
          workspaces={workspaces}
          currentWorkspace={workspace}
          permissions={this.props.permissions}
          appPermissions={this.props.appPermissions}
        />
        <PageContent>
          <Switch>
            {tenantConfig.enabledFeatures.activity && <Route exact path={`${path}/activityfeeds/me`} component={ActivityFeedPage} />}
            {tenantConfig.enabledFeatures.analytics && (!workspace || workspace?.permissions?.includes('analytics:list')) && <Route exact path={`${path}/analytics`} component={AnalyticsPage} />}
            <Route exact path={`${path}/*/attachments/`} component={Collection} />
            {tenantConfig.enabledFeatures.messages && <Route exact path={`${path}/messages`} component={MessagesPage} />}
            {tenantConfig.enabledFeatures.databases && <Route exact path={`${path}/databases/`} component={TilesViewPage} />}
            {tenantConfig.enabledFeatures.people && (!workspace || workspace?.permissions?.includes('people:list')) && (
              <Route path={`/workspaces/:workspaceId/accounts/:accountId`} render={(props) => {
                return (
                  <React.Fragment>
                    <People />
                    {props.match.params.accountId && <UserEditor { ...props } />}
                  </React.Fragment>
                );
              }} />
            )}
            {tenantConfig.enabledFeatures.people && (!workspace || workspace?.permissions?.includes('people:list')) && <Route path={'/workspaces/:workspaceId/accounts'} component={People} />}
            {tenantConfig.enabledFeatures.databases && <Route exact path={`${path}/databases/:databaseId/import`} component={ImportDatabase} />}
            {tenantConfig.enabledFeatures.databases && <Route exact path={`${path}/databases/([0-9A-Za-z]+)`} component={DatabasePage} />}
            {tenantConfig.enabledFeatures.files && <Route exact path={`${path}/*/documents/`} component={Collection} />}
            {tenantConfig.enabledFeatures.files && <Route exact path={`${path}/files`} component={FileManagerPage} />}
            {tenantConfig.enabledFeatures['organizer'] && <Route exact path={`${path}/organizer`} component={TaxOrganizerPage} />}
            {tenantConfig.enabledFeatures['organizer'] && <Route exact path={`${path}/organizer/([0-9A-Za-z].*)`} component={TaxOrganizerPage} />}
            <Route exact path={`${path}/*/history/`} component={Collection} />
            <Route exact path={`${path}/*/history/([0-9A-Za-z]+)`} component={Node} />
            {tenantConfig.enabledFeatures.databases && <Route path={`${path}/*/items/:ticketItemId?`} component={Collection} />}
            {tenantConfig.enabledFeatures.files && <Route exact path={`${path}/repositories/`} component={LoadingIndicator} />}
            {tenantConfig.enabledFeatures.files && <Route exact path={`${path}/repositories/([0-9A-Za-z]+)`} component={RepositoryPage} />}
            {tenantConfig.enabledFeatures.databases && <Route exact path={`${path}/*/sheets/`} component={Collection} />}
            {tenantConfig.enabledFeatures.databases && <Route exact path={`${path}/*/sheets/([0-9A-Za-z]+)`} component={SheetPage} />}
            {tenantConfig.enabledFeatures.tasks && (!workspace || workspace?.permissions?.includes('tasks:list')) && <Route exact path={`${path}/taskdbs/`} component={TilesViewPage} />}
            {tenantConfig.enabledFeatures.tasks && (!workspace || workspace?.permissions?.includes('tasks:list')) && <Route exact path={`${path}/taskdbs/([0-9A-Za-z]+)`} component={TaskDBPage} />}
            {tenantConfig.enabledFeatures.tasks && (!workspace || workspace?.permissions?.includes('tasks:list')) && <Route exact path={`${path}/*/tasks/:ticketItemId?`} component={Collection} />}
            <Route path={`${path}/pages`} component={PagePage} />
            <Redirect to={homepageURI} />
          </Switch>
        </PageContent>
      </PageWrapper>
    );
  }

  private handleRedirect = () => {
    if (this.props.location.pathname === this.props.match.url) {
      this.props.history.replace(`${this.props.location.pathname}/files`);
    }
  };

  private getWorkspaceIdFromURL = (): string | null => this.props.match.params[0] || null;

  private updateCurrentWorkspace = (): void => {
    const { currentWorkspaceId, setCurrentWorkspace } = this.props;
    const urlWorkspaceId = this.getWorkspaceIdFromURL();

    if (currentWorkspaceId !== urlWorkspaceId) {
      setCurrentWorkspace(urlWorkspaceId);
    }
  };

  private loadLatestDatabase = (): void => {
    const pathname = localStorage.getItem(this.props.match.url);
    if (pathname) {
      const hierarchy = pathname.split('/');
      const databaseId = hierarchy[hierarchy.indexOf('databases') + 1];
      if (this.props.databaseIds.includes(databaseId)) {
        this.props.history.replace(pathname);
      }
    }
  };
}

const mapStateToProps = (state: State): StateProps => {
  return {
    workspaces: getAllWorkspacesById(state),
    loadingState: getLoading(state),
    currentWorkspaceId: getCurrentWorkspaceId(state),
    permissions: getWorkspacePermissions(state),
    appPermissions: state.users.user.permissions,
    databaseIds: state.databases.ids,
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchWorkspace: fetchWorkspace.request,
  setCurrentWorkspace,
  getAllUsers,
  getWorkspaceUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePage);
