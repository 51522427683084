import { openURL, cancelEnvelope, resendEnvelope, createWorkspaceMasterDataBase, createEngagementLetterMasterDataBase, createOrganizerMasterDataBase } from './actions';
import { ButtonFieldActions, ButtonRendererFieldProps, ButtonEditorFieldProps } from './types';

// Add here the actions you want to configure for other button types
export const buttonActions: { [key in ButtonFieldActions]?: (data: ButtonRendererFieldProps | ButtonEditorFieldProps) => void } = {
  [ButtonFieldActions.OPEN_URL]: openURL,
  [ButtonFieldActions.CANCEL_ENVELOPE]: cancelEnvelope,
  [ButtonFieldActions.RESEND_ENVELOPE]: resendEnvelope,
  [ButtonFieldActions.CREATE_WORKSPACE]: createWorkspaceMasterDataBase as any,
  [ButtonFieldActions.CREATE_ENGAGEMENT_LETTER]: createEngagementLetterMasterDataBase,
  [ButtonFieldActions.CREATE_TAX_ORGINIZER]: createOrganizerMasterDataBase,
};
