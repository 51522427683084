import * as React from 'react';

import Popover from '@material-ui/core/Popover';
import {
  RichTextEditorComponent,
  Inject,
  Link,
  HtmlEditor,
} from '@syncfusion/ej2-react-richtexteditor';
import cn from 'classnames';
import { getGridRowClassText } from 'components/AgGrid/utils';
import { openLockPermissionsModal } from 'data/modals/actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import { isColumnLocked } from 'utilities/permissions';

import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';

export function MultiLineOfTextRenderer(props: FieldRendererProps): JSX.Element {
  const dispatch = useDispatch();
  const { type, fieldType, permissions, column, node } = props;
  let { value } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'MultiLineofText-Popover' : undefined;
  const canUpdateItems = permissions.includes('items:update');
  const showReadOnlyPopover = !canUpdateItems || (!!column && isColumnLocked(column, permissions));
  const ref = React.useRef(null);

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    return (
      <span
        className={getColumnHeaderClass(fieldType)}
        title={value}
      />
    );
  }

  // TODO: Find root cause of why an object is being passed as a value
  if (value !== null && typeof value === 'object') {
    value = value.toString();
  }


  const handleDbClicked = (event)=>{
    event.stopPropagation();
    if (showReadOnlyPopover) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTextAreaClick = () => {
    if (canUpdateItems) {
      dispatch(openLockPermissionsModal());
    }
  };

  const classRTE = cn('rte-multi-text-grid', getGridRowClassText(node?.rowHeight ?? GRID_ROW_HEIGHT.BASE, 'multi'));

  return (
    <>
      {
        showReadOnlyPopover &&
        <Popover
          id = {id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Outer>
            <Inner>
              <RichTextEditorComponent
                className={`rte-multi-text-grid-popover`}
                id={`txt${id}-renderer-popover`}
                readonly
                ref={ref}
                value={value}
                onClick={handleTextAreaClick}
                toolbarSettings={{
                  enable: false,
                  items: [],
                }}
              >
                <Inject services={[Link, HtmlEditor]} />
              </RichTextEditorComponent>
            </Inner>
          </Outer>
        </Popover>
      }
      <MultiLineOfTextRendererWrapper onDoubleClick={handleDbClicked}>
        <RichTextEditorComponent
          className={classRTE}
          id={`txt${id}-renderer`}
          readonly
          ref={ref}
          value={value}
          toolbarSettings={{
            enable: false,
            items: [],
          }}
        >
          <Inject services={[Link, HtmlEditor]} />
        </RichTextEditorComponent>

        {/* This element prevent click on the RichTextEditor. */}
        <div className='hb-click-barrier'></div>
      </MultiLineOfTextRendererWrapper>
    </>
  );
}

const MultiLineOfTextRendererWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Outer = styled.div`
  width: 232px;
  max-width: 232px;
  height: 165px;
  max-height: 165px;
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 #eae9e9;
  word-break: break-all;
`;

const Inner = styled.div`
  overflow: auto;
  width: 200px;
  max-width: 200px;
  height: 135px;
  max-height: 135px;
`;

export default wrapCellRenderer(MultiLineOfTextRenderer);
