import * as React from 'react';
import VirtualList from './VirtualList';
import ThumbnailPage from 'components/DocumentPreview/ThumbnailPage';
import { THUMBNAILS_WIDTH } from 'components/DocumentPreview/DocumentPreview.style';
import { PreviewNode } from 'types/response/previewNode';

interface Props {
  currentIndex: number;
  previews: PreviewNode[];
  onClick: (index: number) => void;
  onRowsRendered?: (props: { startIndex: number; stopIndex: number }) => void;
}

interface State {
  height: number;
}

export default class ThumbnailsList extends React.PureComponent<Props, State> {
  state = {
    height: 0,
  };

  private scrollTop = 0;
  private listRef: any = React.createRef();

  renderItem = ({ key, style, measure, item, index, parentScroll }) => {
    return (
      <ThumbnailPage
        key={key}
        pageNumber={index + 1}
        src={item.url}
        width={THUMBNAILS_WIDTH}
        height={THUMBNAILS_WIDTH}
        style={style}
        measure={measure}
        parentScroll={parentScroll}
        current={this.props.currentIndex === index}
        onClick={() => this.props.onClick(index)}
      />
    );
  };

  scrollToThumbnail = (index: number) => {
    const { height } = this.state;
    const isLast = index + 1 === this.props.previews.length;

    if (isLast) {
      this.listRef.current.scrollToRow(index);
    } else {
      const elementBottomOffset = this.listRef.current.getOffsetForRow(index + 1);
      const diff = elementBottomOffset - this.scrollTop;
      if (diff > height || diff <= 0) {
        this.listRef.current.scrollToRow(index);
      }
    }
  };

  forceUpdateList = () => {
    this.listRef.current.forceUpdateList();
  };

  onScroll = (props: { scrollTop: number; clientHeight: number }) => {
    this.scrollTop = props.scrollTop;
  };

  handleResize = (props: { height: number }) => {
    this.setState({ height: props.height });
  };

  render() {
    const { previews, onRowsRendered } = this.props;
    return (
      <VirtualList
        {...this.props}
        className="thumbnails-list"
        ref={this.listRef}
        items={previews}
        onScroll={this.onScroll}
        onResize={this.handleResize}
        onRowsRendered={onRowsRendered}
        renderItem={this.renderItem}
      />
    );
  }
}
