import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { nodes } from 'data/collections/collections.selectors';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { openOrganizerRequiresWorkspace } from 'data/modals/actions';
import { State } from 'reducers';

import {
  WORKSPACE_STATUS_COL_ID,
  ORGANIZER_STATUS_COL_ID,
  STATUS_PENDING,
  ORGANIZER_RETURN_TYPE,
  CLIENT_ID_FIELD_ID,
} from '../constants';
import {
  filteringRecords,
  getGridData,
  getRecordsByClientId,
  getColumnClientId,
  parseRecordFields,
  splitRecordsByWorkspaces,
  getIdsForPendingStatus,
  getNextTick,
  formatStatusDate,
} from '../helpers';
import * as services from '../services';
import {
  ProcessPayload,
  CreateOrganizerPayload,
  OrganizerStatus,
} from '../types';
import { updateRecordsInGrid } from './updateRecords';
import { createWorkspaceProcess } from './workspaceCreate';
import { get } from 'lodash';
import { removeHTMLTag } from 'utilities/format';

/**
 * Polling for Organizer data
 */
// tries to get organizer status, if it overpasses this value, stop!
const ATTEMPTS_ALLOW = 30;
// First delay before activate the polling
const INITIAL_DELAY = 5_000;
// Checking time in milliseconds
const DELAY = 10_000;
const updateOrganizerStatus = (rowId, createdAt, dispatch) =>
  updateRecordsInGrid(
    ORGANIZER_STATUS_COL_ID,
    createdAt,
    [rowId],
    dispatch,
  );

export const saveToPolling = createAction<string[]>('SAVE_POLLING_STATUS');

export const updatePolling = createAction<string|number>('UPDATE_POLLING_STATUS');

export const stopPolling = createAsyncThunk(
  'STOP_POLLING_STATUS',
  async (_, { getState }) => {
    const state = getState();
    const timerId = getNextTick(state);
    clearTimeout(timerId);

    return timerId;
  },
);

/**
 * Try many times to get the organizer statuses
 * It's a recursive function
 */
export const pollingBatch = async (getState, dispatch, attempts = 0, clients: Array<{_id: string; clientId: string}> = []) => {
  try {
    const state = getState();
    const wcIds = getIdsForPendingStatus(state);
    let pendingIds = [];
    let hasUpdated = false;
    const RESPONSE_PENDING = STATUS_PENDING.toUpperCase();
    const resOrganizers = await services.getOrganizerStatuses(wcIds);
    if (resOrganizers.status === 200) {
      pendingIds = resOrganizers.body.reduce((acc, item) => {
        const { status, workspaceId, returnId, createdAt } = item;
        const withErrors = status === OrganizerStatus.COMPLETE_ERROR;
        // Update and remove if organizer was completed or finished
        if (
          status === OrganizerStatus.COMPLETE ||
            withErrors || status === RESPONSE_PENDING
        ) {
          // get row IDs by return id
          const clientId = (returnId.split(':')[1] as string).toLowerCase();
          const { columns, records } = getGridData(state);
          const clientIdColId = columns.find(getColumnClientId)?._id ?? '';
          const dbRecords = getRecordsByClientId(clientId, clientIdColId, records);
          const createdDate = formatStatusDate(createdAt);
          const cellValue = status === RESPONSE_PENDING ? STATUS_PENDING : withErrors ? `${createdDate} No CCH Data for PY` : createdDate;
          dbRecords.forEach(record => {
            const fields = record?.fields;
            if ( fields && cellValue !== removeHTMLTag(fields?.[ORGANIZER_STATUS_COL_ID]?.toString()) ) {
              updateOrganizerStatus(record?.id, cellValue, dispatch);
              hasUpdated = true;
            }
            if (status === RESPONSE_PENDING) acc.push(workspaceId);
          });
          // Queue for the next tick
        } else {
          // Keep checking the organizer status for next tick
          acc.push(workspaceId);
        }
        return acc;
      }, []);
    }

    // Next tick
    if (attempts < ATTEMPTS_ALLOW && pendingIds.length) {
      const timerId = setTimeout(
        () => pollingBatch(getState, dispatch, hasUpdated ? 0 : attempts + 1),
        DELAY,
      ) as unknown as string;
      dispatch(saveToPolling(pendingIds));
      dispatch(updatePolling(timerId));
    } else {
      dispatch(stopPolling());
    }

    return null;
  } catch (error) {
    console.error('ERROR Polling status: ', error);
    dispatch(stopPolling());
  }
};

export const createOrganizerProcess = createAsyncThunk<
  undefined,
  ProcessPayload
>(
  `ORGANIZER_CREATE_PROCESS`,
  async ({ ids }, { getState, dispatch }) => {
    try {
      const state = getState() as State;
      const { columns, records } = getGridData(state);
      const filteredRecords = filteringRecords(ids, ORGANIZER_STATUS_COL_ID, records);
      const pendingIDs = filteredRecords.map(({ id }) => id);
      // split records by workspaces(with or without)
      const { withWorkspaces, noWorkspaces } = filteredRecords
        .reduce(splitRecordsByWorkspaces, {
          withWorkspaces: [],
          noWorkspaces: [],
        });
      let organizerPayload: CreateOrganizerPayload[] = [];

      // Set pending columns
      updateRecordsInGrid(ORGANIZER_STATUS_COL_ID, STATUS_PENDING, pendingIDs, dispatch);

      // create new workspaces for those records without workspace
      if (noWorkspaces.length) {
        const recordsIds = noWorkspaces.map(({ id }) => id);
        const { payload } = await dispatch(createWorkspaceProcess({
          ids: recordsIds, notify: false,
        })) as AnyAction;

        if (!payload.map) {
          // delay for remove pending status in workspace organizer when fails
          // there is an error when grid tries to update twice with small time difference
          setTimeout(
            () => updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, '', recordsIds, dispatch),
            3000,
          );
          throw new Error('Workspace Creation Error');
        }

        const workspacePayload = payload.map(({ meta, _id }) => ({
          workspaceId: _id,
          returnType: ORGANIZER_RETURN_TYPE,
          clientId: meta.clientId,
        }));

        // gather data for creating organizer
        organizerPayload = organizerPayload.concat(workspacePayload);
      }

      // Get the workspace data from those records with already workspaces
      if (withWorkspaces.length) {
        const parsedRecords = parseRecordFields(withWorkspaces, columns);
        const clientIds = parsedRecords.map((record) =>
          record?.fields?.['clientId'],
        );
        const clientWorkspace = await services.validClientId([clientIds]);
        const workspaces = clientWorkspace.body.workspaces.map((workspace) => ({
          workspaceId: workspace._id,
          returnType: ORGANIZER_RETURN_TYPE,
          clientId: workspace.meta?.clientId,
        })) as CreateOrganizerPayload;

        // gather data for creating organizer
        organizerPayload = organizerPayload.concat(workspaces);
      }

      // Now it's time for the organizer creation!
      if (organizerPayload.length) {
        const res = await services.createTaxOrganizerByRecord(organizerPayload);
        const newWorkspacesIds = res.body
          .filter(({ error }) => !error)
          .map(({ workspaceId }) => workspaceId);

        const organizersWithErrors = res.body.filter(({ error }) => error);
        if (Array.isArray(organizersWithErrors) && organizersWithErrors.length) {
          const messageError = organizersWithErrors?.[0]?.error?.message;
          const recordIdWithErrors: Array<string> = [];
          organizersWithErrors.forEach( item => {
            const organizer = organizerPayload.find( itemPayload => itemPayload.workspaceId === item.workspaceId);
            if (organizer) {
              const failedRecord = records.find( record => removeHTMLTag(get(record, CLIENT_ID_FIELD_ID)).toLowerCase() === organizer.clientId.toLowerCase());
              if (failedRecord && !recordIdWithErrors.includes(failedRecord.id.toString())) {
                recordIdWithErrors.push(failedRecord.id.toString());
              }
            }
          });
          if (messageError) {
            dispatch(notifications.error({
              title: 'Organizer Create.',
              message: `Organizer creation Failed, (${messageError})`,
            }));
          }
          updateRecordsInGrid(ORGANIZER_STATUS_COL_ID, '', recordIdWithErrors, dispatch);
        }

        const wcIds = [
          ...getIdsForPendingStatus(state),
          ...newWorkspacesIds,
        ];

        await dispatch(stopPolling());
        if (wcIds.length) {
          dispatch(saveToPolling(wcIds));
          setTimeout(() => {
            pollingBatch(getState, dispatch);
          }, INITIAL_DELAY);
        }
      }
    } catch (error) {
      console.error('Organizaer Create Error: ', error);
      dispatch(notifications.error({
        title: 'Organizer Create.',
        message: `Organizer creation Failed.`,
      }));
      updateRecordsInGrid(ORGANIZER_STATUS_COL_ID, '', ids, dispatch);
      return error;
    }
  },
);

/**
 * Check if needs to create workspace(s) and trigger the confirmation modal
 */
export const confirmOrganizerProcess = createAsyncThunk<
null,
ProcessPayload
>(
  `CONFIRM_ORGANIZER_CREATE_PROCESS`,
  async ({ ids }, { getState, dispatch }) => {
    const state = getState() as State;
    const records = nodes(state);
    const filteredRecords = filteringRecords(ids, ORGANIZER_STATUS_COL_ID, records);
    const needWorkspaces = filteredRecords.some((record) =>
      !(record.fields?.[WORKSPACE_STATUS_COL_ID]),
    );

    // create new workspaces for those records without them
    if (needWorkspaces) {
      // trigger here modal for workspace create
      dispatch(openOrganizerRequiresWorkspace(ids));
    } else {
      dispatch(createOrganizerProcess({ ids }));
    }

    return null;
  },
);
