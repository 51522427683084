import { all } from 'redux-saga/effects';

import { collectionsSaga } from 'data/collections/collections.sagas';
import viewsSaga from 'data/views/views.sagas';
import locationChangeSaga from 'data/locationChange.sagas';
import workspacesSaga from 'data/workspaces/workspaces.sagas';
import sheetsSaga from 'data/sheets/sheets.sagas';
import fileUploadSaga from 'data/ui/fileUpload/fileUpload.sagas';
import fileDownloadSaga from 'data/ui/fileDownload/fileDownload.sagas';
import databasesSaga from 'data/databases/databases.saga';
import documentsSaga from 'data/documents/documents.sagas';
import batchJobsSaga from 'data/batchJobs/batchJobs.sagas';
import importFilesSaga from 'data/importFiles/importFiles.saga';
import { watcher as notificationsSaga } from 'data/notifications/notifications';
import { watcher as notificationsUnseenCountSaga } from 'data/notifications/unseen-count';
import uiNotificationsSaga from 'data/ui/notifications/notifications.saga';
import { assureSignSaga } from 'data/assureSign/assureSign.sagas';

import modalsSagas from './data/modals/sagas';
import nodesSagas from './data/nodes/saga';
import filethisSagas from './data/filethis/filethis.saga';
import usersSagas from 'data/users/users.saga';
import { watcher as remindersSaga } from 'data/reminders/reminders';
import { watcher as gridEditorSaga } from 'data/grid-editors/index';

/**
 * @NOTE Migration in progress
 * These sagas will be disabled one by one until remove them all
 */
export default function* rootSaga() {
  yield all([
    collectionsSaga(),
    viewsSaga(),
    locationChangeSaga(),
    workspacesSaga(),
    sheetsSaga(),
    fileUploadSaga(),
    fileDownloadSaga(),
    databasesSaga(),
    documentsSaga(),
    batchJobsSaga(),
    importFilesSaga(),
    notificationsSaga(),
    notificationsUnseenCountSaga(),
    modalsSagas(),
    nodesSagas(),
    filethisSagas(),
    usersSagas(),
    remindersSaga(),
    uiNotificationsSaga(),
    gridEditorSaga(),
    assureSignSaga(),
  ]);
}
