import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as _ from 'lodash/fp';
import * as React from 'react';

import * as Colors from 'styles/colors';

import * as Data from '../../data';
import Select, { Variant as SelectVariant } from '../../../Select';
import Switch from '../../../Switch';
import Typography, { Variant } from '../../../Typography';

interface Props extends WithStyles<ClassKey> {
  data: Data.DateData;
  onDataUpdate(data: Data.DateData): void;
}

function Checkbox(props: Props): JSX.Element {
  const { classes, data } = props;

  function onDateFormatChange(dateFormat: Data.DateFormat): void {
    props.onDataUpdate({ ...data, dateFormat });
  }

  function onChecked(): void {
    props.onDataUpdate({
      ...data,
      timeFormat: (data.timeFormat) ? null : Data.TimeFormat.Twelve,
    });
  }

  function onTimeFormatChange(timeFormat: Data.TimeFormat): void {
    props.onDataUpdate({ ...data, timeFormat });
  }

  const onlyDate = !data.timeFormat;

  // TODO: remove this when time format bugs are fixed
  const hideTimeFormat = true;

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        Enter a date (e.g. 11/12/2013) or pick one from a calendar.
      </Typography>
      <div className={classes.row}>
        <Select
          variant={SelectVariant.Small}
          label="Date format"
          value={data.dateFormat}
          values={Data.dateFormats}
          getLabel={Data.getDateFormatLabel}
          getSelectValue={_.identity}
          onChange={onDateFormatChange}
          fullWidth
        />
      </div>
      {!hideTimeFormat && (
        <div className={classes.row}>
          <Switch
            label="&#9719; Include a time field"
            selected={!onlyDate}
            onClick={onChecked}
            labelRight
          />
        </div>
      )}
      {!onlyDate &&
        <div className={classes.row}>
          <Select
            label="Time format"
            value={data.timeFormat}
            values={Data.timeFormats}
            getLabel={Data.getTimeFormatLabel}
            getSelectValue={_.identity}
            onChange={onTimeFormatChange}
            fullWidth
          />
        </div>
      }
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'row'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  row: { marginTop: 16 },
});

export default styles(Checkbox);
