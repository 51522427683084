import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { rgba } from 'styles/utils/colorUtils';
import * as Colors from 'styles/colors';
import { PaperProps } from '@material-ui/core/Paper';

type ClassKey
  = 'wrapper'
  | 'profileName'
  | 'menuIcon'
  ;

export const userMenuStyles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    height: '50px',
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: rgba(Colors.white, 0.1),
    cursor: 'pointer',
  },
  profileName: { marginLeft: '10px' },
  menuIcon: {
    marginLeft: '10px',
    width: '10px',
  },
};

export const paperPropsStyle: Partial<PaperProps> = { style: { right: 0 } };
