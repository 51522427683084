import * as React from 'react';

import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import svgIcons from 'styles/svgIcons';
import { Column, getId, getName } from 'types/schema';

import Button, { Variant as ButtonVariant } from '../Button';
import Select from '../Select';
import Typography, { Variant as TypographyVariant } from '../Typography';

interface Props extends WithStyles<ClassKey> {
  children: React.ReactNode;
  anchorEl?: HTMLElement;
  selectLabel: string;
  buttonLabel: string;
  selectedField?: Column;
  fields: Column[];
  className?: string;
  showAddFieldBtn? : boolean;
  onNewFieldButtonClick(anchorEl: HTMLElement): void;
  onNewFieldClose(): void;
  onFieldChange(field: Column): void;
}

function NewFieldSelect(props: Props) {
  const { anchorEl, children, className, classes, fields, selectedField, showAddFieldBtn } = props;

  const newClassName = cn(className, classes.wrapper);
  const canSelect = fields.length > 0;

  function onClick(event: React.MouseEvent<HTMLButtonElement>): void {
    props.onNewFieldButtonClick(event.currentTarget);
  }

  return (
    <div className={newClassName}>
      {selectedField &&
        <div className={classes.select}>
          <Select
            disabled={!canSelect}
            label={
              <Typography variant={TypographyVariant.FieldLabel}>
                {props.selectLabel}
              </Typography>
            }
            value={selectedField}
            values={fields}
            getLabel={getName}
            getSelectValue={getId}
            onChange={props.onFieldChange}
          />
        </div>
      }
      {showAddFieldBtn && (
        <div className={classes.button}>
          <Button
            label={props.buttonLabel}
            variant={ButtonVariant.Primary}
            icon={svgIcons.Add}
            onClick={onClick}
          />
          <Popover
            id="newFieldSelect"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={props.onNewFieldClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            {children}
          </Popover>
        </div>
      )}

    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'select'
  | 'button'
  ;

const styles = withStyles<ClassKey>({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  select: { marginBottom: '14px' },
  button: { marginBottom: '1px' },
});

export default styles(NewFieldSelect);

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};
