import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import svgIcons from 'styles/svgIcons';
import { getKey, TreeNode } from 'types/response/http/getNode';

import NavigationNode from './Node';
import Favorite from './Node/Favorite';
import Item from './Node/Item';
import { listStyles } from 'components/NavigationPane/List.style';

const FAVORITES = 'Favorites';
const WORKSPACES = 'Workspaces';

interface Props extends WithStyles<typeof listStyles> {
  navigationTree: TreeNode[];
  permissions: string[];
}

export function List(props: Props): JSX.Element {
  const [favorites, workspaces] = getNodesBySection(props.navigationTree);

  return (
    <ul className={props.classes.list}>
      {favorites &&
        <NavigationNode
          title={FAVORITES}
          nodes={favorites.nodes}
          Icon={svgIcons.StarFilled}
          renderItem={renderFavorite}
        />
      }
      {workspaces &&
        <NavigationNode
          title={WORKSPACES}
          uri={workspaces.uri}
          Icon={svgIcons.Workspace}
          renderItem={renderItem}
        />
      }
    </ul>
  );
}

export default withStyles(listStyles)(List);

type MaybeNode = TreeNode | undefined;
type MaybeNodes = [MaybeNode, MaybeNode];

const emptyNodes: MaybeNodes = [undefined, undefined];

function getNodesBySection(navigationTree: TreeNode[]): MaybeNodes {
  return navigationTree.reduce<MaybeNodes>(([maybeFavorites, maybeWorkspaces], node) => {
    return [
      chooseNode(maybeFavorites, node, FAVORITES),
      chooseNode(maybeWorkspaces, node, WORKSPACES),
    ];
  }, emptyNodes);
}

function chooseNode(maybeNode: MaybeNode, node: TreeNode, title: string): MaybeNode {
  if (maybeNode) {
    return maybeNode;
  }

  if (node.title === title) {
    return node;
  }
}

function renderItem(node: TreeNode, index: number): JSX.Element {
  return <Item key={getKey(node, index)} node={node} />;
}

function renderFavorite(node: TreeNode, index: number): JSX.Element {
  return <Favorite key={getKey(node, index)} node={node} />;
}
