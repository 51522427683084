import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { StaticFields } from 'components/NodeFieldData';
import LoadingState from 'data/LoadingState';
import { Column } from 'types/schema';

import * as FieldData from '../../Fields/data';
import SingleField from '../../Fields/SingleField';
import Switch from '../../Switch';
import ModalActions from '../Modal/Actions';
import ModalContent from '../Modal/Content';
import ModalHeader from '../Modal/Header';
import ModalTextField from '../ModalTextField';
import NewFieldSelect from '../NewFieldSelect';


interface Props extends WithStyles<ClassKey> {
  viewLoadingState: LoadingState;
  viewName: string;
  viewNameErrorMessage?: string;
  titleAnchorEl?: HTMLElement;
  titleFieldName?: string;
  titleFieldNameErrorMessage?: string;
  selectedTitleField?: Column;
  titleFields: Column[];
  titleFieldData: FieldData.FieldData;
  dateAnchorEl?: HTMLElement;
  dateFieldName?: string;
  dateFieldNameErrorMessage?: string;
  selectedDateField?: Column;
  dateFields: Column[];
  dateFieldData: FieldData.FieldData;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  permissions?: string[];
  onPrivateStateChange(isPrivate: boolean): void;
  onLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
  onViewNameChange(viewName: string): void;
  onNewTitleFieldButtonClick(anchorEl: HTMLElement): void;
  onNewTitleFieldClose(): void;
  onTitleFieldChange(field: Column): void;
  onTitleFieldNameChange(newFieldName: string): void;
  onTitleDataUpdate(data: FieldData.FieldData): void;
  onTitleFieldCreated(): void;
  onNewDateFieldButtonClick(anchorEl: HTMLElement): void;
  onNewDateFieldClose(): void;
  onDateFieldChange(field: Column): void;
  onDateFieldNameChange(newFieldName: string): void;
  onDateDataUpdate(data: FieldData.FieldData): void;
  onDateFieldCreated(): void;
  onClose(): void;
  onCreateNewCalendarView(): void;
}

function NewCalendarView(props: Props): JSX.Element {
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { onViewNameChange } = props;
    onViewNameChange(event.currentTarget.value);
  }

  const {
    selectedDateField,
    selectedTitleField,
    viewNameErrorMessage,
    dateFields,
    classes,
    viewName,
    isPrivate,
    isLocked,
    isDefault,
    permissions,
    dateAnchorEl,
    dateFieldData,
    dateFieldName,
    dateFieldNameErrorMessage,
    viewLoadingState,
    onClose,
    onPrivateStateChange,
    onLockStateChange,
    onDefaultStateChange,
    onNewDateFieldButtonClick,
    onDateFieldNameChange,
    onNewDateFieldClose,
    onDateFieldChange,
    onDateDataUpdate,
    onDateFieldCreated,
    onCreateNewCalendarView } = props;

  const canLockView = permissions?.includes('views:lock');
  const canCreateField = permissions?.includes('fields:create');
  const submitDisabled = !!viewNameErrorMessage || !selectedTitleField || !selectedDateField || viewLoadingState === LoadingState.Loading;
  const validFields = dateFields.filter((column: Column) => (![StaticFields.createdDate as string, StaticFields.modifiedDate as string].includes(column.id)));

  return (
    <React.Fragment>
      <ModalHeader onClose={onClose}>Choose a Date Field</ModalHeader>
      <ModalContent>
        <ModalTextField
          value={viewName}
          errorMessage={viewNameErrorMessage}
          onChange={onChange}
          className={classes.viewNameTextField}
          autoSelect
          autoFocus
        />
        <Switch
          label="Personal view"
          selected={isPrivate}
          onClick={() => onPrivateStateChange(!isPrivate)}
          labelRight
          className={'modal-view-switch'}
          disabled={isLocked || isDefault}
        />
        {canLockView && (
          <>
            <Switch
              label="Lock view"
              selected={isLocked}
              onClick={() => onLockStateChange(!isLocked)}
              labelRight
              className={'modal-view-switch'}
              disabled={isPrivate}
            />
            <Switch
              label="Default view"
              selected={isDefault}
              onClick={() => onDefaultStateChange(!isDefault)}
              className={'modal-view-switch'}
              labelRight
              disabled={props.isPrivate}
            />
          </>
        )}
        <NewFieldSelect
          buttonLabel="Create New Date Field"
          selectLabel="USING"
          anchorEl={dateAnchorEl}
          selectedField={selectedDateField}
          fields={validFields}
          onNewFieldButtonClick={onNewDateFieldButtonClick}
          onNewFieldClose={onNewDateFieldClose}
          onFieldChange={onDateFieldChange}
          showAddFieldBtn={canCreateField && canLockView}
        >
          <SingleField
            fieldName={dateFieldName}
            errorMessage={dateFieldNameErrorMessage}
            selectedFieldData={dateFieldData}
            onFieldNameChange={onDateFieldNameChange}
            onDataUpdate={onDateDataUpdate}
            onCancel={onNewDateFieldClose}
            onSave={onDateFieldCreated}
          />
        </NewFieldSelect>
      </ModalContent>
      <ModalActions
        submitLabel="Done"
        submitDisabled={submitDisabled}
        onCancel={onClose}
        onSubmit={onCreateNewCalendarView}
      />
    </React.Fragment>
  );
}

type ClassKey = 'viewNameTextField';

const styles = withStyles<ClassKey>({
  viewNameTextField: { marginBottom: '25px' },
});

export default styles(NewCalendarView);
