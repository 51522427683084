import _ from 'lodash';
import * as actions from 'data/messages/actions';

class MessageEntity {
  created(data) {
    const store = window.appStore;
    const state = store.getState();
    const { user } = state.users;
    if (!user) {
      store.dispatch(actions.setMessageError('Failed to send message'));
      return;
    }

    const message = _.get(data, 'item');
    if (message.senderUserId === user.id) {
      return;
    }

    store.dispatch(actions.appendMessage(message));
  }
}

export default new MessageEntity();
