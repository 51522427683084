import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import * as Colors from 'styles/colors';

import Labelable, { LabelableProps } from '../Labelable';

interface OwnProps {
  id?: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function UnstyledSwitch(props: Props): JSX.Element {
  const { classes, className = '', id, disabled, selected } = props;

  const wrapperClassName = cn({
    [classes.size]: true,
    [classes.wrapper]: true,
    [className]: className,
    [classes.disabledWrapper]: disabled,
  });

  const barClassName = cn({
    [classes.size]: true,
    [classes.bar]: true,
    [classes.selectedBar]: selected,
    [classes.unselectedBar]: !selected,
  });

  const dotClassName = cn({
    [classes.dot]: true,
    [classes.selectedDot]: selected,
    [classes.unselectedDot]: !selected,
  });

  function onClick(): void {
    !props.disabled && props.onClick && props.onClick();
  }

  return (
    <div id={id} className={wrapperClassName} onClick={onClick}>
      <div className={barClassName} />
      <div className={dotClassName} />
    </div>
  );
}

type ClassKey
  = 'bar'
  | 'selectedBar'
  | 'unselectedBar'
  | 'dot'
  | 'selectedDot'
  | 'unselectedDot'
  | 'size'
  | 'wrapper'
  | 'disabledWrapper'
  ;

const styles = withStyles<ClassKey>({
  bar: {
    borderRadius: 4.5,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'backgroundColor 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  selectedBar: {
    opacity: 0.3,
    backgroundColor: Colors.jungleGreen,
  },
  unselectedBar: {
    opacity: 1,
    backgroundColor: Colors.lightBlueGrey,
  },
  dot: {
    height: 5,
    width: 5,
    borderRadius: '50%',
    position: 'absolute',
    top: 2.22,
    transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  selectedDot: {
    left: 10,
    backgroundColor: Colors.jungleGreen,
  },
  unselectedDot: {
    left: 2.22,
    backgroundColor: Colors.white,
  },
  size: {
    height: 10,
    width: 17.78,
  },
  wrapper: {
    position: 'relative',
    cursor: 'pointer',
  },
  disabledWrapper: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});

export const StyledSwitch = styles(UnstyledSwitch);

export default function Switch(props: LabelableProps): JSX.Element {
  return (
    <Labelable
      {...props}
      Component={StyledSwitch}
    />
  );
}
