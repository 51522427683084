import * as React from 'react';

import MuiMenuList from '@material-ui/core/MenuList';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from './ContextMenuBody.style';
import { MenuItem } from 'components/ContextMenu/ContextMenu';

interface OwnProps {
  menuItems: MenuItem[];
  close: () => void;
}

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

const ContextMenuBody: React.SFC<Props> = (props: Props) => {
  const { menuItems, close, classes } = props;

  const handleAction = (item: MenuItem) => (e: React.MouseEvent): void => {
    if (!item.keepMenuOpenAfterClick) {
      close();
    }
    item.action();
  };

  return (
    <MuiMenuList id="menuListMUI">
      {menuItems.map((item, index) => {
        return (
          <MuiMenuItem id={`menuItemMUI_${index}`} key={item.id} onClick={handleAction(item)}>
            <item.Icon className={classes.menuItemIcon}/>
            {item.title}
          </MuiMenuItem>
        );
      })}
    </MuiMenuList>
  );
};

export default withStyles(styles)(ContextMenuBody);
