import { Theme, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { white } from 'styles/colors';
import { TreeNode } from 'types/response/http/getNode';
import { stripDomain } from 'utilities/createUrl';
import { openPdfFileWindow, handleFileUrl } from 'utilities/files';

interface Props extends WithStyles<typeof styles> {
  node: TreeNode;
}

export function Item(props: Props): JSX.Element {
  return (
    <li className={props.classes.box} onClick={props.node.type === 'file' && props.node.title?.endsWith('.pdf') ? openPdfFileWindow : undefined}>
      {props.node.uri
        ? (
          <Link className={props.classes.title} to={props.node.type === 'file' ? handleFileUrl(stripDomain(props.node.uri)) : stripDomain(props.node.uri)}>
            {props.node.title}
          </Link>
        )
        : props.node.title
      }
    </li>
  );
}

type ClassKey
  = 'box'
  | 'title'
  ;

function styles({ tenantTheme = {} }: Theme): StyleRules<ClassKey> {
  const { menuTextColor = white } = tenantTheme;

  return {
    box: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      color: menuTextColor,
    },
    title: {
      color: 'unset',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 13,
      letterSpacing: 0.26,
      lineHeight: '15px',
    },
  };
}

export default withStyles(styles)(Item);
