import { renderToast } from 'components/Toast/NotificationToast';
import { ToastType } from 'components/Toast/types';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { takeEvery } from 'redux-saga/effects';
import { ActionType, getType, isActionOf } from 'typesafe-actions';

import { notifications } from './notifications.actions';


export function* showNotification(action: ActionType<typeof notifications>) {
  const { message } = action.payload;
  let toastHandler: (content: ToastContent, options?: ToastOptions) => void = toast.info;
  let type: ToastType = 'info';

  switch (action.type) {
    case getType(notifications.success):
      toastHandler = toast.success;
      type = 'success';
      break;
    case getType(notifications.error):
      toastHandler = toast.error;
      type = 'error';
      break;
    case getType(notifications.warn):
      toastHandler = toast.warn;
      type = 'warn';
      break;
    case getType(notifications.info):
      toastHandler = toast.info;
      type = 'info';
      break;
    case getType(notifications.custom):
      toastHandler = toast.info;
      type = 'info';
      break;
  }

  toastHandler((props) => renderToast({ message, closeToast: props.closeToast, type }), {
    bodyClassName: 'notification-toastify-body',
    progressClassName: 'notification-toastify-body-progress',
    className: `notification-toastify nt-${type}`,
    hideProgressBar: true,
    closeButton: false,
  });
}

export default function* notificationsSaga() {
  yield takeEvery(
    isActionOf([notifications.success, notifications.error, notifications.warn, notifications.info, notifications.custom]),
    showNotification,
  );
}
