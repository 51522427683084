import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Styles from 'styles';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { TenantNode } from 'types/response/tenantNode';

import Typography, { Variant } from '../../Typography';
import { getIcon, FieldData } from '../data';
import FieldMetadata from './FieldMetadata';
import { getTenantConfig } from 'env';
import { TenantConfig } from 'types/tenantConfig';

interface OwnProps {
  selectedFieldData: FieldData;
  allowMultipleCollaborators?: boolean;
  onToggleSelectFieldType(): void;
  onDataUpdate(data: FieldData): void;
  isCreate?: boolean;
}

interface StateProps {
  tenant?: TenantNode;
}

type Props
  = OwnProps
  & StateProps
  & WithStyles<ClassKey>
  ;

export function SelectedField(props: Props): JSX.Element {
  const { allowMultipleCollaborators, classes, selectedFieldData } = props;

  const Icon = getIcon(selectedFieldData.type);

  const tenantConfig: TenantConfig = getTenantConfig();
  const { enabledFeatures: { columnConversion } } = tenantConfig;


  return (
    <div className={classes.wrapper}>
      {columnConversion || props.isCreate ?
        <div className={classes.dropdown} onClick={props.onToggleSelectFieldType}>
          <Icon className={classes.icon} />
          <Typography variant={Variant.Input} className={classes.label}>
            {selectedFieldData.type}
          </Typography>
          <svgIcons.Dropdown className={classes.icon} />
        </div>
        :<></>
      }
      <FieldMetadata
        selectedFieldData={selectedFieldData}
        allowMultipleCollaborators={allowMultipleCollaborators}
        onDataUpdate={props.onDataUpdate}
        tenant={props.tenant}
      />
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'dropdown'
  | 'label'
  | 'icon'
  ;

const styles = withStyles<ClassKey>({
  wrapper: { marginTop: 5 },
  dropdown: {
    ...Styles.hover(Colors.athensGrey),
    height: 25,
    marginBottom: 4,
    padding: '7px 9px',
    backgroundColor: Colors.athensGrey,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    flex: 1,
    margin: '0 5px',
  },
  icon: {
    width: 11,
    height: 11,
    color: Colors.slateTwo,
  },
});

const stateProps = (state) => ({
  tenant: state.app.tenant,
});

const enhance = compose<Props, OwnProps>(
  styles,
  connect(stateProps),
);

export default enhance(styles(SelectedField));
