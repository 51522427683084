import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { iceBlue } from 'styles/colors';

type ClassKey = 'root' | 'children';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '25px 30px',
    borderBottom: `1px solid ${iceBlue}`,
  },
  children: {
    flexGrow: 1,
  },
};
