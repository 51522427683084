import { last } from 'lodash';

const extractSequenceNumber = columnName => {
  const lastPart = last<string>(columnName.split(' '));

  if (!lastPart) {
    return 0;
  }

  const value = Number.parseInt(lastPart);

  return isNaN(value) ? 0 : value;
};

const getMaxSequenceNumber = (similarNames: string[]): number => {
  const max = Math.max(...similarNames.map(extractSequenceNumber));

  return isNaN(max) || !isFinite(max) ? 0 : max;
};

export const getNextName = (names: string[], name: string) => {
  const regexp = new RegExp(`^${name}( \\d*)?$`, 'gi');
  const similarNames = names.filter(n => n.match(regexp));

  if (similarNames.length === 0) {
    return name;
  }

  const nextSequenceNumber = getMaxSequenceNumber(similarNames) + 1;

  return `${name} ${nextSequenceNumber}`;
};
