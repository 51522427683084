import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey =
  'wrapper'
  | 'textarea'
  | 'actions'
  | 'label'
  | 'actionBtnContainer'
  | 'error'
  | 'checkboxContainer'
  | 'checkbox'
  | 'labelsContainer'
  | 'labelShift'
;

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    position: 'relative',
    padding: '10px 25px',
    width: '100%',
    margin: 'auto',
    backgroundColor: colors.white,
    maxWidth: '100%',
    zIndex: 10,
  },

  textarea: {
    width: '100%',
    height: '50px',
    resize: 'none',
    fontSize: '14px',
    borderRadius: '2px',
    padding: '16px',
    color: colors.black,
    opacity: .38,
    border: `2px solid ${colors.slate}`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '13px',
    opacity: .38,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
  labelShift: {
    fontSize: '12px',
    opacity: .38,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '20px',
  },
  actionBtnContainer: {
    display: 'inline-block',
    marginLeft: '15px',
    marginTop: '9px',
  },
  error: {
    marginTop: '5px',
    fontSize: '12px',
    color: colors.redOrange,
    opacity: 1,
  },
  labelsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '11px',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    color: colors.darkGrey,
  },
};
