import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from 'data/bookmarks/actions';
import {
  getBookmarkByMeta,
  bookmarkBeingSaved,
  getBookmarkById,
} from 'data/bookmarks/bookmarks.selectors';
import { Bookmark } from 'types/schema';
import { Wrapper } from 'components/FavoriteButton/FavoriteButton.style';
import FavoriteIcon from 'components/FavoriteButton/FavoriteIcon';
import { BookmarkMeta } from 'data/bookmarks/types';
import { isEqual } from 'lodash/fp';

interface OwnProps {
  id: string;
  bookmarkMeta?: BookmarkMeta;
  bookmarkId?: string;
  className?: string;
}

interface State {
  bookmarkSaving: boolean;
}

interface StateProps {
  bookmarkBeingSaved: boolean;
  getBookmarkById: (bookmarkURI: string) => Bookmark;
  getBookmarkByMeta: (bookmarkMeta?: BookmarkMeta) => Bookmark;
}

interface DispatchProps {
  saveBookmark: ({ bookmarkMeta: BookmarkMeta }) => void;
  deleteBookmark: ({ bookmarkID: string }) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const selectors = createStructuredSelector({
  bookmarkBeingSaved: bookmarkBeingSaved,
  getBookmarkById: getBookmarkById,
  getBookmarkByMeta: getBookmarkByMeta,
});

const mapDispatchToProps = {
  saveBookmark: actions.saveBookmark,
  deleteBookmark: actions.deleteBookmark,
};

class FavoriteButton extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      bookmarkSaving: false,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { bookmarkBeingSaved } = this.props;
    if (!isEqual(bookmarkBeingSaved, prevProps.bookmarkBeingSaved)) {
      this.setState({ bookmarkSaving: bookmarkBeingSaved });
    }
  }

  saveBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.saveBookmark({
      bookmarkMeta: this.props.bookmarkMeta,
    });
  };

  deleteBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      bookmarkId,
      bookmarkMeta,
      deleteBookmark,
      getBookmarkByMeta,
      getBookmarkById,
    } = this.props;
    const bookmark = bookmarkId
      ? getBookmarkById(bookmarkId)
      : getBookmarkByMeta(bookmarkMeta);

    if (bookmark) {
      deleteBookmark({ bookmarkID: bookmark.id });
    }
  };

  render() {
    const {
      id,
      bookmarkId,
      className,
      getBookmarkById,
      bookmarkMeta,
      getBookmarkByMeta,
    } = this.props;
    const { bookmarkSaving } = this.state;
    const bookmark = bookmarkId
      ? getBookmarkById(bookmarkId)
      : getBookmarkByMeta(bookmarkMeta);
    const action = bookmark ? this.deleteBookmark : this.saveBookmark;

    return (
      <Wrapper
        id={id}
        onClick={bookmarkSaving ? undefined : action}
        className={className}
      >
        <FavoriteIcon isBookmarked={Boolean(bookmark)} />
      </Wrapper>
    );
  }
}

export { FavoriteButton };
export default connect(selectors, mapDispatchToProps)(FavoriteButton);
