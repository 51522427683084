
import { AgGridFilterModel } from 'components/AgGrid/AgGridApi';
import { DEFAULT_DATE_FORMAT } from 'components/Fields/data';
import { accountsById } from 'data/accounts/selectors';
import { AccountNode } from 'types/response/accountNode';
import { attachments } from 'data/attachments/attachments.selectors';
import { AttachmentsState } from 'data/attachments/reducer';
import { nodes, schemaColumnsMap } from 'data/collections/collections.selectors';
import { sortNodes } from 'data/collections/sorts';
import { applyCustomSort } from 'data/collections/customSort';
import { filterNodes } from 'data/collections/filters';
import { customRowOrder, visibleFieldIdsSelector } from 'data/collections/view-config/viewConfig.selectors';
import { QuickFilterState } from 'data/grid-options/quickFilter.reducer';
import * as _ from 'lodash';
import moment from 'moment';
import { State } from 'reducers';
import { createSelector } from 'reselect';
import { CommonNode } from 'types/response';
import { FilterValue, LogicalServerOperator, ServerOperator } from 'types/response/query';

import {
  ColumnsMapById,
  DateFilter,
  FilterModel,
  FilterType,
  Operator,
  RegularFilter,
  SortModelState,
} from '../../types/gridOptions';

export interface SingleFilterParam {
  fieldID: string;
  operation: ServerOperator;
  value?: FilterValue;
  childValues?: FilterValue[];
}

export interface LogicalFilterParam {
  operation: LogicalServerOperator;
  children: FilterParam[];
}

export type FilterParam = SingleFilterParam | LogicalFilterParam;

export const filtersSelector = (state: State): FilterModel => state.gridOptions.filterModel;
export const sortSelector = (state: State): SortModelState => state.gridOptions.sortModel;
export const quickFilters = (state: State): QuickFilterState => state.gridOptions.quickFilters;
export const groupBySelector = (state: State): string[] =>
  _.get(state, 'gridOptions.groupBy.rowGroupCols', []).map(col => col.id);
export const quickSearchSelector = (state): string => filtersSelector(state).quickSearch;

export const nonEmptyFilterFieldsCountSelector = createSelector(
  filtersSelector,
  (filterModel: FilterModel): number => filterModel.regularFilters.length,
);

export const getAllNodes: (state: State) => CommonNode[] = createSelector(
  nodes,
  (nodes: CommonNode[]) => nodes,
);

export const sortedAndFilteredNodes: (state: State) => CommonNode[] = createSelector(
  nodes,
  filtersSelector,
  sortSelector,
  schemaColumnsMap,
  visibleFieldIdsSelector,
  accountsById,
  attachments,
  customRowOrder,
  (
    nodes: CommonNode[],
    filterModel: FilterModel,
    sortModel: SortModelState,
    columns: ColumnsMapById,
    visibleFieldIds: string[],
    accountsById: Record<string, AccountNode> | null,
    attachments: AttachmentsState,
    customRowOrder: Record<string, number>,
  ) => {
    const cloneNodes = _.clone(nodes);
    const emptySorting = sortModel.allIds.length === 0;
    let sortedNodes: CommonNode[];
    if (emptySorting) {
      sortedNodes = applyCustomSort(cloneNodes, customRowOrder);
    } else {
      sortedNodes = sortNodes(cloneNodes, columns, sortModel, accountsById, attachments);
    }
    const filteredNodes = filterNodes(sortedNodes, filterModel, columns, visibleFieldIds, accountsById, attachments);
    return filteredNodes;
  },
);

export const getTotalParamsString = createSelector(
  [visibleFieldIdsSelector, filtersSelector, sortSelector, groupBySelector],
  (visibleColumns, filterModel, sortState, groupBy) => {
    const filterParams = getFilterParamsStrFromModel(filterModel, visibleColumns);
    const sortParams = getSortParamsStrFromSortState(sortState);
    const groupParams = getGroupByParamsStrFromState(groupBy);
    const paramsArray: string[] = [];
    filterParams && paramsArray.push(filterParams);
    sortParams && paramsArray.push(sortParams);
    groupParams && paramsArray.push(groupParams);
    return paramsArray.join('&');
  },
);

function createRegularFilterParam(filter: RegularFilter): SingleFilterParam {
  const commonFilterFields = {
    fieldID: filter.columnId,
    operation: convertClientToServerFilterOperator(filter.operator),
  };

  switch (filter.type) {
    case FilterType.Text:
      return {
        ...commonFilterFields,
        value: filter.filter,
      };

    case FilterType.Number: {
      return {
        ...commonFilterFields,
        value: (typeof filter.filter === 'number') ? filter.filter : undefined,
      };
    }

    case FilterType.Boolean: {
      return {
        ...commonFilterFields,
        operation: commonFilterFields.operation,
        value: filter.filter,
      };
    }

    case FilterType.Singlechoice:
    case FilterType.Multiplechoice:
      return {
        ...commonFilterFields,
        childValues: filter.filter,
      };

    case FilterType.Date:
      return convertDateFilterValue(filter);
  }
}

function convertDateFilterValue(filter: DateFilter): SingleFilterParam {
  const format = filter.dateFormat || DEFAULT_DATE_FORMAT;
  const date = moment(filter.filter || undefined, format, true);

  if (!date.isValid()) {
    return {
      fieldID: filter.columnId,
      value: '',
      operation: convertClientToServerFilterOperator(filter.operator || Operator.Equals),
    };
  }

  switch (filter.operator) {
    // TODO implement not equals logic as soon as we are able to return arrays of filter params
    // case Operator.NotEquals: {
    //   return {
    //     fieldID,
    //     childValues: [
    //       moment(filterValue, DateFormatsValues.US).startOf('day').toDate().getTime(),
    //       moment(filterValue, DateFormatsValues.US).endOf('day').toDate().getTime()
    //     ],
    //     operation: convertFilterOperation(Operator.NotInRange)
    //   };
    // }

    case Operator.Equals: {
      return {
        fieldID: filter.columnId,
        childValues: [
          date.startOf('day').toDate().getTime(),
          date.endOf('day').toDate().getTime(),
        ],
        operation: convertClientToServerFilterOperator(Operator.InRange),
      };
    }

    case Operator.GreaterThan: {
      return {
        fieldID: filter.columnId,
        value: date.endOf('day').toDate().getTime(),
        operation: convertClientToServerFilterOperator(filter.operator),
      };
    }

    case Operator.LessThan: {
      return {
        fieldID: filter.columnId,
        value: date.startOf('day').toDate().getTime(),
        operation: convertClientToServerFilterOperator(filter.operator),
      };
    }

    default: {
      return {
        fieldID: filter.columnId,
        operation: convertClientToServerFilterOperator(filter.operator),
        value: filter.filter || '',
      };
    }
  }
}

const createTextSearchParam = (columnId: string, value: string): SingleFilterParam => ({
  fieldID: columnId,
  operation: ServerOperator.contains,
  value,
});

export const createQuickSearchTextSearchParam = (value: string, shownColumnIds: string[]): FilterParam[] => {
  return value
    .split(/\s/)
    .filter(token => token)
    .map<FilterParam>(token => ({
      operation: ServerOperator.or,
      children: shownColumnIds.map(columnId => createTextSearchParam(columnId, token)),
    }));
};

export const getRegularFilterParamsFromModel = (regularFilters: RegularFilter[]): FilterParam[] =>
  regularFilters
    .map(filter => createRegularFilterParam(filter))
    .filter(filter => filter.value !== undefined || filter.childValues !== undefined);

export const getSearchFilterParamsFromModel = (searchFilters: AgGridFilterModel): FilterParam[] =>
  Object.entries(searchFilters)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => createTextSearchParam(key, value));

export const getQuickSearchFilterParamsFromModel = (quickSearch: string, shownColumnIds: string[]): FilterParam[] =>
  quickSearch && shownColumnIds.length > 0
    ? createQuickSearchTextSearchParam(quickSearch, shownColumnIds)
    : [];

export const getFilterParamsFromModel = (
  filterModel: FilterModel,
  shownColumnIds: string[],
): LogicalFilterParam | null => {
  const allFilters = [
    ...getRegularFilterParamsFromModel(filterModel.regularFilters),
    ...getSearchFilterParamsFromModel(filterModel.searchFilters),
    ...getQuickSearchFilterParamsFromModel(filterModel.quickSearch, shownColumnIds),
  ];

  if (allFilters.length === 0) {
    return null;
  }

  return {
    operation: ServerOperator.and,
    children: allFilters,
  };
};

const getFilterParamsStrFromModel = (filterModel: FilterModel, shownColumnIds: string[]): string => {
  const filterParams = getFilterParamsFromModel(filterModel, shownColumnIds);
  return filterParams ? `filter=${JSON.stringify(filterParams)}` : '';
};

export const getSortParamsFromSortState = (sortState?: SortModelState): string => {
  return sortState
    ? sortState.allIds.map(id => (`${sortState.byId[id].colId}:${sortState.byId[id].sort}`)).join(',')
    : '';
};

const getSortParamsStrFromSortState = (sortState: SortModelState): string => {
  const sortListString = getSortParamsFromSortState(sortState);
  return sortListString ? `orderBy=${sortListString}` : '';
};

const getGroupByParamsStrFromState = (groupByState: string[]): string =>
  groupByState.length ? `groupBy=${groupByState.join(',')}` : '';

export const convertClientToServerFilterOperator = (filter: Operator): ServerOperator => {
  switch (filter) {
    case Operator.Equals:
      return ServerOperator.eq;
    case Operator.NotEquals:
      return ServerOperator.ne;
    case Operator.NotContains:
      return ServerOperator.notcontains;
    case Operator.LessThan:
      return ServerOperator.lt;
    case Operator.LessThanOrEquals:
      return ServerOperator.lte;
    case Operator.GreaterThan:
      return ServerOperator.gt;
    case Operator.GreaterThanOrEquals:
      return ServerOperator.gte;
    case Operator.Empty:
    case Operator.IsExactly:
      return ServerOperator.eq;
    case Operator.NotEmpty:
      return ServerOperator.ne;
    case Operator.StartsWith:
      return ServerOperator.startswith;
    case Operator.EndsWith:
      return ServerOperator.endswith;
    case Operator.InRange:
      return ServerOperator.inrange;
    case Operator.NotInRange:
      return ServerOperator.nin;
    case Operator.Contains:
      return ServerOperator.contains;
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.HasAnyOf:
      return ServerOperator.in;
    case Operator.HasNoneOf:
    case Operator.IsNot:
    case Operator.IsNoneOf:
      return ServerOperator.nin;
    case Operator.HasAllOf:
      return ServerOperator.all;
    default:
      return ServerOperator.eq;
  }
};

export function convertServerToClientOperator(op: ServerOperator, filterType: FilterType, value): Operator {
  switch (op) {
    case ServerOperator.eq: {
      if (filterType === FilterType.Multiplechoice) {
        return Operator.IsExactly;
      }

      return Operator.Equals;
    }

    case ServerOperator.ne: {
      if (filterType === FilterType.Multiplechoice || filterType === FilterType.Singlechoice) {
        return Operator.NotEmpty;
      }

      return Operator.NotEquals;
    }

    case ServerOperator.contains:
      return Operator.Contains;

    case ServerOperator.notcontains:
      return Operator.NotContains;

    case ServerOperator.lt:
      return Operator.LessThan;

    case ServerOperator.lte:
      return Operator.LessThanOrEquals;

    case ServerOperator.gt:
      return Operator.GreaterThan;

    case ServerOperator.gte:
      return Operator.GreaterThanOrEquals;

    case ServerOperator.startswith:
      return Operator.StartsWith;

    case ServerOperator.endswith:
      return Operator.EndsWith;

    case ServerOperator.inrange:
      if (filterType === FilterType.Date) {
        return Operator.Equals;
      }

      return Operator.InRange;

    case ServerOperator.in: {
      if (filterType === FilterType.Multiplechoice) {
        return Operator.HasAnyOf;
      }

      return Operator.IsAnyOf;
    }

    case ServerOperator.nin: {
      if (filterType === FilterType.Multiplechoice) {
        return Operator.HasNoneOf;
      }

      if (Array.isArray(value)) {
        return Operator.IsNoneOf;
      }

      return Operator.IsNot;
    }

    case ServerOperator.all:
      return Operator.HasAllOf;

    default:
      return Operator.Equals;
  }
}
