import { parse, stringify } from 'qs';
import produce from 'immer';
import { forEach, memoize } from 'lodash';
import { chunk, fromPairs, pickBy, identity } from 'lodash/fp';

export const replaceSearchParams = (search: string, newParams: object) => {
  const old = parse(search, { ignoreQueryPrefix: true });
  const _new = produce(old, draft => {
    forEach(newParams, (value, key) => {
      draft[key] = value;
    });
  });

  return stringify(_new, { addQueryPrefix: true });
};

export const parseURL = memoize((url: string) => {
  const pathname = url.split('/').slice(1);

  return pickBy(identity, fromPairs(chunk(2, pathname)));
});

export const getUrlCollectionIds = (url: string) => {
  const pathname = url.split('/').slice(1);
  return fromPairs(chunk(2, pathname));
};

export const getUrlsLastItem = (pathname = ''): string => {
  const splited = pathname.split('/');
  return splited[splited.length - 1];
};

export const stripUrlsLastItem = (pathname = ''): string => {
  const splited = pathname.split('/');
  splited.pop();
  return splited.join('/');
};
