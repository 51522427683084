import { CSSProperties } from '@material-ui/core/styles/withStyles';

// Card height needs to be fixed so that virtualization in cards list works
export const BASE_CARD_HEIGHT = 110;
export const FIELD_HEIGHT = 47;

type ClassKey = 'header' | 'title' | 'field' | 'fieldValue' | 'kanban-card-context-menu';

export const styles: Record<ClassKey, CSSProperties> = {
  'header': {
    display: 'flex',
    marginBottom: '10px',
  },
  'title': {
    'width': '171px',
    'marginRight': '8px',
    'whiteSpace': 'pre-wrap',
    'wordWrap': 'break-word',
    'display': '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    'overflow': 'hidden',
  },
  'field': {
    minHeight: `${FIELD_HEIGHT - 3}px`,
    marginBottom: '9px',
  },
  'fieldValue': {
    '& > *': {
      display: 'block !important',
    },
  },
  'kanban-card-context-menu': {
    marginTop: '-5px',
  },
};
