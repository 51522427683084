import * as React from 'react';

import ModalHeader from 'components/Modals/Modal/Header';
import ModalContent from 'components/Modals/Modal/Content';
import ModalCustomActions from 'components/Modals/Modal/CustomActions';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import { Variant } from 'components/Button';

interface Props {
  title?: string;
  message?: string;
  confirmLabel?: string;
  onClose: () => void;
}

export class Info extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { onClose, title, message, confirmLabel } = this.props;

    return (
      <React.Fragment>
        <ModalHeader onClose={onClose}>{title || 'Info'}</ModalHeader>
        <ModalContent>
          <Typography variant={TypographyVariant.ModalText}>
            {message || ''}
          </Typography>
        </ModalContent>
        <ModalCustomActions
          leftButtons={[]}
          rightButtons={[
            {
              label: confirmLabel || 'OK',
              variant: Variant.Primary,
              onClick: onClose,
            },
          ]}
        />
      </React.Fragment>
    );
  };
}

export default Info;
