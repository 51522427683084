import './BooleanRenderer.style.scss';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';

import BaseRenderer from '../BaseRendererPrototype';
import checkboxChecked from './checkboxChecked.svg';
import checkboxUnchecked from './checkboxUnchecked';

function BooleanRenderer() {
  return true;
}

BooleanRenderer.prototype = Object.create(BaseRenderer.prototype);

BooleanRenderer.prototype.init = function(params) {
  const isSelected = params.value === true || params.value === 'true';
  const isEditable = params.colDef.editable;
  const container = document.createElement('div');
  container.id = `${params.node.id}-${params.column.colId}`;
  container.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  const checkboxWrapper = document.createElement('div');
  checkboxWrapper.className = 'boolean-renderer--wrapper';

  let svg;
  if (isSelected) {
    svg = checkboxChecked();
  } else {
    svg = checkboxUnchecked();
  }

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && ((!params.value && currentQuickSearch === 'false') || (isSelected.toString() === currentQuickSearch))) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  if (container && isEditable && container.getAttribute('listener') !== 'true') {
    container.addEventListener('click', () => {
      if (!params.node.group) {
        params.setValue(!isSelected);
      }
    });
    container.setAttribute('listener', 'true');
  }

  checkboxWrapper.appendChild(svg);
  container.appendChild(checkboxWrapper);
  this.element = container;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

BooleanRenderer.prototype.getGui = function() {
  return this.element;
};

BooleanRenderer.prototype.refresh = function(params) {
  return false;
};

BooleanRenderer.prototype.destroy = function() {
  return true;
};

export default BooleanRenderer;
