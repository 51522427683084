import { Column, GridApi, RowNode } from 'ag-grid-community';
import { CommonNode } from 'types/response';
import { FieldType } from 'types/response/fieldNode';

export interface FieldRendererProps<D = CommonNode> {
  type?: FieldRendererTypes;
  format?: (value: any) => any;
  isGrid?: boolean;
  isGroup?: boolean;
  startEditing: () => any;
  fieldType: FieldType;
  data: D;
  value: any;
  eGridCell: HTMLElement;
  node?: RowNode;
  permissions: string[];
  column?: Column;
  fieldId?: string;
  lock?: boolean;
}

export interface FieldEditorProps<D = CommonNode> {
  id?: string;
  value: any;
  onChange: (value: any, immediateSave?: boolean, tenantData?: any) => void;
  charPress?: string;
  onStopEditing: () => void;
  stopEditing: (cancel?: boolean) => void;
  eGridCell?: HTMLElement;
  keyPress?: number;
  node: RowNode;
  api: GridApi;
  colDef: any;
  default?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  lock?: boolean;
  onBlur?: () => void;
  fieldType?: string;
  permissions?: string[];
  data?: D;
}

export enum FieldRendererTypes {
  normal = 'normal',
  short = 'short',
  createCard = 'createCard'
}

export enum StaticFields {
  createdBy='createdBy',
  createdDate='createdDate',
  modifiedBy='modifiedBy',
  modifiedDate='modifiedDate',
  version='version'
}
