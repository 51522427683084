import { getType, ActionType } from 'typesafe-actions';
import produce from 'immer';

import { DELETE_NODE_SUCCESS } from 'data/collections/constants';
import { Actions } from 'data/collections/collections.actions';

import { commitSelection } from './gridSelection.actions';
import { GridSelectionState } from './gridSelection.types';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';

export const initialState: GridSelectionState = {
  selectedItems: [],
};

const actions = {
  commitSelection,
  deleteNodeSuccess: Actions.deleteNodeSuccess,
};

type Action = ActionType<typeof actions> | LocationChangeAction;

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case getType(commitSelection): {
      return produce(state, draft => {
        // @ts-ignore
        draft.selectedItems = action.payload.items;
      });
    }
    case DELETE_NODE_SUCCESS: {
      return produce(state, draft => {
        draft.selectedItems = [];
      });
    }
    case LOCATION_CHANGE: {
      return produce(state, draft => {
        draft.selectedItems = [];
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
