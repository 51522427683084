import * as React from 'react';
import { useEffect } from 'react';

import keys from 'keycode-js';

import GridTextEditor from '../components/GridTextEditor';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import ModalUrlField from './ModalUrlField/ModalUrlField';

const getValue = (props: FieldEditorProps) =>
  props.value || '';

const UrlEditor = React.forwardRef(
  (props: FieldEditorProps, ref: React.RefObject<HTMLInputElement>): JSX.Element => {
    const { onChange, eGridCell, autoFocus, onStopEditing, onBlur, keyPress=0 } = props;
    if (eGridCell) {
      useEffect(() => {
        if (props.charPress) {
          onChange(props.charPress);
        }

        if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
          onChange('');
        }
      }, []);

      return (
        <GridTextEditor
          id={`txt${props.id}`}
          inputRef={ref}
          value={getValue(props)}
          onChange={e => onChange(e.target.value)}
          onBlur={onStopEditing}
        />
      );
    } else {
      return (
        <ModalUrlField
          id={`txt${props.id}`}
          value={getValue(props)}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          autoSelect
          readOnly={props.readOnly}
          autoFocus={autoFocus}
          lock={props.lock}
        />
      );
    }
  },
);

export default wrapCellEditor()(UrlEditor);
