import * as React from 'react';

import MUIPopover, { PopoverProps } from '@material-ui/core/Popover';

import { ElementOrRenderProp } from './common';

import './styles.scss';

interface PopoverState {
  anchorEl: HTMLElement | null;
}

export interface Props {
  id?: string;
  popoverProps?: Partial<PopoverProps>;
  content: ElementOrRenderProp;
  children: ElementOrRenderProp;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}


class Popover extends React.Component<Props, PopoverState> {
  static defaultProps: Partial<Props> = {
    popoverProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  isOpen = () => {
    if (typeof this.props.isOpen === 'boolean') {
      return this.props.isOpen;
    }

    return !!this.state.anchorEl;
  };

  handleOpen = (e: React.SyntheticEvent<HTMLElement>) => {
    const { currentTarget } = e;
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      anchorEl: currentTarget,
    }), () => {
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { id, children, content } = this.props;
    const { anchorEl } = this.state;

    const isOpen = this.isOpen();
    const handleClose = this.handleClose;

    return (
      <div
        id={`${id}_popoverOption`}
        className={`popover__option ${isOpen ? 'open' : ''}`}
      >
        <div
          id={`${id}_popoverOptionButton`}
          className="popover__option__btn"
          data-qa="toggle-popover"
          onClick={this.handleOpen}
        >
          {typeof children === 'function' ? children({ isOpen, handleClose }) : children}
        </div>
        <MUIPopover
          id={`${id}_popoverMUI`}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          className={'mui-popover'}
          {...this.props.popoverProps}
        >{typeof content === 'function' ? content({ isOpen, handleClose }) : content}
        </MUIPopover>
      </div>
    );
  }
}

export default Popover;
