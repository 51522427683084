import styled from 'styled-components';
import { white } from 'styles/colors';
import { PANEL_SHADOW } from 'styles/constants';

export const Wrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -16px;
  background-color: ${white};
  box-shadow: ${PANEL_SHADOW};
  padding: 0 16px;
  position: relative;
`;

export const LeftWrapper = styled.div`
  align-items: center;
  display: flex;
`;
