import { ActionType, getType } from 'typesafe-actions';
import produce from 'immer';

import { CollectionTypes } from 'types/schema';
import { actions, UPDATE_WORKSPACE_ROLES, UPDATE_WORKSPACE } from 'data/workspaces/workspaces.actions';
import { deleteDatabaseSuccess, updateDatabaseSuccess } from 'data/databases/databases.actions';
import { groupById } from 'utilities/collections';
import { actions as collectionActions } from 'data/collections/collections.actions.new';

import { WorkspaceNode } from 'types/response/workspaceNode';
import LoadingState from 'data/LoadingState';

export interface WorkspacesState {
  current: string | null;
  loadingState: LoadingState;
  loadingStateFavorites: LoadingState;
  loadingDelete: LoadingState;
  byId: {
    [key: string]: WorkspaceNode;
  };
  favorites: {
    [key: string]: WorkspaceNode;
  };
  favoritePages: number;
  pages: number;
  favoriteTotal: number;
  totalNumberOfBookmarks: number;
  total: number;
  ids: string[];
}

export const initialState: WorkspacesState = {
  current: null,
  loadingDelete: LoadingState.Unloaded,
  loadingState: LoadingState.Unloaded,
  loadingStateFavorites: LoadingState.Unloaded,
  totalNumberOfBookmarks: 0,
  byId: {},
  favorites: {},
  pages: 0,
  total: 0,
  favoritePages: 0,
  favoriteTotal: 0,
  ids: [],
};

type WorkspaceActions = ActionType<
  typeof actions
  | typeof updateDatabaseSuccess
  | typeof deleteDatabaseSuccess
  | typeof collectionActions
>;

const reducer = (state: WorkspacesState = initialState, action: WorkspaceActions): WorkspacesState => {
  switch (action.type) {
    case getType(collectionActions.fetchCollectionRequest): {
      return produce(state, draft => {
        if (action.payload.url === `/${CollectionTypes.allWorkspaces}`) {
          draft.loadingState = LoadingState.Loading;
        }
        if (action.payload.url === `/${CollectionTypes.paginatedBookmarks}`) {
          draft.loadingStateFavorites = LoadingState.Loading;
        }
      });
    }
    case getType(collectionActions.fetchCollectionFailure): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.workspaces) {
          draft.loadingState = LoadingState.Error;
        }
        if (action.meta === CollectionTypes.favoriteWorkspaces) {
          draft.loadingStateFavorites = LoadingState.Error;
        }
      });
    }
    case getType(collectionActions.fetchCollectionSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.workspaces) {
          action.payload.nodes && action.payload.nodes.forEach((node: WorkspaceNode) => {
            const taskdbsPermissions = (node.permissions || [])
              .filter((permission: string) => permission.includes('tasks')).map((task: string) => `taskdbs:${task.split(':')[1]}`);
            node.permissions = [...(node.permissions || []), ...taskdbsPermissions];
            const menuItems = node.menuItems.slice();
            const messagesTab = menuItems.find(menu => menu.label === 'Messages');
            if (messagesTab) {
              const filteredMenu = menuItems.filter(menu => menu.label !== 'Messages');
              const databasesTabIndex = filteredMenu.findIndex(menu => menu.label === 'Databases');
              if (databasesTabIndex !== -1) {
                filteredMenu.splice(databasesTabIndex + 1, 0, messagesTab);
                node.menuItems = filteredMenu;
              }
            }
          });

          draft.loadingState = LoadingState.Loaded;
          const payload = action.payload;
          const { byId, ids } = groupById(payload.nodes);
          draft.byId = byId;
          draft.ids = ids;
          draft.total = action.payload.totalCount;
          draft.totalNumberOfBookmarks = action.payload.totalNumberOfBookmarks || 0;
          draft.pages = action.payload.start || 0;
        }

        if (action.meta === CollectionTypes.favoriteWorkspaces) {
          action.payload.nodes && action.payload.nodes.forEach((node: WorkspaceNode) => {
            const taskdbsPermissions = (node.permissions || [])
              .filter((permission: string) => permission.includes('tasks')).map((task: string) => `taskdbs:${task.split(':')[1]}`);
            node.permissions = [...(node.permissions || []), ...taskdbsPermissions];
            const menuItems = node.menuItems.slice();
            const messagesTab = menuItems.find(menu => menu.label === 'Messages');
            if (messagesTab) {
              const filteredMenu = menuItems.filter(menu => menu.label !== 'Messages');
              const databasesTabIndex = filteredMenu.findIndex(menu => menu.label === 'Databases');
              if (databasesTabIndex !== -1) {
                filteredMenu.splice(databasesTabIndex + 1, 0, messagesTab);
                node.menuItems = filteredMenu;
              }
            }
          });

          draft.loadingStateFavorites = LoadingState.Loaded;
          const payload = action.payload;
          const { byId } = groupById(payload.nodes);
          draft.favorites = byId;
          draft.favoriteTotal = action.payload.totalCount;
          draft.favoritePages = action.payload.start || 0;
        }
      });
    }
    case getType(actions.fetchWorkspace.request): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    case getType(actions.fetchWorkspace.success): {
      return produce(state, draft => {
        const { _id: id } = action.payload;
        if (id) {
          draft.current = id;
        }
        // @ts-ignore
        draft.byId[id] = action.payload;
        draft.loadingState = LoadingState.Loaded;
      });
    }
    case getType(actions.deleteWorkspace.request): {
      return produce(state, draft => {
        draft.loadingDelete = LoadingState.Loading;
      });
    }
    case getType(actions.deleteWorkspace.success): {
      return produce(state, draft => {
        draft.loadingDelete = LoadingState.Loaded;
      });
    }
    case getType(actions.setCurrentWorkspace): {
      return produce(state, draft => {
        draft.current = action.payload;
      });
    }
    case getType(updateDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.workspaces) {
          const { id } = action.payload;
          const workspace = action.payload as unknown as WorkspaceNode;
          workspace.menuItems = state.byId[id].menuItems;
          // @ts-ignore
          draft.byId[id] = workspace;
        }
      });
    }
    case getType(deleteDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.workspaces) {
          delete draft.byId[action.payload.id];
          draft.ids.splice(draft.ids.findIndex(id => id === action.payload.id), 1);
        }
      });
    }
    case getType(actions.archiveWorkspace.success): {
      const { id } = action.payload;
      if (state.current && id === state.current) {
        return produce(state, draft => {
          draft.current = id;
        });
      }
      break;
    }
    case getType(actions.unarchiveWorkspace.success): {
      const { id } = action.payload;
      if (state.current && id === state.current) {
        return produce(state, draft => {
          draft.current = id;
        });
      }
      break;
    }
    case getType(collectionActions.copyCollectionSuccess):
    case getType(collectionActions.createCollectionItemSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.workspaces) {
          // @ts-ignore
          draft.byId[action.payload.id] = action.payload as WorkspaceNode;
          // @ts-ignore
          draft.ids.push(action.payload.id);
        }
      });
    }
    case UPDATE_WORKSPACE_ROLES: {
      const byId = { ...state.byId };
      if (byId[action.workspaceId]) {
        const existingWorkspace = { ...state.byId[action.workspaceId] } as WorkspaceNode;
        existingWorkspace.roles = action.roles;
        byId[action.workspaceId] = existingWorkspace;
      }
      return {
        ...state,
        byId,
      };
    }
    case UPDATE_WORKSPACE: {
      const byId = { ...state.byId };
      if (byId[action.workspaceId]) {
        byId[action.workspaceId] = action.workspace as WorkspaceNode;
      }
      return {
        ...state,
        byId,
      };
    }
    default: {
      return state;
    }
  }

  return state;
};

export default reducer;
