import React, { useCallback, useEffect, useState } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import IconButton, {
  Color as IconButtonColor,
  Size,
} from 'components/IconButton';
import LoadingIndicator from 'components/LoadingIndicator';
import { Avatar, AvatarSize } from 'components/Modals/Account/Avatar/Avatar';
import Modal from 'components/Modals/Modal';
import Content from 'components/Modals/Modal/Content';
import { Role } from 'data/accounts/types';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { User } from 'data/users/users.types';
import { getApiV2Url } from 'env';
import { find, xorBy, uniqBy } from 'lodash';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import svgIcons from 'styles/svgIcons';
import getAuthHeaders from 'utilities/getAuthHeaders';
import { deleteRequest, getRequest, postRequest } from 'utilities/httpRequests';

import { AssignRoles, ConfirmDialog } from '.';
import Button, { Variant as ButtonVariant } from '../../../components/Button';
import Typography, { Variant } from '../../../components/Typography';
import { IAssigns } from './AssignRoles';
import { BoxItem } from './RoleBox';

const TENANT_ADMIN_ROLE = 'HubSync Admin';

export const CONSTANTS = {
  labelCancel: 'Cancel',
  removeConfirmTitle: 'Are you sure you want to remove this user?',
  removeTextContent:
    'User will be deleted from the organization and all workspaces.',
  removeLabelAccept: 'Remove user',
  removeUsersConfirmTitle: 'Are you sure you want to remove these users?',
  removeUsersTextContent:
    'Users will be deleted from the organization and all workspaces.',
  removeUsersLabelAccept: 'Remove users',
  upgradeConfirmTitle: 'Are you sure you want to upgrade this user?',
  upgradeTextContent:
    'As a tenant admin this user will be able to add, edit and remove users and workspaces.',
  upgradeLabelAccept: 'Upgrade user',
  downgradeConfirmTitle:
    'Are you sure you want to downgrade this Tenant Admin?',
  downgradeTextContent:
    'We will keep this user\'s access to all workspaces where has other roles like (Administrator, Editor, Reader)',
  downgradeLabelAccept: 'Downgrade Tenant Admin',
};

function canUpgradeDowngrade(isTenantAdmin: boolean, assigns?: IAssigns) {
  return isTenantAdmin || (!isTenantAdmin && !!assigns);
}

function createDowngradeUser(
  getApiUrlHelper: typeof getApiV2Url,
  postRequestHelper: typeof postRequest,
  getHeadersHelper: typeof getAuthHeaders,
  state: RootStateOrAny,
  user: User,
  roleName: string,
  onDone: (newRoles: Role[], downgrade: boolean) => void,
  onError: (error: Error) => void,
) {
  return () => {
    const url = `${getApiUrlHelper()}/users/downgrade`;
    const headers = getHeadersHelper();

    postRequestHelper(
      { url, data: { userId: user.id, roleName } },
      state,
      headers,
    )
      .then((resp) => {
        const { body } = resp;
        if (onDone instanceof Function) onDone(body.data, true);
      })
      .catch((err) => {
        if (onError instanceof Function) onError(err);
      });
  };
}

export function createUpgradeUser(
  getApiUrlHelper: typeof getApiV2Url,
  postRequestHelper: typeof postRequest,
  getHeadersHelper: typeof getAuthHeaders,
  state: RootStateOrAny,
  user: User,
  onDone: (newRoles: Role[]) => void,
  onError: (error: Error) => void,
) {
  return () => {
    const url = `${getApiUrlHelper()}/users/upgrade`;
    const headers = getHeadersHelper();

    postRequestHelper({ url, data: { userId: user.id } }, state, headers)
      .then((resp) => {
        const { body } = resp;
        if (onDone instanceof Function) onDone(body.data);
      })
      .catch((err) => {
        if (onError instanceof Function) onError(err);
      });
  };
}

export function isTenantAdmin(roles?: Role[]) {
  return !!find(roles, (role: Role) => role.name === TENANT_ADMIN_ROLE);
}

export function getTenantUserType(user: User) {
  const roles = user.roles;
  if (!roles) return 'Unknown';

  return isTenantAdmin(roles) ? 'Tenant Administrator' : 'Organization User';
}

export interface TenantUserEditorProps extends WithStyles<typeof styles> {
  userModel: User;
  onClose?: () => void;
  onDone: (newRoles: Role[]) => void;
  onRemoveUser?: () => void;
  onError: (error: Error) => void;
}

interface Office {
  id: string;
  text: string;
}

function TenantUserEditor({
  userModel,
  onClose,
  onDone,
  onError,
  onRemoveUser,
  classes,
}: TenantUserEditorProps) {
  const dispatch = useDispatch();
  const [user] = useState(userModel);
  const [loading, setLoading] = useState(true);

  const state = useSelector((state) => state);
  const [noAccessItems, setNoAccessItems] = useState<BoxItem[]>([]);
  const [readerItems, setReaderItems] = useState<BoxItem[]>([]);
  const [editorItems, setEditorItems] = useState<BoxItem[]>([]);
  const [adminItems, setAdminItems] = useState<BoxItem[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [assigns, setAssigns] = useState<IAssigns>();
  const [downgradeUserConfirmation, setDowngradeUserConfirmation] = useState(
    false,
  );
  const [upgradeUserConfirmation, setUpgradeUserConfirmation] = useState(false);
  const [removeUserConfirmation, setRemoveUserConfirmation] = useState(false);

  const downgradeUser = useCallback(
    (selectedRole: string) =>
      createDowngradeUser(
        getApiV2Url,
        postRequest,
        getAuthHeaders,
        state,
        user,
        selectedRole,
        onDone,
        onError,
      ),
    [user],
  );
  const upgradeUser = useCallback(
    createUpgradeUser(
      getApiV2Url,
      postRequest,
      getAuthHeaders,
      state,
      user,
      onDone,
      onError,
    ),
    [user],
  );

  useEffect(() => {
    let isUnmounted = false;
    getRequest(`${getApiV2Url()}/workspaces?api_version=2`, state).then(
      (response) => {
        if (isUnmounted) return;
        const { body } = response;

        const officesList: Office[] = [{ id: '', text: '' }];
        const allWorkspaces = body.map(({ _id, title, meta }) => {
          if (meta?.office) {
            const text = meta.office;
            const id = text.toLowerCase();
            if (!officesList.some(item => item.id === id)) {
              officesList.push({ id, text });
            }
          }
          return {
            id: _id,
            title: meta?.clientName?.replace?.('&amp;', '&') || title,
            meta,
          };
        });
        const [reader, editor, admin] = getItemsByRole(user, allWorkspaces);
        setReaderItems(reader);
        setEditorItems(editor);
        setAdminItems(admin);

        const noAccess = xorBy(
          allWorkspaces,
          [...reader, ...editor, ...admin],
          'id',
        );
        setOffices(officesList);
        setNoAccessItems(noAccess);
        setLoading(false);
      },
    );

    return () => {
      isUnmounted = true;
    };
  }, []);

  const handleUpgradeDowngrade = () => {
    setLoading(true);
    if (isTenantAdmin(user.roles)) {
      setDowngradeUserConfirmation(true);
    } else {
      setUpgradeUserConfirmation(true);
    }
  };

  const handleRemoveUser = () => {
    setRemoveUserConfirmation(true);
  };

  const handleSaveUserPermissions = () => {
    const url = `${getApiV2Url()}/users/update-permissions`;
    const headers = getAuthHeaders();

    const targetRoles = assigns || {};
    const workspaces = Object.keys(assigns || {}).reduce(
      (accumulator, role) => {
        const roleWorkspaces = targetRoles[role].map(({ id }) => ({
          id,
          role,
        }));
        return [...accumulator, ...roleWorkspaces];
      },
      [],
    );
    const data = { userId: user.id, workspaces };

    postRequest({ url, data }, state, headers).then(({ body }) => {
      if (onDone instanceof Function) onDone(body.data);
    });
  };

  const handleOnClose = () => {
    if (onClose instanceof Function) onClose();
  };

  const handleAssignChange = (assign: IAssigns) => {
    setAssigns(assign);
  };

  const handleOnDoneDowngrade = (selectedRole: string) => {
    downgradeUser(selectedRole)();
    handleOnClose();
  };

  const handleOnDowngradeUserConfirmationClose = () => {
    setDowngradeUserConfirmation(false);
    setLoading(false);
  };

  const handleOnUpgradeCancel = () => {
    setUpgradeUserConfirmation(false);
    setLoading(false);
  };

  const handleOnUpgradeDone = () => {
    setUpgradeUserConfirmation(false);
    setLoading(false);
    upgradeUser();
    handleOnClose();
  };

  const handleOnRemoveDone = () => {
    setRemoveUserConfirmation(false);
    setLoading(false);
    removeUser();
    handleOnClose();
  };

  const handleOnRemoveCancel = () => {
    setRemoveUserConfirmation(false);
    setLoading(false);
  };

  const removeUser = () => {
    const url = `${getApiV2Url()}/users/delete-permissions/${userModel.id}`;
    const headers = getAuthHeaders();
    deleteRequest(url, state, headers)
      .then((resp) => {
        handleOnClose();
        if (onRemoveUser instanceof Function) onRemoveUser();
        dispatch(
          notifications.success({
            title: 'Invitation Sent!',
            message: `User(s) removed successfully.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          notifications.error({
            title: 'Error!',
            message: `The user ${userModel.email} was not removed. Please try again later!`,
          }),
        );
      });
  };

  return (
    <>
      {removeUserConfirmation && (
        <ConfirmDialog
          onDone={handleOnRemoveDone}
          onClose={handleOnRemoveCancel}
          title={CONSTANTS.removeConfirmTitle}
          labelAccept={CONSTANTS.removeLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.removeTextContent}
          showRoleSelection={false}
        />
      )}
      {upgradeUserConfirmation && (
        <ConfirmDialog
          onDone={handleOnUpgradeDone}
          onClose={handleOnUpgradeCancel}
          title={CONSTANTS.upgradeConfirmTitle}
          labelAccept={CONSTANTS.upgradeLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.upgradeTextContent}
          showRoleSelection={false}
        />
      )}
      {downgradeUserConfirmation && (
        <ConfirmDialog
          onDone={handleOnDoneDowngrade}
          onClose={handleOnDowngradeUserConfirmationClose}
          title={CONSTANTS.downgradeConfirmTitle}
          labelAccept={CONSTANTS.downgradeLabelAccept}
          labelCancel={CONSTANTS.labelCancel}
          textContent={CONSTANTS.downgradeTextContent}
          showRoleSelection
        />
      )}
      <Modal onClose={handleOnClose}>
        <div className={'modal-header'}>
          <div className={'modal-header-card'}>
            <Avatar user={user} size={AvatarSize.Large} />
            <div className={'modal-header-card-text'}>
              <span className="modal-header-card-title">
                {user.status === 'pending'
                  ? user.email.split('@')[0]
                  : user.displayName}
              </span>
              <span className="modal-header-card-role">{`${getTenantUserType(
                user,
              )}${user?.status !== 'active' ? ' (pending)' : ''}`}</span>
              <span className="modal-header-card-email">{user?.email}</span>
            </div>
          </div>

          <IconButton
            id="btnIconClose"
            icon={svgIcons.Close}
            color={IconButtonColor.BlueyGrey}
            size={Size.Medium}
            onClick={handleOnClose}
          />
        </div>
        <Content>
          {loading && !isTenantAdmin(user.roles) && <LoadingIndicator />}
          {!loading && !isTenantAdmin(user.roles) && (
            <div className={classes.workspacesContainer}>
              <AssignRoles
                title="User workspaces"
                offices={offices}
                noAccessBoxItems={noAccessItems}
                readerBoxItems={readerItems}
                editorBoxItems={editorItems}
                adminBoxItems={adminItems}
                onAssignChange={handleAssignChange}
              />
            </div>
          )}
          {isTenantAdmin(user.roles) && (
            <div className={classes.workspacesContainer}>
              <Typography variant={Variant.SubHeading}>
                User Workspaces
              </Typography>
              <Typography variant={Variant.ModalText}>
                Tenant Administrator has access to all Workspaces in
                Organization
              </Typography>
            </div>
          )}
          <div className={classes.actionButtonsRow}>
            <Button
              id="btn-remove"
              disabled={false}
              label="Remove user"
              variant={ButtonVariant.SecondaryLink}
              onClick={handleRemoveUser}
              className="remove-user-modal-btn"
            />
            <div className={classes.buttonContainer}>
              <Button
                id="btn-upgrade-downgrade"
                label={
                  isTenantAdmin(user.roles)
                    ? 'Downgrade User'
                    : 'Upgrade to Tenant Admin'
                }
                variant={ButtonVariant.PrimaryLink}
                disabled={
                  !canUpgradeDowngrade(isTenantAdmin(user.roles), assigns)
                }
                onClick={handleUpgradeDowngrade}
              />
              {!isTenantAdmin(user.roles) && (
                <Button
                  id="btn-update"
                  label="Update"
                  variant={ButtonVariant.Primary}
                  disabled={!assigns}
                  onClick={handleSaveUserPermissions}
                />
              )}
            </div>
          </div>
        </Content>
      </Modal>
    </>
  );
}

// TODO: create the right type for a real role
type Workspace = {
  _id?: string;
  id?: string;
  title: string;
  meta?: {
    clientName: string;
  };
  roles: { id: string; name: string }[];
};

function getItemsByRole(user: User, allWorkspaces: Workspace[]): BoxItem[][] {
  const readWorkspaces: BoxItem[] = [];
  const editWorkspaces: BoxItem[] = [];
  const adminWorkspaces: BoxItem[] = [];
  const usersRoles = uniqBy(user.roles, 'id');

  const workspacesById = allWorkspaces.reduce((acc, item)=> {
    if (item.id) {
      acc[item.id] = item;
    }
    return acc;
  }, {});

  usersRoles.forEach(({ name, workspaceId }) => {
    const workspace = workspacesById[workspaceId];
    if (!workspace) return;
    const { id, title } = workspace;
    const boxItem = { id, title };

    if (name === 'Reader') readWorkspaces.push(boxItem);
    if (name === 'Editor') editWorkspaces.push(boxItem);
    if (name === 'Administrator') adminWorkspaces.push(boxItem);
  });

  return [readWorkspaces, editWorkspaces, adminWorkspaces];
}

const styles: Record<
  'buttonContainer' | 'workspacesContainer' | 'actionButtonsRow',
  CSSProperties
> = {
  buttonContainer: {
    display: 'flex',
    columnGap: '1rem',
  },
  workspacesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  actionButtonsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
};

export default withStyles(styles)(TenantUserEditor);
