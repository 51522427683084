const store = window.appStore;
export const StoreSubscriber = (function() {
  let instance = null;
  const callbacks = [];
  return {
    init: function() {
      if (!instance) {
        if (store) {
          instance = store.subscribe(() => {
            callbacks.forEach(callback => callback());
          });
        }
      }
      return instance;
    },
    destroy: function() {
      if (instance) {
        instance();
        instance = null;
      }
    },
    subscribe: (cb) => {
      callbacks.push(cb);
    },
  };
})();
