import { connect } from 'react-redux';

import { Actions as CollectionActions } from 'data/collections/collections.actions';
import { UpdateFieldPayload } from 'data/collections/collections.reducer';
import * as ModalActions from 'data/modals/actions';
import { Column } from 'types/schema';

import DeleteKanbanColumn from './render';

interface OwnProps {
  categoryField: Column;
  choiceId: string;
}

interface DispatchProps {
  onClose(): void;
  onUpdateField(payload: UpdateFieldPayload): void;
}

const mapDispatchToProps: DispatchProps = {
  onClose: ModalActions.closeModal,
  onUpdateField: CollectionActions.updateField,
};

export default connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteKanbanColumn);
