import produce from 'immer';
import { isNil, Dictionary } from 'lodash';
import * as _ from 'lodash/fp';
import { CollectionTypes } from 'types/schema';
import { AttachmentNode } from 'types/response/attachmentNode';

import * as actions from './actions';
import { PreviewAttachmentsPayload, FileModel } from './types';

export interface AttachmentsState {
  byItemId: Dictionary<ItemAttachmentsState>;
  previewFiles?: PreviewAttachmentsPayload;
  allowRequest?: boolean;
  isLoading?: boolean;
  status?: string;
  rowId?: string | null;
}

export interface ItemAttachmentsState {
  byId: Dictionary<AttachmentNode>;
  ids: string[];
}

export const initialState: AttachmentsState = {
  byItemId: {},
  previewFiles: undefined,
  allowRequest: true,
  isLoading: true,
  status: '',
  rowId: null,
};

export default function reducer(state = initialState, action): AttachmentsState {
  switch (action.type) {
    case actions.getAttachments.pending.type: {
      return {
        ...state,
        allowRequest: false,
        isLoading: true,
      };
    }
    case actions.getAttachments.rejected.type: {
      return {
        ...state,
        allowRequest: true,
        isLoading: false,
      };
    }
    case actions.getAttachments.fulfilled.type: {
      return produce(state, draft => {
        const byItemId = {};
        const nodes = action.payload.response;
        if (nodes) {
          // @ts-ignore
          nodes.forEach(document => {
            const byId = {};
            const ids = [];
            if (!byItemId[document['references']['itemID']]) {
              byItemId[document['references']['itemID']] = { byId, ids };
            }
            byItemId[document['references']['itemID']]['byId'][document._id] = document;
            byItemId[document['references']['itemID']]['ids'].push(document._id);
          });
        }
        draft.byItemId = byItemId;
        draft.isLoading = false;
      });
    }

    case actions.changeSortAttachments.pending.type: {
      return produce(state, draft => {
        const { sortItems, itemID } = action.meta.arg;

        sortItems.forEach((attach) => {
          const { id } = attach;
          draft.byItemId[itemID].byId[id].sort = attach.attachment?.sort;
        });
      });
    }

    case actions.indexAttachment.type: {
      return produce(state, draft => {
        const fileItem = action['payload'] as FileModel;
        const itemID = _.get('references.itemID', fileItem);
        if (itemID) {
          if (isNil(draft.byItemId[itemID])) {
            draft.byItemId[itemID] = { byId: {}, ids: [] };
          }
          draft.byItemId[itemID].byId[fileItem._id] = fileItem;
          draft.byItemId[itemID].ids.push(fileItem._id);
        }
      });
    }

    case actions.getAttachment.fulfilled.type: {
      return produce(state, draft => {
        const node = action.payload;
        const itemId = node['itemID'];
        if (itemId) {
          if (isNil(draft.byItemId[itemId])) {
            draft.byItemId[itemId] = { byId: {}, ids: [] };
          }
          draft.byItemId[itemId].byId[node.id] = node;
          draft.byItemId[itemId].ids.push(node.id);
        }
      });
    }

    case actions.updateAttachment.fulfilled.type: {
      const { payload } = action;

      return produce(state, draft => {
        const attachments = draft.byItemId[payload.references?.itemID || ''];
        if (!isNil(attachments)) {
          attachments.byId[payload._id ?? ''] = payload;
        }
      });
    }

    case actions.deleteAttachment.fulfilled.type: {
      return produce(state, draft => {
        const { uri, id } = action.meta.arg;
        const parsedUri = uri.split(/\/|\?/);
        const itemIdx = parsedUri.findIndex(item => item === CollectionTypes.items);
        const itemId = parsedUri[itemIdx + 1];
        if (itemId) {
          if (!isNil(draft.byItemId[itemId])) {
            delete draft.byItemId[itemId].byId[id];
            const attachmentIdIndex = draft.byItemId[itemId].ids.findIndex(attachId => attachId === id);
            draft.byItemId[itemId].ids.splice(attachmentIdIndex, 1);
          }
          draft.status = 'delete_success';
          draft.rowId = itemId;
        }
      });
    }

    case actions.setAttachmentStatus.type: {
      return produce(state, draft => {
        draft.status = action.payload.status;
        draft.rowId = action.payload.rowId;
      });
    }

    case actions.openPreviewAttachments.type: {
      return {
        ...state,
        previewFiles: { ...action.payload },
      };
    }

    case actions.closePreviewAttachments.type: {
      return produce(state, draft => {
        draft.previewFiles = undefined;
      });
    }

    default: {
      return state;
    }
  }
}
