import * as React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererReactComp } from 'ag-grid-react/lib/interfaces';

const wrapCellRenderer = Renderer =>
  class FieldRenderer extends React.PureComponent<ICellRendererParams> implements ICellRendererReactComp {
    static OriginalComponent = Renderer;
    refresh() {
      return false;
    }
    componentDidMount() {
      this.checkValidity();
    }
    componentDidUpdate() {
      this.checkValidity();
    }
    startEditing = () => {
      const { rowIndex, api, column } = this.props;
      api.startEditingCell({
        rowIndex,
        colKey: column.getColId(),
      });
    };
    checkValidity() {
      const { eGridCell, node, colDef } = this.props;

      if (!node || !node.data) return;

      const { isValid, validationMessages } = node.data;

      if (isValid === false) {
        const fieldName = colDef.field;
        if (validationMessages && fieldName && validationMessages[fieldName] && validationMessages[fieldName].length > 0) {
          eGridCell.title = validationMessages[fieldName].join('\r\n');
          eGridCell.classList.add('invalid');
        } else {
          eGridCell.classList.remove('invalid');
        }
      } else {
        eGridCell.classList.remove('invalid');
      }
    }
    render() {
      return (
        <Renderer
          {...this.props}
          startEditing={this.startEditing}
          isGrid
        />
      );
    }
  };

export default wrapCellRenderer;
