import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { Dictionary } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { getAllUsers } from 'data/accounts/actions';
import { accountsById, isAccountsLoading } from 'data/accounts/selectors';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';
import { State } from 'reducers';
import { AccountNode } from 'types/response/accountNode';
import { getInitials } from 'utilities/collections';

import ChoiceRenderer from '../choice/ChoiceRenderer';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';
import AccountChip from '../../AccountChip/AccountChip';

interface StateProps {
  accounts: Dictionary<AccountNode> | null;
  isLoading: boolean;
  quickSearch: string | null;
}

interface DispatchProps {
  getAllUsers(): void;
}

type Props = FieldRendererProps & DispatchProps & StateProps;

class AccountRenderer extends React.PureComponent<Props> {
  public componentDidMount(): void {
    const { accounts, isLoading } = this.props;
    if (accounts === null && !isLoading) {
      this.props.getAllUsers();
    }
    this.props.getAllUsers();
  }

  renderShort() {
    const { value, accounts } = this.props;

    const accountsList = getAccountsList(value, accounts);

    const accountsStr = accountsList.map(acc => acc.displayName).join('; ');

    return (
      <div title={accountsStr}>
        <Badge
          badgeContent={accountsList.length}
          color="primary"
          className={`badge ${accountsList.length <= 1 ? 'hidden' : ''}`}>
          <Button mini variant="fab">
            {accountsList.length ? getInitials(accountsList[0].displayName) : <PersonOutline />}
          </Button>
        </Badge>
      </div>
    );
  }

  render() {
    const { type, accounts, isLoading, ...props } = this.props;
    // console.log(props);

    if (isLoading) {
      return <CircularProgress size="1.5rem" />;
    }

    if (accounts === null) {
      return null;
    }

    let value = props.value;
    if (!accounts[value]) {
      const account = Object.values(accounts).find((account: AccountNode) => account.displayName === value) as AccountNode;
      if (account) {
        value = account.id;
      }
    }

    const _value = typeof value === 'string' && value.includes(',') ? value.split(',') : value? [value]: null;
    // highlight cell on quick search
    if (this.props.eGridCell && _value) {
      this.props.eGridCell.classList.remove('highlighted-cell');
      if (this.props.quickSearch && this.props.quickSearch.length > 0 && Object.keys(accounts).filter(accountID => {
        return _value[0].indexOf(accountID) > -1 && accounts[accountID].displayName.toLowerCase().includes(this.props.quickSearch && this.props.quickSearch.toLowerCase());
      }).length > 0) {
        this.props.eGridCell.classList.add('highlighted-cell');
      }
    }

    switch (type) {
      case FieldRendererTypes.createCard:
        return <PersonOutline />;
      case FieldRendererTypes.short:
        return this.renderShort();
      default:
        return (
          <ChoiceRenderer.OriginalComponent
            {...props}
            choiceType={'account'}
            value={value}
            choices={accounts}
            renderChoice={(choice, className) => <AccountChip accountId={choice.id} className={className}/>}
          />
        );
    }
  }
}

const mapStateToProps = createStructuredSelector<State, FieldRendererProps, StateProps>({
  accounts: accountsById,
  isLoading: isAccountsLoading,
  quickSearch: (state: State) => filtersSelector(state).quickSearch,
});

const mapDispatchToProps: DispatchProps = { getAllUsers };

const enhance = compose(
  wrapCellRenderer,
  connect<StateProps, DispatchProps, FieldRendererProps, State>(mapStateToProps, mapDispatchToProps),
);

export default enhance(AccountRenderer);

function getAccountsList(value: string | string[], accounts: Dictionary<AccountNode> | null): AccountNode[] {
  if (accounts === null) {
    return [];
  }

  if (Array.isArray(value)) {
    return value.reduce((accountsSoFar, id) => {
      const account = accounts[id];

      if (account === undefined) {
        return accountsSoFar;
      }

      return [...accountsSoFar, account];
    }, []);
  }

  const account = accounts[value];

  if (account === undefined) {
    return [];
  }

  return [account];
}
