export function exractDbID(uri) {
  const ids = uri.split('/');
  const indexOfDbId = ids.indexOf('databases') >= 0 ? ids.indexOf('databases') : ids.indexOf('taskdbs');
  const databasId = ids[indexOfDbId + 1];
  return databasId;
}
export function isDB(uri) {
  const ids = uri.split('/');
  const indexOfDbId = ids.indexOf('databases') >= 0 ? ids.indexOf('databases') : ids.indexOf('taskdbs');
  return indexOfDbId > -1;
}
export function isDBExact(uri) {
  return isDB(uri);
}
export function isDocuments(uri) {
  return uri.split('/').indexOf('documents') >= 0;
}

export function parseURI(url) {
  const ids = url.split('/');

  const indexOfDbId = ids.indexOf('databases') >= 0 ? ids.indexOf('databases') : ids.indexOf('taskdbs');
  const indexOfSheet = ids.indexOf('sheets') >= 0 ? ids.indexOf('sheets') : ids.indexOf('tasksheets');

  const workspaceId = ids[ids.indexOf('workspaces') + 1];
  const databaseId = indexOfDbId >= 0 && ids[indexOfDbId + 1];
  const sheetId = ids[indexOfSheet + 1];

  const dbSlug = ids.indexOf('databases') >= 0 ? 'databases' : ids.indexOf('taskdbs') >= 0 ? 'taskdbs' : null;
  const dbSlugV2 = ids.indexOf('databases') >= 0 ? 'databases' : 'tasks';
  const sheetSlug = ids.indexOf('sheets') >= 0 ? 'sheets' : 'tasksheets';

  return {
    workspaceId,
    databaseId,
    sheetId,
    dbSlug,
    dbSlugV2,
    sheetSlug,
  };
}
