import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';
import ChoiceItem from 'components/NodeFieldData/choice/ChoiceItem/ChoiceItem';

import { getRoleChoice } from '../shared/roleChoices';

type RoleChoiceRendererProps = ICellRendererParams;

export const RoleChoiceRenderer = ({ value }: RoleChoiceRendererProps) => {
  const roleChoice = getRoleChoice(value);
  return (
    <ChoiceItem choice={roleChoice} />
  );
};
