import { location as locationSelector } from 'data/app/selectors';
import { FileModel } from 'data/attachments/types';
import * as attachmentActions from 'data/attachments/actions';
import _ from 'lodash';
import { createFile as createFileReference, uploadFile as uploadFileAsync, copyAttachments as copyFiles } from 'pages/Files/Services/index';
import { State } from 'reducers';
import { eventChannel, delay } from 'redux-saga';
import { all, fork, takeEvery, call, put, select, take } from 'redux-saga/effects';
import * as request from 'superagent';
import { Node } from 'types/schema';
import { isActionOf, ActionType } from 'typesafe-actions';
import { normalizeURL } from 'utilities/format';
import getAuthHeaders from 'utilities/getAuthHeaders';
import { postRequest } from 'utilities/httpRequests';
import { handleError, successToast } from 'utilities/saga';

import { getFileExtension } from '../../../utilities/files';
import { parseURI } from '../../../utilities/parseURI';
import { actions } from './fileUpload.actions';
import * as types from './fileUpload.types';
import { getDirectoriesTree, createFolderIdsMap, getAbsolutePath } from './fileUpload.utils';


function* sendFileMetadata(body: types.SendFileMetadataRequestPayload) {
  yield put(actions.sendFileMetadata.request(body));

  const state: State = yield select();

  const { payload, uri: url } = body;
  const { fileSize, ...data } = payload;

  const result = yield call(postRequest, { data, url }, state);
  const node = result.body.node;

  yield put(actions.sendFileMetadata.success({ node, clientFileSize: fileSize }));

  return node;
}

function createSendBlobChannel(payload: types.SendFileBlobRequestPayload) {
  return eventChannel(emit => {
    request
      .put(normalizeURL(`${payload.apiURI}?blob`))
      .set(getAuthHeaders())
      .on('progress', (e: ProgressEvent) => {
        if (e.lengthComputable) {
          emit({
            type: types.UploadBlobChannelActionType.Progress,
            payload: e,
          });
        }
      })
      .on('error', error => {
        emit({
          type: types.UploadBlobChannelActionType.Error,
          error,
        });
      })
      .send(payload.file)
      .end((err, res: request.Response) => {
        if (err || !res.ok) {
          emit({
            type: types.UploadBlobChannelActionType.Error,
            error: res ? res.error : err,
          });

          return;
        }

        emit({
          type: types.UploadBlobChannelActionType.Loaded,
          payload: res.body,
        });
      });

    return () => {
      console.warn('sending blob done');
    };
  });
}

function* sendFileBlob(body: Omit<types.SendFileBlobRequestPayload, 'title'>) {
  yield put(actions.sendFileBlob.request({
    title: body.file.name,
    file: body.file,
    apiURI: body.apiURI,
  }));

  const state: State = yield select();
  const title = body.file.name;

  const { app: { validCredentials } } = state;

  if (validCredentials) {
    const ch = yield call(createSendBlobChannel, body);

    while (true) {
      const action: types.UploadBlobChannelAction = yield take(ch);

      switch (action.type) {
        case types.UploadBlobChannelActionType.Loaded: {
          yield put(actions.sendFileBlob.success({ title }));
          yield put(actions.finishUploadFile({ title }));
          yield call(successToast, `File ${title} successfully uploaded.`);
          break;
        }
        case types.UploadBlobChannelActionType.Progress: {
          yield put(actions.progress({
            title,
            total: action.payload.total,
            loaded: action.payload.loaded,
          }));
          break;
        }
        case types.UploadBlobChannelActionType.Error: {
          yield put(actions.sendFileBlob.failure(action.error));
          yield call(handleError, action.error);
          break;
        }
      }
    }
  }
}

function* uploadFile(item: types.UploadFilePayloadItem) {
  try {
    yield put(actions.startUploadFile({ title: item.file.name }));

    const body: types.SendFileMetadataRequestPayload = {
      uri: item.uri,
      payload: {
        fileName: item.file.name,
        fileSize: item.file.size,
      },
    };

    const fileNode: Node = yield call(sendFileMetadata, body);

    yield call(sendFileBlob, { file: item.file, apiURI: fileNode.apiURI });
  } catch (error) {
    yield call(handleError, error);
    yield put(actions.uploadFileError(error));
  }
}

function* uploadAttachment(item: types.UploadAttachmentPayloadItem) {
  try {
    const { file, rowId, colId } = item;
    const fieldId = colId.replace('fields.', '');
    const { workspaceId, databaseId } = parseURI(window.location.pathname);
    const uploadFileResult = yield uploadFileAsync(file, workspaceId);

    const fileReference = {
      title: file.name,
      fileType: file.type,
      fileExtension: getFileExtension(file.name),
      fileKey: uploadFileResult.fileName,
      meta: { size: file.size },
      sort: item.index,
      references: {
        databaseID: databaseId,
        fieldID: fieldId,
        itemID: rowId,
      },
    };

    const fileNode: FileModel = yield call(createFileReference, workspaceId, fileReference);
    if (fileNode) {
      yield put(attachmentActions.indexAttachment(fileNode));
      yield put(attachmentActions.setAttachmentStatus({ status: 'upload_success', rowId: rowId || null }));
      yield put(actions.attachmentUploadSuccess(item));
      yield delay(1000);
      yield put(actions.finishUploadAttachments());
    }
  } catch (error) {
    yield call(handleError, error);
    yield put(actions.uploadFileError(error));
    yield put(actions.tempPersistAttachments([]));
  }
}


function* copyAttachments(item: types.CopyAttachmentPayload) {
  try {
    const { rowNode, colId, source, attachedIds, onComplete } = item;
    const rowId = rowNode.data.id;
    const fieldId = colId.replace('fields.', '');

    const { workspaceId, databaseId } = parseURI(window.location.pathname);
    // @ts-ignore
    const attachments = yield select(state => state.attachments);

    if (!attachments.byItemId[source]) return;
    const filesToCopy: File[] = [];
    for (let index = 0; index < attachedIds.length; index++) {
      const fileId = attachedIds[index];
      const file = _.clone(attachments.byItemId[source].byId[fileId]);
      file.references = {
        databaseID: databaseId,
        fieldID: fieldId,
        itemID: rowId,
      };
      filesToCopy.push(file);
    }
    let copyFilesResult: types.FileAttachment[] = [];
    if (filesToCopy.length) {
      copyFilesResult = yield copyFiles(workspaceId, filesToCopy);
    }
    if (copyFilesResult && copyFilesResult.length) {
      for (let index = 0; index < copyFilesResult.length; index++) {
        const fileNode: FileModel = copyFilesResult[index] as FileModel;
        yield put(attachmentActions.indexAttachment(fileNode));
      }
      onComplete(rowNode.id);
    }
  } catch (error) {
    yield call(handleError, error);
  }
}

function* uploadDirectorySaga(action: ActionType<typeof actions.uploadDirectory.request>) {
  try {
    const { payload: files } = action;
    const state: State = yield select();
    const location = locationSelector(state);
    const pathname = location ? location.pathname : '';
    const search = location ? location.search : '';
    const url = search ? `${pathname}:tree${search}` : `${pathname}:tree`;
    const { body } = yield call(postRequest, {
      data: {
        nodes: getDirectoriesTree(files),
      },
      url,
    }, state);
    const folderIds = createFolderIdsMap(body.navigationTree.nodes);

    for (const file of files) {
      const absolutePath = getAbsolutePath(file);
      const folderId = folderIds[absolutePath];
      const uri = `${pathname}?folderID=${folderId}`;
      const payload = { file, uri };
      yield fork(uploadFile, payload);
    }

    yield put(actions.uploadDirectory.success());
  } catch (error) {
    yield put(actions.uploadDirectory.failure(error));
    yield call(handleError, error);
  }
}

function* uploadFilesSaga() {
  yield all([
    takeEvery(isActionOf(actions.uploadFiles),
      function* (action: ActionType<typeof actions.uploadFiles>) {
        for (const item of action.payload) {
          yield fork(uploadFile, item);
        }
      }),
    takeEvery(isActionOf(actions.uploadAttachments),
      function* (action: ActionType<typeof actions.uploadAttachments>) {
        for (const [index, item] of action.payload.entries()) {
          yield fork(uploadAttachment, item);
          yield delay(2000);
          if (index === action.payload.length - 1) {
            yield put(actions.tempPersistAttachments([]));
          }
        }
      }),
    takeEvery(isActionOf(actions.uploadDirectory.request), uploadDirectorySaga),
    takeEvery(isActionOf(actions.copyAttachments),
      function* (action: ActionType<typeof actions.copyAttachments>) {
        yield fork(copyAttachments, action.payload);
      }),
  ]);
}

export default function* fileUploadSaga() {
  yield all([
    fork(uploadFilesSaga),
  ]);
}
