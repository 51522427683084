import { AccountNode } from 'types/response/accountNode';

/**
 * Gets accounts response from endpoint and change user structure
 *
 * @param users - account list from backend
 * @param appendToName - in case you need to add something additional to the displayed name
 *                       like the status i.e. "(pending)"
 * @param simplifyId - takes only the role id
 */
export function parseUsers(users, appendToName = '', simplifyId = false) {
  return users.map((user) => {
    if (user.status !== 'active') {
      user.displayName = `${user.email}${
        appendToName ? ' ' + appendToName : ''
      }`;
    }

    if (user.role && simplifyId) {
      user.role = user.role.id;
    }

    return user;
  }) as AccountNode[];
}
