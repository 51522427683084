import {
  CellContextMenuEvent,
  ColDef,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { IsColumnFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { IRowDragItem } from 'ag-grid-community/dist/lib/rendering/rowDragComp';
import AddColumnHeader from 'components/AddColumnHeader/AddColumnHeader';
import getNativeRendererByFieldType, {
  renderers as nativeRenderers,
} from 'components/CellRenderers/getNativeRendererByFieldType';
import getValueFormatter from 'components/NodeFieldData/Fields.valueFormatters';
import { AttachmentsState } from 'data/attachments/reducer';
import { sortedColumnKeys } from 'data/collections/view-config/viewConfig.selectors';
import { getTenantConfig } from 'env';
import * as keys from 'keycode-js';
import { assign, Dictionary, max } from 'lodash';
import { ColumnsMapById } from 'types/gridOptions';
import { PreviewBehavior } from 'types/response';
import { AccountNode } from 'types/response/accountNode';
import { FieldType } from 'types/response/fieldNode';
import { TenantNode } from 'types/response/tenantNode';
import { getUserDefinedFieldKey } from 'utilities/collections';
import { removeHTMLTag } from 'utilities/format';
import { isDB } from 'utilities/parseURI';

import { getColumnTypeIcon } from '../../AddColumnHeader/FieldTypesIcons';
import * as ColID from '../../DataGrid/columns/constants';
import FieldColumnHeader, {
  FieldColumnHeaderParams,
} from '../../FieldColumnHeader';
import { getEditorByFieldType } from '../../NodeFieldData/Fields.editors';
import {
  getRendererByFieldType,
  renderers,
} from '../../NodeFieldData/Fields.renderers';
import getValueGetterByColumnType, {
  groupKeyCreator,
} from '../../NodeFieldData/Fields.valueGetters';
import AgGridTextFilter from '../../NodeFieldData/filters/AgGridTextFilter';
import AgGridTextFloatingFilter from '../../NodeFieldData/filters/AgGridTextFloatingFilter';
import {
  getDefaultOperatorForFilterType,
  mapColumnEditorToFilterType,
} from '../../NodeFieldData/filters/helpers';
import {
  FilterComponentParams,
  FloatingFilterComponentParams,
} from '../../NodeFieldData/filters/types';
import getFieldMetadata, {
  IndexMetadata,
} from '../../NodeFieldData/getFieldMetadata';
import {
  checkboxCheckedHtml,
  checkboxUncheckedHtml,
} from '../columns/Checkbox';

const tenantConfig = getTenantConfig() as any;

const DEFAULT_ROW_NUMBER_COLUMN_WIDTH = 60;
const ROW_NUMBER_ID_COLUMN_WIDTH = 88;

const textWidth = (text: string): number => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = '1rem Roboto';
    return ctx.measureText(text).width;
  }

  return 0;
};

const minColumnWidth = 46;
const columnActionsWidth = 95;

const columnWidth = (text: string, hasActions: boolean) => {
  const textLength = textWidth(text) + (hasActions ? columnActionsWidth : 0);
  Math.max( minColumnWidth, textLength < 300 ? textLength : 300);
};


function getIndexColumn(
  schemaColumns: ColumnsMapById,
  options: SchemaToColumnOptions,
): ColDef {
  const { withCheckbox, expandRecordEnabled, accountsById, attachments } =
    options;
  const cellRendererParams: IndexMetadata = {
    expandRecordEnabled,
  };

  let columnDefinition: ColDef = {
    colId: ColID.ROW_NUMBER_ID,
    // @ts-ignore
    // cellRendererFramework: renderers.IdRenderer,
    cellRenderer: nativeRenderers.IdRenderer,
    width: ROW_NUMBER_ID_COLUMN_WIDTH,
    minWidth: DEFAULT_ROW_NUMBER_COLUMN_WIDTH,
    field: ColID.ROW_NUMBER_ID,
    cellClass: ColID.ROW_NUMBER_ID,
    headerName: '',
    pinned: 'left',
    sortable: false,
    suppressMenu: true,
    filter: false,
    resizable: false,
    lockPosition: true,
    lockPinned: true,
    cellRendererParams,
    headerCheckboxSelection: isDB(document.location.pathname),
    pinnedRowCellRendererFramework: renderers.AddRowRenderer,
    valueGetter: getValueGetterByColumnType(FieldType.Singlelineoftext),
    valueFormatter: getValueFormatter(
      schemaColumns,
      accountsById || {},
      attachments || { byItemId: {} },
    ),
    onCellContextMenu: (event: CellContextMenuEvent): void => {
      if (!tenantConfig.enabledFeatures.advancedContextMenu) {
        event.node.setSelected(true);
      }
      event.api.stopEditing(true);
    },
  };

  if (options.permissions.includes('items:update')) {
    columnDefinition.rowDrag = true;

    // @TODO: Use typescript module augmentation to fix this types mismatch.
    // @ts-ignore dragItemCount is a type from the rowDragText definition but is not beign recognized here.
    columnDefinition.rowDragText = (
      params: IRowDragItem,
      dragItemCount,
    ): string => {
      return dragItemCount > 1 ? dragItemCount + ' items' : removeHTMLTag(params.defaultTextValue || '');
    };
  }

  if (withCheckbox) {
    columnDefinition = assign(columnDefinition, {
      checkboxSelection: true,
      icons: {
        checkboxChecked: checkboxCheckedHtml,
        checkboxUnchecked: checkboxUncheckedHtml,
      },
    });
  }

  return columnDefinition;
}

function getAddNewColumn(): ColDef {
  return {
    colId: ColID.ADD_NEW_FIELD_ID,
    field: ColID.ADD_NEW_FIELD_ID,
    width: 46,
    editable: false,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    filter: false,
    headerComponentFramework: AddColumnHeader,
    onCellContextMenu: (event: CellContextMenuEvent): void => {
      if (!tenantConfig.enabledFeatures.advancedContextMenu) {
        event.node.setSelected(true);
      }
      event.api.stopEditing(true);
    },
  };
}

export interface SchemaToColumnOptions {
  expandRecordEnabled: boolean;
  withCheckbox: boolean;
  fieldIDs: string[];
  groupByFieldIDs: string[];
  permissions: string[];
  previewBehavior: PreviewBehavior;
  hideColumnIcons?: boolean;
  columnWidths: Dictionary<number>;
  leftPinnedFieldIDs: string[];
  rightPinnedFieldIDs: string[];
  accountsById?: Record<string, AccountNode>;
  attachments?: AttachmentsState;
  tenant?: TenantNode;
}

// Extract column definitions from a schema.
export function getAgGridColumnDefs(
  columnsMap: ColumnsMapById,
  options: SchemaToColumnOptions,
): ColDef[] {
  const enablePreview = options.tenant?.thirdParty?.filestack?.enable;
  const columnNames: Record<string, boolean> = {};

  const columnDefs: ColDef[] = [getIndexColumn(columnsMap, options)];

  const canLockViews = options.permissions.includes('views:lock'); // 2020-03-10 - Currently only admin has views:lock permission

  sortedColumnKeys(columnsMap, options.fieldIDs).forEach((id) => {
    const column = columnsMap[id];

    // Determine column properties
    const columnType = column.fieldType;
    const allowMultiple = column.allowMultiple;
    const precision = column.precision;
    const dateFormat = column.dateFormat;

    const headerComponentParams: FieldColumnHeaderParams = {
      ColumnIcon: getColumnTypeIcon(columnType),
      hideColumnIcon: options.hideColumnIcons,
    };

    const filterType = mapColumnEditorToFilterType(columnType, allowMultiple);
    const filterComponentParams: FilterComponentParams = {
      type: filterType,
      operator: getDefaultOperatorForFilterType(filterType),
    };
    const floatingFilterComponentParams: FloatingFilterComponentParams = {
      precision,
      dateFormat,
    };

    const rowGroupIndex = options.groupByFieldIDs.indexOf(id);

    const filter = !(column.grid && column.grid.suppressFilter);
    const sortable = !(column.grid && column.grid.suppressSorting);
    const resizable = !(column.grid && column.grid.suppressResize);
    const gridProperty = column.grid ? { ...column.grid } : {};
    delete gridProperty.suppressFilter;
    delete gridProperty.suppressFilterBar;
    delete gridProperty.suppressFilterDropdown;
    delete gridProperty.suppressSorting;
    delete gridProperty.suppressResize;

    // Static properties.
    const colDef: ColDef = {
      filter,
      sortable,
      resizable,
      enablePivot: true,
      enableRowGroup:
        columnType != FieldType.Attachment &&
        columnType != FieldType.Multilinetext,
      enableValue: true,
      menuTabs: ['generalMenuTab'],
      cellClass: `column-${columnType}`,
      field: id.toString(),
      colId: id.toString(),
      filterFramework: AgGridTextFilter,
      filterParams: filterComponentParams,
      floatingFilterComponentFramework: AgGridTextFloatingFilter,
      floatingFilterComponentParams,
      // @ts-ignore
      sortingOrder: ['asc', 'desc', null],
      headerClass: [`column-${columnType}`],
      // @ts-ignore
      refData: column,
      type: [],
      cellEditorParams: {
        ...getFieldMetadata(column, options.previewBehavior),
        showPreview: enablePreview && !!column.showPreview,
      },
      cellRendererParams: {
        ...getFieldMetadata(column, options.previewBehavior),
        showPreview: enablePreview && !!column.showPreview,
      },
      editable: false,
      singleClickEdit: getSingleClickEditByColumnType(columnType),
      valueGetter: getValueGetterByColumnType(columnType),
      keyCreator: groupKeyCreator(columnType) as any,
      ...getStaticColumnDefParams(columnType),
      ...gridProperty,
      headerComponentParams,
      headerComponentFramework: FieldColumnHeader,
      rowGroupIndex: rowGroupIndex >= 0 ? rowGroupIndex : undefined,
      pinned: getPinnedValue(
        id,
        options.leftPinnedFieldIDs,
        options.rightPinnedFieldIDs,
      ),
      pinnedRowCellRendererFramework: () => null,
      onCellContextMenu: (event: CellContextMenuEvent): void => {
        if (!tenantConfig.enabledFeatures.advancedContextMenu) {
          event.node.setSelected(true);
        }
        event.api.stopEditing(true);
      },
    };

    colDef['columnType'] = columnType;

    // Handle column name conflicts.
    let columnName = id !== 'isBookmarked' ? column.name : ''; // Special case for isBookmarked field
    for (let i = 2; columnName in columnNames; i += 1) {
      columnName = `${column.name} ${i}`;
    }
    columnNames[columnName] = true;
    colDef.headerName = columnName;

    // Set minimum and maximum widths.
    colDef.width =
      options.columnWidths[getUserDefinedFieldKey(id)] ||
      columnWidth(
        columnName,
        !gridProperty.suppressMenu || !gridProperty.suppressFilter,
      );
    colDef.minWidth = max([
      minColumnWidth,
      column.grid && column.grid.minWidth,
    ]);

    if (column.grid && column.grid.maxWidth) {
      colDef.maxWidth = column.grid.maxWidth;
    }

    if (colDef.maxWidth === -1) {
      delete colDef.maxWidth;
    }

    // Handle choice mappings.
    if (column.choices) {
      // Map keys to values.
      // @ts-ignore
      // TODO: Investigate why choices is not an index type
      colDef.valueFormatter = (params) => column.choices[params];
    }

    // Assign column renderer and editor
    const vanillaRenderer = getNativeRendererByFieldType(columnType);
    if (vanillaRenderer) {
      // @ts-ignore
      colDef.cellRenderer = getNativeRendererByFieldType(columnType);
    } else {
      colDef.cellRendererFramework = getRendererByFieldType(columnType);
    }
    colDef.cellEditorFramework = getEditorByFieldType(columnType);
    colDef.cellRendererParams = { ...colDef.cellRendererParams, isGrid: true };

    if (options.fieldIDs && !options.fieldIDs.includes(id)) {
      colDef.hide = true;
    } else {
      colDef.hide = false;
    }
    const isColumnLock = column?.lock ?? false;
    const shouldLock =
      isColumnLock &&
      !options.permissions.includes('fields:lock') &&
      column.fieldType !== FieldType.Attachment;
    // Check edit mode.
    if (
      (column.readOnly && !column.id.startsWith('fields.')) ||
      !options.permissions.includes('items:create') ||
      shouldLock
    ) {
      colDef.editable = false;
    } else if (options.permissions.includes('items:update')) {
      colDef.editable = (params: IsColumnFuncParams) =>
        !params.node.isRowPinned();
    } else if (column.fieldType === FieldType.Attachment) {
      colDef.editable = true;
    }

    if (column.fieldType === FieldType.Attachment && !shouldLock) {
      colDef.editable = true;
    }

    if (column.fieldType === FieldType.Boolean && !shouldLock) {
      colDef.editable = options.permissions.includes('items:update');
    }

    if (
      column.fieldType === FieldType.Button ||
      column.fieldType === FieldType.Status
    ) {
      colDef.editable = false;
    }

    if (columnType === FieldType.Bookmarked && !shouldLock) {
      colDef.lockPosition = true;
    }

    // @ts-ignore
    colDef.readOnly =
      column.readOnly && !options.permissions.includes('items:create');

    if (columnType === FieldType.Account) {
      // @ts-ignore
      colDef.refData['accountsById'] = options.accountsById;
    }

    if (
      columnType === FieldType.Singlechoice ||
      columnType === FieldType.Multiplechoice ||
      columnType === FieldType.Attachment
    ) {
      colDef.suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        const keyCode = params.event.keyCode;
        if (
          params.editing &&
          (keyCode === keys.KEY_ENTER || keyCode === keys.KEY_RETURN)
        ) {
          return true;
        }
        return false;
      };
    }
    columnDefs.push(colDef);
  });

  if (options.permissions.includes('fields:create') && canLockViews) {
    columnDefs.push(getAddNewColumn());
  }

  return columnDefs;
}

function getPinnedValue(
  fieldId: string,
  leftPinnedIDs: string[],
  rightPinnedIDs: string[],
): string | boolean {
  if (leftPinnedIDs.includes(fieldId)) {
    return 'left';
  }

  if (rightPinnedIDs.includes(fieldId)) {
    return 'right';
  }

  return false;
}

function getStaticColumnDefParams(type: FieldType): Partial<ColDef> {
  switch (type) {
    case FieldType.FileName:
    case FieldType.Bookmarked: {
      return { singleClickEdit: false };
    }

    default:
      return {};
  }
}

function getSingleClickEditByColumnType(type: FieldType) {
  switch (type) {
    case FieldType.Url: {
      return false;
    }
    case FieldType.Multiplechoice: {
      return true;
    }
    default:
      return false;
  }
}
