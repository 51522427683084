import svgIcons from 'styles/svgIcons';
import { SvgIcon } from 'types/common';
import { Choice } from 'types/response/fieldNode';

export interface ChoiceOption {
  id: string;
  value: Choice;
}

export enum Precision {
  A = '1',
  B = '1.0',
  C = '1.00',
  D = '1.000',
  E = '1.0000',
  F = '1.00000',
  G = '1.000000',
  H = '1.0000000',
  I = '1.00000000',
}

export const precisions: Precision[] = [
  Precision.A,
  Precision.B,
  Precision.C,
  Precision.D,
  Precision.E,
  Precision.F,
  Precision.G,
  Precision.H,
  Precision.I,
];

export function getPrecisionLabel(predicate: string, precision: Precision): string {
  return `${predicate}${precision}`;
}

export enum DateFormat {
  Local = 'M/D/YYYY',
  Friendly = 'MMMM D, YYYY',
  US = 'M/D/YYYY',
  European = 'D/M/YYYY',
  ISO = 'YYYY-MM-DD'
}

export const DEFAULT_DATE_FORMAT = DateFormat.US;

export const dateFormats: DateFormat[] = [
  DateFormat.Friendly,
  DateFormat.US,
  DateFormat.European,
  DateFormat.ISO,
];

export function getDateFormatLabel(dateFormat: DateFormat): string {
  switch (dateFormat) {
    case DateFormat.Local: {
      return 'Local (12/31/2018)';
    }

    case DateFormat.Friendly: {
      return 'Friendly (December 31, 2018)';
    }

    case DateFormat.US: {
      return 'US (12/31/2018)';
    }

    case DateFormat.European: {
      return 'European (31/12/2018)';
    }

    case DateFormat.ISO: {
      return 'ISO (2018-12-31)';
    }
  }
}

export enum TimeFormat {
  Twelve = 'h',
  TwentyFour = 'H'
}

export const timeFormats: TimeFormat[] = [
  TimeFormat.Twelve,
  TimeFormat.TwentyFour,
];

export function getTimeFormatLabel(timeFormat: TimeFormat): string {
  switch (timeFormat) {
    case TimeFormat.Twelve: {
      return '12 hour';
    }

    case TimeFormat.TwentyFour: {
      return '24 hour';
    }
  }
}

export enum NumberType {
  Integer = 'integer',
  Float = 'float'
}

export const numberTypes: NumberType[] = [
  NumberType.Integer,
  NumberType.Float,
];

export function getNumberTypeLabel(numberType: NumberType): string {
  switch (numberType) {
    case NumberType.Integer: {
      return 'Integer (2)';
    }

    case NumberType.Float: {
      return 'Decimal (1.0)';
    }
  }
}

// Field Types

export enum FieldType {
  SingleLineText = 'Single line text',
  LongText = 'Long text',
  Attachment = 'Attachment',
  Checkbox = 'Checkbox',
  MultiSelect = 'Multi select',
  SingleSelect = 'Single select',
  Person = 'Person',
  Date = 'Date',
  Number = 'Number',
  Currency = 'Currency',
  Percent = 'Percent',
  Rating = 'Rating',
  URL = 'Url',
  Email = 'Email',
  Phone = 'Phone',
  Button = 'Button',
}

export const allFieldTypes: FieldType[] = [
  FieldType.SingleLineText,
  FieldType.LongText,
  FieldType.Attachment,
  FieldType.Checkbox,
  FieldType.MultiSelect,
  FieldType.SingleSelect,
  FieldType.Person,
  FieldType.Date,
  FieldType.Number,
  FieldType.Currency,
  FieldType.Percent,
  FieldType.Rating,
  FieldType.URL,
  FieldType.Email,
  FieldType.Phone,
  FieldType.Button,
];

export function getIcon(fieldType: FieldType): SvgIcon {
  switch (fieldType) {
    case FieldType.SingleLineText: {
      return svgIcons.SingleLineOfText;
    }

    case FieldType.LongText: {
      return svgIcons.MultilineText;
    }

    case FieldType.Attachment: {
      return svgIcons.Attachment;
    }

    case FieldType.Checkbox: {
      return svgIcons.Checkbox;
    }

    case FieldType.MultiSelect: {
      return svgIcons.MultipleChoice;
    }

    case FieldType.SingleSelect: {
      return svgIcons.SingleChoice;
    }

    case FieldType.Person: {
      return svgIcons.Account;
    }

    case FieldType.Date: {
      return svgIcons.Date;
    }

    case FieldType.Number: {
      return svgIcons.Number;
    }

    case FieldType.Currency: {
      return svgIcons.Currency;
    }

    case FieldType.Percent: {
      return svgIcons.Percent;
    }

    case FieldType.Rating: {
      return svgIcons.Rating;
    }

    case FieldType.URL: {
      return svgIcons.Url;
    }

    case FieldType.Email: {
      return svgIcons.Email;
    }

    case FieldType.Phone: {
      return svgIcons.Phone;
    }

    case FieldType.Button: {
      return svgIcons.Button;
    }
  }
}

export function getFieldTypeTooltip(fieldType: FieldType): string | null {
  switch (fieldType) {
    case FieldType.SingleLineText: {
      return 'A single line of text.';
    }

    case FieldType.LongText: {
      return 'A long text field that can span multiple lines.';
    }

    case FieldType.Attachment: {
      return 'Attachments allow you to add images, documents, or other files which can then be viewed or downloaded.';
    }

    case FieldType.Checkbox: {
      return 'A single checkbox that can be checked or unchecked.';
    }

    case FieldType.MultiSelect: {
      return 'Multiple select allows you to select one or more predefined options listed below.';
    }

    case FieldType.SingleSelect: {
      return 'Single select allows you to select a single option from predefined options in a dropdown.';
    }

    case FieldType.Person: {
      return 'A person field lets you add persons to your records.';
    }

    case FieldType.Date: {
      return 'Enter a date (e.g. 11/12/2013) or pick one from a calendar.';
    }

    case FieldType.Number: {
      return null;
    }

    case FieldType.Currency: {
      return null;
    }

    case FieldType.Percent: {
      return null;
    }

    case FieldType.Rating: {
      return null;
    }

    case FieldType.URL: {
      return null;
    }

    case FieldType.Email: {
      return 'A valid email address (e.g. john@example.com).';
    }

    case FieldType.Phone: {
      return null;
    }

    case FieldType.Button: {
      return null;
    }
  }
}

export interface UrlData {
  type: FieldType.URL;
  defaultText?: string;
}

export interface EmailData {
  type: FieldType.Email;
  defaultText?: string;
}

export interface PhoneData {
  type: FieldType.Phone;
  defaultText?: string;
}

export interface ButtonData {
  type: FieldType.Button;
  // Set if the button field form is valid
  valid: boolean;
  // Action  to be called on button click
  action: string;
  // Button label
  label: string;
  // Additional data in JSON format
  additionalData?: string;
}

const defaultUrlData: UrlData = {
  type: FieldType.URL,
};

const defaultEmailData: EmailData = {
  type: FieldType.Email,
};

const defaultPhoneData: PhoneData = {
  type: FieldType.Phone,
};

const defaultButtonData: ButtonData = {
  type: FieldType.Button,
  action: '',
  label: 'Action',
  valid: true,
};

export interface SingleLineTextData {
  type: FieldType.SingleLineText;
  defaultText?: string;
  isPrimary?: boolean;
}

const defaultSingleLineTextData: SingleLineTextData = {
  type: FieldType.SingleLineText,
};

export interface LongTextData {
  type: FieldType.LongText;
  isPrimary?: boolean;
}

const defaultLongTextData: LongTextData = {
  type: FieldType.LongText,
};

export interface AttachmentData {
  type: FieldType.Attachment;
  showPreview: boolean;
}

const defaultAttachmentData: AttachmentData = {
  type: FieldType.Attachment,
  showPreview: false,
};

export interface CheckboxData {
  type: FieldType.Checkbox;
  defaultValue: boolean;
}

const defaultCheckboxData: CheckboxData = {
  type: FieldType.Checkbox,
  defaultValue: false,
};

export interface MultiSelectData {
  type: FieldType.MultiSelect;
  choices: ChoiceOption[];
  choiceOrder?: string[];
  allowGridAddingOptions?: boolean;
  defaultValue?: string;
}

const defaultMultiSelectData: MultiSelectData = {
  type: FieldType.MultiSelect,
  choices: [],
  allowGridAddingOptions: false,
};

export interface SingleSelectData {
  type: FieldType.SingleSelect;
  choices: ChoiceOption[];
  choiceOrder?: string[];
  allowGridAddingOptions?: boolean;
  defaultValue?: string;
}

const defaultSingleSelectData: SingleSelectData = {
  type: FieldType.SingleSelect,
  choices: [],
  allowGridAddingOptions: false,
};

export interface PersonData {
  type: FieldType.Person;
  allowMultiple: boolean;
}

const defaultPersonData: PersonData = {
  type: FieldType.Person,
  allowMultiple: false,
};

export interface DateData {
  type: FieldType.Date;
  dateFormat: DateFormat;
  timeFormat: TimeFormat | null;
}

const defaultDateData: DateData = {
  type: FieldType.Date,
  dateFormat: DateFormat.US,
  timeFormat: null,
};

export interface NumberData {
  type: FieldType.Number;
  defaultNumber?: number | string;
  allowNegative: boolean;
  precision: Precision;
  subtype: NumberType;
}

const defaultNumberData: NumberData = {
  type: FieldType.Number,
  allowNegative: false,
  precision: Precision.A,
  subtype: NumberType.Float,
};

export interface CurrencyData {
  type: FieldType.Currency;
  defaultNumber?: number | string;
  allowNegative: boolean;
  precision: Precision;
  currencySymbol: string;
}

const defaultCurrencyData: CurrencyData = {
  type: FieldType.Currency,
  allowNegative: false,
  precision: Precision.A,
  currencySymbol: '$',
};

export interface PercentData {
  type: FieldType.Percent;
  defaultNumber?: number | string;
  allowNegative: boolean;
  precision: Precision;
}

const defaultPercentData: PercentData = {
  type: FieldType.Percent,
  allowNegative: false,
  precision: Precision.A,
};

export interface RatingData {
  type: FieldType.Rating;
}

const defaultRatingData: RatingData = {
  type: FieldType.Rating,
};

export type FieldData
  = SingleLineTextData
  | LongTextData
  | AttachmentData
  | CheckboxData
  | MultiSelectData
  | SingleSelectData
  | PersonData
  | DateData
  | NumberData
  | CurrencyData
  | PercentData
  | RatingData
  | UrlData
  | EmailData
  | PhoneData
  | ButtonData
  ;

export function getDefaultFieldData(fieldType: FieldType): FieldData {
  switch (fieldType) {
    case FieldType.SingleLineText: {
      return defaultSingleLineTextData;
    }

    case FieldType.LongText: {
      return defaultLongTextData;
    }

    case FieldType.Attachment: {
      return defaultAttachmentData;
    }

    case FieldType.Checkbox: {
      return defaultCheckboxData;
    }

    case FieldType.MultiSelect: {
      return defaultMultiSelectData;
    }

    case FieldType.SingleSelect: {
      return defaultSingleSelectData;
    }

    case FieldType.Person: {
      return defaultPersonData;
    }

    case FieldType.Date: {
      return defaultDateData;
    }

    case FieldType.Number: {
      return defaultNumberData;
    }

    case FieldType.Currency: {
      return defaultCurrencyData;
    }

    case FieldType.Percent: {
      return defaultPercentData;
    }

    case FieldType.Rating: {
      return defaultRatingData;
    }

    case FieldType.URL: {
      return defaultUrlData;
    }

    case FieldType.Email: {
      return defaultEmailData;
    }

    case FieldType.Phone: {
      return defaultPhoneData;
    }

    case FieldType.Button: {
      return defaultButtonData;
    }
  }
}

export function parseNumber(possibleNumber: string): number | undefined {
  const parsed = parseFloat(possibleNumber);

  if (Number.isNaN(parsed)) {
    return undefined;
  }

  return parsed;
}

export function maybeNumberToString(possibleNumber?: number | string): string {
  return typeof possibleNumber === 'number' ? `${possibleNumber}` : '';
}
