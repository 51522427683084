import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import cn from 'classnames';
import { styles } from 'components/IconButton/IconButton.style';
import { SvgIcon } from 'types/common';

export enum Color {
  Blue,
  LightGray,
  DarkGray,
  BlueyGrey,
  LightBlueGrey,
  DarkGrayNoHover,
}

export enum Size {
  ExtraSmall,
  Small,
  Medium,
  Large,
}

interface OwnProps {
  id?: string;
  icon: SvgIcon;
  color: Color;
  size: Size;
  selected?: boolean;
  className?: string;
}

type Props
  = OwnProps
  & WithStyles<typeof styles>
  & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>
  ;

class IconButton extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { id, classes, className = '', icon, color, size, selected, type, disabled, ...buttonProps } = this.props;
    const Icon = icon;
    const buttonClassName = cn({
      [className]: className,
      [classes.root]: true,
      [classes.buttonExtraSmall]: size === Size.ExtraSmall,
      [classes.buttonSmall]: size === Size.Small,
      [classes.buttonMedium]: size === Size.Medium,
      [classes.buttonLarge]: size === Size.Large,
      [classes.iconBlue]: color === Color.Blue,
      [classes.iconLightGray]: color === Color.LightGray,
      [classes.iconDarkGrayNoHover]: color === Color.DarkGrayNoHover,
      [classes.iconDarkGray]: color === Color.DarkGray,
      [classes.iconBlueyGrey]: color === Color.BlueyGrey,
      [classes.iconLightBlueGrey]: color === Color.LightBlueGrey,
      [classes.selected]: selected,
      [classes.buttonDisabled]: disabled,
    });
    const iconClassName = cn({
      [classes.iconExtraSmall]: size === Size.ExtraSmall,
      [classes.iconSmall]: size === Size.Small,
      [classes.iconMedium]: size === Size.Medium,
      [classes.iconLarge]: size === Size.Large,
    });

    return (
      <button id={id} className={buttonClassName} type={type || 'button'} {...buttonProps}>
        <Icon className={iconClassName} />
      </button>
    );
  };
}

export default withStyles(styles)(IconButton);
