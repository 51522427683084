import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';

/** */
export function parseOneBookmark(response, state) {
  const node = response.body;
  return {
    ...node,
    id: node._id.toString(),
    permissions: getWorkspacePermissions(state),
  };
}

/** */
export function parseBookmarksResponse(response, state) {
  const nodes = response.body;
  const bookmarks = nodes.map(node => parseOneBookmark({ body: node }, state));
  return {
    bookmarks,
    permissions: getWorkspacePermissions(state),
  };
}
