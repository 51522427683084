import svgIcons from 'styles/fileExtensionsSvgIcons';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const StarFilled = require('icons/star-filled.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const StarLined = require('icons/star-lined.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Dropdown = require('icons/dropdown.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Add = require('icons/add.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MenuDots = require('icons/menu-dots.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Expand = require('icons/expand-record.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Collapse = require('icons/smaller.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Link = require('icons/link.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const UserIcon = require('icons/user-icon.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Clock = require('icons/clock.svg');

const iconsToDownload = {
  ...svgIcons,
  starFilled: StarFilled,
  starLined: StarLined,
  dropDown: Dropdown,
  add: Add,
  menuDots: MenuDots,
  expand: Expand,
  collapse: Collapse,
  link: Link,
  userIcon: UserIcon,
  clockIcon: Clock,
};

export const typeIcon = {};

export const downloadIcon = (type, path, onComplete) => {
  const ajax = new XMLHttpRequest();
  ajax.open('GET', path, true);
  ajax.send();
  ajax.onload = function(e) {
    onComplete(type, ajax.responseText);
  };
};

Object.keys(iconsToDownload).forEach(type => {
  downloadIcon(type, iconsToDownload[type], (iconType, content) => {
    const div = document.createElement('div');
    div.innerHTML = content.replace(/<(\?xml|(!DOCTYPE[^>[]+(\[[^\]]+)?))+[^>]+>/g, '').trim();
    typeIcon[type] = div.firstChild;
  });
});
