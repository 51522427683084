import { EMPTY_TITLE } from '../../AgGrid/utils';
import { enableRipple } from '@syncfusion/ej2-base';
import {
  RichTextEditor,
  Link,
  HtmlEditor,
} from '@syncfusion/ej2-richtexteditor';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';

import { getGridRowMaxTextLines, getGridRowClassText } from '../../AgGrid/utils';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import BaseRenderer from '../BaseRendererPrototype';
import { FieldsTypes as FieldRendererTypes } from '../FieldsTypes';
import GenericTextRenderer from '../GenericTextRenderer';

enableRipple(true);
RichTextEditor.Inject(Link, HtmlEditor);

function SingleLineOfTextRenderer() {
  return true;
}

SingleLineOfTextRenderer.prototype = Object.create(BaseRenderer.prototype);

export function createSingleLineOfTextElement(params) {
  const { type, fieldType, node, isGrid, column } = params;
  let { value } = params;
  value = value ? `${value}` : '';

  if (
    type === FieldRendererTypes.short ||
    type === FieldRendererTypes.createCard
  ) {
    const element = document.createElement('span');
    element.classList.add(getColumnHeaderClass(fieldType));
    element.innerHTML = value;
    return { element };
  }

  const lines = isGrid && node ? getGridRowMaxTextLines(node.rowHeight) : 1;
  const element = document.createElement('div');
  element.id = `${node.id}-${column.colId}`;
  element.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  const valueElement = document.createElement('div');
  valueElement.classList.add(getGridRowClassText(node.rowHeight));
  valueElement.style = GenericTextRenderer(
    lines,
    params.color || null,
    params.fontSize || null,
    params.width || null,
  );
  valueElement.innerHTML = node?.group && value == EMPTY_TITLE ? '' : value;
  element.append(valueElement);

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState())
      .quickSearch;
    if (
      currentQuickSearch &&
      value?.toLowerCase()?.includes(currentQuickSearch.toLowerCase())
    ) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  return {
    element,
    valueElement,
  };
}

SingleLineOfTextRenderer.prototype.init = function(params) {
  const { valueElement, element } = createSingleLineOfTextElement(params);
  this.element = element;
  this.eGridCell = params.eGridCell;

  // This element prevent click on the RichTextEditor.
  const clickBarrier = document.createElement('div');
  clickBarrier.classList.add('hb-click-barrier');
  element.appendChild(clickBarrier);
  this.defaultRTE = new RichTextEditor({
    readonly: true,
    inlineMode: {
      enable: true,
      onSelection: true,
    },
    format: {
      width: 'auto',
    },
    fontFamily: {
      width: 'auto',
    },
  });

  if (valueElement) {
    this.valueElement = valueElement;
    this.defaultRTE.appendTo(valueElement);
  }

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

SingleLineOfTextRenderer.prototype.getGui = function() {
  return this.element;
};

SingleLineOfTextRenderer.prototype.refresh = function() {
  return false;
};

SingleLineOfTextRenderer.prototype.destroy = function() {
  return true;
};

export default SingleLineOfTextRenderer;
