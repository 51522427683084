import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community/dist/lib/headerRendering/header/headerComp';
import { getColumnTypeIcon } from 'components/AddColumnHeader/FieldTypesIcons';
import { FieldType } from 'types/response/fieldNode';
import svgIcons from '../../../styles/svgIcons';

enum Sort {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

interface State {
  sort: Sort;
}

interface OwnProps {
  sortable?: boolean;
}

type Props = IHeaderParams & OwnProps;

class ColumnHeader extends React.Component<Props, State> {
  private ref;

  static getDerivedStateFromProps(props: Props) {
    const sortModel = props.api.getSortModel().find(model => model.colId === props.column.getColDef().colId);
    if (!sortModel) return;
    return { sort: sortModel.sort };
  }

  public render(): JSX.Element {
    const column = this.props.column.getColDef();
    const Icon = getColumnTypeIcon(column.type as FieldType);
    return (
      <div className="ag-cell-label-container" role="presentation" onClick={this.sortColumn}>
        {column.colId && (
          <React.Fragment>
            <span ref={ref => this.ref = ref} className="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" onClick={this.showMenu}>
              <span className="ag-icon ag-icon-menu" unselectable="on"></span>
            </span>
          </React.Fragment>
        )}
        <div className="ag-header-cell-label" role="presentation" unselectable="on">
          {Icon && <Icon />}
          {column.headerName && <span className="ag-header-cell-text" role="columnheader" unselectable="on">{column.headerName}</span>}
          {this.state?.sort === Sort.ASC && <svgIcons.ArrowUp className="people-sort-column-icon" />}
          {this.state?.sort === Sort.DESC && <svgIcons.ArrowDown className="people-sort-column-icon" />}
        </div>
      </div>
    );
  }

  private sortColumn = () => {
    if (!this.props.column.getColDef().sortable) return;

    switch (this.state?.sort) {
      case Sort.ASC:
        this.props.setSort(Sort.DESC, true);
        this.setState({ sort: Sort.DESC });
        break;
      case Sort.DESC:
        this.props.setSort(Sort.NONE, true);
        this.setState({ sort: Sort.NONE });
        break;
      default:
        this.props.setSort(Sort.ASC, true);
        this.setState({ sort: Sort.ASC });
    }
  };

  private showMenu = () => {
    this.props.showColumnMenu(this.ref);
  };
}

export default ColumnHeader;
