import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import { styles } from './ListSelectPopup.style';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';
import Button from '@material-ui/core/Button';
import * as keys from 'keycode-js';

interface ListSelectPopupProps extends WithStyles<typeof styles> {
  id?: string;
  items: any[];
  renderItem: (item: any, i: number) => React.ReactNode;
  matchItem: (query: string, item: any) => boolean;
  onSelect: (item: any) => void;
  mapItemToValue: (item: any) => any;
  includeEmpty?: boolean;
  isItemSelected: (item: any) => boolean;
  onKeyDown?: (e: any) => void;
  addNewOptions?: boolean;
  newOptionOnClick?: (option: any) => void;
}

interface ListSelectPopupState {
  query: string;
  index: number;
}

class ListSelectPopup extends React.PureComponent<ListSelectPopupProps, ListSelectPopupState> {
  state = {
    query: '',
    index: 0,
  };

  getMatchedItems() {
    const { matchItem, items } = this.props;
    const { query } = this.state;

    const matchedItems = query
      ? items.filter(item => matchItem(query, item))
      : items;

    if (query && query.length > 0 && this.props.addNewOptions) {
      matchedItems.push({
        id: '--add-new-option',
        choice: {
          color: '',
          label: `Create a new option named: ${query}`,
          byQuery: query,
        },
      });
    }

    return matchedItems;
  }

  handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: query } = e.target;
    this.setState({ query });
  };

  handleItemSelect = item => () => {
    const { onSelect, mapItemToValue } = this.props;
    let itemValue;
    if (item.id === '--add-new-option' && this.state.query) {
      if (typeof this.props.newOptionOnClick == 'function') {
        itemValue = this.props.newOptionOnClick(item);
      }
    } else {
      itemValue = mapItemToValue(item);
    }

    onSelect(itemValue);
  };

  /**
   * Handle root ref set and scroll to selected item on ref set.
   *
   * @param ref - list ref
   */
  onRootRef = (ref) => {
    if (ref) {
      const selected = ref.querySelector('.selected');
      if (selected) {
        selected.scrollIntoView();
      }
    }
  };

  render() {
    const { id, renderItem, mapItemToValue, includeEmpty, onSelect, classes } = this.props;
    const matchedItems = this.getMatchedItems();
    const { query } = this.state;
    return (
      <RootRef rootRef={this.onRootRef}>
        <MenuList className={classes.list} onKeyDown={(e: any) => {
          if (e.keyCode === keys.KEY_DOWN) {
            this.setState({
              index: this.state.index >= matchedItems.length? matchedItems.length - 1: this.state.index + 1,
            });
          } else if (e.keyCode === keys.KEY_UP) {
            this.setState({
              index: this.state.index <= 0? 0: this.state.index - 1,
            });
          } else if ((e.keyCode === keys.KEY_RETURN || e.keyCode === keys.KEY_ENTER) && matchedItems && matchedItems.length > 0) {
            if (!query && includeEmpty && this.state.index === 0) {
              onSelect(null);
            } else {
              this.handleItemSelect(matchedItems[includeEmpty? matchedItems.length === 1? 0: this.state.index > 0? this.state.index - 1: 0 : this.state.index])();
            }
          } else {
            if (this.props.onKeyDown) {
              this.props.onKeyDown(e);
            }
          }
        }}>
          <SearchBar
            className={classes.searchBox}
            placeholder="Find an option"
            value={query}
            onChange={this.handleQuery}
            size={SearchBarSize.Small}
            autoFocus
          />
          {includeEmpty && !query && (
            <MenuItem selected={0 === this.state.index} className={classes.item} onClick={() => onSelect(null)}/>
          )}
          {matchedItems.map((item, i) => {
            const itemValue = mapItemToValue(item);
            return (
              <MenuItem
                key={`${itemValue}`}
                component={(buttonProps) => {
                  return (
                    <Button id={`btn${id}_${i}`} onClick={buttonProps.onClick} className={buttonProps.className} fullWidth={true}>
                      {buttonProps.children}
                    </Button>
                  );
                }}
                onClick={this.handleItemSelect(item)}
                selected={!query ? (!includeEmpty ? i === this.state.index: i === this.state.index - 1): i === 0}
                className={classes.item}
              >
                {renderItem(item, i)}
              </MenuItem>
            );
          })}
        </MenuList>
      </RootRef>
    );
  }
}

export default withStyles(styles)(ListSelectPopup);
