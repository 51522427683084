import withStyles from '@material-ui/core/styles/withStyles';

export type ClassKey = 'wrapper';

export const styles = withStyles<ClassKey>({
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
