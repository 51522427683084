import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cn from 'classnames';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { fontFamily } from 'styles/fonts';
import { SvgIcon } from 'types/common';

enum GlobalClassNames {
  Primary = 'app-btn-primary',
}

export enum Variant {
  Primary,
  Secondary,
  PrimaryLink,
  SecondaryLink,
  TertiaryLink,
  DropDown,
  BackgroundLink,
  BackgroundLinkDanger,
  Link,
  LinkDanger,
}

export enum Size {
  Normal = 'Normal',
  Small = 'Small',
}

interface OwnProps {
  label: string;
  variant: Variant;
  size?: Size; // Default is Size.Normal
  icon?: SvgIcon;
  postIcon?: SvgIcon;
  selected?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
}

export type ButtonProps = OwnProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

type Props
  = ButtonProps
  & WithStyles<ClassKey>
  ;

function Button(props: Props): JSX.Element {
  const { className = '', classes, label, variant, size = Size.Normal, icon, selected, type, postIcon, fullWidth = false, isLoading = false, ...buttonProps } = props;
  const isFilledVariant = variant === Variant.Primary || variant === Variant.Secondary;
  const isLinkVariant =
    variant === Variant.PrimaryLink
    || variant === Variant.SecondaryLink
    || variant === Variant.TertiaryLink
    || variant === Variant.BackgroundLink
    || variant === Variant.BackgroundLinkDanger;
  const isNormalSize = size === Size.Normal;
  const isSmallSize = size === Size.Small;

  const buttonClassName = cn({
    [className]: className,
    [classes.button]: true,
    [classes.primary]: variant === Variant.Primary,
    [classes.secondary]: variant === Variant.Secondary,
    [classes.primaryLink]: variant === Variant.PrimaryLink && isNormalSize,
    [classes.secondaryLink]: variant === Variant.SecondaryLink && isNormalSize,
    [classes.tertiaryLink]: variant === Variant.TertiaryLink && isNormalSize,
    [classes.smallPrimaryLink]: variant === Variant.PrimaryLink && isSmallSize,
    [classes.smallSecondaryLink]: variant === Variant.SecondaryLink && isSmallSize,
    [classes.smallTertiaryLink]: variant === Variant.TertiaryLink && isSmallSize,
    [classes.dropDown]: variant === Variant.DropDown && isNormalSize,
    [classes.backgroundLink]: variant === Variant.BackgroundLink,
    [classes.backgroundLinkDanger]: variant === Variant.BackgroundLinkDanger,
    [classes.link]: variant === Variant.Link,
    [classes.linkDanger]: variant === Variant.LinkDanger,
    [classes.selected]: selected,
    [classes.fullWidth]: fullWidth,
  });

  const iconClassName = cn({
    [classes.filledButtonIcon]: isFilledVariant && isNormalSize,
    [classes.linkButtonIcon]: isLinkVariant && isNormalSize,
    [classes.smallButtonIcon]: isSmallSize,
  });

  const Icon: SvgIcon | undefined = icon;
  const PostIcon: SvgIcon | undefined = postIcon;
  const buttonGlobalClassname = variant === Variant.Primary ? GlobalClassNames.Primary : '';

  return (
    <button className={`${buttonClassName} ${buttonGlobalClassname}`} type={type || 'button'} {...buttonProps}>
      {Icon && <Icon className={iconClassName} />}
      {label}
      {PostIcon && <PostIcon className={iconClassName} />}
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
    </button>
  );
}

type ClassKey
  = 'button'
  | 'filledButtonIcon'
  | 'linkButtonIcon'
  | 'smallButtonIcon'
  | 'primary'
  | 'secondary'
  | 'primaryLink'
  | 'secondaryLink'
  | 'tertiaryLink'
  | 'smallPrimaryLink'
  | 'smallSecondaryLink'
  | 'smallTertiaryLink'
  | 'selected'
  | 'dropDown'
  | 'backgroundLink'
  | 'backgroundLinkDanger'
  | 'link'
  | 'linkDanger'
  | 'fullWidth'
  | 'buttonProgress'
  ;

const styles = withStyles<ClassKey>({
  button: {
    'padding': 0,
    'border': 'none',
    'cursor': 'pointer',
    '&:focus': { outline: 'none', boxShadow: 'none !important' },
    '&:disabled': {
      cursor: 'unset',
      opacity: 0.5,
    },
    'display': 'flex',
    'alignItems': 'center',
    'whiteSpace': 'nowrap',
    'width': 'fit-content',
    'position': 'relative',
  },
  filledButtonIcon: {
    height: '13px',
    marginRight: '8px',
    width: '13px',
  },
  linkButtonIcon: {
    height: '13px',
    marginRight: '8px',
    width: '13px',
  },
  smallButtonIcon: {
    height: '10px',
    marginRight: '4px',
    marginBottom: '1px',
  },
  primary: {
    fontFamily,
    'fontSize': '13px',
    'fontWeight': 500,
    'letterSpacing': 0.26,
    'lineHeight': '14px',
    'color': Colors.white,
    'backgroundColor': Colors.hubsyncBlue,
    'padding': '12px 10px',
    'borderRadius': 2,
    '&:hover:enabled': {
      color: Colors.darken(Colors.white, 0.08),
      backgroundColor: Colors.darken(Colors.hubsyncBlue, 0.08),
    },
  },
  secondary: {
    fontFamily,
    'fontSize': '13px',
    'fontWeight': 500,
    'letterSpacing': 0.26,
    'lineHeight': '14px',
    'color': Colors.slateTwo,
    'backgroundColor': Colors.athensGrey,
    'padding': '12px 10px',
    'borderRadius': 2,
    '&:hover:enabled': {
      color: Colors.darken(Colors.slateTwo, 0.08),
      backgroundColor: Colors.darken(Colors.athensGrey, 0.08),
    },
  },
  primaryLink: {
    fontFamily,
    'fontSize': '14px',
    'letterSpacing': '0.28px',
    'color': Colors.hubsyncBlue,
    '&:hover:enabled': { color: Colors.darken(Colors.hubsyncBlue, 0.3) },
  },
  secondaryLink: {
    fontFamily,
    'fontSize': '14px',
    'lineHeight': '16px',
    'color': Colors.brownishGrey,
    'textDecoration': 'underline',
    '&:hover:enabled': { color: Colors.darken(Colors.brownishGrey, 0.3) },
  },
  tertiaryLink: {
    fontFamily,
    'fontSize': '13px',
    'fontWeight': 500,
    'letterSpacing': '0.26px',
    'color': Colors.slateTwo,
    'padding': '6px 10px',
    '&:hover:enabled': { color: Colors.darken(Colors.slateTwo, 0.3) },
  },
  smallPrimaryLink: {
    fontFamily,
    'fontSize': '11px',
    'letterSpacing': 0.18,
    'lineHeight': '13px',
    'color': Colors.hubsyncBlue,
    '&:hover:enabled': { color: Colors.darken(Colors.hubsyncBlue, 0.3) },
  },
  smallSecondaryLink: {
    fontFamily,
    'fontSize': '11px',
    'letterSpacing': 0.18,
    'lineHeight': '13px',
    'color': Colors.brownishGrey,
    'textDecoration': 'underline',
    '&:hover:enabled': { color: Colors.darken(Colors.brownishGrey, 0.3) },
  },
  smallTertiaryLink: {
    fontFamily,
    'fontSize': '11px',
    'lineHeight': '13px',
    'color': Colors.slateTwo,
    '&:hover:enabled': { color: Colors.darken(Colors.slateTwo, 0.3) },
  },
  selected: {
    backgroundColor: Colors.skyBlue,
    color: Colors.hubsyncBlue,
  },
  fullWidth: {
    width: '100%',
    justifyContent: 'center',
  },
  dropDown: {
    fontFamily,
    'fontSize': '13px',
    'fontWeight': 500,
    'marginRight': '10px',
    'letterSpacing': '0.26px',
    'color': Colors.slateTwo,
    'padding': '6px 10px',
    'border': '1px solid #CFD8DC',
    '& svg:first-child': {
      marginRight: '8px',
    },
    '& svg:last-child': {
      marginLeft: '10px',
    },
    '& svg': {
      height: '14px',
      width: '14px',
    },
    '&$selected': {
      'backgroundColor': 'white',
      '& svg:first-child': {
        color: Colors.hubsyncBlue,
      },
    },
  },
  backgroundLink: {
    fontFamily,
    'fontSize': '13px',
    'letterSpacing': '0.2px',
    'lineHeight': '14px',
    'color': Colors.hubsyncBlue,
    'backgroundColor': Colors.ghostBlue,
    'padding': '8px',
    'borderRadius': 2,
    '&:hover:enabled': {
      backgroundColor: Colors.darken(Colors.ghostBlue, 0.08),
    },
  },
  backgroundLinkDanger: {
    fontFamily,
    'fontSize': '13px',
    'letterSpacing': '0.2px',
    'lineHeight': '14px',
    'color': Colors.tomato,
    'backgroundColor': Colors.tomatoFaded,
    'padding': '8px',
    'borderRadius': 2,
    '&:hover:enabled': {
      backgroundColor: Colors.darken(Colors.tomatoFaded, 0.08),
    },
  },
  link: {
    fontFamily,
    color: Colors.hubsyncBlue,
    fontSize: '13px',
    letterSpacing: '0.2px',
    lineHeight: '16px',
    padding: '3px 12px',
  },
  linkDanger: {
    fontFamily,
    color: Colors.darkPink,
    fontSize: '13px',
    letterSpacing: '0.2px',
    lineHeight: '16px',
    padding: '3px 12px',
  },
  buttonProgress: {
    color: Colors.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default styles(Button);
