import * as React from 'react';

import TextRenderer from '../components/TextRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { getGridRowMaxTextLines } from '../../AgGrid/utils';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import { fixUrl } from '../../../utilities/createUrl';

interface State {
  selected: boolean;
}

class UrlRenderer extends React.Component<FieldRendererProps, State> {
  constructor(props: FieldRendererProps) {
    super(props);
    this.state = { selected: false };
  }

  componentDidMount() {
    if (this.props.eGridCell) {
      this.props.eGridCell.onclick = (e) => {
        if (!this.state.selected) {
          e.preventDefault();
          this.setState({ selected: true });
        }
      };

      this.props.eGridCell.onblur = (e: any) => {
        if (e.relatedTarget && e.relatedTarget.tagName === 'A') {
          e.preventDefault();
        }
        /**
         * Delay state change to allow browser to handel a click
         */
        setTimeout(() => this.setState({ selected: false }), 200);
      };
    }
  }

  render() {
    const { type, fieldType, node, isGrid } = this.props;
    let { value } = this.props;

    if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
      return (
        <span
          className={getColumnHeaderClass(fieldType)}
          title={value}
        />
      );
    }

    // TODO: Find root cause of why an object is being passed as a value
    if (value !== null && typeof value === 'object') {
      value = value.toString();
    }

    const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;

    return (
      <TextRenderer lines={lines}>
        {this.state.selected ? <a href={fixUrl(value)} target="_blank" rel="noopener noreferrer" style={{ color: '#2d7ff9', borderBottom: '1px solid' }}>{value}</a> : <span style={{ borderBottom: '1px solid' }} >{value}</span>}
      </TextRenderer>
    );
  }
}

export default wrapCellRenderer(UrlRenderer);
