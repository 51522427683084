import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ICellRendererParams, Column } from 'ag-grid-community';
import * as actions from 'data/attachments/actions';
import { ItemAttachmentsState } from 'data/attachments/reducer';
import { attachmentsByIdFromAgGrid, isAttachmentsLoading } from 'data/attachments/attachments.selectors';
import { nodesInCreation } from 'data/collections/collections.selectors';
import { openDeleteAttachmentModal } from 'data/modals/actions';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import svgIcons from 'styles/svgIcons';
import { CommonNode } from 'types/response';
import { allowToModify } from 'utilities/permissions';


import ListOverflow from '../../ListOverflow/ListOverflow';
import { ChipsWrapper } from '../components/ChipsWrapper';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererProps } from '../Fields.types';
import { AttachmentMetadata } from '../getFieldMetadata';
import AttachmentItem from './AttachmentItem';
import { MoreChip } from './AttachmentsEditor.style';


import 'styles/components/attachment-renderer.scss';

interface StateProps {
  attachments?: ItemAttachmentsState;
  isAttachmentsLoading: boolean;
  nodesInCreation: string[];
  permissions: string[];
  allowRequest: boolean;
}

interface DispatchProps {
  onDeleteAttachment(uri: string, id: string): void;
  openPreviewCarousel: typeof actions.openPreviewAttachments;
}

interface OwnProps {
  column: Column;
}

const selectors = createStructuredSelector<ReduxState, ICellRendererParams, StateProps>({
  attachments: attachmentsByIdFromAgGrid,
  isAttachmentsLoading,
  nodesInCreation,
  permissions: (state: ReduxState) => state.collections.collections.permissions,
  allowRequest: (state: ReduxState) => typeof state.attachments.allowRequest === 'boolean' ? state.attachments.allowRequest : true,
});

const mapDispatchToProps: DispatchProps = {
  onDeleteAttachment: openDeleteAttachmentModal,
  openPreviewCarousel: actions.openPreviewAttachments,
};

const enhance = connect(selectors, mapDispatchToProps);

type Props = FieldRendererProps<CommonNode> & StateProps & DispatchProps & AttachmentMetadata & OwnProps;

interface State {
  key: number;
}

class AttachmentRenderer extends React.Component<Props, State> {
  state = {
    key: 1,
  };

  public componentDidUpdate(prevProps: Props): void {
    const prevIds = (prevProps.attachments && prevProps.attachments.ids) || [];
    const currentIds = (this.props.attachments && this.props.attachments.ids) || [];
    if (prevIds.length !== currentIds.length) {
      this.setState({ key: currentIds.length + 1 });
    }
  }

  public render(): JSX.Element | null {
    const { isAttachmentsLoading } = this.props;


    if (isAttachmentsLoading) {
      return <CircularProgress size="1.5rem"/>;
    }

    const attachmentIds = this.getAttachmentIds();
    if (!attachmentIds.length) {
      return null;
    }

    if (this.props.isGroup) {
      return (
        <div className="group-attachment-wrapper">
          <svgIcons.Attachment width="15" height="15" />
          <span>{attachmentIds.length}</span>
        </div>
      );
    }

    return (
      <ChipsWrapper className="attachment-renderer-wrapper">
        <div className="attachment-renderer-inner-wrapper">
          <ListOverflow
            key={this.state.key}
            renderMore={count => <MoreChip label={`+${count}`} style={{ marginRight: 0 }} />}
          >
            {attachmentIds.map(this.renderAttachment)}
          </ListOverflow>
          <div className="attachment-renderer-hidden-options">
            {attachmentIds.map(this.renderAttachment)}
            <MoreChip label={`+${attachmentIds.length}`} />
          </div>
        </div>
      </ChipsWrapper>
    );
  }

  private getAttachmentIds = (): string[] => {
    const attachmentId: string[] = [];
    if (this.props.attachments && this.props.attachments.byId) {
      Object.keys(this.props.attachments.byId).forEach((id: string) => {
        if (this.props.attachments && this.props.attachments.byId && this.props.column) {
          const attachment = this.props.attachments.byId[id];
          const attachmentFieldID = `${this.props.column && attachment['references']['fieldID']}`;
          const colId = this.props.column.getColDef().colId?.replace('fields.', '');
          if (attachmentFieldID === colId) {
            attachmentId.push(attachment._id);
          }
        }
      });
    }

    return attachmentId;
  };

  private renderAttachment = (id: string, i: number): JSX.Element | null => {
    const { attachments, previewBehavior } = this.props;
    if (!attachments) {
      return null;
    }
    const attachment = attachments.byId[id];
    if (!attachment) return null;

    // @ts-ignore
    const readOnly = this.props.column && this.props.column.getColDef().readOnly;
    return (
      <AttachmentItem
        key={`${id}${i}`}
        attachment={attachment}
        previewBehavior={previewBehavior}
        onDelete={() => this.handleDelete(id)}
        permissions={this.props.permissions}
        readOnly={readOnly}
        onClick={() => this.handleItemClick(i)}
      />
    );
  };

  private handleDelete = (id: string): void => {
    const { data, attachments } = this.props;

    const attachmentId = attachments && attachments.ids.includes(id);

    if (attachmentId) {
      this.props.onDeleteAttachment(id, data.apiURI);
    }
  };

  private handleItemClick = (index: number) => {
    const { openPreviewCarousel, column, permissions } = this.props;

    openPreviewCarousel?.({
      index,
      node: this.props.data,
      enableEdit: allowToModify(column, permissions),
      fieldId: '',
    });
  };
}

export default wrapCellRenderer(enhance(AttachmentRenderer));
