import { CSSProperties } from '@material-ui/core/styles/withStyles';

import * as Colors from 'styles/colors';

type ClassKey =
  'dropdown'
  | 'collectionIcon'
  | 'searchBox'
  | 'searchIcon'
  | 'searchInput'
  | 'selectedItemIcon'
  | 'unselectedItemIcon'
  | 'databaseListItem'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  dropdown: {
    width: '200px',
    maxHeight: '500px',
    overflow: 'auto',
  },
  collectionIcon: {
    color: Colors.slateTwo,
  },
  searchBox: {
    'borderStyle': 'solid',
    'borderWidth': '1px',
    'borderColor': Colors.lightBlueGrey,
    '&:focus-within': { borderColor: Colors.hubsyncBlueSemiTransparent },
  },
  searchInput: {
    marginLeft: '5px',
    padding: '2px',
    fontSize: '12px',
  },
  searchIcon: {
    height: '12px',
    color: Colors.slateTwo,
    margin: '6px',
  },
  selectedItemIcon: {
    color: Colors.turquoise,
    marginTop: '3px',
  },
  unselectedItemIcon: {
    color: 'transparent',
  },
  databaseListItem: {
    alignItems: 'flex-start',
  },
};
