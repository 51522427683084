import action from 'utilities/action-creator';
import produce, { Draft } from 'immer';
import { takeLatest, put, call } from 'redux-saga/effects';
import { post } from 'utilities/httpRequests';
import { handleError } from 'utilities/saga';

/**
 * CONSTANTS
 */
const SEND_GRID_CELL_EDITING_STARTED_MESSAGE = 'GRID-EDITORS/SEND_GRID_CELL_EDITING_STARTED_MESSAGE';
const SET_CELL_USER_EDITOR = 'GRID-EDITORS/SET_CELL_USER_EDITOR';
const REMOVE_CELL_USER_EDITOR = 'GRID-EDITORS/REMOVE_CELL_USER_EDITOR';
const SET_ERROR = 'GRID-EDITORS/SET_ERROR';
const CLEAR = 'GRID-EDITORS/CLEAR';
const SEND_GRID_CELL_EDITING_STOPPED_MESSAGE = 'GRID-EDITORS/SEND_GRID_CELL_EDITING_STOPPED_MESSAGE';

/**
 * TYPES
 */
export interface SendMessageInput {
  itemId: number;
  columnKey: string;
  references: {
    workspaceID: string;
    databaseID?: string;
    taskDatabaseID?: string;
    sheetID: string;
    viewID: string;
  };
}

export interface SetCellUserEditorInput {
  itemId: number;
  columnKey: string;
  userId: string;
  userDisplayName: string;
  color: string;
}

export interface RemoveCellUserEditorInput {
  itemId: number;
  columnKey: string;
  userId: string;
}

export interface UserEditor {
  userId: string;
  userDisplayName: string;
  color: string;
}

export interface UserPositionInGridMap {
  editors: Draft<Record<string, UserEditor>>;
}

interface UserPositionInGrid {
  position: string;
}

export interface State {
  gridCellIndexUserEditorsMap: Draft<Record<string, UserPositionInGridMap>>;
  editorsPositions: Draft<Record<string, UserPositionInGrid>>;
}

export const initialState: State = {
  gridCellIndexUserEditorsMap: {},
  editorsPositions: {},
};

/**
 * REDUCER
 */
export const reducer = (state = initialState, action: { payload: SetCellUserEditorInput; type: string }): State => {
  switch (action.type) {
    case SET_CELL_USER_EDITOR: {
      return produce(state, draft => {
        const { itemId, columnKey, userId, userDisplayName, color } = action.payload;
        const indexKey = `${itemId}-${columnKey}`;

        const exists = Object.keys(state.gridCellIndexUserEditorsMap)
          .map((key: string) => state.gridCellIndexUserEditorsMap[key].editors[userId] && key).filter(x => x);

        if (exists) {
          exists.forEach((key: string) => {
            delete draft.gridCellIndexUserEditorsMap[key].editors[userId];
            delete draft.editorsPositions[userId];
            if (Object.keys(draft.gridCellIndexUserEditorsMap[key].editors).length === 0) {
              delete draft.gridCellIndexUserEditorsMap[key];
            }
          });
        }

        const currentEditors = draft.gridCellIndexUserEditorsMap[indexKey]?.editors as Draft<Record<string, UserEditor>> || [];
        const userEditors: Record<string, UserEditor> = {
          [userId]: {
            userId,
            userDisplayName,
            color,
          },
        };
        draft.editorsPositions[userId] = { position: indexKey };
        draft.gridCellIndexUserEditorsMap[indexKey] = { editors: { ...currentEditors, ...userEditors as Draft<Record<string, UserEditor>> } };
      });
    }
    case REMOVE_CELL_USER_EDITOR: {
      return produce(state, draft => {
        const { itemId, columnKey, userId } = action.payload;
        const indexKey = `${itemId}-${columnKey}`;
        delete draft.editorsPositions[userId];
        delete draft.gridCellIndexUserEditorsMap[indexKey];
      });
    }
    default:
      return state;
  }
};

/**
 * ACTIONS
 */
export const actions = {
  request: (payload: SendMessageInput) => action(SEND_GRID_CELL_EDITING_STARTED_MESSAGE, { payload }),
  setCellUserEditor: (payload: SetCellUserEditorInput) => action(SET_CELL_USER_EDITOR, { payload }),
  removeCellUserEditor: (payload: RemoveCellUserEditorInput) => action(REMOVE_CELL_USER_EDITOR, { payload }),
  cellEditingStopped: (payload: SendMessageInput) => action(SEND_GRID_CELL_EDITING_STOPPED_MESSAGE, { payload }),
  setError: (error) => action(SET_ERROR, { payload: error }),
  clear: () => action(CLEAR),
};

/**
 * SAGAS
 */
export const sagas = {
  * request(action: { payload: SendMessageInput; type: string }) {
    try {
      const {
        itemId,
        columnKey,
        references: {
          workspaceID,
          databaseID,
          taskDatabaseID,
          sheetID,
          viewID,
        },
      } = action.payload;
      yield (post({ url: `/workspaces/${workspaceID}/databases/${databaseID || taskDatabaseID}/sheets/${sheetID}/views/${viewID}/grid-cell-editing-started?api_version=2`, data: {
        itemId,
        columnKey,
      } }));
    } catch (error) {
      yield call(handleError, error);
      yield put(actions.setError(error));
    }
  },

  * cellEditingStopped(action: { payload: SendMessageInput; type: string }) {
    try {
      const { itemId, columnKey, references: { workspaceID, databaseID, sheetID, viewID, taskDatabaseID } } = action.payload;
      yield (
        post({
          url: `/workspaces/${workspaceID}/databases/${databaseID || taskDatabaseID}/sheets/${sheetID}/views/${viewID}/grid-cell-editing-stopped?api_version=2`,
          data: { itemId, columnKey },
        })
      );
    } catch (error) {
      yield put(actions.setError(error));
    }
  },
};

export const watcher = function* w() {
  yield takeLatest(SEND_GRID_CELL_EDITING_STARTED_MESSAGE, sagas.request);
  yield takeLatest(SEND_GRID_CELL_EDITING_STOPPED_MESSAGE, sagas.cellEditingStopped);
};
