import * as React from 'react';
import Modal from 'components/Modals/Modal';
import ModalHeader from 'components/Modals/Modal/Header';
import ModalContent from 'components/Modals/Modal/Content';
import svgIcons from 'styles/svgIcons';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { menuItemContentWithSwitcher } from 'components/TableHeaderTools/modules/FilteringOption/ChoiceFilterOptionInput';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { useEffect } from 'react';
import { AccountNode } from 'types/response/accountNode';
import DayPicker from 'react-day-picker/DayPicker';
import Button, { Size, Variant as ButtonVariant } from 'components/Button';
import moment from 'moment';
import { Popover } from '@material-ui/core';
import { Notification, RemindTypes } from './DatePickerNotify';
import AccountChip from 'components/AccountChip';
import { RoleNames, User } from 'data/users/users.types';
import { References } from 'data/reminders/reminders';
import { actions as remindersActions, Reminder as ReminderType } from 'data/reminders/reminders';
import { Role } from 'data/accounts/types';
import { getActiveWorkspaceUsers } from 'data/accounts/selectors';
import { textWidth } from 'utilities/text';

export enum Units {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum EventTypes {
  Before = 'before',
  After = 'after',
  On = 'on',
}

enum ReminderModes {
  BROWSE = 'browse',
  FETCHING = 'fetching',
  DATE_IN_PAST_ERROR = 'date_in_the_past',
}

interface OwnProps {
  primaryFieldName?: string;
  onClose: () => void;
  dateValue: string;
  showActiveReminders: () => void;
  selectedReminder: Notification | null;
  references: References;
  onNotifyDateChange: (timestamp: number) => void;
  bulkReminders?: Array<ReminderType>;
}

type Props = OwnProps;

export const isValidDate = (value: string) => {
  if (!moment(value).isValid()) return false;
  const dateValue = value.split('/');
  return [1, 2].includes(dateValue[0]?.length) && [1, 2].includes(dateValue[1]?.length) && dateValue[2]?.length === 4;
};

let targetPopupRef;

const Reminder: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [searchUserQuery, setSearchQuery] = React.useState<string>('');
  const [remindUsers, setReminderUsers] = React.useState<string[]>([]);
  const [isUserDropdownOpen, setDropdownState] = React.useState<boolean>(false);
  const workspaceUsers = useSelector((state: ReduxState) => getActiveWorkspaceUsers(state));
  const [userChoices, setUserChoices] = React.useState<{ key: { label: string }} | object>({});
  const [reminder, setReminder] = React.useState<Notification>({ qty: 1, unit: Units.Day, eventType: EventTypes.Before, message: '', isScheduled: true, ...props.selectedReminder });
  const [dateValue, setDateValue] = React.useState<string>(props.dateValue || moment().format('MM/DD/YYYY'));
  const [dateInputValue, setDateInputValue] = React.useState<string>('');
  const [showDatePicker, setDatePickerState] = React.useState(false);
  const [mode, setMode] = React.useState<ReminderModes>(ReminderModes.BROWSE);
  const user: User = useSelector((state: ReduxState) => state.users.user);
  const permissions: string[] = user.permissions || [];
  const workspaceId = useSelector((state: ReduxState) => state.workspaces.current);
  const role = user?.roles?.find((role: Role) => role.workspaceId === workspaceId) as unknown as Role;

  const modalTitle = React.useMemo( () => {
    if ( !props.references.rowID) return 'New Reminder';
    return props.bulkReminders ? ( props.selectedReminder ? 'Edit Bulk Reminder' : 'New Bulk Reminder' ) : ( props.selectedReminder ? 'Edit Reminder' : 'New Reminder' );
  }, [props]);

  const primaryFieldNameRender = React.useMemo( () => (
    props.primaryFieldName ? (
      <div className="reminder-value reminder-cell-name">{props.primaryFieldName}</div>
    ) : (
      <div className="reminder-alert-container warning">
        <svgIcons.Info width={14} />
        <div>{props.references.rowID ? 'Please set a value in the primary field' : 'When the new record is saved you will see the primary field value here.'}</div>
      </div>
    )
  ), [props.primaryFieldName, props.references.rowID]);

  useEffect(() => {
    setInitialState();
    createUserChoices();
  }, []);

  const setInitialState = () => {
    setReminder({ ...reminder });
    setReminderUsers(reminder.remindUsers || (role?.name === RoleNames.Editor ? [user.id] : []));
  };

  const createUserChoices = () => {
    if (!Object.keys(userChoices).length) {
      const choices = {};
      workspaceUsers.forEach((user: AccountNode) => {
        choices[user.id] = { label: user.displayName, color: user.backgroundColor };
      });
      setUserChoices(choices);
    }
  };

  useEffect(() => {
    validateDate();
  }, [reminder.qty, reminder.eventType, reminder.unit, dateValue]);

  const onChange = (_: React.ChangeEvent<HTMLSelectElement>, value: { key }) => {
    if (!value.key) return;
    if (remindUsers.includes(value.key)) {
      setReminderUsers(remindUsers.filter(id => id !== value.key));
      return;
    }
    setReminderUsers([...remindUsers, value.key]);
  };

  const onDropdownClose = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDropdownState(false);
    event.stopPropagation();
  };

  const onDropdownOpen = () => {
    setSearchQuery('');
  };

  const onSearchQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value);
    event.stopPropagation();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setReminder({ ...reminder, [event.target.name]: event.target.value });
    event.stopPropagation();
  };

  const onEventTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onInputChange(event);
  };

  const onUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onInputChange(event);
  };

  const validateDate = () => {
    const subtracted = moment(dateValue)[reminder.eventType === EventTypes.After ? 'add' : 'subtract'](reminder.qty, reminder.unit as moment.unitOfTime.DurationConstructor).format('MM/DD/YYYY');
    const isInThePast = moment(subtracted).isBefore(moment(), Units.Day);
    const isTheSame = moment(dateValue).isSame(moment(), Units.Day);
    if (!reminder?._id && ((reminder.eventType !== EventTypes.On && isInThePast) || (reminder.eventType === EventTypes.On && isInThePast && !isTheSame))) {
      setMode(ReminderModes.DATE_IN_PAST_ERROR);
      return;
    }
    setMode(ReminderModes.BROWSE);
  };

  const onQtyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = parseInt(event.target.value) || 1;
    setReminder({ ...reminder, [event.target.name]: value });
    event.stopPropagation();
  };

  const filteredChoices = (() => {
    const filteredChoices = {};
    Object.keys(userChoices).forEach((key: string) => {
      if (userChoices[key].label.toLowerCase().includes(searchUserQuery.toLowerCase())) {
        filteredChoices[key] = userChoices[key];
      }
    });
    return filteredChoices;
  })();

  const onDayChange = (date: Date) => {
    const dateValue = moment(date).format('MM/DD/YYYY');
    setDateValue(dateValue);
    setDateInputValue(dateValue);
    toggleDatePicker();
  };

  const onDateInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDateInputValue(event.target.value);
    if (isValidDate(event.target.value) && isValidDate(event.target.value)) {
      setDateValue(event.target.value);
    }
    event.stopPropagation();
  };

  const toggleDatePicker = () => {
    setDatePickerState(!showDatePicker);
  };

  const setTodayDate = () => {
    const dateValue = moment().format('MM/DD/YYYY');
    setDateInputValue(dateValue);
    setDateValue(dateValue);
  };

  const saveReminder = () => {
    setMode(ReminderModes.FETCHING);

    const data: ReminderType = {
      _id: undefined,
      recordID: props.references.rowID,
      model: 'item',
      eventType: reminder?.eventType || '',
      isScheduled: reminder?.isScheduled || false,
      qty: reminder?.qty || 0,
      unit: reminder?.unit || '',
      remindUsers,
      remindType: permissions.includes('reminders:create') ? RemindTypes.SharedUsers : RemindTypes.Me,
      message: reminder.message || '',
      timestamp: getDateWithUTCOffset(dateValue),
      references: {
        workspaceID: props.references.workspaceID,
        databaseID: props.references.databaseID,
        sheetID: props.references.sheetID,
        fieldID: props.references.fieldID,
        fieldName: props.references.fieldName,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      version: undefined,
      createdBy: undefined,
      createdAt: undefined,
    };

    if (props?.bulkReminders) {
      if (props?.selectedReminder) {
        for ( const property in props.selectedReminder ) {
          if (data?.[property]) props.selectedReminder[property] = data[property];
        }
      } else {
        props.bulkReminders.push(data);
      }
      props.showActiveReminders();
      return;
    }

    props.onNotifyDateChange(moment(dateValue).toDate().getTime());

    if (!reminder._id) {
      dispatch(remindersActions.requestPost(data));
      return;
    }

    dispatch(remindersActions.requestPatch({ id: reminder._id, data: { ...data, version: reminder.version } }));
    props.showActiveReminders();
  };

  const selectAll = () => {
    if (Array.isArray(workspaceUsers)) {
      setReminderUsers(
        workspaceUsers.filter(user => user.displayName?.toLowerCase?.().includes(searchUserQuery.toLowerCase())).map(user => user.id),
      );
    }
  };

  const deSelectAll = () => {
    setReminderUsers([]);
  };

  const renderSelectedAccounts = (accounts: AccountNode[]): (selected: string[]) => (JSX.Element | null)[] => {
    const moreUsersWidth = 25;
    const maxContainerWidth = 250;
    let width = 0; let showingItems = 0; let moreUsersVisible = false;
    return (selected: string[]) => selected.map((value: string, index: number) => {
      if (index === 0) {
        width = showingItems = 0;
        moreUsersVisible = false;
      }
      const account = accounts.find(account => account.id === value);
      if (!account || moreUsersVisible) return null;
      width += textWidth(account.displayName);
      if (width > maxContainerWidth || index < remindUsers.length - 1 && width + moreUsersWidth > maxContainerWidth) {
        moreUsersVisible = true;
        return (
          <div key={index} className="reminder-show-more-users-container">
            <svgIcons.Add />
            <span key={index} className='reminder-show-more-users'>
              {remindUsers.length - showingItems}
            </span>
          </div>
        );
      }
      showingItems++;
      return <AccountChip key={index} accountId={account.id} />;
    });
  };

  const fieldName = (
    <div className="reminder-row">
      <div className="reminder-row-label">Name</div>
      {primaryFieldNameRender}
    </div>
  );

  return (
    <Modal onClose={props.onClose}>
      <ModalHeader onClose={props.onClose}>
        { modalTitle }
      </ModalHeader>
      <ModalContent>
        <div onClick={() => showDatePicker ? toggleDatePicker() : null}>
          {props.references.rowID ? (
            <>
              {!props?.bulkReminders && (
                <>{ fieldName }</>
              )}
            </>
          ) : (
            <>{fieldName}</>
          )}
          <div className="reminder-row">
            <div className="reminder-row-label">{props.references.fieldName}</div>
            <div className="reminder-value">
              <div className={`reminder-input-container reminder-date-input ${showDatePicker ? 'active-input' : ''}`}>
                <span className="reminder-date-value" onClick={toggleDatePicker} ref={ref => targetPopupRef = ref}>{dateValue}</span>
                <Popover open={showDatePicker} anchorEl={targetPopupRef} className="reminder-input-popup" disableAutoFocus disableEnforceFocus anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                  <div className="DayPickerInput-Overlay reminder-day-picker-container" onClick={event => event.stopPropagation()}>
                    <div className="datepicker-date-input-container">
                      <input type="text" value={dateInputValue || dateValue} onChange={onDateInputChange} />
                      <Button label="Today" variant={ButtonVariant.BackgroundLink} size={Size.Normal} onClick={setTodayDate} />
                    </div>
                    <DayPicker
                      canChangeMonth
                      className="reminder-day-picker"
                      onDayClick={onDayChange}
                      month={moment(dateValue).toDate()}
                      selectedDays={moment(dateValue).toDate()}
                    />
                  </div>
                </Popover>
                <svgIcons.Dropdown className="reminder-date-icon" onClick={toggleDatePicker} />
              </div>
            </div>
          </div>
          <div className="reminder-row">
            <div className="reminder-row-label">Send reminder to</div>
            {role?.name === RoleNames.Editor && (
              <div className="reminder-value">
                <AccountChip accountId={user.id} showName />
              </div>
            )}
            {role?.name !== RoleNames.Editor && (
              <div className="reminder-value expand" onClick={() => setDropdownState(true)}>
                <div className="reminder-input-container">
                  <Select
                    key="reminderUsers"
                    name="select"
                    multiple
                    value={remindUsers}
                    onChange={onChange}
                    renderValue={renderSelectedAccounts(workspaceUsers)}
                    disableUnderline
                    fullWidth
                    open={isUserDropdownOpen}
                    onOpen={onDropdownOpen}
                    onClose={onDropdownClose}
                    className="reminder-select-users"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    <SearchBar value={searchUserQuery} size={SearchBarSize.Small} onChange={onSearchQuery} autoFocus placeholder="Find a user" className="hubsync-menu-searchbar" />
                    {Object.keys(filteredChoices).map((key: string) => {
                      return (
                        <MenuItem key={key} value={filteredChoices[key].label}>
                          {menuItemContentWithSwitcher(remindUsers, key, <AccountChip accountId={key} showName className="reminder-account-chip" />)}
                        </MenuItem>
                      );
                    })}
                    <div className="reminder-select-deselect-container">
                      <span className="reminder-deselect-all" onClick={deSelectAll}>Deselect All</span>
                      <span className="reminder-select-all" onClick={selectAll}>Select All</span>
                    </div>
                  </Select>
                </div>
              </div>
            )}
          </div>
          <div className="reminder-row">
            <div className="reminder-row-label">When</div>
            <div>
              <div className="reminder-row-value">
                <div className="reminder-input-container">
                  <input type="number" min={1} className="reminder-amount" value={reminder.eventType === EventTypes.On ? '' : reminder.qty} name="qty" onChange={onQtyChange} disabled={reminder.eventType === EventTypes.On} />
                </div>
                <Select value={reminder.eventType === EventTypes.On ? '' : reminder.unit} disableUnderline className="reminder-select reminder-unit" name="unit" onChange={onUnitChange} disabled={reminder.eventType === EventTypes.On} >
                  {Object.values(Units).map(unit => {
                    return (
                      <MenuItem className="date-picker-notify-select-item" key={unit} value={unit}>{unit}{reminder.qty === 1 ? '' : 's'}</MenuItem>
                    );
                  })}
                </Select>
                <Select value={reminder.eventType} disableUnderline className="reminder-select reminder-type" name="eventType" onChange={onEventTypeChange}>
                  {Object.values(EventTypes).map(item => {
                    return (
                      <MenuItem className="date-picker-notify-select-item" key={item} value={item}>{item}</MenuItem>
                    );
                  })}
                </Select>
                <div className="date-picker-date-value">{dateValue}</div>
              </div>
              {mode === ReminderModes.DATE_IN_PAST_ERROR && (
                <div className="reminder-alert-container error">
                  <svgIcons.Limited2 width={14} />
                  <div>{'You can\'t set a reminder in the past.'}</div>
                </div>
              )}
            </div>
          </div>
          <div className="reminder-row">
            <div className="reminder-row-label">Add a message (optional)</div>
            <div className="reminder-value expand">
              <textarea className="reminder-message" name="message" onChange={onInputChange} value={reminder.message} />
            </div>
          </div>
        </div>
      </ModalContent>
      <div className="reminder-action-buttons">
        <Button label="Cancel" variant={ButtonVariant.SecondaryLink} onClick={props.showActiveReminders} />
        <Button
          label={reminder?._id ? 'Update' : 'Save'}
          variant={ButtonVariant.Primary}
          onClick={saveReminder}
          disabled={mode === ReminderModes.FETCHING || remindUsers.length === 0 || mode === ReminderModes.DATE_IN_PAST_ERROR}
        />
      </div>
    </Modal>
  );
};

export const getDateWithUTCOffset = (dateValue: string | number): number => {
  let timestamp = moment(dateValue).toDate().getTime();
  if (new Date().getTimezoneOffset() < 0) {
    timestamp = moment(timestamp).add(- (new Date().getTimezoneOffset()), 'minutes').toDate().getTime();
  }
  return timestamp;
};

export default Reminder;
