export default function checkboxUnchecked() {
  const svgUnchecked = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgUnchecked.setAttribute('class', 'boolean-renderer--wrapper--checkbox-unchecked');
  svgUnchecked.setAttribute('height', 10);
  svgUnchecked.setAttribute('width', 10);
  svgUnchecked.setAttribute('viewBox', '0 0 10 10');

  const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.id = 'checkbox-unchecked';
  path.setAttributeNS(null, 'd', 'M8.889 1.111V8.89H1.11V1.11H8.89zm0-1.111H1.11C.5 0 0 .5 0 1.111V8.89C0 9.5.5 10 1.111 10H8.89C9.5 10 10 9.5 10 8.889V1.11C10 .5 9.5 0 8.889 0z');
  defs.appendChild(path);
  svgUnchecked.appendChild(defs);

  const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#checkbox-unchecked');
  use.setAttributeNS(null, 'fill', 'currentColor');
  use.setAttributeNS(null, 'fill-rule', 'evenodd');
  svgUnchecked.appendChild(use);
  return svgUnchecked;
}
