import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey = 'wrapper' | 'divider' | 'dividerText';

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: colors.white,
    width: '100%',
    maxWidth: '100%',
  },

  divider: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'position': 'relative',
    'margin': '37px 0',
    '&:after': {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      content: '""',
      height: '1px',
      margin: 'auto',
      position: 'absolute',
      backgroundColor: colors.ebb,
    },
  },

  dividerText: {
    zIndex: 1,
    position: 'relative',
    backgroundColor: colors.white,
    padding: '0 10px',
    fontSize: '11px',
    color: colors.charocalGrey,
  },
};
