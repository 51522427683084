import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

type ClassKey
  = 'notificationsIconWrapper'
  | 'notificationsCounter'
  | 'icon'
  | 'title'
  | 'notificationItem'
  | 'notificationItemLinkLayer'
  | 'notificationItemText'
  | 'notificationItemTagText'
  | 'notificationAction'
  | 'notificationWorkspace'
  | 'dotIcon'
  | 'notificationUnread'
  | 'mentionMessage'
  | 'notificationContentContainer'
  | 'notificationUnreadButton'
  | 'notificationDeleteButton'
  | 'notificationFileName'
  ;

const notificationsItemStyles: Record<ClassKey, CSSProperties> = {
  notificationsIconWrapper: {
    position: 'relative',
  },
  notificationsCounter: {
    height: 12,
    minWidth: 12,
    backgroundColor: Colors.pinkishOrange,
    position: 'absolute',
    fontSize: 10,
    textAlign: 'center',
    display: 'flex',
    right: 14,
    color: Colors.white,
    top: -3,
    borderRadius: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 2,
    paddingLeft: 2,
  },
  icon: {
    width: '21px',
    height: '21px',
    margin: '0 16px',
    opacity: 0.5,
  },
  title: {
    color: Colors.charocalGrey,
    fontWeight: 400,
    flex: 1,
  },
  notificationItem: {
    position: 'relative',
    padding: '15px 31px',

    ['&:hover $notificationDeleteButton']: {
      display: 'block',
    },
  },
  notificationItemLinkLayer: {
    cursor: 'pointer',
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  notificationItemText: {
  },
  notificationItemTagText: {
    color: Colors.brightBlue,
    cursor: 'pointer',
  },
  notificationAction: {
    justifyContent: 'space-between',
    alignItems: 'center',
    transform: 'none',
    display: 'flex',
    right: -40,
    height: 16,
    width: 30,
    zIndex: 2,
    top: 3,
  },
  notificationUnreadButton: {
    backgroundColor: '#E8384F',
    borderRadius: '100%',
    overflow: 'hidden',
    outline: 'none',
    border: 'none',
    lineHeight: 0,
    padding: 0,
    margin: 0,
    height: 8,
    width: 8,
  },
  notificationDeleteButton: {
    color: Colors.blueyGrey,
    display: 'none',
    height: 16,
    width: 15,
    padding: 0,
  },
  notificationContentContainer: {
    position: 'relative',
  },
  notificationWorkspace: {
    display: 'flex',
    paddingTop: 15,
    justifyContent: 'space-between',
  },
  dotIcon: {
    zIndex: 2,
    height: 9,
    width: 9,
    borderRadius: 9,
    backgroundColor: Colors.pinkyRed,
  },
  notificationUnread: {
    backgroundColor: Colors.lightBlueTransparent,
  },
  mentionMessage: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 14,
    color: Colors.dark,
  },
  notificationFileName: {
    fontWeight: 'bold',
  },
};

export default notificationsItemStyles;
