import * as React from 'react';

import { IHeaderParams } from 'ag-grid-community';
import { filtersSelector, sortSelector } from 'data/grid-options/gridOptions.selector';
import { connect } from 'react-redux';
import { State } from 'reducers';
import { SvgIcon } from 'types/common';
import { FilterModel, SortModel, SortModelState, SortOperator } from 'types/gridOptions';

import View, { SortStatus } from './view';

export interface FieldColumnHeaderParams {
  ColumnIcon?: SvgIcon | null;
  hideColumnIcon?: boolean;
}

interface StateProps {
  filterModel: FilterModel;
  sortModel: SortModelState;
}

export type Props = IHeaderParams & FieldColumnHeaderParams & StateProps;

export class FieldColumnHeader extends React.Component<Props> {
  private menuButtonRef: React.RefObject<HTMLSpanElement>;

  constructor(props: Props) {
    super(props);

    this.menuButtonRef = React.createRef();
  }

  public render(): JSX.Element {
    const { ColumnIcon, hideColumnIcon, displayName, column, filterModel, sortModel } = this.props;
    const colDef = column.getColDef();
    const isFilterActive = !!filterModel.searchFilters[column.getColId()] || !!filterModel?.regularFilters?.find(filter => filter.columnId === column.getColId());
    const sort: SortStatus = this.convertSortStatus(sortModel.byId[column.getColId()]);
    const isPrimary = colDef.refData && colDef.refData.isPrimary ? true : false;
    const isLocked = !!(colDef.refData?.lock);
    const isPinned = column.isPinned();

    return (
      <View
        displayName={displayName}
        isFilterActive={isFilterActive}
        menuButtonRef={this.menuButtonRef}
        sort={sort}
        suppressSorting={colDef.suppressSorting}
        suppressMenu={colDef.suppressMenu}
        ColumnIcon={ColumnIcon}
        hideColumnIcon={hideColumnIcon}
        openMenuClick={this.openMenu}
        onSortClick={this.sortClick}
        isPrimary={isPrimary}
        isLocked={isLocked}
        isPinned={isPinned}
      />
    );
  }

  private openMenu = (): void => {
    if (this.props.enableMenu) {
      if (this.menuButtonRef.current) {
        this.props.showColumnMenu(this.menuButtonRef.current);
      } else {
        console.warn('Tried to open menu before menu was rendered');
      }
    }
  };

  private sortClick = (): void => {
    if (this.props.enableSorting) {
      this.props.progressSort(true);
    }
  };

  private convertSortStatus = (sortModel: SortModel | undefined): SortStatus => {
    if (!sortModel) return SortStatus.None;

    switch (sortModel.sort) {
      case SortOperator.Asc:
        return SortStatus.Ascending;
      case SortOperator.Desc:
        return SortStatus.Descending;
    }
  };
}

const FieldColumnHeaderWithRef = React.forwardRef(
  (props: Props, ref: React.RefObject<FieldColumnHeader>) => <FieldColumnHeader ref={ref} {...props} />,
);

const mapStateToProps = (state: State): StateProps => ({
  filterModel: filtersSelector(state),
  sortModel: sortSelector(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(FieldColumnHeaderWithRef);
