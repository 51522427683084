import * as React from 'react';
import moment from 'moment';
import { ICellRendererParams, ColDef } from 'ag-grid-community';
import Checkbox from 'components/Checkbox';
import Star from 'components/NodeFieldData/rating/Star';
import * as Data from 'components/Fields/data';
import { fixUrl } from 'utilities/createUrl';
import AccountChip from 'components/AccountChip/AccountChip';
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface ImportColDef extends ColDef {
  choices?: {
    [key: string]: {
      label: string;
      color: string;
    };
  };
  dateFormat: string;
  currencySymbol: string;
  precision: string;
  subtype: string;
}

const CellRenderer = (props: ICellRendererParams) => {
  const column = props.column.getColDef() as ImportColDef;
  switch (column.type) {
    case Data.FieldType.Checkbox:
      return <Checkbox selected={props.value} />;
    case Data.FieldType.SingleSelect: {
      if (!props.value) return null;
      const value = Array.isArray(props.value) ? props.value.toString() : props.value;
      const color = column.choices && column.choices[value] && column.choices[value].color;
      return (
        <React.Fragment>
          <div className="import-field-choice-bullet" style={{ backgroundColor: color }} />
          {props.value}
        </React.Fragment>
      );
    }
    case Data.FieldType.MultiSelect: {
      if (!props.value) return null;
      return (
        <React.Fragment>
          {props.value.map(value => {
            const color = column.choices && column.choices[value] && column.choices[value].color;
            return (
              <span key={value} className="import-choice-container">
                <div className="import-field-choice-bullet" style={{ backgroundColor: color }} />
                {value}
              </span>
            );
          })}
        </React.Fragment>
      );
    }
    case Data.FieldType.Person:
      if (!props.value) return null;
      return <AccountChip accountId={props.value} showName />;
    case Data.FieldType.Date: {
      const format = column.dateFormat || Data.DEFAULT_DATE_FORMAT;
      return moment(props.value).isValid() ? moment(props.value).format(format) : '';
    }
    case Data.FieldType.Rating:
      return (
        <div>
          {props.value && new Array(props.value).fill(null).map((_, index: number) => <Star key={index} isFilled isActive />)}
        </div>
      );
    case Data.FieldType.Number: {
      if (props.value === null) return null;
      const precision = column.precision;
      if (precision && column.subtype === 'float') {
        return Number(props.value).toFixed(precision.substring(2).length);
      }
      return props.value;
    }
    case Data.FieldType.Currency: {
      if (props.value === null) return null;
      const precision = column.precision;
      const currencySymbol = column.currencySymbol || '';
      if (precision) {
        return `${currencySymbol}${Number(props.value).toFixed(precision.substring(2).length)}`;
      }
      return `${currencySymbol}${props.value}`;
    }
    case Data.FieldType.Percent: {
      if (props.value === null) return null;
      const precision = column.precision;
      const value = column.precision ? Number(props.value).toFixed(precision.substring(2).length) : props.value;
      return `${value}%`;
    }
    case Data.FieldType.URL:
      return <a href={fixUrl(props.value)} rel="noopener noreferrer" target="_blank" className="import-field-url">{props.value}</a>;
    case Data.FieldType.Phone:
      // eslint-disable-next-line no-case-declarations
      let phoneValue = props.value;
      try {
        if (phoneUtil.isValidNumber(phoneUtil.parse(phoneValue, 'US'))) {
          phoneValue = phoneUtil.parseAndKeepRawInput(phoneValue, 'US');
          phoneValue = phoneUtil.format(phoneValue, libphonenumber.PhoneNumberFormat.NATIONAL);
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
      return <div>{phoneValue}</div>;
    case Data.FieldType.Email:
      return <div>
        {props.value}
      </div>;
    default:
      return <div>{props.value}</div>;
  }
};

export default CellRenderer;
