import * as React from 'react';

import { Wrapper, Inner, BORDER_WIDTH } from './GridCellFloatingWrapper.style';

interface Props {
  anchorEl: HTMLElement;
  children: React.ReactNode;
  innerRef?: (inner: HTMLDivElement) => void;
  showPreview?: boolean;
}

interface State {
  clientStyle: {
    maxHeight?: string | number;
    top?: string;
    bottom?: string;
  };
}

class GridCellFloatingWrapper extends React.Component<Props, State> {
  public state = {
    clientStyle: {
      maxHeight: '0px',
    },
  };

  public componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public render(): JSX.Element {
    const { children, innerRef, showPreview } = this.props;

    return (
      <Wrapper innerRef={this.onRef} style={this.state.clientStyle}>
        <Inner innerRef={innerRef} showPreview={showPreview}>
          {children}
        </Inner>
      </Wrapper>
    );
  }

  private updateDimensions = (): void => {
    const { anchorEl } = this.props;
    const viewport = this.props.anchorEl.closest('.ag-body-viewport');
    const viewportContainer = this.props.anchorEl.closest('.ag-center-cols-container') ||
      this.props.anchorEl.closest('.ag-pinned-left-cols-container') ||
      this.props.anchorEl.closest('.ag-pinned-right-cols-container');

    if (!viewportContainer || !viewport) {
      return;
    }

    const viewportContainerRect = viewportContainer.getBoundingClientRect();
    const viewportRect = viewport.getBoundingClientRect();
    const parentRect = anchorEl.getBoundingClientRect();

    const hasSpaceAbove = parentRect.top > viewportContainerRect.top;
    const heightToTheTop = parentRect.bottom - viewportContainerRect.top - 1;
    const heightToTheBottom = viewportRect.bottom - parentRect.top;

    if (hasSpaceAbove && (heightToTheTop >= heightToTheBottom)) {
      this.setState({
        clientStyle: {
          maxHeight: `${parentRect.bottom - viewportContainerRect.top - BORDER_WIDTH}px`,
          bottom: '0px',
        },
      });

      return;
    }

    this.setState({
      clientStyle: {
        maxHeight: `${viewportRect.bottom - parentRect.bottom - BORDER_WIDTH}px`,
        top: '0px',
      },
    });
  };

  private onRef = (inner: HTMLDivElement | null): void => {
    if (inner) {
      this.updateDimensions();
    }
  };
}

export default GridCellFloatingWrapper;
