import { RowNode } from 'ag-grid-community';
import { DirectoryType } from 'data/documents/documents.types';
import { DocumentNode } from 'types/response/documentNode';

export interface AttachmentReferences {
  databaseID: string;
  fieldID: string;
  itemID: string;
}

export interface FileAttachment extends File{
  _id: string;
  fileExtension: string;
  fileKey?: string;
  contentType?: string;
  createdAt: number;
  createdBy: string;
  fileType?: string;
  modifiedAt: number;
  modifiedBy: string;
  references: AttachmentReferences;
  title: string;
  workspaceID?: string;
}

export interface FileUploadState {
  byFileName: {
    [key: string]: ByNameState;
  };
  attachmentsToUpload: UploadAttachmentsPayload;
  attachmentsUploadInProgress: File[];
  attachmentsUploaded: File[];
  uploadInProgress: boolean;
}

export interface ByNameState {
  state: FileUploadProcessState;
  progress?: {
    total: number;
    loaded: number;
  };
}

export interface UploadFilePayloadItem {
  file: File;
  uri: string;
  webkitRelativePath?: string;
}

export type UploadFilesPayload = UploadFilePayloadItem[];

export interface UploadAttachmentPayloadItem extends UploadFilePayloadItem {
  colId: string;
  rowId?: string;
  index?: number;
  attachedIds: string[];
}

export type UploadAttachmentsPayload = UploadAttachmentPayloadItem[];

export interface CopyAttachmentPayload {
  rowNode: RowNode;
  colId: string;
  source: string | number;
  attachedIds: string[];
  onComplete: (id: string) => void;
}

export enum FileUploadProcessState {
  SendingMetadata = 'SENDING_METADATA',
  SendingBlob = 'SENDING_BLOB'
}

export interface SendFileMetadataRequestPayload {
  uri: string;
  payload: {
    fileName: string;
    fileSize: number;
    fieldID?: string;
    itemID?: string;
    rowId?: string;
  };
}

export interface SendFileMetadataSucessPayload {
  node: DocumentNode;
  clientFileSize: number;
}

export interface StartUploadFilePayload {
  title: string;
}

export type StartUploadBlobPayload = StartUploadFilePayload;

export interface SendFileBlobRequestPayload {
  title: string;
  file: File;
  apiURI: string;
}

export interface ProgressPayload {
  title: string;
  total: number;
  loaded: number;
}

export enum UploadFileChannelActionType {
  Loaded = 'loaded',
  Progress = 'progress',
  Error = 'error'
}

export interface UploadFileChannelAction {
  type: UploadFileChannelActionType;
  payload: any;
}

export enum UploadBlobChannelActionType {
  Loaded = 'loaded',
  Progress = 'progress',
  Error = 'error',
  Request = 'request'
}

interface BaseUploadBlobChannelAction {
  type: UploadBlobChannelActionType.Loaded | UploadBlobChannelActionType.Progress | UploadBlobChannelActionType.Request;
  payload?: any;
}

interface ErrorUploadBlobChannelAction {
  type: UploadBlobChannelActionType.Error;
  error: Error;
}

export type UploadBlobChannelAction = BaseUploadBlobChannelAction | ErrorUploadBlobChannelAction;

export interface DirectoryTree {
  fileName: string;
  isDirectory: true;
  nodes?: DirectoryTree[];
}

export interface CreateDirectoryTreePayload {
  nodes: DirectoryType[];
}

export interface CreateDirectoryTreeResponseItem extends DirectoryTree {
  id: string;
}

export interface CreateDirectoryTreeResponse {
  navigationTree: {
    nodes: CreateDirectoryTreeResponseItem[];
  };
}

export interface DeleteTempAttachment {
  colId: string;
  index: number;
}
