import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './Password.scss';
import { AppMessages, PASSWORD_PATTERN_REQUIREMENTS_COUNT, PASSWORD_REGEX, PASSWORD_ENUM } from '../../constants';
import { InputProps } from '@material-ui/core/Input';

export interface HubsyncPasswordProps {
  value: string;
  disabled?: boolean;
  onChange: (text: string) => void;
  onInput?: (event: React.FormEvent<HTMLDivElement>) => void;
  onBlur?: (event: any) => void;
  passwordReset: () => void;
  setIsPasswordValid: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: React.ReactNode;
  InputProps?: Partial<InputProps>;
  showPatternChecks?: boolean;
  withReset?: boolean;
}

const Password: React.FC<HubsyncPasswordProps> = ({
  value,
  disabled = false,
  withReset= true,
  onChange,
  onInput,
  onBlur,
  passwordReset,
  setIsPasswordValid,
  errorMessage,
  InputProps,
  showPatternChecks = true,
}: HubsyncPasswordProps) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect( () => {
    setIsPasswordValid(isPasswordValid());
  }, [password]);

  const hasError = !!errorMessage;

  const handleChange = (_value: string) => {
    onChange(_value);
    setPassword(_value);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const getPasswordStregth = (type: PASSWORD_ENUM) => {
    return PASSWORD_REGEX[type].test(password) ? 'valid' : 'invalid';
  };

  const isPasswordValid = () => {
    let count = 0;
    for (const item of Object.values(PASSWORD_ENUM)) {
      count = count + (PASSWORD_REGEX[item as PASSWORD_ENUM].test(password|| '') ? 1 : 0);
    }
    if (count === PASSWORD_PATTERN_REQUIREMENTS_COUNT) {
      return true;
    }

    return false;
  };

  return (
    <div className={`password-container ${disabled ? 'reset' : ''} ${focused ? 'focused' : ''}`}>
      <TextField
        id="standard-password-input"
        className="password__input"
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        onInput={onInput}
        onChange={(evt) => handleChange(evt.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={(event) => {
          setFocused(false);
          if (onBlur) onBlur(event);
        }}
        value={disabled ? 'pwprotected' : value}
        error={hasError}
        helperText={errorMessage}
        InputProps={{
          ...(InputProps? InputProps: undefined),
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
                {showPassword ? (
                  <Visibility style={{ fontSize: '17px' }} />
                ) : (
                  <VisibilityOff style={{ fontSize: '17px' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {disabled && withReset && (
        <button className="reset-btn" onClick={passwordReset}>
          Reset Password
        </button>
      )}
      {!disabled && focused && showPatternChecks && (
        <div className="strength">
          <span className="strength-title">Your password must contain:</span>
          <ul className="list">
            <li className={`password--${getPasswordStregth(PASSWORD_ENUM.MINIMUM_LENGTH)}`}>
              {AppMessages.PASSWORD_MINIMUM_LENGTH}
            </li>
            <li className={`password--${getPasswordStregth(PASSWORD_ENUM.LOWERCASE)}`}>
              {AppMessages.PASSWORD_LOWERCASE}
            </li>
            <li className={`password--${getPasswordStregth(PASSWORD_ENUM.UPPERCASE)}`}>
              {AppMessages.PASSWORD_UPPERCASE}
            </li>
            <li className={`password--${getPasswordStregth(PASSWORD_ENUM.NUMBER)}`}>
              {AppMessages.PASSWORD_NUMBER}
            </li>
            <li className={`password--${getPasswordStregth(PASSWORD_ENUM.CHAR)}`}>
              {AppMessages.PASSWORD_CHAR}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Password;
