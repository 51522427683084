import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
// import { Link } from 'react-router-dom';
import * as qs from 'qs';

import Button, { Variant as ButtonVariant } from 'components/Button';
import HeaderBar from 'components/HeaderBar/LoggedOut';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import { ReactComponent as Logo } from 'icons/hubsync-logout-logo.svg';
import * as Colors from 'styles/colors';
import { TenantNode } from 'types/response/tenantNode';
import { LOCAL_STORAGE_KEYS } from '../../constants';

interface OwnProps {
  tenant?: TenantNode;
  search?: string;
}

type Props =
  & OwnProps
  & WithStyles<ClassKey>
  ;

function LogOut(props: Props): JSX.Element {
  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';
  React.useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK);
  }, []);

  return (
    <div className={props.classes.mainPage}>
      <HeaderBar tenant={props.tenant} />
      <div className={props.classes.logoutContent}>
        {tenantConfig.logoURL ? <img src={tenantConfig.logoURL} alt={'Hubsync'} className={props.classes.logoutLogo} /> :
          <Logo className={props.classes.logoutLogo} /> }
        <Typography
          variant={TypographyVariant.SubHeading}
          className={props.classes.logoutText}
        >
          {generateMessage(props.search)}
        </Typography>
        <Typography
          variant={TypographyVariant.CardText}
          className={props.classes.logbackText}
        >
          Log back in below to continue your session.
        </Typography>

        <a id="navLogin" href={window.location.search.includes('ssoLogout') ? '/' : '/login?showLogin'}>
          <div className={'auth-action-btn'+ (tenantConfig.clientName || '')} style={{ marginTop: '-10px' }}>
            <Button
              label="Log In"
              variant={ButtonVariant.Primary}
            />
          </div>
        </a>
      </div>
    </div>
  );
}

const generateMessage = (query = ''): string => {
  const parsedQuery = qs.parse(query.slice(1));
  if (!parsedQuery.unauthorized) return 'You have logged out.';
  const account = (parsedQuery.email && ` (${parsedQuery.email}) `) || ' ';
  return `Your account${account}does not have access to this tenant.`;
};

type ClassKey =
  | 'mainPage'
  | 'logoutContent'
  | 'logoutLogo'
  | 'logoutText'
  | 'logbackText'
  ;

const styles = withStyles<ClassKey>({
  mainPage: {
    height: '100vh',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoutContent: {
    position: 'relative',
    top: '130px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoutLogo: { marginBottom: '40px' },
  logoutText: {
    color: Colors.hubsyncBlue,
    marginBottom: '8px',
  },
  logbackText: { marginBottom: '36px' },
});

export default styles(LogOut);
