import * as React from 'react';

import ModalContent from 'components/Modals/Modal/Content';
import ModalHeader from 'components/Modals/Modal/Header';
import Modal from 'components/Modals/Modal';
import { useDispatch } from 'react-redux';
import * as ModalActions from 'data/modals/actions';
import { Container, LeftContent, RightContent } from './styles';
import { replaceAmpCodeBySymbol } from 'utilities/text';

interface Props {
  title: string;
  workSpaceData?: WorkspaceData;
  close: () => void;
}

interface WorkspaceData {
  taxpayerEmail: string;
  clientId: string;
  clientLastname: string;
  clientName: string;
  description: string;
  office: string;
  partnerEmail: string;
  region: string;
  returnType: string;
  email: string;
  spouseLastName: string;
  spouseFirstName: string;
  taxpayerFirstName: string;
  taxpayerLastName: string;
}


export const WorkSpaceModalInfo = ({ workSpaceData, title, close }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const onClose = () =>
    dispatch(ModalActions.closeModal());

  return (
    <Modal onClose={() => onClose}>
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalContent>
        {workSpaceData &&
          <>
            <Container>
              <LeftContent>
                Client ID:
              </LeftContent>
              <RightContent>
                {workSpaceData.clientId}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Client Name:
              </LeftContent>
              <RightContent>
                {replaceAmpCodeBySymbol(workSpaceData.clientName)}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                First Name:
              </LeftContent>
              <RightContent>
                {workSpaceData.taxpayerFirstName}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Last Name:
              </LeftContent>
              <RightContent>
                {workSpaceData.taxpayerLastName}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Return Type:
              </LeftContent>
              <RightContent>
                {workSpaceData.returnType}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Region:
              </LeftContent>
              <RightContent>
                {workSpaceData.region}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Office:
              </LeftContent>
              <RightContent>
                {workSpaceData.office}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Email:
              </LeftContent>
              <RightContent>
                {workSpaceData.taxpayerEmail}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Partner in Charge Email:
              </LeftContent>
              <RightContent>
                {workSpaceData.partnerEmail}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Spouse First Name:
              </LeftContent>
              <RightContent>
                {workSpaceData.spouseFirstName}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Spouse Lastname:
              </LeftContent>
              <RightContent>
                {workSpaceData.spouseLastName}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Spouse Email:
              </LeftContent>
              <RightContent>
                {workSpaceData.email}
              </RightContent>
            </Container>
            <Container>
              <LeftContent>
                Description:
              </LeftContent>
              <RightContent>
                {workSpaceData.description}
              </RightContent>
            </Container>
          </>
        }
      </ModalContent>
    </Modal>
  );
};


export default WorkSpaceModalInfo;
