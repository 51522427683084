import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import * as Colors from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

import Typography, { Variant } from '../Typography';

interface TabData {
  title: string;
  content: React.ReactNode;
}

interface Props extends WithStyles<ClassKey> {
  data: TabData[];
}

interface State {
  activeTab: number;
}

class Tabs extends React.Component<Props, State> {
  public state: State = { activeTab: 0 };

  public render(): JSX.Element {
    const { classes, data } = this.props;
    const [tabTitles, children] = splitTabData(data);

    return (
      <div>
        <div className={classes.tabs}>
          {tabTitles.map(this.renderTab)}
        </div>
        <div className={classes.content}>
          {children.map(this.renderContent)}
        </div>
      </div>
    );
  }

  private renderTab = (title: string, index: number): JSX.Element => {
    const { classes } = this.props;

    const className = cn({
      [classes.tab]: true,
      [classes.selectedTab]: index === this.state.activeTab,
    });

    return (
      <Typography
        variant={Variant.Label}
        key={index}
        className={className}
      >
        <div
          onClick={() => this.setState({ activeTab: index })}
        >
          {title}
        </div>
      </Typography>
    );
  };

  private renderContent = (content: React.ReactNode, index: number): React.ReactNode => {
    if (index !== this.state.activeTab) {
      return null;
    }

    return (
      <React.Fragment key={index}>
        {content}
      </React.Fragment>
    );
  };
}

type ClassKey
  = 'tabs'
  | 'tab'
  | 'selectedTab'
  | 'content'
  ;

const backgroundColor = rgba(Colors.black, 0.05);

const styles = withStyles<ClassKey>({
  tabs: {
    display: 'flex',
    margin: '0 5px',
  },
  tab: {
    'color': 'hsl(0,0%,30%)',
    'padding': '4px 16px',
    'cursor': 'pointer',
    'transition': 'background-color 0.5s ease',
    '&:hover': { backgroundColor },
  },
  selectedTab: {
    backgroundColor,
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
    cursor: 'default',
  },
  content: {
    backgroundColor,
    padding: 16,
    borderRadius: 3,
  },
});

export default styles(Tabs);

type SplitTabData = [string[], React.ReactNode[]];

function splitTabData(data: TabData[]): SplitTabData {
  return data.reduce(tabDataReducer, [[], []]);
}

function tabDataReducer([tabTitles, children]: SplitTabData, data: TabData): SplitTabData {
  return [[...tabTitles, data.title], [...children, data.content]];
}
