import moment from 'moment';
import { ColumnsMapById, SortModelState, SortOperator } from 'types/gridOptions';
import { CommonNode, FieldValue } from 'types/response';
import { getValueFromNode } from 'data/collections/filters';
import { Choice, Column } from 'types/schema';
import { FieldType } from 'types/response/fieldNode';
import { isStringArray } from 'components/NodeFieldData/filters/helpers';
import { AccountNode } from 'types/response/accountNode';
import { AttachmentsState } from 'data/attachments/reducer';
import { AttachmentNode } from 'types/response/attachmentNode';

export const sortNodes = (
  nodes: CommonNode[],
  columns: ColumnsMapById,
  sortModel: SortModelState,
  accountsById: Record<string, AccountNode> | null,
  attachments: AttachmentsState,
): CommonNode[] =>
  sortModel.allIds.length > 0
    ? nodes.sort(
      (nodeA: CommonNode, nodeB: CommonNode): number =>
        sortModel.allIds.reduce(
          (result: number, columnId: string) => {
            if (result !== 0) return result;

            const compareResult = compareValues(nodeA, nodeB, columns[columnId], accountsById, attachments);

            return sortModel.byId[columnId] && sortModel.byId[columnId].sort === SortOperator.Asc
              ? compareResult
              : compareResult * -1;
          },
          0,
        ),
    )
    : nodes;

const compareValues = (
  nodeA: CommonNode,
  nodeB: CommonNode,
  column: Column,
  accountsById: Record<string, AccountNode> | null,
  attachments: AttachmentsState,
): number => {
  if (!column?.id) {
    return 0;
  }

  const valueA: FieldValue | undefined = getValueFromNode(nodeA, column.id);
  const valueB: FieldValue | undefined = getValueFromNode(nodeB, column.id);

  switch (column.fieldType) {
    case FieldType.Singlelineoftext:
    case FieldType.Multilinetext:
    case FieldType.Email:
    case FieldType.FileName:
    case FieldType.FileExtension:
    case FieldType.FileSize:
    case FieldType.Staticformula:
      return compareStrings(getStringValue(valueA), getStringValue(valueB));
    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Percent:
    case FieldType.Rating:
    case FieldType.Date:
      return compareNumbers(getNumberValue(valueA), getNumberValue(valueB));
    case FieldType.Boolean:
      return compareBooleans(getBooleanValue(valueA), getBooleanValue(valueB));
    case FieldType.Singlechoice:
    case FieldType.Dropdown:
      return column.choices
        ? compareStrings(getSingleChoiceValue(valueA, column.choices), getSingleChoiceValue(valueB, column.choices))
        : 0;
    case FieldType.Multiplechoice:
      return column.choices
        ? compareStringArrays(
          getMultipleChoiceValue(valueA, column.choices),
          getMultipleChoiceValue(valueB, column.choices),
        )
        : 0;
    case FieldType.Account:
      if (accountsById) {
        return column.allowMultiple
          ? compareStringArrays(
            getMultipleAccountValue(valueA, accountsById),
            getMultipleAccountValue(valueB, accountsById),
          )
          : compareStrings(getSingleAccountValue(valueA, accountsById), getSingleAccountValue(valueB, accountsById));
      } else {
        return 0;
      }
    case FieldType.Attachment:
      return compareStringArrays(
        getAttachmentValue(valueA, nodeA.id, attachments),
        getAttachmentValue(valueB, nodeB.id, attachments),
      );
    case FieldType.Barcode:
    case FieldType.BLOBReference:
    case FieldType.Bookmarked:
    case FieldType.Checklist:
    case FieldType.Computed:
    case FieldType.Currency:
    case FieldType.DataReference:
    case FieldType.DateTime:
    case FieldType.Duration:
    case FieldType.Fraction:
    case FieldType.GUID:
    case FieldType.ID:
    case FieldType.Location:
    case FieldType.Lookup:
    case FieldType.Phone:
    case FieldType.Button:
    case FieldType.SearchReference:
    case FieldType.Tag:
    case FieldType.Time:
    case FieldType.Timespan:
    case FieldType.Url:
    case FieldType.Favorite:
    case FieldType.Status:
      return 0;

    default:
      return 0;
  }
};

export const getStringValue = (value: FieldValue | undefined): string => typeof value === 'string' ? value : '';

export const getNumberValue = (value: FieldValue | undefined): number => typeof value === 'number'
  ? value
  : Number.MIN_SAFE_INTEGER;

export const getBooleanValue = (value: FieldValue | undefined): boolean => typeof value === 'boolean' ? value : false;

export const getDateValue = (value: FieldValue | undefined): moment.Moment => typeof value === 'number'
  ? moment(value)
  : moment(0);

export const getSingleChoiceValue = (value: FieldValue | undefined, choices: Record<string, Choice>): string =>
  typeof value === 'string' && choices[value] ? choices[value].label : '';

export const getMultipleChoiceValue = (value: FieldValue | undefined, choices: Record<string, Choice>): string[] =>
  isStringArray(value) ? value.map(id => getSingleChoiceValue(id, choices)) : [];

export const getSingleAccountValue = (value: FieldValue | undefined, accountsById: Record<string, AccountNode>): string =>
  typeof value === 'string' && accountsById[value] ? accountsById[value].displayName : '';

export const getMultipleAccountValue = (value: FieldValue | undefined, accountsById: Record<string, AccountNode>): string[] =>
  isStringArray(value) ? value.map(id => getSingleAccountValue(id, accountsById)) : [];

export const getAttachmentValue = (value: FieldValue | undefined, nodeId: string, attachments: AttachmentsState): string[] => {
  const attachmentsById: Record<string, AttachmentNode> | undefined = attachments.byItemId[nodeId] && attachments.byItemId[nodeId].byId;

  if (value) {
    return isStringArray(value)
      ? value.map(id => attachmentsById && attachmentsById[id] ? attachmentsById[id].fileName : '')
      : [];
  }

  if (!attachmentsById) {
    return [];
  }

  const attachmentList: AttachmentNode[] = Object.values(attachmentsById);
  return attachmentList.map(attachment => attachment?.title || '');
};

const compareStrings = (a: string, b: string): number => a.localeCompare(b);

const compareNumbers = (a: number, b: number): number => a - b;

const compareBooleans = (a: boolean, b: boolean): number => a === b ? 0 : a < b ? -1 : 1;

const compareStringArrays = (a: string[], b: string[]): number => {
  let i = 0;
  for (; i < a.length && i < b.length; i++) {
    const valueResult = compareStrings(a[i], b[i]);
    if (valueResult !== 0) return valueResult;
  }
  return i === a.length ? (i === b.length ? 0 : -1) : 1;
};
