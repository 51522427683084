import { black } from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

export const HEADER_HEIGHT = '50px';
export const SHEETS_FOOTER_HEIGHT = '2rem';
export const CARDS_WIDTH = '300px';
export const CARDS_HEIGHT = '194px';
export const COLLAPSED_CARDS_WIDTH = '40px';
export const LEFTHAND_SIDEBAR_WIDTH = 240;
export const PANEL_SHADOW = `0 2px 4px 0 ${rgba(black, 0.08)}, 0 0 2px 0 ${rgba(black, 0.12)}`;

// Grid related style constants
export const BASE_ROW_HEIGHT = 32;

export enum GRID_ROW_HEIGHT {
  BASE = BASE_ROW_HEIGHT,
  MEDIUM = BASE_ROW_HEIGHT * 2,
  TALL = BASE_ROW_HEIGHT * 3
}

export const ROW_HEIGHT_CLASS_MAP = {
  [GRID_ROW_HEIGHT.BASE]: 'short',
  [GRID_ROW_HEIGHT.MEDIUM]: 'medium',
  [GRID_ROW_HEIGHT.TALL]: 'tall',
};
