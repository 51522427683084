import produce from 'immer';

import { groupById } from 'utilities/collections';

import * as actions from './actions';
import { BookmarksState } from './types';

export const initialState: BookmarksState = {
  isFetchLoading: false,
  isSaveLoading: false,
  byId: {},
  byRecordId: {},
  permissions: [],
};

const BOOKMARK_UPDATE='bookmarks/UPDATE';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.getAllBookmarks.pending.type: {
      return produce(state, draft => {
        draft.isFetchLoading = true;
      });
    }
    case actions.getAllBookmarks.fulfilled.type: {
      return {
        ...state,
        isFetchLoading: false,
        byId: groupById(action.payload.bookmarks).byId,
        byRecordId: groupById(action.payload.bookmarks, 'recordID').byId,
        permissions: action.payload.permissions,
      };
    }
    case actions.saveBookmark.pending.type: {
      return produce(state, draft => {
        draft.isSaveLoading = true;
      });
    }
    case actions.saveBookmark.fulfilled.type: {
      return produce(state, draft => {
        draft.isSaveLoading = false;
      });
    }
    case actions.saveBookmark.rejected.type: {
      return produce(state, draft => {
        draft.isSaveLoading = false;
      });
    }
    case BOOKMARK_UPDATE: {
      return produce(state, draft => {
        draft.isSaveLoading = false;
        draft.byId = {
          ...state.byId,
          [action.bookmarkMeta.id]: {
            ...action.bookmarkMeta,
          },
        };
        draft.byRecordId = {
          ...state.byRecordId,
          [action.bookmarkMeta.id]: {
            ...action.bookmarkMeta,
          },
        };
      });
    }

    case actions.deleteBookmark.pending.type: {
      return produce(state, draft => {
        draft.isSaveLoading = true;
      });
    }
    case actions.deleteBookmark.rejected.type: {
      return produce(state, draft => {
        draft.isSaveLoading = false;
      });
    }
    case actions.deleteBookmark.fulfilled.type: {
      return produce(state, draft => {
        const { bookmarkID } = action.meta.arg;
        draft.isSaveLoading = false;
        delete draft.byId[bookmarkID];
        if (draft.byRecordId) {
          for (const bookmark in draft.byRecordId) {
            if (draft.byRecordId[bookmark]?.id === bookmarkID) {
              delete draft.byRecordId[bookmark];
            }
          }
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
