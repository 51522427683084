export const add = `
<?xml version="1.0" encoding="UTF-8"?>
<svg viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="add" points="9 5.14285714 5.14285714 5.14285714 5.14285714 9 3.85714286 9 3.85714286 5.14285714 0 5.14285714 0 3.85714286 3.85714286 3.85714286 3.85714286 0 5.14285714 0 5.14285714 3.85714286 9 3.85714286"></polygon>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-19.000000, -153.000000)">
            <g transform="translate(10.000000, 145.000000)">
                <g transform="translate(9.000000, 8.000000)">
                    <mask fill="white">
                        <use xlink:href="#add"></use>
                    </mask>
                    <use id="Mask" fill="currentColor" fill-rule="evenodd" xlink:href="#add"></use>
                </g>
            </g>
        </g>
    </g>
</svg>
`;
export const expand = `
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <path id="expand-record" d="M10.229 12.927v8.35H5L11.722 28l6.723-6.722h-5.229v-8.351H10.23zm2.987-2.854v-8.35h5.229L11.722-5 5 1.722h5.229v8.351h2.987z"/>
  </defs>
  <use fill="currentColor" fill-rule="evenodd" transform="rotate(46 11.722 11.5)" xlink:href="#expand-record"/>
</svg>
`;
