import * as React from 'react';

import {
  RichTextEditorComponent,
  QuickToolbar,
  Toolbar,
  Inject,
  Link,
  HtmlEditor,
  Resize,
  NodeSelection,
} from '@syncfusion/ej2-react-richtexteditor';
import { getTextNodesUnder } from 'components/AgGrid/utils';
import * as types from 'data/messages/types';
import { RoleNames } from 'data/users/users.types';
import * as keys from 'keycode-js';
import styled from 'styled-components';
import { getUserRole } from 'utilities/permissions';

import FieldEditorWrapper from '../components/FieldEditorWrapper';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';

Toolbar.prototype.refreshToolbarOverflow = function() {
  this.baseToolbar?.toolbarObj?.refreshOverflow?.();
};

interface State {
  suggestions: types.Suggestions[];
  hidePlaceholder: boolean;
  isFocused: boolean;
}

class MultilineRichTextEditor extends React.Component<FieldEditorProps, State> {
  private ref: React.RefObject<RichTextEditorComponent> = React.createRef();
  private refMain: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      suggestions: [],
      hidePlaceholder: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);

    // @ts-ignore we need this.props instead of the this object.
    this.handleBlurEditor = this.handleBlurEditor(this.props);
  }

  handleBlurEditor = (props) => (event) => {
    // We destroy the toolbar element in case Syncfusion doesn't do it in the onClose listener.
    this.ref?.current?.quickToolbarModule.destroy();
  };


  private handleChange(e) {
    const { permissions, onChange } = this.props;

    if (permissions?.includes('items:update')) {
      const newValue = this.ref.current?.sanitizeHtml(this.ref.current?.getHtml());
      onChange(newValue);
    }
  }

  private handleKeyUp(e) {
    if (e.keyCode === 13 && e.shiftKey && this.props.eGridCell) {
      this.props.stopEditing();
      this.props.api.clearRangeSelection();

      if (!this.props.node.lastChild) {
        this.props.api.setFocusedCell(
          this.props.node.rowIndex + 1,
          this.props.colDef.colId,
          'bottom',
        );
      }
    }
  }

  private onCreate() {
    const { eGridCell } = this.props;
    const contentElement = this.ref.current?.element;
    contentElement?.addEventListener('keyup', this.handleKeyUp);
    this.ref.current?.refreshUI();

    // Focus automatically when it's only in the grid/cell
    if (eGridCell) {
      this.ref.current?.focusIn();
      this.handleFocus();
    }
  }

  private handleClickOutside({ event, ref, callback }) {
    let exclude = false;
    const body = document.getElementsByTagName('body');
    if (body[0]) {
      for (let i = 0; i < body[0].children.length; i++) {
        if (body[0].children[i].id.includes('Link_Quick_Popup_')) {
          exclude = true;
          break;
        }
      }
    }
    if (ref.current && !ref.current.contains(event.target) && !exclude) {
      callback();
    }
  }

  componentDidMount() {
    const { keyPress = 0, charPress, onChange } = this.props;

    if (charPress) {
      onChange(charPress);
      this.ref.current?.focusIn();
      this.handleFocus();
    }

    if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
      onChange('');
    }
    document.addEventListener('mousedown', (event)=>this.handleClickOutside({ event, ref: this.refMain, callback: this.props.stopEditing }));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (event)=>this.handleClickOutside({ event, ref: this.refMain, callback: this.props.stopEditing }));
  }

  private handleFocus() {
    const element = this.ref.current?.getContent().lastElementChild;
    const textNodes: Array<ChildNode> = getTextNodesUnder(document, element);
    const lastTextNode: ChildNode = textNodes[textNodes.length - 1];

    if (lastTextNode) {
      const selectionCursor = new NodeSelection();
      const range = document.createRange();
      const width = this.ref.current?.getContent()?.clientWidth ?? 0;
      const height = this.ref.current?.getContent()?.clientHeight ?? 0;
      this.ref.current?.getContent()?.scroll?.(width, height);

      range.setStart(lastTextNode, lastTextNode?.textContent?.length ?? 0);
      selectionCursor.setRange(document, range);
    }
  }

  private handleDialogOpen() {
    const rteDialog = document.querySelector('.e-rte-link-dialog') as HTMLElement;
    const { x: xRD, y: yRD, width: wRD, height: hRD } = rteDialog?.getClientRects()[0];
    const { innerHeight, innerWidth } = window;

    const centerX = (innerWidth - wRD) / 2;
    const centerY = (innerHeight - hRD) / 2;
    const translateX = centerX - xRD;
    const translateY = centerY - yRD;
    rteDialog.style.transform = `translate(${translateX}px, ${translateY}px)`;
  }

  render() {
    const { value, eGridCell, onBlur, readOnly, lock, permissions } = this.props;
    const userRole: RoleNames = getUserRole(permissions ?? []);
    const isReadOnly = userRole === RoleNames.Administrator ? false : readOnly || lock;
    if (eGridCell) {
      return (
        <div ref={this.refMain}>
          <Wrapper suppress={[keys.KEY_ENTER, keys.KEY_RETURN]}>
            <Inner>
              <RichTextEditorComponent
                blur={ this.handleBlurEditor }
                change={this.handleChange}
                created={this.onCreate}
                className={`rte-multi-text-grid-editor`}
                focus={this.handleFocus}
                id={`rte${this.props.id}`}
                readonly={isReadOnly}
                ref={this.ref}
                toolbarSettings={{
                  items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'CreateLink'],
                }}
                saveInterval={1}
                value={value}
                enableResize
                dialogOpen={this.handleDialogOpen}
              >
                <Inject services={[Link, QuickToolbar, HtmlEditor, Toolbar, Resize]} />
              </RichTextEditorComponent>
            </Inner>
          </Wrapper>
        </div>
      );
    } else {
      return (
        <div className="editor_personalized" id={`txt${this.props.id}`}>
          <RichTextEditorComponent
            blur={onBlur}
            change={this.handleChange}
            created={this.onCreate.bind(this)}
            className={`rte-multi-text-detailed-editor`}
            id={`rte${this.props.id}`}
            readonly={isReadOnly}
            inlineMode={{
              enable: true,
              onSelection: true,
            }}
            ref={this.ref}
            saveInterval={1}
            toolbarSettings={{
              items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'CreateLink'],
            }}
            value={value}
          >
            <Inject services={[Link, QuickToolbar, HtmlEditor, Toolbar]} />
          </RichTextEditorComponent>
        </div>
      );
    }
  }
}

export default wrapCellEditor({
  isPopup: true,
})(MultilineRichTextEditor);

const Wrapper = styled(FieldEditorWrapper)`
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 #eae9e9;
`;

const Inner = styled.div`
  padding: 0px;
`;

