import * as React from 'react';
import { connect } from 'react-redux';

import { updateNode, UpdateNodeRequestPayload } from 'data/collections/collections.actions.new';
import { Breadcrumb, getURIFromBreadcrumb } from 'types/schema';

import Rename from './Rename';

interface OwnProps {
  breadcrumb: Breadcrumb;
  onClose(): void;
}

interface DispatchProps {
  onUpdateNode(payload: UpdateNodeRequestPayload, folderBreadcrumbUri: string): void;
}

type Props =
  & OwnProps
  & DispatchProps
  ;

function RenameBreadcrumb(props: Props): JSX.Element {
  function onUpdate(newValue: string): void {
    props.onUpdateNode({
      url: getURIFromBreadcrumb(props.breadcrumb),
      data: { fileName: newValue },
      isFullObject: false,
    }, props.breadcrumb.uri);
    props.onClose();
  }

  return (
    <Rename
      currentValue={props.breadcrumb.title}
      existingValues={[]}
      close={props.onClose}
      onUpdate={onUpdate}
      windowTitle="Rename Folder"
      valueTitle="name"
    />
  );
}

const mapDispatchToProps: DispatchProps = {
  onUpdateNode: updateNode.request,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(RenameBreadcrumb);
