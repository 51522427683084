import styled from 'styled-components';

import * as Colors from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: padding .1s;
  min-width: 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  .filename-menu-icon {
    color: ${Colors.slateTwo};
    background: ${Colors.athensGrey};
    text-align: center;
    line-height: 30px;
    font-size: 0;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0;
    
    .file-context-menu-icon {
      margin-top: 5px;
      height: 20px;
    }
    
    &:hover {
      background: rgba(0, 101, 255, 0.15);
      
      .file-context-menu-icon {    
        color: ${Colors.hubsyncBlue};
      }
    }

    &.visible {
      display: inline-block;
    }
  }
  
  &.selected, .ag-row-hover & {
    padding-right: 30px;
  }

  .ag-row-hover & {
      .filename-menu-icon {
        display: inline-block;
      }
  }
`;

export const DocumentLink = styled.button`
  font: inherit;
  display: inline-block;
  color: ${Colors.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
  .ag-row-selected &, &:hover {
    color: ${Colors.hubsyncBlue};
  }
`;
