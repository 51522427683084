import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';
import * as keys from 'keycode-js';

import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import Labelable, { LabelableProps } from '../Labelable';

interface OwnProps {
  id?: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

const ALLOWED_KEYS = [keys.KEY_ENTER, keys.KEY_SPACE];

function UnstyledCheckbox(props: Props): JSX.Element {
  const { id, classes, className = '', disabled, selected } = props;
  const [isFocus, setIsFocus] = React.useState(false);

  const Checkbox = selected ? svgIcons.CheckboxChecked : svgIcons.CheckboxUnchecked;

  const newClassName = cn({
    [classes.checkbox]: true,
    [className]: className,
    [classes.checked]: selected,
    [classes.unchecked]: !selected,
    [classes.disabled]: disabled,
  });

  function onClick(): void {
    !disabled && props.onClick && props.onClick();
  }

  React.useEffect(() => {
    if (!isFocus) return;
    const onKeyUp = ({ keyCode }) => {
      if (ALLOWED_KEYS.includes(keyCode) && isFocus) {
        onClick();
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [isFocus]);


  return (
    <div
      id={id}
      className={'boolean-wrapper_editor'}
      onClick={onClick}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={() => {
        setIsFocus(false);
      }}
      tabIndex={0}
    >
      <Checkbox
        className={`${newClassName} ${isFocus ? classes.focus : ''}`}
      />
    </div>
  );
}

type ClassKey
  = 'checkbox'
  | 'checked'
  | 'unchecked'
  | 'disabled'
  | 'focus'
  ;

const styles = withStyles<ClassKey>({
  checkbox: {
    width: '15px',
    height: '15px',
  },
  checked: { color: Colors.hubsyncBlue },
  unchecked: { color: Colors.slateTwo },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  focus: {
    border: `1px solid ${Colors.hubsyncBlue}`,
  },
});

const StyledCheckbox = styles(UnstyledCheckbox);

export default function Checkbox(props: LabelableProps): JSX.Element {
  return (
    <Labelable
      {...props}
      Component={StyledCheckbox}
    />
  );
}
