import {
  ColumnApi,
  GridApi,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams,
  RowNode as AgGridRowNode,
} from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { Column } from 'ag-grid-community/dist/lib/entities/column';
import suppressRowDrag from 'components/AgGrid/helpers/suppressRowDrag';
import { GridColumnState } from 'data/collections/collections.reducer';
import * as _ from 'lodash/fp';
import moment from 'moment';

import { FilterModel, SortModel, SortModelState } from '../../types/gridOptions';
import { CommonNode } from '../../types/response';
import { ADD_NEW_FIELD_ID, ROW_NUMBER_ID } from '../DataGrid/columns/constants';
import { removeHTMLTag } from '../../utilities/format';

export interface FocusedCell {
  colId: string;
  rowIndex: number;
  isEditing?: boolean;
}


export interface GridContext {
  currentViewIsLocked?: boolean;
}

export interface AgGridFilterModel {
  [columnId: string]: string; // Map from column ID to text value
}

export interface Props<Node extends CommonNode> {
  gridApi: GridApi;
  columnApi: ColumnApi;
}

export enum ExportType {
  EXCEL,
  CSV
}

class AgGridApi<Node extends CommonNode> {
  private gridApi: GridApi;
  private columnApi: ColumnApi;

  public constructor(props: Props<Node>) {
    this.gridApi = props.gridApi;
    this.columnApi = props.columnApi;
  }

  public getColumnApi = (): ColumnApi => {
    return this.columnApi;
  };

  public updateColumnState = (columnState: GridColumnState[]): void => {
    const currentColumnState = this.columnApi.getColumnState();
    const changes: ColumnState[] = [];
    for (let index = 0; index < columnState.length; index++) {
      const column = columnState[index];
      const currentColumn = currentColumnState.find(columnState => columnState.colId === column.colId);
      const filtered = Object.keys(column)
        .reduce((obj, key) => ({ ...obj, [key]: currentColumn && currentColumn[key] }), {});
      if (!currentColumn || !_.equals(column, filtered)) {
        changes.push(column as ColumnState);
      }
    }

    if (changes && changes.length > 0) {
      this.columnApi.setColumnState(columnState as ColumnState[]);
    }
  };

  public setColumnState = (columnState: GridColumnState[]): void => {
    this.columnApi.setColumnState(columnState as ColumnState[]);
  };

  public redrawRows = (rowNodes?: RowNode<Node>[]): void => {
    if (rowNodes) {
      this.gridApi.redrawRows({ rowNodes });
    } else {
      this.gridApi.redrawRows();
    }
  };

  public getSelectedNodes = (): RowNode<Node>[] => {
    return this.gridApi.getSelectedNodes();
  };

  public getFocusedCell = (): FocusedCell | undefined => {
    const cell = this.gridApi.getFocusedCell();
    const editingCells = this.gridApi.getEditingCells()
      .filter(c => c.rowIndex === cell.rowIndex && c.column.getId() === cell.column.getId());
    return cell
      ? {
        colId: cell.column.getId(),
        rowIndex: cell.rowIndex,
        isEditing: editingCells.length > 0,
      }
      : undefined;
  };
  public clearFocusedCell = (): void => {
    return this.gridApi.clearFocusedCell();
  };

  public getFilterModel = (): AgGridFilterModel => {
    return this.gridApi.getFilterModel();
  };

  public getLastDisplayedRowIndex = (): number | null => {
    const index = this.gridApi.getLastDisplayedRow();
    return index >= 0 ? index : null;
  };

  public getSortModel = (): SortModel[] => {
    return this.gridApi.getSortModel() as SortModel[];
  };

  public stopEditing = (cancel = false): void => {
    this.gridApi.stopEditing(cancel);
  };

  public refreshHeader = (): void => {
    this.gridApi.refreshHeader();
  };

  public resetRowHeights = (): void => {
    this.gridApi.resetRowHeights();
  };

  public filter = (filterModel: FilterModel): void => {
    this.gridApi.setFilterModel(filterModel.searchFilters);
  };

  public setFilterModel = (keys: { [key: string]: string}): void => {
    this.gridApi.setFilterModel(keys);
  };

  public setColumnDefs = (columnDefs): void => {
    this.gridApi.setColumnDefs(columnDefs);
  };

  public sort = (sortModel: SortModelState): void => {
    const sortModelApi = sortModel.allIds.map(id => (sortModel.byId[id]));
    if (!_.equals(sortModelApi, this.gridApi.getSortModel())) {
      this.gridApi.setSortModel(sortModelApi);
    }
  };

  public getGroupingRowIndexMap = (): Record<string, number> => {
    const activeGroups = this.columnApi.getRowGroupColumns();

    const result: Record<string, number> = {};
    let currentIndex = 0;
    if (activeGroups.length) {
      this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
        if (rowNode.group) return;
        result[rowNode.data.id] = currentIndex++;
      });
    }

    return result;
  };

  public focusCell = (rowIndex: number, columnKey: string): void => this.gridApi.setFocusedCell(rowIndex, columnKey);

  public startEditingCell = (rowIndex: number, colKey: string): void =>
    this.gridApi.startEditingCell({ rowIndex, colKey });

  public isGroupMode = (): boolean => {
    return this.columnApi.getRowGroupColumns().length > 0;
  };

  public activeGroups = (): Column[] => {
    return this.columnApi.getRowGroupColumns();
  };

  public clearSelection = (): void => {
    this.gridApi.deselectAll();
    this.gridApi.clearRangeSelection();
  };

  public addPinnedBottomRow = (): void => {
    this.gridApi.setPinnedBottomRowData([{ agGridApi: this }]);
  };

  public removePinnedBottomRow = (): void => {
    this.gridApi.setPinnedBottomRowData([]);
  };

  public refreshCells = (colIds: string[]): void => this.gridApi.refreshCells({ columns: colIds, force: true });

  public redrawRow = (index: number) => {
    const rowNode = this.gridApi.getDisplayedRowAtIndex(index);
    if (rowNode) {
      this.gridApi.redrawRows({ rowNodes: [rowNode] });
    }
  };

  public autoSizeColumns = (columnIds: string[]): void => {
    this.columnApi.autoSizeColumns(columnIds);
  };

  public getDisplayedRowAtIndex = (index: number): any => {
    return this.gridApi.getDisplayedRowAtIndex(index);
  };

  public destroyAllActiveCharts = (): void => {
    // @ts-ignore
    this.gridApi.chartService.destroyAllActiveCharts();
  };

  public getChartModels = () => {
    return this.gridApi.getChartModels();
  };

  public createRangeChart = (params) => {
    this.gridApi.createRangeChart(params);
  };

  public getDisplayedRowCount = (): number => {
    return this.gridApi.getDisplayedRowCount();
  };

  public getRowGroupColumns = (): Column[] => {
    return this.columnApi.getRowGroupColumns();
  };

  public getAllVisibleColumnsIds = (): string[] => {
    return this.columnApi.getAllDisplayedColumns().map(column => column.getColId());
  };

  private processExportRowGroup = (params: ProcessRowGroupForExportParams) => {
    const refData = params.node.rowGroupColumn?.getColDef().refData;
    if (params.node.key.includes(',') && refData && refData.fieldType && refData.fieldType === 'account') {
      const accounts = (refData && refData.accountsById) || {};
      return params.node.key.split(',').map(item => {
        return (accounts[item] && accounts[item].displayName) || item;
      }).join(', ');
    }
    return params.node.key;
  };

  private processExportTransformation = (params: ProcessCellForExportParams, { clearHTML = false }: {clearHTML: boolean}) => {
    // fast return if no value found
    if (!params.value) return '';

    const refData = params.column.getColDef().refData;
    const fieldType = refData && refData.fieldType;

    if (clearHTML && ['singlelineoftext', 'multilinetext'].includes(fieldType || '')) {
      params.value = removeHTMLTag(params.value);
    }

    if (fieldType === 'date') {
      return moment(typeof params.value === 'object' && params.value.timestamp ? params.value.timestamp : params.value).format(refData?.dateFormat || 'MM/DD/YYYY HH:mm:ss Z');
    }

    if (fieldType === 'singlechoice') {
      const choices = (refData && refData.choices) || {};
      if (!Object.keys(choices).length) return '';
      const key = parseInt(params.value) || params.value;
      const choiceFound: any = Object.values(choices).find( (choice: any) => choice.label === params.value);
      return (choices[key] && choices[key].label) || choiceFound?.label || '';
    }

    if (fieldType === 'multiplechoice') {
      const choices = (refData && refData.choices) || {};
      if (!Object.keys(choices).length) return '';
      if (Array.isArray(params.value)) {
        let options = params.value.map(item => {
          return (choices[parseInt(item)] && choices[parseInt(item)].label) || choices[item] && choices[item].label || '';
        }).join(',').replace(/,$/, '');
        // if options exists and it has a comma but one object after split, remove comma.
        if (options && options.includes(',') && options.split(',').length === 1) {
          options = options.replace(/,$/, '');
        }
        return options;
      }
    }

    if (fieldType === 'account') {
      const accounts = (refData && refData.accountsById) || {};
      if (Array.isArray(params.value)) {
        return params.value.map(item => {
          return (accounts[item] && accounts[item].displayName) || item;
        });
      } else {
        return (accounts[params.value] && accounts[params.value].displayName) || params.value;
      }
    }

    return params.value;
  };

  public exportData = (type: ExportType, params?: any): void => {
    const exportParams = {
      ...params,
      processCellCallback: (e: ProcessCellForExportParams) => this.processExportTransformation(e, { clearHTML: true }),
      allColumns: false,
      processRowGroupCallback: this.processExportRowGroup,
      columnKeys: this.columnApi.getAllDisplayedColumns().filter(item => {
        // @ts-ignore
        const isAttachment = item.getColDef().refData && item.getColDef().refData.fieldType === 'documentreference';
        return item.getColId() !== ROW_NUMBER_ID && item.getColId() !== ADD_NEW_FIELD_ID && !isAttachment;
      }).map(item => item.getColId()),
      columnGroups: true,
    };

    switch (type) {
      case ExportType.EXCEL:
        this.gridApi.exportDataAsExcel(exportParams);
        return;
      case ExportType.CSV:
        this.gridApi.exportDataAsCsv(exportParams);
        return;
      default:
        console.warn('Export type not supported');
    }
  };

  public gridApiInstance(): GridApi {
    return this.gridApi;
  }

  public setSuppressRowDrag(suppress) {
    suppressRowDrag(suppress);
    return this.gridApi.setSuppressRowDrag(suppress);
  }

  public setFocusedCell(rowIndex: number, colKey: string): void {
    this.gridApi.setFocusedCell(rowIndex, colKey);
  }
}

export default AgGridApi;

export interface RowNode<a> extends AgGridRowNode {
  data: a;
}
