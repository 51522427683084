import * as React from 'react';

import Toolbar from '../Toolbar';
import { RightSideWrapper } from './TableHeaderTools.style';
// TODO remove this styles as soon as we complete redesign of columns and views
import './style.scss';

interface TableHeaderToolsProps {
  rightSideOptions: React.ReactNode;
  children: React.ReactNode;
}

const TableHeaderTools = ({ children, rightSideOptions }: TableHeaderToolsProps) => (
  <Toolbar>
    {children}
    <RightSideWrapper>
      {rightSideOptions}
    </RightSideWrapper>
  </Toolbar>
);

export default TableHeaderTools;
