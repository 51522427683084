import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import FileUploader from 'components/NodeFieldData/attachment/uploaders/FileUploader';
import { FileAttachment } from 'types/common';
import svgIcons from 'styles/svgIcons';
import { Uploader } from 'components/NodeFieldData/attachment/uploaders/types';
import IconButton, { Color as IconButtonColor, Size } from 'components/IconButton';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from 'components/NodeFieldData/attachment/AddAttachmentsModal.style';
import Typography, { Variant } from 'components/Typography';
import ModalAttachmentItem from './ModalAttachmentItem';
import Button, { Variant as ButtonVariant } from 'components/Button';
import { getFormattedFileSize } from 'utilities/files';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State as ReduxState } from 'reducers';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { TenantNode } from 'types/response/tenantNode';
import { DEFAULT_FILE_ALLOWED_EXTENSION_LIST } from '../../../constants';


const UPLOADERS: Uploader[] = [
  { component: FileUploader, icon: svgIcons.Attachment, name: 'My Device' },
];

interface OwnProps {
  onClose: () => void;
  onAdd: (files: FileAttachment[]) => void;
  acceptTypes?: string;
  permissions: string[];
  disableAutoAdd?: boolean;
  tenant?: TenantNode;
}

interface DispatchProps {
  warningToast: typeof notifications.warn;
}

type Props = OwnProps & DispatchProps & State & WithStyles<typeof styles>;

interface State {
  activeUploader: number;
  attachments: any[];
  uploadViewActive: boolean;
}

class AddAttachmentsModal extends React.Component<Props, State> {
  public state: State = {
    activeUploader: 0,
    attachments: [],
    uploadViewActive: true,
  };

  public render = (): JSX.Element => {
    const { classes, onClose } = this.props;
    return (
      <Modal
        onClose={onClose}
        open={true}
      >
        <div className={classes.root}>
          {this.renderActiveUploader()}
        </div>
      </Modal>
    );
  };

  private setTempAttachments = (items: any) => {
    let matchFileExtensionType;
    const tenantFileAllowedExtensionList = this.props.tenant?.thirdParty?.fileAllowedExtensionList;
    const typeExtensionAllow = tenantFileAllowedExtensionList || DEFAULT_FILE_ALLOWED_EXTENSION_LIST;

    const matchFiles = [];
    items.map(item => {
      matchFileExtensionType = typeExtensionAllow.find(extension => extension.toLowerCase() === item.extension.toLowerCase());
      if (matchFileExtensionType) {
        matchFiles.push(item as never);
      } else {
        this.props.warningToast({ message: `For security reasons .${item.extension} is not allowed.` });
      }
    });
    if (matchFiles.length) {
      this.setState({ attachments: [...this.state.attachments, ...matchFiles], uploadViewActive: false });
    }
  };

  private removeAttachment = (index: number) => {
    this.state.attachments.splice(index, 1);
    this.setState({ attachments: this.state.attachments });
    if (this.state.attachments) {
      this.setState({ uploadViewActive: true });
    }
  };

  private renderAttachments = () => this.state.attachments.map((file, i) => (
    <ModalAttachmentItem
      key={`${file.file.name}${i}`}
      fileName={file.file.name}
      onDelete={() => this.removeAttachment(i)}
      permissions={this.props.permissions}
      size={getFormattedFileSize(file.file.size)}
    />
  ));

  private toggleUploadView = () => {
    this.setState({ uploadViewActive: !this.state.uploadViewActive });
  };

  private deselectAll = () => {
    this.setState({ uploadViewActive: true, attachments: [] });
  };

  private uploadAttachments = () => {
    this.props.onAdd(this.state.attachments);
  };

  private renderActiveUploader = (): JSX.Element => {
    const { classes, acceptTypes, onClose } = this.props;
    const { activeUploader, uploadViewActive, attachments } = this.state;
    const { component: UploaderComponent } = UPLOADERS[activeUploader];
    return (
      <div className={classes.uploader}>
        <div className={classes.header}>
          <span />
          <Typography variant={Variant.SubHeading}>
            My Device
          </Typography>
          <IconButton icon={svgIcons.Close} color={IconButtonColor.DarkGray} size={Size.Small} onClick={onClose} />
        </div>
        {
          uploadViewActive ?
            <UploaderComponent
              onAdd={this.setTempAttachments}
              acceptTypes={acceptTypes}
            />
            :
            <div className={classes.filesWrapper}>
              {this.renderAttachments()}
            </div>
        }
        {
          attachments.length ?
            <>
              <div className={classes.divider} />
              <div className={classes.footer}>
                {
                  uploadViewActive ?
                    <>
                      <span>Selected Files: <strong>{attachments.length}</strong></span>
                      <Button label="View Selected" variant={ButtonVariant.Primary} onClick={this.toggleUploadView}/>
                    </>
                    :
                    <>
                      <Button label="Deselect All" variant={ButtonVariant.SecondaryLink} onClick={this.deselectAll} />
                      <div className={classes.actionButtons}>
                        <Button label="Upload More" variant={ButtonVariant.SecondaryLink} onClick={this.toggleUploadView} />
                        <Button label="Upload" variant={ButtonVariant.Primary} onClick={this.uploadAttachments} />
                      </div>
                    </>
                }
              </div>
            </>
            :
            null
        }
      </div>
    );
  };
}
const mapStateToProps = (state: ReduxState) => {
  return state;
};

const mapDispatchToProps: DispatchProps = {
  warningToast: notifications.warn,
};

const addAttachments = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default addAttachments(AddAttachmentsModal);
