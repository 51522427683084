import React from 'react';

import Button, { Variant } from 'components/Button';
import svgIcons from 'styles/svgIcons';

import './NotificationToast.scss';
import { toastIcons } from './toastIcons';
import { ToastType } from './types';

export interface ToastProps {
  closeToast: () => void;
  message: string;
  buttonLabel?: string;
  type: ToastType;
  paragraph?: string;
  onButtonClick?: () => void;
}

export const renderToast = ({
  closeToast,
  message,
  buttonLabel,
  type,
  paragraph,
  onButtonClick,
}: ToastProps): React.ReactElement => {
  const ToastIcon = toastIcons[type];
  const handleClick = () => {
    if (typeof onButtonClick === 'function') {
      onButtonClick();
    } else {
      closeToast();
    }
  };
  return (
    <div className='notification-toastify-wrapper'>
      <ToastIcon className='notification-toastify-icon' />
      <span className='notification-toastify-message'>{message}</span>
      <svgIcons.Close
        className='notification-toastify-close-icon'
        onClick={closeToast}
      />
      {paragraph && (
        <span className='notification-toastify-paragraph'>{paragraph}</span>
      )}
      {buttonLabel && (
        <Button
          className='notification-toastify-button'
          label={buttonLabel}
          variant={Variant.PrimaryLink}
          onClick={handleClick}
        />
      )}
    </div>
  );
};
