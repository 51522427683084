import * as React from 'react';

import { getTenantConfig } from 'env';
import { get } from 'lodash';
import { downloadFiles } from 'pages/Files/Services';
import { PreviewBehavior } from 'types/response';
import { AttachmentNode } from 'types/response/attachmentNode';

import { ChipProps } from '../../ui/Chip';
import OpenModalPreview from './OpenModalPreview';
import { downloadFile } from '../../../utilities/httpRequests';

const tenantConfig = getTenantConfig() as any;

export interface PreviewProps {
  attachment: AttachmentNode;
  children: React.ReactElement<ChipProps>;
  openInNewTab?: boolean;
}

interface Props extends PreviewProps {
  previewBehavior: PreviewBehavior;
}

export default function PreviewBehaviorWrapper(props: Props): JSX.Element {
  const { previewBehavior, ...previewProps } = props;

  if (tenantConfig.enabledFeatures.openFileInNewTab) {
    return <Download {...previewProps} openInNewTab={true} />;
  }

  switch (previewBehavior) {
    case PreviewBehavior.Download:
      return <Download {...previewProps} />;

    case PreviewBehavior.OpenInNewTab:
      return <Download {...previewProps} openInNewTab={true} />;

    case PreviewBehavior.ModalPreview: {
      const file = previewProps.attachment;
      // @ts-ignore
      if (file.fileType.includes('image') || file.preview) {
        return <OpenModalPreview {...previewProps} />;
      }
      return <Download {...previewProps} />;
    }

    default:
      return <Download {...previewProps} />;
  }
}

function onLinkClick(event: React.MouseEvent): void {
  event.stopPropagation();
}

async function onDownloadLinkClick(event: React.MouseEvent, props: PreviewProps): Promise<void> {
  event.stopPropagation();
  const workspace = get(window.appStore.getState(), 'workspaces.current');
  const { downloadUrl } = await downloadFiles([{ _id: props.attachment._id }], workspace);
  downloadFile(downloadUrl);
}

type DownloadProps
  = ChipProps
  & Pick<React.HTMLProps<HTMLAnchorElement>, ('href' | 'download')>;

export function Download(props: PreviewProps): JSX.Element {
  // @ts-ignore
  return React.cloneElement<DownloadProps>(props.children, {
    component: 'a',
    download: true,
    onClick: (e) => onDownloadLinkClick(e, props),
  });
}

type OpenInNewTabProps
  = ChipProps
  & Pick<React.HTMLProps<HTMLAnchorElement>, ('href' | 'target')>;

export function OpenInNewTab(props: PreviewProps): JSX.Element {
  // @ts-ignore
  return React.cloneElement<OpenInNewTabProps>(props.children, {
    component: 'a',
    href: props.attachment.inlineURI,
    target: '_blank',
    onClick: onLinkClick,
  });
}
