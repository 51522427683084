import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import _ from 'lodash';
import Button, { Variant as ButtonVariant } from 'components/Button';
import svgIcons from 'styles/svgIcons';
import { styles } from './Checklist.style';
import TextField from '@material-ui/core/TextField';
import ContextMenu, { MenuItem } from 'components/ContextMenu/ContextMenu';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import { deleteChecklist, updateChecklist } from 'data/collections/collections.actions.new';
import * as types from 'data/collections/collections.types';
import ChecklistItem from './ChecklistItem';
import DeleteConfirm from 'components/Modals/DeleteConfirm/DeleteConfirm';
import Checkbox from 'components/Checkbox';
import update from 'immutability-helper';
import { uuidv4 } from 'utilities/common';
import { SortableList, SortableListItem } from '../../../TableHeaderTools/Sortable';

export enum ChecklistStates {
  BROWSE = 'browse',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

interface DispatchProps {
  deleteChecklist: (id: string, itemId: string) => void;
  updateChecklist: (checklist: types.Checklist, itemId: string) => void;
}

interface OwnProps {
  itemId: string;
  addChecklist: () => void;
  checklist: types.Checklist;
  permissions?: string[];
}

interface State {
  checklistState: ChecklistStates;
  checklistItemState: ChecklistStates;
  checklist: types.Checklist;
  checklistItem: types.ChecklistItem;
}

type Props = OwnProps & WithStyles<typeof styles> & DispatchProps;

const initialState = {
  checklistState: ChecklistStates.BROWSE,
  checklistItemState: ChecklistStates.BROWSE,
  checklist: {
    id: '',
    name: '',
    items: [],
  },
  checklistItem: {
    id: uuidv4(),
    name: '',
    completed: false,
  },
};

export class Checklist extends React.Component<Props, State> {
  private updateItemRef = React.createRef<HTMLInputElement>();
  state = { ...initialState };

  public render(): JSX.Element {
    const { classes, permissions, checklist } = this.props;

    const menuItems: MenuItem[] = [];

    if ((permissions || []).includes('checklists:update')) {
      menuItems.push(
        {
          id: 'rename-checklist',
          title: 'Rename',
          Icon: svgIcons.Edit,
          action: this.updateChecklist,
        },
      );
    }

    if ((permissions || []).includes('checklists:delete')) {
      menuItems.push(
        {
          id: 'delete-checklist',
          title: 'Delete',
          Icon: svgIcons.Delete,
          action: () => this.setState({ checklistState: ChecklistStates.DELETE }),
        },
      );
    }

    if ((permissions || []).includes('checklists:create')) {
      menuItems.push(
        {
          id: 'new-checklist',
          title: 'New Checklist',
          Icon: svgIcons.Checklist,
          action: this.props.addChecklist,
        },
      );
    }

    const checked = _.countBy(checklist.items, 'completed');
    const checklistLength = checklist.items.length;
    const completedCount = checked['true'] || 0;
    const completedPercentage = (completedCount / checklistLength * 100) || 0;

    return (
      <div className={classes.checklistWrapper}>
        <div className={`${classes.rowWithSpace} ${classes.checklist} ${this.state.checklistState === ChecklistStates.UPDATE ? classes.activeItem : ''}`}>
          {(permissions || []).includes('checklists:update') && this.state.checklistState === ChecklistStates.UPDATE && (
            <div className={classes.rowEdit}>
              <svgIcons.Checklist className={classes.checklistIcon} />
              <div className={classes.textFieldWrapper}>
                <TextField
                  inputRef={this.updateItemRef}
                  value={this.state.checklist.name}
                  className={classes.textFieldRoot}
                  inputProps={{ className: classes.textField }}
                  onChange={this.onChange}
                  onKeyDown={this.onChecklistKeyDown}
                  onBlur={this.onBlur}
                />
              </div>
            </div>
          )}
          {this.state.checklistState === ChecklistStates.DELETE && (
            <DeleteConfirm
              title="Delete this checklist"
              message="Are you sure you want to delete this checklist item? Deleting this checklist will remove all the list items."
              onClose={() => this.setState({ checklistState: ChecklistStates.BROWSE })}
              onConfirm={() => {
                this.props.deleteChecklist(this.props.checklist.id, this.props.itemId);
              }}
            />
          )}

          {this.state.checklistState !== ChecklistStates.UPDATE && (
            <div className={classes.rowBrowse} onClick={this.updateChecklist}>
              <svgIcons.Checklist className={classes.checklistIcon} />
              <div className={classes.textFieldWrapper}>
                <Typography variant={TypographyVariant.FieldLabel} className={`${classes.fieldLabel} ${classes.checklistFieldLabel}`}>
                  {this.props.checklist.name}
                </Typography>
              </div>
            </div>
          )}

          <div className={`${classes.contextMenuWrapper} ${classes.checklistContextMenu}`}>
            {((this.props.permissions || []).includes('checklists:create') ||
                (this.props.permissions || []).includes('checklists:update') ||
                (this.props.permissions || []).includes('checklists:delete')) && (
              <ContextMenu
                id="checklist"
                menuItems={menuItems}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              />
            )}
          </div>
        </div>

        {/* Checklist Progress bar */}
        <div className={classes.percentageWrapper}>
          <span className={classes.percentageLabel}>{completedPercentage.toFixed(0)} %</span>
          <div className={classes.percentageLineWrapper}>
            <div className={classes.percentageLine} style={{ width: `${completedPercentage}%` }}></div>
          </div>
        </div>
        {this.props.checklist?.items?.filter(item => item).length > 0 && (
          <div>
            <SortableList lockAxis="y" lockToContainerEdges useDragHandle onSortEnd={this.moveChecklistItem}>
              {this.props.checklist.items.filter(item => item).map((item, index) => {
                return (
                  <SortableListItem className={classes.checklistItemDraggableWrapper} key={index.toString()} index={index} hideDragHandle={!(this.props.permissions || []).includes('checklists:update')}>
                    <ChecklistItem
                      item={item}
                      index={index}
                      removeItem={() => this.removeItem(index)}
                      toggleItem={() => this.toggleItem(index)}
                      updateItem={(item) => this.updateItem(index, item)}
                      permissions={this.props.permissions}
                    />
                  </SortableListItem>
                );
              })}
            </SortableList>
          </div>
        )}

        {this.state.checklistItemState === ChecklistStates.CREATE && (
          <div className={`${classes.checklistItemWrapper} ${classes.activeItem}`}>
            <div className={classes.rowEdit}>
              <div className={classes.checklistItemCheckboxWrapper}>
                <Checkbox selected={false} />
              </div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  value={this.state.checklistItem.name}
                  onChange={this.onItemChange}
                  onKeyDown={this.onChecklistItemKeyDown}
                  className={classes.textFieldRoot}
                  autoFocus
                  onBlur={this.onBlur}
                />
              </div>
            </div>
          </div>
        )}

        {(this.props.permissions || []).includes('checklists:create') && (
          <div className={classes.addButtonWrapper}>
            <Button
              id="addChecklistBtn"
              label="Add Checklist Item"
              variant={ButtonVariant.PrimaryLink}
              icon={svgIcons.Add}
              onClick={this.addChecklistItem}
            />
          </div>
        )}
      </div>
    );
  }

  private onBlur = (e): void => {
    if (e.relatedTarget && e.relatedTarget?.getAttribute('id') === 'addChecklistBtn') {
      this.saveChecklistItem();
    } else {
      this.resetState();
    }
  };

  private moveChecklistItem = ({ oldIndex, newIndex }): void => {
    const { items } = this.props.checklist;
    const dragItem = items[oldIndex];
    const updatedItems = update(items, { $splice: [[oldIndex, 1], [newIndex, 0, dragItem]] });
    this.props.updateChecklist(
      {
        ...this.props.checklist,
        items: [...updatedItems],
      },
      this.props.itemId,
    );
  };

  private removeItem = (index: number): void => {
    const items = this.props.checklist.items.slice();
    this.props.updateChecklist(
      {
        ...this.props.checklist,
        items: [
          ...items.slice(0, index),
          ...items.slice(index + 1),
        ],
      },
      this.props.itemId,
    );
  };

  private toggleItem = (index: number): void => {
    const items = this.props.checklist.items.slice();
    items[index].completed = !items[index].completed;
    this.props.updateChecklist(
      {
        ...this.props.checklist,
        items,
      },
      this.props.itemId,
    );
  };

  private updateItem = (index: number, item: types.ChecklistItem): void => {
    const name = item.name.trim();
    if (name.length < 1 || name.length > 100) return;
    const items = this.props.checklist.items.slice();
    items[index] = { ...item };
    this.props.updateChecklist(
      {
        ...this.props.checklist,
        items,
      },
      this.props.itemId,
    );
  };

  private onChecklistKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.saveChecklist();
        return;
      case 'Escape':
        this.setState({ checklistState: ChecklistStates.BROWSE });
        event.stopPropagation();
        return;
      default: break;
    }
  };

  private resetState = () => {
    if (this.state.checklistItemState === ChecklistStates.CREATE) {
      this.setState({ checklistItem: { ...initialState.checklistItem } });
    }
    this.setState({ checklistItemState: ChecklistStates.BROWSE, checklistState: ChecklistStates.BROWSE });
  };

  private onChecklistItemKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.saveChecklistItem();
        return;
      case 'Escape':
        this.resetState();
        event.stopPropagation();
        return;
      default: break;
    }
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      checklist: {
        ...this.state.checklist,
        name: event.target.value,
      },
    });
  };

  private onItemChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      checklistItem: {
        ...this.state.checklistItem,
        name: event.target.value,
      },
    });
  };

  private updateChecklist = (): void => {
    if (!(this.props.permissions || []).includes('checklists:update')) return;
    this.setState({
      checklistState: ChecklistStates.UPDATE,
      checklist: { ...this.props.checklist },
    });
    this.focusInput();
  };

  private focusInput = (): void => {
    setTimeout(() => {
      if (this.updateItemRef.current) {
        this.updateItemRef.current.select();
      }
    }, 0);
  };

  private saveChecklist = (): void => {
    const name = this.state.checklist.name.trim();
    if (name.length < 1 || name.length > 100) return;
    this.props.updateChecklist(this.state.checklist, this.props.itemId);
    this.setState({
      checklistState: ChecklistStates.BROWSE,
      checklist: { ...initialState.checklist },
    });
  };

  private addChecklistItem = (): void => {
    this.setState({ checklistItemState: ChecklistStates.CREATE });
  };

  private saveChecklistItem = (): void => {
    const name = this.state.checklistItem.name.trim();
    if (name.length < 1 || name.length > 100) return;
    this.props.updateChecklist(
      {
        ...this.props.checklist,
        items: [
          ...this.props.checklist.items,
          { ...this.state.checklistItem },
        ],
      },
      this.props.itemId,
    );

    this.setState({
      checklistItemState: ChecklistStates.BROWSE,
      checklistItem: { ...initialState.checklistItem, id: uuidv4() },
    }, this.addChecklistItem);
  };
}

const mapDispatchToProps: DispatchProps = {
  deleteChecklist,
  updateChecklist,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Checklist));
