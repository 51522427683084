import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import * as colors from 'styles/colors';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 24px;
`;

const CardsSwitcherButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 5px;
  color: ${colors.lightBlueGrey}
`;

export {
  CardsSwitcherButton,
  Wrapper,
};
