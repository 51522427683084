import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { hubsyncBlue, slateTwo, blueGrey, iceBlue } from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey =
  'root'
  | 'content'
  | 'inlineField'
  | 'fieldLabel'
  | 'fieldLabelText'
  | 'fieldIcon'
  | 'inlineEditorContainer'
  | 'errorFooter'
  | 'addFieldRoot'
  | 'addFieldIcon'
  | 'addFieldLabel'
  | 'actionButtons'
  | 'halfWidthField'
  | 'addFieldsContainer'
  | 'divider'
  | 'svgLockCircle'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    paddingTop: '12px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '30px',
    flex: '1',
  },
  inlineField: {
    display: 'grid',
    margin: '6px 0',
    gap: '2px',
    gridTemplateColumns: '1fr 10px 2fr',
  },
  fieldLabel: {
    display: 'flex',
    height: '18px',
    alignItems: 'center',
    margin: '11px 2px 11px 0',
  },
  fieldLabelText: {
    color: blueGrey,
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily,
    letterSpacing: 0.22,
    maxWidth: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fieldIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px',
    color: slateTwo,
  },
  inlineEditorContainer: {
    'marginLeft': '9px',
    'width': '100%',
    'minWidth': '120px',
    'maxWidth': '270px',
  },
  halfWidthField: {
    width: '200px',
  },
  errorFooter: {
    color: 'red',
  },
  addFieldRoot: {
    cursor: 'pointer',
  },
  addFieldIcon: {
    color: hubsyncBlue,
    width: '10px',
    marginRight: '15px',
  },
  addFieldLabel: {
    color: hubsyncBlue,
  },
  addFieldsContainer: {
    'display': 'flex',
    'margin': '4px 30px 16px 30px',
    '& :nth-child(2)': {
      marginLeft: '8px',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 30px',
  },
  divider: {
    width: '100%',
    height: '1px',
    minHeight: '1px',
    backgroundColor: iceBlue,
  },
  svgLockCircle: {
    width: '7px',
  },
};
