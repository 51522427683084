import {
  GridApi,
} from 'ag-grid-community';


class ColumnSummaryGridApi {
  public gridApi: GridApi;

  public constructor(gridApi: GridApi) {
    this.gridApi = gridApi;
  }
}

export default ColumnSummaryGridApi;
