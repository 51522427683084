import { Role } from 'data/accounts/types';
import { Dictionary } from 'lodash';
import * as Colors from 'styles/colors';
import { Choice } from 'types/schema';

import { OrganizationUserRoles } from './organizationUserRoles';
import { TenantAdminRoles } from './tenantAdminRoles';

export const roleChoices: Dictionary<Choice> = {
  [TenantAdminRoles.TENANT_ADMIN]: {
    label: 'Tenant Administrator',
    color: Colors.hubsyncBlue,
  },
  [TenantAdminRoles.ORGANIZATION_USER]: {
    label: 'Organization User',
    color: Colors.jungleGreen,
  },
};

export const organizationRoleChoices: Dictionary<Choice> = {
  [OrganizationUserRoles.READER]: {
    label: 'Reader',
    color: Colors.lightGrey,
  },
  [OrganizationUserRoles.EDITOR]: {
    label: 'Editor',
    color: Colors.jungleGreen,
  },
  [OrganizationUserRoles.ADMINISTRATOR]: {
    label: 'Administrator',
    color: Colors.lightOrange,
  },
};

export const getRoleChoice = (roles?: Role[]): Choice =>
  roles?.some((item) => item.name === 'HubSync Admin')
    ? roleChoices[TenantAdminRoles.TENANT_ADMIN]
    : roleChoices[TenantAdminRoles.ORGANIZATION_USER];

export const getRoleId = (roles?: Role[]): string =>
  roles?.some((item) => item.name === 'HubSync Admin')
    ? TenantAdminRoles.TENANT_ADMIN.toString()
    : TenantAdminRoles.ORGANIZATION_USER.toString();
