import React from 'react';
import './List.scss';
import svgIcons from 'styles/svgIcons';
import Typography, { Variant } from 'components/Typography';

const ListItem = ({ id, active, icon, title, onClick }): JSX.Element => {
  const Icon = icon;
  return (
    <div id={id} className={`account-tab-list-item ${active ? 'active' : ''}`} onClick={onClick}>
      <Icon className={'atbli-icon'} />
      <Typography
        variant={Variant.SubHeading}
        className={'atbli-title'}
      >
        {title}
      </Typography>
    </div>
  );
};

export const List = ({ active, navigateTo, permissions }): JSX.Element => {
  return (
    <div className={'account-tab-list'}>
      <ListItem id="navGeneral" onClick={() => navigateTo('general')} active={active == 'general'} icon={svgIcons.Settings} title={'General'} />
      {permissions.includes('notifications:list') && <ListItem id="navNotifications" onClick={() => navigateTo('notifications')} active={active == 'notifications'} icon={svgIcons.HeaderNotifications} title={'Notifications'} />}
    </div>
  );
};
