import cn from 'classnames';
import { injectGlobal } from 'styled-components';

import {
  AG_CELL_WRAPPER,
  EXPAND_RECORD,
  ROW_INDEX_VALUE,
  ROW_NUMBER_ID,
  SELECTABLE_ROW,
} from '../../DataGrid/columns/constants';
import { Color, Size } from '../../IconButton';
import IconButton, { iconType } from '../utils/IconButton';

import './idRenderer.scss';
import { selectedView } from 'data/views/views.selectors';
import { createModalUrl } from 'utilities/createUrl';
import { push } from 'connected-react-router';
import { rowIndexMap } from 'data/collections/collections.selectors';
let store = window.appStore;

const InsertPosition = {
  ABOVE: 'above',
  BELOW: 'below',
};

function IdRenderer() {
  return true;
}

function select(state) {
  return selectedView(state).permissions;
}

function selectRowIndexMap(state) {
  return rowIndexMap(state);
}

const getRowIndex = (params) => {
  const rowIndexMap = selectRowIndexMap(store.getState());
  const { columnApi, node } = params;
  if (node.group) return '';
  const activeGroups = columnApi?.getRowGroupColumns() || [];
  const currentIndex = activeGroups.length === 0
    ? node.rowIndex
    : rowIndexMap[node.data.id] !== undefined ? rowIndexMap[node.data.id] : -1;
  return currentIndex >= 0 ? (currentIndex + 1).toString() : '';
};

IdRenderer.prototype.init = function(params) {
  store = window.appStore;
  const permissions = select(store.getState());

  const wrapper = document.createElement('div');
  wrapper.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  let className = 'wrapper';
  if (!(permissions || []).includes('databases:create') &&
    !(permissions || []).includes('files:create')) {
    className += ` noCreatePermission`;
  }

  const idWrapper = document.createElement('div');
  idWrapper.className = className;

  /**
   * VALUE DIV
   */
  const rowIndexValue = document.createElement('div');
  rowIndexValue.className = ROW_INDEX_VALUE;
  rowIndexValue.innerText = getRowIndex(params);
  this.rowIndexValue = rowIndexValue;
  idWrapper.appendChild(rowIndexValue);

  if (params.expandRecordEnabled) {
    /**
     * ICONS
     */
    const iconsWrapper = document.createElement('div');
    iconsWrapper.className = cn('rowTools', EXPAND_RECORD);
    const aboveBelowWrapper = document.createElement('div');
    aboveBelowWrapper.className = 'above-below-icons_wrapper';
    /**
     * Event Handlers
     * */
    const validateInsertRecord = (position) => {
      const { rowIndex, id, data } = params.node;
      store.dispatch({
        type: 'SET_ID_RENDERER_PARAMS',
        payload: {
          actionType: 'validateInsertRecord',
          insertPosition: position,
          node: { rowIndex, id, data },
        },
      });
      setTimeout(() => {
        // dirty fix, remove hover classes because of ag grid not refreshing
        const hoveredRows = document.querySelectorAll('.ag-row-hover');
        if (hoveredRows) {
          for (let i = 0; i < hoveredRows.length; i++) {
            hoveredRows[i].classList.remove('ag-row-hover');
          }
        }
        const rowPosition = position === InsertPosition.ABOVE ? rowIndex : rowIndex + 1;
        params.api.setFocusedCell(rowPosition, ROW_NUMBER_ID);
      }, 0);
    };

    this.expandRecord = () => {
      const state = store.getState();
      const { node } = params;
      store.dispatch(push(createModalUrl(state.router.location.pathname, node.id)));
    };

    const addAboveIcon = IconButton({
      color: Color.DarkGray,
      size: Size.ExtraSmall,
      className: 'insertRowAboveButton',
      iconClassName: 'iconExtraSmall',
      icon: iconType.ADD,
      onclick: this.insertAboveOnClick,
    });
    this.insertAboveOnClick = () => {
      validateInsertRecord(InsertPosition.ABOVE);
    };
    addAboveIcon.addEventListener('click', this.insertAboveOnClick);
    addAboveIcon.setAttribute('id', 'insertRowAboveButton'+params.node.id);
    this.addAboveIcon = addAboveIcon;
    aboveBelowWrapper.appendChild(addAboveIcon);

    if (!isNaN(parseInt(params.node.id))) {
      const expandRecordIcon = IconButton({
        color: Color.DarkGray,
        size: Size.Medium,
        className: 'expandRecordButton',
        iconClassName: 'iconMedium',
        icon: iconType.EXPAND,
      });
      expandRecordIcon.addEventListener('click', this.expandRecord);
      expandRecordIcon.setAttribute('id', params.node.id);
      this.expandRecordIcon = expandRecordIcon;
      iconsWrapper.appendChild(expandRecordIcon);
    }
    this.addBelowIcon = IconButton({
      color: Color.DarkGray,
      size: Size.ExtraSmall,
      className: 'insertRowBelowButton',
      iconClassName: 'iconExtraSmall',
      icon: iconType.ADD,
      onclick: this.insertBelowOnClick,
    });
    this.insertBelowOnClick = () => {
      validateInsertRecord(InsertPosition.BELOW);
    };
    this.addBelowIcon.addEventListener('click', this.insertBelowOnClick);
    this.addBelowIcon.setAttribute('id', 'insertRowBelowButton'+params.node.id);
    aboveBelowWrapper.appendChild(this.addBelowIcon);

    if (params.colDef.rowDrag) {
      iconsWrapper.appendChild(aboveBelowWrapper);
    }

    idWrapper.appendChild(iconsWrapper);
  }
  wrapper.appendChild(idWrapper);

  if (params.node?.data?.messageCount) {
    const messageCount = document.createElement('div');
    messageCount.className = 'item-message-count';
    messageCount.textContent = params.node.data.messageCount;
    wrapper.appendChild(messageCount);
  }

  this.element = wrapper;
};

IdRenderer.prototype.getGui = function() {
  return this.element;
};

IdRenderer.prototype.refresh = function(params) {
  return false;
};

IdRenderer.prototype.render = function(params) {
  return true;
};

IdRenderer.prototype.destroy = function() {
  if (this.addAboveIcon) {
    this.addAboveIcon.removeEventListener('click', this.insertAboveOnClick);
  }
  if (this.addBelowIcon) {
    this.addBelowIcon.removeEventListener('click', this.insertBelowOnClick);
  }
  if (this.expandRecordIcon) {
    this.expandRecordIcon.removeEventListener('click', this.expandRecord);
  }
  return true;
};

export default IdRenderer;

injectGlobal`
  .${SELECTABLE_ROW} {
    .${ROW_NUMBER_ID} {
      .${AG_CELL_WRAPPER} {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
      }

      .row-index-value {
        padding-left: 20px;
      }

      .ag-selection-checkbox {
        display: none;
      }
    }

    &.ag-row-selected {
      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE} {
          display: none;
        }
      }
    }

    .${EXPAND_RECORD} {
      display: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .${EXPAND_RECORD} * {
      outline: none !important;
      box-shadow: none !important;
    }

    .item-message-count {
      font-size: 11px;
      color: #0065FF;
      width: 18px;
      height: 18px;
      text-align: center;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      padding-top: 3px;
      background-color: rgba(0,101,255,0.1);
    }

    &.ag-row-hover {

      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE}, .item-message-count {
          display: none;
        }

        .${EXPAND_RECORD} {
          justify-content: space-between;
          display: flex;
          width: 43px !important;
        }

        .above-below-icons_wrapper {
          width: 14px;
          height: 100%;
          display: inline-flex;
          margin-right: -1px;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
`;
