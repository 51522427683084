import * as React from 'react';
import Modal from 'components/Modals/Modal';
import Confirm from 'components/Modals/Confirm';

interface OwnProps {
  onClose: () => void;
  onConfirm: () => void;
}

class DeleteMessageModal extends React.Component<OwnProps> {
  render() {
    return (
      <Modal id="deleteMessageModal" onClose={this.props.onClose}>
        <Confirm
          title="Delete this Message"
          message="Are you sure you want to delete this message? This cannot be undone."
          close={this.props.onClose}
          onConfirm={this.props.onConfirm}
          confirmLabel="Delete"
        />
      </Modal>
    );
  }
}

export default DeleteMessageModal;
