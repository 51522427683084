import styled from 'styled-components';
import { hubsyncBlue, white } from 'styles/colors';

export const BORDER_WIDTH = 1;

export const Wrapper = styled.div`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overflow: auto;
  box-shadow: none;
  left: -${BORDER_WIDTH}px;
  right: -${BORDER_WIDTH}px;

  border: ${BORDER_WIDTH}px solid ${hubsyncBlue};
  background: ${white};
  border-radius: 2px;

  line-height: normal;
`;

export const Inner = styled.div<{ showPreview?: boolean }>`
  padding: 4px 16px;
  display: flex;
  flex-direction: column;
  align-items: ${({ showPreview = false }) => showPreview ? 'center' : 'flex-start'};
  justify-content: center;

  button {
    align-self: flex-end;
  }
`;
