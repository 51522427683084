export const WORKSPACE_STATUS_COL_ID = '66';
export const ORGANIZER_STATUS_COL_ID = '70';
export const EL_STATUS_COL_ID = '68';
export const FIELD_ID = `fields.${WORKSPACE_STATUS_COL_ID}`;
export const ORGANIZER_FIELD_ID = `fields.${ORGANIZER_STATUS_COL_ID}`;
export const CLIENT_ID_FIELD_NAME = 'clientId';
export const CLIENT_ID_COL_ID = '1';
export const CLIENT_ID_FIELD_ID = `fields.${CLIENT_ID_COL_ID}`;
export const STATUS_PENDING = 'Pending';
export const ORGANIZER_RETURN_TYPE = 'i';
