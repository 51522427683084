import { Field } from 'components/Kanban/cards/Task/CardBody';
import { TaskItemState } from 'components/Kanban/cards/Task/TaskItem';
import getFieldMetadata from 'components/NodeFieldData/getFieldMetadata';
import { GridColumnState } from 'data/collections/collections.reducer';
import { find } from 'lodash';
import * as _ from 'lodash/fp';
import { ColumnsMapById } from 'types/gridOptions';
import { CommonNode, FieldValue } from 'types/response';
import { FieldType } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import { CollectionTypes, Column, Schema, SchemaProperty } from 'types/schema';

import { UNNAMED_RECORD_TITLE } from './common';


export const getNodeFieldValue = (node: CommonNode, fieldKey: string): FieldValue | undefined => {
  const fieldValue = _.get(fieldKey, node);
  if (fieldValue !== null && typeof fieldValue === 'object' && typeof fieldValue.timestamp === 'number') {
    return fieldValue.timestamp;
  }
  return fieldValue;
};

export const getUserDefinedFieldKey = (str = '') => str.replace('fields.', '');
export const createUserDefinedFieldKey = (str: string) => str.startsWith('fields.') ? str : `fields.${str}`;

export const getPrimaryField = () => {
  const store = window.appStore.getState();
  const fields = store.collections.collections.schema.properties.fields.properties;
  return find(fields, { isPrimary: true });
};

export const getInitials = (displayName?: string): string => {
  const [firstName = '', lastName = ''] = displayName?.split(' ') ?? ['', ''];
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

interface CalculateFieldsOptions {
  task: CommonNode;
  columnsState: GridColumnState[];
  schemaColumns: ColumnsMapById;
  view: ViewNode;
}

export const calculateFields = ({ task, columnsState = [], schemaColumns, view }: CalculateFieldsOptions): TaskItemState => {
  const fields: Field[] = [];
  const titleFieldId = view.titleFieldID || 'title';

  columnsState
    .filter(item => !item.hide && schemaColumns[item.colId])
    .forEach((item) => {
      if (item.colId !== titleFieldId && task.fields) {
        const value = _.get(item.colId, task);
        const schemaColumn: Column | undefined = schemaColumns[item.colId];
        const field: Field = {
          fieldType: schemaColumn.fieldType,
          id: item.colId,
          name: schemaColumn.name,
          value,
          meta: getFieldMetadata(schemaColumn),
        };

        fields.push(field);
      }
    });

  return {
    title: _.get(titleFieldId, task) || UNNAMED_RECORD_TITLE,
    fields,
  };
};

export const insertAt = <T>(array: T[], index: number, item: T): T[] => {
  return [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
  ];
};

export const removeAt = <T>(array: T[], index: number): T[] => {
  return array.filter((_, i) => i !== index);
};

export const moveItem = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const fromItem = array[fromIndex];

  return insertAt(removeAt(array, fromIndex), toIndex, fromItem);
};

export const groupById = (collection: any, iteratee = 'id') => {
  const byId = _.keyBy(iteratee, collection);
  const ids = _.keys(byId);

  return {
    byId,
    ids,
  };
};

export function createPartialNodeFromDefaultSchemaValues<Node extends CommonNode>(schema: Schema): Partial<Node> {
  const node: Partial<Node> = {};

  const userDefinedColumns = schema.properties.fields.properties;
  if (userDefinedColumns) {
    node.fields = _.mapValues<SchemaProperty, any>(prop => prop.default)(userDefinedColumns);
  }

  return node;
}

export function isUserDefinedFieldKey(field = '') {
  return field.startsWith('fields.');
}

export const getSingularCollectionName = (collectionType: CollectionTypes): string => {
  switch (collectionType) {
    case CollectionTypes.workspaces:
      return 'Workspace';
    case CollectionTypes.databases:
      return 'Database';
    case CollectionTypes.taskdbs:
      return 'Task Database';
    case CollectionTypes.items:
      return 'Record';
    case CollectionTypes.tasks:
      return 'Task';
    default:
      return 'unsupported collection type';
  }
};

export const getPluralCollectionName = (collectionType: CollectionTypes): string => {
  switch (collectionType) {
    case CollectionTypes.workspaces:
      return 'Workspaces';
    case CollectionTypes.databases:
      return 'Databases';
    case CollectionTypes.taskdbs:
      return 'Tasks';
    case CollectionTypes.items:
      return 'Records';
    case CollectionTypes.tasks:
      return 'Tasks';
    default:
      return 'unsupported collection type';
  }
};

export const getFieldsCount = (fieldsState: GridColumnState[], view: ViewNode): number => fieldsState
  .reduce<number>(
    (previous: number, current: GridColumnState) =>
      current.hide || current.colId === view.titleFieldID ? previous : previous + 1,
    0,
  );

export const getMultiLineFieldCount = (fieldsState: GridColumnState[], view: ViewNode, multipleChoiceColumns: ColumnsMapById): number => fieldsState
  .reduce<number>(
    (previous: number, current: GridColumnState) =>
      current.hide || current.colId === view.titleFieldID || !multipleChoiceColumns[current.colId] || multipleChoiceColumns[current.colId].fieldType !== 'multilinetext' ? previous : previous + 1,
    0,
  );

export const reOrderRows = (
  nodeId: string,
  fromPosition: number,
  toPosition: number,
  rowOrder: Record<string, number>,
) => {
  if (fromPosition == toPosition) {
    return rowOrder;
  }

  // If we're moving the row down in the grid, then we're essentially
  // removing the row and moving everything below it up, then placing
  // the row above the toPosition (which is toPosition-1).
  if (fromPosition < toPosition) {
    toPosition--;
  }

  let customRowOrder: Record<string, number> = _.cloneDeep(rowOrder);
  if (!_.isEmpty(rowOrder)) {
    for (const [rowID, index] of Object.entries(customRowOrder)) {
      if (toPosition <= index && index <= fromPosition) {
        customRowOrder[rowID] = index + 1;
      } else if (fromPosition <= index && index <= toPosition) {
        customRowOrder[rowID] = index - 1;
      }
    }

    customRowOrder[nodeId] = toPosition;
  } else {
    customRowOrder = {
      [nodeId]: toPosition,
    };
  }

  return customRowOrder;
};

/**
 * Check if the column type supports markup
 *
 * @param fieldType - the type of the column
 * @return
 */
export const fieldTypeWithMarkup = (fieldType: string): boolean => {
  return fieldType === FieldType.Singlelineoftext ||
    fieldType === FieldType.Multilinetext;
};
