import { IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';
import * as React from 'react';
import SelfSelectingInput from 'components/SelfSelectingInput';
import { FieldType } from 'types/response/fieldNode';
import qs from 'querystring';

type Props = IFloatingFilterParams;

interface State {
  value: string;
  viewId?: string;
}

class AgGridTextFloatingFilter extends React.PureComponent<Props, State> implements IFloatingFilter {
  isFocused = false;
  private timeout = 0;

  public state = {
    value: '',
    viewId: undefined,
  };

  public componentDidMount(): void {
    const searchFilters = window.appStore && window.appStore.getState().gridOptions.filterModel.searchFilters;
    setTimeout(() => {
      const field = this.props.column.getColDef().field as string;
      if (searchFilters[field]) {
        this.setState({ value: searchFilters[field] });
      }
    }, 0);
  }

  public onParentModelChanged(parentModel: string | null): void {
    const queryString = qs.parse(window.location.search.slice(1));
    if (queryString.viewId === this.state.viewId && !this.isFocused && this.state.value && !parentModel) {
      const model = this.props.api.getFilterModel();
      const field = this.props.column.getColDef().field as string;
      if (model[field]) return;
      model[field] = this.state.value;
      this.props.api.setFilterModel(model);
      return;
    }

    if (this.isFocused) return;

    let value = parentModel;
    if (!this.isFocused && parentModel) {
      value = parentModel.trimLeft();
    }

    this.setState({
      value: value || '',
      viewId: queryString.viewId as string || undefined,
    });
  }

  public isFilterActive(): boolean {
    return this.isFocused;
  }

  public render(): JSX.Element {
    return (
      <SelfSelectingInput
        value={this.state.value}
        onChange={this.handleInputChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={{ width: '100%' }}
      />
    );
  }

  private onFocus = (): void => {
    this.isFocused = true;
    this.props.api.clearFocusedCell();
    this.props.api.clearRangeSelection();
  };

  private onBlur = (): void => {
    this.isFocused = false;
  };

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const refData = this.props.column.getColDef().refData;
    const fieldType = refData && refData.fieldType;
    if (value !== '' &&
      fieldType === FieldType.Rating &&
      (isNaN(parseInt(value)) || (parseInt(value) < 1 || parseInt(value) > 5))
    ) {
      return;
    }
    this.setState({ value });
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.props.parentFilterInstance(instance => {
        instance.setModel(value);
        this.props.api.onFilterChanged();
      });
    }, 500);
  };
}

export default AgGridTextFloatingFilter;
