import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Document } from 'types/schema';
import { AttachmentNode } from 'types/response/attachmentNode';
import { MODULE } from './constants';
import * as services from './services';
import * as types from './types';

export const getAttachments = createAsyncThunk<
    types.AllAttachmentsPayload,
    { workspaceId: string; databaseId: string; sheetId: string }
>(
  `${MODULE}/FETCH_ALL_ATTACHMENTS_BY_ITEMS`,
  async ({ workspaceId, databaseId }) => {
    const response = await services.fetchAttachments(workspaceId, databaseId);
    return { response: response.body };
  },
);

export const getAttachment = createAsyncThunk<
    types.AttachmentPayload,
    Document
>(
  `${MODULE}/GET_ATTACHMENT`,
  async ({ uri, id }) => services.fetchAttachmentByURI(id, uri),
);

export const updateAttachment = createAsyncThunk<
    AttachmentNode,
    types.PatchAttachmentPayload
>(
  `${MODULE}/UPDATE_ATTACHMENT`,
  async ({ workspaceID, id, attachment }) =>
    services.patchAttachment(workspaceID, id, attachment),
);

export const deleteAttachment = createAsyncThunk<
    types.AttachmentPayload,
    types.AttachmentPayload
>(
  `${MODULE}/DELETE_ATTACHMENT`,
  async ({ uri, id }) => services.deleteAttachment(uri, id),
);

export const changeSortAttachments = createAsyncThunk<
    AttachmentNode[],
    types.ChangeOrderAttachmentPayload
>(
  `${MODULE}/CHANGE_SORT_ORDER`,
  async ({ sortItems, workspaceID }) => {
    const response = await services.patchAttachmentsBulk(sortItems, workspaceID);
    return response.body;
  },
);

export const setAttachmentStatus = createAction<
    { status: string; rowId: string | null }
>(`${MODULE}/SET_STATUS`);

export const openPreviewAttachments = createAction<
    types.PreviewAttachmentsPayload
>(`${MODULE}/OPEN_PREVIEW_ATTACHMENTS`);

export const closePreviewAttachments = createAction(
  `${MODULE}/CLOSE_PREVIEW_ATTACHMENTS`,
);

export const indexAttachment = createAction<
    types.FileModel
>(`${MODULE}/INDEX_ATTACHMENT`);
