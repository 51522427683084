import * as React from 'react';
import svgIcons from 'styles/svgIcons';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from 'components/SearchBar/SearchBar.style';
import cn from 'classnames';
import SelfSelectingInput from 'components/SelfSelectingInput';

export enum Size {
  Regular,
  Small,
}

interface SearchBarProps extends WithStyles<typeof styles> {
  value: string;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  size?: Size;
  autoFocus?: boolean;
  className?: string;
  tabIndex?: number;
  onKeyDown?: (e: any) => void;
}

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const { value, onChange, placeholder, tabIndex, size = Size.Regular, autoFocus, className, classes } = props;
  const iconClass = size === Size.Regular ? classes.iconRegular : classes.iconSmall;
  const inputClass = size === Size.Regular ? classes.inputRegular : classes.inputSmall;

  return (
    <div className={cn(className, classes.root)}>
      <svgIcons.Search className={iconClass} />
      <SelfSelectingInput
        id="txtSearchInput"
        tabIndex={tabIndex || 0}
        className={inputClass}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        onKeyDown={props.onKeyDown || undefined}
      />
    </div>
  );
};

export default withStyles(styles)(SearchBar);
