import * as React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import * as Colors from 'styles/colors';

import ChipComponent from '../../ui/Chip';
import { SvgIcon, SvgIconProps } from 'types/common';

export const Title = styled.h4`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.charocalGrey};
  margin-bottom: 22px;
`;

interface UploaderIconProps extends SvgIconProps {
  icon: SvgIcon;
  active?: boolean;
}

export const UploaderIcon =
  styled<UploaderIconProps>(({ icon: Icon, active, ...props }) => <Icon {...props} />)`
    color: ${({ active }) => active ? Colors.hubsyncBlue : Colors.slateTwo};
    width: 20px;
  `;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 13px;
  opacity: 0.5;
`;

export const Chip = styled(ChipComponent)`
  height: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 3px;
  background-color: ${Colors.deprecatedFileChipBackground};
  color: ${Colors.slate};
  border-radius: 4px;
  max-width:11.25rem;
`;

export const MoreChip = styled(Chip).attrs({ disableShrink: true })`
  color: ${Colors.blueyGreyDark};
`;
