import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { FieldRendererProps } from 'components/NodeFieldData/Fields.types';
import wrapCellRenderer from 'components/NodeFieldData/components/wrapCellRenderer';
import { styles, DropdownIcon } from 'components/NodeFieldData/dropdown/DropdownRenderer.style';
import { Choice } from 'types/schema';


interface ChoiceRendererProps extends FieldRendererProps {
    choices: { [key: string]: Choice };
    allowMultiple?: boolean;
    renderChoice?: (choice: Choice, className?: string) => React.ReactNode;
}

type Props = ChoiceRendererProps & WithStyles<typeof styles>;

const DropdownRenderer = (props: Props): JSX.Element => {
  return (
    <div className={props.classes.wrapper}>
      <span className={props.classes.label}>
        {props.choices && props.choices[props.value] && props.choices[props.value].label}
      </span>
      <DropdownIcon />
    </div>
  );
};

export default wrapCellRenderer(withStyles(styles)(DropdownRenderer));
