import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey =
  | 'mfaFormContainer'
  | 'topContent'
  | 'textContainer'
  | 'radioButtonContainer'
  | 'inputSelected'
  | 'itemTitle'
  | 'itemSubtitle'
  | 'continueActionButton'
  | 'helpActionButton'
  | 'fieldTextCode'
  | 'textInput'
  | 'topContainer'
  | 'textContainerCode'
  | 'textSpanCode'
  | 'helperText'
  | 'textLabel'
  | 'textSpan'
  | 'textSpanRight'
  | 'backToMFAButton'
  | 'textSpanLeft'
  | 'textNormal'
  | 'textNormalLight'
  | 'textLink'
  | 'textLinkReset'
  | 'textSMSContainer'
  | 'verifyActionButton'
  | 'topContentPhone'
  | 'textLabelPhone'
  | 'textContainerCodePhone'
  | 'backtActionButton'
  | 'needHelpReset'
  | 'backtActionButtonReset'
  | 'textResetContainer'
  | 'verifyActionButtonbakertilly';

export const styles: Record<ClassKey, CSSProperties> = {
  topContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mfaFormContainer: {
    color: '#212121',
    padding: '0 3rem 2rem 3rem',
  },

  topContent: {
    'display': 'flex',
    'textAlign': 'center',
    'flexDirection': 'column',

    '& h3': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      padding: '0',
      margin: '0 0 0.5rem',
      color: '#212121',
    },

    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.1.rem',
      fontSize: '0.8rem',
      letterSpacing: '0.03rem',
      padding: 0,
    },
  },

  textContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 11,
    marginLeft: '0.5rem',
  },

  textContainerCode: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flex: 11,
    justifyContent: 'space-between',
  },

  textContainerCodePhone: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flex: 11,
    justifyContent: 'space-between',
    marginTop: '8px',
  },

  radioButtonContainer: {
    'flex': 1,
    'alignItems': 'flex-start',

    '& span': {
      padding: 0,
    },

    '& input[type="radio"]:checked:after': {
      width: '15px',
      height: '15px',
      borderRadius: '15px',
      top: '-2px',
      left: '-1px',
      position: 'relative',
      backgroundColor: '#ffa500',
      color: '#ffa500',
      content: '',
      display: 'inline-block',
      visibility: 'visible',
      border: '2px solid white',
    },
  },

  inputSelected: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.8rem',
  },

  itemTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#212121',
    letterSpacing: '0.04rem',
  },

  itemSubtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.1rem',
    fontSize: '0.8rem',
    color: '#4A4A4A',
    letterSpacing: '0.04rem',
    textAlign: 'left',
    marginTop: '0.3rem',
  },

  continueActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
  },

  helpActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
  fieldTextCode: {
    display: 'flex',
    flexDirection: 'row',
  },
  textInput: {
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid #A19F9D',
    boxSizing: 'border-box',
    borderRadius: '2px',
    paddingLeft: '8px',
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '318px',
    marginTop: '8px',
    marginBottom: '8px',
    fontSize: '14px',
  },

  textSpanCode: {
    color: '#A80000',
    marginLeft: '0.3rem',
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    fontWeight: 'bold',
  },
  helperText: {
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.5px !important',
    marginBottom: '24px',
    color: '#4A4A4A',
    fontWeight: 500,
  },
  textLabel: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  textSpan: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    float: 'left',
  },

  textSpanRight: {
    '& span': {
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
      float: 'right',
      color: '#0065FF',
    },
  },

  needHelpReset: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    color: '#0065FF',
    cursor: 'pointer',
  },

  textSpanLeft: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    float: 'left',
    color: '#0065FF',
    cursor: 'pointer',
  },
  backToMFAButton: {
    fontSize: '14px',
    width: '100%',
    justifyContent: 'center',
    marginTop: '-3px',
    borderRadius: '0.25rem',
    borderTop: '1px solid #EDEBE9',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontWeight: 500,
  },
  textNormal: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#4A4A4A',
    marginTop: '8px',
  },
  textNormalLight: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#4A4A4A',
    marginTop: '8px',
  },
  textLink: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#0065FF',
    cursor: 'pointer',
  },
  textLinkReset: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#0065FF',
    cursor: 'pointer',
    marginTop: '4px',
  },
  textSMSContainer: {
    textAlign: 'center',
  },

  textResetContainer: {
    'textAlign': 'center',
    'marginTop': '15px',
    '& a': {
      '& span': {
        fontSize: '13px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        color: '#0065FF',
      },
    },
  },

  verifyActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    marginBottom: '8px',
  },

  verifyActionButtonbakertilly: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    marginBottom: '8px',
    backgroundColor: '#D1EC51 !important',
    color: 'black !important',
  },

  backtActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    marginBottom: '8px',
    border: '1px solid #9E9E9E',
    boxSizing: 'border-box',
    color: '#4A4A4A',
  },

  backtActionButtonReset: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    marginBottom: '8px',
    border: '1px solid #9E9E9E',
    boxSizing: 'border-box',
    color: '#4A4A4A',
    backgroundColor: 'white',
  },

  topContentPhone: {
    'display': 'flex',
    'textAlign': 'center',
    'flexDirection': 'column',

    '& h3': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      padding: '0',
      margin: '0 0 0.5rem',
      color: '#212121',
    },

    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.1.rem',
      fontSize: '0.8rem',
      letterSpacing: '0.03rem',
      padding: 0,
    },
  },

  textLabelPhone: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '4px',
  },
};
