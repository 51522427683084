import * as React from 'react';
import { useEffect } from 'react';
import { FieldType } from 'types/response/fieldNode';

import ModalTextField from 'components/Modals/ModalTextField';
import * as keys from 'keycode-js';

import GridTextEditor from '../components/GridTextEditor';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';


const getValue = (props: FieldEditorProps) =>
  props.value || '';

const NormalSingleLineOfTextEditor = React.forwardRef(
  (props: FieldEditorProps, ref: React.RefObject<HTMLInputElement>): JSX.Element => {
    const { onChange, eGridCell, autoFocus, onStopEditing, onBlur, keyPress=0, fieldType } = props;

    if (eGridCell) {
      useEffect(() => {
        if (props.charPress) {
          onChange(props.charPress);
        }

        if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
          onChange('');
        }
      }, []);

      return (
        <GridTextEditor
          id={`txt-grid-${props.id}`}
          inputRef={ref}
          value={getValue(props)}
          onChange={e => onChange(e.target.value)}
          onBlur={onStopEditing}
        />
      );
    } else {
      return (
        <ModalTextField
          id={`txt-modal-${props.id}`}
          value={getValue(props)}
          onChange={e => onChange( fieldType === FieldType.Email ? e.target.value.trim() : e.target.value)}
          autoSelect
          autoFocus={autoFocus}
          disabled={props.readOnly}
          onBlur={onBlur}
        />
      );
    }
  },
);

export default wrapCellEditor()(NormalSingleLineOfTextEditor);
