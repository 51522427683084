export enum ProcessType {
  SUCCESS = 'successed',
  FAIL = 'failed',
}

export interface ProcessPayload {
  ids: string[];
  notify?: boolean;
}

export interface WorkspaceCreateResponse {
  createdAt: number;
}

export interface EngagementLetterResponse {
  status: {
    createdAt: number;
    status: { envelopeID: string };
  }[];
}

export interface ValidWorkspaceReponse {
  body: {
    workspaces: {
      _id: string;
      title: string;
      meta?: {
        returnType: string;
        clientId: string;
      };
    }[];
  };
}

export interface CreateOrganizerPayload {
  workspaceId: string;
  returnType: string;
  clientId: string;
}

export enum OrganizerStatus {
  COMPLETE = 'COMPLETE',
  COMPLETE_ERROR = 'COMPLETE_WITH_ERRORS',
}
