import * as React from 'react';

import { Description, Header, PageContent, PlugLeft, PlugRight, Plugs, PlugWrapper } from './AppErrorBoundary.style';
import Button, { Variant } from '../Button';

export default class ErrorContent extends React.Component<{}> {
  handleRefreshPage = () => {
    window.location.reload();
  };

  render() {
    return (
      <PageContent>
        <Header>Oops...</Header>
        <Description>Something went wrong. Please refresh your page.</Description>
        <Button
          variant={Variant.Primary}
          onClick={this.handleRefreshPage}
          label="Refresh Page"
        />
        <Plugs>
          <PlugWrapper>
            <PlugLeft/>
          </PlugWrapper>
          <PlugWrapper>
            <PlugRight/>
          </PlugWrapper>
        </Plugs>

      </PageContent>
    );
  }
}
