import React, {
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
} from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import IconButton, {
  Color as IconButtonColor,
  Size,
} from 'components/IconButton';
import PreviewBehaviorComponent from 'components/NodeFieldData/attachment/PreviewBehavior';
import { TitleTooltip } from 'components/Tooltip';
import * as actions from 'data/attachments/actions';
import { RoleNames } from 'data/users/users.types';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { KEY_ENTER, KEY_RETURN } from 'keycode-js';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Colors from 'styles/colors';
import getFileIcon from 'styles/fileExtension';
import { fontFamily } from 'styles/fonts';
import svgIcons from 'styles/svgIcons';
import { PermissionTypes, CollectionTypes } from 'types/schema';
import { getFileExtension, getFileNameNoExt } from 'utilities/files';
import { getUserRole } from 'utilities/permissions';

import { AttachmentType } from './constants';
import { PreviewBehavior } from '../../../types/response';
import { AttachmentNode } from '../../../types/response/attachmentNode';
import { TooltipProps, FileInfoTooltip } from './AttachmentPreviewEditor';
interface OwnProps {
  fileName: string;
  displayPreview: boolean;
  onDelete?: () => void;
  permissions: string[];
  size?: string;
  attachment?: AttachmentNode;
  readOnly?: boolean;
  showPreview?: boolean;
  lock?: boolean;
  onClick?: () => void;
  type?: string;
}

interface StateProps {
  permissions: string[];
}

interface DispatchProps {
  saveFileChange: typeof actions.updateAttachment;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<ClassKey>;

const ModalAttachmentItem = (props: Props): JSX.Element => {
  const { fileName, classes, size, attachment, permissions, lock, readOnly, onDelete, saveFileChange, displayPreview, onClick, type } = props;
  const [editNameMode, setEditNameMode] = useState(false);
  const [fileNameValue, setFileTitleValue] = useState(
    getFileNameNoExt(fileName),
  );
  const fileExt = getFileExtension(fileName);
  const computeFileName = () =>
    fileExt ? `${fileNameValue}.${fileExt}` : fileName;
  const tooltipComp = (
    <FileInfoTooltip {...props as TooltipProps} fileName={computeFileName()} />
  );
  const FileIcon = getFileIcon(getFileExtension(fileName));
  const userRole: RoleNames = getUserRole(permissions ?? []);
  const isUserAdmin = userRole === RoleNames.Administrator;
  const isButtonDisabled = isUserAdmin ? false : lock || readOnly;
  const canDelete = isUserAdmin ? true : (permissions?.includes(`${CollectionTypes.files}:${PermissionTypes.delete}`) && !isButtonDisabled);

  const saveFileName = useCallback(() => {
    const { workspaceID = '', _id = '', version = 1 } = attachment ?? {};
    const attachPayload = { title: computeFileName(), version };

    saveFileChange({ attachment: attachPayload, workspaceID, id: _id });
  }, [fileNameValue, fileName]);

  const onEditFileName = useCallback(() => {
    if (!isUserAdmin && lock) return;

    setEditNameMode(true);
  }, []);

  const onHandleDelete = () => {
    if (!isUserAdmin && lock) return;

    onDelete?.();
  };

  const onTitleChange = useCallback((event: ChangeEvent) => {
    const input = event.target as HTMLInputElement;

    setFileTitleValue(input.value);
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onTitleFinishEdit = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const keyCode: number = event.keyCode || Number(event.key);
      if ([KEY_ENTER, KEY_RETURN].includes(keyCode)) {
        setEditNameMode(false);
        saveFileName();
      }
    },
    [fileNameValue],
  );

  const onTitleBlur = useCallback(() => {
    setEditNameMode(false);
    saveFileName();
  }, [fileNameValue]);

  const showEditDeleteButtons = userRole !== RoleNames.Reader;
  const itemContent = (
    <div className={classes.downloadableFile}>
      <div className={classes.overflowMaxModal}>
        {editNameMode ? (
          <div className={classes.editInput}>
            <input
              type="text"
              value={fileNameValue}
              onChange={onTitleChange}
              onKeyUp={onTitleFinishEdit}
              onBlur={onTitleBlur}
              autoFocus={editNameMode}
            />
          </div>
        ) : (
          <TitleTooltip
            interactive
            placement="bottom-start"
            title={tooltipComp}
          >
            <span className={classes.fileName}>{fileName}</span>
          </TitleTooltip>
        )}
      </div>
      {size ? <span className={classes.size}>{size}</span> : null}
    </div>
  );
  return (
    <div className={classes.fileWrapper}>
      <FileIcon className={classes.fileIcon} />
      <div className={classes.textWrapper}>
        {attachment && !displayPreview && (
          <PreviewBehaviorComponent
            attachment={attachment}
            previewBehavior={PreviewBehavior.ModalPreview}
          >
            {itemContent}
          </PreviewBehaviorComponent>
        )}
        {attachment && displayPreview && (
          <div onClick={onClick}>
            {itemContent}
          </div>
        )}
        {!attachment && (
          <>
            <div className={classes.overflowMaxModal}>
              <span className={classes.fileName}>{fileName}</span>
            </div>
            {size ? <span className={classes.size}>{size}</span> : null}
          </>
        )}
      </div>
      {showEditDeleteButtons && type === AttachmentType.UPDATE && <IconButton
        icon={svgIcons.Edit}
        color={!isButtonDisabled ? IconButtonColor.DarkGrayNoHover: IconButtonColor.LightBlueGrey}
        size={Size.Medium}
        onClick={onEditFileName}
        disabled={isButtonDisabled}
      />}
      {type === AttachmentType.UPDATE && <PreviewBehaviorComponent
        attachment={attachment as AttachmentNode}
        previewBehavior={PreviewBehavior.Download}>
        <IconButton
          icon={svgIcons.Download}
          color={IconButtonColor.DarkGrayNoHover}
          size={Size.Medium}
        />
      </PreviewBehaviorComponent>}
      {showEditDeleteButtons && <IconButton
        icon={svgIcons.Delete}
        color={canDelete ? IconButtonColor.DarkGrayNoHover: IconButtonColor.LightBlueGrey}
        size={Size.Medium}
        onClick={onHandleDelete}
        disabled={!canDelete}
      />}
    </div>
  );
};

type ClassKey =
  | 'fileWrapper'
  | 'fileIcon'
  | 'fileName'
  | 'fileIconWrapper'
  | 'editInput'
  | 'textWrapper'
  | 'size'
  | 'downloadableFile'
  | 'overflowMaxModal';

const styles = withStyles<ClassKey>({
  fileWrapper: {
    'display': 'flex',
    'alignItems': 'center',
    'padding': '4px 0',
    'width': '100%',
    '& > button': {
      display: 'none',
    },
    '&:hover': {
      'backgroundColor': 'rgba(233,237,239,0.3)',
      '& > button': {
        display: 'block',
      },
    },
  },
  textWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    marginRight: '10px',
  },
  downloadableFile: {
    '&:hover $fileName': {
      color: Colors.hubsyncBlue,
      cursor: 'pointer',
    },
  },
  size: {
    color: Colors.ghostGrey,
    flex: 1,
    fontSize: '12px',
    fontFamily,
    fontWeight: 'normal',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fileIcon: {
    width: '25px',
    margin: '0 10px 0 14px',
  },
  fileName: {
    color: Colors.brownishGrey,
    flex: 1,
    fontSize: '14px',
    fontFamily,
    fontWeight: 'normal',
    lineHeight: '23px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fileIconWrapper: {
    background: 'none',
  },
  overflowMaxModal: {
    maxWidth: '600px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  editInput: {
    fontSize: '14px',
  },
});

const mapState = (state) => ({
  permissions: getWorkspacePermissions(state),
});

const mapDispatchToProps: DispatchProps = {
  saveFileChange: actions.updateAttachment,
};

const enhance = compose(
  connect(mapState, mapDispatchToProps),
  styles,
);

export default enhance(ModalAttachmentItem);
