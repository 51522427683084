import svgIcons from 'styles/svgIcons';
import { SvgIcon } from 'types/common';
import { FieldType } from 'types/response/fieldNode';

export function getIconForFieldTypeLabel(fieldType: FieldType): SvgIcon {
  switch (fieldType) {
    case FieldType.Account: {
      return svgIcons.Account;
    }

    case FieldType.Attachment: {
      return svgIcons.Attachment;
    }

    case FieldType.Barcode: {
      return svgIcons.Barcode;
    }

    case FieldType.Boolean: {
      return svgIcons.CheckboxForLabel;
    }

    case FieldType.Checklist: {
      return svgIcons.Checklist;
    }

    case FieldType.Currency: {
      return svgIcons.Currency;
    }

    case FieldType.Date:
    case FieldType.DateTime: {
      return svgIcons.Date;
    }

    case FieldType.Email: {
      return svgIcons.Email;
    }

    case FieldType.Float:
    case FieldType.Integer: {
      return svgIcons.Number;
    }

    case FieldType.Singlechoice: {
      return svgIcons.SingleChoice;
    }

    case FieldType.Multiplechoice: {
      return svgIcons.MultipleChoice;
    }

    case FieldType.Singlelineoftext:
    case FieldType.FileName: {
      return svgIcons.SingleLineOfText;
    }

    case FieldType.Multilinetext: {
      return svgIcons.MultilineText;
    }

    case FieldType.Percent: {
      return svgIcons.Percent;
    }

    case FieldType.Phone: {
      return svgIcons.Phone;
    }

    case FieldType.Rating: {
      return svgIcons.Rating;
    }

    case FieldType.Tag: {
      return svgIcons.Tag;
    }

    case FieldType.Url: {
      return svgIcons.Url;
    }

    case FieldType.Button: {
      return svgIcons.Button;
    }

    default: {
      return svgIcons.Info;
    }
  }
}
