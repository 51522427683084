import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { InsertPosition as IDInsertPosition } from 'components/CellRenderers/id/react/IdRenderer';
import { ADD_NEW_FIELD_ID } from 'components/DataGrid/columns/constants';
import { Actions as collectionActions } from 'data/collections/collections.actions';
import { actions as newCollectionActions } from 'data/collections/collections.actions.new';
import * as constants from 'data/collections/constants';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { actions as fileUploadActions } from 'data/ui/fileUpload/fileUpload.actions';
import * as viewsActions from 'data/views/views.actions';
import idx from 'idx';
import produce from 'immer';
import * as _ from 'lodash';
import { isEqual, cloneDeep } from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import { FileAttachment } from 'types/common';
import { FilterModel, SortModel } from 'types/gridOptions';
import { CommonNode, Fields } from 'types/response';
import { DatabaseNode } from 'types/response/databaseNode';
import { DocumentNode, isDocumentNode } from 'types/response/documentNode';
import { FieldNode, FieldType } from 'types/response/fieldNode';
import { WorkspaceNode } from 'types/response/workspaceNode';
import {
  Breadcrumb,
  Choice,
  Collection,
  CollectionTypes,
  convertFieldNodeToSchemaProperty,
  HttpResponseViewport,
  Node,
  Schema,
} from 'types/schema';
import { ActionType, getType } from 'typesafe-actions';
import {
  createUserDefinedFieldKey,
  getUserDefinedFieldKey,
  reOrderRows,
} from 'utilities/collections';
import { isDocumentsUrl } from 'utilities/createUrl';
import { getUrlsLastItem } from 'utilities/queryParams';
import { Omit } from 'utility-types';

import { ViewNode } from '../../types/response/viewNode';
import { actions as databaseActions } from '../databases/databases.actions';
import LoadingState from '../LoadingState';
import { actions as sheetActions } from '../sheets/sheets.actions';
import { Revision } from './collections.actions.new';
import { Checklist } from './collections.types';
import { applyCustomSort } from './customSort';
import * as OrderedCollectionViews from './orderedCollectionViews';

export const newRowIndex = '(New Row)';

const normalizeNodes = (nodes: CommonNode[] = [], schema: Schema) => {
  // need to add fields property for proper work in ag-grid
  if (idx(schema, (_) => _.properties.fields)) {
    nodes.forEach((node) => {
      node.fields = node.fields || {};
    });
  }
  return nodes;
};

export interface ItemHistory {
  undo: Revision[];
  redo: Revision[];
}

export interface CollectionState {
  readonly collections: Collections;
  readonly views: CollectionViews;
  readonly selectedViewId: string | undefined;
  readonly selectedChartViewId: string | undefined;
  readonly currentViewConfig: ViewConfig;
  readonly err: string | object;
  readonly fetching: boolean;
  readonly fields: CollectionFields;
  readonly rowIndexMap: Record<string, number>;
  readonly nodesInCreation: string[];
  readonly checklists: Checklist[];
  readonly checklistError: string;
  readonly nodeLoadingState: LoadingState;
  readonly isDragging: boolean;
  readonly draggingItem: CommonNode | null;
  readonly idRendererParams?: IdRendererParams | null;
  readonly nodesPatchProgress?: any;
  readonly itemHistory: ItemHistory;
  readonly lastUpdatedItem: {
    id: string;
    version: number;
  };
}

export interface Collections {
  breadcrumbs: Breadcrumb[];
  schema: Schema;
  collection?: Partial<Collection>;
  permissions: string[];
  view?: ViewNode;
  viewport: HttpResponseViewport;
}

export interface CollectionViews {
  nodes: OrderedCollectionViews.State;
  totalCount: number;
  loadingState: LoadingState;
}

export interface ColumnSummary {
  type?: string;
  value?: string;
}
export interface GridColumnState {
  colId: string;
  hide: boolean;
  width?: number;
  rowGroupIndex: number | null;
  pinned?: string | null;
  summary?: ColumnSummary;
}

export interface ViewConfig {
  columns: GridColumnState[];
  rowHeight?: number;
  categoryFieldOrder?: string[];
  filters?: FilterModel;
  sorts?: SortModel[];
  customRowOrder: Record<string, number>;
}

export interface CollectionFields {
  permissions: string[];
}

export interface CreateNodeRequestPayload {
  url: string;
  data: Partial<CommonNode> | Partial<DocumentNode>;
  rowPosition?: number;
  initData?: any;
}

export interface DuplicateNodeRequestPayload {
  url: string;
  data: Partial<CommonNode> | Partial<DocumentNode>;
  rowPosition?: number;
  initData?: any;
  onComplete?: (id: string) => void;
}

export interface CreateNodeSuccessPayload {
  node: CommonNode;
  nodeInCreationId?: string;
}

export interface CreateNodeErrorPayload {
  error: string | object;
  nodeInCreationId?: string;
}

export interface ErrorPayload {
  error: string | object;
}

export interface DeletedNodesPayload {
  ids: string[];
}

export interface DeletedNodesErrorPayload {
  error: string | object;
  nodes: CommonNode[];
}

export interface CreateFieldPayload {
  title: string;
  fieldType: string;
  maxLength?: number;
  minLength?: number;
  default?: any;
  allowNegative?: boolean;
  allowMultiple?: boolean;
  precision?: number;
  currencySymbol?: string;
  dateFormat?: string;
  timeFormat?: string | null;
  includeTime?: boolean;
  choices?: Record<string, Choice>;
  choiceOrder?: string[];
  columnIndexInView?: number;
  allowGridAddingOptions?: boolean;
  action?: string;
  label?: string;
  additionalData?: string;
  showPreview?: boolean;
}

export interface DuplicateFieldPayload {
  fieldId: string;
}

export interface UpdateFieldPayload {
  fieldId: string;
  title?: string;
  choices?: Record<string, Choice>;
  choiceOrder?: string[];
  default?: any;
  allowNegative?: boolean;
  allowMultiple?: boolean;
  precision?: number;
  currencySymbol?: string;
  dateFormat?: string;
  timeFormat?: string;
  includeTime?: boolean;
  columnIndexInView?: number;
  isPrimary?: boolean;
  summary?: FieldSummary;
  typeChanged?: boolean;
  originalFieldType?: string;
  allowGridAddingOptions?: boolean;
  showPreview?: boolean;
  lock?: boolean;
}

export interface SaveFieldSuccessPayload {
  field: FieldNode;
  columnIndexInView?: number;
}

export interface CreateCollectionItemRequestPayload {
  url: string;
  data: Partial<Omit<WorkspaceNode | DatabaseNode, 'id'>>;
}

export interface CreateCollectionItemWithAttachmentRequestPayload
  extends CreateCollectionItemRequestPayload {
  fileAttachment: FileAttachment;
}

export interface SetFieldSummary {
  fieldId: string;
  type: string;
}

export interface IdRendererParams {
  actionType: string;
  node: RowNode;
  insertPosition: IDInsertPosition;
}

export interface SetFieldSummaryActionType {
  payload: SetFieldSummary;
  type: string;
}

export interface FieldSummary {
  type: string;
  value: string;
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState: CollectionState = {
  views: {
    nodes: OrderedCollectionViews.defaultViews(),
    totalCount: 0,
    loadingState: LoadingState.Unloaded,
  },
  collections: {
    breadcrumbs: [],
    collection: {},
    schema: {
      properties: {
        fields: {
          name: 'Fields',
          type: 'object',
          properties: {},
        },
      },
    },
    permissions: [],
    view: undefined,
    viewport: {
      nodes: [],
      totalCount: 0,
      returnedCount: 0,
    },
  },
  selectedViewId: undefined,
  selectedChartViewId: undefined,
  currentViewConfig: {
    columns: [],
    customRowOrder: {},
  },
  err: '',
  fetching: false,
  fields: {
    permissions: [],
  },
  rowIndexMap: {},
  nodesInCreation: [],
  checklists: [],
  checklistError: '',
  idRendererParams: null,
  nodeLoadingState: LoadingState.Loaded,
  itemHistory: {
    undo: [],
    redo: [],
  },
  lastUpdatedItem: {
    id: '',
    version: 1,
  },
  draggingItem: null,
  isDragging: false,
};

type ReducerActions = ActionType<
  | typeof collectionActions
  | typeof newCollectionActions
  | typeof viewsActions
  | typeof viewConfigActions
  | typeof databaseActions
  | typeof sheetActions
  | typeof fileUploadActions
>;

export default function collections(
  state = initialState,
  action,
): CollectionState {
  switch (action.type) {
    case constants.CLEAN_COLLECTION:
      return initialState;

    case getType(newCollectionActions.fetchDataCollection.failure): {
      return {
        ...initialState,
        err: action.payload.error,
      };
    }

    case getType(newCollectionActions.fetchDataCollection.request):
      return {
        ...state,
        fetching: true,
      };

    case getType(newCollectionActions.fetchDataCollection.success): {
      const {
        permissions,
        breadcrumbs,
        schema,
        collection,
        viewport,
        views,
        fields,
        selectedViewId,
      } = action.payload;
      const nodes = [...normalizeNodes(viewport.nodes, schema)];

      return {
        ...state,
        fetching: false,
        collections: {
          permissions: permissions || [],
          breadcrumbs: breadcrumbs || [],
          schema,
          collection,
          viewport: {
            ...viewport,
            totalCount: (nodes.length || 0) + 1,
            nodes: nodes,
          },
        },
        views: {
          nodes: [CollectionTypes.documents, CollectionTypes.tasks].includes(
            collection.type,
          )
            ? OrderedCollectionViews.orderedViews([], views.nodes)
            : OrderedCollectionViews.addViews(views.nodes, state.views.nodes),
          totalCount: views.nodes.length,
          loadingState: LoadingState.Loaded,
        },
        fields: {
          permissions: fields.permissions,
        },
        selectedViewId,
      };
    }

    case constants.LOAD_ITEMS_PAGINATE: {
      const {
        items,
        pageInfo,
      } = action.payload;
      const schema = state.collections.schema;
      const nodes = [...normalizeNodes(items, schema)];
      const currentItems = state.collections?.viewport?.nodes;
      const newItems = currentItems.concat(nodes);
      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            totalCount: (newItems.length || 0) + 1,
            nodes: newItems,
            pageInfo,
          },
        },
      };
    }

    case getType(collectionActions.setNodes): {
      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes: action.payload,
          },
        },
      };
    }

    case getType(collectionActions.addNodeInCreation): {
      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes: [...state.collections.viewport.nodes, action.payload],
          },
        },
        nodesInCreation: [...state.nodesInCreation, action.payload.id],
      };
    }

    case constants.CREATE_NODE_SUCCESS: {
      const { nodeInCreationId, node } = action.payload;
      const successNode: CommonNode = { ...node, agGridId: nodeInCreationId };
      let nodes = [...state.collections.viewport.nodes];

      if (nodeInCreationId) {
        const index = nodes.findIndex((node) => node.id === nodeInCreationId);
        delete successNode.agGridId;
        nodes[index] = successNode;
      } else {
        nodes.push(successNode);
      }

      if (isDocumentsUrl(window.location.pathname)) {
        const sorted = _.orderBy(
          nodes,
          [
            function(item) {
              return item.isFolder;
            },
            function(item) {
              return item.createdDate;
            },
          ],
          ['desc', 'asc'],
        );
        nodes = sorted;
      }

      const customRowOrder = { ...state.currentViewConfig.customRowOrder };
      if (nodeInCreationId && customRowOrder[nodeInCreationId] !== undefined) {
        customRowOrder[node.id] = customRowOrder[nodeInCreationId];
        delete customRowOrder[nodeInCreationId];
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes,
          },
        },
        nodesInCreation: nodeInCreationId
          ? state.nodesInCreation.filter((id) => id !== nodeInCreationId)
          : state.nodesInCreation,
        currentViewConfig: {
          ...state.currentViewConfig,
          customRowOrder,
        },
      };
    }

    case constants.CREATE_NODE_ERROR: {
      const { error, nodeInCreationId } = action.payload;
      return {
        ...state,
        err: error,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes: nodeInCreationId
              ? state.collections.viewport.nodes.filter(
                (node) => node.id !== nodeInCreationId,
              )
              : state.collections.viewport.nodes,
          },
        },
        nodesInCreation: nodeInCreationId
          ? state.nodesInCreation.filter((id) => id !== nodeInCreationId)
          : state.nodesInCreation,
      };
    }

    case constants.DELETE_NODE_ERROR:
      return {
        ...state,
        err: action.payload.error,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes: [
              ...state.collections.viewport.nodes,
              ...action.payload.nodes,
            ],
          },
        },
      };

    case constants.COPY_NODE_SUCCESS:
      if (action.meta === CollectionTypes.items) {
        const originalNodeIndex = state.collections.viewport.nodes.findIndex(
          (node) => node.uri === action.payload.copiedFrom,
        );
        const newNodes = [...state.collections.viewport.nodes];
        newNodes.splice(originalNodeIndex, 0, action.payload as Node);

        return {
          ...state,
          collections: {
            ...state.collections,
            viewport: {
              ...state.collections.viewport,
              nodes: newNodes,
            },
          },
        };
      }

      return state;

    case constants.SAVE_FIELD_SUCCESS: {
      const payload: SaveFieldSuccessPayload = action.payload;
      const { field, columnIndexInView } = payload;

      // return {
      //   ...state,
      //   collections: {
      //     ...state.collections,
      //     schema: {
      //       ...state.collections.schema,
      //       properties: {
      //         ...state.collections.schema.properties,
      //         fields: {
      //           ...state.collections.schema.properties.fields,
      //           [field.id]: convertFieldNodeToSchemaProperty(field),
      //         },
      //       },
      //     },
      //   },
      // };

      return produce(state, (draft) => {
        const schema = draft.collections.schema;
        if (!schema.properties.fields) {
          schema.properties.fields = { name: 'Fields', type: 'object' };
        }
        if (!schema.properties.fields.properties) {
          schema.properties.fields.properties = {};
        }
        const columnExists = !!schema.properties.fields.properties[field.id];
        // @ts-ignore
        schema.properties.fields.properties[
          field.id
        ] = convertFieldNodeToSchemaProperty(field);

        if (!columnExists) {
          const columns = draft.currentViewConfig.columns;
          columns.splice(
            columnIndexInView || columns.length - 1,
            0,
            // @ts-ignore
            { colId: createUserDefinedFieldKey(field.id), hide: false, rowGroupIndex: null, width: 150 },
          );
        }

        // Remove single-choice option from nodes if it was updated
        if (field.fieldType === FieldType.Singlechoice) {
          draft.collections.viewport.nodes.forEach((node) => {
            // @ts-ignore
            if (node.fields) {
              // @ts-ignore
              const nodeFieldValue = node.fields[field.id];
              if (
                field.choices &&
                typeof nodeFieldValue === 'string' &&
                !field.choices[nodeFieldValue]
              ) {
                // @ts-ignore
                delete node.fields[field.id];
              }
            }
          });
        }
      });
    }

    case constants.DELETE_FIELD_SUCCESS: {
      const id = action.payload;
      return produce(state, (draft) => {
        if (draft.collections.schema.properties.fields.properties) {
          delete draft.collections.schema.properties.fields.properties[id];
        }
        draft.currentViewConfig.columns = draft.currentViewConfig.columns
          // @ts-ignore
          .filter((column) => getUserDefinedFieldKey(column.colId) !== id);
      });
    }

    case getType(newCollectionActions.updateNode.request):
      return produce(state, (draft) => {
        const { url, data } = action.payload;
        const id = getUrlsLastItem(url);
        draft.nodeLoadingState = LoadingState.Loading;
        draft.draggingItem = null;
        draft.isDragging = false;
        draft.nodesPatchProgress = { ...state.nodesPatchProgress };
        Object.keys(data.fields || {}).forEach((key) => {
          _.set(draft.nodesPatchProgress, `row-${id}.col-${key}`, 'loading');
        });
        const nodes = draft.collections.viewport.nodes;
        // @ts-ignore
        const nodeIndex = nodes.findIndex((node) => node.id === id);
        if (nodeIndex >= 0) {
          // @ts-ignore
          const { fields: currentFields, ...currentProps } = nodes[nodeIndex];
          const { fields: newFields, ...newProps } = data;
          const clonedFields = cloneDeep(currentFields);
          // @ts-ignore
          nodes[nodeIndex] = { ...currentProps, ...newProps, fields: { ...currentFields, ...newFields } };
          if (
            action.payload.mode !== 'undo' &&
            action.payload.mode !== 'redo' &&
            action.payload.data.fields
          ) {
            const newFieldKey = Object.keys(action.payload.data.fields)[0];
            if (clonedFields[newFieldKey] instanceof Date) {
              return;
            }
            // @ts-ignore
            draft.itemHistory.undo = [
              ...draft.itemHistory.undo.slice(-9),
              {
                fieldId: newFieldKey,
                fieldValue: clonedFields[newFieldKey] || '',
                url: action.payload.url,
                isFullObject: action.payload.isFullObject,
              },
            ];
            draft.itemHistory.redo = [];
          }
        }
      });

    case constants.SET_ITEM_HISTORY: {
      return {
        ...state,
        itemHistory: {
          ...state.itemHistory,
          undo: action.undo,
          redo: action.redo,
        },
      };
    }

    case getType(newCollectionActions.updateNode.success):
      return produce(state, (draft) => {
        const { isDragging, draggingItem } = state;
        const { node: item } = action.payload;
        const isStillDrag = isDragging && draggingItem?.id === item.id;

        draft.nodeLoadingState = LoadingState.Loaded;

        if (!isStillDrag) {
          const nodes: CommonNode[] = draft.collections.viewport.nodes;
          const nodeIndex = nodes.findIndex((node) => node.id === item.id);
          if (nodeIndex >= 0) {
            const { fields: currentFields, ...currentProps } = nodes[nodeIndex];
            const { fields: newFields, ...newProps } = item;
            const fields: Fields = action.payload.updatedByCurrentUser
              ? { ...currentFields }
              : { ...currentFields, ...newFields };
            nodes[nodeIndex] = { ...currentProps, ...newProps, fields };
          }
          draft.lastUpdatedItem = {
            id: item.id,
            version: action.payload.node.version || 1,
          };
          draft['nodesPatchProgress'] = _.pickBy(
            Object.keys(action.payload.updatePayload?.fields || {}).forEach(
              (key) => {
                _.unset(
                  draft['nodesPatchProgress'],
                  `row-${item.id}.col-${key}`,
                );
              },
            ),
            _.identity,
          );
        }
      });
    case getType(newCollectionActions.updateNode.failure):
      return produce(state, (draft) => {
        const { error, previousNode, folderBreadcrumbUri } = action.payload;
        draft.nodeLoadingState = LoadingState.Error;
        draft.err = error;

        if (previousNode) {
          if (isDocumentNode(previousNode)) {
            // @ts-ignore
            const breadcrumb = draft.collections.breadcrumbs.find((item) => item.uri === folderBreadcrumbUri);
            // @ts-ignore
            if (breadcrumb) breadcrumb.title = previousNode.fileName;
          }

          const nodes = draft.collections.viewport.nodes;
          // @ts-ignore
          const nodeIndex = nodes.findIndex((node) => node.id === previousNode.id);
          if (nodeIndex >= 0) {
            // @ts-ignore
            nodes[nodeIndex] = previousNode;
          }
        }
      });

    case getType(viewsActions.createView.request):
      return {
        ...state,
        views: {
          ...state.views,
          loadingState: LoadingState.Loading,
        },
      };
    case getType(viewsActions.createView.success): {
      return {
        ...state,
        views: {
          nodes: OrderedCollectionViews.addNewView(
            action.payload,
            state.views.nodes,
          ),
          totalCount: state.views.totalCount + 1,
          loadingState: LoadingState.Loaded,
        },
      };
    }
    case getType(viewsActions.createView.failure):
      return {
        ...state,
        views: {
          ...state.views,
          loadingState: LoadingState.Error,
        },
      };

    case getType(viewsActions.updateView.request): {
      return {
        ...state,
        views: {
          ...state.views,
          loadingState: LoadingState.Loading,
        },
      };
    }
    case getType(viewsActions.updateView.failure): {
      return {
        ...state,
        views: {
          ...state.views,
          loadingState: LoadingState.Loaded,
        },
      };
    }
    case getType(viewsActions.updateView.success): {
      return {
        ...state,
        views: {
          ...updateViewMapping(action.payload, state.views),
          loadingState: LoadingState.Loaded,
        },
        collections: updateCollectionView(action.payload, state.collections),
      };
    }

    case getType(viewsActions.deleteView.request): {
      return {
        ...state,
        views: {
          ...state.views,
          loadingState: LoadingState.Loading,
        },
      };
    }

    case getType(viewsActions.deleteView.success): {
      return {
        ...state,
        views: {
          nodes: OrderedCollectionViews.removeView(
            action.payload.id,
            state.views.nodes,
          ),
          totalCount: state.views.totalCount - 1,
          loadingState: LoadingState.Loaded,
        },
        selectedViewId:
          state.selectedViewId === action.payload.id
            ? undefined
            : state.selectedViewId,
      };
    }

    case getType(viewsActions.setViewOrder): {
      return {
        ...state,
        views: {
          ...state.views,
          nodes:
            action.payload !== null
              ? OrderedCollectionViews.addOrder(
                action.payload,
                state.views.nodes,
              )
              : OrderedCollectionViews.removeOrder(state.views.nodes),
        },
      };
    }

    case getType(sheetActions.selectSheet): {
      return {
        ...state,
        views: {
          ...state.views,
          nodes: OrderedCollectionViews.addOrder(
            action.payload?.viewIDOrder || [],
            state.views.nodes,
          ),
        },
      };
    }

    case getType(viewsActions.selectView): {
      return {
        ...state,
        selectedViewId: action.payload,
      };
    }

    case getType(viewsActions.setDefaultView): {
      return {
        ...state,
        views: {
          ...setDefaultView(action.payload, state.views),
        },
      };
    }

    case getType(viewConfigActions.applyViewConfig): {
      if (
        state.collections.collection &&
        state.collections.collection.type === CollectionTypes.documents &&
        isEqual(state.currentViewConfig.columns, action.payload.columns)
      ) {
        return state;
      }
      const {
        columns,
        rowHeight,
        categoryFieldOrder,
        customRowOrder,
      } = action.payload;
      const { nodes } = state.collections.viewport;

      const adjustedCustomRowOrder = nodes.length ? Object.keys(customRowOrder).reduce<
        Record<string, number>
      >((result, nodeId) => {
        if (nodes.findIndex((node) => node.id === nodeId) >= 0) {
          result[nodeId] = customRowOrder[nodeId];
        }
        return result;
      }, {}) : customRowOrder;

      return {
        ...state,
        currentViewConfig: {
          columns,
          rowHeight,
          categoryFieldOrder,
          customRowOrder: adjustedCustomRowOrder,
        },
      };
    }

    case getType(viewConfigActions.setColumnsState): {
      const oldIndex = action.payload.findIndex(
        (column) => column.colId === ADD_NEW_FIELD_ID,
      );
      const newIndex = action.payload.length - 1;
      const columns =
        oldIndex >= 0
          ? arrayMove(action.payload, oldIndex, newIndex)
          : action.payload;

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          columns,
        },
      };
    }

    case getType(viewConfigActions.setRowHeight):
      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          rowHeight: action.payload,
        },
      };

    case getType(viewConfigActions.setCategoryFieldOrder):
      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          categoryFieldOrder: action.payload,
        },
      };

    case getType(viewConfigActions.setCustomRowOrder):
      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          customRowOrder: action.payload,
        },
      };

    case getType(viewConfigActions.setCustomRowPosition):
    {
      // toPosition == stateNodes.length if we're trying to move the
      // rows to the end of the grid
      const toPosition = action.payload.position;
      const stateNodes = state?.collections?.viewport?.nodes;
      let customRowOrder = state?.currentViewConfig?.customRowOrder;
      const sortedNodes = applyCustomSort(stateNodes, customRowOrder);

      // If we're moving a group of rows, we need to track the ID of the target
      // position so that as we move each node, if the position of the target
      // node changes, we target it's updated position.
      let toId;
      if (toPosition < sortedNodes.length) {
        toId = sortedNodes[toPosition].id;
      }

      const nodeIds = action.payload.nodeIds;

      while (nodeIds.length) {
        const nodeID = nodeIds.shift()!;
        const nodes = applyCustomSort(stateNodes, customRowOrder);
        const fromPosition = nodes.findIndex(node => node.id === nodeID);
        let toIndex;
        if (toPosition < sortedNodes.length) {
          toIndex = nodes.findIndex(node => node.id === toId);
        } else {
          toIndex = nodes.length;
        }

        customRowOrder = reOrderRows(
          nodeID,
          fromPosition,
          toIndex,
          customRowOrder,
        );
      }

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          customRowOrder,
        },
      };
    }

    case getType(viewConfigActions.deleteCustomRowPosition): {
      const customRowOrder = { ...state.currentViewConfig.customRowOrder };
      delete customRowOrder[action.payload];

      return {
        ...state,
        currentViewConfig: {
          ...state.currentViewConfig,
          customRowOrder,
        },
      };
    }

    case getType(fileUploadActions.sendFileMetadata.success): {
      const currentViewport = state.collections.viewport;
      const computedNode: DocumentNode = {
        ...action.payload.node,
        size: action.payload.clientFileSize,
      };

      let nodes: CommonNode[] = [];
      const nodeFromURL =
        computedNode &&
        computedNode.apiURI.match(
          /(?:sheets|tasksheets)\/([a-zA-Z0-9]+)?\/attachments\/[a-zA-Z0-9]+$/,
        );
      if (
        computedNode &&
        computedNode.fieldID &&
        !!computedNode.fieldID.length &&
        nodeFromURL
      ) {
        const node =
          currentViewport.nodes[
            currentViewport.nodes.findIndex(
              (node) => node.id === nodeFromURL[1],
            )
          ];
        if (node && node.fields) {
          // @ts-ignore
          node.fields[computedNode.fieldID.replace('fields.', '')] = [...node.fields[computedNode.fieldID.replace('fields.', '')], computedNode.id];
        }
        currentViewport.nodes[
          currentViewport.nodes.findIndex((node) => node.id === nodeFromURL[1])
        ] = node;
        nodes = currentViewport.nodes;
      } else {
        nodes = [...currentViewport.nodes, computedNode];
      }

      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            nodes: nodes,
            returnedCount: currentViewport.returnedCount + 1,
            totalCount: currentViewport.returnedCount + 1,
          },
        },
      };
    }

    case constants.START_DRAG_ITEM:
      return {
        ...state,
        draggingItem: action.payload,
        isDragging: true,
      };

    case constants.STOP_DRAG_ITEM:
      return {
        ...state,
        draggingItem: null,
        isDragging: false,
      };

    case getType(newCollectionActions.setRowIndexMap):
      return {
        ...state,
        rowIndexMap: action.payload,
      };

    case constants.APPEND_CHECKLIST:
      return {
        ...state,
        checklists: [...state.checklists, action.checklist],
      };
    case constants.SET_CHECKLISTS:
      return {
        ...state,
        checklists: action.checklists,
      };
    case constants.SET_CHECKLIST_ERROR:
      return {
        ...state,
        checklistError: action.errorMessage,
      };
    case constants.RESET_CHECKLISTS:
      return {
        ...state,
        checklists: [],
      };
    case constants.SET_ID_RENDERER_PARAMS:
      return {
        ...state,
        idRendererParams: action.payload,
      };
    // case getType(actions.setLoadingStateTrue): {
    //   return produce(state, draft => {
    //     draft.nodeLoadingState = LoadingState.Loading;
    //   });
    // }
    case constants.UPDATE_ITEMS: {
      const nodes = state.collections.viewport.nodes.map((item) => {
        const fields =
          action.payload.items[item.id] && action.payload.items[item.id].fields;
        if (!fields) return item;
        return {
          ...item,
          fields: {
            ...item.fields,
            ...fields,
          },
        };
      });
      return {
        ...state,
        collections: {
          ...state.collections,
          viewport: {
            ...state.collections.viewport,
            nodes,
          },
        },
      };
    }
    case constants.UPDATE_ITEMS_FIELD_BY_ID: {
      return produce(state, (draft) => {
        const itemsIdMap = action.payload.itemIds.reduce(
          (obj: { [key: string]: boolean }, val: string) => {
            obj[val] = true;
            return obj;
          },
          {},
        );
        // @ts-ignore
        draft.collections.viewport.nodes.filter((node) => itemsIdMap[node.id])
          .forEach((node: Node) => {
            node.fields[action.payload.fieldId] = action.payload.value;
          });
      });
    }
    case constants.SET_CURRENT_VIEW_ID: {
      return produce(state, (draft) => {
        draft.selectedViewId = action.payload;
      });
    }
    default:
      return state;
  }
}

function updateViewMapping(
  viewNode: ViewNode,
  collectionViews: CollectionViews,
): CollectionViews {
  return {
    ...collectionViews,
    nodes: OrderedCollectionViews.updateView(viewNode, collectionViews.nodes),
  };
}

function setDefaultView(viewId: string, collectionViews: CollectionViews): CollectionViews {
  return {
    ...collectionViews,
    nodes: OrderedCollectionViews.setDefaultView(viewId, collectionViews.nodes),
  };
}

function updateCollectionView(
  view: ViewNode,
  collections: Collections,
): Collections {
  return { ...collections, view };
}
