import { get } from 'lodash/fp';
import * as qs from 'qs';

import { normalizeURL } from 'utilities/format';
import { getURLTokens } from 'utilities/authentication';
import { DocumentNode } from 'types/response/documentNode';

function downloadViaATag(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = '';
  a.click();
}

function formatDownloadFolderURL(item: DocumentNode) {
  const pathname = normalizeURL();
  const query = qs.stringify({
    folderID: item.id,
    ...getURLTokens(),
    format: 'blobzip',
  });
  return `${pathname}?${query}`;
}

function formatDownloadFileURL(item: DocumentNode) {
  const pathname = `${normalizeURL(item.uri)}`;
  const query = qs.stringify({
    blob: '',
    ...getURLTokens(),
  });
  return `${pathname}?${query}`;
}

function formatDownloadFilesURL(items: DocumentNode[]): string {
  const pathname = normalizeURL();
  const query = qs.stringify({
    filters: JSON.stringify([{
      fieldID: 'id',
      operation: 'in',
      childValues: items.map(get('id')),
    }]),
    ...getURLTokens(),
    format: 'blobzip',
  });

  const union = (pathname.includes('?')) ? '&' : '?';

  return `${pathname}${union}${query}`;
}

export const downloadRepositoriesFiles = (items: DocumentNode[]) => {
  let url;
  if (items.length === 1) {
    const item = items[0];
    if (item.isFolder) {
      url = formatDownloadFolderURL(item);
    } else {
      url = formatDownloadFileURL(item);
    }
  } else {
    url = formatDownloadFilesURL(items);
  }
  downloadViaATag(url);
};
