import _ from 'lodash';
import { CollectionTypes } from 'types/schema';
import { parseURL } from 'utilities/queryParams';
import { push } from 'connected-react-router';
import { Actions } from '../../collections/collections.actions';
import { mapFieldResponse } from '../../collections/collections.mapper';
import { patchFieldsUrl } from '../../../utilities/createUrl';

class FieldEntity {
  DATABASE_CREATION_COMPLETED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    const {
      [CollectionTypes.databases]: urlDatabaseId,
      [CollectionTypes.workspaces]: urlWorkspaceId,
    } = parseURL(window.location.pathname);

    const {
      workspaceId,
      databaseId,
    } = _.pick(data.item, ['workspaceId', 'databaseId']);

    if (data.eventBy !== currentUserId) {
      return;
    }

    if (!urlDatabaseId && urlWorkspaceId === workspaceId) {
      const databaseType = data?.item?.type === 'task' ? CollectionTypes.taskdbs : CollectionTypes.databases;
      const sheet = data?.item?.type === 'task' ? CollectionTypes.tasksheets : CollectionTypes.sheets;

      setTimeout(() => {
        const databaseUrl = `/workspaces/${workspaceId}/${databaseType}/${databaseId}/${sheet}/default/items`;
        store.dispatch(push(databaseUrl));
      }, 3000);
    }
  }

  updated(data) {
    const store = window.appStore;
    const state = store.getState();
    const {
      [CollectionTypes.databases]: urlDatabaseId,
    } = parseURL(window.location.pathname);

    if (urlDatabaseId === data.item?.requestMeta?.references?.databaseID) {
      const baseUrl = patchFieldsUrl(state, data.item._id);
      const { requestMeta, ...body } = data.item;
      const field = mapFieldResponse({ body }, baseUrl);
      store.dispatch(Actions.saveFieldSuccess({ field }));
    }
  }
}

export default new FieldEntity();
