// @ts-nocheck
import { replace } from 'connected-react-router';
import { accountsById } from 'data/accounts/selectors';
import { fetchNavigationTree } from 'data/app/actions';
import { currentUser } from 'data/app/selectors';
import * as attachmentsActions from 'data/attachments/actions';
import { FileModel } from 'data/attachments/types';
import { attachments } from 'data/attachments/attachments.selectors';
import { Actions } from 'data/collections/collections.actions';
import {
  SaveFieldSuccessPayload,
  SetFieldSummaryActionType,
} from 'data/collections/collections.reducer';
import { nodes, nodesInCreation } from 'data/collections/collections.selectors';
import * as constants from 'data/collections/constants';
import {
  createCollectionItemImportSaga,
  createCollectionItemSaga,
} from 'data/collections/createitem.sagas';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { appendDirectory } from 'data/documents/documents.actions';
import * as modalActions from 'data/modals/actions';
import { actions as reminderActions } from 'data/reminders/reminders';
import { currentSheet } from 'data/sheets/sheets.selectors';
import { uploadAttachments } from 'data/ui/fileUpload/fileUpload.actions';
import { notifications } from 'data/ui/notifications/notifications.actions';
import * as viewsActions from 'data/views/views.actions';
import { patchCustomOrderAction } from 'data/views/views.actions';
import { selectedView } from 'data/views/views.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { getApiV2Url } from 'env';
import * as _ from 'lodash';
import { get, getOr, path } from 'lodash/fp';
import { copyAttachments } from 'pages/Files/Services';
import { stringify } from 'qs';
import { State } from 'reducers';
import { delay } from 'redux-saga';
import {
  call,
  fork,
  put,
  all,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as request from 'superagent';
import { CommonNode } from 'types/response';
import { DocumentNode } from 'types/response/documentNode';
import { FieldNode } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import { CollectionViewTypes, CollectionTypes } from 'types/schema';
import { ActionType, getType, isActionOf } from 'typesafe-actions';
import { uuidv4 } from 'utilities/common';
import { removeHTMLTag } from 'utilities/format';
import {
  createFieldsUrl,
  getCollectionTypeFromUrl,
  patchFieldsUrl,
} from 'utilities/createUrl';
import getAuthHeaders from 'utilities/getAuthHeaders';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from 'utilities/httpRequests';
import { isDB, parseURI } from 'utilities/parseURI';
import {
  getUrlsLastItem,
  parseURL,
  stripUrlsLastItem,
} from 'utilities/queryParams';
import {
  createAPIHandler,
  handleError,
  repeatableFactory,
  RequestType,
  successToast,
  errorToast,
} from 'utilities/saga';
import { prepareFieldIDs } from 'data/views/helpers';
import {
  getGridData,
  getColumnClientId,
} from '../../data/workspaceCreate/helpers';
import {
  validClientId,
  deleteOrganizers,
  deleteWorkspace,
} from '../../data/workspaceCreate/services';
import { columnsStateSelector } from 'data/collections/view-config/viewConfig.selectors';

import { DataGrid } from '../../components/DataGrid';
import { ROW_NUMBER_ID } from '../../components/DataGrid/columns/constants';
import { fieldsValue } from '../../components/NodeFieldData/Fields.value';
import { normalizeURL } from '../../utilities/format';
import ItemsService from '../items/items.service';
import {
  fetchDatabaseSheets,
  selectSheet,
  setCurrentSheetId,
} from '../sheets/sheets.actions';
import * as actions from './collections.actions.new';
import {
  appendChecklist,
  copyCollectionFailure,
  copyCollectionRequest,
  copyCollectionSuccess,
  CreateChecklist,
  DeleteChecklist,
  FetchChecklists,
  fetchCollectionFailure,
  fetchCollectionRequest,
  fetchCollectionSuccess,
  fetchDataCollection,
  UpdateChecklist,
  updateNode,
  UpdatePrimaryField,
  UpdateRecords,
  setItemHistory,
  setRowIndexMap,
  LockColumnAction,
} from './collections.actions.new';
import {
  mapDatabasesResponse,
  mapFieldResponse,
  mapResultNode,
  mapSheetNodesResponse,
  mapSheetResponse,
  mapWorkspacesResponse,
} from './collections.mapper';
import * as types from './collections.types';
import summarizeColumn from './column-summary/summarizeColumn';

export const repeatable = repeatableFactory({});
const ITEMS_AMOUNT_PER_PAGINATE = 120000;

function* getSheet(url, state) {
  const baseUrl = normalizeURL(stripUrlsLastItem(url));
  let sheetsUrl = `${baseUrl.replace('default', '')}?api_version=2`;
  if (!baseUrl.includes('default')) {
    sheetsUrl = `${baseUrl.split('/').slice(0, -1).join('/')}?api_version=2`;
  }
  const result = yield call(getRequest, sheetsUrl, state);
  const defaultSheet = result?.body[0];

  return { sheets: result.body, defaultSheet };
}

function* fetchDataCollectionSaga(
  action: ActionType<typeof fetchDataCollection.request>,
) {
  const DEFAULT_LIMIT = 80000;

  const { selectViewId, queryParams, url } = action.payload;
  const collectionParams = {
    limit: (queryParams && queryParams.limit) || DEFAULT_LIMIT,
    sections: 'breadcrumbs,schema,permissions,collection,viewport',
    filters: JSON.stringify([{}]),
    ...queryParams,
  };
  const collectionUrl = `${url}?${stringify(collectionParams)}`;

  try {
    const state: State = yield select();

    const [viewsResult, fieldsResult, collectionResult] = [{}, {}, {}];
    const yieldData = [];
    const paginatedData = {};

    // NEW API INTEGRATION
    const collectionType = getCollectionTypeFromUrl(action.payload.url);
    if (
      collectionType === CollectionTypes.items ||
      collectionType === CollectionTypes.tasks
    ) {
      // @NOTE disable this for usual sheet request
      const { workspaceId, databaseId } = parseURI(collectionUrl);
      const { sheets, defaultSheet } = yield call(getSheet, url, state);

      const [fieldsBody, viewsBody, collectionBody] = mapSheetResponse(
        defaultSheet,
        collectionUrl,
      );
      fieldsResult.body = fieldsBody;
      viewsResult.body = viewsBody;
      yieldData.push(put(setCurrentSheetId(defaultSheet?._id)));
      yieldData.push(put(
        fetchDatabaseSheets.success({
          sheets: mapSheetNodesResponse({ body: sheets }),
          databaseId,
        }),
      ));
      yieldData.push(
        put(selectSheet(defaultSheet)),
      );
      yieldData.push(
        put(
          attachmentsActions.getAttachments({
            workspaceId,
            databaseId,
            sheetId: defaultSheet?._id,
          }),
        ),
      );
      yieldData.push(
        put(
          reminderActions.fetch({
            workspaceID: workspaceId,
            databaseID: databaseId,
          }),
        ),
      );

      collectionResult.body = collectionBody;

      if (
        collectionResult.body.collection.type === CollectionTypes.items ||
        collectionResult.body.collection.type === CollectionTypes.tasks
      ) {
        try {
          const offset = 0 * ITEMS_AMOUNT_PER_PAGINATE;
          const paginate = { offset, limit: ITEMS_AMOUNT_PER_PAGINATE };
          const { items, pageInfo, error } = yield call(
            ItemsService.fetchPartialItems,
            window.location.pathname,
            defaultSheet?._id,
            paginate,
          );

          if (error) {
            yield call(errorToast, 'There was an error fetching database rows.');
          } else {
            paginatedData.items = items;
            paginatedData.pageInfo = pageInfo;
          }
        } catch (error) {
          yield call(errorToast, error.toString());
        }
      }
      // END NEW API INTEGRATION
    }

    if (collectionResult && collectionResult.body) {
      const viewPermissions: string[] = getWorkspacePermissions(state);
      const viewNodes: ViewNode[] = viewsResult?.body?.viewport?.nodes || [];

      if (collectionResult.body?.view?.synthetic) {
        viewNodes.push(collectionResult.body.view);
      }

      const { schema } = collectionResult.body;
      if (
        schema &&
        schema.properties &&
        schema.properties.fields &&
        fieldsResult &&
        fieldsResult.body &&
        fieldsResult.body.viewport
      ) {
        const { nodes } = fieldsResult.body.viewport;
        if (nodes) {
          const primaryField = nodes.find((node) => node.isPrimary);
          if (
            primaryField &&
            schema.properties.fields.properties[primaryField.id]
          ) {
            schema.properties.fields.properties[primaryField.id] = {
              ...schema.properties.fields.properties[primaryField.id],
              isPrimary: true,
            };
          }
        }
      }

      yieldData.push(
        put(
          fetchDataCollection.success({
            breadcrumbs: collectionResult.body.breadcrumbs || [],
            collection: collectionResult.body.collection,
            permissions: collectionResult.body.permissions || [],
            schema: collectionResult.body.schema,
            viewport: collectionResult.body.viewport,
            views: {
              permissions: viewPermissions,
              nodes: viewNodes,
            },
            fields:
              fieldsResult && fieldsResult.body
                ? {
                  permissions: fieldsResult.body.permissions,
                  nodes: fieldsResult.body.viewport.nodes,
                }
                : { permissions: [], nodes: [] },
            selectedViewId: selectViewId,
          }),
        ),
      );

      if (paginatedData.items && paginatedData.pageInfo) {
        yieldData.push(
          put({
            type: constants.LOAD_ITEMS_PAGINATE,
            payload: paginatedData,
          }),
        );
      }

      if (selectViewId) {
        yieldData.push(
          put(viewsActions.selectView(selectViewId)),
        );
      }
      if (
        state.collections.collections.collection &&
        state.collections.collections.collection.type ===
          CollectionTypes.documents
      ) {
        if (!state.collections.collections.viewport.nodes.length) {
          yieldData.push(
            put(viewsActions.selectView(selectViewId)),
          );
        }
      } else {
        yieldData.push(
          put(viewsActions.selectView(selectViewId)),
        );
      }
      const sheet = currentSheet(state);
      if (sheet) {
        yieldData.push(
          put(viewsActions.setViewOrder(sheet.viewIDOrder || [])),
        );
      }
    } else {
      yieldData.push(
        put(
          fetchDataCollection.failure({ error: new Error('Empty result') }),
        ),
      );
    }
    if (!selectViewId) {
      const views = _.get(viewsResult, 'body.viewport.nodes');
      const defaultView = views.filter((view) => view.isDefault === true)[0];
      if (defaultView?.id) {
        yieldData.push(
          put(actions.setCurrentViewId(defaultView.id)),
        );
      }
    }

    yield all(yieldData);
  } catch (error) {
    yield call(handleError, error);
    yield put(fetchDataCollection.failure({ error }));
  }
}

export function* getCollectionSaga() {
  yield createAPIHandler({
    actions: {
      request: fetchCollectionRequest,
      success: fetchCollectionSuccess,
      failure: fetchCollectionFailure,
    },
    buildUrl: (action) => {
      if (
        action.payload.url === `/${CollectionTypes.allWorkspaces}` ||
        action.payload.url === `/${CollectionTypes.paginatedBookmarks}`
      ) {
        return `${normalizeURL(action.payload.url)}?api_version=2${
          action.payload.query ? '&search=' + action.payload.query : ''
        }${action.payload.page ? '&page=' + action.payload.page : ''}`;
      }
      if (
        getCollectionTypeFromUrl(action.payload.url) ===
          CollectionTypes.databases ||
        getCollectionTypeFromUrl(action.payload.url) === CollectionTypes.taskdbs
      ) {
        if (
          getCollectionTypeFromUrl(action.payload.url) ===
          CollectionTypes.taskdbs
        ) {
          return `${normalizeURL(
            action.payload.url.replace('taskdbs', 'databases'),
          )}?api_version=2`;
        }
        return `${normalizeURL(action.payload.url)}?api_version=2`;
      }
      return `${normalizeURL(action.payload.url)}?sections=viewport,collection`;
    },
    successPayloadMapper: (data, action) => {
      if (
        action.payload.url === `/${CollectionTypes.allWorkspaces}` ||
        action.payload.url === `/${CollectionTypes.paginatedBookmarks}`
      ) {
        return mapWorkspacesResponse(data);
      } else if (
        getCollectionTypeFromUrl(action.payload.url) ===
          CollectionTypes.databases ||
        getCollectionTypeFromUrl(action.payload.url) === CollectionTypes.taskdbs
      ) {
        return mapDatabasesResponse(
          data,
          getCollectionTypeFromUrl(action.payload.url),
        );
      }
      return path(['body', 'viewport'], data);
    },
    successMetaMapper: (data, action) => {
      if (action.payload.url === `/${CollectionTypes.allWorkspaces}`) {
        return 'workspaces';
      }
      if (action.payload.url === `/${CollectionTypes.paginatedBookmarks}`) {
        return 'favoriteWorkspaces';
      }
      if (
        getCollectionTypeFromUrl(action.payload.url) ===
        CollectionTypes.databases
      ) {
        return 'databases';
      }

      if (
        getCollectionTypeFromUrl(action.payload.url) === CollectionTypes.taskdbs
      ) {
        return 'taskdbs';
      }

      return get(['body', 'collection', 'type'], data);
    },
    * onSuccess(payload, action, _, meta) {
      if (meta === CollectionTypes.repositories) {
        const redirectURL = getOr('', 'nodes[0].id', payload);
        yield put(replace(`${action.payload}/${redirectURL}`));
      }
    },
  });
}

export function* createNode(action: ActionType<typeof Actions.createNode>) {
  const nodeInCreationId = uuidv4();

  try {
    const { url, data, rowPosition, initData } = action.payload;
    const state: State = yield select();
    const account = currentUser(state);

    const normalizedData: Partial<CommonNode> = data.fields
      ? { fields: data.fields }
      : data;

    if (
      state.collections.collections.collection &&
      state.collections.collections.collection.type === CollectionTypes.tasks
    ) {
      if (!normalizedData.title) {
        normalizedData.title = ' ';
      }

      if (normalizedData['assignedTo']) {
        normalizedData['assignedTo'] = [data['assignedTo']];
      } else if (data['assignedTo']) {
        normalizedData['assignedTo'] = [data['assignedTo']];
      }
    }

    const nodeInCreation: CommonNode = {
      id: nodeInCreationId,
      apiURI: `${action.payload.url}/${nodeInCreationId}`,
      uri: '',
      createdBy: account ? account.id : '',
      modifiedBy: account ? account.id : '',
      createdDate: Date.now(),
      modifiedDate: Date.now(),
      historyMessage: '',
      agGridId: nodeInCreationId,
      ...normalizedData,
    };

    if (initData) {
      nodeInCreation.fields = initData;
      normalizedData.fields = { ...normalizedData.fields, ...initData };
    }

    yield put(Actions.addNodeInCreation(nodeInCreation));

    if (
      rowPosition !== undefined &&
      rowPosition < state.collections.collections.viewport.nodes.length
    ) {
      const customRowOrder = {
        ...state.collections.currentViewConfig.customRowOrder,
      };

      // Adjust position of rows below this one
      Object.keys(customRowOrder).forEach((nodeId: string) => {
        if (customRowOrder[nodeId] >= rowPosition) {
          customRowOrder[nodeId]++;
        }
      });
      customRowOrder[nodeInCreationId] = rowPosition;
      yield put(viewConfigActions.setCustomRowOrder(customRowOrder));
    }
    let result;
    if (isDB(document.location.pathname)) {
      result = yield call(
        repeatable,
        ItemsService.createItem,
        { url, data: normalizedData },
        state,
      );
    } else {
      result = yield call(
        repeatable,
        postRequest,
        { url, data: normalizedData },
        state,
      );
    }
    if (!result) {
      yield put(
        Actions.createNodeError({
          error: 'Not valid credentials.',
          nodeInCreationId,
        }),
      );
    } else {
      const currentState: State = yield select();
      const resultNode: CommonNode = result.body.node;
      yield put(
        Actions.createNodeSuccess({
          node: mapResultNode(resultNode),
          nodeInCreationId,
        }),
      );
      yield put(patchCustomOrderAction());
      if (resultNode['isFolder']) {
        const dirObj = {
          id: resultNode.id,
          uri: resultNode.uri,
          name: resultNode['fileName'],
          nodes: [],
        };
        yield put(appendDirectory(dirObj));
      }
      // Check if node was updated in redux while waiting for POST response
      // and if so send a PUT request with the new data
      const allNodes = nodes(currentState);
      const nodeIndex = allNodes.findIndex(
        (node) => node.id === nodeInCreationId,
      );
      const currentNode = nodeIndex >= 0 ? allNodes[nodeIndex] : undefined;
      if (currentNode) {
        const {
          id,
          apiURI,
          uri,
          createdDate,
          modifiedDate,
          historyMessage,
          agGridId,
          ...currentNodeData
        } = currentNode;
        const expectedNode: CommonNode = {
          ...resultNode,
          ...currentNodeData,
        };
        Object.keys(expectedNode.fields || {}).forEach(
          (key) =>
            expectedNode.fields &&
            expectedNode.fields[key] === undefined &&
            delete expectedNode.fields[key],
        );

        let expectedNodeFields = expectedNode.fields || {};
        if (!Object.keys(expectedNodeFields).length) {
          const propertiesMap =
            state.collections.collections.schema.properties['fields'][
              'properties'
            ];
          if (propertiesMap) {
            const [primaryFieldId] = Object.keys(propertiesMap).filter(
              (key) => propertiesMap[key]['isPrimary'],
            );
            expectedNodeFields = { [primaryFieldId]: '' };
          }
        }
        // todo: check for side effects
        // const updateUrl = isDB(document.location.pathname) ? resultNode.apiURI : normalizeURL(resultNode.apiURI);
        // const payload: UpdateNodeRequestPayload = {
        //   url: updateUrl,
        //   data: { fields: expectedNodeFields, version: resultNode.version },
        //   isFullObject: false,
        // };
        // yield put(updateNode.request(payload));
      }

      let fileUploadPayload = state.ui.fileUpload.attachmentsToUpload;
      if (fileUploadPayload.length) {
        fileUploadPayload = fileUploadPayload.map((x) => ({
          ...x,
          uri: resultNode.apiURI,
          rowId: resultNode.id,
        }));
        yield put(uploadAttachments(fileUploadPayload));
      }
      const bulkReminders = data.bulkReminders;
      if (bulkReminders?.length) {
        const updatedBulkReminders = bulkReminders.map(
          (reminder: ReminderType) => ({
            ...reminder,
            recordID: resultNode.id,
          }),
        );

        const store = window.appStore;
        store.dispatch(
          reminderActions.requestBulkChange({
            reminders: updatedBulkReminders,
            rows: [resultNode.id],
          }),
        );
      }

      return resultNode;
    }
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.createNodeError({ error, nodeInCreationId }));
  }
}

export function* duplicateNode(
  action: ActionType<typeof Actions.duplicateNode>,
) {
  const { data, url, onComplete } = action.payload;
  const currentNode = data;
  const newNode = yield call(createNode, action);
  // @ts-ignore
  const attachments = yield select((state) => state.attachments);
  const currentNodeAttachments = attachments.byItemId[currentNode.id];
  if (currentNodeAttachments) {
    const filesToCopy = currentNodeAttachments.ids.map((id) => {
      const file = _.clone(currentNodeAttachments.byId[id]);
      file.references.itemID = newNode.id;
      return file;
    });

    let copyFilesResult = [];
    if (filesToCopy.length) {
      const { [CollectionTypes.workspaces]: urlWorkspaceId } = parseURL(url);
      copyFilesResult = yield copyAttachments(urlWorkspaceId, filesToCopy);
    }
    if (copyFilesResult && copyFilesResult.length) {
      for (let index = 0; index < copyFilesResult.length; index++) {
        const fileNode: FileModel = copyFilesResult[index] as FileModel;
        yield put(attachmentsActions.indexAttachment(fileNode));
      }
    }
  }
  onComplete?.(newNode.id);
}

export function* createField(action: ActionType<typeof Actions.createField>) {
  try {
    const state: State = yield select();
    const colums = yield select(columnsStateSelector);
    const { id: viewId, type } = yield select(selectedView);
    const [currentView] = state.collections.views.nodes['views'].filter(
      (view) => view.id === viewId,
    );
    const url = createFieldsUrl(state, viewId);

    const params = {
      data: {
        fieldData: action.payload,
        viewData: {
          id: viewId,
          query: { fieldIDs: prepareFieldIDs(colums) },
          type,
          version: currentView.version || 1,
        },
      },
      url,
    };

    const result = yield call(repeatable, postRequest, params, state);

    if (!result) {
      yield put(Actions.saveFieldError({ error: 'Not valid credentials.' }));
    } else {
      const field: FieldNode = mapFieldResponse(result, url);
      const successPayload: SaveFieldSuccessPayload = {
        field,
        columnIndexInView: action.payload.columnIndexInView,
      };
      yield put(Actions.saveFieldSuccess(successPayload));

      return field;
    }
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.saveFieldError({ error }));
  }
}

export function* duplicateField(
  action: ActionType<typeof Actions.duplicateField>,
) {
  try {
    const state: State = yield select();
    const url = patchFieldsUrl(state, action.payload.fieldId, true);
    const params = {
      url,
    };
    const result = yield call(repeatable, postRequest, params, state);

    if (!result) {
      yield put(Actions.saveFieldError({ error: 'Not valid credentials.' }));
    } else {
      const field: FieldNode = result.body.node;
      const successPayload: SaveFieldSuccessPayload = { field };

      yield put(Actions.saveFieldSuccess(successPayload));
      return field;
    }
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.saveFieldError({ error }));
  }
}

export function* updateField(action: ActionType<typeof Actions.updateField>) {
  try {
    const state: State = yield select();
    const { fieldId, ...data } = action.payload;
    const baseUrl = patchFieldsUrl(state, fieldId);
    const query = stringify({ overwrite: true });
    const currentField =
      state.collections.collections.schema.properties.fields.properties?.[
        parseInt(action.payload.fieldId)
      ];
    const params = {
      url: `${baseUrl}&${query}`,
      data: { ...data, version: currentField.version || 1 },
    };
    const result = yield call(repeatable, patchRequest, params, state);

    if (!result) {
      yield put(Actions.saveFieldError({ error: 'Not valid credentials.' }));
    } else {
      const field: FieldNode = mapFieldResponse(result, baseUrl);
      const successPayload: SaveFieldSuccessPayload = { field };

      yield put(Actions.saveFieldSuccess(successPayload));
      return field;
    }
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.saveFieldError({ error }));
  }
}

export function* deleteField(action) {
  try {
    const state: State = yield select();
    const url = patchFieldsUrl(state, action.payload);
    const result = yield call(repeatable, deleteRequest, url, state);
    if (!result) {
      yield put(Actions.saveFieldError({ error: 'Not valid credentials.' }));
    } else {
      yield put(Actions.deleteFieldSuccess(action.payload));
    }
    yield removeFieldRevisions({ fieldId: action.payload });
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.saveFieldError({ error }));
  }
}

export function* updateNodeSaga(action: ActionType<typeof updateNode.request>) {
  const { url, data, folderBreadcrumbUri } = action.payload;
  const id = getUrlsLastItem(url);
  let detailedNodeId: string | undefined;
  let previousNode: CommonNode | DocumentNode | undefined;
  try {
    const state: State = yield select();
    const currentView = selectedView(state);
    const allNodes = nodes(state);
    const nodeIndex = allNodes.findIndex((node) => node.id === id);
    detailedNodeId = state.modals?.node?.id;
    previousNode = nodeIndex >= 0 ? allNodes[nodeIndex] : undefined;

    if (currentView.type === CollectionViewTypes.grid) {
      const changes = state.collections.currentViewConfig.columns.filter(
        (col) => {
          if (col.rowGroupIndex || col.rowGroupIndex === 0) {
            return (
              typeof data.fields[col.colId.replace('fields.', '')] !==
              'undefined'
            );
          }
        },
      );
      if (changes?.length) {
        yield put(
          notifications.custom({ message: 'Record moved to another group.' }),
        );
        yield put(setRowIndexMap(DataGrid.agGridApi?.getGroupingRowIndexMap()));
        yield delay(100);
        DataGrid.agGridApi?.refreshCells([ROW_NUMBER_ID]);
      }
    }

    // updates detailed modal fields when record/node already exists
    if (detailedNodeId) {
      /**
       * Update node modal
       */
      yield put(
        modalActions.setNodeData({
          fields: { ...previousNode?.fields, ...data.fields },
        }),
      );
    }

    let resultNode: CommonNode;

    if (previousNode && nodesInCreation(state).includes(id)) {
      resultNode = {
        ...previousNode,
        ...data,
      };
    } else {
      const result = yield call(
        repeatable,
        ItemsService.putOrPatchNode,
        action.payload,
        state,
        data.version,
      );

      if (!result) {
        throw new Error('Not valid credentials.');
      }

      resultNode = result.body.node;
    }
    yield put(
      updateNode.success({
        node: { ...resultNode, modifiedDate: resultNode.modifiedAt },
        updatePayload: data,
        updatedByCurrentUser: resultNode.modifiedBy === state.users.user?.id,
      }),
    );
  } catch (error) {
    yield call(handleError, error);
    yield put(updateNode.failure({ error, previousNode, folderBreadcrumbUri }));
  }
}

export function* deleteNodes(action) {
  let customRowOrder: Record<string, number> = {};
  let newCustomRowOrder: Record<string, number> = {};
  const nodesToDelete: CommonNode[] = [];
  try {
    const state: State = yield select();
    const { ids } = action.payload;
    customRowOrder = state.collections.currentViewConfig.customRowOrder;

    // Delete nodes in store
    const nodesResult = [...nodes(state)];
    ids.forEach((id: string) => {
      const i = nodesResult.findIndex((node) => node.id === id);
      if (i >= 0) {
        nodesToDelete.push(nodesResult[i]);
        nodesResult.splice(i, 1);
      }
    });
    yield put(Actions.setNodes(nodesResult));

    // Adjust custom row order
    newCustomRowOrder = { ...customRowOrder };
    ids.forEach((id: string) => {
      if (newCustomRowOrder[id] !== undefined) {
        const rowPosition = newCustomRowOrder[id];
        delete newCustomRowOrder[id];

        // Adjust position of rows below this one
        Object.keys(newCustomRowOrder).forEach((nodeId: string) => {
          if (newCustomRowOrder[nodeId] >= rowPosition) {
            newCustomRowOrder[nodeId]--;
          }
        });
      }
    });
    if (!_.isEqual(newCustomRowOrder, customRowOrder)) {
      yield put(viewConfigActions.setCustomRowOrder(newCustomRowOrder));
    }

    if (
      nodesToDelete?.length &&
      state?.databases?.byId?.[state?.databases?.current]?.master
    ) {
      const { columns } = getGridData(state);
      const clientIdColumn = columns?.find?.(getColumnClientId);
      const clientIds = nodesToDelete?.map?.((record) =>
        removeHTMLTag(record.fields?.[clientIdColumn?._id ?? ''] as string),
      ) as string[];

      if (clientIds?.length) {
        const workspacesReponse = yield call(validClientId, clientIds);
        const workspaces: Array<any> =
          workspacesReponse?.body?.workspaces || [];
        const workspaceIds = workspaces?.map?.(({ _id }) => ({
          workspaceId: _id,
        }));
        if (workspaceIds?.length) {
          yield call(deleteOrganizers, workspaceIds);
          yield workspaceIds.map(({ workspaceId }) =>
            call(deleteWorkspace, workspaceId),
          );
        }
      }
    }

    // Request nodes deletion in backend
    let result;

    if (isDB(document.location.pathname)) {
      if (ids.length === 1) {
        result = yield call(
          repeatable,
          ItemsService.singleDelete,
          `${state.router.location.pathname}`,
          ids[0],
          state,
        );
      } else {
        result = yield call(
          repeatable,
          ItemsService.bulkDelete,
          `${state.router.location.pathname}`,
          ids,
          state,
        );
      }
    } else {
      for (const id of ids) {
        const url = `${state.router.location.pathname}/${id}`;
        result =
          +id < 0 ? true : yield call(repeatable, deleteRequest, url, state);
      }
    }

    if (!result) {
      yield put(
        Actions.deleteNodeError({
          error: 'Not valid credentials.',
          nodes: nodesToDelete,
        }),
      );
      if (!_.isEqual(newCustomRowOrder, customRowOrder)) {
        yield put(viewConfigActions.setCustomRowOrder(customRowOrder));
      }
    } else {
      if (ids.length > 0) {
        yield call(
          successToast,
          `You have deleted ${ids.length} ${
            ids.length === 1 ? 'row' : 'rows'
          }.`,
        );
      }
      yield put(Actions.deleteNodeSuccess({ ids: action.payload.ids }));
    }

    yield put(patchCustomOrderAction());
    yield removeItemRevisions(ids);
  } catch (error) {
    yield call(handleError, error);
    yield put(Actions.deleteNodeError({ error, nodes: nodesToDelete }));
    if (!_.isEqual(newCustomRowOrder, customRowOrder)) {
      yield put(viewConfigActions.setCustomRowOrder(customRowOrder));
    }
  }
}

/**
 * Example:
 * copy DATABASE
 * {baseURL}/workspaces/{id}/databases:copy?source={baseURL}/workspaces/{id}/databases/{id}
 * copy WORKSPACE
 * {baseURL}/workspaces:copy?source={baseURL}/workspaces/{id}
 */
export function* copyCollectionsSaga() {
  yield createAPIHandler({
    actions: {
      request: copyCollectionRequest,
      success: copyCollectionSuccess,
      failure: copyCollectionFailure,
    },
    buildUrl: (action: ActionType<typeof copyCollectionRequest>) => {
      const { payload, meta } = action;
      const regex = new RegExp(`((${CollectionTypes[meta]})/).*$`, 'g');
      const matches = payload.match(regex);
      const destinationBaseUri = payload.replace(
        matches ? matches[0] : '',
        CollectionTypes[meta],
      );
      const query = stringify({ source: payload });

      return `${destinationBaseUri}/:copy?${query}`;
    },
    requestType: RequestType.Post,
    successPayloadMapper: path(['body', 'node']),
    successMetaMapper: (_, action) => action.meta,
    * onSuccess(payload, action, _, meta) {
      if (meta === CollectionTypes.workspaces) {
        yield put(fetchNavigationTree());
      }
    },
  });
}

export function* fetchChecklists(action: FetchChecklists) {
  const state: State = yield select();
  const databaseId = state.databases.current;
  const workspaceId = state.workspaces.current;
  const sheetId = state.sheets.currentId;
  const endpoint = `${getApiV2Url()}/workspaces/${workspaceId}/databases/${databaseId}/sheets/${sheetId}/items/${
    action.itemId
  }/checklists`;
  try {
    const headers = yield getAuthHeaders();
    const { body } = yield call(() => request.get(endpoint).set(headers));
    yield put(actions.setChecklists(body));
  } catch (error) {
    yield put(actions.setChecklistError('Failed to fetch checklists'));
  }
}

export function* updateChecklistInStore(id: string, options: types.Checklist) {
  const state: State = yield select();
  const { checklists } = state.collections;
  const index = checklists.findIndex((checklist) => checklist.id === id);
  const updatedChecklists = [
    ...checklists.slice(0, index),
    { ...options },
    ...checklists.slice(index + 1),
  ];
  yield put(actions.setChecklists(updatedChecklists));
}

export function* removeChecklistFromStore(id: string) {
  const state: State = yield select();
  const { checklists } = state.collections;
  const filteredChecklists = checklists.filter(
    (checklist) => checklist.id !== id,
  );
  yield put(actions.setChecklists(filteredChecklists));
}

export function* createChecklist(action: CreateChecklist) {
  const state: State = yield select();
  const databaseId = state.databases.current;
  const workspaceId = state.workspaces.current;
  const sheetId = state.sheets.currentId;
  const endpoint = `${getApiV2Url()}/workspaces/${workspaceId}/databases/${databaseId}/sheets/${sheetId}/items/${
    action.itemId
  }/checklists`;
  try {
    const headers = yield getAuthHeaders();
    yield put(appendChecklist(action.checklist));
    const { body } = yield call(() =>
      request
        .post(endpoint)
        .set(headers)
        .send({
          itemId: action.itemId,
          ...action.checklist,
        }),
    );
    yield updateChecklistInStore(action.checklist.id, body);
  } catch (error) {
    yield removeChecklistFromStore(action.checklist.id);
    yield put(actions.setChecklistError('Failed to create checklist'));
  }
}

export function* deleteChecklist(action: DeleteChecklist) {
  const state: State = yield select();
  const databaseId = state.databases.current;
  const workspaceId = state.workspaces.current;
  const sheetId = state.sheets.currentId;
  const { checklists } = state.collections;
  yield removeChecklistFromStore(action.id);
  try {
    const headers = yield getAuthHeaders();
    const endpoint = `${getApiV2Url()}/workspaces/${workspaceId}/databases/${databaseId}/sheets/${sheetId}/items/${
      action.itemId
    }/checklists/${action.id}`;
    yield call(() => request.delete(endpoint).set(headers));
  } catch (error) {
    yield put(actions.setChecklists(checklists));
    yield put(actions.setChecklistError('Failed to delete checklist'));
  }
}

export function* updateChecklist(action: UpdateChecklist) {
  const state: State = yield select();
  const databaseId = state.databases.current;
  const workspaceId = state.workspaces.current;
  const sheetId = state.sheets.currentId;
  const { checklists } = state.collections;
  yield updateChecklistInStore(action.checklist.id, action.checklist);
  const endpoint = `${getApiV2Url()}/workspaces/${workspaceId}/databases/${databaseId}/sheets/${sheetId}/items/${
    action.itemId
  }/checklists/${action.checklist.id}`;
  try {
    const headers = yield getAuthHeaders();
    yield call(() =>
      request.patch(endpoint).set(headers).send({
        itemId: action.itemId,
        name: action.checklist.name,
        items: action.checklist.items,
      }),
    );
  } catch (error) {
    yield put(actions.setChecklists(checklists));
    yield put(actions.setChecklistError('Failed to update checklist'));
  }
}

export function* updateRecords(action: UpdateRecords) {
  const state: State = yield select();
  try {
    yield call(ItemsService.bulkUpdate, action, state);
    const { field, payload, items: itemIds } = action;
    const fieldId = field.replace('fields.', '');
    const value = payload;
    const currentView = selectedView(state);
    const allNodes = nodes(state);
    const changedNodes = allNodes.filter((node) => itemIds.includes(node.id));

    if (currentView.type === CollectionViewTypes.grid) {
      const changes = state.collections.currentViewConfig.columns.filter(
        (col) => {
          if (col.rowGroupIndex || col.rowGroupIndex === 0) {
            const colId = col.colId.replace('fields.', '');
            const changesN = changedNodes.filter((node) => {
              if (node.fields[colId]) {
                return node.fields[colId] !== value;
              }
              return false;
            });
            return changesN.length;
          }
        },
      );
      if (changes.length) {
        yield put(
          notifications.custom({
            message: `Record${
              changes.length > 1 ? 's' : ''
            } moved to another group.`,
          }),
        );
      }
    }

    yield put(actions.updateItemsField({ itemIds, fieldId, value }));
    DataGrid.agGridApi?.refreshCells();
  } catch (error) {
    console.error(error);
  }
}

export function* updatePrimaryField(action: UpdatePrimaryField) {
  try {
    yield put(
      Actions.updateField({
        isPrimary: true,
        fieldId: action.newFieldId,
        prevPrimaryFieldId: action.existingFieldId,
      }),
    );
    if (action.existingFieldId) {
      yield put(
        Actions.updateField({
          isPrimary: false,
          fieldId: action.existingFieldId,
        }),
      );
    }
  } catch (error) {
    yield put(
      Actions.updateField({ isPrimary: false, fieldId: action.newFieldId }),
    );
    if (action.existingFieldId) {
      yield put(
        Actions.updateField({
          isPrimary: true,
          fieldId: action.existingFieldId,
        }),
      );
    }
  }
}

export function* lockField(action: LockColumnAction) {
  try {
    yield put(Actions.updateField({ ...action.payload }));
  } catch (error) {
    console.error('Error: ', error);
  }
}

export function* setFieldSummary(action: SetFieldSummaryActionType) {
  try {
    const { type, fieldId } = action.payload;
    const state: State = yield select();
    const nodes = state.collections.collections.viewport.nodes;
    const field = fieldId.replace('fields.', '').replace('_1', '');
    const schemaProperties = state.collections.collections.schema.properties;
    const column = schemaProperties?.fields?.properties[field];
    const dataNotFormatted = nodes.map(
      (node) => node.fields && { data: node.fields[field], nodeId: node.id },
    );
    const data = dataNotFormatted
      .map((node) =>
        fieldsValue(
          column,
          node ? node.data : '',
          accountsById(state),
          (node && node.nodeId) || '',
          attachments(state),
        ),
      )
      .filter((cell) => cell || cell === 0);
    const value = summarizeColumn({
      type,
      fieldId,
      data,
      column,
      attachments: attachments(state),
      dataNotFormatted,
    });
    const summary = {
      type,
      value: value + '',
    };
    yield put(
      Actions.updateField({ fieldId: fieldId.replace('fields.', ''), summary }),
    );
  } catch (error) {
    console.error('Error while setting field summary', error);
  }
}

const buildItemPayload = (item, mode) => {
  return {
    url: item.url,
    isFullObject: item.isFullObject,
    data: {
      fields: {
        [item.fieldId]: item.fieldValue,
      },
    },
    mode,
  };
};

const builItemdRevision = (item, revision) => {
  return {
    url: item.apiURI,
    isFullObject: false,
    fieldId: revision.fieldId,
    fieldValue: item.fields[revision.fieldId],
  };
};

function* redoItem() {
  const state: State = yield select();
  const redo = state.collections.itemHistory.redo.slice();
  if (!redo.length) {
    return;
  }
  const redoItem = redo.pop();
  const itemId = getUrlsLastItem(redoItem.url);
  const node = state.collections.collections.viewport.nodes
    .slice()
    .find((node) => node.id === itemId);
  const payload = buildItemPayload(redoItem, 'redo');
  const revision = builItemdRevision(node, redoItem);
  yield put(updateNode.request(payload));
  yield put(
    setItemHistory({
      redo,
      undo: [...state.collections.itemHistory.undo.slice(-9), revision],
    }),
  );
}

function* undoItem() {
  const state: State = yield select();
  const undo = state.collections.itemHistory.undo.slice();
  if (!undo.length) {
    return;
  }
  const undoItem = undo.pop();
  const itemId = getUrlsLastItem(undoItem.url);
  const node = state.collections.collections.viewport.nodes
    .slice()
    .find((node) => node.id === itemId);
  const payload = buildItemPayload(undoItem, 'undo');
  const revision = builItemdRevision(node, undoItem);
  yield put(updateNode.request(payload));
  yield put(
    setItemHistory({
      undo,
      redo: [...state.collections.itemHistory.redo.slice(-9), revision],
    }),
  );
}

function* removeFieldRevisions(action) {
  const state: State = yield select();
  const undo = state.collections.itemHistory.undo.filter(
    (revision) => revision.fieldId !== `${action.fieldId}`,
  );
  const redo = state.collections.itemHistory.redo.filter(
    (revision) => revision.fieldId !== `${action.fieldId}`,
  );
  yield put(setItemHistory({ undo, redo }));
}

function* removeItemRevisions(itemIds: string[]) {
  const state: State = yield select();
  const filter = (item: actions.Revision) =>
    !itemIds.some((itemId) => item.url.endsWith(`/${itemId}`));
  const undo = state.collections.itemHistory.undo.filter(filter);
  const redo = state.collections.itemHistory.undo.filter(filter);
  yield put(setItemHistory({ undo, redo }));
}

export function* collectionsSaga() {
  yield takeEvery(constants.CREATE_NODE_REQUEST, createNode);
  yield takeEvery(constants.DUPLICATE_NODE, duplicateNode);
  yield takeEvery(isActionOf(updateNode.request), updateNodeSaga);
  yield takeEvery(constants.DELETE_NODE_REQUEST, deleteNodes);
  yield takeEvery(constants.CREATE_FIELD, createField);
  yield takeEvery(constants.DUPLICATE_FIELD, duplicateField);
  yield takeEvery(constants.UPDATE_FIELD, updateField);
  yield takeEvery(constants.DELETE_FIELD, deleteField);
  yield fork(getCollectionSaga);
  yield fork(createCollectionItemSaga);
  yield fork(createCollectionItemImportSaga);
  yield fork(copyCollectionsSaga);
  yield takeLatest(
    getType(fetchDataCollection.request),
    fetchDataCollectionSaga,
  );
  yield takeEvery(constants.FETCH_CHECKLISTS, fetchChecklists);
  yield takeEvery(constants.CREATE_CHECKLIST, createChecklist);
  yield takeEvery(constants.DELETE_CHECKLIST, deleteChecklist);
  yield takeEvery(constants.UPDATE_CHECKLIST, updateChecklist);
  yield takeEvery(constants.UPDATE_RECORDS, updateRecords);
  yield takeEvery(constants.UPDATE_PRIMARY_FIELD, updatePrimaryField);
  yield takeEvery(constants.SET_FIELD_SUMMARY, setFieldSummary);
  yield takeEvery(constants.REDO_ITEM, redoItem);
  yield takeEvery(constants.UNDO_ITEM, undoItem);
  yield takeEvery(constants.REMOVE_FIELD_REVISIONS, removeFieldRevisions);
  yield takeEvery(constants.LOCK_COLUMN, lockField);
}
