import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { path } from 'lodash/fp';

import { buildURI } from '../../utilities/buildApiURI';
import { parseURI } from '../../utilities/parseURI';

export const staticFieldIds = [
  'row_number_id',
  '_new',
  'createdBy',
  'createdDate',
  'modifiedBy',
  'modifiedDate',
  'deletedBy',
  'version',
];
const staticFields = {
  'createdBy': {
    'name': 'Created By',
    'type': 'string',
    'fieldType': 'account',
    'readOnly': true,
    'grid': {
      'hide': false,
    },
  },
  'createdDate': {
    'name': 'Created Date',
    'type': 'number',
    'editor': 'datetime',
    'fieldType': 'date',
    'formatter': 'datetime',
    'readOnly': true,
    'grid': {
      'hide': false,
    },
  },
  'modifiedBy': {
    'name': 'Modified By',
    'type': 'string',
    'fieldType': 'account',
    'readOnly': true,
    'grid': {
      'hide': false,
    },
  },
  'modifiedDate': {
    'name': 'Modified Date',
    'type': 'number',
    'editor': 'datetime',
    'fieldType': 'date',
    'formatter': 'datetime',
    'readOnly': true,
    'grid': {
      'hide': false,
    },
  },
  'deletedBy': {
    'name': 'Deleted By',
    'type': 'string',
    'fieldType': 'account',
    'readOnly': true,
    'grid': {
      'hide': true,
    },
  },
  'version': {
    'name': 'Version',
    'type': 'number',
    'default': 1,
    'editor': 'integer',
    'fieldType': 'integer',
    'readOnly': true,
    'grid': {
      'hide': false,
      'suppressFilterBar': true,
      'suppressFilterDropdown': true,
    },
  },
};
export function mapSheetResponse(sheet, collectionUrl) {
  const fields = sheet?.fields || [];
  const { workspaceId, databaseId } = parseURI(collectionUrl);
  const fieldsResult = {
    body: {
      viewport: {
        nodes: fields.map(node => ({ ...node, id: node._id.toString(), permissions: getPermissions() })),
        start: 0,
        totalCount: fields.length,
        returnedCount: fields.length,
      },
      permissions: getPermissions(),
    },
  };

  const views = sheet?.views || [];
  const viewResult = {
    body: {
      permissions: [],
      viewport: {
        nodes: views.map((view, index) => ({
          ...view,
          id: view._id,
          isDefault: view.isDefault ?? false,
          query: view.query && {
            ...view.query,
            count: (view.query && view.query.count) || 1,
            fieldIDs: [
              ...view.query && view.query.fieldIDs && view.query.fieldIDs.map(fieldId => staticFieldIds.includes(fieldId) || fieldId.toString().includes('fields.') ? fieldId : 'fields.' + fieldId),
            ],
          },
          permissions: getPermissions(),
          acl: {},
          apiUri: buildURI('view', { workspaceID: workspaceId, databaseID: databaseId, sheetID: sheet._id, _id: view._id }),
        })),
        start: 0,
        totalCount: views.length,
        returnedCount: views.length,
      },
    },
  };

  const fieldsMap = fieldsResult.body.viewport.nodes.reduce((map, field) => {
    map[field._id] = {
      ...field,
      name: field.title,
    };
    return map;
  }, {});

  const collectionResult = {
    body: {
      permissions: getPermissions(),
      breadcrumbs: [{}],
      collection: {
        blobsSupported: false,
        customViewsSupported: true,
        fieldsCollectionName: 'fields',
        icon: 'database',
        pluralLabel: 'items',
        singularLabel: 'item',
        supportedViewTypes: [
          'grid',
          'kanban',
          'calendar',
        ],
        type: 'items',
        Nodes: null,
      },
      viewport: {
        nodes: [],
        start: 0,
        totalCount: 0,
        returnedCount: 0,
      },
      schema: {
        properties: {
          fields: {
            properties:
            {
              ...fieldsMap,
            },
          },
          ...staticFields,
        },
      },
      view: viewResult.body.viewport.nodes[0],
    },
  };
  return [fieldsResult.body, viewResult.body, collectionResult.body];
}

export function mapWorkspacesResponse(response) {
  const body = path(['body'], response);
  const nodes = (body.results || []).map((node) => mapWorkspaceResponse({ body: node }));
  return { start: body.metadata.totalPages, totalCount: body.metadata.total, nodes, totalNumberOfBookmarks: body.metadata.totalNumberOfBookmarks };
}

export function mapWorkspaceResponse(response) {
  const node = response.body;
  return {
    ...response.body,
    id: response.body._id,
    apiURI: buildURI('workspaces', node),
    linkURI: buildURI('workspacesLinkURI', node),
    menuItems: node?.modules?.map?.(module => ({
      label: module.options.title,
      linkURI: buildURI(`workspaceMenuItem_${module.type}`, node, module.options),
    })) || [],
    permissions: response.body.permissions || [],
    bookmarkMeta: {
      title: node.title,
      model: 'workspace',
      recordID: node._id,
      references: {
        modifiedAt: node.modifiedAt,
      },
    },
  };
}

export function mapDatabasesResponse(response, collectionType) {
  const nodes = path(['body'], response).map((node) => ({
    ...node,
    id: node._id,
    apiURI: buildURI(collectionType, node),
    linkURI: buildURI(`${collectionType}LinkURI`, node),
    permissions: getPermissions(),
    bookmarkMeta: {
      title: node.title,
      model: 'database',
      recordID: node._id,
      references: {
        workspaceID: node.workspaceID,
        type: node.type,
      },
    },
  }));
  return { returnedCount: nodes.length, start: 0, totalCount: nodes.length, nodes };
}

export function mapDatabaseResponse(response) {
  const node = response.body;
  return {
    ...node,
    id: node._id,
    apiURI: buildURI('databases', node),
    linkURI: buildURI('databasesLinkURI', node),
    permissions: getPermissions(),
    bookmarkMeta: {
      title: node.title,
      model: 'database',
      recordID: node._id,
      references: {
        workspaceID: node.workspaceID,
        type: node.type,
      },
    },
  };
}

export function mapFieldResponse(response, url) {
  const { workspaceId, databaseId, sheetId } = parseURI(url);
  const node = response.body;
  return {
    ...node,
    id: node._id.toString(),
    apiURI: buildURI('fields', { _id: node._id, workspaceID: workspaceId, databaseID: databaseId, sheetID: sheetId }),
    grid: { maxWidth: -1, minWidth: -1 },
    permissions: getPermissions(),
  };
}


export function mapViewResponse(response, url, currentView) {
  const { workspaceId, databaseId, sheetId } = parseURI(url);
  const node = response.body;
  return {
    ...currentView,
    ...node,
    id: node._id.toString(),
    apiURI: buildURI('views', { _id: node._id, workspaceID: workspaceId, databaseID: databaseId, sheetID: sheetId }),
    permissions: getPermissions(),
  };
}

export function mapSheetNodesResponse(response) {
  const nodes = response.body;
  return nodes.map(node => (
    {
      ...node,
      id: node._id.toString(),
      permissions: getPermissions(),
    }
  ));
}

export function mapBookmarksResponse(response) {
  const nodes = response.body;
  const bookmarks = nodes.map(node => mapBookmarkResponse({ body: node }));
  return {
    bookmarks,
    permissions: getPermissions(),
  };
}

export function mapBookmarkResponse(response) {
  const node = response.body;
  return {
    ...node,
    id: node._id.toString(),
    permissions: getPermissions(),
  };
}

function getPermissions() {
  return getWorkspacePermissions(window.appStore.getState());
}

export function mapResultNode(resultNode) {
  return {
    ...resultNode,
    createdDate: resultNode.createdAt,
    modifiedDate: resultNode.modifiedAt,
  };
}
