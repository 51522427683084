import React from 'react';

import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import get from 'lodash/get';

import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = get(activity, 'eventBy.displayName');
  const databaseTitle = get(activity, 'references.database.title') + '.';
  const workspaceTitle = get(activity, 'references.workspace.title');
  if (activity.action === ActivityItemStatuses.CREATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          created a new database,
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.UPDATED) {
    const references = {
      ...activity.references,
      databaseID: activity.modelID,
    };
    const isAnyDiff = activity.diff.length >= 1;
    const oldName = isAnyDiff ? activity.diff[0].oldVal : activity.references.title;
    const actualName = isAnyDiff ? activity.diff[0].val : activity.references.title;

    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          renamed the existing database
        <strong className={'bold'}> {oldName} </strong>
          to
        <ReferenceLink references={references} type={ReferenceLinkType.DATABASE}>
          {actualName}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.DELETED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          deleted database inside
        <ReferenceLink references={activity.references} type={ReferenceLinkType.WORKSPACE}>
          {workspaceTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.DUPLICATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          made a duplicate of the database,
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  return 'activity';
}

export const describeActivityDetails = () => <></>;


export default {
  describeActivity,
  describeActivityDetails,
};
