import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography, { Variant } from '../Typography';
import Avatar from '@material-ui/core/Avatar';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from './NotificationsItem.style';
import moment from 'moment';
import {
  Notification,
} from 'data/notifications/notifications';
import { removeHTMLTag } from 'utilities/format';

interface OwnProps {
  id: string;
  markAsRead: (id: string, isRead: boolean) => void;
  delete: (id: string) => void;
  markAsReadAndOpen: (id: string, isRead: boolean, url: string, type?: string) => void;
  model?: string;
}

export type Props
  = OwnProps
  & Notification
  & WithStyles<typeof styles>
  ;

export function NotificationsItem(props: Props): JSX.Element {
  const {
    classes,
    createdAt,
    seenAt,
    type,
    references,
  } = props;

  const isRead = (seenAt && seenAt !== 0) as boolean;

  const notificationIcon = (type: string): JSX.Element => {
    if (type === 'REMINDER') {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.purpleBlue}` }}
        >
          <svgIcons.Clock width="1.5em" height="1.5em" />
        </Avatar>
      );
    } else if (type === 'MENTION') {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.lightGreen}` }}
        >
          <AlternateEmailIcon fontSize="small" />
        </Avatar>
      );
    } else if (type === 'DOWNLOAD_READY') {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.blueGrey}` }}
        >
          <GetAppIcon fontSize="small"/>
        </Avatar>
      );
    } else if (['FILE_CREATED', 'FILE_DELETED', 'FILE_UPDATED', 'FILE_DOWNLOADED', 'GROUP_FILE_CREATED', 'GROUP_FILE_DELETED'].includes(type)) {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.purple}` }}
        >
          <svgIcons.Files width="2.5em" height="2.5em"/>
        </Avatar>
      );
    } else if (['FOLDER_CREATED', 'FOLDER_DELETED', 'FOLDER_UPDATED'].includes(type)) {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.blueGrey}` }}
        >
          <svgIcons.FolderNew width="1.5em" height="1.5em"/>
        </Avatar>
      );
    } else {
      return (
        <Avatar
          style={{ backgroundColor: `${Colors.purpleBlue}` }}
        >
          <svgIcons.Clock width="1.5em" height="1.5em" />
        </Avatar>
      );
    }
  };

  const notificationSecondaryAction = (): JSX.Element => {
    return (
      <ListItemSecondaryAction className={classes.notificationAction} style={isRead? { justifyContent: 'flex-end' } : undefined}>
        {!isRead && (
          <button className={classes.notificationUnreadButton} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.markAsRead(props.id, isRead);
          }} />
        )}
        <button className={classes.notificationDeleteButton} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.delete(props.id);
        }}>
          <svgIcons.Delete />
        </button>
      </ListItemSecondaryAction>
    );
  };

  const notificationDescribe = (type: string, content): JSX.Element => {
    if (type === 'REMINDER') {
      return (
        <React.Fragment>
          <Typography variant={content.primaryField ? Variant.Title : Variant.ItalicTitle}
            className={classes.notificationItemTagText}>
            {`${removeHTMLTag(content.primaryField) || '<Blank Field Name>'}`}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  due on ${moment(content.dueTime).format('MM/DD/YY')}  `}
          </Typography>
        </React.Fragment>
      );
    } else if (type === 'ADDED_IN_RECORD') {
      return (
        <React.Fragment>
          <Typography variant={Variant.Title} className={classes.notificationItemTagText}>
            {content?.userRequestName || 'Anonymous'}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  added you ${content?.databaseTitle && content?.databaseTitle.length > 0 ? ' in field ' : ' in '}`}
          </Typography>
          {content?.primaryFieldID && (
            <Typography variant={content?.primaryField ? Variant.Title : Variant.ItalicTitle}
              className={classes.notificationItemTagText}>
              {`${content?.primaryField || '<Blank Field Name>'}`}
            </Typography>
          )}
          {!content?.primaryFieldID && (
            <Typography variant={Variant.Title} className={classes.notificationItemTagText}>Messages</Typography>
          )}
          {
              content?.message && (
              <p
                className={classes.mentionMessage}
                dangerouslySetInnerHTML={{ __html: `"${content?.message}"` }}
              />
            )
          }
        </React.Fragment>
      );
    } else if (['FILE_CREATED', 'FILE_DELETED', 'FILE_UPDATED', 'FOLDER_CREATED', 'FOLDER_DELETED', 'FOLDER_UPDATED', 'FILE_DOWNLOADED'].includes(type)) {
      const types = {
        FILE_CREATED: 'created',
        FILE_DELETED: 'deleted',
        FILE_UPDATED: 'updated',
        FOLDER_CREATED: 'created',
        FOLDER_DELETED: 'deleted',
        FOLDER_UPDATED: 'updated',
        FILE_DOWNLOADED: 'downloaded',
      };
      const itemType = references?.isFolder ? 'folder' : 'file';
      return (
        <React.Fragment>
          <Typography variant={Variant.Title} className={classes.notificationItemTagText}>
            {content?.userRequestName || 'Anonymous'}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  ${types[type]} `}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {` ${itemType} `}
          </Typography>
          <Typography variant={Variant.CardText} className={`${classes.notificationItemText} ${props.classes.notificationFileName}`}>
            {content.fileName || ''}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {` in `}
          </Typography>
          <Typography variant={content.primaryField ? Variant.Title : Variant.ItalicTitle}
            className={classes.notificationItemTagText}>
            {content?.meta?.clientName || content?.workspaceTitle || 'workspace'}
          </Typography>
        </React.Fragment>
      );
    } else if (type === 'MENTION') {
      return (
        <React.Fragment>
          <Typography variant={Variant.Title} className={classes.notificationItemTagText}>
            {content.mentionner || 'Anonymous'}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  mentioned you ${content.databaseTitle && content.databaseTitle.length > 0 ? ' in field ' : ' in '}`}
          </Typography>
          {content.primaryFieldID && (
            <Typography variant={content.primaryField ? Variant.Title : Variant.ItalicTitle}
              className={classes.notificationItemTagText}>
              {`${content.primaryField?.replace(/<[^>]+>|&nbsp;/g, '') || '<Blank Field Name>'}`}
            </Typography>
          )}
          {!content.primaryFieldID && (
            <Typography variant={Variant.Title} className={classes.notificationItemTagText}>Messages</Typography>
          )}
          {
            content.message && (
              <p
                className={classes.mentionMessage}
                dangerouslySetInnerHTML={{ __html: `"${content.message}"` }}
              />
            )
          }
        </React.Fragment>
      );
    } else if (type === 'DOWNLOAD_READY') {
      return (
        <React.Fragment>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`Your file `}
          </Typography>
          <Typography variant={Variant.ItalicTitle}
            className={classes.notificationItemTagText}>
            {content.fileName}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {` is ready to be downloaded.`}
          </Typography>
          <p
            className={classes.mentionMessage}
            dangerouslySetInnerHTML={{ __html: `This file will expire after 30 days!` }}
          />
        </React.Fragment>
      );
    } else if (['GROUP_FILE_CREATED', 'GROUP_FILE_DELETED'].includes(type)) {
      const types = {
        GROUP_FILE_CREATED: 'uploaded',
        GROUP_FILE_DELETED: 'deleted',
      };
      return (
        <React.Fragment>
          <Typography variant={Variant.Title} className={classes.notificationItemTagText}>
            {content?.userRequestName || 'Anonymous'}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  ${types[type]} `}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            { `${content?.items?.length} files` }
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {` in `}
          </Typography>
          <Typography variant={content.primaryField ? Variant.Title : Variant.ItalicTitle}
            className={classes.notificationItemTagText}>
            { content?.workspaceTitle || 'workspace'}
          </Typography>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Typography variant={Variant.Title} className={classes.notificationItemTagText}>
            {`${content.databaseTitle || 'Untitled'}`}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.notificationItemText}>
            {`  due on ${moment(content.dueTime).format('MM/DD/YY')}  `}
          </Typography>
        </React.Fragment>
      );
    }
  };

  const notificationContent = (type: string, content): JSX.Element => {
    return (
      <div className={classes.notificationContentContainer}>
        {notificationDescribe(type, content)}
        {notificationSecondaryAction()}
      </div>
    );
  };

  const generateUrl = (): string => {
    const { databaseType, databaseID, sheetID, workspaceID, itemID, downloadUrl, parentID } = references;
    switch (type) {
      case 'DOWNLOAD_READY':
        return downloadUrl;
      case 'MENTION':
      case 'ADDED_IN_RECORD':
        if (databaseType === 'tasks') {
          return `/workspaces/${workspaceID}/taskdbs/${databaseID}/tasksheets/${sheetID || 'default'}/items/${itemID}`;
        }
        if (databaseType === 'databases') {
          return `/workspaces/${workspaceID}/databases/${databaseID}/sheets/${sheetID || 'default'}/items/${itemID}`;
        }
        return `/workspaces/${workspaceID}/messages`;
      case 'FILE_CREATED':
      case 'FILE_DELETED':
      case 'FILE_DOWNLOADED':
      case 'FILE_UPDATED': {
        let query = `?from=notifications`;
        if (references?.parentID) {
          query += `&parentID=${references.parentID}`;
        }
        return `/workspaces/${workspaceID}/files${query}`;
      }
      case 'FOLDER_CREATED':
      case 'FOLDER_UPDATED':
        return `/workspaces/${workspaceID}/files?parentID=${itemID}`;
      case 'FOLDER_DELETED':
        return `/workspaces/${workspaceID}/files${parentID ? '?parentID=' + parentID : ''}`;
      case 'REMINDER':
        return references.primaryFieldURL;
      default:
        return '';
    }
  };

  return (
    <ListItem
      alignItems="center"
      className={`${classes.notificationItem} ${!isRead ? classes.notificationUnread : ''}`}
      component={['DOWNLOAD_READY', 'MENTION', 'REMINDER'].includes(type) ? 'a' : 'li'}
      onClick={(e) => {
        e.preventDefault();
        props.markAsReadAndOpen(props.id, isRead, generateUrl(), type);
      }}
    >
      <div className={classes.notificationItemLinkLayer} />
      <ListItemAvatar>
        {notificationIcon(type)}
      </ListItemAvatar>
      <ListItemText>
        {notificationContent(type, references)}
        <div className={classes.notificationWorkspace}>
          {references?.workspaceTitle && (
            <Typography variant={Variant.Timestamp} ellipsisOverflow={true}>
              <span>
                {references?.meta?.clientName || references.workspaceTitle}
                {type === 'REMINDER' && references.databaseTitle && ` / ${references.databaseTitle}`}
                {type === 'MENTION' && (references.databaseTitle && references.databaseTitle.length > 0 ? ` / ${references.databaseTitle}` : ' / Messages')}
              </span>
            </Typography>
          )}
          <Typography variant={Variant.Timestamp} ellipsisOverflow={true}>
            {`${moment(createdAt).format('MMM DD, YYYY')} ${moment(createdAt).format('hh:mmA')}`}
          </Typography>
        </div>
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(NotificationsItem);
