import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

type ClassKey = 'editorButton' | 'editorActiveButton';

export const styles: Record<ClassKey, CSSProperties> = {
  editorButton: {
    'fontSize': '12px',
    'color': colors.slateTwo,
    'marginRight': '1px',
    'display': 'inline-block',
    'height': '23px',
    'width': '21px',
    'cursor': 'pointer',
    'borderRadius': '2px',
    'textAlign': 'center',
    '& > svg': {
      'marginTop': '4px',
      'width': '18px',
      'height': '16px',
    },
    '&:hover': {
      'backgroundColor': rgba(colors.black, .05),
    },
  },

  editorActiveButton: {
    'borderRadius': '2px',
    'backgroundColor': rgba(colors.black, .12),
    '&:hover': {
      'backgroundColor': rgba(colors.black, .12),
    },
  },
};
