import { getType } from 'typesafe-actions';
import { Actions, actions, SHOW_GROUP_PANEL, HIDE_GROUP_PANEL } from './groupBy.actions';
import produce from 'immer';

export interface GroupByState {
  rowGroupPanelShow: boolean;
}

const initialState: GroupByState = {
  rowGroupPanelShow: true,
};

const reducer = (state: GroupByState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.changeRowGroupPanelShow): {
      return produce(state, draft => {
        draft.rowGroupPanelShow = !draft.rowGroupPanelShow;
      });
    }
    case SHOW_GROUP_PANEL:
      return {
        rowGroupPanelShow: true,
      };
    case HIDE_GROUP_PANEL:
      return {
        rowGroupPanelShow: false,
      };
    default:
      return state;
  }
};

export default reducer;
