import * as React from 'react';
import Page from 'components/DocumentPreview/Page';
import VirtualList from './VirtualList';
import { PreviewNode } from 'types/response/previewNode';

interface Props {
  zoom: number;
  previews: PreviewNode[];
  scrollToIndex?: number | null;
  onRowsRendered?: (props: { startIndex: number; stopIndex: number }) => void;
}

export default class PagesList extends React.PureComponent<Props> {
  private listRef: any = React.createRef<VirtualList>();

  componentDidUpdate() {
    this.forceUpdateList();
  }

  renderItem = ({ key, style, measure, item }) => {
    return (
      <Page
        key={key}
        src={item.url}
        zoom={this.props.zoom}
        width={item.size.width}
        height={item.size.height}
        style={style}
        measure={measure}
      />
    );
  };

  forceUpdateList = () => {
    this.listRef.current.forceUpdateList();
  };

  scrollToPage = (index: number) => {
    this.listRef.current.scrollToRow(index);
  };

  render() {
    const { scrollToIndex, previews, zoom, onRowsRendered } = this.props;
    return (
      <VirtualList
        ref={this.listRef}
        className="pages-list"
        items={previews}
        zoom={zoom}
        scrollToIndex={scrollToIndex}
        onRowsRendered={onRowsRendered}
        renderItem={this.renderItem}
      />
    );
  }
}
