import React from 'react';
import _ from 'lodash';
import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import describeDiff from './describeDiff';
import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = _.get(activity, 'eventBy.displayName');
  const workspaceTitle = _.get(activity, 'references.workspace.title');
  if (activity.action === ActivityItemStatuses.CREATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          created workspace
        <ReferenceLink references={activity.references} type={ReferenceLinkType.WORKSPACE}>
          {workspaceTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          changed workspace
      </span>
    );
  }
  return 'activity';
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (describeDiff(activity.diff));
  }
}

export default {
  describeActivity,
  describeActivityDetails,
};
