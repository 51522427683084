import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import Button, { Variant } from '../../Button';

interface OwnProps {
  submitLabel: string;
  submitDisabled?: boolean;
  onCancel(): void;
  onSubmit(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Actions(props: Props): JSX.Element {
  return (
    <div className={props.classes.wrapper}>
      <Button
        label="Cancel"
        variant={Variant.SecondaryLink}
        onClick={props.onCancel}
      />
      <Button
        label={props.submitLabel}
        variant={Variant.Primary}
        onClick={props.onSubmit}
        disabled={props.submitDisabled}
      />
    </div>
  );
}

type ClassKey = 'wrapper';

const styles = withStyles<ClassKey>({
  wrapper: {
    padding: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export default styles(Actions);
