import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import IconButton, { Color, Size } from 'components/IconButton';
import svgIcons from 'styles/svgIcons';
import AgGridApi from 'components/AgGrid/AgGridApi';
import { CommonNode } from 'types/response';
import idx from 'idx';

type Props = ICellRendererParams;

const AddRowRenderer = (props: Props): JSX.Element => {
  const handleClick = (): void => {
    const agGridApi = idx<Props, AgGridApi<CommonNode>>(props, _ => _.data.agGridApi);
    if (agGridApi) {
      // Implementation is disabled until we define how to re-implement adding rows with "plus" button
      // agGridApi.addRowToTheEnd({});
    }
  };

  return (
    <IconButton icon={svgIcons.Add} color={Color.DarkGray} size={Size.Medium} onClick={handleClick} />
  );
};

export default AddRowRenderer;
