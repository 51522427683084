import React from 'react';
import { ReferenceLinkType, References } from 'data/activityFeed/types';
import { Link } from 'react-router-dom';

interface Props {
  references: References;
  type: ReferenceLinkType;
  children: React.ReactNode;
}

export default function ReferenceLink(props: Props) {
  const to = createURL(props.type, props.references);
  return (
    <>
      <Link to={to}> {props.children} </Link>
    </>
  );
}

function createURL(type: ReferenceLinkType, references: References): string {
  switch (type) {
    case ReferenceLinkType.WORKSPACE: {
      return `/workspaces/${references.workspaceID}/databases`;
    }
    case ReferenceLinkType.DATABASE: {
      return `${createURL(ReferenceLinkType.WORKSPACE, references)}/${references.databaseID}/sheets/default/items`;
    }
    case ReferenceLinkType.TASK_DATABASE: {
      return `/workspaces/${references.workspaceID}/taskdbs/${references.databaseID}/tasksheets/default/items`;
    }
    case ReferenceLinkType.ITEM: {
      return `${createURL(ReferenceLinkType.DATABASE, references)}/${references.itemID}`;
    }
    case ReferenceLinkType.TASK_ITEM: {
      return `${createURL(ReferenceLinkType.TASK_DATABASE, references)}/${references.itemID}`;
    }
    case ReferenceLinkType.FILE: {
      return `/workspaces/${references.workspaceID}/files`;
    }
    case ReferenceLinkType.MESSAGE: {
      if (!references.primaryFieldID) {
        return `/workspaces/${references.workspaceID}/messages`;
      } else {
        let id = references.itemID || '';
        if (id.indexOf('_') > -1) {
          id = id.replace(`${references.sheetID}_`, '');
        }
        return `${createURL(references.databaseType && references.databaseType === 'task'? ReferenceLinkType.TASK_DATABASE : ReferenceLinkType.DATABASE, references)}/${id}`;
      }
    }
  }
  return '/';
}
