import styled from 'styled-components';
import * as colors from 'styles/colors';
import { ReactComponent as PlugLeftSvg } from 'icons/plug-left.svg';
import { ReactComponent as PlugRightSvg } from 'icons/plug-right.svg';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 226px;
  margin-bottom: 100px;
`;

export const Header = styled.div`
  font-size: 60px;
  font-weight: bold;
  color: ${colors.brightBlue};  
  margin-bottom: 33px;
`;

export const Description = styled.div`
  margin-bottom: 15px;
`;

export const Plugs = styled.div`
  display: flex;
  width: 100%;
`;

export const PlugWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 128px;
`;

export const PlugLeft = styled(PlugLeftSvg)`
  position: absolute;
  right: 54px;
  height: 128px;
`;

export const PlugRight = styled(PlugRightSvg)`
  position: absolute;
  left: 54px;
  height: 109px;
`;
