import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State as ReduxState } from 'reducers';
import CurrentWorkspace from './CurrentWorkspace/CurrentWorkspace';
import WorkspaceMenu from './WorkspaceMenu/WorkspaceMenu';

import { LeftWrapper, Wrapper } from './WorkspaceBar.style';
import { WorkspaceNode } from 'types/response/workspaceNode';

import { getTenantConfig } from 'env';
import { fetchAnalytics } from 'data/analytics/actions';

const tenantConfig = getTenantConfig();

interface Props {
  workspaces: Record<string, WorkspaceNode>;
  currentWorkspace?: WorkspaceNode;
  permissions: string[];
  appPermissions: string[];
}

const WorkspaceBar = (props: Props): JSX.Element | null => {
  // Using useDispatch from React Redux to be able to use hooks as triggers for status changes
  // Also using useSelector to be able to select props from the states
  const dispatch = useDispatch();
  const analytics = useSelector((state: ReduxState) => state.analytics);
  const actualUser = useSelector((state: ReduxState) => state.users);
  const { workspaces, currentWorkspace } = props;

  // @ts-ignore:next-line adding meta variable breaks deeper type checking for workspaceNode
  const workspaceHasMetadata = currentWorkspace && currentWorkspace.meta && currentWorkspace.meta.clientName || false;

  // Checking for Organizer role to hide Messages tab.
  const isTaxRole = (role) => role['name'] === 'Organizer editor';
  const isTenantRole = (role) => role['name'] === 'HubSync Admin';
  const isTaxPayer = actualUser && actualUser.user && actualUser.user.roles && actualUser.user.roles.some(isTaxRole);
  const isTenantAdmin = actualUser && actualUser.user && actualUser.user.roles && actualUser.user.roles.some(isTenantRole);
  const shouldHideMessageTab = isTaxPayer && workspaceHasMetadata && !isTenantAdmin;

  // useEffect is used to check the WorkspaceBar every time the workspace changes,
  // not only when the componen is mounted
  React.useEffect(() => {
    dispatch(fetchAnalytics());
  }, [currentWorkspace]);

  if (!currentWorkspace) {
    return null;
  }

  // Validates if the Workspace actually has the Analytics module
  const menuItems = currentWorkspace?.menuItems?.filter?.((menuItem) => {
    const label = menuItem.label?.toLowerCase();

    if (
      label === 'analytics' &&
      // This validation is to show the analytics tab for specific workspaces
      (!analytics.analytics ||
        // This validation is to show the analytics tab only for editors and admins
        !props.permissions.includes('analytics:create'))
    ) {
      return false;
    }

    if (
      label === 'messages' &&
      // This validation is to show the analytics tab for specific workspaces
      shouldHideMessageTab
    ) {
      return false;
    }

    if (label === 'files') {
      // Dyanmo config to hide the files tab
      if (window.appStore?.getState().app?.tenant?.maintenance?.clientConfigs?.disableFilesTab) return false;
    }


    if (!tenantConfig.enabledFeatures[label]) {
      return false;
    }

    return !(['tasks', 'analytics', 'people'].includes(label) && !props.permissions.includes(`${label}:list`));
  });

  return (
    <Wrapper>
      <LeftWrapper>
        <CurrentWorkspace
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
          permissions={props.permissions}
          appPermissions={props.appPermissions}
        />
        <WorkspaceMenu menuItems={menuItems} />
      </LeftWrapper>
    </Wrapper>
  );
};

export default WorkspaceBar;
