import * as React from 'react';
import { closeModal } from 'data/modals/actions';
import { connect } from 'react-redux';
import Rename from 'components/Modals/Rename';
import { Choice } from 'types/schema';
import { Actions as CollectionActions } from 'data/collections/collections.actions';
import { getUserDefinedFieldKey } from 'utilities/collections';

interface DispatchProps {
  updateField: typeof CollectionActions.updateField;
  close: () => void;
}

interface OwnProps {
  categoryFieldId: string;
  choiceId: string;
  choices: Record<string, Choice>;
}

type Props = DispatchProps & OwnProps;

export class RenameKanbanColumn extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { choiceId, choices, close } = this.props;
    const { [choiceId]: choice, ...existingChoices } = choices || {};
    const existingLabels: string[] = Object
      .values(existingChoices)
      .map(choice => choice.label);

    return (
      <Rename
        currentValue={choice.label}
        existingValues={existingLabels}
        close={close}
        onUpdate={this.handleUpdateColumn}
        windowTitle="Rename Column Title"
        valueTitle="title"
      />
    );
  };

  private handleUpdateColumn = (newLabel: string): void => {
    const { updateField, choices, choiceId, categoryFieldId } = this.props;
    const newChoices: Record<string, Choice> = {
      ...choices,
      [choiceId]: {
        ...choices[choiceId],
        label: newLabel,
      },
    };
    updateField({
      fieldId: getUserDefinedFieldKey(categoryFieldId),
      choices: newChoices,
    });
  };
}

const mapDispatchToProps: DispatchProps = {
  updateField: CollectionActions.updateField,
  close: closeModal,
};

export default connect(null, mapDispatchToProps)(RenameKanbanColumn);
