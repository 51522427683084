import * as React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import './DatePicker.scss';
import { createPopper } from '@popperjs/core';
import Button, { Size, Variant as ButtonVariant } from 'components/Button';
import {
  Notification as DatePickerNotifyI,
} from 'components/ui/DatePicker/DatePickerNotify/DatePickerNotify';
import { Reminder, References, actions as remindersActions } from 'data/reminders/reminders';
import { isEqual } from 'lodash';
import moment from 'moment';
import { DayPickerInputProps } from 'react-day-picker';
// @ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';

import Confirm from '../../Modals/Confirm';
import Modal from '../../Modals/Modal';
import Notify from './DatePickerNotify/Notify';
import { getDateWithUTCOffset, isValidDate } from './DatePickerNotify/Reminder';

import Portal from './Portal';

interface DayPickerInputPropsExtended extends DayPickerInputProps {
  hasNotify?: boolean;
  onNotifyChange?: (value: DatePickerNotifyI[]) => void;
  reminders?: Reminder[];
  onStopEditing?: () => void;
  clearDate?: () => void;
  reminderKey?: string;
  primaryFieldName?: string;
  references?: References;
  onDateChange?: (timestamp: number | string) => void;
  onInputChange?: (timestamp: number | string) => void;
  permissions?: string[];
  isGrid?: boolean;
  bulkReminders?: Array<Reminder>;
  clearChanged?: boolean;
  readOnly?: boolean;
}

enum DatePickerModes {
  BROWSE = 'browse',
  CLEAR_CONFIRM = 'clear_confirm',
}

interface State {
  value: string;
}

const DATE_FORMAT = 'MM/DD/YYYY';

// date picker reference to expose methods
// let dayPickerInputRef;

class DatePickerOverlay extends React.Component<any, State> {
  triggerRef;
  popupRef;
  popper;

  state = {
    value: '',
  };

  constructor(props) {
    super(props);

    this.triggerRef = React.createRef();
    this.popupRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value ? moment(this.props.value).format(this.props.format) : moment().format(this.props.format),
    });

    this.popper = createPopper(
      this.triggerRef.current,
      this.popupRef.current,
      {
        placement: 'auto-end',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              boundary: document.querySelector('body'),
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: ({ placement, reference }) => {
                const datePickerHeight = 387;
                const datePickerWidth = 240;
                switch (placement) {
                  case 'top':
                    return [0, 40];
                  case 'left-start':
                    if (datePickerHeight + reference.y > window.innerHeight) {
                      return [-(datePickerHeight + reference.y - window.innerHeight), datePickerWidth - reference.width];
                    }
                  // eslint-disable-next-line no-fallthrough
                  case 'left-end': {
                    if (reference.width >= datePickerWidth) {
                      return [];
                    }
                    return [0, datePickerWidth - reference.width];
                  }
                  case 'right-start': {
                    if (datePickerHeight + reference.y > window.innerHeight) {
                      const xPosition = datePickerWidth + reference.x > window.innerWidth ? datePickerWidth - reference.width: 0;
                      return [-(datePickerHeight + reference.y - window.innerHeight), xPosition];
                    }
                    return [];
                  }
                  default:
                    return [];
                }
              },
            },
          },
        ],
      },
    );
  }

  componentWillUnmount() {
    this.popper.destroy();
  }

  render() {
    const {
      classNames, selectedDay, children, hasNotify, scheduleUpdate, clearDate, reminders, format, permissions,
      reminderKey, primaryFieldName, references, onNotifyDateChange, isGrid, fieldName, onClearDate, hideDayPicker, ...props
    } = this.props;
    return (
      <>
        <div ref={this.triggerRef}>
          <Portal>
            <div className={classNames.overlayWrapper} {...props} ref={this.popupRef}>
              <div className={`datepicker-inner-wrapper ${!hasNotify ? 'shrink-date-picker' : ''}`}>
                <div className={classNames.overlay}>
                  <div className="datepicker-date-input-container">
                    <input id="date-picker-overlay-input" type="text" value={this.state.value} onChange={this.onChange} disabled={!permissions?.includes('items:update') && hasNotify} />
                    <Button label="Today" variant={ButtonVariant.BackgroundLink} size={Size.Normal} onClick={this.setTodayDate} disabled={!permissions?.includes('items:update') && hasNotify} />
                  </div>
                  {children}
                  {hasNotify && (
                    <Notify
                      {...props}
                      dateValue={this.state.value}
                      bulkReminders={this.props.bulkReminders}
                      reminders={reminders}
                      reminderKey={reminderKey}
                      primaryFieldName={primaryFieldName}
                      references={references}
                      onNotifyDateChange={onNotifyDateChange}
                      format={format}
                      permissions={permissions}
                      isScheduledOnChange={() => {
                        scheduleUpdate?.();
                      }}
                    />
                  )}
                  {hasNotify && (
                    <div className="calendar-actions">
                      <Button label="Clear" variant={ButtonVariant.SecondaryLink} onClick={this.props.onClearDate} disabled={!permissions?.includes('items:update') && hasNotify} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Portal>
        </div>
      </>
    );
  }

  private setTodayDate = (): void => {
    this.setState({ value: moment().format(this.props.format) });
    this.props.onChange(moment().format(this.props.format));
    if (!this.props.hasNotify) {
      this.props.hideDayPicker();
    }
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    this.setState({ value: event.target.value });
    if (moment(event.target.value).isValid()) {
      if (isValidDate(event.target.value)) {
        if (!this.props.hasNotify) {
          this.props.onChange(moment(event.target.value).format(this.props.format));
          return;
        }
        this.props.onChange(event.target.value);
      }
    }
    if (!event.target.value && this.props.hasNotify) {
      this.props.onClearDate();
    }
  };
}

export class DatePicker extends React.Component<DayPickerInputPropsExtended> {
  private dayPickerInputRef: DayPickerInput | null = null;

  state = {
    reminders: this.props.reminders || [],
    value: '',
    initialValue: '',
    mode: DatePickerModes.BROWSE,
  };

  public componentDidMount(): void {
    this.setState({ value: this.props.value, initialValue: this.props.value });
    document.body.addEventListener('click', this.handleClick);
    if (this.props.inputProps?.['inputRef']?.current && this.props.hasNotify) {
      this.props.inputProps?.['inputRef']?.current?.addEventListener('keydown', this.handleKeyDown);
    }
  }

  public componentWillUnmount(): void {
    document.body.removeEventListener('click', this.handleClick);
    if (this.props.hasNotify) {
      if (this.props.inputProps?.['inputRef']?.current) {
        this.props.inputProps?.['inputRef']?.current?.addEventListener('keydown', this.handleKeyDown);
      }
      this.updateReminders();
    }
  }

  public componentDidUpdate(prevProps: Readonly<DayPickerInputPropsExtended>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.props.onNotifyChange && this.props.onNotifyChange(this.state.reminders || []);
    }

    if (this.props.clearChanged !== prevProps['clearChanged']) {
      this.onClearDate();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      value: nextProps.value,
    };
  }

  public render(): JSX.Element {
    const isDatePickerChanged = !this.props.clearChanged;
    const { readOnly } = this.props;
    return (
      <React.Fragment>
        {(this.state.mode === DatePickerModes.CLEAR_CONFIRM && isDatePickerChanged ) && (
          <DayPickerInput
            key="DayPickerInput"
            parseDate={parseDate}
            formatDate={formatDate}
            keepFocus={true}
            hideOnDayClick={!this.props.hasNotify}
            dayPickerProps={{ todayButton: 'Today', month: moment(this.state.value || this.props.value || moment()).toDate() }}
            overlayComponent={(overlayProps) => {
              return (
                this.state.mode !== DatePickerModes.CLEAR_CONFIRM && !readOnly ? (
                  <DatePickerOverlay
                    clearDate={this.clearDate}
                    bulkReminders={this.props.bulkReminders}
                    {...overlayProps}
                    hasNotify={this.props.hasNotify}
                    reminders={this.props.reminders}
                    value={this.props.hasNotify ? (this.state.value || this.props.value) : this.props.value}
                    onChange={this.onInputChange}
                    reminderKey={this.props.reminderKey}
                    primaryFieldName={this.props.primaryFieldName}
                    references={this.props.references}
                    onNotifyDateChange={this.onNotifyDateChange}
                    permissions={this.props.permissions}
                    format={this.props.format || DATE_FORMAT}
                    onClearDate={this.onClearDate}
                    hideDayPicker={this.hideDayPicker}
                  />) : (<></>)
              );
            }}
            ref={ref => this.dayPickerInputRef = ref}
            onDayChange={this.onDayChange}
            {...this.props}
            value={this.props.hasNotify ? (this.state.value || this.props.value) : this.props.value}
          />
        )}
        {(this.state.mode !== DatePickerModes.CLEAR_CONFIRM || !isDatePickerChanged) && (
          <DayPickerInput
            key="DayPickerInput"
            parseDate={parseDate}
            formatDate={formatDate}
            keepFocus={true}
            hideOnDayClick={!this.props.hasNotify}
            dayPickerProps={{ todayButton: 'Today', month: moment(this.state.value || this.props.value || moment()).toDate() }}
            overlayComponent={(overlayProps) => {
              return (
                this.state.mode !== DatePickerModes.CLEAR_CONFIRM && !readOnly ? (
                  <DatePickerOverlay
                    clearDate={this.clearDate}
                    bulkReminders={this.props.bulkReminders}
                    {...overlayProps}
                    hasNotify={this.props.hasNotify}
                    reminders={this.props.reminders}
                    value={this.props.hasNotify ? (this.state.value || this.props.value) : this.props.value}
                    onChange={this.onInputChange}
                    reminderKey={this.props.reminderKey}
                    primaryFieldName={this.props.primaryFieldName}
                    references={this.props.references}
                    onNotifyDateChange={this.onNotifyDateChange}
                    permissions={this.props.permissions}
                    format={this.props.format || DATE_FORMAT}
                    onClearDate={this.onClearDate}
                    hideDayPicker={this.hideDayPicker}
                  />) : (<></>)
              );
            }}
            ref={ref => this.dayPickerInputRef = ref}
            onDayChange={this.onDayChange}
            {...this.props}
            value={this.props.hasNotify ? (this.state.value || this.props.value) : this.props.value}
          />
        )}
        {this.state.mode === DatePickerModes.CLEAR_CONFIRM && (
          <Modal onClose={this.onClose}>
            <Confirm
              title="Are you sure you want to remove the date?"
              close={this.onClose}
              onConfirm={this.confirmClearDate}
              confirmLabel="Accept"
              message="Removing the date will delete reminders"
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  private hideDayPicker = () => {
    this.dayPickerInputRef?.hideDayPicker?.();
  };

  private handleKeyDown = (event) => {
    if (this.props.reminders?.length && !event.target.value) {
      event.stopPropagation();
      this.onClearDate();
    }
  };

  private onClose = () => {
    this.setState({ mode: DatePickerModes.BROWSE });
    this.props.onDateChange?.(moment(this.state.initialValue).toDate().getTime());
  };

  private onClearDate = () => {
    if (this.props.reminders?.length === 0) {
      this.clearDate();
      return;
    }
    this.setState({ mode: DatePickerModes.CLEAR_CONFIRM });
  };

  private confirmClearDate = () => {
    this.setState({ mode: DatePickerModes.BROWSE });
    this.clearDate();
  };

  private clearDate = () => {
    this.setState({ value: '' });
    this.hideDayPicker();
    setTimeout(() => {
      this.props.onDateChange?.('');
    }, 0);
    const store = window.appStore;
    store.dispatch(remindersActions.requestBulkChange({ ids: { toDelete: this.props.reminders?.map(reminder => reminder?._id), keysToDelete: [this.props.reminderKey] } }));
  };

  private updateReminders = () => {
    if (!this.props.reminders?.length || !this.props.value || moment(this.props.value).isSame(this.state.initialValue, 'day')) {
      return;
    }

    const store = window.appStore;
    const timestamp = moment(this.props.value).toDate().getTime();
    this.props.reminders.forEach(async (reminder: Reminder) => {
      const timestampWithOffset = getDateWithUTCOffset(timestamp);
      if (reminder.timestamp === timestampWithOffset) {
        return;
      }
      store.dispatch(remindersActions.requestPatch({ id: reminder._id, data: { timestamp: timestampWithOffset, version: reminder.version } }));
    });
  };

  private onDayChange = (date) => {
    if (!date && this.props.reminders?.length && this.props.hasNotify) return;
    if (!this.props.permissions?.includes('items:update')) return;

    if (document.activeElement === this.props.inputProps?.['inputRef']?.current) {
      if (date && isValidDate(moment(date).format(DATE_FORMAT))) {
          this.props.onInputChange?.(moment(date).toDate().getTime());
      }

      if (this.props.inputProps?.['inputRef']?.current?.value === '') {
          this.props.onInputChange?.('');
      }

      return;
    }

    if (!date) {
        this.props.onInputChange?.('');
    }

    if (!this.props.isGrid) {
      if (date && isValidDate(moment(date).format(DATE_FORMAT))) {
          this.props.onDateChange?.(moment(this.props.value).toDate().getTime());
      } else {
          this.props.onDateChange?.('');
          return;
      }
    }

    if (isValidDate(moment(date).format(DATE_FORMAT))) {
        this.props.onInputChange?.(moment(date).toDate().getTime());
    }

    this.stopEditing();

    this.hideDayPicker();
  };

  private stopEditing = () => {
    setTimeout(() => {
      this.props.onStopEditing?.();
    }, 0);
  };

  private onNotifyDateChange = (value) => {
    this.props.onInputChange?.(moment(value).toDate().getTime());
    this.setState({ value: moment(value).format(DATE_FORMAT) });
    setTimeout(() => {
      this.updateReminders();
    }, 0);
  };

  private onInputChange = (value: string): void => {
    this.setState({ value });
    this.props.onInputChange?.(moment(value).toDate().getTime());
    this.props.onDateChange?.(moment(value).toDate().getTime());
    if (document.activeElement?.id !== 'date-picker-overlay-input') {
      this.hideDayPicker();
      this.stopEditing();
    }
  };

  private handleClick = (event) => {
    if (!this.props.permissions?.includes('items:update') && this.props.hasNotify && event.target?.classList.contains('DayPicker-Day')) {
      event.stopPropagation();
      return;
    }

    if (this.dayPickerInputRef && this.shouldHideCalendar(event)) {
      setTimeout(() => {
        this.stopEditing();
      }, 0);
      this.hideDayPicker();
    }
  };

  private shouldHideCalendar = (event): boolean => {
    let hideCalendar = true;
    let target = event.target;
    if (target === document.body) {
      hideCalendar = false;
    }
    while (target !== document.body) {
      if (!target) {
        break;
      }

      if (target.classList.contains('ag-cell') ||
        target.classList.contains('date-picker-notify-select') ||
        target.classList.contains('date-picker-notify-select-item') ||
        target.classList.contains('DayPickerInput-OverlayWrapper') ||
        target.classList.contains('DayPickerInput') ||
        target.className.indexOf?.('MuiBackdrop') >= 0
      ) {
        hideCalendar = false;
        break;
      }
      target = target.parentNode;
    }

    if (hideCalendar) {
      const dialogs = [].filter.call(document.body.childNodes, (node: HTMLDivElement) => node?.getAttribute?.('role') === 'dialog');
      if ((this.props.isGrid && dialogs?.length === 1) || (!this.props.isGrid && dialogs?.length > 1)) {
        return false;
      }
    }

    return hideCalendar;
  };
}
