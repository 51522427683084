import { SortEnd } from 'react-sortable-hoc';
import { action, ActionsUnion } from '../../types/reduxHelpers';
import { SortModel } from '../../types/gridOptions';
import { ChangeSortColumnPayload, ChangeSortOperatorPayload } from './sortModel.reducer';

export const SET_SORT_MODEL = 'SET_SORT_MODEL';
export type SET_SORT_MODEL = typeof SET_SORT_MODEL;
export const ADD_SORT = 'ADD_SORT';
export type ADD_SORT = typeof ADD_SORT;
export const CHANGE_SORT_COLUMN = 'CHANGE_SORT_COLUMN';
export type CHANGE_SORT_COLUMN = typeof CHANGE_SORT_COLUMN;
export const CHANGE_SORT_OPERATOR = 'CHANGE_SORT_OPERATOR';
export type CHANGE_SORT_OPERATOR = typeof CHANGE_SORT_OPERATOR;
export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER';
export type CHANGE_SORT_ORDER = typeof CHANGE_SORT_ORDER;
export const DELETE_SORT = 'DELETE_SORT';
export type DELETE_SORT = typeof DELETE_SORT;
export const RESET_SORT_MODEL = 'RESET_SORT_MODEL';

interface ResetSortModel {
  type: typeof RESET_SORT_MODEL;
}

export const resetSortModel = (): ResetSortModel => {
  return {
    type: RESET_SORT_MODEL,
  };
};

export const Actions = {
  addSort: action<typeof ADD_SORT, SortModel>(ADD_SORT),
  changeSortColumn: action<typeof CHANGE_SORT_COLUMN, ChangeSortColumnPayload>(CHANGE_SORT_COLUMN),
  changeSortOperator: action<typeof CHANGE_SORT_OPERATOR, ChangeSortOperatorPayload>(CHANGE_SORT_OPERATOR),
  deleteSort: action<typeof DELETE_SORT, string>(DELETE_SORT),
  setSortModel: action<typeof SET_SORT_MODEL, SortModel[]>(SET_SORT_MODEL),
  changeSortOrder: action<typeof CHANGE_SORT_ORDER, SortEnd>(CHANGE_SORT_ORDER),
  resetSortModel,
};

export type Actions = ActionsUnion<typeof Actions>;
