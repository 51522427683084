import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import HeaderBar from 'components/HeaderBar/LoggedOut';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import Logo from 'icons/hubsync-logout-logo.svg';
import * as Colors from 'styles/colors';
import { TenantNode } from 'types/response/tenantNode';
import { useSelector } from 'react-redux';
import { State as ReduxState } from '../../reducers';

interface OwnProps {
  tenant?: TenantNode;
  search?: string;
}

type Props =
  & OwnProps
  & WithStyles<ClassKey>
  ;

function LinkExpired(props: Props): JSX.Element {
  const tenant = useSelector((state: ReduxState) => state.app.tenant);

  return (
    <div className={props.classes.mainPage}>
      <HeaderBar tenant={tenant}>
        Guest Access
      </HeaderBar>
      <div className={props.classes.expiredContent}>
        <div className="expire-logo">
          <img
            className={props.classes.logo}
            src={(tenant && tenant.theme && tenant.theme.logoBlackURL) || Logo}
            alt={'Logo'}
          />
        </div>
        <Typography
          variant={TypographyVariant.SubHeading}
          className={props.classes.title}
        >
          Sorry, this link is no longer active
        </Typography>
        <Typography
          variant={TypographyVariant.CardText}
          className={props.classes.subtitle}
        >
          Please check with the person who shared it with you to
          see if there&apos;s a new link available.
        </Typography>
      </div>
    </div>
  );
}

type ClassKey =
  | 'mainPage'
  | 'expiredContent'
  | 'logo'
  | 'title'
  | 'subtitle'
  ;

const styles = withStyles<ClassKey>({
  mainPage: {
    height: '100vh',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  expiredContent: {
    position: 'relative',
    top: '130px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: { marginBottom: '40px' },
  title: {
    color: Colors.hubsyncBlue,
    marginBottom: '8px',
    fontSize: '18px',
  },
  subtitle: {
    fontSize: '16px',
    marginBottom: '36px',
    textAlign: 'center',
    width: '450px',
  },
});

export default styles(LinkExpired);
