import React, { useState } from 'react';

import { InputLabel, MenuItem, Select, withStyles, WithStyles } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import SearchBar from 'components/SearchBar';
import { Size as SearchBarSize } from 'components/SearchBar/SearchBar';
import * as Colors from 'styles/colors';

export interface Item {
  // Item id
  id: string | number;
  // Text that will be filtered on search change
  text: string;
  // Element to be rendered as item. If there is no element, it'll render the text search
  element?: React.ReactNode;
}

interface SelectSearchBoxProps extends WithStyles<typeof styles> {
  onChange?: (selectedItem: Item) => void;
  items: Item[];
  // Item id that should be selected
  value?: string | number;
  // Search bar placeholder
  searchBarPlaceholder?: string;
  // Search bar initial value
  searchBarValue?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  label?: string;
}

function SelectSearchBox(props: SelectSearchBoxProps): JSX.Element {
  const {
    onChange,
    items,
    value,
    classes,
    searchBarPlaceholder,
    searchBarValue,
    readOnly,
    autoFocus,
    fullWidth,
    label,
  } = props;
  const [searchValue, setCurrentSearchValue] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<Item | undefined>(value && items.find((item) => item.id.toString() === value)?.[0]);

  const handleOnChange = (_event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactElement<MenuItemProps>) => {
    const targetValue = child.props.value?.toString();
    const item = items.find((item) => item.id.toString() === targetValue);
    setCurrentItem(item);
    if (onChange instanceof Function && item) onChange(item);
  };

  const handleSearchBarChange = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    setCurrentSearchValue(e.currentTarget.value);
  };

  return (
    <div className={`${classes.selectWrapper}`}>
      {label && <InputLabel htmlFor="selectSearchBox" className={`${classes.inputLabel}`}>{label}</InputLabel>}
      <Select
        id="selectSearchBox"
        name="selectSearchBox"
        onChange={handleOnChange}
        fullWidth={fullWidth}
        value={currentItem?.id ?? ''}
        className={`${classes.select}`}
        autoFocus={autoFocus}
        readOnly={readOnly}
        renderValue={() =>
          currentItem?.element ? <MenuItem value={currentItem.id} key={currentItem.id}>
            {currentItem.element ?? currentItem.text}
          </MenuItem> : (currentItem?.text ?? '')
        }
      >
        <SearchBar
          className="hubsync-menu-searchbar"
          placeholder={searchBarPlaceholder ?? 'Find an option'}
          value={searchBarValue ?? ''}
          size={SearchBarSize.Small}
          onChange={handleSearchBarChange}
          autoFocus
        />
        {
          items
            .filter((item) => searchValue ? item.text.toLowerCase().includes(searchValue.toLowerCase()) : item)
            .map((item: Item) =>
              <MenuItem value={item.id} key={item.id}>
                {item.element ?? item.text}
              </MenuItem>,
            )
        }

      </Select>
    </div>
  );
}

const styles: Record<
  'select' | 'selectWrapper' | 'inputLabel',
  CSSProperties
> = {
  select: {
    border: `1px solid ${Colors.lightBlueGrey}`,
    borderRadius: '2px',
    height: 40,
    position: 'static',
  },
  selectWrapper: {},
  inputLabel: {
    textTransform: 'none',
  },
};

export default withStyles(styles)(SelectSearchBox);
