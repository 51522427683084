import styled from 'styled-components';

export const Container = styled.div<{justifyContent?: string}>`
    display: flex;
    flex-direction: row;
    justify-items: space-between;
    margin-bottom: 18px;
    justify-content: ${props => props.justifyContent || 'flex-start'};
`;

export const Column = styled.div`
    align-items: space-between;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Input = styled.input`
    border-radius: 2px;
    border: 1px solid #A19F9D;
    height: 32px;
    margin-top: 5px;
    padding: 5px;
    width: ${props => props.width + 'rem'};

    ::placeholder {
        font-size: 14px;
        color: #A19F9D;
    }

    :focus {
        background-color: #E8F2FF;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 500;

    span {
        font-weight: normal;
    }
`;

export const TextArea = styled.textarea`
    border-radius: 2px;
    border: 1px solid #A19F9D;
    height: 60px;
    margin-top: 5px;
    padding: 5px;
    resize: none;
    :focus {
        background-color: #E8F2FF;
    }
`;

export const Error = styled.span<{avaliable?: string}>`
    color: ${props => props.avaliable ? props.avaliable :'#A80000'};
    font-size: 12px;
    padding-top: 3px;
`;

export const ButtonForm = styled.button<{hover: string; active: string}>`
    --webkit-appearance: none;
    background-color: ${props => props.color ? props.color : '#96BBF8'};
    border-radius: 4px;
    color: white;
    font-size: 14px;
    height: 2.57rem;
    width: 6.07rem;

    :hover {
        background-color: ${props => props.hover ? props.hover : '#96BBF8' };
    }
    :active {
        background-color: ${props => props.active ? props.active : '#96BBF8' };
    }
`;

export const ButtonFormCancel = styled.button`
    --webkit-appearance: none;
    border-radius: 4px;
    border: 2px solid #A19F9D;
    color: #4A4A4A;
    font-size: 14px;
    height: 2.57rem;
    margin-right: 1.42rem;
    width: 6.07rem;
`;

export const Form = styled.form`
    width: 45rem;
`;
