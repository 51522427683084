import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { composeControls, Control, getSchemaColumns } from 'data/collections/collections.selectors';
import { RoleNames, User } from 'data/users/users.types';
import { FormikProps, withFormik } from 'formik';
import * as _ from 'lodash/fp';
import styled from 'styled-components';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { SvgIcon } from 'types/common';
import { CommonNode } from 'types/response';
import { FieldType } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import { Schema } from 'types/schema';
import { createPartialNodeFromDefaultSchemaValues } from 'utilities/collections';
import { ValidateNode } from 'utilities/jsonSchema';
import { getUserRole } from 'utilities/permissions';

import AddColumnHeaderModal from '../../AddColumnHeader/AddColumnHeaderModal';
import Button, { Variant as ButtonVariant } from '../../Button';
import ChecklistContainer from './Checklist/ChecklistContainer';
import { styles } from './CollectionForm.style';
import { editorFactory } from './componentsFactory';
import { getIconForFieldTypeLabel } from './helpers';
import { Reminder as ReminderType } from 'data/reminders/reminders';
import { EMAIL_REGEXP } from '../../../constants';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { notifications } from 'data/ui/notifications/notifications.actions';

interface OwnProps {
  schema: Schema;
  node: Partial<CommonNode>;
  view: ViewNode;
  fieldIDs?: string[]; // If it's provided, should use only them
  permissions: string[];
  user?: User;
  type?: string;
  onClose: () => void;
  onSubmit: (values: CommonNode, closeModal: boolean, forceUpdate?: boolean) => void;
  openLockPermissionsModal?: () => void;
  bulkReminders?: Array<ReminderType>;
}
interface DispatchProps {
  warningToast: typeof notifications.warn;
}

type Props = OwnProps & DispatchProps & FormikProps<any> & WithStyles<typeof styles>;

interface State {
  controls: Control[];
  addFieldModalAnchorEl: HTMLElement | null;
}

const IconWrapper = styled('span')<{fieldType: FieldType}>`
  color: ${Colors.slateTwo};
  width: 11px;
  position: relative;
  > svg {
    margin-top: 13px;
    width: 0.833rem;
    height: 0.917rem;
  }
`;
class CollectionForm extends React.Component<Props, State> {
  private checklist;
  private controls;
  private formRef: React.RefObject<HTMLFormElement> = React.createRef();
  private collectionEndRef: React.RefObject<HTMLDivElement> = React.createRef();
  public constructor(props: Props) {
    super(props);
    this.state = {
      controls: [],
      addFieldModalAnchorEl: null,
    };
  }

  public componentDidMount = (): void => {
    this.handleFields();
  };

  public componentDidUpdate = (prevProps: Props): void => {
    const shouldUpdateControls =
      !_.isEqual(prevProps.node, this.props.node)
      || !_.isEqual(prevProps.schema, this.props.schema)
      || !_.isEqual(prevProps.fieldIDs, this.props.fieldIDs);
    if (shouldUpdateControls) {
      this.handleFields();
    }
  };

  private handleClick = (userRole: RoleNames, readOnly) => {
    const { openLockPermissionsModal } = this.props;

    if (userRole === RoleNames.Editor && readOnly) {
      openLockPermissionsModal?.();
    }
  };

  public render = (): JSX.Element => {
    const { controls, addFieldModalAnchorEl } = this.state;
    const {
      values,
      errors,
      handleSubmit,
      isSubmitting,
      onClose,
      classes,
      type,
      bulkReminders,
    } = this.props;

    const inlineEditorContainerClass = 'inlineEditorContainer';
    const enableSave = !isSubmitting && _.isEmpty(errors);
    const permissions = this.props.permissions || [];

    const canLockView = permissions.includes('views:lock'); // 20200310 - Currently only admin has views:lock permission
    const showAddFieldBtn = (permissions.includes('fields:create') || permissions.includes('fields:update')) && canLockView;

    const userRole: RoleNames = getUserRole(permissions);

    return (
      <>
        <form onSubmit={handleSubmit} className={classes.root} ref={this.formRef}>
          <div className={classes.content}>
            {controls.map((control, index) => {
              const { name, fieldType, id, meta, readOnly, lock } = control;
              const FieldIcon: SvgIcon = getIconForFieldTypeLabel(fieldType);

              return (
                <React.Fragment key={id}>
                  <div className={classes.inlineField}>
                    <div className={classes.fieldLabel}>
                      <FieldIcon className={classes.fieldIcon} />
                      <span className={classes.fieldLabelText}>
                        {name}
                      </span>
                    </div>
                    {userRole !== RoleNames.Reader && lock ? <IconWrapper fieldType={fieldType}><svgIcons.LockFilled /></IconWrapper> : <div>&nbsp;</div>}
                    <div className={`${classes.inlineEditorContainer} ${inlineEditorContainerClass}`} onClick={() => this.handleClick(userRole, readOnly)}>
                      {editorFactory({
                        value: _.get(id, values),
                        name,
                        onChange: permissions.includes('items:create') || permissions.includes('items:update')
                          ? this.handleChange(id)
                          : () => null,
                        fieldType,
                        fieldId: id,
                        data: values,
                        meta: { ...meta, target: 'collection_form' },
                        autoFocus: index === 0,
                        readOnly: !!readOnly,
                        lock: userRole !== RoleNames.Reader && lock,
                        type,
                        onBlur: this.handleBlur,
                        permissions,
                        bulkReminders: type === 'CREATE' ? bulkReminders : undefined,
                      })}
                      {errors[id] && <FormHelperText className={classes.errorFooter}>{errors[id]}</FormHelperText>}
                    </div>
                  </div>
                  {this.props.node.id && index === 0 ?
                    <>
                      <ChecklistContainer innerRef={ref => this.checklist = ref} itemId={this.props.node.id} permissions={this.props.view.permissions} />
                    </>
                    : null}
                </React.Fragment>
              );
            })}
          </div>
          <div className={classes.addFieldsContainer} ref={this.collectionEndRef}>
            {showAddFieldBtn && (
              <Button
                id="btnAddField"
                label="Add a Field"
                variant={ButtonVariant.BackgroundLink}
                icon={svgIcons.Add}
                onClick={this.handleOpenAddFieldPopper}
              />
            )}
            {this.checklist &&
            (this.props.view.permissions || []).includes('checklists:create') &&
            this.props.node.id &&
            this.checklist.props.checklists.length > 0 && (
              <Button
                id="btnAddChecklist"
                label="Add Checklist"
                variant={ButtonVariant.BackgroundLink}
                icon={svgIcons.Add}
                onClick={this.addChecklist}
              />
            )}
          </div>
          {!this.props.node.id && (
            <>
              <div className={classes.divider} />
              <div className={classes.actionButtons}>
                <Button id="btnCancel" label="Cancel" variant={ButtonVariant.SecondaryLink} onClick={onClose} />
                {(permissions.includes('items:create') || permissions.includes('items:update')) && (
                  <Button id="btnSave" label="Save" variant={ButtonVariant.Primary} type="submit" disabled={!enableSave} />
                )}
              </div>
            </>
          )}
        </form>
        {!!addFieldModalAnchorEl &&
          <AddColumnHeaderModal
            anchorEl={addFieldModalAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClosePopper={this.handleCloseAddFieldPopper}
          />
        }
      </>
    );
  };

  private addChecklist = (): void => {
    if (!this.checklist) return;
    this.checklist.addChecklist();
  };

  private handleFields = (): void => {
    const { schema, fieldIDs, permissions } = this.props;
    const columns = getSchemaColumns(schema);

    const keys = fieldIDs || Object.keys(columns);

    const controls = composeControls(columns, keys, permissions);
    const fieldsReady = controls.length;

    const changes = _.pickBy(
      (value, key) => !this.props.values.fields || !_.isEqual(value, this.props.values.fields[key]),
      this.props.node.fields,
    ) || {};

    if ((Object.keys(changes).length)) {
      for (const key of Object.keys(changes)) {
        if (changes[key]) {
          this.props.setFieldValue(`fields.${key}`, changes[key]);
        }
      }

      setTimeout(() => {
        this.handleBlur(null, changes);
      }, 100);
    }

    if (fieldsReady) {
      this.setState({
        controls,
      }, () => {
        // scroll down when a new column/field is added to the grid schema inside the modal
        if (this.controls && controls.length !== this.controls.length) {
          this.formRef && this.formRef.current && (this.formRef.current.scrollTop = this?.collectionEndRef?.current?.offsetTop || 0);
        }

        this.controls = controls;
      });
    }
  };

  private handleChange = (id: string) => async (value: any, immediateSave?: false) => {
    this.props.setFieldValue(id, value);
    if (immediateSave) {
      await Promise.resolve();
      this.handleBlur();
    }
  };

  private handleBlur = (_event?: any, changes?: any) => {
    const partialNode = {
      fields: (changes && changes) || _.pickBy(
        (value, key) => {
          if (value && this.props.schema.properties.fields?.properties?.[key]?.fieldType === FieldType.Email) {
            const validEmail = new RegExp(EMAIL_REGEXP).test(value);
            if (!validEmail ) this.props.warningToast({ message: `${value} is not a valid email address` });
            return validEmail;
          } else {
            return !this.props.node.fields || !_.isEqual(value, this.props.node.fields[key]);
          }
        },
        this.props.values.fields,
      ),
    };
    if (!this.props.node.id) return;
    if ((this.props.permissions || []).includes('items:create') ||
      (this.props.permissions || []).includes('items:update')) {
      if (Object.keys(partialNode.fields).length) {
        this.props.onSubmit(this.props.values, false, !!changes);
      }
    }
  };

  private handleOpenAddFieldPopper = (event: React.SyntheticEvent<HTMLElement>): void => {
    const { currentTarget } = event;
    this.setState({
      addFieldModalAnchorEl: currentTarget,
    });
  };

  private handleCloseAddFieldPopper = (): void => {
    this.setState({ addFieldModalAnchorEl: null });
  };
}


const mapStateToProps = (state: ReduxState) => (state);

const mapDispatchToProps: DispatchProps = {
  warningToast: notifications.warn,
};

export default withFormik<OwnProps, any>({
  handleSubmit: (values, {
    props,
    setErrors /* setValues, setStatus, and other goodies */,
  }) => {
    if ((props.permissions || []).includes('items:create') ||
      (props.permissions || []).includes('items:update')) {
      props.onSubmit(values, true);
    }
  },
  mapPropsToValues: (props) => {
    const node = props.node || createPartialNodeFromDefaultSchemaValues(props.schema);

    if (props.type === 'CREATE') {
      node.createdDate = new Date().getTime();
      node.createdBy = props.user?.id;
      node.modifiedBy = props.user?.id;
      node.modifiedDate = new Date().getTime();
      node.version = 1;
    }

    return node;
  },
  validate: (values, props) => {
    return ValidateNode({
      node: { data: values },
      prefix: '',
      schema: props.schema,
    });
  },
})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CollectionForm)));
