import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ModalTextField from 'components/Modals/ModalTextField';
import svgIcons from 'styles/svgIcons';
import IconButton, { Color, Size } from '../../components/IconButton';
import authService from '../../services/AuthService';
import { AppMessages, COGNITO_EXCEPTIONS_MESSAGES } from '../../constants';
import Password from 'components/Password/Password';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Button, { Variant as ButtonVariants } from 'components/Button';
import * as Colors from 'styles/colors';
import { Hub } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import RequestedTemporaryPassword from './RequestedTemporaryPassword';
import RequestTemporaryPassword from './RequestTemporaryPassword';
import { EmailSentVerification, FirstLogin } from 'pages/MFA';
import ConfirmCodeMail from 'pages/MFA/ConfirmCodeMail';
import { useHistory } from 'react-router-dom';
import ConfirmCodeSMS from 'pages/MFA/ConfirmCodeSMS';
import PhoneSelector from 'pages/MFA/PhoneSelector';
import ResetMFA from 'pages/MFA/ResetMFA';
import { isSSO } from 'utilities/common';
import { LOCAL_STORAGE_KEYS } from '../../constants';


export enum SIGN_IN_MODES {
  BROWSE = 'browse',
  FETCHING = 'fetching',
  WITH_ERROR = 'with_error',
  PASSWORD_CHALLANGE = 'password_challange',
  REQUEST_NEW_TEMPORARY_PASSWORD = 'request_new_temporary_password',
  REQUESTED_NEW_TEMPORARY_PASSWORD = 'requested_new_temporary_password',
  CHOOSING_MFA_OPTION = 'set-mfa',
  MFA_SET_PHONE = 'set-phone',
  MFA_MAIL_SELECTED = 'code-verification-mail',
  MFA_SMS_SELECTED = 'code-verification-sms',
  RESET_MFA = 'reset-mfa',
  LOGGED_IN = 'logged_in',
  EMAIL_VERIFICATION = 'email-verification',

}
// TODO - make enum answer for the lambdas
const BACK_TO_SET_MFA_METHOD = 'back_to_set_mfa_method';
export const BACK_TO_CODE_VERIFICATION = 'back_to_code_verification';
interface State {
  tenant?: any;
  user?: any;
  email: string;
  error: string;
  mode: SIGN_IN_MODES;
  showPassword: boolean;
  showConfirmPassword: boolean;
  showRequestNewPasswordLink: boolean;
  isSSOButtonVisible: boolean;
}

type Inputs = {
  email: string;
  password: string;
  name: string;
  newPassword: string;
  confirmPassword: string;
};

const SignIn: React.FC = () => {
  const [state, setState] = useState<State>({
    tenant: window.appStore.getState().app.tenant,
    user: null,
    email: '',
    error: '',
    mode: SIGN_IN_MODES.BROWSE,
    showPassword: false,
    showConfirmPassword: false,
    showRequestNewPasswordLink: false,
    isSSOButtonVisible: true,
  });
  const [loginFocuses, setLoginFocuses] = useState({
    isEmailFocused: false,
    isPassFocused: false,
  });
  const [firstTimeFocuses, setFirstTimeFocuses] = useState({
    isNameFocused: false,
    isNewPassFocused: false,
    isConfirmPassFocused: false,
  });

  const history = useHistory();
  const location = useLocation();
  const firstTime = location?.search?.includes?.('firstTime');
  const { control, handleSubmit, errors, formState, trigger, watch, clearErrors, reset } = useForm({
    mode: 'onChange',
  });

  const { isDirty, isValid } = formState;
  const watchNewPassword = watch('newPassword');
  const watchConfirmPassword = watch('confirmPassword');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    if (watchConfirmPassword) trigger('confirmPassword');
  }, [watchNewPassword]);

  useEffect(() => {
    reset();
  }, [state.user]);

  useEffect(() => {
    if (!isDirty) return;
    trigger('newPassword');
  }, [isPasswordValid]);

  const completeNewPasswordChallenge: SubmitHandler<Inputs> = (data) => {
    const { name, newPassword, confirmPassword } = data;
    if (state.mode === SIGN_IN_MODES.FETCHING) return;
    if (isValid && state.user) {
      setState({ ...state, mode: SIGN_IN_MODES.FETCHING, error: '' });

      const user = state.user as any;
      const userAttributes: any = {};
      const [firstName, ...restName] = name.split(' ');
      const lastName = restName.join(' ');

      if (!(firstName.length >= 1 && lastName.length >= 1)) {
        setState({
          ...state,
          error: 'Required fields',
          mode: SIGN_IN_MODES.WITH_ERROR,
        });
        return;
      }

      if (newPassword !== confirmPassword) {
        setState({
          ...state,
          error: 'New password and Confirm password must match',
          mode: SIGN_IN_MODES.WITH_ERROR,
        });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/camelcase
      userAttributes.given_name = firstName;
      // eslint-disable-next-line @typescript-eslint/camelcase
      userAttributes.family_name = lastName;
      userAttributes.name = `${firstName} ${lastName}`;

      user.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: () => {
          Hub.dispatch('auth', { event: 'signIn', data: null }, 'Auth');
        },
        onFailure: (error) => {
          if (error.message.includes('MFANotSet')) {
            redirectToLogin();
          } else {
            setState({
              ...state,
              error: error.message,
              mode: SIGN_IN_MODES.WITH_ERROR,
            });
          }
        },
      });
    }
  };

  const redirectToLogin = () => {
    const isSSOUser = isSSO(state.tenant, state.user?.challengeParam?.userAttributes?.email || '');
    window.location.href = isSSOUser ? '/login' : '/login?showLogin';
  };

  const requestNewTemporaryPassword: SubmitHandler<Inputs> = async (data) => {
    if (state.mode === SIGN_IN_MODES.FETCHING) return;
    setState({ ...state, mode: SIGN_IN_MODES.FETCHING, error: '' });
    try {
      await authService.requestNewTemporaryPassword(state.email);
      setState({
        ...state,
        mode: SIGN_IN_MODES.REQUESTED_NEW_TEMPORARY_PASSWORD,
      });
    } catch (exception) {
      setState({
        ...state,
        mode: SIGN_IN_MODES.WITH_ERROR,
        error: 'Could not request new temporary password',
      });
    }
  };


  const [isLoadingChallenge, setIsLoadingChallenge] = useState(false);
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [emailSent, setEmailSent] = useState('');

  const signIn: SubmitHandler<Inputs> = async (data) => {
    const { email, password } = data;
    if (state.mode === SIGN_IN_MODES.FETCHING) return;


    if (isValid) {
      setState({ ...state, mode: SIGN_IN_MODES.FETCHING });

      authService
        .initMFA(email, password)
        .then((user) => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK);
          setChallengeRedirection(user);
        })
        .catch((error) => {
          if (
            error?.message === 'Incorrect username or password.' ||
            error?.code === 'UserNotFoundException'
          ) {
            setState({
              ...state,
              error:
                'Email or password is not correct.',
              mode: SIGN_IN_MODES.WITH_ERROR,
            });
          } else if (
            error?.code === 'NotAuthorizedException' &&
            error?.message?.includes?.('expired')
          ) {
            setState({
              ...state,
              error: 'The temporary password has expired.',
              mode: SIGN_IN_MODES.WITH_ERROR,
              showRequestNewPasswordLink: true,
              email,
            });
          } else if (
            error?.message === 'Invalid session for the user.'
          ) {
            setState({
              ...state,
              error: 'The sessión for the loggin has finished. Try again.',
              mode: SIGN_IN_MODES.WITH_ERROR,
            });
            history.push('/login');
          } else {
            setState({
              ...state,
              error: error.message.includes('attempts exceeded') ? COGNITO_EXCEPTIONS_MESSAGES.LimitExceededException : error.message,
              mode: SIGN_IN_MODES.WITH_ERROR,
            });
          }
        });
    }
  };

  const ssoSignIn = (event, idp): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK);
    event.preventDefault();
    setState({ ...state, mode: SIGN_IN_MODES.FETCHING });
    window.location.href = `https://${state.tenant.authProvider.domain}/authorize?idp_identifier=${idp.identifier}&response_type=token&client_id=${state.tenant.authProvider.userPoolClientId}&redirect_uri=${idp.redirectUri}`;
  };

  const goToLoginPage = (): void => {
    setState({
      ...state,
      mode: SIGN_IN_MODES.BROWSE,
      email: '',
      showRequestNewPasswordLink: false,
    });
  };

  const clearError = (): void => {
    if (state.error || state.mode === SIGN_IN_MODES.WITH_ERROR) {
      setState({ ...state, error: '', mode: SIGN_IN_MODES.BROWSE });
    }
  };

  const backToMFA = async (): Promise<void> => {
    const response = {
      method: BACK_TO_SET_MFA_METHOD,
    };
    setMfaMethod(response.method);
    setState({ ...state, error: '', mode: SIGN_IN_MODES.CHOOSING_MFA_OPTION });
  };

  const sendChallengeResponse = (response: string) => {
    setIsLoadingChallenge(true);
    setIsCodeWrong(false);
    if (state.user.challengeParam.customChallengeName == SIGN_IN_MODES.MFA_SET_PHONE && response !== BACK_TO_SET_MFA_METHOD) {
      response = '+' + response;
    }
    authService.responseChallenge(state.user, response).then((user) => {
      setChallengeRedirection(user);
      setIsLoadingChallenge(false);
    }).catch((error) => {
      errorChallengeHandle(error);
      setIsLoadingChallenge(false);
    });
  };

  const errorChallengeHandle = (error): void => {
    switch (error.message) {
      case 'Invalid session for the user.':
        setState({
          ...state,
          error: 'The sessión for the loggin has finished. Try again.',
          mode: SIGN_IN_MODES.WITH_ERROR,
          user: null,
        });
        history.push('/login');
        break;
      default:
        setState({
          ...state,
          error: 'Authentication error. Try again.',
          mode: SIGN_IN_MODES.WITH_ERROR,
          user: null,
        });
        history.push('/login');
        break;
    }
  };

  const updateSettings = (): void => {
    const response = 'reset_mfa';
    authService.responseChallenge(state.user, response).then((user) => {
      setChallengeRedirection(user);
    }).catch((error) => {
      errorChallengeHandle(error);
    });
  };

  const setChallengeRedirection = (user): void => {
    const methodSelected: SIGN_IN_MODES = getModeFromChallenge(user);
    setState({ ...state, mode: methodSelected, user });

    if (methodSelected === SIGN_IN_MODES.LOGGED_IN) window.location.href = '/workspaces';
  };

  const getModeFromChallenge = (user): SIGN_IN_MODES => {
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return SIGN_IN_MODES.PASSWORD_CHALLANGE;
    }

    if (user?.challengeName === 'CUSTOM_CHALLENGE') {
      return setSignInMode(
        user?.challengeParam.customChallengeName,
        user?.challengeParam.customChallengeMethod,
        user?.challengeParam.isCodeWrong,
        user?.challengeParam?.emailSentTo,
      );
    } else {
      return SIGN_IN_MODES.LOGGED_IN;
    }
  };

  const setSignInMode = (challenge: string, method = '', verificationCodeResponse = '', emailSent = '') => {
    let response: SIGN_IN_MODES;

    switch (challenge) {
      case 'code-verification':
        if (verificationCodeResponse === 'true') {
          setIsCodeWrong(true);
        }
        if (method === 'sms') {
          response = SIGN_IN_MODES.MFA_SMS_SELECTED;
        } else if (method === 'email') {
          response = SIGN_IN_MODES.MFA_MAIL_SELECTED;
        } else {
          response = SIGN_IN_MODES.RESET_MFA;
        }
        break;
      case 'set-phone':
        response = SIGN_IN_MODES.MFA_SET_PHONE;
        break;
      case 'reset-mfa':
        response = SIGN_IN_MODES.RESET_MFA;
        break;
      case 'set-mfa':
        response = SIGN_IN_MODES.CHOOSING_MFA_OPTION;
        break;
      case 'email-verification':
        response = SIGN_IN_MODES.EMAIL_VERIFICATION;
        setEmailSent(emailSent);
        break;
      default:
        response = SIGN_IN_MODES.CHOOSING_MFA_OPTION;
        break;
    }

    return response;
  };

  const resendCode = (): void => {
    const response = 'resend_verification_code';
    sendChallengeResponse(response);
  };

  const setMfaMethod = (response): void => {
    sendChallengeResponse(response);
  };

  const hanleOnInputEmail = (event) => {
    const emailValue = event.target.value;

    if (emailValue.replace(/\s/g, '') === '') {
      setState({ ...state, isSSOButtonVisible: true });
    } else {
      setState({ ...state, isSSOButtonVisible: true });
    }
  };

  const renderAuthFooter = () => {
    return firstTime ?
      <>
        <p>If you have already activated your account,</p>
        <p>
          <Link to={`/login?showLogin`}>
            Click Here to Log In
          </Link>.
        </p>
      </> :
      <>
        <p>By clicking &quot;Log In&quot; you agree to HubSync&apos;s</p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="//hubsync.com/privacy-policy"
          >
            Privacy Policy
          </a>
          <span> and </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="//hubsync.com/terms-of-use"
          >
            Terms of Use
          </a>
        </p>
      </>;
  };

  const renderWelcomeOptionalText = () => {
    const { tenant } = state;
    if (!tenant) return '';

    if (tenant.login?.isWelcomeOptionalTextEnabled) return <span className='welcome-tenant-optional-text'>{ tenant.login?.welcomeOptionalText || '' }</span>;

    if (firstTime) {
      return <span>Log In to {tenant.email?.clientSplittedName ?? 'HubSync'} with your <b>Temporary Password</b></span>;
    } else {
      return `Log In to ${tenant.email?.clientSplittedName ?? 'HubSync'} to continue`;
    }
  };

  if (state.mode === SIGN_IN_MODES.REQUESTED_NEW_TEMPORARY_PASSWORD) {
    return (
      <RequestedTemporaryPassword
        email={state.email}
        goToLoginPage={goToLoginPage}
      />
    );
  }

  if (state.showRequestNewPasswordLink) {
    return (
      <RequestTemporaryPassword
        error={state.error}
        isLoading={state.mode === SIGN_IN_MODES.FETCHING}
        onSubmit={handleSubmit(requestNewTemporaryPassword)}
        goToLoginPage={goToLoginPage}
      />
    );
  }

  if (state.mode === SIGN_IN_MODES.MFA_MAIL_SELECTED) {
    return (
      <ConfirmCodeMail
        method={'email'}
        backToMFA={backToMFA}
        isCodeWrong={isCodeWrong}
        resendCode={resendCode}
        isLoadingEmail={isLoadingChallenge}
        onConfirmClicked={sendChallengeResponse}
      />
    );
  }

  if (state.mode === SIGN_IN_MODES.MFA_SET_PHONE) {
    return (
      <PhoneSelector
        backToMFA={backToMFA}
        isLoadingChallenge={isLoadingChallenge}
        onConfirmClicked={sendChallengeResponse}
      />
    );
  }

  if (
    state.mode === SIGN_IN_MODES.CHOOSING_MFA_OPTION
  ) {
    return (
      <FirstLogin
        onSelectedMethod={setMfaMethod}
        isLoadingChallenge={isLoadingChallenge}
        mode={state.mode}
      />);
  }

  if (state.mode === SIGN_IN_MODES.MFA_SMS_SELECTED) {
    return (
      <ConfirmCodeSMS
        method={'sms'}
        isCodeWrong={isCodeWrong}
        backToMFA={backToMFA}
        resendCode={resendCode}
        updateSettings={updateSettings}
        isLoadingChallenge={isLoadingChallenge}
        onConfirmClicked={sendChallengeResponse}
      />
    );
  }

  if (state.mode === SIGN_IN_MODES.RESET_MFA) {
    return (
      <ResetMFA
        method={'sms'}
        isCodeWrong={isCodeWrong}
        backToMFA={backToMFA}
        resendCode={resendCode}
        updateSettings={updateSettings}
        isLoadingChallenge={isLoadingChallenge}
        onConfirmClicked={sendChallengeResponse}
      />
    );
  }

  if (state.mode === SIGN_IN_MODES.EMAIL_VERIFICATION) {
    return (
      <EmailSentVerification emailSent={emailSent} goLogin={()=>{
        setState({ ...state, mode: SIGN_IN_MODES.BROWSE, user: null });
      }} />
    );
  }

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <div>
      <div className="auth-welcome-container auth-welcome-container--login">
        <h3>{firstTime || state.user ? 'Welcome, activate your account!' : 'Welcome Back!'}</h3>
        <p>
          {state.user
            ? <p>Enter the requested information to update the<br/> password. After updating this password, you will be<br/>redirected back to the Log In screen.</p>
            : renderWelcomeOptionalText()
          }
        </p>
      </div>
      {!state.user && (
        <form onSubmit={handleSubmit(signIn)}>
          <div className="auth-input-container">
            <label htmlFor="email">
              {AppMessages.LOGIN_EMAIL_ADDRESS_LABEL}
            </label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: AppMessages.LOGIN_EMAIL_REQUIRED,
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: AppMessages.LOGIN_INVALID_EMAIL,
                },
              }}
              render={(props) => (
                <ModalTextField
                  tabIndex={0}
                  id="email"
                  placeholder="Enter Your Email"
                  value={props.value}
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  onInput={(e) => {
                    hanleOnInputEmail(e);
                    setLoginFocuses({ ...loginFocuses, isEmailFocused: true });
                    clearErrors('email');
                    clearError();
                  }}
                  onChange={props.onChange}
                  onBlur={() => {
                    setLoginFocuses({ ...loginFocuses, isEmailFocused: false });
                    trigger('email');
                    props.onBlur();
                  }}
                  errorMessage={
                    (errors.email && !loginFocuses.isEmailFocused) && (
                      <div className="auth-input-error-container">
                        <svgIcons.Limited />{' '}
                        <span className="auth-input-error">
                          {errors.email?.message}
                        </span>
                      </div>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        icon={svgIcons.Email}
                        color={Color.DarkGray}
                        size={Size.Large}
                      />
                    ),
                  }}
                />
              )}
            />
          </div>

          <div
            className={`auth-input-container ${
              state.showRequestNewPasswordLink
                ? 'auth-input-container-hide-input-error'
                : ''
            }`}
          >
            <label htmlFor="password">{firstTime ? AppMessages.LOGIN_TEMPORARY_PASSWORD_LABEL : AppMessages.LOGIN_PASSWORD_LABEL}</label>
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: AppMessages.LOGIN_PASSWORD_REQUIRED,
                },
              }}
              render={(props) => (
                <ModalTextField
                  id="password"
                  tabIndex={1}
                  name="password"
                  type={state.showPassword ? 'text' : 'password'}
                  placeholder="Enter Password"
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  errorMessage={
                    (errors.password && !loginFocuses.isPassFocused) && (
                      <div className="auth-input-error-container">
                        <svgIcons.Limited
                          tabIndex={-1}
                        />{' '}
                        <span className="auth-input-error">
                          {errors.password?.message}
                        </span>
                      </div>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        tabIndex={-1}
                        icon={svgIcons.LockFilled}
                        color={Color.DarkGrayNoHover}
                        size={Size.Large}
                      />
                    ),
                    endAdornment: (
                      <div className="show-password-toggle" tabIndex={-1}>

                        <IconButton
                          tabIndex={-1}
                          icon={
                            !state.showPassword
                              ? svgIcons.PasswordShow
                              : svgIcons.Columns
                          }
                          color={Color.DarkGray}
                          size={Size.Large}
                          onClick={() =>
                            setState({
                              ...state,
                              showPassword: !state.showPassword,
                            })
                          }
                        />
                      </div>
                    ),
                  }}
                  value={props.value}
                  onInput={() => {
                    setLoginFocuses({ ...loginFocuses, isPassFocused: true });
                    clearErrors('password');
                    clearError();
                  }}
                  onChange={props.onChange}
                  onBlur={() => {
                    setLoginFocuses({ ...loginFocuses, isPassFocused: false });
                    trigger('password');
                    props.onBlur();
                  }}
                />
              )}
            />
          </div>

          {state.mode === SIGN_IN_MODES.WITH_ERROR && (
            <div className="auth-error-message auth-error-container">
              <span>
                <svgIcons.Limited />
              </span>
              {state.error}
            </div>
          )}

          <div className="auth-options">
            {(!state.showRequestNewPasswordLink && !firstTime) && (
              <NavLink className="forgot-password-link" to="/auth/forgot-password"><span>Forgot Password?</span></NavLink>
            )}
            <a className={`help-option-link ${firstTime ? 'help-option-link--align-right' : ''}`} href={state.tenant.apiEndpoints.zendeskTargetURL} target='_blank' rel="noopener noreferrer"><span>Need Help?</span></a>
          </div>


          <div className={'auth-action-btn'+ (tenantConfig.clientName || '')}>
            <Button
              type="submit"
              label="Client Log In"
              variant={ButtonVariants.Primary}
              fullWidth
              disabled={
                !isDirty || !isValid || state.mode === SIGN_IN_MODES.FETCHING
              }
              isLoading={state.mode === SIGN_IN_MODES.FETCHING}
            />
          </div>
          {(!firstTime && state.isSSOButtonVisible) && state.tenant.authProvider.identityProviders &&
            state.tenant.authProvider.identityProviders.map((idp) => (
              <div key={idp.identifier} className={'auth-action-btn'+ (tenantConfig.clientName || '')}>
                <button
                  className="fillWidth app-btn-primary"
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  onClick={(event) => {
                    ssoSignIn(event, idp);
                  }}
                >
                  {idp.loginButtonLabel}
                </button>
              </div>
            ))}

          <div className={`auth-footer-container ${firstTime ? 'auth-footer-container--temporary' : ''}`}>{ renderAuthFooter() }</div>
        </form>
      )}

      {state.user && (
        <form onSubmit={handleSubmit(completeNewPasswordChallenge)}>
          <div className="auth-input-container">
            <label htmlFor="name">{AppMessages.LOGIN_NAME_LABEL}</label>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: AppMessages.LOGIN_NAME_REQUIRED,
                },
                pattern: {
                  value:
                  /^[\p{L} ,.'-]+$/u,
                  message: AppMessages.LOGIN_NAME_INVALID,
                },
              }}
              render={(props) => (
                <ModalTextField
                  id="name"
                  placeholder="Enter First and Last Name"
                  value={props.value}
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  onInput={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isNameFocused: true });
                    clearErrors('name');
                    clearError();
                  }}
                  onChange={props.onChange}
                  onBlur={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isNameFocused: false });
                    trigger('name');
                    props.onBlur();
                  }}
                  errorMessage={
                    (errors.name && !firstTimeFocuses.isNameFocused) && (
                      <div className="auth-input-error-container">
                        <svgIcons.Limited />{' '}
                        <span className="auth-input-error">
                          {errors.name?.message}
                        </span>
                      </div>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        icon={svgIcons.UserName}
                        color={Color.DarkGray}
                        size={Size.Large}
                      />
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="auth-input-container auth-input-container-hide-input-error">
            <label htmlFor="password">New Password</label>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'The new password is required.',
                },
                validate: () =>
                  isPasswordValid || 'The new password is invalid.',
              }}
              render={(props) => (
                <Password
                  value={props.value}
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  withReset={false}
                  onInput={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isNewPassFocused: true });
                    clearErrors('newPassword');
                    clearError();
                  }}
                  onChange={props.onChange}
                  onBlur={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isNewPassFocused: false });
                    trigger('newPassword');
                    props.onBlur();
                  }}
                  passwordReset={() => null}
                  setIsPasswordValid={setIsPasswordValid}
                  errorMessage={
                    (errors.newPassword && !firstTimeFocuses.isNewPassFocused) && (
                      <div className="auth-input-error-container">
                        <svgIcons.Limited />{' '}
                        <span className="auth-input-error">
                          {errors.newPassword?.message}
                        </span>
                      </div>
                    )
                  }
                  InputProps={{
                    placeholder: 'Enter Password',
                    startAdornment: (
                      <InputAdornment position="start" variant="standard">
                        <svgIcons.LockInput
                          tabIndex={-1}
                          className="auth-input-start-icon"
                          color={Colors.inputIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="auth-input-container auth-input-container-hide-input-error">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'The confirmation password is required.',
                },
                validate: (value) =>
                  value === watch('newPassword') ||
                  'The passwords do not match.',
              }}
              render={(props) => (
                <Password
                  value={props.value}
                  disabled={state.mode === SIGN_IN_MODES.FETCHING}
                  withReset={false}
                  onInput={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isConfirmPassFocused: true });
                    clearErrors('confirmPassword');
                    clearError();
                  }}
                  onChange={props.onChange}
                  onBlur={() => {
                    setFirstTimeFocuses({ ...firstTimeFocuses, isConfirmPassFocused: false });
                    trigger('confirmPassword');
                    props.onBlur();
                  }}
                  passwordReset={() => null}
                  setIsPasswordValid={() => null}
                  errorMessage={
                    (errors.confirmPassword && !firstTimeFocuses.isConfirmPassFocused) && (
                      <div className="auth-input-error-container">
                        <svgIcons.Limited />{' '}
                        <span className="auth-input-error">
                          {errors.confirmPassword?.message}
                        </span>
                      </div>
                    )
                  }
                  InputProps={{
                    placeholder: 'Confirm Password',
                    startAdornment: (
                      <InputAdornment position="start" variant="standard">
                        <svgIcons.LockInput
                          className="auth-input-start-icon"
                          color={Colors.inputIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          {state.mode === SIGN_IN_MODES.WITH_ERROR && (
            <div className="auth-error-message auth-error-container">
              <span>
                <svgIcons.Limited />
              </span>
              {state.error}
            </div>
          )}

          <div className={'auth-action-btn'+ (tenantConfig.clientName || '')}>
            <Button
              type="submit"
              label="Update Password"
              variant={ButtonVariants.Primary}
              fullWidth
              disabled={
                !isDirty || !isValid || state.mode === SIGN_IN_MODES.FETCHING
              }
              isLoading={state.mode === SIGN_IN_MODES.FETCHING}
            />
          </div>
          <div className="auth-footer-container">
            {
              state.user ? null : (
                <><p>By clicking “Log In” you agree to HubSync’s</p><p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="//hubsync.com/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  <span> and </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="//hubsync.com/terms-of-use"
                  >
                    Terms of Use
                  </a>
                </p></>
              )
            }
          </div>
        </form>
      )}
    </div>
  );
};

export default SignIn;
