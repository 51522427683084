import { SummarizeInput } from '../types';
import * as _ from 'lodash';
import moment from 'moment';

function summarize({ data }: SummarizeInput) {
  const minDate = _.min(data.map(dateString => (new Date(dateString))));
  const maxDate = _.max(data.map(dateString => (new Date(dateString))));
  return `${moment(maxDate).diff(moment(minDate), 'days')} days`;
}

export default summarize;
