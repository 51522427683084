import * as React from 'react';
import { connect } from 'react-redux';

import * as ModalActions from 'data/modals/actions';
import * as ViewActions from 'data/views/views.actions';

import Confirm from '../Confirm';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
  deleteView: typeof ViewActions.deleteView.request;
}

interface OwnProps {
  viewId: string;
}

export type Props = OwnProps & DispatchProps;

const DeleteView = (props: Props): JSX.Element => {
  const handleConfirm = (): void => {
    props.deleteView({ id: props.viewId });
  };

  return (
    <Confirm
      title="Are you sure you want to delete this view?"
      close={props.close}
      onConfirm={handleConfirm}
      confirmLabel="Delete"
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  deleteView: ViewActions.deleteView.request,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteView);
