import { CSSProperties } from '@material-ui/core/styles/withStyles';

import * as Colors from './colors';

export function hover(color: string): Record<string, CSSProperties> {
  return { '&:hover': { backgroundColor: Colors.darken(color, 0.08) } };
}

export const focus: Record<string, CSSProperties> = {
  '&:focus': {
    borderColor: Colors.hubsyncBlueTransparent,
    borderStyle: 'solid',
    borderWidth: 1,
    outline: 'none',
  },
};
