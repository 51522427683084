import moment from 'moment';
import { FieldValue } from 'types/response';
import { Choice, SchemaProperty } from 'types/schema';
import { FieldType } from 'types/response/fieldNode';
import { isStringArray } from 'components/NodeFieldData/filters/helpers';
import { AccountNode } from 'types/response/accountNode';
import { AttachmentsState } from 'data/attachments/reducer';
import { AttachmentNode } from 'types/response/attachmentNode';
import { DEFAULT_DATE_FORMAT } from '../Fields/data';

export const fieldsValue = (
  column: SchemaProperty | undefined,
  value: FieldValue,
  accountsById: Record<string, AccountNode>,
  nodeId: string,
  attachments: AttachmentsState,
): any => {
  if (!column) {
    return undefined;
  }
  switch (column.fieldType) {
    case FieldType.Singlelineoftext:
    case FieldType.Multilinetext:
    case FieldType.Email:
    case FieldType.FileName:
    case FieldType.FileExtension:
    case FieldType.FileSize:
    case FieldType.Staticformula:
    case FieldType.Url:
      return getStringValue(value);
    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Percent:
    case FieldType.Rating:
    case FieldType.Currency:
      return getNumberValue(value);
    case FieldType.Boolean:
      return getBooleanValue(value);
    case FieldType.Date:
      return getDateValue(value ? { timestamp: typeof value !== 'object' ? value : value['timestamp'], dateFormat: column.dateFormat || '' } : undefined);
    case FieldType.Singlechoice:
    case FieldType.Dropdown:
      return column.choices
        ? getSingleChoiceValue(value, column.choices)
        : '';
    case FieldType.Multiplechoice:
      return column.choices
        ? getMultipleChoiceValue(value, column.choices).join('')
        : '';
    case FieldType.Account:
      if (accountsById) {
        return column.allowMultiple
          ? getMultipleAccountValue(value, accountsById).join('')
          : getSingleAccountValue(value, accountsById);
      } else {
        return 0;
      }
    case FieldType.Attachment:
      return getAttachmentValue(value, nodeId, attachments).join('');
    case FieldType.Barcode:
    case FieldType.BLOBReference:
    case FieldType.Bookmarked:
    case FieldType.Checklist:
    case FieldType.Computed:
    case FieldType.DataReference:
    case FieldType.DateTime:
    case FieldType.Duration:
    case FieldType.Fraction:
    case FieldType.GUID:
    case FieldType.ID:
    case FieldType.Location:
    case FieldType.Lookup:
    case FieldType.Phone:
    case FieldType.SearchReference:
    case FieldType.Tag:
    case FieldType.Time:
    case FieldType.Timespan:
      return undefined;
  }
};

export const getStringValue = (value: FieldValue | undefined): string => typeof value === 'string' ? value : '';

export const getNumberValue = (value: FieldValue | undefined): number | undefined => typeof value === 'number'
  ? value
  : undefined;

export const getBooleanValue = (value: FieldValue | undefined): boolean => typeof value === 'boolean' ? value : false;

export const getDateValue = (value: { timestamp: number | string; dateFormat: string } | undefined): string | undefined => value ? moment(value.timestamp).format(value.dateFormat || DEFAULT_DATE_FORMAT) : undefined;


export const getSingleChoiceValue = (value: FieldValue | undefined, choices: Record<string, Choice>): string =>
  typeof value === 'string' && choices[value] ? choices[value].label : '';

export const getMultipleChoiceValue = (value: FieldValue | undefined, choices: Record<string, Choice>): string[] =>
  isStringArray(value) ? value.map(id => getSingleChoiceValue(id, choices)) : [];

export const getSingleAccountValue = (value: FieldValue | undefined, accountsById: Record<string, AccountNode>): string =>
  typeof value === 'string' && accountsById[value] ? accountsById[value].displayName : '';

export const getMultipleAccountValue = (value: FieldValue | undefined, accountsById: Record<string, AccountNode>): string[] =>
  isStringArray(value) ? value.map(id => getSingleAccountValue(id, accountsById)) : [];

export const getAttachmentValue = (value: FieldValue | undefined, nodeId: string, attachments: AttachmentsState): string[] => {
  const attachmentsById: Record<string, AttachmentNode> | undefined =
    attachments.byItemId[nodeId] && attachments.byItemId[nodeId].byId;
  return isStringArray(value)
    ? value.map(id => attachmentsById && attachmentsById[id] ? attachmentsById[id].fileName : '')
    : [];
};
