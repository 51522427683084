import slugify from 'slugify';

export const UNNAMED_RECORD_TITLE = 'Unnamed record';


export function coerseArray<T>(value?: T[] | T): T[] {
  if (value) {
    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }
  return [];
}

/**
 *  Simple implementation for UUID generation
 *  Taken from https://stackoverflow.com/a/2117523
 *  (Use only for small purposes)
 */
export const uuidv4 = (): string => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : ((r & 0x3) | 0x8);
  return v.toString(16);
});

export const slugifyText = (value) => slugify(value.trim(), {
  replacement: '_',
  strict: true,
  remove: /[*+~.,()'"!:@]/g,
});

/**
 * Escaping special string characters for regex manipulation
 *
 * @param rawString
 * @return parsedString
 */
export const escapeCharacters = (rawString: string): string => {
  return rawString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const isSSO = (tenant, userEmail: string): boolean => {
  if (!tenant || !userEmail) return false;
  const trustedDomains = tenant.authProvider?.identityProviders?.length
    ? tenant.authProvider?.identityProviders[0].trustedDomains
    : [];
  const emailDomain = userEmail.split('@')[1];
  const matchDomain = trustedDomains.find((domain) => domain === emailDomain);
  const isSSOUser = matchDomain? true : false;

  return isSSOUser;
};

export const getAllowedExtensionsString = (extensionList: string[]) => (`.${extensionList.join(', .')}` || '');
