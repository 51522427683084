import * as React from 'react';

import { ToolButton } from 'components/DocumentPreview/Tools/PreviewTools.style';
import svgIcons from 'styles/svgIcons';

interface Props {
  previews: any[];
  onError: () => void;
}

const pageStyle = `
  @page { size: auto;  margin: 0mm; }
  @media print {
    img {
      display: block;
      max-width: 20cm;
      max-height: 29cm; 
    }
  }
`;

export default class Print extends React.PureComponent<Props> {
  appendAndLoadImage = (src, container) => {
    const img = document.createElement('img');
    container.appendChild(img);
    img.src = src;
    return new Promise((res, rej) => {
      img.onload = res;
      img.onerror = rej;
    });
  };

  createPrintFrame = (): Promise<{ printFrame: any; clear: () => void }> => {
    const printFrame = document.createElement('iframe');
    printFrame.style.display = 'none';
    return new Promise(res => {
      printFrame.onload = () => {
        const printDoc = printFrame.contentDocument || (printFrame.contentWindow && printFrame.contentWindow.document);
        if (printDoc) {
          const styleEl = printDoc.createElement('style');
          styleEl.appendChild(printDoc.createTextNode(pageStyle));
          printDoc.head.appendChild(styleEl);

          res({
            printFrame,
            clear: () => document.body.removeChild(printFrame),
          });
        }
      };
      document.body.appendChild(printFrame);
    });
  };

  async print() {
    const { previews, onError } = this.props;
    const result = await this.createPrintFrame();

    try {
      const doc = result.printFrame.contentDocument || result.printFrame.contentWindow.document;
      const imageRequests = previews.map(r => this.appendAndLoadImage(r.url, doc.body));
      await Promise.all(imageRequests);

      result.printFrame.contentWindow.print();
    } catch (error) {
      onError();
    } finally {
      // adding to the queue to wait for print
      setTimeout(result.clear);
    }
  }

  handleClick = () => this.print();

  render() {
    return (
      <ToolButton onClick={this.handleClick}>
        <svgIcons.Print height="1em" />
      </ToolButton>
    );
  }
}

