import { FieldType } from 'types/response/fieldNode';

import AccountEditor from './account/AccountEditor';
import AttachmentsEditor from './attachment/AttachmentsEditor';
import BarcodeEditor from './barcode/Editor';
import BooleanEditor from './boolean/BooleanEditor';
import ButtonEditor from './button/ButtonEditor';
import ChecklistEditor, {
  ChecklistFormEditor,
} from './checklist/ChecklistEditor';
import ChoiceEditor from './choice/ChoiceEditor';
import DateTimeEditor from './datetime/DateTimeEditor';
import DropdownEditor from './dropdown/DropdownEditor';
import MultilineRichTextEditor from './multilineRichTextEditor/MultiLineRichTextEditor';
import NumberEditor from './number/NumberEditor';
import NumberFormattedEditor from './number/NumberFormattedEditor';
import RatingEditor from './rating/RatingEditor';
import SingleLineOfTextEditor from './singlelineoftext/SingleLineOfTextEditor';
import NormalSingleLineOfTextEditor from './normalSingleLineOfTextEditor/NormalSingleLineOfTextEditor';
import TagsEditor from './tags/Editor';
import UrlEditor from './url/UrlEditor';

export const editors = {
  AccountEditor,
  AttachmentsEditor,
  BooleanEditor,
  ChoiceEditor,
  ChecklistEditor,
  DateTimeEditor,
  NumberEditor,
  MultilineRichTextEditor,
  SingleLineOfTextEditor,
  NormalSingleLineOfTextEditor,
  TagsEditor,
  BarcodeEditor,
  RatingEditor,
  UrlEditor,
};

export function getEditorByFieldType(
  type: FieldType,
  fallback = NormalSingleLineOfTextEditor,
) {
  switch (type) {
    case FieldType.Account: {
      return AccountEditor;
    }

    case FieldType.Multiplechoice:
    case FieldType.Singlechoice: {
      return ChoiceEditor;
    }

    case FieldType.Dropdown: {
      return DropdownEditor;
    }

    case FieldType.Checklist: {
      return ChecklistEditor;
    }

    case FieldType.Date: {
      return DateTimeEditor;
    }


    case FieldType.Percent: {
      return NumberEditor;
    }

    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Currency: {
      return NumberFormattedEditor;
    }

    case FieldType.Rating: {
      return RatingEditor;
    }

    case FieldType.Multilinetext: {
      return MultilineRichTextEditor;
    }

    case FieldType.Singlelineoftext: {
      return SingleLineOfTextEditor;
    }

    case FieldType.Email:
    case FieldType.Phone: {
      return NormalSingleLineOfTextEditor;
    }

    case FieldType.Url: {
      return UrlEditor;
    }

    case FieldType.Tag: {
      return TagsEditor;
    }

    case FieldType.Boolean: {
      return BooleanEditor;
    }

    case FieldType.Attachment: {
      return AttachmentsEditor;
    }

    case FieldType.Barcode: {
      return BarcodeEditor;
    }

    case FieldType.Button: {
      return ButtonEditor;
    }

    // post MVP:
    case FieldType.Timespan:
    case FieldType.Computed:
    case FieldType.Lookup:
    case FieldType.SearchReference:
    case FieldType.Location: {
      return fallback;
    }

    default: {
      return fallback;
    }
  }
}

export function getFormEditorByFieldType(type: FieldType) {
  switch (type) {
    case FieldType.Checklist: {
      return ChecklistFormEditor;
    }

    default: {
      return getEditorByFieldType(type).OriginalComponent;
    }
  }
}

export function shouldAddOnBlurEventForType(type: FieldType) {
  switch (type) {
    case FieldType.Singlelineoftext:
    case FieldType.Multilinetext:
    case FieldType.Email:
    case FieldType.Phone:
    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Currency:
    case FieldType.Percent:
    case FieldType.Timespan:
    case FieldType.Computed:
    case FieldType.Lookup:
    case FieldType.SearchReference:
    case FieldType.Location:
    case FieldType.Url: {
      return true;
    }

    default: {
      return false;
    }
  }
}
