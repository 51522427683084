import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ExternalHtml from '../components/ExternalHtml';
import { nodeBody, State } from '../data/nodes/selectors';
import { fetchNode } from 'data/nodes/actions';

interface StateProps {
  body?: string;
}

interface DispatchProps {
  getNode(url: string): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<{}>;

export class Page extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getNode(`${this.props.location.pathname}?sections=node`);
  }

  render(): JSX.Element | null {
    if (this.props.body === undefined) {
      return null;
    }

    return (
      <ExternalHtml body={this.props.body} />
    );
  }
}

function mapStateToProps(state: State): StateProps {
  return { body: nodeBody(state) };
}

const mapDispatchToProps: DispatchProps = {
  getNode: fetchNode.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
