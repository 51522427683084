import React from 'react';

import { getInitials } from 'utilities/collections';
import './Avatar.scss';
import cn from 'classnames';
import { User } from 'data/users/users.types';

export enum AvatarSize {
  Small,
  Medium,
  Large,
  ExtraLarge,
}

interface Props {
  user: any;
  size: AvatarSize;
}

export const Avatar = (props: Props): JSX.Element => {
  const { user, size } = props;
  const sizeClassName = cn({
    'account-avatar': true,
    'small': size === AvatarSize.Small,
    'medium': size === AvatarSize.Medium,
    'large': size === AvatarSize.Large,
    'extra-large': size === AvatarSize.ExtraLarge,
  });

  return (
    <div
      style={{ backgroundColor: user.backgroundColor }}
      className={sizeClassName}
    >
      <span style={{ color: user.textColor }} className={'account-avatar-ic'}>
        {getInitials(calcUserDisplayName(user))}
      </span>
    </div>
  );
};

function calcUserDisplayName(user: User): string {
  let displayName = user.displayName;
  if (user.status === 'pending') {
    const fragments = user.email.split('@');
    displayName = fragments[0];
  }

  return displayName;
}
