import * as React from 'react';
import { CloseButton } from 'components/TableHeaderTools/TableHeaderTools.style';
import Popover, { PopoverRenderParams } from 'components/ui/Popover';
import { Input, QuerySearchIcon, Wrapper } from './SearchOption.style';
import { debounce } from 'lodash';
import svgIcons from 'styles/svgIcons';
import Button, { Variant } from 'components/Button';
import { connect } from 'react-redux';
import loadingAction from 'data/ui/loading/actions';

interface OwnProps {
  query: string;
  placeholder?: string;
  omitLoader?: boolean;
  onQueryChanges: (query: string) => void;
  close?: boolean;
}
interface DispatchProps {
  startLockingLoader: () => void;
  endLockingLoader: () => void;
}

type Props = OwnProps & DispatchProps;

interface State {
  query: string;
  close: boolean;
}

const ellipsifyString = (maxLength: number, string: string): string =>
  string.length <= maxLength ? string : string.substr(0, maxLength - 3) + '...';

class SearchOption extends React.Component<Props, State> {
  ref: any;

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    return props.query !== state.query
      ? { query: state.query, close: state.close }
      : null;
  }

  state = {
    query: this.props.query,
    close: false,
  };

  endingLocking = () => {
    const timer = setTimeout(() => {
      if (!this.props.omitLoader) this.props.endLockingLoader();
      clearTimeout(timer);
    }, 100);
  };

  applyQuery = debounce(() => {
    const { query } = this.state;
    if (!this.props.omitLoader) this.props.startLockingLoader();
    if (this.state.query === '') {
      if (!this.props.omitLoader) this.endingLocking();
    }
    this.props.onQueryChanges(query);
  }, 1000);

  handleQueryChange = (e) => {
    const { value: query } = e.target;
    this.setState({ query }, () => {
      this.applyQuery();
    });
  };

  inputRef = (input: HTMLInputElement) => {
    if (!input) return;
    this.ref = input;
    input.focus();
    input.setSelectionRange(0, input.value.length);
  };

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.query !== this.props.query &&
      this.props.query !== this.state.query
    ) {
      this.setState({ query: this.props.query });
    }
    if (prevProps.close !== this.props.close) {
      this.setState({ close: this.props.close || false });
    }
  }

  render() {
    return (
      <Popover
        renderTrigger={this.renderTrigger}
        className="quick-search-popover"
        disableEnforceFocus={true}
        disableAutoFocus={true}
        hideBackdrop={true}
        close={this.state.close}
      >
        {this.renderContent}
      </Popover>
    );
  }

  private renderTrigger = ({
    opened,
    openPopover,
  }: PopoverRenderParams): JSX.Element => {
    const { query } = this.state;
    const isQueryApplied = !!query;
    const buttonLabel = query
      ? `Searching "${ellipsifyString(10, query)}"`
      : 'Quick Search';

    return (
      <Button
        id="btnQuickSearch"
        label={buttonLabel}
        variant={Variant.TertiaryLink}
        selected={opened || isQueryApplied}
        onClick={openPopover}
        icon={svgIcons.Search}
      />
    );
  };

  private clearSearch = () => {
    this.setState({ query: '' });
    if (this.state.query) {
      if (!this.props.omitLoader) this.props.startLockingLoader();
      this.props.onQueryChanges('');
    }
    if (this.ref) {
      this.ref.focus();
    }
  };

  private renderContent = ({
    closePopover,
  }: PopoverRenderParams): JSX.Element => {
    const { query } = this.state;
    const { placeholder } = this.props;
    return (
      <Wrapper>
        <QuerySearchIcon />
        <Input
          id="txtQuickSearch"
          ref={this.ref}
          placeholder={placeholder}
          value={query}
          onChange={this.handleQueryChange}
          inputRef={this.inputRef}
          autoComplete="off"
          disableUnderline
          fullWidth
        />
        <span
          id="clearQuickSearch"
          className="clear-main-search"
          onClick={this.clearSearch}
        >
          Clear
        </span>
        <CloseButton id="btnCloseQuickSearch" onClick={closePopover} />
      </Wrapper>
    );
  };
}

const mapDispatchToProps: DispatchProps = {
  startLockingLoader: loadingAction.startLockingLoader,
  endLockingLoader: loadingAction.endLockingLoader,
};

export default connect(null, mapDispatchToProps)(SearchOption);
