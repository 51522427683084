import * as React from 'react';
import Confirm from 'components/Modals/Confirm';
import Modal from 'components/Modals/Modal';
import { Actions as AssureSignActions } from 'data/assureSign/assureSign.actions';
import * as ModalActions from 'data/modals/actions';
import { connect } from 'react-redux';

const constants = {
  TITLE: 'Resend Envelope Signature',
  MESSAGE: 'Are you sure you want to resend the signature?',
  CLOSE_LABEL: 'Close',
  CONFIRM_LABEL: 'Confirm',
};

interface DispatchProps {
  closeModal: typeof ModalActions.closeModal;
  resendEnvelope: typeof AssureSignActions.resendEnvelope;
}

interface OwnProps {
  envelopeID: string;
  signerEmail: string;
}

export type Props = OwnProps & DispatchProps;

const ResendEnvelopeModal = (props: Props): JSX.Element => {
  const { closeModal, resendEnvelope, envelopeID, signerEmail } = props;
  const { TITLE, MESSAGE, CONFIRM_LABEL } = constants;
  const handleConfirm = (): void => {
    resendEnvelope( { envelopeId: envelopeID, signerEmail: signerEmail });
    closeModal();
  };


  return (
    <Modal id="resendEnvelopeMessageModal" onClose={closeModal}>
      <Confirm
        title={TITLE}
        message={MESSAGE}
        close={closeModal}
        onConfirm={handleConfirm}
        confirmLabel={CONFIRM_LABEL}
      />
    </Modal>
  );
};

const mapDispatchToProps: DispatchProps = {
  closeModal: ModalActions.closeModal,
  resendEnvelope: AssureSignActions.resendEnvelope,
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps,
)(ResendEnvelopeModal);
