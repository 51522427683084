import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { SingleLineTextData } from '../../data';
import TextField from '../../TextField';
import Typography, { Variant } from '../../../Typography';

interface Props extends WithStyles<ClassKey> {
  data: SingleLineTextData;
  onDataUpdate(data: SingleLineTextData): void;
}

function SingleLineText(props: Props): JSX.Element {
  const { classes, data } = props;

  function onDefaultTextChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    props.onDataUpdate({ ...data, defaultText: event.target.value });
  }

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        A single line of text. You can optionally prefill each new cell with a default value:
      </Typography>
      <TextField
        className={classes.textField}
        id="defaultText"
        label="Default text"
        placeholder="Enter default text"
        value={data.defaultText || ''}
        onChange={onDefaultTextChange}
        fullWidth
      />
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'textField'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  textField: { marginTop: 8 },
});

export default styles(SingleLineText);
