import * as constants from 'data/filethis/filethis.constants';
import * as types from 'data/filethis/filethis.types';
import LoadingState from 'data/LoadingState';

export const initialState: types.FilethisState = {
  session: null,
  loadingState: LoadingState.Unloaded,
  didInvalidate: false,
  lastUpdated: 0,
};

export default (state: types.FilethisState = initialState, action: types.Actions) => {
  switch (action.type) {
    case constants.FETCH_FILETHIS_SESSION:
      return {
        ...state,
        loadingState: LoadingState.Loading,
        didInvalidate: false,
      };
    case constants.FETCH_FILETHIS_SESSION_SUCCESS:
      return {
        ...state,
        loadingState: LoadingState.Loaded,
        didInvalidate: false,
      };
    case constants.FETCH_FILETHIS_SESSION_ERROR:
      return {
        ...state,
        loadingState: LoadingState.Error,
        didInvalidate: false,
      };
    case constants.SET_FILETHIS_SESSION:
      return {
        ...state,
        session: action.session,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};
