import * as React from 'react';
import SelectField, { OwnProps as SelectFieldProps } from './SelectField';
import SelectedField from './SelectedField';
import { FieldData } from '../data';

export enum SelectionState {
  SELECT_FIELD = 'SELECT_FIELD',
  SELECTED_FIELD = 'SELECTED_FIELD',
}
export interface Props extends SelectFieldProps {
  selectionState: SelectionState;
  allowMultipleCollaborators?: boolean;
  onDataUpdate(data: FieldData): void;
  isCreate?: boolean;
}

export default function SelectFieldType(props: Props): JSX.Element {
  switch (props.selectionState) {
    case SelectionState.SELECT_FIELD: {
      return (
        <SelectField
          fieldTypes={props.fieldTypes}
          fieldTypeFilterQuery={props.fieldTypeFilterQuery}
          selectedFieldData={props.selectedFieldData}
          onFieldTypeFilterQueryChange={props.onFieldTypeFilterQueryChange}
          onFieldTypeSelected={props.onFieldTypeSelected}
          onToggleSelectFieldType={props.onToggleSelectFieldType}
        />
      );
    }

    case SelectionState.SELECTED_FIELD: {
      return (
        <SelectedField
          selectedFieldData={props.selectedFieldData}
          allowMultipleCollaborators={props.allowMultipleCollaborators}
          onToggleSelectFieldType={props.onToggleSelectFieldType}
          onDataUpdate={props.onDataUpdate}
          isCreate={props.isCreate}
        />
      );
    }
  }
}
export function flipSelectionState(current: SelectionState): SelectionState {
  return (current === SelectionState.SELECTED_FIELD)
    ? SelectionState.SELECT_FIELD
    : SelectionState.SELECTED_FIELD
  ;
}
