import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton, { Color, Size } from 'components/IconButton';
import LockPermission from 'components/Modals/LockPermission';
import ModalHeader from 'components/Modals/Modal/Header';
import CardSwitcher from 'components/Modals/NodeView/CardsSwitcher/CardsSwitcher';
import CollectionForm from 'components/Modals/NodeView/CollectionForm';
import { TitleTooltip } from 'components/Tooltip';
import { location } from 'data/app/selectors';
import { Actions as CollectionActions } from 'data/collections/collections.actions';
import {
  updateNode,
  UpdateNodeRequestPayload,
} from 'data/collections/collections.actions.new';
import { CreateNodeRequestPayload } from 'data/collections/collections.reducer';
import { schemaSelector } from 'data/collections/collections.selectors';
import { modalVisibleFieldIdsSelector } from 'data/collections/view-config/viewConfig.selectors';
import { sortedAndFilteredNodes } from 'data/grid-options/gridOptions.selector';
import { openNodeModal } from 'data/modals/actions';
import * as ModalActions from 'data/modals/actions';
import { tempPersistAttachments } from 'data/ui/fileUpload/fileUpload.actions';
import loadingAction from 'data/ui/loading/actions';
import { selectedView } from 'data/views/views.selectors';
import { getCurrentWorkspaceId, getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { User } from 'data/users/users.types';
import { getTenantConfig } from 'env';
import { Location } from 'history';
import * as _ from 'lodash/fp';
import { REASON_CLOSE } from 'pages/Messages/constants';
import Conversation, { ShowWarningMessage } from 'pages/Messages/Conversation';
import { MessageStates } from 'pages/Messages/Message';
import { SendMessageStates } from 'pages/Messages/MessageEditor';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { history } from 'store/configureStore';
import svgIcons from 'styles/svgIcons';
import { ViewNode } from 'types/response/viewNode';
import { Schema } from 'types/schema';
import { getPrimaryField } from 'utilities/collections';
import { normalizeURL } from 'utilities/format';
import { parseURI } from 'utilities/parseURI';
import { stripUrlsLastItem } from 'utilities/queryParams';
import { Reminder as ReminderType } from 'data/reminders/reminders';

import { currentSheetValidId } from '../../../data/sheets/sheets.selectors';
import { CommonNode } from '../../../types/response';
import { styles } from './index.style';
import { removeHTMLTagsFromText } from 'components/AgGrid/utils';


const tenantConfig = getTenantConfig() as any;

interface State {
  messagingShowing: boolean;
  openSnackbar: boolean;
  shouldClose: boolean;
  openLockPermissionsModal: boolean;
  messageState: SendMessageStates | MessageStates;
  bulkReminders: Array<ReminderType>;
}

interface OwnProps {
  node: Partial<CommonNode>;
  location?: Location;
  windowTitle?: string;
  enableSwitcher?: boolean;
  onClose?(): void;
  checkClose: boolean;
  resetCheckClose?(): void;
}

interface StateProps {
  fieldIds: string[];
  view: ViewNode;
  schema: Schema;
  nodes: Array<object>;
  location: Location;
  workspaceId: string;
  permissions: string[];
  user?: User;
  sheet?: string | null;
}

interface DispatchProps {
  close: typeof ModalActions.closeModal;
  createNode: typeof CollectionActions.createNode;
  updateNode: typeof updateNode.request;
  closeLoader(): void;
  tempPersistAttachments: typeof tempPersistAttachments;
  openNodeModal(
    node: Partial<CommonNode>,
    windowTitle?: string,
    enableSwitcher?: boolean,
  ): void;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const selectors = createStructuredSelector<ReduxState, StateProps>({
  fieldIds: modalVisibleFieldIdsSelector,
  view: selectedView,
  schema: schemaSelector,
  location,
  nodes: sortedAndFilteredNodes,
  workspaceId: getCurrentWorkspaceId,
  permissions: getWorkspacePermissions,
  user: (state: ReduxState) => state?.users?.user,
  sheet: currentSheetValidId,
});

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  createNode: CollectionActions.createNode,
  updateNode: updateNode.request,
  closeLoader: loadingAction.endLockingLoader,
  openNodeModal,
  tempPersistAttachments,
};

class NodeView extends React.Component<Props, State> {
  state = {
    openLockPermissionsModal: false,
    messagingShowing: true,
    openSnackbar: false,
    messageState: SendMessageStates.NO_VALUE,
    shouldClose: true,
    bulkReminders: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.checkClose !== prevProps.checkClose && this.props.checkClose) {
      this.onClose();
    }
  }

  onShowWarning = (showWarning: boolean, messageState: SendMessageStates | MessageStates) => {
    this.setState({ openSnackbar: false, shouldClose: !showWarning, messageState }, () => {
      if (this.props.checkClose) {
        this.props.resetCheckClose?.();
      }
    });
  };

  handleClose = (event: React.SyntheticEvent<any, Event>, reason: string) => {
    if (reason === REASON_CLOSE.CLICK_AWAY) {
      return;
    }

    let newState: State = { ...this.state, openSnackbar: false };
    if (reason === REASON_CLOSE.OK) {
      newState = { ...newState, shouldClose: true };
    }

    if (reason === REASON_CLOSE.CANCEL) {
      this.props.resetCheckClose?.();
    }

    this.setState(newState, () => {
      this.state.shouldClose && this.onClose();
    });
  };

  handleOpenLockPermissionsModal = () => {
    this.setState({ openLockPermissionsModal: true });
  };

  closeLockPermissionModal = () => {
    this.setState({ openLockPermissionsModal: false });
  };

  public render = (): JSX.Element => {
    const {
      node,
      windowTitle,
      schema,
      view,
      fieldIds,
      enableSwitcher,
      classes,
      sheet,
      workspaceId,
      permissions,
      user,
    } = this.props;

    const showMessaging = this.state.messagingShowing && node.id;
    const messagesInsideRecordEnabled =
      tenantConfig.enabledFeatures.messagesInsideRecord;
    const stripWindowTitle = removeHTMLTagsFromText(windowTitle || '');

    return (
      <>
        <div className={`nodeView-modal ${classes.nodeViewWrapper}`}>
          <div className={classes.nodeViewContent}>
            <ModalHeader onClose={this.onClose}>
              <React.Fragment>
                <div className={classes.leftHeaderWRapper}>
                  {enableSwitcher && (
                    <CardSwitcher
                      nextDisabled={this.isNextDisabled()}
                      prevDisabled={this.isPrevDisabled()}
                      onNextClick={this.onSwitchNext}
                      onPrevClick={this.onSwitchPrev}
                    />
                  )}

                  <TitleTooltip placement = 'top-start' title={<span >{stripWindowTitle || ''}</span>}>
                    <span className={classes.windowTitle}>{stripWindowTitle || ''}</span>
                  </TitleTooltip>

                </div>

                <span
                  className={`${classes.arrowIcon} ${classes.arrowIconToRight}`}
                >
                  {messagesInsideRecordEnabled &&
                  !showMessaging && node.id && (
                    <IconButton
                      icon={svgIcons.Message}
                      color={Color.LightBlueGrey}
                      size={Size.Medium}
                      onClick={this.toggleCommentSection}
                      className={classes.iconButton}
                    />
                  )}
                </span>
              </React.Fragment>
            </ModalHeader>
            <CollectionForm
              bulkReminders={this.state.bulkReminders}
              key={node && node.id}
              schema={schema}
              node={node}
              view={view}
              fieldIDs={fieldIds}
              onSubmit={this.handleSubmit}
              onClose={this.onClose}
              permissions={permissions}
              user={user}
              type={node && node.apiURI ? 'UPDATE' : 'CREATE'}
              openLockPermissionsModal={this.handleOpenLockPermissionsModal}
            />
          </div>
          {messagesInsideRecordEnabled && showMessaging && (
            <div className={classes.messagesWrapper}>
              <ModalHeader
                onClose={this.onClose}
                className={classes.headerWrapper}
              >
                <React.Fragment>
                  <span>Comments</span>
                  <span
                    className={`${classes.arrowIcon} ${classes.arrowIconToLeft}`}
                  >
                    {showMessaging && (
                      <IconButton
                        icon={svgIcons.ArrowToLeft}
                        color={Color.LightBlueGrey}
                        size={Size.ExtraSmall}
                        onClick={this.toggleCommentSection}
                        className={classes.iconButton}
                      />
                    )}
                  </span>
                </React.Fragment>
              </ModalHeader>
              <div className={classes.messagesInnerWrapper}>
                <Conversation
                  showWarning={false}
                  onShowWarning={this.onShowWarning}
                  channelId={
                    (sheet || '') + '_' + (node.id || '')
                  }
                  workspaceId={workspaceId}
                  className={classes.backgroundColor}
                >
                  <ShowWarningMessage>
                    <Snackbar
                      className={classes.snackBar}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={this.state.openSnackbar}
                      onClose={this.handleClose}
                      message="You're about to close this dialog and your message has not been saved. Do you want to close without saving?"
                      action={
                        <React.Fragment>
                          <Button
                            id="btnSnackbarCancel"
                            color="secondary"
                            size="small"
                            onClick={(event) =>
                              this.handleClose(event, REASON_CLOSE.CANCEL)
                            }
                          >
                          Cancel
                          </Button>
                          <Button
                            id="btnSnackbarOk"
                            color="primary"
                            size="small"
                            onClick={(event) =>
                              this.handleClose(event, REASON_CLOSE.OK)
                            }
                          >
                          OK
                          </Button>
                        </React.Fragment>
                      }
                    />
                  </ShowWarningMessage>
                </Conversation>
              </div>
            </div>
          )}
        </div>
        <Dialog
          open={this.state.openLockPermissionsModal}
          BackdropProps={{
            classes: {
              root: classes.rootDialog,
            },
          }}
        >
          <LockPermission closeDialog={this.closeLockPermissionModal} />
        </Dialog>
      </>
    );
  };

  private currentNodeKey = (): number => {
    const { nodes, node } = this.props;
    return nodes.findIndex(
      (nextNode: { id: string }) => nextNode && nextNode.id === node.id,
    );
  };

  private isNextDisabled = (): boolean => {
    const currentNodeRowIndex = this.currentNodeKey();
    return currentNodeRowIndex === this.props.nodes.length - 1 || this.state.openSnackbar;
  };

  private isPrevDisabled = (): boolean => {
    const currentNodeRowIndex = this.currentNodeKey();
    return currentNodeRowIndex === 0 || this.state.openSnackbar;
  };

  private onSwitchNext = (): void => {
    const { nodes } = this.props;
    const currentNodeRowIndex = this.currentNodeKey();
    const nextNodeRowIndex = currentNodeRowIndex + 1;
    if (nodes.length !== nextNodeRowIndex) {
      const nodeToSwitchTo = nodes[nextNodeRowIndex];
      this.pushToUrl(nodeToSwitchTo);
      this.handleSwitchToNode(nodeToSwitchTo);
    }
  };

  private onSwitchPrev = (): void => {
    const { nodes } = this.props;
    const currentNodeRowIndex = this.currentNodeKey();
    if (currentNodeRowIndex !== 0) {
      const nodeToSwitchTo = nodes[currentNodeRowIndex - 1];
      this.pushToUrl(nodeToSwitchTo);
      this.handleSwitchToNode(nodeToSwitchTo);
    }
  };

  private pushToUrl = (node: object): void => {
    const { databaseId, dbSlug, workspaceId, sheetId, sheetSlug } = parseURI(
      this.props.location.pathname,
    );
    history.push(
      `/workspaces/${workspaceId}/${dbSlug}/${databaseId}/${sheetSlug}/${sheetId}/items/${node['id']}`,
    );
  };

  private handleSwitchToNode = (nodeToSwitchTo: Partial<CommonNode>) => {
    const primaryField = getPrimaryField();
    this.props.openNodeModal(
      nodeToSwitchTo,
      nodeToSwitchTo.fields?.[primaryField.id].toString() || '',
      true,
    );
  };

  private toggleCommentSection = (): void => {
    this.setState({ messagingShowing: !this.state.messagingShowing });
  };

  private handleSubmit = (
    data: CommonNode,
    closeModal: true,
    forceUpdate = false,
  ) => {
    const { node, createNode, updateNode, close, location } = this.props;
    if (node && node.apiURI) {
      const partialNode = {
        fields: forceUpdate
          ? data.fields
          : _.pickBy(
            (value, key) =>
              !node.fields || !_.isEqual(value, node.fields[key]),
            data.fields,
          ),
      };
      const payload: UpdateNodeRequestPayload = {
        data: partialNode,
        isFullObject: false,
        url: node.apiURI,
      };
      updateNode(payload);
    } else {
      const pathname = location.pathname;
      const url = normalizeURL(pathname);
      const bulkReminders = this.state.bulkReminders;
      const payload: CreateNodeRequestPayload = { url, data: { ...data, bulkReminders } };
      createNode(payload);
    }
    if (closeModal) {
      close();
    }
  };

  private onClose = (): void => {
    const { node, close, closeLoader, tempPersistAttachments, location } = this.props;
    const { shouldClose } = this.state;
    if (shouldClose) {
      if (location.pathname.includes('attachments')) {
        closeLoader();
        close();
        tempPersistAttachments([]);
      } else if (node?.id) {
        history.push(stripUrlsLastItem(location?.pathname || ''));
      }
      closeLoader();
      close();
      tempPersistAttachments([]);
    } else {
      this.setState({ openSnackbar: true });
    }
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(NodeView));
