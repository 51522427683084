import * as React from 'react';

import AddColumnHeaderModal from 'components/AddColumnHeader/AddColumnHeaderModal';
import IconButton, { Color, Size } from 'components/IconButton';
import svgIcons from 'styles/svgIcons';

interface State {
  open: boolean;
  anchorEl: HTMLElement | null;
}

class AddColumnHeader extends React.PureComponent<null, State> {
  state = {
    anchorEl: null,
    open: false,
  };

  public render(): React.ReactNode {
    const { open, anchorEl } = this.state;

    return (
      <>
        <IconButton icon={svgIcons.Add} color={Color.DarkGray} size={Size.Medium} onClick={this.handleOpenPopper} />

        {open &&
          <AddColumnHeaderModal
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClosePopper={this.handleClosePopper}
          />
        }
      </>
    );
  }

  private handleOpenPopper = (event: React.SyntheticEvent<HTMLElement>): void => {
    const { currentTarget } = event;
    // @ts-ignore
    if (this.props.api) {
      // @ts-ignore
      this.props.api.clearFocusedCell();
    }
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  private handleClosePopper = (): void => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  };
}

export default AddColumnHeader;
