import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { schemaColumnsMap } from 'data/collections/collections.selectors';

import CardBody from 'components/Kanban/cards/Task/CardBody';
import { CalendarEvent, ColumnsMapById } from 'types/gridOptions';

import { columnsStateSelector } from 'data/collections/view-config/viewConfig.selectors';

import { State } from '../../../reducers';
import { CommonNode } from '../../../types/response';
import { ViewNode } from '../../../types/response/viewNode';
import { calculateFields } from '../../../utilities/collections';
import { selectedView } from 'data/views/views.selectors';
import { GridColumnState } from 'data/collections/collections.reducer';

interface OwnProps {
  className?: string;
  event: CalendarEvent;
  onOpenPopup?(event: CommonNode): void;
}

interface StateProps {
  columns: GridColumnState[];
  schemaColumns: ColumnsMapById;
  selectedView: ViewNode;
}

type Props = OwnProps & StateProps;

const selectors = createStructuredSelector<State, StateProps>({
  columns: columnsStateSelector,
  schemaColumns: schemaColumnsMap,
  selectedView,
});


export function CardWrapper(props: Props): JSX.Element {
  const { className, event, columns, schemaColumns, selectedView, onOpenPopup } = props;

  const { fields } = calculateFields({
    task: event.node,
    columnsState: columns,
    schemaColumns,
    view: selectedView,
  });

  const handleExpandRecord = (): void => {
    if (onOpenPopup) {
      onOpenPopup(event.node);
    }
  };

  return (
    <div className={className}>
      <CardBody
        title={event.title}
        task={props.event.node}
        fields={fields}
        permissions={[]}
        onExpandRecord={handleExpandRecord}
      />
    </div>
  );
}

export default connect<StateProps, {}, OwnProps>(selectors)(CardWrapper);
