import styled, { css } from 'styled-components';
import { blueyGrey, lightGreyBlue, white } from 'styles/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'root' | 'content' | 'noPreviewContainer' | 'noPreviewText' | 'pages';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0 -16px',
  },
  content: {
    display: 'flex',
    height: '100%',
  },
  noPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  noPreviewText: {
    textAlign: 'center',
    marginBottom: '5px',
  },
  pages: {
    flex: 1,
  },
};

// We don't have access to react-virtualized grid classNames overrides
// overriding it here
// TODO https://github.com/bvaughn/react-virtualized/issues/1248
export const PagesListWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  
  .pages-list {
    padding-bottom: 15px;
    overflow: auto !important;
  }
  
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
    padding-bottom: 15px;
  }
  
`;

const pageCss = css`
  background: ${white};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  
  img {
    width: 100%;
    position: absolute;
    display: block;  
  }
`;

export const PreviewPageWrapper = styled.div`
  width: 100%;
  padding: 16px 15px 0 15px;
  margin: 0 auto;
`;

export const PreviewPage = styled.div``;

export const PreviewPageImgWrapper = styled.div`
  ${pageCss}
`;

export const THUMBNAILS_WIDTH = 155;
export const Thumbnails = styled<{ opened?: boolean }, 'div'>('div')`
  width: ${THUMBNAILS_WIDTH}px;
  margin-left: ${({ opened }) => opened ? 0 : -THUMBNAILS_WIDTH}px;
  
  .thumbnails-list {
    padding-bottom: 15px;
  }
`;

export const ThumbnailWrapper = styled<{parentScroll: boolean}, 'div'>('div')` 
  padding: 16px ${({ parentScroll }) => parentScroll ? 12 : 0}px 0 12px;
`;

const BORDER_WIDTH = 3;

// Bordered wrapper to let user know which page is currently active
export const ThumbnailFrame = styled<{ current: boolean }, 'div'>('div')` 
  border: ${BORDER_WIDTH}px solid ${({ current }) => current ? lightGreyBlue : 'transparent'};
  margin-bottom: 6px;
`;

export const ThumbnailPageImgWrapper = styled.div`
  ${pageCss}
  display: block;
  cursor: pointer;
`;

export const PageNumber = styled.div`
  padding-left: ${BORDER_WIDTH}px;
  font-size: 14px;
  color: ${blueyGrey}; 
`;
