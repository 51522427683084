import { IDoesFilterPassParams } from 'ag-grid-community';
import { textFilterComparator } from '../../../../data/collections/filters';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function TextFilter() {

}

TextFilter.prototype.init = function(params) {
  this.valueGetter = params.valueGetter;
  this.value = '';
  this.model = null;
  this.field = params.colDef.field;
};

TextFilter.prototype.getGui = function() {
  return document.createElement('div');
};

TextFilter.prototype.doesFilterPass = function(params: IDoesFilterPassParams): boolean {
  return textFilterComparator(this.filter, params.data?.[this.field]?.toLowerCase() || '', this.value);
};

TextFilter.prototype.isFilterActive = function() {
  return this.value !== '';
};

TextFilter.prototype.getModel = function() {
  return { value: this.value };
};

TextFilter.prototype.setModel = function(model) {
  if (!model) return;
  this.value = model.value?.toLowerCase();
  this.filter = model.filter;
};

export default TextFilter;
