import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import MessageList from './MessageList';
import MessageEditor, { SendMessageStates } from './MessageEditor';
import { MessageStates, MessageState } from './Message';
import { styles } from './Conversation.styles';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import * as types from 'data/messages/types';
import { AccountNode } from 'types/response/accountNode';
import { User } from 'data/users/users.types';
import { getAllUsers } from 'data/accounts/actions';
import {
  getMessages,
  updateMessage,
  deleteMessage,
  setCurrentChannel,
} from 'data/messages/actions';
import LoadingState from 'data/LoadingState';
import { getActiveWorkspaceUsers } from 'data/accounts/selectors';
import loadingAction from 'data/ui/loading/actions';

interface StateProps {
  user: User;
  messagesCount: number;
  messages: types.Message[];
  loadingState: LoadingState;
  currentChannelId: string;
  messagesGroupedByDay: types.Message[][];
  suggestions?: types.Suggestions[];
}

interface DispatchProps {
  getAllUsers: () => void;
  deleteMessage: (id: string) => void;
  updateMessage: (options: types.UpdateMessage) => void;
  fetchMessages: ( payload: { workspaceId: string; query: types.QueryParams }) => void;
  setCurrentChannel: (channelId: string) => void;
  startLockingLoader: () => void;
  endLockingLoader: () => void;
}

interface ShowWarningMessageProps {
  children?: React.ReactNode;
  showWarning?: boolean;
}

interface OwnProps {
  channelId: string;
  workspaceId: string;
  className?: string;
  showWarning?: boolean;
  children?: React.ReactNode;
  onShowWarning?: (
    showWarning: boolean,
    messageState: SendMessageStates | MessageStates | MessageState,
  ) => void;
}

interface State {
  limit: number;
  isFetching: boolean;
  initialFetch: boolean;
  scrollHeight: number;
  showWarning: boolean;
  messageStateList: MessageStates[];
  isHidden: boolean;
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & OwnProps;

export const ShowWarningMessage: React.FC<ShowWarningMessageProps> = ({
  showWarning,
  children,
}: ShowWarningMessageProps) => {
  return showWarning ? (children as React.ReactElement<any>) : null;
};

export class Conversation extends React.Component<Props, State> {
  private wrapper: HTMLDivElement | null;
  private ref = React.createRef<HTMLDivElement>();
  private heightValueOfTwoMessages = 154;

  constructor(props: Props) {
    super(props);
    this.state = {
      showWarning: false,
      limit: 20,
      isFetching: false,
      initialFetch: true,
      scrollHeight: 0,
      messageStateList: [],
      isHidden: true,
    };
    this.wrapper = null;
  }

  public componentDidMount = (): void => {
    if (this.props.currentChannelId !== this.props.channelId) {
      this.props.setCurrentChannel(this.props.channelId);
    }

    if (this.ref.current) {
      this.wrapper = this.ref.current;
      this.wrapper.addEventListener('scroll', this.handleScroll);
      this.props.getAllUsers();
      if (this.props.messages.length === 0) {
        this.fetchMessages();
      }
      this.scrollToBottom();
    }

    if (this.state.isHidden) {
      this.setState({ isHidden: false });
      const timer = setTimeout( () => {
        this.props.endLockingLoader();
        clearTimeout(timer);
      }, 1500);
    }
  };

  toggle = (
    showWarning = false,
    messageState: SendMessageStates | MessageStates | MessageState,
  ) =>
    this.setState({ showWarning }, () =>
      this.props.onShowWarning?.(this.state.showWarning, messageState),
    );

  handleEditorChange = (messageState: SendMessageStates | MessageStates) => {
    const newMessageStateList = [...this.state.messageStateList];
    if (
      messageState === MessageStates.UPDATE ||
      messageState === MessageStates.BROWSE
    ) {
      if (messageState === MessageStates.UPDATE) {
        newMessageStateList.push(messageState);
      }
      if (messageState === MessageStates.BROWSE) {
        newMessageStateList.pop();
      }
      this.setState({
        messageStateList: newMessageStateList,
      });
    }
    if (
      messageState === SendMessageStates.WITH_VALUE ||
      newMessageStateList.length
    ) {
      !this.state.showWarning && this.toggle(true, messageState);
    } else if (
      messageState === SendMessageStates.NO_VALUE ||
      !newMessageStateList.length
    ) {
      this.state.showWarning && this.toggle(false, messageState);
    }
  };

  public render(): JSX.Element {
    const className = this.props.className || '';

    if (this.state.isHidden) {
      this.setState({ isHidden: false });
      const timer = setTimeout( () => {
        this.props.startLockingLoader();
        clearTimeout(timer);
      }, 0);
    }

    return (
      <>
        <div
          ref={this.ref}
          className={`${this.props.classes.wrapper} ${className}`}
        >
          <MessageList
            suggestions={this.props.suggestions}
            currentUserId={this.props.user && this.props.user.id}
            messagesGroupedByDay={this.props.messagesGroupedByDay}
            updateMessage={this.props.updateMessage}
            deleteMessage={this.props.deleteMessage}
            className={this.props.className}
            onChange={this.handleEditorChange}
          />
        </div>
        <MessageEditor
          onChangeHandler={this.handleEditorChange}
          user={this.props.user}
          className={this.props.className}
          suggestions={this.props.suggestions}
        />
        {React.Children.map(this.props.children, (childElement) =>
          React.cloneElement(childElement as React.ReactElement<any>, {
            showWarning: this.state.showWarning,
          }),
        )}
      </>
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.channelId !== this.props.channelId) {
      this.props.setCurrentChannel(this.props.channelId);
      return;
    }

    if (
      prevProps.currentChannelId !== this.props.currentChannelId &&
      !this.props.messages.length &&
      this.props.loadingState !== LoadingState.Loading
    ) {
      this.fetchMessages();
      return;
    }

    if (
      prevProps.loadingState === LoadingState.Loading &&
      this.props.loadingState === LoadingState.Loaded
    ) {
      if (this.state.initialFetch) {
        this.scrollToBottom();
        this.setState({ initialFetch: false });
        return;
      }
      this.setScrollPosition();
      return;
    }

    if (
      this.props.loadingState !== LoadingState.Loading &&
      this.props.messages.length > prevProps.messages.length
    ) {
      this.scrollToBottom();
    }
  }

  private fetchMessages(): void {
    if (this.wrapper) {
      this.setState({ scrollHeight: this.wrapper.scrollHeight });
    }
    const query: types.QueryParams = {
      channelId: this.props.channelId,
      limit: this.state.limit,
      createdOn: this.props.messages[0] && this.props.messages[0].createdOn,
    };
    this.props.fetchMessages({ workspaceId: this.props.workspaceId, query });
  }

  private handleScroll = (event: MouseEvent): void => {
    const target = event.target as HTMLDivElement;
    if (
      this.props.loadingState !== LoadingState.Loading &&
      target.scrollTop < this.heightValueOfTwoMessages &&
      this.props.messages.length < this.props.messagesCount
    ) {
      this.fetchMessages();
    }
  };

  private scrollToBottom = (): void => {
    setTimeout(() => {
      if (this.wrapper) {
        this.wrapper.scrollTop = this.wrapper.scrollHeight;
      }
    }, 0);
  };

  private setScrollPosition = (): void => {
    if (this.wrapper) {
      this.wrapper.scrollTop =
        this.wrapper.scrollHeight - this.state.scrollHeight;
    }
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const channel = state.messages.channels[state.messages.currentChannelId];
  const accounts = getActiveWorkspaceUsers(state).map(
    (account: AccountNode): types.Suggestions => {
      return {
        id: account.id,
        name: account.displayName || '',
        email: account.primaryEmail,
      };
    },
  );
  return {
    user: state.users.user,
    loadingState: state.messages.loadingState,
    currentChannelId: state.messages.currentChannelId,
    messages: (channel && channel.messages) || [],
    messagesCount: (channel && channel.messagesCount) || 0,
    messagesGroupedByDay: (channel && channel.messagesGroupedByDay) || [],
    suggestions: [...accounts, { id: 'team', name: 'TEAM' }],
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchMessages: getMessages,
  updateMessage,
  deleteMessage,
  setCurrentChannel,
  getAllUsers,
  startLockingLoader: loadingAction.startLockingLoader,
  endLockingLoader: loadingAction.endLockingLoader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Conversation));
