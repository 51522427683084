import * as React from 'react';

import { getFormattedDate } from './DateTime.utils';
import TextRenderer from '../components/TextRenderer';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import { DateFormat, TimeFormat } from '../../Fields/data';
import svgIcons from 'styles/svgIcons';
import * as colors from 'styles/colors';
import moment from 'moment';
import { State as ReduxState } from 'reducers';
import { connect } from 'react-redux';

interface StateProps {
  userEmail: string;
}

interface DateTimeRendererProps extends FieldRendererProps {
  userEmail?: string;
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
  includeTime?: boolean;
}

type Props = StateProps & DateTimeRendererProps;

const DateTimeRenderer = (props: Props) => {
  const { type, fieldType } = props;
  const value = typeof props.value === 'string' ? { timestamp: props.value } : props.value;

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    return (
      <span
        className={getColumnHeaderClass(fieldType)}
        title={getFormattedDate(value, props)}
      />
    );
  }

  const isScheduled = (): boolean => {
    if (value && value.scheduled) {
      const userScheduled = value.scheduled.filter(item => item.email === props.userEmail);
      if (userScheduled.length < 1) return false;

      return userScheduled[0].isScheduled;
    }

    return false;
  };

  const formattedDate = getFormattedDate(value && value.timestamp? value.timestamp : value, props);
  return (
    <TextRenderer color={isScheduled() ? moment(value.timestamp).isAfter(moment(new Date())) ? colors.algaeGreen : colors.redOrange : undefined}>
      {formattedDate}
      {formattedDate && isScheduled() && (
        <span className="datepicker-notify-icon-wrapper">
          <svgIcons.Clock height="1rem" />
        </span>
      )}
    </TextRenderer>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userEmail: (state.app.user && state.app.user.primaryEmail) || '',
  };
};

export default connect(mapStateToProps)(wrapCellRenderer(DateTimeRenderer));
