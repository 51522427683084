import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import Typography, { Variant } from '../../../Typography';

function LongText(props: WithStyles<ClassKey>): JSX.Element {
  const { classes } = props;

  return (
    <Typography variant={Variant.Label} className={classes.description}>
      A long text field that can span multiple lines.
    </Typography>
  );
}

type ClassKey = 'description';

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
});

export default styles(LongText);
