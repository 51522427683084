class StateUtils {
  async wait(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  }
}

export default new StateUtils();
