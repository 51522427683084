import React from 'react';

import Button, { Variant as ButtonVariant } from 'components/Button';
import Modal from 'components/Modals/Modal';
import ModalContent from 'components/Modals/Modal/Content';
import ModalHeader from 'components/Modals/Modal/Header';
import ModalTextField from 'components/Modals/ModalTextField';
import * as keys from 'keycode-js';

interface Props {
  onClose(): void;
  moveRowsToPosition(position: number): void;
}

const actionButtons = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '30px',
};

const MoveToPosition: React.FC<Props> = ({ onClose, moveRowsToPosition }) => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState(1);

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    if (parseInt(event.target.value) <= 0) {
      return;
    }
    setPosition(parseInt(event.target.value));
    setHasError(isNaN(parseInt(event.target.value)));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if ( [keys.KEY_ENTER, keys.KEY_RETURN].includes(e.keyCode) ) {
      moveRowsToPosition(position);
    }
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose}>Move Above Position</ModalHeader>
      <ModalContent>
        <ModalTextField
          type='number'
          label='Position'
          value={position}
          onKeyDown={handleKeyDown}
          onChange={onValueChange}
          autoSelect
          autoFocus
          inputProps={{
            min: 1,
          }}
        />
      </ModalContent>
      <div style={actionButtons}>
        <Button label='Cancel' variant={ButtonVariant.SecondaryLink} onClick={onClose} />
        <Button
          label={'Move'}
          variant={ButtonVariant.Primary}
          onClick={() => moveRowsToPosition(position)}
          disabled={hasError}
        />
      </div>
    </Modal>
  );
};

export default MoveToPosition;
