import * as React from 'react';
import { removeHTMLTag } from 'utilities/format';
import { getGridRowMaxTextLines } from '../../AgGrid/utils';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import TextRenderer from '../components/TextRenderer';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';

export function SingleLineOfTextRenderer(props: FieldRendererProps): JSX.Element {
  const { type, fieldType, node, isGrid } = props;
  let { value } = props;

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    return (
      <span
        className={getColumnHeaderClass(fieldType)}
        title={value}
      />
    );
  }

  // TODO: Find root cause of why an object is being passed as a value
  if (value !== null && typeof value === 'object') {
    value = value.toString();
  }

  const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;

  if (value !== null) {
    value = value.toString();
    value = removeHTMLTag(value);
  }

  return (
    <TextRenderer lines={lines}>
      {value}
    </TextRenderer>
  );
}

export default wrapCellRenderer(SingleLineOfTextRenderer);
