import {
  constructFilter,
  getDefaultOperatorForFilterType,
  isOperatorTypeHasChanged,
} from 'components/NodeFieldData/filters/helpers';
import * as constants from 'data/collections/constants';
import * as fromCollectionActions from 'data/collections/collections.actions';
import * as fromViewConfigActions from 'data/collections/view-config/viewConfig.actions';

import * as fromActions from './filterModel.actions';
import { FilterModel, FilterType, RegularFilter } from '../../types/gridOptions';

export const defaultState: FilterModel = {
  regularFilters: [],
  searchFilters: {},
  quickSearch: '',
};

type Actions = fromActions.Action | fromCollectionActions.ActionsType | fromViewConfigActions.ActionsType;

const reducer = (state: FilterModel = defaultState, action: Actions): FilterModel => {
  switch (action.type) {
    case fromActions.ActionType.SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      };

    case fromViewConfigActions.APPLY_VIEW_CONFIG:
      return action.payload.filters || defaultState;

    case constants.CLEAN_COLLECTION:
    case fromActions.ActionType.RESET_FILTER_MODEL:
      return defaultState;

    case fromActions.ActionType.UPDATE_FILTER: {
      const { index, columnId, operator, type, value, dateFormat } = action.payload;
      const filter = state.regularFilters[index];
      const newColumnId = columnId || filter.columnId;
      const newType = type || filter.type;
      const newOperator =
        operator !== undefined
          ? operator
          : newType !== filter.type
            ? getDefaultOperatorForFilterType(newType)
            : filter.operator;
      const newValue = value !== undefined
        ? value
        : isOperatorTypeHasChanged(filter.operator, newOperator)
        || (filter.type === FilterType.Date && dateFormat && dateFormat !== filter.dateFormat)
          ? undefined // Reset filter value if operator type or date format changed
          : filter.filter; // value from payload could be null, which is valid
      const newDateFormat = dateFormat !== undefined
        ? dateFormat
        : filter.type === FilterType.Date
          ? filter.dateFormat
          : undefined;
      const updatedFilter: RegularFilter = constructFilter(newColumnId, newType, newOperator, newValue, newDateFormat);
      const regularFilters = [...state.regularFilters];
      regularFilters[index] = updatedFilter;

      return {
        ...state,
        regularFilters,
      };
    }

    case fromActions.ActionType.DELETE_FILTER: {
      const regularFilters = [...state.regularFilters];
      regularFilters.splice(action.payload, 1);

      return {
        ...state,
        regularFilters,
      };
    }

    case fromActions.ActionType.ADD_FILTER: {
      const { columnId, type, dateFormat } = action.payload;
      const newFilter = constructFilter(
        columnId,
        type,
        getDefaultOperatorForFilterType(type),
        undefined,
        dateFormat,
      );
      const updatedFilters = [...state.regularFilters, newFilter];

      return {
        ...state,
        regularFilters: updatedFilters,
      };
    }

    case fromActions.ActionType.SET_QUICK_SEARCH_QUERY: {
      return {
        ...state,
        quickSearch: action.payload,
      };
    }
  }

  return state;
};

export default reducer;
