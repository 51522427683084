import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { PersonData } from '../../data';
import Switch from '../../../Switch';
import Typography, { Variant } from '../../../Typography';

interface Props extends WithStyles<ClassKey> {
  data: PersonData;
  allowMultipleCollaborators?: boolean;
  onDataUpdate(data: PersonData): void;
}

function Person(props: Props): JSX.Element {
  const { allowMultipleCollaborators, classes, data } = props;

  function onChecked(): void {
    props.onDataUpdate({ ...data, allowMultiple: !data.allowMultiple });
  }

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        A collaborator field lets you add collaborators to your records. Collaborators can optionally be notified when they&apos;re added.
      </Typography>
      {allowMultipleCollaborators &&
        <div className={classes.toggle}>
          <Switch
            label="Allow adding multiple collaborators"
            selected={data.allowMultiple}
            onClick={onChecked}
            labelRight
          />
        </div>
      }
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'toggle'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  toggle: { marginTop: 8 },
});

export default styles(Person);
