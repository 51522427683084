import * as React from 'react';

import { From } from 'components/DataGrid/items/BulkUpdate';
import { CommonNode } from 'types/response';
import { FieldType } from 'types/response/fieldNode';

import { getEditorByFieldType, getRendererByFieldType, FieldRendererTypes } from '../../NodeFieldData';
import { getFormEditorByFieldType, shouldAddOnBlurEventForType } from '../../NodeFieldData/Fields.editors';
import Popover from '../../Popover/Popover';
import { Reminder as ReminderType } from 'data/reminders/reminders';


interface RendererWithPopoverFactoryProps {
  value: any;
  name: string;
  onChange: (id: string, value: any) => void;
  formatter: FieldType;
  editor: FieldType;
  rendererType: FieldRendererTypes;
  meta?: any;
}

export const rendererWithPopoverEditorFactory = (props: RendererWithPopoverFactoryProps) => {
  // TODO check all this functionality
  const { value, name, onChange, formatter, editor, rendererType = FieldRendererTypes.normal } = props;
  const meta = props.meta || {};
  const RendererComponent = getRendererByFieldType(formatter);
  const EditorComponent = getEditorByFieldType(editor).OriginalComponent;
  return (
    <Popover id={name} content={<EditorComponent value={value} onChange={onChange} {...meta} />}>
      <RendererComponent value={value} type={rendererType} name={name} {...meta} />
    </Popover>
  );
};


interface EditorFactoryProps<Node extends CommonNode = CommonNode> {
  value?: any;
  id?: string;
  name: string;
  onChange: (id: string, value: any, event?: any) => void;
  fieldType: FieldType;
  data: Node;
  fieldId: string;
  meta?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  lock?: boolean;
  from?: From;
  type?: string;
  onBlur?: () => void;
  permissions?: string[];
  bulkReminders?: Array<ReminderType>;
}

export const editorFactory = (props: EditorFactoryProps) => {
  const {
    value,
    name,
    onChange,
    fieldType,
    data,
    fieldId,
    autoFocus,
    readOnly,
    lock,
    from,
    type,
    onBlur,
    permissions,
    bulkReminders,
  } = props;
  const meta = props.meta || {};
  const EditorComponent = getFormEditorByFieldType(fieldType);
  const strippedId = name.replace(/[\W]/gi, '');

  return (
    <EditorComponent
      id={strippedId}
      value={value}
      name={name}
      onChange={onChange}
      onBlur={shouldAddOnBlurEventForType(fieldType) ? onBlur : null}
      fieldId={fieldId}
      data={data}
      autoFocus={autoFocus}
      readOnly={readOnly}
      lock={lock}
      from={from}
      type={type}
      permissions={permissions}
      bulkReminders={bulkReminders}
      {...meta}
    />
  );
};

interface RendererFactoryProps<Node extends CommonNode = CommonNode> {
  value?: any;
  name: string;
  formatter: FieldType;
  meta?: any;
  data: Node;
}

export const rendererFactory = <Node extends CommonNode>(props: RendererFactoryProps<Node>) => {
  const { value, name, formatter, data } = props;
  const meta = props.meta || {};
  const RendererComponent = getRendererByFieldType(formatter);

  return <RendererComponent value={value} name={name} data={data} {...meta} isGroup={true} />;
};
