import React, { useEffect, useState } from 'react';
import Button, { Variant as ButtonVariant } from 'components/Button';
import Logo from '../../assets/images/auth/hubsync-logo-white.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFAGeneralStyles.index.style';
import { SIGN_IN_MODES } from 'pages/Auth/SignIn';
import svgIcons from 'styles/svgIcons';

interface StateProps {
  user: User;
  mode: SIGN_IN_MODES;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
  // @ts-ignore state
  mode: (state: ReduxState) => state.mode || '',
});

const ConfirmCode = (props) => {
  const {
    classes,
    onConfirmClicked,
    backToMFA,
    isLoadingEmail,
    isCodeWrong,
    resendCode,
  } = props;
  const [code, setCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);

  const tenant = window.appStore.getState().app.tenant;

  useEffect(() => {
    setIsLoading(isLoadingEmail);
  }, [isLoadingEmail]);

  useEffect(() => {
    setWrongCode(isCodeWrong);
  }, [isCodeWrong]);

  const selectedMethod = () => {
    onConfirmClicked(code);
  };

  const sendResendCode = () => {
    resendCode();
  };

  const goBackToMFA = () => {
    backToMFA();
  };

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={'auth-container'+ (tenantConfig.clientName || '')}>
        <div className="auth-form-container">
          <div className="auth-logo">
            <img src={tenantConfig.logoURL ?? Logo} alt={'Hubsync'} />
          </div>
          <div className={classes.mfaFormContainer}>
            <div className={classes.topContent}>
              <h3>Email Verification</h3>
              <p className={classes.helperText}>
                Enter the security code that <br /> was sent to your email.
              </p>
            </div>
            <div>
              <div className={classes.textField}>
                <div className={classes.radioButtonContainer}>
                  <label className={classes.textLabel}>Security Code</label>
                  <input
                    className={classes.textInput}
                    value={code}
                    placeholder="Enter the six-digit code"
                    color="primary"
                    onChange={(event) => {
                      setCode(event.target.value);
                    }}
                  />
                </div>
                {wrongCode ? (
                  <div className="auth-input-error-container">
                    <svgIcons.Limited />{' '}
                    <span
                      className="auth-error-message"
                      style={{ paddingTop: '11px', paddingLeft: '5px' }}
                    >
                      {'Invalid code. Please try again.'}
                    </span>
                  </div>
                ) : null}
                <div className={classes.textContainerCode}>
                  <div>
                    <div
                      onClick={sendResendCode}
                      className={classes.textSpanLeft}
                    >
                      Resend Code
                    </div>{' '}
                  </div>{' '}
                  <a
                    href={tenant.apiEndpoints.zendeskTargetURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.textSpanRight}
                  >
                    <span>Need Help?</span>
                  </a>
                </div>
              </div>
              <div>
                <Button
                  id="verify"
                  className={ tenantConfig.clientName ? classes.verifyActionButtonbakertilly : classes.verifyActionButton }
                  label="Verify"
                  isLoading={isLoading}
                  variant={tenantConfig.clientName ? ButtonVariant.Secondary :ButtonVariant.Primary}
                  onClick={() => {
                    setIsLoading(true);
                    setWrongCode(false);
                    selectedMethod();
                  }}
                  disabled={!code || isLoading}
                />
              </div>
            </div>
          </div>
          <Button
            id="needHelp"
            className={classes.backToMFAButton}
            label="Change MFA Method Selection"
            variant={ButtonVariant.PrimaryLink}
            onClick={() => {
              goBackToMFA();
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(ConfirmCode));
