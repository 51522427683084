import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as _ from 'lodash/fp';
import * as React from 'react';

import * as Data from '../../data';
import Select, { Variant } from '../../../Select';
import Tabs from '../../../Tabs';
import Switch from '../../../Switch';
import DefaultNumberTextField from './DefaultNumberTextField';

interface Props extends WithStyles<ClassKey> {
  data: Data.NumberData;
  onDataUpdate(data: Data.NumberData): void;
}

function Number(props: Props): JSX.Element {
  const { data, classes } = props;

  function onSubtypeChange(subtype: Data.NumberType): void {
    props.onDataUpdate({ ...data, subtype });
  }

  function onPrecisionChange(precision: Data.Precision): void {
    props.onDataUpdate({ ...data, precision });
  }

  function onAllowNegativeChange(): void {
    props.onDataUpdate({ ...data, allowNegative: !data.allowNegative });
  }

  function onDefaultValueChange(defaultNumber?: number): void {
    props.onDataUpdate({ ...data, defaultNumber });
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        data={[{
          title: 'Format',
          content: (
            <React.Fragment>
              <Select
                variant={Variant.Small}
                value={data.subtype}
                values={Data.numberTypes}
                getLabel={Data.getNumberTypeLabel}
                getSelectValue={_.identity}
                onChange={onSubtypeChange}
                fullWidth
              />
              {data.subtype === Data.NumberType.Float &&
                <div className={classes.row}>
                  <Select
                    label="Precision"
                    value={data.precision}
                    values={Data.precisions}
                    getLabel={_.curry(Data.getPrecisionLabel)('')}
                    getSelectValue={_.identity}
                    onChange={onPrecisionChange}
                    fullWidth
                  />
                </div>
              }
              <div className={classes.row}>
                <Switch
                  label="Allow negative numbers"
                  selected={data.allowNegative}
                  onClick={onAllowNegativeChange}
                  labelRight
                />
              </div>
            </React.Fragment>
          ),
        }, {
          title: 'Default',
          content: (
            <DefaultNumberTextField
              label="Prefill new cells with this number"
              defaultNumber={data.defaultNumber}
              onValueChange={onDefaultValueChange}
            />
          ),
        }]}
      />
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'row'
  ;

const styles = withStyles<ClassKey>({
  wrapper: { marginTop: 16 },
  row: { marginTop: 5 },
});

export default styles(Number);
