import { flatMap } from 'lodash';
import { DirectoryType } from 'data/documents/documents.types';

const matchQuery = (query = '', string: string) =>
  string.toLowerCase().includes(query.toLowerCase());

export function deepFilter(query: string, directories: DirectoryType[]) {
  return flatMap(directories, directory => {
    const { name, nodes = [] } = directory;
    const matchedSubDirectories = deepFilter(query, nodes);
    const matchedSelf = matchQuery(query, name);
    return (matchedSelf || matchedSubDirectories.length)
      ? { ...directory, nodes: matchedSubDirectories }
      : [];
  });
}
