import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { styles } from 'components/Modals/ModalTextField.style';
import SelfSelectingField from 'components/SelfSelectingField';
import { Omit } from 'utility-types';


type FieldProps = Omit<TextFieldProps,
  'inputRef' | 'error' | 'helperText' | 'fullWidth' | 'rows' | 'variant'>;
type StyleProps = WithStyles<typeof styles>;

interface OwnProps {
  id?: string;
  lock?: boolean;
  errorMessage?: React.ReactNode;
  autoSelect?: boolean;
  permissions?: string[];
  tabIndex?: number;
}

type Props = FieldProps & StyleProps & OwnProps;

function ModalTextField(props: Props): JSX.Element {
  const { id, name, autoSelect, errorMessage, classes, lock, InputProps, permissions, ...fieldProps } = props;
  const hasError = !!errorMessage;
  const Field = autoSelect ? SelfSelectingField : TextField;
  const { formControlRoot, ...inputClasses } = classes;
  const fieldId = name || id;

  return (
    <Field
      id={fieldId}
      name={name}
      error={hasError}
      fullWidth
      helperText={errorMessage}
      classes={{ root: formControlRoot }}
      InputProps={{
        ...(InputProps? InputProps: undefined),
        disableUnderline: true,
        classes: inputClasses,
      }}
      lock={lock}
      rows={2}
      {...fieldProps}
    />
  );
}

export default withStyles(styles)(ModalTextField);
