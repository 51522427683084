import { slateTwo } from 'styles/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export type ClassKey = 'menuItemIcon';

export const styles: Record<ClassKey, CSSProperties> = {
  menuItemIcon: {
    width: '13px',
    color: slateTwo,
    marginRight: '12px',
  },
};
