import { WorkspacesState } from 'data/workspaces/workspaces.reducer';

import { State } from '../../reducers';
import { WorkspaceNode, PaginatedData } from 'types/response/workspaceNode';
import LoadingState from 'data/LoadingState';

export const getWorkspacesState = (state: State): WorkspacesState =>
  state.workspaces;

export const getCurrentWorkspaceId = (state: State): string =>
  getWorkspacesState(state).current || '';
export const getAllWorkspacesById = (
  state: State,
): Record<string, WorkspaceNode> => getWorkspacesState(state).byId;

export const getAllPaginatedData = (state: State): PaginatedData => {
  const response = getWorkspacesState(state);
  return {
    totalNumberOfBookmarks: response.totalNumberOfBookmarks,
    favoriteNodes: response.favorites,
    total: response.total,
    totalFavorites: response.favoriteTotal,
    nodes: response.byId,
    totalPages: response.pages,
    totalPagesFavorites: response.favoritePages,
    isDeleting: response.loadingDelete,
  };
};

export const getAllPaginationInfo = (
  state: State,
): Record<string, WorkspaceNode> => getWorkspacesState(state).favorites;
export const getAllWorkspaces = (state: State): WorkspaceNode[] =>
  Object.values(getAllWorkspacesById(state));
export const getCurrentWorkspace = (state: State): WorkspaceNode | undefined =>
  getAllWorkspacesById(state)[getCurrentWorkspaceId(state)];

export function getLoading(state: State): LoadingState {
  return getWorkspacesState(state).loadingState;
}

export function getWorkspacePermissions(state: State): string[] {
  return (
    (state.workspaces.current &&
      state.workspaces.byId[state.workspaces.current] &&
      state.workspaces.byId[state.workspaces.current].permissions) ||
    []
  );
}
