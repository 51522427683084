import { createAsyncThunk } from '@reduxjs/toolkit';

import { tenantSelector } from 'data/app/selectors';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { State } from 'reducers';

import {
  WORKSPACE_STATUS_COL_ID,
  EL_STATUS_COL_ID,
  STATUS_PENDING,
} from '../constants';
import {
  formatStatusDate,
  filteringRecords,
  getGridData,
  parseRecordFields,
} from '../helpers';
import * as services from '../services';
import {
  ProcessPayload,
  EngagementLetterResponse,
} from '../types';
import { updateRecordsInGrid } from './updateRecords';

/**
 * Triggers EL process
 */
export const createEngagementLetterProcess = createAsyncThunk<
  EngagementLetterResponse,
  ProcessPayload
>(
  `ENGAGEMENT_LETTER_CREATE_PROCESS`,
  async ({ ids }, { getState, dispatch }) => {
    try {
      const state = getState() as State;
      const { columns, records } = getGridData(state);
      const tenant = tenantSelector(state);
      const assureSignCredentials = tenant?.thirdParty?.assureSign?.credentials;
      const filteredRecords = filteringRecords(ids, EL_STATUS_COL_ID, records);
      const parsedRecords = parseRecordFields(filteredRecords, columns);
      const processIDs = parsedRecords.map(({ id }) => id);
      const mapValues = {
        values: parsedRecords.map(({ fields }) => ({
          'Client ID': fields['clientId'],
          'Taxpayer Name': `${fields['taxpayerFirstName']} ${fields['taxpayerLastName']}`,
          'Taxpayer Email': fields['taxpayerEmail'],
          'Office': fields['office'],
          'Partner Name': `${fields['partnerFirstName']} ${fields['partnerLastName']}`,
          'Spouse Email': `${fields['email']}`,
          'Spouse Name': `${fields['spouseFirstName']} ${fields['spouseLastName']}`,
        })),
      };

      // Set pending columns and checking if the spouse criteria is ok
      for (const values of mapValues.values) {
        const spouseEmail = values['Spouse Email'].trim();
        const spouseName = values['Spouse Name'].trim();
        const isSingle = (!spouseEmail && !spouseName) ? true : false;
        const isJoint = (spouseEmail && spouseName) ? true : false;
        if (isSingle || isJoint) {
          updateRecordsInGrid(EL_STATUS_COL_ID, STATUS_PENDING, processIDs, dispatch);
        } else {
          updateRecordsInGrid(EL_STATUS_COL_ID, '', processIDs, dispatch);
          return dispatch(notifications.error({
            title: 'Engagement Letter Create.',
            message: `Engagement Letter creation Failed. One or More Engagement Letter(s) has not Spouse Data Complete.`,
          }));
        }
      }

      // trigger process
      const { body } = await services.sendEngagementLettersByRecords(
        mapValues,
        assureSignCredentials?.['engagement-letters'],
      );

      const { status } = body;
      dispatch(notifications.success({
        title: 'Engagement Letter Create.',
        message: `Engagement Letter(s) created Successfully.`,
      }));

      status.forEach((item, index) => {
        const rowID = [processIDs[index]];
        if (item.status.envelopeID) {
          const createdDate = formatStatusDate(item.createdAt);
          updateRecordsInGrid(EL_STATUS_COL_ID, createdDate, rowID, dispatch);
        } else {
          updateRecordsInGrid(EL_STATUS_COL_ID, '', rowID, dispatch);
        }
      });

      return body;
    } catch (error) {
      console.error('error ', error);
      dispatch(notifications.error({
        title: 'Engagement Letter Create.',
        message: `Engagement Letter creation Failed.`,
      }));
      updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, '', ids, dispatch);
      return error;
    }
  },
);
