import * as React from 'react';
import { State as RootState } from 'reducers';
import { closeModal } from 'data/modals/actions';
import { connect } from 'react-redux';
import Rename from 'components/Modals/Rename';
import { updateDatabaseRequest } from 'data/databases/databases.actions';
import { CollectionTypes, CollectionTypeWithTitle, NodeWithTitle } from 'types/schema';
import { getAllWorkspaces } from 'data/workspaces/workspaces.selectors';
import { getAllDatabases } from 'data/databases/databases.selectors';
import { getSingularCollectionName } from 'utilities/collections';
import { getAllTaskDatabases } from 'data/taskdbs/taskdbs.selectors';

interface StateProps {
  workspaceTitles: string[];
  databaseTitles: string[];
  taskDatabaseTitles: string[];
}

interface DispatchProps {
  updateNode: typeof updateDatabaseRequest;
  close: () => void;
}

interface OwnProps {
  collectionType: CollectionTypeWithTitle;
  node: NodeWithTitle;
}

type Props = StateProps & DispatchProps & OwnProps;

export class RenameCollectionItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { node, collectionType, close } = this.props;
    const windowTitle = `Rename ${getSingularCollectionName(collectionType)}`;

    return (
      <Rename
        currentValue={node.title}
        existingValues={this.getExistingTitles()}
        close={close}
        onUpdate={this.onUpdateNode}
        windowTitle={windowTitle}
        valueTitle="title"
      />
    );
  };

  private getExistingTitles = (): string[] => {
    const { collectionType, workspaceTitles, databaseTitles, taskDatabaseTitles } = this.props;
    switch (collectionType) {
      case CollectionTypes.workspaces:
        return workspaceTitles;
      case CollectionTypes.databases:
        return databaseTitles;
      case CollectionTypes.taskdbs:
        return taskDatabaseTitles;
      default:
        return [];
    }
  };

  private onUpdateNode = (title: string): void => {
    const { updateNode, node, collectionType } = this.props;
    updateNode({
      url: node.apiURI,
      data: { title },
      isFullObject: false,
    }, collectionType);
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const workspaces = getAllWorkspaces(state) || [];
  const databases = getAllDatabases(state) || [];
  const taskDatabases = getAllTaskDatabases(state) || [];

  return {
    workspaceTitles: workspaces.map(node => node.title),
    databaseTitles: databases.map(node => node.title),
    taskDatabaseTitles: taskDatabases.map(node => node.title),
  };
};

const mapDispatchToProps: DispatchProps = {
  updateNode: updateDatabaseRequest,
  close: closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameCollectionItem);
