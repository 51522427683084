import { FieldType } from 'types/response/fieldNode';
import { SummaryOption, summaryTypeLabelMap, SummaryTypes } from './types';

const noneOption: SummaryOption = {
  label: summaryTypeLabelMap[SummaryTypes.none],
  type: SummaryTypes.none,
};

const fallbackOptions: SummaryOption[] = [
  noneOption,
  {
    label: summaryTypeLabelMap[SummaryTypes.empty],
    type: SummaryTypes.empty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.filled],
    type: SummaryTypes.filled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.unique],
    type: SummaryTypes.unique,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentEmpty],
    type: SummaryTypes.percentEmpty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentFilled],
    type: SummaryTypes.percentFilled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentUnique],
    type: SummaryTypes.percentUnique,
  },
];

export const numericOptions: SummaryOption[] = [
  noneOption,
  {
    label: summaryTypeLabelMap[SummaryTypes.sum],
    type: SummaryTypes.sum,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.average],
    type: SummaryTypes.average,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.median],
    type: SummaryTypes.median,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.min],
    type: SummaryTypes.min,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.max],
    type: SummaryTypes.max,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.range],
    type: SummaryTypes.range,
  },
];

const booleanOptions: SummaryOption[] = [
  noneOption,
  {
    label: summaryTypeLabelMap[SummaryTypes.unchecked],
    type: SummaryTypes.unchecked,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.checked],
    type: SummaryTypes.checked,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentUnchecked],
    type: SummaryTypes.percentUnchecked,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentChecked],
    type: SummaryTypes.percentChecked,
  },
];

const attachmentsOptions: SummaryOption[] = [
  noneOption,
  {
    label: summaryTypeLabelMap[SummaryTypes.empty],
    type: SummaryTypes.empty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.filled],
    type: SummaryTypes.filled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentEmpty],
    type: SummaryTypes.percentEmpty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentFilled],
    type: SummaryTypes.percentFilled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.totalAttachmentSize],
    type: SummaryTypes.totalAttachmentSize,
  },
];


const accountOptions: SummaryOption[] = [
  noneOption,
  {
    label: summaryTypeLabelMap[SummaryTypes.empty],
    type: SummaryTypes.empty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.filled],
    type: SummaryTypes.filled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentEmpty],
    type: SummaryTypes.percentEmpty,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentFilled],
    type: SummaryTypes.percentFilled,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.unique],
    type: SummaryTypes.unique,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.percentUnique],
    type: SummaryTypes.percentUnique,
  },
];


const dateOptions: SummaryOption[] = [
  ...fallbackOptions,
  {
    label: summaryTypeLabelMap[SummaryTypes.earliestDate],
    type: SummaryTypes.earliestDate,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.latestDate],
    type: SummaryTypes.latestDate,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.dateRangeDays],
    displayLabel: 'RNG',
    type: SummaryTypes.dateRangeDays,
  },
  {
    label: summaryTypeLabelMap[SummaryTypes.dateRangeMonths],
    type: SummaryTypes.dateRangeMonths,
  },
];

export function getSummaryOptionsForColumnType(type: FieldType, fallback = fallbackOptions) {
  switch (type) {
    case FieldType.Float:
    case FieldType.Integer:
    case FieldType.Currency:
    case FieldType.Rating:
    case FieldType.Percent: {
      return [
        ...numericOptions,
        ...fallbackOptions.slice(1),
      ];
    }
    case FieldType.Boolean: {
      return booleanOptions;
    }
    case FieldType.Attachment: {
      return attachmentsOptions;
    }
    case FieldType.Account: {
      return accountOptions;
    }
    case FieldType.Date: {
      return dateOptions;
    }
    default: {
      return fallback;
    }
  }
}
