import React, { useCallback } from 'react';

import {
  RichTextEditorComponent,
  QuickToolbar,
  Toolbar,
  Inject,
  Link,
  HtmlEditor,
  NodeSelection,
} from '@syncfusion/ej2-react-richtexteditor';
import { getTextNodesUnder } from 'components/AgGrid/utils';
import { RoleNames } from 'data/users/users.types';
import * as keys from 'keycode-js';
import { getUserRole } from 'utilities/permissions';

import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';

const HYPERLINK_DIALOG_CLASS = '.e-rte-link-dialog';
const DIALOG_CONTENT_CLASS = '.e-dlg-content';
const ROOT_ELEMENT_ID = 'root';

/**
 * Detach HTML node from its parent and attach it into a new node
 * Like cut and paste for Node HTML elements
 * @param element - node is going to be moved
 * @param destNode - destination node
 */
function moveNode(element, destNode) {
  element?.parentNode?.removeChild(element);
  destNode?.appendChild(element);
}

/**
 * Checks if Enter or Return key was pressed
 *
 * @param event - Keyboard event
 * @returns
 */
function isEnterPressed(event): boolean {
  return event.keyCode === keys.KEY_ENTER || event.keyCode === keys.KEY_RETURN;
}

const SingleLineOfTextEditor = React.forwardRef(
  (
    props: FieldEditorProps,
    ref: React.RefObject<RichTextEditorComponent>,
  ): JSX.Element => {
    const {
      onChange,
      eGridCell,
      onStopEditing,
      onBlur,
      keyPress = 0,
      readOnly,
      lock,
      value,
      api,
      node,
      colDef,
      permissions,
      id,
    } = props;

    if (!ref) {
      ref = React.useRef(null);
    }

    const handleKeyUp = (e) => {
      handleOnChange();
      if (isEnterPressed(e) && e.shiftKey && eGridCell) {
        onStopEditing?.();
        api?.clearRangeSelection();

        if (node && !node.lastChild) {
          api?.setFocusedCell(
            node.rowIndex + 1,
            colDef.colId,
            'bottom',
          );
        }
      }
    };

    const blockEnterKey = useCallback((event) => {
      if (!eGridCell && isEnterPressed(event)) {
        event.preventDefault();
        event.stopPropagation();
      }
    }, []);

    const onCreate = () => {
      const contentElement = ref.current?.element;
      contentElement?.addEventListener('keyup', handleKeyUp);
    };

    const onDestroyed = () => {
      ref.current?.hideInlineToolbar();
      ref.current?.element?.removeEventListener('keyup', handleKeyUp);
    };

    const handleOnChange = (): void => {
      if (permissions?.includes('items:update')) {
        const newContent = ref.current?.sanitizeHtml(ref.current?.inputElement.innerHTML) ?? '';
        onChange(newContent);
      }
    };

    const handleOnBlur = (): void => {
      if (eGridCell) {
        onStopEditing();
      } else {
        onBlur?.();
        ref.current?.element.addEventListener('keydown', blockEnterKey);
      }
      ref.current?.hideInlineToolbar();
    };

    const handleToolbarClick = (): void => {
      handleOnChange();
    };

    const handleFocus = (): void => {
      const element = ref.current?.element.lastChild;
      const textNodes: Array<ChildNode> = getTextNodesUnder(document, element);
      const lastTextNode: ChildNode = textNodes[textNodes.length - 1];

      if (lastTextNode) {
        const selectionCursor = new NodeSelection();
        const range = document.createRange();
        const width = ref.current?.element.lastElementChild?.firstElementChild?.firstElementChild?.clientWidth ?? 0;
        ref.current?.element.lastElementChild?.firstElementChild?.scroll?.(width, 0);

        range.setStart(lastTextNode, lastTextNode?.textContent?.length ?? 0);
        selectionCursor.setRange(document, range);
        if (!eGridCell) {
          ref.current?.selectAll();
        }
      }

      // When single line of text is inside the detailed modal
      // block the enter/return key in order to avoid break lines
      if (!eGridCell) {
        ref.current?.element.addEventListener('keydown', blockEnterKey);
      }
    };

    const handleDialogOpen = () => {
      if (!eGridCell) return;

      const rteDialog = document.querySelector(HYPERLINK_DIALOG_CLASS) as HTMLElement;
      const rteDialogContent = rteDialog.querySelector(DIALOG_CONTENT_CLASS) as HTMLElement;
      rteDialogContent.style.overflow = 'hidden';

      /**
       * Extract and take the editor link's modal from the cell
       * and append it into the app's root element
       * in this way we avoid the modal cropped by the grid overflowing
       */
      moveNode(rteDialog, document.getElementById(ROOT_ELEMENT_ID));

      // Placing the hyperlink modal close to the cell
      const { x: xRD, y: yRD, width: wRD, height: hRD } = rteDialog?.getClientRects()[0];
      const { innerHeight, innerWidth } = window;

      const centerX = (innerWidth - wRD) / 2;
      const centerY = (innerHeight - hRD) / 2;
      const translateX = centerX - xRD;
      const translateY = centerY - yRD;
      rteDialog.style.transform = `translate(${translateX}px, ${translateY}px)`;
    };

    if (eGridCell) {
      React.useEffect(() => {
        if (props.charPress) {
          onChange(props.charPress);
        }

        if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
          onChange('');
        }

        return () => {
          ref.current?.hideInlineToolbar();
        };
      }, []);
    }

    const rteId = eGridCell ? `txt-${colDef?.colId?.replace('.', '-')}-${node?.data?.rowIndex}` : `txt-${id}`;
    const userRole: RoleNames = getUserRole(permissions ?? []);
    const isReadOnly = userRole === RoleNames.Administrator ? false : readOnly || lock;

    return (
      <RichTextEditorComponent
        className={`rte-single-text-${eGridCell ? 'grid': 'detailed'}`}
        created={onCreate}
        destroyed={onDestroyed}
        blur={handleOnBlur}
        change={handleOnChange}
        focus={handleFocus}
        toolbarClick={handleToolbarClick}
        dialogOpen={handleDialogOpen}
        id={rteId}
        inlineMode={{
          enable: true,
          onSelection: true,
        }}
        readonly={isReadOnly}
        ref={ref}
        toolbarSettings={{
          items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'CreateLink'],
        }}
        value={value}
      >
        <Inject services={[Link, QuickToolbar, HtmlEditor, Toolbar]} />
      </RichTextEditorComponent>
    );
  },
);

export default wrapCellEditor()(SingleLineOfTextEditor);
