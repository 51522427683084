import { getApiV2Url, getOrigin } from '../env';

export const buildURI = (type, data: {
  _id: string;
  workspaceID: string;
  databaseID: string;
  sheetID: string;
  url?: string;
}, options?: {
  type: string;
}): string => {
  switch (type) {
    case 'workspaces': {
      return `${getApiV2Url(`/workspaces/${data._id}`)}/workspaces/${data._id}`;
    }
    case 'workspacesLinkURI': {
      return `${getOrigin()}/workspaces/${data._id}/files`;
    }
    case 'databases': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/databases/${data._id}`;
    }
    case 'taskdbs': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/taskdbs/${data._id}`;
    }
    case 'databasesLinkURI': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/databases/${data._id}/sheets/default/items`;
    }
    case 'taskdbsLinkURI': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/taskdbs/${data._id}/tasksheets/default/items`;
    }
    case 'fields': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/databases/${data.databaseID}/sheets/${data.sheetID}/fields/${data._id}?api_version=2`;
    }
    case 'view': {
      return `${getOrigin()}/workspaces/${data.workspaceID}/databases/${data.databaseID}/sheets/${data.sheetID}/views/${data._id}?api_version=2`;
    }
    case 'workspaceMenuItem_files': {
      return `${getOrigin()}/workspaces/${data._id}/files`;
    }
    case 'workspaceMenuItem_filemanager': {
      return `${getOrigin()}/workspaces/${data._id}/filemanager`;
    }
    case 'workspaceMenuItem_organizer': {
      return `${getOrigin()}/workspaces/${data._id}/organizer`;
    }
    case 'workspaceMenuItem_databases': {
      return `${getOrigin()}/workspaces/${data._id}/${options && options.type === 'databases' ? options.type : 'taskdbs'}`;
    }
    case 'workspaceMenuItem_messages': {
      return `${getOrigin()}/workspaces/${data._id}/messages`;
    }
    case 'workspaceMenuItem_people': {
      return `${getOrigin()}/workspaces/${data._id}/accounts`;
    }
    case 'workspaceMenuItem_activity': {
      return `${getOrigin()}/workspaces/${data._id}/activityfeeds/me`;
    }
    case 'workspaceMenuItem_analytics': {
      return `${getOrigin()}/workspaces/${data._id}/analytics`;
    }
    case 'bookmark': {
      return `${getOrigin()}${data.url}`;
    }
  }
  return '';
};
