import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Schema } from 'types/schema';

import { RowNode } from '../../AgGrid/AgGridApi';

import { withStyles, WithStyles, MenuItem } from '@material-ui/core';

import { FieldType as NodeFieldType } from 'types/response/fieldNode';
import { FieldData } from 'components/Fields/data';
import { UpdateRecordsOptions } from 'data/collections/collections.actions.new';
import { parse } from 'qs';
import { getCollectionUrl } from 'utilities/createUrl';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import Popover from '@material-ui/core/Popover';
import { Variant as ButtonVariant } from 'components/Button';
import Button from 'components/Button';
import Modal from 'components/Modals/Modal';
import ModalContent from 'components/Modals/Modal/Content';
import ModalHeader from 'components/Modals/Modal/Header';
import { DropdownIcon } from 'components/NodeFieldData/choice/ChoiceEditor.style';
import { ButtonFieldActions } from 'components/NodeFieldData/button/types';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';
import { composeControls, Control, getSchemaColumns } from 'data/collections/collections.selectors';
import * as workspaceCreateActions from 'data/workspaceCreate/actions';
import 'styles/components/bulk-update.scss';
import { State as RemindersState, Reminder as ReminderType } from 'data/reminders/reminders';
import _ from 'lodash';
import { CommonNode } from '../../../types/response';
import { editorFactory } from '../../Modals/NodeView/componentsFactory';
import Typography, { Variant as TypographyVariant } from '../../Typography';
import { styles } from './BulkUpdate.style';

interface OwnProps {
  schema: Schema;
  fieldIds?: string[];
  onClose: () => void;
  updateRecords: (options: UpdateRecordsOptions) => void;
  selectedRows: RowNode<CommonNode>[];
  reminders: RemindersState;
  currentSheetId: string;
  permissions: string[];
  bulkChangeReminders: (payload) => void;
  bulkCreateWorkspaces: any;

}

interface State {
  controls: Control[];
  selectedControl?: Control;
  selectedField: string;
  value: boolean | string | number | [];
  isUpdating: boolean;
  isDropdownOpen: boolean;
  searchValue: string;
  changedData: boolean;
  bulkReminders: Array<ReminderType>;
}

interface DispatchProps {
  createWorkspaceProcess: typeof workspaceCreateActions.createWorkspaceProcess;
  createEngagementLetterProcess: typeof workspaceCreateActions.createEngagementLetterProcess;
  confirmOrganizerProcess: typeof workspaceCreateActions.confirmOrganizerProcess;
}

interface FormData {
  name: string;
  data: CommonNode;
  value: boolean | string | number | [];
  fieldName: string;
  fieldType: NodeFieldType;
  fieldId: string;
  meta?: FieldData;
  autoFocus: boolean;
  onChange: (value: string) => void;
  from: From;
  bulkReminders?: Array<ReminderType>;
}

export enum From {
  BULK_UPDATE
}

type Props = OwnProps & WithStyles<typeof styles> & URLInjectedProps & DispatchProps;

export class BulkUpdate extends React.Component<Props, State> {
  private wrapper: HTMLDivElement | null = null;
  private skippedControls: NodeFieldType[] = [NodeFieldType.Attachment];

  state = {
    controls: [],
    selectedField: '',
    value: '',
    isUpdating: false,
    isDropdownOpen: false,
    searchValue: '',
    changedData: false,
    bulkReminders: [],
  } as State;

  public componentDidMount(): void {
    this.setFields();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (!_.isEqual(prevProps.schema.properties.fields, this.props.schema.properties.fields)) {
      this.setFields();
    }
  }

  public render(): JSX.Element {
    return (
      <Modal onClose={this.props.onClose}>
        <ModalHeader onClose={this.props.onClose}>Bulk Action</ModalHeader>
        <ModalContent>
          <div className={this.props.classes.field}>
            <div className={this.props.classes.fieldLabel}>
              <Typography variant={TypographyVariant.FieldLabel}>
                Modify Field
              </Typography>
            </div>
            <div>
              <div onClick={this.toggleDropdown} className="bulk-update-dropdown" ref={wrapper => this.wrapper = wrapper}>
                <div className="bulk-update-dropdown-placeholder">
                  {(this.state.selectedField && this.getLabel(this.state.selectedField)) || 'Select field'}
                </div>
                <DropdownIcon />
              </div>
              <Popover
                id="bulkUpdate"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={this.wrapper}
                open={this.state.isDropdownOpen}
                onClose={this.closeDropdown}
                className="bulk-update-popover"
              >
                <SearchBar
                  className="hubsync-menu-searchbar"
                  placeholder="Find a Field"
                  value={this.state.searchValue}
                  size={SearchBarSize.Small}
                  onChange={this.onSearchChange}
                  autoFocus
                />
                {this.state.controls
                  .filter((control: Control) => control.name.toLowerCase().includes(this.state.searchValue.toLowerCase()))
                  .map((control: Control) => {
                    return (
                      <MenuItem key={control.id} onClick={() => this.onClickMenu(control.id)}>
                        {control.name}
                      </MenuItem>
                    );
                  })}
              </Popover>
            </div>
          </div>
          {this.state.selectedField && (
            <div className={this.props.classes.field}>
              <div className={this.props.classes.fieldLabel}>
                <Typography variant={TypographyVariant.FieldLabel}>
                  Set Value To
                </Typography>
              </div>
              <div className={this.props.classes.fieldWrapper}>
                {this.state.selectedField && this.state.selectedControl && editorFactory(this.getRowData())}
              </div>
            </div>
          )}
        </ModalContent>
        <div className={this.props.classes.divider} />
        <div className={this.props.classes.actionButtons}>
          <Button label="Cancel" variant={ButtonVariant.SecondaryLink} onClick={this.props.onClose} />
          <Button
            disabled={this.state.isUpdating || !this.state.selectedField}
            label={this.state.isUpdating ? 'Updating...' : 'Update'}
            variant={ButtonVariant.Primary}
            onClick={this.updateRecords}
          />
        </div>
      </Modal>
    );
  }

  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };

  private toggleDropdown = (): void => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  private closeDropdown = (): void => {
    this.setState({ isDropdownOpen: false, searchValue: '' });
  };

  private onClickMenu = (id: string): void => {
    this.closeDropdown();
    this.onFieldSelect(id);
  };

  private getLabel = (id: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const control: Control = this.state.controls.find((control: Control) => control.id === id)!;
    return control.name;
  };

  private getControl = (id: string): Control => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.state.controls.find((control: Control) => control.id === id)! as Control;
  };

  private getFieldType = (id: string): NodeFieldType => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const control: Control = this.state.controls.find((control: Control) => control.id === id)!;
    return control.fieldType;
  };

  private getMetadata = (): FieldData => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const control: Control = this.state.selectedControl!;
    return control.meta;
  };

  private getRowData = (): FormData => {
    return {
      data: this.props.selectedRows[0].data,
      name: this.getLabel(this.state.selectedField),
      value: this.state.changedData ? this.state.value : this.getRowsValue(),
      fieldType: this.getFieldType(this.state.selectedField),
      fieldName: this.getLabel(this.state.selectedField),
      fieldId: this.state.selectedField,
      onChange: this.onFieldValueChange,
      meta: this.getMetadata(),
      autoFocus: true,
      from: From.BULK_UPDATE,
      bulkReminders: this.state.bulkReminders,
    };
  };

  private getRowsValue = (): any => {
    const fieldId = this.state.selectedField.replace('fields.', '');
    const allRowsValues = this.props.selectedRows.map((row: RowNode<CommonNode>): any => {
      if (!row.data.fields || !row.data.fields[fieldId]) return '';
      return JSON.stringify(row.data.fields[fieldId]);
    });
    const sameValues = allRowsValues.every(value => allRowsValues[0] === value);
    if (sameValues && this.props.selectedRows && this.props.selectedRows[0] && this.props.selectedRows[0].data.fields && this.props.selectedRows[0].data.fields[fieldId]) {
      return this.props.selectedRows[0].data.fields[fieldId];
    }
  };

  private onFieldValueChange = (value: string): void => {
    this.setState({ value, changedData: true });
  };

  private onFieldSelect = (value: string): void => {
    this.setState({
      value: '',
      selectedField: value,
      selectedControl: this.getControl(value),
    });
  };

  private setFields = (): void => {
    const { schema, fieldIds, permissions } = this.props;
    const columns = getSchemaColumns(schema);
    const keys = fieldIds || Object.keys(columns);
    const keyIds = keys.filter(key => key.includes('fields.'));
    const controls = composeControls(columns, keyIds, permissions);
    const filteredControls = controls.filter((control: Control) =>
      !this.skippedControls.includes(control.fieldType) &&
      !control.readOnly,
    );

    this.setState({
      controls: filteredControls,
    }, () => {
      if (this.state.selectedField) {
        this.setState({
          selectedControl: this.getControl(this.state.selectedField),
        });
      }
    });
  };

  private updateRecords = (): void => {
    this.setState({
      isUpdating: true,
    });

    const { selectedControl } = this.state;
    const rowIds = this.props.selectedRows.map((row: RowNode<CommonNode>): string => row.data.id);

    /** trigger Actions instead of editing rows in groups*/
    if (selectedControl?.fieldType === NodeFieldType.Button) {
      this.triggerBulkAction(selectedControl.meta.action ?? '', rowIds);

    /** Regular editing rows in groups */
    } else {
      const field = this.state.selectedField;
      const { location } = this.props;
      const { viewId, ...queryParams } = parse(location.search, { ignoreQueryPrefix: true });
      const updateGridParams = {
        url: getCollectionUrl(location.pathname),
        selectViewId: viewId,
        queryParams,
      };

      this.props.updateRecords({
        payload: this.state.changedData ? this.formatValue(this.state.value) : this.getRowsValue(),
        field: this.state.selectedField,
        items: this.props.selectedRows.map((row: RowNode<CommonNode>): string => row.data.id),
        locationData: updateGridParams,
      });

      const remindersPayload = this.prepareRemindersPayload({
        rowIds,
        field,
      });

      if (remindersPayload._changed) {
        this.props.bulkChangeReminders(remindersPayload);
      }
    }

    setTimeout(() => {
      this.setState({
        isUpdating: false,
      }, this.props.onClose);
    }, 1000);
  };   // Finish update records

  private triggerBulkAction(action: string, ids: string[]) {
    switch (action) {
      case ButtonFieldActions.CREATE_WORKSPACE:
        this.props.createWorkspaceProcess({ ids });
        break;
      case ButtonFieldActions.CREATE_ENGAGEMENT_LETTER:
        this.props.createEngagementLetterProcess({ ids });
        break;
      case ButtonFieldActions.CREATE_TAX_ORGINIZER:
        this.props.confirmOrganizerProcess({ ids });
        break;
    }
  }

  private prepareRemindersPayload = ({ rowIds, field }): any => {
    return { rows: rowIds, reminders: this.state.bulkReminders, _changed: this.state.bulkReminders.length ? true : false };
  };


  private formatValue = (input: any): string => {
    if (input['timestamp']) return input['timestamp'];
    return input;
  };
}

const mapDispatch: DispatchProps = {
  createWorkspaceProcess: workspaceCreateActions.createWorkspaceProcess,
  createEngagementLetterProcess: workspaceCreateActions.createEngagementLetterProcess,
  confirmOrganizerProcess: workspaceCreateActions.confirmOrganizerProcess,

};

const enchance = compose(
  withStyles(styles),
  withURLParams,
  connect(null, mapDispatch),
);

export default enchance(BulkUpdate);
