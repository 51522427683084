import * as React from 'react';

import AddAttachmentsModal from 'components/NodeFieldData/attachment/AddAttachmentsModal';
import ModalAttachmentItem from 'components/NodeFieldData/attachment/ModalAttachmentItem';
import FileUploader from 'components/NodeFieldData/attachment/uploaders/FileUploaderForModals';
import * as actions from 'data/attachments/actions';
import { RoleNames } from 'data/users/users.types';
import update from 'immutability-helper';
import { SortableContainer } from 'react-sortable-hoc';
import * as Colors from 'styles/colors';
import { FileAttachment } from 'types/common';
import { CommonNode } from 'types/response';
import { TenantNode } from 'types/response/tenantNode';
import { CollectionTypes, PermissionTypes } from 'types/schema';
import { getUserRole } from 'utilities/permissions';


import PreviewEditor from './AttachmentPreviewEditor';
import { AttachmentType } from './constants';

interface Props {
  attachments: any[];
  onAdd: (files: FileAttachment[]) => void;
  onDelete: (index: number | string) => void;
  fieldId?: string;
  node?: CommonNode;
  modalType?: string;
  limit?: number;
  acceptTypes?: string;
  permissions: string[];
  readOnly?: boolean;
  collectionType: CollectionTypes;
  disableAutoAdd?: boolean;
  showPreview?: boolean;
  lock?: boolean;
  onChangeSort?: typeof actions.changeSortAttachments;
  openPreviewCarousel?: typeof actions.openPreviewAttachments;
  tenant?: TenantNode;
}

interface State {
  modalOpen: boolean;
  isFocus: boolean;
}

const containerStyle = {
  maxHeight: '150px',
  overflowY: 'auto',
  padding: '4px 0',
  borderBottom: `1px dashed ${Colors.lightBlueGrey}`,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
} as React.CSSProperties;

const style = {
  root: {
    height: '100%',
    padding: 0,
    color: Colors.slateTwo,
    border: `1px solid ${Colors.lightBlueGrey}`,
  },
  attachmentContainer: {
    ...containerStyle,
  },
  previewContainer: {
    ...containerStyle,
    maxHeight: 300,
    padding: '8px 7px 0 8px',
  },
  focused: {
    border: `1px solid ${Colors.hubsyncBlue}`,
  },
};

/**
 * Checks if user is able to edit and remove files
 *
 * @param permissions - list of user's permission in the current view
 */
function permissionCheck(permissions: string[] = []): boolean {
  return (permissions || []).includes(
    `${CollectionTypes.files}:${PermissionTypes.update}`,
  );
}

const ThumbnailSortList = SortableContainer(
  ({
    attachments,
    modalType,
    allowDownload,
    permissions,
    readOnly,
    lock,
    handleDeleteAttachment,
    handleThumbnailClick,
  }) => {
    const canEdit = permissionCheck(permissions);

    return (
      <div style={style.previewContainer}>
        {attachments.map((file, index) => {
          const title = file.title || file;
          const id = file._id || `${file}.${index}`;

          return (
            <PreviewEditor
              fileName={title}
              attachment={file}
              onDelete={handleDeleteAttachment(id)}
              isPreload={modalType === AttachmentType.CREATE}
              allowDownload={allowDownload}
              thumbnailClick={handleThumbnailClick(index)}
              permissions={permissions}
              readOnly={readOnly || !canEdit}
              key={id}
              index={file.sort || index}
              lock={lock}
            />
          );
        })}
      </div>
    );
  },
);

class ModalAttachmentsEditor extends React.Component<Props, State> {
  public state: State = {
    modalOpen: false,
    isFocus: false,
  };

  public render = (): JSX.Element => {
    const {
      attachments,
      limit,
      acceptTypes,
      onAdd,
      showPreview,
      permissions,
      readOnly,
      lock,
      tenant,
    } = this.props;
    const { modalOpen } = this.state;
    const canEdit = permissionCheck(permissions);
    const featurePreview = !!(tenant?.thirdParty?.filestack?.enable);
    const showThumbnails = featurePreview && showPreview;


    return (
      <div
        style={ this.state.isFocus ? style.focused : style.root}
        onFocus={() => this.setState({ isFocus: true })}
        onBlur={() => this.setState({ isFocus: false })}
      >
        {attachments.length > 0 &&
          (!showThumbnails ? (
            <div style={style.attachmentContainer}>
              {this.renderAttachments()}
            </div>
          ) : (
            <ThumbnailSortList
              {...this.props}
              readOnly={readOnly || !canEdit}
              handleThumbnailClick={this.handleThumbnailClick}
              allowDownload
              lock={lock}
              handleDeleteAttachment={this.handleDeleteAttachment}
              axis={'xy'}
              useDragHandle
              transitionDuration={200}
              onSortEnd={this.onDropThumbnail}
            />
          ))}
        {((permissions || []).includes(
          `${this.props.collectionType}:create`,
        ) ||
          (permissions || []).includes(
            `${this.props.collectionType}:update`,
          )) &&
          (!limit || attachments.length < limit) && (
          <FileUploader
            lock={lock}
            readOnly={readOnly || !canEdit}
            permissions={permissions}
            filesAttachedCounter={attachments.length}
            onAdd={(attachments) => {
              onAdd(attachments);
              this.closeModal();
            }}
            onClick={this.openModal}
            acceptTypes={acceptTypes}
          />
        )}
        {modalOpen && (
          <AddAttachmentsModal
            onClose={this.closeModal}
            permissions={permissions}
            disableAutoAdd={this.props.disableAutoAdd}
            onAdd={(attachments) => {
              onAdd(attachments);
              this.closeModal();
            }}
            acceptTypes={acceptTypes}
            tenant={tenant}
          />
        )}
      </div>
    );
  };

  private renderAttachments = (): React.ReactNode => {
    const { attachments, modalType, readOnly, lock, permissions, tenant } = this.props;
    const displayPreview = !!(tenant?.thirdParty?.filestack?.enable);
    const canEdit = permissionCheck(permissions);

    return attachments.map((file, i) => {
      if (modalType === AttachmentType.UPDATE) {
        return (
          <ModalAttachmentItem
            attachment={file}
            key={`${file.title}${i}`}
            fileName={file.title}
            readOnly={readOnly || !canEdit}
            onDelete={this.handleDeleteAttachment(file._id)}
            permissions={permissions}
            lock={lock}
            displayPreview={displayPreview}
            onClick={this.handleThumbnailClick(i)}
            type={modalType}
          />
        );
      }
      return (
        <ModalAttachmentItem
          key={`${file}${i}`}
          fileName={file}
          onDelete={this.handleDeleteAttachment(i)}
          permissions={permissions}
          displayPreview={false}
          type={modalType}
        />
      );
    });
  };

  private handleDeleteAttachment = (index: number | string) => (): void =>
    this.props.onDelete(index);

  private openModal = (): void => this.setState({ modalOpen: true });

  public closeModal = (): void => this.setState({ modalOpen: false });

  private onDropThumbnail = ({ oldIndex, newIndex }) => {
    const { attachments } = this.props;
    if (oldIndex !== newIndex) {
      const fromIndex = attachments.findIndex((item) => item.sort === oldIndex);
      const toIndex = attachments.findIndex((item) => item.sort === newIndex);
      const dragItem = attachments[fromIndex] || attachments[oldIndex];
      // changes attachments position
      const sortedAttachments = update(attachments, {
        $splice: [
          [fromIndex >= 0 ? fromIndex : oldIndex, 1],
          [toIndex >= 0 ? toIndex : newIndex, 0, dragItem],
        ],
      });
      const filesPayload = sortedAttachments.map((item, index) => ({
        attachment: { sort: index, version: item.version },
        id: item._id,
        workspaceID: item.workspaceID,
      }));

      // Updates attachments arrangement
      this.props.onChangeSort?.({
        sortItems: filesPayload,
        workspaceID: dragItem.workspaceID,
        itemID: dragItem.references.itemID,
      });
    }
  };

  private handleThumbnailClick = (index: number) => ((event): void => {
    event.stopPropagation();
    const { openPreviewCarousel, lock, permissions, node, fieldId } = this.props;
    const isReader = getUserRole(permissions) === RoleNames.Reader;
    const lockNotAllow = lock && !permissions.includes('fields:lock');
    const canModify = !isReader && !lockNotAllow;

    if (node && fieldId) {
      openPreviewCarousel?.({
        index,
        fieldId,
        node: node,
        enableEdit: canModify,
      });
    }
  });
}

export default ModalAttachmentsEditor;
