import * as React from 'react';

import './style.scss';

export default function LoadingIndicator(): JSX.Element {
  return (
    <div className="loading-indicator">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
