import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import HeaderBar from 'components/HeaderBar/LoggedIn';
import Modals from 'components/Modals';
import NavigationPane from 'components/NavigationPane';
import ThemeProvider from 'components/ThemeProvider';
import Routes from 'routes';
// import auth from 'utilities/auth';
import PropertiesModal from 'components/PropertiesModal/PropertiesModal';
import { applyTheme } from 'styles/theme';
import { fetchTenantData } from 'data/app/constants';
import { getAllBookmarks } from 'data/bookmarks/actions';
import AppErrorBoundary from 'components/ErrorBoundary/AppErrorBoundary';
// import { AccountNode } from 'types/response/accountNode';
import { TenantNode } from 'types/response/tenantNode';
import { TreeNode } from 'types/response/http/getNode';
import { navigationTreeSelector, tenantSelector } from 'data/app/selectors';
import { SetInactivityTimeOut, checkInactivity } from 'utilities/inactivityTimeout';
import { State as ReduxState } from 'reducers';
import CustomDragLayer from 'components/CustomDragLayer';
import { HEADER_HEIGHT, LEFTHAND_SIDEBAR_WIDTH } from 'styles/constants';
import LogOut from 'pages/LogOut/render';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
// import { getIdToken } from 'env';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'typeface-roboto/index.css';
import '../../styles/base.scss';
import { Auth as AmplifyAuth, Hub } from 'aws-amplify';
import { fetchUser } from 'data/users/users.actions';
import '@aws-amplify/ui/dist/style.css';
import { Bookmark } from 'types/schema';
import { bookmarks } from 'data/bookmarks/bookmarks.selectors';
import { getAllWorkspaces } from 'data/workspaces/workspaces.selectors';
import { buildURI } from '../../utilities/buildApiURI';
import Auth from '../../pages/Auth/Auth';
import { WorkspaceWithTitle } from 'types/response/workspaceNode';
import { wsConnect, wsClose } from 'data/ws';
import { LockingLoading } from 'components/LockingLoading';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../../constants';

interface StateProps {
  tenant?: TenantNode;
  // user?: AccountNode;
  user: any;
  navigationTree?: TreeNode;
  validCredentials: boolean;
  bookmarkPermissions: string[];
  bookmarks: Bookmark[];
  workspaces: WorkspaceWithTitle[];
}

interface DispatchProps {
  fetchBookmarks(): void;
  fetchTenantData(): void;
  fetchUser(): void;
}

type Props =
  & StateProps
  & DispatchProps
  & WithStyles<ClassKey>
  & URLInjectedProps
  ;

interface State {
  menuOpen: boolean;
  themeApplied: boolean;
  anchorEl?: HTMLElement;
  notificationsAnchorEl?: HTMLElement;
  authenticated?: boolean;
  showTerms: boolean;
  ssoUser: boolean;
  showMFA: boolean;
}

class App extends React.Component<Props, State> {
  public state: State = {
    menuOpen: false,
    themeApplied: false,
    authenticated: undefined,
    showTerms: false,
    ssoUser: false,
    showMFA: false,
  };

  public componentDidMount(): void {
    const urlParams = new URLSearchParams(document.URL);
    if (urlParams.get('debug') === 'true') {
      document.body.classList.add('debug-mode');
    }

    const activityCheck = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK));
    if (activityCheck && new Date().getTime() >= activityCheck) {
      localStorage.clear();
    }

    if (this.props.location.pathname === '/logout') {
      this.setTheme();
      this.handleLogout();
      this.setState({ authenticated: false });
      return;
    }

    this.initialize();
  }

  public componentDidUpdate(prevProps: Props) {
    const { user, tenant } = this.props;
    if (user && user.id !== prevProps.user.id) {
      this.setTheme();
      this.isSSO(tenant, user);
    }
  }

  async handleLogout(): Promise<void> {
    await AmplifyAuth.signOut();
  }

  async setAuthState(): Promise<void> {
    let user = null;
    try {
      user = await AmplifyAuth.currentAuthenticatedUser();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('User:', e);
    }
    this.setState({ authenticated: user ? true : false });
    if (user) {
      wsConnect(() => {
        // eslint-disable-next-line no-console
        console.log('WebSocket connection established');
      }).then();
      SetInactivityTimeOut();
    }
  }

  handleAuthStateChange() {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        this.setAuthState().then(() => {
          this.props.fetchUser();
        });
      }

      if (data.payload.event === 'signOut') {
        this.props.history.push('/login?reason=hubAuthSignOutEvent');
        wsClose().then();
      }
    });

    // redirect to the login page if there isn't user session
    Hub.listen('login', () => {
      // double check if there is any error with the user session
      AmplifyAuth.currentSession()
      // Display login view and force the user to login again
        .catch(() => {
        // reloading with no credentials and the store with default values
          window.location.reload();
        });
    });
  }

  async initialize(): Promise<void> {
    try {
      this.setTheme();
      this.handleAuthStateChange();
      await this.setAuthState();
      this.props.fetchTenantData();
      this.props.fetchBookmarks();
      if (this.state.authenticated) {
        this.checkUserSessionInactivity();
        this.props.fetchUser();
      }
    } catch (error) {
      console.error('Error initializing:', error);
    }
  }

  setTheme() {
    applyTheme(this.props.tenant);
    this.setState({ themeApplied: true });
  }

  openNavigation = () => {
    this.setState({ menuOpen: true });
  };

  closeNavigation = () => {
    this.setState({ menuOpen: false });
  };

  isSSO = (tenant, user): boolean => {
    const trustedDomains = tenant.authProvider?.identityProviders?.length
      ? tenant.authProvider?.identityProviders[0].trustedDomains
      : [];
    const emailDomain = user.email.split('@')[1];
    const matchDomain = trustedDomains.find((domain) => domain === emailDomain);
    const isSSOUser = matchDomain? true : false;

    this.setState({ ssoUser: isSSOUser }, () => {
      this.showTermsAndConditions(user);
    });

    return isSSOUser;
  };

  showTermsAndConditions = (user): boolean => {
    const acceptedTerms = user?.termsAndConditions?.accepted;
    const acceptedTermsDate = user?.termsAndConditions?.date;
    const acceptedTermsYear = moment(acceptedTermsDate).year();
    const currentYear = moment().year();
    const matchYear = currentYear === acceptedTermsYear;
    const enable = this.state.ssoUser ? false : !acceptedTerms
      ? true : !matchYear ? true : false;

    if (enable) {
      this.setState({ showTerms: false });
    }

    return false;
  };


  validCredentialsContent = (tenant: TenantNode) => {
    const { user } = this.props;
    const contentClassname = cn({
      [this.props.classes.content]: true,
      [this.props.classes.menuOpened]: this.state.menuOpen,
      'hubsync-content': true,
    });

    const bookmarks = this.props.bookmarks.map(bookmark => ({
      id: bookmark.id,
      title: bookmark.title,
      nodeType: 'bookmark',
      type: bookmark.model,
      uri: buildURI('bookmark', {
        _id: '',
        workspaceID: '',
        databaseID: '',
        sheetID: '',
        url: bookmark.url,
      }),
      isFolder: false,
    }));
    const workspaces = this.props.workspaces.map(workspace => ({
      id: workspace.id,
      title: workspace.meta?.clientName ? workspace.meta.clientName : workspace.title,
      nodeType: 'workspace',
      type: 'collection',
      uri: workspace.linkURI,
      isFolder: false,
    }));

    const navigationTree = { nodes: [
      { nodeType: 'bookmark', title: 'Favorites', type: 'collection', nodes: [] },
      { nodeType: 'workspace', title: 'Workspaces', type: 'collection', uri: '/workspaces', nodes: [] },
    ] };
    if (navigationTree && navigationTree.nodes) {
      // @ts-ignore
      navigationTree.nodes[0].nodes = bookmarks;
      // @ts-ignore
      navigationTree.nodes[1].nodes = workspaces;
    }
    return (
      <ThemeProvider theme={tenant.theme}>
        <div className={this.props.classes.wrapper}>
          <HeaderBar
            tenant={tenant}
            user={user}
            isNavigationMenuOpen={this.state.menuOpen}
            anchorEl={this.state.anchorEl}
            notificationsAnchorEl={this.state.notificationsAnchorEl}
            onToggleNavigationMenu={this.onToggleNavigationMenu}
            onOpenUserMenu={this.onOpenUserMenu}
            onCloseUserMenu={this.onCloseUserMenu}
            onOpenNotificationsMenu={this.onOpenNotificationsMenu}
            onCloseNotificationsMenu={this.onCloseNotificationsMenu}
          />
          <NavigationPane
            isOpened={this.state.menuOpen}
            navigationTree={(navigationTree || {}).nodes || []}
            bookmarks={this.props.bookmarks}
            permissions={this.props.bookmarkPermissions}
            onClose={this.closeNavigation}
          />
          <div id="hubsync-content" className={contentClassname}>
            <Routes homepageURI={tenant.homepageURI} />
          </div>
          <Modals />
          <PropertiesModal />
          <LockingLoading/>
        </div>
        <CustomDragLayer />
      </ThemeProvider>
    );
  };

  render() {
    const { authenticated, themeApplied } = this.state;
    const { tenant, location } = this.props;
    return (
      <AppErrorBoundary>
        { authenticated === undefined ? <></> :
          location.pathname.includes('/logout')
            ? <LogOut search={location.search} tenant={tenant} /> :
            !authenticated ? <Auth /> :
              themeApplied ? this.validCredentialsContent(tenant as any):
                <></>
        }
        <ToastContainer />
      </AppErrorBoundary>
    );
  }

  private onToggleNavigationMenu = (): void => {
    if (this.state.menuOpen) {
      this.closeNavigation();
    } else {
      this.openNavigation();
    }
  };

  private onOpenUserMenu = (anchorEl: HTMLElement): void => {
    this.setState({ anchorEl });
  };

  private onCloseUserMenu = (): void => {
    this.setState({ anchorEl: undefined });
  };

  private onOpenNotificationsMenu = (anchorEl: HTMLElement): void => {
    this.setState({ notificationsAnchorEl: anchorEl });
  };

  private onCloseNotificationsMenu = (): void => {
    this.setState({ notificationsAnchorEl: undefined });
  };

  private checkUserSessionInactivity = (): void => {
    const globalInactivity = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK));
    if (globalInactivity !== 0) {
      checkInactivity(globalInactivity);
    }
  };
}

function mapStateToProps(state: ReduxState): StateProps {
  return {
    tenant: tenantSelector(state),
    user: state.users.user,
    navigationTree: navigationTreeSelector(state),
    validCredentials: state.app.validCredentials,
    bookmarkPermissions: state.bookmarks.permissions,
    bookmarks: bookmarks(state),
    workspaces: getAllWorkspaces(state),
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchTenantData,
  fetchBookmarks: getAllBookmarks,
  fetchUser: fetchUser.request,
};

export type ClassKey =
  | 'wrapper'
  | 'content'
  | 'menuOpened'
  ;

const CONTENT_PADDING = '16px';

const styles = withStyles<ClassKey>({
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    overflowY: 'auto',
    paddingLeft: CONTENT_PADDING,
    paddingRight: CONTENT_PADDING,
    transition: 'padding-left 0.3s',
  },
  menuOpened: {
    paddingLeft: `calc(${LEFTHAND_SIDEBAR_WIDTH}px + ${CONTENT_PADDING})`,
  },
});

const enhance = compose<Props, {}>(
  styles,
  withURLParams,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(App);
