import React from 'react';

import PageHeader from 'components/PageHeader';
import { Title } from 'components/PageHeader/PageHeader.styles';
import { Wrapper } from 'pages/common/Styled';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import Dashboard from './Dasboards';
import { fetchAnalytics } from 'data/analytics/actions';
import { Analytics, Dashboard as DashboardType } from 'data/analytics/types';
import { debounce } from 'lodash';
import withStyles, { WithStyles, CSSProperties } from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';

interface State {
  value: number;
  height?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface StateProps {
  analytics?: Analytics;
  currentWorkspace: string | null;
}

interface DispatchProps {
  fetchAnalytics(): void;
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles>;

export class AnalyticsPage extends React.Component<Props, State> {
  private analyticsContainer: React.RefObject<HTMLDivElement> = React.createRef();
  state: State = {
    value: 0,
    height: 0,
  };

  componentDidMount() {
    if (this.props.currentWorkspace) {
      this.props.fetchAnalytics();
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    if (!this.state.height && this.analyticsContainer.current?.clientHeight) {
      this.updateAnalyticsHeight();
    }
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    this.setState({ value: newValue });
  };

  private updateAnalyticsHeight = debounce(() => {
    this.setState({ height: this.analyticsContainer.current?.clientHeight });
  }, 200);

  private handleResize = debounce(() => {
    this.setState({ height: 0 });
  }, 200);

  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  renderDashboard(item: any, index: string, serverUrl: string) {
    if ( this.state.height) {
      return (
        <Dashboard
          id={`dashboard-${index}`}
          dashboardId={item.id}
          rootUrl={serverUrl}
          workspace={this.props.currentWorkspace}
          height={this.state.height}
        />
      );
    }
  }

  renderAnalytics(value, dashboard: DashboardType, serverUrl) {
    return (
      <div ref={this.analyticsContainer} className={' analytics-container ' + this.props.classes.flex1}>
        {this.renderDashboard(dashboard, dashboard.id, serverUrl)}
      </div>
    );
  }

  public render(): JSX.Element {
    const { value } = this.state;
    const { analytics } = this.props;
    const { classes } = this.props;
    return (
      <Wrapper className={classes.fullContainer + ' ' + classes.AnalyticsFlex}>
        <PageHeader titleContent={<Title>Analytics</Title>} />
        {analytics ? (
          this.renderAnalytics(value, analytics.dashboard, analytics.serverUrl)
        ) : (
          <EmptyResource resource="Analytics" />
        )}
      </Wrapper>
    );
  }
}

function EmptyResource(props) {
  return <p>Loading {props.resource}...</p>;
}

function mapStateToProps(state: ReduxState): StateProps {
  return {
    analytics: state.analytics.analytics,
    currentWorkspace: state.workspaces.current,
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchAnalytics: fetchAnalytics,
};

type ClassKey =
  | 'fullContainer'
  | 'AnalyticsFlex'
  | 'flex1'
  ;

const styles: Record<ClassKey, CSSProperties> = {
  fullContainer: {
    height: '100%',
    width: '100%',
  },
  AnalyticsFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex1: {
    flex: '1',
    display: 'flex',
  },
};

const enhance = compose<Props>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(AnalyticsPage);

