import * as React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import Portal from 'components/ui/DatePicker/Portal';
import { AnchorLoader } from './LockingLoading.styles';
import { useSelector } from 'react-redux';
import { State as ReduxState } from 'reducers';


export const LockingLoading = () => {
  const lockingLoading = useSelector(
    (state: ReduxState) => state.ui.loading.lockingLoader,
  );

  return (
    <Portal>
      <AnchorLoader id={lockingLoading ? 'spinner' : 'spinner-deactivated'}>
        {lockingLoading ? (
          <LoadingIndicator />
        ) : (<></>)}
      </AnchorLoader>
    </Portal>

  );
};
