import withStyles, { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import * as keys from 'keycode-js';
import * as React from 'react';
import { arrayMove } from 'react-sortable-hoc';

import * as Styles from 'styles';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import * as Data from '../../data';
import { FieldType } from '../../data';
import Typography, { Variant } from '../../../Typography';
import Switch from '../../../Switch';
import { SortableList, SortableListItem } from 'components/TableHeaderTools/Sortable';
import Radio from '@material-ui/core/Radio';
import Checkbox from '../../../Checkbox';
import { GreyTooltip } from '../../../Tooltip';

interface OwnProps {
  data: Data.SingleSelectData | Data.MultiSelectData;
  description: string;

  onDataUpdate(data: Data.SingleSelectData | Data.MultiSelectData): void;

  onAddOption?: () => void;
  defaultValue?: string;
  onDefaultSelect?: (data: string) => void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Select(props: Props): JSX.Element {
  const { classes, data, description } = props;

  function onAddOption(): void {
    const choices = addChoice(data.choices);
    const choiceOrder = data.choiceOrder?.length
      ? [...data.choiceOrder, choices[choices.length - 1].id]
      : choices.map(choice => choice.id) || [];
    props.onDataUpdate({ ...data, choices, choiceOrder });
  }

  function onAllowGridAddingOptions(): void {
    props.onDataUpdate({ ...data, allowGridAddingOptions: !data.allowGridAddingOptions });
  }

  function onDefaultSelect(key: string): void {
    if (data.type === FieldType.SingleSelect) {
      props.onDataUpdate({ ...data, defaultValue: data.defaultValue === key ? '' : key });
    } else if (data.type === FieldType.MultiSelect) {
      let defaultValues = data?.defaultValue?.split(',')?.filter(value => value) || [];
      if (defaultValues.find(value => value === key)) {
        defaultValues = defaultValues.filter(value => value !== key);
      } else {
        defaultValues.push(key);
      }

      props.onDataUpdate({ ...data, defaultValue: defaultValues.join(',') });
    }
  }

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        {description}
      </Typography>
      <div className={classes.toggle}>
        <Switch
          label="Allow quick option adding"
          selected={data.allowGridAddingOptions}
          onClick={onAllowGridAddingOptions}
          labelRight
        />
      </div>

      <List onAddOption={onAddOption} {...props} onDefaultSelect={onDefaultSelect} defaultValue={data.defaultValue}/>
      <div className={classes.greyBox} onClick={onAddOption}>
        <svgIcons.Add className={classes.icon}/>
        <Typography variant={Variant.Input} className={classes.label}>
          Add an option
        </Typography>
      </div>
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'listWrapper'
  | 'centered'
  | 'greyBox'
  | 'label'
  | 'icon'
  | 'list'
  | 'choice'
  | 'removeIcon'
  | 'choiceInput'
  | 'colorBox'
  | 'toggle'
  | 'defaultRadioButton'
  | 'defaultCheckbox'
  ;

const icon: CSSProperties = {
  width: 11,
  height: 11,
  color: Colors.slateTwo,
};

const styles = withStyles<ClassKey>({
  listWrapper: {
    'margin': '15px 0px',
    '& .styled-DropdownList': {
      maxHeight: '100%',
    },
  },
  description: {
    color: Colors.blueyGrey,
    padding: '8px 0',
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  greyBox: {
    ...Styles.hover(Colors.athensGrey),
    height: 25,
    padding: '7px 9px',
    backgroundColor: Colors.athensGrey,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    flex: 1,
    margin: '0 5px',
  },
  icon,
  list: {
    margin: '16px 0',
    padding: 0,
  },
  choice: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 3,
  },
  removeIcon: {
    ...icon,
    marginRight: 3,
  },
  choiceInput: {
    ...Styles.focus,
    color: Colors.brownishGrey,
    padding: '7px 9px',
    borderColor: Colors.athensGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    flex: 1,
  },
  colorBox: {
    width: 10,
    height: 10,
    margin: '0 3px',
  },
  toggle: { marginTop: 8 },
  defaultRadioButton: {
    padding: '5px',

    ['& svg']: {
      width: '0.7em',
    },
  },
  defaultCheckbox: {
    padding: '0 7px',

    ['& svg']: {
      cursor: 'pointer',
    },
  },
});

export default styles(Select);

function List(props: Props): JSX.Element {
  const { classes, data } = props;

  if (!data.choices || !data.choices.length) {
    return (
      <div className={classes.centered}>
        <Typography variant={Variant.Label} className={classes.description}>
          <p>No options defined</p>
        </Typography>
      </div>
    );
  }

  function onSortEnd({ oldIndex, newIndex }): void {
    let choiceOrder = data.choiceOrder && data.choiceOrder.length > 0 ? data.choiceOrder : data.choices.map(choice => choice.id);
    choiceOrder = arrayMove(choiceOrder, oldIndex, newIndex);
    const choices = orderChoices(choiceOrder, data.choices);
    props.onDataUpdate({ ...data, choices, choiceOrder });
  }

  function renderChoice(choice: Data.ChoiceOption, index: number): JSX.Element {
    let defaultValue = data.defaultValue || '';
    if (data.type === FieldType.MultiSelect && defaultValue.split(',').find(value => value === choice.id)) {
      defaultValue = defaultValue.split(',').filter(value => value !== choice.id).join(',');
    } else if (data.type === FieldType.SingleSelect && defaultValue === choice.id) {
      defaultValue = '';
    }

    function onDelete(): void {
      props.onDataUpdate({
        ...data,
        defaultValue,
        choices: data.choices.filter((c) => c.id !== choice.id),
      });
    }

    function onChange(event: React.FormEvent<HTMLInputElement>): void {
      props.onDataUpdate({
        ...data,
        choices: data.choices.map((c) => {
          if (c.id !== choice.id) {
            return c;
          }

          return { ...c, value: { ...c.value, label: event.currentTarget.value } };
        }),
      });
    }

    function onKeyDown({ keyCode }: React.KeyboardEvent<HTMLInputElement>): void {
      if (keyCode === keys.KEY_ENTER || keyCode === keys.KEY_RETURN) {
        props.onAddOption && props.onAddOption();
      }
    }

    return (
      <SortableListItem key={choice.id} index={index} hideDragHandle={false} className="sortable-list-item">
        <svgIcons.Remove className={classes.removeIcon} onClick={onDelete}/>
        <Typography variant={Variant.Input} className={classes.choiceInput}>
          <input
            value={choice.value.label}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus
          />
        </Typography>
        {props.onDefaultSelect && (
          <>
            {props.data.type === FieldType.SingleSelect && (
              <GreyTooltip
                interactive
                enterDelay={300}
                placement="bottom-start"
                title={
                  <Typography variant={Variant.GreyToolTipText}>Set default value</Typography>
                }>
                <Radio
                  disableRipple={true}
                  checked={choice?.id === props.defaultValue}
                  onClick={() => props.onDefaultSelect && props.onDefaultSelect(choice.id)}
                  value={choice.id}
                  className={classes.defaultRadioButton}
                  color="primary"
                />
              </GreyTooltip>
            )}
            {props.data.type === FieldType.MultiSelect && (
              <GreyTooltip
                interactive
                enterDelay={300}
                placement="bottom-start"
                title={
                  <Typography variant={Variant.GreyToolTipText}>Set default values</Typography>
                }>
                <div className={classes.defaultCheckbox}>
                  <Checkbox
                    onClick={() => props.onDefaultSelect && props.onDefaultSelect(choice.id)}
                    selected={Boolean(props.defaultValue && props.defaultValue?.split(',')?.indexOf(choice.id) > -1)}
                  />
                </div>
              </GreyTooltip>
            )}
          </>
        )}
        <div
          className={classes.colorBox}
          style={{ backgroundColor: choice.value.color }}
        />
      </SortableListItem>
    );
  }

  function orderChoices(choiceOrder, choices) {
    const results: Data.ChoiceOption[] = [];
    if (choiceOrder && choiceOrder.length > 0) {
      choiceOrder.forEach((order) => {
        const findChoice = choices.find(choice => choice.id === order);
        if (findChoice && !results.find(choice => choice.id === findChoice.id)) {
          results.push(findChoice);
        }
      });
      return results;
    }
    return choices;
  }

  const results = orderChoices(data.choiceOrder, data.choices);
  return (
    <div className={classes.listWrapper}>
      <SortableList
        lockAxis="y"
        lockToContainerEdges
        useDragHandle
        onSortEnd={onSortEnd}
      >
        {results.map(renderChoice)}
      </SortableList>
    </div>
  );
}

function addChoice(choices: Data.ChoiceOption[]): Data.ChoiceOption[] {
  const choiceIds = choices.map(choice => parseInt(choice.id)).filter(choice => choice > 0);
  const maxValue = Math.max(...choiceIds);
  const id = isFinite(maxValue) ? `${maxValue + 1}` : '1';
  return [
    ...choices,
    {
      id,
      value: { label: '', color: Colors.getColorByIndex(choices.length) },
    },
  ];
}
