import { GetQuickFilterTextParams } from 'ag-grid-community';
import moment from 'moment';
import { getFormattedFileSize } from '../../../utilities/files';

export const getDateQuickFilterText = (event: GetQuickFilterTextParams) => {
  if (!event.value) return event.value;
  return moment(event.value).format('MM/DD/YYYY h:mmA');
};

export const getUserQuickFilterText = (event: GetQuickFilterTextParams) => {
  const choices = event.column.getColDef().cellEditorParams.choices;
  if (choices && choices[event.value]) {
    return choices[event.value].label;
  }
  return event.value;
};

export const getFileSizeQuickFilterText = (event: GetQuickFilterTextParams) => {
  if (event.data.isFolder) {
    return `${event.value} ${event.value === 1 ? 'file' : 'files'}`;
  }
  return getFormattedFileSize(event.value);
};
