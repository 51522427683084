import { FC, SVGProps } from 'react';

import svgIcons from 'styles/svgIcons';

import { ToastType } from './types';

export const toastIcons: { [key in ToastType]: FC<SVGProps<SVGSVGElement>> } = {
  success: svgIcons.Check,
  warn: svgIcons.Limited2,
  error: svgIcons.CloseCircle,
  info: svgIcons.InfoFilled,
};
