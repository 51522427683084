import React from 'react';
import { ActivityFeedItem as ActivityFeedItemType } from 'data/activityFeed/types';

export function describeActivity(activity: ActivityFeedItemType) {
  return <span>not implemented</span>;
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  return 'not implemented';
}


export default {
  describeActivity,
  describeActivityDetails,
};
