import styled from 'styled-components';

import * as Colors from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

export const ICON_WIDTH_HEIGHT = 30;

export const Wrapper = styled.div`
  display: flex;
  padding: 0.3125rem 0;
  position: relative;
  font-size: .875rem;
  line-height: normal;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  a {
    color: ${Colors.hubsyncBlue};
  }
`;

export const Tail = styled.div`
  height: 100%;
  width: 1px;
  background: ${Colors.lightBlueGrey};
  position: absolute;
  left: ${ICON_WIDTH_HEIGHT / 2}px;

  ${Wrapper}:first-child & {
    top: 50%;
  }

  ${Wrapper}:last-child & {
    bottom: 50%;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 1rem;
  margin-top: 1.25rem;
  z-index: 1;
  display: flex;
  justify-content: center;

  svg {
    height: ${ICON_WIDTH_HEIGHT}px;
    width: ${ICON_WIDTH_HEIGHT}px;
  }
`;

export const Body = styled.div`
  background: ${Colors.white};
  border-radius: 2px;
  box-shadow: 0 0 2px 0 ${rgba(Colors.black, 0.12)};
  padding: 18px;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
`;

export const Description = styled.div`
  flex: 1;
`;

export const Meta = styled.div`
  flex: 1;
  margin: -1rem 0;
  padding: 1rem 0 1rem 1rem;
  border-left: 1px solid #F5F5F5;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const DateWrapper = styled.div`
  margin-bottom: 2px;
  color: ${Colors.slate};
  font-size: 11px;
`;

export const Day = styled.span`
  margin-right: 10px;
`;

export const Message = styled.div`
  word-break: break-word;
`;

export const DetailedMessage = styled.div`
  margin-top: 10px;
  word-break: break-word;
`;

export const EmptyEventIcon = styled.div`
  width: ${ICON_WIDTH_HEIGHT}px;
  height: ${ICON_WIDTH_HEIGHT}px;
  background: ${Colors.white};
  border-radius: ${ICON_WIDTH_HEIGHT}px;
`;
