import { ColDef } from 'ag-grid-community';
import * as ColID from 'components/DataGrid/columns/constants';

const DEFAULT_ROW_NUMBER_COLUMN_WIDTH = 48;
const ROW_NUMBER_ID_COLUMN_WIDTH = 75;

export const columnDefinition: ColDef = {
  colId: ColID.ROW_NUMBER_ID,
  width: ROW_NUMBER_ID_COLUMN_WIDTH,
  minWidth: DEFAULT_ROW_NUMBER_COLUMN_WIDTH,
  field: ColID.ROW_NUMBER_ID,
  cellClass: ColID.ROW_NUMBER_ID,
  headerName: '',
  pinned: 'left',
  sortable: false,
  suppressMenu: true,
  filter: false,
  resizable: false,
  lockPosition: true,
  lockPinned: true,
  cellRenderer: 'idCellRenderer',
  checkboxSelection: true,
};

