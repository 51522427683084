import * as React from 'react';
import { parse } from 'qs';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Subtract } from 'utility-types';

import { parseURL } from 'utilities/queryParams';

export interface URLParamsProps {
  viewId: string;
  folderID?: string;
  workspaces?: string;
  databases?: string;
  taskdbs?: string;
  sheets?: string;
  repositories?: string;
}

export type URLInjectedProps = RouteComponentProps & URLParamsProps;

export function withURLParams<P extends URLInjectedProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  class URLParamsProvider extends React.PureComponent<
    RouteComponentProps & Subtract<P, URLInjectedProps>
  > {
    render() {
      const searchParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
      const pathnameParams = parseURL(this.props.location.pathname);

      return (
        <WrappedComponent
          {...this.props}
          {...searchParams}
          {...pathnameParams}
        />
      );
    }
  }

  return withRouter(URLParamsProvider);
}
