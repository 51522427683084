
import { Role } from 'data/accounts/types';
import { Actions } from 'data/assureSign/assureSign.actions';
import assureSignService from 'data/assureSign/assureSign.service';
import { getCurrentDatabase } from 'data/databases/databases.selectors';
import * as modalActions from 'data/modals/actions';
import { State } from 'reducers';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AssureSignCredentialsSet } from 'types/assureSign';
import { CommonNode, FieldValue } from 'types/response';
import { ActionType } from 'typesafe-actions';
import { errorToast, successToast, warningToast } from 'utilities/saga';
import { removeHTMLTag } from '../../utilities/format';

import * as constants from './constants';


export function* cancelEnvelope(
  action: ActionType<typeof Actions.cancelEnvelope>,
) {
  const { envelopeId, remarks } = action.payload;
  const correctEnvelopeId = removeHTMLTag(envelopeId);
  try {
    const assureSignCredentials = yield select((state: State) => state.app?.tenant?.thirdParty?.assureSign?.credentials);
    const currentDatabase = yield select((state: State) => getCurrentDatabase(state));
    const databaseType = currentDatabase?.metadata?.subtype; // 8879 or engagement-letters
    const assureSignCredentialsSet = assureSignCredentials?.[databaseType] ?? {} as AssureSignCredentialsSet;
    yield call(
      successToast,
      'Cancellation successfully submitted.',
    );
    yield call(() => assureSignService.cancelEnvelope(correctEnvelopeId, remarks, assureSignCredentialsSet));
    yield call(
      successToast,
      'The signature was successfully cancelled.',
    );
  } catch (e) {
    yield call(errorToast, 'The cancellation was unsuccessful.  Please check the status of the envelope to confirm it can be cancelled or try again later.');
    console.error(e.message);
    return null;
  }
}

const ENVELOPE_COLUMN_ID = 'Envelope ID';
const EMAIL_COLUMN_ID = 'Taxpayer Email';

export function* preCancelEnvelope(
  action: ActionType<typeof Actions.preCancelEnvelope>,
) {
  const { nodeId } = action.payload;
  const state: State = yield select();

  // Validate cancel envelope permission in users
  const isTenantAdmin = state.users.user?.roles?.some(
    (role) => (role as Role).name === 'HubSync Admin'
  );
  // If user is tenant admin we bypass permissions validation
  if (!isTenantAdmin) {
    const permissions = state.users.user.permissions as string[];
    const hasCancelEnvelopePermission = permissions.includes('envelopes:update');

    if (!hasCancelEnvelopePermission) {
      yield put(modalActions.openCancelEnvelopePermissionModal());
      return null;
    }
  }

  const columns = state.collections.collections.schema.properties.fields.properties;
  const data = state.collections.collections.viewport.nodes.find((value) => value.id === nodeId);
  // Handling programmatic errors, when there are no columns. You should never reach this code
  if (!columns) return null;

  const validationWarningMessage = 'The signature cannot be cancelled without an ID. Please try again later.';
  const envelopeColumnId = Object.values(columns).find((column) => column.name === ENVELOPE_COLUMN_ID)?._id;
  if (!envelopeColumnId) {
    yield call(warningToast, validationWarningMessage);
  } else {
    const envelopeID = getEnvelopeId(envelopeColumnId, data);
    if (envelopeID) {
      yield put(modalActions.openCancelEnvelopeModal(envelopeID.toString()));
    } else {
      yield call(warningToast, validationWarningMessage);
    }
  }
}

export function* resendEnvelope(
  action: ActionType<typeof Actions.resendEnvelope>,
) {
  const { envelopeId, signerEmail } = action.payload;
  const email = removeHTMLTag(signerEmail);
  const correctEnvelopeId = removeHTMLTag(envelopeId);
  try {
    const assureSignCredentials = yield select((state: State) => state.app?.tenant?.thirdParty?.assureSign?.credentials);
    const currentDatabase = yield select((state: State) => getCurrentDatabase(state));
    const databaseType = currentDatabase?.metadata?.subtype; // 8879 or engagement-letters
    const assureSignCredentialsSet = assureSignCredentials?.[databaseType] ?? {} as AssureSignCredentialsSet;
    yield call(
      successToast,
      'Signature successfully submitted.',
    );
    yield call(() => assureSignService.resendEnvelope(correctEnvelopeId, email, assureSignCredentialsSet));
    yield call(
      successToast,
      'The signature was successfully resent.',
    );
  } catch (e) {
    yield call(errorToast, 'The signature was unsuccessful resent. Please check the status of the envelope to confirm it can be resent or try again later.');
    console.error(e.message);
    return null;
  }
}

export function* preResendEnvelope(
  action: ActionType<typeof Actions.preResendEnvelope>,
) {
  const { nodeId } = action.payload;
  const state: State = yield select();

  // Validate resend envelope permission in users
  const isTenantAdmin = state.users.user?.roles?.some(
    (role) => (role as Role).name === 'HubSync Admin'
  );
  // If user is tenant admin we bypass permissions validation
  if (!isTenantAdmin) {
    const permissions = state.users.user.permissions as string[];
    const hasResendEnvelopePermission = permissions.includes('envelopes:update');

    if (!hasResendEnvelopePermission) {
      yield put(modalActions.openResendEnvelopePermissionModal());
      return null;
    }
  }

  const columns = state.collections.collections.schema.properties.fields.properties;
  const data = state.collections.collections.viewport.nodes.find((value) => value.id === nodeId);
  // Handling programmatic errors, when there are no columns. You should never reach this code
  if (!columns) return null;

  const validationWarningMessage = 'The signature cannot be resent without an ID. Please try again later.';
  const envelopeColumnId = Object.values(columns).find((column) => column.name === ENVELOPE_COLUMN_ID)?._id;
  const signerEmailColumnId = Object.values(columns).find((column) => column.name === EMAIL_COLUMN_ID)?._id;
  if (!envelopeColumnId || !signerEmailColumnId) {
    yield call(warningToast, validationWarningMessage);
  } else {
    const envelopeID = getEnvelopeId(envelopeColumnId, data);
    const signerEmail = getSignerEmail(signerEmailColumnId, data);
    if (envelopeID && signerEmail) {
      yield put(modalActions.openResendEnvelopeModal(envelopeID.toString(), signerEmail.toString()));
    } else {
      yield call(warningToast, validationWarningMessage);
    }
  }
}

const getEnvelopeId = (envelopeColumnID: string, data?: CommonNode): FieldValue | null => {
  return data?.fields ? data?.fields[envelopeColumnID] : null;
};

const getSignerEmail = (signerEmail: string, data?: CommonNode): FieldValue | null => {
  return data?.fields ? data?.fields[signerEmail] : null;
};

export function* assureSignSaga() {
  yield takeEvery(constants.PRE_CANCEL_ENVELOPE, preCancelEnvelope);
  yield takeEvery(constants.CANCEL_ENVELOPE, cancelEnvelope);
  yield takeEvery(constants.PRE_RESEND_ENVELOPE, preResendEnvelope);
  yield takeEvery(constants.RESEND_ENVELOPE, resendEnvelope);
}
