import { arrayToTree } from 'performant-array-to-tree';

function flatData(elements) {
  return elements.map(node => {
    const data = node.data;
    delete node.data;

    if (node?.nodes?.length) {
      node.nodes = flatData(node.nodes);
    }

    return {
      ...node,
      ...data,
    };
  });
}

function createTree(list) {
  const tree = arrayToTree(list, {
    id: '_id',
    parentId: 'parentID',
    childrenField: 'nodes',
  });

  return flatData(tree);
}

export default createTree;
