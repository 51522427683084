import { updateRecords } from 'data/collections/collections.actions.new';
import { getCollectionUrl } from 'utilities/createUrl';

/**
 * Action shortcut to update records in the grid based on their status
*/
export function updateRecordsInGrid(fieldId, value, recordIds, dispatch) {
  dispatch(updateRecords({
    items: recordIds,
    field: `fields.${fieldId}`,
    payload: value,
    locationData: {
      url: getCollectionUrl(window.location.pathname),
      queryParams: {},
    },
  }));
}
