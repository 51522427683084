import * as actions from 'data/attachments/actions';

class FileEntity {
  created(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    const databaseId = state.databases.current || state.taskDatabases.current;
    const workspaceId = state.workspaces.current;

    if (data.eventBy === currentUserId || data?.item.workspaceID !== workspaceId || data?.item?.references?.databaseID !== databaseId) {
      return;
    }

    store.dispatch(actions.indexAttachment(data.item));
  }

  BULK_DELETED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    const workspaceId = state.workspaces.current;
    if (data.eventBy === currentUserId || data.item.references?.workspaceID !== workspaceId) {
      return;
    }

    for (const fileId of data.item.ids) {
      for (const itemId in state.attachments.byItemId) {
        if (state.attachments.byItemId?.[itemId]?.byId?.[fileId]) {
          store.dispatch(actions.deleteAttachment.success({ uri: `/items/${itemId}`, id: fileId }));
          break;
        }
      }
    }
  }
}

export default new FileEntity();
