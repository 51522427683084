import * as React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from 'pages/common/Styled';
import PageHeader from 'components/PageHeader';
import * as activityFeedActions from 'data/activityFeed/actions';
import * as type from 'data/activityFeed/types';
import { ActivityFeedGroup as ActivityFeedGroupType } from 'data/activityFeed/types';
import ActivityFeedGroups from 'components/ActivityFeed/ActivityFeedGroups';
import { Title } from 'components/PageHeader/PageHeader.styles';
import { parseURI } from '../../utilities/parseURI';
import ActivityFeedFilters from '../../components/ActivityFeed/ActivityFeedFilters';
import loadingAction from 'data/ui/loading/actions';

interface StateToProps {
  feed: ActivityFeedGroupType[] | null;
  isFetchLoading: boolean;
  isEndResult: boolean;
  next: string;
  query: string;
  type?: string;
  lockScroll?: boolean;
}

interface DispatchProps {
  fetchActivityFeeds: (query: type.FetchActivityFeedQuery) => void;
  fetchActivityFeedsReset: () => void;
  filter: (any) => void;
  startLockingLoader: () => void;
  endLockingLoader: () => void;
}

type Props = StateToProps & DispatchProps;

interface State {
  isHidden: boolean;
}

export class ActivityFeedPage extends React.Component<Props, State> {
  scroller: any;
  scrollElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      isHidden: true,
    };
  }

  public componentDidMount() {
    const { workspaceId: workspaceID } = parseURI(window.location.pathname);
    this.props.fetchActivityFeedsReset();
    this.props.fetchActivityFeeds({ workspaceID });
    this.scrollElement = document.querySelector('#hubsync-content');
    if (this.scrollElement) {
      this.scroller = this.scrollElement.addEventListener('scroll', this.handleScroll);
    }

    if (this.state.isHidden) {
      this.setState({ isHidden: false });
      const timer = setTimeout( () => {
        this.props.endLockingLoader();
        clearTimeout(timer);
      }, 1500);
    }
  }

  componentWillUnmount(): void {
    if (this.scroller) {
      this.scrollElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  public render(): JSX.Element {
    const { feed } = this.props;

    if (this.state.isHidden) {
      this.setState({ isHidden: false });
      const timer = setTimeout( () => {
        this.props.startLockingLoader();
        clearTimeout(timer);
      }, 0);
    }

    return (
      <Wrapper>
        <PageHeader titleContent={<Title>Activity</Title>}>
          <ActivityFeedFilters query={this.props.query || ''} type={this.props.type} onQuery={(event) => {
            const searchQuery: string = event.target.value;
            this.props.filter({ query: searchQuery, type: this.props.type });
          }} onTypeChange={(type) => {
            this.props.filter({ query: this.props.query, type: type });
          }} />
        </PageHeader>

        {feed &&
        <ActivityFeedGroups groups={feed}/>
        }

      </Wrapper>
    );
  }

  private handleScroll = (event: any): void => {
    if (this.props.lockScroll) return;

    if (this.props.next && this.props.feed && !this.props.isFetchLoading && !this.props.isEndResult && event.target.offsetHeight + event.target.scrollTop + 50 >= event.target.scrollHeight) {
      if (this.props.feed[this.props.feed.length - 1]) {
        const { workspaceId: workspaceID } = parseURI(window.location.pathname);
        this.props.fetchActivityFeeds({ next: this.props.next, workspaceID });
      }
    }
  };
}

const mapStateToProps = (state): StateToProps => ({
  feed: state.activityFeed.feed,
  isFetchLoading: state.activityFeed.isFetchLoading,
  isEndResult: state.activityFeed.isEndResult,
  next: state.activityFeed.next,
  query: state.activityFeed.query,
  type: state.activityFeed.type,
  lockScroll: state.activityFeed.lockScroll,
});

const mapDispatchToProps: DispatchProps = {
  fetchActivityFeeds: activityFeedActions.loadActivities,
  fetchActivityFeedsReset: activityFeedActions.reset,
  filter: activityFeedActions.filter,
  startLockingLoader: loadingAction.startLockingLoader,
  endLockingLoader: loadingAction.endLockingLoader,
};

export default connect<StateToProps>(mapStateToProps, mapDispatchToProps)(ActivityFeedPage);
