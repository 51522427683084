import * as React from 'react';
import ModalTextField from 'components/Modals/ModalTextField';
import svgIcons from 'styles/svgIcons';
import Logo from 'assets/images/auth/hubsync-logo-white.svg';
import IconButton, { Color, Size } from 'components/IconButton';

interface Props {
  onSubmit: (password: string) => void;
  error: string;
  validating: boolean;
  tenant: any;
}

const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

class SharedFilesAuth extends React.Component<Props> {
  state = {
    error: '',
    password: '',
    showPassword: false,
  };


  render() {
    return (
      <div className={'auth-container'+ (tenantConfig.clientName || '')}>
        <div className="auth-form-container">
          <div className="auth-logo">
            <img src={tenantConfig.logoURL ?? Logo} alt={'Hubsync'} />
          </div>
          <div>
            <div className="auth-welcome-container">
              <p>These shared files are password protected. Enter the password to access files.</p>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="auth-input-container">
                <label htmlFor="password">
                  <svgIcons.LockFilled/>
                  Password
                </label>
                <ModalTextField
                  id="password"
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  InputProps={{
                    endAdornment: (
                      <div className="show-password-toggle">
                        <IconButton
                          tabIndex={-1}
                          icon={!this.state.showPassword ? svgIcons.PasswordShow : svgIcons.Columns }
                          color={Color.DarkGray}
                          size={Size.Medium}
                          onMouseDown={() => this.setState({ showPassword: true })}
                          onClick={() => this.setState({ showPassword: false })}/>
                      </div>
                    ),
                  }}
                  onChange={this.handleInputChange}/>
              </div>
              {this.props.error && (
                <div className="auth-error-message auth-error-container"><span>
                  <svgIcons.Limited /></span>
                {this.props.error}
                </div>
              )}
              <div className="auth-action-btn">
                <button className="app-btn-primary" disabled={this.props.validating || !this.state.password}>Access folder</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }


  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  private onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.onSubmit(this.state.password);
  };
}

export default SharedFilesAuth;
