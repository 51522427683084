import './styles/People.scss';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ExportType } from 'components/AgGrid/AgGridApi';
import GridToolbar from 'components/AgGrid/GridToolbar';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { Role } from 'data/accounts/types';
import {
  removeWorkspaceUser,
  removeWorkspaceUsers,
  resendUserInvitation,
  updateUserRole,
  getWorkspaceUsers,
} from 'data/accounts/actions';

import columnDefs from './grid-options/column-defs';
import components from './renderers/Renderers';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import { getSvgIconNode } from '../../components/DataGrid/utils';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { State as ReduxState } from 'reducers';
import * as Colors from 'styles/colors';
import { CollectionTypes, Column, ColumnVM } from 'types/schema';

import Button, { Variant as ButtonVariant } from '../../components/Button';
import * as ColID from '../../components/DataGrid/columns/constants';
import { DateFormat } from '../../components/Fields/data';
import LoadingIndicator from '../../components/LoadingIndicator';
import Confirm from '../../components/Modals/Confirm';
import Modal from '../../components/Modals/Modal';
import ChoiceEditor from '../../components/NodeFieldData/choice/ChoiceEditor';
import PageHeader from '../../components/PageHeader';
import { Title } from '../../components/PageHeader/PageHeader.styles';
import { AppMessages } from '../../constants';
import svgIcons from '../../styles/svgIcons';
import { AccountNode } from '../../types/response/accountNode';
import { Choice, FieldType } from '../../types/response/fieldNode';
import { WorkspaceNode } from '../../types/response/workspaceNode';
import StateUtils from '../../utilities/state-utils';
import ColumnHeader from '../Files/Renderers/ColumnRenderer';
import UpdateUserRole from './UpdateUserRole';
import loadingAction from 'data/ui/loading/actions';
import moment from 'moment';

export enum MODES {
  BROWSE = 'browse',
  UPDATE = 'update',
  UPDATE_CONFIRM = 'update_confirm',
  UPDATE_CELL_CONFIRM = 'update_cell_confirm',
  TENANT_UPDATES = 'tenant_updates',
  BULK_UPDATE = 'bulk_update',
  BULK_UPDATE_CONFIRM = 'bulk_update_confirm',
  DELETE_CONFIRM = 'delete_confirm',
  BULK_DELETE_CONFIRM = 'bulk_delete_confirm',
  LOGGED_USER = 'logged_user',
}

const TENANT_LABEL = 'HubSync Admin';
const ROW_HEIGHT = 30;

const People: React.FunctionComponent<URLInjectedProps> = (
  props: URLInjectedProps,
) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(MODES.BROWSE);
  const [isFetchingWorkspaces, setWorkspaceMode] = useState(false);
  const workspaceUsers = useSelector((state: ReduxState) =>
    state.account.workspaceUsers.slice(),
  );

  // @ts-ignore no-data-property-in-redux
  const accounts = useSelector((state: ReduxState) => state.account.workspaceUsers);
  let TenantUser;
  for (let index = 0; index < accounts.length; index++) {
    const account = accounts[index];
    TenantUser = account?.roles?.find(role => role.name === TENANT_LABEL);
    if (TenantUser) break;
  }

  const loggedUser = useSelector((state: ReduxState) =>
    state.users.user,
  );

  const workspace = useSelector(
    (state: ReduxState) =>
      state.workspaces.current &&
      state.workspaces.byId[state.workspaces.current],
  ) as WorkspaceNode;
  const permissions: string[] = workspace?.permissions || [];
  const grid = useRef({ api: new GridApi(), columnApi: new ColumnApi() });
  const [selectedUser, setUser] = useState<AccountNode | null>(null);
  const [role, setRole] = useState<Role | null>(null);
  const [gridCellValue, setGridCellValue] = useState('');
  const [focusedCell, setFocusedCell] = useState({ rowIndex: 0, colKey: '' });
  const [isReady, setGridState] = useState<any>(false);
  const [quickFiltersBarEnabled, toggleQuickFilterVisibility] = useState(true);

  const columns: Column[] = [
    {
      id: 'name',
      allowMultiple: false,
      fieldType: FieldType.Singlelineoftext,
      type: 'text',
      name: 'Name',
    },
    {
      id: 'email',
      allowMultiple: false,
      fieldType: FieldType.Singlelineoftext,
      type: 'text',
      name: 'Email',
    },
    {
      id: 'lastLogin',
      allowMultiple: false,
      fieldType: FieldType.Date,
      type: 'text',
      name: 'Last Log In (local time)',
      dateFormat: DateFormat.US,
    },
    {
      id: 'role',
      allowMultiple: false,
      fieldType: FieldType.Singlechoice,
      type: 'text',
      name: 'Role',
      ...createRoleChoices(workspace?.roles || [], TenantUser || {}),
    },
  ];

  const columnSchema: { [key: string]: ColumnVM } = {
    name: Object.assign({}, columns[0]),
    email: Object.assign({}, columns[1]),
    lastLogin: Object.assign({}, columns[2]),
    role: Object.assign({}, columns[3]),
  };

  useEffect(() => {
    dispatch(getWorkspaceUsers());
    if (
      !isFetchingWorkspaces &&
      workspace &&
      (!workspace.roles || !workspace.roles.length)
    ) {
      setWorkspaceMode(true);
      dispatch(fetchCollectionRequest({ url: CollectionTypes.workspaces }));
    }
  }, []);

  if (!workspaceUsers.length || !permissions.length) {
    return <LoadingIndicator />;
  }

  const onGridReady = (event: GridReadyEvent): void => {
    grid.current = { api: event.api, columnApi: event.columnApi };
    setGridState(true);
  };

  const isEditingTenant = (selectedRows): boolean => {
    const isTenantHere = selectedRows?.find((row) => {
      return row.data?.role == TenantUser?.id;
    });

    return isTenantHere;
  };

  const isLoggedUser = (params) =>{
    if (params.length) {
      return !!params.some(item => item.id === loggedUser.id);
    } else if (params.node.data.id == loggedUser.id) {
      return true;
    }
    return false;
  };

  const getContextMenuItems = (params): MenuItemDef[] => {
    const selectedRows = grid.current.api.getSelectedNodes();
    const menu: MenuItemDef[] = [];

    if (isEditingTenant(selectedRows)) {
      setMode(MODES.TENANT_UPDATES);
      setGridCellValue('');
      grid.current.api?.deselectAll();
    }
    if (isLoggedUser(selectedRows.length ? selectedRows:params)) {
      setMode(MODES.LOGGED_USER);
      setGridCellValue('');
      grid.current.api?.deselectAll();
      return [];
    }

    if (!params.node || !permissions.includes('workspaces:update')) return [];

    if (!selectedRows.length && params.node) {
      setUser(handleEmailName(params.node.data));
      selectedRows.push(params.node);
    }

    if (selectedRows.length === 1) {
      menu.push(
        {
          name: 'Show Profile',
          action: () => {
            setUser(handleEmailName(params.node.data));
            setMode(MODES.UPDATE);
          },
          icon: getSvgIconNode(svgIcons.UserProfile),
          cssClasses: ['context-menu-item'],
        },
        {
          name: 'Remove User',
          action: () => {
            setUser(handleEmailName(params.node.data));
            setMode(MODES.DELETE_CONFIRM);
          },
          icon: getSvgIconNode(svgIcons.Delete),
          cssClasses: ['context-menu-item'],
        },
      );

      if (params.node.data.status === 'pending') {
        menu.splice(1, 0, {
          name: 'Resend Invitation',
          action: () => {
            setUser(handleEmailName(params.node.data));
            resendInvitation(params.node.data);
          },
          icon: getSvgIconNode(svgIcons.Email),
          cssClasses: ['context-menu-item'],
        });
      }
    }

    if (selectedRows.length > 1) {
      menu.push(
        {
          name: 'Bulk Edit',
          action: () => {
            setMode(MODES.BULK_UPDATE);
          },
          icon: getSvgIconNode(svgIcons.Edit),
        },
        {
          name: 'Remove Users',
          action: () => {
            setMode(MODES.BULK_DELETE_CONFIRM);
          },
          icon: getSvgIconNode(svgIcons.Delete),
        },
      );
    }

    return menu;
  };

  const resendInvitation = (user?: AccountNode): void => {
    const currentUser = selectedUser || user;
    if (currentUser?.email && currentUser?.displayName) {
      dispatch(
        resendUserInvitation({
          userId: currentUser.id,
          email: currentUser.email,
        }),
      );
    }
  };

  const handleEmailName = (user: AccountNode): AccountNode => {
    return {
      ...user,
      displayName: user.displayName?.replace(' (pending)', ''),
    };
  };

  const saveUserRole = () => {
    const userIds =
      mode === MODES.UPDATE_CONFIRM || mode === MODES.UPDATE_CELL_CONFIRM
        ? [selectedUser?.id]
        : grid.current.api?.getSelectedNodes?.()?.map((user) => user?.data?.id);

    if (role) dispatch(updateUserRole({ userIds, role }));
    setGridCellValue('');
    grid.current.api?.deselectAll();
  };

  const removeUser = () => {
    if (!selectedUser) return;
    const node = grid.current.api.getRowNode(selectedUser.id);
    grid.current.api.updateRowData({ remove: [node.data] });
    dispatch(removeWorkspaceUser({ userId: selectedUser.id }));
  };

  const removeUsers = () => {
    const selectedRows = grid.current.api?.getSelectedNodes();
    grid.current.api.updateRowData({
      remove: selectedRows.map((row) => row.data),
    });
    dispatch(
      removeWorkspaceUsers({ userIds: selectedRows.map((row) => row.id) }),
    );
  };

  const onCellClicked = (event: CellClickedEvent) => {
    if (!event.event) return;

    let target = event.event.target as HTMLElement;
    while (target !== document.body) {
      if (target?.classList.contains('ag-cell') || !target) break;
      if (target?.classList?.contains('update-user-arrows')) {
        if (isLoggedUser(event)) {
          setMode(MODES.LOGGED_USER);
          break;
        }
        setUser(handleEmailName(event.node.data));
        setMode(MODES.UPDATE);
        break;
      }
      target = target.parentNode as HTMLElement;
    }

    const currentFocusedCell = {
      rowIndex: event.rowIndex,
      colKey: event.column.getColId(),
    };
    if (isEqual(currentFocusedCell, focusedCell)) {
      grid.current.api.startEditingCell({
        rowIndex: event.rowIndex,
        colKey: event.column.getColId(),
      });
    }
    setFocusedCell(currentFocusedCell);
  };

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    if (gridCellValue) return;
    if (role?.id || event.value === event.node.data.role) return;
    const selectedRole: Role | null =
      workspace.roles?.find((role) => role.name === event.value) || null;
    setGridCellValue(event.oldValue);
    setUser(handleEmailName(event.data));
    const TenantLabel = 'Tenant Administrator';
    setRole(selectedRole);
    if (isLoggedUser(event)) {
      setMode(MODES.LOGGED_USER);
      return;
    }
    if (selectedRole?.name == TenantLabel || event.oldValue == TenantLabel) {
      setMode(MODES.TENANT_UPDATES);
    } else {
      setMode(MODES.UPDATE_CELL_CONFIRM);
    }
  };

  const onClose = () => {
    if (
      (mode === MODES.UPDATE_CELL_CONFIRM || mode === MODES.TENANT_UPDATES || mode === MODES.LOGGED_USER) &&
      gridCellValue
    ) {
      const focusedCell = grid.current.api.getFocusedCell();
      if (focusedCell) {
        const node = grid.current.api.getDisplayedRowAtIndex(
          focusedCell.rowIndex,
        );
        const oldRole: Role | null =
          workspace.roles?.find((role) => role.name === gridCellValue) || null;
        if (oldRole) {
          node.setDataValue('role', oldRole.id);
        }
      }
    }

    setUser(null);
    setMode(MODES.BROWSE);
    setTimeout(() => {
      setRole(null);
      setGridCellValue('');
    }, 0);
  };

  const permissionClassname = !permissions.includes('workspaces:update')
    ? 'no-user-update-permission'
    : '';

  const changeQuickFiltersVisibility = async () => {
    toggleQuickFilterVisibility(!quickFiltersBarEnabled);
    await StateUtils.wait(0);
    grid.current.api.refreshHeader();
  };

  const handleExport = (type: ExportType): void => {
    switch (type) {
      case ExportType.EXCEL:
        grid.current.api.exportDataAsExcel({
          fileName: 'People',
          processCellCallback: processCell,
        });
        break;
      case ExportType.CSV:
        grid.current.api.exportDataAsCsv({
          fileName: 'People',
          processCellCallback: processCell,
        });
        break;
    }
  };

  const processCell = (params) => {
    if (params.column.getColId() === 'lastLogin' && params.value) {
      return moment(params.value)
        .utc(true)
        .format('MM/DD/YYYY hh:mmA')
        .toString();
    }
    return params.value;
  };

  return (
    <div
      className={`collections__main__container users ${permissionClassname}`}
    >
      <div className="ag-theme-material users">
        <div className="page-header-container">
          <PageHeader titleContent={<Title>People</Title>}>
            {permissions.includes('workspaces:update') && (
              <Button
                id="btnAddUser"
                label="Add User"
                variant={ButtonVariant.Primary}
                icon={svgIcons.Add}
                onClick={() =>
                  props.history.push(`${props.location.pathname}/new`)
                }
              />
            )}
          </PageHeader>
        </div>

        {isReady && (
          <>
            <GridToolbar
              columns={columns}
              columnSchema={columnSchema}
              changeQuickFiltersVisibility={changeQuickFiltersVisibility}
              quickFiltersBarEnabled={quickFiltersBarEnabled}
              grid={grid.current}
              onExport={handleExport}
            />
          </>
        )}

        {mode === MODES.DELETE_CONFIRM && (
          <Modal id="modalDelete" onClose={onClose}>
            <Confirm
              title={AppMessages.CONFIRMATION_REMOVE_USER_MESSAGE}
              close={onClose}
              onConfirm={removeUser}
              confirmLabel={AppMessages.REMOVE_USER_LABEL}
              message={`Removing ${selectedUser?.['displayName']} will restrict this user from accessing files and databases of the workspace.`}
            />
          </Modal>
        )}

        {mode === MODES.TENANT_UPDATES && (
          <Modal id="modalDelete" onClose={onClose}>
            <Confirm
              title={AppMessages.TENANT_UPDATE_PEOPLE_NEGATION}
              close={onClose}
              onConfirm={onClose}
              confirmLabel={AppMessages.TENANT_UPDATE_PEOPLE_NEGATION_CONFIRM}
              cancelBtn={false}
              message={`Tenant Administrator cannot be updated in People tab.`}
            />
          </Modal>
        )}

        {mode === MODES.LOGGED_USER && (
          <Modal id="modalDelete" onClose={onClose}>
            <Confirm
              title={AppMessages.LOGGED_USER_UPDATE_PEOPLE_NEGATION}
              close={onClose}
              onConfirm={onClose}
              confirmLabel={AppMessages.LOGGED_USER_PEOPLE_NEGATION_CONFIRM}
              cancelBtn={false}
              message={`Logged user cannot be updated in People tab.`}
            />
          </Modal>
        )}

        {mode === MODES.BULK_DELETE_CONFIRM && (
          <Modal id="modalDeleteBulk" onClose={onClose}>
            <Confirm
              title={AppMessages.CONFIRMATION_REMOVE_USERS_MESSAGE}
              close={onClose}
              onConfirm={removeUsers}
              confirmLabel={AppMessages.REMOVE_USERS_LABEL}
              message={`Removing these users will restrict them from accessing files and databases of the workspace.`}
            />
          </Modal>
        )}

        {(mode === MODES.UPDATE_CONFIRM ||
          mode === MODES.UPDATE_CELL_CONFIRM) && (
          <Modal id="modalUpdate" onClose={onClose}>
            <Confirm
              title={AppMessages.CONFIRMATION_ROLE_MESSAGE_USER}
              close={onClose}
              onConfirm={saveUserRole}
              confirmLabel={AppMessages.CONFIRM_CHANGE_LABEL}
              message={`Do you want to change ${
                (selectedUser?.['displayName'] &&
                  selectedUser?.['displayName'] + '\'s') ||
                'user'
              } role to ${role?.name}`}
            />
          </Modal>
        )}

        {(mode === MODES.UPDATE || mode === MODES.BULK_UPDATE) && (
          <UpdateUserRole
            onClose={onClose}
            roles={(workspace && workspace.roles) || []}
            mode={mode}
            setMode={setMode}
            setRole={setRole}
            user={selectedUser}
            permissions={permissions}
            workspaceTitle={workspace?.title || ''}
            resendInvitation={resendInvitation}
            roleChoices={createRoleChoices(
              workspace?.roles || [],
              TenantUser || {},
            )}
          />
        )}

        {mode === MODES.BULK_UPDATE_CONFIRM && (
          <Modal id="modalUpdateBulk" onClose={onClose}>
            <Confirm
              title={AppMessages.CONFIRMATION_ROLE_MESSAGE_USERS}
              close={onClose}
              onConfirm={saveUserRole}
              confirmLabel="Confirm change"
              message={`Do you want to change these users' roles to ${role?.name}`}
            />
          </Modal>
        )}

        <AgGridReact
          defaultColDef={{ sortable: true, filter: true }}
          columnDefs={extendRoleParams(
            columnDefs as ColDef[],
            workspace?.roles || [],
            permissions,
            TenantUser || {},
          )}
          rowData={workspaceUsers}
          headerHeight={ROW_HEIGHT}
          rowHeight={ROW_HEIGHT}
          animateRows
          rowSelection="multiple"
          // @ts-ignore
          components={components}
          frameworkComponents={{
            agColumnHeader: ColumnHeader,
            singleChoiceEditor: ChoiceEditor,
          }}
          allowContextMenuWithControlKey
          getContextMenuItems={getContextMenuItems}
          gridOptions={{ getRowNodeId: (row: RowNode): string => row.id }}
          onGridReady={onGridReady}
          onCellClicked={onCellClicked}
          suppressRowClickSelection={true}
          accentedSort={true}
          suppressDragLeaveHidesColumns
          onCellValueChanged={onCellValueChanged}
          floatingFilter={quickFiltersBarEnabled}
          floatingFiltersHeight={31}
          onFilterChanged={() => {
            dispatch(loadingAction.endLockingLoader());
            grid.current?.api?.refreshCells?.({
              force: true,
              columns: [ColID.ROW_NUMBER_ID],
            });
          }}
        />
      </div>
    </div>
  );
};

const roleColors = {
  'Administrator': Colors.lightOrange,
  'Editor': Colors.jungleGreen,
  'Tenant Administrator': Colors.bluish,
};

export const createRoleChoices = (
  workspaceRoles: Role[],
  TenantUser,
): { choices: { [key: string]: Choice }; choiceOrder: string[] } => {
  const choices = {};
  const TenantRole: Role = {
    id: TenantUser ? TenantUser.id : '',
    workspaceId: undefined,
    name: 'Tenant Administrator',
  };

  const isTenantAdded = workspaceRoles.find((role) => {
    return role.name == 'Tenant Administrator';
  });

  if (!isTenantAdded && TenantRole.id) workspaceRoles?.push(TenantRole);

  workspaceRoles.map((role: Role) => {
    choices[role.id] = { label: role.name, color: roleColors[role.name] };
  });

  return { choices, choiceOrder: Object.keys(choices) };
};

export const extendRoleParams = (
  columnDefs: ColDef[],
  workspaceRoles: Role[],
  permissions: string[],
  TenantUser,
): ColDef[] => {
  return columnDefs.map((columnDef) => {
    if (columnDef.field === 'role') {
      const params = {
        ...columnDef.cellEditorParams,
        ...createRoleChoices(workspaceRoles, TenantUser),
      };
      return {
        ...columnDef,
        cellRendererParams: { ...params },
        cellEditorParams: { ...params, includeEmpty: false },
        editable: permissions.includes('workspaces:update'),
      };
    }
    return columnDef;
  });
};

export default withURLParams(People);
