import React from 'react';
import svgIcons from 'styles/svgIcons';
import Button, { Variant as ButtonVariants } from 'components/Button';

interface Props {
  error: string;
  onSubmit: () => void;
  isLoading: boolean;
  goToLoginPage: () => void;
}

const RequestTemporaryPassword: React.FC<Props> = ({ error, onSubmit, isLoading, goToLoginPage }: Props ) => {
  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <form onSubmit={onSubmit}>
      <div className="auth-password-expired">
        <div className="password-expired-title">
          <h3>Your password has expired</h3>
        </div>
        <div className="icon">
          {tenantConfig.expireIcon? <img src={tenantConfig.expireIcon} alt="HubSync" /> : <svgIcons.Expire /> }
        </div>
        <div className="password-expired-message">
          <span>Your temporary password has expired. Click the button below to request a new one.</span>
        </div>
        {error && (
          <div className="password-expired-message">
            <div className="auth-error-message auth-error-container">
              <span><svgIcons.Limited /></span>
              {error}
            </div>
          </div>
        )}
        <div className={'password-expired-button'+tenantConfig.clientName}>
          <Button
            type="submit"
            label="Request Temporary Password"
            variant={ButtonVariants.Primary}
            fullWidth
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
        <div className="auth-code-send-link">
          <span onClick={goToLoginPage}>Back to Log In</span>
        </div>
      </div>
    </form>
  );
};

export default RequestTemporaryPassword;
