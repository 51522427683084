import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

type ClassKey =
  | 'wrapper'
  | 'subheader'
  | 'menuItem'
  | 'icon'
  | 'itemWrapper'
;

export const profileMenuStyle: Record<ClassKey, CSSProperties> = {
  wrapper: {
    padding: '0.5rem 1.1rem 0.5rem 1.1rem',
  },
  subheader: {
    marginBottom: 4,
  },
  menuItem: {
    color: '#37474F',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '22px',
  },
  icon: {
    height: 16,
    width: 'auto',
    marginRight: 8,
  },
  itemWrapper: {
    height: 30,
    padding: '0 !important',
    color: Colors.blueyGrey,
  },
};
