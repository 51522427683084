import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from 'components/NodeFieldData/attachment/uploaders/FileUploaderForModals.style';
import { RoleNames } from 'data/users/users.types';
import * as Dropzone from 'react-dropzone/dist/index';
import svgIcons from 'styles/svgIcons';
import { FileAttachment } from 'types/common';
import { getFileExtension, MediaTypeByExtension } from 'utilities/files';
import { getUserRole } from 'utilities/permissions';

interface Props extends WithStyles<typeof styles> {
  lock?: boolean;
  readOnly?: boolean;
  permissions?: string[];
  filesAttachedCounter?: number;
  acceptTypes?: string;
  onAdd: (attachment: FileAttachment[]) => void;
  onClick: () => void;
}

const FileUploaderForModals = ({
  classes,
  onAdd,
  acceptTypes,
  lock,
  readOnly,
  filesAttachedCounter,
  permissions,
  onClick,
}: Props): JSX.Element => {
  const userRole: RoleNames = getUserRole(permissions ?? []);
  const disabled = userRole === RoleNames.Administrator ? false : lock || readOnly;
  const onDrop = (files: File[]): void => {
    onAdd(files.map(file => {
      const extension = getFileExtension(file.name);
      return ({
        file,
        extension,
        contentType: MediaTypeByExtension[extension] || file.type,
      });
    }));
  };

  const handleClick = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    onClick();
  };


  const iconClassName = `${classes.uploadIcon} ${
    disabled && lock ? classes.uploadIconLocked : ''
  }`;

  return (
    <Dropzone
      className={classes.root}
      onDropAccepted={onDrop}
      multiple={true}
      accept={acceptTypes}
      disabled={disabled}
      disabledClassName={classes.disabled}
    >
      <div className={classes.title} onClick={handleClick}>
        <svgIcons.Upload className={iconClassName} />
        <span className={classes.label}>Click or drag to upload files</span>
      </div>
    </Dropzone>
  );
};

export default withStyles(styles)(FileUploaderForModals);
