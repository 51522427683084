import { RowNode } from 'ag-grid-community';

import { nodes, fieldSchema } from 'data/collections/collections.selectors';
import { State } from 'reducers';
import { CommonNode } from 'types/response';
import { removeHTMLTag } from 'utilities/format';

import {
  WORKSPACE_STATUS_COL_ID,
  STATUS_PENDING,
  CLIENT_ID_FIELD_NAME,
} from './constants';

/**
 * From unixstamp to US date
 * After pending status, format the created date
 * i.e. 1619845200 => 05/01/2019
 * @param createdAt
 * @returns
 */
export const formatStatusDate = (createdAt: number): string =>
  new Date(createdAt).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

/**
  * Get record data in the grid by its ID
  *
  * @param id
  * @param records - all nodes in the grid
  * @returns
  */
export const getRecordById = (id: string, records: CommonNode[]) =>
  records.find((record) => record.id === id);

/**
 *
 * @param clientId
 * @param records
 * @returns
 */
export const getRecordByClientId = (clientId: string, colId: string, records: CommonNode[]) =>
  records.find((record) => removeHTMLTag(record['fields']?.[colId] as string).toLowerCase() === clientId.toLowerCase());

/**
 *
 * @param clientId
 * @param records
 * @returns
 */
export const getRecordsByClientId = (clientId: string, colId: string, records: CommonNode[]) =>
  records.filter((record) => removeHTMLTag((record?.['fields']?.[colId] || '' )?.toString()).toString()?.toLowerCase() === clientId?.toString()?.toLowerCase());

/**
 * Filter records to edit by status and availability
 *
 * @param ids
 * @param fieldStatusID
 * @param records
 * @returns
 */
export const filteringRecords = (
  ids: string[],
  fieldStatusID:
  string, records: CommonNode[]) =>
  ids.reduce((acc, id) => {
    const record = getRecordById(id, records);
    const noProcess = !(record?.fields?.[fieldStatusID]);
    if (record && noProcess) acc.push(record);

    return acc;
  }, [] as CommonNode[]);

/**
 * Scraping all Grid data
 */
export const getGridData = (state: State) => ({
  columns: Object.values(fieldSchema(state)),
  records: nodes(state),
});

/**
 * Parse payload to submit to the backend
 * Replace ids to field names for row/record fields's
 *
 * @param record
 * @param columns
 * @returns
 */
export function mapFields(record, columns): Pick<RowNode, 'data'> {
  const baseObj = {
    ...record,
  };
  const fields = record.fields || {};
  baseObj.fields = columns.reduce((acc, col) => {
    const { _id, title, fieldName, name } = col ?? {};
    if (_id) {
      const keyName = fieldName || name || title;
      const value = fields[_id];
      // @TODO: Better implement a new Cell renderer for plain/raw text
      //        In order to avoid removing tags
      // Remove all HTML Tags from grid cells
      acc[keyName] = value ? removeHTMLTag(value) : '';
    }

    return acc;
  }, {});

  return baseObj;
}

/** */
export const parseRecordFields = (records, columns) =>
  records.map((rec) => mapFields(rec, columns));

export const splitRecordsByWorkspaces = (acc, record) => {
  const status = record?.fields?.[WORKSPACE_STATUS_COL_ID];

  if (status && (status !== STATUS_PENDING)) {
    acc.withWorkspaces.push(record);
  } else {
    acc.noWorkspaces.push(record);
  }
  return acc;
};

/** */
export const getColumnClientId = (col) =>
  col['fieldName'] === CLIENT_ID_FIELD_NAME;

/** */
export const getIdsForPendingStatus = (state) =>
  state.workspaceCreate.idsForPendingOrganizers;

/** */
export const getNextTick = (state) =>
  state.workspaceCreate.nextTickId;
