import { Draft, DraftArray } from 'immer';

import { Role } from '../accounts/types';

export enum NotificationDistributionType {
  INSTANTLY = 'instantly',
  DAILY = 'daily',
  NONE = 'none',
}

export interface Notification {
  mentioned: NotificationDistributionType;
  addedInRecord: NotificationDistributionType;
  files: NotificationDistributionType;
}

export interface UserSettings {
  sendOnEnter?: boolean;
  hideModifyFieldConfirmModal?: boolean;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  color?: string;
  permissions: DraftArray<string[]>;
  thumbnailURL?: string;
  phone?: string;
  notificationPreferences?: Notification;
  status?: 'pending' | 'active';
  settings?: UserSettings;
  roles?: DraftArray<Role[]>;
}

export type UserState = {
  user: Draft<User>;
  isLoading: boolean;
  error: object | null;
};

export enum RoleNames {
  Administrator = 'Administrator',
  Editor = 'Editor',
  Reader = 'Reader',
}
