import React from 'react';

import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import get from 'lodash/get';

import describeDiff from './describeDiff';
import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = get(activity, 'eventBy.displayName');
  const databaseTitle = get(activity, 'references.database.title');

  if (activity.action === ActivityItemStatuses.CREATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          created a new column
        <strong className={'bold'}> {activity.references.title} </strong>
          in the
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
          database.
      </span>);
  }
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          edited field in
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.DELETED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          deleted the column
        <strong className={'bold'}> {activity.references.title} </strong>
          in the
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
          database.
      </span>);
  }
  return 'activity';
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (describeDiff(activity.diff));
  }
}


export default {
  describeActivity,
  describeActivityDetails,
};
