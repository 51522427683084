import { updateDatabaseSuccess } from 'data/databases/databases.actions';
import { mapDatabaseResponse } from 'data/collections/collections.mapper';
import { CollectionTypes } from 'types/schema';
import { createCollectionItemSuccess } from '../../collections/collections.actions.new';
import pluralize from 'pluralize';
import { notifications } from '../../ui/notifications/notifications.actions';
import { push } from 'connected-react-router';

class DatabaseEntity {
  updated(data) {
    const store = window.appStore;
    const databaseType = data?.item?.type && pluralize(data?.item?.type) === CollectionTypes.databases ? CollectionTypes.databases : CollectionTypes.taskdbs;
    store.dispatch(updateDatabaseSuccess(mapDatabaseResponse({ body: data.item }), databaseType));
  }

  duplicated(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    if (data.eventBy !== currentUserId) {
      return;
    }

    const databaseType = data?.item?.type && pluralize(data?.item?.type) === CollectionTypes.databases ? CollectionTypes.databases : CollectionTypes.taskdbs;
    store.dispatch(createCollectionItemSuccess(mapDatabaseResponse({ body: { ...data.item, duplicated: true } }), databaseType));
    store.dispatch(notifications.success({ title: 'Database duplicated', message: 'You can now access duplicated database' }));

    const sheet = data?.item?.type === 'task' ? CollectionTypes.tasksheets : CollectionTypes.sheets;
    const { workspaceID: workspaceId, _id: databaseId } = data.item;

    if (workspaceId && databaseId) {
      const databaseUrl = `/workspaces/${workspaceId}/${databaseType}/${databaseId}/${sheet}/default/items`;
      store.dispatch(push(databaseUrl));
    }
  }
}

export default new DatabaseEntity();
