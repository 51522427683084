import * as React from 'react';
import { range } from 'lodash';
import { IStatusPanelParams, Column } from 'ag-grid-community';
import { FilterType } from 'types/gridOptions';
import './AggregationStatusBar.scss';

export interface AggregationStatusBarState {
    sum: number;
    min: number;
    max: number;
    avg: number;
    showStatusBar: boolean;
}

export interface SelectionCalculatedValues {
    min: number;
    max: number;
    avg: number;
    sum: number;
    count: number;
}

class AggregationStatusBarComponent extends React.Component<IStatusPanelParams> {
    state: AggregationStatusBarState = {
      sum: 0,
      min: 0,
      max: 0,
      avg: 0,
      showStatusBar: false,
    };

    constructor(props: IStatusPanelParams) {
      super(props);
      this.props.api.addEventListener('rangeSelectionChanged', this.onRangeSelectionChanged);
    }

    public render() {
      if (!this.state.showStatusBar) return null;
      return (
        <>
          <div className="aggregation-status-container">
            <div className="aggregation-status-container__item">
              <span className="aggregation-status-container__item__title">Average:</span>
              <span className="aggregation-status-container__item__value"> {this.state.avg}</span>
            </div>
            <div className="aggregation-status-container__item">
              <span className="aggregation-status-container__item__title">Min:</span>
              <span className="aggregation-status-container__item__value"> {this.state.min}</span>
            </div>
            <div className="aggregation-status-container__item">
              <span className="aggregation-status-container__item__title">Max:</span>
              <span className="aggregation-status-container__item__value"> {this.state.max}</span>
            </div>
            <div className="aggregation-status-container__item">
              <span className="aggregation-status-container__item__title">Sum:</span>
              <span className="aggregation-status-container__item__value"> {this.state.sum}</span>
            </div>
          </div>
        </>
      );
    }

    private calculateSelections = (): SelectionCalculatedValues => {
      let count = 0;
      let sum = 0;
      let min;
      let max;

      const selections = this.props.api.getCellRanges();
      if (selections) {
        selections.forEach(selection => {
          if (selection.startRow && selection.endRow) {
            const columns = selection.columns;
            const startRow = Math.min(selection.startRow.rowIndex, selection.endRow.rowIndex);
            const endRow = Math.max(selection.startRow.rowIndex, selection.endRow.rowIndex) + 1;

            range(startRow, endRow).forEach(rowIndex => {
              columns.forEach((column: Column) => {
                if (!column.getColDef().filterParams || column.getColDef().filterParams.type === FilterType.Date) return;
                const rowModel = this.props.api.getModel();
                const rowNode = rowModel.getRow(rowIndex);
                if (!rowNode) return;
                const value = parseFloat(this.props.api.getValue(column, rowNode));
                if (!this.isValidNumber(value)) return;

                count++;
                sum += value;
                min = min === undefined || min > value ? value : min;
                max = max === undefined || max < value ? value : max;
              });
            });
          }
        });
      }

      return {
        sum,
        count,
        min: min || 0,
        max: max || 0,
        avg: sum / count || 0,
      };
    };

    private onRangeSelectionChanged = () => {
      if (this.state.showStatusBar) {
        this.setState({ showStatusBar: false });
      }

      const calculatedSelections = this.calculateSelections();
      const { count, ...otherProps } = calculatedSelections;
      if (count > 1) {
        this.setState({
          ...otherProps,
          showStatusBar: true,
          avg: Number.isInteger(calculatedSelections.avg) ? calculatedSelections.avg : parseFloat(Number(calculatedSelections.avg).toFixed(2)),
        });
      }
    };

    private isValidNumber = (value: number): boolean => {
      return !isNaN(value) && isFinite(value);
    };
}

export default AggregationStatusBarComponent;
