import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';
import { typeIcon } from '../../../pages/Files/Renderers/CellRenderers/IconRenderer';
import { slugifyText } from '../../../utilities/common';
import { getGridRowMaxTextLines, getGridRowWrapLines, removeHTMLTagsFromText } from '../../AgGrid/utils';
import BaseRenderer from '../BaseRendererPrototype';
import GenericTextRenderer from '../GenericTextRenderer';
import { isNullEmptyBlankOrUndefined } from '../../NodeFieldData/filters/helpers';
function MultiSelectRenderer() {
  return true;
}

function richTextToChoices(text) {
  const value = removeHTMLTagsFromText(text);
  return value.split(',').map((choice) => slugifyText(`${choice}`));
}

MultiSelectRenderer.prototype = Object.create(BaseRenderer.prototype);
MultiSelectRenderer.prototype.createElement = function(params) {
  const { node, isGrid, choices, value, column } = params;

  const parsedValue = richTextToChoices(value);

  const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;
  const element = document.createElement('div');
  element.id = `${node.id}-${column.colId}`;
  const wrapClass = (isGrid && node) ? getGridRowWrapLines(node.rowHeight) : 'single-choice-item';
  element.setAttribute('class', wrapClass);

  if (parsedValue && parsedValue.length > 0) {
    let found = false;
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    for (let valueIndex = 0; valueIndex < parsedValue.length; valueIndex++) {
      const item = choices[parsedValue[valueIndex]];

      if (item && isNullEmptyBlankOrUndefined(item.label)) {
        continue;
      }

      if (!item) {
        continue;
      }

      const valueWrapper = document.createElement('div');
      valueWrapper.style = `
          display: inline-flex;
          align-items: center;
          margin-right: 6px;
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-shrink: 0;
      `;

      const valueElement = document.createElement('div');
      valueElement.style = GenericTextRenderer(lines);
      valueElement.style.whiteSpace = 'nowrap';
      valueElement.innerHTML = item.label;

      if (!found && currentQuickSearch && item?.label?.toLowerCase()?.includes(currentQuickSearch.toLowerCase())) {
        found = true;
      }

      const bulletColor = document.createElement('div');
      bulletColor.setAttribute('class', 'grid-choice-item-bullet');
      bulletColor.style = `
        background-color: ${item ? (item.color || 'lightGrey') : 'lightGrey'};
      `;
      valueWrapper.append(bulletColor);
      valueWrapper.append(valueElement);
      element.append(valueWrapper);
    }

    if (params.eGridCell) {
      params.eGridCell.classList.remove('highlighted-cell');
      if (currentQuickSearch && found) {
        params.eGridCell.classList.add('highlighted-cell');
      }
    }
  } else {
    const emptyElement = document.createElement('div');
    emptyElement.style = `
      width: 100%;
    `;
    element.append(emptyElement);
  }

  if (params.eGridCell && params.colDef && params.colDef.editable) {
    const arrowIcon = document.createElement('span');
    arrowIcon.setAttribute('class', 'dropdown-button');
    arrowIcon.style = `margin-right: -15px;`;
    const icon = typeIcon['dropDown'].cloneNode(true);
    icon.style = `
      height: 14px;
      color: #37474f;
      display: block;
    `;
    arrowIcon.append(icon);
    element.append(arrowIcon);

    this.editingOverlay = document.createElement('span');
    this.editingOverlay.setAttribute('class', 'singlechoice-to-cell-editor');
    element.append(this.editingOverlay);
  }

  return {
    element,
  };
};

MultiSelectRenderer.prototype.init = function(params) {
  const { valueElement, element } = this.createElement(params);
  if (valueElement) this.valueElement = valueElement;
  this.params = params;
  this.element = element;
  this.eGridCell = params.eGridCell;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

MultiSelectRenderer.prototype.getGui = function() {
  return this.element;
};

MultiSelectRenderer.prototype.refresh = function(params) {
  return false;
};

MultiSelectRenderer.prototype.destroy = function() {
  return true;
};

export default MultiSelectRenderer;
