import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { useState } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import SelfSelectingInput from 'components/SelfSelectingInput';
import Typography, { Variant } from 'components/Typography';
import * as Styles from 'styles';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { filterDropdown } from 'utilities/filterDropdown';

import { DropdownItem } from './data';

interface Props extends WithStyles<ClassKey> {
  dropdownData: DropdownItem[];
  onItemClick?: (item: DropdownItem) => void;
  dropdownLabel?: string;
  searchItemPlaceholder?: string;
  selectItemPlaceholder?: string;
}

function Dropdown({
  classes,
  dropdownData,
  onItemClick,
  dropdownLabel,
  searchItemPlaceholder = '',
  selectItemPlaceholder = '',
}: Props): JSX.Element {
  const [selected, setSelected] = useState<boolean>(true);
  const [queryValue, setQueryValue] = useState('');
  const [currentDropdownItem, setCurrentDropdownItem] = useState<DropdownItem | null>();
  const [actionItems, setDropdownItems] = useState<DropdownItem[]>(dropdownData);

  const handleItemClick = (item: DropdownItem) => (e: React.MouseEvent): void => {
    setCurrentDropdownItem(item);
    // Handle item specific action
    if (item.action instanceof Function) {
      item.action();
    }
    // Handle general item click function
    if (onItemClick instanceof Function) {
      onItemClick(item);
    }
    // Handle prop to keep open the menu after item clicked
    if (!item.keepMenuOpenAfterClick) {
      setSelected(true);
    }
  };

  const onToggleActionType = () => {
    setSelected(!selected);
  };

  const onActionTypeQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterString = event.currentTarget.value;
    setQueryValue(filterString);
    setDropdownItems(filterDropdown<DropdownItem, 'title'>(dropdownData, 'title', filterString));
  };

  return (
    selected ?
      <>
        {dropdownLabel && <InputLabel shrink={true} className={classes.inputLabel}>
          {dropdownLabel}
        </InputLabel>}
        <div className={classes.dropdownWrapper}>
          <div className={classes.dropdown} onClick={onToggleActionType}>
            {currentDropdownItem && <currentDropdownItem.Icon className={classes.icon} />}
            <Typography variant={Variant.Input} className={classes.label}>
              {currentDropdownItem ? currentDropdownItem.title : selectItemPlaceholder}
            </Typography>
            <svgIcons.Dropdown className={classes.icon} />
          </div>
        </div>
      </>
      :
      <>
        <div className={classes.wrapper}>
          <div className={classes.input}>
            <svgIcons.Search className={classes.icon} />
            <Typography
              variant={Variant.Input}
              className={classes.filterQuery}
            >
              <SelfSelectingInput
                value={queryValue}
                onChange={onActionTypeQueryChange}
                placeholder={searchItemPlaceholder}
              />
            </Typography>
            <svgIcons.Close
              className={classes.icon}
              onClick={onToggleActionType}
            />
          </div>
        </div>
        <MenuList>
          {actionItems.map(item => {
            return (
              <MenuItem key={item.id} className={classes.menuItemClasses} onClick={handleItemClick(item)}>
                <item.Icon className={classes.menuItemIcon} />
                <Typography variant={Variant.MenuItem}>
                  {item.title}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </>
  );
}

type ClassKey
  = 'wrapper'
  | 'icon'
  | 'dropdown'
  | 'label'
  | 'input'
  | 'filterQuery'
  | 'menuItemIcon'
  | 'dropdownWrapper'
  | 'menuItemClasses'
  | 'inputLabel'
  ;

const styles = withStyles<ClassKey>({
  inputLabel: { marginTop: 8 },
  dropdownWrapper: {
    marginTop: 5,
    cursor: 'pointer',
  },
  wrapper: {
    backgroundColor: Colors.athensGrey,
    marginTop: 5,
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropdown: {
    ...Styles.hover(Colors.athensGrey),
    height: 25,
    marginBottom: 4,
    padding: '7px 9px',
    backgroundColor: Colors.athensGrey,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    flex: 1,
    margin: '0 5px',
  },
  icon: {
    width: 11,
    height: 11,
    color: Colors.slateTwo,
  },
  input: {
    width: '100%',
    padding: '5px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  filterQuery: {
    ...Styles.focus,
    backgroundColor: Colors.athensGrey,
    margin: '0 7px',
    border: '1px solid transparent',
    flex: 1,
  },
  menuItemIcon: {
    width: 10,
    height: 10,
    marginRight: 6,
    color: Colors.slateTwo,
  },
  menuItemClasses: {
    padding: '0 8px',
  },
});

export default styles(Dropdown);
