import * as React from 'react';

import { offYellow } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

interface Props {
  isBookmarked: boolean;
}

export default function FavoriteIcon({ isBookmarked }: Props): JSX.Element {
  if (!isBookmarked) {
    return <svgIcons.StarLined width={12} height={12} />;
  }

  return (
    <svgIcons.StarFilled width={12} height={12} style={{ color: offYellow }} />
  );
}
