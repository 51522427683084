import tenantConfig from 'mocks/tenantConfig';

import { TenantConfig } from './types/tenantConfig';

/**
 * @deprecated This method is deprecated, use getApiV2Url instead
 */
export function getApiUrl(requestedURL?: string): string | undefined {
  return getApiV2Url(requestedURL);
}

export function getApiV2Url(requestedURL?: string): string | undefined {
  const shouldRequestBeRoutedToApiGW = requestedURL && Array.isArray(window.apiGWAuthorizedEndpoints) &&
      window.apiGWAuthorizedEndpoints.some(apiGWRoute => {
        return !!requestedURL?.match(apiGWRoute);
      });
  return window.apiEndpoints && (shouldRequestBeRoutedToApiGW ? window.apiEndpoints.v2ApiGWEndpoint : window.apiEndpoints.v2ApiEndpoint);
}

export function getIdToken(): string | null {
  return localStorage.getItem('id_token');
}

export function getOrigin(): string {
  return window.location.origin;
}

export function getXsrfState(): string | null {
  return localStorage.getItem('xsrf_state');
}

export function isInDevelopment(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function logReduxState(): boolean {
  return process.env.REACT_APP_LOG_REDUX === 'true';
}

export function isInProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function getSocketUrl(): string | undefined {
  return window.apiEndpoints && window.apiEndpoints.wsEndpoint;
}

export function getItemsApiEndpoint(): string | undefined {
  return window.apiEndpoints && window.apiEndpoints.itemsApiEndpoint;
}

export function getFileProviderApiEndpoint(): string | undefined {
  return window.apiEndpoints && window.apiEndpoints.fileProviderApiEndpoint;
}

export function getTaxOrganizerApiEndpoint(): string | undefined {
  return window.apiEndpoints && window.apiEndpoints.taxOrganizerApiEndpoint;
}

export function getAssureSignApiEndpoint(): string | undefined {
  return window.appStore?.getState()?.app?.tenant?.thirdParty?.assureSign
    ?.apiEndpoint;
}

export function getWorkspaceCreateApiEndpoint(): string {
  return window.apiEndpoints?.['workspaceCreateEndpoint'] ?? '';
}

export function getApiGatewayIdentityEndpoint(): string | undefined {
  return process.env.REACT_APP_IDENTITY_API_ENDPOINT;
}

export function getDataDogEnvironmentIdentifier(): string {
  return process.env.REACT_APP_DD_ENV_ID ?? '';
}
export function getDataDogApplicationIdentifier(): string {
  return process.env.REACT_APP_DD_APP_ID ?? '';
}
export function getDataDogClientToken(): string {
  return process.env.REACT_APP_DD_CLIENT_TOKEN ?? '';
}

export function getTenantConfig(): TenantConfig {
  if (process.env.NODE_ENV === 'test') return tenantConfig;
  return (
    (process.env.REACT_APP_TENANT_CONFIG &&
      JSON.parse(process.env.REACT_APP_TENANT_CONFIG)) || {
      enabledFeatures: {},
    }
  );
}
