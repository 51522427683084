import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { rgba } from 'styles/utils/colorUtils';
import * as Colors from 'styles/colors';
import { PaperProps } from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/core/styles/withStyles';

type ClassKey
  = 'wrapper'
  | 'notificationsIconWrapper'
  | 'notificationsCounter'
  | 'icon'
  | 'root'
  | 'inline'
  | 'title'
  | 'popoverHeader'
  | 'popoverTools'
  | 'popoverToolsList'
  | 'popoverToolsListItem'
  | 'notificationItem'
  | 'notificationItemText'
  | 'notificationItemTagText'
  | 'notificationsListDivider'
  | 'notificationAction'
  | 'dotIcon'
  | 'notificationUnread'
  | 'dropdownBox'
  | 'dropdownBoxText'
  | 'dropdownBoxIcon'
  | 'emptyNotificationsList'
  | 'emptyNotificationsListText'
  | 'loadingMore'
  | 'notificationExtraActions'
  ;

export const notificationsMenuStyles: Record<ClassKey, CSSProperties> = {
  notificationsIconWrapper: {
    position: 'relative',
  },
  notificationsCounter: {
    height: 12,
    minWidth: 12,
    backgroundColor: Colors.pinkishOrange,
    position: 'absolute',
    fontSize: 10,
    textAlign: 'center',
    display: 'flex',
    right: 14,
    color: Colors.white,
    top: -3,
    borderRadius: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 2,
    paddingLeft: 2,
  },
  wrapper: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: rgba(Colors.white, 0.1),
    cursor: 'pointer',
  },
  icon: {
    width: '21px',
    height: '21px',
    margin: '0 16px',
    opacity: 0.5,
  },
  root: {
    width: '100%',
    backgroundColor: Colors.white,
    height: '100%',
    overflowY: 'auto',
  },
  inline: {
    display: 'inline',
  },
  title: {
    color: Colors.charocalGrey,
    fontWeight: 400,
    flex: 1,
  },
  popoverHeader: {
    padding: '27px 31px',
    display: 'flex',
    alignItems: 'center',
  },
  popoverTools: {
    padding: '15px 31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popoverToolsList: {
    padding: '12px 31px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 200,
  },
  popoverToolsListItem: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationItem: {
    padding: '15px 31px',
  },
  notificationItemText: {
  },
  notificationItemTagText: {
    color: Colors.brightBlue,
    cursor: 'pointer',
  },
  notificationsListDivider: {
    margin: 0,
  },
  notificationAction: {
    right: 25,
  },
  dotIcon: {
    height: 9,
    width: 9,
    borderRadius: 9,
    backgroundColor: Colors.pinkyRed,
  },
  notificationUnread: {
    backgroundColor: Colors.lightBlueTransparent,
  },
  dropdownBox: {
    backgroundColor: Colors.athensGrey,
    borderRadius: '.25rem',
    width: '200px',
    height: '30px',
    cursor: 'pointer',
    marginRight: '2px',
  },
  dropdownBoxText: {
    width: '170px',
    padding: '5px 10px',
    borderRadius: '2px',
  },
  dropdownBoxIcon: {
    fontSize: '1.3em',
    marginLeft: '5px',
    marginTop: '4px',
    color: Colors.slateTwo,
  },
  emptyNotificationsList: {
    marginTop: 30,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  emptyNotificationsListText: {
    textAlign: 'center',
    marginTop: 15,
    color: Colors.slateGrey,
  },
  loadingMore: {
    padding: '5px 0',
    textAlign: 'center',
  },
  notificationExtraActions: {
    'display': 'flex',
    'alignItems': 'center',
    '& > span': {
      color: Colors.slateTwo,
      fontFamily: 'Roboto',
      fontSize: '16px',
      letterSpacing: '0.22px',
      lineHeight: '2px',
      margin: '0 8px',
      height: '11px',
    },
  },
};

export const paperPropsStyle: Partial<PaperProps> = {
  style: {
    left: 'auto!important',
    right: 0,
    marginTop: 15,
    paddingTop: 0,
    overflow: 'hidden',
    height: 'calc(100% - 52px)',
    paddingBottom: 160,
    width: 450,
  },
};

export const NotificationsDropdown = withStyles(() => ({
  input: {
    backgroundColor: Colors.athensGrey,
    borderRadius: '.25rem',
    justifyContent: 'center',
    fontSize: 13,
    paddingLeft: 13,
    fontWeight: 500,
    textAlign: 'center',
  },
}))(InputBase);
