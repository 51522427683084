import * as React from 'react';
import { connect } from 'react-redux';

import * as ModalActions from 'data/modals/actions';
import { Actions as CollectionActions } from 'data/collections/collections.actions';

import Confirm from '../Confirm';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
  deleteField: typeof CollectionActions.deleteField;
}

interface OwnProps {
  columnId: string;
}

export type Props = OwnProps & DispatchProps;

const DeleteGridColumn = (props: Props): JSX.Element => {
  const handleConfirm = (): void => {
    props.deleteField(props.columnId);
  };

  return (
    <Confirm
      title="Are you sure you want to delete this column?"
      close={props.close}
      onConfirm={handleConfirm}
      confirmLabel="Delete"
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  deleteField: CollectionActions.deleteField,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteGridColumn);
