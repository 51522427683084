import { AgGridFilterModel } from 'components/AgGrid/AgGridApi';
import { DateFormat } from 'components/Fields/data';
import { Column } from 'types/schema';

export enum FilterType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  Singlechoice = 'singlechoice',
  Multiplechoice = 'multiplechoice'
}

export function isMultiValueFilterType(filterType: FilterType): boolean {
  return filterType === FilterType.Singlechoice || filterType === FilterType.Multiplechoice;
}

export enum Operator {
  Equals = 'isEqual',
  NotEquals = 'isNotEqual',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  Contains = 'contains',
  NotContains = 'notContains',
  LessThan = 'isLessThan',
  LessThanOrEquals = 'isLessThanOrEqual',
  GreaterThan = 'isGreaterThan',
  GreaterThanOrEquals = 'isGreaterThanOrEqual',
  InRange = 'isInRange',
  NotInRange = 'isNotInRange',
  Empty = 'isEmpty',
  NotEmpty = 'isNotEmpty',
  Is = 'is',
  IsNot = 'isNot',
  IsAnyOf = 'isAnyOf',
  IsNoneOf = 'isNoneOf',
  HasAnyOf = 'hasAnyOf',
  HasAllOf = 'hasAllOf',
  IsExactly = 'isExactly',
  HasNoneOf = 'hasNoneOf'
}

export interface BaseFilter {
  columnId: string;
  operator: Operator;
}

export interface TextFilter extends BaseFilter {
  type: FilterType.Text;
  filter: string;
}

export interface NumberFilter extends BaseFilter {
  type: FilterType.Number;
  filter: number | string | null;
}

export interface BooleanFilter extends BaseFilter {
  type: FilterType.Boolean;
  filter: boolean;
}

export interface DateFilter extends BaseFilter {
  type: FilterType.Date;
  filter: string | null;
  dateFormat: DateFormat;
}

export interface SingleChoiceFilter extends BaseFilter {
  type: FilterType.Singlechoice;
  filter: string[];
}

export interface MultipleChoiceFilter extends BaseFilter {
  type: FilterType.Multiplechoice;
  filter: string[];
}

export type RegularFilter =
  TextFilter
  | NumberFilter
  | BooleanFilter
  | DateFilter
  | SingleChoiceFilter
  | MultipleChoiceFilter;

export interface FilterModel {
  regularFilters: RegularFilter[];
  searchFilters: AgGridFilterModel;
  quickSearch: string;
}

export enum FilterOperators {
  Equals = 'is equal',
  NotEquals = 'is not equal',
  StartsWith = 'starts with',
  EndsWith = 'ends with',
  Contains = 'contains',
  NotContains = 'not contains',
  LessThan = 'is less than',
  LessThanOrEquals = 'is less than or equal',
  GreaterThan = 'is greater than',
  GreaterThanOrEquals = 'is greater than or equal',
  InRange = 'is in range',
  Empty = 'is empty',
  NotEmpty = 'is not empty',
  Is = 'is',
  IsNot = 'is not',
  IsAnyOf = 'is any of',
  IsNoneOf = 'is none of',
  HasAnyOf = 'has any of',
  HasAllOf = 'has all of',
  IsExactly = 'is exactly',
  HasNoneOf = 'has none of'
}

export enum SortOperator {
  Asc = 'asc',
  Desc = 'desc'
}

export interface SortModel {
  colId: string;
  sort: SortOperator;
}

export interface SortById {
  [key: string]: SortModel;
}

export interface SortModelState {
  byId: SortById;
  allIds: string[];
}

export interface CalendarEvent {
  end?: Date;
  node: any;
  start?: Date;
  title: string;
  allDay: boolean;
  color: string;
}

export interface ColumnsMapById {
  [key: string]: Column;
}
