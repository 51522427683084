import * as React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import Typography, { Variant } from '../Typography';

import './style.scss';

const PrimaryFieldTooltip = withStyles(() => ({
  popper: {
    opacity: 1,
    marginTop: '-2px',
  },
  tooltip: {
    boxShadow: '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    backgroundColor: '#ffffff',
    maxWidth: 'none',
    marginLeft: -16,
    zIndex: 9999,
  },
}))(Tooltip);

export default function PrimaryColumnIcon(): JSX.Element {
  return (
    <PrimaryFieldTooltip
      interactive
      placement="bottom-start"
      title={
        <div className="primary-column-tooltip-wrapper">
          <span style={{ color: Colors.charocalGrey }}><svgIcons.PrimaryKey /></span>
          <div>
            <Typography variant={Variant.ToolTipText}>This is the table’s primary field. The name is meant to be </Typography>
            <Typography variant={Variant.ToolTipText}>a short, unique representation of each record in your table.</Typography>
          </div>
        </div>
      }
    >
      <span className="primary-column-icon"><svgIcons.PrimaryKey /></span>
    </PrimaryFieldTooltip>
  );
}
