import { createSingleLineOfTextElement } from 'components/CellRenderers/singlelineoftext/SingleLineOfTextRenderer';
import IconButton, {
  iconType,
} from 'components/CellRenderers/utils/IconButton';
import { Color, Size } from 'components/IconButton';

function IdCellRenderer() {
  //
}

IdCellRenderer.prototype.init = function(params) {
  const { valueElement, element } = createSingleLineOfTextElement(params);
  if (valueElement) {
    valueElement.innerText = params.rowIndex + 1;
    this.valueElement = valueElement;
  }
  const div = document.createElement('div');
  div.className = 'update-user-arrows';
  // @ts-ignore
  const expandRecordIcon = IconButton({
    color: Color.DarkGray,
    size: Size.Medium,
    className: 'expandRecordButton',
    iconClassName: 'iconMedium',
    icon: iconType.EXPAND,
  });
  expandRecordIcon.addEventListener('click', this.expandRecord);
  this.expandRecordIcon = expandRecordIcon;
  div.appendChild(expandRecordIcon);
  element.appendChild(div);
  this.element = element;
};

IdCellRenderer.prototype.getGui = function() {
  return this.element;
};

IdCellRenderer.prototype.refresh = function(params) {
  if (this.element && params.value !== undefined) {
    if (this.valueElement) {
      this.valueElement.innerHTML = params.value;
    } else {
      this.element.innerHTML = params.value;
    }
    return true;
  }
};

IdCellRenderer.prototype.destroy = function() {
  if (this.expandRecordIcon) {
    this.expandRecordIcon.removeEventListener('click', this.expandRecord);
  }
  return true;
};

export default IdCellRenderer;
