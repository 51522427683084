import * as React from 'react';
import {
  FullscreenExitIcon,
  FullscreenIcon,
  PageOfPages,
  PaginationIconNext,
  PaginationIconPrev,
  PreviewToolsBar,
  ToolButton,
  ToolsGroup,
  ZoomInIcon,
  ZoomOutIcon,
} from 'components/DocumentPreview/Tools/PreviewTools.style';
import { maxZoom, minZoom, zoomIn, zoomOut } from 'components/DocumentPreview/utils';
import Print from 'components/DocumentPreview/Tools/Print';
import svgIcons from 'styles/svgIcons';
import { PreviewNode } from 'types/response/previewNode';

interface Props {
  onCollapse?: () => void;
  onGoToPage: (index: number) => void;
  onPrintError: () => void;
  onZoom: (zoom: number) => void;
  onFullscreen: () => void;
  fullscreen: boolean;
  zoom: number;
  collapsed?: boolean;
  currentPageIndex: number;
  totalPages: number;
  previews: PreviewNode[];
  show: boolean;
}

export default class PreviewTools extends React.PureComponent<Props> {
  handleToNextPage = () => this.props.onGoToPage(this.props.currentPageIndex + 1);

  handleToPrevPage = () => this.props.onGoToPage(this.props.currentPageIndex - 1);

  handleZoomIn = () => this.props.onZoom(zoomIn(this.props.zoom));

  handleZoomOut = () => this.props.onZoom(zoomOut(this.props.zoom));

  render() {
    const {
      onCollapse, collapsed, fullscreen, currentPageIndex, show,
      totalPages, zoom, onPrintError, previews, onFullscreen,
    } = this.props;

    const currentPage = currentPageIndex + 1;

    const IsCollapsedIcon = collapsed ? svgIcons.Expand : svgIcons.Collapse;

    return (
      <PreviewToolsBar show={show}>
        <ToolsGroup>
          <ToolButton onClick={onCollapse}>
            <IsCollapsedIcon height="1em" />
          </ToolButton>
        </ToolsGroup>

        {totalPages > 1 &&
        <ToolsGroup>
          <ToolButton onClick={this.handleToPrevPage} disabled={currentPageIndex === 0}>
            <PaginationIconPrev/>
          </ToolButton>
          <PageOfPages>{`${currentPage} / ${totalPages}`}</PageOfPages>
          <ToolButton onClick={this.handleToNextPage} disabled={currentPage === totalPages}>
            <PaginationIconNext/>
          </ToolButton>
        </ToolsGroup>
        }

        <ToolsGroup>
          <ToolButton onClick={this.handleZoomIn} disabled={zoom > maxZoom}>
            <ZoomInIcon/>
          </ToolButton>

          <ToolButton onClick={this.handleZoomOut} disabled={zoom < minZoom}>
            <ZoomOutIcon/>
          </ToolButton>
        </ToolsGroup>

        <ToolsGroup>
          <ToolButton onClick={onFullscreen}>
            {fullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
          </ToolButton>
        </ToolsGroup>

        <ToolsGroup>
          <Print previews={previews} onError={onPrintError}/>
        </ToolsGroup>
      </PreviewToolsBar>
    );
  }
}

