import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import { fontFamily } from 'styles/fonts';
import * as Colors from 'styles/colors';
import { DatabaseNode } from 'types/response/databaseNode';
import { WorkspaceNode } from 'types/response/workspaceNode';

import ModalActions from '../Modal/Actions';
import ModalContent from '../Modal/Content';
import ModalHeader from '../Modal/Header';
import Switch from '../../Switch';

interface OwnProps {
  database: DatabaseNode;
  workspace: WorkspaceNode;
  availableWorkspaces: WorkspaceNode[];
  shouldDuplicateRecords: boolean;
  shouldDuplicateComments?: boolean;
  onWorkspaceChange(workspace: WorkspaceNode): void;
  onShouldDuplicateRecordsChange(): void;
  onShouldDuplicateCommentsChange?: () => void;
  onDuplicate(): void;
  onClose(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function DuplicateDatabase(props: Props): JSX.Element {
  return (
    <React.Fragment>
      <ModalHeader onClose={props.onClose}>
        {`Duplicate ‘${props.database.title}’`}
      </ModalHeader>
      <ModalContent>
        <div className={props.classes.distanced}>
          <Switch
            label="Duplicate Records"
            labelRight
            selected={props.shouldDuplicateRecords}
            onClick={props.onShouldDuplicateRecordsChange}
          />
        </div>
        {props.shouldDuplicateRecords && (
          <div className={props.classes.distanced}>
            <Switch
              label="Duplicate Comments"
              labelRight
              selected={props.shouldDuplicateComments}
              onClick={props.onShouldDuplicateCommentsChange}
            />
          </div>
        )}
      </ModalContent>
      <ModalActions
        submitLabel="Duplicate"
        onCancel={props.onClose}
        onSubmit={props.onDuplicate}
      />
    </React.Fragment>
  );
}

type ClassKey
  = 'select'
  | 'selectInput'
  | 'distanced'
  ;

const styles = withStyles<ClassKey>({
  select: {
    width: 196,
    marginBottom: 15,
  },
  selectInput: {
    color: Colors.slateTwo,
    fontSize: 13,
    fontFamily,
    letterSpacing: 0.22,
    lineHeight: '22px',
  },
  distanced: {
    marginTop: '10px',
  },
});

export default styles(DuplicateDatabase);
