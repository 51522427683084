import * as React from 'react';

import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as keys from 'keycode-js';
import KeyHandler from 'react-key-handler';
import { compose } from 'recompose';

import Checkbox from '../../Checkbox';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import { ClassKey, styles } from './Boolean.style';

type Props
  = FieldEditorProps
  & WithStyles<ClassKey>
  ;

function BooleanEditor(props: Props): JSX.Element {
  function onClick() {
    if (props.readOnly) return;
    props.onChange(!props.value, true);
  }

  return (
    <div className={props.classes.wrapper}>
      <CheckboxKeyHandler onToggleCheckbox={onClick} />
      <Checkbox
        id={`chk${props.id}`}
        selected={getValue(props.value)}
        onClick={onClick}
      />
    </div>
  );
}

const enhance = compose<Props, {}>(
  wrapCellEditor({ stopEditingAfterChange: true }),
  styles,
);

export default enhance(BooleanEditor);

function getValue(value: unknown): boolean {
  if (typeof value === 'boolean') {
    return value;
  }

  return false;
}

interface CheckboxKeyHandlerProps {
  onToggleCheckbox(): void;
}

function CheckboxKeyHandler(props: CheckboxKeyHandlerProps): JSX.Element {
  return (
    <React.Fragment>
      <KeyHandler
        keyCode={keys.KEY_ENTER}
        onKeyHandle={props.onToggleCheckbox}
      />
      <KeyHandler
        keyCode={keys.KEY_RETURN}
        onKeyHandle={props.onToggleCheckbox}
      />
    </React.Fragment>
  );
}
