import './Button.style.scss';
import * as React from 'react';

import Button, { Variant } from 'components/Button';
import { Actions } from 'data/assureSign/assureSign.actions';
import { connect } from 'react-redux';


import wrapCellRenderer from '../components/wrapCellRenderer';
import { buttonActions } from './buttonActionsRegistry';
import { ButtonEditorFieldProps, EditorDispatchProps } from './types';

function ButtonEditor(props: ButtonEditorFieldProps): JSX.Element {
  const { label, permissions, lock } = props;
  const canUpdateItems = permissions?.includes('items:update');
  const showLockPermissionModal =
    !canUpdateItems || (!!lock && !permissions?.includes('fields:lock'));

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!showLockPermissionModal) {
      event.stopPropagation();
      if (buttonActions[props.action] instanceof Function) {
        buttonActions[props.action](props);
      } else {
        console.error('Missing action for the clicked button');
      }
    }
  };

  return (
    <span className="button-editor--wrapper">
      <Button
        className="button-editor--wrapper--button"
        label={label}
        variant={Variant.Primary}
        onClick={handleButtonClick}
        disabled={!canUpdateItems}
      />
    </span>
  );
}

const mapDispatchToProps: EditorDispatchProps = {
  preCancelEnvelope: Actions.preCancelEnvelope,
  preResendEnvelope: Actions.preResendEnvelope,
};

export default wrapCellRenderer(
  connect(null, mapDispatchToProps)(ButtonEditor),
);
