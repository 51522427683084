import {
  CreateCollectionItemRequestPayload,
  CreateCollectionItemWithAttachmentRequestPayload,
} from 'data/collections/collections.reducer';
import * as constants from 'data/collections/constants';
import { CommonNode } from 'types/response';
import { DocumentNode } from 'types/response/documentNode';
import { FieldNode } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import {
  Breadcrumb,
  Collection,
  CollectionTypes,
  HttpResponseViewport,
  Schema,
} from 'types/schema';
import { ActionType, createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as types from './collections.types';

export interface FetchDataCollectionPayload {
  url: string;
  queryParams?: Record<string, string>;
  selectViewId?: string;
}

export interface FetchDataPaginatedPayload {
  url: string;
  query?: string;
  page?: number;
}

export interface FetchDataCollectionSuccessPayload {
  breadcrumbs: Breadcrumb[];
  collection: Collection;
  permissions: string[];
  schema: Schema;
  viewport: HttpResponseViewport;
  views: {
    permissions: string[];
    nodes: ViewNode[];
  };
  fields: {
    permissions: string[];
    nodes: FieldNode[];
  };
  selectedViewId?: string;
}

export interface FetchDataCollectionErrorPayload {
  error: Error;
}

export interface UpdateNodeRequestPayload {
  url: string;
  data: Partial<DocumentNode>;
  isFullObject?: boolean;
  folderBreadcrumbUri?: string;
  mode?: string;
}

interface UpdateNodeSuccessPayload {
  node: CommonNode | DocumentNode;
  updatePayload: Partial<DocumentNode>;
  updatedByCurrentUser?: boolean;
}

interface UpdateNodeFailurePayload {
  error: string;
  previousNode?: CommonNode | DocumentNode;
  folderBreadcrumbUri?: string;
}

// Fetches collection along with views and fields data
// To be used for items, tasks, and documents
export const fetchDataCollection = createAsyncAction(
  'collections/FETCH_DATA_REQUEST',
  'collections/FETCH_DATA_SUCCESS',
  'collections/FETCH_DATA_FAILURE',
)<FetchDataCollectionPayload, FetchDataCollectionSuccessPayload, FetchDataCollectionErrorPayload>();

export const fetchCollectionRequest = createAction(
  'collections/FETCH_ALL_REQUEST',
  resolve => (payload: FetchDataPaginatedPayload, type?: string) => resolve(payload, type),
);

export const fetchCollectionSuccess = createAction(
  'collections/FETCH_ALL_SUCCESS',
  resolve => (payload: HttpResponseViewport, flag: CollectionTypes) => resolve(payload, flag),
);
export const fetchCollectionFailure = createAction(
  'collections/FETCH_ALL_FAILURE',
  resolve => (payload: Error, flag: CollectionTypes) => resolve(payload, flag),
);

export const createCollectionItemImportRequest = createAction(
  'collections/CREATE_ITEM_IMPORT_REQUEST',
  resolve =>
    (payload: CreateCollectionItemWithAttachmentRequestPayload, flag: CollectionTypes) =>
      resolve(payload, flag),
);

export const createCollectionItemImportSuccess = createAction(
  'collections/CREATE_ITEM_IMPORT_SUCCESS',
  resolve =>
    (payload: CommonNode, flag: CollectionTypes) =>
      resolve(payload, flag),
);

export const createCollectionItemImportFailure = createAction(
  'collections/CREATE_ITEM_IMPORT_FAILURE',
  resolve => (payload: Error, flag: CollectionTypes) =>
    resolve(payload, flag),
);

export const createCollectionItemRequest = createAction(
  'collections/CREATE_ITEM_REQUEST',
  resolve => (payload: CreateCollectionItemRequestPayload, flag: CollectionTypes) => resolve(payload, flag),
);
export const createCollectionItemSuccess = createAction(
  'collections/CREATE_ITEM_SUCCESS',
  resolve => (payload: CommonNode, flag: CollectionTypes) => resolve(payload, flag),
);
export const createCollectionItemFailure = createAction(
  'collections/CREATE_ITEM_FAILURE',
  resolve => (payload: Error, flag: CollectionTypes) => resolve(payload, flag),
);

export const copyCollectionRequest = createAction(
  constants.COPY_NODE_REQUEST,
  resolve => (payload: string, flag: CollectionTypes) => resolve(payload, flag),
);
export const copyCollectionSuccess = createAction(
  constants.COPY_NODE_SUCCESS,
  resolve => (payload: CommonNode, flag: CollectionTypes) => resolve(payload, flag),
);
export const copyCollectionFailure = createAction(
  constants.COPY_NODE_ERROR,
  resolve => (payload: Error, flag: CollectionTypes) => resolve(payload, flag),
);

export const updateNode = createAsyncAction(
  'collections/UPDATE_NODE_REQUEST',
  'collections/UPDATE_NODE_SUCCESS',
  'collections/UPDATE_NODE_FAILURE',
)<UpdateNodeRequestPayload, UpdateNodeSuccessPayload, UpdateNodeFailurePayload>();

export const setRowIndexMap = createStandardAction('collections/SET_ROW_INDEX_MAP')<Record<string, number>>();

export interface FetchChecklistsParams {
  itemId: string;
  databaseId: string;
  workspaceId: string;
}

export interface FetchChecklists {
  type: typeof constants.FETCH_CHECKLISTS;
  itemId: string;
}

export const fetchChecklists = (itemId: string): FetchChecklists => {
  return {
    type: constants.FETCH_CHECKLISTS,
    itemId,
  };
};

export interface CreateChecklist {
  type: typeof constants.CREATE_CHECKLIST;
  itemId: string;
  checklist: types.Checklist;
}

export const createChecklist = (checklist: types.Checklist, itemId: string): CreateChecklist => {
  return {
    type: constants.CREATE_CHECKLIST,
    itemId,
    checklist,
  };
};

export interface DeleteChecklist {
  type: typeof constants.DELETE_CHECKLIST;
  id: string;
  itemId: string;
}

export const deleteChecklist = (id: string, itemId: string): DeleteChecklist => {
  return {
    type: constants.DELETE_CHECKLIST,
    id,
    itemId,
  };
};

export interface UpdateChecklist {
  type: typeof constants.UPDATE_CHECKLIST;
  checklist: types.Checklist;
  itemId: string;
}

export const updateChecklist = (checklist: types.Checklist, itemId: string): UpdateChecklist => {
  return {
    type: constants.UPDATE_CHECKLIST,
    checklist,
    itemId,
  };
};

export interface SetChecklists {
  type: typeof constants.SET_CHECKLISTS;
  checklists: types.Checklist[];
}

export const setChecklists = (checklists: types.Checklist[]): SetChecklists => {
  return {
    type: constants.SET_CHECKLISTS,
    checklists,
  };
};

export interface AppendChecklist {
  type: typeof constants.APPEND_CHECKLIST;
  checklist: types.Checklist;
}

export const appendChecklist = (checklist: types.Checklist): AppendChecklist => {
  return {
    type: constants.APPEND_CHECKLIST,
    checklist,
  };
};

interface ChecklistError {
  type: typeof constants.SET_CHECKLIST_ERROR;
  errorMessage: string;
}

export const setChecklistError = (errorMessage: string): ChecklistError => {
  return {
    type: constants.SET_CHECKLIST_ERROR,
    errorMessage,
  };
};

interface ResetChecklists {
  type: typeof constants.RESET_CHECKLISTS;
}

export const resetChecklists = (): ResetChecklists => {
  return {
    type: constants.RESET_CHECKLISTS,
  };
};

export interface UpdateRecords {
  type: typeof constants.UPDATE_RECORDS;
  field: string;
  items: string[];
  payload: string | number | [];
  locationData: FetchDataCollectionPayload;
}

export interface UpdateRecordsOptions {
  field: string;
  items: string[];
  payload: string | number | [];
  locationData: FetchDataCollectionPayload;
}

export const updateRecords = (options: UpdateRecordsOptions): UpdateRecords => {
  return {
    type: constants.UPDATE_RECORDS,
    field: options.field,
    items: options.items,
    payload: options.payload,
    locationData: options.locationData,
  };
};

export interface UpdatePrimaryField {
  type: typeof constants.UPDATE_PRIMARY_FIELD;
  newFieldId: string;
  existingFieldId: string;
}

export interface UpdatePrimaryFieldOptions {
  newFieldId: string;
  existingFieldId: string;
}

export const updatePrimaryField = (options: UpdatePrimaryFieldOptions): UpdatePrimaryField => {
  return {
    type: constants.UPDATE_PRIMARY_FIELD,
    newFieldId: options.newFieldId,
    existingFieldId: options.existingFieldId,
  };
};

export interface LockColumnPayload {
  lock: boolean;
  fieldId: string | number;
}

export interface LockColumnAction {
  type: typeof constants.LOCK_COLUMN;
  payload: LockColumnPayload;
}


export const lockColumn = (option: LockColumnPayload): LockColumnAction => {
  return {
    type: constants.LOCK_COLUMN,
    payload: option,
  };
};

export interface UpdateItemsPayload {
  items: Node[];
}

export interface UpdateItems {
  type: typeof constants.UPDATE_ITEMS;
  payload: UpdateItemsPayload;
}

export const updateItems = (payload: UpdateItemsPayload): UpdateItems => {
  return {
    type: constants.UPDATE_ITEMS,
    payload,
  };
};

export interface UpdateItemsFieldPayload {
  itemIds: string[];
  fieldId: string;
  value: any;
}

export interface UpdateItemsField {
  type: typeof constants.UPDATE_ITEMS_FIELD_BY_ID;
  payload: UpdateItemsFieldPayload;
}

export const updateItemsField = (payload: UpdateItemsFieldPayload): UpdateItemsField => {
  return {
    type: constants.UPDATE_ITEMS_FIELD_BY_ID,
    payload,
  };
};

export interface SetCurrentViewId {
  type: typeof constants.SET_CURRENT_VIEW_ID;
  payload: string;
}

export const setCurrentViewId = (payload: string): SetCurrentViewId => {
  return {
    type: constants.SET_CURRENT_VIEW_ID,
    payload,
  };
};

export interface UndoItem {
  type: typeof constants.UNDO_ITEM;
}

export interface RedoItem {
  type: typeof constants.REDO_ITEM;
}

export interface Revision {
  fieldId: string;
  fieldValue: string;
  url: string;
  isFullObject: boolean;
}

export interface RedoItemPayload {
  payload: Revision;
}

export interface UndoItemPayload {
  payload: Revision;
}

export const redoItem = (): RedoItem => {
  return {
    type: constants.REDO_ITEM,
  };
};

export const undoItem = (): UndoItem => {
  return {
    type: constants.UNDO_ITEM,
  };
};

export interface ItemHistory {
  type: typeof constants.SET_ITEM_HISTORY;
  undo: Revision[];
  redo: Revision[];
}

export interface SetItemHistoryPayload {
  undo: Revision[];
  redo: Revision[];
}

export const setItemHistory = (payload: SetItemHistoryPayload): ItemHistory => {
  return {
    type: constants.SET_ITEM_HISTORY,
    ...payload,
  };
};

interface RemoveFieldRevisions {
  type: typeof constants.REMOVE_FIELD_REVISIONS;
  fieldId: string;
}

export const removeFieldRevisions = (fieldId: string): RemoveFieldRevisions => {
  return {
    type: constants.REMOVE_FIELD_REVISIONS,
    fieldId,
  };
};

export const actions = {
  fetchDataCollection,
  fetchCollectionRequest,
  fetchCollectionSuccess,
  fetchCollectionFailure,
  createCollectionItemRequest,
  createCollectionItemSuccess,
  createCollectionItemFailure,
  copyCollectionRequest,
  copyCollectionSuccess,
  copyCollectionFailure,
  createCollectionItemImportRequest,
  createCollectionItemImportSuccess,
  createCollectionItemImportFailure,
  updateNode,
  setRowIndexMap,
  fetchChecklists,
  createChecklist,
  deleteChecklist,
  updateChecklist,
  setChecklists,
  appendChecklist,
  setChecklistError,
  resetChecklists,
  updateRecords,
  updateItems,
  updateItemsField,
  setCurrentViewId,
  redoItem,
  undoItem,
  setItemHistory,
};

export type Actions = ActionType<typeof actions>;
