import * as React from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';
import IconButton, { Color, Size } from 'components/IconButton';
import Confirm from 'components/Modals/Confirm';
import Modal from 'components/Modals/Modal';
import { indexRendererStyles } from 'components/NodeFieldData/id/IndexRenderer.style';
import { location } from 'data/app/selectors';
import { Actions as collectionActions } from 'data/collections/collections.actions';
import { nodeLoadingState, rowIndexMap, schemaSelector } from 'data/collections/collections.selectors';
import { getCurrentDatabaseId } from 'data/databases/databases.selectors';
import { resetFilterModel } from 'data/grid-options/filterModel.actions';
import { filtersSelector, sortSelector } from 'data/grid-options/gridOptions.selector';
import { resetSortModel } from 'data/grid-options/sortModel.actions';
import { openNodeModal } from 'data/modals/actions';
import { getCurrentTaskDatabaseId } from 'data/taskdbs/taskdbs.selectors';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { getWorkspacePermissions, getCurrentWorkspaceId } from 'data/workspaces/workspaces.selectors';
import { Location } from 'history';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { history } from 'store/configureStore';
import { injectGlobal } from 'styled-components';
import svgIcons from 'styles/svgIcons';
import { FilterModel, SortModelState } from 'types/gridOptions';
import { CommonNode } from 'types/response';
import { Schema } from 'types/schema';
import { createPartialNodeFromDefaultSchemaValues } from 'utilities/collections';
import { createModalUrl } from 'utilities/createUrl';
import { normalizeURL } from 'utilities/format';

import {
  AG_CELL_WRAPPER,
  EXPAND_RECORD,
  ROW_INDEX_VALUE,
  ROW_NUMBER_ID,
  SELECTABLE_ROW,
} from '../../DataGrid/columns/constants';
import { IndexMetadata } from '../getFieldMetadata';

enum RendererModes {
  BROWSE = 'browse',
  INSERT = 'insert'
}

enum InsertPosition {
  ABOVE = 'above',
  BELOW = 'below'
}

interface StateProps {
  rowIndexMap: Record<string, number>;
  filterModel: FilterModel;  // Needed only to re-render when filters change
  sortModel: SortModelState; // Needed only to re-render when sorts change
  location: Location;
  schema: Schema;
  nodeLoadingState: string;
  permissions?: string[];
  workspaceId: string;
  databaseId: string | null;
  taskDatabaseId: string | null;
}

interface DispatchProps {
  createNode: typeof collectionActions.createNode;
  openNodeModal(node: Partial<CommonNode>, windowTitle?: string, enableSwitcher?: boolean): void;
  resetSortModel: () => void;
  resetFilterModel: () => void;
  warningToast: typeof notifications.warn;
}

export type Props
  = StateProps
  & DispatchProps
  & WithStyles<typeof indexRendererStyles>
  & ICellRendererParams
  & IndexMetadata
  ;

interface State {
  modalTitle: string;
  rendererMode: RendererModes;
  insertPosition: InsertPosition | string;
}

export class IndexRenderer extends React.Component<Props, State> {
  state = {
    modalTitle: '',
    insertPosition: '',
    rendererMode: RendererModes.BROWSE,
  };

  public render = (): JSX.Element => {
    let className = this.props.classes.wrapper;
    if (!(this.props.permissions || []).includes('items:create')) {
      className += ` ${this.props.classes.noCreatePermission}`;
    }
    return (
      <div className={className} draggable>
        <div className={ROW_INDEX_VALUE}>
          {this.getRowIndex()}
        </div>
        {this.state.rendererMode === RendererModes.INSERT && (
          <Modal onClose={this.onClose}>
            <Confirm
              title={this.state.modalTitle}
              close={this.onClose}
              onConfirm={this.onConfirm}
              confirmLabel="Remove"
            />
          </Modal>
        )}
        {this.props.expandRecordEnabled && (
          <div className={cn(this.props.classes.rowTools, EXPAND_RECORD)}>
            {!isNaN(parseInt(this.props.node.id)) ? (
              <IconButton
                icon={svgIcons.ExpandRecord}
                color={Color.DarkGray}
                size={Size.Medium}
                className={this.props.classes.expandRecordButton}
                onClick={this.expandRecord}
              />): null}
            <div className={this.props.classes.insertRowButtonContainer}>
              <IconButton
                icon={svgIcons.Add}
                color={Color.DarkGray}
                size={Size.ExtraSmall}
                className={this.props.classes.insertRowAboveButton}
                onClick={() => this.validateInsertRecord(InsertPosition.ABOVE)}
              />
              <IconButton
                icon={svgIcons.Add}
                color={Color.DarkGray}
                size={Size.ExtraSmall}
                className={this.props.classes.insertRowBelowButton}
                onClick={() => this.validateInsertRecord(InsertPosition.BELOW)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  private getRowIndex = (): string => {
    const { columnApi, rowIndexMap, node } = this.props;
    if (node.group) return '';
    const activeGroups = columnApi.getRowGroupColumns();
    const currentIndex = activeGroups.length === 0
      ? node.rowIndex
      : rowIndexMap[node.data.id] !== undefined ? rowIndexMap[node.data.id] : -1;
    return currentIndex >= 0 ? (currentIndex + 1).toString() : '';
  };

  private expandRecord = (): void => {
    const { location, node } = this.props;
    history.push(createModalUrl(location.pathname, node.id));
  };

  private onClose = (): void => {
    setTimeout(() => {
      if (this.state.rendererMode === RendererModes.INSERT) {
        this.setState({ rendererMode: RendererModes.BROWSE });
        this.props.warningToast({ message: 'You cannot add a new row when a sort or filter is applied.' });
      }
    }, 0);
  };

  private onConfirm = (): void => {
    this.setState({ rendererMode: RendererModes.BROWSE });
    this.props.resetSortModel();

    const keys = {};
    Object.keys(this.props.filterModel.searchFilters)
      .forEach(key => {
        keys[key] = ' ';
      });

    this.props.api.setFilterModel(keys);
    setTimeout(() => {
      this.props.resetFilterModel();
      this.handleInsertRecord();
    }, 0);
  };

  private validateInsertRecord = (position: InsertPosition): void => {
    const { quickSearch, regularFilters, searchFilters } = this.props.filterModel;
    const isFiltered = quickSearch || regularFilters.length || Object.keys(searchFilters).length;
    const isSorted = this.props.sortModel.allIds.length > 0;

    if (isFiltered && isSorted) {
      this.setState({
        insertPosition: position,
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove filters and sorting?',
      });
      return;
    }

    if (isSorted) {
      this.setState({
        insertPosition: position,
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove sorting?',
      });
      return;
    }

    if (isFiltered) {
      this.setState({
        insertPosition: position,
        rendererMode: RendererModes.INSERT,
        modalTitle: 'Would you like to remove filters?',
      });
      return;
    }

    this.setState({
      insertPosition: position,
    }, () => this.handleInsertRecord());
  };

  private handleInsertRecord = (): void => {
    const fields = this.props.node.data.fields;
    const activeGroups = this.props.columnApi.getRowGroupColumns();
    const activeGroupsColumnIds = activeGroups?.map(groupColumn => parseInt(groupColumn.getColId().replace('fields.', ''), 10));
    const initData = activeGroupsColumnIds.reduce((result, columnId) => {
      result[columnId] = fields[columnId];
      return result;
    }, {});

    const { rowIndex } = this.props.node;
    const rowPosition = this.state.insertPosition === InsertPosition.ABOVE ? rowIndex : rowIndex + 1;
    const url = normalizeURL(this.props.location.pathname);
    const data: Partial<CommonNode> = createPartialNodeFromDefaultSchemaValues<CommonNode>(this.props.schema);
    this.props.createNode({
      url,
      data,
      rowPosition,
      initData,
    });

    setTimeout(() => {
      // dirty fix, remove hover classes because of ag grid not refreshing
      const hoveredRows = document.querySelectorAll('.ag-row-hover');
      if (hoveredRows) {
        for (let i = 0; i < hoveredRows.length; i++) {
          hoveredRows[i].classList.remove('ag-row-hover');
        }
      }
      this.props.api.setFocusedCell(rowPosition, ROW_NUMBER_ID);
    }, 0);
  };
}

const IndexRendererWithRef = React.forwardRef(
  (props: Props, ref: React.RefObject<IndexRenderer>) => <IndexRenderer ref={ref} {...props} />,
);

const StyledIndexRenderer = withStyles(indexRendererStyles)(IndexRendererWithRef);

const StyledIndexRendererWithRef = React.forwardRef(
  (props: Props, ref: React.RefObject<IndexRenderer>) => <StyledIndexRenderer ref={ref} {...props} />,
);

const mapStateToProps = (state: ReduxState): StateProps => ({
  rowIndexMap: rowIndexMap(state),
  filterModel: filtersSelector(state),
  sortModel: sortSelector(state),
  location: location(state),
  schema: schemaSelector(state),
  nodeLoadingState: nodeLoadingState(state),
  permissions: getWorkspacePermissions(state),
  workspaceId: getCurrentWorkspaceId(state),
  databaseId: getCurrentDatabaseId(state),
  taskDatabaseId: getCurrentTaskDatabaseId(state),
});

const mapDispatchToProps: DispatchProps = {
  createNode: collectionActions.createNode,
  openNodeModal,
  resetSortModel,
  resetFilterModel,
  warningToast: notifications.warn,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StyledIndexRendererWithRef);

injectGlobal`
  .${SELECTABLE_ROW} {
    .${ROW_NUMBER_ID} {
      .${AG_CELL_WRAPPER} {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
      }

      .row-index-value {
        padding-left: 20px;
      }

      .ag-selection-checkbox {
        display: none;
      }
    }

    &.ag-row-selected {
      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE} {
          display: none;
        }
      }
    }

    .${EXPAND_RECORD} {
      display: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &.ag-row-hover {

      .${ROW_NUMBER_ID} {
        .ag-selection-checkbox {
          display: block;
        }

        .${ROW_INDEX_VALUE} {
          display: none;
        }

        .${EXPAND_RECORD} {
          justify-content: space-between;
          display: flex;
          width: 43px;
        }
      }
    }
  }
`;
