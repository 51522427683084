import { createAction } from 'typesafe-actions';

interface NotificationPayload {
  title?: string;
  message: string;
}

const success = createAction('notifications/success',
  resolve => (payload: NotificationPayload) => resolve(payload),
);

const error = createAction(
  'notifications/error',
  resolve => (payload: NotificationPayload) => resolve(payload),
);

const warn = createAction(
  'notifications/warn',
  resolve => (payload: NotificationPayload) => resolve(payload),
);

const info = createAction(
  'notifications/info',
  resolve => (payload: NotificationPayload) => resolve(payload),
);

const custom = createAction(
  'notifications/custom',
  resolve => (payload: NotificationPayload) => resolve(payload),
);

export const notifications = {
  success,
  error,
  warn,
  info,
  custom,
};
