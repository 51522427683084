import React from 'react';
import _ from 'lodash';
import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import describeDiff from './describeDiff';
import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = _.get(activity, 'eventBy.displayName');
  const databaseTitle = _.get(activity, 'references.database.title');
  const primaryFieldValue = _.get(activity, 'references.primaryFieldValue');
  if (activity.action === ActivityItemStatuses.CREATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          created item
        <ReferenceLink references={activity.references} type={ReferenceLinkType.ITEM}>
          {primaryFieldValue}
        </ReferenceLink>
          in
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          edited database
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>,
          changed record
        <ReferenceLink references={activity.references} type={ReferenceLinkType.ITEM}>
          {primaryFieldValue}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.DELETED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          deleted database record from
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
        <strong className={'bold'}> {primaryFieldValue}</strong>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.ITEM_BULK_UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          bulk updated database records from
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>
    );
  }
  if (activity.action === ActivityItemStatuses.ITEM_BULK_DELETED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          bulk deleted database records from
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>
    );
  }
  return 'activity';
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (activity.diff && describeDiff(activity.diff));
  }
}


export default {
  describeActivity,
  describeActivityDetails,
};
