import { EMPTY_TITLE } from '../../AgGrid/utils';
import { getGridRowMaxTextLines } from '../../AgGrid/utils';
import GenericTextRenderer from '../GenericTextRenderer';
import libphonenumber from 'google-libphonenumber';
import BaseRenderer from '../BaseRendererPrototype';
import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

function PhoneRenderer() {
  return true;
}

PhoneRenderer.prototype = Object.create(BaseRenderer.prototype);

export function createSingleLineOfTextElement(params) {
  const { node, isGrid } = params;
  let { value } = params;
  value = value || ' ';

  if (typeof value === 'object') {
    value = value.toString();
  }

  try {
    if (phoneUtil.isValidNumber(phoneUtil.parse(value, 'US'))) {
      value = phoneUtil.parseAndKeepRawInput(value, 'US');
      value = phoneUtil.format(value, libphonenumber.PhoneNumberFormat.NATIONAL);
    }
    // eslint-disable-next-line no-empty
  } catch (ignore) { }

  const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;
  const element = document.createElement('div');
  element.id = `${node.id}-${params.column.colId}`;
  element.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  const valueElement = document.createElement('div');
  valueElement.style = GenericTextRenderer(lines);
  valueElement.innerHTML = valueElement.innerHTML = node?.group && value == EMPTY_TITLE ? '' : value;
  element.append(valueElement);

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && value?.toString()?.includes(currentQuickSearch)) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  return {
    element,
    valueElement,
  };
}

PhoneRenderer.prototype.init = function(params) {
  const { valueElement, element } = createSingleLineOfTextElement(params);
  if (valueElement) this.valueElement = valueElement;
  this.element = element;
  this.eGridCell = params.eGridCell;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

PhoneRenderer.prototype.getGui = function() {
  return this.element;
};

PhoneRenderer.prototype.refresh = function(params) {
  return false;
};

PhoneRenderer.prototype.destroy = function() {
  return true;
};

export default PhoneRenderer;
