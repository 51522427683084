import {
  actions as notificationsUnseenCountActions,
} from 'data/notifications/unseen-count';

class NotificationEntity {
  created(data) {
    this.handleNotificationCountUpdate(data);
  }

  deleted(data) {
    this.handleNotificationCountUpdate(data);
  }

  handleNotificationCountUpdate(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    if (data.eventBy !== currentUserId) {
      return;
    }

    store.dispatch(notificationsUnseenCountActions.fetch());
  }
}

export default new NotificationEntity();
