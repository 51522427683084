import * as React from 'react';
import _ from 'lodash';
import {
  Body,
  Content,
  DateWrapper,
  Day,
  Description,
  DetailedMessage,
  EmptyEventIcon,
  IconWrapper,
  Message,
  Tail,
  Wrapper,
} from './ActivityFeedItem.style';
import { getEventIcon } from 'components/ActivityFeed/icons';
import { ActivityFeedItem as ActivityFeedItemType } from 'data/activityFeed/types';
import moment from 'moment';
import { getDescriber } from './ItemTypes/DescriberType';
import pluralize from 'pluralize';

interface Props {
  id?: string;
  item: ActivityFeedItemType;
}

export const ActivityFeedItem: React.FunctionComponent<Props> = (props) => {
  const { id, item: activity } = props;

  const activityModel = getModelType(activity);
  // @ts-ignore
  const Icon = getEventIcon(`${activityModel.toLowerCase()}`) || EmptyEventIcon;
  const date = moment(activity.eventAt).format('MMMM DD, YYYY');
  const time = moment(activity.eventAt).format('hh:mmA');
  const { describeActivity, describeActivityDetails } = getDescriber(activityModel);

  return (
    <Wrapper id={id}>
      <Tail/>
      <IconWrapper><Icon/></IconWrapper>
      <Body>
        <Content>
          <Description id={`${id}_description`}>
            <DateWrapper><Day>{date}</Day> {time}</DateWrapper>
            <Message id={`${id}_message`}>
              {describeActivity(activity)}
            </Message>
            <DetailedMessage id={`${id}_detailedMessage`}>
              {describeActivityDetails(activity)}
            </DetailedMessage>
          </Description>
        </Content>
      </Body>
    </Wrapper>
  );
};


function getModelType(activity: ActivityFeedItemType) {
  const modelType = (_.get(activity, 'references.databaseType') || '');
  if ((activity.model === 'Database' && !modelType.toLowerCase().includes('database')) || activity.model === 'Item') {
    return `${activity.model}${pluralize(modelType)}`;
  }

  return `${activity.model}`;
}
