import { SummarizeInput } from '../types';
import * as _ from 'lodash';
import prettyBytes from 'pretty-bytes';

function summarize({ dataNotFormatted, attachments }: SummarizeInput) {
  const idsMap = [].concat(...dataNotFormatted.filter(item => item.data).map(item => item.data)).reduce((obj: { [key: string]: boolean}, item) => {
    obj[item] = true;
    return obj;
  }, {});

  const result: any[] = [];
  if (attachments && attachments.byItemId) {
    const attachmentIdSizeMap = Object.keys(attachments.byItemId).map(key => attachments.byItemId[key].byId);
    attachmentIdSizeMap.forEach((item) => {
      const arr = Object.keys(item).filter(id => idsMap[id]).map(id => item[id].size);
      if (arr.length) {
        result.push(arr);
      }
    });
    const sizes = [].concat(...result);
    return prettyBytes(_.sum(sizes));
  }

  return 0;
}

export default summarize;
