import { DateFormat, TimeFormat, NumberType } from 'components/Fields/data';
import { CommonNode, FieldValue } from 'types/response';
import { ViewNode } from 'types/response/viewNode';
import { Omit } from 'utility-types';

import { BookmarkMeta } from '../data/bookmarks/types';
import { FieldSummary } from '../data/collections/collections.reducer';
import { FieldNode, FieldType, Grid } from './response/fieldNode';

export interface Properties {
  [id: string]: SchemaProperty;
}

export interface Schema {
  version?: string;
  type?: string;
  properties: Properties;
  isPrimary?: boolean;
}

export interface Choice {
  label: string;
  color: string;
  byQuery?: string;
}

export interface SchemaProperty {
  name: string;
  type: string;
  default?: FieldValue;
  allowMultiple?: boolean;
  allowNegative?: boolean;
  precision?: number;
  currencySymbol?: string;
  subtype?: NumberType;
  maxLength?: number;
  minLength?: number;
  dateFormat?: DateFormat;
  includeTime?: boolean;
  timeFormat?: TimeFormat;
  fieldType?: FieldType;
  required?: boolean;
  readOnly?: boolean;
  apiURI?: string;
  choices?: {
    [key: string]: Choice;
  };
  summary?: FieldSummary;
  choiceOrder?: string[];
  grid?: Grid;
  checklistOrder?: string[];
  checklistNames?: { [key: string]: string };
  checklistValues?: { [key: string]: boolean };
  allowGridAddingOptions?: boolean;
  action?: string;
  label?: string;
  additionalData?: string;
  title?: string;
  _id?: string;

  properties?: {
    [key: string]: SchemaProperty;
  };
}

// @ts-ignore
export interface Column extends Omit<SchemaProperty, 'properties'> {
  allowMultiple: boolean;
  id: string;
  fieldType: FieldType;
  isPrimary?: boolean;
  lock?: boolean;
  showPreview?: boolean;
  permissions?: string[];
}

export const convertFieldNodeToSchemaProperty = (field: FieldNode): SchemaProperty => {
  const { id, title, fieldType, ...fieldProps } = field;
  return {
    ...fieldProps,
    name: title,
    type: convertFieldTypeToValueType(fieldType),
    fieldType,
  };
};

export const convertFieldNodeToSchemaColumn = (field: FieldNode): Column => {
  const { title, fieldType, ...fieldProps } = field;
  // @ts-ignore
  return {
    ...fieldProps,
    name: title,
    type: convertFieldTypeToValueType(fieldType),
    fieldType,
  };
};

export const convertFieldTypeToValueType = (fieldType: FieldType): 'boolean' | 'number' | 'string' => {
  switch (fieldType) {
    case FieldType.Boolean:
      return 'boolean';
    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Currency:
    case FieldType.Rating:
      return 'number';
    case FieldType.Attachment:
    case FieldType.Singlelineoftext:
    case FieldType.Date:
    case FieldType.Singlechoice:
    case FieldType.Multiplechoice:
    case FieldType.Account:
    case FieldType.Checklist:
    case FieldType.Email:
    case FieldType.Url:
    case FieldType.Phone:
    case FieldType.Barcode:
    case FieldType.Percent:
    case FieldType.Tag:
    default:
      return 'string';
  }
};

export const getDefaultFieldTypeFromValueType = (valueType: string): FieldType => {
  switch (valueType) {
    case 'boolean':
      return FieldType.Boolean;
    case 'number':
      return FieldType.Float;
    default:
      return FieldType.Singlelineoftext;
  }
};

export function getId(column: Column): string {
  return column.id;
}

export function getName(column: Column): string {
  return column.name;
}

export function hasDuplicateFieldName(fields: Column[], fieldName: string): boolean {
  return fields.some((field) => field.name === fieldName);
}

export interface ColumnVM extends Column {
  hide?: boolean;
}

export enum CollectionTypes {
  items = 'items',
  sheets = 'sheets',
  tasks = 'tasks',
  taskdbs = 'taskdbs',
  tasksheets = 'tasksheets',
  documents = 'documents',
  bookmarks = 'bookmarks',
  accounts = 'accounts',
  workspaces = 'workspaces',
  favoriteWorkspaces = 'favoriteWorkspaces',
  attachments = 'attachments',
  databases = 'databases',
  activityfeeds = 'activityfeeds',
  repositories = 'repositories',
  history = 'history',
  pages = 'pages',
  files = 'files',
  allWorkspaces = 'workspaces/paginated/all-workspaces',
  paginatedBookmarks = 'workspaces/paginated/bookmarks'
}

export enum CollectionViewTypes {
  kanban = 'kanban',
  grid = 'grid',
  calendar = 'calendar',
  tiles = 'tiles',
  // commenting out 'map' and 'gantt' views, as we are not including them in MVP
  // map = 'map',
  // gantt = 'gantt'
}

// status of nodes (database, workspace)
export enum Status {
  // @TODO: enable this based on tenant flag for creating workspace with SDS Data(Baker Tilly)
  client = 'Client workspace',
  active = 'active',
  archived = 'archived',
}

export enum PermissionTypes {
  addlegalhold = 'addlegalhold',
  create = 'create',
  delete = 'delete',
  export = 'export',
  freeze = 'freeze',
  import = 'import',
  link = 'link',
  list = 'list',
  managepermissions = 'managepermissions',
  read = 'read',
  readdeleted = 'readdeleted',
  readversions = 'readversions',
  removelegalhold = 'removelegalhold',
  undelete = 'undelete',
  unfreeze = 'unfreeze',
  update = 'update'
}

interface HttpRequest {
  accountEmail: string;
  accountID: string;
  date: number;
}

interface Response {
  code: number;
}

export interface Collection {
  customViewsSupported: boolean;
  icon: string;
  supportedViewTypes: CollectionViewTypes[];
  title: string;
  type: CollectionTypes;
}

export interface HttpResponseView {
  id: string;
  apiURI?: string;
  checkOutSupported?: boolean;
  createdBy: string;
  createdDate: number;
  defaultViewID?: string;
  deletedBy?: string;
  deletedDate?: number;
  externalAccessSupported?: boolean;
  fieldGroupOrder?: string[];
  fieldOrder?: string[];
  fields?: {
    [k: string]: any;
  };
  folderIDs?: string[];
  frozenBy?: string;
  frozenDate?: number;
  highwayHash?: string;
  historyMessage: string;
  iconClass?: string;
  iconURL?: string;
  isDeleted?: boolean;
  isFrozen?: boolean;
  isOnLegalHold?: boolean;
  legalHoldBy?: string;
  legalHoldDate?: number;
  modifiedBy: string;
  modifiedDate: number;
  title: string;
  version?: number;
  versionsSupported?: boolean;
  viewTypes?: string[];
  permissions?: string[];
  aggregations?: {
    [k: string]: any;
  };
  uri: string;
  explicitRowOrder?: string[];
  freezePaneFieldIDs?: {
    [k: string]: any;
  };
  freezePaneRowIDs?: {
    [k: string]: any;
  };
  groupByFieldIDs?: string[];
  layoutID?: string;
  query?: {
    count?: number;
    fieldIDs?: string[];
    filters?: {
      [k: string]: any;
    }[];
    groupFieldIDs?: string[];
    groupValues?: string[];
    pivotFieldIDs?: string[];
    sorts?: {
      [k: string]: any;
    }[];
    start?: number;
  };
  titleFieldID?: string;
  type?: string;
  rowHeight?: number;
  cardHeight?: number;
  categoryFieldID?: string;
  categoryFieldOrder?: string[];
  displayFieldShortcutKeys?: {
    [k: string]: any;
  };
  fieldIDs?: string[];
  includeDefaultFields?: boolean;
}

export interface GroupRow {
  fields: {
    [k: string]: string;
  };
  count: number;
}

export interface Group {
  count: number;
  title: string;
  nodes?: Node[];
  groups?: Group[];
}

export interface PaginateQueries {
  nextOffset: number;
  limit: number;
  pageSize: number;
}

export interface Node {
  // TODO: check, are those properties really belongs to Node type?
  editor?: string;
  formatter?: string;
  maxLength: number;
  minLength: number;
  type?: string;
  fileName?: string;

  id: string;
  apiURI: string;
  uri: string;
  checkOutSupported?: boolean;
  createdBy: string;
  createdDate: number;
  defaultViewID?: string;
  deletedBy?: string;
  deletedDate?: number;
  externalAccessSupported: boolean;
  fieldGroupOrder?: string[];
  fieldOrder: string[];
  fields: {
    [k: string]: any;
  };
  folderIDs?: string[];
  frozenBy?: string;
  frozenDate?: number;
  highwayHash?: string;
  historyMessage: string;
  iconClass?: string;
  iconURL?: string;
  isBookmarked: boolean;
  isDeleted?: boolean;
  isFrozen?: boolean;
  isOnLegalHold?: boolean;
  legalHoldBy?: string;
  legalHoldDate?: number;
  modifiedBy: string;
  modifiedDate: number;
  tags?: string[];
  title: string;
  version: number;
  versionsSupported: boolean;
  viewTypes?: string[];
  attachmentsSupported?: boolean;
  customHTMLViewsSupported?: boolean;
  description?: string;
  exportSupported?: boolean;
  fieldIDOrder?: string[];
  foldersSupported?: boolean;
  fromImport?: boolean;
  isMaster?: boolean;
  maxAttachments?: number;
  maxItems?: number;
  maxVersions?: number;
  sheetIDOrder?: string[];
  viewIDOrder?: string[];
  visualizationsSupported?: boolean;
  isFolder?: boolean;
  folderID?: string;
  bookmarkMeta?: BookmarkMeta;
}

export interface Bookmark extends Node {
  bookmarkURI: string;
  recordID: string;
  title: string;
  url: string;
  id: string;
  model: string;
}

export interface Document extends Node {
  fileExtension: string;
  fileName: string;
  isFolder: boolean;
  mediaType: string;
  size: number;
  numberOfPages: number;
}

export interface HttpResponseViewport {
  returnedCount: number;
  totalNumberOfBookmarks?: number;
  totalCount: number;
  start?: number;
  nodes: CommonNode[];
  groups?: Group[];
  pageInfo?: PaginateQueries;
}

export interface Breadcrumb {
  title: string;
  type: string;
  uri: string;
}

export function getURIFromBreadcrumb(breadcrumb: Breadcrumb): string {
  const [pathname, id] = breadcrumb.uri.split('?folderID=');

  if (!id) {
    return breadcrumb.uri;
  }

  return `${pathname}/${id}`;
}

export interface HttpResponse {
  breadcrumbs?: Breadcrumb[];
  request?: HttpRequest;
  response?: Response;
  collection?: Collection;
  permissions?: string[];
  node?: Node;
  schema: Schema;
  view: ViewNode;
  viewport: HttpResponseViewport;
}

export interface HistoryItem {
  date: number;
  historyBy: string;
  historyMessage: string;
}

export type CollectionTypeWithTitle = CollectionTypes.workspaces | CollectionTypes.databases | CollectionTypes.taskdbs | CollectionTypes.tasks;

export type NodeWithTitle = CommonNode & {title: string; meta?: {[k: string]: string}};
