import { FieldRendererTypes } from '../../NodeFieldData';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import { createSingleLineOfTextElement } from '../singlelineoftext/SingleLineOfTextRenderer';
import { fixUrl } from '../../../utilities/createUrl';
import { EMPTY_TITLE } from '../../AgGrid/utils';
import { validateEmail } from '../../../utilities/validation';
import BaseRenderer from '../BaseRendererPrototype';
import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';

function eGridCellOnClick(e, state, setState) {
  if (!state.selected) {
    // e.preventDefault();
    setState({ selected: true });
  }
}

function eGridCellOnBlur(e, state, setState) {
  if (e.relatedTarget && e.relatedTarget.tagName === 'A') {
    e.preventDefault();
  }
  /**
   * Delay state change to allow browser to handel a click
   */
  setTimeout(() => setState({ selected: false }), 200);
}

function createUrlElement(params, state, setState) {
  const { value, fieldType, type } = params;
  if (params.eGridCell && value !== EMPTY_TITLE) {
    params.eGridCell.onclick = (e) => {
      eGridCellOnClick(e, state, setState);
    };

    params.eGridCell.onblur = (e) => {
      eGridCellOnBlur(e, state, setState);
    };
  }

  const wrapper = document.createElement('div');
  wrapper.id = `${params.node.id}-${params.column.colId}`;
  wrapper.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    const span = document.createElement('span');
    span.setAttribute('class', getColumnHeaderClass(fieldType));
    span.setAttribute('title', value);
    wrapper.appendChild(span);
    return { element: wrapper };
  }

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && value?.toString().toLowerCase()?.includes(currentQuickSearch)) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  const { valueElement, element } = createSingleLineOfTextElement(params);
  if ((value !== EMPTY_TITLE && fieldType === 'url') || (fieldType === 'email' && validateEmail(value))) {
    valueElement.style = `
        border-bottom: 1px solid;
    `;
  }
  return { element, valueElement };
}


function UrlRenderer() {
  return true;
}

UrlRenderer.prototype = Object.create(BaseRenderer.prototype);

UrlRenderer.prototype.init = function(params) {
  this.eGridCell = params.eGridCell;
  this.state = {
    selected: false,
  };

  this.setState = (newState) => {
    this.state = newState;
    this.render(params);
  };

  const { element, valueElement } = createUrlElement(params, this.state, this.setState);
  this.element = element;
  this.valueElement = valueElement;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

UrlRenderer.prototype.getGui = function() {
  return this.element;
};

UrlRenderer.prototype.refresh = function(params) {
  return false;
};

UrlRenderer.prototype.render = function(params) {
  if (!this.valueElement || !params.value) {
    return true;
  }
  let { value } = params;

  if (value !== null && typeof value === 'object') {
    value = value.toString();
  }

  if (this.state.selected && (params.fieldType === 'url' || (params.fieldType === 'email' && validateEmail(value)))) {
    const a = document.createElement('a');
    a.setAttribute('href', fixUrl(params.fieldType, value));
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noopener noreferrer');
    a.style = `
      color: #2d7ff9;
      border-bottom: 1px solid;
    `;
    a.innerHTML = value;
    this.valueElement.innerHTML = '';
    this.valueElement.appendChild(a);
  } else {
    const span = document.createElement('span');
    span.innerText = value;
    if (params.fieldType === 'url' || (params.fieldType === 'email' && validateEmail(value))) {
      span.style = `
          border-bottom: 1px solid;
      `;
    }
    this.valueElement.innerHTML = '';
    this.valueElement.appendChild(span);
  }

  return true;
};

UrlRenderer.prototype.destroy = function() {
  return true;
};

export default UrlRenderer;
