import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ICellEditorParams } from 'ag-grid-community';
import DeleteAttachment from 'components/Modals/DeleteConfirm/DeleteAttachment';
import Modal from 'components/Modals/Modal';
import GridAttachmentsEditor from 'components/NodeFieldData/attachment/GridAttachmentsEditor';
import ModalAttachmentsEditor from 'components/NodeFieldData/attachment/ModalAttachmentsEditor';
import * as actions from 'data/attachments/actions';
import {
  attachmentsByIdFromAgGrid,
  isAttachmentsLoading,
  attachmentsToUpload,
  attachmentsUploaded,
} from 'data/attachments/attachments.selectors';
import { openDeleteAttachmentModal } from 'data/modals/actions';
import {
  uploadAttachments,
  tempPersistAttachments,
  deleteTempAttachment,
} from 'data/ui/fileUpload/fileUpload.actions';
import {
  UploadAttachmentPayloadItem,
  UploadAttachmentsPayload,
} from 'data/ui/fileUpload/fileUpload.types';
import * as keys from 'keycode-js';
import { Dictionary, isNumber } from 'lodash';
import _ from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { FileAttachment } from 'types/common';
import { CommonNode } from 'types/response';
import { AttachmentNode } from 'types/response/attachmentNode';
import { TenantNode } from 'types/response/tenantNode';
import { CollectionTypes } from 'types/schema';

import wrapCellEditor from '../components/wrapCellEditor';
import { AttachmentMetadata } from '../getFieldMetadata';
import { AttachmentType } from './constants';

interface FieldEditorParams<Node extends CommonNode>
  extends ICellEditorParams,
    AttachmentMetadata {
  value: string[];
  fieldId: string;
  data: Node;
  isGrid?: boolean;
  readOnly?: boolean;
  type?: string;
}

interface StateProps {
  attachments: { byId: Dictionary<AttachmentNode>; ids: string[] } | undefined;
  isAttachmentsLoading: boolean;
  permissions: string[];
  attachmentsToUpload: UploadAttachmentsPayload;
  attachmentsUploaded: UploadAttachmentsPayload;
  tenant?: TenantNode;
}

interface DispatchProps {
  uploadAttachments: typeof uploadAttachments;
  tempPersistAttachments: typeof tempPersistAttachments;
  deleteTempAttachment: typeof deleteTempAttachment;
  onDeleteAttachment(uri: string, id: string): void;
  changeSort: typeof actions.changeSortAttachments;
  openPreview: typeof actions.openPreviewAttachments;
}

interface RefProps {
  filesModal: any;
  lock?: boolean;
}

interface DeleteModalAttachmentMetaInterface {
  id: string;
  uri: string;
}

interface LocalStateProps {
  deleteModalAttachmentMeta: DeleteModalAttachmentMetaInterface | null;
}

type Props<Node extends CommonNode> = FieldEditorParams<Node> &
  StateProps &
  DispatchProps &
  RefProps &
  LocalStateProps;

class AttachmentsEditor<Node extends CommonNode> extends React.Component<
  Props<Node>
> {
  private filesModalRef;
  state: LocalStateProps = {
    deleteModalAttachmentMeta: null,
  };
  constructor(props) {
    super(props);
    this.filesModalRef = React.createRef<typeof ModalAttachmentsEditor>();
  }

  public componentDidMount(): void {
    if (this.props.isGrid) {
      if (
        this.props['keyPress'] &&
        (this.props['keyPress'] === keys.KEY_RETURN ||
          this.props['keyPress'] === keys.KEY_ENTER)
      ) {
        setTimeout(() => {
          this.filesModalRef?.current?.openModal();
        }, 50);
      }
      document.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  componentWillUnmount() {
    if (this.props.isGrid) {
      document.removeEventListener('keydown', this.handleKeyPress, false);
    }
  }

  handleKeyPress = (event) => {
    if (event.keyCode === keys.KEY_RETURN || event.keyCode === keys.KEY_ENTER) {
      if (this.props.isGrid && this.props.permissions.includes('files:create')) {
        this.filesModalRef?.current?.openModal();
      }
    }
  };

  public render(): JSX.Element | null {
    const {
      column,
      permissions,
      tenant,
      readOnly,
      isAttachmentsLoading,
      data,
      isGrid,
      eGridCell,
      attachments,
      previewBehavior,
      type,
      attachmentsToUpload,
      attachmentsUploaded,
      showPreview,
      lock,
    } = this.props;
    const attachedIds = attachments?.ids ?? [];
    const attachmentsById = attachments?.byId ?? {};
    // @ts-ignore
    const attachmentId = this.getAttachmentIds();
    const fieldId = this.getFieldId();
    if (isAttachmentsLoading && !attachmentsUploaded.length) {
      return <CircularProgress size="1.5rem" />;
    }
    let modalAttachments: AttachmentNode[] | string[] = [];
    if (type === AttachmentType.UPDATE) {
      modalAttachments = [
        ...attachmentId.map((id) => attachmentsById[id] && attachmentsById[id]),
      ]
        .filter((attachment) => attachment)
        .sort((file1, file2) => file1?.sort - file2?.sort);
    } else {
      modalAttachments = [
        ...attachmentsToUpload
          .filter((attachment) => attachment['colId'] === `fields.${fieldId}`)
          .map((attachment) => attachment.file.name),
      ].filter((attachment) => attachment);
    }

    return (
      <>
        {isGrid ? (
          <GridAttachmentsEditor
            ref={this.filesModalRef}
            item={data}
            fieldId={fieldId}
            attachedIds={attachedIds}
            attachmentIds={attachmentId}
            attachmentsById={attachmentsById}
            previewBehavior={previewBehavior}
            eGridCell={eGridCell}
            onAdd={this.handleAdd}
            onDelete={this.handleDeleteAttachment}
            attachments={attachments}
            column={column}
            permissions={permissions}
            showPreview={showPreview}
            onClickItem={this.props.openPreview}
            tenant={this.props.tenant}
          />
        ) : (
          <ModalAttachmentsEditor
            ref={this.filesModalRef}
            fieldId={fieldId}
            attachments={modalAttachments}
            showPreview={showPreview}
            modalType={type}
            onAdd={this.handleAdd}
            onDelete={
              type === AttachmentType.UPDATE
                ? this.handleDeleteAttachment
                : this.handleDeleteTempAttachment
            }
            onChangeSort={this.props.changeSort}
            openPreviewCarousel={this.props.openPreview}
            disableAutoAdd={type === AttachmentType.UPDATE}
            permissions={permissions}
            readOnly={readOnly}
            collectionType={CollectionTypes.items}
            lock={lock}
            node={this.props.data}
            tenant={tenant}
          />
        )}
        {this.state.deleteModalAttachmentMeta && (
          <Modal
            onClose={() => this.setState({ deleteModalAttachmentId: null })}
          >
            <DeleteAttachment
              id={this.state.deleteModalAttachmentMeta.id}
              uri={this.state.deleteModalAttachmentMeta.uri}
              onClose={() => this.setState({ deleteModalAttachmentMeta: null })}
            />
          </Modal>
        )}
      </>
    );
  }

  private handleAdd = (files: FileAttachment[]): void => {
    const {
      uploadAttachments,
      tempPersistAttachments,
      data,
      isGrid,
      stopEditing,
      fieldId,
      rowIndex,
      column,
      api,
      node,
      attachments,
    } = this.props;

    if (this.props?.['target'] === 'collection_form') {
      const uriExists = data?.['apiURI'];
      const attachedIds = data?.fields?.[fieldId.replace('fields.', '')] ?? [];
      // looks for the last sort value in order to take it
      // as base for the incoming files
      const attachSort: number[] = attachments?.ids?.map((attachId) => {
        const attach = attachments?.byId[attachId];
        const sortIndex = attach?.sort;

        return isNumber(sortIndex) ? sortIndex : 0;
      }) || [];
      const maxSortIndex = attachSort.length
        ? (Math.max(...attachSort) + 1)
        : 0;

      // @ts-ignore
      const items: UploadAttachmentPayloadItem[] = files.map(
        (fileAttachment, uploadIndex) => ({
          file: fileAttachment.file,
          uri: data.apiURI,
          colId: fieldId,
          index: uploadIndex + maxSortIndex,
          attachedIds,
          rowId: data.id,
        }),
      );
      if (uriExists) {
        uploadAttachments(items);
        if (isGrid) {
          stopEditing(true);
          setTimeout(() => {
            api?.setFocusedCell(
              rowIndex,
              column.getColId(),
            );
          }, 0);
        }
        this.filesModalRef?.current?.closeModal();
      } else {
        tempPersistAttachments(items);
        this.filesModalRef?.current?.closeModal();
      }
    } else {
      const attachedIds = data?.fields?.[column.getColId().replace('fields.', '')] ?? [];
      // @ts-ignore
      const items: UploadAttachmentPayloadItem[] = files.map(
        (fileAttachment) => ({
          file: fileAttachment.file,
          uri: data.apiURI,
          colId: column.getColId(),
          attachedIds,
          rowId: node.id,
        }),
      );
      uploadAttachments(items);

      if (isGrid) {
        stopEditing(true);
        setTimeout(() => {
          api?.setFocusedCell(
            rowIndex,
            column.getColId(),
          );
        }, 0);
      }
    }
  };

  private getAttachmentIds = (): string[] => {
    const { fieldId, attachments } = this.props;
    const attachmentId: string[] = [];
    const checkedFieldId = fieldId
      ? fieldId.replace('fields.', '')
      : (this.props['column']['colId'] || '').replace('fields.', '');
    if (attachments && attachments.byId) {
      Object.keys(attachments.byId).forEach((id: string) => {
        if (attachments && attachments.byId) {
          const attachment = attachments.byId[id];
          const attachmentFieldId = `${_.get(
            'references.fieldID',
            attachment,
          )}`;
          if (attachmentFieldId === checkedFieldId) {
            attachmentId.push(attachment._id);
          }
        }
      });
    }
    return attachmentId;
  };

  private getFieldId = (): string => {
    const { fieldId, colDef } = this.props;

    return fieldId?.replace('fields.', '') ||
      String(colDef?.refData?._id || '');
  };

  private handleDeleteAttachment = (id: string) => {
    const { data, attachments } = this.props;
    const attachmentId = attachments?.byId[id] ? id : null;
    if (attachmentId) {
      this.setState({
        deleteModalAttachmentMeta: { id: attachmentId, uri: data.apiURI },
      });
      return;
    }
  };

  private handleDeleteTempAttachment = (index: number): void => {
    const { attachmentsToUpload, fieldId, deleteTempAttachment } = this.props;

    const inFilesToUpload = attachmentsToUpload.filter(
      (attachment) => attachment.colId === fieldId,
    )[index];
    if (inFilesToUpload) {
      deleteTempAttachment({
        colId: fieldId,
        index,
      });
    }
  };
}

const selectors = createStructuredSelector<ReduxState, {}, StateProps>({
  attachments: attachmentsByIdFromAgGrid,
  isAttachmentsLoading,
  permissions: (state: ReduxState) => state.collections.collections.permissions,
  tenant: (state: ReduxState) => state.app.tenant,
  attachmentsToUpload,
  attachmentsUploaded,
});

const mapDispatchToProps: DispatchProps = {
  uploadAttachments,
  tempPersistAttachments,
  deleteTempAttachment,
  onDeleteAttachment: openDeleteAttachmentModal,
  changeSort: actions.changeSortAttachments,
  openPreview: actions.openPreviewAttachments,
};

const enhance = compose(
  wrapCellEditor(),
  connect(selectors, mapDispatchToProps, null, { forwardRef: true }),
);

export default enhance(AttachmentsEditor);
