import * as React from 'react';
import { Dropdown } from '../TableHeaderTools/TableHeaderTools.style';
import MenuItem from '@material-ui/core/MenuItem';
import SearchBar, { Size as SearchBarSize } from '../SearchBar/SearchBar';

import './ActivityFeedFilters.scss';

interface Props {
  onQuery(query): void;
  onTypeChange(type): void;
  type?: string;
  query: string;
}

const ACTIVITY_TYPES = [
  {
    id: 'allActivity',
    title: 'All Activity',
  },
  {
    id: 'Database',
    title: 'Databases',
  },
  {
    id: 'Task',
    title: 'Tasks',
  },
  {
    id: 'Item',
    title: 'Records',
  },
  {
    id: 'Message',
    title: 'Messages',
  },
  {
    id: 'File',
    title: 'Files',
  },
];

export default class ActivityFeedFilters extends React.PureComponent<Props> {
  renderTypeMenuItem = (item) => {
    return <MenuItem
      key={item.id}
      onClick={() => this.props.onTypeChange(item.id)}
      selected={this.props.type === item.id}
    >
      {item.title}
    </MenuItem>;
  };

  render() {
    return (
      <div className={'activity-filter-container'}>
        <SearchBar
          placeholder={'Search Activity'}
          value={this.props.query}
          onChange={this.props.onQuery}
          size={SearchBarSize.Small}
          className={'activity-search'}
        />
        <Dropdown
          id="dropdownActivityFilter"
          label={(this.props.type && ACTIVITY_TYPES.find(type => type.id === this.props.type)?.title) || 'All Activity'}
          menuItems={ACTIVITY_TYPES.map(this.renderTypeMenuItem)}/>
      </div>
    );
  }
}
