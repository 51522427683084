import * as React from 'react';

import { Content, TitleWrapper, Wrapper } from './PageHeader.styles';

interface Props {
  titleContent: React.ReactNode;
  children?: React.ReactNode;
}

export default function PageHeader({ titleContent, children }: Props) {
  return (
    <Wrapper>

      <TitleWrapper>
        {titleContent}
      </TitleWrapper>

      {children && <Content>{children}</Content>}
    </Wrapper>
  );
}
