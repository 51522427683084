import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

type PreviewModeInputClassKey
  = 'root'
  | 'input'
  | 'inputMultiline'
  | 'a'
  | 'locked'
  ;

export const styles: Record<PreviewModeInputClassKey, CSSProperties> = {
  root: {
    borderRadius: '2px',
    border: `1px solid ${Colors.lightBlueGrey}`,
    padding: 0,
  },
  input: {
    display: 'flex',
    height: '38px',
    backgroundColor: Colors.greyTransparent,
    padding: '8px',
    fontSize: '14px',
    alignItems: 'center',
  },
  inputMultiline: {
    height: '88px',
    lineHeight: '17px',
  },
  a: {
    color: Colors.hubsyncBlue,
    borderBottom: '1px solid',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  locked: {
    paddingRight: '24px',
  },
};
