// import { get } from 'utilities/httpRequests';
import { getApiV2Url } from 'env';

/**
 * Generates URL for all users by workspace
 *
 * @param workspaceID
 * @returns endpoint URL for users
 */
export const getAnalyticsEndpoint = (workspaceID) =>
  `${getApiV2Url()}/workspaces/${workspaceID}/analytics`;

/** */
export const fetchAnalytics = async (workspaceID) => {
  const analytics = window?.appStore?.getState()?.app?.tenant.thirdParty.analytics;
  let body = null;
  if (Array.isArray(analytics)) {
    body = analytics.find(item => item._id === workspaceID);
  }
  const response = { body };
  return response;
};
