import { typeIcon } from '../../pages/Files/Renderers/CellRenderers/IconRenderer';
import { createPopper } from '@popperjs/core';
import { EMPTY_TITLE } from '../AgGrid/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default function BaseRenderer() {
}

const backDropAction = function() {
  document.querySelector('.expand-options-area').remove();
  document.querySelector('.group-expand-backdrop').remove();
};

const expandAllChildrenNodes = function(group) {
  if (!group || !group.length) return;

  for (let index = 0; index < group.length; index++) {
    group[index].setExpanded(true);
    expandAllChildrenNodes(group[index].childrenAfterGroup);
  }
};

const collapseAllChildrenNodes = function(group) {
  if (!group || !group.length) return;

  for (let index = 0; index < group.length; index++) {
    group[index].setExpanded(false);
    collapseAllChildrenNodes(group[index].childrenAfterGroup);
  }
};

BaseRenderer.prototype.onExpandClicked = function() {
  this.expandButtons = document.createElement('ul');
  this.expandButtons.setAttribute('class', 'expand-options-area');

  let expandTypes = [
    {
      type: 'expandGroup',
      title: 'Expand Group',
      icon: 'expand',
    },
    {
      type: 'collapseGroup',
      title: 'Collapse Group',
      icon: 'collapse',
    },
    {
      type: 'collapseSubGroups',
      title: 'Collapse subgroups',
      icon: 'collapse',
    },
    {
      type: 'expandSubGroups',
      title: 'Expand subgroups',
      icon: 'expand',
    },
    {
      type: 'expandAll',
      title: 'Expand All',
      icon: 'expand',
    },
    {
      type: 'collapseAll',
      title: 'Collapse All',
      icon: 'collapse',
    },
  ];

  const rowsToDisplay = this.params.api.rowModel.rowsToDisplay.filter(row => row.group);
  if (rowsToDisplay.filter(row => !row.expanded).length === 0) {
    expandTypes = expandTypes.filter(expandType => expandType.type !== 'expandAll');
  }

  if (rowsToDisplay.filter(row => row.expanded).length === 0) {
    expandTypes = expandTypes.filter(expandType => expandType.type !== 'collapseAll');
  }

  if (this.params.node.expanded === true) {
    expandTypes = expandTypes.filter(expandType => expandType.type !== 'expandGroup');
  }

  if (this.params.node.expanded === false) {
    expandTypes = expandTypes.filter(expandType => expandType.type !== 'collapseGroup');
  }

  const childrenAfterGroup = this.params.node.childrenAfterGroup.filter(row => row.group);
  if (childrenAfterGroup && childrenAfterGroup.length > 0) {
    if (childrenAfterGroup.filter(row => !row.expanded).length === 0) {
      expandTypes = expandTypes.filter(expandType => expandType.type !== 'expandSubGroups');
    }

    if (childrenAfterGroup.filter(row => row.expanded).length === 0) {
      expandTypes = expandTypes.filter(expandType => expandType.type !== 'collapseSubGroups');
    }
  } else {
    expandTypes = expandTypes.filter(expandType => expandType.type !== 'collapseSubGroups' && expandType.type !== 'expandSubGroups');
  }

  this.expandButtons.append(...expandTypes.map((expandType) => {
    const expandButton = document.createElement('li');

    const expandButtonIcon = typeIcon[expandType.icon].cloneNode(true);
    expandButtonIcon.style = `
        height: 14px;
        color: #37474f;
      `;
    expandButton.append(expandButtonIcon);

    const expandButtonTitle = document.createElement('span');
    expandButtonTitle.innerText = expandType.title;
    expandButton.append(expandButtonTitle);
    expandButton.addEventListener('click', () => {
      if (this.params && this.params.api) {
        switch (expandType.type) {
          case 'collapseAll':
            this.params.api.collapseAll();
            break;
          case 'expandAll':
            this.params.api.expandAll();
            break;
          case 'collapseSubGroups':
            this.params.node.setExpanded(false);
            collapseAllChildrenNodes(this.params.node.childrenAfterGroup);
            break;
          case 'expandSubGroups':
            this.params.node.setExpanded(true);
            expandAllChildrenNodes(this.params.node.childrenAfterGroup);
            break;
          case 'expandGroup':
            this.params.node.setExpanded(true);
            break;
          case 'collapseGroup':
            this.params.node.setExpanded(false);
            break;
          default:
            console.warn('Unknown type', expandType.type);
        }
        // close popper after option click
        backDropAction();
      }
    });

    return expandButton;
  }));


  const backDrop = document.createElement('div');
  backDrop.setAttribute('class', 'group-expand-backdrop');
  backDrop.style = `
    display: block;
    position: fixed;
    background: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  `;
  backDrop.addEventListener('click', backDropAction);

  document.body.append(backDrop);

  document.body.append(this.expandButtons);
  createPopper(this.expandButtonContainer, this.expandButtons, {
    placement: 'bottom-end',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
      },
    ],
  });
};

BaseRenderer.prototype.handleGroup = function(params, element) {
  this.params = params;
  if (params?.value == EMPTY_TITLE) {
    addEmptyElement(element);
  }

  const counter = document.createElement('span');
  counter.setAttribute('class', 'ag-group-child-count');
  counter.innerText = `(${params.node.allChildrenCount})`;

  element.append(counter);

  this.expandButtonContainer = document.createElement('div');
  this.expandButtonContainer.setAttribute('class', 'group-expand-container');

  this.expandButton = document.createElement('button');
  const icon = typeIcon['menuDots'].cloneNode(true);
  icon.style = `
    height: 14px;
    color: #37474f;
  `;
  this.expandButtonListener = this.onExpandClicked.bind(this);
  this.expandButton.addEventListener('click', this.expandButtonListener);
  this.expandButton.append(icon);
  this.expandButtonContainer.append(this.expandButton);
  element.append(this.expandButtonContainer);
};


export function addEmptyElement(element) {
  const emptyElement = document.createElement('div');
  emptyElement.style = `width: 100%;`;
  emptyElement.innerHTML = EMPTY_TITLE;
  element.append(emptyElement);
}
