import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      width: '100%',
    },
    autosizer: {
      width: '100% !important',
      height: '100% !important',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignContent: 'center',
    },
  });
