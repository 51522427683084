import { SVG } from '@svgdotjs/svg.js';
import cn from 'classnames';
import './IconButton.scss';

import {
  add as addSVG,
  expand as expandSVG,
} from './idIcons.svg';
import { Color, Size } from '../../IconButton';

export const iconType = {
  ADD: 'add',
  EXPAND: 'expand',
};

const drawAdd = SVG().addTo('body');
drawAdd.svg(addSVG);
const addIcon = drawAdd.node.firstChild.cloneNode(true);
drawAdd.node.style = 'display: none';

const drawExpand= SVG().addTo('body');
drawExpand.svg(expandSVG);
const expandIcon = drawExpand.node.firstChild.cloneNode(true);
drawExpand.node.style = 'display: none';

const icons = {
  [iconType.ADD]: addIcon,
  [iconType.EXPAND]: expandIcon,
};

const classes = {
  'root': 'root',
  'buttonExtraSmall': 'buttonExtraSmall',
  'buttonMedium': 'buttonMedium',
  'DarkGray': 'DarkGray',
  'selected': 'selected',
};
export default function IconButton({
  color,
  type,
  icon,
  className,
  iconClassName,
  size,
  selected,
  ...buttonAttributes
}) {
  const buttonClassName = cn({
    [className]: className,
    [classes.root]: true,
    [classes.buttonExtraSmall]: size === Size.ExtraSmall,
    [classes.buttonMedium]: size === Size.Medium,
    [classes.iconDarkGray]: color === Color.DarkGray,
    [classes.selected]: selected,
  });

  const button = document.createElement('button');
  button.setAttribute('type', type || 'button' );
  Object.keys(buttonAttributes).forEach(qualifiedName => button.setAttribute(qualifiedName, buttonAttributes[qualifiedName]));
  Object.keys(buttonAttributes).forEach(qualifiedName => button[qualifiedName] = buttonAttributes[qualifiedName]);
  button.className = buttonClassName;
  const iconNode = icons[icon].cloneNode(true);
  iconNode.setAttribute('class', iconClassName);
  button.appendChild(iconNode);
  return button;
}

