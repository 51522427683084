import * as React from 'react';
import Fade from '@material-ui/core/Fade';

interface Props {
  className?: string;
  show: boolean;
  children: React.ReactNode;
}

interface State {
  isVisible: boolean;
}

export default class ToolWrapper extends React.PureComponent<Props, State> {
  state = {
    isVisible: false,
  };

  handleMouseEnter = () => this.setState({ isVisible: true });
  handleMouseLeave = () => this.setState({ isVisible: false });

  render() {
    const { className, show, children } = this.props;

    return (
      <Fade in={show || this.state.isVisible}>
        <div
          className={className}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {children}
        </div>
      </Fade>
    );
  }
}
