import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey = 'root' | 'iconRegular' | 'iconSmall' | 'inputRegular' | 'inputSmall';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    'display': 'flex',
    'flexWrap': 'nowrap',
    'alignItems': 'center',
    'width': '100%',
    'borderStyle': 'solid',
    'borderWidth': '1px',
    'borderColor': Colors.lightBlueGrey,
    '&:focus-within': { borderColor: Colors.hubsyncBlueSemiTransparent },
    'color': Colors.slateTwo,
    'backgroundColor': Colors.greyTransparent,
  },
  iconRegular: {
    height: '15px',
    margin: '0 12px',
  },
  iconSmall: {
    height: '10px',
    margin: '0 7px',
  },
  inputRegular: {
    fontFamily,
    fontSize: '14px',
    letterSpacing: '0.28px',
    width: '100%',
    height: '38px',
    color: Colors.slateTwo,
    backgroundColor: Colors.greyTransparent,
  },
  inputSmall: {
    fontFamily,
    fontSize: '11px',
    letterSpacing: '0.18px',
    width: '100%',
    height: '23px',
    color: Colors.slateTwo,
    backgroundColor: Colors.greyTransparent,
  },
};
