import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';
import { lightRed } from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey =
  | 'wrapper'
  | 'content'
  | 'profileCard'
  | 'leftWrapper'
  | 'titleClassName'
  | 'tabContent'
  | 'actionButtons'
  | 'divider'
  | 'input'
  | 'inputLabel'
  | 'inputRow'
  | 'inputWrapper'
  | 'emailValue'
  | 'saveButton'
  | 'mb'
  | 'miniFormWrapper'
  | 'miniFormActionButtons'
  | 'avatarWrapper'
  | 'errorMessage'
  | 'changeEmailErrorContainer'
  | 'changeEmailErrorIcon'
  | 'changeEmailErrorMessage'
  | 'phoneInputProfile';

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    'display': 'flex',
    '& .messages-label-container': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  content: {
    width: '620px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },

  leftWrapper: {
    position: 'relative',
    width: '228px',
    minWidth: '228px',
    padding: '0 12px',
    overflowY: 'auto',
    backgroundColor: colors.white,
    borderRight: `1px solid ${colors.iceBlue}`,
  },
  profileCard: {
    padding: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleClassName: {
    flex: 1,
    textAlign: 'center',
  },
  tabContent: {
    'padding': 24,
    'flex': 1,
    'overflowY': 'auto',
    '& >div:nth-child(n+2)': {
      marginTop: 24,
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 30px',
  },
  divider: {
    width: '100%',
    height: '1px',
    minHeight: '1px',
    backgroundColor: colors.iceBlue,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputWrapper: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    '&:nth-child(2)': {
      marginLeft: 16,
    },
  },
  input: {
    marginTop: 5,
  },
  inputLabel: {
    marginLeft: 12,
    letterSpacing: 0,
    lineHeight: '21px',
  },
  emailValue: {
    color: colors.slateTwo,
    fontFamily,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '22px',
    marginBottom: '8px',
  },
  mb: {
    marginBottom: '8px',
  },
  saveButton: {
    marginLeft: 24,
  },
  miniFormWrapper: {
    '& >div:first-child': {
      marginTop: 6,
    },
    '& >div:nth-child(n+2)': {
      marginTop: 24,
    },
  },
  miniFormActionButtons: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  avatarWrapper: {
    margin: '16px 0',
  },
  errorMessage: {
    color: lightRed,
    fontSize: '12px',
  },
  changeEmailErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  changeEmailErrorIcon: {
    height: '16px',
    width: '16px',
  },
  changeEmailErrorMessage: {
    fontWeight: 'bold',
    fontSize: '12px',
    paddingLeft: '5px',
    color: colors.lightRed,
  },
  phoneInputProfile: {
    width: '40px',
  },
};
