import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  logoUrl?: string;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Logo(props: Props): JSX.Element {
  const { classes, logoUrl = '/hubsync-logo.svg' } = props;

  return (
    <Link className={classes.wrapper} to="/">
      <img className={classes.logo} src={logoUrl} alt='Logo' />
    </Link>
  );
}

type ClassKey
  = 'wrapper'
  | 'logo'
  ;

const styles = withStyles<ClassKey>({
  wrapper: { padding: '13px' },
  logo: { height: '100%', maxWidth: '250px' },
});

export default styles(Logo);
