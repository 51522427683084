import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { ICellRendererParams } from 'ag-grid-community';
import * as qs from 'qs';
import * as React from 'react';

import getFileIcon from 'styles/fileExtension';
import svgIcons from 'styles/svgIcons';

import { FileExtensionWrapper, FolderLink } from './style';
import { DocumentNode } from 'types/response/documentNode';
import { stripUrlsLastItem } from 'utilities/queryParams';
import { stripDomain } from 'utilities/createUrl';

type Props
  = ICellRendererParams
  & WithStyles<ClassKey>
  ;

function FileExtensionRenderer(props: Props): JSX.Element | null {
  const { classes, data } = props;
  const isFolder = data && data.isFolder;

  if (isFolder) {
    return <Folder document={data} />;
  }
  const Icon = getFileIcon(props.value);
  return (
    <FileExtensionWrapper isGroup={props['isGroup']}>
      <Icon className={classes.icon} style={{ 'width': '60px' }}/>
    </FileExtensionWrapper>
  );
}

type ClassKey = 'icon';

const styles = withStyles<ClassKey>({
  icon: { height: '2rem' },
});

export default styles(FileExtensionRenderer);

interface FolderProps {
  document: DocumentNode;
}

function Folder(props: FolderProps): JSX.Element {
  const maybeFolderURI = getFolderURI(props.document);

  if (maybeFolderURI) {
    return (
      <FileExtensionWrapper>
        <FolderLink to={maybeFolderURI}>
          <svgIcons.folder height="1rem" />
        </FolderLink>
      </FileExtensionWrapper>
    );
  }

  return (
    <FileExtensionWrapper>
      <svgIcons.folder height="1rem" />
    </FileExtensionWrapper>
  );
}

function getFolderURI(document: DocumentNode): string {
  const { id, uri } = document;
  const pathname = stripDomain(stripUrlsLastItem(uri));
  const queries = qs.stringify({ folderID: id });
  return `${pathname}?${queries}`;
}
