import LoadingState from '../LoadingState';
import { RawDraftContentState } from 'draft-js';

export interface QueryParams {
  limit?: number;
  createdOn?: string;
  channelId?: string;
}

export interface UpdateMessageInStore {
  id: string;
  messageBody: MessageBody;
}

export interface DeleteMessageInStore {
  id: string;
}

export interface MessageBody {
  text?: string;
  json?: RawDraftContentState;
  html?: string;
}

export interface Message {
  id: string;
  channelId: string;
  body: MessageBody;
  databaseId?: string;
  taskDatabaseId?: string;
  sheetId?: string;
  workspaceId: string;
  workspaceTitle: string;
  workspaceURL: string;
  tennantId?: string;
  senderUserId: string;
  sender: {
    id: string;
    displayName: string;
    thumbnailURL: string;
  };
  recipientUserId?: string;
  recipient?: {
    id: string;
    displayName: string;
    thumbnailURL: string;
  };
  createdOn?: string;
  status?: MessageStatuses;
}

export interface MessagesState {
  currentChannelId: string;
  channels: {
    [key: string]: ChannelState;
  };
  messages: Message[];
  messagesCount: number;
  messagesGroupedByDay: Message[][];
  loadingState: LoadingState;
  error: string;
}

export interface ChannelState {
  messagesCount: number;
  messages: Message[];
  messagesGroupedByDay: Message[][];
}

export interface UpdateMessage {
  id: string;
  messageBody: MessageBody;
}

export enum MessageStatuses {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted'
}

export interface Suggestions {
  id: string;
  name: string;
  email?: string;
}
