import { createAsyncAction, ActionType } from 'typesafe-actions';
import { User } from 'data/users/users.types';

export const fetchUser = createAsyncAction(
  'users/FETCH_REQUEST/ME',
  'users/FETCH_SUCCESS/ME',
  'users/FETCH_FAILURE/ME',
)<undefined, User, Error>();

export const updateUser = createAsyncAction(
  'users/UPDATE_REQUEST/ME',
  'users/UPDATE_SUCCESS/ME',
  'users/UPDATE_FAILURE/ME',
)<any, User, Error>();

export const changePassword = createAsyncAction(
  'users/CHANGE_PASSWORD_REQUEST/ME',
  'users/CHANGE_PASSWORD_SUCCESS/ME',
  'users/CHANGE_PASSWORD_FAILURE/ME',
)<undefined, User, Error>();

export const deleteUser = createAsyncAction(
  'users/DELETE_REQUEST/ME',
  'users/DELETE_SUCCESS/ME',
  'users/DELETE_FAILURE/ME',
)<undefined, User, Error>();

export const changeUserEmail = createAsyncAction(
  'users/CHANGE_EMAIL_REQUEST/ME',
  'users/CHANGE_EMAIL_SUCCESS/ME',
  'users/CHANGE_EMAIL_FAILURE/ME',
)<any, User, Error>();

export const changeUserSendMessagePreference = createAsyncAction(
  'users/CHANGE_USER_SEND_MESSAGE_PREFERENCE_REQUEST/ME',
  'users/CHANGE_USER_SEND_MESSAGE_PREFERENCE_SUCCESS/ME',
  'users/CHANGE_USER_SEND_MESSAGE_PREFERENCE_FAILURE/ME',
)<any, User, Error>();

export const changeUserModifyFieldModalPreference = createAsyncAction(
  'users/CHANGE_USER_MODIFY_FIELD_PREFERENCE_REQUEST/ME',
  'users/CHANGE_USER_MODIFY_FIELD_PREFERENCE_SUCCESS/ME',
  'users/CHANGE_USER_MODIFY_FIELD_PREFERENCE_FAILURE/ME',
)<any, User, Error>();

export const updateUserTermsAndCondition = createAsyncAction(
  'users/PATCH_REQUEST/ME',
  'users/PATCH_SUCCESS/ME',
  'users/PATCH_FAILURE/ME',
)<any, User, Error>();

export const updateUserMFAMethod = createAsyncAction(
  'users/MFA_METHOD_PATCH_REQUEST/ME',
  'users/MFA_METHOD_PATCH_SUCCESS/ME',
  'users/MFA_METHOD_PATCH_FAILURE/ME',
)<any, User, Error>();

export const actions = {
  fetchUser,
  updateUser,
  changePassword,
  deleteUser,
  changeUserEmail,
  changeUserSendMessagePreference,
  changeUserModifyFieldModalPreference,
  updateUserTermsAndCondition,
  updateUserMFAMethod,
};

export type Actions = ActionType<typeof actions>;
