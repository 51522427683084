import * as React from 'react';
import { FieldEditorProps } from 'components/NodeFieldData/Fields.types';
import ListSelectPopup from './ListSelectPopup';
import wrapCellEditor from 'components/NodeFieldData/components/wrapCellEditor';
import { coerseArray } from 'utilities/common';
import Popover from '@material-ui/core/Popover';
import { difference } from 'lodash/fp';
import {
  DropdownIcon,
  styles,
} from 'components/NodeFieldData/dropdown/DropdownEditor.style';
import { Choice } from 'types/schema';
import { withStyles, WithStyles } from '@material-ui/core';

export interface RenderChoiceProps {
  onDelete: () => void;
  className?: string;
}

interface OwnProps {
  id?: string;
  allowMultiple?: boolean;
  renderChoice?: (choice: Choice, props?: RenderChoiceProps) => React.ReactNode;
  matchChoice?: (query: string, choice: Choice) => boolean;
  choices: Record<string, Choice>;
  choiceOrder: string[];
}

export type ChoiceEditorProps = OwnProps & FieldEditorProps & WithStyles<typeof styles>;

interface ChoiceEditorState {
  open: boolean;
}

function matchChoiceDefault(query: string, choice: Choice): boolean {
  return choice.label.toLowerCase().includes(query.toLowerCase());
}

class DropdownEditor extends React.Component<ChoiceEditorProps, ChoiceEditorState> {
  public state: ChoiceEditorState = {
    open: false,
  };

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ open: true });
    }, 0);
  }

  private wrapper: HTMLDivElement | null = null;
  private matchChoice = this.props.matchChoice || matchChoiceDefault;

  public render = (): JSX.Element => {
    const { classes, choices, value } = this.props;
    const remainingChoices = this.getChoicesList();
    const renderedList = this.renderList(remainingChoices);

    return (
      <>
        <div
          className={classes['wrapper']}
          ref={wrapper => this.wrapper = wrapper}
          onClick={this.openList}
        >
          <span className={classes['label']}>
            {choices && choices[value].label}
          </span>
          <DropdownIcon up={this.state.open} />
        </div>
        {renderedList}
      </>
    );
  };

  private getChoicesList() {
    const { choices, value, allowMultiple, choiceOrder } = this.props;
    let choicesToReturn = choiceOrder;

    if (allowMultiple) {
      choicesToReturn = difference(
        choiceOrder,
        coerseArray(value),
      );
    }

    return choicesToReturn
      .map(id => ({ id, choice: choices[id] }));
  }

  private openList = () => this.setState({ open: true });

  private closeList = () => this.setState({ open: false });

  private handleSelect = selected => {
    const { allowMultiple, onChange } = this.props;
    if (allowMultiple) {
      const value = coerseArray(this.props.value);
      onChange([...value, selected], true);
    } else {
      onChange(selected, true);
    }
    this.closeList();
  };

  private isItemSelected = (itemValue): boolean => {
    return coerseArray(this.props.value).indexOf(itemValue) >= 0;
  };

  private renderItem = (item) => {
    return <b> {item.choice.label} </b>;
  };

  private renderList(choices) {
    const { open } = this.state;
    return (
      <Popover
        id={`${this.props.id}`}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={this.wrapper}
        open={open}
        onClose={this.closeList}
      >
        <ListSelectPopup
          id={`dropdown${this.props.id}`}
          items={choices}
          renderItem={this.renderItem}
          matchItem={(query, item) => this.matchChoice(query, item.choice)}
          onSelect={this.handleSelect}
          mapItemToValue={choice => choice.id}
          isItemSelected={this.isItemSelected}
        />
      </Popover>
    );
  }
}

const StyledChoiceEditor = withStyles(styles)(DropdownEditor);

export { StyledChoiceEditor as ChoiceEditor };

export default wrapCellEditor({ stopEditingAfterChange: true })(StyledChoiceEditor);
