import { IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';
import * as React from 'react';
import SelfSelectingInput from 'components/SelfSelectingInput';
import { Operator } from '../../../../types/gridOptions';

type Props = IFloatingFilterParams;

interface State {
  value: string;
}

class TextFloatingFilter extends React.PureComponent<Props, State> implements IFloatingFilter {
  public state = {
    value: '',
  };

  public render(): JSX.Element {
    return (
      <SelfSelectingInput
        value={this.state.value}
        onChange={this.onChange}
        style={{ width: '100%' }}
      />
    );
  }

  onParentModelChanged = (): void => {
    if (window.resetFloatingFilter && this.state.value) {
      this.setState({ value: '' });
    }
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ value: event.target.value });
    this.props.parentFilterInstance(instance => {
      instance.setModel({ value: event.target.value, filter: { operator: Operator.Contains } });
      this.props.api.onFilterChanged();
    });
  };
}

export default TextFloatingFilter;
