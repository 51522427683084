import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import cn from 'classnames';
import IconButton, { Color as IconButtonColor, Size } from 'components/IconButton';
import { styles } from 'components/NodeFieldData/choice/ChoiceItem/ChoiceItem.style';
import Chip from 'components/ui/Chip';
import { getTenantConfig } from 'env';
import { dark } from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { Choice } from 'types/schema';
const tenantConfig = getTenantConfig() as any;

interface ChoiceItemProps {
  className?: string;
  readonly?: boolean;
  lock?: boolean;
  choice: Choice;
  onDelete?: () => void;
}

type Props = ChoiceItemProps & WithStyles<typeof styles>;

const ChoiceItem = (props: Props): JSX.Element | null => {
  if (!props.choice) {
    return null;
  }

  const rootClassName = cn(props.classes.root, props.className);
  if (tenantConfig.enabledFeatures.chipChoices) {
    const color = props.choice.color === '#fff'? dark : props.choice.color;
    return (
      <div className={rootClassName}>
        <Chip
          className={props.classes.chip}
          avatar={<div className={`${props.classes.chipBullet} grid-choice-item-bullet`} style={{ backgroundColor: color }} />}
          label={props.choice.label}
          color={color}
          textColor={color}
          outline={true}
          onDelete={props.onDelete}
        />
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      {props.choice?.byQuery && (
        <div className={`${props.classes.addChoice}`}>+</div>
      )}
      {!props.choice?.byQuery && (
        <div className={`${props.classes.bullet} grid-choice-item-bullet`} style={{ backgroundColor: props.choice.color }} />
      )}
      <span className={props.classes.label}>{props.choice.label}</span>
      {!props.readonly && props.onDelete &&
        <IconButton
          icon={svgIcons.Remove}
          color={IconButtonColor.LightGray}
          size={Size.Small}
          onClick={props.onDelete}
        />
      }
    </div>
  );
};

export default withStyles(styles)(ChoiceItem);
