import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { FieldRendererProps } from 'components/NodeFieldData/Fields.types';
import ListOverflow from 'components/ListOverflow/ListOverflow';
import { coerseArray, slugifyText } from 'utilities/common';
import Chip from 'components/ui/Chip';
import wrapCellRenderer from 'components/NodeFieldData/components/wrapCellRenderer';
import { Choice } from 'types/schema';
import ChoiceItem from 'components/NodeFieldData/choice/ChoiceItem/ChoiceItem';
import { styles } from 'components/NodeFieldData/choice/ChoiceRenderer.style';
import { connect } from 'react-redux';
import { State } from '../../../reducers';
import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';
import { FieldType } from 'types/response/fieldNode';
import { DropdownIcon } from './ChoiceEditor.style';
import { injectGlobal } from 'styled-components';
import { FOCUSED_CELL } from '../../DataGrid/columns/constants';
import { ChipsWrapperDropdown } from '../components/ChipsWrapper';
import { ColDef } from 'ag-grid-community';

interface StateProps {
  quickSearch: string | null;
}

interface ChoiceRendererProps extends FieldRendererProps {
  choices: { [key: string]: Choice };
  allowMultiple?: boolean;
  renderChoice?: (choice: Choice, className?: string) => React.ReactNode;
  choiceType?: string;
  colDef?: ColDef;
}

type Props = StateProps & ChoiceRendererProps & WithStyles<typeof styles>;

function renderChoiceDefault(choice: Choice, className?: string) {
  return <ChoiceItem choice={choice} className={className} />;
}

class ChoiceRenderer extends React.PureComponent<Props> {
  render() {
    const { allowMultiple, choices, isGroup, value, classes, fieldType, renderChoice = renderChoiceDefault } = this.props;

    if (allowMultiple) {
      let parsedValue = value;
      if (fieldType === FieldType.Multiplechoice) {
        const formattedValue = value && (typeof value === 'object' ? value : typeof value === 'string' && value.includes(',')? value.split(',') : [value + '']);
        parsedValue = formattedValue && formattedValue.filter(choice => choice).map(choice => slugifyText(`${choice}`));
      }

      // highlight cell on quick search
      if (this.props.eGridCell && parsedValue && parsedValue.length && !this.props.choiceType) {
        this.props.eGridCell.classList.remove('highlighted-cell');
        if (this.props.quickSearch && this.props.quickSearch.length > 0 && parsedValue.map(val => choices[val] && choices[val].label && choices[val].label.toLowerCase()).filter(choiceValue => choiceValue && choiceValue.includes(this.props.quickSearch && this.props.quickSearch.toLowerCase())).length > 0) {
          this.props.eGridCell.classList.add('highlighted-cell');
        }
      }

      if (this.props.node && this.props.node.group) {
        const _value = typeof parsedValue === 'string' ? parsedValue.split(',') : parsedValue;
        return (
          <React.Fragment>
            {coerseArray(_value).map((id, i) => choices[id] && (
              <React.Fragment key={`${id}${i}`}>{renderChoice(choices[id], classes.rowItem)}</React.Fragment>
            ))}
          </React.Fragment>
        );
      }

      return (
        <div className={`${classes.root} ${classes.rootColumn}`}>
          <ListOverflow
            className={isGroup ? classes.listOverflowItemsGroupRow : undefined}
            renderMore={count => <Chip label={`+${count}`} disableShrink={true} className={classes.rowItem}/>}
          >
            {coerseArray(parsedValue).map((id, i) => choices[id] && (
              <React.Fragment key={`${id}${i}`}>{renderChoice(choices[id], classes.rowItem)}</React.Fragment>
            ))}
          </ListOverflow>
          <div className={classes.hiddenOptions}>
            {coerseArray(parsedValue).map((id, i) => choices[id] && (
              <React.Fragment key={`${id}${i}`}>
                {renderChoice(choices[id], classes.rowItem)}
              </React.Fragment>
            ))}
            <Chip label={1} disableShrink={true} className={classes.rowItem}/>
          </div>
        </div>
      );
    } else {
      const choice = value && Object.values(choices).find(choice => choice.label === value);

      // highlight cell on quick search
      if (choice && this.props.eGridCell && !this.props.choiceType) {
        this.props.eGridCell.classList.remove('highlighted-cell');
        if (this.props.quickSearch && this.props.quickSearch.length > 0 && choice.label.toLowerCase().includes(this.props.quickSearch.toLowerCase())) {
          this.props.eGridCell.classList.add('highlighted-cell');
        }
      }

      return (
        <ChipsWrapperDropdown className={classes.choiceWrapper}>
          {choice && renderChoice(choice)}
          {!choice && value && choices[value] && renderChoice(choices[value])}
          {!choice && !(value && choices[value]) && <div />}
          {this.props.eGridCell && this.props.colDef && this.props.colDef.editable && (this.props.fieldType === FieldType.Account || this.props.fieldType === FieldType.Singlechoice || this.props.fieldType === FieldType.Dropdown) && (
            <>
              <DropdownIcon className='dropdown-button' />
              <span className='singlechoice-to-cell-editor' onClick={() => this.props.startEditing && this.props.startEditing()} />
            </>
          )}
        </ChipsWrapperDropdown>
      );
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    quickSearch: filtersSelector(state).quickSearch,
  };
};

injectGlobal`
  .column-singlechoice,
  .column-account {
    .dropdown-button,
    .singlechoice-to-cell-editor {
      display: none;
    }

    &.${FOCUSED_CELL} {
      .singlechoice-to-cell-editor {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }

      .dropdown-button {
        display: inline-block;
      }
    }
  }
`;

export default connect(mapStateToProps)(wrapCellRenderer(withStyles(styles)(ChoiceRenderer)));
