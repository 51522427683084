
export default class Timer {
    private timerId = 0;
    rate: number; // in milliseconds
    callback: () => void;

    constructor(intervalRate, cb) {
      this.callback = cb;
      this.rate = intervalRate;
    }

    start(triggerToStart = false) {
      if (triggerToStart) {
        this.callback();
      }

      this.timerId = window.setInterval(this.callback, this.rate);
    }

    stop() {
      window.clearInterval(this.timerId);
    }
}
