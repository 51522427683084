import * as React from 'react';
import { connect } from 'react-redux';
import fscreen from 'fscreen';
import { PagesListWrapper, styles, Thumbnails } from 'components/DocumentPreview/DocumentPreview.style';
import Header from 'components/DocumentPreview/Header';
import PreviewTools from 'components/DocumentPreview/Tools/PreviewTools';
import {
  documentPrintError,
} from 'data/documents/documents.actions';
import PagesList from 'components/DocumentPreview/PagesList';
import ThumbnailsList from 'components/DocumentPreview/ThumbnailsList';
import { throttle } from 'lodash';
import { RouteComponentProps } from 'react-router';
import LoadingState from 'data/LoadingState';
import LoadingIndicator from 'components/LoadingIndicator';
import { withStyles, WithStyles } from '@material-ui/core';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import Button, { Variant as ButtonVariant } from 'components/Button';
import { getFile } from 'pages/Files/Services';
import { getSharedFile } from '../../pages/SharedFiles/Services';

interface DispatchProps {
  documentPrintError: typeof documentPrintError;
}

type Props =
  RouteComponentProps<string[]>
  & DispatchProps
  & WithStyles<typeof styles>;

interface State {
  isThumbnailsOpened: boolean;
  isFullscreenMode: boolean;
  scrollToIndex: number;
  currentPageIndex: number;
  zoom: number;
  zoomBeforeFullscreen: number;
  isToolsVisible: boolean;
  previewsLoadState: LoadingState;
  isDocumentLoading: boolean;
  document: any;
  previews: any[];
}

const TOOLS_MOUSE_MOVE_THROTTLE_WAIT = 500;
const TOOLS_VISIBILITY_TIMEOUT = 2000;
const FULLSCREEN_INITIAL_ZOOM = 50;

class DocumentPreview extends React.PureComponent<Props, State> {
  private toolsTimeout: number;
  private thumbnailsListRef: React.RefObject<ThumbnailsList> = React.createRef<ThumbnailsList>();
  private pagesListRef: React.RefObject<PagesList> = React.createRef<PagesList>();
  private node: HTMLDivElement | null;


  constructor(props: Props) {
    super(props);

    this.state = {
      isThumbnailsOpened: true,
      isFullscreenMode: false,
      scrollToIndex: 0,
      currentPageIndex: 0,
      zoom: 100,
      zoomBeforeFullscreen: 100,
      isToolsVisible: false,
      document: null,
      isDocumentLoading: false,
      previewsLoadState: LoadingState.Unloaded,
      previews: [],
    };

    this.toolsTimeout = 0;
    this.node = null;
  }

  private toggleToolsVisibility = throttle(() => {
    window.clearTimeout(this.toolsTimeout);
    this.setState({ isToolsVisible: true }, () => {
      this.toolsTimeout = window.setTimeout(() => {
        this.setState({ isToolsVisible: false });
      }, TOOLS_VISIBILITY_TIMEOUT);
    });
  }, TOOLS_MOUSE_MOVE_THROTTLE_WAIT);

  private handleRowsRendered = throttle((props: { startIndex: number; stopIndex: number }) => {
    if (this.state.currentPageIndex !== props.startIndex && this.thumbnailsListRef.current) {
      this.thumbnailsListRef.current.scrollToThumbnail(props.startIndex);
      this.setState({ currentPageIndex: props.startIndex });
    }
  }, 300);

  public componentWillUnmount = (): void => {
    window.clearTimeout(this.toolsTimeout);
    fscreen.removeEventListener('fullscreenchange', this.fullscreenListener);
  };

  public componentDidMount = (): void => {
    this.loadDocumentData();
    fscreen.addEventListener('fullscreenchange', this.fullscreenListener);
  };

  public render = (): JSX.Element | null => {
    const { documentPrintError, history, classes } = this.props;
    const { previewsLoadState, isDocumentLoading, document, previews, isThumbnailsOpened, isFullscreenMode, isToolsVisible, currentPageIndex, zoom } = this.state;

    if (isDocumentLoading) return <LoadingIndicator/>;

    if (previewsLoadState === LoadingState.Error && !previews.length) {
      return this.renderNoPreviewMessage();
    }
    if (!document) return null;

    return (
      <div className={classes.root} ref={node => (this.node = node)}>
        {!isFullscreenMode &&
          <Header document={document} onBack={history.goBack} />
        }

        {previews &&
          <div className={classes.content}>
            <Thumbnails opened={isThumbnailsOpened}>
              <ThumbnailsList
                ref={this.thumbnailsListRef}
                currentIndex={currentPageIndex}
                previews={previews}
                onClick={this.handleGoToPage}
              />
            </Thumbnails>

            <div onMouseMove={this.toggleToolsVisibility} className={classes.pages}>
              <PagesListWrapper>
                <PagesList
                  ref={this.pagesListRef}
                  zoom={zoom}
                  previews={previews}
                  onRowsRendered={this.handleRowsRendered}
                />
              </PagesListWrapper>
              <PreviewTools
                zoom={zoom}
                currentPageIndex={currentPageIndex}
                totalPages={previews.length}
                onCollapse={this.toggleThumbnails}
                collapsed={!isThumbnailsOpened}
                onGoToPage={this.handleGoToPage}
                onPrintError={documentPrintError}
                onZoom={this.handleZoom}
                onFullscreen={this.toggleFullscreenMode}
                fullscreen={isFullscreenMode}
                previews={previews}
                show={isToolsVisible}
              />
            </div>
          </div>
        }
      </div>
    );
  };

  private handleFile = (file) => {
    if (!file || !file.preview) {
      throw new Error('No File Preview');
    } else {
      if (file.fileType.includes('image')) {
        this.setState({
          isDocumentLoading: false,
          previewsLoadState: LoadingState.Loaded,
          document: file,
          previews: [{
            size: {
              width: '100%',
              height: '100%',
            },
            url: file.preview,
          }],
        });
      } else {
        this.setState({
          isDocumentLoading: false,
          previewsLoadState: LoadingState.Loaded,
          document: file,
          previews: file.preview,
        });
      }
    }
  };

  private handleFileError = (_err) => {
    this.setState({
      isDocumentLoading: false,
      previewsLoadState: LoadingState.Error,
    });
  };

  private loadDocumentData = async (): Promise<void> => {
    this.setState({ isDocumentLoading: true, previewsLoadState: LoadingState.Loading });
    // @ts-ignore
    const { linkID, fileID, workspaceID } = this.props.match.params;

    try {
      if (this.props.match.path.startsWith('/shared-files')) {
        await getSharedFile(linkID, fileID)
          .then(this.handleFile)
          .catch(this.handleFileError);
      } else {
        await getFile(workspaceID, fileID, true)
          .then(this.handleFile)
          .catch(this.handleFileError);
      }
    } catch (err) {
      this.setState({
        isDocumentLoading: false,
        previewsLoadState: LoadingState.Error,
      });
    }
  };

  private toggleThumbnails = (): void => {
    this.setState((prevState) => ({
      isThumbnailsOpened: !prevState.isThumbnailsOpened,
    }), () => {
      // force list update on thumbnails visibility change
      // (according to animation timeout)
      if (this.pagesListRef.current) {
        setTimeout(this.pagesListRef.current.forceUpdateList);
      }
    });
  };

  private handleGoToPage = (index: number): void => {
    if (this.pagesListRef.current) {
      this.pagesListRef.current.scrollToPage(index);
    }
  };

  private handleZoom = (zoom: number): void => this.setState({ zoom }, () => {
    if (this.pagesListRef.current) {
      this.pagesListRef.current.forceUpdateList();
    }
  });

  private toggleFullscreenMode = (): void => {
    this.setFullscreenMode(!this.state.isFullscreenMode);
  };

  private setFullscreenMode = (isFullscreenMode: boolean): void => {
    this.setState(({ zoomBeforeFullscreen, zoom }) => ({
      isFullscreenMode,
      zoom: isFullscreenMode ? FULLSCREEN_INITIAL_ZOOM : zoomBeforeFullscreen,
      zoomBeforeFullscreen: isFullscreenMode ? zoom : zoomBeforeFullscreen,
    }));

    if (fscreen.fullscreenEnabled) {
      const enabled = fscreen.fullscreenElement === this.node;
      if (isFullscreenMode && !enabled) {
        fscreen.requestFullscreen(this.node);
      } else if (!isFullscreenMode && enabled) {
        fscreen.exitFullscreen();
      }
    }
  };

  private fullscreenListener = (): void => {
    const enabled = fscreen.fullscreenElement === this.node;
    if (enabled !== this.state.isFullscreenMode) {
      this.toggleFullscreenMode();
    }
  };

  private renderNoPreviewMessage = (): JSX.Element => {
    const { classes, history } = this.props;

    return (
      <div className={classes.noPreviewContainer}>
        <Typography variant={TypographyVariant.SubHeading} className={classes.noPreviewText}>
          <span>
              No preview for this file has been generated yet.
            <br/>
              You can check back later.
          </span>
        </Typography>
        <Button label="Go back" variant={ButtonVariant.Primary} onClick={history.goBack} />
      </div>
    );
  };
}

const mapDispatchToProps: DispatchProps = {
  documentPrintError,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(DocumentPreview));
