
import * as CollectionsActionType from 'data/collections/constants';
import { DatabaseNode } from 'types/response/databaseNode';
import { WorkspaceNode } from 'types/response/workspaceNode';
import { convertFieldNodeToSchemaColumn, CollectionTypes } from 'types/schema';
import { ActionType as TypesafeActionType, getType } from 'typesafe-actions';

import { Actions as CollectionsActions } from '../collections/collections.actions';
import * as ViewsActions from '../views/views.actions';
import { Action as ModalAction, ActionType } from './actions';
import * as Modal from './state';


const initialState: Modal.State = Modal.noModal();

type Action
  = ModalAction
  | TypesafeActionType<typeof CollectionsActions.saveFieldSuccess>
  | TypesafeActionType<typeof ViewsActions.createView.success>
  ;

export default function reducer(state = initialState, action: Action): Modal.State {
  switch (action.type) {
    case ActionType.CLOSE_DELETE_ATTACHMENT_MODAL: {
      return Modal.returnToPreviousModal(state);
    }

    case ActionType.CLOSE_MODAL:
      return Modal.noModal();

    case ActionType.OPEN_EDIT_VIEW_MODAL: {
      return Modal.editViewModal(action.view);
    }

    case ActionType.OPEN_NEW_CALENDAR_VIEW_MODAL: {
      return Modal.newCalendarViewModal(
        action.existingViewNames,
        action.newViewName,
        action.fields,
      );
    }

    case ActionType.OPEN_NEW_GRID_VIEW_MODAL: {
      return Modal.newGridViewModal(action.apiURI, action.existingViewNames, action.viewName);
    }

    case ActionType.OPEN_NEW_KANBAN_VIEW_MODAL: {
      return Modal.newKanbanViewModal(
        action.existingViewNames,
        action.newViewName,
        action.fields,
      );
    }

    case ActionType.NEW_VIEW_NAME:
      return Modal.newViewName(state, action.viewName);

    case ActionType.NEW_VIEW_PRIVATE:
      // @ts-ignore
      return { ...state, isPrivate: action.isPrivate };

    case ActionType.NEW_VIEW_LOCK:
      return { ...state, isLocked: action.isLocked };

    case ActionType.NEW_VIEW_DEFAULT:
      return { ...state, isDefault: action.isDefault };

    case ActionType.SAVE_NEW_GRID_VIEW:
      return Modal.noModal();

    case ActionType.OPEN_NODE_MODAL:
      return Modal.nodeViewModal(action.node, action.windowTitle, action.enableSwitcher);

    case ActionType.OPEN_DELETE_ATTACHMENT_MODAL: {
      return Modal.deleteAttachmentModal(action.id, action.uri, state);
    }

    case ActionType.OPEN_DELETE_FOLDER_MODAL: {
      return Modal.deleteFolderModal(action.breadcrumb);
    }

    case ActionType.OPEN_DELETE_NODE_MODAL:
      return Modal.deleteNodeModal(action.node, action.collectionType);

    case ActionType.OPEN_DELETE_ITEMS_MODAL:
      return Modal.deleteItemsModal(action.ids, action.collectionType, action.agGridApi);

    case ActionType.OPEN_DELETE_GRID_COLUMN_MODAL:
      return Modal.deleteGridColumnModal(action.columnId);

    case ActionType.OPEN_DELETE_VIEW_MODAL:
      return Modal.deleteViewModal(action.viewId);

    case ActionType.OPEN_DELETE_SHEET_MODAL:
      return Modal.deleteViewModal(action.sheetId);

    case ActionType.OPEN_RENAME_BREADCRUMB_MODAL: {
      return Modal.openRenameBreadcrumbModal(action.breadcrumb);
    }
    case ActionType.OPEN_ACCOUNT_MODAL: {
      return Modal.openAccountModal(action.tab);
    }
    case ActionType.OPEN_RENAME_COLLECTION_ITEM_MODAL:
      return Modal.renameCollectionItemModal(action.collectionType, action.node);

    case ActionType.OPEN_RENAME_DOCUMENT_MODAL:
      return Modal.renameDocumentModal(action.document);

    case ActionType.OPEN_RENAME_KANBAN_COLUMN_MODAL:
      return Modal.renameKanbanColumnModal(action.categoryFieldId, action.choiceId, action.choices);

    case ActionType.OPEN_DELETE_KANBAN_COLUMN_MODAL:
      return Modal.deleteKanbanColumnModal(action.categoryField, action.choiceId);

    case ActionType.OPEN_CREATE_COLLECTION_ITEM_MODAL:
      return Modal.createCollectionItemModal(action.collectionType);

    case ActionType.OPEN_DUPLICATE_DATABASE: {
      const workspace = getWorkspace(action.database, action.availableWorkspaces);
      const collectionType = getCollectionType(action.database);
      return Modal.openDuplicateDatabase(action.database, workspace, action.availableWorkspaces, collectionType);
    }

    case ActionType.CHANGE_SELECTED_WORKSPACE: {
      return Modal.changeSelectedWorkspace(action.workspace, state);
    }

    case ActionType.CHANGE_SHOULD_DUPLICATE_RECORDS: {
      return Modal.changeShouldDuplicateRecords(state);
    }

    case ActionType.CHANGE_SHOULD_DUPLICATE_COMMENTS: {
      return Modal.changeShouldDuplicateComments(state);
    }

    case ActionType.DUPLICATE_DATABASE: {
      return Modal.noModal();
    }

    case ActionType.SELECT_CATEGORY_FIELD: {
      return Modal.selectCategoryField(state, action.selectedCategoryField);
    }

    case ActionType.SELECT_DATE_FIELD: {
      return Modal.selectDateField(state, action.selectedDateField);
    }

    case ActionType.SELECT_TITLE_FIELD: {
      return Modal.selectTitleField(state, action.selectedTitleField);
    }

    case ActionType.OPEN_MOVE_COPY_DOCUMENT_MODAL:
      return Modal.moveCopyDocumentModal(action.selectedItems);

    case ActionType.OPEN_INFO_MODAL:
      return Modal.infoModal(action.title, action.message, action.confirmLabel);

    case ActionType.OPEN_CANCEL_ENVELOPE_MODAL:
      return Modal.cancelEnvelopeModal(action.envelopeId);

    case ActionType.OPEN_CANCEL_ENVELOPE_PERMISSION_MODAL:
      return Modal.cancelEnvelopePermissionModal();

    case ActionType.OPEN_RESEND_ENVELOPE_MODAL:
      return Modal.resendEnvelopeModal(action.envelopeId, action.signerEmail);

    case ActionType.OPEN_RESEND_ENVELOPE_PERMISSION_MODAL:
      return Modal.resendEnvelopePermissionModal();

    case ActionType.OPEN_LOCK_PERMISSION_MODAL:
      return Modal.lockPermisionModal();

    case ActionType.OPEN_ORGANIZER_REQUIRES_WORKSPACE:
      return Modal.organizerRequiresModal(action.ids);

    case ActionType.OPEN_WORKSPACE_INFO_MODAL:
      return Modal.openWorkspaceInfoModal(action.workspaceInfo);

    case ActionType.RENAME_WORKSPACE_WITH_META:
      return Modal.renameWorkspaceWithMeta(action.currentNode);

    case CollectionsActionType.SAVE_FIELD_SUCCESS: {
      const { field } = action.payload;
      return Modal.addNewField(state, convertFieldNodeToSchemaColumn(field));
    }

    case getType(ViewsActions.createView.success): {
      return Modal.noModal();
    }

    case ActionType.SET_NODE_FIELDS: {
      return {
        ...state,
        // @ts-ignore
        node: {
          // @ts-ignore
          ...state.node,
          // @ts-ignore
          fields: action.node.fields,
        },
      };
    }

    default:
      return state;
  }
}

function getWorkspace(database: DatabaseNode, availableWorkspaces: WorkspaceNode[]): WorkspaceNode {
  const possibleMatches = database.apiURI.match(/workspaces\/([A-Za-z0-9]+)\//);

  if (possibleMatches === null) {
    return availableWorkspaces[0];
  }

  const [, workspaceId] = possibleMatches;

  return availableWorkspaces.find((w) => w.id === workspaceId) || availableWorkspaces[0];
}

function getCollectionType(database: DatabaseNode): CollectionTypes {
  return database.apiURI.includes(CollectionTypes.databases) ? CollectionTypes.databases : CollectionTypes.taskdbs;
}
