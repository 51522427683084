import * as React from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';

interface OwnProps {
  lock?: boolean;
}

type Props
  = OwnProps
  & Omit<TextFieldProps, 'inputRef'>;

export default class SelfSelectingField extends React.Component<Props> {
  private textFieldRef: React.RefObject<HTMLInputElement> = React.createRef();

  public componentDidMount(): void {
    if (this.props.autoFocus) {
      // Timeout needed for not losing focus from input component in modal when popover is closed
      // This seems to be the minimum value for it to work fine
      setTimeout(() => {
        this.setFocus();
      }, 350);
    }
  }

  public render(): JSX.Element {
    const { autoFocus, lock, className, ...props } = this.props;

    return (
      <TextField
        {...props}
        variant="standard"
        inputRef={this.textFieldRef}
        onFocus={this.selectText}
        className={`${lock ? 'locked': ''}`}
      />
    );
  }

  private selectText = (): void => {
    const { current: input } = this.textFieldRef;

    if (input) {
      input.select();
    }
  };

  private setFocus = (): void => {
    const { current: input } = this.textFieldRef;

    if (input) {
      input.focus();
    }
  };
}
