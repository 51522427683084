import _ from 'lodash';
import { getGridRowMaxTextLines, removeHTMLTagsFromText } from '../../AgGrid/utils';
import GenericTextRenderer from '../GenericTextRenderer';
import { typeIcon } from '../../../pages/Files/Renderers/CellRenderers/IconRenderer';
import BaseRenderer from '../BaseRendererPrototype';
import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';

function SingleSelectRenderer() {
  return true;
}

const getNodesPatchProgress = (rowId, columnId) => {
  const store = window.appStore;
  if (!store) return;
  return _.get(store.getState().collections.nodesPatchProgress, `row-${rowId}.col-${columnId.replace('fields.', '')}`);
};

SingleSelectRenderer.prototype = Object.create(BaseRenderer.prototype);

SingleSelectRenderer.prototype.createElement = function(params) {
  const { node, isGrid, choices, column } = params;
  let { value } = params;
  value = value ? removeHTMLTagsFromText(value) : ' ';

  if (typeof value === 'object') {
    value = value.toString();
  }
  if (getNodesPatchProgress(params?.data?.id, params?.column?.colId) === 'loading') {
    value = '';
  }
  const choice = ((choices && Object.values(choices)) || []).find(choiceItem => choiceItem.label === value);
  if (!choice) {
    // prevent rendering single select key if choice not found
    value = '';
  }

  const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;
  const element = document.createElement('div');
  element.id = `${node.id}-${column.colId}`;
  element.setAttribute('class', 'single-choice-item');

  const valueElement = document.createElement('div');
  valueElement.style = GenericTextRenderer(lines);
  valueElement.innerHTML = value;

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && value?.toLowerCase()?.includes(currentQuickSearch.toLowerCase())) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  if (value.trim() !== '') {
    const bulletColor = document.createElement('div');
    bulletColor.setAttribute('class', 'grid-choice-item-bullet');
    bulletColor.style = `
      background-color: ${choice ? (choice.color || 'lightGrey') : 'lightGrey'};
    `;
    element.append(bulletColor);
  }

  element.append(valueElement);

  if (params.eGridCell && params.colDef && params.colDef.editable) {
    const arrowIcon = document.createElement('span');
    arrowIcon.setAttribute('class', 'dropdown-button');
    const icon = typeIcon['dropDown'].cloneNode(true);
    icon.style = `
    height: 14px;
    color: #37474f;
    display: block;
  `;
    arrowIcon.append(icon);
    element.append(arrowIcon);

    this.editingOverlay = document.createElement('span');
    this.editingOverlay.setAttribute('class', 'singlechoice-to-cell-editor');
    element.append(this.editingOverlay);
  }

  if (params.node.group) {
    this.handleGroup(params, element);
  }

  return {
    element,
    valueElement,
  };
};

SingleSelectRenderer.prototype.init = function(params) {
  const { valueElement, element } = this.createElement(params);
  this.params = params;
  if (valueElement) this.valueElement = valueElement;
  this.element = element;
  this.eGridCell = params.eGridCell;
};

SingleSelectRenderer.prototype.getGui = function() {
  return this.element;
};

SingleSelectRenderer.prototype.refresh = function(params) {
  return false;
};

SingleSelectRenderer.prototype.destroy = function() {
  return true;
};

export default SingleSelectRenderer;
