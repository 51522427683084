import { createAsyncThunk } from '@reduxjs/toolkit';

import { notifications } from 'data/ui/notifications/notifications.actions';
import { State } from 'reducers';

import {
  WORKSPACE_STATUS_COL_ID,
  STATUS_PENDING,
} from '../constants';
import {
  formatStatusDate,
  filteringRecords,
  getGridData,
  parseRecordFields,
} from '../helpers';
import * as services from '../services';
import {
  ProcessType,
  ProcessPayload,
  WorkspaceCreateResponse,
} from '../types';
import { updateRecordsInGrid } from './updateRecords';

/**
 * Triggers Workspace creation process
 */
export const createWorkspaceProcess = createAsyncThunk<
 WorkspaceCreateResponse[],
 ProcessPayload
>(
  `WORKSPACE_CREATE_PROCESS`,
  async ({ ids, notify = true }, { getState, dispatch }) => {
    try {
      const state = getState() as State;
      const { columns, records } = getGridData(state);
      const filteredRecords = filteringRecords(ids, WORKSPACE_STATUS_COL_ID, records);
      const parsedRecords = parseRecordFields(filteredRecords, columns);
      const processIDs = parsedRecords.map(({ id }) => id);

      // Set Pending columns...
      updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, STATUS_PENDING, processIDs, dispatch);
      // Create process
      const { body } = await services.createWorkspacesByRecords(parsedRecords);
      const { workspaces } = body;

      if (notify) {
        dispatch(notifications.success({
          title: 'Workspace Create.',
          message: `Workspace(s) created Successfully.`,
        }));
      }

      workspaces.forEach((workspace, index) => {
        const createdDate = formatStatusDate(workspace.createdAt);
        const rowID = [processIDs[index]];

        if (workspace.processType === ProcessType.SUCCESS) {
          // Update grid with dates
          updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, createdDate, rowID, dispatch);
        } else {
          // remove pending status
          updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, '', rowID, dispatch);
        }
      });

      return workspaces;
    } catch (error) {
      console.error('Workspace Create Error: ', error);
      dispatch(notifications.error({
        title: 'Workspace Create.',
        message: `Workspace creation Failed.`,
      }));
      updateRecordsInGrid(WORKSPACE_STATUS_COL_ID, '', ids, dispatch);
      return error;
    }
  },
);
