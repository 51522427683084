import { getApiUrl, getApiV2Url } from 'env';

export function normalizeURL(url?: string): string {
  // Remove origin if found. Create a new URL based on window.location.
  try {
    const { pathname, search } = new URL(url || `${window.location.pathname}${window.location.search}`, getApiUrl(url));

    if (url && url.indexOf('api_version=2') > -1) {
      return `${getApiV2Url(pathname)}${pathname
        .replace('/api/v1', '')
        .replace('taskdbs', 'databases')
        .replace('tasksheets', 'sheets')}${search}`;
    }

    return `${getApiUrl(pathname)}${pathname}${search}`
      .replace('/demo/demo', '/demo');
  } catch (err) {
    return '/';
  }
}

/**
 * Remove all HTML tags inside a string(<p>, <br>, <span>...)
 *
 * @param text - text with possible markup inside
 * @returns - text with no markup
 */
export function removeHTMLTag(text: string): string {
  return text?.toString().replace(/<[^>]+>|&nbsp;/g, '');
}
