import {
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';

import * as types from './fileUpload.types';

export const uploadFiles = createStandardAction('UPLOAD_FILES')<types.UploadFilesPayload>();
export const uploadAttachments = createStandardAction('UPLOAD_ATTACHMENTS')<types.UploadAttachmentsPayload>();
export const attachmentUploadSuccess = createStandardAction('UPLOAD_ATTACHMENTS/UPLOAD_SUCCESS')<types.UploadAttachmentPayloadItem>();
export const finishUploadAttachments = createStandardAction('UPLOAD_ATTACHMENTS/FINISH_UPLOAD')();
export const copyAttachments = createStandardAction('COPY_ATTACHMENTS')<types.CopyAttachmentPayload>();
export const tempPersistAttachments = createStandardAction('TEMP_PERSIST_ATTACHMENTS')<types.UploadAttachmentsPayload>();
export const deleteTempAttachment = createStandardAction('DELETE_TEMP_PERSISTET_ATTACHMENT')<types.DeleteTempAttachment>();

export const uploadDirectory = createAsyncAction(
  'fileUpload/UPLOAD_DIRECTORY_REQUEST',
  'fileUpload/UPLOAD_DIRECTORY_SUCCESS',
  'fileUpload/UPLOAD_DIRECTORY_FAILURE',
)<File[], any, Error>();

export const sendFileMetadata = createAsyncAction(
  'fileUpload/SEND_FILE_METADATA_REQUEST',
  'fileUpload/SEND_FILE_METADATA_SUCCESS',
  'fileUpload/SEND_FILE_METADATA_FAILURE',
)<types.SendFileMetadataRequestPayload, types.SendFileMetadataSucessPayload, Error>();

export const sendFileBlob = createAsyncAction(
  'fileUpload/SEND_FILE_BLOB_REQUEST',
  'fileUpload/SEND_FILE_BLOB_SUCCESS',
  'fileUpload/SEND_FILE_BLOB_FAILURE',
)<types.SendFileBlobRequestPayload, { title: string }, Error>();

export const startUploadFile = createStandardAction('fileUpload/START_UPLOAD_FILE')<types.StartUploadFilePayload>();
export const uploadFileError = createStandardAction('fileUpload/ERROR')<Error>();
export const finishUploadFile = createStandardAction('fileUpload/FINISH_UPLOAD_FILE')<types.StartUploadFilePayload>();

export const progress = createStandardAction('fileUpload/PROGRESS')<types.ProgressPayload>();

export const actions = {
  uploadDirectory,
  uploadFiles,
  uploadAttachments,
  attachmentUploadSuccess,
  finishUploadAttachments,
  copyAttachments,
  tempPersistAttachments,
  deleteTempAttachment,
  sendFileMetadata,
  sendFileBlob,
  startUploadFile,
  finishUploadFile,
  uploadFileError,
  progress,
};
