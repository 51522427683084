import * as React from 'react';
import { connect } from 'react-redux';

import * as ModalActions from 'data/modals/actions';
import { Actions as CollectionActions } from 'data/collections/collections.actions';

import Confirm from '../Confirm';
import { CollectionTypes } from 'types/schema';
import AgGridApi from '../../AgGrid/AgGridApi';
import { CommonNode } from '../../../types/response';
import { ROW_NUMBER_ID } from '../../DataGrid/columns/constants';

import { useSelector } from 'react-redux';
import { State as RemindersState } from 'data/reminders/reminders';
interface DispatchProps {
  close: typeof ModalActions.closeModal;
  deleteNodes: typeof CollectionActions.deleteNodes;
}

interface OwnProps {
  ids: string[];
  collectionType: CollectionTypes;
  agGridApi?: AgGridApi<CommonNode>;
}

export type Props = OwnProps & DispatchProps;

const DeleteItems = (props: Props): JSX.Element => {
  const reminders = Object.keys(useSelector<RemindersState>(state => state?.reminders?.reminders) as string);
  const remindersId=reminders.map(key=>key.split('_')[0]);
  const hasReminders = props.ids.some(id => remindersId.includes(id));
  const deletableName = getDeletableName(props.ids.length, props.collectionType);

  const handleConfirm = (): void => {
    props.deleteNodes({ ids: props.ids });
    if (props.agGridApi) {
      setTimeout(() => {
        props.agGridApi && props.agGridApi.refreshCells([ROW_NUMBER_ID]);
      }, 100);
    }
  };

  return (
    <Confirm
      title={`Are you sure you want to delete ${deletableName}?`}
      close={props.close}
      onConfirm={handleConfirm}
      message={hasReminders ? 'Removing the date will delete reminders': ''}
      confirmLabel="Delete"
    />
  );
};

const getDeletableName = (length: number, collectionType: CollectionTypes): string => {
  switch (collectionType) {
    case CollectionTypes.documents:
      return length === 1 ? 'this file' : 'these files';
    case CollectionTypes.items:
      return length === 1 ? 'this record' : 'these records';
    case CollectionTypes.tasks:
      return length === 1 ? 'this task' : 'these tasks';
    default:
      return length === 1 ? 'this item' : 'these items';
  }
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  deleteNodes: CollectionActions.deleteNodes,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteItems);
