import * as React from 'react';
// import { get } from 'lodash/fp';

import FavoriteButton from 'components/FavoriteButton/FavoriteButton';
import { FieldRendererProps } from '../Fields.types';

const IsBookmarkedRenderer = ({ data, ...props }: FieldRendererProps ) => {
  return (
    <div style={{ margin: '0 auto' }}>
      <FavoriteButton
        // TODO FIX THIS
        // bookmarkURI={get('uri', data)}
        id={`isFavoriteBtn_${props?.node?.id}`}
        bookmarkMeta={{ title: '', model: '', recordID: '' }}
      />
    </div>
  );
};

export default IsBookmarkedRenderer;
