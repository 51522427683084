import * as React from 'react';
import { Redirect } from 'react-router-dom';

const RepositoryPage = (props) => {
  const redirectURL = `${document.location.pathname}/documents`;
  return (
    <Redirect to={redirectURL} />
  );
};

export default RepositoryPage;
