import * as React from 'react';

import { PreviewProps } from './PreviewBehavior';
import { createAttachmentUrl } from 'utilities/createUrl';
import { Link } from 'react-router-dom';

type Props = PreviewProps;

export const OpenModalPreview = (props: Props): JSX.Element => {
  // @ts-ignore
  const url = createAttachmentUrl(window.location.pathname, props.attachment._id);
  return (
    <Link to={url}>
      {props.children}
    </Link>
  );
};

export default OpenModalPreview;
