import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';

import { ExportMenu, ExportMenuItemIcon } from 'components/TableHeaderTools/modules/ExportOption/ExportOption.style';
import { MenuRenderTriggerParams } from 'components/ui/Menu';
import svgIcons from 'styles/svgIcons';

import Button, { Variant } from 'components/Button';
import { ExportType as AgGridExportType } from '../../../AgGrid/AgGridApi';
import { CollectionViewTypes } from '../../../../types/schema';

interface ExportType {
  name: string;
  extension: string;
  icon: React.ReactNode;
}

const availableExports: ExportType[] = [
  { name: 'Excel', icon: <svgIcons.Excel height="1em"/>, extension: 'xlsx' },
  { name: 'CSV', icon: <svgIcons.Excel height="1em"/>, extension: 'csv' },
];

interface Props {
  onExport: (type: AgGridExportType) => void;
  viewType?: CollectionViewTypes;
}

class ExportOption extends React.Component<Props> {
  render() {
    return (
      <ExportMenu renderTrigger={this.renderTrigger}>
        {availableExports.map(this.renderMenuItem)}
      </ExportMenu>
    );
  }

  private handleExport = (item: ExportType) => {
    this.props.onExport(item.extension === 'xlsx'? AgGridExportType.EXCEL : AgGridExportType.CSV);
  };

  private renderMenuItem = (item: ExportType): JSX.Element => {
    return (
      <MenuItem
        key={item.name}
        component={'a'}
        onClick={() => this.handleExport(item)}
      >
        <ExportMenuItemIcon>{item.icon}</ExportMenuItemIcon>
        {item.name}
      </MenuItem>
    );
  };

  private renderTrigger = ({ openMenu, opened }: MenuRenderTriggerParams): JSX.Element => {
    return (
      <Button
        id="btnExportOption"
        label="Export"
        variant={Variant.TertiaryLink}
        selected={opened}
        onClick={openMenu}
        icon={svgIcons.Export}
      />
    );
  };
}

export default ExportOption;
