import { SummarizeInput } from '../types';

function summarize({ data }: SummarizeInput) {
  return median(data.filter(value => value));
}

export default summarize;

const median = function(array) {
  array = array.sort();
  if (array.length % 2 === 0) {
    return (array[array.length/2] + array[(array.length / 2) - 1]) / 2;
  } else {
    return array[(array.length - 1) / 2];
  }
};
