export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';

export interface SelectedRows {
  type: typeof SET_SELECTED_ROWS;
  selectedRowCount: number;
}

export const setSelectedRowCount = (count: number): SelectedRows => {
  return {
    type: SET_SELECTED_ROWS,
    selectedRowCount: count,
  };
};
