import * as request from 'superagent';
import { getFileProviderApiEndpoint } from 'env';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

let AuthorizationToken = '';
let currentSharedFolder: any = {};

export async function getFileLinkById(fileLinkId: string) {
  const url = `${getFileProviderApiEndpoint()}/public/file-links/${fileLinkId}`;
  const response = await request
    .get(url);
  return _.get(response, 'body');
}

export async function createToken(fileLinkId: string, password?: string) {
  const url = `${getFileProviderApiEndpoint()}/public/file-links/${fileLinkId}/token`;
  const body = password ? { password } : {};
  const response = await request
    .post(url)
    .send(body);
  return _.get(response, 'body');
}

export function setToken(token: string) {
  AuthorizationToken = token;
  setCurrentSharedFolder(token);
}

export function getToken(): string | undefined {
  return AuthorizationToken;
}

export function setCurrentSharedFolder(token: string) {
  currentSharedFolder = jwtDecode(token);
}

export function getCurrentSharedFolder() {
  return currentSharedFolder;
}
