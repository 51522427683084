import { ReactComponent as AnalyticsIcon } from 'icons/service/ic-service-analytics.svg';
import { ReactComponent as DashboardIcon } from 'icons/service/ic-service-dashboard.svg';
import { ReactComponent as DatabasesIcon } from 'icons/service/ic-service-databases.svg';
import { ReactComponent as FilesIcon } from 'icons/service/ic-service-files.svg';
import { ReactComponent as MessagesIcon } from 'icons/service/ic-service-messages.svg';
import { ReactComponent as PeopleIcon } from 'icons/service/ic-service-people.svg';
import { ReactComponent as TasksIcon } from 'icons/service/ic-service-tasks.svg';
import { ReactComponent as WorkspacesIcon } from 'icons/service/ic-service-workspaces.svg';
import { EventIcon } from 'types/response/activityfeedNode';
import { SvgIcon } from 'types/common';

type EventIcons = {
  [key in keyof typeof EventIcon]?: SvgIcon | null;
};

const eventIcons: EventIcons = {
  [EventIcon.analytics]: AnalyticsIcon,
  [EventIcon.dashboard]: DashboardIcon,
  [EventIcon.database]: DatabasesIcon,
  [EventIcon.itemdatabases]: DatabasesIcon,
  [EventIcon.field]: DatabasesIcon,
  [EventIcon.repository]: FilesIcon,
  [EventIcon.message]: MessagesIcon,
  [EventIcon.people]: PeopleIcon,
  [EventIcon.task]: TasksIcon,
  [EventIcon.workspace]: WorkspacesIcon,
  [EventIcon.checklist]: DatabasesIcon,
  [EventIcon.databasetasks]: DatabasesIcon,
  [EventIcon.itemtasks]: TasksIcon,
  [EventIcon.file]: FilesIcon,
  [EventIcon.view]: DashboardIcon,
};

export function getEventIcon(eventIcon: EventIcon): SvgIcon | null {
  return eventIcons[eventIcon] || null;
}
