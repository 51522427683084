import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey =
  | 'root'
  | 'container'
  | 'paper'
  | 'chip'
  | 'inputRoot'
  | 'inputInput'
  | 'item'
  | 'activeItem'
  | 'selectedItem'
  | 'suggestionEmail'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '6px',
    left: 0,
    right: 0,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: '#fff',
  },
  chip: {
    margin: `4px`,
  },
  inputRoot: {
    'flexWrap': 'wrap',
    '&:before': {
      'border': 'none!important',
    },
    '&:after': {
      'border': 'none!important',
    },
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    paddingTop: '5px',
    paddingLeft: '12px',
  },
  activeItem: {
    padding: '5px 10px',
    backgroundColor: 'rgba(0, 101, 255, .05)',
  },
  item: {
    padding: '5px 10px',
    cursor: 'pointer',
  },
  selectedItem: {
    display: 'flex',
    width: '100%',
    padding: '3px 7px',
    cursor: 'pointer',
  },
  suggestionEmail: {
    color: '#607D8B',
    fontSize: '11px',
    marginLeft: '23px',
  },
};
