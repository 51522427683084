import { Column } from 'ag-grid-community';

import { RoleNames } from '../data/users/users.types';

export const isColumnLocked = (column: Column, permissions: string[]) =>
  !!column.getColDef().refData?.lock && !permissions.includes('fields:lock');

export const getUserRole = (permissions: string[]): RoleNames => {
  // 20200310 - Currently only admin has views:lock permission
  const isUserAdmin = permissions.includes('views:lock');
  if (isUserAdmin) return RoleNames.Administrator;

  // TODO: Find a better way to check if the user is an Editor
  // If an user can create or edit fields but cannot lock views then is an Editor
  const isUserEditor = (permissions.includes('items:create') || permissions.includes('items:update')) && !isUserAdmin;
  if (isUserEditor) return RoleNames.Editor;

  return RoleNames.Reader;
};

/**
 *
 * @param column
 * @param permissions
 * @returns
 */
export const allowToModify = (column: Column, permissions: string[]): boolean => {
  const notAllowLock = isColumnLocked(column, permissions);
  const isReader = getUserRole(permissions) === RoleNames.Reader;

  return !isReader && !notAllowLock;
};
