import { createWorkspaceProcess } from 'data/workspaceCreate/actions';


/**
 * Event for creating a single workspace create from Master Database
 * @param props
 */
export const createWorkspaceMasterDataBase = async (props, isOrganizer = false) => {
  const { dispatch, data, isGrid } = props;
  if (isGrid) {
    dispatch(createWorkspaceProcess({ ids: [data.id] }));
  }

  return {};
};


