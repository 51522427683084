import * as _ from 'lodash';

import { withStyles, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CollectionTypes, NodeWithTitle } from 'types/schema';

import { styles } from './CurrentNodeDropdown.style';
import svgIcons from 'styles/svgIcons';
import { SvgIcon } from 'types/common';
import SearchBar from 'components/SearchBar';
import { Size as SearchBarSize } from 'components/SearchBar/SearchBar';
import { stripDomain } from 'utilities/createUrl';
import { getPluralCollectionName } from 'utilities/collections';
import { fetchAllWorkspaces } from 'data/workspaces/services';

interface OwnProps {
  collectionType: CollectionTypes;
  CollectionIcon: SvgIcon;
  collectionUrl: string;
  nodes: Record<string, NodeWithTitle>;
  currentNode: NodeWithTitle;
  onItemClick: () => void;
}

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

interface State {
  searchQuery: string;
  matchedNodes: NodeWithTitle[];
  loadingState: boolean;
}

export class CurrentNodeDropdown extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      searchQuery: '',
      matchedNodes: Object.values(this.props.nodes),
      loadingState: true,
    };
  }

  componentDidMount() {
    this.getWorkspaces('');
  }

  public render = (): JSX.Element => {
    const {
      classes,
      CollectionIcon,
      collectionUrl,
      collectionType,
      currentNode,
      onItemClick,
    } = this.props;
    const { searchQuery, matchedNodes } = this.state;
    const itemNames = getPluralCollectionName(collectionType);

    return (
      <List className={classes.dropdown}>
        <div>
          <ListItem>
            <ListItemIcon className={classes.collectionIcon}>
              <CollectionIcon height="1em" />
            </ListItemIcon>
            <Link to={collectionUrl}>
              <ListItemText primary={itemNames} />
            </Link>
          </ListItem>
          <Divider />
        </div>
        <ListItem>
          <SearchBar
            placeholder={`Search ${itemNames}`}
            value={searchQuery}
            onChange={this.handleQueryChange}
            size={SearchBarSize.Small}
            autoFocus
          />
        </ListItem>
        {this.state.loadingState && itemNames === 'Workspaces' ? (
          <ListItemText primary={'Loading workspaces ...'} />
        ) : (
          matchedNodes.map((node) => (
            <ListItem className={classes.databaseListItem} key={node.id}>
              <ListItemIcon>
                <svgIcons.Check
                  width="10px"
                  height="10px"
                  className={
                    currentNode && node.id === currentNode.id
                      ? classes.selectedItemIcon
                      : classes.unselectedItemIcon
                  }
                />
              </ListItemIcon>
              <Link
                to={stripDomain(node.linkURI || node.uri)}
                onClick={onItemClick}
              >
                <ListItemText
                  primary={
                    node.meta?.clientName
                      ? String(node.meta.clientName).replace(/amp;/g, '')
                      : String(node.title).replace(/amp;/g, '')
                  }
                />
              </Link>
            </ListItem>
          ))
        )}
      </List>
    );
  };

  private getWorkspaces = _.debounce(async (query): Promise<any> => {
    const itemNames = getPluralCollectionName(this.props.collectionType);
    this.setState({ loadingState: true });
    const {
      body: { results },
    } = await fetchAllWorkspaces(query);
    const workspacesTransformed = results.map((node) => {
      return {
        ...node,
        id: node._id,
        linkURI: `${window.location.protocol}//${window.location.host}/workspaces/${node._id}`,
      };
    });

    if (itemNames === 'Workspaces') {
      this.setState({
        matchedNodes: workspacesTransformed,
        loadingState: false,
      });
    }
  }, 500);

  private handleQueryChange = (event) => {
    const searchQuery: string = event.target.value;
    const itemNames = getPluralCollectionName(this.props.collectionType);
    if (itemNames === 'Workspaces') {
      this.setState({ searchQuery });
      this.getWorkspaces(event.target.value);
    } else {
      const matchedNodes = Object.values(this.props.nodes).filter((w) =>
        w.title.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      this.setState({
        searchQuery,
        matchedNodes,
      });
    }
  };
}

export default withStyles(styles)(CurrentNodeDropdown);
