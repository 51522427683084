import * as React from 'react';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { push } from 'connected-react-router';
import Tabs from '@material-ui/core/Tabs';
import { MenuItem } from 'types/response/workspaceNode';
import { WorkspaceMenuWrapper } from 'components/WorkspaceBar/WorkspaceMenu/WorkspaceMenu.style';
import MenuTab from './MenuTab';
import { stripDomain } from 'utilities/createUrl';
import { exractDbID, isDB } from 'utilities/parseURI';
import { currentLocationPath } from 'data/app/selectors';
import { getDatabasesIds } from 'data/databases/databases.selectors';
import { createStructuredSelector } from 'reselect';
import { State } from 'reducers';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { getTenantConfig } from 'env';
const tenantConfig = getTenantConfig() as any;

interface OwnProps {
  menuItems: MenuItem[];
}

interface StateToProps {
  currentPath: string | null;
  dbIds: string[];
}

interface DispatchToProps {
  push: (path: string) => void;
}

export type Props = OwnProps & StateToProps & DispatchToProps & URLInjectedProps;

export class WorkspaceMenu extends React.PureComponent<Props> {
  render() {
    const { menuItems, dbIds } = this.props;
    const selectedMenuIndex = this.getSelectedMenuIndex();
    const tabsValue = selectedMenuIndex === -1 ? false : selectedMenuIndex;
    const { pathname } = this.props.location;

    if (((pathname.includes('databases') && pathname.includes('items')) || pathname.includes('pages')) &&
      tenantConfig.customMenus && Array.isArray(tenantConfig.customMenus.databaseItems)
    ) {
      const menuIndex = tenantConfig.customMenus.databaseItems.findIndex(menu => menu.apiUri === pathname);
      return (
        <WorkspaceMenuWrapper>
          <Tabs
            value={menuIndex}
            indicatorColor="primary"
            textColor="primary">
            {tenantConfig.customMenus.databaseItems.filter(item => {
              if (isDB(item.apiUri)) return dbIds.includes(exractDbID(item.apiUri));
              return true;
            }).map(menu => {
              return (
                <MenuTab
                  key={menu.label}
                  item={menu}
                  onClick={() => this.props.history.push(menu.apiUri)}
                />
              );
            })}
          </Tabs>
        </WorkspaceMenuWrapper>
      );
    }

    return (
      <WorkspaceMenuWrapper>
        <Tabs
          value={tabsValue}
          indicatorColor="primary"
          textColor="primary"
        >
          {menuItems?.map?.(this.renderItem) || []}
        </Tabs>
      </WorkspaceMenuWrapper>
    );
  }

  private renderItem = (item: MenuItem) => (
    <MenuTab
      key={item.label}
      item={item}
      onClick={this.handleMenuItemClick}
    />
  );

  private handleMenuItemClick = (item: MenuItem) => {
    this.props.push(stripDomain(item.linkURI));
  };

  private getSelectedMenuIndex = (): number => {
    const { menuItems, currentPath } = this.props;
    if (currentPath) {
      return findIndex(menuItems, (item) => currentPath.includes(stripDomain(item.linkURI)));
    }

    return -1;
  };
}

const mapDispatchToProps: DispatchToProps = {
  push,
};

const mapStateToProps = createStructuredSelector<State, StateToProps>({
  currentPath: currentLocationPath,
  dbIds: getDatabasesIds,
});

export default connect(mapStateToProps, mapDispatchToProps)(withURLParams(WorkspaceMenu));
