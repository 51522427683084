import * as React from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { blueyGrey } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import { DropdownList, DropdownListItem } from './TableHeaderTools.style';

interface ItemProps {
  children: React.ReactNode;
  hideDragHandle?: boolean;
}

export const SortableList = SortableContainer(DropdownList);

export const SortableListItem = SortableElement<ItemProps>(
  ({ children, hideDragHandle, ...props }) => {
    return (
      <DropdownListItem {...props}>
        {children}
        {!hideDragHandle && <DragThumb id={`drag-thumb-${props.id}`} />}
      </DropdownListItem>
    );
  },
);

const DragThumb = SortableHandle(styled(svgIcons.Drag)`
  height: 1em;
  cursor: grab;
  padding: 0 0 0 0.5rem;
  margin-left: auto;
  margin-right: -1px;
  color: ${blueyGrey};
`);
