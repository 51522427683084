import { FieldType } from '../../../../../types/response/fieldNode';
import { SummarizeInput, SummaryTypes } from '../../types';
import { numericOptions } from '../../getSummaryOptionsForColumnType';

function modifyValue(value, input: SummarizeInput) {
  let prepend = '';
  let append = '';

  const { type, column } = input;

  if (numericOptions.map(no => no.type).includes(SummaryTypes[type])) {
    if (column && column.fieldType === FieldType.Currency) {
      prepend = column.currencySymbol || '$';
    }

    if (column && column.fieldType === FieldType.Percent) {
      append = '%';
    }
  }

  if (prepend) {
    return `${prepend}${value}`;
  }

  if (append) {
    return `${value}${append}`;
  }

  if ([
    SummaryTypes.percentFilled,
    SummaryTypes.percentEmpty,
    SummaryTypes.percentChecked,
    SummaryTypes.percentUnchecked,
    SummaryTypes.percentUnique,
  ].includes(SummaryTypes[input.type])) {
    const percentFormatted = (Math.round(value * 100) / 100).toFixed(2);
    return `${percentFormatted}%`;
  }
  return value;
}

export default modifyValue;
