import { Theme, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { white } from 'styles/colors';
import { TreeNode } from 'types/response/http/getNode';
import { stripDomain } from 'utilities/createUrl';

import FavoriteButton from '../../FavoriteButton/FavoriteButton';
import Typography, { Variant } from '../../Typography';

interface Props extends WithStyles<typeof styles> {
  node: TreeNode;
}

export function Item(props: Props): JSX.Element {
  return (
    <li className={props.classes.box}>
      {props.node.uri
        ? (
          <Typography
            variant={Variant.NavigationTitle}
            className={props.classes.title}
          >
            <Link to={stripDomain(props.node.uri)}>
              {props.node.title}
            </Link>
          </Typography>
        )
        : props.node.title
      }
      {props.node.uri
        && <FavoriteButton
          id={`favoriteBtn_${props.node.id}`}
          bookmarkId={props.node.id || ''}
          className={props.classes.favorite}
        />
      }
    </li>
  );
}

type ClassKey
  = 'box'
  | 'title'
  | 'favorite'
  ;

function styles({ tenantTheme = {} }: Theme): StyleRules<ClassKey> {
  const { menuTextColor = white } = tenantTheme;

  return {
    box: {
      'display': 'flex',
      'alignItems': 'center',
      'marginTop': 10,
      'color': menuTextColor,
      '&:hover > div': { visibility: 'visible' },
    },
    title: {
      color: 'unset',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    favorite: {
      marginLeft: 7,
      visibility: 'hidden',
    },
  };
}

export default withStyles(styles)(Item);
