import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash/fp';

import * as ModalActions from 'data/modals/actions';
import { deleteWorkspace } from 'data/workspaces/workspaces.actions';
import { deleteDatabaseRequest } from 'data/databases/databases.actions';
import { CollectionTypes, CollectionTypeWithTitle, NodeWithTitle } from 'types/schema';

import Confirm from '../Confirm';
import { getSingularCollectionName } from 'utilities/collections';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
  deleteWorkspace: typeof deleteWorkspace.request;
  deleteDatabase: typeof deleteDatabaseRequest;
}

interface OwnProps {
  node: NodeWithTitle;
  collectionType: CollectionTypeWithTitle;
}

export type Props = OwnProps & DispatchProps;

const DeleteNode = (props: Props): JSX.Element => {
  const { node, collectionType, deleteWorkspace, deleteDatabase } = props;
  const collectionName = _.toLower(getSingularCollectionName(collectionType));

  const handleConfirm = (): void => {
    switch (collectionType) {
      case CollectionTypes.workspaces:
        deleteWorkspace(node.id);
        break;
      case CollectionTypes.databases:
      case CollectionTypes.taskdbs:
        deleteDatabase({
          uri: node.apiURI,
          id: node.id,
        }, collectionType);
        break;
    }
  };

  return (
    <Confirm
      title={`Are you sure you want to delete this ${collectionName}?`}
      close={props.close}
      onConfirm={handleConfirm}
      confirmLabel="Delete"
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  deleteWorkspace: deleteWorkspace.request,
  deleteDatabase: deleteDatabaseRequest,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteNode);
