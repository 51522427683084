import { white } from 'styles/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'wrapper' | 'header';

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    background: white,
    margin: '0 -16px',
  },
  header: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '32px 16px 24px',
    height: '94px',
  },
};
