import { get } from 'utilities/httpRequests';

const ITEMS_PER_PAGE = 10;

function buildActivityURL(workspaceID, next) {
  const path = `/workspaces/${workspaceID}/activity-indexes?api_version=2`;
  return next ? path.concat(`&next=${next}`) : path;
}

export const fetchActivityFeed = async (workspaceID, nextQuery) => {
  const endpoint = buildActivityURL(workspaceID, nextQuery);
  const { body: { items, next } } = await get(endpoint);
  const nextValue = items.length >= ITEMS_PER_PAGE
    ? next
    : undefined;

  return { items, nextValue };
};
