import { CommonNode } from 'types/response';
import { DatabaseNode } from 'types/response/databaseNode';
import { DocumentNode, DocumentMinimalType } from 'types/response/documentNode';
import { ViewNode } from 'types/response/viewNode';
import { WorkspaceNode } from 'types/response/workspaceNode';
import { Breadcrumb, Choice, CollectionTypes, CollectionTypeWithTitle, Column, NodeWithTitle } from 'types/schema';

import AgGridApi from '../../components/AgGrid/AgGridApi';

// All Actions

export type Action =
  | CloseDeleteAttachmentModal
  | CloseModal
  | OpenEditViewModal
  | OpenNewCalendarViewModal
  | OpenNewGridViewModal
  | OpenNewKanbanViewModal
  | NewViewName
  | SaveNewGridView
  | OpenNodeModal
  | OpenDeleteAttachmentModal
  | OpenDeleteFolderModal
  | OpenDeleteNodeModal
  | OpenDeleteItemsModal
  | OpenDeleteGridColumnModal
  | OpenDeleteViewModal
  | OpenDeleteSheetModal
  | OpenRenameBreadcrumbModal
  | OpenRenameCollectionItemModal
  | OpenRenameDocumentModal
  | OpenRenameKanbanColumnModal
  | OpenDeleteKanbanColumnModal
  | OpenCreateCollectionItemModal
  | OpenDuplicateDatabase
  | ChangeSelectedWorkspace
  | ChangeShouldDuplicateRecords
  | ChangeShouldDuplicateComments
  | DuplicateDatabase
  | SelectCategoryField
  | SelectDateField
  | SelectTitleField
  | OpenMoveCopyDocumentModal
  | OpenInfoModal
  | NewViewPrivateState
  | NewViewLockState
  | NewViewDefaultState
  | SetNodeData
  | OpenAccountModal
  | OpenCancelEnvelopeModal
  | OpenLockPermission
  | OpenCancelEnvelopePermission
  | OpenOrganizerRequiresWorkspace
  | OpenResendEnvelopeModal
  | OpenResendEnvelopePermission
  | OpenWorkSpaceInfoModal
  | RenameWorkspaceWithMeta
  ;

// Type Constants

export enum ActionType {
  CLOSE_DELETE_ATTACHMENT_MODAL = 'CLOSE_DELETE_ATTACHMENT_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_EDIT_VIEW_MODAL = 'OPEN_EDIT_VIEW_MODAL',
  OPEN_NEW_CALENDAR_VIEW_MODAL = 'OPEN_NEW_CALENDAR_VIEW_MODAL',
  OPEN_NEW_GRID_VIEW_MODAL = 'OPEN_NEW_GRID_VIEW_MODAL',
  OPEN_NEW_KANBAN_VIEW_MODAL = 'OPEN_NEW_KANBAN_VIEW_MODAL',
  NEW_VIEW_NAME = 'NEW_VIEW_NAME',
  NEW_VIEW_PRIVATE = 'NEW_VIEW_PRIVATE',
  NEW_VIEW_LOCK = 'NEW_VIEW_LOCK',
  NEW_VIEW_DEFAULT = 'NEW_VIEW_DEFAULT',
  SAVE_NEW_GRID_VIEW = 'SAVE_NEW_GRID_VIEW',
  OPEN_NODE_MODAL = 'OPEN_NODE_MODAL',
  OPEN_DELETE_ATTACHMENT_MODAL = 'OPEN_DELETE_ATTACHMENT_MODAL',
  OPEN_DELETE_FOLDER_MODAL = 'OPEN_DELETE_FOLDER_MODAL',
  OPEN_DELETE_NODE_MODAL = 'OPEN_DELETE_NODE_MODAL',
  OPEN_DELETE_ITEMS_MODAL = 'OPEN_DELETE_ITEMS_MODAL',
  OPEN_DELETE_GRID_COLUMN_MODAL = 'OPEN_DELETE_GRID_COLUMN_MODAL',
  OPEN_DELETE_VIEW_MODAL = 'OPEN_DELETE_VIEW_MODAL',
  OPEN_DELETE_SHEET_MODAL = 'OPEN_DELETE_SHEET_MODAL',
  OPEN_RENAME_BREADCRUMB_MODAL = 'OPEN_RENAME_BREADCRUMB_MODAL',
  OPEN_RENAME_COLLECTION_ITEM_MODAL = 'OPEN_RENAME_COLLECTION_ITEM_MODAL',
  OPEN_RENAME_DOCUMENT_MODAL = 'OPEN_RENAME_DOCUMENT_MODAL',
  OPEN_RENAME_KANBAN_COLUMN_MODAL = 'OPEN_RENAME_KANBAN_COLUMN_MODAL',
  OPEN_DELETE_KANBAN_COLUMN_MODAL = 'OPEN_DELETE_KANBAN_COLUMN_MODAL',
  OPEN_CREATE_COLLECTION_ITEM_MODAL = 'OPEN_CREATE_COLLECTION_ITEM_MODAL',
  OPEN_DUPLICATE_DATABASE = 'OPEN_DUPLICATE_DATABASE',
  OPEN_ACCOUNT_MODAL = 'OPEN_ACCOUNT_MODAL',
  OPEN_LOCK_PERMISSION_MODAL = 'OPEN_LOCK_PERMISSION',
  CHANGE_SELECTED_WORKSPACE = 'CHANGE_SELECTED_WORKSPACE',
  CHANGE_SHOULD_DUPLICATE_RECORDS = 'CHANGE_SHOULD_DUPLICATE_RECORDS',
  CHANGE_SHOULD_DUPLICATE_COMMENTS = 'CHANGE_SHOULD_DUPLICATE_COMMENTS',
  DUPLICATE_DATABASE = 'DUPLICATE_DATABASE',
  SELECT_CATEGORY_FIELD = 'SELECT_CATEGORY_FIELD',
  SELECT_DATE_FIELD = 'SELECT_DATE_FIELD',
  SELECT_TITLE_FIELD = 'SELECT_TITLE_FIELD',
  OPEN_MOVE_COPY_DOCUMENT_MODAL = 'OPEN_MOVE_COPY_DOCUMENT_MODAL',
  OPEN_INFO_MODAL = 'OPEN_INFO_MODAL',
  SET_NODE_FIELDS = 'modals/SET_NODE_FIELDS',
  OPEN_CANCEL_ENVELOPE_MODAL = 'OPEN_CANCEL_ENVELOPE_MODAL',
  OPEN_CANCEL_ENVELOPE_PERMISSION_MODAL = 'OPEN_CANCEL_ENVELOPE_PERMISSION_MODAL',
  OPEN_ORGANIZER_REQUIRES_WORKSPACE = 'OPEN_ORGANIZER_REQUIRES_WORKSPACE',
  OPEN_RESEND_ENVELOPE_MODAL = 'OPEN_RESEND_ENVELOPE_MODAL',
  OPEN_RESEND_ENVELOPE_PERMISSION_MODAL = 'OPEN_RESEND_ENVELOPE_PERMISSION_MODAL',
  OPEN_WORKSPACE_INFO_MODAL = 'OPEN_WORKSPACE_INFO_MODAL',
  RENAME_WORKSPACE_WITH_META = 'RENAME_WORKSPACE_WITH_META',
}

// Interfaces

interface OpenAccountModal {
  type: ActionType.OPEN_ACCOUNT_MODAL;
  tab?: string;
}

interface CloseDeleteAttachmentModal {
  type: ActionType.CLOSE_DELETE_ATTACHMENT_MODAL;
}

interface CloseModal {
  type: ActionType.CLOSE_MODAL;
}

export interface OpenEditViewModal {
  type: ActionType.OPEN_EDIT_VIEW_MODAL;
  view: ViewNode;
}

interface OpenNewCalendarViewModal {
  type: ActionType.OPEN_NEW_CALENDAR_VIEW_MODAL;
  existingViewNames: string[];
  newViewName: string;
  fields: Column[];
}

interface OpenNewGridViewModal {
  type: ActionType.OPEN_NEW_GRID_VIEW_MODAL;
  apiURI: string;
  existingViewNames: string[];
  viewName: string;
}

interface OpenNewKanbanViewModal {
  type: ActionType.OPEN_NEW_KANBAN_VIEW_MODAL;
  existingViewNames: string[];
  newViewName: string;
  fields: Column[];
}

interface NewViewName {
  type: ActionType.NEW_VIEW_NAME;
  viewName: string;
}

interface NewViewPrivateState {
  type: ActionType.NEW_VIEW_PRIVATE;
  isPrivate: boolean;
}

interface NewViewLockState {
  type: ActionType.NEW_VIEW_LOCK;
  isLocked: boolean;
}

interface NewViewDefaultState {
  type: ActionType.NEW_VIEW_DEFAULT;
  isDefault: boolean;
}

export interface SaveNewGridView {
  type: ActionType.SAVE_NEW_GRID_VIEW;
  apiURI: string;
  viewName: string;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
}

export interface OpenNodeModal {
  type: ActionType.OPEN_NODE_MODAL;
  node: Partial<CommonNode>;
  windowTitle?: string;
  enableSwitcher?: boolean;
}

export interface OpenDeleteAttachmentModal {
  type: ActionType.OPEN_DELETE_ATTACHMENT_MODAL;
  uri: string;
  id: string;
}

export interface OpenDeleteFolderModal {
  type: ActionType.OPEN_DELETE_FOLDER_MODAL;
  breadcrumb: Breadcrumb;
}

export interface OpenDeleteNodeModal {
  type: ActionType.OPEN_DELETE_NODE_MODAL;
  node: NodeWithTitle;
  collectionType: CollectionTypeWithTitle;
}

export interface OpenDeleteItemsModal {
  type: ActionType.OPEN_DELETE_ITEMS_MODAL;
  ids: string[];
  collectionType: CollectionTypes;
  agGridApi?: AgGridApi<CommonNode>;
}

export interface OpenDeleteGridColumnModal {
  type: ActionType.OPEN_DELETE_GRID_COLUMN_MODAL;
  columnId: string;
}

export interface OpenCancelEnvelopeModal {
  type: ActionType.OPEN_CANCEL_ENVELOPE_MODAL;
  envelopeId: string;
}

export interface OpenCancelEnvelopePermission {
  type: ActionType.OPEN_CANCEL_ENVELOPE_PERMISSION_MODAL;
}

export interface OpenOrganizerRequiresWorkspace {
  type: ActionType.OPEN_ORGANIZER_REQUIRES_WORKSPACE;
  ids: string[];
}
export interface OpenResendEnvelopeModal {
  type: ActionType.OPEN_RESEND_ENVELOPE_MODAL;
  envelopeId: string;
  signerEmail: string;
}

export interface OpenResendEnvelopePermission {
  type: ActionType.OPEN_RESEND_ENVELOPE_PERMISSION_MODAL;
}

export interface OpenDeleteViewModal {
  type: ActionType.OPEN_DELETE_VIEW_MODAL;
  viewId: string;
}

export interface OpenDeleteSheetModal {
  type: ActionType.OPEN_DELETE_SHEET_MODAL;
  sheetId: string;
}

export interface OpenRenameBreadcrumbModal {
  type: ActionType.OPEN_RENAME_BREADCRUMB_MODAL;
  breadcrumb: Breadcrumb;
}

interface OpenRenameCollectionItemModal {
  type: ActionType.OPEN_RENAME_COLLECTION_ITEM_MODAL;
  collectionType: CollectionTypeWithTitle;
  node: NodeWithTitle;
}

export interface OpenRenameDocumentModal {
  type: ActionType.OPEN_RENAME_DOCUMENT_MODAL;
  document: DocumentNode;
}

export interface OpenRenameKanbanColumnModal {
  type: ActionType.OPEN_RENAME_KANBAN_COLUMN_MODAL;
  categoryFieldId: string;
  choiceId: string;
  choices: Record<string, Choice>;
}

export interface OpenDeleteKanbanColumnModal {
  type: ActionType.OPEN_DELETE_KANBAN_COLUMN_MODAL;
  categoryField: Column;
  choiceId: string;
}

export interface OpenCreateCollectionItemModal {
  type: ActionType.OPEN_CREATE_COLLECTION_ITEM_MODAL;
  collectionType: CollectionTypes;
}

export interface OpenLockPermission {
  type: ActionType.OPEN_LOCK_PERMISSION_MODAL;
}

interface OpenDuplicateDatabase {
  type: ActionType.OPEN_DUPLICATE_DATABASE;
  database: DatabaseNode;
  availableWorkspaces: WorkspaceNode[];
}

interface ChangeSelectedWorkspace {
  type: ActionType.CHANGE_SELECTED_WORKSPACE;
  workspace: WorkspaceNode;
}

interface ChangeShouldDuplicateRecords {
  type: ActionType.CHANGE_SHOULD_DUPLICATE_RECORDS;
}

interface ChangeShouldDuplicateComments {
  type: ActionType.CHANGE_SHOULD_DUPLICATE_COMMENTS;
}

export interface DuplicateDatabase {
  type: ActionType.DUPLICATE_DATABASE;
  database: DatabaseNode;
  databases: DatabaseNode[];
  workspace: WorkspaceNode;
  shouldDuplicateRecords: boolean;
  shouldDuplicateComments: boolean;
  collectionType: CollectionTypes;
}

interface SelectCategoryField {
  type: ActionType.SELECT_CATEGORY_FIELD;
  selectedCategoryField: Column;
}

interface SelectDateField {
  type: ActionType.SELECT_DATE_FIELD;
  selectedDateField: Column;
}

interface SelectTitleField {
  type: ActionType.SELECT_TITLE_FIELD;
  selectedTitleField: Column;
}

export interface OpenMoveCopyDocumentModal {
  type: ActionType.OPEN_MOVE_COPY_DOCUMENT_MODAL;
  selectedItems: DocumentMinimalType[];
}

export interface OpenInfoModal {
  type: ActionType.OPEN_INFO_MODAL;
  title?: string;
  message?: string;
  confirmLabel?: string;
}

export interface SetNodeData {
  type: ActionType.SET_NODE_FIELDS;
  node: Node;
}

export interface OpenWorkSpaceInfoModal {
  type: ActionType.OPEN_WORKSPACE_INFO_MODAL;
  workspaceInfo: any;
}

export interface RenameWorkspaceWithMeta {
  type: ActionType.RENAME_WORKSPACE_WITH_META;
  currentNode: NodeWithTitle;
}

// Constructors

export function closeDeleteAttachmentModal(): CloseDeleteAttachmentModal {
  return { type: ActionType.CLOSE_DELETE_ATTACHMENT_MODAL };
}

export function closeModal(): CloseModal {
  return { type: ActionType.CLOSE_MODAL };
}

export function openEditViewModal(view: ViewNode): OpenEditViewModal {
  return { type: ActionType.OPEN_EDIT_VIEW_MODAL, view };
}

export function openNewCalendarViewModal(
  existingViewNames: string[],
  newViewName: string,
  fields: Column[],
): OpenNewCalendarViewModal {
  return {
    type: ActionType.OPEN_NEW_CALENDAR_VIEW_MODAL,
    existingViewNames,
    newViewName,
    fields,
  };
}

export function openNewGridViewModal(apiURI: string, existingViewNames: string[], viewName: string): OpenNewGridViewModal {
  return { type: ActionType.OPEN_NEW_GRID_VIEW_MODAL, apiURI, existingViewNames, viewName };
}

export function openNewKanbanViewModal(
  existingViewNames: string[],
  newViewName: string,
  fields: Column[],
): OpenNewKanbanViewModal {
  return {
    type: ActionType.OPEN_NEW_KANBAN_VIEW_MODAL,
    existingViewNames,
    newViewName,
    fields,
  };
}

export function newViewName(viewName: string): NewViewName {
  return { type: ActionType.NEW_VIEW_NAME, viewName };
}

export function newViewPrivateStatus(isPrivate: boolean): NewViewPrivateState {
  return { type: ActionType.NEW_VIEW_PRIVATE, isPrivate };
}

export function newViewLockStatus(isLocked: boolean): NewViewLockState {
  return { type: ActionType.NEW_VIEW_LOCK, isLocked };
}

export function newViewDefaultStatus(isDefault: boolean): NewViewDefaultState {
  return { type: ActionType.NEW_VIEW_DEFAULT, isDefault };
}

export function saveNewGridView(apiURI: string, viewName: string, isPrivate?: boolean, isLocked?: boolean, isDefault?: boolean): SaveNewGridView {
  return { type: ActionType.SAVE_NEW_GRID_VIEW, apiURI, viewName, isPrivate, isLocked, isDefault };
}

export function openNodeModal(node: Partial<CommonNode>, windowTitle?: string, enableSwitcher?: boolean): OpenNodeModal {
  return { type: ActionType.OPEN_NODE_MODAL, node, windowTitle, enableSwitcher };
}

export function openDeleteAttachmentModal(id: string, uri: string): OpenDeleteAttachmentModal {
  return { type: ActionType.OPEN_DELETE_ATTACHMENT_MODAL, id, uri };
}

export function openDeleteFolderModal(breadcrumb: Breadcrumb): OpenDeleteFolderModal {
  return { type: ActionType.OPEN_DELETE_FOLDER_MODAL, breadcrumb };
}

export function openDeleteNodeModal(node: NodeWithTitle, collectionType: CollectionTypeWithTitle): OpenDeleteNodeModal {
  return { type: ActionType.OPEN_DELETE_NODE_MODAL, node, collectionType };
}

export function openDeleteItemsModal(ids: string[], collectionType: CollectionTypes, agGridApi?: any): OpenDeleteItemsModal {
  return { type: ActionType.OPEN_DELETE_ITEMS_MODAL, ids, collectionType, agGridApi };
}

export function openDeleteGridColumnModal(columnId: string): OpenDeleteGridColumnModal {
  return { type: ActionType.OPEN_DELETE_GRID_COLUMN_MODAL, columnId };
}

export function openLockPermissionsModal(): OpenLockPermission {
  return { type: ActionType.OPEN_LOCK_PERMISSION_MODAL };
}

export function openDeleteViewModal(viewId: string): OpenDeleteViewModal {
  return { type: ActionType.OPEN_DELETE_VIEW_MODAL, viewId };
}

export function openDeleteSheetModal(sheetId: string): OpenDeleteSheetModal {
  return { type: ActionType.OPEN_DELETE_SHEET_MODAL, sheetId };
}

export function openRenameBreadcrumbModal(breadcrumb: Breadcrumb): OpenRenameBreadcrumbModal {
  return { type: ActionType.OPEN_RENAME_BREADCRUMB_MODAL, breadcrumb };
}

export function openAccountModal(tab?: string): OpenAccountModal {
  return { type: ActionType.OPEN_ACCOUNT_MODAL, tab: tab };
}

export function openRenameCollectionItemModal(
  collectionType: CollectionTypeWithTitle,
  node: NodeWithTitle,
): OpenRenameCollectionItemModal {
  return { type: ActionType.OPEN_RENAME_COLLECTION_ITEM_MODAL, collectionType, node };
}

export function openRenameDocumentModal(document: DocumentNode): OpenRenameDocumentModal {
  return { type: ActionType.OPEN_RENAME_DOCUMENT_MODAL, document };
}

export function openRenameKanbanColumnModal(
  categoryFieldId: string,
  choiceId: string,
  choices: Record<string, Choice>,
): OpenRenameKanbanColumnModal {
  return { type: ActionType.OPEN_RENAME_KANBAN_COLUMN_MODAL, categoryFieldId, choiceId, choices };
}

export function openDeleteKanbanColumnModal(categoryField: Column, choiceId: string): OpenDeleteKanbanColumnModal {
  return { type: ActionType.OPEN_DELETE_KANBAN_COLUMN_MODAL, categoryField, choiceId };
}

export function openCreateCollectionItemModal(collectionType: CollectionTypes): OpenCreateCollectionItemModal {
  return { type: ActionType.OPEN_CREATE_COLLECTION_ITEM_MODAL, collectionType };
}

export function openDuplicateDatabase(database: DatabaseNode, availableWorkspaces: WorkspaceNode[]): OpenDuplicateDatabase {
  return { type: ActionType.OPEN_DUPLICATE_DATABASE, database, availableWorkspaces };
}

export function changeSelectedWorkspace(workspace: WorkspaceNode): ChangeSelectedWorkspace {
  return { type: ActionType.CHANGE_SELECTED_WORKSPACE, workspace };
}

export function changeShouldDuplicateRecords(): ChangeShouldDuplicateRecords {
  return { type: ActionType.CHANGE_SHOULD_DUPLICATE_RECORDS };
}

export function changeShouldDuplicateComments(): ChangeShouldDuplicateComments {
  return { type: ActionType.CHANGE_SHOULD_DUPLICATE_COMMENTS };
}

export function duplicateDatabase(database: DatabaseNode, databases: DatabaseNode[], workspace: WorkspaceNode, shouldDuplicateRecords: boolean, shouldDuplicateComments: boolean, collectionType: CollectionTypes): DuplicateDatabase {
  return { type: ActionType.DUPLICATE_DATABASE, database, databases, workspace, shouldDuplicateRecords, shouldDuplicateComments, collectionType };
}

export function selectCategoryField(selectedCategoryField: Column): SelectCategoryField {
  return { type: ActionType.SELECT_CATEGORY_FIELD, selectedCategoryField };
}

export function selectDateField(selectedDateField: Column): SelectDateField {
  return { type: ActionType.SELECT_DATE_FIELD, selectedDateField };
}

export function selectTitleField(selectedTitleField: Column): SelectTitleField {
  return { type: ActionType.SELECT_TITLE_FIELD, selectedTitleField };
}

export function openMoveCopyDocumentModal(selectedItems: DocumentMinimalType[]): OpenMoveCopyDocumentModal {
  return { type: ActionType.OPEN_MOVE_COPY_DOCUMENT_MODAL, selectedItems };
}

export function openInfoModal(title?: string, message?: string, confirmLabel?: string): OpenInfoModal {
  return { type: ActionType.OPEN_INFO_MODAL, title, message, confirmLabel };
}

export function setNodeData(node: Node): SetNodeData {
  return { type: ActionType.SET_NODE_FIELDS, node };
}

export function openCancelEnvelopeModal(envelopeId: string): OpenCancelEnvelopeModal {
  return { type: ActionType.OPEN_CANCEL_ENVELOPE_MODAL, envelopeId };
}

export function openCancelEnvelopePermissionModal(): OpenCancelEnvelopePermission {
  return { type: ActionType.OPEN_CANCEL_ENVELOPE_PERMISSION_MODAL };
}

export function openOrganizerRequiresWorkspace(ids: string[]): OpenOrganizerRequiresWorkspace {
  return { type: ActionType.OPEN_ORGANIZER_REQUIRES_WORKSPACE, ids };
}

export function openResendEnvelopeModal(envelopeId: string, signerEmail: string): OpenResendEnvelopeModal {
  return { type: ActionType.OPEN_RESEND_ENVELOPE_MODAL, envelopeId, signerEmail };
}

export function openResendEnvelopePermissionModal(): OpenResendEnvelopePermission {
  return { type: ActionType.OPEN_RESEND_ENVELOPE_PERMISSION_MODAL };
}

export function openWorkspaceInfoModal(workspaceInfo: any): OpenWorkSpaceInfoModal {
  return { type: ActionType.OPEN_WORKSPACE_INFO_MODAL, workspaceInfo };
}

export function onReNameWorkspaceWithMeta(currentNode: NodeWithTitle): RenameWorkspaceWithMeta {
  return { type: ActionType.RENAME_WORKSPACE_WITH_META, currentNode };
}
