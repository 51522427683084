import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import {
  EditorState,
  CompositeDecorator,
  convertFromRaw,
  RawDraftContentState,
  ContentBlock,
  ContentState,
} from 'draft-js';
import Editor, { EditorPlugin } from 'draft-js-plugins-editor';

import { styles } from './Editor.style';

import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify';


interface OwnProps {
  id?: string;
  editorState: RawDraftContentState;
}

interface State {
  editorState: EditorState;
}

type Props = OwnProps & WithStyles<typeof styles>;

const Mention = (props) => {
  return <span className={props.classes.mentionWrapper} >{props.children}</span>;
};

const Linky = (props) => {
  let link = props.decoratedText;
  link = (link.indexOf('http://') != 0 && link.indexOf('https://') != 0) ? link = 'https://'+link : link;

  return (
    <a style={styles.linkyWrapper} href={link} target="_blank" rel="noopener noreferrer">{props.children}</a>
  );
};

const MentionWithStyles = withStyles(styles)(Mention);
const LinkyWithStyles = withStyles(styles)(Linky);


const findMentions = (
  contentBlock: ContentBlock,
  callback,
  contentState: ContentState,
): void => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'mention'
    );
  }, callback);
};


const handleRegexStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  const text = contentBlock.getText();
  const arrayLink = extractLinks(text);
  arrayLink?.forEach((link) => callback(link.index, link.lastIndex));
};

const decorator = new CompositeDecorator([
  {
    strategy: findMentions,
    component: MentionWithStyles,
  },
  {
    strategy: handleRegexStrategy,
    component: LinkyWithStyles,
  },
]);


class TextReader extends React.Component<Props, State> {
  linkifyPlugin: EditorPlugin;

  constructor(props: Props) {
    super(props);

    this.linkifyPlugin = createLinkifyPlugin();

    if (this.props.editorState) {
      this.state = {
        editorState: EditorState.createWithContent(
          convertFromRaw(this.props.editorState),
          decorator,
        ),
      };
      return;
    }
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  public render() {
    return (
      <div id={this.props.id} className={this.props.classes.readerWrapper}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          readOnly
          plugins={[this.linkifyPlugin]}
        />
      </div>
    );
  }

  private onChange = (): void => {
    return;
  };
}

export default withStyles(styles)(TextReader);
