import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import ActionButtons, { OwnProps as ButtonProps } from './ActionButtons';
import FieldNameInput from './FieldNameInput';
import styles, { ClassKey } from './styles';

interface OwnProps extends ButtonProps {
  fieldName?: string;
  errorMessage?: string;
  onFieldNameChange(newFieldName: string): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function RenameField(props: Props): JSX.Element {
  const {
    classes,
    fieldName,
    errorMessage,
  } = props;

  return (
    <div className={classes.wrapper}>
      <FieldNameInput
        fieldName={fieldName}
        errorMessage={errorMessage}
        onChange={props.onFieldNameChange}
      />
      <ActionButtons
        onCancel={props.onCancel}
        onSave={props.onSave}
      />
    </div>
  );
}

export default styles(RenameField);
