import { State } from 'reducers';
import { SheetNode } from 'types/response/sheetNode';
import LoadingState from 'data/LoadingState';

export const sheets = (state: State): SheetNode[] => state.sheets.ids.map(id => state.sheets.byId[id]);
export const sheetById = (state: State, id: string): SheetNode | undefined => state.sheets.byId[id];
export const sheetsLoadingState = (state: State): LoadingState => state.sheets.loadingState;
export const isEditing = (state: State): boolean => state.sheets.isEditing;
export const sheetTitles = (state: State): string[] => sheets(state).map(sheet => sheet.title);
export const isOnlySheet = (state: State): boolean => sheets(state).length === 1;
export const currentSheetId = (state: State): string | null => state.sheets.current || null;
export const currentSheetValidId = (state: State): string | null => state.sheets.currentId || null;
export const currentSheet = (state: State): SheetNode | undefined => currentSheetId(state) === 'default'
  ? defaultSheet(state)
  : sheetById(state, currentSheetId(state) || '');
export const defaultSheet = (state: State): SheetNode | undefined => sheets(state).filter(sheet => sheet.isDefault)[0];
