import './Button.style.scss';
import * as React from 'react';
import { bindActionCreators } from 'redux';

import Button, { Variant } from 'components/Button';

import { Actions } from 'data/assureSign/assureSign.actions';
import { openLockPermissionsModal } from 'data/modals/actions';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { connect } from 'react-redux';
import { isColumnLocked } from 'utilities/permissions';

import wrapCellRenderer from '../components/wrapCellRenderer';
import { buttonActions } from './buttonActionsRegistry';
import {
  ButtonRendererFieldProps,
  RendererDispatchProps,
  ButtonFieldActions,
} from './types';
import { WORKSPACE_STATUS_COL_ID, EL_STATUS_COL_ID, ORGANIZER_STATUS_COL_ID } from './constants';

/**
 * If it's in Master Database, check the current process status
 * @param props - button props
 * @returns
 */
const getMDStatus = ({ action, data }) => {
  let statusEnabled = false;

  if ( action === ButtonFieldActions.CREATE_WORKSPACE) {
    statusEnabled = data?.fields?.[WORKSPACE_STATUS_COL_ID] as boolean;
  } else if ( action === ButtonFieldActions.CREATE_ENGAGEMENT_LETTER) {
    statusEnabled = data?.fields?.[EL_STATUS_COL_ID] as boolean;
  } else if ( action === ButtonFieldActions.CREATE_TAX_ORGINIZER) {
    statusEnabled = data?.fields?.[ORGANIZER_STATUS_COL_ID] as boolean;
  }

  return statusEnabled;
};


/*
It creates a button that can be used to perform an action on a record.
*/
function ButtonRenderer(props: ButtonRendererFieldProps): JSX.Element {
  const { label, workspacePermissions, column, openLockPermissionsModal, action, data } = props;
  let canUpdateItems = workspacePermissions.includes('items:update');
  const showLockPermissionModal = !canUpdateItems || (!!column && isColumnLocked(column, workspacePermissions));
  const masterDataBaseStatus = getMDStatus(props);
  const dataFields = data?.fields;

  // STATUS FOR ENVELOPES
  // const ENVELOPE_CANCELLED_STATUS = 'ECA';
  // const ENVELOPE_EXPIRED_STATUS = 'EEX';
  const ENVELOPE_STARTED_STATUS = 'EST';
  const ENVELOPE_CLOSED_STATUS = 'ECL';

  // const DOCUMENT_CANCELLED_STATUS = 'DCA';
  // const DOCUMENT_EXPIRED_STATUS = 'DEX';
  const DOCUMENT_STARTED_STATUS = 'DST';

  if (action === 'resend_envelope' && typeof dataFields === 'object') {
    canUpdateItems = Object.values(dataFields).includes(ENVELOPE_STARTED_STATUS)
      || Object.values(dataFields).includes(DOCUMENT_STARTED_STATUS)
      || Object.values(dataFields).includes(ENVELOPE_CLOSED_STATUS);
  }

  if (action === 'cancel_envelope' && typeof dataFields === 'object') {
    canUpdateItems = Object.values(dataFields).includes(ENVELOPE_STARTED_STATUS)
      || Object.values(dataFields).includes(DOCUMENT_STARTED_STATUS)
      || Object.values(dataFields).includes(ENVELOPE_CLOSED_STATUS);
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (showLockPermissionModal) openLockPermissionsModal();
    else {
      if (buttonActions[props.action] instanceof Function) {
        buttonActions[props.action](props);
      } else {
        console.error('Missing action for the clicked button');
      }
    }
  };

  return (
    <span className='button-renderer--wrapper'>
      <Button
        className='button-renderer--wrapper--button'
        label={label}
        variant={Variant.Primary}
        onClick={handleButtonClick}
        disabled={!canUpdateItems || masterDataBaseStatus}
      />
    </span>
  );
}

const mapStateProps = (state) => ({
  workspacePermissions: getWorkspacePermissions(state),
});

const mapDispatchToProps: (dispatch) => RendererDispatchProps = (dispatch) => ({
  preCancelEnvelope: bindActionCreators(Actions.preCancelEnvelope, dispatch),
  preResendEnvelope: bindActionCreators(Actions.preResendEnvelope, dispatch),
  updateWorkspaces: fetchCollectionRequest,
  openLockPermissionsModal,
  dispatch,
});

export default wrapCellRenderer(connect(mapStateProps, mapDispatchToProps)(ButtonRenderer));
