import produce from 'immer';
import { Action } from 'redux';
import { getType } from 'typesafe-actions';
import loadingAction from './actions';


export interface UiLoadingState {
  loadings: { [id: string]: boolean };
  notLockingLoader: boolean;
  lockingLoader: boolean;
}

export const initialState: UiLoadingState = {
  loadings: {},
  notLockingLoader: false,
  lockingLoader: false,
};

interface ActionWithLoadingMeta extends Action {
  meta: {
    loading: {
      loading: boolean;
      id: string;
    };
    notLockingLoader: boolean;
    lockingLoader: boolean;
  };
}

const reducer = (state = initialState, action): UiLoadingState => {
  switch (action.type) {
    case getType(loadingAction.startLockingLoader): {
      return {
        ...state,
        lockingLoader: action.payload.lockingLoading,
      };
    }
    case getType(loadingAction.endLockingLoader): {
      return {
        ...state,
        lockingLoader: action.payload.lockingLoading,
      };
    }
    default: {
      if (!(action.meta && action.meta.loading)) {
        return state;
      }

      const { loading } = action.meta;

      if (loading) {
        return produce(state, draft => {
          if (loading.loading) {
            draft.loadings[loading.id] = true;
          } else {
            delete draft.loadings[loading.id];
          }
        });
      }
      return state;
    }
  }
};

export default reducer;
