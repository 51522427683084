import { CommonNode, FieldValue } from '../response';
import { DateFormat, TimeFormat } from 'components/Fields/data';

export interface FieldNode extends CommonNode {
  // API properties.
  default?: FieldValue; // Default value for the field.
  fieldGroup?: string; // Name of the field group.
  fieldType: FieldType; // Type of the field.
  order?: number; // Order the field should appear.
  readOnly?: boolean; // Whether values in this field are read-only.
  required?: boolean; // Whether the field is required.
  title: string; // Title of the node.
  uniqueValues?: boolean; // Whether rows require unique values for this field.

  // Grid properties.
  grid?: Grid; // Grid properties.

  // General validation properties.
  equalTo?: unknown; // Value that this field must be equal to.
  equalToField?: string; // ID of the field containing a value that this field must be equal to.
  greaterThanField?: string; // ID of the field containing a value that this field must be greater than.
  greaterThanOrEqualToField?: string; // ID of the field containing a value that this field must be greater than or equal to.
  length?: number; // Required length of the field.
  lessThanField?: string; // ID of the field containing a value that this field must be less than.
  lessThanOrEqualToField?: string; // ID of the field containing a value that this field must be less than or equal to.
  maxLength?: number; // Maximum length of the field's value.
  maximum?: unknown; // Value that this field must be less than, inclusive.
  minLength?: number; // Minimum length of the field's value.
  minimum?: unknown; // Value that this field  must be greater than, inclusive.
  notEqualTo?: unknown; // Value that this field  must not be equal to.
  notEqualToField?: string; // ID of the field containing a value that this field must not be equal to.
  notOneOf?: unknown; // List of invalid values.
  oneOf?: unknown; // Complete list of valid values.
  validationFunction?: string; // Validation function to pass before saving the field.

  // Account properties.
  allowMultiple?: boolean; // Whether to allow multiple entries.

  // Checklist properties.
  checklistNames?: Record<string, string>; // Names of checklist items (maps from ID keys to names).
  checklistOrder?: string[]; // Order of checklist keys for the checklist field.
  checklistValues?: Record<string, boolean>; // Values of checklist items (maps from ID keys to values).

  // Choice properties.
  choices?: Record<string, Choice>; // Choices for the lookup field.
  choiceOrder?: string[]; // Order of choice keys for the choice field.

  // Database lookup properties.
  referenceDatabaseURL?: string; // URL to the reference collection.
  referenceFieldID?: string; // Field ID of the reference field to display.
  referenceSheetID?: string; // Sheet ID of the reference field to filter within.

  // Date properties.
  dateFormat?: DateFormat; // Format mask for dates.
  includeTime?: boolean; // Whether to include times.
  timeFormat?: TimeFormat; // Format mask for times.
  timeZone?: number; // Fixed timezone to display.

  // Masked field properties.
  mask?: string; // Mask for displaying data for accounts that lack permission.

  // Formula properties.
  formula?: string; // Formula to execute.

  // Number properties.
  allowNegative?: boolean; // Whether negative numbers are allowed.
  currencySymbol?: string; // Currency symbol.
  precision?: number; // Digits of precision.

  // String properties.
  pattern?: string; // Pattern to match.

  // Taxonomy lookup properties.
  referenceTaxonomyURL?: string; // URL to the reference collection.

  isPrimary?: boolean;
}

export enum FieldType {
  Account = 'account',
  Attachment = 'documentreference',
  BLOBReference = 'blobreference',
  Barcode = 'barcode',
  Bookmarked = 'isbookmarked',
  Boolean = 'boolean',
  Button = 'button',
  Checklist = 'checklist',
  Computed = 'computed',
  Currency = 'currency',
  DataReference = 'datareference',
  Date = 'date',
  DateTime = 'datetime',
  Dropdown = 'dropdown',
  Duration = 'duration',
  Email = 'email',
  Favorite = 'favorite',
  FileExtension = 'fileextension',
  FileName = 'filename',
  FileSize = 'filesize',
  Float = 'float',
  Fraction = 'fraction',
  GUID = 'guid',
  ID = 'id',
  Integer = 'integer',
  Location = 'location',
  Lookup = 'lookup',
  Multilinetext = 'multilinetext',
  Multiplechoice = 'multiplechoice',
  Percent = 'percent',
  Phone = 'phone',
  Rating = 'rating',
  SearchReference = 'searchreference',
  Singlechoice = 'singlechoice',
  Singlelineoftext = 'singlelineoftext',
  Staticformula = 'staticformula',
  Status = 'status',
  Tag = 'tagreference',
  Time = 'time',
  Timespan = 'timespan',
  Url = 'url',
}

export interface Grid {
  hide?: boolean; // Whether to hide the column in the grid.
  iconClass?: string; // Class name of the icon to use for this field.
  lockPinned?: boolean; // Whether to lock pinned status in the grid.
  lockPosition?: boolean; // Whether to lock the position in the grid.
  lockVisible?: boolean; // Whether to lock visibility in the grid.
  maxWidth?: number; // Maximum width of the grid column.
  minWidth?: number; // Minimum width of the grid column.
  pinned?: string; // 'left' or 'right' to pin the column to one side.
  suppressFilter?: boolean; // Whether to suppress filtering this field.
  suppressFilterBar?: boolean; // Whether to suppress bar filtering for this field.
  suppressFilterDropdown?: boolean; // Whether to suppress dropdown filtering for this field.
  suppressMenu?: boolean; // Whether to suppress the context menu for this field.
  suppressMovable?: boolean; // Whether to suppress moving / reordering this field.
  suppressResize?: boolean; // Whether to suppress resizing this field.
  suppressSizeToFit?: boolean; // Whether to suppress sorting for this field.
  suppressSorting?: boolean; // Whether to suppress sorting for this field.
  suppressToolPanel?: boolean; // Whether to suppress this field in the tool panel.
}

export interface Choice {
  label: string; // Label for choice
  color: string; // Color for choice
}
