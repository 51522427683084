import produce from 'immer';
import { arrayMove } from 'react-sortable-hoc';

import * as fromActions from './sortModel.actions';
import { SortModelState, SortOperator } from '../../types/gridOptions';
import { groupById } from 'utilities/collections';
import * as constants from 'data/collections/constants';
import * as fromCollectionActions from 'data/collections/collections.actions';
import * as fromViewConfigActions from 'data/collections/view-config/viewConfig.actions';
import { DataGrid } from '../../components/DataGrid';
import { ROW_NUMBER_ID } from '../../components/DataGrid/columns/constants';

export interface ChangeSortColumnPayload {
  oldColumnKey: string;
  newColumnId: string;
}

export interface ChangeSortOperatorPayload {
  columnToChangeKey: string;
  newOperator: SortOperator;
}

const initialState = {
  byId: {},
  allIds: [],
};

type Actions = fromActions.Actions | fromCollectionActions.ActionsType | fromViewConfigActions.ActionsType;

const reducer = (state: SortModelState = initialState, action: Actions): SortModelState => {
  switch (action.type) {
    case fromActions.SET_SORT_MODEL: {
      const { payload } = action;
      return produce(state, draft => {
        if (payload.length) {
          const { ids, byId } = groupById(payload, 'colId');
          draft.byId = byId;
          draft.allIds = ids;
        } else {
          draft.byId = {};
          draft.allIds = [];
        }

        setTimeout(() => {
          DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
        }, 100);
      });
    }
    case fromViewConfigActions.APPLY_VIEW_CONFIG: {
      const { sorts } = action.payload;
      return produce(state, draft => {
        if (sorts && sorts.length) {
          const { ids, byId } = groupById(sorts, 'colId');
          draft.byId = byId;
          draft.allIds = ids;
        } else {
          draft.byId = {};
          draft.allIds = [];
        }

        setTimeout(() => {
          DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
        }, 100);
      });
    }
    case constants.CLEAN_COLLECTION:
      return produce(state, draft => {
        draft.byId = {};
        draft.allIds = [];
      });
    case fromActions.ADD_SORT: {
      const newColumnKey = action.payload.colId;
      return produce(state, draft => {
        draft.byId[newColumnKey] = {
          ...action.payload,
          colId: newColumnKey,
        };
        // @ts-ignore
        draft.allIds.push(newColumnKey);

        setTimeout(() => {
          DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
        }, 100);
      });
    }
    case fromActions.DELETE_SORT: {
      return produce(state, draft => {
        delete draft.byId[action.payload];
        draft.allIds = draft.allIds.filter(id => id !== action.payload);

        setTimeout(() => {
          DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
        }, 100);
      });
    }
    case fromActions.CHANGE_SORT_COLUMN: {
      const { oldColumnKey, newColumnId } = action.payload;

      return produce(state, draft => {
        draft.byId[newColumnId] = {
          colId: newColumnId,
          sort: draft.byId[oldColumnKey].sort,
        };
        delete draft.byId[oldColumnKey];
        const idx = draft.allIds.findIndex(id => id === oldColumnKey);
        // @ts-ignore
        draft.allIds.splice(idx, 1, newColumnId);
      });
    }
    case fromActions.CHANGE_SORT_OPERATOR: {
      const { columnToChangeKey, newOperator } = action.payload;
      return produce(state, draft => {
        draft.byId[columnToChangeKey].sort = newOperator;
      });
    }
    case fromActions.CHANGE_SORT_ORDER: {
      const { oldIndex, newIndex } = action.payload;
      return produce(state, draft => {
        draft.allIds = arrayMove(draft.allIds, oldIndex, newIndex);
      });
    }

    case fromActions.RESET_SORT_MODEL:
      setTimeout(() => {
        DataGrid.getGridApi()?.refreshCells([ROW_NUMBER_ID]);
      }, 100);

      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
