import { FieldType } from 'types/response/fieldNode';

import AccountRenderer from './account/AccountRenderer';
import AddRowRenderer from './addRow/Renderer';
import AttachmentRenderer from './attachment/AttachmentRenderer';
import BarcodeRenderer from './barcode/Renderer';
import BooleanRenderer from './boolean/BooleanRenderer';
import ButtonRenderer from './button/ButtonRenderer';
import ChecklistRenderer from './checklist/ChecklistRenderer';
import ChoiceRenderer from './choice/ChoiceRenderer';
import { withValidation } from './components/withValidation';
import DateTimeRenderer from './datetime/DateTimeRenderer';
import DropdownRenderer from './dropdown/DropdownRenderer';
import FileExtensionRenderer from './fileextension/Renderer';
import FilenameRenderer from './filename/Renderer';
import FileSizeRenderer from './filesize/Renderer';
import IdRenderer from './id/IndexRenderer';
import IsBookmarkedRenderer from './isBookmarked/IsBookmarkedRenderer';
import MultiLineOfTextRenderer from './multilinetext/MultiLineOfTextRenderer';
import NumberRenderer from './number/NumberRenderer';
import NumberFormattedRenderer from './number/NumberRenderer';
import RatingRenderer from './rating/RatingRenderer';
import SingleLineOfTextRenderer from './singlelineoftext/SingleLineOfTextRenderer';
import TagsRenderer from './tags/Renderer';
import UrlRenderer from './url/UrlRenderer';
import StatusRenderer from './status/StatusRenderer';

export const renderers = {
  AccountRenderer,
  AttachmentRenderer,
  BooleanRenderer,
  ChecklistRenderer,
  ChoiceRenderer,
  DateTimeRenderer,
  NumberRenderer,
  SingleLineOfTextRenderer,
  MultiLineOfTextRenderer,
  TagsRenderer,
  FileExtensionRenderer,
  FilenameRenderer,
  FileSizeRenderer,
  IsBookmarkedRenderer,
  IdRenderer,
  BarcodeRenderer,
  RatingRenderer,
  UrlRenderer,
  AddRowRenderer,
  DropdownRenderer,
  ButtonRenderer,
  NumberFormattedRenderer,
  StatusRenderer,
};

Object.keys(renderers).forEach((key) => {
  renderers[key] = withValidation(renderers[key]);
});

export function getRendererByFieldType(type: FieldType, fallback = renderers.SingleLineOfTextRenderer) {
  switch (type) {
    case FieldType.Account: {
      return renderers.AccountRenderer;
    }

    case FieldType.Multiplechoice:
    case FieldType.Singlechoice: {
      return renderers.ChoiceRenderer;
    }

    case FieldType.Dropdown: {
      return renderers.DropdownRenderer;
    }

    case FieldType.Checklist: {
      return renderers.ChecklistRenderer;
    }

    case FieldType.Date:
    case FieldType.DateTime: {
      return renderers.DateTimeRenderer;
    }

    case FieldType.Percent: {
      return renderers.NumberRenderer;
    }

    case FieldType.Integer:
    case FieldType.Float:
    case FieldType.Currency: {
      return renderers.NumberFormattedRenderer;
    }

    case FieldType.Rating: {
      return renderers.RatingRenderer;
    }

    case FieldType.Multilinetext: {
      return renderers.MultiLineOfTextRenderer;
    }

    case FieldType.Singlelineoftext:
    case FieldType.Staticformula:
    case FieldType.Email:
    case FieldType.Phone: {
      return renderers.SingleLineOfTextRenderer;
    }

    case FieldType.Url: {
      return renderers.UrlRenderer;
    }

    case FieldType.Tag: {
      return renderers.TagsRenderer;
    }

    case FieldType.Boolean: {
      return renderers.BooleanRenderer;
    }

    case FieldType.FileExtension: {
      return renderers.FileExtensionRenderer;
    }

    case FieldType.FileName: {
      return renderers.FilenameRenderer;
    }

    case FieldType.FileSize: {
      return renderers.FileSizeRenderer;
    }

    case FieldType.Attachment: {
      return renderers.AttachmentRenderer;
    }

    case FieldType.Barcode: {
      return renderers.BarcodeRenderer;
    }

    case FieldType.Bookmarked:
      return renderers.IsBookmarkedRenderer;

    case FieldType.Button:
      return renderers.ButtonRenderer;

    case FieldType.Status:
      return renderers.StatusRenderer;

    // post MVP:
    case FieldType.Timespan:
    case FieldType.Computed:
    case FieldType.Lookup:
    case FieldType.SearchReference:
    case FieldType.Location: {
      return fallback;
    }

    default: {
      console.warn(`Column type ${type} doesn't have renderer`);
      return fallback;
    }
  }
}
