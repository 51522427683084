import { createAsyncAction } from 'typesafe-actions';

export const batchJobs = createAsyncAction(
  'batchJobs/BATCH_START',
  'batchJobs/BATCH_SUCCESS',
  'batchJobs/BATCH_FAILURE',
)<{ uri: string; retryAfterSeconds: number}, any, any>();

export const batchJobCheck = createAsyncAction(
  'batchJobs/CHECK_REQUEST',
  'batchJobs/CHECK_SUCCESS',
  'batchJobs/CHECK_FAILURE',
)<any, any, any>();
