import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SendIcon from '@material-ui/icons/Send';
import AccountChip from 'components/AccountChip/AccountChip';
import IconButton, { Color, Size } from 'components/IconButton';
import * as types from 'data/messages/types';
import {
  EditorState,
  RichUtils,
  DraftHandleValue,
  DraftEditorCommand,
  DraftBlockType,
  DraftInlineStyleType,
  getDefaultKeyBinding,
  ContentState,
  Modifier,
} from 'draft-js';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  PluginEditor,
  MentionPlugin,
} from 'draft-js-mention-plugin';
import Editor, { EditorPlugin } from 'draft-js-plugins-editor';

import BlockStyleControls from './BlockStyleControls';
import { styles } from './Editor.style';
import InlineStyleControls from './InlineStyleControls';


import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';

const styleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

const SUGGESTION_WIDTH = 220;

interface OwnProps {
  id?: string;
  version?: number;
  placeholder?: string;
  resetOnEnter?: boolean;
  editorState?: EditorState | null;
  suggestions?: types.Suggestions[];
  suggestionPositionBottom?: boolean;
  onChange?: (editorState: EditorState) => void;
  onReset?: (editorState: EditorState) => void;
  editorRef?: React.RefObject<HTMLDivElement>;
  showSendButton?: boolean;
  disableSendButton?: boolean;
  readOnly?: boolean;
}

interface MentionStyles {
  display: string;
  transformOrigin: string;
  transform: string;
  transition: string;
  top: string;
  left: string;
  zIndex: number;
}

interface PluginProps {
  mentionPrefix: string;
  positionSuggestions?: (object: any) => MentionStyles | void;
}

interface State {
  editorState: EditorState;
  suggestions: types.Suggestions[];
  hidePlacehoder: boolean;
  isFocused: boolean;
}

type Props = OwnProps & WithStyles<typeof styles>;

class TextEditor extends React.Component<Props, State> {
  private mentionPlugin: MentionPlugin;
  private linkifyPlugin: EditorPlugin;
  private editorRef: React.RefObject<PluginEditor> = React.createRef();
  private editorWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.mentionPlugin = createMentionPlugin(this.getPluginProps());
    this.linkifyPlugin = createLinkifyPlugin();
    if (this.props.editorState) {
      this.state = {
        isFocused: true,
        suggestions: [],
        editorState: EditorState.moveFocusToEnd(this.props.editorState),
        hidePlacehoder: false,
      };
      return;
    }

    this.state = {
      isFocused: false,
      suggestions: [],
      editorState: EditorState.createEmpty(),
      hidePlacehoder: false,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.version !== this.props.version) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(''),
        'undo',
      );
      this.setState({ editorState });
    }
  }

  public componentDidMount() {
    setTimeout(() => {
      const state = EditorState.moveFocusToEnd(this.state.editorState);
      this.setState({ editorState: state });
    }, 0);
  }

  public render(): JSX.Element {
    const {
      id,
      classes,
      placeholder,
      editorRef,
      showSendButton,
      disableSendButton,
      readOnly,
    } = this.props;

    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, this.linkifyPlugin];

    const editorProps = {
      onChange: this.onChange,
      customStyleMap: styleMap,
      placeholder: placeholder,
      editorState: this.state.editorState,
      handleKeyCommand: this.handleKeyCommand,
      plugins: plugins,
      className: this.state.isFocused ? classes.editorFocused : '',
      readOnly,
    };

    if (!this.mentionPlugin.getAccessibilityProps().ariaExpanded) {
      editorProps['keyBindingFn'] = this.onKeyboard;
    }

    let editorWrapperClassnames = classes.editorWrapper;
    if (this.state.hidePlacehoder) {
      editorWrapperClassnames += ` ${classes.hidePlaceholder}`;
    }

    if (this.state.isFocused) {
      editorWrapperClassnames += ` ${classes.editorFocused}`;
    }

    return (
      <div id={id}>
        <div className={classes.buttonWrapper}>
          <InlineStyleControls
            id={`${id}_inlineStyle`}
            editorState={this.state.editorState}
            onToggle={this.toggleInlineStyle}
          />
          <BlockStyleControls
            id={`${id}_blockStyle`}
            editorState={this.state.editorState}
            onToggle={this.toggleBlockType}
          />
          <span className={classes.buttonSeparator}></span>
          <span
            id={`${id}_addMention`}
            className={classes.mentionSign}
            onClick={this.addMention}
          >
            <AlternateEmailIcon />
          </span>
        </div>
        <div className={classes.relativeWrapper}>
          <div className={editorWrapperClassnames} ref={this.editorWrapperRef}>
            <Editor
              {...editorProps}
              ref={editorRef || this.editorRef}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
            {showSendButton && (
              <IconButton
                id={`${id}_btnSendMessage`}
                icon={SendIcon}
                color={Color.DarkGray}
                size={Size.Large}
                disabled={
                  this.state.editorState
                    .getCurrentContent()
                    .getPlainText()
                    .trim() === '' || disableSendButton || readOnly
                }
                className={classes.sendButton}
                onClick={this.resetEditor}
              />
            )}
          </div>
          <MentionSuggestions
            id={`${id}_mention`}
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            entryComponent={EntryWithStyles}
          />
        </div>
      </div>
    );
  }

  private onFocus = (): void => {
    this.setState({ isFocused: true });
  };

  private onBlur = (): void => {
    this.setState({ isFocused: false });
  };

  private getRef = (): React.RefObject<PluginEditor> => {
    if (this.props.editorRef) {
      return this.props.editorRef;
    }
    return this.editorRef;
  };

  private addMention = (): void => {
    if (this.props.readOnly) return;

    try {
      const selection = this.state.editorState.getSelection();
      const contentState = this.state.editorState.getCurrentContent();
      const insertedText = Modifier.insertText(contentState, selection, '@');
      const updatedState = EditorState.push(
        this.state.editorState,
        insertedText,
        'insert-fragment',
      );
      this.onChange(updatedState);
      if (this.getRef().current) {
        setTimeout(() => {
          this.getRef().current.focus();
        }, 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  private onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.suggestions),
    });
  };

  private onKeyboard = (
    event: React.KeyboardEvent,
  ): DraftEditorCommand | null => {
    if (
      (!event.shiftKey && event.key === 'Enter' && this.props.resetOnEnter) ||
      (event.ctrlKey && event.key === 'Enter')
    ) {
      this.resetEditor();
      event.preventDefault();
      return null;
    }
    return getDefaultKeyBinding(event);
  };

  private resetEditor = (): void => {
    if (typeof this.props.onReset === 'function') {
      this.props.onReset(this.state.editorState);
    }

    const editorState = EditorState.push(
      this.state.editorState,
      ContentState.createFromText(''),
      'undo',
    );
    this.setState(
      {
        editorState,
        suggestions: [],
      },
      () => {
        this.getRef().current.focus();
      },
    );
  };

  private onChange = (editorState: EditorState): void => {
    if (this.state.editorState.getCurrentContent().getPlainText()) {
      this.setState({ editorState });
    } else if (editorState.getCurrentContent().getPlainText()) {
      const state = EditorState.moveFocusToEnd(editorState);
      this.setState({ editorState: state });
    } else {
      this.setState({ editorState });
    }

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(editorState);
    }

    this.handlePlaceholder(editorState);
  };

  private handleKeyCommand = (
    command: DraftEditorCommand,
  ): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command,
    );
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  private toggleBlockType = (blockType: DraftBlockType) => {
    if (this.props.readOnly) return;

    const editorState = RichUtils.toggleBlockType(
      this.state.editorState,
      blockType,
    );
    this.onChange(editorState);
  };

  private handlePlaceholder = (editorState: EditorState): void => {
    const contentState = editorState.getCurrentContent();
    const hasContent =
      contentState.hasText() ||
      contentState.getBlockMap().first().getType() !== 'unstyled';
    this.setState({ hidePlacehoder: hasContent });
  };

  private toggleInlineStyle = (inlineStyle: DraftInlineStyleType) => {
    if (this.props.readOnly) return;

    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    );
  };

  private getPluginProps = (): PluginProps => {
    return {
      mentionPrefix: '@',
      positionSuggestions: ({ decoratorRect }): MentionStyles | void => {
        let editorRect: DOMRect | ClientRect | undefined;
        if (this.editorWrapperRef && this.editorWrapperRef.current) {
          editorRect = this.editorWrapperRef.current.getBoundingClientRect();
        }
        if (!editorRect) return;

        let left = decoratorRect.left - editorRect.left;
        if (
          decoratorRect.left + SUGGESTION_WIDTH - 10 >
          editorRect.left + editorRect.width
        ) {
          left = editorRect.width - SUGGESTION_WIDTH;
        }
        const translateY =
          this.props.suggestionPositionBottom !== undefined &&
          this.props.suggestionPositionBottom === true
            ? '25px'
            : '-100%';
        return {
          display: 'block',
          transformOrigin: '1em 0% 0px',
          transform: `scale(1) translateY(${translateY})`,
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
          top: decoratorRect.top - editorRect.top - 10 + 'px',
          left: left + 'px',
          zIndex: 1000,
        };
      },
    };
  };
}

const Entry = (props) => {
  const {
    mention,
    theme,
    classes,
    isFocused,
    searchValue, // eslint-disable-line no-unused-vars
    ...otherProps
  } = props;

  let mentionRowClass = classes.mentionRow;
  if (isFocused) {
    mentionRowClass += ` ${classes.mentionRowFocused}`;
  }

  return (
    <div {...otherProps} className={mentionRowClass}>
      {mention.id !== 'team' ? (
        <React.Fragment>
          <div>
            <AccountChip
              accountId={mention.id}
              initials={true}
              showName={false}
            />
          </div>
          <div className={classes.mentionName}>{mention.name}</div>
        </React.Fragment>
      ) : (
        <div className={classes.teamMentionName}>@{mention.name}</div>
      )}
    </div>
  );
};

const EntryWithStyles = withStyles(styles)(Entry);

export default withStyles(styles)(TextEditor);
