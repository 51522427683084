import { createMuiTheme, Theme } from '@material-ui/core';
import * as Colors from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

import { TenantNode, Theme as TenantTheme } from '../types/response/tenantNode';

// https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    tenantTheme?: TenantTheme;
  }

  interface ThemeOptions {
    tenantTheme?: TenantTheme;
  }
}

export default interface ThemeInterface {
  primaryColor: string;
  primaryColorInverted: string;
}

export function muiTheme(tenantTheme?: TenantTheme): Theme {
  return createMuiTheme({
    palette: {
      primary: {
        main: Colors.brightBlue,
      },
      secondary: {
        main: Colors.greenBaker,
      },
      text: {
        primary: Colors.slate,
      },
    },
    overrides: {
      MuiRadio: {
        root: {
          color: Colors.greenBaker,
        },
        colorSecondary: {
          '&$checked': {
            color: Colors.greenBaker,
          },
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '12px',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          color: Colors.slateTwo,
          fontWeight: 500,
          padding: '8px 10px',
          lineHeight: '1em',
          minHeight: 30,
          borderRadius: 2,
        },
        outlined: {
          padding: '7px 10px',
          borderColor: Colors.lightBlueGrey,
          borderRadius: 4,
        },
        contained: {
          boxShadow: 'none',
        },
        containedPrimary: {
          color: 'var(--buttonTextColor, white)',
        },
        sizeSmall: {
          padding: '6px 11px',
          fontSize: 13,
          minWidth: 'auto',
          minHeight: 26,
        },

      },
      MuiSelect: {
        selectMenu: {
          paddingRight: 20,
          fontWeight: 500,
        },
        icon: {
          fontSize: 18,
          top: 'calc(50% - 9px)',
        },
      },
      MuiFormLabel: {
        root: {
          'textTransform': 'uppercase',
          'color': '#99a5aa',
          'fontSize': 13,
          'fontWeight': 500,
          'marginBottom': 5,
          'display': 'flex',
          'alignItems': 'center',
          '&$focused': {
            color: '#99a5aa',
          },
        },
      },
      MuiTab: {
        root: {
          'textTransform': 'none',
          'minHeight': 50,
          'fontWeight': 'normal',
          'minWidth': 'auto',
          '@media (min-width: 960px)': {
            minWidth: 'auto',
          },
          '&$selected $labelContainer': {
            color: Colors.brightBlue,
          },
        },
        labelContainer: {
          'fontSize': 13,
          'padding': '0 10px',
          'color': Colors.darkSlateBlue,
          '@media (min-width: 960px)': {
            padding: '0 10px',
          },
        },
      },
      MuiTabs: {
        root: {
          minHeight: 50,
        },
      },
      MuiInput: {
        root: {
          'fontSize': 13,
          'borderRadius': 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: 2,
            },
          },
        },
        input: {
          'color': Colors.slateTwo,
          '&::placeholder': {
            color: Colors.lightBlueGrey,
            opacity: 1,
          },
          'borderRadius': 2,
        },
        underline: {
          '&:before': {
            borderBottom: `1px solid ${Colors.slate}`,
          },
          '&$disabled:before': {
            borderBottom: `1px solid ${Colors.slate}`,
          },
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: `rgba(55, 71, 79, 0.2)`,
        },
      },
      MuiChip: {
        root: {
          height: 24,
          color: Colors.charocalGrey,
        },
        label: {
          paddingLeft: 12,
          paddingRight: 12,
        },
        avatar: {
          width: 24,
          height: 24,
          fontSize: 'inherit',
          background: 'inherit',
          color: 'inherit',
          filter: 'brightness(0.9)',
        },
      },
      MuiMenuItem: {
        root: {
          'fontSize': 13,
          'padding': '2px',
          'paddingLeft': '12px', // Left and right can't be specified above since they get overridden by MUI defaults
          'paddingRight': '50px',
          'fontWeight': 'normal',
          'color': Colors.darkSlateBlue,
          '&$selected': {
            backgroundColor: rgba(Colors.hubsyncBlue, 0.04),
          },
          '&:hover': {
            backgroundColor: rgba(Colors.hubsyncBlue, 0.04),
          },
        },
      },
      MuiDivider: {
        root: {
          marginTop: '8px',
          marginBottom: '8px',
        },
      },
      MuiListSubheader: {
        root: {
          'fontSize': 11,
          'lineHeight': '18px',
          'paddingLeft': '16px',
          'paddingRight': '16px',
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
        sticky: {
          position: 'unset',
        },
      },
      MuiListItem: {
        root: {
          'paddingTop': '4px',
          'paddingBottom': '4px',
          '&:hover': {
            backgroundColor: rgba(Colors.hubsyncBlue, 0.04),
          },
        },
        gutters: {
          'paddingLeft': '16px',
          'paddingRight': '16px',
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
      MuiListItemIcon: {
        root: {
          marginRight: 8,
          fontSize: 16,
          color: Colors.darkSlateBlue,
        },
      },
      MuiAvatar: {
        root: {
          width: 30,
          height: 30,
          fontSize: 12,
          fontWeight: 'normal',
        },
      },
      MuiDialog: {
        paper: {
          minWidth: 500,
          borderRadius: 2,
        },
      },
      MuiDialogTitle: {
        root: {
          borderBottom: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: Colors.iceBlue,
        },
      },
      MuiCheckbox: {
        root: {
          color: '#ced8dc',
        },
      },
      MuiCardHeader: {
        root: {
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
      MuiCardContent: {
        root: {
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      fontSize: 13,
    },
    tenantTheme,
  });
}

export function applyTheme(tenant?: TenantNode) {
  if (!tenant) {
    return;
  }

  const { title, theme } = tenant;

  if (title) {
    document.title = title;
  }

  if (theme) {
    for (const prop in theme) {
      if (prop !== 'css' && !prop.includes('URL')) {
        document.documentElement.style.setProperty(`--${prop}`, theme[prop]);
      }
    }
    if (theme.css) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(theme.css));
      document.getElementsByTagName('head')[0].appendChild(style);
    }
    const tenantAPIURI = tenant.apiURI;
    if (tenantAPIURI) {
      if (theme.faviconURL) {
        const favIconLink = document.createElement('link');
        favIconLink.type = 'image/x-icon';
        favIconLink.rel = 'shortcut icon';
        favIconLink.href = injectJWT(tenantAPIURI, theme.faviconURL);
        document.getElementsByTagName('head')[0].appendChild(favIconLink);
      }
      if (theme.logoURL) {
        const logo = document.getElementById('logo') as HTMLImageElement;
        if (logo) {
          logo.src = injectJWT(tenantAPIURI, theme.logoURL);
        }
      }
    }
  }
}

function injectJWT(tenantAPIURI: string, link: string): string {
  const token = localStorage.getItem('id_token');
  const xsrf = localStorage.getItem('xsrf_state');
  if (link.startsWith(tenantAPIURI + '/') && !link.includes('jwt=')) {
    if (link.includes('?')) {
      return `${link}&blob&jwt=${token}&state=${xsrf}`;
    }

    return `${link}?blob&jwt=${token}&state=${xsrf}`;
  }

  return link;
}
