import { createEngagementLetterProcess } from 'data/workspaceCreate/actions';

/**
 *
 * @param props
 * @returns
 */
export const createEngagementLetterMasterDataBase = async (props: any) => {
  const { dispatch, data, isGrid } = props;
  if (isGrid) {
    dispatch(createEngagementLetterProcess({ ids: [data.id] }));
  }

  return {};
};

