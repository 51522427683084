import { SummaryTypes } from '../types';
import none from './none';
import empty from './empty';
import filled from './filled';
import unique from './unique';
import percentEmpty from './percentEmpty';
import percentFilled from './percentFilled';
import percentUnique from './percentUnique';
import sum from './sum';
import average from './average';
import median from './median';
import min from './min';
import max from './max';
import range from './range';
import totalAttachmentSize from './totalAttachmentSize';
import unchecked from './unchecked';
import checked from './checked';
import percentUnchecked from './percentUnchecked';
import percentChecked from './percentChecked';
import earliestDate from './earliestDate';
import latestDate from './latestDate';
import dateRangeDays from './dateRangeDays';
import dateRangeMonths from './dateRangeMonths';

const summarizers = {
  [SummaryTypes.none]: none,
  [SummaryTypes.empty]: empty,
  [SummaryTypes.filled]: filled,
  [SummaryTypes.unique]: unique,
  [SummaryTypes.percentEmpty]: percentEmpty,
  [SummaryTypes.percentFilled]: percentFilled,
  [SummaryTypes.percentUnique]: percentUnique,
  [SummaryTypes.sum]: sum,
  [SummaryTypes.average]: average,
  [SummaryTypes.median]: median,
  [SummaryTypes.min]: min,
  [SummaryTypes.max]: max,
  [SummaryTypes.range]: range,
  [SummaryTypes.totalAttachmentSize]: totalAttachmentSize,
  [SummaryTypes.unchecked]: unchecked,
  [SummaryTypes.checked]: checked,
  [SummaryTypes.percentUnchecked]: percentUnchecked,
  [SummaryTypes.percentChecked]: percentChecked,
  [SummaryTypes.earliestDate]: earliestDate,
  [SummaryTypes.latestDate]: latestDate,
  [SummaryTypes.dateRangeDays]: dateRangeDays,
  [SummaryTypes.dateRangeMonths]: dateRangeMonths,
};
export { summarizers };
