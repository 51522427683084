import styled from 'styled-components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { charocalGrey, white } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

export type ClassKey = 'wrapper' | 'label';

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    display: 'flex',
    flex: 1,
    minWidth: '200px',
    maxWidth: '250px',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid rgba(153,153,153,0.38)',
    borderRadius: '2px',
    backgroundColor: white,
    padding: '4px 20px',
  },
  label: {
    opacity: '0.8',
    color: charocalGrey,
    fontSize: '13px',
    letterSpacing: '0.23px',
    lineHeight: '22px',
  },
};

export const DropdownIcon = styled(svgIcons.Dropdown)`
  color: ${charocalGrey};
  height: 14px;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: -15px;
  order: 2;
`;
