import React, { useEffect, useState } from 'react';
import Button, { Variant as ButtonVariant } from 'components/Button';
import Logo from '../../assets/images/auth/hubsync-logo-white.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFAGeneralStyles.index.style';
import PhoneInput from 'react-phone-input-2';

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
});

const PhoneSelector = (props) => {
  const { classes, onConfirmClicked, backToMFA, isLoadingChallenge } = props;

  const [selected, setSelected] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [invalidNumber, setInvalidNumber] = useState(true);

  useEffect(() => {
    setIsLoading(isLoadingChallenge);
  }, [isLoadingChallenge]);

  const tenant = window.appStore.getState().app.tenant;

  const selectedMethod = () => {
    onConfirmClicked(selected);
  };

  const goBackToMFA = () => {
    backToMFA();
  };

  const isInvalid = (formattedValue: string): boolean => {
    const splittedNumber = formattedValue.split(' ');
    const phoneWithoutCountryCode = splittedNumber[1];
    if (phoneWithoutCountryCode) {
      const onlyNumber = phoneWithoutCountryCode.replace(/-/g, '');
      return (onlyNumber && onlyNumber.length < 10) as boolean;
    } else {
      return true;
    }
  };

  const handlePhoneChange = (value: string, _country, _e, formattedValue) => {
    setSelected(value);
    setInvalidNumber(isInvalid(formattedValue));
  };

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={'auth-container'+ (tenantConfig.clientName || '')}>
        <div className="auth-form-container">
          <div className="auth-logo">
            <img src={tenantConfig.logoURL ?? Logo} alt={'Hubsync'} />
          </div>
          <div className={classes.mfaFormContainer}>
            <div className={classes.topContentPhone}>
              <h3>Phone Verification</h3>
              <p className={classes.helperText}>
                Enter your phone number below. A text will be sent to that
                number with a code to enter on the next screen.
              </p>
            </div>
            <div>
              <div className={classes.textField}>
                <div className={classes.radioButtonContainer}>
                  <label className={classes.textLabelPhone}>Phone Number</label>

                  <PhoneInput
                    placeholder="Enter your phone number"
                    country={'us'}
                    copyNumbersOnly={true}
                    value={selected}
                    onChange={(value, _country, _e, formattedValue) => handlePhoneChange(value, _country, _e, formattedValue)}
                    autoFormat={true}
                    countryCodeEditable={false}
                    enableSearch={true}
                    disableSearchIcon={true}
                    searchPlaceholder={'Search country...'}
                    defaultMask={'...-...-....'}
                    alwaysDefaultMask={true}
                    inputClass={'phoneInput'}
                  />
                </div>
                <div className={classes.textContainerCodePhone}>
                  <div></div>{' '}
                  <a
                    href={tenant.apiEndpoints.zendeskTargetURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.textSpanRight}
                  >
                    <span>Need Help?</span>
                  </a>
                </div>
              </div>
              <div>
                <Button
                  id="verify"
                  className={ tenantConfig.clientName ? classes.verifyActionButtonbakertilly : classes.verifyActionButton }
                  label="Verify"
                  isLoading={isLoading}
                  variant={tenantConfig.clientName ? ButtonVariant.Secondary : ButtonVariant.Primary}
                  onClick={() => {
                    setIsLoading(true);
                    selectedMethod();
                  }}
                  disabled={invalidNumber}
                />
              </div>

              <div className={classes.textSMSContainer}>
                <span className={classes.textNormalLight}>
                  By continuing you may receive an SMS. Message and data rates
                  may apply.
                </span>
              </div>
            </div>
          </div>
          <Button
            id="needHelp"
            className={classes.backToMFAButton}
            label="Change MFA Method Selection"
            variant={ButtonVariant.PrimaryLink}
            onClick={() => {
              goBackToMFA();
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(PhoneSelector));
