import * as _ from 'lodash/fp';

import { ViewNode } from '../../types/response/viewNode';

export const VIEWS_NOT_ASKED = 'VIEWS_NOT_ASKED';
export const UNORDERED_VIEWS = 'UNORDERED_VIEWS';
export const ONLY_ORDER = 'ONLY_ORDER';
export const ORDERED_VIEWS = 'ORDERED_VIEWS';

interface ViewsNotAsked {
  type: 'VIEWS_NOT_ASKED';
}

interface UnorderedViews {
  type: 'UNORDERED_VIEWS';
  views: ViewNode[];
}

interface OnlyOrder {
  type: 'ONLY_ORDER';
  viewIDOrder: string[];
}

interface OrderedViews {
  type: 'ORDERED_VIEWS';
  viewIDOrder: string[];
  views: ViewNode[];
}

export type State
  = ViewsNotAsked
  | UnorderedViews
  | OnlyOrder
  | OrderedViews
  ;

export function defaultViews(): ViewsNotAsked {
  return { type: VIEWS_NOT_ASKED };
}

export function unorderedViews(views: ViewNode[]): UnorderedViews {
  return { type: UNORDERED_VIEWS, views };
}

function onlyOrder(viewIDOrder: string[]): OnlyOrder {
  return { type: ONLY_ORDER, viewIDOrder };
}

export function orderedViews(viewIDOrder: string[], unorderedViews: ViewNode[]): OrderedViews {
  const views = _.sortBy((view) => {
    const index = viewIDOrder.indexOf(view.id);

    if (index === -1) {
      return Number.POSITIVE_INFINITY;
    }

    return index;
  }, unorderedViews);

  return { type: ORDERED_VIEWS, viewIDOrder, views };
}

export function updateView(view: ViewNode, state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case ONLY_ORDER: {
      return state;
    }

    case UNORDERED_VIEWS:
    case ORDERED_VIEWS: {
      return { ...state, views: replaceView(view, state.views) };
    }
  }
}

export function setDefaultView(viewId: string, state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case ONLY_ORDER: {
      return state;
    }

    case UNORDERED_VIEWS:
    case ORDERED_VIEWS: {
      return { ...state, views: updateDefaultView(viewId, state.views) };
    }
  }
}

export function removeView(viewId: string, state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case ONLY_ORDER: {
      return state;
    }

    case UNORDERED_VIEWS:
    case ORDERED_VIEWS: {
      return { ...state, views: state.views.filter((v) => v.id !== viewId) };
    }
  }
}

export function addOrder(viewIDOrder: string[], state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case ONLY_ORDER: {
      return onlyOrder(viewIDOrder);
    }

    case UNORDERED_VIEWS:
    case ORDERED_VIEWS: {
      return orderedViews(viewIDOrder, state.views);
    }
  }
}

export function removeOrder(state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case UNORDERED_VIEWS:
      return state;

    case ONLY_ORDER:
      return defaultViews();

    case ORDERED_VIEWS:
      return unorderedViews(state.views);
  }
}

export function addViews(views: ViewNode[], state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case UNORDERED_VIEWS: {
      return unorderedViews(views);
    }

    case ONLY_ORDER:
    case ORDERED_VIEWS:
      return orderedViews(state.viewIDOrder, views);
  }
}

export function addNewView(view: ViewNode, state: State): State {
  switch (state.type) {
    case VIEWS_NOT_ASKED: {
      return unorderedViews([view]);
    }

    case UNORDERED_VIEWS: {
      return unorderedViews([...state.views, view]);
    }

    case ONLY_ORDER: {
      return orderedViews(state.viewIDOrder, [view]);
    }

    case ORDERED_VIEWS:
      return orderedViews(state.viewIDOrder, [...state.views, view]);
  }
}

export function views(state: State): ViewNode[] | null {
  switch (state.type) {
    case VIEWS_NOT_ASKED:
    case UNORDERED_VIEWS:
    case ONLY_ORDER: {
      return null;
    }

    case ORDERED_VIEWS: {
      return state.views;
    }
  }
}

function replaceView(view: ViewNode, views: ViewNode[]): ViewNode[] {
  return views.map((v) => {
    if (v.id === view.id) {
      return view;
    }

    return v;
  });
}

function updateDefaultView(viewId: string, views: ViewNode[]): ViewNode[] {
  return views.map((view) => {
    view.isDefault = false;
    if (view.id === viewId) {
      view.isDefault = true;
    }
    return view;
  });
}
