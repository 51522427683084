import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core';
import * as Colors from 'styles/colors';
import { truncateText } from 'styles/textStyles';

const titleRuleStyles = {
  'font-size': 9,
  'padding': '6px 0',
};

const styleRules = (theme: Theme) => (createStyles({
  previewWrapper: {
    backgroundColor: 'white',
    marginBottom: 5,
    width: '45%',
    zIndex: theme.zIndex.modal,
  },
  previewFileContent: {
    alignItems: 'center',
    borderRadius: 2,
    border: `1px solid ${Colors.lightBlueGrey}`,
    display: 'flex',
    justifyContent: 'center',
    height: 116,
    width: '100%',
  },
  previewCover: {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewCaption: {
    'cursor': 'pointer',
    'position': 'relative',
    'width': '100%',

    '&:hover $buttonsGroup': {
      display: 'flex',
    },
  },
  fileIcon: {
    width: '50px',
    margin: '0 auto',
  },
  fileTitle: {
    ...truncateText,
    ...titleRuleStyles,
  },
  editTitle: {
    ...titleRuleStyles,
  },
  buttonsGroup: {
    background: 'white',
    display: 'none',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '60%',
  },
  buttonsGroupLocked: {
    width: 'auto',
  },
  ContentTooltip: {
    minWidth: 120,
  },
  titleTooltip: {
    fontSize: 13,
  },
  InfoTooltip: {
    display: 'flex',
    fontSize: 9,
    justifyContent: 'space-between',
  },
  InfoSize: {
    paddingLeft: 7,
  },
}));

export type StyleProps = WithStyles<typeof styleRules>;
export const styles = withStyles(styleRules);
