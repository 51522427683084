import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { flatten, isEqual, uniq } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import slugify from 'slugify';

import { State as ReduxState } from 'reducers';
import Button, { Variant as ButtonVariant } from 'components/Button';
import PageHeader from 'components/PageHeader';
import { getSvgIconNode } from 'components/DataGrid/utils';
import svgIcons from 'styles/svgIcons';
import './ImportDatebase.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'components/AgGrid/style.scss';
import { ImportedDatabaseState, ImportedDatabaseColumn, ImportedDatabaseData } from 'data/databases/databases.types';
import { DragStoppedEvent, GridColumnsChangedEvent } from 'ag-grid-community';
import Modal from 'components/Modals/Modal';
import Header from 'components/Modals/Modal/Header';
import Content from 'components/Modals/Modal/Content';
import Actions from 'components/Modals/Modal/Actions';
import ModalTextField from 'components/Modals/ModalTextField';
import ActionButtons from 'components/Fields/ActionButtons';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import FieldNameInput from 'components/Fields/FieldNameInput';
import * as Data from 'components/Fields/data';
import SelectFieldType, { SelectionState } from 'components/Fields/SelectFieldType';
import ColumnHeader from './ColumnHeader';
import CellRenderer from './CellRenderer';
import {
  fetchImportedDatabase,
  FetchImportedDatabaseOptions,
  ImportedField,
  saveImportedDatabase,
  SaveImportedDatabaseOptions,
  createSocketConnection,
  destroySocketConnection,
  saveImportedDatabaseSuccess,
} from 'data/databases/databases.actions';
import { URLInjectedProps } from 'containers/withURLParams';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingState from 'data/LoadingState';
import { getColorByIndex } from 'styles/colors';
import { mapDataFieldTypeIntoDbFieldType } from './FieldMapper';
import { getAccounts } from '../../data/accounts/state';
import { getAllUsers } from 'data/accounts/actions';
import { getSocketUrl } from '../../env';
import { AccountNode } from '../../types/response/accountNode';
import { getAuthToken } from '../../utilities/getAuthHeaders';
import { DateFormat } from 'components/Fields/data';
import loadingAction from 'data/ui/loading/actions';
import memoize from 'memoize-one';

const defaultPopoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

interface StateProps {
  currentWorkspace: string;
  importedDatabaseState?: LoadingState;
  importedDatabase?: ImportedDatabaseState | null;
  users: AccountNode[];
}

interface DispatchProps {
  fetchImportedDatabase: (options: FetchImportedDatabaseOptions) => void;
  saveImportedDatabase: (options: SaveImportedDatabaseOptions) => void;
  fetchAccountsRequest: () => void;
  saveImportedDatabaseSuccess: (id: string) => void;
  createSocketConnection: (socketUrl: string) => void;
  destroySocketConnection: () => void;
  endLockingLoader: () => void;
}

interface State {
  mode: string;
  selectedField: ImportedDatabaseColumn;
  importedDatabase: ImportedDatabaseState | null;
  ref: HTMLElement | null;
  fieldTypeFilterQuery: string;
}

type Props = StateProps & DispatchProps & URLInjectedProps;

const ROW_HEIGHT = 40;

const indexColumn = {
  width: 75,
  menuTabs: [],
  headerName: '',
  pinned: 'left',
  valueGetter: 'node.rowIndex + 1',
  lockPosition: true,
};

export const ImportDatabaseModes = {
  BROWSE: 'browse',
  DELETE_FIELD: 'delete_field',
  MODIFY_FIELD: 'modify_field',
  RENAME_FIELD: 'rename_field',
};

export class ImportDatabase extends React.Component<Props> {
  state = {
    ref: null,
    fieldTypeFilterQuery: '',
    mode: ImportDatabaseModes.BROWSE,
    selectionState: SelectionState.SELECTED_FIELD,
    importedDatabase: { id: '', title: '', originalFilename: '', columns: [], rows: [] },
    selectedField: { colId: '', type: Data.FieldType.SingleLineText, field: '', headerName: '', subtype: '' },
  };

  checkIsSaveAllowed = memoize(columnList => columnList.length && columnList[0].type === Data.FieldType.SingleLineText);

  static getDerivedStateFromProps(props: Props, state: State): State {
    if ((state.importedDatabase && state.importedDatabase.id) || !props.importedDatabase) return state;
    return { ...state, importedDatabase: props.importedDatabase };
  }

  componentDidMount() {
    if (!this.props.importedDatabase || !this.props.importedDatabase.id) {
      const fileName = (this.props.match.params as any).databaseId || '';
      const searchParams = new URLSearchParams(this.props.location.search.substring(1));
      const title = searchParams.get('title') || '';
      const originalFilename = searchParams.get('originalFilename') || '';
      this.props.fetchImportedDatabase({ fileName, title, originalFilename });
    }
    this.props.fetchAccountsRequest();
  }

  componentDidUpdate(prevProps: Props) {
    const currentId = this.props.importedDatabase && this.props.importedDatabase.id;
    const prevId = prevProps.importedDatabase && prevProps.importedDatabase.id;
    if (currentId && prevId && currentId !== prevId) {
      this.props.history.replace(`/workspaces/${this.props.currentWorkspace}/databases/${currentId}/sheets/default/items`);
    }
  }

  componentWillUnmount() {
    if (this.props.importedDatabaseState == LoadingState.Loading) {
      this.props.saveImportedDatabaseSuccess(this.state.importedDatabase.id);
    }
    this.props.destroySocketConnection();
  }

  render() {
    const isSaveAllowed = this.checkIsSaveAllowed(this.state.importedDatabase.columns);

    if (this.props.importedDatabaseState === LoadingState.Loading && !this.props.importedDatabase) {
      return <LoadingIndicator />;
    }
    return (
      <div className="import-database-grid__wrapper">
        {this.props.importedDatabaseState === LoadingState.Error && (
          <div className="import-database-error-container">
            <div className="error-message-container import-database">Something went wrong.</div>
          </div>
        )}
        <PageHeader titleContent={
          <div className="import-database-filename-container">
            {this.state.importedDatabase.originalFilename.includes('.csv') ? <svgIcons.Csv /> : <svgIcons.Excel />}
            <span className="import-database-title">
              {this.state.importedDatabase && this.state.importedDatabase.originalFilename}
            </span>
          </div>
        }>
          <Button
            label="Save"
            variant={ButtonVariant.Primary}
            disabled={this.props.importedDatabaseState === LoadingState.Loading || !isSaveAllowed}
            onClick={this.saveDatabase}
          />
        </PageHeader>
        <div className="import-database-grid ag-theme-material">
          <AgGridReact
            columnDefs={this.getColumnDefs()}
            rowData={this.getRowData()}
            headerHeight={this.getRowHeight()}
            getRowHeight={this.getRowHeight}
            suppressContextMenu
            getMainMenuItems={this.getMainMenuItems}
            suppressChangeDetection
            onGridColumnsChanged={this.onColumnChange}
            onDragStopped={this.onDragStopped}
            frameworkComponents={{ agColumnHeader: ColumnHeader }}
            onFilterChanged={() => {
              this.props.endLockingLoader();
            }}
          />
        </div>
        {this.state.mode === ImportDatabaseModes.DELETE_FIELD && (
          <Modal onClose={this.onClose}>
            <Header onClose={this.onClose}>Are you sure you want to delete this field?</Header>
            <Content>
              <p className="import-database-delete-message">You cannot import this field to the table after delete</p>
            </Content>
            <Actions submitLabel="Remove" onSubmit={this.deleteField} onCancel={this.onClose} />
          </Modal>
        )}
        {this.state.mode === ImportDatabaseModes.RENAME_FIELD && (
          <Modal onClose={this.onClose}>
            <Header onClose={this.onClose}>Rename</Header>
            <Content>
              <ModalTextField value={this.state.selectedField.headerName} onChange={event => this.onModifyFieldName(event.target.value)} autoSelect autoFocus placeholder="New Field Name" />
            </Content>
            <Actions submitLabel="Rename" onSubmit={this.updateField} onCancel={this.onClose} />
          </Modal>
        )}
        {this.state.mode === ImportDatabaseModes.MODIFY_FIELD && (
          <Popover
            id="importDatabase"
            {...defaultPopoverProps}
            open={true}
            anchorEl={this.state.ref}
            onClose={this.onClose}
          >
            <div className="import-database-modify-field-container">
              <FieldNameInput
                fieldName={this.state.selectedField.headerName}
                onChange={this.onModifyFieldName}
              />
              <div className={`import-database-select-field ${[Data.FieldType.SingleSelect, Data.FieldType.MultiSelect].includes(this.state.selectedField.type) ? 'import-choice-field' : ''}`}>
                <SelectFieldType
                  fieldTypes={Data.allFieldTypes.filter(fieldType => fieldType.toLocaleLowerCase().includes(this.state.fieldTypeFilterQuery.toLowerCase()))}
                  fieldTypeFilterQuery={this.state.fieldTypeFilterQuery}
                  selectedFieldData={this.state.selectedField as any}
                  selectionState={this.state.selectionState}
                  onDataUpdate={this.onDateUpdate}
                  onFieldTypeFilterQueryChange={this.onFieldTypeFilterQueryChange}
                  onFieldTypeSelected={this.onFieldTypeSelected}
                  onToggleSelectFieldType={this.onToggleSelectFieldType}
                  isCreate={true}
                />
              </div>
              <ActionButtons
                onCancel={this.onClose}
                onSave={this.updateField}
              />
            </div>
          </Popover>
        )}
      </div>
    );
  }

  private onDateUpdate = (data: Data.FieldData) => {
    this.setState({ selectedField: { ...data } });
  };

  private onFieldTypeFilterQueryChange = (value: string) => {
    this.setState({ fieldTypeFilterQuery: value });
  };

  private onFieldTypeSelected = (fieldType: Data.FieldType) => {
    this.setState({ selectedField: { ...this.state.selectedField, type: fieldType }, selectionState: SelectionState.SELECTED_FIELD });
  };

  private onToggleSelectFieldType = () => {
    this.setState({ selectionState: this.state.selectionState === SelectionState.SELECTED_FIELD ? SelectionState.SELECT_FIELD : SelectionState.SELECTED_FIELD });
  };

  private getRowHeight = (): number => {
    return ROW_HEIGHT;
  };

  private getColumnDefs = (): any[] => {
    if (!this.state.importedDatabase) return [];
    return [indexColumn, ...this.state.importedDatabase.columns];
  };

  private getRowData = (): ImportedDatabaseData[] => {
    if (!this.state.importedDatabase) return [];
    return this.state.importedDatabase.rows;
  };

  private getMainMenuItems = ({ column }) => {
    const field = column.getColDef() as ImportedDatabaseColumn;
    return [
      {
        name: 'Rename',
        action: () => this.setState({ selectedField: field, mode: ImportDatabaseModes.RENAME_FIELD }),
        icon: getSvgIconNode(svgIcons.Edit),
        cssClasses: ['hubsync-grid-column-menu-item'],
      },
      {
        name: 'Delete Field',
        action: () => this.setState({ selectedField: field, mode: ImportDatabaseModes.DELETE_FIELD }),
        icon: getSvgIconNode(svgIcons.Delete),
        cssClasses: ['hubsync-grid-column-menu-item'],
      },
      {
        name: 'Modify Field Type',
        action: () => this.setState({ selectedField: field, mode: ImportDatabaseModes.MODIFY_FIELD, ref: this.getColumnRef(field.colId) }),
        icon: getSvgIconNode(svgIcons.ModifyField),
        cssClasses: ['hubsync-grid-column-menu-item'],
      },
    ];
  };

  private deleteField = (): void => {
    this.setState({
      mode: ImportDatabaseModes.BROWSE,
      importedDatabase: {
        ...this.state.importedDatabase,
        columns: this.state.importedDatabase.columns.filter((column: ImportedDatabaseColumn) => column.field !== this.state.selectedField.field),
      },
    });
  };

  private onClose = () => {
    this.setState({ mode: ImportDatabaseModes.BROWSE });
  };

  private onModifyFieldName = (headerName: string): void => {
    this.setState({ selectedField: { ...this.state.selectedField, headerName } });
  };

  private onColumnChange = (event: GridColumnsChangedEvent) => {
    const columnState = event.columnApi.getColumnState();
    if (isEqual(this.state.importedDatabase.columns, columnState)) {
      this.setState({
        importedDatabase: {
          ...this.state.importedDatabase,
          columns: columnState,
        },
      });
    }
  };

  private onDragStopped = (event: DragStoppedEvent) =>{
    const { columnApi } = event;
    const apiStateColumn = columnApi.getColumnState();
    apiStateColumn.shift();
    const newStateColumns = apiStateColumn.map(column => columnApi.getColumn(column.colId).getColDef());

    this.setState({ importedDatabase: { ...this.state.importedDatabase, columns: newStateColumns } });
  };

  private updateField = (): void => {
    this.setState({
      mode: ImportDatabaseModes.BROWSE,
      importedDatabase: {
        ...this.state.importedDatabase,
        rows: this.state.importedDatabase.rows.map((row: any, index: number) => {
          const field = this.state.selectedField.field;
          const transformedValue = transformValue(this.state.selectedField.type, this.state.importedDatabase.rows[index][field], this.props.users, this.props.users);
          return { ...row, [field]: transformedValue };
        }),
        columns: this.state.importedDatabase.columns.map((column: ImportedDatabaseColumn) => {
          if (column.colId === this.state.selectedField.colId) {
            if (Data.FieldType.SingleSelect === this.state.selectedField.type) {
              return { ...this.state.selectedField, choices: this.createSingleSelectChoices(), cellRendererFramework: CellRenderer };
            }
            if (Data.FieldType.MultiSelect === this.state.selectedField.type) {
              return { ...this.state.selectedField, choices: this.createMultiSelectChoices(), cellRendererFramework: CellRenderer };
            }
            return { ...this.state.selectedField, cellRendererFramework: CellRenderer };
          }
          return column;
        }),
      },
    });
  };

  private createSingleSelectChoices = () => {
    const rowValues = this.state.importedDatabase.rows.map(row => row[this.state.selectedField.colId]).filter(value => value);
    const uniqueValues = uniq(rowValues);
    return this.createChoices(uniqueValues);
  };

  private createChoices = (values) => {
    const choices = {};
    values.forEach((value: string, index: number) => {
      const slugValue = slugify(value.trim(), {
        replacement: '_',
        strict: true,
        remove: /[*+~.,()'"!:@]/g,
      });
      choices[slugValue] = {
        label: value,
        color: getColorByIndex(index),
      };
    });
    return choices;
  };

  private createMultiSelectChoices = () => {
    const rowValues = this.state.importedDatabase.rows.map(row => {
      const value: any = row[this.state.selectedField.colId];
      return typeof value === 'string' ? value.split(',') : value;
    }).filter(value => value);
    const uniqueValues = uniq(flatten(rowValues));
    return this.createChoices(uniqueValues);
  };

  private getColumnRef = (id: string): HTMLElement | null => {
    return document.querySelector(`.ag-header-container .ag-header-cell[col-id='${id}']`);
  };

  private saveDatabase = async () => {
    const title = this.state.importedDatabase.title;
    const fields = this.state.importedDatabase.columns.map((column: ImportedDatabaseColumn): ImportedField => {
      const data: any = {
        fieldName: column.headerName,
        fieldType: mapDataFieldTypeIntoDbFieldType(column.type),
        prevOrder: column.prevOrder,
      };
      if (column.subtype) data.subtype = column.subtype;
      if (column.choices) data.choices = column.choices;
      if (column.precision) data.precision = column.precision;
      if (column.dateFormat) data.dateFormat = column.dateFormat;
      if (column.currencySymbol) data.currencySymbol = column.currencySymbol;
      return data;
    });
    this.props.saveImportedDatabase({ id: this.state.importedDatabase.id, title, fields });
    const socketUrl = `${getSocketUrl()}?token=${await getAuthToken()}`;
    this.props.createSocketConnection(socketUrl);
  };
}

export const convertUnixDate = (value: string, format: string | null, unix = true) => {
  if (unix) {
    return value && moment(value.match(/^\d+$/)? +value: value).isValid() ? moment(value.match(/^\d+$/)? +value: value).toDate().getTime() : null;
  } else {
    return value && moment(value.match(/^\d+$/)? +value: value).isValid() ? moment(value.match(/^\d+$/)? +value: value).format(format || DateFormat.Local) : null;
  }
};

export const transformValue = (type: Data.FieldType, value: any, selectedField, users: AccountNode[] = [], allowMultiple = false) => {
  let modifiedValue = Array.isArray(value) ? value.join(', ') : value === true ? 'Checked' : [null, undefined, false].includes(value) ? '' : `${value}`;
  switch (type) {
    case Data.FieldType.Date:
      if (selectedField?.originalField?.type && (selectedField?.originalField?.type === Data.FieldType.Rating || selectedField?.originalField?.type === Data.FieldType.Email || selectedField?.originalField?.type === Data.FieldType.Phone)) {
        return null;
      } else {
        if (selectedField?.originalField?.type === Data.FieldType.Number) {
          if (isNaN(parseInt(modifiedValue)) || parseInt(modifiedValue) > 12 || parseInt(modifiedValue) < 1) {
            return null;
          }

          const now = new Date();
          modifiedValue = `${parseInt(modifiedValue)}/${now.getMonth() + 1}/${now.getFullYear()}`;
        }

        return convertUnixDate(modifiedValue, null);
      }
    case Data.FieldType.Checkbox: {
      let value = modifiedValue.toLowerCase().trim();
      if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.Number) {
        value = +value > 0? '1': '';
      } else if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.Rating) {
        value = +value > 0? 'true': '';
      } else if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.URL) {
        value = +value > 0? 'true': value;
      } else if (selectedField?.originalField?.type && (selectedField?.originalField?.type === Data.FieldType.Email || selectedField?.originalField?.type === Data.FieldType.Phone)) {
        return null;
      }

      return value === '1' || value === 'true' || value === 'checked' || value === 'yes';
    }
    case Data.FieldType.Attachment:
      return '';
    case Data.FieldType.Rating: {
      if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.Date) {
        return null;
      } else if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.Email) {
        return null;
      } else {
        const parsedValue = parseInt(modifiedValue);
        return parsedValue >= 0 && parsedValue <= 5 ? parsedValue : parsedValue > 5 ? 5 : null;
      }
    }
    case Data.FieldType.Number:
    case Data.FieldType.Currency:
    case Data.FieldType.Percent: {
      let numberValue = modifiedValue;
      if (selectedField?.originalField?.type === 'Date') {
        numberValue = modifiedValue && moment(modifiedValue.match(/^\d+$/)? +modifiedValue: modifiedValue).isValid() ? moment(modifiedValue.match(/^\d+$/)? +modifiedValue: modifiedValue).format(selectedField?.originalField?.dateFormat) : '';
      } else if (selectedField?.originalField?.type && selectedField?.originalField?.type === Data.FieldType.Email) {
        return null;
      }

      numberValue = numberValue.replace(/[^0-9-]/g, '');
      const subtype = selectedField.subtype;
      const parsedValue = subtype === 'integer' ? parseInt(numberValue) : parseFloat(numberValue);
      return !isNaN(parsedValue) ? parsedValue : null;
    }
    case Data.FieldType.SingleSelect: {
      const userNames = convertIdIntoUsername(modifiedValue, users);
      return userNames.length ? userNames[0] : modifiedValue.split(',')[0];
    }
    case Data.FieldType.MultiSelect: {
      const userNames = convertIdIntoUsername(modifiedValue, users);
      const value = ((userNames && userNames.join(',')) || modifiedValue).split(',').map(value => value.trim()).filter(value => value);
      return value || null;
    }
    case Data.FieldType.Person: {
      if (selectedField?.originalField?.type === Data.FieldType.Number || selectedField?.originalField?.type === Data.FieldType.Phone) {
        return null;
      }

      const value = convertUsernameIntoId(modifiedValue, users);
      return allowMultiple ? value : value[0];
    }
    case Data.FieldType.SingleLineText:
    case Data.FieldType.LongText:
    case Data.FieldType.URL: {
      if (selectedField?.originalField?.type === 'Date') {
        return modifiedValue && moment(modifiedValue.match(/^\d+$/)? +modifiedValue: modifiedValue).isValid() ? moment(modifiedValue.match(/^\d+$/)? +modifiedValue: modifiedValue).format(selectedField?.originalField?.dateFormat) : null;
      } else {
        const userNames = convertIdIntoUsername(modifiedValue, users);
        return userNames.length ? userNames.join(', ') : modifiedValue;
      }
    }
    default:
      return modifiedValue;
  }
};

const mapStateToProps = (state: ReduxState): StateProps => {
  const importedDatabase = state.databases.importedDatabase;
  return {
    importedDatabase: importedDatabase,
    importedDatabaseState: state.databases.importLoadingState,
    currentWorkspace: state.workspaces.current || '',
    users: getAccounts(state.app.accounts) || [],
  };
};

export const convertIdIntoUsername = (value: string, users: AccountNode[]): any[] => {
  return value.split(',')
    .map(value => value.trim())
    .filter(value => isUUID(value))
    .map(value => {
      const user = users.find((user) => user.id === value);
      if (user) {
        return user.displayName;
      }
    }).filter(userName => userName);
};

export const convertUsernameIntoId = (value: string, users: AccountNode[]): any[] => {
  return value.split(',')
    .map(value => {
      const user = users.find((user) => user.displayName === value.trim());
      if (user) return user.id;
    }).filter(user => user);
};

const mapDispatchToProps: DispatchProps = {
  fetchImportedDatabase,
  saveImportedDatabase,
  createSocketConnection,
  destroySocketConnection,
  saveImportedDatabaseSuccess,
  fetchAccountsRequest: getAllUsers,
  endLockingLoader: loadingAction.endLockingLoader,
};

export const isUUID = (value: string): null | boolean => {
  return value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) && true;
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportDatabase);
