import MenuIcon from '@material-ui/icons/MoreVert';
import { ICellRendererParams } from 'ag-grid-community';
import { Location } from 'history';
import produce from 'immer';
import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { isSelected } from 'data/ui/gridSelection/gridSelection.selectors';
import { replaceSearchParams } from 'utilities/queryParams';

import { Wrapper, DocumentLink } from './index.style';
import { stripDomain } from 'utilities/createUrl';
import { setDocumentForPreview } from 'data/documents/documents.actions';
import { openInfoModal } from 'data/modals/actions';
import { State } from 'reducers';
import { CommonNode } from 'types/response';
import { nodes } from 'data/collections/collections.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';

import 'styles/components/node-field-data.scss';

interface DispatchToProps {
  setDocument: typeof setDocumentForPreview;
  showInfo: typeof openInfoModal;
}

interface StateProps {
  isSelected: boolean;
  nodes: CommonNode[];
  permissions: string[];
}

type Props
  = DispatchToProps
  & ICellRendererParams
  & RouteComponentProps
  & StateProps
  ;

class FilenameRenderer extends React.Component<Props> {
  public render(): JSX.Element | null {
    const { data, value, isSelected } = this.props;

    if (!data) {
      return null;
    }

    return (
      <Wrapper className={isSelected ? 'selected' : ''}>
        <DocumentLink onClick={this.handleDocumentClick}>
          {value}
          <span className="filename-renderer-space"></span>
        </DocumentLink>
        {this.getNumberOfItemsInMenu() > 0 && (
          <div className={`filename-menu-icon ${isSelected ? 'visible' : ''}`}>
            <MenuIcon className={'file-context-menu-icon'} onClick={this.openContextMenu} />
          </div>
        )}
      </Wrapper>
    );
  }

  private handleDocumentClick = (): void => {
    const { data, nodes, location, history, setDocument, showInfo } = this.props;
    const document: CommonNode | undefined = nodes.find(node => node.id === data.id || node.agGridId === data.id);

    if (document && document.uri && (data.isFolder || data.hasPreview)) {
      const newLocation: Location = produce(location, draft => {
        if (data.isFolder) {
          draft.search = replaceSearchParams(draft.search, { folderID: document.id });
        } else {
          draft.pathname = stripDomain(document.uri);
          setDocument(data);
        }
      });

      history.push(newLocation);
    } else {
      showInfo('Unavailable Preview', 'This document does not have a preview yet. Check again later.');
    }
  };

  private openContextMenu = (event: React.MouseEvent<SVGGElement>): void => {
    event.persist();

    const {
      node,
      value,
      column,
      rowIndex,
    } = this.props;


    const nudgedEvent = nudgeEventPosition(event, this.getNumberOfItemsInMenu());

    // @ts-ignore
    const cell = this.props.api.context.beanWrappers.rowRenderer.beanInstance.rowCompsByIndex[rowIndex].getRenderedCellForColumn(column);
    cell.beans.contextMenuFactory.showMenu(node, column, value, nudgedEvent);
  };

  private getNumberOfItemsInMenu = (): number => {
    const menuPermissions = ['files:update', 'files:create', 'files:update', 'files:export', 'files:delete'];
    let numberOfItemsInMenu = menuPermissions.filter(permission => this.props.permissions.includes(permission)).length;
    if (!this.props.node.data.isFolder && this.props.node.data.hasPreview) numberOfItemsInMenu++;
    return numberOfItemsInMenu;
  };
}

const mapStateToProps = (state: State, props: Props): StateProps => ({
  isSelected: isSelected(state, { id: get(props, 'data.id') }),
  nodes: nodes(state),
  permissions: getWorkspacePermissions(state),
});

const mapDispatchToProps: DispatchToProps = {
  setDocument: setDocumentForPreview,
  showInfo: openInfoModal,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(FilenameRenderer);

function nudgeEventPosition(event: React.MouseEvent, numberOfItems: number): React.MouseEvent {
  return {
    ...event,
    clientX: event.clientX + 27,
    clientY: event.clientY - numberOfItems * 15,
  };
}
