import React from 'react';
import svgIcons from 'styles/svgIcons';

interface Props {
  email: string;
  goToLoginPage: () => void;
}

const RequestedTemporaryPassword: React.FC<Props> = ({ email, goToLoginPage }: Props ) => {
  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <div className="auth-password-expired">
      <div className="password-expired-title">
        <h3>Email Sent</h3>
      </div>
      <div className="icon">
        {tenantConfig.emailSentIcon? <img src={tenantConfig.emailSentIcon} alt="HubSync" /> : <svgIcons.EmailSent /> }
      </div>
      <div className="password-expired-message">
        <span>New temporary password was sent to</span>
        <p className="email">{email}</p>
      </div>
      <div className="auth-code-send-link">
        <span onClick={goToLoginPage}>Back to Log In</span>
      </div>
    </div>
  );
};

export default RequestedTemporaryPassword;
