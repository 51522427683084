import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { slateTwo, white } from 'styles/colors';

type ClassKey
  = 'box'
  | 'icon'
  | 'input'
  ;

export const querySearchStyles: Record<ClassKey, CSSProperties> = {
  box: {
    display: 'inline-flex',
    backgroundColor: white,
    margin: '10px 15px',
  },
  icon: {
    width: 14,
    height: 14,
    margin: '6px 11px',
  },
  input: {
    color: slateTwo,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.26,
    lineHeight: '15px',
    width: '100%',
  },
};
