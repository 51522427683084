import { patchFile, patchFiles } from 'pages/Files/Services';
import { get, post } from 'utilities/httpRequests';

/** */
export const fetchAttachments = async (workspaceId, databaseId) => {
  const path = `/workspaces/${workspaceId}/files/`;
  const query = `references.databaseID=${databaseId}&api_version=2`;
  const url = `${path}?${query}`;
  const response = await get(url);
  return response;
};

/** */
export const fetchAttachmentByURI = async (attachId, uri) => {
  const ids = uri.split('/');
  const workspaceId = ids[ids.indexOf('workspaces') + 1];
  const response = await get(`/workspaces/${workspaceId}/files/${attachId}`);
  return response;
};

/** */
export const patchAttachment = async (workspaceID, id, attachment) => {
  const response = await patchFile(workspaceID, id, attachment);
  return response;
};

/** */
export const deleteAttachment = async (uri, id) => {
  const ids = uri.split('/');
  const workspaceId = ids[ids.indexOf('workspaces') + 1];
  const url = `/workspaces/${workspaceId}/files/bulk-delete?api_version=2`;
  const response = await post({ url, data: [id] });
  return response;
};

/** */
export const patchAttachmentsBulk = async (files, workspaceID) => {
  const response = await patchFiles(workspaceID, { files });
  return response;
};
