import { CommonNode } from 'types/response';
export const applyCustomSort = (nodes: CommonNode[], customRowOrder: Record<string, number>): CommonNode[] => {
  let index = 0;
  function getIndex(nodeID) {
    const customOrderIndex = customRowOrder[nodeID];
    if (customOrderIndex !== null && customOrderIndex !== undefined) {
      return customOrderIndex;
    } else {
      let found = false;
      do {
        found = customIndexes.indexOf(index++) < 0;
      } while (!found);
      return index - 1;
    }
  }

  const customIndexes = Object.values(customRowOrder);
  return nodes.map((node) => {
    return { ...node, rowIndex: getIndex(node.id) };
  }).sort((entryA, entryB) => entryA.rowIndex - entryB.rowIndex);
};
