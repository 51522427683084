import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const BaseDialogContent = styled(DialogContent)`
  padding-top: 24px;
  height: 100%;
  overflow-y: auto;
`;

export const BaseDialogActions = styled(DialogActions)`
  margin: 12px 24px 24px 24px;
  justify-content: space-between;

  > div, button {

    &:first-child {
      margin-left: 0;
      margin-right: 5px;
    }

    &:last-child{
      margin-left: 5px;
      margin-right: 0;
    }
  }

  .left-side {
    flex: 1;
    text-align: left;
  }

  .right-side {
    flex: 1;
    text-align: right;

    button {

      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const LeftContent = styled.p`
  width: 42%;
  font-size: 14px;
  font-weight: 500;
  padding: 1rem 1rem 1rem 0;
`;

export const RightContent = styled.p`
  font-size: 14px;
  padding: 1rem 1rem 1rem 0;
`;

export const Input = styled.input`
    background-color: rgba(233, 237, 239, 0.3);
    border-radius: 4px;
    border: 1px solid #000000;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-family: 'Roboto';
    height: 40px;
    margin-top: 15px;
    padding: 0 10px;
    width: 100%;
`;
