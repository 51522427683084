import * as React from 'react';
import produce from 'immer';
import { Redirect, RouteComponentProps } from 'react-router-dom';

const SheetPage = (props: RouteComponentProps<never>) => {
  const newLocation = produce(props.location, draft => {
    draft.pathname = `${props.location.pathname}/items`;
  });

  return (
    <Redirect to={newLocation} />
  );
};

export default SheetPage;
