import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { State } from 'reducers';

import TextField from 'components/Fields/TextField';
import { ButtonFieldActions } from 'components/NodeFieldData/button/types';
import { getCurrentDatabase } from 'data/databases/databases.selectors';
import * as Colors from 'styles/colors';

import Dropdown from './Dropdown';
import { ActionItem, ACTION_ITEMS } from './data';
import { ButtonData } from '../../../data';
import Typography, { Variant } from '../../../..//Typography';

interface Props extends WithStyles<ClassKey> {
  data: ButtonData;
  onDataUpdate(data: ButtonData): void;
}

function ButtonField(props: Props): JSX.Element {
  const { currentDatabase } = useSelector((state: State) => ({
    currentDatabase: getCurrentDatabase(state),
  }));

  const { classes, data, onDataUpdate } = props;
  const [currentActionItem, setCurrentActionItem] = useState<ActionItem | null>();
  const [labelText, setLabelText] = useState('');

  const OptionalComponent = currentActionItem?.OptionalComponent ?? null;
  const rest = currentActionItem?.rest ?? {};

  useEffect(() => {
    setLabelText(currentActionItem?.title || '');
    onDataUpdate({ ...data, action: currentActionItem?.id ?? '' });
  }, [currentActionItem]);

  useEffect(() => {
    onDataUpdate({ ...data, label: labelText });
  }, [labelText]);

  const onActionItemClick = (item: ActionItem): void => {
    setCurrentActionItem(item);
  };

  const onLabelTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelText(event.target.value);
  };

  const handleChildrenCallback = (valid: boolean, additionalData?: string): void => {
    onDataUpdate({ ...data, valid, additionalData });
  };

  const getDisabledActions = (): string[] => {
    const result: string[] = [];
    if (currentDatabase?.metadata?.type !== 'assuresign') {
      result.push(ButtonFieldActions.CANCEL_ENVELOPE);
      result.push(ButtonFieldActions.RESEND_ENVELOPE);
    }
    return result;
  };

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        A button that can trigger customizable actions.
      </Typography>
      <Dropdown
        // Dropdown data is equivalent to the ACTION_ITEMS filtered by the ones that can be enable for the current database
        dropdownData={ACTION_ITEMS.filter((action) => !getDisabledActions().includes(action.id))}
        onItemClick={onActionItemClick}
        dropdownLabel='Action'
        searchItemPlaceholder='Find an action'
        selectItemPlaceholder='Select an action'
      />
      <TextField
        className={classes.textField}
        id="labelButton"
        label="Button Label"
        placeholder="Enter a label for the Button"
        value={labelText}
        onChange={onLabelTextChange}
        fullWidth
      />
      {OptionalComponent && <OptionalComponent buttonFieldCallback={handleChildrenCallback} {...rest} />}
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'textField'
  ;

const styles = withStyles<ClassKey>({
  textField: { marginTop: 3 },
  description: { color: Colors.blueyGrey },
});

export default styles(ButtonField);
