import { Dictionary } from 'lodash';
import * as _ from 'lodash/fp';

import { AccountNode } from '../../types/response/accountNode';

import * as actions from './actions';

const NOT_CALLED = 'NOT_CALLED';
const LOADING = 'LOADING';
const ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';
export const ACCOUNTS_LOADED = 'ACCOUNTS_LOADED';

interface NotCalled {
  type: 'NOT_CALLED';
}

interface Loading {
  type: 'LOADING';
}

interface AccountsError {
  type: 'ACCOUNTS_ERROR';
  err: Error;
}

interface AccountsLoaded {
  type: 'ACCOUNTS_LOADED';
  data: AccountNode[];
  byId: Dictionary<AccountNode>;
}

export type State
  = NotCalled
  | Loading
  | AccountsError
  | AccountsLoaded
  ;

export function notCalled(): NotCalled {
  return { type: NOT_CALLED };
}

export function loading(): Loading {
  return { type: LOADING };
}

export function accountsError(err: Error): AccountsError {
  return { type: ACCOUNTS_ERROR, err };
}

export function accountsLoaded(data: AccountNode[]): AccountsLoaded {
  return { type: ACCOUNTS_LOADED, data, byId: _.keyBy('id', data) };
}

export function getAccounts(state: State): AccountNode[] | null {
  switch (state.type) {
    case NOT_CALLED:
    case LOADING:
    case ACCOUNTS_ERROR: {
      return null;
    }

    case ACCOUNTS_LOADED: {
      return state.data;
    }
  }
}

export function getIndexedAccounts(state: State): Dictionary<AccountNode> | null {
  switch (state.type) {
    case NOT_CALLED:
    case LOADING:
    case ACCOUNTS_ERROR: {
      return null;
    }

    case ACCOUNTS_LOADED: {
      return state.byId;
    }
  }
}

export function isLoading(state: State): boolean {
  switch (state.type) {
    case NOT_CALLED: {
      return false;
    }

    case LOADING: {
      return true;
    }

    case ACCOUNTS_ERROR: {
      return false;
    }

    case ACCOUNTS_LOADED: {
      return false;
    }
  }
}

export enum AccountMode {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface AccountState {
  accountMode: AccountMode | null;
  workspaceUsers: AccountNode[];
}

export const initialState: AccountState = {
  accountMode: null,
  workspaceUsers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.inviteUsers.fulfilled.type:
      return {
        ...state,
        accountMode: AccountMode.SUCCESS,
      };
    case actions.inviteUsers.rejected.type:
      return {
        ...state,
        accountMode: AccountMode.ERROR,
      };
    case actions.setWorkspaceUsers.type:
    case actions.getWorkspaceUsers.fulfilled.type:
    case actions.updateUserRole.fulfilled.type:
    case actions.updateUserRole.rejected.type:
      return {
        ...state,
        workspaceUsers: action.payload,
      };
    case actions.resetInviteUserMode.type:
      return {
        ...state,
        accountMode: null,
      };
    default:
      return state;
  }
};
