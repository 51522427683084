import { Theme, withStyles } from '@material-ui/core';
import { CSSProperties, StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CaretOpen from '@material-ui/icons/ArrowDropUp';
import CaretClose from '@material-ui/icons/ArrowDropDown';
import cn from 'classnames';
import * as _ from 'lodash/fp';
import * as React from 'react';

import { white } from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { SvgIcon } from 'types/common';
import { TreeNode } from 'types/response/http/getNode';
import { stripDomain } from 'utilities/createUrl';

interface Props extends WithStyles<typeof styles> {
  title: string;
  isOpen: boolean;
  nodes?: TreeNode[];
  uri?: string;
  Icon: SvgIcon;
  toggleOpen(): void;
}

type PropsWithUrl = Props & URLInjectedProps;

function Title(props: PropsWithUrl): JSX.Element {
  const linkClassName = cn(props.classes.icon, props.classes.linkIcon);
  const hasNodes = props.nodes && props.nodes.length;
  const onTitleClick = hasNodes ? props.toggleOpen : _.noop;

  function stopPropagation(event: React.MouseEvent<HTMLSpanElement>): void {
    event.stopPropagation();
    event.preventDefault();

    props.history.push(stripDomain(props.uri || '/'));
  }

  return (
    <div className={`${props.classes.box} app-navigation-pane-title`} onClick={onTitleClick}>
      <props.Icon className={props.classes.icon} />
      <span className={props.classes.title}>{props.title}</span>
      {props.uri &&
        <a
          className={linkClassName}
          href={stripDomain(props.uri)}
          onClick={stopPropagation}
        >
          <svgIcons.Pagination height="1em" />
        </a>
      }
      <Caret
        nodes={props.nodes || []}
        isOpen={props.isOpen}
        className={props.classes.caret}
      />
    </div>
  );
}

type ClassKey
  = 'box'
  | 'icon'
  | 'title'
  | 'caret'
  | 'linkIcon'
  ;

const margins: CSSProperties = {
  marginTop: 8,
  marginLeft: 10,
  marginBottom: 8,
};

const iconSize: CSSProperties = {
  width: 15,
  height: 15,
};

function styles({ tenantTheme = {} }: Theme): StyleRules<ClassKey> {
  const { menuTextColor = white } = tenantTheme;

  return {
    box: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 30,
      color: menuTextColor,
      backgroundColor: '#003497',
    },
    icon: {
      ...iconSize,
      ...margins,
    },
    title: {
      flexGrow: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 13,
      fontWeight: 500,
      letterSpacing: 0.26,
      lineHeight: '15px',
      ...margins,
    },
    caret: {
      ...iconSize,
      marginRight: 10,
    },
    linkIcon: {
      color: 'unset',
      marginRight: 10,
    },
  };
}

export default withURLParams(withStyles(styles)(Title));

interface CaretProps extends SvgIconProps {
  nodes: TreeNode[];
  isOpen: boolean;
  className: string;
}

function Caret({ nodes, isOpen, className, ...props }: CaretProps): JSX.Element | null {
  if (!nodes.length) {
    return null;
  }

  const CaretIcon = isOpen ? CaretOpen : CaretClose;
  return <CaretIcon {...props} className={className} />;
}
