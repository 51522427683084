import * as React from 'react';
import { useState } from 'react';
import Modal from 'components/Modals/Modal';
import ModalHeader from 'components/Modals/Modal/Header';
import ModalContent from 'components/Modals/Modal/Content';
import Button, { Variant as ButtonVariant } from '../../components/Button';
import Typography, {
  Variant as TypographyVariant,
} from '../../components/Typography';
import { MODES } from './People';
import svgIcons from 'styles/svgIcons';
import IconButton, {
  Color as IconButtonColor,
  Size,
} from 'components/IconButton';
import { Avatar, AvatarSize } from 'components/Modals/Account/Avatar/Avatar';
import { Role } from '../../data/accounts/types';
import { editorFactory } from 'components/Modals/NodeView/componentsFactory';
import { Choice, FieldType } from '../../types/response/fieldNode';
import { Size as ButtonSize } from 'components/Button';

const TENANT_LABEL = 'Tenant Administrator';

interface Props {
  onClose: () => void;
  roles: Role[];
  mode: MODES;
  user: any;
  permissions: string[];
  workspaceTitle: string;
  setMode: (mode: MODES) => void;
  setRole: (role: Role) => void;
  resendInvitation: () => void;
  roleChoices: {
    choiceOrder: string[];
    choices: { [key: string]: Choice };
  };
}

const UpdateUserRole: React.FunctionComponent<Props> = (props: Props) => {
  const [selectedRole, setRole] = useState({ id: '', name: '' });
  const { user } = props;
  const isAdmin = !props.roles.find((role) => role.id === user?.role);


  const onChange = (value: string, inmediateSave, userData) => {
    const role = props.roles.find((role) => role.id === value);

    const TenantUser = props.roles.find((role) => {
      return role.name == TENANT_LABEL;
    });

    if (role) {
      setRole(role);
      props.setRole(role);
      if (role.name == TENANT_LABEL || TenantUser?.id == userData?.role) {
        props.setMode(MODES.TENANT_UPDATES);
      }
    }
  };

  return (
    <Modal id="modalUpdateUserRole" onClose={props.onClose}>
      {props.mode === MODES.BULK_UPDATE ? (
        <ModalHeader onClose={props.onClose}>Bulk update</ModalHeader>
      ) : (
        <div className={'modal-header'}>
          <div className={'modal-header-card'}>
            <Avatar user={user} size={AvatarSize.Large} />
            <div className={'modal-header-card-text'}>
              <span className="modal-header-card-title">
                {user.displayName}
              </span>
              <span className="modal-header-card-role">
                {props.roles.find((role) => role.id === user?.role)?.name}
                {user?.status !== 'active' ? ' (pending)' : ''}
              </span>
              <span className="modal-header-card-email">
                {user?.email}
              </span>
            </div>
          </div>
          <IconButton
            id="btnIconClose"
            icon={svgIcons.Close}
            color={IconButtonColor.BlueyGrey}
            size={Size.Medium}
            onClick={props.onClose}
          />
        </div>
      )}
      <ModalContent>
        <div>
          <div className="role-update-label">
            <Typography variant={TypographyVariant.FieldLabel}>
              <span>Role in {props.workspaceTitle}</span>
            </Typography>
          </div>
          <div className="role-update-select">
            {editorFactory({
              data: user,
              fieldId: 'role',
              fieldType: FieldType.Singlechoice,
              name: 'role',
              permissions: props.permissions,
              value:
                selectedRole?.name ||
                props.roles.find((role) => role.id === user?.role)
                  ?.name ||
                'Role',
              onChange,
              meta: {
                ...props.roleChoices,
                includeEmpty: false,
              },
            })}
          </div>
        </div>
        {props.mode === MODES.UPDATE &&
          user.status === 'pending' && (
          <div className="resend-invitation-container">
            <Button
              id="btnResendInvitation"
              label="Resend Invitation"
              variant={ButtonVariant.BackgroundLink}
              icon={svgIcons.Email}
              size={ButtonSize.Small}
              onClick={props.resendInvitation}
            />
          </div>
        )}
      </ModalContent>
      <div className="update-user-action-buttons">
        {props.mode === MODES.UPDATE && (
          <Button
            disabled={isAdmin}
            label="Remove from workspace"
            variant={ButtonVariant.SecondaryLink}
            onClick={() => props.setMode(MODES.DELETE_CONFIRM)}
            className="remove-user-modal-btn"
          />
        )}
        {props.mode === MODES.BULK_UPDATE && (
          <Button
            label="Cancel"
            variant={ButtonVariant.SecondaryLink}
            onClick={props.onClose}
          />
        )}
        <Button
          id="btnUpdate"
          label="Update"
          variant={ButtonVariant.Primary}
          disabled={!selectedRole?.id}
          onClick={() =>
            props.setMode(
              props.mode === MODES.UPDATE
                ? MODES.UPDATE_CONFIRM
                : MODES.BULK_UPDATE_CONFIRM,
            )
          }
        />
      </div>
    </Modal>
  );
};

export default UpdateUserRole;
