import styled from 'styled-components';

export const WorkspaceMenuWrapper = styled.div`
  margin-left: 16px;
`;

export const TabLabel = styled.span`
  font-size: 14px;
  letter-spacing: 0.38px;
`;
