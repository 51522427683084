import { assign } from 'lodash';

import * as AccountsAction from 'data/accounts/actions';
import * as Accounts from 'data/accounts/state';
import {
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_TENANT_DATA_SUCCESS,
  FETCH_NAVIGATION_TREE_SUCCESS,
} from 'data/app/constants';
import { AccountNode } from 'types/response/accountNode';
import { TreeNode } from 'types/response/http/getNode';
import { TenantNode } from 'types/response/tenantNode';

import * as Actions from '../../data/app/actions';
import { PreviewBehavior } from '../../types/response';

// ------------------------------------
// Action Handlers
// ------------------------------------
// TODO: refactor this section & move to /data
const ACTION_HANDLERS = {
  [FETCH_CURRENT_USER_SUCCESS]: (state, action) => (
    assign({}, state, { user: action.user.node })
  ),
  [FETCH_TENANT_DATA_SUCCESS]: (state, action) => (
    assign({}, state, { tenant: action.tenant, navigationTree: action.tenant.navigationTree })
  ),
  [FETCH_NAVIGATION_TREE_SUCCESS]: (state, action) => (
    assign({}, state, { navigationTree: action.navigationTree })
  ),
};

export interface State {
  tenant?: TenantNode;
  navigationTree?: TreeNode;
  user?: AccountNode;
  err: string;
  token: string;
  tokenExpiration: string;
  xsrf: string;
  xsrfExpiration: string;
  validCredentials: boolean;
  previewBehavior: PreviewBehavior;
  accounts: Accounts.State;
}

// ------------------------------------
// Reducer
// ------------------------------------

// TODO uncomment if needed for Baker
// Tenant theme should be applied on load

// const bakerTillyTheme: Theme = {
//   logoURL: 'https://s3.amazonaws.com/hubsyncassets/tenants/PfDCj8LRBBK8YAYEkPKcPw/logo.png',
//   buttonBackgroundColor: '#d4eb53',
//   buttonTextColor: '#302f2e',
//   headerBackgroundColor: '#302f2e',
//   headerTextColor: '#ffffff',
//   css: '.header .MuiButton-root-1 {color: #fff} .themeableButton {display:none !important}; div.header {background-color: #302f2e !important}; .bUENVy {display:none !important}',
// };

// const bakerTillyTenant: Partial<TenantNode> = {
//   id: 'PfDCj8LRBBK8YAYEkPKcPw',
//   apiURI: 'https://bakertilly.hubsync.com/api/v1',
//   uri: 'https://bakertilly.hubsync.com',
//   title: 'Baker Tilly',
//   theme: bakerTillyTheme,
// };

export const initialState: State = {
  err: '',
  token: '',
  tokenExpiration: '',
  xsrf: '',
  xsrfExpiration: '',
  validCredentials: true,
  previewBehavior: PreviewBehavior.ModalPreview,
  accounts: Accounts.notCalled(),
};

export default function app(state = initialState, action): State {
  switch (action.type) {
    case Actions.PREVIEW_BEHAVIOR_UPDATED: {
      return { ...state, previewBehavior: action.previewBehavior };
    }

    case AccountsAction.getAllUsers.pending.type: {
      if (state.accounts.type === Accounts.ACCOUNTS_LOADED) {
        return state;
      }
      return { ...state, accounts: Accounts.loading() };
    }

    case AccountsAction.getAllUsers.rejected.type: {
      return { ...state, accounts: Accounts.accountsError(action.error) };
    }

    case AccountsAction.getAllUsers.fulfilled.type: {
      return {
        ...state,
        accounts: Accounts.accountsLoaded(action.payload),
      };
    }
  }

  // TODO: Our current action type doesn't encompass all possible actions
  // TS thinks that there are no other actions. It thinks that the following
  // code is irrelevant
  // @ts-ignore
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
