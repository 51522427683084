import styled, { StyledFunction } from 'styled-components';

import Link from 'components/Link';
import * as Colors from 'styles/colors';


interface FileExtensionWrapperProps {
  isGroup: boolean;
}

const div: StyledFunction<FileExtensionWrapperProps & any> = styled.div;

export const FileExtensionWrapper = div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.lightGreyBlue};
  ${(props: any) => props.isGroup ? 'width: auto;' : ''};
`;

export const FolderLink = styled(Link)`
  color: unset;
`;
