import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AccountNode } from 'types/response/accountNode';

import { notifications } from 'data/ui/notifications/notifications.actions';
import { isGuest } from 'utilities/authentication';
import { CustomError } from 'utilities/errors';
import { State } from 'reducers';

import { AppMessages } from '../../constants';
import * as constants from './constants';
import { parseUsers } from './helpers';
import * as selectors from './selectors';
import * as services from './services';
import * as types from './types';

export const getAllUsers = createAsyncThunk(
  `FETCH_ACCOUNTS`,
  async (_, { getState }) => {
    const { app } = getState() as State;
    const accountsLoaded = app.accounts.type !== constants.ACCOUNTS_LOADED;
    let users: AccountNode[] = (app.accounts as types.AccountsLoaded).data || [];

    if (accountsLoaded && !isGuest()) {
      const response = await services.fetchAllUsers();
      users = parseUsers(response.body);
    }

    return users;
  },
);

export const resetInviteUserMode = createAction(`RESET_INVITE_USER_MODE`);

export const inviteUsers = createAsyncThunk<
  void,
  types.InviteUsers
>(
  `INVITE_USERS`,
  async ({ workspaceID, role, email, message }, { dispatch, getState }) => {
    const state = getState() as State;
    const currentUsers = selectors.getCurrentUsers(state);
    try {
      dispatch(resetInviteUserMode());
      const emailFiltered = email.filter((userEmail) =>
        !currentUsers.map((user) => user.email).includes(userEmail),
      );
      if (emailFiltered.length === 0) {
        throw new CustomError(
          'EmailError',
          `${AppMessages.NOTIFICATION_EXISTING_USER} ${email.length > 1 ? `'s` : ''}`,
        );
      }

      const { body } = await services.sendInviteToUsers(
        role, emailFiltered, message, workspaceID,
      );
      if (body) {
        const usersParsed = body.map(user => ({
          ...user,
          role: role?.id || user.role?.id,
          display: user?.status === 'pending'
            ? `${user.email} (pending)`
            : user.displayName,
        }));
        dispatch(setWorkspaceUsers([
          ...currentUsers,
          ...usersParsed,
        ]));
      } else {
        throw new CustomError('EmailInviteResponse', 'Empty invite response');
      }
    } catch (error) {
      if (error.name === 'EmailError') {
        dispatch(notifications.error({ message: error.message }));
      } else {
        dispatch(setWorkspaceUsers(currentUsers));
        dispatch(notifications.error({ message: 'Failed to invite user' }));
      }
      console.error(error);
      throw error;
    }
  },
);

export const getWorkspaceUsers = createAsyncThunk(
  `FETCH_WORKSPACE_USERS`,
  async (_, { getState }) => {
    const state = getState() as State;
    const currentWorkspace = state.workspaces.current;
    const response = await services.fetchUsersByWorkspace(currentWorkspace);
    return parseUsers(response.body, '(pending)', true);
  },
);

export const updateUserRole = createAsyncThunk<
  AccountNode[],
  { userIds: string[]; role: types.Role }
>(
  `UPDATE_USER_ROLE`,
  async ({ userIds, role }, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as State;
    const currentUsers = selectors.getCurrentUsers(state);
    const currentWorkspace = state.workspaces.current;

    try {
      await services.patchUser(userIds, role, currentWorkspace);
      dispatch(notifications.success({
        message: AppMessages.NOTIFICATION_ROLE_UPDATED,
      }));

      return currentUsers.map((user) => {
        if (userIds.includes(user.id)) {
          return {
            ...user,
            role: role.id,
          };
        }
        return user;
      });
    } catch (error) {
      console.error('Error:', error);
      dispatch(notifications.error({
        message: AppMessages.NOTIFICATION_ROLE_UPDATED_ERROR,
      }));

      return rejectWithValue(currentUsers);
    }
  },
);

export const setWorkspaceUsers = createAction<AccountNode[]>(`SET_WORKSPACES_USERS`);

export const removeWorkspaceUser = createAsyncThunk<
  AccountNode[],
  { userId: string }
>(
  `REMOVE_WORKSPACE_USER`,
  async ({ userId }, { dispatch, getState }) => {
    const state = getState() as State;
    const currentWorkspace = state.workspaces.current;

    try {
      const response = await services.removeWorkspaceUser(userId, currentWorkspace);
      const currentUsers = selectors.getCurrentUsers(state);
      const remainingCurrentUsers = currentUsers.filter(user => user.id !== userId);
      dispatch(setWorkspaceUsers(remainingCurrentUsers));
      dispatch(notifications.success({
        message: AppMessages.NOTIFICATION_USER_REMOVED,
      }));
      return response;
    } catch (error) {
      console.error('Error:', error);
      dispatch(notifications.error({
        message: AppMessages.NOTIFICATION_USER_REMOVED_ERROR,
      }));
    }
  },
);

export const removeWorkspaceUsers = createAsyncThunk<
  AccountNode[],
  { userIds: string[] }
>(`REMOVE_WORKSPACE_USERS`,
  async ({ userIds }, { dispatch, getState }) => {
    const state = getState() as State;
    const currentWorkspace = state.workspaces.current || '';

    try {
      const response = await services.removeWorkspaceUsers(userIds, currentWorkspace);
      dispatch(notifications.success({
        message: AppMessages.NOTIFICATION_USERS_REMOVED,
      }));
      return response;
    } catch (error) {
      console.error('Error:', error);
      dispatch(notifications.error({
        message: AppMessages.NOTIFICATION_USERS_REMOVED_ERROR,
      }));
    }
  },
);

export const resendUserInvitation = createAsyncThunk<
  void,
  { userId: string; email: string }
>(`RESEND_USER_INVITATION`,
  async ({ userId, email }, { dispatch, getState }) => {
    const state = getState() as State;
    const currentWorkspace = state.workspaces.current;
    try {
      const response = await services.resendInviteUser(userId, email, currentWorkspace);
      dispatch(notifications.success({
        message: AppMessages.NOTIFICATION_USER_INVITATION_RESENT,
      }));
      return response;
    } catch (error) {
      console.error('Error:', error);
      dispatch(notifications.error({
        message: AppMessages.NOTIFICATION_USER_INVITATION_RESENT_ERROR,
      }));
    }
  });
