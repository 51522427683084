import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey =
  | 'mfaFormContainer'
  | 'topContent'
  | 'bodyContent'
  | 'footerContent'
  | 'imageBodyCenter'
  | 'textBodyCenter'
  | 'lineSeparator'
  | 'textContainer'
  | 'radioButtonContainer'
  | 'inputSelected'
  | 'itemTitle'
  | 'itemSubtitle'
  | 'continueActionButton'
  | 'helpActionButton'
  | 'continueActionButtonbakertilly'
;

export const styles: Record<ClassKey, CSSProperties> = {
  mfaFormContainer: {
    textAlign: 'center',
    color: '#212121',
    padding: '0 3rem 2rem 3rem',
  },

  topContent: {
    'display': 'flex',
    'flexDirection': 'column',

    '& h3': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      padding: '0',
      margin: '0 0 0.5rem',
      color: '#212121',
    },

    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.1.rem',
      fontSize: '0.8rem',
      letterSpacing: '0.03rem',
      padding: 0,
    },
  },

  bodyContent: {
    'display': 'flex',
    'flexDirection': 'column',
  },

  footerContent: {

    '& a': {
      'textAlign': 'center',
      'margin': 'auto',
      'display': 'block',
      'padding': '15px 11.25px 16px 11.25px',

      '& span': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '1.1.rem',
        fontSize: '0.8rem',
        letterSpacing: '0.03rem',
        color: '#0065FF',
        cursor: 'pointer',
      },
    },
  },

  imageBodyCenter: {
    'margin': '3.15rem 0 3.15rem 0',

    '& img': {
      width: '70.4px',
    },
  },

  textBodyCenter: {
    'fontFamily': 'Roboto',
    'fontStyle': 'normal',
    'fontWeight': 'normal',
    'fontSize': '0.875rem',
    'lineHeight': '1.25rem',
    'textAlign': 'center',
    'letterSpacing': '0.016rem',

    '& span': {
      fontWeight: 'bold',
    },

  },

  lineSeparator: {
    margin: '0',
  },

  textContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 11,
    marginLeft: '0.5rem',
  },

  radioButtonContainer: {
    'flex': 1,
    'alignItems': 'flex-start',

    '& span': {
      padding: 0,
    },

    '& input[type="radio"]:checked:after': {
      width: '15px',
      height: '15px',
      borderRadius: '15px',
      top: '-2px',
      left: '-1px',
      position: 'relative',
      backgroundColor: '#ffa500',
      content: '',
      display: 'inline-block',
      visibility: 'visible',
      border: '2px solid white',
    },
  },

  inputSelected: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.8rem',
  },

  itemTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#212121',
    letterSpacing: '0.04rem',
  },

  itemSubtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.1rem',
    fontSize: '0.8rem',
    color: '#4A4A4A',
    letterSpacing: '0.04rem',
    textAlign: 'left',
    marginTop: '0.3rem',
  },

  continueActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
  },

  continueActionButtonbakertilly: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1.5rem',
    borderRadius: '0.25rem',
    backgroundColor: '#D1EC51 !important',
    color: 'black !important',
  },

  helpActionButton: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
};
