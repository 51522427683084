import React from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ICellRendererParams } from 'ag-grid-community';
import { athensGrey } from 'styles/colors';

import svgIcons from '../../../styles/svgIcons';

type ClassKey =
  | 'expandIconContainer'
  | 'container'
  | 'expandIcon';

export const styles: Record<ClassKey, CSSProperties> = {
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 2,
    marginLeft: '0.125rem',
  },
  expandIconContainer: {
    'width': 24,
    'height': 24,
    'position': 'absolute',
    'top': '50%',
    'left': '50%',
    'transform': 'translate(-50%, -50%)',
    'borderRadius': 4,
    '&:hover': {
      backgroundColor: athensGrey,
    },
  },
  expandIcon: {
    height: '100%',
    width: '100%',
  },
};

interface RowNumberRendererProps
  extends WithStyles<typeof styles>,
    ICellRendererParams {}

export const RowNumberRenderer = withStyles(styles)(
  ({ node, classes, context, data, value }: RowNumberRendererProps) => {
    return (
      <>
        <div className={`${classes.container} expand`}>
          <button
            className={classes.expandIconContainer}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              event.preventDefault();
              context.handleOnExpandRow(data, node);
            }}
          >
            <svgIcons.ExpandRecord className={classes.expandIcon} />
          </button>
        </div>
        <div className={`${classes.container} row-number`}>
          <p className='row-number-value'>{value}</p>
        </div>
      </>
    );
  },
);
