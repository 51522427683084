import { Location } from 'history';
import { State } from 'reducers';
import { PreviewBehavior } from 'types/response';
import { TreeNode } from 'types/response/http/getNode';
import { TenantNode } from 'types/response/tenantNode';


export const location = (state: State): Location => state.router.location;

export const currentLocationPath = (state: State): string | null => {
  const loc = location(state);

  if (loc) {
    return loc.pathname + loc.search;
  }

  return null;
};

export const currentUser = (state: State) => state.app.user;

// TODO determine default tenant logic
// If we are waiting for a tenant should we have any default configured tenant
export function tenantSelector(state: State): TenantNode | undefined {
  return state.app.tenant;
}

// TODO navigation tree to be stored separately from tenant
// selectors to be updated accordingly
export function navigationTreeSelector(state: State): TreeNode | undefined {
  return state.app.navigationTree;
}

export const previewBehavior = (state: State): PreviewBehavior => state.app.previewBehavior;
