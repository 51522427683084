import { Theme, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import { hubsyncBlue } from 'styles/colors';
import { LEFTHAND_SIDEBAR_WIDTH } from 'styles/constants';
import { TreeNode } from 'types/response/http/getNode';

import List from './List';
import QuerySearch from './QuerySearch';

interface Props extends WithStyles<typeof styles> {
  isOpened: boolean;
  navigationTree: TreeNode[];
  query?: string;
  handleQuery(event: React.ChangeEvent<HTMLInputElement>): void;
  permissions: string[];
}

export function Pane(props: Props): JSX.Element {
  const { classes, isOpened, navigationTree, query, handleQuery } = props;
  const { wrapper, openWrapper, closeWrapper } = classes;

  const wrapperClassName = cn(wrapper, {
    [openWrapper]: isOpened,
    [closeWrapper]: !isOpened,
  });

  return (
    <div className={`${wrapperClassName} app-navigation-pane`}>
      <QuerySearch
        query={query}
        handleQuery={handleQuery}
      />
      <List navigationTree={navigationTree} permissions={props.permissions} />
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'openWrapper'
  | 'closeWrapper'
  ;

function styles({ tenantTheme = {} }: Theme): StyleRules<ClassKey> {
  const { menuBackgroundColor = hubsyncBlue } = tenantTheme;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'fixed',
      width: `${LEFTHAND_SIDEBAR_WIDTH}px`,
      transition: 'left 0.3s',
      backgroundColor: menuBackgroundColor,
      height: 'calc(100vh - 3rem)',
      zIndex: 99999999999,
    },
    openWrapper: {
      left: 0,
    },
    closeWrapper: {
      left: `${-LEFTHAND_SIDEBAR_WIDTH}px`,
    },
  };
}

export default withStyles(styles)(Pane);
