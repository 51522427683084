export const textWidth = (text: string): number => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = '1rem Roboto';
    return ctx.measureText(text).width;
  }

  return 0;
};

export const replaceAmpCodeBySymbol = (text: string) =>
  text.includes('&amp;') ? text.replace('&amp;', '&') : text;
