import MenuList from '@material-ui/core/MenuList';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Styles from 'styles';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import FieldMenuItem from './FieldMenuItem';
import FieldMenuItems from './FieldMenuItems';
import { FieldData, FieldType } from '../../data';
import SelfSelectingInput from '../../../SelfSelectingInput';
import Typography, { Variant } from '../../../Typography';

export interface OwnProps {
  fieldTypes: FieldType[];
  selectedFieldData: FieldData;
  fieldTypeFilterQuery?: string;
  onFieldTypeFilterQueryChange(query: string): void;
  onFieldTypeSelected(fieldType: FieldType): void;
  onToggleSelectFieldType(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function SelectField(props: Props): JSX.Element {
  const {
    fieldTypes,
    classes,
    fieldTypeFilterQuery = '',
    selectedFieldData,
  } = props;

  function onFieldTypeFilterQueryChange(event: React.FormEvent<HTMLInputElement>): void {
    props.onFieldTypeFilterQueryChange(event.currentTarget.value);
  }

  function renderMenuItem(fieldType: FieldType): JSX.Element {
    return (
      <FieldMenuItem
        key={fieldType}
        fieldType={fieldType}
        selectedFieldType={selectedFieldData.type}
        onSelect={props.onFieldTypeSelected}
      />
    );
  }

  return (
    <div className={classes.wrapper} >
      <div className={classes.input}>
        <svgIcons.Search className={classes.icon} />
        <Typography
          variant={Variant.Input}
          className={classes.filterQuery}
        >
          <SelfSelectingInput
            value={fieldTypeFilterQuery}
            onChange={onFieldTypeFilterQueryChange}
            placeholder="Find a Field Type"
          />
        </Typography>
        <svgIcons.Close
          className={classes.icon}
          onClick={props.onToggleSelectFieldType}
        />
      </div>
      <MenuList className={classes.fieldsList}>
        <FieldMenuItems
          query={fieldTypeFilterQuery}
          fieldTypes={fieldTypes}
          renderMenuItem={renderMenuItem}
        />
      </MenuList>
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'input'
  | 'icon'
  | 'filterQuery'
  | 'fieldsList'
  ;

const styles = withStyles<ClassKey>({
  wrapper: {
    backgroundColor: Colors.athensGrey,
    marginTop: 5,
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    padding: '5px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 10,
    height: 10,
    color: Colors.slateTwo,
  },
  filterQuery: {
    ...Styles.focus,
    backgroundColor: Colors.athensGrey,
    margin: '0 7px',
    border: '1px solid transparent',
    flex: 1,
  },
  fieldsList: {
    width: 274,
    maxHeight: 444,
    padding: '6px 0',
    backgroundColor: Colors.white,
    borderRadius: 2,
  },
});

export default styles(SelectField);
