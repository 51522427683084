export const forcePositiveString = (value: string) => {
  const parsedNumber = Number(value.toString().replace(',', ''));

  return !isNaN(parsedNumber) ? value.toString().replace('-', '') : null;
};

export const transformValue = (value: string, allowNegative?: boolean) => {
  let numericValue: string | null = value
    ? value?.toString().replace(',', '')
    : value;
  if (isNaN(Number(numericValue))) {
    return null;
  }
  if (!allowNegative && value) {
    numericValue = forcePositiveString(value);
  }
  return numericValue;
};

export const forcePositive = (value) => (value >= 0 ? value : -value);
export const parseValue = (
  value: number | string | null,
  precision?: number,
  allowNegative?: boolean,
) => {
  let numericValue =
    typeof value === 'number' ? value : parseFloat(value || '');

  if (isNaN(numericValue)) {
    return null;
  }

  if (!allowNegative) {
    numericValue = forcePositive(numericValue);
  }

  if (typeof precision === 'number') {
    numericValue = +numericValue.toFixed(precision);
  }

  return numericValue;
};

export const parseValueFormatted = (
  value: string,
  precision?: number,
  allowNegative?: boolean,
) => {
  let addDash = false;

  if (allowNegative && value && value[0] == '-') addDash = true;
  let finalValue = value?.toString().replace('-', '');

  if (isNaN(Number(finalValue))) return '';

  if (finalValue && finalValue.length > 0) {
    finalValue = addPrecision(addCommas(finalValue), precision);
  }

  if (addDash) {
    finalValue = '-' + finalValue;
  }

  return value?.length == 0 ? '' : finalValue;
};

export const getNumberFromFormat = (formattedNum = ''): number => {
  return parseFloat(formattedNum.toString().replace(',', ''));
};

export const toPrecision = (
  value: number | string | null,
  precision?: number,
) => {
  if (!value && value !== 0) {
    return null;
  }

  return typeof precision === 'number'
    ? (+value).toFixed(precision)
    : `${value}`;
};

export const precisionToStep = (precision?: number) =>
  typeof precision === 'number'
    ? 1 / Math.pow(10, precision) // eg 2 => 0.01
    : undefined;

export const addCommas = (nStr = ''): string => {
  const tempString = nStr;
  const x = tempString.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

export const fillWithRepeatedChar = (string: string, times: number): string => {
  let repeatedString = '';
  while (times > 0) {
    repeatedString += string;
    times--;
  }
  return repeatedString;
};
export const addPrecision = (numberString = '', precision = 0): string => {
  if (precision > 0 && numberString) {
    if (numberString.indexOf('.') > -1) {
      const fullNumber = numberString.split('.');
      const integerPart = fullNumber[0] ?? '';
      const decimalPart = fullNumber[1] ?? '';

      const zeros = precision - decimalPart.length;

      return integerPart + '.' + decimalPart + fillWithRepeatedChar('0', zeros);
    } else {
      return numberString += '.' + fillWithRepeatedChar('0', precision);
    }
  } else {
    return numberString;
  }
};
