import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { compose } from 'recompose';

import Star from './Star';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';

type Props =
  & FieldRendererProps
  & WithStyles<ClassKey>
  ;

function RatingRenderer(props: Props): JSX.Element {
  const value = getValue(props);
  if (props.type === FieldRendererTypes.short || props.type === FieldRendererTypes.createCard) {
    return (
      <span
        className={getColumnHeaderClass(props.fieldType)}
        title={`${value}`}
      />
    );
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <div style={{ display: 'inline-flex', cursor: 'default' }}>
        {new Array(value || 0).fill(null).map(renderStar)}
      </div>
    </div>
  );
}

type ClassKey =
  | 'wrapper'
  | 'stars'
  ;

const styles = withStyles<ClassKey>({
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  stars: { display: 'inline-flex', cursor: 'pointer' },
});

const enhance = compose<Props, FieldRendererProps>(
  wrapCellRenderer,
  styles,
);

export default enhance(RatingRenderer);

function getValue(props: FieldRendererProps): number {
  return (typeof props.value === 'number') ? props.value : 0;
}

function renderStar(_unknown: unknown, index: number): JSX.Element {
  return <Star key={index} isFilled isActive />;
}
