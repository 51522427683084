import * as React from 'react';
import * as Dropzone from 'react-dropzone/dist';

import { styles } from './FileUploader.style';
import { getFileExtension, MediaTypeByExtension } from 'utilities/files';
import { UploaderProps } from 'components/NodeFieldData/attachment/uploaders/types';
import Typography, { Variant } from 'components/Typography';
import svgIcons from 'styles/svgIcons';
import { withStyles, WithStyles } from '@material-ui/core';
import { useEffect } from 'react';

type Props = UploaderProps & WithStyles<typeof styles>;

const FileUploader = ({ onAdd, acceptTypes, classes }: Props) => {
  const onDrop = (files: File[]): void => {
    onAdd(files.map(file => {
      const extension = getFileExtension(file.name);
      return ({
        file,
        extension,
        contentType: MediaTypeByExtension[extension] || file.type,
      });
    }));
  };

  const pasteUpload = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    // eslint-disable-next-line guard-for-in
    for (const index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        if (acceptTypes && !acceptTypes.includes(item.type)) {
          return;
        }
        onDrop([item.getAsFile()]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('paste', pasteUpload);
    return () => {
      document.removeEventListener('paste', pasteUpload);
    };
  }, []);

  return (
    <>
      <Dropzone className={classes.root} onDrop={onDrop} accept={acceptTypes}>
        <svgIcons.Attach className={classes.uploadIcon} />
        <Typography variant={Variant.SubHeading}>
          Select Files to Upload
        </Typography>
        <Typography variant={Variant.Notes}>
          or drag and drop files
        </Typography>
      </Dropzone>
    </>
  );
};

export default withStyles(styles)(FileUploader);
