import { values } from 'lodash';
import { find, compose, where, equals } from 'lodash/fp';
import { Bookmark } from 'types/schema';
import { BookmarkMeta } from './types';

export const bookmarksById = (state) => state.bookmarks.byId;
export const bookmarks = (state) => values(bookmarksById(state));
export const bookmarkBeingSaved = (state): boolean => state.bookmarks.isSaveLoading;
export const getBookmarkByUri = (state) => (bookmarkURI: string): Bookmark | undefined => {
  return compose(
    find<Bookmark>(where({ bookmarkURI: equals(bookmarkURI) })),
    bookmarks,
  )(state);
};
export const getBookmarkById = (state) => (bookmarkURI: string): Bookmark | undefined => {
  return compose(
    find<Bookmark>(where({ id: equals(bookmarkURI) })),
    bookmarks,
  )(state);
};

export const getBookmarkByMeta = (state) => (bookmarkMeta?: BookmarkMeta): Bookmark | undefined => {
  if (!bookmarkMeta) return undefined;
  return compose(
    find<Bookmark>(where({ recordID: equals(bookmarkMeta.recordID), model: equals(bookmarkMeta.model) })),
    bookmarks,
  )(state);
};
