import { createReducer, current } from '@reduxjs/toolkit';

import * as actions from './actions';
import * as helpers from './helpers';
import { ActivityFeedState, ActivityFeedItem } from './types';

export const initialState: ActivityFeedState = {
  isFetchLoading: false,
  feed: [],
  error: null,
  isSyncInProgress: false,
  isEndResult: false,
  next: '',
  query: '',
  type: '',
  lockScroll: false,
  items: [],
};

export default createReducer(initialState, {
  [actions.loadActivities.pending.type]: (draft) => {
    draft.isFetchLoading = true;
  },

  [actions.loadActivities.fulfilled.type]: (draft, { payload }) => {
    const state = current(draft);
    const prevActivities = (state.feed ?? [])
      .map(item => item.activities)
      .flat() as ActivityFeedItem[];
    helpers.setCollectionItems(prevActivities, payload.items);

    draft.isFetchLoading = false;
    draft.isEndResult = payload.length < 1;
    draft.feed = helpers.transformActivitiesResponse(
      [...prevActivities, ...payload.items],
    );
    draft.items = [...prevActivities, ...payload.items];
  },

  [actions.loadActivities.rejected.type]: (draft, { error }) => {
    draft.isFetchLoading = false;
    draft.error = error.message;
  },

  [actions.startActivityFeedRefresh.type]: (draft) => {
    draft.isSyncInProgress = true;
  },

  [actions.stopActivityFeedRefresh.type]: (draft) => {
    draft.isSyncInProgress = false;
  },

  [actions.reset.type]: (draft) => {
    draft.isFetchLoading = true;
    draft.feed = [];
    draft.error = null;
    draft.isSyncInProgress = false;
  },

  [actions.setNext.type]: (draft, { payload }) => {
    draft.next = payload;
  },

  [actions.filter.type]: (draft, { payload }) => {
    const state = current(draft);
    const { query, type } = payload;
    const filterApplied = type !== 'allActivity';
    let items = state.items;
    let lockScroll = false;

    if (query) {
      items = helpers.searchActivities(query);
      lockScroll = true;
    }

    if (type && filterApplied) {
      items = helpers.filterActivitiesByType(type, items);
      lockScroll = true;
    }

    draft.query = query;
    draft.type = type;
    draft.feed = helpers.transformActivitiesResponse(items);
    draft.lockScroll = lockScroll;
  },
});
