import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import { DirectoryType } from 'data/documents/documents.types';
import svgIcons from 'styles/svgIcons';
import { DocumentMinimalType } from 'types/response/documentNode';
import { normalizeURL } from 'utilities/format';

import { Variant } from '../../Button';
import LoadingIndicator from '../../LoadingIndicator/index';
import SearchBar from '../../SearchBar';
import Typography, { Variant as TypographyVariant } from '../../Typography';
import ModalContent from '../Modal/Content';
import ModalCustomActions from '../Modal/CustomActions';
import ModalHeader from '../Modal/Header';
import { DirectoryWrapper, DirectoryWrapperProps, styles } from './MoveCopyDocument.style';

interface OwnProps {
  selectedItems: DocumentMinimalType[];
  loading: boolean;
  directories: DirectoryType[];
  selected: DirectoryType | null;
  opened: Record<string, boolean>;
  query: string;
  onDirectoryClick(directory: DirectoryType): void;
  onQueryChange(query: string): void;
  onMove(): void;
  onCopy(): void;
  onClose(): void;
}

type Props =
  & OwnProps
  & WithStyles<typeof styles>
  ;

function MoveCopyDocument(props: Props): JSX.Element {
  function onChange(event: React.SyntheticEvent<HTMLInputElement>) {
    props.onQueryChange(event.currentTarget.value);
  }

  function renderDirectory(directory: DirectoryType): JSX.Element {
    return (
      <Directory
        {...props}
        key={directory._id}
        directory={directory}
        depth={0}
      />
    );
  }

  return (
    <React.Fragment>
      <ModalHeader onClose={props.onClose}>Copy or Move</ModalHeader>
      <ModalContent className={props.classes.content}>
        {props.loading
          ? <LoadingIndicator />
          : (
            <>
              <Typography variant={TypographyVariant.ModalText} className={props.classes.label}>
                Select a Destination Folder
              </Typography>
              <SearchBar
                placeholder="Search Folders"
                value={props.query}
                onChange={onChange}
                autoFocus
                className={props.classes.searchBar}
              />
              <div className={props.classes.directories}>
                {props.directories.map(renderDirectory)}
              </div>
            </>
          )
        }
      </ModalContent>
      <ModalCustomActions
        leftButtons={[
          {
            label: 'Cancel',
            variant: Variant.SecondaryLink,
            onClick: props.onClose,
          },
        ]}
        rightButtons={[
          {
            label: 'Move',
            variant: Variant.Primary,
            onClick: props.onMove,
            disabled: props.loading || !props.selected,
          }, {
            label: 'Copy',
            variant: Variant.Primary,
            onClick: props.onCopy,
            disabled: props.loading || !props.selected,
          },
        ]}
      />
    </React.Fragment>
  );
}

export default withStyles(styles)(MoveCopyDocument);

interface DirectoryProps {
  depth: number;
  directory: DirectoryType;
  selectedItems: DocumentMinimalType[];
  selected: DirectoryType | null;
  opened: Record<string, boolean>;
  onDirectoryClick(directory: DirectoryType): void;
}

function Directory(props: DirectoryProps & WithStyles<typeof styles>): JSX.Element {
  const { depth, directory, selectedItems, opened, selected, classes } = props;

  const selectedItemsMap = selectedItems.reduce(function(map, obj) {
    map[normalizeURL(obj.apiURI)] = true;
    return map;
  }, {});

  const { _id, uri, title, nodes = [] } = directory;
  const isOpened = opened[_id];
  const disabled = selectedItemsMap[normalizeURL(uri)];
  const hasDirectories = !disabled && !!nodes.length;

  function onClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    props.onDirectoryClick(directory);
  }

  function renderDirectory(directory: DirectoryType): JSX.Element {
    return (
      <Directory
        {...props}
        key={directory._id}
        directory={directory}
        depth={depth + 1}
      />
    );
  }

  const directoryProps: DirectoryWrapperProps & React.HTMLAttributes<HTMLDivElement> =
    disabled
      ? { disabled, depth }
      : { depth, opened: !isOpened, selected: selected === directory, onClick }
    ;

  return (
    <React.Fragment>
      <DirectoryWrapper {...directoryProps} >
        {hasDirectories && !isOpened ? <svgIcons.CollapseFolder className={classes.collapseIcon} />
          : <svgIcons.ExpandFolder className={classes.expandIcon} />
        }
        <svgIcons.FolderNew className={classes.folderIcon} />
        {title}
      </DirectoryWrapper>
      {!isOpened && nodes.map(renderDirectory)}
    </React.Fragment>
  );
}
