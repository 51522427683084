import { MenuItem } from 'components/ContextMenu/ContextMenu';
import svgIcons from 'styles/svgIcons';
import pluralize from 'pluralize';

interface GetMenuItemsProps {
  collectionType: string;
  isArchived: boolean;
  isFavorite: boolean;
  isInformation: () => void;
  isMeta: string | undefined;
  onAddAsFavorite: () => void;
  onArchiveClick: () => void;
  onDeleteClick: () => void;
  onDuplicate: () => void;
  onEditTitle: () => void;
  onRenameWorkspaceWithMeta: () => void;
  permissions: string[];
  withFavorite?: boolean;
}

export default function getMenuItems(props: GetMenuItemsProps): MenuItem[] {
  const { withFavorite, permissions, isFavorite, isInformation, isMeta } = props;
  const result: MenuItem[] = [];
  const duplicateEnabled = true;
  const collectionType = pluralize((props.collectionType).toLowerCase()) || null;

  if (withFavorite) {
    result.push({
      id: 'favorite',
      title: isFavorite ? 'Unfavorite' : 'Favorite',
      Icon: isFavorite ? svgIcons.StarFilled : svgIcons.StarLined,
      action: () => props.onAddAsFavorite(),
      keepMenuOpenAfterClick: true,
    });
  }

  if (isInformation && isMeta) {
    result.push({
      id: 'information',
      title: 'Information',
      Icon: svgIcons.Info,
      action: isInformation,
    });
  }

  // Rename Workspace with meta data
  if (permissions.includes(`${collectionType}:update`) && isMeta) {
    result.push({
      id: 'rename',
      title: 'Rename',
      Icon: svgIcons.Edit,
      action: () => props.onRenameWorkspaceWithMeta(),
    });
  }

  if (permissions.includes(`${collectionType}:update`) && !isMeta) {
    result.push({
      id: 'rename',
      title: 'Rename',
      Icon: svgIcons.Edit,
      action: () => props.onEditTitle(),
    });
  }

  if (permissions.includes(`${collectionType}:create`) && duplicateEnabled && collectionType === 'databases') {
    result.push({
      id: 'copy',
      title: 'Duplicate',
      Icon: svgIcons.Duplicate,
      action: () => props.onDuplicate(),
    });
  }

  // Enable in scope of https://airtable.com/tblIUTnfJDElm5Sdg/viwDfzKZagK4BIK4g/recSZaoqFr2xMyzDl
  // if (permissions.includes(PermissionTypes.freeze) && !isArchived) {
  //   result.push({
  //     id: 'archive',
  //     title: 'Archive',
  //     icon: 'archive',
  //     action: () => props.onArchiveClick()
  //   });
  // }
  //
  // if (permissions.includes(PermissionTypes.unfreeze) && isArchived) {
  //   result.push({
  //     id: 'unarchive',
  //     title: 'Unarchive',
  //     icon: 'archive',
  //     action: () => props.onArchiveClick()
  //   });
  // }

  if (permissions.includes(`${collectionType}:delete`)) {
    result.push({
      id: 'delete',
      title: 'Delete',
      Icon: svgIcons.Delete,
      action: props.onDeleteClick,
    });
  }

  return result;
}
