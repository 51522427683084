import * as React from 'react';

import TextRenderer from '../../components/TextRenderer';
import wrapCellRenderer from '../../components/wrapCellRenderer';
import { FieldRendererProps } from '../../Fields.types';

const FileSizeRenderer = ({ value }: FieldRendererProps) => {
  return (
    <TextRenderer>{value}</TextRenderer>
  );
};

export default wrapCellRenderer(FileSizeRenderer);
