import { Auth } from 'aws-amplify';
import { getApiV2Url } from '../env';
import * as request from 'superagent';

class AuthService {
  initMFA(email: string, password: string): Promise<any> {
    return Auth.signIn(email.toLocaleLowerCase('en-US'), password);
  }

  responseChallenge(user: any, response: string): Promise<any> {
    return Auth.sendCustomChallengeAnswer(user, response);
  }

  authenticate(email: string, password: string): Promise<any> {
    return Auth.signIn(email.toLocaleLowerCase('en-US'), password);
  }

  forgotPasswordRequest(email: string): Promise<any> {
    const endpoint = `${getApiV2Url()}/public/forgot-password`;
    return request
      .post(endpoint)
      .send({ email: email.toLocaleLowerCase('en-US') });
  }

  forgotPasswordSubmit(
    code: string,
    email: string,
    password: string,
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(email.toLocaleLowerCase('en-US'), code, password);
  }

  async requestNewTemporaryPassword(email: string) {
    const endpoint = `${getApiV2Url()}/public/request-new-password`;
    return request.post(endpoint).send({ email: email.toLocaleLowerCase('en-US') });
  }

  async confirmEmail(user: string, hash: string) {
    const endpoint = `${getApiV2Url()}/public/verify-email`;
    return request.post(endpoint).send({ userId: user, uuid: hash });
  }
}

export default new AuthService();
