import * as actions from 'data/filethis/filethis.actions';
import * as constants from 'data/filethis/filethis.constants';
import * as types from 'data/filethis/filethis.types';
import * as request from 'superagent';
import { all, put, call, takeEvery } from 'redux-saga/effects';
import { createWorkspaceUrl } from 'utilities/createUrl';
import { normalizeURL } from 'utilities/format';
import getAuthHeaders from 'utilities/getAuthHeaders';
import { handleError } from 'utilities/saga';


export function* fetchFilethisSession(action: types.FetchFilethisSessionAction) {
  const workspaceUrl = normalizeURL(createWorkspaceUrl(action.workspaceId));
  const endpoint = normalizeURL('/filethis');
  try {
    const { body } = yield call(() => request.get(endpoint).set(getAuthHeaders()).query({ workspace: workspaceUrl }));
    yield put(actions.setFilethisSession(body));
    yield put(actions.fetchFilethisSessionSuccess());
  } catch (error) {
    yield put(actions.fetchFilethisSessionError(error.toString()));
    yield call(handleError, error.status === 401 ? 'Permission denied' : error.toString());
  }
}

function* watchFetchFilethisSession() {
  yield takeEvery(constants.FETCH_FILETHIS_SESSION, fetchFilethisSession);
}

export default function* watchSaga() {
  yield all([
    watchFetchFilethisSession(),
  ]);
}
