export const DEBOUNCE_MILLIS = 400;

export enum AppMessages {
  CONFIRMATION_ROLE_MESSAGE_USER = 'Are you sure you want to change the role for this user?',
  CONFIRMATION_ROLE_MESSAGE_USERS = 'Are you sure you want to change the role for these users?',
  CONFIRMATION_REMOVE_USER_MESSAGE = 'Are you sure you want to remove this user from the Workspace',
  CONFIRMATION_REMOVE_USERS_MESSAGE = 'Are you sure you want to remove these users from the Workspace',
  NOTIFICATION_EXISTING_USER = 'Existing user',
  NOTIFICATION_USER_INVITATION_SENT = 'User invitation sent',
  NOTIFICATION_USER_INVITATION_SENT_ERROR = 'Invites was not sent to user. Please try again later!',
  NOTIFICATION_USERS_INVITATION_SENT = 'Users invitations sent',
  NOTIFICATION_USERS_INVITATION_SENT_ERROR_ = 'Invites were not sent to users. Please try again later!',
  NOTIFICATION_USER_INVITATION_RESENT = 'User invitation resent',
  NOTIFICATION_USER_INVITATION_RESENT_ERROR = 'User invitation was not resent',
  NOTIFICATION_USER_REMOVED = 'User removed',
  NOTIFICATION_USER_REMOVED_ERROR = 'User was not removed',
  NOTIFICATION_USERS_REMOVED = 'Users removed',
  NOTIFICATION_USERS_REMOVED_ERROR = 'Users were not removed',
  NOTIFICATION_PASSWORD_UPDATED = 'Password updated ',
  NOTIFICATION_PASSWORD_UPDATED_ERROR = `Password coudn't be updated`,
  NOTIFICATION_PROFILE_UPDATED = 'Profile updated',
  NOTIFICATION_PROFILE_UPDATED_ERROR = `The profile couldn't be updated`,
  NOTIFICATION_PROFILE_EXISTING_USER_EMAIL = 'An Account already exists with this email',
  NOTIFICATION_ROLE_UPDATED = 'User role updated',
  NOTIFICATION_ROLE_UPDATED_ERROR = `User role couldn't be updated`,
  CONFIRM_CHANGE_LABEL = 'Confirm change',
  REMOVE_USER_LABEL = 'Remove user',
  REMOVE_USERS_LABEL = 'Remove users',
  ERROR_TITLE_LABEL = 'Error!',
  GENERIC_ERROR_MESSAGE= 'Something went wrong!',
  GENERIC_SHARED_FILES_ERROR_MESSAGE= 'Admin has made changes to link settings. Please refresh the page.',
  INVITATION_SENT_LABEL = 'Invitation Sent!',
  LOGIN_INVALID_EMAIL = 'Invalid email address.',
  LOGIN_EMAIL_REQUIRED = 'Email is required.',
  LOGIN_PASSWORD_REQUIRED = 'Password required.',
  LOGIN_EMAIL_ADDRESS_LABEL = 'Email',
  LOGIN_PASSWORD_LABEL = 'Password',
  LOGIN_TEMPORARY_PASSWORD_LABEL = 'Temporary Password',
  LOGIN_NAME_REQUIRED = 'Name is required.',
  LOGIN_NAME_LABEL = 'First and Last Name',
  LOGIN_NAME_INVALID = 'Invalid first and last name.',
  CHANGE_EMAIL_REQUIRED = 'Email is required.',
  CHANGE_EMAIL_INVALID = 'Invalid email.',
  WARNING_TITLE_LABEL = '⚠',
  LOCKED_COLUMN_KANBAN = 'The column is locked, and can be only edited by Administrators.',
  INVALID_EMAIL_LABEL = 'Invalid Email',
  USER_ALREADY_EXISTS_LABEL = 'User already exists',
  TENANT_ADMIN_INVITE_PARAGRAPH = 'The role chosen for users will apply to all existing workspaces. Tenant Administrators will have access to newly created workspaces.',
  LOCKED_VIEW_MESSAGE = 'This view is locked. Only Administrators can edit locked views.',
  LOCKED_VIEW_MESSAGE_SAVEBUTTON = 'This view is locked. If you want to save this view, use the view menu to create a personal view.',
  ADMINISTRATOR_HAS_LOCKED_VIEW_MESSAGE = 'An Administrator has locked this view. Any changes you made can\'t be saved. Please refresh to see their changes.',
  USER_HAS_CHANGED_VIEW_MESSAGE = ' has made changes in page view. Refresh to see their updates.',
  PRIVATE_VIEW_MESSAGE = 'This view is private and available only to you.',
  DEFAULT_VIEW_MESSAGE = 'This view is visible to your team and edited by collaborators.',
  FILES_SESSION_ERROR_MESSAGE = 'The session has expired. Please click on refresh and try again.',
  TENANT_UPDATE_PEOPLE_NEGATION = '⚠ Tenant Administrator update',
  TENANT_UPDATE_PEOPLE_NEGATION_CONFIRM = 'Ok',
  LOGGED_USER_UPDATE_PEOPLE_NEGATION = '⚠ Logged user update',
  LOGGED_USER_PEOPLE_NEGATION_CONFIRM = 'Ok',
  GENERAL = 'General',
  EMAIL_NOTIFICATION_PREFERENCES='Email notification preferences',
  PASSWORD_MINIMUM_LENGTH = 'Minimum length of 8 characters',
  PASSWORD_LOWERCASE = 'Lowercase letters (a-z)',
  PASSWORD_UPPERCASE = 'Uppercase letters (A-Z)',
  PASSWORD_NUMBER = 'Numbers (0-9)',
  PASSWORD_CHAR = 'Special characters (e.g. %!@)',
  FAVORITES_NOT_FOUND_TITLE = 'Sorry, no matches were found in the Favorite Workspaces tab.',
  NOT_FOUND_TEXT = 'Sorry, no matches were found for your search term.',
  NOT_FOUND_TEXT_FAVORITE='Sorry, no matches were found in the Favorite Workspaces.',
  NONFAVORITES_NOT_FOUND_TITLE = 'Sorry, no matches were found for your search term in All Workspaces tab.',
  AUTH_SESSION_EXPIRED= 'Session is expired. Click the Resend Link',
  PAGE_NOT_FOUND= 'Page Not Found',
  PAGE_NOT_FOUND_TEXT='We cannot find the page you\'re looking for. You can either return to the previous page or go back to the homepage.'
}

export const PASSWORD_PATTERN_REQUIREMENTS_COUNT = 6;

export const PASSWORD_REGEX = {
  MINIMUM_LENGTH: /(?=.{8,})/,
  CHAR: /([^A-Za-z0-9])/,
  NUM: /(?=.*[0-9])/,
  UPPER: /(?=.*[A-Z])/,
  LOWER: /(?=.*[a-z])/,
  ALL: /(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])/,
};

export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum PASSWORD_ENUM {
  'ALL' = 'ALL',
  'CHAR' = 'CHAR',
  'NUMBER' = 'NUM',
  'UPPERCASE' = 'UPPER',
  'LOWERCASE' = 'LOWER',
  'MINIMUM_LENGTH' = 'MINIMUM_LENGTH',
}

export const INCLUDES_STRINGS = {
  EMAIL_ALREADY_EXIST: 'email already exists',
};

export const LOCAL_STORAGE_KEYS = {
  ACTIVITY_CHECK: 'activityCheck',
};

// Important:
//   - This list will be replaced(in Database, fileManager and taxOrganizer) if thirdParty.fileAllowedExtensionList exist in the tenant DynamoDB.
//   - If thirdParty.fileAllowedExtensionList exist on tenant DynamoDB: instead of add a new extension here, please add it there.
export const DEFAULT_FILE_ALLOWED_EXTENSION_LIST = [
  'bmp',
  'csv',
  'doc',
  'docx',
  'email',
  'jpeg',
  'jpg',
  'mp4',
  'msg',
  'pdf',
  'png',
  'pps',
  'ppt',
  'qba',
  'qbb',
  'qbj',
  'qbm',
  'qbo',
  'qbr',
  'qbw',
  'qbx',
  'qby',
  'qsm',
  'qss',
  'qst',
  'rtf',
  'tiff',
  'txt',
  'xls',
  'xlsm',
  'xlsx',
];

export const COGNITO_EXCEPTIONS_MESSAGES = {
  LimitExceededException: 'Max login attempts exceeded, please try again in 15 minutes.',
  ExpiredCodeException: 'Invalid code provided, please request a code again.',
};
