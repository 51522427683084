import React from 'react';
import _ from 'lodash';
import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import describeDiff from './describeDiff';
import ReferenceLink from './ReferenceLink';

const statusLabel = {
  [ActivityItemStatuses.CREATED]: 'posted',
  [ActivityItemStatuses.DELETED]: 'deleted',
  [ActivityItemStatuses.UPDATED]: 'updated',
};

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = _.get(activity, 'eventBy.displayName');
  const workspaceTitle = _.get(activity, 'references.workspace.title');
  const primaryFieldID = _.get(activity, 'references.primaryFieldID');
  const primaryFieldValue = _.get(activity, 'references.primaryFieldValue') || '<Empty Field Name>';

  return (
    <span>
      <strong className={'bold'}>{eventBy} </strong>
      {statusLabel[activity.action]} message in
      <ReferenceLink references={activity.references} type={ReferenceLinkType.MESSAGE}>
        {primaryFieldID? primaryFieldValue : workspaceTitle}
      </ReferenceLink>
    </span>
  );
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  if (activity.action === ActivityItemStatuses.CREATED) {
    return _.get(activity, 'meta.text');
  }
  if (activity.action === ActivityItemStatuses.UPDATED && activity.diff) {
    return (describeDiff(activity.diff));
  }
}


export default {
  describeActivity,
  describeActivityDetails,
};
