import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey = 'root' | 'title' | 'uploadIcon' | 'label' | 'disabled' | 'lockContainer' | 'uploadIconLocked';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    background: Colors.greyTransparent,
    cursor: 'pointer',
    width: '100%',
    borderRadius: '4px',
    transition: 'all 0.3s',
    textAlign: 'center',
  },
  title: {
    padding: '11px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    height: '16px',
    color: Colors.lightBlueGrey,
    marginRight: '7px',
  },
  label: {
    color: Colors.blueGrey,
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily,
    letterSpacing: 0.22,
  },
  disabled: {
    backgroundColor: '#CCC',
    cursor: 'default',
    opacity: 0.5,
  },
  lockContainer: {
    marginLeft: '12px',
  },
  uploadIconLocked: {
    color: '#333',
    width: '10px',
  },
};
