import { CreateFieldPayload } from 'data/collections/collections.reducer';
import { Choice, FieldType } from 'types/response/fieldNode';

import * as Data from './data';

export default function createFieldPayload(title: string, data: Data.FieldData, columnIndexInView?: number): CreateFieldPayload {
  const base = { title, columnIndexInView };

  switch (data.type) {
    case Data.FieldType.SingleLineText: {
      return {
        ...base,
        fieldType: FieldType.Singlelineoftext,
        default: data.defaultText,
      };
    }

    case Data.FieldType.LongText: {
      return {
        ...base,
        fieldType: FieldType.Multilinetext,
      };
    }

    case Data.FieldType.Attachment: {
      return {
        ...base,
        fieldType: FieldType.Attachment,
        showPreview: data.showPreview || false,
      };
    }

    case Data.FieldType.Checkbox: {
      return {
        ...base,
        fieldType: FieldType.Boolean,
        default: data.defaultValue,
      };
    }

    case Data.FieldType.MultiSelect: {
      return {
        ...base,
        default: data.defaultValue,
        fieldType: FieldType.Multiplechoice,
        choices: data.choices.reduce(reduceChoice, {}),
        choiceOrder: data.choices.map(getId),
        allowGridAddingOptions: data.allowGridAddingOptions || false,
      };
    }
    case Data.FieldType.SingleSelect: {
      return {
        ...base,
        default: data.defaultValue,
        fieldType: FieldType.Singlechoice,
        choices: data.choices.reduce(reduceChoice, {}),
        choiceOrder: data.choices.map(getId),
        allowGridAddingOptions: data.allowGridAddingOptions || false,
      };
    }

    case Data.FieldType.Person: {
      return {
        ...base,
        fieldType: FieldType.Account,
        allowMultiple: data.allowMultiple,
      };
    }

    case Data.FieldType.Date: {
      return {
        ...base,
        fieldType: FieldType.Date,
        dateFormat: data.dateFormat,
        includeTime: !!data.timeFormat,
        timeFormat: data.timeFormat,
      };
    }

    case Data.FieldType.Number: {
      const numberBase = {
        ...base,
        allowNegative: data.allowNegative,
        default: data.defaultNumber,
      };

      if (data.subtype === Data.NumberType.Float) {
        return {
          ...numberBase,
          fieldType: FieldType.Float,
          precision: getPrecision(data.precision),
        };
      }

      return {
        ...numberBase,
        fieldType: FieldType.Integer,
      };
    }

    case Data.FieldType.Currency: {
      return {
        ...base,
        allowNegative: data.allowNegative,
        fieldType: FieldType.Currency,
        precision: getPrecision(data.precision),
        currencySymbol: data.currencySymbol,
        default: data.defaultNumber,
      };
    }

    case Data.FieldType.Percent: {
      return {
        ...base,
        allowNegative: data.allowNegative,
        fieldType: FieldType.Percent,
        precision: getPrecision(data.precision),
        default: data.defaultNumber,
      };
    }

    case Data.FieldType.Rating: {
      return {
        ...base,
        fieldType: FieldType.Rating,
      };
    }

    case Data.FieldType.URL: {
      return {
        ...base,
        fieldType: FieldType.Url,
      };
    }

    case Data.FieldType.Email: {
      return {
        ...base,
        fieldType: FieldType.Email,
      };
    }

    case Data.FieldType.Button: {
      return {
        ...base,
        fieldType: FieldType.Button,
        action: data.action,
        label: data.label,
        additionalData: data.additionalData,
      };
    }

    case Data.FieldType.Phone: {
      return {
        ...base,
        fieldType: FieldType.Phone,
      };
    }
  }
}

type Choices = Record<string, Choice>;

function reduceChoice(record: Choices, choice: Data.ChoiceOption): Choices {
  return { ...record, [choice.id]: choice.value };
}

function getId(choice: Data.ChoiceOption): string {
  return choice.id;
}

export function getPrecision(precision: Data.Precision): number {
  return precision === Data.Precision.A || !precision ? 0 : precision.split('.')[1].length;
}
