import { MuiThemeProvider } from '@material-ui/core';
import * as React from 'react';

import { muiTheme } from 'styles/theme';
import { Theme } from 'types/response/tenantNode';

interface Props {
  theme?: Theme;
  children: React.ReactNode;
}

// TODO: Wire up with styled-components theme provider or remove styled-components
export default function ThemeProvider(props: Props): JSX.Element {
  const theme = muiTheme(props.theme);

  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  );
}
