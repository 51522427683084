
import { ReactComponent as AccountIcon } from 'icons/field-types/account.svg';
import { ReactComponent as AttachmentIcon } from 'icons/field-types/attachment.svg';
import { ReactComponent as BarcodeIcon } from 'icons/field-types/barcode.svg';
import { ReactComponent as ButtonIcon } from 'icons/field-types/button.svg';
import { ReactComponent as CheckboxIcon } from 'icons/field-types/checkbox.svg';
import { ReactComponent as CurrencyIcon } from 'icons/field-types/currency.svg';
import { ReactComponent as DateIcon } from 'icons/field-types/date.svg';
import { ReactComponent as DocumentSize } from 'icons/field-types/document-size.svg';
import { ReactComponent as DocumentType } from 'icons/field-types/document-type.svg';
import { ReactComponent as EmailIcon } from 'icons/field-types/email.svg';
import { ReactComponent as FavoriteIcon } from 'icons/field-types/favorite.svg';
import { ReactComponent as MultilineTextIcon } from 'icons/field-types/multiline-text.svg';
import { ReactComponent as MultipleChoiceIcon } from 'icons/field-types/multiple-choice.svg';
import { ReactComponent as NumberIcon } from 'icons/field-types/number.svg';
import { ReactComponent as PercentIcon } from 'icons/field-types/percent.svg';
import { ReactComponent as PhoneIcon } from 'icons/field-types/phone.svg';
import { ReactComponent as RatingIcon } from 'icons/field-types/rating.svg';
import { ReactComponent as SingleChoiceIcon } from 'icons/field-types/single-choice.svg';
import { ReactComponent as SingleLineOfTextIcon } from 'icons/field-types/single-line-of-text.svg';
import { ReactComponent as TagIcon } from 'icons/field-types/tag.svg';
import { ReactComponent as UrlIcon } from 'icons/field-types/url.svg';
import { SvgIcon } from 'types/common';
import { FieldType } from 'types/response/fieldNode';

// TODO find better place for this mapping to be used by grid header and in other places
// move as soon as we refactor FieldTypesSelect
type ColumnTypesIcons = {
  [colType in FieldType]?: SvgIcon | null;
};

const columnTypesIcons: ColumnTypesIcons = {
  [FieldType.Account]: AccountIcon,
  [FieldType.Barcode]: BarcodeIcon,
  [FieldType.Boolean]: CheckboxIcon,
  [FieldType.Bookmarked]: RatingIcon,
  [FieldType.Checklist]: SingleChoiceIcon,
  [FieldType.Currency]: CurrencyIcon,
  [FieldType.Date]: DateIcon,
  [FieldType.DateTime]: DateIcon,
  [FieldType.Attachment]: AttachmentIcon,
  [FieldType.Email]: EmailIcon,
  [FieldType.FileExtension]: DocumentType,
  [FieldType.FileName]: SingleLineOfTextIcon,
  [FieldType.FileSize]: DocumentSize,
  [FieldType.Float]: NumberIcon,
  [FieldType.Integer]: NumberIcon,
  [FieldType.Location]: null,
  [FieldType.Multiplechoice]: MultipleChoiceIcon,
  [FieldType.Dropdown]: MultipleChoiceIcon,
  [FieldType.Multilinetext]: MultilineTextIcon,
  [FieldType.Percent]: PercentIcon,
  [FieldType.Phone]: PhoneIcon,
  [FieldType.Rating]: RatingIcon,
  [FieldType.Singlechoice]: SingleChoiceIcon,
  [FieldType.Singlelineoftext]: SingleLineOfTextIcon,
  [FieldType.Tag]: TagIcon,
  [FieldType.Url]: UrlIcon,
  [FieldType.Favorite]: FavoriteIcon,
  [FieldType.Button]: ButtonIcon,
  [FieldType.Status]: DateIcon,
};

export function getColumnTypeIcon(columnType: FieldType): SvgIcon | null {
  return columnTypesIcons[columnType] || null;
}
