import styled from 'styled-components';
import Menu from 'components/ui/Menu';
import { MenuItemIcon } from 'components/ui/Menu/Menu.style';

export const ExportMenuItemIcon = styled(MenuItemIcon)`
  font-size: 18px
`;

export const ExportMenu = styled(Menu).attrs({
  MenuListProps: {
    style: { width: 140 },
  },
})``;
