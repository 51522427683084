import { createSelector } from 'reselect';

import { GridSelectionState } from './gridSelection.types';
import { nodes } from 'data/collections/collections.selectors';
import { DocumentNode } from 'types/response/documentNode';

export const gridSelection = (state): GridSelectionState => state.ui.gridSelection;

export const getSelectedItems = (state): DocumentNode[] => gridSelection(state).selectedItems;

export const isSelected = (state, { id }): boolean => gridSelection(state).selectedItems.some(item => item.id === id);

export const getSelectedItemsCount = (state): number => {
  const gs = gridSelection(state);
  return gs ? gs.selectedItems.length : 0;
};

export const selecteItemNodeSelector = createSelector(
  [nodes, getSelectedItems],
  (nodes, selectedItems): DocumentNode | undefined => {
    const selectedItem = selectedItems[0];
    if (selectedItem) {
      return (nodes as DocumentNode[]).find(node => node.id === selectedItem.id);
    }
  },
);
