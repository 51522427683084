import withStyles from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

export type ClassKey =
  | 'wrapper'
  | 'fieldTypeSelect'
  | 'label'
  | 'labelIcon'
  | 'fieldMetadata'
  | 'confirmSaveLabel'
  | 'disableModifyModal'
  | 'disableModifyModalCheckbox';

export default withStyles<ClassKey>({
  wrapper: {
    width: 300,
    maxHeight: 320,
    backgroundColor: Colors.white,
    borderRadius: 4,
    boxShadow:
      '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    padding: '8px 10px 11px',
    overflow: 'inherit',
  },
  fieldTypeSelect: { marginTop: 5 },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: 5,
    width: 10,
    height: 10,
  },
  fieldMetadata: { marginTop: 5 },
  confirmSaveLabel: {
    fontSize: '14px',
    color: Colors.brownishGrey,
  },
  disableModifyModal: {
    display: 'flex',
    marginTop: '20px',
    cursor: 'pointer',
  },
  disableModifyModalCheckbox: {
    marginRight: '10px',
  },
});
