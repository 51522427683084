import React from 'react';
import _ from 'lodash';
import Typography, { Variant } from '../../../Typography';

const opLabel = {
  'update': 'Changed',
  'add': 'Added',
  'delete': 'Deleted',
};

const renderActivity = (change) => {
  switch (change.op) {
    case 'add':
      return renderAdd(change);
    case 'update':
      return renderUpdate(change);
    case 'delete':
      return renderDelete(change);
    default:
      return;
  }
};


const describeDiff = (diff) => diff.map((change) => {
  if (change.val instanceof Object) return;
  if (change.oldVal && change.oldVal instanceof Object) return;
  return (
    <>
      <span>{opLabel[change.op]} </span>
      {renderActivity(change)}
    </>
  );
});

const renderAdd = (change) => (
  <>
    <Typography variant={Variant.ItalicTitle}><span> {_.get(change, 'path[0]')} </span></Typography>
    with the value
    <Typography variant={Variant.ItalicTitle}><span> {JSON.stringify(change.val) } </span></Typography>
    <br />
  </>
);

const renderUpdate = (change) => (
  <>
    value of
    <Typography variant={Variant.ItalicTitle}><span> {_.get(change, 'path[0]')} </span></Typography>
    from
    <Typography variant={Variant.ItalicTitle}><span> {change.oldVal} </span></Typography>
    to
    <Typography variant={Variant.ItalicTitle}><span> {change.val} </span></Typography>
    <br />
  </>
);

const renderDelete = (change) => (
  <>
    value
    <Typography variant={Variant.ItalicTitle}><span> {_.get(change, 'path[0]')}</span></Typography>
    <br />
  </>
);

export default describeDiff;
