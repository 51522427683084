import {
  Actions as CollectionsActions,
  copyCollectionRequest,
  copyCollectionSuccess,
} from 'data/collections/collections.actions.new';
import { actions as databaseActions } from 'data/databases/databases.actions';
import LoadingState from 'data/LoadingState';
import { actions as viewsActions } from 'data/views/views.actions';
import produce from 'immer';
import { SheetNode } from 'types/response/sheetNode';
import { CollectionTypes } from 'types/schema';
import { ActionType, getType } from 'typesafe-actions';
import { groupById } from 'utilities/collections';

import { actions, Actions as SheetsActions } from './sheets.actions';


export interface SheetsState {
  current: string | null;
  currentId: string | null;
  byId: Record<string, SheetNode>;
  ids: string[];
  loadingState: LoadingState;
  isEditing: boolean;
}

export const initialState: SheetsState = {
  current: null,
  currentId: null,
  byId: {},
  ids: [],
  loadingState: LoadingState.Unloaded,
  isEditing: false,
};

type Actions =
  | SheetsActions
  | CollectionsActions
  | ActionType<typeof databaseActions.setCurrentDatabase>
  | ActionType<typeof viewsActions>;

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.cleanSheet):
      return initialState;
    case getType(actions.fetchDatabaseSheets.request): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    case getType(actions.fetchDatabaseSheets.success): {
      return produce(state, draft => {
        const { byId, ids } = groupById(action.payload.sheets);

        draft.byId = byId;
        draft.ids = ids;
        draft.loadingState = LoadingState.Loaded;
      });
    }
    case getType(actions.createSheetRequest.request): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    case getType(actions.createSheetRequest.success): {
      const { sheet } = action.payload;

      return produce(state, draft => {
        // @ts-ignore
        draft.byId[sheet.id] = sheet;
        // @ts-ignore
        draft.ids.push(sheet.id);
        draft.loadingState = LoadingState.Loaded;
        draft.isEditing = true;
      });
    }
    case getType(actions.renameSheet.success): {
      return produce(state, draft => {
        // @ts-ignore
        draft.byId[action.payload.id] = action.payload;
      });
    }
    case getType(actions.deleteSheet.request): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    case getType(actions.deleteSheet.success): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loaded;
        delete draft.byId[action.payload];
        // @ts-ignore
        draft.ids = state.ids.filter(id => id !== action.payload);
      });
    }
    case getType(actions.reorderSheets.request): {
      return produce(state, draft => {
        // @ts-ignore
        draft.ids = action.payload.sheetIdOrder;
      });
    }
    case getType(actions.startEditing): {
      return produce(state, draft => {
        draft.isEditing = true;
      });
    }
    case getType(actions.renameSheet.request):
    case getType(actions.stopEditing): {
      return produce(state, draft => {
        draft.isEditing = false;
      });
    }
    case getType(actions.setCurrentSheet): {
      return produce(state, draft => {
        draft.current = action.payload;
      });
    }
    case getType(actions.setCurrentSheetId): {
      return produce(state, draft => {
        draft.currentId = action.payload;
      });
    }
    case getType(actions.selectSheet): {
      return produce(state, draft => {
        draft.current = action.payload.id;
      });
    }
    case getType(copyCollectionRequest): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    case getType(copyCollectionSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.sheets) {
          const { payload: sheet } = action;
          // @ts-ignore
          draft.byId[sheet.id] = sheet as SheetNode;
          // @ts-ignore
          draft.ids.push(sheet.id);
          draft.loadingState = LoadingState.Loaded;
        }
      });
    }
    case getType(databaseActions.setCurrentDatabase): {
      return produce(state, draft => {
        draft.byId = {};
        draft.ids = [];
        draft.current = null;
        if (draft.loadingState !== LoadingState.Loading) {
          draft.loadingState = LoadingState.Unloaded;
        }
      });
    }
    case getType(viewsActions.changeViewsOrder.success): {
      return produce(state, draft => {
        const { payload: sheet } = action;
        // These ts-ignores are needed because the type of the state is defined as string[]
        // and we are trying to update to a different type, in this case draft: SheetNode
        // @ts-ignore
        draft.byId[sheet._id].version = sheet.version;
        // @ts-ignore
        draft.byId[sheet._id].viewIDOrder = sheet.viewIDOrder;
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
