import * as React from 'react';

import { ButtonProps, Variant } from 'components/Button';
import ModalContent from 'components/Modals/Modal/Content';
import ModalCustomActions from 'components/Modals/Modal/CustomActions';
import ModalHeader from 'components/Modals/Modal/Header';
import * as _ from 'lodash/fp';
import styled from 'styled-components';

import Switch from '../../Switch';
import ModalTextField from '../ModalTextField';

interface Props {
  currentValue: string;
  existingValues: string[];
  close: () => void;
  onUpdate: (newValue: string, isPrivate?: boolean, isLocked?: boolean, isDefault? : boolean) => void;
  onDelete?: () => void;
  windowTitle?: string;
  valueTitle?: string;
  submitButton?: string;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  canBePrivate?: boolean;
  fileType?: string;
  fileExtension?: string;
  permissions?: string[];
  showExtension?: boolean;
}

interface State {
  value: string;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  errorMessage: string;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileExtension = styled.span`
  margin-left: 15px;
`;

export class Rename extends React.Component<Props, State> {
  private existingValues: string[];

  public constructor(props: Props) {
    super(props);
    this.existingValues = this.props.existingValues.filter(
      (value) => value !== this.props.currentValue,
    );
    this.state = {
      value: !_.isEmpty(props.fileType)
        ? props.currentValue.substring(0, props.currentValue.lastIndexOf('.'))
        : props.currentValue,
      isPrivate: props.isPrivate,
      isLocked: props.isLocked,
      isDefault: props.isDefault,
      errorMessage: '',
    };
  }

  public componentDidUpdate = (prevProps: Readonly<Props>): void => {
    if (
      !_.equals(prevProps.existingValues, this.props.existingValues) ||
      prevProps.currentValue !== this.props.currentValue
    ) {
      this.existingValues = this.props.existingValues.filter(
        (value) => value !== this.props.currentValue,
      );
    }
  };

  public render = (): JSX.Element => {
    const {
      close,
      windowTitle,
      onDelete,
      canBePrivate,
      submitButton,
      permissions,
      fileExtension,
      showExtension = false,
      isDefault: currentViewIsDefault,
    } = this.props;
    const { value, errorMessage, isPrivate, isLocked, isDefault } = this.state;
    const hasError = !!errorMessage;
    const canLockView = permissions?.includes('views:lock');
    const canSetDefault = permissions?.includes('views:lock');

    const leftButtons: ButtonProps[] = [
      {
        label: 'Cancel',
        variant: Variant.SecondaryLink,
        onClick: close,
      },
    ];

    const rightButtons: ButtonProps[] = [];
    if (onDelete && !currentViewIsDefault) {
      rightButtons.push({
        label: 'Delete',
        variant: Variant.SecondaryLink,
        onClick: this.handleDeleteClick,
      });
    }
    rightButtons.push({
      label: submitButton || 'Rename',
      variant: Variant.Primary,
      onClick: this.handleUpdateClick,
      disabled: hasError,
    });

    return (
      <>
        <ModalHeader onClose={close}>{windowTitle || 'Rename'}</ModalHeader>
        <ModalContent>
          <InputWrapper>
            <ModalTextField
              value={value}
              errorMessage={errorMessage}
              onChange={this.handleValueChanged}
              autoSelect
              autoFocus
            />
            {showExtension && <FileExtension>{fileExtension}</FileExtension>}
          </InputWrapper>
          {canBePrivate && (
            <Switch
              label='Personal view'
              selected={isPrivate}
              onClick={this.handlePrivateChanged}
              className={'modal-view-switch'}
              labelRight
              disabled={isLocked || isDefault}
            />
          )}
          {canLockView && (
            <Switch
              label='Lock view'
              selected={isLocked}
              onClick={this.handleLockChanged}
              className={'modal-view-switch'}
              labelRight
              disabled={isPrivate}
            />
          )}
          {canSetDefault && (
            <Switch
              label='Default view'
              selected={isDefault}
              onClick={this.handleDefaultChanged}
              className={'modal-view-switch'}
              labelRight
              disabled={isPrivate || currentViewIsDefault}
            />
          )}
        </ModalContent>
        <ModalCustomActions
          leftButtons={leftButtons}
          rightButtons={rightButtons}
        />
      </>
    );
  };

  private handlePrivateChanged = () => {
    this.setState({
      isPrivate: !this.state.isPrivate,
    });
  };

  private handleLockChanged = () => {
    this.setState({
      isLocked: !this.state.isLocked,
    });
  };

  private handleDefaultChanged = () => {
    this.setState({
      isDefault: !this.state.isDefault,
    });
  };

  private handleValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { valueTitle } = this.props;
    const valueNameAsLow = _.toLower(valueTitle || 'value');
    const valueNameAsCap = _.capitalize(valueNameAsLow);
    const value = event?.target?.value.trimStart().replace(/ +(?= )/g, '');
    this.setState({ value });
    if (value === '') {
      this.setState({ errorMessage: `Please provide a ${valueNameAsLow}.` });
    } else if (this.existingValues.includes(value.trim())) {
      this.setState({
        errorMessage: `${valueNameAsCap} already exists. Please enter unique ${valueNameAsLow}.`,
      });
    } else {
      this.setState({ errorMessage: '' });
    }
  };

  private handleDeleteClick = (): void => {
    const { onDelete, close } = this.props;
    if (onDelete !== undefined) {
      onDelete();
      close();
    }
  };

  private handleUpdateClick = (): void => {
    const { onUpdate, close, fileType, fileExtension } = this.props;
    const { value, isPrivate, isLocked, isDefault } = this.state;
    const hasError = !!this.state.errorMessage;
    const newFileName: string = !_.isEmpty(fileType)
      ? value.trim().concat(`.${fileExtension as string}`)
      : value.trim();
    if (!hasError) {
      onUpdate(newFileName, isPrivate, isLocked, isDefault);
      close();
    }
  };
}

export default Rename;
