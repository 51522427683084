import React, { useEffect, useState } from 'react';

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { xorBy, find, findIndex, inRange } from 'lodash';

import { FixedSizeList as List } from 'react-window';
import { styles } from './RoleBox.styles';

export interface BoxItem {
  id: string;
  title: string;
  index?: number;
  meta?: {
    clientName: string;
    office: string;
  };
}

export interface RoleBoxProps extends WithStyles<typeof styles> {
  items: BoxItem[];
  selectedItems?: BoxItem[];
  title: string;
  id: string;
  onSelectionChanged: (items: BoxItem[]) => void;
}

export const ItemTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 'none',
    zIndex: 9999,
    fontSize: '1rem',
  },
}))(Tooltip);

function RoleBox({
  items,
  title,
  selectedItems,
  id,
  onSelectionChanged,
  classes,
}: RoleBoxProps): React.ReactElement<RoleBoxProps> {
  const [selected, setSelected] = useState<BoxItem[]>(selectedItems || []);
  const [lastItemSelected, setLastItemSelected] = useState<BoxItem | null>(null);
  const [lastItemShiftSelected, setLastItemShiftSelected] = useState<BoxItem | null>(null);
  const [lastItemsSelected, setLastItemsSelected] = useState<BoxItem[] | null>(null);

  const handleSelect = (item: BoxItem, itemIndex: number) => {
    const newSelected = xorBy(selected, [item], 'id');
    setLastItemSelected({ ...item, index: itemIndex });
    setLastItemShiftSelected(null);
    setLastItemsSelected(null);
    setSelected(newSelected);
    onSelectionChanged(newSelected);
  };

  const checkItemIsBetween = (item: BoxItem) => {
    const selectedClone = [...selected];
    const firstItemIndex = findIndex(items, (_item: BoxItem) => _item.id === selectedClone[0].id);
    const lastItemIndex = findIndex(items, (_item: BoxItem) => _item.id === selectedClone[selectedClone.length - 1].id);
    const currentItemIndex = findIndex(items, (_item: BoxItem) => _item.id === item.id);
    return inRange(currentItemIndex, firstItemIndex, lastItemIndex);
  };

  const applyBulkSelection = (referenceItem: BoxItem, currentItem, currentItemIndex) => {
    if (referenceItem && referenceItem.index !== undefined) {
      const startIndex = referenceItem.index < currentItemIndex ? referenceItem.index : currentItemIndex;
      const endIndex = referenceItem.index > currentItemIndex ? referenceItem.index : currentItemIndex;
      const bulkSelectedItems = items.slice(startIndex, endIndex+1);
      const newSelected = [referenceItem, ...xorBy(selected, bulkSelectedItems, 'id')];
      setLastItemsSelected(bulkSelectedItems);
      setLastItemShiftSelected({ ...currentItem, index: currentItemIndex });
      setSelected(newSelected);
      onSelectionChanged(newSelected);
    }
  };

  const handleSelectWithShift = (item: BoxItem, itemIndex: number) => {
    const isItemBetween = checkItemIsBetween(item);
    const isItemAlreadySelected = selected.some((selectedItem) => selectedItem.id === item.id);
    const isItemSameAsLastItemShiftSelected = lastItemShiftSelected?.index === itemIndex;

    if ((isItemBetween && lastItemShiftSelected) || (isItemAlreadySelected && !isItemSameAsLastItemShiftSelected)) handleSelect(item, itemIndex);
    else {
      if (lastItemShiftSelected) {
        if (isItemSameAsLastItemShiftSelected) {
          const newSelected = [lastItemSelected, ...xorBy(selected, lastItemsSelected, 'id')];
          setLastItemsSelected(null);
          setLastItemShiftSelected(null);
          setSelected(newSelected);
          onSelectionChanged(newSelected);
        } else {
          applyBulkSelection(lastItemShiftSelected, item, itemIndex);
        }
      } else {
        if (lastItemSelected && lastItemSelected.index !== undefined) {
          applyBulkSelection(lastItemSelected, item, itemIndex);
        }
      }
    }
  };

  const Row = ({ index, style }) => {
    const item = items[index];
    return (
      <ItemTooltip title={item.meta ? item.meta.clientName : item.title} key={`${item.id}Tooltip`} style={style}>
        <Typography
          key={item.id}
          classes={{
            root: find(selected, ['id', item.id])
              ? classes.selectedBoxItem
              : classes.boxItem,
          }}
          onClick={(e) => {
            if (e.shiftKey) handleSelectWithShift(item, index);
            else handleSelect(item, index);
          }}
        >
          {item.meta?.clientName?.replace(/amp;/g, '') || item.title.replace(/amp;/g, '')}
        </Typography>
      </ItemTooltip>
    );
  };

  useEffect(() => {
    setSelected(selectedItems || []);
  }, [selectedItems]);

  return (
    <Grid className={classes.rectangle}>
      <Grid
        item
        xs
        container
        direction="column"
        classes={{
          container: classes.container,
        }}
      >
        <Grid
          onDoubleClick={()=> {
            const newSelected = selected.length ? [] : items;
            setSelected(newSelected);
            onSelectionChanged(newSelected);
          }}
          classes={{
            item: classes.boxTitleContainer,
          }}
          item
        >
          <h2 className={classes.boxTitle}>{title}</h2>
        </Grid>
        <Grid item>
          <div>
            {items.length > 0 ? (
              <List
                className="List"
                height={145}
                itemCount={items.length}
                itemSize={24}
                width={166}
              >
                {Row}
              </List>
            ) : (
              <Typography
                id={`${id}-none`}
                classes={{
                  root: classes.boxItem,
                }}
              >

              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(RoleBox);
