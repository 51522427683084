import { getType } from 'typesafe-actions';
import produce from 'immer';
import { openModal, closeModal, Actions } from 'data/ui/modal/modal.actions';
import { ModalNames } from 'data/ui/modal/modal.types';


export interface UiModalState {
  modals: { [name in ModalNames]?: boolean };
}

export const initialState: UiModalState = {
  modals: {},
};

const reducer = (state = initialState, action: Actions): UiModalState => {
  switch (action.type) {
    case getType(openModal): {
      return produce(state, draft => {
        draft.modals[action.payload] = true;
      });
    }

    case getType(closeModal): {
      return produce(state, draft => {
        draft.modals[action.payload] = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default reducer;
