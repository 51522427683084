import { renderToast } from 'components/Toast/NotificationToast';
import { mapViewResponse } from 'data/collections/collections.mapper';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { actions as gridEditorActions } from 'data/grid-editors';
import { updateView } from 'data/views/views.actions';
import { selectedView } from 'data/views/views.selectors';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createViewsUrl } from 'utilities/createUrl';
import areEqualShallow from 'utilities/shallow-compare';

import { AppMessages } from '../../../constants';
class ViewEntity {
  updated(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentViewId = state.collections.selectedViewId;
    const currentView = _.omit(selectedView(state), ['query.count']);
    let { eventBy, item } = _.pick(data, ['eventBy', 'item']);
    const eventUser = _.get(state, `app.accounts.byId.${eventBy}`);
    let message = '';

    item = _.omit(item, ['query.count']);

    const { user } = state.users;
    if (!user) {
      return;
    }

    if (user.id === eventBy) {
      return;
    }

    if (!currentViewId) {
      return;
    }

    if (currentViewId !== item.id) {
      return;
    }
    const defaultSheetId = state.sheets.currentId || '';
    const viewPatchUrl = `${createViewsUrl(state.router.location, defaultSheetId, currentView.id)}?api_version=2`;
    const result = mapViewResponse(
      {
        body: item,
      },
      viewPatchUrl,
      currentView,
    );

    // map ag grid auto generated column for comparison
    result.query.fieldIDs = result.query.fieldIDs.map((fieldId) => {
      if (fieldId.includes('ag-Grid-AutoColumn') && !fieldId.startsWith('fields.')) {
        return `fields.${fieldId}`;
      }

      return fieldId;
    });
    const diff = _.omitBy(areEqualShallow(currentView, result), (prop) =>
      [
        'createdAt',
        'createdBy',
        'modifiedAt',
        'modifiedBy',
        'version',
        'apiURI',
      ].includes(prop),
    );
    const diffSize = _.size(diff);
    if (diffSize === 0) {
      return;
    }

    if (diffSize === 1) {
      const keyChanged = Object.values(diff)[0];
      switch (keyChanged) {
        case 'customRowOrder':
          store.dispatch(
            viewConfigActions.setCustomRowOrder(item.customRowOrder),
          );
          store.dispatch(updateView.success(result, viewPatchUrl, currentView));
          // @typescript-eslint/no-var-requires
          const { DataGrid } = require('components/DataGrid'); // eslint-disable-line
          setTimeout(() => {
            const agGridAPI = DataGrid.getGridApi();
            if (agGridAPI) {
              agGridAPI.refreshCells(['row_number_id']);
            }
          }, 100);
          break;

        case 'isLocked':
          if (!currentView.isLocked) {
            this.showToast('warn', AppMessages.ADMINISTRATOR_HAS_LOCKED_VIEW_MESSAGE);
            break;
          }
          // falls through
        default:
          message = eventUser.displayName + AppMessages.USER_HAS_CHANGED_VIEW_MESSAGE;
          this.showToast('info', message);

          break;
      }
    } else {
      message = eventUser.displayName + AppMessages.USER_HAS_CHANGED_VIEW_MESSAGE;
      this.showToast('info', message);
    }
  }

  VIEW_LOCKED(data) {
    const store = window.appStore;
    const state = store.getState();
    const { user } = state.users;
    const { eventBy } = _.pick(data, ['eventBy']);

    if (!user) {
      return;
    }

    if (user.id !== eventBy) {
      return;
    }

    this.showToast('warn', AppMessages.ADMINISTRATOR_HAS_LOCKED_VIEW_MESSAGE);
  }

  showToast(type, message) {
    toast(
      (props) =>
        renderToast({
          message: message,
          closeToast: props.closeToast,
          onButtonClick: () => window.location.reload(),
          type: type,
          buttonLabel: 'Refresh',
        }),
      {
        bodyClassName: 'notification-toastify-body',
        progressClassName: 'notification-toastify-body-progress',
        className: `notification-toastify nt-${type}`,
        hideProgressBar: true,
        closeButton: false,
      },
    );
  }

  GRID_CELL_EDITING_STARTED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentViewId = state.collections.selectedViewId;

    const { eventBy, item } = _.pick(data, ['eventBy', 'item']);
    const viewID = _.get(item, 'references.viewID');
    const { user } = state.users;
    if (!user) {
      return;
    }

    if (user.id === eventBy) {
      return;
    }

    if (!currentViewId) {
      return;
    }

    if (currentViewId !== viewID) {
      return;
    }
    const eventUser = _.get(state, `app.accounts.byId.${eventBy}`);
    // TODO CLEAR EDITORS ON NAVIGATION EVENT
    store.dispatch(
      gridEditorActions.setCellUserEditor({
        itemId: item.itemId,
        columnKey: item.columnKey,
        userId: eventBy,
        userDisplayName: eventUser.displayName,
        color: eventUser.backgroundColor,
      }),
    );
    // setTimeout(() => {
    //   store.dispatch(gridEditorActions.removeCellUserEditor({
    //     itemId: item.itemId,
    //     columnKey: item.columnKey,
    //     userId: eventBy,
    //   }));
    // }, 10000);
  }

  GRID_CELL_EDITING_STOPPED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentViewId = state.collections.selectedViewId;

    if (state.users?.user?.id === data.eventBy) {
      return;
    }

    if (!currentViewId || currentViewId !== data.item?.references?.viewID) {
      return;
    }

    store.dispatch(
      gridEditorActions.removeCellUserEditor({
        itemId: data.item.itemId,
        columnKey: data.item.columnKey,
        userId: data.eventBy,
      }),
    );
  }
}

export default new ViewEntity();
