import './FileManager.styles.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { compose } from 'recompose';
import { State } from 'reducers';
import HubsyncFileManager, { HubsyncAjaxSettings } from '@hubsync/file-manager';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { getAuthToken } from '../../utilities/getAuthHeaders';
import { getFileProviderApiEndpoint, getOrigin } from 'env';
import { bookmarks as bookmarksSelector } from 'data/bookmarks/bookmarks.selectors';
import { deleteBookmark, saveBookmark } from 'data/bookmarks/actions';

import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import {
  getCurrentWorkspaceId,
  getWorkspacePermissions,
} from 'data/workspaces/workspaces.selectors';
import { DEFAULT_FILE_ALLOWED_EXTENSION_LIST } from '../../constants';
import { getAllowedExtensionsString } from '../../utilities/common';

interface OptionalProps {
  permissions?: string[];
  token?: string;
  workspaceId?: string;
  isShared?: boolean;
  filePath?: string;
}

type Props = URLInjectedProps & typeof withURLParams & OptionalProps;

export const FileManagerPage = (props: Props): React.ReactElement => {
  const baseUrl = getFileProviderApiEndpoint();
  const dispatch = useDispatch();
  const workspaceId: string = useSelector(
    (state: State) => props.workspaceId || getCurrentWorkspaceId(state),
  );
  const permissions: string[] = useSelector(
    (state: State) => props.permissions || getWorkspacePermissions(state),
  );
  const bookmarks = useSelector(bookmarksSelector);

  const tenant = window.appStore.getState().app.tenant;

  const ajaxSettings: HubsyncAjaxSettings = props.isShared
    ? {
      readUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files`,
      uploadUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/upload`,
      downloadUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/download`,
      renameUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files`,
      copyUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/copy`,
      moveUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/move`,
      deleteUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files`,
      detailsUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/details`,
      groupNotificationUrl: `${baseUrl}/public/workspaces/${workspaceId}/shared-files/group-notification`,
    }
    : {
      readUrl: `${baseUrl}/workspaces/${workspaceId}/files`,
      uploadUrl: `${baseUrl}/workspaces/${workspaceId}/files/upload`,
      downloadUrl: `${baseUrl}/workspaces/${workspaceId}/files/download`,
      renameUrl: `${baseUrl}/workspaces/${workspaceId}/files`,
      copyUrl: `${baseUrl}/workspaces/${workspaceId}/files/copy`,
      moveUrl: `${baseUrl}/workspaces/${workspaceId}/files/move`,
      deleteUrl: `${baseUrl}/workspaces/${workspaceId}/files`,
      detailsUrl: `${baseUrl}/workspaces/${workspaceId}/files/details`,
      groupNotificationUrl: `${baseUrl}/workspaces/${workspaceId}/files/group-notification`,
      linksUrl: `${baseUrl}/workspaces/${workspaceId}/files/`,
    };

  const [token, setToken] = useState(props.token);

  const canCreate = permissions.includes('files:create');
  const canUpdate = permissions.includes('files:update');
  const canList = permissions.includes('files:list');
  const canDelete = permissions.includes('files:delete');
  const canDetails = permissions.includes('files:get');

  useEffect(() => {
    if (!token) {
      const getToken = async () => setToken(await getAuthToken());
      getToken();
    }
  }, []);

  const handleError = (error: Error) => {
    dispatch(notifications.error({ message: error.message }));
  };

  const handleToast = (message: string) => {
    dispatch(notifications.info({ message }));
  };

  const handleSaveBookmark = (node) => {
    dispatch(
      saveBookmark({
        bookmarkMeta: {
          title: node.name,
          model: 'file-manager',
          recordID: node.fileId,
          references: {
            ...node,
          },
        },
      }),
    );
  };
  const handleDeleteBookmark = (bookmarkID: string, recordID: string) => {
    dispatch(deleteBookmark({ bookmarkID, recordID }));
  };

  const getFilePath = () =>{
    const params = queryString.parse(props.location.search);
    return params.path ? params.path : undefined;
  };

  return (
    <div className="file-manager-wrapper">
      <div className="file-manager-header-wrapper">
        <span className="file-manager-header-title">Files</span>
      </div>
      {token ? (
        <HubsyncFileManager
          hubsyncAjaxSettings={ajaxSettings}
          authToken={token}
          showSearchBar={canList}
          showNewFolderButton={canCreate}
          showUploadButton={canCreate}
          /** Given our permission system it's not possible to have a files:download
           * permission valid for the configured URL so for now we are working with
           * the files:create permission to validate users than can download */
          showDownloadButton={canCreate || canDetails}
          showRenameButton={canUpdate}
          showDeleteButton={canDelete}
          showDetailsButton={canDetails}
          showActionLink={canCreate && !props.isShared}
          allowDragAndDrop={canCreate}
          showCopyButton={canCreate}
          showCutButton={canCreate}
          showPasteButton={canCreate}
          onError={handleError}
          handleToast={handleToast}
          origin={getOrigin()}
          isShared={props.isShared}
          filePath={props.filePath}
          path={getFilePath()}
          bookmarks={bookmarks}
          handleSaveBookmark={handleSaveBookmark}
          handleDeleteBookmark={handleDeleteBookmark}
          viewType={tenant.thirdParty.fileManagerView}
          fileAllowedExtensionList={getAllowedExtensionsString(tenant?.thirdParty?.fileAllowedExtensionList || DEFAULT_FILE_ALLOWED_EXTENSION_LIST)}
        ></HubsyncFileManager>
      ) : (
        <></>
      )}
    </div>
  );
};

const enhace = compose<Props, {}>(withURLParams);

export default enhace(FileManagerPage);
