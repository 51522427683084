import * as React from 'react';
import { connect } from 'react-redux';
// import CrashReporter from '../../utilities/crashReporter';
import { notifications } from 'data/ui/notifications/notifications.actions';
import ErrorContent from './ErrorContent';

interface Props {
  children: React.ReactNode;
  showErrorNotification: typeof notifications.error;
}

interface State {
  hasError: boolean;
}

class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // componentDidMount() {
  //   window.addEventListener('error', this.handleError);
  // }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  // componentWillUnmount() {
  //   window.removeEventListener('error', this.handleError);
  // }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContent/>
      );
    }
    return this.props.children;
  }

  private handleError = (error: ErrorEvent) => {
    // CrashReporter.reportError({
    //   file: error.filename,
    //   error: error.message,
    // });
    this.props.showErrorNotification({
      message: 'Something went wrong',
    });
  };
}

const mapDispatchToProps = {
  showErrorNotification: notifications.error,
};

export default connect(null, mapDispatchToProps)(AppErrorBoundary);
