import sevenZ from 'icons/file-extensions/7z.svg';
import ai from 'icons/file-extensions/ai.svg';
import aif from 'icons/file-extensions/aif.svg';
import aifc from 'icons/file-extensions/aifc.svg';
import aiff from 'icons/file-extensions/aiff.svg';
import asf from 'icons/file-extensions/asf.svg';
import aspx from 'icons/file-extensions/aspx.svg';
import atom from 'icons/file-extensions/atom.svg';
import au from 'icons/file-extensions/au.svg';
import azw from 'icons/file-extensions/azw.svg';
import bat from 'icons/file-extensions/bat.svg';
import bmp from 'icons/file-extensions/bmp.svg';
import bz from 'icons/file-extensions/bz.svg';
import bz2 from 'icons/file-extensions/bz2.svg';
import cer from 'icons/file-extensions/cer.svg';
import chm from 'icons/file-extensions/chm.svg';
import cin from 'icons/file-extensions/cin.svg';
import cmd from 'icons/file-extensions/cmd.svg';
import config from 'icons/file-extensions/config.svg';
import crl from 'icons/file-extensions/crl.svg';
import crt from 'icons/file-extensions/crt.svg';
import cs from 'icons/file-extensions/cs.svg';
import csh from 'icons/file-extensions/csh.svg';
import csproj from 'icons/file-extensions/csproj.svg';
import css from 'icons/file-extensions/css.svg';
import csv from 'icons/file-extensions/csv.svg';
import dds from 'icons/file-extensions/dds.svg';
import der from 'icons/file-extensions/der.svg';
import dng from 'icons/file-extensions/dng.svg';
import dvi from 'icons/file-extensions/dvi.svg';
import emf from 'icons/file-extensions/emf.svg';
import eml from 'icons/file-extensions/eml.svg';
import eps from 'icons/file-extensions/eps.svg';
import epub from 'icons/file-extensions/epub.svg';
import excel from 'icons/file-extensions/excel.svg';
import exe from 'icons/file-extensions/exe.svg';
import exr from 'icons/file-extensions/exr.svg';
import flv from 'icons/file-extensions/flv.svg';
import folder from 'icons/file-extensions/folder.svg';
import gif from 'icons/file-extensions/gif.svg';
import go from 'icons/file-extensions/go.svg';
import gz from 'icons/file-extensions/gz.svg';
import hdr from 'icons/file-extensions/hdr.svg';
import htm from 'icons/file-extensions/htm.svg';
import html from 'icons/file-extensions/html.svg';
import ico from 'icons/file-extensions/ico.svg';
import ics from 'icons/file-extensions/ics.svg';
import ini from 'icons/file-extensions/ini.svg';
import jpeg from 'icons/file-extensions/jpeg.svg';
import jpg from 'icons/file-extensions/jpg.svg';
import js from 'icons/file-extensions/js.svg';
import json from 'icons/file-extensions/json.svg';
import latex from 'icons/file-extensions/latex.svg';
import less from 'icons/file-extensions/less.svg';
import lnk from 'icons/file-extensions/lnk.svg';
import log from 'icons/file-extensions/log.svg';
import man from 'icons/file-extensions/man.svg';
import md from 'icons/file-extensions/md.svg';
import mdb from 'icons/file-extensions/mdb.svg';
import mid from 'icons/file-extensions/mid.svg';
import midi from 'icons/file-extensions/midi.svg';
import mkv from 'icons/file-extensions/mkv.svg';
import mobi from 'icons/file-extensions/mobi.svg';
import mp2 from 'icons/file-extensions/mp2.svg';
import mp3 from 'icons/file-extensions/mp3.svg';
import mp4 from 'icons/file-extensions/mp4.svg';
import mpe from 'icons/file-extensions/mpe.svg';
import mpeg from 'icons/file-extensions/mpeg.svg';
import mpg from 'icons/file-extensions/mpg.svg';
import mpp from 'icons/file-extensions/mpp.svg';
import msg from 'icons/file-extensions/msg.svg';
import msi from 'icons/file-extensions/msi.svg';
import nfo from 'icons/file-extensions/nfo.svg';
import odp from 'icons/file-extensions/odp.svg';
import ods from 'icons/file-extensions/ods.svg';
import odt from 'icons/file-extensions/odt.svg';
import one from 'icons/file-extensions/one.svg';
import ost from 'icons/file-extensions/ost.svg';
import otf from 'icons/file-extensions/otf.svg';
import p7b from 'icons/file-extensions/p7b.svg';
import p7c from 'icons/file-extensions/p7c.svg';
import p7r from 'icons/file-extensions/p7r.svg';
import p12 from 'icons/file-extensions/p12.svg';
import pbm from 'icons/file-extensions/pbm.svg';
import pcx from 'icons/file-extensions/pcx.svg';
import pdf from 'icons/file-extensions/pdf.svg';
import pfx from 'icons/file-extensions/pfx.svg';
import php from 'icons/file-extensions/php.svg';
import pict from 'icons/file-extensions/pict.svg';
import pl from 'icons/file-extensions/pl.svg';
import png from 'icons/file-extensions/png.svg';
import powerpoint from 'icons/file-extensions/powerpoint.svg';
import prc from 'icons/file-extensions/prc.svg';
import ps from 'icons/file-extensions/ps.svg';
import ps1 from 'icons/file-extensions/ps1.svg';
import psb from 'icons/file-extensions/psb.svg';
import psd from 'icons/file-extensions/psd.svg';
import psp from 'icons/file-extensions/psp.svg';
import pst from 'icons/file-extensions/pst.svg';
import pub from 'icons/file-extensions/pub.svg';
import py from 'icons/file-extensions/py.svg';
import rar from 'icons/file-extensions/rar.svg';
import resx from 'icons/file-extensions/resx.svg';
import rss from 'icons/file-extensions/rss.svg';
import sass from 'icons/file-extensions/sass.svg';
import sh from 'icons/file-extensions/sh.svg';
import snd from 'icons/file-extensions/snd.svg';
import spc from 'icons/file-extensions/spc.svg';
import sql from 'icons/file-extensions/sql.svg';
import svg from 'icons/file-extensions/svg.svg';
import tab from 'icons/file-extensions/tab.svg';
import tar from 'icons/file-extensions/tar.svg';
import tex from 'icons/file-extensions/tex.svg';
import tga from 'icons/file-extensions/tga.svg';
import tif from 'icons/file-extensions/tif.svg';
import tiff from 'icons/file-extensions/tiff.svg';
import toml from 'icons/file-extensions/toml.svg';
import tsv from 'icons/file-extensions/tsv.svg';
import ttf from 'icons/file-extensions/ttf.svg';
import txtRtf from 'icons/file-extensions/txt-rtf.svg';
import unknown from 'icons/file-extensions/unknown.svg';
import vbs from 'icons/file-extensions/vbs.svg';
import vcf from 'icons/file-extensions/vcf.svg';
import vcs from 'icons/file-extensions/vcs.svg';
import vsd from 'icons/file-extensions/vsd.svg';
import vsdx from 'icons/file-extensions/vsdx.svg';
import vst from 'icons/file-extensions/vst.svg';
import wav from 'icons/file-extensions/wav.svg';
import webp from 'icons/file-extensions/webp.svg';
import wmf from 'icons/file-extensions/wmf.svg';
import wmz from 'icons/file-extensions/wmz.svg';
import word from 'icons/file-extensions/word.svg';
import xaml from 'icons/file-extensions/xaml.svg';
import xht from 'icons/file-extensions/xht.svg';
import xhtml from 'icons/file-extensions/xhtml.svg';
import xml from 'icons/file-extensions/xml.svg';
import xps from 'icons/file-extensions/xps.svg';
import xsl from 'icons/file-extensions/xsl.svg';
import xslt from 'icons/file-extensions/xslt.svg';
import xlsx from 'icons/file-extensions/xlsx.svg';
import yaml from 'icons/file-extensions/yaml.svg';
import zip from 'icons/file-extensions/zip.svg';

const svgIcons = {
  // File Extensions
  sevenZ,
  ai,
  aif,
  aifc,
  aiff,
  asf,
  aspx,
  atom,
  au,
  azw,
  bat,
  bmp,
  bz,
  bz2,
  cer,
  chm,
  cin,
  cmd,
  config,
  crl,
  crt,
  cs,
  csh,
  csproj,
  css,
  csv,
  dds,
  der,
  dng,
  dvi,
  emf,
  eml,
  eps,
  epub,
  excel,
  exe,
  exr,
  flv,
  folder,
  gif,
  go,
  gz,
  hdr,
  htm,
  html,
  ico,
  ics,
  ini,
  jpeg,
  jpg,
  js,
  json,
  latex,
  less,
  lnk,
  log,
  man,
  md,
  mdb,
  mid,
  midi,
  mkv,
  mobi,
  mp2,
  mp3,
  mp4,
  mpe,
  mpeg,
  mpg,
  mpp,
  msg,
  msi,
  nfo,
  odp,
  ods,
  odt,
  one,
  ost,
  otf,
  p7b,
  p7c,
  p7r,
  p12,
  pbm,
  pcx,
  pdf,
  pfx,
  php,
  pict,
  pl,
  png,
  powerpoint,
  prc,
  ps,
  ps1,
  psb,
  psd,
  psp,
  pst,
  pub,
  py,
  rar,
  resx,
  rss,
  sass,
  sh,
  snd,
  spc,
  sql,
  svg,
  tab,
  tar,
  tex,
  tga,
  tif,
  tiff,
  toml,
  tsv,
  ttf,
  txtRtf,
  unknown,
  vbs,
  vcf,
  vcs,
  vsd,
  vsdx,
  vst,
  wav,
  webp,
  wmf,
  wmz,
  word,
  xaml,
  xht,
  xhtml,
  xml,
  xps,
  xsl,
  xslt,
  yaml,
  zip,
  xlsx,
  doc: word,
  docx: word,
  xlsm: xlsx,
  xls: xlsx,
  webm: mp4,
};

export default svgIcons;
