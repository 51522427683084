import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentsState, ItemAttachmentsState } from 'data/attachments/reducer';
import { State } from 'reducers';

export const attachments = (state: State): AttachmentsState => state.attachments;

export const attachmentsByIdFromAgGrid = (state: State, props: ICellRendererParams): ItemAttachmentsState | undefined => {
  const itemId = props.data && props.data.id;
  const attachments = itemId && state.attachments.byItemId[itemId];
  const attachIds = attachments?.ids.slice().sort((attachId1, attachId2) => {
    const attach1 = attachments?.byId[attachId1];
    const attach2 = attachments?.byId[attachId2];

    return attach1?.sort - attach2?.sort;
  });

  return {
    byId: attachments?.byId,
    ids: attachIds,
  };
};

export const isAttachmentsLoading = (state, props) => {
  return state.ui.fileUpload.uploadInProgress;
};

export const attachmentsToUpload = (state, props) => {
  return state.ui.fileUpload.attachmentsToUpload;
};

export const attachmentsUploaded = (state, props) => {
  return state.ui.fileUpload.attachmentsUploaded;
};

export const attachmentsToPreview = (state, props) => {
  return state.attachments.previewFiles;
};
