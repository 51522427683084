import styled from 'styled-components';
import Chip from 'components/ui/Chip';

export const ChipsWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const ChipsWrapperDropdown = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${Chip} {
    margin-right: 8px;
    flex: 0 1 auto;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    & > span{
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
