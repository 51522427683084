import * as React from 'react';

import * as actions from 'data/attachments/actions';
import { ItemAttachmentsState } from 'data/attachments/reducer';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import svgIcons from 'styles/svgIcons';
import { FileAttachment } from 'types/common';
import { CommonNode, PreviewBehavior } from 'types/response';
import { AttachmentNode } from 'types/response/attachmentNode';
import { TenantNode } from 'types/response/tenantNode';
import { isColumnLocked, allowToModify } from 'utilities/permissions';

import IconButton, { Color, Size } from '../../IconButton';
import GridCellFloatingWrapper from '../components/GridCellFloatingWrapper';
import AddAttachmentsModal from './AddAttachmentsModal';
import AttachmentItem from './AttachmentItem';

interface Props {
  item: CommonNode;
  fieldId: string;
  attachments?: ItemAttachmentsState;
  attachedIds: string[]; // All the ids attached to the record
  attachmentsById: Record<string, AttachmentNode>;
  attachmentIds: string[]; // The ids attached to the column. This is a subset of attachedIds
  previewBehavior: PreviewBehavior;
  eGridCell: HTMLElement;
  column: any;
  onAdd: (files: FileAttachment[]) => void;
  onDelete: (index: string) => void;
  onClickItem: typeof actions.openPreviewAttachments;
  permissions: string[];
  showPreview?: boolean;
  tenant?: TenantNode;
}

interface State {
  modalOpen: boolean;
}

class GridAttachmentsEditor extends React.Component<Props, State> {
  public state: State = {
    modalOpen: false,
  };

  public render = (): JSX.Element => {
    const { eGridCell, onAdd, permissions, column, showPreview, tenant } = this.props;
    const { modalOpen } = this.state;
    const isLocked = isColumnLocked(column, permissions);

    return (
      <div>
        <GridCellFloatingWrapper anchorEl={eGridCell} showPreview={showPreview}>
          {this.renderAttachments()}
          {this.props.permissions.includes('files:create') && !isLocked && (
            <IconButton
              style={showPreview ? {
                position: 'absolute',
                bottom: '0',
                right: '0',
              }: undefined}
              icon={svgIcons.Add}
              color={Color.DarkGray}
              size={Size.Medium}
              onClick={this.openModal}
            />
          )}
        </GridCellFloatingWrapper>
        {modalOpen && !isLocked && (
          <AddAttachmentsModal
            onClose={this.closeModal}
            onAdd={onAdd}
            permissions={permissions}
            disableAutoAdd
            tenant={tenant}
          />
        )}
      </div>
    );
  };

  private renderAttachments = (): React.ReactNode => {
    const { attachmentIds, attachmentsById, showPreview } = this.props;
    if (!attachmentsById) {
      return [];
    }

    if (showPreview) {
      const [attachmentId] = attachmentIds;
      return this.renderAttachment(attachmentId, 0);
    }

    return attachmentIds.map((attachmentId, i) => {
      return this.renderAttachment(attachmentId, i);
    });
  };

  private renderAttachment = (id: string, i: number): JSX.Element | null => {
    const { item, attachments, previewBehavior, showPreview, column, permissions, eGridCell, onDelete, onClickItem } = this.props;
    if (!attachments) {
      return null;
    }

    const attachment = attachments?.byId?.[id];
    if (!attachment) return null;

    const getRowHeight = () => {
      let gridRowHeight = GRID_ROW_HEIGHT.BASE;
      if (eGridCell.clientHeight > GRID_ROW_HEIGHT.MEDIUM - 5 && eGridCell.clientHeight < GRID_ROW_HEIGHT.MEDIUM + 5) {
        gridRowHeight = GRID_ROW_HEIGHT.MEDIUM;
      } else if (eGridCell.clientHeight > GRID_ROW_HEIGHT.TALL - 5 && eGridCell.clientHeight < GRID_ROW_HEIGHT.TALL + 5) {
        gridRowHeight = GRID_ROW_HEIGHT.TALL;
      }
      return gridRowHeight;
    };

    return (
      <AttachmentItem
        key={`${id}${i}`}
        attachment={attachment}
        previewBehavior={previewBehavior}
        onDelete={() => onDelete(id)}
        onClick={() => onClickItem({
          index: i,
          fieldId: this.props.fieldId,
          node: item,
          enableEdit: allowToModify(column, permissions),
        })}
        permissions={permissions}
        tenant={this.props.tenant}
        hideDelete={isColumnLocked(column, permissions)}
        showPreview={showPreview}
        rowHeight={getRowHeight()}
      />
    );
  };

  private openModal = (): void => this.setState({ modalOpen: true });

  private closeModal = (): void => this.setState({ modalOpen: false });
}

export default GridAttachmentsEditor;
