import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  blueGrey,
  lightGrey,
  lightGreyB,
  white,
  selectedGrey,
} from 'styles/colors';
import { fontFamily } from 'styles/fonts';
const boxItem: CSSProperties = {
  fontFamily: 'inherit',
  fontSize: '0.813rem',
  fontWeight: 400,
  letterSpacing: 0.1,
  textAlign: 'center',
  color: '#253238',
  height: 24,
  backgroundColor: white,
  cursor: 'pointer',
  width: '100%',
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '0 5px 0 5px',
  userSelect: 'none',
};

type ClassKey =
  | 'rectangle'
  | 'boxItem'
  | 'selectedBoxItem'
  | 'boxTitle'
  | 'boxTitleContainer'
  | 'container'
  | 'overflow';

export const styles: Record<ClassKey, CSSProperties> = {
  rectangle: {
    fontFamily: fontFamily,
    boxSizing: 'border-box',
    width: '170px',
    border: `2px solid ${lightGreyB}`,
    borderRadius: '4px',
    backgroundColor: white,
  },
  boxTitleContainer: {
    minHeight: 29,
    backgroundColor: lightGrey,
    borderRadius: '3px 3px 0 0',
    userSelect: 'none',
    cursor: 'pointer',
  },
  boxTitle: {
    fontFamily: 'inherit',
    color: blueGrey,
    textAlign: 'center',
    fontSize: '0.813rem',
    letterSpacing: 0,
    fontWeight: 500,
    margin: '4% 0 3% 0',
  },
  boxItem: {
    ...boxItem,
  },
  selectedBoxItem: {
    ...boxItem,
    backgroundColor: selectedGrey,
  },
  overflow: {
    'flexDirection': 'column',
    'rowGap': '0.125rem',
    'height': '155px',
    'width': '100%',
    'overflow': 'auto',
    'scrollbar-width': '0.25rem' /* Firefox */,
    'boxSizing': 'border-box',
    'padding': '10px 10px',
  },
  container: {
    flexWrap: 'nowrap',
  },
};
