import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey
  = 'node'
  | 'list'
  | 'listClose'
  ;

export const nodeStyles: Record<ClassKey, CSSProperties> = {
  node: {
    marginTop: 10,
  },
  list: {
    margin: '0 10px',
    transition: 'height .2s ease',
    overflowY: 'hidden',
  },
  listClose: {
    height: '0 !important',
  },
};
