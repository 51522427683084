import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';

import IconButton, { Color, Size } from 'components/IconButton';
import PreviewBehaviorComponent from 'components/NodeFieldData/attachment/PreviewBehavior';
import { TitleTooltip } from 'components/Tooltip';
import * as actions from 'data/attachments/actions';
import { RoleNames } from 'data/users/users.types';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { KEY_ENTER, KEY_RETURN } from 'keycode-js';
import { connect } from 'react-redux';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { compose } from 'recompose';
import getFileIcon from 'styles/fileExtension';
import svgIcons from 'styles/svgIcons';
import { PreviewBehavior } from 'types/response';
import { AttachmentNode } from 'types/response/attachmentNode';
import { CollectionTypes, PermissionTypes } from 'types/schema';
import {
  getFormattedFileSize,
  getFileExtension,
  getFileNameNoExt,
} from 'utilities/files';
import { getUserRole } from 'utilities/permissions';

import { styles, StyleProps } from './AttachmentPreviewEditor.style';

interface OwnProps {
  fileName: string;
  onDelete?: () => void;
  thumbnailClick: () => void;
  size?: string;
  attachment: AttachmentNode;
  readOnly?: boolean;
  allowDownload?: boolean;
  isPreload?: boolean;
  lock?: boolean;
}

interface StateProps {
  permissions: string[];
}

interface DispatchProps {
  saveFileChange: typeof actions.updateAttachment;
}

type Props = OwnProps & StyleProps & StateProps & DispatchProps;
export type TooltipProps = StyleProps &
  Pick<OwnProps, 'fileName' | 'attachment' | 'isPreload'>;

/**
 * Gets MIME type or if the MIME type is so long
 * gets the file extension instead
 *
 * @param fileType
 * @param ext
 * @returns MIME type or file ext.
 */
function getFileTypeCopy(fileType='', ext='') {
  const thresholdLength = 20;
  const fileTypeCopy = fileType.length <= thresholdLength
    ? fileType
    : ext;

  return fileTypeCopy;
}

/**
 * Drag Icon component trigger
 */
const DragThumb = SortableHandle(() =>
  <div>
    <IconButton
      icon={svgIcons.Drag}
      color={Color.DarkGray}
      size={Size.Small}
    />
  </div>,
);

/**
 * Thumbnail File Tooltip comp.
 * @param props
 * @returns
 */
export function FileInfoTooltip(props: TooltipProps): JSX.Element {
  const { classes, fileName, attachment, isPreload } = props;
  const fileSize = attachment?.meta?.size ?? 0;
  const fileTypeCopy = getFileTypeCopy(
    attachment?.fileType,
    attachment?.fileExtension,
  );

  return (
    <div className={classes.ContentTooltip}>
      <div className={classes.titleTooltip}>{fileName}</div>
      {
        !isPreload && (
          <div className={classes.InfoTooltip}>
            <div>{fileTypeCopy}</div>
            <div className={classes.InfoSize}>
              {getFormattedFileSize(fileSize)}
            </div>
          </div>
        )
      }
    </div>
  );
}

function AttachmentPreviewEditor(props: Props): JSX.Element {
  const {
    fileName,
    classes,
    attachment,
    readOnly,
    allowDownload,
    isPreload,
    lock,
    permissions,
    onDelete,
    thumbnailClick,
    saveFileChange,
  } = props;
  const [editNameMode, setEditNameMode] = useState(false);
  const [fileNameValue, setFileTitleValue] = useState(getFileNameNoExt(fileName));
  const fileExt = getFileExtension(fileName);
  const FallbackIcon = getFileIcon(fileExt);
  const computeFileName = () => fileExt ? `${fileNameValue}.${fileExt}` : fileName;
  const tooltipComp = <FileInfoTooltip {...props} fileName={computeFileName()} />;
  const userRole: RoleNames = getUserRole(permissions ?? []);
  const isUserAdmin = userRole === RoleNames.Administrator;
  const isButtonDisabled = isUserAdmin ? false : lock || readOnly;
  const canDelete = isUserAdmin ||
    (permissions?.includes(`${CollectionTypes.files}:${PermissionTypes.delete}`) && !isButtonDisabled);
  const showEditDeleteButtons = userRole !== RoleNames.Reader;

  const saveFileName = useCallback(() => {
    const { workspaceID = '', _id = '', version = 1 } = attachment;
    const attachPayload = { title: computeFileName(), version };

    saveFileChange({ attachment: attachPayload, workspaceID, id: _id });
  }, [fileNameValue, fileName]);

  const onEditFileName = useCallback(() => {
    setEditNameMode(true);
  }, []);

  const onTitleChange = useCallback((event: ChangeEvent) => {
    const input = event.target as HTMLInputElement;

    setFileTitleValue(input.value);
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onTitleFinishEdit = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const keyCode: number = event.keyCode || Number(event.key);
      if ([KEY_ENTER, KEY_RETURN].includes(keyCode)) {
        setEditNameMode(false);
        saveFileName();
      }
    },
    [fileNameValue],
  );

  const onTitleBlur = useCallback(() => {
    setEditNameMode(false);
    saveFileName();
  }, [fileNameValue]);

  const onHandleDelete = () => {
    if (!isUserAdmin && lock) return;

    onDelete?.();
  };

  return (
    <figure className={classes.previewWrapper}>
      <div className={classes.previewFileContent} onClick={thumbnailClick}>
        {attachment.thumbnail ? (
          <img
            className={classes.previewCover}
            src={attachment.thumbnail}
            alt={fileNameValue}
          />
        ) : (
          <FallbackIcon className={classes.fileIcon} />
        )}
      </div>

      <figcaption className={classes.previewCaption}>
        {editNameMode ? (
          <div className={classes.editTitle}>
            <input
              type="text"
              value={fileNameValue}
              onChange={onTitleChange}
              onKeyUp={onTitleFinishEdit}
              onBlur={onTitleBlur}
              autoFocus={editNameMode}
            />
          </div>
        ) : (
          <>
            {/** FILE NAME */}
            <div className={classes.fileTitle}>
              <TitleTooltip
                interactive
                placement="bottom-start"
                title={tooltipComp}
              >
                <span>{computeFileName()}</span>
              </TitleTooltip>
            </div>

            {
              !isPreload && (
                <div className={`${classes.buttonsGroup} ${lock ? classes.buttonsGroupLocked: ''}`}>
                  {/** EDIT ICON */}
                  {showEditDeleteButtons && (
                    <TitleTooltip
                      interactive
                      placement="bottom-start"
                      title={<div className={classes.titleTooltip}>Edit</div>}
                    >
                      <IconButton
                        icon={svgIcons.Edit}
                        color={!isButtonDisabled ? Color.DarkGrayNoHover: Color.LightBlueGrey}
                        size={Size.Small}
                        onClick={onEditFileName}
                        disabled={isButtonDisabled}
                      />
                    </TitleTooltip>
                  )}

                  {/** DOWNLOAD ICON */}
                  {
                    allowDownload && (
                      <PreviewBehaviorComponent
                        attachment={attachment}
                        previewBehavior={PreviewBehavior.Download}
                      >
                        <TitleTooltip
                          interactive
                          placement="bottom-start"
                          title={<div className={classes.titleTooltip}>Download</div>}
                        >
                          <IconButton
                            icon={svgIcons.Download}
                            color={Color.DarkGray}
                            size={Size.Small}
                          />
                        </TitleTooltip>
                      </PreviewBehaviorComponent>
                    )
                  }

                  {/** DELETE ICON */}
                  {showEditDeleteButtons && (
                    <TitleTooltip
                      interactive
                      placement="bottom-start"
                      title={<div className={classes.titleTooltip}>Delete</div>}
                    >
                      <IconButton
                        icon={svgIcons.Delete}
                        color={canDelete ? Color.DarkGrayNoHover: Color.LightBlueGrey}
                        size={Size.Small}
                        onClick={onHandleDelete}
                        disabled={!canDelete}
                      />
                    </TitleTooltip>
                  )}

                  {/** DRAG ICON */}
                  {!readOnly && !lock && (
                    <DragThumb />
                  )}
                </div>
              )
            }
          </>
        )}
      </figcaption>
    </figure>
  );
}

const mapState = (state) => ({
  permissions: getWorkspacePermissions(state),
});

const mapDispatchToProps: DispatchProps = {
  saveFileChange: actions.updateAttachment,
};

const enhance = compose(
  connect(mapState, mapDispatchToProps),
  styles,
  SortableElement,
);

export default enhance(AttachmentPreviewEditor);
