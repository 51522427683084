import { CSSProperties } from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';

type ClassKey = 'root';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

export const Title = styled.p`
  color: #212121;
  font-size: 1.143rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 12.66rem;
`;

export const toolTipStyle = {
  fontSize: 14,
  padding: '5px',
};
