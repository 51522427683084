import MenuItem, { MenuItemClassKey } from '@material-ui/core/MenuItem';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { FieldType, getIcon, getFieldTypeTooltip } from '../../data';
import Typography, { Variant } from '../../../Typography';

interface OwnProps {
  fieldType: FieldType;
  selectedFieldType?: FieldType;
  onSelect(fieldType: FieldType): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function FieldMenuItem(props: Props): JSX.Element {
  const { classes, fieldType, selectedFieldType } = props;
  const { icon, ...menuItemClasses } = classes;

  const Icon = getIcon(fieldType);
  const tooltip = getFieldTypeTooltip(fieldType) || undefined;

  function onClick(): void {
    props.onSelect(fieldType);
  }

  return (
    <MenuItem
      classes={menuItemClasses}
      selected={fieldType === selectedFieldType}
      onClick={onClick}
      title={tooltip}
    >
      <Icon className={icon} />
      <Typography variant={Variant.MenuItem}>
        {fieldType}
      </Typography>
    </MenuItem>
  );
}

type ClassKey
  = 'icon'
  | Extract<MenuItemClassKey, 'root'>
  ;

const styles = withStyles<ClassKey>({
  icon: {
    width: 10,
    height: 10,
    marginRight: 6,
    color: Colors.slateTwo,
  },
  root: { padding: '0 6px' },
});

export default styles(FieldMenuItem);
