import React from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Button, { Variant as ButtonVariant } from 'components/Button';
import Modal from 'components/Modals/Modal';
import Content from 'components/Modals/Modal/Content';
import Header from 'components/Modals/Modal/Header';
import Typography, { Variant } from 'components/Typography';


export interface ConfirmDialogProps extends WithStyles<typeof styles> {
  onClose?: () => void;
  onDone: (selectedRole: string) => void;
  title: string;
  textContent: string;
  labelCancel: string;
  labelAccept: string;
  showRoleSelection?: boolean;
}
function ConfirmDialog({
  onClose,
  classes,
  onDone,
  title,
  textContent,
  labelAccept,
  labelCancel,
  showRoleSelection,
}: ConfirmDialogProps) {
  const handleOnClose = () => {
    if (onClose instanceof Function) onClose();
  };

  const handleOnAccept = () => {
    onDone('');
    handleOnClose();
  };


  return (
    <Modal onClose={handleOnClose} classes={{ paper: classes.paper }}>
      <Header onClose={handleOnClose}>{title}</Header>
      <Content classes={{ wrapper: classes.wrapper }}>
        <Typography variant={Variant.ModalText}>{textContent}</Typography>
        <div className={`${classes.buttonsWrapper}`}>
          <Button
            id="btnCancel"
            label={labelCancel}
            onClick={handleOnClose}
            variant={ButtonVariant.SecondaryLink}
          />
          <Button
            id="btnAccept"
            label={labelAccept}
            onClick={handleOnAccept}
            variant={ButtonVariant.Primary}
          />
        </div>
      </Content>
    </Modal>
  );
}

const styles: Record<
  'buttonsWrapper' | 'paper' | 'selectWrapper' | 'wrapper',
  CSSProperties
> = {
  paper: {
    width: 'min-content',
  },
  selectWrapper: {
    width: '45%',
    marginTop: 16,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '16px 0',
  },
};

export default withStyles(styles)(ConfirmDialog);
