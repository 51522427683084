import * as _ from 'lodash/fp';
import { createSelector } from 'reselect';
import { Dictionary } from 'lodash';
import idx from 'idx';

import { CollectionViewTypes, Column, ColumnVM } from 'types/schema';
import { editableSchemaColumnsSelector, schemaColumnsMap } from 'data/collections/collections.selectors';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import { ColumnsMapById } from 'types/gridOptions';

import { selectedView } from '../../views/views.selectors';
import { State } from '../../../reducers';
import { ViewNode } from 'types/response/viewNode';
import * as ColID from 'components/DataGrid/columns/constants';
import { GridColumnState } from 'data/collections/collections.reducer';
import { getUserDefinedFieldKey } from 'utilities/collections';

export const getViewFieldIds = (view: ViewNode): string[] => idx(view, _ => _.query.fieldIDs) || [];

export const selectedViewFields: (state: State) => string[] = createSelector(
  selectedView,
  getViewFieldIds,
);

export const sortedColumnKeys = (columns: ColumnsMapById, viewFieldIDs: string[]): string[] => {
  const validViewFieldIDs = viewFieldIDs.filter(key => columns[key]);
  const keysNotInView = _.difference(Object.keys(columns), validViewFieldIDs);
  return validViewFieldIDs.concat(keysNotInView);
};

const getColumnWidthFromView = (view: ViewNode, colId: string): number | undefined =>
  view.columnWidths && view.columnWidths[getUserDefinedFieldKey(colId)];

export const getColumnsForView = (
  columns: ColumnsMapById,
  view: ViewNode,
  permissions: string[],
): GridColumnState[] => {
  const fieldIDs = getViewFieldIds(view);

  const sortedColumns: GridColumnState[] = sortedColumnKeys(columns, fieldIDs)
    .map(key => {
      const rowGroupIndex = view.groupByFieldIDs ? view.groupByFieldIDs.indexOf(key) : -1;
      return {
        colId: key,
        hide: fieldIDs.indexOf(key) < 0,
        width: getColumnWidthFromView(view, key),
        rowGroupIndex: rowGroupIndex >= 0 ? rowGroupIndex : null,
        pinned: null,
      };
    });

  const result: GridColumnState[] = [];

  if (view.type === CollectionViewTypes.grid) {
    result.push({
      colId: ColID.ROW_NUMBER_ID,
      hide: false,
      width: getColumnWidthFromView(view, ColID.ROW_NUMBER_ID),
      rowGroupIndex: null,
      pinned: 'left',
    });
  }

  result.push(...sortedColumns);
  if (view.type === CollectionViewTypes.grid && permissions.includes('items:create')) {
    result.push({
      colId: ColID.ADD_NEW_FIELD_ID,
      hide: false,
      width: getColumnWidthFromView(view, ColID.ADD_NEW_FIELD_ID),
      rowGroupIndex: null,
      pinned: null,
    });
  }

  return result;
};

export const columnsStateSelector = (state: State): GridColumnState[] => state.collections.currentViewConfig.columns;

export const rowHeightSelector = (state: State): number =>
  state.collections.currentViewConfig.rowHeight || GRID_ROW_HEIGHT.BASE;

export const categoryFieldOrderSelector = (state: State): string[] | undefined =>
  state.collections.currentViewConfig.categoryFieldOrder;

export const createColumnsVMFilteredSelector = (filter: (column: Column) => boolean = () => true) => createSelector(
  columnsStateSelector, schemaColumnsMap,
  (columnsState: GridColumnState[] = [], schemaColumns: ColumnsMapById): ColumnVM[] => {
    return columnsState
      .filter(item => {
        const column = schemaColumns[item.colId];
        return column && filter(column);
      })
      .map((item): ColumnVM => ({
        ...schemaColumns[item.colId],
        hide: item.hide,
        id: item.colId,
      }));
  },
);

export const columnsVMSelector = createColumnsVMFilteredSelector();

export const columnsVMToGroupBySelector = createColumnsVMFilteredSelector(
  (column: Column) => _.getOr(true, 'grid.enableRowGroup', column),
);

export const columnVMToFilterBySelector = createColumnsVMFilteredSelector(
  (column: Column) => {
    return !(_.getOr(false, 'grid.suppressFilter', column) || _.getOr(false, 'grid.suppressFilterDropdown', column));
  },
);

export const columnsVMMapSelector = createSelector(
  columnsVMSelector,
  (columnsVM: ColumnVM[]): Dictionary<ColumnVM> => {
    return columnsVM.reduce<Dictionary<ColumnVM>>((result, current) => {
      result[current.id] = current;
      return result;
    }, {});
  },
);

export const visibleFieldIds = (columnsVM: ColumnVM[]): string[] => columnsVM
  .filter(columnVM => !columnVM.hide)
  .map(columnVM => columnVM.id);

export const visibleFieldIdsSelector: (state: State) => string[] = createSelector(
  columnsVMSelector,
  visibleFieldIds,
);

export const modalVisibleFieldIdsSelector = (state: State): string[] => {
  const schemaColumns: ColumnsMapById = editableSchemaColumnsSelector(state);
  return columnsStateSelector(state)
    .filter(col => !col.hide && schemaColumns[col.colId])
    .map(col => col.colId);
};

export function groupByFieldIDsSelector(
  state: State,
  viewType: CollectionViewTypes,
): string[] {
  const columns = columnsStateSelector(state);
  let fieldIDs: string[] = [];

  // group by fields columns is only available for Grid views
  if (viewType === CollectionViewTypes.grid) {
    fieldIDs = _.sortBy('rowGroupIndex', columns)
      .reduce(rowGroupIndexReducer, []);
  }

  return fieldIDs;
}

export const customRowOrder = (state: State): Record<string, number> =>
  state.collections.currentViewConfig.customRowOrder;

function rowGroupIndexReducer(indexes: string[], { colId, rowGroupIndex }: GridColumnState): string[] {
  if (typeof rowGroupIndex === 'number') {
    return [...indexes, colId];
  }

  return indexes;
}
