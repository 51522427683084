import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { DirectoryType } from 'data/documents/documents.types';
import { DocumentNode } from 'types/response/documentNode';
import { HistoryGroup } from 'data/ui/documentPreview/documentPreview.types';
import { PreviewNode } from 'types/response/previewNode';

enum ActionType {
  FETCH_DIRECTORIES_REQUEST = 'documents/FETCH_DIRECTORIES_REQUEST',
  FETCH_DIRECTORIES_SUCCESS = 'documents/FETCH_DIRECTORIES_SUCCESS',
  FETCH_DIRECTORIES_FAILURE = 'documents/FETCH_DIRECTORIES_FAILURE',
  MOVE_DOCUMENT_REQUEST = 'documents/MOVE_REQUEST',
  MOVE_DOCUMENT_SUCCESS = 'documents/MOVE_SUCCESS',
  MOVE_DOCUMENT_FAILURE = 'documents/MOVE_FAILURE',
  COPY_DOCUMENT_REQUEST = 'documents/COPY_REQUEST',
  COPY_DOCUMENT_SUCCESS = 'documents/COPY_SUCCESS',
  COPY_DOCUMENT_FAILURE = 'documents/COPY_FAILURE',
  SET_DOCUMENT_FOR_PREVIEW = 'documents/SET_DOCUMENT',
  FETCH_DOCUMENT_REQUEST = 'documents/DOCUMENT_REQUEST',
  FETCH_DOCUMENT_SUCCESS = 'documents/DOCUMENT_SUCCESS',
  FETCH_DOCUMENT_FAILURE = 'documents/DOCUMENT_FAILURE',
  PRINT_DOCUMENT_ERROR = 'documents/PRINT_ERROR',
  FETCH_DOCUMENT_PREVIEWS_REQUEST = 'documents/PREVIEWS_REQUEST',
  FETCH_DOCUMENT_PREVIEWS_SUCCESS = 'documents/PREVIEWS_SUCCESS',
  FETCH_DOCUMENT_PREVIEWS_FAILURE = 'documents/PREVIEWS_FAILURE',
  FETCH_DOCUMENT_HISTORY_REQUEST = 'documents/HISTORY_REQUEST',
  FETCH_DOCUMENT_HISTORY_SUCCESS = 'documents/HISTORY_SUCCESS',
  FETCH_DOCUMENT_HISTORY_FAILURE = 'documents/HISTORY_FAILURE',
  CLEAR_DOCUMENT = 'documents/CLEAR_DOCUMENT',
  APPEND_DIRECTORY = 'documents/APPEND_DIRECTORY',
}

export interface MoveCopyDocumentPayload {
  documentURI: string;
  folderID: string;
  folderURI: string;
}

export const fetchDirectories = createAsyncAction(
  ActionType.FETCH_DIRECTORIES_REQUEST,
  ActionType.FETCH_DIRECTORIES_SUCCESS,
  ActionType.FETCH_DIRECTORIES_FAILURE,
)<string, DirectoryType[], Error>();

export const moveDocument = createAsyncAction(
  ActionType.MOVE_DOCUMENT_REQUEST,
  ActionType.MOVE_DOCUMENT_SUCCESS,
  ActionType.MOVE_DOCUMENT_FAILURE,
)<MoveCopyDocumentPayload, DocumentNode, Error>();

export const copyDocument = createAsyncAction(
  ActionType.COPY_DOCUMENT_REQUEST,
  ActionType.COPY_DOCUMENT_SUCCESS,
  ActionType.COPY_DOCUMENT_FAILURE,
)<MoveCopyDocumentPayload, DocumentNode, Error>();

export const setDocumentForPreview = createStandardAction(ActionType.SET_DOCUMENT_FOR_PREVIEW)<DocumentNode>();

export const loadDocument = createAsyncAction(
  ActionType.FETCH_DOCUMENT_REQUEST,
  ActionType.FETCH_DOCUMENT_SUCCESS,
  ActionType.FETCH_DOCUMENT_FAILURE,
)<string, DocumentNode, Error>();

export const documentPrintError = createStandardAction(ActionType.PRINT_DOCUMENT_ERROR)();

export const loadPreviews = createAsyncAction(
  ActionType.FETCH_DOCUMENT_PREVIEWS_REQUEST,
  ActionType.FETCH_DOCUMENT_PREVIEWS_SUCCESS,
  ActionType.FETCH_DOCUMENT_PREVIEWS_FAILURE,
)<string, PreviewNode[], Error>();

export const loadHistory = createAsyncAction(
  ActionType.FETCH_DOCUMENT_HISTORY_REQUEST,
  ActionType.FETCH_DOCUMENT_HISTORY_SUCCESS,
  ActionType.FETCH_DOCUMENT_HISTORY_FAILURE,
)<string, HistoryGroup[], Error>();

export const clearDocumentWithPreviews = createStandardAction(ActionType.CLEAR_DOCUMENT)();

export const appendDirectory = createStandardAction(ActionType.APPEND_DIRECTORY)<DirectoryType>();
