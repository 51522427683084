import * as keys from 'keycode-js';
import * as _ from 'lodash/fp';
import * as React from 'react';
import KeyHandler from 'react-key-handler';

import { TreeNode } from 'types/response/http/getNode';

import Pane from './Pane';
import { Bookmark } from 'types/schema';

interface State {
  query?: string;
  filteredNavigationTree: TreeNode[];
}

export interface Props {
  isOpened: boolean;
  navigationTree: TreeNode[];
  onClose(): void;
  bookmarks: Bookmark[];
  permissions: string[];
}

export default class NavigationPane extends React.Component<Props, State> {
  public state: State = {
    filteredNavigationTree: this.props.navigationTree,
  };

  public componentDidUpdate(prevProps: Props): void {
    const { navigationTree } = this.props;
    const { query } = this.state;

    if (!_.isEqual(prevProps.navigationTree, navigationTree)) {
      if (query) {
        this.setState({
          filteredNavigationTree: filterNavigationTree(query, navigationTree),
        });
      } else {
        this.setState({
          filteredNavigationTree: navigationTree,
        });
      }
    }
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {this.props.isOpened && (
          <KeyHandler
            keyCode={keys.KEY_ESCAPE}
            onKeyHandle={this.props.onClose}
          />
        )}
        <Pane
          query={this.state.query}
          isOpened={this.props.isOpened}
          navigationTree={this.state.filteredNavigationTree}
          handleQuery={this.handleQuery}
          permissions={this.props.permissions}
        />
      </React.Fragment>
    );
  }

  private handleQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { navigationTree } = this.props;
    const { value } = event.target;
    const query = value || undefined;

    if (query) {
      this.setState({
        query,
        filteredNavigationTree: filterNavigationTree(query, navigationTree),
      });
    } else {
      this.setState({
        query,
        filteredNavigationTree: navigationTree,
      });
    }
  };
}

function filterNavigationTree(query: string, tree: TreeNode[]): TreeNode[] {
  return tree.map(_.curry(removeUnmatchedNodes)(query));
}

function removeUnmatchedNodes(query: string, node: TreeNode): TreeNode {
  if (!node.nodes) {
    return node;
  }

  return {
    ...node,
    nodes: filterTreeNodes(query, node.nodes),
  };
}

function filterTreeNodes(query: string, tree: TreeNode[]): TreeNode[] {
  return tree.filter(_.curry(matchesNodeTitle)(query));
}

function matchesNodeTitle(query: string, node: TreeNode): boolean {
  return node.title ? matchesQuery(node.title, query) : false;
}

function matchesQuery(text: string, query: string): boolean {
  return text.trim().toLowerCase().includes(
    query.trim().toLowerCase(),
  );
}
