import { IDoesFilterPassParams } from 'ag-grid-community';
import { singleChoiceComparator } from 'data/collections/filters';

import { getRoleChoice, getRoleId } from '../shared';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function RoleChoiceFilter() {

}

RoleChoiceFilter.prototype.init = function(params) {
  this.valueGetter = params.valueGetter;
  this.value = '';
  this.model = null;
  this.field = params.colDef.field;
  this.choices = params.colDef.cellRendererParams.choices;
};

RoleChoiceFilter.prototype.getGui = function() {
  return document.createElement('div');
};

RoleChoiceFilter.prototype.doesFilterPass = function(params: IDoesFilterPassParams): boolean {
  const roles = params.data[this.field];
  if (this.isFloatingFilter) {
    const roleName = getRoleChoice(roles).label;
    return roleName.toLowerCase().includes(this.value.toString().toLowerCase());
  } else {
    const roleId = getRoleId(roles);
    return singleChoiceComparator(roleId, this.filter);
  }
};

RoleChoiceFilter.prototype.isFilterActive = function() {
  return this.value !== '';
};

RoleChoiceFilter.prototype.getModel = function() {
  return { value: this.value };
};

RoleChoiceFilter.prototype.setModel = function(model) {
  if (!model) return;
  this.value = model.value;
  this.filter = model.filter;
  this.isFloatingFilter = model.isFloatingFilter;
};

export default RoleChoiceFilter;
