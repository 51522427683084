import { Auth, Hub } from 'aws-amplify';
import { LOCAL_STORAGE_KEYS } from '../constants';

import Timer from './timer';

const TIMEOUT_WINDOW = 15 * 60 * 1000;
const TIMEOUT_CHECK = 15 * 1000;
let inactivityTimeout = 0;

/**
 * Compares and returns the last inactivity timeout updated
 *
 * @param localInactivityTime - unix timestamp - possible last updated time
 * @return unix timestamp - updated inactiviy time
 */
function getLastInactivityTime(localInactivityTime: number): number {
  let result = localInactivityTime;

  // check if there is another tab with inactivity timeout updated
  const globalInactivity = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK));
  if (!isNaN(globalInactivity)) {
    result = Math.max(globalInactivity, result);
  }

  return result;
}

/**
 * Redirects to logout page
 */
export function checkInactivity(timeOutToCompare = inactivityTimeout) {
  const currentDate = Date.now();
  if (currentDate >= timeOutToCompare) {
    window.location.href = '/logout?reason=inactivity';
  }
}

/**
 * updates inactivity timeout
 */
function trackActivity() {
  inactivityTimeout = getLastInactivityTime(Date.now() + TIMEOUT_WINDOW);
}

/**
 * check if the tab is active or not
 * @param timer
 */
function trackActivityBetweenTabs(timer: Timer) {
  if (document.visibilityState === 'visible') {
    Auth.currentSession()
      .then(() => {
      // Take the inactivity time saved from another tab if that's the case
        inactivityTimeout = getLastInactivityTime(inactivityTimeout);

        timer.start(true);
      })
      .catch(() => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK);
        // redirect to the login page
        Hub.dispatch('login', { event: 'inactivity' });
      });
  } else {
    const timoutString = inactivityTimeout.toString();
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVITY_CHECK, timoutString);
    timer.stop();
  }
}

/**
 * Checking user inactivity
 * Will redirect to the logout page if the user doesn't trigger any event.
 */
export const SetInactivityTimeOut = () => {
  const timer = new Timer(TIMEOUT_CHECK, checkInactivity);
  timer.start();

  trackActivity();
  window.onload = trackActivity;
  window.onclick = trackActivity;
  window.onkeypress = trackActivity;
  window.addEventListener('scroll', trackActivity, true);
  // event for checking tab active
  document.addEventListener(
    'visibilitychange',
    trackActivityBetweenTabs.bind(null, timer),
  );
};
