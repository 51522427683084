import { FieldRendererTypes } from '../../NodeFieldData';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import './RatingRenderer.style.scss';
import starFilledSVG from './starFilled.svg';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';
import BaseRenderer from '../BaseRendererPrototype';

function RatingRenderer() {
  return true;
}

RatingRenderer.prototype = Object.create(BaseRenderer.prototype);

function getValue(props) {
  return (typeof props.value === 'number' || +props.value > 0) ? +props.value : 0;
}

function createWrapper() {
  const wrapper = document.createElement('div');
  wrapper.style = `
    height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `;
  return wrapper;
}

function createBox() {
  const wrapper = document.createElement('div');
  wrapper.style = `
    display: inline-flex;
    cursor: default;
  `;
  return wrapper;
}

function createStar() {
  const star = document.createElement('span');
  star.appendChild(starFilledSVG());
  star.className = 'star';
  return star;
}

function createStars(value) {
  const box = createBox();
  const starts = new Array(value || 0).fill(null).map(createStar);
  if (starts) {
    starts.forEach(star => {
      box.appendChild(star);
    });
  }
  return box;
}
RatingRenderer.prototype.init = function(params) {
  this.eGridCell = params.eGridCell;
  const value = getValue(params);

  const wrapper = document.createElement('div');
  wrapper.id = `${params.node.id}-${params.column.colId}`;
  wrapper.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  if (params.type === FieldRendererTypes.short || params.type === FieldRendererTypes.createCard) {
    const span = document.createElement('span');
    span.setAttribute('class', getColumnHeaderClass(params.fieldType));
    span.setAttribute('title', value);
    wrapper.appendChild(span);
    this.element = wrapper;
    return;
  }

  const starsWrapper = createWrapper();
  const stars = createStars(value);
  starsWrapper.appendChild(stars);
  wrapper.appendChild(starsWrapper);

  if (this.eGridCell) {
    this.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && value?.toString()?.includes(currentQuickSearch)) {
      this.eGridCell.classList.add('highlighted-cell');
    }
  }

  this.stars = stars;
  this.starsWrapper = starsWrapper;
  this.element = wrapper;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

RatingRenderer.prototype.getGui = function() {
  return this.element;
};

RatingRenderer.prototype.refresh = function(params) {
  return false;
};

RatingRenderer.prototype.destroy = function() {
  return true;
};

export default RatingRenderer;
