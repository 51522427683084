import * as React from 'react';
import Tab, { TabProps } from '@material-ui/core/Tab';
import { TabLabel } from 'components/WorkspaceBar/WorkspaceMenu/WorkspaceMenu.style';
import { MenuItem } from 'types/response/workspaceNode';

export interface Props extends TabProps {
  item: MenuItem;
  onClick: (item: MenuItem) => void;
}

export default class MenuTab extends React.PureComponent<Props> {
  handleClick = () => {
    this.props.onClick(this.props.item);
  };

  render() {
    const { item: { label } } = this.props;

    return (
      <Tab
        {...this.props}
        id={`btnTab${label}`}
        key={label}
        label={<TabLabel>{label}</TabLabel>}
        onClick={this.handleClick}
      />
    );
  }
}
