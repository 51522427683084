import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as _ from 'lodash/fp';
import * as React from 'react';

import * as Data from '../../data';
import TextField from '../../TextField';
import Select, { Variant } from '../../../Select';
import Switch from '../../../Switch';
import Tabs from '../../../Tabs';
import DefaultNumberTextField from './DefaultNumberTextField';

interface Props extends WithStyles<ClassKey> {
  data: Data.CurrencyData;
  onDataUpdate(data: Data.CurrencyData): void;
}

function Currency(props: Props): JSX.Element {
  const { data, classes } = props;

  function onCurrencySymbolChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    props.onDataUpdate({ ...data, currencySymbol: event.target.value });
  }

  function onPrecisionChange(precision: Data.Precision): void {
    props.onDataUpdate({ ...data, precision });
  }

  function onAllowNegativeChange(): void {
    props.onDataUpdate({ ...data, allowNegative: !data.allowNegative });
  }

  function onDefaultValueChange(defaultNumber?: number | string): void {
    props.onDataUpdate({ ...data, defaultNumber });
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        data={[{
          title: 'Format',
          content: (
            <React.Fragment>
              <TextField
                id="currencySymbol"
                label="Currency symbol"
                placeholder="Currency symbol"
                value={data.currencySymbol}
                onChange={onCurrencySymbolChange}
                fullWidth
              />
              <div className={classes.row}>
                <Select
                  variant={Variant.Small}
                  label="Precision"
                  value={data.precision}
                  values={Data.precisions}
                  getLabel={_.curry(Data.getPrecisionLabel)(data.currencySymbol || '')}
                  getSelectValue={_.identity}
                  onChange={onPrecisionChange}
                  fullWidth
                />
              </div>
              <div className={classes.row}>
                <Switch
                  label="Allow negative numbers"
                  selected={data.allowNegative}
                  onClick={onAllowNegativeChange}
                  labelRight
                />
              </div>
            </React.Fragment>
          ),
        }, {
          title: 'Default',
          content: (
            <DefaultNumberTextField
              label="Prefill new cells with this number"
              defaultNumber={data.defaultNumber}
              onValueChange={onDefaultValueChange}
            />
          ),
        }]}
      />
    </div>
  );
}

type ClassKey
  = 'wrapper'
  | 'row'
  ;

const styles = withStyles<ClassKey>({
  wrapper: { marginTop: 16 },
  row: { marginTop: 5 },
});

export default styles(Currency);
