import * as React from 'react';

import { ListItemText, Menu, MenuItem, withStyles, WithStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button, { Variant as ButtonVariant } from 'components/Button';
import IconButton, { Color as IconButtonColor, Size } from 'components/IconButton';
import Modal from 'components/Modals/Modal';
import Content from 'components/Modals/Modal/Content';
import Header from 'components/Modals/Modal/Header';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import { inviteUsers } from 'data/accounts/actions';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { ReactComponent as CheckIcon } from 'icons/check-blue.svg';
import { uniq } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State as ReduxState } from 'reducers';
import svgIcons from 'styles/svgIcons';

import AccountChip from '../../components/AccountChip';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import Tooltip from '../../components/Tooltip';
import { AppMessages } from '../../constants';
import { AccountMode, getAccounts } from '../../data/accounts/state';
import { AccountNode } from '../../types/response/accountNode';
import { styles } from './UserEditor.style';

const roles = [
  {
    title: 'Administrator',
    content: 'Full access to Workspace management',
    value: 'admin',
  },
  {
    title: 'Editor',
    content: 'Download, upload, share, edit and delete',
    value: 'editor',
  },
  {
    title: 'Reader',
    content: 'View only',
    value: 'reader',
  },
];

export enum AllowedEmails {
  ANY = 'any',
  SOME = 'some'
}

export enum UserEditorStates {
  BROWSE = 'browse',
  UPDATE = 'update',
  DELETE = 'delete',
  FETCHING = 'fetching',
  WITH_ERROR = 'with_error'
}

interface StateProps {
  accountMode: AccountMode | null;
  permissions: string[];
  roles: { id: string; name: string }[];
  users: AccountNode[];
  workspaceUsers: AccountNode[];
  filteredUsers: AccountNode[];
}

interface MapDispatchToProps {
  inviteUsers: (InviteUsers) => void;
  successToast: typeof notifications.success;
}

interface OwnProps {
  match: {
    params: {
      [key: string]: string;
    };
  };
}

interface State {
  email: string;
  message: string;
  role: number;
  link: string;
  accessLevel: string;
  allowedEmail: string;
  emailValidationError: boolean;
  messageValidationError: boolean;
  userEditorStates: UserEditorStates;
  showRoleInfoBox: boolean;
  showLinkAccessLevelInfoBox: boolean;
  anchorEl: null | HTMLElement;
  invitedEmails: string[];
  selectedUsers: string[];
  activeItem: null | string;
  inputFocused: boolean;
}

interface Suggestion {
  _id?: string;
  id: string;
  label?: string;
  name?: string;
}

type Props = RouteComponentProps & MapDispatchToProps & OwnProps & StateProps & WithStyles<typeof styles>;

export class UserEditor extends React.Component<Props, State> {
  state = {
    email: '',
    message: '',
    role: 2,
    link: '',
    accessLevel: 'Reader',
    allowedEmail: '',
    emailValidationError: false,
    messageValidationError: false,
    userEditorStates: UserEditorStates.BROWSE,
    showRoleInfoBox: false,
    showLinkAccessLevelInfoBox: false,
    anchorEl: null,
    invitedEmails: [],
    selectedUsers: [],
    activeItem: null,
    inputFocused: false,
  };

  public componentDidMount(): void {
    if (!this.props.permissions.includes('workspaces:update')) {
      this.props.history.push(`/workspaces/${this.props.match.params.workspaceId}/accounts`);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const { successToast, history, accountMode } = this.props;
    if (prevProps.accountMode !== accountMode) {
      if (accountMode === AccountMode.SUCCESS) {
        history.push(`/workspaces/${this.props.match.params.workspaceId}/accounts`);
        successToast({ message: AppMessages.NOTIFICATION_USER_INVITATION_SENT });
        return;
      }

      if (accountMode === AccountMode.ERROR) {
        this.setState({ userEditorStates: UserEditorStates.BROWSE });
      }
    }
  }

  public render(): JSX.Element {
    return (
      <div onClick={this.onClick}>
        <Modal onClose={this.onClose}>
          <Header onClose={this.onClose}>Invite collaborators to Workspace</Header>
          <Content>
            {this.state.userEditorStates === UserEditorStates.WITH_ERROR && (
              <div className="error-message-container">Failed to invite collaborator to HubSync</div>
            )}
            <div className={this.props.classes.row}>
              <div className={this.props.classes.field}>
                <div className={this.props.classes.fieldLabel}>
                  <Typography variant={TypographyVariant.FieldLabel}>
                    Invite collaborators
                  </Typography>
                </div>
                <div className={`${this.props.classes.inviteSelect} ${this.state.inputFocused ? this.props.classes.inputFocused : ''}`}>
                  {this.renderAutocomplete()}
                </div>
                {this.state.emailValidationError && (
                  <Typography variant={TypographyVariant.ModalText} className={this.props.classes.errorMessage}>
                    Email is not valid
                  </Typography>
                )}
              </div>
              <div className={this.props.classes.field}>
                <div className={this.props.classes.fieldLabel}>
                  <Typography variant={TypographyVariant.FieldLabel}>
                    Role
                  </Typography>
                </div>
                <div className={`${this.props.classes.rowWithSpaceInBetween} ${this.props.classes.relative}`}>
                  <div>
                    <div className={this.props.classes.customSelectWrapper}>
                      <Button
                        id="btnSelectRole"
                        className={this.props.classes.customSelectButton}
                        aria-controls="role-select-menu"
                        aria-haspopup="true"
                        variant={ButtonVariant.DropDown}
                        color="primary"
                        onClick={this.handleClick}
                        label={roles[this.state.role].title}
                      />
                      <ArrowDropDownIcon />
                    </div>
                    <Menu
                      id="role-select-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      className={this.props.classes.customSelectMenu}
                    >
                      {roles.map((role, index) => (
                        <MenuItem
                          key={index}
                          selected={index === this.state.role}
                          onClick={(event) => this.onRoleSelect(event, index)}
                          className={this.props.classes.customSelectMenuItem}
                        >
                          <div className={this.props.classes.checkedRoleIcon}>
                            {
                              index === this.state.role &&
                              <CheckIcon />
                            }
                          </div>
                          <ListItemText className={this.props.classes.customSelectMenuItemText} primary={role.title} secondary={role.content} />
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className={this.props.classes.field}>
              <div className={this.props.classes.fieldLabel}>
                <Typography variant={TypographyVariant.FieldLabel}>
                  Message (optional)
                </Typography>
              </div>
              <div>
                <textarea
                  value={this.state.message}
                  onChange={this.onChangeMessage}
                  className={this.props.classes.textarea}
                />
                <Typography variant={TypographyVariant.ModalText} className={this.props.classes.errorMessage}>
                  {this.state.messageValidationError ? 'Message should be between 5 and 1000 characters' : ' '}
                </Typography>
              </div>
            </div>
            <div className={this.props.classes.field}>
              <div className={`${this.props.classes.rowWithSpaceInBetween} ${this.props.classes.relative}`}>
                <Button
                  id="btnCancelInvite"
                  label="Cancel"
                  onClick={this.onClose}
                  variant={ButtonVariant.SecondaryLink}
                />
                <Button
                  id="btnSendInvite"
                  label="Send Invite"
                  onClick={this.inviteUsers}
                  variant={ButtonVariant.Primary}
                  disabled={this.state.userEditorStates === UserEditorStates.FETCHING || this.hasInvalidEmailAddress()}
                />
              </div>
            </div>
          </Content>
        </Modal>
      </div>
    );
  }

  private renderAutocomplete = () => {
    return (
      <Autocomplete
        type="account"
        placeholder=""
        allowCreation
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        suggestions={this.getUserSuggestions()}
        onActiveItem={this.onActiveUser}
        onChange={this.onUserSelect}
        selectedItems={this.state.selectedUsers}
        renderSelectedItem={props => {
          const user = this.props.users.find(user => user.id === props.item);
          const email = user?.email || props.item;
          let className = this.props.classes.chip + ` ${[user?.id, props.item].includes(this.state.activeItem) ? this.props.classes.activeItem : ''}`;
          const suggestion = (className: string) => {
            return <div key={props.item} className={className}>
              <span>{email}</span>
              <IconButton
                icon={svgIcons.Close}
                color={IconButtonColor.BlueyGrey}
                size={Size.ExtraSmall}
                onClick={props.deleteItem}
              />
            </div>;
          };
          if (!emailIsValid(email)) {
            className += ` ${this.props.classes.invalidChip}`;
            return (
              <Tooltip key={props.item} title={<span className={this.props.classes.inviteUserTooltip}>This is not a valid email address.</span>}>
                {suggestion(className)}
              </Tooltip>
            );
          }
          return suggestion(className);
        }}
        renderSuggestion={props => {
          const suggestionClassname = props.highlightedIndex === props.index ? props.classes.activeItem : props.classes.item;
          if (props.suggestion.type === 'add') {
            return (
              <div key={props.index} className={`${this.props.classes.inviteUserSuggestionAdd} ${suggestionClassname}`} onClick={() => props.handleClick(props.suggestion.id || props.suggestion._id ) }>
                <IconButton
                  icon={svgIcons.Add}
                  color={IconButtonColor.Blue}
                  size={Size.Large}
                  onClick={props.deleteItem}
                />
                Invite {`"${props.suggestion.label}"`} via email
              </div>
            );
          }
          return (
            <div key={props.index} className={suggestionClassname} onClick={() => props.handleClick(props.suggestion.id || props.suggestion._id) }>
              <AccountChip accountId={props.suggestion.id} showName showEmail className={props.inviteUserAccountChip} />
            </div>
          );
        }}
      />
    );
  };

  private onFocus = () => {
    this.setState({ inputFocused: true });
  };

  private onBlur = () => {
    this.setState({ inputFocused: false });
  };

  private getUserSuggestions = (): Suggestion[] => {
    return this.props.filteredUsers
      .filter((user: AccountNode): boolean => {
        return !(this.state.selectedUsers as string[]).includes(user.id);
      })
      .map((user): Suggestion => {
        return {
          _id: user._id,
          id: user.id,
          label: user.email,
          name: user.displayName?.toLowerCase(),
        };
      });
  };

  private onActiveUser = (item: string | null): void => {
    this.setState({ activeItem: item });
  };

  private hasInvalidEmailAddress = () => {
    if (this.state.selectedUsers?.length === 0) {
      return true;
    }
    const emails = this.mapUserIdToEmail();
    return emails.some((email: string) => !emailIsValid(email));
  };

  private mapUserIdToEmail = (): string[] => {
    return this.state.selectedUsers.map((id: string): string => {
      const user = this.props.users.find(user => user.id === id);
      if (user?.email) {
        return user.email;
      }
      return id;
    });
  };

  private onUserSelect = (value: string[]) => {
    this.setState({ selectedUsers: value });
  };

  private onRoleSelect = (event: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({ role: index, anchorEl: null });
  };

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private onClose = (): void => {
    this.props.history.push(`/workspaces/${this.props.match.params.workspaceId}/accounts`);
  };

  private onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({
      message: event.target.value,
      messageValidationError: this.state.messageValidationError && false,
    });
  };

  private inviteUsers = () => {
    const message = this.state.message.trim();
    if (message.length && (message.length < 5 || message.length > 1000)) {
      this.setState({ messageValidationError: true });
      return;
    }

    this.setState({ userEditorStates: UserEditorStates.FETCHING });
    this.props.inviteUsers({
      role: this.props.roles.find(role => role.name === roles[this.state.role].title) || { id: '', name: roles[this.state.role].title },
      message,
      workspaceID: this.props.match.params.workspaceId,
      email: uniq(this.mapUserIdToEmail()),
    });
  };

  private validateEmailField = (email: string): boolean => {
    const emailToArray = email.split(/[ ,]+/).filter(email => email);
    return emailToArray.every(email => emailIsValid(email));
  };

  private onAllowedEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ allowedEmail: event.target.value });
  };

  private showRoleInfoBox = (event: React.MouseEvent): void => {
    event.stopPropagation();
    this.setState({ showRoleInfoBox: true });
  };

  private hideRoleInfoBox = (): void => {
    this.setState({ showRoleInfoBox: false });
  };

  private showLinkAccessLevelInfoBox = (event: React.MouseEvent): void => {
    event.stopPropagation();
    this.setState({ showLinkAccessLevelInfoBox: true });
  };

  private hideLinkAccessLevelInfoBox = (): void => {
    this.setState({ showLinkAccessLevelInfoBox: false });
  };

  private onClick = (): void => {
    this.hideRoleInfoBox();
    this.hideLinkAccessLevelInfoBox();
  };
}

export const emailIsValid = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const mapDispatchToProps: MapDispatchToProps = {
  inviteUsers,
  successToast: notifications.success,
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    accountMode: state.account.accountMode,
    permissions: state.users.user.permissions,
    roles: (state.workspaces.current && state.workspaces.byId[state.workspaces.current].roles) || [],
    users: getAccounts(state.app.accounts) || [],
    workspaceUsers: state.account.workspaceUsers,
    filteredUsers: filterUsers(getAccounts(state.app.accounts) || [], state.account.workspaceUsers),
  };
};

const filterUsers = (tenantUsers: AccountNode[], workspaceUsers: AccountNode[]): AccountNode[] => {
  const workspaceUsersId = workspaceUsers.map(user => user.id);
  return tenantUsers.filter(user => !workspaceUsersId.includes(user.id));
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserEditor));
