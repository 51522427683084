function checkboxChecked() {
  const className = 'boolean-renderer--wrapper--checkbox-checked';
  const svgChecked = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgChecked.setAttribute('class', className);
  svgChecked.setAttribute('height', 10);
  svgChecked.setAttribute('width', 10);
  svgChecked.setAttribute('viewBox', '0 0 10 10');

  const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.id = 'checkbox-checked';
  path.setAttributeNS(null, 'd', 'M8.889 0H1.11C.494 0 0 .5 0 1.111V8.89A1.11 1.11 0 0 0 1.111 10H8.89C9.506 10 10 9.5 10 8.889V1.11A1.11 1.11 0 0 0 8.889 0zm-4.98 7.163L1.528 4.88l.671-.644 1.71 1.635 3.614-3.465.671.648L3.91 7.163z');
  defs.appendChild(path);
  svgChecked.appendChild(defs);

  const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#checkbox-checked');
  use.setAttributeNS(null, 'fill', 'currentColor');
  use.setAttributeNS(null, 'fill-rule', 'evenodd');
  svgChecked.appendChild(use);
  return svgChecked;
}

export default checkboxChecked;
