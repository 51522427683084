import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { Title } from 'components/PageHeader/PageHeader.styles';
import { styles } from './MessagesPage.styles';
import Conversation from './Conversation';
import { connect } from 'react-redux';
import { resetMessages } from 'data/messages/actions';

interface DispatchProps {
  resetMessages: () => void;
}

type OwnProps = RouteComponentProps<string[]>;

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

class MessagesPage extends React.Component<Props> {
  public componentWillUnmount(): void {
    this.props.resetMessages();
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        <PageHeader titleContent={<Title>Messages</Title>} />
        <div className={this.props.classes.wrapper}>
          <Conversation
            workspaceId={this.props.match.params[0]}
            channelId={this.props.match.params[0]}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps: DispatchProps = {
  resetMessages,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(MessagesPage));
