import * as React from 'react';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import Checkbox from 'components/Checkbox';
import { withStyles, WithStyles } from '@material-ui/core';
import svgIcons from 'styles/svgIcons';
import { styles } from './Checklist.style';
import * as types from 'data/collections/collections.types';
import IconButton, { Color, Size } from 'components/IconButton';
import TextField from '@material-ui/core/TextField';

export enum ChecklistStates {
  BROWSE = 'browse',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

interface OwnProps {
  index: number;
  item: types.ChecklistItem;
  removeItem: () => void;
  toggleItem: () => void;
  updateItem: (item: types.ChecklistItem) => void;
  permissions?: string[];
}

interface State {
  itemState: ChecklistStates;
  item: types.ChecklistItem;
}

type Props = OwnProps & WithStyles<typeof styles>;

const initialState = {
  itemState: ChecklistStates.BROWSE,
  item: {
    id: '',
    name: '',
    completed: false,
  },
};

export class ChecklistItem extends React.Component<Props, State> {
  private updateItemRef = React.createRef<HTMLInputElement>();
  state = { ...initialState };

  public render(): JSX.Element {
    const checklistItem = (
      <div className={`${this.props.classes.checklistItemWrapper}`}>
        {this.state.itemState !== ChecklistStates.UPDATE && (
          <div className={this.props.classes.rowWithSpace}>
            <div className={this.props.classes.rowBrowse}>
              <div className={this.props.classes.checklistItemCheckboxWrapper}>
                <Checkbox
                  onClick={() => {
                    if ((this.props.permissions || []).includes('checklists:update')) {
                      this.props.toggleItem();
                    }
                  }}
                  selected={this.props.item.completed}
                />
              </div>
              <div onClick={this.editItem} className={`${this.props.classes.textFieldWrapper} ${this.props.item.completed ? this.props.classes.lineThroughText : ''}`}>
                <Typography variant={TypographyVariant.FieldLabel} className={this.props.classes.fieldLabel}>
                  {this.props.item.name}
                </Typography>
              </div>
            </div>

            <div className={`${this.props.classes.contextMenuWrapper} ${this.props.classes.itemContextMenuWrapper}`}>
              {(this.props.permissions || []).includes('checklists:delete') && (
                <IconButton
                  icon={svgIcons.Remove}
                  color={Color.LightGray}
                  size={Size.Medium}
                  onClick={this.props.removeItem}
                  className="checklist-delete-item"
                />
              )}
            </div>
          </div>
        )}

        {this.state.itemState === ChecklistStates.UPDATE && (
          <div className={this.props.classes.rowEdit}>
            <div className={this.props.classes.checklistItemCheckboxWrapper}>
              <Checkbox selected={this.props.item.completed} />
            </div>
            <div className={this.props.classes.textFieldWrapper}>
              <TextField
                inputRef={this.updateItemRef}
                value={this.state.item.name}
                className={this.props.classes.textFieldRoot}
                inputProps={{ className: this.props.classes.textField }}
                onChange={this.onItemChange}
                onKeyDown={this.onChecklistItemKeyDown}
                onBlur={this.onBlur}
              />
            </div>
            <div className={`${this.props.classes.contextMenuWrapper} ${this.props.classes.itemContextMenuWrapper}`}>
              <IconButton
                icon={svgIcons.Remove}
                color={Color.LightGray}
                size={Size.Medium}
                onClick={this.props.removeItem}
              />
            </div>
          </div>
        )}
      </div>
    );

    return checklistItem;
  }

  private onBlur = (): void => {
    this.setState({ itemState: ChecklistStates.BROWSE });
  };

  private onItemChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      item: {
        ...this.state.item,
        name: event.target.value,
      },
    });
  };

  private editItem = (): void => {
    if (!(this.props.permissions || []).includes('checklists:update')) return;
    this.setState({
      itemState: ChecklistStates.UPDATE,
      item: { ...this.props.item },
    });
    this.focusInput();
  };

  private focusInput = (): void => {
    setTimeout(() => {
      if (this.updateItemRef.current) {
        this.updateItemRef.current.select();
      }
    }, 0);
  };

  private onChecklistItemKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.saveItem();
        return;
      case 'Escape':
        this.setState({ itemState: ChecklistStates.BROWSE });
        event.stopPropagation();
        return;
      default: break;
    }
  };

  saveItem = (): void => {
    const name = this.state.item.name.trim();
    if (name.length < 1 || name.length > 100) return;
    this.props.updateItem(this.state.item);
    this.setState({ itemState: ChecklistStates.BROWSE });
  };
}

export default withStyles(styles)(ChecklistItem);
