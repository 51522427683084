import * as React from 'react';
import { connect } from 'react-redux';

import { AttachmentPayload } from 'data/attachments/types';
import { deleteAttachment } from 'data/attachments/actions';

import Confirm from '../Confirm';

interface DispatchProps {
  onDelete(payload: AttachmentPayload): void;
}

interface OwnProps {
  onClose(): void;
  id: string;
  uri: string;
}

export type Props =
  & OwnProps
  & DispatchProps
  ;

function DeleteAttachment(props: Props): JSX.Element {
  function onConfirm(): void {
    const payload: AttachmentPayload = {
      id: props.id,
      uri: props.uri,
    };

    props.onDelete(payload);
    props.onClose();
  }

  return (
    <Confirm
      title="Are you sure you want to delete this attachment?"
      close={props.onClose}
      onConfirm={onConfirm}
      confirmLabel="Delete"
    />
  );
}

const mapDispatchToProps: DispatchProps = {
  onDelete: deleteAttachment,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteAttachment);
