import MenuItem, { MenuItemClassKey } from '@material-ui/core/MenuItem';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import { FieldType } from '../../data';
import Typography, { Variant } from '../../../Typography';

interface OwnProps {
  query: string;
  fieldTypes: FieldType[];
  renderMenuItem(fieldType: FieldType): JSX.Element;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function FieldMenuItems(props: Props): JSX.Element {
  if (props.fieldTypes.length) {
    return (
      <React.Fragment>
        {props.fieldTypes.map(props.renderMenuItem)}
      </React.Fragment>
    );
  }

  const { label, ...menuItemClasses } = props.classes;

  return (
    <MenuItem classes={menuItemClasses}>
      <Typography variant={Variant.MenuItem} className={label}>
        {`No field types matching ${props.query}`}
      </Typography>
    </MenuItem>
  );
}

type ClassKey
  = 'label'
  | Extract<MenuItemClassKey, 'root'>
  ;

const styles = withStyles<ClassKey>({
  label: { margin: 'auto' },
  root: { padding: '0 6px' },
});

export default styles(FieldMenuItems);
