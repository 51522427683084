import styled, { css, StyledFunction } from 'styled-components';

import svgIcons from 'styles/svgIcons';

export const ColumnContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ColumnLabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

export const DisplayName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ColumnIconWrapperProps {
  iconOnly: boolean;
}

const span: StyledFunction<ColumnIconWrapperProps & any> = styled.span;

export const ColumnIconWrapper = span`
  display: flex;
  align-items: center;
  ${(props: any) => props.iconOnly ? 'margin: 0 auto;' : ''};
  svg {
    height: 13px;
    width: 13px;
  }

  & + ${DisplayName} {
    margin-left: 5px;
  }
`;

export const MenuButtonWrapper = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: -15px;

  span {
    display: flex;
    align-items: center;
  }
`;

export const DropdownMenuIcon = styled(svgIcons.Dropdown)`
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin: 0 6px;
`;

const iconCss = css`
  margin-left: 10px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
`;

export const FilterIcon = styled(svgIcons.Filter)`${iconCss}`;
export const SortAscendingIcon = styled(svgIcons.ArrowUp)`${iconCss}`;
export const SortDescendingIcon = styled(svgIcons.ArrowDown)`${iconCss}`;
export const PinnedIcon = styled(svgIcons.PinColumnV2)`${iconCss}`;
