import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CancelIcon from '@material-ui/icons/Cancel';
import { Dictionary } from 'lodash';

import { ButtonFieldActions } from 'components/NodeFieldData/button/types';
import { SvgIcon } from 'types/common';
import { MergeObjects as MergeTypes } from 'utilities/types';

import OpenURL from './Actions/OpenURL';

// TODO: move this to a dropdown folder component
export interface DropdownItem {
    id: string;
    title: string;
    action?: () => void;
    Icon?: SvgIcon;
    keepMenuOpenAfterClick?: boolean;
}

export interface OptionalComponentProps {
    buttonFieldCallback: (valid: boolean, additionalData?: Dictionary<string>) => void;
}

export interface ActionItem<T = {}, K = {}> extends DropdownItem {
    OptionalComponent?: React.ElementType<MergeTypes<OptionalComponentProps, T>>;
    rest?: K;
}

export const ACTION_ITEMS: ActionItem<{}>[] = [
  {
    id: ButtonFieldActions.OPEN_URL,
    title: 'Open URL',
    Icon: OpenInNewIcon,
    OptionalComponent: OpenURL,
  },
  {
    id: ButtonFieldActions.CREATE_WORKSPACE,
    title: 'Create Workspace',
    Icon: OpenInNewIcon,
  },
  {
    id: ButtonFieldActions.CREATE_ENGAGEMENT_LETTER,
    title: 'Create Engagement Letter',
    Icon: OpenInNewIcon,
  },
  {
    id: ButtonFieldActions.CANCEL_ENVELOPE,
    title: 'Cancel Envelope',
    Icon: CancelIcon,
  },
  {
    id: ButtonFieldActions.RESEND_ENVELOPE,
    title: 'Resend Envelope',
    Icon: CancelIcon,
  }];
