import React, { useState, FC, ReactElement } from 'react';
import { connect } from 'react-redux';

import Modal, { FluidModal } from './Modal';
import * as ModalsActions from '../../data/modals/actions';
import { ModalType, State as ModalsState } from '../../data/modals/state';
import { State } from '../../reducers';
import { BaseModal } from 'components/Modals/BaseModal';

interface DispatchProps {
  closeModal(): void;
}

interface StateProps {
  modals: ModalsState;
}

type Props = StateProps & DispatchProps;

export const Modals: FC<Props> = (props: Props): ReactElement | null => {
  const [checkClose, setCheckClose] = useState(false);
  if (props.modals.type === ModalType.NO_MODAL || (props.modals.type === ModalType.DELETE_ITEMS && props.modals.ids.length === 0)) {
    if (checkClose && props.modals.type === ModalType.NO_MODAL) {
      setCheckClose(false);
    }
    return null;
  }

  if (props.modals.type === ModalType.DELETE_ATTACHMENT) {
    return null;
  }

  if (props.modals.type === ModalType.NODE_VIEW) {
    const handleCustomClose = () => {
      setCheckClose(true);
    };

    const handleOnExit = () => {
      setCheckClose(false);
    };

    return (
      <FluidModal onClose={handleCustomClose}>
        <BaseModal closeModal={props.closeModal} modals={props.modals} checkClose={checkClose} resetCheckClose={handleOnExit} />
      </FluidModal>
    );
  }

  return (
    <Modal onClose={props.closeModal}>
      <BaseModal closeModal={props.closeModal} modals={props.modals}/>
    </Modal>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  modals: state.modals,
});

const mapDispatchToProps: DispatchProps = {
  closeModal: ModalsActions.closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
