import { FieldRendererTypes } from '../../NodeFieldData';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import { getFormattedDate } from '../../NodeFieldData/datetime/DateTime.utils';
import { createSingleLineOfTextElement } from '../singlelineoftext/SingleLineOfTextRenderer';
import moment from 'moment';
import * as colors from '../../../styles/colors';
import clockIcon from './clock.svg';
import { buildKey as buildReminderKey } from '../../../data/reminders/reminders';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';
import BaseRenderer from '../BaseRendererPrototype';

let store;

const getReminders = () => {
  store = window.appStore;
  if (!store) return;
  return store.getState().reminders.reminders;
};

function DateTimeRenderer() {
  return true;
}

DateTimeRenderer.prototype = Object.create(BaseRenderer.prototype);

const isScheduled = (params) => {
  const currentReminders = getReminders();
  if (!currentReminders) return false;
  const recordID = params['data'] && params['data'].id;
  const fieldID = params['column'] && params['column'].colId.replace('fields.', '');
  const reminderKey = recordID && fieldID && buildReminderKey({ recordID, references: { fieldID } });
  const reminders = currentReminders[reminderKey];
  return reminders?.find(reminder => reminder.isScheduled);
};

DateTimeRenderer.prototype.init = function(params) {
  this.eGridCell = params.eGridCell;
  const { type, value, fieldType } = params;
  const container = document.createElement('div');
  container.id = `${params.node.id}-${params.column.colId}`;
  container.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  `;

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    const span = document.createElement('span');
    span.setAttribute('class', getColumnHeaderClass(fieldType));
    span.setAttribute('title', getFormattedDate(value, params));
    container.appendChild(span);
    this.element = container;
    return;
  }
  const scheduled = isScheduled(params);
  const formattedDate = getFormattedDate(value && value.timestamp ? value.timestamp : value, params);
  const color = scheduled ? moment(formattedDate).isSameOrAfter(moment(), 'day') ? colors.hubsyncBlue : colors.redOrange : undefined;
  const { valueElement, element } = createSingleLineOfTextElement({ ...params, width: 'auto', value: formattedDate });

  if (formattedDate && scheduled) {
    this.clockIcon = clockIcon();
    this.clockIcon.style.color = color;
    element.appendChild(this.clockIcon);
  }

  if (this.eGridCell) {
    this.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(store.getState()).quickSearch;
    if (currentQuickSearch && formattedDate?.includes(currentQuickSearch)) {
      this.eGridCell.classList.add('highlighted-cell');
    }
  }

  if (valueElement) this.valueElement = valueElement;
  this.element = element;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

DateTimeRenderer.prototype.getGui = function() {
  return this.element;
};

DateTimeRenderer.prototype.refresh = function() {
  return false;
};

DateTimeRenderer.prototype.destroy = function() {
  return true;
};

export default DateTimeRenderer;
