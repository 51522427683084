import { FieldType } from '../../../types/response/fieldNode';
import { columnDefinition as idColumnDef } from '../../Files/GridOptions/IdColumnDef';
import { ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import TextFilter from './filters/TextFilter';
import DateFilter from './filters/DateFilter';
import ChoiceFilter from './filters/ChoiceFilter';
import TextFloatingFilter from './filters/TextFloatingFilter';
import DateFloatingFilter from './filters/DateFloatingFilter';
import ChoiceFloatingFilter from './filters/ChoiceFloatingFilter';
import { getDateQuickFilterText } from './quick-filter';


export default [
  {
    ...idColumnDef,
    type: FieldType.ID,
    width: 75,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellRenderer: 'id',
    valueGetter: 'node.rowIndex + 1',
  },
  {
    colId: 'name',
    width: 282,
    field: 'displayName',
    type: FieldType.Singlelineoftext,
    headerName: 'Name',
    filter: TextFilter,
    floatingFilterComponentFramework: TextFloatingFilter,
  },
  {
    colId: 'email',
    width: 240,
    field: 'email',
    type: FieldType.Singlelineoftext,
    headerName: 'Email',
    filter: TextFilter,
    floatingFilterComponentFramework: TextFloatingFilter,
  },
  {
    colId: 'lastLogin',
    width: 240,
    field: 'lastLogin',
    type: FieldType.DateTime,
    headerName: 'Last Log In (local time)',
    cellRenderer: 'datetime',
    cellRendererParams: {
      includeTime: true,
      dateFormat: 'MM/DD/YYYY',
    },
    filter: DateFilter,
    floatingFilterComponentFramework: DateFloatingFilter,
    getQuickFilterText: getDateQuickFilterText,
  },
  {
    colId: 'role',
    width: 240,
    field: 'role',
    type: FieldType.Singlechoice,
    headerName: 'Role',
    cellRenderer: 'singleChoice',
    cellEditor: 'singleChoiceEditor',
    editable: true,
    filter: ChoiceFilter,
    valueGetter: (event: ValueGetterParams) => {
      return event.column.getColDef().cellEditorParams.choices[event.data.role]?.label || '';
    },
    valueSetter: (event: ValueSetterParams) => {
      const role = event.column.getColDef().cellEditorParams.choices[event.newValue];
      if (!role || role?.label === event.oldValue) return false;
      event.data.role = event.newValue;
    },
    cellEditorParams: {
      allowMultiple: false,
      fieldType: FieldType.Singlechoice,
    },
    floatingFilterComponentFramework: ChoiceFloatingFilter,
  },
];
