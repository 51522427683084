import { dark } from 'styles/colors';

export default (lines = 1, color = dark, fontSize = '14px', width = '100%') => {
  let style = `
    line-height: 17px;
    width: ${width || '100%'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${fontSize || '14px'};
    display: ${lines === 1 ? 'block': 'flex' };
    color: ${color || dark};
    height: auto;
  `;

  if (lines > 1) {
    style += `
      line-height: normal;
      white-space: pre-wrap;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${lines};
    `;
  }

  return style;
};
