import * as Data from 'components/Fields/data';
import { push } from 'connected-react-router';
import { fetchNavigationTree } from 'data/app/actions';
import { location as locationSelector } from 'data/app/selectors';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import {
  deleteDatabaseFailure,
  deleteDatabaseRequest,
  deleteDatabaseSuccess,
  updateDatabaseFailure,
  updateDatabaseRequest,
  updateDatabaseSuccess,
  importDatabaseSuccess,
  ImportDatabase,
  FetchImportedDatabase,
  SaveImportedDatabase,
  importDatabaseError,
} from 'data/databases/databases.actions';
import LoadingState from 'data/LoadingState';
import { getApiV2Url } from 'env';
import { path, prop } from 'lodash/fp';
import { State } from 'reducers';
import { delay } from 'redux-saga';
import { all, call, fork, select, put, takeEvery } from 'redux-saga/effects';
import * as request from 'superagent';
import { CollectionTypes } from 'types/schema';
import { isDatabasesUrl, isTaskDBUrl } from 'utilities/createUrl';
import getAuthHeaders from 'utilities/getAuthHeaders';
import { createAPIHandler, RequestType } from 'utilities/saga';

import { createWorkspaceUrl } from '../../utilities/createUrl';
import { parseURI } from '../../utilities/parseURI';
import { mapDatabaseResponse, mapWorkspaceResponse } from '../collections/collections.mapper';
import * as constants from './databases.constants';

function* updateDatabaseSaga() {
  yield createAPIHandler({
    actions: {
      request: updateDatabaseRequest,
      success: updateDatabaseSuccess,
      failure: updateDatabaseFailure,
    },
    requestType: RequestType.Patch,
    buildUrl: (action) => {
      const { workspaceId, databaseId } = parseURI(action.payload.url);
      // fizz-buzz
      if (databaseId) {
        return `/workspaces/${workspaceId}/databases/${databaseId}?api_version=2`;
      } else if (workspaceId) {
        return `/workspaces/${workspaceId}?api_version=2`;
      }
      return action.payload.url;
    },
    buildData: (action, state) => {
      let version = 1;
      const { workspaceId, databaseId, dbSlug } = parseURI(action.payload.url);
      // fizz-buzz
      if (databaseId) {
        if (dbSlug === CollectionTypes.databases) {
          version = state.databases.byId[databaseId] && state.databases.byId[databaseId].version;
        } else {
          version = state.taskDatabases.byId[databaseId] && state.taskDatabases.byId[databaseId].version;
        }
      } else if (workspaceId) {
        version = state.workspaces.byId[workspaceId] && state.workspaces.byId[workspaceId].version;
      }
      return { ...prop('payload.data', action), version };
    },
    successPayloadMapper: (data, action) => {
      const { workspaceId, databaseId } = parseURI(action.payload.url);
      // fizz-buzz
      if (databaseId) {
        return mapDatabaseResponse(data);
      } else if (workspaceId) {
        return mapWorkspaceResponse(data);
      }

      return path(['body', 'node'], data);
    },
    successMetaMapper: (_, action) => action.meta,
    * onSuccess(payload, action, _, meta) {
      if (meta === CollectionTypes.workspaces) {
        yield put(fetchNavigationTree());
      }
    },
  });
}

function* deleteDatabaseSaga() {
  yield createAPIHandler({
    actions: {
      request: deleteDatabaseRequest,
      success: deleteDatabaseSuccess,
      failure: deleteDatabaseFailure,
    },
    buildUrl: (action) => {
      const { workspaceId, databaseId } = parseURI(action.payload.uri);
      return `workspaces/${workspaceId}/databases/${databaseId}?api_version=2`;
    },
    requestType: RequestType.Delete,
    successPayloadMapper: (_, action) => action.payload,
    successMetaMapper: (_, action) => action.meta,
    * onSuccess(payload, action, _, meta) {
      const { pathname }: Location = yield select(locationSelector);
      if (!isDatabasesUrl(pathname) && !isTaskDBUrl(pathname)) {
        const matches = pathname.match(/((databases|taskdbs)\/).*$/);
        if (matches) {
          const newPathname = pathname.replace(matches[0], matches[2]);
          yield put(push(newPathname));
        }
      }
      if (meta === CollectionTypes.workspaces) {
        yield put(fetchNavigationTree());
      }
    },
  });
}

function* fetchImportedDatabaseSaga(action: FetchImportedDatabase) {
  yield delay(1000);
  const state: State = yield select();
  const parseFileEndpoint = `${getApiV2Url()}/workspaces/${state.workspaces.current}/databases/import-data/parse/${action.fileName}`;
  const headers = yield getAuthHeaders();

  let parsedFileResponse;
  try {
    parsedFileResponse = yield call(() => request.get(parseFileEndpoint).set(headers));
  } catch (error) {
    yield put(importDatabaseError());
    return;
  }

  const columns = parsedFileResponse.body.columns;
  const importedDatabase = {
    loadingState: LoadingState.Loaded,
    importedDatabase: {
      id: action.fileName,
      title: action.title,
      columns: (columns && columns.map(column => {
        return {
          ...column,
          resizable: true,
          type: Data.FieldType.SingleLineText,
        };
      })) || [],
      rows: parsedFileResponse.body.rows || [],
      originalFilename: action.originalFilename,
    },
  };
  yield put(importDatabaseSuccess(importedDatabase));
}

function* importDatabaseSaga(action: ImportDatabase) {
  const state: State = yield select();
  const signUrlEndpoint = `${getApiV2Url()}/workspaces/${state.workspaces.current}/databases/import-data/sign-url?contentType=${action.attachment.file.type}`;
  let signedUrlResponse;
  const headers = yield getAuthHeaders();
  try {
    signedUrlResponse = yield call(() => request.get(signUrlEndpoint).set(headers));

    yield fetch(
      new Request(signedUrlResponse.body.data.signedUrl, {
        method: 'PUT',
        body: action.attachment.file,
        headers: new Headers({
          'Content-Type': action.attachment.file.type,
        }),
      }),
    );
  } catch (error) {
    yield put(importDatabaseError());
    return;
  }

  yield fetchImportedDatabaseSaga({
    type: constants.FETCH_IMPORTED_DATABASE,
    originalFilename: action.attachment.file.name,
    fileName: signedUrlResponse.body.data.fileName,
    title: action.data.title,
  });
}

function* watchImportDatabaseSaga() {
  yield takeEvery(constants.IMPORT_DATABASE, importDatabaseSaga);
}

function* watchFetchImportedDatabaseSaga() {
  yield takeEvery(constants.FETCH_IMPORTED_DATABASE, fetchImportedDatabaseSaga);
}

function* saveImportedDatabaseSaga(action: SaveImportedDatabase) {
  const state: State = yield select();
  const saveDatabaseEndpoint = `${getApiV2Url()}/workspaces/${state.workspaces.current}/databases/import-data/save/${action.id}`;
  const headers = yield getAuthHeaders();
  try {
    yield call(() =>
      request.post(saveDatabaseEndpoint)
        .send({
          workspaceId: state.workspaces.current,
          title: action.title,
          fields: action.fields,
        })
        .set(headers),
    );
  } catch (error) {
    yield put(importDatabaseError());
  }

  const workspaceId = state.workspaces.current;
  if (workspaceId) {
    const url = `${createWorkspaceUrl(workspaceId)}/${CollectionTypes.databases}`;
    yield put(fetchCollectionRequest({ url }));
  }
}

function* watchSaveImportedDatabase() {
  yield takeEvery(constants.SAVE_IMPORTED_DATABASE, saveImportedDatabaseSaga);
}

export default function* databasesSaga() {
  yield all([
    fork(updateDatabaseSaga),
    fork(deleteDatabaseSaga),
    watchImportDatabaseSaga(),
    watchFetchImportedDatabaseSaga(),
    watchSaveImportedDatabase(),
  ]);
}
