import { ActionType, createStandardAction } from 'typesafe-actions';
import { ModalNames } from 'data/ui/modal/modal.types';

export const openModal = createStandardAction('modal/OPEN_MODAL')<ModalNames>();
export const closeModal = createStandardAction('modal/CLOSE_MODAL')<ModalNames>();

export const actions = {
  openModal,
  closeModal,
};

export type Actions = ActionType<typeof actions>;

