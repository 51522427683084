import * as qs from 'qs';
import { State } from 'reducers';
import * as Effects from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import { DatabaseNode } from 'types/response/databaseNode';
import { PostResponse, Section } from 'types/response/http/post';
import { CollectionViewTypes } from 'types/schema';
import AjaxService from 'utilities/ajaxService';
import { escapeCharacters } from 'utilities/common';
import { getAuthToken } from 'utilities/getAuthHeaders';

import { ViewNode } from '../../types/response/viewNode';
import { postRequest } from '../../utilities/httpRequests';
import { errorToast, handleError, successToast } from '../../utilities/saga';
import * as CollectionsActions from '../collections/collections.actions.new';
import { mapViewResponse } from '../collections/collections.mapper';
import {
  columnsStateSelector,
  groupByFieldIDsSelector,
  rowHeightSelector,
} from '../collections/view-config/viewConfig.selectors';
import { prepareView } from '../views/helpers';
import { createView, selectView, setDefaultView } from '../views/views.actions';
import * as Actions from './actions';


// Only change the Pick type if you change the sections query below
type AjaxPostResponse = Pick<PostResponse<DatabaseNode>, Section.Node>;

const DUPLICATE_SUFIX = 'Copy';

/**
 * Computes title for the new duplicated database
 * Avoids to duplicate same title
 *
 * @param database - selected database to duplicate
 * @param databases - all databases in the workspace
 */
function getCopyTitle(database: DatabaseNode, databases: DatabaseNode[]) {
  const currentTitle = database.title;
  const safeTitle = escapeCharacters(currentTitle);
  const titleRoot = new RegExp(`${safeTitle} \\(${DUPLICATE_SUFIX}( \\d+)?\\)$`);
  const numOfCopiedDbs = databases.filter(
    (db) => db.id !== database.id && db.title.match(titleRoot),
  ).length;
  const newCopyNum = numOfCopiedDbs ? ` ${numOfCopiedDbs + 1}` : '';
  const titleSufix = `(${DUPLICATE_SUFIX}${newCopyNum})`;

  return `${currentTitle} ${titleSufix}`;
}

function* duplicateDatabase(action: Actions.DuplicateDatabase) {
  const { database, databases, shouldDuplicateRecords, shouldDuplicateComments, workspace } = action;
  const newTitle = getCopyTitle(database, databases);
  const query = qs.stringify({
    includeItems: shouldDuplicateRecords,
    includeMessages: shouldDuplicateComments,
    title: newTitle,
  });
  const url = `${workspace.apiURI}/${action.collectionType}/${database.id}/duplicate?${query}`;


  try {
    const response: AjaxPostResponse = yield Effects.call(
      ajaxPost,
      `Bearer ${yield getAuthToken()}`,
      url,
    );

    if (!response) {
      yield call(errorToast, `Database failed to duplicate.`);
    } else {
      yield call(successToast, `Database is being duplicated.`);
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      const duplicateDatabase: DatabaseNode = {
        ...database,
        title: newTitle,
        id: '',
        apiURI: '',
        downloadURI: undefined,
        inlineURI: undefined,
        linkURI: undefined,
        uri: '',
      };

      yield Effects.put(
        CollectionsActions.copyCollectionSuccess(duplicateDatabase, action.collectionType),
      );
      return;
    }

    yield Effects.call(handleError, error);
    yield Effects.put(
      CollectionsActions.copyCollectionFailure(error, action.collectionType),
    );
  }
}

function* saveNewGridView(action: Actions.SaveNewGridView) {
  const state: State = yield Effects.select();

  const defaultSheetId = state.sheets.currentId || '';
  const gridType = CollectionViewTypes.grid;

  const viewParams = prepareView({
    columns: columnsStateSelector(state),
    gridOptions: state.gridOptions,
    groupByFieldIDs: groupByFieldIDsSelector(state, gridType),
    height: rowHeightSelector(state),
    viewType: gridType,
  });

  const url = `${action.apiURI.replace('default', defaultSheetId)}/views?api_version=2`;

  const data: Partial<ViewNode> = {
    ...viewParams,
    title: action.viewName,
    isPrivate: action.isPrivate,
    isLocked: action.isLocked,
    isDefault: action.isDefault,
    type: gridType,
  };

  try {
    const result = yield Effects.call(postRequest, { url, data }, state);
    let permissions = [];
    if (state.collections.views.nodes) {
      const nodes = state.collections.views.nodes as any;
      permissions = (nodes.views && nodes.views[0] && nodes.views[0].permissions) || [];
    }
    const node: ViewNode = {
      ...mapViewResponse(result, url, {}),
      permissions,
    };
    yield Effects.put(createView.success(node));
    if (node.isDefault) {
      yield Effects.put(setDefaultView(node.id));
    }
    yield Effects.put(selectView(node.id));
  } catch (error) {
    yield Effects.call(handleError, error);
    yield Effects.put(createView.failure(error));
  }
}

function* watchDuplicateDatabase(): Iterable<Effects.ForkEffect> {
  yield Effects.takeEvery(Actions.ActionType.DUPLICATE_DATABASE, duplicateDatabase);
}

function* watchSaveNewGridView(): Iterable<Effects.ForkEffect> {
  yield Effects.takeEvery(Actions.ActionType.SAVE_NEW_GRID_VIEW, saveNewGridView);
}

export default function* modalsSagas(): Iterable<Effects.AllEffect | Effects.ForkEffect> {
  yield Effects.all([
    Effects.fork(watchDuplicateDatabase),
    Effects.fork(watchSaveNewGridView),
  ]);
}

async function ajaxPost(authToken: string, url: string): Promise<AjaxPostResponse> {
  const ajax = new AjaxService(authToken);

  const response = await ajax.post({ url, shouldAbort: true });

  return response.json();
}
