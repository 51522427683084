import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

type ClassKey =
  'root'
  | 'buttonDisabled'
  | 'buttonExtraSmall'
  | 'buttonSmall'
  | 'buttonMedium'
  | 'buttonLarge'
  | 'iconExtraSmall'
  | 'iconSmall'
  | 'iconMedium'
  | 'iconLarge'
  | 'iconBlue'
  | 'iconLightGray'
  | 'iconDarkGray'
  | 'iconDarkGrayNoHover'
  | 'iconBlueyGrey'
  | 'iconLightBlueGrey'
  | 'selected'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    'borderRadius': '50%',
    'lineHeight': 0,
    'padding': 0,
    '&:focus': {
      border: `1px solid ${Colors.hubsyncBlue}`,
    },
  },
  buttonExtraSmall: {
    'width': '16px',
    'height': '16px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  buttonSmall: {
    'width': '24px',
    'height': '24px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  iconExtraSmall: {
    'height': '8px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  iconSmall: {
    'height': '10px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  buttonMedium: {
    'width': '25px',
    'height': '26px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  iconMedium: {
    'height': '13px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  buttonLarge: {
    'height': '30px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  iconLarge: {
    'height': '16px',
    'width': '16px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  iconBlue: {
    'color': Colors.hubsyncBlue,
    '&:hover': {
      color: Colors.darkBlue,
    },
  },
  iconLightGray: {
    'color': Colors.lightBlueGrey,
    '&:hover': {
      color: Colors.slateTwo,
    },
  },
  iconDarkGray: {
    'color': Colors.slateTwo,
    '&:hover': {
      color: Colors.hubsyncBlue,
    },
  },
  iconDarkGrayNoHover: {
    'color': Colors.slateTwo,
  },
  iconBlueyGrey: {
    'color': Colors.blueyGrey,
    '&:hover': {
      color: Colors.blueGrey,
    },
  },
  iconLightBlueGrey: {
    'color': Colors.lightBlueGrey,
    '&:hover': {
      color: Colors.lightBlueGrey,
    },
  },
  selected: {
    backgroundColor: Colors.skyBlue,
    color: Colors.hubsyncBlue,
  },
  buttonDisabled: {
    cursor: 'default',
  },
};
