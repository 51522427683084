import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';

import { fixUrl } from '../../../../../utilities/createUrl';
import { styles } from './PreviewMode.style';

type StyleProps = WithStyles<typeof styles>;

interface PreviewModeProps {
  lock?: boolean;
  value?: string;
  onClick?: any;
  onFocus?: any;
}

class PreviewMode extends React.Component<StyleProps & PreviewModeProps> {
  render() {
    const { classes, lock } = this.props;
    return (
      <div className={classes.root}>
        <div
          className={`${classes.input} ${lock ? classes.locked: ''}`}
          onClick={() => (this.props.onClick() )}
          onFocus={() => (this.props.onFocus() )}
        >
          <a href={fixUrl('url', this.props.value)} target="_blank" rel="noopener noreferrer" className={classes.a}>{this.props.value}</a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PreviewMode);
