import * as qs from 'qs';
import { getApiV2Url } from 'env';
import { get, post, patch, deleteReq } from 'utilities/httpRequests';

const getMessagesURL = (workspaceID) =>
  `${getApiV2Url()}/workspaces/${workspaceID}/messages`;

export const fetchMessages = async (workspaceID, query) => {
  const queries = qs.stringify(query);
  const url = `${getMessagesURL(workspaceID)}?${queries}`;
  return await get(url);
};

export const createMessage = async (workspaceID, message) => {
  const url = getMessagesURL(workspaceID);
  return await post({ url, data: message });
};

export const patchMessage = async (workspaceID, messageID, messageBody) => {
  const url = `${getMessagesURL(workspaceID)}/${messageID}`;
  return await patch({ url, data: messageBody });
};

export const deleteMessage = async (workspaceID, messageID) => {
  const url = `${getMessagesURL(workspaceID)}/${messageID}`;
  return await deleteReq(url);
};
