import * as React from 'react';
import Modal from 'components/Modals/Modal';
import ModalHeader from 'components/Modals/Modal/Header';
import ModalContent from 'components/Modals/Modal/Content';
import { Notification } from './DatePickerNotify';
import Button, { Variant as ButtonVariant } from 'components/Button';
import svgIcons from 'styles/svgIcons';
import { useSelector } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { AccountNode } from 'types/response/accountNode';
import { ReactNode } from 'react';
import { Popper } from '@material-ui/core';
import AccountChip from 'components/AccountChip';
import { RoleNames } from 'data/users/users.types';
import { Role } from 'data/accounts/types';
// import moment from 'moment';
import { EventTypes } from './Reminder';
import moment from 'moment';
//
// const WEEK_DAY = {
//   0: 'Sunday',
//   1: 'Monday',
//   2: 'Tuesday',
//   3: 'Wednesday',
//   4: 'Thursday',
//   5: 'Friday',
//   6: 'Saturday',
// };

interface OwnProps {
  onClose: () => void;
  reminders?: Notification[];
  bulk?: boolean;
  addReminder: () => void;
  editReminder: (index: number) => void;
  confirmDelete: (index: number) => void;
  primaryFieldName?: string;
  format: string;
}

type Props = OwnProps;

const refs = {};

const ActiveReminders: React.FunctionComponent<Props> = (props: Props) => {
  const workspaceUsers = useSelector((state: ReduxState) => state.account.workspaceUsers.slice());
  const [activeIndex, setSelectedIndex] = React.useState<number | null>(null);
  const state = useSelector((state: ReduxState) => state);
  const user = state.users.user;
  const role = user?.roles?.find((role: Role) => role.workspaceId === state.workspaces.current) as unknown as Role;

  const getAccountName = (id?: string): string => {
    return workspaceUsers?.find((user: AccountNode) => user.id === id)?.displayName || '';
  };

  const getReminderUser = (reminder: Notification, index: number): string | ReactNode => {
    if (reminder.remindType === 'me') {
      return getAccountName(reminder.createdBy);
    }
    if (reminder.remindUsers?.length === 1) {
      return getAccountName(reminder.remindUsers[0]);
    }
    return <span className="reminder-shared-users" ref={ref => refs[index] = ref} onMouseOver={() => setSelectedIndex(index)} onMouseLeave={() => setSelectedIndex(null)}>
      Shared Users
    </span>;
  };

  // const getDate = (reminder: Notification) => {
  //   switch (reminder.eventType) {
  //     case EventTypes.Before:
  //       return moment(reminder.timestamp).subtract(reminder.qty, reminder.unit as moment.unitOfTime.DurationConstructor).format(props.format);
  //     case EventTypes.After:
  //       return moment(reminder.timestamp).add(reminder.qty, reminder.unit as moment.unitOfTime.DurationConstructor).format(props.format);
  //     default:
  //       return moment(reminder.timestamp).format(props.format);
  //   }
  // };

  return (
    <Modal onClose={props.onClose}>
      <ModalHeader onClose={props.onClose}>{props.bulk ? `Bulk Reminders` : `Reminders for ${props.primaryFieldName}`}</ModalHeader>
      <ModalContent>
        <div onClick={() => setSelectedIndex(null)}>
          {props.reminders?.map((reminder: Notification, index: number) => {
            return (
              <div className="reminder-item" key={index}>
                <div className="reminder-item-icon-container">
                  <svgIcons.HeaderNotifications />
                </div>
                <div className="reminder-item-content">
                  <div className="reminder-content">Send {getReminderUser(reminder, index)} a reminder on{reminder.eventType === EventTypes.On ? '' : ` ${reminder.qty} ${reminder.unit}${reminder.qty === 1 ? '' : 's'} ${reminder.eventType}`} {moment(reminder.timestamp).format(props.format)} for this record.</div>
                  <div className="reminder-creator">Set by {getAccountName(reminder.createdBy)}</div>
                  {((role?.name !== RoleNames.Reader && role?.name !== RoleNames.Editor) || (role?.name === RoleNames.Editor && reminder.createdBy === user.id)) && (
                    <div className="reminder-item-actions">
                      <Button label="Edit" variant={ButtonVariant.PrimaryLink} onClick={() => props.editReminder(index)} />
                      <Button label="Delete" variant={ButtonVariant.PrimaryLink} onClick={() => props.confirmDelete(index)} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {typeof activeIndex === 'number' && (
          <Popper open anchorEl={refs[activeIndex]} className="reminder-input-popup">
            <div className="reminder-item-users-list">
              {props.reminders?.[activeIndex]?.remindUsers?.map((id: string) => {
                return (
                  <AccountChip key={id} accountId={id} showName />
                );
              })}
            </div>
          </Popper>
        )}
      </ModalContent>
      {role?.name !== RoleNames.Reader && (
        <div className="reminder-action-buttons reminder-list-actions">
          <Button
            label="New Reminder"
            variant={ButtonVariant.Primary}
            icon={svgIcons.Add}
            onClick={props.addReminder}
          />
        </div>
      )}
    </Modal>
  );
};

export default ActiveReminders;
