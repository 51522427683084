import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { getAuthToken } from 'utilities/getAuthHeaders';
import { getAnalyticsEndpoint } from 'data/analytics/services';

export interface DashboardProps {
  id: string;
  dashboardId?: string;
  rootUrl: string;
  workspace: string | null;
  classes: any;
  height?: number;
}

const Dashboard = ({
  id,
  dashboardId,
  rootUrl,
  workspace,
  classes,
  height,
}: DashboardProps): JSX.Element => {
  const siteIdentifier = 'site/site1';
  const authorizationUrl = getAnalyticsEndpoint(workspace);
  let instanceBoldBI;

  async function loadBI() {
    const token = await getAuthToken();
    // @ts-ignore
    instanceBoldBI = BoldBI.create({
      serverUrl: rootUrl + siteIdentifier,
      dashboardId: dashboardId,
      embedContainerId: id,
      // @ts-ignore
      embedType: BoldBI.EmbedType.Component,
      // @ts-ignore
      mode: BoldBI.Mode.View,
      // @ts-ignore
      environment: BoldBI.Environment.Enterprise, // 'onpremise',
      width: '100%',
      height: `${height}px`,
      expirationTime: 100000,
      authorizationServer: {
        url: authorizationUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
    instanceBoldBI?.loadDashboard();
  }

  useEffect(()=>{
    if (height) {
      loadBI();
    }
    return () => {
      // @ts-ignore
      const instance = BoldBI.getInstance(id);
      instance?.destroy();
    };
  }, [height]);

  return (
    <div className={classes.BoldBIContainer}>
      <div id={id}></div>
    </div>
  );
};

type ClassKey
  = 'BoldBIContainer'
  ;

const styles = withStyles<ClassKey>({
  BoldBIContainer: {
    width: '100%',
    flex: '1',
  },
});

export default styles(Dashboard);
