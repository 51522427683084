import _ from 'lodash';
import { updateNode } from 'data/collections/collections.actions.new';
import { Actions } from 'data/collections/collections.actions';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { push } from 'connected-react-router';
import { parseURL } from 'utilities/queryParams';
import { CollectionTypes } from '../../../types/schema';

class ItemEntity {
  created(data) {
    const store = window.appStore;
    const state = store.getState();
    const databaseId = state.databases.current || state.taskDatabases.current;
    const nodesResult = state.collections.collections.viewport.nodes;
    const {
      eventBy,
      item,
    } = _.pick(data, ['eventBy', 'item']);

    const { user } = state.users;
    if (!user) {
      return;
    }

    if (user.id === eventBy || state.sheets.currentId !== data?.sheetId || state.workspaces.current !== data?.workspaceId || data?.databaseId !== databaseId) {
      return;
    }

    if (nodesResult.findIndex(node => node.id === `${item.id}`) === -1) {
      store.dispatch(Actions.createNodeSuccess({ node: { ...item, id: `${item.id}` } }));
    }
  }

  updated(data) {
    const store = window.appStore;
    const state = store.getState();
    const databaseId = state.databases.current || state.taskDatabases.current;
    if (state.sheets.currentId === data?.sheetId && state.workspaces.current === data?.workspaceId && data?.databaseId === databaseId) {
      const { item } = _.pick(data, ['item']);
      store.dispatch(updateNode.success({ node: { ...item, id: `${item.id}` }, updatedByCurrentUser: store.getState().users.user?.id === item?.modifiedBy }));
    }
  }

  deleted(data) {
    const store = window.appStore;
    const state = store.getState();
    const databaseId = state.databases.current || state.taskDatabases.current;

    if (state.sheets.currentId !== data?.sheetId || state.workspaces.current !== data?.workspaceId || data?.databaseId !== databaseId) {
      return;
    }

    const {
      item,
    } = _.pick(data, ['item']);

    let customRowOrder = {};
    let newCustomRowOrder = {};
    const nodesToDelete = [];
    try {
      const ids = [item.id];
      customRowOrder = state.collections.currentViewConfig.customRowOrder;
      const nodesResult = state.collections.collections.viewport.nodes;
      // Delete nodes in store
      ids.forEach((id) => {
        const i = nodesResult.findIndex(node => node.id === id);
        if (i >= 0) {
          nodesToDelete.push(nodesResult[i]);
          nodesResult.splice(i, 1);
        }
      });
      store.dispatch(Actions.setNodes(nodesResult));

      // Adjust custom row order
      newCustomRowOrder = { ...customRowOrder };
      ids.forEach((id) => {
        if (newCustomRowOrder[id] !== undefined) {
          const rowPosition = newCustomRowOrder[id];
          delete newCustomRowOrder[id];

          // Adjust position of rows below this one
          Object.keys(newCustomRowOrder).forEach((nodeId) => {
            if (newCustomRowOrder[nodeId] >= rowPosition) {
              newCustomRowOrder[nodeId]--;
            }
          });
        }
      });
      if (!_.isEqual(newCustomRowOrder, customRowOrder)) {
        store.dispatch(viewConfigActions.setCustomRowOrder(newCustomRowOrder));
      }
    } catch (e) {
      console.error('error happened', e);
    }
  }

  IMPORT_COMPLETED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    const {
      [CollectionTypes.workspaces]: urlWorkspaceId,
    } = parseURL(window.location.pathname);

    const {
      workspaceId,
      databaseId,
    } = _.pick(data, ['workspaceId', 'databaseId']);

    if (data.eventBy !== currentUserId) {
      return;
    }

    if (urlWorkspaceId === workspaceId) {
      setTimeout(() => {
        const databaseUrl = `/workspaces/${workspaceId}/databases/${databaseId}/sheets/default/items`;
        store.dispatch(push(databaseUrl));
      }, 1000);
    }
  }
}

export default new ItemEntity();
