import * as React from 'react';
import Message, { MessageStates } from './Message';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from './Conversation.styles';
import * as types from 'data/messages/types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
let moment = require('moment');
if (moment.default) {
  moment = moment.default;
}

interface OwnProps {
  className?: string;
  currentUserId?: string;
  messagesGroupedByDay: types.Message[][];
  suggestions?: types.Suggestions[];
  deleteMessage: (id: string) => void;
  updateMessage: (options: types.UpdateMessage) => void;
  onChange?: (messageState: MessageStates) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class MessageList extends React.Component<Props> {
  onChange = (messageState: MessageStates) => {
    this.props.onChange?.(messageState);
  };

  render() {
    const className = this.props.className || '';
    return (
      <React.Fragment>
        {this.props.messagesGroupedByDay.map((messages, index) => {
          return (
            <React.Fragment key={index}>
              <div className={this.props.classes.divider}>
                <span id={`dateDivider_${index}`} className={`${this.props.classes.dividerText} ${className}`}>
                  {moment(messages[0].createdOn).format('MMMM DD, YYYY')}
                </span>
              </div>
              {messages.map((message, index) => {
                return (
                  <Message
                    onChange={this.onChange}
                    index={index}
                    key={message.id}
                    message={message}
                    suggestions={this.props.suggestions}
                    updateMessage={this.props.updateMessage}
                    deleteMessage={this.props.deleteMessage}
                    currentUserId={this.props.currentUserId}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MessageList);
