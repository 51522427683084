import styled from 'styled-components';
import { dark } from 'styles/colors';

const GenericTextRenderer = styled<{lines?: number; color?: string}, 'div'>('div')`
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  display: flex;
  color: ${({ color }) => color || dark};
  height: ${({ lines }) => lines === 3? '50px': 'auto'};

  ${({ lines }) => lines && lines > 1 ? `
    line-height: normal;
    white-space: pre-wrap;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
  ` : ''}
`;

export default GenericTextRenderer;
