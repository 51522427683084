import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as keys from 'keycode-js';
import KeyHandler from 'react-key-handler';
import { compose } from 'recompose';
import styled from 'styles/styled-components';
import * as Colors from 'styles/colors';

import FieldEditorWrapper from '../components/FieldEditorWrapper';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import Star from './Star';

interface State {
  hoverValue?: number;
}

type Props =
  & FieldEditorProps
  & WithStyles<ClassKey>
  ;

class RatingEditor extends React.Component<Props, State> {
  public state: State = {};

  public render(): JSX.Element {
    const { classes, eGridCell, readOnly, lock } = this.props;

    const Wrapper = eGridCell ? FieldEditorWrapper : styled.div``;

    return (
      <Wrapper className={classes.wrapper}>
        { !readOnly && !lock && <RatingKeyHandler onRatingChange={this.props.onChange} /> }
        <div
          className={eGridCell ? classes.starsCell : classes.stars}
          onMouseLeave={() => this.onHoverChange(undefined)}
          tabIndex={0}
        >
          {new Array(5).fill(null).map(this.renderStar)}
        </div>
      </Wrapper>
    );
  }

  componentDidMount() {
    const { keyPress=0, onChange } = this.props;

    if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
      onChange(0);
    }
  }

  private onHoverChange = (hoverValue?: number): void => {
    this.setState({ hoverValue });
  };

  private renderStar = (_unknown: unknown, index: number): JSX.Element => {
    const { hoverValue } = this.state;
    const { value: propValue = 0, readOnly, lock, permissions } = this.props;

    const canEdit = permissions?.includes('items:update');
    const canLockItems = permissions?.includes('items:lock');

    const value = propValue ?? 0;
    const starValue = index + 1;
    const isActive = (value !== undefined) && (value >= starValue);
    const isHovered = (hoverValue !== undefined) && (hoverValue >= starValue);
    const isFilled = isActive || isHovered;

    const onEmptyChange = () => this.props.onChange(undefined, true);
    const onValueChange = () => this.props.onChange(starValue, true);

    const onClick = ( (lock && !canLockItems) || readOnly || !canEdit) ? () => null : (value === starValue) ? onEmptyChange : onValueChange;

    return (
      <Star
        id={`rating${this.props.id}_${index}`}
        key={index}
        isActive={isActive}
        isFilled={isFilled}
        onMouseEnter={() => ((lock && !canLockItems) || readOnly || !canEdit) ? null : this.onHoverChange(starValue)}
        onClick={onClick}
      />
    );
  };
}

type ClassKey =
  | 'wrapper'
  | 'starsCell'
  | 'stars'
  ;

const styles = withStyles<ClassKey>({
  wrapper: {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
  },
  starsCell: {
    display: 'inline-flex',
    cursor: 'pointer',
  },
  stars: {
    'display': 'inline-flex',
    'cursor': 'pointer',
    'height': '100%',
    'alignItems': 'center',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
    '&:focus': {
      borderBottom: `1px solid ${Colors.hubsyncBlue}}`,
    },
  },
});

const enhance = compose<Props, FieldEditorProps>(
  wrapCellEditor(),
  styles,
);

export default enhance(RatingEditor);
interface RatingKeyHandlerProps {
  onRatingChange(rating: number): void;
}

function RatingKeyHandler(props: RatingKeyHandlerProps): JSX.Element {
  return (
    <React.Fragment>
      <KeyHandler
        keyCode={keys.KEY_0}
        onKeyHandle={() => props.onRatingChange(0)}
      />
      <KeyHandler
        keyCode={keys.KEY_1}
        onKeyHandle={() => props.onRatingChange(1)}
      />
      <KeyHandler
        keyCode={keys.KEY_2}
        onKeyHandle={() => props.onRatingChange(2)}
      />
      <KeyHandler
        keyCode={keys.KEY_3}
        onKeyHandle={() => props.onRatingChange(3)}
      />
      <KeyHandler
        keyCode={keys.KEY_4}
        onKeyHandle={() => props.onRatingChange(4)}
      />
      <KeyHandler
        keyCode={keys.KEY_5}
        onKeyHandle={() => props.onRatingChange(5)}
      />
    </React.Fragment>
  );
}
