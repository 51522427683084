import * as constants from 'data/filethis/filethis.constants';
import * as types from 'data/filethis/filethis.types';

export const fetchFilethisSession = (workspaceId: string): types.FetchFilethisSessionAction => {
  return {
    type: constants.FETCH_FILETHIS_SESSION,
    workspaceId,
  };
};

export const fetchFilethisSessionSuccess = (): types.FetchFilethisSessionSuccessAction => {
  return {
    type: constants.FETCH_FILETHIS_SESSION_SUCCESS,
  };
};

export const fetchFilethisSessionError = (error: string): types.FetchFilethisSessionErrorAction => {
  return {
    type: constants.FETCH_FILETHIS_SESSION_ERROR,
    error,
  };
};

export const setFilethisSession = (session: types.FilethisSession): types.SetFilethisSessionAction => {
  return {
    type: constants.SET_FILETHIS_SESSION,
    session,
    receivedAt: Date.now(),
  };
};
