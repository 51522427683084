import { withStyles, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';
import { CollectionTypes } from 'types/schema';
import { createWorkspaceUrl } from 'utilities/createUrl';

import { styles } from './CurrentWorkspace.style';
import NodeContextMenu from 'components/NodeContextMenu/NodeContextMenu';
import svgIcons from 'styles/svgIcons';
import Typography, { Variant } from 'components/Typography';
import { WorkspaceNode, WorkspaceWithTitle } from 'types/response/workspaceNode';
import { WithEllipsis } from 'components/EditableLabel/EditableLabel.style';
import CurrentNodeDropdown from 'components/CurrentNodeDropdown';
import { Popover } from 'components/Popover';

interface OwnProps {
  workspaces: Record<string, WorkspaceNode>;
  currentWorkspace?: WorkspaceWithTitle;
  permissions: string[];
  appPermissions: string[];
}

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

interface State {
  isDropdownOpen: boolean;
}

export class CurrentWorkspace extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  public render = (): JSX.Element | null => {
    const { classes, workspaces, currentWorkspace } = this.props;
    const { isDropdownOpen } = this.state;
    const DropdownIcon = isDropdownOpen ? ArrowDropUp : ArrowDropDown;

    if (!currentWorkspace) {
      return null;
    }

    return (
      <Grid container className={classes.mainContainer}>
        <Popover
          id="currentWorkspace"
          content={
            <CurrentNodeDropdown
              collectionType={CollectionTypes.workspaces}
              CollectionIcon={svgIcons.Workspace}
              collectionUrl={createWorkspaceUrl()}
              nodes={workspaces}
              currentNode={currentWorkspace}
              onItemClick={this.toggleDropdown}
            />
          }
          isOpen={isDropdownOpen}
          onOpen={this.toggleDropdown}
          onClose={this.toggleDropdown}
        >
          <Grid container className={classes.dropdownBox}>
            <Grid container className={classes.dropdownBoxText}>
              <WithEllipsis>
                <Typography variant={Variant.Title}>{currentWorkspace.meta?.clientName ? currentWorkspace.meta?.clientName.replace(/amp;/g, '') : currentWorkspace.title.replace(/amp;/g, '')}</Typography>
              </WithEllipsis>
            </Grid>
            <DropdownIcon className={classes.dropdownBoxIcon} />
          </Grid>
        </Popover>
        <NodeContextMenu
          currentNode={currentWorkspace}
          collectionType={CollectionTypes.workspaces}
          withFavorite={currentWorkspace && this.props.appPermissions?.includes('workspaces:update')}
        />
      </Grid>
    );
  };

  private toggleDropdown = (): void => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };
}

export default withStyles(styles)(CurrentWorkspace);
