import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import RootRef from '@material-ui/core/RootRef';
import IconButton, { Color as IconButtonColor, Size } from '../IconButton';
import Button, { Variant as ButtonVariant, Size as ButtonSize } from '../Button';
import Typography, { Variant } from '../Typography';
import svgIcons from 'styles/svgIcons';
import NotificationsItem from './NotificationsItem';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { State } from 'reducers';
import {
  actions as notificationsActions, Notification,
  State as NotificationsState,
} from 'data/notifications/notifications';
import {
  actions as notificationsUnseenCountActions,
  State as NotificationsUnseenCountState,
} from 'data/notifications/unseen-count';

import { paperPropsStyle, notificationsMenuStyles } from './Notifications.style';
import LoadingIndicator from '../LoadingIndicator';

const ZOOM_RATIO_CORRECTION = 10;

interface OwnProps {
  anchorEl?: HTMLElement;
  onOpen(anchorEl: HTMLElement): void;
  onClose(): void;
}
export type Props = OwnProps & URLInjectedProps & WithStyles<typeof notificationsMenuStyles>;


export const Notifications: React.FunctionComponent<Props> = (props) => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch();

  /**
   * EFFECTS
   * */
  useEffect(() => {
    dispatch(notificationsUnseenCountActions.fetch());
  }, []);

  /**
   * SELECTORS
   * */
  const {
    items,
    isLoading,
    pageNumber,
    hasNextPage,
  } = useSelector<State, NotificationsState>(state => state.notifications);
  const { count: unseenCount } = useSelector<State, NotificationsUnseenCountState>( state => state.notificationsUnseenCount);
  const ref = useRef<HTMLUListElement>(null);


  const { anchorEl, classes } = props;
  const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };
  const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };

  const isOpen = !!anchorEl;

  const onClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const isOpen = !!props.anchorEl;

    if (!isOpen && event) {
      dispatch(notificationsActions.resetNotifications());
      props.onOpen(event.currentTarget);
      dispatch(notificationsActions.fetch());
    }
  };

  const markAsReadAndOpen = (id: string, isRead: boolean, url: string, type?: string) => {
    if (!isRead) {
      dispatch(notificationsActions.seenRequest(id));
    }

    if (url) {
      if (type === 'DOWNLOAD_READY') {
        const a = window.document.createElement('a');
        a.href = url;
        a.download = '';
        a.click();
        a.parentNode?.removeChild(a);
      } else {
        props.history.push(url);
      }
      onClose();
    }
  };

  const markAsRead = (id: string, isRead: boolean) => {
    if (isRead) {
      dispatch(notificationsActions.unSeenRequest(id));
    } else {
      dispatch(notificationsActions.seenRequest(id));
      dispatch(notificationsActions.markNotificationAsSeen(id));
    }
  };

  const deleteOne = (id: string) => {
    dispatch(notificationsActions.delete(id));
    setImmediate(() => {
      dispatch(notificationsUnseenCountActions.fetch());
    });
  };

  const handleScroll = (event) => {
    const element = event.target;

    // When scrolled to bottom of notifications list
    if (((element.scrollHeight - element.scrollTop) - ZOOM_RATIO_CORRECTION ) <= element.clientHeight) {
      if (!isLoading && pageNumber && hasNextPage
      ) {
        dispatch(notificationsActions.fetch({ pageNumber }));
      }
    }
  };

  const onClose = () => {
    props.onClose();
    dispatch(notificationsActions.resetNotifications());
  };

  const markAllNotificationsAsSeen = () => {
    dispatch(notificationsActions.seenAllRequest());
  };

  const deleteAllNotificationsButtonOnClick = () => {
    dispatch(notificationsActions.deleteAll());
  };

  useLayoutEffect(() => {
    if (ref.current) {
      const listHeight = ref.current.clientHeight;
      const listChildren = ref.current.children;
      let childrenHeight = 0;

      for (const child of listChildren) {
        childrenHeight += child.clientHeight;
      }

      if (childrenHeight < listHeight && (pageNumber !== 0 && hasNextPage)) dispatch(notificationsActions.fetch({ pageNumber }));
    }
  }, [ref.current]);

  return (
    <div className={classes.wrapper}>
      <div id="btnNotifications" className={classes.notificationsIconWrapper} onClick={onClick}>
        <svgIcons.HeaderNotifications className={classes.icon} />
        {
          unseenCount > 0 && (
            <div className={classes.notificationsCounter}>
              {unseenCount}
            </div>
          )
        }
      </div>

      <Popover
        id="notifications"
        anchorEl={anchorEl}
        open={isOpen}
        PaperProps={paperPropsStyle}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        marginThreshold={0}
        onClose={() => {
          props.onClose();
          dispatch(notificationsActions.resetNotifications());
        }}
      >
        <div className={classes.popoverHeader}>
          <Typography variant={Variant.SubHeading} className={classes.title}>
            Notifications
          </Typography>
          <IconButton icon={svgIcons.Close} color={IconButtonColor.DarkGray} size={Size.Small} onClick={onClose} />
        </div>
        <Divider className={classes.notificationsListDivider} />
        <div className={classes.popoverTools}>
          <div className={classes.notificationExtraActions}>
            <Button
              label="Mark all as read"
              size={ButtonSize.Small}
              variant={ButtonVariant.PrimaryLink}
              onClick={markAllNotificationsAsSeen}
            />
            <span>.</span>
            <Button
              label="Delete All"
              size={ButtonSize.Small}
              variant={ButtonVariant.TertiaryLink}
              onClick={deleteAllNotificationsButtonOnClick}
            />
          </div>
        </div>
        <Divider className={classes.notificationsListDivider} />

        {
          !items.length && !isLoading && (
            <div className={classes.emptyNotificationsList}>
              <svgIcons.HeaderNotifications width="5em" height="5em" className={classes.icon} />
              <h5 className={classes.emptyNotificationsListText}>No notifications to show.</h5>
            </div>
          )
        }
        {(!items.length && isLoading) && <LoadingIndicator />}
        { items.length > 0 && (
          <RootRef rootRef={ref}>
            <List className={classes.root} onScroll={handleScroll} disablePadding={true}>
              {
                items.map((notification: Notification, key) => (
                  <React.Fragment key={key}>
                    <NotificationsItem
                      id={notification['_id']}
                      {...notification}
                      markAsReadAndOpen={markAsReadAndOpen}
                      markAsRead={markAsRead}
                      delete={deleteOne}
                    />
                    <Divider className={classes.notificationsListDivider} />
                  </React.Fragment>
                ))
              }
              {isLoading && (
                <h5 className={classes.loadingMore}>Loading...</h5>
              )}
            </List>
          </RootRef>
        )}
      </Popover>
    </div>
  );
};


export default withURLParams(withStyles(notificationsMenuStyles)(Notifications));
