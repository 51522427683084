import { createAsyncAction, ActionType, createStandardAction } from 'typesafe-actions';

import { SheetNode } from 'types/response/sheetNode';

export interface FetchDatabaseSheetsSuccessPayload {
  sheets: SheetNode[];
  databaseId: string;
}

export const fetchDatabaseSheets = createAsyncAction(
  'sheets/FETCH_BY_DATABASE_ID_REQUEST',
  'sheets/FETCH_BY_DATABASE_ID_SUCCESS',
  'sheets/FETCH_BY_DATABASE_ID_FAILURE',
)<string, FetchDatabaseSheetsSuccessPayload, Error>();

export const selectSheet = createStandardAction('sheets/SELECT')<SheetNode>();

export const createSheetRequest = createAsyncAction(
  'sheets/CREATE_REQUEST',
  'sheets/CREATE_SUCCESS',
  'sheets/CREATE_FAILURE',
)<{ title: string }, { sheet: SheetNode }, Error>();

export const createSheet = createStandardAction('sheets/CREATE')<never>();

export const renameSheet = createAsyncAction(
  'sheets/RENAME_REQUEST',
  'sheets/RENAME_SUCCESS',
  'sheets/RENAME_FAILURE',
)<{ id: string; title: string }, SheetNode, Error>();

export const deleteSheet = createAsyncAction(
  'sheets/DELETE_REQUEST',
  'sheets/DELETE_SUCCESS',
  'sheets/DELETE_FAILURE',
)<{ id: string }, string, Error>();

export const reorderSheets = createAsyncAction(
  'sheets/REORDER_REQUEST',
  'sheets/REORDER_SUCCESS',
  'sheets/REORDER_FAILURE',
)<{ sheetIdOrder: string[] }, SheetNode, Error>();

export const startEditing = createStandardAction('sheets/START_EDITING')();
export const stopEditing = createStandardAction('sheets/STOP_EDITING')();

export const setCurrentSheet = createStandardAction('sheets/SET_CURRENT')<string | null>();
export const setCurrentSheetId = createStandardAction('sheets/SET_CURRENT_ID')<string | null>();

export const cleanSheet = createStandardAction('sheets/CLEAN_SHEET')();

export const actions = {
  fetchDatabaseSheets,
  selectSheet,
  createSheetRequest,
  createSheet,
  renameSheet,
  deleteSheet,
  reorderSheets,
  startEditing,
  stopEditing,
  setCurrentSheet,
  setCurrentSheetId,
  cleanSheet,
};

export type Actions = ActionType<typeof actions>;
