import React from 'react';

import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import { Auth } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import HubSync from 'containers/App';
import * as authActions from 'data/auth/actions';
import { create } from 'jss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { configureStore, history, AsyncDispatch } from 'store/configureStore';


import { loadTenantConfig } from 'data/app/actions';

import * as constants from './data/app/constants';
import SharedNewFiles from 'pages/SharedNewFiles';
import { AnyAction } from 'redux';

import OutagePage from 'pages/OutagePage/OutagePage';

LicenseManager.setLicenseKey(constants.LICENSE_KEY);

const store = configureStore();
const dispatch = (store.dispatch as AsyncDispatch);
declare global {
  interface Window {
    appStore?: any;
    apiEndpoints: {
      [key: string]: string;
    };
    apiGWAuthorizedEndpoints: string[];
    resetFloatingFilter: boolean;
    pdfFileWindow: Window | null;
    showOutagePage: boolean;
    whitelistedIps: string[];
  }
}

window.appStore = store;

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c',
});

const jss = create({
  ...jssPreset(),
  // @ts-ignore
  insertionPoint: document.getElementById('jss-insertion-point'),
});

interface State {
  tenantConfigReady: boolean;
  ssoEnabled: boolean;
  autoSSO: boolean;
  ip: string;
  user: any; // TODO: add interface
}

class App extends React.PureComponent {
  state: State = {
    tenantConfigReady: false,
    ssoEnabled: false,
    autoSSO: false,
    user: null,
    ip: '',
  };

  public componentDidMount(): void {
    this.fetchConfig();
  }

  public async fetchConfig(): Promise<void> {
    let body; let ssoEnabled; let autoSSO; let user;

    try {
      const action = await dispatch(loadTenantConfig()) as AnyAction;
      body = action.payload;

      // body.apiEndpoints.v2ApiEndpoint = 'http://localhost:8090';
      // body.apiEndpoints.itemsApiEndpoint = 'http://localhost:8082';
      // body.apiEndpoints.fileProviderApiEndpoint = 'http://localhost:8080';

      window.showOutagePage = body.maintenance && body.maintenance.showOutagePage || false;
      window.whitelistedIps = body.maintenance && body.maintenance.whitelistedIps || [];

      if (window.showOutagePage) {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState({ ip: data.IPv4 });
      }

      ssoEnabled = body &&
        body.authProvider &&
        body.authProvider.identityProviders &&
        body.authProvider.identityProviders.length > 0;

      autoSSO = body && body.authProvider && body.authProvider.autoRedirect === false ? false : !window.location.pathname.includes('/shared-files/') &&
      !window.location.pathname.includes('/logout') &&
      !window.location.pathname.includes('/auth') &&
      !window.location.search.includes('showLogin');


      this.setState({ autoSSO });

      // try to get authenticated user context (will throw an error if unauthenticated)
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error(error, ssoEnabled, autoSSO);
      // if the user is not authenticated
      // and we're not logging out
      // and explicit login hasn't been requested
      // and sso is enabled for the tenant
      // TODO: Use optional chaining
      if (
        error === 'not authenticated' &&
        ssoEnabled &&
        this.state.autoSSO
      ) {
        // then perform automatic sso login
        const idp = body.authProvider.identityProviders[0];

        // TODO: Use window.location.assign so we can mock easier
        window.location.href = `https://${body.authProvider.domain}/authorize?idp_identifier=${idp.identifier}&response_type=token&client_id=${body.authProvider.userPoolClientId}&redirect_uri=${idp.redirectUri}`;
      }
    }


    window.apiEndpoints = body.apiEndpoints;
    window.apiGWAuthorizedEndpoints = body.ApiGWAuthorizedEndpoints;
    dispatch(authActions.initRefreshTokenPoll());
    this.setState({ tenantConfigReady: true, ssoEnabled, autoSSO, user });
  }

  render() {
    if (!this.state.tenantConfigReady) {
      return <div>Loading...</div>;
    }

    // if sso is enabled
    // and the user is not authenticated
    // and we're not logging out
    // and explicit login hasn't been requested
    if (this.state.ssoEnabled && this.state.autoSSO && !this.state.user) {
      return <div>Initiating single sign-on...</div>;
    }

    return (
      (window.showOutagePage && window.whitelistedIps.indexOf(this.state.ip) === -1 )? (<OutagePage />) :(
        <BrowserRouter>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <Provider store={store}>
              <DndProvider backend={HTML5Backend}>
                <ConnectedRouter history={history}>
                  <Switch>
                    <Route
                      path="/shared-files/:fileLinkId"
                      component={SharedNewFiles}
                    />
                    <Route path="/" component={HubSync} />
                  </Switch>
                </ConnectedRouter>
              </DndProvider>
            </Provider>
          </JssProvider>
        </BrowserRouter>
      )
    );
  }
}

export default App;
