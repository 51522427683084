import * as React from 'react';
import { useEffect } from 'react';

import keys from 'keycode-js';
import delay from 'lodash/delay';
import NumberFormat from 'react-number-format';

import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import { transformValue } from './NumberEditor.utils';

import './NumberFormattedEditor.scss';

interface NumberEditorProps extends FieldEditorProps {
  allowNegative: boolean;
  precision: number;
}

const NumberFormattedEditor = React.forwardRef(
  (
    props: NumberEditorProps,
    ref: React.RefObject<HTMLInputElement>,
  ): JSX.Element => {
    const {
      allowNegative,
      precision,
      value,
      onChange,
      eGridCell,
      onStopEditing,
      onBlur,
      keyPress = 0,
      lock,
      permissions,
    } = props;

    const newInputRef = React.createRef<HTMLInputElement>();

    const canEdit =
      permissions?.includes('items:update') ||
      permissions?.includes('items:lock');

    const handleChange = (valueInput) => {
      if (canEdit) onChange(valueInput.value);
    };

    const parsedValue = transformValue(value, allowNegative);

    const setFocus = (): void => {
      const input = newInputRef.current;
      input?.focus();
    };

    useEffect(() => {
      if (!eGridCell) return;

      if (props.charPress) {
        if (canEdit) onChange(props.charPress);
      }

      if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
        if (canEdit) onChange('');
      }
    }, [newInputRef.current, props.charPress]);

    if (eGridCell) {
      delay(setFocus, 3); // This delay is a workaround to get auto-focus working.

      return (

        <NumberFormat
          id={`txtGrid${props?.data?.id}`}
          className="number-editor-wrapper"
          getInputRef={newInputRef}
          onBlur={onStopEditing}
          disabled={props.readOnly || !canEdit}
          value={parsedValue ?? ''}
          thousandSeparator={true}
          isNumericString={true}
          decimalScale={precision ?? 0}
          allowNegative={allowNegative ?? false}
          allowLeadingZeros={true}
          onValueChange={(valueInput) => handleChange(valueInput)}
        />
      );
    } else {
      return (
        <NumberFormat
          id={`txtModal${props.id}`}
          className={`number-editor number-editor-wrapper ${
            lock ? 'locked' : ''
          }`}
          value={parsedValue ?? ''}
          thousandSeparator={true}
          decimalScale={precision ?? 0}
          isNumericString={true}
          allowLeadingZeros={true}
          onValueChange={(valueInput) => handleChange(valueInput)}
          onBlur={onBlur}
          disabled={props.readOnly || !canEdit}
          allowNegative={allowNegative ?? false}
        />
      );
    }
  },
);

export default wrapCellEditor()(NumberFormattedEditor);
