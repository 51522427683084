import * as React from 'react';
import * as _ from 'lodash/fp';
import { withStyles, WithStyles } from '@material-ui/core';

import Toolbar from 'components/Toolbar';
import SearchOption from 'components/Toolbar/SearchOption/SearchOption';
import { CollectionTypes } from 'types/schema';
import { styles } from './TilesViewBar.style';
import Button, { Variant } from 'components/Button';
import svgIcons from 'styles/svgIcons';
import Typography, { Variant as TypographyVariant } from 'components/Typography';

interface Props extends WithStyles<typeof styles> {
  collectionType: CollectionTypes;
  collectionName: string;
  itemName: string;
  query?: string;
  onAddNew?: () => void;
  onQueryChanges: (query: string) => void;
  permissions: string[];
  omitLoader?: boolean;
}

const collectionTypesWithToolbar = [CollectionTypes.workspaces, CollectionTypes.databases, CollectionTypes.taskdbs];

function TilesViewBar(props: Props): JSX.Element {
  const {
    classes,
    collectionType,
    collectionName,
    itemName,
    query,
    onAddNew,
    onQueryChanges,
    omitLoader,
  } = props;
  const hasToolbar = collectionTypesWithToolbar.includes(collectionType);
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant={TypographyVariant.Heading}>
          {collectionName}
        </Typography>
        {props.permissions.includes(`${props.collectionName.toLowerCase()}:create`) && (
          <Button id={`btnAddNew${props.collectionName}`} label={`New ${itemName}`} variant={Variant.Primary} icon={svgIcons.Add} onClick={onAddNew}/>
        )}
      </div>

      {hasToolbar &&
        <TileViewToolbar
          collectionName={collectionName}
          query={query}
          onQueryChanges={onQueryChanges}
          omitLoader={omitLoader}
        />
      }
    </div>
  );
}

interface TileViewToolbarProps {
  omitLoader?: boolean;
  query?: string;
  collectionName: string;
  onQueryChanges(query: string): void;
}

function TileViewToolbar(props: TileViewToolbarProps): JSX.Element {
  const { collectionName, query = '', onQueryChanges, omitLoader } = props;

  return (
    <Toolbar>
      <SearchOption
        query={query}
        placeholder={`Search ${_.capitalize(collectionName)}`}
        onQueryChanges={onQueryChanges}
        omitLoader={omitLoader}
      />
    </Toolbar>
  );
}

export default withStyles(styles)(TilesViewBar);
