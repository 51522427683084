import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import FieldEditorWrapper from '../../components/FieldEditorWrapper.tsx';
import wrapCellEditor from '../../components/wrapCellEditor.tsx';

const TagsEditor = ({ id, value, onChange }) => (
  <FieldEditorWrapper className="multilinetext__editor">
    <textarea
      id={`txt${id}`}
      className="multilinetext__editor__input"
      cols={60}
      defaultValue={value}
      rows={5}
      onChange={onChange}
    />
  </FieldEditorWrapper>
);

TagsEditor.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default wrapCellEditor()(TagsEditor);
