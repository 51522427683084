import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import moment from 'moment';

import { State as ReduxState } from 'reducers';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { ClassKey } from 'containers/App/App';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { HEADER_HEIGHT, LEFTHAND_SIDEBAR_WIDTH } from 'styles/constants';

import { PageContent, PageWrapper } from 'pages/common/Styled';
import HeaderBar from 'components/HeaderBar/LoggedOut';
import ThemeProvider from 'components/ThemeProvider';
import LinkExpired from '../../components/SharedLink/LinkExpired';
import SharedFilesAuth from '../../components/SharedLink/SharedFilesAuth';
import * as service from './Services/index';
import { applyTheme } from 'styles/theme';
import FileManagerPage from 'pages/FileManager';

const CONTENT_PADDING = '16px';

const styles = withStyles<ClassKey>({
  wrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    overflowY: 'auto',
    paddingLeft: CONTENT_PADDING,
    paddingRight: CONTENT_PADDING,
    transition: 'padding-left 0.3s',
  },
  menuOpened: {
    paddingLeft: `calc(${LEFTHAND_SIDEBAR_WIDTH}px + ${CONTENT_PADDING})`,
  },
});

interface LinkExpiration {
  expiration: {
    days: number;
    hours: number;
    mins: number;
  };
  modifiedAt: number;
}

type Props = WithStyles<ClassKey> & URLInjectedProps & typeof withURLParams;

const SharedNewFiles: React.FunctionComponent<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isAuthorizing, setIsAuthorizing] = React.useState(false);
  const [fileLink, setFileLink] = React.useState<any>();
  const [error, setError] = React.useState<boolean>(false);
  const [authorizationError, setAuthorizationError] = React.useState<string>('');
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [expiration, setLinkExpiration] = React.useState<null | LinkExpiration>(null);
  const [linkExpirationInitialized, setLinkExpirationInitialized] = React.useState(false);

  const tenant = useSelector((state: ReduxState) => state.app.tenant);
  const { fileLinkId }: any = props.match.params;

  React.useEffect(( ) => {
    const run = async () => {
      try {
        const result = await service.getFileLinkById(fileLinkId);
        setFileLink(result);
        const { isPasswordProtected } = result;
        if (!isPasswordProtected) {
          const result = await service.createToken(fileLinkId);
          service.setToken(result.token);
          extractAndSetPermissions();
          setIsAuthorized(true);
        }
        setIsLoading(false);
      } catch (exception) {
        setError(true);
        setIsLoading(false);
      }
    };
    run();
    applyTheme(tenant);
  }, []);

  React.useEffect(() => {
    if (expiration && !linkExpirationInitialized) {
      setLinkExpirationInitialized(true);
      initializeLinkExpirationTimer();
    }
  }, [expiration]);

  const initializeLinkExpirationTimer = () => {
    if (expiration) {
      const expirationTime = moment(expiration.modifiedAt).utc()
        .add(expiration.expiration.days, 'days')
        .add(expiration.expiration.hours, 'hours')
        .add(expiration.expiration.mins, 'minutes');
      const diff = expirationTime.diff(moment().utc());

      if (diff < 0) {
        setError(true);
        return;
      }

      setTimeout(() => {
        initializeLinkExpirationTimer();
      }, 1000);
    }
  };

  const filesAuthFormOnSubmit = async (password: string) => {
    setAuthorizationError('');
    setIsAuthorizing(true);
    try {
      const result = await service.createToken(fileLinkId, password);
      service.setToken(result.token);
      extractAndSetPermissions();
      setIsAuthorized(true);
    } catch (exception) {
      setAuthorizationError('Incorrect password is provided.');
    } finally {
      setIsAuthorizing(false);
    }
  };

  const extractAndSetPermissions = () => {
    const currentSharedFolder = service.getCurrentSharedFolder();
    const { expiration, modifiedAt } = currentSharedFolder?.fileLink
      ?.expiration ?? { expiration: null, modifiedAt: 0 };
    setPermissions(currentSharedFolder.permissions);

    if (expiration && modifiedAt && Object.keys(expiration).length) {
      setLinkExpiration({
        expiration,
        modifiedAt,
      });
    }
  };

  if (isLoading) {
    return <b>loading...</b>;
  }
  if (error) {
    return <LinkExpired />;
  }
  if (fileLink?.isPasswordProtected && !isAuthorized) {
    return (
      <SharedFilesAuth
        error={authorizationError}
        validating={isAuthorizing}
        onSubmit={filesAuthFormOnSubmit}
        tenant={tenant}
      />
    );
  }

  const { filterPath, name, workspaceId, isRoot } = service.getCurrentSharedFolder().file;
  const filePath = isRoot ? '' : `${filterPath}${name}`;

  return (
    <PageWrapper>
      <PageContent>
        <HeaderBar tenant={tenant}>
          Guest Access
        </HeaderBar>
        <div className={`collections__main__container files `}>
          <div className="ag-theme-material users">
            <div className="page-header-container">
              {error && <b>Error: {error}</b>}
              <ThemeProvider theme={tenant?.theme}>
                <div className={props.classes.wrapper}>
                  <div id="hubsync-content" className={props.classes.content}>
                    <Switch>
                      <Route
                        exact
                        path="/shared-files/:fileLinkId"
                        component={() => <FileManagerPage
                          permissions={permissions}
                          token={service.getToken()}
                          workspaceId={workspaceId}
                          filePath={filePath}
                          isShared={true}
                        />}
                      />
                    </Switch>
                  </div>
                </div>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </PageContent>
    </PageWrapper>
  );
};

const enhance = compose<Props, {}>(
  styles,
  withURLParams,
);

export default enhance(SharedNewFiles);
