import MuiInput from '@material-ui/core/Input';
import styled from 'styled-components';

import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

export const Wrapper = styled.div`
  width: 279px;
  height: 31px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const Input = styled(MuiInput)`
  flex: 1;
  font-size: 11px;
  line-height: 11px;
`;

export const QuerySearchIcon = styled(svgIcons.Search)`
  height: 10px;
  color: ${Colors.lightBlueGrey};
  margin-right: 8px;
`;
