import { GridColumnState, ViewConfig } from 'data/collections/collections.reducer';
import { ActionsUnion } from 'types/reduxHelpers';
import { createStandardAction } from 'typesafe-actions';

export const SET_COLUMNS = 'viewConfig/SET_COLUMNS';
export const SET_ROW_HEIGHT = 'viewConfig/SET_ROW_HEIGHT';
export const SET_CATEGORY_FIELD_ORDER = 'viewConfig/SET_CATEGORY_FIELD_ORDER';
export const APPLY_VIEW_CONFIG = 'viewConfig/APPLY_VIEW_CONFIG';
export const SET_CUSTOM_ROW_ORDER = 'viewConfig/SET_CUSTOM_ROW_ORDER';
export const ADD_CUSTOM_ROW_POSITION = 'ADD_CUSTOM_ROW_POSITION';
export const DELETE_CUSTOM_ROW_POSITION = 'DELETE_CUSTOM_ROW_POSITION';

export interface AddCustomRowPositionPayload {
  nodeIds: string[];
  position: number;
}

export const actions = {
  setColumnsState: createStandardAction(SET_COLUMNS)<GridColumnState[]>(),
  setRowHeight: createStandardAction(SET_ROW_HEIGHT)<number>(),
  setCategoryFieldOrder: createStandardAction(SET_CATEGORY_FIELD_ORDER)<string[]>(),
  applyViewConfig: createStandardAction(APPLY_VIEW_CONFIG)<ViewConfig>(),
  setCustomRowOrder: createStandardAction(SET_CUSTOM_ROW_ORDER)<Record<string, number>>(),
  setCustomRowPosition: createStandardAction(ADD_CUSTOM_ROW_POSITION)<AddCustomRowPositionPayload>(),
  deleteCustomRowPosition: createStandardAction(DELETE_CUSTOM_ROW_POSITION)<string>(),
};

export type ActionsType = ActionsUnion<typeof actions>;
