import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Actions } from 'data/collections/collections.actions';
import { DeletedNodesPayload } from 'data/collections/collections.reducer';
import { Breadcrumb } from 'types/schema';

import Confirm from '../Confirm';

interface DispatchProps {
  onDelete(payload: DeletedNodesPayload): void;
  onRedirect(pathname: string): void;
}

interface OwnProps {
  breadcrumb: Breadcrumb;
  onClose(): void;
}

export type Props =
  & OwnProps
  & DispatchProps
  ;

function DeleteFolder(props: Props): JSX.Element {
  function onConfirm(): void {
    props.onDelete({ ids: [getIdFromBreadcrumb(props.breadcrumb)] });
    props.onRedirect(window.location.pathname);
  }

  return (
    <Confirm
      title="Are you sure you want to delete this folder?"
      close={props.onClose}
      onConfirm={onConfirm}
      confirmLabel="Delete"
    />
  );
}

const mapDispatchToProps: DispatchProps = {
  onDelete: Actions.deleteNodes,
  onRedirect: push,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteFolder);

function getIdFromBreadcrumb(breadcrumb: Breadcrumb): string {
  const [, id = ''] = breadcrumb.uri.split('?folderID=');
  return id;
}
