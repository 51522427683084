import React from 'react';
import Button, { Variant as ButtonVariant } from 'components/Button';
import AccountIcon from '../../assets/images/mfa/verified_account.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFA.index.style';

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
});


const IdentifyVerified = (props) => {
  const { classes } = props;

  const redirectSignIn = (event): void => {
    window.location.href = '/login';
  };

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={classes.mfaFormContainer}>
        <div className={classes.topContent}>
          <h3>Identity Verified</h3>
        </div>
        <div className={classes.bodyContent}>
          <div className={classes.imageBodyCenter}>
            {tenantConfig.verifiedAccountIcon? <img src={tenantConfig.verifiedAccountIcon} alt="Account Verified Hubsync" /> : <img src={AccountIcon} alt={'Account Verified Hubsync'} /> }
          </div>
          <p className={classes.textBodyCenter}>
            <span>We have successfully verified your identity.</span><br /> <br /> You can now update your multi-factor authentication settings.
          </p>
          <div>
            <Button
              id="continueSetup"
              className={ tenantConfig.clientName ? classes.continueActionButtonbakertilly : classes.continueActionButton }
              label="Continue"
              variant={tenantConfig.clientName ? ButtonVariant.Secondary :ButtonVariant.Primary}
              onClick={redirectSignIn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(IdentifyVerified));
