import React, { useEffect, useState } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import IconButton, {
  Color as IconButtonColor,
  Size,
} from 'components/IconButton';
import { getApiV2Url } from 'env';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import svgIcons from 'styles/svgIcons';
import { getRequest } from 'utilities/httpRequests';

import AccountChip from '../../../components/AccountChip';
import Tooltip from '../../../components/Tooltip';
import { AppMessages } from '../../../constants';
import {
  createPaginationQueryParams,
  objectToQueryParams,
} from '../hooks/useDatasource';
import { styles } from './UserSuggestion.styles';

function emailIsValid(email: string) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

type UserSuggestionProps = WithStyles<typeof styles> & {
  onSelectionChange?: (emails: Array<string>) => void;
  onValidation?: (validationResult: string | null) => void;
};

function Selection({ renderArgs, email, classes, selected, resetValidation }) {
  const deteteSuggestion = () => {
    resetValidation();
    renderArgs.deleteItem();
  };
  return (
    <div
      key={renderArgs.item}
      className={`${classes.chip} ${selected ? classes.activeItem : ''}`}
    >
      <span>{email}</span>
      <IconButton
        icon={svgIcons.Close}
        color={IconButtonColor.BlueyGrey}
        size={Size.ExtraSmall}
        onClick={deteteSuggestion}
      />
    </div>
  );
}

function UserSuggestion({
  onSelectionChange,
  onValidation,
  classes,
}: UserSuggestionProps) {
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>([]);
  const [usersToSuggest, setUsersToSuggest] = useState<
    Array<{
      id: string;
      name: string;
      label: string;
    }>
  >([]);
  const [activeItem, setActiveItem] = useState(null);
  const state = useSelector((state) => state);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    let isUnmounted = false;
    getRequest(
      `${getApiV2Url()}/users/query?${objectToQueryParams(
        createPaginationQueryParams(100, 0, [{ colId: 'email', sort: 'asc' }]),
      )}`,
      state,
    ).then((resp) => {
      if (isUnmounted) return;
      setUsersToSuggest(
        resp.body.data.map((user) => ({
          id: user.id,
          name: user.displayName,
          label: user.email,
        })),
      );
    });

    return () => {
      isUnmounted = true;
    };
  }, []);

  const handleOnInputChanged = (selection: Array<string>) => {
    setSelectedUsers(selection);
    if (onSelectionChange instanceof Function) onSelectionChange(selection);
    if (onValidation instanceof Function && selection.length > 0) {
      const lastEmailAdded = selection[selection.length - 1];
      const isValidEmail = emailIsValid(lastEmailAdded);
      const exists = usersToSuggest.some(
        (user) => user.label === lastEmailAdded,
      );
      if (!isValidEmail || exists) {
        onValidation(
          !isValidEmail
            ? AppMessages.INVALID_EMAIL_LABEL
            : AppMessages.USER_ALREADY_EXISTS_LABEL,
        );
      }
    }
  };
  const handleRenderSelectedItem = (renderArgs) => {
    const selected = activeItem === renderArgs.item;
    const exists = !!find(usersToSuggest, { label: renderArgs.item });
    if (!emailIsValid(renderArgs.item) || exists) {
      return (
        <Tooltip
          key={renderArgs.item}
          title={
            <span className={classes.inviteUserTooltip}>
              is not a valid email address.
            </span>
          }
        >
          <Selection
            key={renderArgs.item}
            renderArgs={renderArgs}
            email={renderArgs.item}
            classes={classes}
            selected={selected}
            resetValidation={() => {
              if (onValidation instanceof Function) onValidation(null);
            }}
          />
        </Tooltip>
      );
    }
    return (
      <Selection
        key={renderArgs.item}
        renderArgs={renderArgs}
        email={renderArgs.item}
        classes={classes}
        selected={selected}
        resetValidation={() => {
          if (onValidation instanceof Function) onValidation(null);
        }}
      />
    );
  };
  const handleRenderSuggestion = (props) => {
    if (props.suggestion.type === 'add') {
      return (
        <div
          key={props.index}
          className={`${props.classes.inviteUserSuggestionAdd}`}
          onClick={() => props.handleClick(props.suggestion.id)}
        >
          <IconButton
            icon={svgIcons.Add}
            color={IconButtonColor.Blue}
            size={Size.Large}
            onClick={props.deleteItem}
          />
          Invite {`"${props.suggestion.label}"`} via email
        </div>
      );
    }
    return (
      <div
        key={props.index}
        className={`${props.classes.item} ${classes.invalidSuggestion}`}
      >
        <AccountChip accountId={props.suggestion.id} showEmail />
      </div>
    );
  };

  return (
    <div
      className={`${classes.inviteSelect} ${
        inputFocused ? classes.inputFocused : ''
      }`}
    >
      <Autocomplete
        type="account"
        placeholder=""
        allowCreation
        onFocus={() => {
          setInputFocused(true);
        }}
        onBlur={() => {
          setInputFocused(false);
        }}
        suggestions={usersToSuggest}
        onActiveItem={(item) => {
          setActiveItem(item);
        }}
        onChange={handleOnInputChanged}
        selectedItems={selectedUsers}
        renderSelectedItem={handleRenderSelectedItem}
        renderSuggestion={handleRenderSuggestion}
      />
    </div>
  );
}

export default withStyles(styles)(UserSuggestion);
