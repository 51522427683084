import styled from 'styled-components';

import * as Colors from 'styles/colors';

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};
  margin: 0 -1rem;
  padding: 0 1rem;
  height: 75px;
  border-bottom: 1px solid ${Colors.lightBlueGrey};
`;

export const TitleWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.8125rem;
`;

const Title = styled.h1`
  color: ${Colors.charocalGrey};
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const Content = styled.div``;

export {
  Wrapper,
  Title,
  Content,
};
