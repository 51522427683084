import { filterInputStyles } from 'components/TableHeaderTools/modules/FilteringOption/FilterInput.style';
import Input, { InputProps } from '@material-ui/core/Input';
import { withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';

type Props = InputProps & WithStyles<typeof filterInputStyles>;

const FilterInput = (props: Props): JSX.Element =>
  <Input
    {...props}
  />;

export default withStyles(filterInputStyles)(FilterInput);
