import styled from 'styled-components';

import menuStyles from './styles/Menu.styles';

export const Wrapper = styled.div`
  // display: flex;
  // flex-flow: column nowrap;
  flex: 1 1;
  box-sizing: border-box;
  line-height: 1rem;
  width: 100%;

  & > div {
    // flex: 1 1 auto;
    // min-height: 300px;
  }

  &__new__row__button {
    position: relative;
  }
  
  ${menuStyles}
`;

export const ShowEditorsPopup = styled.div`
  background: #fff;
  margin-top: 5px; 
  padding: 5px;
  font-size: 12px
  box-shadow: 1px 0px 3px 0px #0000009e;
`;
