import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';

import app, { State as AppState } from './containers/App/reducer';
import collections, {
  CollectionState,
} from 'data/collections/collections.reducer';
import { State as NodesState } from 'data/nodes/state';
import nodes from 'data/nodes/reducer';
import gridOptions, {
  State as GridOptionsState,
} from 'data/grid-options/gridOptions.reducer';
import workspaces, {
  WorkspacesState,
} from 'data/workspaces/workspaces.reducer';
import databases from 'data/databases/databases.reducer';
import taskDatabases from 'data/taskdbs/taskdbs.reducer';
import analytics from 'data/analytics/reducer';
import bookmarks from 'data/bookmarks/reducer';
import sheets, { SheetsState } from 'data/sheets/sheets.reducer';
import ui, { UiState } from 'data/ui/ui.reducer';
import attachments, { AttachmentsState } from 'data/attachments/reducer';
import activityFeed from 'data/activityFeed/reducer';
import documents, { DocumentsState } from 'data/documents/documents.reducer';
import messages from 'data/messages/messages.reducer';
import { MessagesState } from 'data/messages/types';
import workspaceCreate from 'data/workspaceCreate/reducer';
import {
  reducer as notifications,
  State as NotificationsState,
} from 'data/notifications/notifications';
import {
  reducer as notificationsUnseenCount,
  State as NotificationsUnseenCountState,
} from 'data/notifications/unseen-count';
import filethis from 'data/filethis/filethis.reducer';
import { FilethisState } from 'data/filethis/filethis.types';
import account, { AccountState } from 'data/accounts/state';

import modals from './data/modals';
import { State as ModalsState } from './data/modals/state';
import { DatabasesState } from 'data/databases/databases.types';
import { AnalyticsState } from 'data/analytics/types';
import { BookmarksState } from 'data/bookmarks/types';
import { ActivityFeedState } from 'data/activityFeed/types';
import users from 'data/users/users.reducer';
import {
  reducer as reminders,
  State as RemindersState,
} from 'data/reminders/reminders';
import {
  reducer as gridEditors,
  State as GridEditorsSate,
} from 'data/grid-editors';

import { UserState } from 'data/users/users.types';
import { History } from 'history';

export interface State {
  app: AppState;
  modals: ModalsState;
  router: RouterState;
  collections: CollectionState;
  nodes: NodesState;
  gridOptions: GridOptionsState;
  workspaces: WorkspacesState;
  databases: DatabasesState;
  taskDatabases: DatabasesState;
  documents: DocumentsState;
  sheets: SheetsState;
  attachments: AttachmentsState;
  messages: MessagesState;
  notifications: NotificationsState;
  notificationsUnseenCount: NotificationsUnseenCountState;
  bookmarks: BookmarksState;
  ui: UiState;
  activityFeed: ActivityFeedState;
  filethis: FilethisState;
  account: AccountState;
  users: UserState;
  reminders: RemindersState;
  gridEditors: GridEditorsSate;
  analytics: AnalyticsState;
  workspaceCreate: any;
}

const createRootReducer = (history: History) =>
  combineReducers<State>({
    app,
    modals,
    router: connectRouter(history),
    nodes,
    collections,
    gridOptions,
    workspaces,
    databases,
    taskDatabases,
    bookmarks,
    sheets,
    ui,
    attachments,
    activityFeed,
    documents,
    messages,
    notifications,
    notificationsUnseenCount,
    filethis,
    account,
    users,
    reminders,
    gridEditors,
    analytics,
    workspaceCreate,
  });

export default createRootReducer;
