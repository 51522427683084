import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

export enum AccountChipSize {
  small = 'SMALL',
  medium = 'MEDIUM',
  large = 'LARGE'
}

export const getSize = (size: AccountChipSize) => {
  let iconSize = 16; // Default value for 'small'
  switch (size) {
    case AccountChipSize.medium: iconSize = 24;
      break;
    case AccountChipSize.large: iconSize = 30;
      break;
  }

  return iconSize;
};

export const StyledAvatar = styled(Avatar)`
  background-color: ${({ color }) => color};
  cursor: inherit;
`;

export const StyledName = styled.span`
  margin-left: 0.5rem;
`;

export const StyledAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const IconWrapper = styled.div<{ size?: AccountChipSize }>`
  background-color: ${({ color }) => color};
  width: ${({ size = AccountChipSize.small }) => `${getSize(size)}px !important`};
  height: ${({ size = AccountChipSize.small }) => `${getSize(size)}px !important`};
`;
