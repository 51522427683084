import { createAction } from 'typesafe-actions';

const startLockingLoader = createAction('loading/startlocking',
  resolve => () => resolve( { lockingLoading: true }),
);

const endLockingLoader = createAction('loading/endlocking',
  resolve => () => resolve( { lockingLoading: false }),
);

const startNotLockingLoader = createAction('loading/startNotlocking',
  resolve => () => resolve( { notLockingLoading: true }),
);

const endNotLockingLoader = createAction('loading/endNotlocking',
  resolve => () => resolve( { notLockingLoading: false }),
);

const loadingAction = {
  startLockingLoader,
  endLockingLoader,
  startNotLockingLoader,
  endNotLockingLoader,
};

export default loadingAction;
