import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { slateTwo } from 'styles/colors';

type ClassKey =
  'root'
  | 'rootLink'
  | 'headerRoot'
  | 'headerTitle'
  | 'headerAction'
  | 'actions'
  | 'content'
  | 'field'
  | 'fieldLabel'
  | 'timeText'
  | 'itemCount'
  | 'itemCountIcon'
  | 'emptyTile'
  | 'authorChipInitials'
  | 'circle'
  ;

const CARD_WIDTH = 300;
const CARD_HEIGHT = 194;
const CARD_RIGHT_MARGIN = 16;
export const CARD_TOTAL_WIDTH = CARD_WIDTH + CARD_RIGHT_MARGIN;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    'boxShadow': '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
    'width': `${CARD_WIDTH}px`,
    'height': `${CARD_HEIGHT}px`,
    'display': 'inline-block',
    'borderRadius': '2px',
    'marginRight': `${CARD_RIGHT_MARGIN}px`,
    'marginBottom': '16px',
    'cursor': 'pointer',
    '&:hover': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.36), 0 2px 4px 0 rgba(0,0,0,0.24)',
    },
  },
  rootLink: {
    display: 'block',
    padding: '16px 24px',
  },
  headerRoot: {
    alignItems: 'baseline',
    padding: 0,
    position: 'relative',
    margin: '0 -24px',
    paddingLeft: '24px',
  },
  headerTitle: {
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  headerAction: {
    marginTop: 0,
    width: '30px',
    marginRight: '16px',
    marginLeft: '16px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '48px',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: '9px',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  field: {
    'paddingBottom': '4px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  fieldLabel: {
    marginBottom: '2px',
  },
  timeText: {
    marginLeft: '14px',
  },
  itemCount: {
    display: 'flex',
    paddingTop: '16px',
  },
  itemCountIcon: {
    height: '15px',
    marginRight: '7px',
    color: slateTwo,
  },
  emptyTile: {
    width: `${CARD_WIDTH + CARD_RIGHT_MARGIN}px`,
  },
  authorChipInitials: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: -24,
  },
  circle: {
    'width': '9px',
    'height': '9px',
    'position': 'absolute',
    'display': 'inline-block',
    'top': '12px',
    'left': '-15px',
    'borderRadius': '50%',
    'textIndent': '-9999px',
    'overflow': 'hidden',
    '& > div': {
      'borderRadius': 0,
    },
  },
};
