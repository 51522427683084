import React from 'react';

import {
  ActivityFeedItem as ActivityFeedItemType,
  ActivityItemStatuses,
  ReferenceLinkType,
} from 'data/activityFeed/types';
import get from 'lodash/get';

import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = get(activity, 'eventBy.displayName');
  const dbTitle = get(activity, 'references.database.title');
  const databaseType = activity.references.databaseType === 'database' ? ReferenceLinkType.DATABASE : ReferenceLinkType.TASK_DATABASE;

  if ([ActivityItemStatuses.CREATED, ActivityItemStatuses.DELETED].includes(activity.action)) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
        {activity.action.toLowerCase()} view in
        <ReferenceLink references={activity.references} type={databaseType}>
          {dbTitle}
        </ReferenceLink>
      </span>
    );
  }

  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          made changes in the view
        <strong className={'bold'}> {activity.references.title} </strong>
          in the
        <ReferenceLink references={activity.references} type={databaseType}>
          {dbTitle}
        </ReferenceLink>
        database.
      </span>
    );
  }

  return 'Activity';
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  return '';
}


export default {
  describeActivity,
  describeActivityDetails,
};
