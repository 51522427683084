import { Auth, Hub } from 'aws-amplify';

export const refreshSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const { refreshToken } = await cognitoUser.getSignInUserSession();

  return new Promise((resolve, reject) =>
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) reject(err);
      resolve(session);
    }),
  );
};

export const refreshLogin = () => {
  // reload and display login page but keep same location
  Hub.dispatch('login', { event: 'force-login' });
};
