import { Auth } from 'aws-amplify';

import { UserSessionError } from './errors';

interface AuthHeaders {
  Accept: string;
  Authorization?: string;
}

export function bearerToken(token: string, xsrf: string): string {
  return `Bearer ${token} ${xsrf}`;
}

// function authHeaders(token: string | null, xsrf: string | null): AuthHeaders {
//   const headers: AuthHeaders = { Accept: 'application/json' };

//   if (token && xsrf) {
//     headers.Authorization = bearerToken(token, xsrf);
//   }

//   return headers;
// }

export async function getAuthToken(): Promise<string> {
  const session = await Auth.currentSession() as any;
  return session.idToken.jwtToken;
}

async function getAuthHeaders(): Promise<AuthHeaders> {
  try {
    const session = await Auth.currentSession() as any;
    const headers: AuthHeaders = { Accept: 'application/json' };
    headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
    return headers;
  } catch (e) {
    throw new UserSessionError(e);
  }
}

export default getAuthHeaders;
