import { GetContextMenuItemsParams, MenuItemDef, RowNode } from 'ag-grid-community';
import { getSvgIconNode } from 'components/DataGrid/utils';
import { getTenantConfig } from 'env';
import svgIcons from 'styles/svgIcons';
import { CommonNode } from 'types/response';
import { DocumentNode } from 'types/response/documentNode';
import { Bookmark } from 'types/schema';
import { TenantConfig } from '../../types/tenantConfig';

const tenantConfig: TenantConfig = getTenantConfig();
type MenuItem = MenuItemDef | string;

interface GetDocumentsCellContextMenuParams {
  selectedItems: DocumentNode[];
  actions: {
    rename: (items: boolean) => void;
    favorite: () => void;
    unFavorite: () => void;
    moveCopy: () => void;
    share?: (items: DocumentNode[]) => void;
    download: (items: DocumentNode[]) => void;
    delete: (items: DocumentNode[]) => void;
    preview: (item: DocumentNode) => void;
    navigateToHistory: (id: string) => void;
  };
  permissions: string[];
  showHistoryOption: boolean;
  getBookmarkById: (bookmarkURI: string) => Bookmark | undefined;
}

export function getDocumentsCellContextMenu(params: GetDocumentsCellContextMenuParams): MenuItem[] {
  const { selectedItems, actions, getBookmarkById, permissions } = params;

  const {
    rename,
    favorite,
    unFavorite,
    download,
    moveCopy,
    delete: deleteItem,
    preview,
  } = actions;
  const items: MenuItem[] = [];

  if (selectedItems.length <= 1) {
    const selectedItem = selectedItems[0];
    if (!selectedItem.isFolder && selectedItem.hasPreview) {
      items.push({
        name: 'Preview',
        action: () => preview(selectedItem),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.Search),
      });
    }

    if (permissions.includes('databases:create')) {
      items.push({
        name: 'Rename',
        action: () => rename(true),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.Edit),
      });
    }
  }

  if (permissions.includes('databases:create') && selectedItems.length >= 1) {
    items.push(
      {
        name: 'Copy or Move',
        action: () => moveCopy(),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.Duplicate),
      },
    );
  }

  const hasFavorites = !!selectedItems.find(item => !!getBookmarkById(item.uri));

  if (permissions.includes('databases:update')) {
    if (hasFavorites) {
      items.push({
        name: 'Unfavorite',
        action: () => unFavorite(),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.StarLined),
      });
    } else {
      items.push({
        name: 'Favorite',
        action: () => favorite(),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.StarLined),
      });
    }
  }

  if (permissions.includes('databases:export')) {
    items.push(
      {
        name: 'Download',
        action: () => download(selectedItems),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.Download),
      },
    );
  }

  if (permissions.includes('databases:delete')) {
    items.push(
      {
        name: 'Delete',
        action: () => deleteItem(selectedItems),
        cssClasses: ['context-menu-item'],
        icon: getSvgIconNode(svgIcons.Delete),
      },
    );
  }

  return items;
}

interface GetCellContextMenuItemsActions {
  duplicateRecords(nodes: CommonNode[]): void;
  insertRecords(position: number, count: number, data: any): void;
  deleteRows(ids: string[]): void;
  navigateToHistory(id: string): void;
  expandRecord(node: CommonNode): void;
  showBulkEdit(): void;
  showMoveToPosition(): void;
}

interface GetCellContextMenuItemsParams {
  selectedItems: DocumentNode[];
  params: GetContextMenuItemsParams;
  rows: RowNode[];
  actions: GetCellContextMenuItemsActions;
  permissions: string[];
  showHistoryOption: boolean;
}

function addIdToSvgElement(svg: HTMLElement, rowId: string): HTMLElement {
  const newSvg = svg;
  newSvg.id = rowId;
  return newSvg;
}


export function getCellContextMenuItems(options: GetCellContextMenuItemsParams, isGridSorted: boolean, isFiltered: boolean, isLocked: boolean): MenuItem[] {
  const { params, selectedItems, rows, actions, permissions } = options;
  const items: MenuItem[] = [];
  const data: CommonNode = params.node && params.node.data;

  const {
    deleteRows,
    expandRecord,
    showBulkEdit,
  } = actions;

  // Clipboard.
  // Removed temporarily until it is properly implemented in S62: https://airtable.com/tblIUTnfJDElm5Sdg/viwfF4JDn5Cf7Ad35/recFW6yccOH5AiOhC?blocks=hide
  // items.push('copy');
  // items.push('paste');
  // items.push('separator');

  if (rows.length > 0) {
    const sortedSelection = rows
      .sort((rowA, rowB) => rowA.rowIndex - rowB.rowIndex);
    const topSelectedRowIndex = sortedSelection[0].rowIndex;
    const bottomSelectedRowIndex = sortedSelection[sortedSelection.length - 1].rowIndex;
    const isContiguousSelection = sortedSelection.reduce<boolean>(
      (prev: boolean, row: RowNode, index: number) => prev && row.rowIndex === sortedSelection[0].rowIndex + index,
      true,
    );
    const nodes: CommonNode[] = sortedSelection.map(row => row.data);
    const plural = sortedSelection.length === 1 ? '' : 's';

    if (permissions.includes('items:update') && (!params.columnApi?.getRowGroupColumns().length || (params.columnApi?.getRowGroupColumns().length && tenantConfig?.enabledFeatures?.orderInGrouping))) {
      if (!isGridSorted && !isLocked && !isFiltered) {
        items.push({
          name: 'Move to',
          action: () => actions.showMoveToPosition(),
          icon: getSvgIconNode(svgIcons.Drag),
        });
      }
    }

    if (isContiguousSelection && permissions.includes('items:create')) {
      items.push({
        name: `Insert ${sortedSelection.length} Record${plural} Above`,
        action: () => actions.insertRecords(topSelectedRowIndex, sortedSelection.length, data),
        icon: addIdToSvgElement(getSvgIconNode(svgIcons.ArrowUp), 'insertAbove'+data.id),
        cssClasses: [],
      });
      items.push({
        name: `Insert ${sortedSelection.length} Record${plural} Below`,
        action: () => actions.insertRecords(bottomSelectedRowIndex + 1, sortedSelection.length, data),
        icon: addIdToSvgElement(getSvgIconNode(svgIcons.ArrowDown), 'insertBelow'+data.id),
        cssClasses: [],
      });
      items.push({
        name: `Duplicate Record${plural}`,
        action: () => actions.duplicateRecords(nodes),
        icon: getSvgIconNode(svgIcons.Duplicate),
      });
    }
  }

  if (rows.length === 1) {
    items.push({
      name: 'Expand Record',
      action: () => expandRecord(data),
      icon: getSvgIconNode(svgIcons.ExpandRecord),
    });
  }

  if (permissions.includes('items:delete') && rows.length > 0) {
    const ids = rows.map((row) => row.data.id);
    items.push({
      name: ids.length > 1 ? 'Delete Records' : 'Delete Record',
      action: () => deleteRows(ids),
      icon: getSvgIconNode(svgIcons.Delete),
      cssClasses: [],
    });
  }

  if (selectedItems.length > 1 && permissions.includes('items:create')) {
    items.push({
      name: 'Bulk Actions',
      action: () => showBulkEdit(),
      icon: getSvgIconNode(svgIcons.Edit),
      cssClasses: [],
    });
  }

  if (tenantConfig.enabledFeatures.advancedContextMenu) {
    items.push(...['copy', 'paste', 'chartRange']);
  }
  return items;
}


