import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';
import { DateFormat, TimeFormat } from 'components/Fields/data';
import TextRenderer from 'components/NodeFieldData/components/TextRenderer';
import { getFormattedDate } from 'components/NodeFieldData/datetime/DateTime.utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DateTimeRendererProps extends ICellRendererParams {}

export const DateTimeRenderer = ({ value }: DateTimeRendererProps) => {
  const formattedDate = getFormattedDate(value, {
    dateFormat: DateFormat.US,
    timeFormat: TimeFormat.Twelve,
    includeTime: true,
  });

  return (
    <TextRenderer>
      {formattedDate}
    </TextRenderer>
  );
};
