import * as actions from './actions';

export const initialState = {
  nextTickId: null,
  idsForPendingOrganizers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.saveToPolling.type: {
      return {
        ...state,
        idsForPendingOrganizers: [...action.payload],
      };
    }

    case actions.updatePolling.type: {
      return {
        ...state,
        nextTickId: action.payload,
      };
    }

    case actions.stopPolling.fulfilled.type: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
