import 'regenerator-runtime/runtime';
import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, createStore, compose, Store, AnyAction } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk, { ThunkDispatch } from 'redux-thunk';
import logger from 'redux-logger';

// create the saga middleware
import rootSaga from 'sagas';

import createRootReducer, { State } from 'reducers';

import { isInDevelopment, logReduxState } from '../env';
// import CrashReporter from '../utilities/crashReporter';

export type AsyncDispatch = ThunkDispatch<void, void, AnyAction>;

const sagaMiddleware = createSagaMiddleware();

// const crashReporterMiddleware = () => (next) => (action) => {
//   try {
//     next(action);
//   } catch (err) {
//     // const data = {
//     //   file: action.type,
//     //   error: err.message,
//     // };
//     // CrashReporter.reportError(data);
//   }
// };

export const history = createHistory();

const middlewares = [
  thunk,
  sagaMiddleware,
  routerMiddleware(history),
  // crashReporterMiddleware,
];

if (isInDevelopment() && logReduxState()) {
  // add logger
  middlewares.push(logger);
}

// @ts-ignore
const composeEnhancers = (isInDevelopment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export function configureStore(): Store<State> {
  const store = createStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(...middlewares),
    ),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
