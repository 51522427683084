import * as React from 'react';

import LoadingState from 'data/LoadingState';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';

import {
  saveNewGridView,
  newViewName,
  newViewLockStatus,
  newViewPrivateStatus,
  newViewDefaultStatus,
} from '../../../data/modals/actions';
import { updateView } from '../../../data/views/views.actions';
import { ViewNameOrError, disolveViewNameOrError } from '../common/viewNameOrError';
import NewGridViewRender from './render';


interface DispatchProps {
  onCreateNewGridView(apiURI: string, viewName: string, isPrivate?: boolean, isLocked?: boolean, isDefault?: boolean): void;
  updateView: typeof updateView.request;
  onViewNameChange(value: string): void;
  onViewPrivateStateChange(isPrivate: boolean): void;
  onViewLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
}

interface OwnProps {
  apiURI: string;
  viewNameOrError: ViewNameOrError;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  onClose(): void;
}

interface StateProps {
  viewLoadingState: LoadingState;
  permissions: string[];
}

type Props
  = OwnProps
  & DispatchProps
  & StateProps
  ;

export class NewGridView extends React.Component<Props> {
  public render(): JSX.Element {
    const { viewName, errorMessage } = disolveViewNameOrError(this.props.viewNameOrError);

    return (
      <NewGridViewRender
        viewName={viewName}
        isPrivate={this.props.isPrivate}
        isLocked={this.props.isLocked}
        isDefault={this.props.isDefault}
        permissions={this.props.permissions}
        errorMessage={errorMessage}
        onCreateNewGridView={this.onCreateNewGridView}
        onClose={this.props.onClose}
        onViewNameChange={this.props.onViewNameChange}
        onPrivateStateChange={this.props.onViewPrivateStateChange}
        onLockStateChange={this.props.onViewLockStateChange}
        onDefaultStateChange={this.props.onDefaultStateChange}
        viewLoadingState={this.props.viewLoadingState}
      />
    );
  }

  private onCreateNewGridView = (): void => {
    const { viewName, hasError } = disolveViewNameOrError(this.props.viewNameOrError);
    const { apiURI, isPrivate, isLocked, isDefault } = this.props;

    if (!hasError && viewName !== '') {
      this.props.onCreateNewGridView(apiURI, viewName, isPrivate, isLocked, isDefault);
    }
  };
}

const mapDispatchToProps: DispatchProps = {
  updateView: updateView.request,
  onCreateNewGridView: saveNewGridView,
  onViewNameChange: newViewName,
  onViewPrivateStateChange: newViewPrivateStatus,
  onViewLockStateChange: newViewLockStatus,
  onDefaultStateChange: newViewDefaultStatus,
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  viewLoadingState: state.collections.views.loadingState,
  // @ts-ignore
  isPrivate: state.modals.isPrivate,
  isLocked: state.modals.isLocked,
  isDefault: state.modals.isDefault,
  permissions: getWorkspacePermissions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGridView);
