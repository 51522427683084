import * as React from 'react';
import Button, { Size, Variant as ButtonVariant } from 'components/Button';
import svgIcons from 'styles/svgIcons';
import { Notification } from './DatePickerNotify';
import Reminder from './Reminder';
import ActiveReminders from './ActiveReminders';
import './DatePickerNotify.scss';
import Modal from 'components/Modals/Modal';
import Confirm from 'components/Modals/Confirm';
import { useDispatch } from 'react-redux';
import { actions as remindersActions } from 'data/reminders/reminders';
import { Reminder as ReminderType, References } from 'data/reminders/reminders';

interface OwnProps {
  input?: JSX.Element;
  reminders: ReminderType[];
  onChange?: (value: Notification[], hideDayPicker?: boolean) => void;
  isScheduledOnChange?: () => void;
  clearDate?: () => void;
  dateValue: string;
  reminderKey: string;
  primaryFieldName: string;
  references: References;
  onNotifyDateChange: (timestamp: number) => void;
  format: string;
  permissions: string[];
  bulkReminders?: Array<ReminderType>;
}

type Props = OwnProps;

enum MODES {
  BROWSE = 'browse',
  ADD_REMINDER = 'add_reminder',
  ACTIVE_REMINDERS = 'active_reminders',
  UPDATE_REMINDER = 'update_reminder',
  DELETE_CONFIRM = 'delete_confirm',
}

const Notify: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState(MODES.BROWSE);
  const [selectedReminder, setSelectedReminder] = React.useState<Notification | null>(null);
  const [indexReminder, setIndexReminder] = React.useState<number>(-1);
  const reminders = props?.bulkReminders ? props.bulkReminders : props.reminders;

  const addReminder = () => {
    setMode(MODES.ADD_REMINDER);
  };

  const showActiveReminders = (): void => {
    setSelectedReminder(null);
    setMode(MODES.ACTIVE_REMINDERS);
  };

  const onClose = (): void => {
    setSelectedReminder(null);
    setMode(MODES.BROWSE);
  };

  const editReminder = (index: number) => {
    const reminder = reminders?.[index] || null;
    setSelectedReminder(reminder);
    setMode(MODES.UPDATE_REMINDER);
  };

  const confirmDelete = (index: number) => {
    const reminder = reminders?.[index] || null;
    setIndexReminder(index);
    setSelectedReminder(reminder);
    setMode(MODES.DELETE_CONFIRM);
  };

  const closeConfirm = () => {
    setSelectedReminder(null);
    setMode(MODES.ACTIVE_REMINDERS);
  };

  const deleteReminder = () => {
    if (props.bulkReminders) {
      if (reminders?.[indexReminder]) {
        reminders.splice(indexReminder, 1);
      }
    } else {
      dispatch(remindersActions.requestDelete({ id: selectedReminder?._id, key: props.reminderKey }));
    }
  };

  return (
    <div className="calendar-notifier">
      {(mode === MODES.ADD_REMINDER || mode === MODES.UPDATE_REMINDER) && (
        <Reminder
          bulkReminders={props.bulkReminders}
          onClose={onClose}
          dateValue={props.dateValue}
          showActiveReminders={reminders.length === 0 ? onClose : showActiveReminders}
          selectedReminder={selectedReminder}
          primaryFieldName={props.primaryFieldName}
          references={props.references}
          onNotifyDateChange={props.onNotifyDateChange}
        />
      )}

      {mode === MODES.ACTIVE_REMINDERS && (
        <ActiveReminders
          onClose={onClose}
          bulk={props.bulkReminders === reminders}
          reminders={reminders}
          addReminder={addReminder}
          editReminder={editReminder}
          confirmDelete={confirmDelete}
          primaryFieldName={props.primaryFieldName}
          format={props.format}
        />
      )}

      {mode === MODES.DELETE_CONFIRM && (
        <Modal onClose={onClose}>
          <Confirm
            title="Are you sure you want to delete this reminder?"
            close={closeConfirm}
            onConfirm={deleteReminder}
            confirmLabel="Delete Reminder"
          />
        </Modal>
      )}

      <div className="calendar-notifier-switch">
        {reminders?.length > 0 && (
          <Button label={`${reminders?.length}  Active Reminder${(reminders?.length > 1 ) ? 's' : '' }`} variant={ButtonVariant.BackgroundLink} size={Size.Normal} onClick={showActiveReminders} />
        )}
        {!reminders?.length && (
          <Button label="Add Reminder" disabled={!props.permissions?.includes('items:update')} variant={ButtonVariant.BackgroundLink} icon={svgIcons.Add} size={Size.Normal} onClick={addReminder} />
        )}
      </div>
    </div>
  );
};

export default Notify;
