import moment from 'moment';
import { IDoesFilterPassParams } from 'ag-grid-community';
import { dateFilterComparator } from '../../../../data/collections/filters';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function DateFilter() {

}

DateFilter.prototype.init = function(params) {
  this.valueGetter = params.valueGetter;
  this.value = '';
  this.model = null;
  this.field = params.colDef.field;
};

DateFilter.prototype.getGui = function() {
  return document.createElement('div');
};

DateFilter.prototype.doesFilterPass = function(params: IDoesFilterPassParams): boolean {
  if (!params.data?.[this.field]) return false;

  if (this.isFloatingFilter) {
    return moment(params.data?.[this.field]).format('MM/DD/YYYY h:mmA').toLowerCase().includes(this.value?.toLowerCase?.());
  }

  return dateFilterComparator(this.filter, moment(params.data?.[this.field]), moment(this.value, this.filter.dateFormat, true));
};

DateFilter.prototype.isFilterActive = function() {
  return this.value !== '';
};

DateFilter.prototype.getModel = function() {
  return { value: this.value };
};

DateFilter.prototype.setModel = function(model) {
  if (!model) return;
  this.value = model.value?.toLowerCase();
  this.filter = model.filter;
  this.isFloatingFilter = model.isFloatingFilter;
};

export default DateFilter;
