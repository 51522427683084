import React from 'react';
import Button, { Variant as ButtonVariant } from 'components/Button';
import CodeExpired from '../../assets/images/mfa/code_expired.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFA.index.style';

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
});


const LinkExpired = (props) => {
  const { classes } = props;

  const redirectSignIn = (event): void => {
    window.location.href = '/login';
  };

  return (
    <>
      <div className={classes.mfaFormContainer}>
        <div className={classes.topContent}>
          <h3>Link Expired</h3>
        </div>
        <div className={classes.bodyContent}>
          <div className={classes.imageBodyCenter}>
            <img src={CodeExpired} alt={'Code Expired Hubsync'} />
          </div>
          <p className={classes.textBodyCenter}>
            <span>Your link has expired.</span>
          </p>
          <div>
            <Button
              id="continueSetup"
              className={ classes.continueActionButton }
              label="Try Again"
              variant={ButtonVariant.Primary}
              onClick={redirectSignIn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(LinkExpired));
