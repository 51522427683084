import * as React from 'react';
import { ghostGrey, white } from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      backgroundColor: white,
      borderTop: `1px solid ${rgba(ghostGrey, 0.8)}`,
      borderBottom: `1px solid ${rgba(ghostGrey, 0.8)}`,
      padding: '4px 6px',
      display: 'flex',
      alignItems: 'stretch',
    },
  });

function Toolbar({ classes, ...props }: React.HTMLAttributes<HTMLDivElement> & WithStyles<typeof styles>) {
  return <div className={classes.root} {...props} />;
}

export default withStyles(styles)(Toolbar);
