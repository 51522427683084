import * as React from 'react';
import { debounce } from 'lodash';
import { Input, Label, Container, Column, TextArea, Error, ButtonForm, ButtonFormCancel, Form } from './createClientWorkspace.style';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { validClientId } from 'data/workspaceCreate/services';
import { CreateClientWorkspaceFormTypes, emailRegex, alphaNumericRegex, nameRegex } from './types';
import { createCollectionItemRequest } from 'data/collections/collections.actions.new';


function CreateClientWorkspaceForm({ createCollectionItem, onClose }) {
  const [error, setError] = React.useState<string>('');

  const { handleSubmit, errors, control, formState } = useForm<CreateClientWorkspaceFormTypes>({
    defaultValues: {
      taxpayerEmail: '',
      taxpayerFirstName: '',
      clientId: '',
      taxpayerLastName: '',
      clientName: '',
      description: '',
      office: '',
      partnerEmail: '',
      region: '',
      returnType: '',
      email: '',
      spouseLastName: '',
      spouseFirstName: '',
    },
    mode: 'onChange',
  });

  const verifyClientId = debounce((id: string, onChange: any) => {
    onChange(id);
    validClientId([id.toLocaleLowerCase()]).then(res => {
      if (!res.body.avaliable) {
        setError(`Client ID already exists.`);
      } else {
        setError('');
      }
    });
  }, 500);

  const onSubmit: SubmitHandler<CreateClientWorkspaceFormTypes> = async (data: CreateClientWorkspaceFormTypes, e) => {
    e?.preventDefault();
    const { clientName, description } = data;
    const payload = {
      url: window.location.pathname,
      data: {
        title: clientName,
        description,
        status: 'active',
        meta: data,
      },
    };
    await createCollectionItem(payload, 'workspaces');
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container >
        {/* Client ID */}
        <Column>
          <Label htmlFor="clientId">Client ID</Label>
          <Controller
            control={control}
            name="clientId"
            rules={{
              required: true,
              pattern: alphaNumericRegex,
              minLength: 2,
              maxLength: 10,
            }}
            render={({ onChange }) => (
              <Input
                type="text"
                onChange={(e) => {
                  verifyClientId(e.target.value, onChange);
                }}
                placeholder="Enter Client ID"
                width={21.21}
              />
            )}
          />
          {error && !errors.clientId ? <Error>{error}</Error> : ''}
          {errors.clientId?.type === 'required' && <Error>Client ID is required.</Error>}
          {(errors.clientId?.type === 'pattern' && formState.isDirty) && <Error>Client ID cannot have special characters.</Error>}
          {(errors.clientId?.type === 'maxLength' && formState.isDirty) && <Error>Client ID must be at most 10 characters.</Error>}
        </Column>

        <Column>
          <Label htmlFor="clientName">Client Name</Label>
          <Controller
            control={control}
            name="clientName"
            rules={{
              minLength: 2,
              maxLength: 40,
              required: true,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Client Name"
              />
            )}
          />
          {errors.taxpayerFirstName?.type === 'required' && <Error>Client Name is required.</Error>}
          {(errors.clientName?.type === 'minLength' && formState.isDirty) && <Error>Client Name must be at least 2 characters..</Error>}
          {(errors.clientName?.type === 'maxLength' && formState.isDirty) && <Error>Client Name must be less than 40 characters..</Error>}
        </Column>
      </Container>

      {/* first name */}
      <Container>
        <Column>
          <Label htmlFor="taxpayerFirstName">First Name</Label>
          <Controller
            control={control}
            name="taxpayerFirstName"
            rules={{
              required: true,
              pattern: nameRegex,
              minLength: 2,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Client Name"
                width={21.21}
              />
            )}
          />
          {errors.taxpayerFirstName?.type === 'required' && <Error>First Name is required.</Error>}
          {(errors.taxpayerFirstName?.type === 'pattern' && formState.isDirty) && <Error>Only alphanumeric and some special characters like:” Hyphen, Period, Apostrophe”.</Error>}
          {(errors.taxpayerFirstName?.type === 'minLength' && formState.isDirty) && <Error>First Name must be at least 2 characters.</Error>}
          {(errors.taxpayerFirstName?.type === 'maxLength' && formState.isDirty) && <Error>First Name must be less than 40 characters.</Error>}
        </Column>

        {/* Last Name */}
        <Column>
          <Label htmlFor="taxpayerLastName">Last Name</Label>
          <Controller
            control={control}
            name="taxpayerLastName"
            rules={{
              required: true,
              pattern: nameRegex,
              minLength: 2,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Last Name"
              />
            )}
          />
          {errors.taxpayerLastName?.type === 'required' && <Error>Last Name is required.</Error>}
          {(errors.taxpayerLastName?.type === 'pattern' && formState.isDirty) && <Error>Only alphanumeric and some special characters like:” Hyphen, Period, Apostrophe”.</Error>}
          {(errors.taxpayerLastName?.type === 'minLength' && formState.isDirty) && <Error>Last Name must be at least 2 characters.</Error>}
          {(errors.taxpayerLastName?.type === 'maxLength' && formState.isDirty) && <Error>Last Name must be less than 40 characters.</Error>}
        </Column>
      </Container>

      {/* Client Email */}
      <Container >
        <Column>
          <Label htmlFor="taxpayerEmail">Email</Label>
          <Controller
            control={control}
            name="taxpayerEmail"
            rules={{
              required: true,
              pattern: emailRegex,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Email"
              />
            )}
          />
          {errors.taxpayerEmail?.type === 'required' && <Error>Email is required.</Error>}
          {(errors.taxpayerEmail?.type === 'pattern' && formState.isDirty) && <Error>Enter a correct email.</Error>}
          {(errors.taxpayerEmail?.type === 'maxLength' && formState.isDirty) && <Error>Email must be less than 40 characters.</Error>}
        </Column>
      </Container>

      {/* Partner email */}
      <Container >
        <Column>
          <Label htmlFor="partnerEmail">Partner in Charge Email</Label>
          <Controller
            control={control}
            name="partnerEmail"
            rules={{
              required: true,
              pattern: emailRegex,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Partner Email"
              />
            )}
          />
          {errors.partnerEmail?.type === 'required' && <Error>Partner in Charge Email is required.</Error>}
          {(errors.partnerEmail?.type === 'pattern' && formState.isDirty) && <Error>Enter a correct email.</Error>}
        </Column>
      </Container>

      {/* Region */}
      <Container >
        <Column>
          <Label htmlFor="region">Region</Label>
          <Controller
            control={control}
            name="region"
            rules={{
              required: true,
              minLength: 2,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Region"
              />
            )}
          />
          {errors.region?.type === 'required' && <Error>Region is required.</Error>}
          {(errors.region?.type === 'minLength' && formState.isDirty) && <Error>Region must be at least 2 characters.</Error>}
          {(errors.region?.type === 'maxLength' && formState.isDirty) && <Error>Region must be less than 40 characters.</Error>}
        </Column>
      </Container>

      {/* Return type */}
      <Container>
        <Column>
          <Label htmlFor="returnType">Return Type</Label>
          <Controller
            control={control}
            name="returnType"
            rules={{
              required: true,
              minLength: 4,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Return type"
                width={21.21}
              />
            )}
          />
          {errors.returnType?.type === 'required' && <Error>Return type is required.</Error>}
          {(errors.returnType?.type === 'minLength' && formState.isDirty) && <Error>Return type must be at least 4 characters.</Error>}
          {(errors.returnType?.type === 'maxLength' && formState.isDirty) && <Error>Return Type must be less than 40 characters..</Error>}
        </Column>

        {/* Office */}
        <Column>
          <Label htmlFor="office">Office</Label>
          <Controller
            control={control}
            name="office"
            rules={{
              required: true,
              minLength: 4,
              maxLength: 40,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Office"
              />
            )}
          />
          {errors.office?.type === 'required' && <Error>Office is required.</Error>}
          {(errors.office?.type === 'minLength' && formState.isDirty) && <Error>Office must be at least 4 characters.</Error>}
          {(errors.office?.type === 'maxLength' && formState.isDirty) && <Error>Office must be less than 40 characters.</Error>}
        </Column>
      </Container>

      {/* Spouse Fist Name */}
      <Container>
        <Column>
          <Label htmlFor="spouseFirstName">Spouse First Name <span>(Optional)</span></Label>
          <Controller
            control={control}
            name="spouseFirstName"
            rules={{
              minLength: 2,
              maxLength: 40,
              pattern: alphaNumericRegex,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Spouse name"
                width={21.21}
              />
            )}
          />
          {(errors.spouseFirstName?.type === 'minLength' && formState.isDirty) && <Error>Spouse First Name must be at least 2 characters..</Error>}
          {(errors.spouseFirstName?.type === 'maxLength' && formState.isDirty) && <Error>Spouse First Name must be less than 40 characters..</Error>}
          {(errors.spouseFirstName?.type === 'pattern' && formState.isDirty) && <Error>Spouse First Name must be alphanumeric..</Error>}
        </Column>

        {/* Spouse Last Name */}
        <Column>
          <Label htmlFor="spouseLastName">Spouse Last Name <span>(Optional)</span></Label>
          <Controller
            control={control}
            name="spouseLastName"
            rules={{
              minLength: 2,
              maxLength: 40,
              pattern: alphaNumericRegex,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Spouse Last name"
              />
            )}
          />
          {(errors.spouseLastName?.type === 'minLength' && formState.isDirty) && <Error>Spouse Last Name must be at least 2 characters.</Error>}
          {errors.spouseLastName?.type === 'maxLength' && <Error>Spouse Last Name must be less than 40 characters.</Error>}
          {errors.spouseLastName?.type === 'pattern' && <Error>Spouse Last Name must be alphanumeric.</Error>}
        </Column>
      </Container>

      {/* Spouse Email */}
      <Container >
        <Column>
          <Label htmlFor="email">Spouse Email <span>(Optional)</span></Label>
          <Controller
            control={control}
            name="email"
            rules={{
              pattern: emailRegex,
            }}
            render={(props) => (
              <Input
                type="text"
                onChange={props.onChange}
                placeholder="Enter Spouse Email"
              />
            )}
          />
          {errors.email?.type === 'pattern' && <Error>Insert a correct Email address.</Error>}
        </Column>
      </Container>

      {/* Description */}
      <Container >
        <Column>
          <Label htmlFor="description">Description<span>(Optional)</span></Label>
          <Controller
            control={control}
            name="description"
            render={(props) => (
              <TextArea
                onChange={props.onChange}
                placeholder="Enter description"
              ></TextArea>
            )}
          />
        </Column>
      </Container>

      <Container
        justifyContent="flex-end"
      >
        <ButtonFormCancel
          onClick={() => onClose()}
        >
          Cancel
        </ButtonFormCancel>
        <ButtonForm
          color={(formState.isValid && error.length === 0 ? '#0065FF' : '#96BBF8')}
          hover={(formState.isValid && error.length === 0 ? '#1960CB' : '#96BBF8')}
          active={(formState.isValid && error.length === 0 ? '#02409F' : '#96BBF8')}
          disabled={!formState.isValid || formState.isSubmitting || error.length >= 1}
        >
          Save
        </ButtonForm>
      </Container>
    </Form>
  );
}
const mapDispacth = { createCollectionItem: createCollectionItemRequest };
export default connect(null, mapDispacth)(CreateClientWorkspaceForm);
