import { DatabasesState } from 'data/databases/databases.types';

import { State } from 'reducers';
import { DatabaseNode } from 'types/response/databaseNode';
import LoadingState from 'data/LoadingState';

export const getTaskDatabasesState = (state: State): DatabasesState => state.taskDatabases;
export const getCurrentTaskDatabaseId = (state: State): string | null => getTaskDatabasesState(state).current || null;
export const getAllTaskDatabasesById = (state: State) => getTaskDatabasesState(state).byId;
export const getAllTaskDatabases = (state: State): DatabaseNode[] => Object.values(getAllTaskDatabasesById(state));
export const getCurrentTaskDatabase = (state: State): DatabaseNode | undefined =>
  getAllTaskDatabasesById(state)[getCurrentTaskDatabaseId(state) || ''];

export function getLoading(state: State): LoadingState {
  return getTaskDatabasesState(state).loadingState;
}
