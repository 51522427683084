import * as React from 'react';

import { CancelEnvelopeModal, CancelEnvelopePermission } from 'components/Modals/CancelEnvelope';
import { ResendEnvelopeModal, ResendEnvelopePermission } from 'components/Modals/ResendEnvelope';
import CreateCollectionItem from 'components/Modals/CreateCollectionItem';
import DeleteFolder from 'components/Modals/DeleteConfirm/DeleteFolder';
import DeleteGridColumn from 'components/Modals/DeleteConfirm/DeleteGridColumn';
import DeleteItems from 'components/Modals/DeleteConfirm/DeleteItems';
import DeleteNode from 'components/Modals/DeleteConfirm/DeleteNode';
import DeleteSheet from 'components/Modals/DeleteConfirm/DeleteSheet';
import DeleteView from 'components/Modals/DeleteConfirm/DeleteView';
import DeleteKanbanColumn from 'components/Modals/DeleteKanbanColumn';
import DuplicateDatabase from 'components/Modals/DuplicateDatabase';
import EditView from 'components/Modals/EditView';
import Info from 'components/Modals/Info';
import LockPermission from 'components/Modals/LockPermission';
import MoveCopyDocument from 'components/Modals/MoveCopyDocument';
import NewCalendarView from 'components/Modals/NewCalendarView';
import NewGridView from 'components/Modals/NewGridView';
import NewKanbanView from 'components/Modals/NewKanbanView';
import NodeView from 'components/Modals/NodeView';
import RenameBreadcrumb from 'components/Modals/RenameBreadcrumb';
import RenameCollectionItem from 'components/Modals/RenameCollectionItem';
import RenameDocument from 'components/Modals/RenameDocument';
import RenameKanbanColumn from 'components/Modals/RenameKanbanColumn';
import OrganizerRequiresModal from 'components/Modals/OrganizerRequiresModal';
import { DeleteAttachmentModal, ModalType, NoModal, State as ModalsState } from 'data/modals/state';


import AccountView from './Account';
import WorkSpaceModalInfo from './workSpaceModalInfo';
import { RenameWorkspaceWithMeta } from './RenameWorkspaceWithMeta';

type ExcludedModal =
  | NoModal
  | DeleteAttachmentModal
  ;

interface Props {
  closeModal: () => void;
  modals: Exclude<ModalsState, ExcludedModal>;
  checkClose?: boolean;
  resetCheckClose?(): void;
}

export const BaseModal = ({ closeModal, modals, checkClose = false, resetCheckClose }: Props): JSX.Element => {
  switch (modals.type) {
    case ModalType.ACCOUNT:
      return (
        <AccountView />
      );
    case ModalType.NEW_CALENDAR_VIEW_MODAL:
      return (
        <NewCalendarView
          viewNameOrError={modals.viewNameOrError}
          allFields={modals.allFields}
          selectedTitleField={modals.selectedTitleField}
          titleFields={modals.titleFields}
          selectedDateField={modals.selectedDateField}
          dateFields={modals.dateFields}
          onClose={closeModal}
        />
      );

    case ModalType.NEW_GRID_VIEW_MODAL:
      return (
        <NewGridView
          apiURI={modals.apiURI}
          viewNameOrError={modals.viewNameOrError}
          onClose={closeModal}
        />
      );

    case ModalType.NEW_KANBAN_VIEW_MODAL:
      return (
        <NewKanbanView
          viewNameOrError={modals.viewNameOrError}
          allFields={modals.allFields}
          selectedTitleField={modals.selectedTitleField}
          titleFields={modals.titleFields}
          selectedCategoryField={modals.selectedCategoryField}
          categoryFields={modals.categoryFields}
          onClose={closeModal}
        />
      );

    case ModalType.NODE_VIEW:
      return (
        <NodeView
          node={modals.node}
          windowTitle={modals.windowTitle}
          enableSwitcher={modals.enableSwitcher}
          checkClose={checkClose}
          resetCheckClose={resetCheckClose}
        />
      );

    case ModalType.EDIT_VIEW:
      return (
        <EditView
          view={modals.view}
        />
      );

    case ModalType.DELETE_FOLDER:
      return (
        <DeleteFolder
          {...modals}
          onClose={closeModal}
        />
      );

    case ModalType.DELETE_NODE:
      return (
        <DeleteNode
          node={modals.node}
          collectionType={modals.collectionType}
        />
      );

    case ModalType.LOCK_PERMISSION:
      return (
        <LockPermission />
      );

    case ModalType.DELETE_ITEMS:
      return (
        <DeleteItems
          ids={modals.ids}
          collectionType={modals.collectionType}
          agGridApi={modals.agGridApi}
        />
      );

    case ModalType.DELETE_GRID_COLUMN:
      return (
        <DeleteGridColumn
          columnId={modals.columnId}
        />
      );

    case ModalType.DELETE_VIEW:
      return (
        <DeleteView
          viewId={modals.viewId}
        />
      );

    case ModalType.DELETE_SHEET:
      return (
        <DeleteSheet
          sheetId={modals.sheetId}
        />
      );

    case ModalType.DUPLICATE_DATABASE:
      return (
        <DuplicateDatabase
          database={modals.database}
          workspace={modals.workspace}
          availableWorkspaces={modals.availableWorkspaces}
          shouldDuplicateRecords={modals.shouldDuplicateRecords}
          shouldDuplicateComments={modals.shouldDuplicateComments}
          collectionType={modals.collectionType}
          onClose={closeModal}
        />
      );

    case ModalType.RENAME_BREADCRUMB:
      return (
        <RenameBreadcrumb
          {...modals}
          onClose={closeModal}
        />
      );

    case ModalType.RENAME_COLLECTION_ITEM:
      return (
        <RenameCollectionItem
          collectionType={modals.collectionType}
          node={modals.node}
        />
      );

    case ModalType.RENAME_DOCUMENT:
      return (
        <RenameDocument
          document={modals.document}
        />
      );

    case ModalType.RENAME_KANBAN_COLUMN:
      return (
        <RenameKanbanColumn
          categoryFieldId={modals.categoryFieldId}
          choiceId={modals.choiceId}
          choices={modals.choices}
        />
      );

    case ModalType.DELETE_KANBAN_COLUMN:
      return (
        <DeleteKanbanColumn
          categoryField={modals.categoryField}
          choiceId={modals.choiceId}
        />
      );

    case ModalType.CREATE_COLLECTION_ITEM:
      return (
        <CreateCollectionItem
          collectionType={modals.collectionType}
        />
      );

    case ModalType.MOVE_COPY_DOCUMENT:
      return (
        <MoveCopyDocument
          selectedItems={modals.selectedItems}
        />
      );

    case ModalType.INFO:
      return (
        <Info
          onClose={closeModal}
          title={modals.title}
          message={modals.message}
          confirmLabel={modals.confirmLabel}
        />
      );

    case ModalType.CANCEL_ENVELOPE_PERMISSION:
      return (
        <CancelEnvelopePermission />
      );

    case ModalType.CANCEL_ENVELOPE:
      return (
        <CancelEnvelopeModal
          envelopeID={modals.envelopeId}
        />
      );

    case ModalType.ORGANIZER_REQUIRES_WORKSPACE:
      return <OrganizerRequiresModal
        recordIds={modals.ids}
      />;

    case ModalType.RESEND_ENVELOPE_PERMISSION:
      return (
        <ResendEnvelopePermission />
      );

    case ModalType.RESEND_ENVELOPE:
      return (
        <ResendEnvelopeModal
          envelopeID={modals.envelopeId}
          signerEmail={modals.signerEmail}
        />
      );

    case ModalType.OPEN_WORKSPACE_INFO_MODAL:
      return (
        <WorkSpaceModalInfo
          title='Information'
          close={() => closeModal()}
          workSpaceData={modals.workspaceInfo}
        />
      );

    case ModalType.RENAME_WORKSPACE_WITH_META:
      return (
        <RenameWorkspaceWithMeta
          title='Rename Workspace'
          close={() => closeModal()}
          currentNode={modals.currentNode}
        />
      );

    case ModalType.SET_NODE_DATA:
      return (<></>);

    default:
      return (<></>);
  }
};
