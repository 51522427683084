import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';

import { styles } from './ListOverflow.style';

export interface ListOverflowItemsProps extends WithStyles<typeof styles> {
  children: React.ReactNode[];
  renderMore: (count: number) => React.ReactNode;
  width: number;
  height: number;
  fixedRight?: React.ReactNode;
}

interface ListOverflowItemsState {
  computedRowHeight: number | null;
  computedItemsWidth: number[] | null;
}

const TEMP_ITEM_CLASSNAME = 'list-overflow-item';

class ListOverflowItems extends React.Component<ListOverflowItemsProps, ListOverflowItemsState> {
  listRef = React.createRef<HTMLDivElement>();
  unmounting = false;

  public constructor(props: ListOverflowItemsProps) {
    super(props);

    this.state = {
      computedRowHeight: null,
      computedItemsWidth: null,
    };
  }

  public componentDidMount(): void {
    this.recalculateDimensions();
  }

  public componentWillUnmount(): void {
    this.unmounting = true;
  }

  public render(): React.ReactNode {
    const { fixedRight = null, children } = this.props;
    const { computedRowHeight, computedItemsWidth } = this.state;

    // no children to render
    if (!children.length) {
      return fixedRight;
    }

    // measure
    if (computedRowHeight === null || computedItemsWidth === null) {
      return (
        <div style={{ display: 'flex', opacity: 0 }} ref={this.listRef}>
          {this.props.children.map(this.renderChildToMeasure)}
        </div>
      );
    }

    return this.renderItems();
  }

  private renderChildToMeasure = (child: React.ReactNode, index: number): JSX.Element => {
    return (
      <div
        key={index}
        style={{ display: 'flex' }}
        className={TEMP_ITEM_CLASSNAME}>
        {child}
      </div>
    );
  };

  private renderItems = (): React.ReactNode => {
    const { height, width, renderMore, fixedRight = null, children, classes } = this.props;
    const { computedRowHeight, computedItemsWidth } = this.state;

    if (computedRowHeight === null || computedItemsWidth === null) {
      return null;
    }

    const totalRows = Math.floor(height / computedRowHeight);
    let currentRow: React.ReactNode[] = [];
    let currentRowWidth = 0;
    let itemsAdded = 0;

    const rows: React.ReactNode[][] = [currentRow];

    for (let i = 0; i < computedItemsWidth.length; ++i) {
      const itemWidth = computedItemsWidth[i];
      let shouldAddItem = false;

      if ((!currentRow.length && itemWidth < width - 45) || (currentRowWidth + itemWidth < width - (i < computedItemsWidth.length - 1 ? 45 : 0))) {
        shouldAddItem = true;
      } else if (rows.length < totalRows) {
        currentRow = [];
        currentRowWidth = 0;
        rows.push(currentRow);

        shouldAddItem = true;
      }

      if (shouldAddItem) {
        currentRowWidth += itemWidth;
        ++itemsAdded;
        currentRow.push(<React.Fragment key={currentRow.length}>{children[i]}</React.Fragment>);
      } else {
        break;
      }
    }

    if (itemsAdded < children.length) {
      currentRow.push(
        <React.Fragment key={currentRow.length}>
          {renderMore(children.length - itemsAdded)}
        </React.Fragment>,
      );
    }

    if (fixedRight) {
      currentRow.push(fixedRight);
    }

    return (
      rows.map((row, index) => (
        <div className={classes.row} key={index}>{row}</div>
      ))
    );
  };

  private recalculateDimensions = (): void => {
    const listNode = this.listRef.current;

    // setImmediate to make it work inside agGrid's cell
    setTimeout(() => {
      if (this.unmounting || !listNode) return;
      const listRefRect = listNode.getBoundingClientRect();
      const listItems = Array.from(listNode.querySelectorAll(`.${TEMP_ITEM_CLASSNAME}`).values());
      const computedItemsWidth = listItems.map(node => node.getBoundingClientRect().width);
      this.setState({
        computedRowHeight: listRefRect.height,
        computedItemsWidth,
      });
    }, 0);
  };
}

export default withStyles(styles)(ListOverflowItems);
