import {
  compose,
  map,
  tail,
  groupBy,
  uniqWith,
  reject,
  first,
  equals,
  isEmpty,
  values,
  flatMap,
  mergeAll,
} from 'lodash/fp';
import { DirectoryTree } from 'data/ui/fileUpload/fileUpload.types';

const nestFolders = compose(
  map((folderPaths) => ({
    fileName: folderPaths[0][0],
    isDirectory: true,
    nodes: nestFolders(map(tail, folderPaths)),
  })),
  values,
  // @ts-ignore
  groupBy(first),
  uniqWith(equals),
  reject(isEmpty),
);

// TODO browser specific property webkitRelativePath for folder upload
// check in other browsers, replace with more common solution
const getFolderPath = (file: File) => (file as any).webkitRelativePath
  .split('/')
  .slice(0, -1); // remove filename

export const getDirectoriesTree: (files: File[]) => DirectoryTree[] =
  compose(nestFolders, map(getFolderPath));

const unnestDirectories = (directories, prefix = '') => flatMap(
  dir => {
    const { fileName, id, nodes = [] } = dir;
    const dirName = prefix + fileName;
    return [
      { [dirName]: id },
      ...unnestDirectories(nodes, `${fileName}/`),
    ];
  },
  directories,
);

export const createFolderIdsMap = compose(
  mergeAll,
  unnestDirectories,
);

export const getAbsolutePath = (file: File) => getFolderPath(file).join('/');
