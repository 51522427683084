import { InputClassKey } from '@material-ui/core/Input';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as Colors from 'styles/colors';

type RelevantInputClassKey
  = 'root'
  | 'input'
  | 'inputMultiline'
  | 'focused'
  | 'error'
  ;

type ClassKey
  = Extract<InputClassKey, RelevantInputClassKey>
  | 'formControlRoot'
  | 'locked'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    borderRadius: '2px',
    border: `1px solid ${Colors.lightGreyA}`,
    padding: '0px 0px 0px 8px',
  },
  input: {
    height: '20px',
    backgroundColor: Colors.greyTransparent,
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    padding: '10px 8px',
  },
  inputMultiline: {
    height: '88px',
    lineHeight: '17px',
  },
  focused: {
    border: `1px solid ${Colors.hubsyncBlue}`,
  },
  locked: { paddingRight: '24px' },
  error: { borderColor: Colors.tomato },
  formControlRoot: { marginBottom: '1px' },
};
