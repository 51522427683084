import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { MODULE } from './constants';
import * as services from './services';
import * as types from './types';

export const startActivityFeedRefresh = createAction<
  {pathname: string}
>(`${MODULE}/START_REFRESH`);

export const filter = createAction<types.FilterQuery>(`${MODULE}/FILTER`);

export const stopActivityFeedRefresh = createAction(`${MODULE}/STOP_REFRESH`);

export const setNext = createAction<string>(`${MODULE}/SET_NEXT`);

export const reset = createAction(`${MODULE}/RESET`);

export const loadActivities = createAsyncThunk<
  types.LoadActivitiesPayload,
  types.LoadActivitiesParams
>(
  `${MODULE}/LOAD_ACTIVITIES`,
  async ({ workspaceID, next }, { dispatch }) => {
    const response = await services.fetchActivityFeed(workspaceID, next);
    dispatch(setNext(response?.nextValue));
    return { items: response?.items, next: response?.nextValue };
  },
);
