import { createWorkspaceUrl } from '../../../utilities/createUrl';
import { CollectionTypes } from '../../../types/schema';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';

class ImportEntity {
  IMPORT_DATABASE_CREATED(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;

    if (data.eventBy !== currentUserId) {
      return;
    }

    const workspaceId = state.workspaces.current;
    const url = `${createWorkspaceUrl(workspaceId)}/${CollectionTypes.databases}`;

    store.dispatch(fetchCollectionRequest(url));
  }
}

export default new ImportEntity();
