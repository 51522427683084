import { HistoryItem } from 'types/schema';
import { formatDate } from 'utilities/dates';
import { HistoryGroup } from 'data/ui/documentPreview/documentPreview.types';

const ITEM_DATE_FORMAT = 'YYYYMMDD';
const MONTH_FORMAT = 'MMMM YYYY';
const TIME_FORMAT = 'h:mma';
const DATE_WITH_TIME_FORMAT = 'MM/DD/YYYY hh:mma';

const getGroupLabel = (item: HistoryItem, todayKey: string, yesterdayKey: string) => {
  const itemDateKey = formatDate(item.date, ITEM_DATE_FORMAT);

  if (itemDateKey === todayKey) return 'Today';
  if (itemDateKey === yesterdayKey) return 'Yesterday';

  return formatDate(item.date, MONTH_FORMAT);
};

interface Groups {
  [groupDateStr: string]: {
    label: string;
    subGroupKeys: string[];
    subGroups: {
      [key: string]: { label: string; items: HistoryItem[] };
    };
  };
}

/**
 * Group items in the following way:
 * - today
 * - yesterday
 * - by month + year
 *
 * In scope of each group group items by specific time format.
 * Format for today, yesterday - {@link TIME_FORMAT}
 * Format for month + year - {@link DATE_WITH_TIME_FORMAT}
 *
 * @param {HistoryItem[]} historyItems
 * @returns {HistoryGroup[]}
 */
export const transformHistoryResponse = (historyItems: HistoryItem[]): HistoryGroup[] => {
  const groups: Groups = {};
  const groupKeys: string[] = [];
  const today = new Date().getTime();
  const todayGroupKey = formatDate(today, ITEM_DATE_FORMAT);
  const yesterdayGroupKey = formatDate(today - 24 * 3600 * 1000, ITEM_DATE_FORMAT);

  historyItems.forEach(item => {
    const groupLabel = getGroupLabel(item, todayGroupKey, yesterdayGroupKey);
    let group = groups[groupLabel];
    if (!group) {
      group = {
        label: groupLabel,
        subGroupKeys: [],
        subGroups: {},
      };
      groups[groupLabel] = group;
      groupKeys.push(groupLabel);
    }
    const itemDateFormat = (groupLabel === 'Today' || groupLabel === 'Yesterday') ? TIME_FORMAT : DATE_WITH_TIME_FORMAT;
    const timeLabel = formatDate(item.date, itemDateFormat);
    let subgroup = group.subGroups[timeLabel];
    if (!subgroup) {
      subgroup = { label: timeLabel, items: [] };
      group.subGroups[timeLabel] = subgroup;
      group.subGroupKeys.push(timeLabel);
    }
    subgroup.items.push(item);
  });

  return groupKeys.map(key => {
    const { label, subGroups, subGroupKeys } = groups[key];
    return {
      label,
      byTime: subGroupKeys.map(sk => ({
        label: subGroups[sk].label,
        items: subGroups[sk].items,
      })),
    };
  });
};
