export interface References {
    workspaceID: string;
    databaseID: string;
    sheetID: string;
    itemID: string;
    primaryFieldID?: number;
    primaryFieldValue?: string;
    database: { type: string };
    databaseType?: string;
    title? : string;
}

export interface ActivityFeedItem {
    _id: string;
    model: string;
    modelType: string;
    modelID: string;
    action: string;
    diff: any;
    eventAt: number;
    eventBy: string;
    ip: string;
    userAgent: string;
    tags: string[];
    meta: any;
    references: References;
}

export interface FetchActivityFeedQuery {
    next?: string;
    workspaceID?: string;
    type?: string;
    query?: string;
}

export interface FetchActivityFeed {
    type: string;
    query: FetchActivityFeedQuery;
}

export interface FilterQuery {
    query?: string;
    type?: string;
}

export interface LoadActivitiesPayload {
    items: ActivityFeedItem[];
    next: string;
}

export interface LoadActivitiesParams {
    workspaceID: string;
    next: string;
}

export interface ActivityFeedGroup {
    eventDateStr: string;
    activities: ActivityFeedItem[];
}

export interface ActivityFeedState {
    isFetchLoading: boolean;
    isEndResult: boolean;
    feed: ActivityFeedGroup[] | null;
    error: string | null;
    isSyncInProgress: boolean;
    next: string | undefined;
    query: string | undefined;
    type: string | undefined;
    lockScroll: boolean | undefined;
    items: ActivityFeedItem[];
}

export enum ReferenceLinkType {
    WORKSPACE = 'WORKSPACE',
    DATABASE = 'DATABASE',
    TASK_DATABASE = 'TASK_DATABASE',
    ITEM = 'ITEM',
    TASK_ITEM = 'TASK_ITEM',
    MESSAGE = 'MESSAGE',
    FILE = 'FILE'
}

export const ActivityItemStatuses = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  DUPLICATED: 'DUPLICATED',
  ITEM_BULK_UPDATED: 'ITEM_BATCH_UPDATED',
  ITEM_BULK_DELETED: 'ITEM_BATCH_DELETED',
};
