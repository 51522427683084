import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { CheckboxData } from '../../data';
import Switch from '../../../Switch';
import Typography, { Variant } from '../../../Typography';

interface Props extends WithStyles<ClassKey> {
  data: CheckboxData;
  onDataUpdate(data: CheckboxData): void;
}

function Checkbox(props: Props): JSX.Element {
  const { classes, data } = props;

  function onChecked(): void {
    props.onDataUpdate({ ...data, defaultValue: !data.defaultValue });
  }

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        A single checkbox that can be checked or unchecked.
      </Typography>
      <div className={classes.toggle}>
        <Switch
          label="Default value"
          selected={data.defaultValue}
          onClick={onChecked}
          labelRight
        />
      </div>
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'toggle'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  toggle: { marginTop: 8 },
});

export default styles(Checkbox);
