import { typeIcon } from '../../../pages/Files/Renderers/CellRenderers/IconRenderer';
import _ from 'lodash';
import BaseRenderer from '../BaseRendererPrototype';
import 'components/AccountChip/AccountChip.style.scss';
import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';
import { getGridRowWrapLines, getGridRowMaxTextLines } from '../../AgGrid/utils';
import GenericTextRenderer from '../GenericTextRenderer';

const GET_ACCOUNTS_KEY = 'app.accounts.byId';

function AccountRenderer() {
  return true;
}

AccountRenderer.prototype = Object.create(BaseRenderer.prototype);

export function createMultiChoiceElement(params) {
  const { node, column, value } = params;

  const formattedValue = value && (typeof value === 'object' ? value : typeof value === 'string' && value.includes(',')? value.split(',') : [value + '']);
  const parsedValue = formattedValue && formattedValue.filter(choice => choice);
  const lines = node ? getGridRowMaxTextLines(node.rowHeight) : 1;

  const element = document.createElement('div');
  element.id = `${node.id}-${column.colId}`;
  const wrapClass = node? getGridRowWrapLines(node.rowHeight) : 'single-choice-item';
  element.setAttribute('class', wrapClass);

  if (parsedValue && parsedValue.length > 0) {
    let found = false;
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    for (let valueIndex = 0; valueIndex < parsedValue.length; valueIndex++) {
      let item = _.get(window.appStore.getState(), `${GET_ACCOUNTS_KEY}.${parsedValue[valueIndex]}`);
      if (!item) {
        const account = Object.values(_.get(window.appStore.getState(), `${GET_ACCOUNTS_KEY}`) || {}).find((account) => account.displayName === parsedValue[valueIndex]);
        if (account) {
          item = account;
        }
      }

      if (!item) {
        continue;
      }

      const AccountWrapper = document.createElement('div');
      AccountWrapper.className = 'account-chip-account_wrapper';
      const IconWrapper = document.createElement('div');
      IconWrapper.style = `
        background-color: ${item.backgroundColor || 'lightGrey'};
      `;
      IconWrapper.className = 'account-chip-icon_wrapper';
      IconWrapper.append(typeIcon['userIcon'].cloneNode(true));
      AccountWrapper.append(IconWrapper);

      const valueElement = document.createElement('div');
      valueElement.className = 'account-chip-account_name';
      valueElement.style = GenericTextRenderer(lines);
      valueElement.style.whiteSpace = 'nowrap';
      valueElement.innerHTML = item.displayName;

      if (!found && currentQuickSearch && item?.displayName?.toLowerCase()?.includes(currentQuickSearch.toLowerCase())) {
        found = true;
      }

      AccountWrapper.append(valueElement);
      element.append(AccountWrapper);
    }

    if (params.eGridCell) {
      params.eGridCell.classList.remove('highlighted-cell');
      if (currentQuickSearch && found) {
        params.eGridCell.classList.add('highlighted-cell');
      }
    }
  }

  if (params.eGridCell && params.colDef && params.colDef.editable) {
    const arrowIcon = document.createElement('span');
    arrowIcon.setAttribute('class', 'dropdown-button');
    const icon = typeIcon['dropDown'].cloneNode(true);
    icon.style = `
    height: 14px;
    color: #37474f;
    display: block;
  `;
    arrowIcon.append(icon);
    element.append(arrowIcon);

    const editingOverlay = document.createElement('span');
    editingOverlay.setAttribute('class', 'singlechoice-to-cell-editor');
    element.append(editingOverlay);
  }

  return {
    element,
  };
}

AccountRenderer.prototype.init = function(params) {
  const { valueElement, element } = createMultiChoiceElement(params);
  if (valueElement) this.valueElement = valueElement;
  this.element = element;
  this.eGridCell = params.eGridCell;

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

AccountRenderer.prototype.getGui = function() {
  return this.element;
};

AccountRenderer.prototype.refresh = function(params) {
  return false;
};

AccountRenderer.prototype.destroy = function() {
  return true;
};

export default AccountRenderer;

export function createIconWrapper(backgroundColor) {
  const IconWrapper = document.createElement('div');
  IconWrapper.style = `
        background-color: ${backgroundColor || 'lightGrey'};
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      `;
  IconWrapper.className = 'account-chip-icon_wrapper';
  IconWrapper.append(typeIcon['userIcon'].cloneNode(true));
  return IconWrapper;
}
