import { getLocation, push } from 'connected-react-router';
import { mapViewResponse } from 'data/collections/collections.mapper';
import { ViewConfig } from 'data/collections/collections.reducer';
import { collectionFields, schemaSelector } from 'data/collections/collections.selectors';
import * as orderedCollectionViews from 'data/collections/orderedCollectionViews';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import {
  convertApiViewConfigToUIViewConfig,
  getViewIdFromSearch,
  prepareView,
  replaceViewId,
} from 'data/views/helpers';
import { CreateKanbanViewState } from 'data/views/types';
import {
  changeViewsOrder,
  createView,
  deleteView, patchCustomOrderAction, patchView,
  selectView,
  setViewOrder,
  updateView,
  setDefaultView,
} from 'data/views/views.actions';
import { selectedView, viewById, currentDefaultView } from 'data/views/views.selectors';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { CollectionViewTypes } from 'types/schema';
import { ActionType, isActionOf } from 'typesafe-actions';
import { createSheetsUrl, createViewsUrl, isDocumentsUrl } from 'utilities/createUrl';
import { deleteRequest, patchRequest, postRequest } from 'utilities/httpRequests';
import { handleError } from 'utilities/saga';

import { State } from '../../reducers';
import { ViewNode } from '../../types/response/viewNode';
import {
  categoryFieldOrderSelector,
  columnsStateSelector, customRowOrder,
  groupByFieldIDsSelector,
  rowHeightSelector,
} from '../collections/view-config/viewConfig.selectors';


function* createViewSaga() {
  yield takeEvery(isActionOf(createView.request), function* (action: ActionType<typeof createView.request>) {
    const state: State = yield select();
    const location = state.router.location;

    const defaultSheetId = state.sheets.currentId || '';
    const url = createViewsUrl(location, defaultSheetId);
    const viewType = action.payload.type;
    try {
      const viewParams = prepareView({
        columns: columnsStateSelector(state),
        gridOptions: state.gridOptions,
        groupByFieldIDs: groupByFieldIDsSelector(state, viewType),
        height: rowHeightSelector(state),
        viewType,
      });

      const inheritedParams: { titleFieldID?; categoryFieldID?} = {};
      if (action.payload.type === CollectionViewTypes.kanban) {
        const view = selectedView(state);
        const { titleFieldID, categoryFieldID } = action.payload as CreateKanbanViewState;

        inheritedParams.titleFieldID = titleFieldID || view.titleFieldID || 'title';
        inheritedParams.categoryFieldID = categoryFieldID || view.categoryFieldID;
      }

      const data = {
        ...action.payload,
        ...viewParams,
        ...inheritedParams,
      };

      const result = yield call(postRequest, { url, data }, state);
      let permissions = [];
      if (state.collections.views.nodes) {
        const nodes = state.collections.views.nodes as any;
        permissions = (nodes.views && nodes.views[0] && nodes.views[0].permissions) || [];
      }

      const resultNode = mapViewResponse(result, url, {});

      const node: ViewNode = {
        ...resultNode,
        permissions,
      };

      yield put(createView.success(node));
      if (node.isDefault) {
        yield put(setDefaultView(node.id));
      }
      yield put(selectView(node.id));
    } catch (error) {
      yield call(handleError, error);
      yield put(createView.failure(error));
    }
  });
}

function* updateViewSaga() {
  yield takeEvery(isActionOf(updateView.request), function* (action: ActionType<typeof updateView.request>) {
    const state: State = yield select();
    const viewId = action.payload.id;

    const defaultSheetId = state.sheets.currentId || '';
    const currentView = state.collections.views.nodes['views'].filter(view => view.id === viewId)[0];
    const url = `${createViewsUrl(state.router.location, defaultSheetId, viewId)}?api_version=2`;
    const viewType = action.payload.type || CollectionViewTypes.grid;

    const viewParams = prepareView({
      columns: columnsStateSelector(state),
      gridOptions: state.gridOptions,
      groupByFieldIDs: groupByFieldIDsSelector(state, viewType),
      height: rowHeightSelector(state),
      categoryFieldOrder: categoryFieldOrderSelector(state),
      customRowOrder: customRowOrder(state),
      viewType,
    });

    const data = {
      ...action.payload,
      ...viewParams,
      version: currentView.version || 1,
    };

    if (isDocumentsUrl(window.location.pathname)) {
      if (data.query && data.query.fieldIDs) {
        data.query.fieldIDs.push('folderEntriesCount');
      }
    }

    try {
      const result = yield call(patchRequest, { url, data }, state);
      const oldDefaultView = currentDefaultView(state);
      yield put(updateView.success(mapViewResponse(result, url, currentView)));
      if (result.body.isDefault && !(oldDefaultView?.id === result.body._id.toString())) {
        yield put(setDefaultView(result.body._id.toString()));
      }
    } catch (error) {
      yield call(handleError, error);
      yield put(updateView.failure(error));
    }
  });
}

function* patchViewSaga() {
  yield takeEvery(isActionOf(patchView.request), function* (action: ActionType<typeof patchView.request>) {
    const state: State = yield select();
    const viewId = action.payload.id;

    const defaultSheetId = state.sheets.currentId || '';
    const currentView = state.collections.views.nodes['views'].filter(view => view.id === viewId)[0];
    const url = `${createViewsUrl(state.router.location, defaultSheetId, viewId)}?api_version=2`;

    const data = {
      ...action.payload,
      version: currentView.version || 1,
    };

    try {
      const result = yield call(patchRequest, { url, data }, state);
      yield put(updateView.success(mapViewResponse(result, url, currentView)));
    } catch (error) {
      yield call(handleError, error);
      yield put(updateView.failure(error));
    }
  });
}

function* patchCustomOrder() {
  yield takeEvery(isActionOf(patchCustomOrderAction), function* (_action: ActionType<typeof patchCustomOrderAction>) {
    const state: State = yield select();
    const currentView = selectedView(state);
    const viewParams = {
      customRowOrder: customRowOrder(state),
    };

    const data = {
      ...viewParams,
      version: currentView.version || 1,
    };

    try {
      const defaultSheetId = state.sheets.currentId || '';
      const viewPatchUrl = `${createViewsUrl(state.router.location, defaultSheetId, currentView.id)}?api_version=2`;
      const result = yield call(patchRequest, { url: viewPatchUrl, data }, state);
      yield put(updateView.success(mapViewResponse(result, viewPatchUrl, currentView)));
    } catch (error) {
      yield call(handleError, error);
      yield put(updateView.failure(error));
    }
  });
}

function* deleteViewSaga() {
  yield takeEvery(isActionOf(deleteView.request), function* (action: ActionType<typeof deleteView.request>) {
    const state: State = yield select();

    const { id } = action.payload;
    const { location } = state.router;
    const defaultSheetId = state.sheets.currentId || '';
    const url = createViewsUrl(location, defaultSheetId, id);

    try {
      const result = yield call(deleteRequest, url, state);

      if (result.body) yield put(deleteView.success({ id }));

      const updatedState: State = yield select();

      if ('views' in updatedState.collections.views.nodes) {
        const defaultView = updatedState.collections.views.nodes.views.filter(view => view.isDefault)[0];
        if (defaultView && defaultView.id) yield put(selectView(defaultView.id));
      }
    } catch (error) {
      yield call(handleError, error);
      yield put(deleteView.failure(error));
    }
  });
}

function* changeViewsOrderSaga() {
  yield takeEvery(isActionOf(changeViewsOrder.request), function* (action: ActionType<typeof changeViewsOrder.request>) {
    const state: State = yield select();
    const location = getLocation(state);
    const viewNodesState = state.collections.views.nodes;
    const sheet = state.sheets.byId[state.sheets.currentId || ''];
    const previousOrder =
      viewNodesState.type === orderedCollectionViews.ONLY_ORDER
        || viewNodesState.type === orderedCollectionViews.ORDERED_VIEWS
        ? viewNodesState.viewIDOrder
        : null;

    yield put(setViewOrder(action.payload));

    const sheetURL = createSheetsUrl(location, sheet.id);
    const url = sheetURL + '?api_version=2';
    const data = {
      viewIDOrder: action.payload,
      version: sheet.version,
    };

    try {
      const result = yield call(patchRequest, { url, data }, state);
      yield put(changeViewsOrder.success(result.body));
    } catch (error) {
      yield call(handleError, error);
      yield put(changeViewsOrder.failure(error));
      yield put(setViewOrder(previousOrder));
    }
  });
}

function* selectViewSaga() {
  yield takeLatest(isActionOf(selectView), function* (action: ActionType<typeof selectView>) {
    const state: State = yield select();
    const requestedView = viewById(state, action.payload);
    const { pathname, search } = state.router.location;

    // Change viewId in URL if necessary
    const urlViewId = getViewIdFromSearch(search);
    const targetViewId = requestedView ? action.payload : undefined;
    if (urlViewId !== targetViewId) {
      const newLocation = `${pathname}${replaceViewId(search, targetViewId)}`;
      yield put(push(newLocation));
    }

    // Apply view configuration
    const payload: ViewConfig = requestedView
      ? convertApiViewConfigToUIViewConfig(requestedView, schemaSelector(state), collectionFields(state).permissions)
      : { columns: [], customRowOrder: {} };

    payload.columns = payload.columns.map(column => {
      const leftPinned = requestedView?.leftPinnedFieldIDs?.find(colId => colId === column.colId);
      const rightPinned = requestedView?.rightPinnedFieldIDs?.find(colId => colId === column.colId);
      const pinned = leftPinned ? 'left' : rightPinned ? 'right' : column.pinned;
      return { ...column, pinned };
    });

    yield put(viewConfigActions.applyViewConfig(payload));
  });
}

export default function* viewsSagas() {
  yield all([
    fork(createViewSaga),
    fork(updateViewSaga),
    fork(deleteViewSaga),
    fork(changeViewsOrderSaga),
    fork(selectViewSaga),
    fork(patchViewSaga),
    fork(patchCustomOrder),
  ]);
}
