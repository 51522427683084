import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';

import ChecklistRenderer from './ChecklistRenderer';
import { ChecklistParams } from './types';
import EditorPopup from '../components/EditorPopup';
import FielEditorWrapper from '../components/FieldEditorWrapper';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import Checkbox from '../../Checkbox';

type Props = ChecklistParams & FieldEditorProps;

class ChecklistEditor extends React.Component<Props> {
  public render = (): JSX.Element => {
    return (
      <FielEditorWrapper>
        <List>
          {this.props.checklistOrder.map(this.renderChecklist)}
        </List>
      </FielEditorWrapper>
    );
  };

  private renderChecklist = (id: string): JSX.Element => (
    <Checklist
      checklistNames={this.props.checklistNames}
      id={id}
      value={this.props.value}
      onChange={this.props.onChange}
    />
  );
}

export const ChecklistFormEditor = (props: Props) => {
  return (
    <EditorPopup
      editor={ChecklistEditor}
      {...props}
    >
      {({ open, close }) => (
        <ChecklistRenderer.OriginalComponent {...props} startEditing={open}/>
      )}
    </EditorPopup>
  );
};

export default wrapCellEditor({
  isPopup: true,
})(ChecklistEditor);

type Value = Record<string, boolean>;

interface ChecklistProps {
  checklistNames: Record<string, string>;
  id: string;
  value?: Value;
  onChange(value: Value): void;
}

function Checklist(props: ChecklistProps): JSX.Element {
  const { checklistNames, id, value = {} } = props;

  function onClick() {
    props.onChange({ ...value, [id]: !value[id] });
  }

  return (
    <ListItem key={id} dense>
      <ListItemText primary={checklistNames[id]} />
      <ListItemSecondaryAction>
        <Checkbox
          selected={value[id]}
          onClick={onClick}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
