import { actions as gridEditorActions } from 'data/grid-editors';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import { CollectionTypes } from 'types/schema';

class UserEntity {
  USER_DISCONNECTED(data) {
    const store = window.appStore;
    const state = store.getState();
    const editorPosition = state.gridEditors?.editorsPositions?.[data.userId]?.position;
    if (editorPosition) {
      const [itemId, columnKey] = editorPosition.split('-');
      store.dispatch(gridEditorActions.removeCellUserEditor({
        itemId,
        columnKey,
        userId: data.userId,
      }));
    }
  }

  USER_INVITED(data) {
    this.handleRoleUpdate(data);
  }

  USER_ROLE_UPDATED(data) {
    this.handleRoleUpdate(data);
  }

  USER_REMOVED(data) {
    this.handleRoleUpdate(data);
  }

  LOGOUT_USER(data) {
    const store = window.appStore;
    const state = store.getState();
    const userId = data?.item?.id;
    if (state.users.user?.id === userId) {
      window.location.href = '/logout?reason=forceLogout';
    }
  }

  handleRoleUpdate(data) {
    const store = window.appStore;
    const state = store.getState();
    if (state.users.user?.id === data?.item?.id) {
      store.dispatch(fetchCollectionRequest(CollectionTypes.workspaces));
    }
  }
}

export default new UserEntity();
