export interface Filter {
  children?: Filter[]; // Child filters.
  childValues?: FilterValue[]; // Child values.
  disposition?: string; // Disposition ("filter", "must", "mustnot", "should").
  fieldID?: string; // ID of the field to filter.
  operation: ServerOperator; // Operation (see ServerOperator map).
  type?: string; // Node or edge.
  value?: FilterValue; // Value to filter with.
}

export interface Query {
  count?: number; // Number of items to return.
  fieldIDs?: string[]; // Field IDs to display.
  filters?: Filter[]; // Filtered values, mapped from field IDs to values.
  filtersInBar?: Filter[]; // Filtered values, mapped from field IDs to values.
  groupFieldIDs?: string[]; // Group field IDs.
  pivotFieldIDs?: string[]; // Pivot field IDs.
  sorts?: Sort[]; // Sort rules.
  start?: number; // Item index to start with.
}

export interface Sort {
  descending?: boolean; // Whether the field is sorted in descending order.
  fieldID: string; // ID of field.
}

export type FilterValue = string | number | boolean;


export enum ServerOperator {
  eq = 'eq',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  inrange = 'inrange',
  lt = 'lt',
  lte = 'lte',
  ne = 'ne',
  nin = 'nin',
  contains = 'contains',
  empty = 'empty',
  endswith = 'endswith',
  notcontains = 'notcontains',
  notempty = 'notempty',
  startswith = 'startswith',
  all = 'all',
  and = 'and',
  or = 'or',
  nor = 'nor',
  not = 'not',
}

export type LogicalServerOperator = ServerOperator.and | ServerOperator.or | ServerOperator.nor;
