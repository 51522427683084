import * as React from 'react';
import {
  PreviewPage,
  PreviewPageImgWrapper,
  PreviewPageWrapper,
} from 'components/DocumentPreview/DocumentPreview.style';

interface Props {
  zoom: number;
  src: string;
  width: number;
  height: number;
  style: any;
  measure: () => void;
}

export default class Page extends React.Component<Props> {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const { style, src, width, height, zoom } = this.props;
    return (
      <PreviewPage style={{ ...style }}>
        <PreviewPageWrapper style={{ width: `${zoom}%` }}>
          <PreviewPageImgWrapper style={{ paddingBottom: `${100 * height / width}%` }}>
            <img src={src} alt="" />
          </PreviewPageImgWrapper>
        </PreviewPageWrapper>
      </PreviewPage>
    );
  }
}
