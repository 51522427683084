import { getType } from 'typesafe-actions';
import { Actions, actions } from 'data/users/users.actions';
import { UserState } from 'data/users/users.types';
import produce from 'immer';
import _ from 'lodash';

export const initialState: UserState = {
  isLoading: false,
  user: { id: '', firstName: '', lastName: '', displayName: '', email: '', permissions: [], roles: [] },
  error: null,
};

const reducer = (state: UserState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(actions.fetchUser.request): {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getType(actions.fetchUser.success): {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    }
    case getType(actions.fetchUser.failure): {
      return {
        ...state,
        isLoading: false,
      };
    }
    case getType(actions.updateUser.request): {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getType(actions.updateUser.success): {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case getType(actions.updateUser.failure): {
      return {
        ...state,
      };
    }
    case getType(actions.changeUserSendMessagePreference.request): {
      return produce(state, draft => {
        const currentSendOnEnter = state.user?.settings?.sendOnEnter || false;
        _.set(draft, 'user.settings.sendOnEnter', !currentSendOnEnter);
      });
    }
    case getType(actions.changeUserSendMessagePreference.failure): {
      return produce(state, draft => {
        const currentSendOnEnter = state.user?.settings?.sendOnEnter || false;
        _.set(draft, 'user.settings.sendOnEnter', !currentSendOnEnter);
      });
    }
    case getType(actions.changeUserModifyFieldModalPreference.success): {
      return produce(state, draft => {
        const hideModifyFieldConfirmModal = state.user?.settings?.hideModifyFieldConfirmModal || false;
        _.set(draft, 'user.settings.hideModifyFieldConfirmModal', !hideModifyFieldConfirmModal);
      });
    }
    case getType(actions.updateUserTermsAndCondition.request): {
      return {
        ...state,
        user: {
          ...state.user,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
