import styled from 'styled-components';

import * as Colors from 'styles/colors';

const Wrapper = styled.div`
  cursor: pointer;
  color: ${Colors.blueyGrey};
  display: flex;
  align-items: center;
  height: 100%;
`;

export {
  Wrapper,
};
