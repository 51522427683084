import React, { useEffect, useState } from 'react';

import { From } from 'components/DataGrid/items/BulkUpdate';
import ModalTextField from 'components/Modals/ModalTextField';


// import { Notification as DatePickerNotifyI } from 'components/ui/DatePicker/DatePickerNotify/DatePickerNotify';
import {
  // actions as remindersActions,
  State as RemindersState,
  buildKey as buildReminderKey,
  Reminder as ReminderType,
} from 'data/reminders/reminders';
import * as keys from 'keycode-js';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { State } from 'reducers';
import { parseURI } from 'utilities/parseURI';

import { getWorkspacePermissions } from '../../../data/workspaces/workspaces.selectors';
import { DateFormat, TimeFormat } from '../../Fields/data';
import { DatePicker } from '../../ui/DatePicker';
import FieldEditorWrapper from '../components/FieldEditorWrapper';
import GridTextEditor from '../components/GridTextEditor';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import { getFormat } from './DateTime.utils';
import { removeHTMLTag } from 'utilities/format';
// import database from "data/ws/entities/database";
// import {Fields} from "../../../types/response";

interface DateTimeEditorProps extends FieldEditorProps {
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
  includeTime?: boolean;
  from?: From;
  name?: string;
  bulkReminders?: Array<ReminderType>;
}

const DateTimeEditor = React.forwardRef(
  (props: DateTimeEditorProps, ref: React.RefObject<HTMLInputElement>): JSX.Element => {
    /**
     * HOOKS
     */
    // const dispatch = useDispatch();
    const permissions: string[] = useSelector((state: State) => getWorkspacePermissions(state));
    const sheetID = useSelector<State, string | null>(state => state.sheets.currentId) || '';
    const reminders = useSelector<State, RemindersState>(state => state.reminders);
    const primaryFieldId = useSelector((state: State) => {
      const fields = state.collections.collections.schema.properties.fields.properties as any;
      let primaryFieldId = '';
      Object.keys(fields).forEach((field: string) => {
        if (fields[field]?.isPrimary) {
          primaryFieldId = fields[field]._id;
        }
      });
      return primaryFieldId;
    });
    const primaryFieldName = removeHTMLTag(props.node?.data?.fields?.[primaryFieldId] || props.data?.fields?.[primaryFieldId] || '');

    const { id, value, onChange, eGridCell, lock } = props;
    const format = getFormat(props);
    const [initialValueChanged, setInitialValueChanged] = useState(false);
    const [clearChanged, setClearChanged] = useState(false);

    const InputComponent = eGridCell ? GridTextEditor : ModalTextField;

    let dateValue: number | string | undefined;
    if (!initialValueChanged && eGridCell && !value && props['keyPress'] && (props['keyPress'] === keys.KEY_RETURN || props['keyPress'] === keys.KEY_ENTER)) {
      dateValue = new Date().getTime();
    } else {
      dateValue = value && value.timestamp ? new Date(value.timestamp).getTime() : value? new Date(value).getTime() : '';
    }

    useEffect(() => {
      const { keyPress=0 } = props;

      if (!value && dateValue) {
        onChange(dateValue, true);
      }

      if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
        setClearChanged(!clearChanged);
      }
      setInitialValueChanged(true);
    }, []);

    const { workspaceId: workspaceID, databaseId: databaseID } = parseURI(window.location.pathname);

    const recordID = _.get(props, 'node.id') || _.get(props, 'data.id');
    const fieldID = _.get(props, 'column.colId') || _.get(props, 'fieldId');
    const fieldIDFormatted = fieldID && fieldID.replace('fields.', '');
    const reminderKey = recordID && fieldID && buildReminderKey({ recordID, references: { fieldID: fieldIDFormatted } });
    const fieldReminders = (reminderKey && reminders.reminders[reminderKey]) || [];
    let isReadOnly;
    if (permissions?.includes('items:lock')) isReadOnly = false;
    else if (!permissions?.includes('items:update')) isReadOnly = true;
    else isReadOnly = lock;


    return (
      <FieldEditorWrapper>
        <DatePicker
          readOnly={isReadOnly}
          placeholder={''}
          format={format}
          bulkReminders={props.bulkReminders}
          value={dateValue ? new Date(dateValue) : ''}
          reminderKey={reminderKey}
          clearChanged={clearChanged}
          onDayPickerShow={() => {
            if (ref?.current) {
              ref.current.selectionStart = ref.current.selectionEnd = ref.current.value.length;
            }
          }}
          onDateChange={(value: string | number) => {
            eGridCell?.focus();
            if (props.node) {
              props.node.setDataValue(props.colDef.colId, value);
              props.stopEditing(true);
            } else {
              setInitialValueChanged(true);
              onChange(value, true);
            }
          }}
          onInputChange={(value: number): void => onChange(value)}
          component={InputComponent}
          inputProps={{ id: `dateTimePicker${id}`, inputRef: ref, disabled: !permissions?.includes('items:update') }}
          hasNotify={true}
          reminders={fieldReminders}
          primaryFieldName={primaryFieldName}
          permissions={permissions}
          isGrid={!!eGridCell}
          references={{
            workspaceID,
            databaseID,
            sheetID,
            rowID: recordID,
            fieldID: fieldIDFormatted,
            fieldName: props.colDef?.headerName || props.name,
          }}
          onStopEditing={props.onStopEditing}
        />
      </FieldEditorWrapper>


    );
  },
);

export default wrapCellEditor()(DateTimeEditor);
