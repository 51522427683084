import * as React from 'react';

export interface WithValidationProps {
  eGridCell: {
    title: string;
    classList: {
      add: (string) => void;
      remove: (string) => void;
    };
  };
  node: {
    data: {
      isValid: boolean;
      validationMessages: {
        [key: string]: string[];
      } | null;
    };
  };
  column: {
    colDef: {
      field: string;
    };
  };
}

export const withValidation = (WrappedComponent) => (props: WithValidationProps) => {
  const {
    eGridCell,
    node,
    column,
  } = props;
  if (eGridCell && node && node.data) {
    const { isValid, validationMessages } = node.data;
    if (isValid === false) {
      const fieldName = column.colDef.field;
      if (validationMessages && validationMessages[fieldName] && validationMessages[fieldName].length > 0) {
        eGridCell.title = validationMessages[fieldName].join('\r\n');
        eGridCell.classList.add('invalid');
      } else {
        eGridCell.classList.remove('invalid');
      }
    } else {
      eGridCell.classList.remove('invalid');
    }
  }
  return <WrappedComponent {...props} />;
};
