import * as React from 'react';

import LoadingState from 'data/LoadingState';

import Switch from '../../Switch';
import ModalActions from '../Modal/Actions';
import ModalContent from '../Modal/Content';
import ModalHeader from '../Modal/Header';
import ModalTextField from '../ModalTextField';

interface Props {
  viewLoadingState: LoadingState;
  viewName: string;
  errorMessage?: string;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  permissions?: string[];
  onViewNameChange(viewName: string): void;
  onPrivateStateChange(isPrivate: boolean): void;
  onLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
  onClose(): void;
  onCreateNewGridView(): void;
}

export default function NewGridView(props: Props): JSX.Element {
  const canLockView = props.permissions?.includes('views:lock');
  const canSetDefaultView = props.permissions?.includes('views:lock');

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onViewNameChange(event.currentTarget.value);
  }

  function onPrivateStateChange(): void {
    props.onPrivateStateChange(!props.isPrivate);
  }

  function onLockStateChange(): void {
    props.onLockStateChange(!props.isLocked);
  }

  function onDefaultStateChange(): void {
    props.onDefaultStateChange(!props.isDefault);
  }

  return (
    <React.Fragment>
      <ModalHeader onClose={props.onClose}>New Grid View</ModalHeader>
      <ModalContent>
        <ModalTextField
          value={props.viewName}
          errorMessage={props.errorMessage}
          onChange={onChange}
          autoSelect
          autoFocus
        />
        <Switch
          label="Personal view"
          selected={props.isPrivate}
          onClick={onPrivateStateChange}
          className={'modal-view-switch'}
          labelRight
          disabled={props.isLocked || props.isDefault}
        />
        {canLockView && (
          <Switch
            label="Lock view"
            selected={props.isLocked}
            onClick={onLockStateChange}
            className={'modal-view-switch'}
            labelRight
            disabled={props.isPrivate}
          />
        )}
        {canSetDefaultView && (
          <Switch
            label="Default view"
            selected={props.isDefault}
            onClick={onDefaultStateChange}
            className={'modal-view-switch'}
            labelRight
            disabled={props.isPrivate}
          />
        )}
      </ModalContent>
      <ModalActions
        submitLabel="Save"
        submitDisabled={!!props.errorMessage}
        onCancel={props.onClose}
        onSubmit={props.onCreateNewGridView}
      />
    </React.Fragment>
  );
}
