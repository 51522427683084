import { CommonNode } from 'types/response';
import { CollectionTypes } from 'types/schema';
import LoadingState from 'data/LoadingState';

interface SimpleState {
  readonly loadingState: LoadingState.Unloaded | LoadingState.Loading;
}

interface ErrorState {
  readonly loadingState: LoadingState.Error;
  readonly error: Error;
}

interface NodeState {
  readonly loadingState: LoadingState.Loaded;
  readonly collectionType: CollectionTypes;
  readonly node: CommonNode;
}

export type State = SimpleState | ErrorState | NodeState;

export const initialState: State = {
  loadingState: LoadingState.Unloaded,
};
