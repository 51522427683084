import * as React from 'react';
import cn from 'classnames';

import svgIcons from '../../../styles/svgIcons';

import Typography, { Variant } from '../../Typography';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { withStyles } from '@material-ui/core';
import IconButton, { Color, Size } from '../../IconButton';
import { styles } from 'components/Modals/DialogTitle/DialogTitle.style';

interface ChildrenProps {
  className: string;
}

interface OwnProps {
  children: string | React.ReactElement<ChildrenProps>;
  titleClassName?: string;
  onClose?: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class DialogTitle extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { classes, children, onClose, titleClassName } = this.props;
    const className = cn(classes.children, titleClassName);

    return (
      <div className={classes.root}>
        <Typography variant={Variant.SubHeading} className={className}>
          {children}
        </Typography>
        <IconButton icon={svgIcons.Close} color={Color.DarkGray} size={Size.Small} onClick={onClose} />
      </div>
    );
  };
}

export default withStyles(styles)(DialogTitle);
