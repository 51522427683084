import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  blueyGrey,
  greyTransparent,
  iceBlue,
  lightBlueGrey,
  lightRed,
  slateTwo,
  vividAzure,
  white,
} from 'styles/colors';

type ClassKey =
  | 'field'
  | 'fieldLabel'
  | 'fieldIcon'
  | 'infoIcon'
  | 'textarea'
  | 'errorMessage'
  | 'copyLink'
  | 'accessLevelSelect'
  | 'radioButtonWrapper'
  | 'domainInputWrapper'
  | 'label'
  | 'infoText'
  | 'infoBoxWrapper'
  | 'infoBoxWrapperTop'
  | 'divider'
  | 'dividerText'
  | 'row'
  | 'relative'
  | 'rowWithSpaceInBetween'
  | 'hidden'
  | 'inviteInfo';

export const styles: Record<ClassKey, CSSProperties> = {
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  fieldLabel: {
    'display': 'flex',
    'alignItems': 'flex-end',
    'marginBottom': '10px',
    '& > span': {
      textTransform: 'initial',
      fontSize: '12px',
    },
  },
  fieldIcon: {
    width: '15px',
    marginRight: '10px',
    color: slateTwo,
  },
  infoIcon: {
    width: '15px',
    color: slateTwo,
    marginLeft: '10px',
    marginTop: '7px',
    alignSelf: 'end',
  },
  textarea: {
    'width': '100%',
    'height': '60px',
    'borderRadius': '2px',
    'backgroundColor': greyTransparent,
    'padding': '10px 12px',
    'fontSize': '14px',
    'color': slateTwo,
    'resize': 'none',
    'border': `1px solid ${lightBlueGrey}`,
    '&:focus': {
      border: `1px solid ${vividAzure}`,
    },
  },
  errorMessage: {
    color: lightRed,
    fontSize: '12px',
  },
  copyLink: {
    height: '30px',
    marginTop: '5px',
    marginLeft: '20px',
  },
  accessLevelSelect: {
    marginLeft: '10px',
  },
  radioButtonWrapper: {
    padding: 0,
    fontSize: '14px',
    alignSelf: 'normal',
    marginRight: '10px',
  },
  domainInputWrapper: {
    'marginLeft': '10px',
    'marginRight': '10px',
    'minWidth': '50%',
    'position': 'relative',
    '& > span': {
      position: 'absolute',
      bottom: '-20px',
      left: 0,
    },
  },
  label: {
    alignSelf: 'center',
  },
  infoText: {
    whiteSpace: 'nowrap',
    color: blueyGrey,
    fontSize: '14px',
    marginTop: '5px',
    display: 'inline-block',
  },
  infoBoxWrapper: {
    position: 'absolute',
    top: '27px',
    left: '160px',
    right: 0,
    zIndex: 100,
  },
  infoBoxWrapperTop: {
    top: 'auto',
    right: '155px',
    bottom: '27px',
    left: 0,
  },
  divider: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'position': 'relative',
    'marginBottom': '25px',
    '&:after': {
      top: 0,
      right: '-30px',
      bottom: 0,
      left: '-30px',
      content: '""',
      height: '1px',
      margin: 'auto',
      position: 'absolute',
      backgroundColor: iceBlue,
    },
  },
  dividerText: {
    zIndex: 1,
    position: 'relative',
    backgroundColor: white,
    padding: '0 10px',
    fontSize: '11px',
    color: blueyGrey,
  },
  row: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'maxWidth': 480,
    '& > div:first-child': {
      'width': '63%',
      '& textarea': {
        height: '40px',
      },
    },
  },
  rowWithSpaceInBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  relative: {
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  inviteInfo: {
    marginTop: 10,
    marginBottom: 22,
  },
};
