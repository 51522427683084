import moment from 'moment';

// Return the current date, for Date headers.
export const dateHeader = () => (new Date()).toUTCString();

export const formatDate = (date: number | Date | null, format: string): string => {
  if (date == null) {
    return moment().format(format);
  }

  return moment(date).format(format);
};
