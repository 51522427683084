import { createAsyncThunk } from '@reduxjs/toolkit';
import { REFRESH_INTERVAL } from './constants';
import * as services from './services';

type Timer = ReturnType<typeof setInterval>;

export const refreshToken = createAsyncThunk(
  'AUTH/REFRESH_TOKEN',
  async () => {
    try {
      await services.refreshSession();
    } catch (error) {
      console.error('Unable to refresh token:', error);
      throw error;
    }
  },
);

export const initRefreshTokenPoll = createAsyncThunk<Timer>(
  'AUTH/INIT_REFRESH_TOKEN_POLL',
  async (_, { dispatch }) => {
    const timer = setInterval(async () => {
      dispatch(refreshToken());
    }, REFRESH_INTERVAL);

    return timer;
  },
);
