import { updateWorkspaceRoles } from 'data/workspaces/workspaces.actions';

class RoleEntity {
  ROLES_CREATED(data) {
    const store = window.appStore;
    store.dispatch(updateWorkspaceRoles(data.item.workspaceId, data.item.roles));
  }
}

export default new RoleEntity();
