import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import { FieldMetadata } from 'components/NodeFieldData/getFieldMetadata';
import svgIcons from 'styles/svgIcons';
import { CommonNode, FieldValue } from 'types/response';
import { FieldType } from 'types/response/fieldNode';

import ContextMenu, { MenuItem } from '../../../ContextMenu/ContextMenu';
import { rendererFactory } from '../../../Modals/NodeView/componentsFactory';
import Typography, { Variant } from '../../../Typography';
import { styles } from './CardBody.style';

export interface Field {
  fieldType: FieldType;
  value: FieldValue;
  name: string;
  id: string;
  meta?: FieldMetadata;
}

interface Props extends WithStyles<typeof styles> {
  title: string;
  task: CommonNode;
  fields: Field[];
  permissions: string[];
  onDuplicateRecord?: () => void;
  onExpandRecord?: () => void;
  onDeleteRecord?: () => void;
}

export class CardBody extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { classes, title, fields } = this.props;

    return (
      <React.Fragment>
        <div className={classes.header}>
          <Typography variant={Variant.SubHeading} className={classes.title} onClick={this.props.onExpandRecord}>
            {`${title}`}
          </Typography>
          <ContextMenu id="cardBody" className={classes['kanban-card-context-menu']} menuItems={this.getMenuItems()} />
        </div>
        {this.renderAuto(fields.filter(field => field && field.value))}
      </React.Fragment>
    );
  }

  private getMenuItems = (): MenuItem[] => {
    const { onDuplicateRecord, onExpandRecord, onDeleteRecord, permissions } = this.props;
    const result: MenuItem[] = [];

    if (onDuplicateRecord && permissions.includes('items:create')) {
      result.push({
        id: 'duplicate',
        title: 'Duplicate Record',
        action: onDuplicateRecord,
        Icon: svgIcons.Duplicate,
      });
    }

    if (onExpandRecord && permissions.includes('items:get')) {
      result.push({
        id: 'expand',
        title: 'Expand Record',
        action: onExpandRecord,
        Icon: svgIcons.ExpandRecord,
      });
    }

    if (onDeleteRecord && permissions.includes('items:delete')) {
      result.push({
        id: 'delete',
        title: 'Delete Record',
        action: onDeleteRecord,
        Icon: svgIcons.Delete,
      });
    }

    return result;
  };

  private renderAuto = (fields: Field[]): JSX.Element => (
    <div onClick={this.props.onExpandRecord}>
      {fields.map(this.renderField)}
    </div>
  );

  private renderField = ({ name, fieldType, value, meta, id }: Field): React.ReactNode => {
    const { classes, task } = this.props;

    return (
      <div key={id} className={classes.field}>
        <Typography variant={Variant.FieldLabel}>
          {name}
        </Typography>
        <div className={classes.fieldValue}>
          {rendererFactory({ value, name, formatter: fieldType, meta, data: task })}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(CardBody);
