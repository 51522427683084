import React from 'react';
import {
  createUltimatePagination,
  ITEM_TYPES,
} from 'react-ultimate-pagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';
import * as Colors from 'styles/colors';

const flatButtonStyle = {
  minWidth: '32px',
  width: '32px',
  height: '32px',
  borderRadius: '20px',
  color: '#212121',
};

const colors = {
  white: '#fff',
  black: '#000',
  blackText: '#212121',
  disabled: '#8F8F8F',
};

const Page = ({ value, isActive, onClick, isDisabled }) => {
  const style = {
    ...flatButtonStyle,
    color: isActive ? colors.white : colors.black,
    backgroundColor: isActive ? Colors.darkSlateBlue : 'initial',
  };
  return (
    <Tooltip title={`Page ${value.toString()}`}>
      <Button
        className={isActive ? 'app-btn-primary' : ''}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
      >
        {value.toString()}
      </Button>
    </Tooltip>
  );
};

const Ellipsis = ({ onClick, isDisabled }) => (
  <Tooltip title="Move Page Set">
    <Button style={flatButtonStyle} onClick={onClick} disabled={isDisabled}>
      ...
    </Button>
  </Tooltip>
);

const FirstPageLink = ({ isActive, onClick }) => {
  const style = {
    ...flatButtonStyle,
    color: isActive ? colors.disabled : colors.blackText,
  };
  return (
    <Tooltip title="Go to First Page">
      <IconButton style={style} onClick={onClick} disabled={isActive}>
        <FirstPage />
      </IconButton>
    </Tooltip>
  );
};

const PreviousPageLink = ({ isActive, onClick }) => {
  const style = {
    ...flatButtonStyle,
    color: isActive ? colors.disabled : colors.blackText,
  };
  return (
    <Tooltip title="Go to Previous Page">
      <IconButton style={style} onClick={onClick} disabled={isActive}>
        <ChevronLeft />
      </IconButton>
    </Tooltip>
  );
};

const NextPageLink = ({ isActive, onClick }) => {
  const style = {
    ...flatButtonStyle,
    color: isActive ? colors.disabled : colors.blackText,
  };
  return (
    <Tooltip title="Go to Next Page">
      <IconButton style={style} onClick={onClick} disabled={isActive}>
        <ChevronRight />
      </IconButton>
    </Tooltip>
  );
};

const LastPageLink = ({ isActive, onClick }) => {
  const style = {
    ...flatButtonStyle,
    color: isActive ? colors.disabled : colors.blackText,
  };
  return (
    <Tooltip title="Go to Last Page">
      <IconButton style={style} onClick={onClick} disabled={isActive}>
        <LastPage />
      </IconButton>
    </Tooltip>
  );
};

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};

const UltimatePaginationMaterialUi = createUltimatePagination({
  itemTypeToComponent,
});

export default UltimatePaginationMaterialUi;
