import getFieldMetadata from 'components/NodeFieldData/getFieldMetadata';
import { URLInjectedProps } from 'containers/withURLParams';
import {
  getAllDatabasesById,
  getCurrentDatabase,
  getCurrentDatabaseId,
  getLoading as getDatabasesLoading,
} from 'data/databases/databases.selectors';
import LoadingState from 'data/LoadingState';
import {
  getAllTaskDatabasesById,
  getCurrentTaskDatabase,
  getCurrentTaskDatabaseId,
  getLoading as getTaskDatabasesLoading,
} from 'data/taskdbs/taskdbs.selectors';
import {
  getAllWorkspacesById,
  getCurrentWorkspaceId,
  getLoading as getWorkspacesLoading,
  getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import * as _ from 'lodash/fp';
import { State } from 'reducers';
import { createSelector } from 'reselect';
import { ColumnsMapById } from 'types/gridOptions';
import { CommonNode } from 'types/response';
import { DatabaseNode } from 'types/response/databaseNode';
import { FieldType } from 'types/response/fieldNode';
import {
  Breadcrumb,
  CollectionTypes,
  Column,
  Document,
  getDefaultFieldTypeFromValueType,
  Properties,
  SchemaProperty,
  Schema,
} from 'types/schema';
import { createUserDefinedFieldKey } from 'utilities/collections';
import { getCollectionTypeFromUrl } from 'utilities/createUrl';
import { getUrlsLastItem } from 'utilities/queryParams';

import { CollectionFields, Collections, CollectionState, IdRendererParams, ViewConfig } from './collections.reducer';


function root(state: State): CollectionState {
  return state.collections;
}

export function collections(state: State): Collections {
  return root(state).collections;
}

export const collectionFields = (state: State): CollectionFields => root(state).fields;

export function nodes(state: State): CommonNode[] {
  const { viewport } = collections(state);

  return viewport.nodes || [];
}

export const schemaSelector = (state: State): Schema => state.collections.collections.schema;
export const fieldSchema = (state: State): { [key: string]: SchemaProperty } =>
  schemaSelector(state).properties.fields.properties || {};
export const breadcrumbs = (state): Breadcrumb[] => state.collections.collections.breadcrumbs || [];
export const permissions = (state): string[] => getWorkspacePermissions(state);
export const collectionType = (state) => state.collections.collections.collection && state.collections.collections.collection.type;
export const rowIndexMap = (state: State): Record<string, number> => state.collections.rowIndexMap;
export const nodesInCreation = (state: State): string[] => state.collections.nodesInCreation;
export const nodeLoadingState = (state: State): string => state.collections.nodeLoadingState;
export const getCurrentViewConfig = (state: State): ViewConfig => state.collections.currentViewConfig;
export const getIdRendererParams = (state: State): IdRendererParams | undefined | null => state.collections.idRendererParams;
export const nodesLengthSelector = (state: State): number => state.collections.collections.viewport.nodes.length;

export const getSchemaSystemColumns = (schema: Schema): ColumnsMapById => {
  const properties: Properties = _.pickBy(
    prop => prop.grid && !prop.grid.hide && prop.name !== 'Fields',
    schema.properties,
  );
  // @ts-ignore
  return Object
    .keys(properties)
    // @ts-ignore
    .reduce<ColumnsMapById>((columns: ColumnsMapById, key: string) => ({
      ...columns,
      [key]: {
        ...properties[key],
        id: key,
        fieldType: properties[key].fieldType || getDefaultFieldTypeFromValueType(properties[key].type),
      },
    }), {});
};

export const getSchemaUserDefinedColumns = (schema: Schema): ColumnsMapById => {
  const fields = schema.properties && schema.properties.fields;
  const properties = (fields && fields.properties) || {};
  // @ts-ignore
  return Object
    .keys(properties)
    .reduce((columns: ColumnsMapById, key: string) => {
      const userDefinedKey = createUserDefinedFieldKey(key);
      return {
        ...columns,
        [userDefinedKey]: {
          ...properties[key],
          id: userDefinedKey,
          fieldType: properties[key].fieldType || getDefaultFieldTypeFromValueType(properties[key].type),
        },
      };
    }, {});
};

export const getSchemaColumns = (schema: Schema): ColumnsMapById => ({
  ...getSchemaUserDefinedColumns(schema),
  ...getSchemaSystemColumns(schema),
});

export const getEditableSchemaColumns = (columns: ColumnsMapById): ColumnsMapById => _.pickBy(
  column => !column.hide,
  columns,
);

export const schemaColumnsMap = createSelector<State, Schema, ColumnsMapById>(
  schemaSelector,
  getSchemaColumns,
);

export const schemaColumnsArray = createSelector<State, ColumnsMapById, Column[]>(
  schemaColumnsMap,
  (columns: ColumnsMapById) => Object.keys(columns).map(key => columns[key]),
);

export const editableSchemaColumnsSelector = createSelector<State, ColumnsMapById, ColumnsMapById>(
  schemaColumnsMap,
  getEditableSchemaColumns,
);

export const allColumnsNamesSelector = createSelector(
  schemaColumnsMap,
  (columnsMap: ColumnsMapById) => {
    return (ignoreId): string[] => {
      const filteredColumnsMap: ColumnsMapById = _.omit([ignoreId], columnsMap);
      return Object.values(filteredColumnsMap)
        .map(column => column.name);
    };
  },
);

export interface Control {
  fieldType: FieldType;
  name: string;
  id: string;
  meta?: any;
  readOnly?: boolean;
  lock?: boolean;
}

export const composeControls = (
  columns: ColumnsMapById,
  keys: string[],
  permissions: string[],
): Control[] => keys.filter(key => columns[key]).map(key => {
  const column = columns[key];
  const shouldLock = column.lock && !permissions.includes('fields:lock');
  // @FIXME: This is repeated logic, it is the same in src/components/DataGrid/columns/index.ts:377
  const readOnly = !!column.readOnly || !permissions.includes('items:update') || shouldLock;

  return {
    fieldType: column && (column.fieldType || FieldType.Singlelineoftext),
    id: key,
    name: (column && column.name) || '',
    meta: column && getFieldMetadata(column),
    readOnly,
    lock: column?.lock ?? false,
  };
});

export const isCustomViewsSupported = (state): boolean =>
  state.collections.collections.collection.customViewsSupported;

export const folders = (state): Document[] => {
  const _nodes = nodes(state) as Document[];
  return _nodes.filter(_.prop('isFolder'));
};

export const getCollectionByIdForTileView = (state, props: URLInjectedProps) => {
  const mapCollectionNameToSelector = {
    [CollectionTypes.workspaces]: getAllWorkspacesById(state),
    [CollectionTypes.databases]: getAllDatabasesById(state),
    [CollectionTypes.taskdbs]: getAllTaskDatabasesById(state),
  };
  return mapCollectionNameToSelector[getUrlsLastItem(props.location.pathname)];
};

export function getLoadingForTileView(state: State, props: URLInjectedProps): LoadingState {
  const mapCollectionNameToSelector = {
    [CollectionTypes.workspaces]: getWorkspacesLoading(state),
    [CollectionTypes.databases]: getDatabasesLoading(state),
    [CollectionTypes.taskdbs]: getTaskDatabasesLoading(state),
  };
  const collectionType = getCollectionTypeFromUrl(props.location.pathname);

  return collectionType ? mapCollectionNameToSelector[collectionType] : LoadingState.Unloaded;
}

export const getCurrentNodeForTileView = (state: State, props: URLInjectedProps): string => {
  const mapCollectionTypeToSelector = {
    [CollectionTypes.workspaces]: getCurrentWorkspaceId(state),
    [CollectionTypes.databases]: getCurrentDatabaseId(state),
    [CollectionTypes.taskdbs]: getCurrentTaskDatabaseId(state),
  };

  return mapCollectionTypeToSelector[getUrlsLastItem(props.location.pathname)];
};

export function getHideColumnIconsPropForDatabase(state: State): boolean {
  const database: DatabaseNode | undefined = getCurrentDatabase(state) || getCurrentTaskDatabase(state);

  if (!database) {
    return false;
  }

  return !!database.hideColumnIcons;
}
