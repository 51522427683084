import { WithStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { connect } from 'react-redux';

import {
  DropdownWrapper,
  InputWrapper,
  OperatorDropdown,
} from 'components/TableHeaderTools/modules/FilteringOption/FilteringOption.style';
import {
  CloseButton,
  Dropdown,
  DropdownColumnWrapper,
  DropdownFooter,
  DropdownHeader,
  DropdownListItem,
  DropdownListItemLabel,
  DropdownListItemRemove,
} from 'components/TableHeaderTools/TableHeaderTools.style';
import { FilterOperators, FilterType, Operator, RegularFilter } from 'types/gridOptions';
import { Column, ColumnVM } from 'types/schema';
import { filtersDropdownStyles } from 'components/TableHeaderTools/modules/FilteringOption/FiltersDropdown.style';

import FilteringOptionInput from 'components/TableHeaderTools/modules/FilteringOption/FilteringOptionInput';
import { columnVMToFilterBySelector } from 'data/collections/view-config/viewConfig.selectors';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';
import { schemaColumnsMap } from 'data/collections/collections.selectors';
import * as fromActions from 'data/grid-options/filterModel.actions';
import { Actions as QuickFilterActions } from 'data/grid-options/quickFilter.actions';
import { State as ReduxState } from 'reducers';

import PopupMenuButton from '../../PopupMenuButton';
import {
  getDefaultOperatorForFilterType,
  getListOfOperatorsForFilterType,
  mapColumnEditorToFilterType,
  mapFilterViewOperatorToOperator,
} from '../../../NodeFieldData/filters/helpers';
import Switch from '../../../Switch';
import Typography, { Variant } from 'components/Typography';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';

interface StateProps {
  filters: RegularFilter[];
  schemaColumns: {
    [id: string]: Column;
  };
  columnsVM: ColumnVM[];
  quickFiltersBarEnabled: boolean;
}

interface DispatchProps {
  addFilter: (payload: fromActions.AddFilterPayload) => void;
  updateFilter: (payload: fromActions.UpdateFilterPayload) => void;
  changeQuickFiltersVisibility: () => void;
  deleteFilter: (payload: number) => void;
}

interface OwnProps {
  showFilterBarSwitchEnabled?: boolean;
  closePopover: () => void;
  onlyComponent?: boolean;
}

type Props = WithStyles<typeof filtersDropdownStyles> & StateProps & DispatchProps & OwnProps;

interface State {
  fieldSearchValue: string;
}

export class FiltersDropdown extends React.Component<Props, State> {
  state = {
    fieldSearchValue: '',
  };

  public render(): React.ReactNode {
    const {
      classes,
      filters,
      quickFiltersBarEnabled,
      columnsVM,
      closePopover,
      showFilterBarSwitchEnabled = false,
    } = this.props;

    return (
      <DropdownWrapper>
        <DropdownHeader>
          <Grid
            container
            direction="row"
            justify={showFilterBarSwitchEnabled ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {showFilterBarSwitchEnabled &&
              <Switch
                label="Show Filter Bar"
                selected={quickFiltersBarEnabled}
                onClick={this.handleShowHideQuickFilters}
              />
            }
            <CloseButton onClick={closePopover} />
          </Grid>
        </DropdownHeader>
        <Grid container className={classes.filtersContent}>
          {filters.length === 0
            ? <Typography variant={Variant.Label}>No filters applied to this view</Typography>
            : (
              <Grid container direction="column">
                {filters.map(this.renderFilterItem)}
              </Grid>
            )
          }
        </Grid>
        <DropdownFooter>
          <PopupMenuButton label="Add Filter" calculateHeight>
            <SearchBar
              className="hubsync-menu-searchbar"
              placeholder="Find a Field"
              value={this.state.fieldSearchValue}
              size={SearchBarSize.Small}
              onChange={this.onFieldSearch}
              autoFocus
            />
            {columnsVM
              .filter(column => column.name && column.name.toLowerCase().includes(this.state.fieldSearchValue?.toLowerCase()))
              .map(this.renderColumnItem(column => this.addFilter(column)))}
          </PopupMenuButton>
        </DropdownFooter>
      </DropdownWrapper>
    );
  }

  private onFieldSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ fieldSearchValue: event.target.value });
  };

  private handleShowHideQuickFilters = (): void => this.props.changeQuickFiltersVisibility();

  private addFilter = (column: ColumnVM): void => {
    const payload: fromActions.AddFilterPayload = {
      columnId: column.id,
      type: mapColumnEditorToFilterType(column.fieldType, column.allowMultiple),
      dateFormat: column.dateFormat,
    };

    this.props.addFilter(payload);
    this.setState({ fieldSearchValue: '' });
  };

  private changeFilterType = (index: number, newColumn: ColumnVM): void => {
    const newFilterType = mapColumnEditorToFilterType(newColumn.fieldType, newColumn.allowMultiple);
    const payload: fromActions.UpdateFilterPayload = {
      index,
      columnId: newColumn.id,
      operator: getDefaultOperatorForFilterType(newFilterType),
      type: newFilterType,
      dateFormat: newColumn.dateFormat,
    };

    if (this.props.filters?.[index]?.type !== FilterType.Date && newFilterType === FilterType.Date) {
      payload.value = null;
    }

    this.props.updateFilter(payload);
    this.setState({ fieldSearchValue: '' });
  };

  private changeFilterOperator = (index: number, operator: FilterOperators): void => {
    const payload: fromActions.UpdateFilterPayload = {
      index,
      operator: mapFilterViewOperatorToOperator(operator),
    };
    this.props.updateFilter(payload);
  };

  private addWhitespaceIfCapital = (letter: string): string => {
    return letter === letter.toUpperCase()
      ? ` ${letter.toLowerCase()}`
      : letter.toLowerCase();
  };

  private mapWordToHaveSpaces = (letter: string, i: number): string => {
    return i === 0
      ? letter
      : this.addWhitespaceIfCapital(letter);
  };

  private mapFilterOperatorToViewOperator = (filterOperator: Operator): FilterOperators => {
    const result = filterOperator
      .split('')
      .map(this.mapWordToHaveSpaces)
      .join('');
    return result as FilterOperators;
  };

  private isNotEmptyOperatorType = (type: Operator): boolean => {
    return type !== Operator.Empty && type !== Operator.NotEmpty;
  };

  private renderOperatorItem = (index: number) => (operator: FilterOperators): JSX.Element => {
    return (
      <MenuItem
        key={operator}
        onClick={() => this.changeFilterOperator(index, operator)}
      >
        {operator}
      </MenuItem>
    );
  };

  private renderColumnItem = onClick => (column: ColumnVM): JSX.Element => {
    const { id, name } = column;
    return (
      <MenuItem key={id} onClick={() => onClick(column)}>
        {name}
      </MenuItem>
    );
  };

  private renderFilterItem = (filter: RegularFilter, index: number): JSX.Element => {
    const { filters, schemaColumns, columnsVM, updateFilter, deleteFilter } = this.props;
    const column = schemaColumns[filter.columnId];

    return (
      <DropdownListItem key={index}>
        <DropdownListItemRemove onClick={() => deleteFilter(index)} />
        <DropdownListItemLabel>
          {`${(index === 0) ? 'Where' : 'And'}`}
        </DropdownListItemLabel>
        <DropdownColumnWrapper>
          <Dropdown
            id={`dropdownFilters_${index}`}
            calculateHeight
            label={column && column.name}
            menuItems={columnsVM
              .filter(column => column.name.toLowerCase().includes(this.state.fieldSearchValue.toLowerCase()))
              .map(this.renderColumnItem(column => this.changeFilterType(index, column)),
              )}
            menuItemsSearch={
              <SearchBar
                className="hubsync-menu-searchbar"
                placeholder="Find a Field"
                value={this.state.fieldSearchValue}
                size={SearchBarSize.Small}
                onChange={this.onFieldSearch}
                autoFocus
              />
            }
          />
        </DropdownColumnWrapper>
        <OperatorDropdown
          label={this.mapFilterOperatorToViewOperator(filters[index].operator)}
          menuItems={getListOfOperatorsForFilterType(filters[index].type).map(this.renderOperatorItem(index))}
        />
        <InputWrapper className={this.props.classes.inputWrapper}>
          {this.isNotEmptyOperatorType(filters[index].operator) &&
            <FilteringOptionInput
              index={index}
              filter={filters[index]}
              column={column}
              updateFilter={updateFilter}
              isLastFilter={filters.length - 1 === index}
              onlyComponent={this.props.onlyComponent}
              schemaColumns={this.props.schemaColumns}
            />
          }
        </InputWrapper>
      </DropdownListItem>
    );
  };
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  columnsVM: columnVMToFilterBySelector(state),
  filters: filtersSelector(state).regularFilters,
  schemaColumns: schemaColumnsMap(state),
  quickFiltersBarEnabled: state.gridOptions.quickFilters,
});

const mapDispatchToProps: DispatchProps = {
  addFilter: fromActions.addFilter,
  updateFilter: fromActions.updateFilter,
  deleteFilter: fromActions.deleteFilter,
  changeQuickFiltersVisibility: QuickFilterActions.changeQuickFiltersVisibility,
};

const FiltersDropdownWithRef = React.forwardRef(
  (props: Props, ref: React.RefObject<FiltersDropdown>) => <FiltersDropdown ref={ref} {...props} />,
);

export const FiltersDropdownStyled = withStyles(filtersDropdownStyles)(FiltersDropdownWithRef);

export default withStyles(filtersDropdownStyles)(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
  )(FiltersDropdownWithRef),
);
