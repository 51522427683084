import { Location } from 'history';
import { getUrlsLastItem, stripUrlsLastItem } from 'utilities/queryParams';
import { CollectionTypes } from 'types/schema';
import { normalizeURL } from 'utilities/format';
import { DatabaseType } from 'data/databases/databases.types';
import { State } from 'reducers';
import { validateEmail } from './validation';
import { parseURI } from './parseURI';

export const createFieldsUrl = (state: State, viewId: string, id?: string, copy?: boolean): string => {
  const { router: { location } } = state;
  const { databaseId, dbSlug, workspaceId, sheetId, sheetSlug } = parseURI(location.pathname);
  const urlParams = `/workspaces/${workspaceId}/${dbSlug}/${databaseId}/${sheetSlug}/${sheetId}`.split('/');
  const urlArr = urlParams
    .concat('fields');
  id && urlArr.push(id);
  viewId && urlArr.push(viewId);
  urlArr.push('addField');
  let url = urlArr.join('/');
  let sourceUrl;
  if (copy) {
    sourceUrl = normalizeURL(url);
    url = urlArr.slice(0, -1).join('/') + '/:copy';
  }
  const defaultSheetId = state.sheets.currentId || '';
  return `${url.replace('default', defaultSheetId)}?api_version=2&sections=request,response,node${copy ? `&source=${sourceUrl}` : ''}`;
};

export const patchFieldsUrl = (state: State, id?: string, copy?: boolean): string => {
  const { router: { location } } = state;
  const { databaseId, dbSlug, workspaceId, sheetId, sheetSlug } = parseURI(location.pathname);
  const urlParams = `/workspaces/${workspaceId}/${dbSlug}/${databaseId}/${sheetSlug}/${sheetId}`.split('/');
  const urlArr = urlParams
    .concat('fields');
  id && urlArr.push(id);
  let url = urlArr.join('/');
  let sourceUrl;
  if (copy) {
    sourceUrl = normalizeURL(url);
    url = urlArr.slice(0, -1).join('/') + '/:copy';
  }
  const defaultSheetId = state.sheets.currentId || '';
  return `${url.replace('default', defaultSheetId)}?api_version=2&sections=request,response,node${copy ? `&source=${sourceUrl}` : ''}`;
};

export const createViewsUrl = (location: Location, sheetId: string, id?: string): string => {
  const parts = location.pathname.split('/');
  const sheetPartIndex = parts.findIndex(x => x.endsWith('sheets'));

  let url = createDatabasesUrl(location, true);
  if (sheetPartIndex > 0) {
    // will not work for /databases/{databaseId}/sheets url
    // solution is to move to named search params: /databases/{databaseId}/sheets?sheetID={sheetId}
    url = createSheetsUrl(location, sheetId, parts[sheetPartIndex]);
  }

  url = url.concat('/views');
  url = id ? url.concat(`/${id}`) : url;
  if (!url.includes('api_version=2')) url += '?api_version=2';
  return url;
};

const databasesTypes: DatabaseType[] = [
  CollectionTypes.databases,
  CollectionTypes.taskdbs,
  CollectionTypes.repositories,
];

export const createDatabasesUrl = (location: Location, includeId = false): string => {
  const parts = location.pathname.split('/');
  const databaseKey = databasesTypes.find(key => parts.some(part => part === key));
  const databaseSectionIndex = parts.findIndex(part => databaseKey === part);

  const databaseUrlParts = databaseSectionIndex > 0
    ? parts.slice(0, databaseSectionIndex + 1)
    : databaseKey ? [databaseKey] : [];

  const maybeId = parts[databaseSectionIndex + 1];

  return includeId && maybeId
    ? databaseUrlParts.concat(maybeId).join('/')
    : databaseUrlParts.join('/');
};

export const createSheetsUrl = (location: Location, id?: string, sheetSlug = 'sheets'): string => {
  let url = createDatabasesUrl(location, true).concat('/' + sheetSlug);
  url = id ? url.concat(`/${id}`) : url;
  return url;
};

export const createWorkspaceUrl = (id?: string): string => {
  return `/${CollectionTypes.workspaces}${id ? `/${id}` : ''}`;
};

export const createWorkspaceUrlPaginated = (): string => {
  return `/${CollectionTypes.allWorkspaces}`;
};

export const createBookmarksUrl = (id?: string): string => {
  return `/${CollectionTypes.paginatedBookmarks}${id ? `/${id}` : ''}`;
};

export const createAttachmentsUrl = (apiURI = '', id = ''): string => {
  let url = `${apiURI}/${CollectionTypes.attachments}`;
  if (id) {
    url += `/${id}`;
  }
  return url;
};

export const createRepositoryUrl = (workspaceId: string, repositoryId: string) => {
  return `${createWorkspaceUrl(workspaceId)}/${CollectionTypes.repositories}/${repositoryId}`;
};

export const isCollectionUrl = (pathname = ''): boolean => {
  return Object.keys(CollectionTypes).includes(getUrlsLastItem(pathname));
};

export const getCollectionUrl = (pathname = ''): string => {
  return isCollectionUrl(pathname) ? pathname : stripUrlsLastItem(pathname);
};

export const getNodeUrl = (pathname = ''): string => {
  return isCollectionUrl(pathname) ? stripUrlsLastItem(pathname) : pathname;
};

export const getCollectionTypeFromUrl = (pathname = ''): CollectionTypes | undefined => {
  const splitted = pathname.split('/');
  const lastItem = splitted[splitted.length - 1];
  const nextToLastItem = splitted[splitted.length - 2];
  return CollectionTypes[lastItem] || CollectionTypes[nextToLastItem];
};

export const isDocumentsUrl = (pathname = ''): boolean => {
  return getUrlsLastItem(pathname) === CollectionTypes.documents;
};

export const isWorkspacesUrl = (pathname = ''): boolean => {
  return getUrlsLastItem(pathname) === CollectionTypes.workspaces;
};

export const isDatabasesUrl = (pathname = ''): boolean => {
  return getUrlsLastItem(pathname) === CollectionTypes.databases;
};

export const isTaskDBUrl = (pathname = ''): boolean => {
  return getUrlsLastItem(pathname) === CollectionTypes.taskdbs;
};

export const isActivityFeedUrl = (pathname = ''): boolean => {
  return pathname.includes(CollectionTypes.activityfeeds);
};

export const isHistoryUrl = (pathname = ''): boolean => {
  return pathname.includes('history');
};

export const isPageUrl = (pathname = ''): boolean => {
  return !!pathname.match('/pages/([0-9A-Za-z]+)$');
};

export const createImportItemsUri = (uri: string): string => {
  const { sheets, items } = CollectionTypes;
  const importToDefaultSheetUri = `${sheets}/default/${items}:import`;
  return `${uri}/${importToDefaultSheetUri}`;
};

export function stripDomain(url: string): string {
  try {
    const { pathname, search } = new URL(url);
    return pathname + search;
  } catch (err) {
    return '/';
  }
}

export function fixUrl(fieldType: string, url?: string): string {
  if (!url) return '';

  if (fieldType === 'url' && !/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }

  if (fieldType === 'email' && validateEmail(url)) {
    url = 'mailto:' + url;
  }

  return url;
}

// Create url for item modal view for Tasks & Databases
export function createModalUrl(locationUrl: string, id: string): string {
  const { databaseId, dbSlug, workspaceId, sheetId, sheetSlug } = parseURI(locationUrl);
  return `/workspaces/${workspaceId}/${dbSlug}/${databaseId}/${sheetSlug}/${sheetId}/items/${id}`;
}

// Create url for item modal view for Tasks & Databases
export function createAttachmentUrl(locationUrl: string, id: string): string {
  const { databaseId, dbSlug, workspaceId, sheetId, sheetSlug } = parseURI(locationUrl);
  return `/workspaces/${workspaceId}/${dbSlug}/${databaseId}/${sheetSlug}/${sheetId}/attachments/${id}`;
}
