import { identity, prop, noop, path } from 'lodash/fp';
import { State } from 'reducers';
import { ActionCreator, AnyAction } from 'redux';
import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as request from 'superagent';
import { ActionType, isActionOf } from 'typesafe-actions';
import * as requests from 'utilities/httpRequests';

import * as services from 'data/auth/services';

import { notifications } from '../data/ui/notifications/notifications.actions';
import { UserSessionError } from './errors';


const repeatableStatuses = [408, 429, 502, 503, 504, 521, 522, 523, 524, 525, 598];

export const repeatableFactory = (
  {
    failedMessage = 'The save operation failed. We will retry and notify you when successful.',
    successMessage = 'The save operation has completed.',
  },
) => function* repeatable(request, ...args: any[]) {
  let err;
  let delayMs = 250;
  while (true) {
    try {
      // @ts-ignore
      const res = yield call(request, ...args);
      if (err) {
        yield call(successToast, successMessage);
      }
      return res;
    } catch (error) {
      if (!error.status || repeatableStatuses.indexOf(error.status) >= 0) {
        if (!err) {
          err = error;
          yield call(warningToast, failedMessage);
        }
        yield call(delay, delayMs);
        delayMs = delayMs >= 8 * 1000 ? delayMs : delayMs * 2;
      } else {
        throw error;
      }
    }
  }
};

export function* handleError(error: string | Error = 'Cannot reach server. Please try again later.') {
  console.error('error', error);

  if (error instanceof UserSessionError) {
    services.refreshLogin();
  } else {
    const message = path(['response', 'body', 'response', 'errorType'], error) || (error as Error).message || error;

    yield put(notifications.error({
      message,
    }));
  }
}

export function* successToast(message) {
  yield put(notifications.success({
    message,
  }));
}

export function* warningToast(message) {
  yield put(notifications.warn({
    message,
  }));
}

export function* errorToast(message) {
  yield put(notifications.error({
    message,
  }));
}

export function* infoToast(message) {
  yield put(notifications.info({
    message,
  }));
}

export enum RequestType {
  Get = 'get',
  Post = 'post',
  PostWithoutNormalization = 'postWithoutNormalization',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete'
}

const requestsByType = {
  [RequestType.Get]: requests.getRequest,
  [RequestType.Post]: requests.postRequest,
  [RequestType.PostWithoutNormalization]: requests.postRequestWithoutNormalization,
  [RequestType.Put]: requests.putRequest,
  [RequestType.Patch]: requests.patchRequest,
  [RequestType.Delete]: requests.deleteRequest,

};

interface PayloadFnParams {
  url: string;
  data: any;
}

type PayloadFn = (params: PayloadFnParams) => string | PayloadFnParams;
const payloadFnByType = {
  [RequestType.Get]: prop('url') as PayloadFn,
  [RequestType.Post]: identity as PayloadFn,
  [RequestType.PostWithoutNormalization]: identity as PayloadFn,
  [RequestType.Put]: identity as PayloadFn,
  [RequestType.Patch]: identity as PayloadFn,
  [RequestType.Delete]: prop('url') as PayloadFn,
};

// TODO: add type inferring for actions in buildUrl, buildData etc factories
interface CreateHandlerParams {
  takeFn?: typeof takeEvery; // takeLatest has the same type
  actions: {
    request: ActionCreator<any>;
    success: ActionCreator<any>;
    failure: ActionCreator<any>;
  };
  buildUrl: (action: AnyAction, state: any) => string;
  buildData?: (action: AnyAction, state: any) => any;
  buildHeaders?: (action: AnyAction, state: any) => {[h: string]: string};
  requestType?: RequestType;
  successPayloadMapper?: (response: request.Response, action: AnyAction, state: any) => any;
  successMetaMapper?: (response: request.Response, action: AnyAction, state: any) => any;
  failurePayloadMapper?: (error: Error, action: AnyAction, state: any) => any;
  onSuccess?: ((payload, action, state, meta, result) => void);
  onFailure?: (payload, action, state) => void;
}


export function* createAPIHandler({
  takeFn = takeEvery,
  actions,
  buildUrl,
  // @ts-ignore
  buildHeaders = noop,
  buildData = noop,
  requestType = RequestType.Get,
  successPayloadMapper = path(['body', 'node']),
  failurePayloadMapper = (error) => error,
  successMetaMapper = noop,
  onSuccess = noop,
  onFailure = noop,
}: CreateHandlerParams) {
  yield takeFn(
    isActionOf(actions.request),
    function* (action: ActionType<typeof actions.request>) {
      const state: State = yield select();

      const url = buildUrl(action, state);

      const data = buildData(action, state);

      const headers = buildHeaders(action, state);

      const request = requestsByType[requestType];

      const payloadFn = payloadFnByType[requestType];
      const payload = payloadFn({ url, data });


      try {
        // @ts-ignore
        const result = yield call(request, payload, state, headers);
        let successPayload = successPayloadMapper(result, action, state);
        successPayload = successPayload ?? result.body;
        const meta = successMetaMapper(result, action, state);
        yield put(actions.success(successPayload, meta, result, result));
        yield call(onSuccess, successPayload, action, state, meta, result);

        return successPayload;
      } catch (error) {
        yield call(handleError, error);
        const failurePayload = failurePayloadMapper(error, action, state);
        yield put(actions.failure(failurePayload));
        yield call(onFailure, error, action, state);
      }
    },
  );
}
