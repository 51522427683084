import * as React from 'react';

import { Actions as CollectionsActions } from 'data/collections/collections.actions';
import { CreateFieldPayload } from 'data/collections/collections.reducer';
import LoadingState from 'data/LoadingState';
import * as Actions from 'data/modals/actions';
import { CreateKanbanViewPayload } from 'data/views/types';
import { createView } from 'data/views/views.actions';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { CollectionViewTypes, Column, hasDuplicateFieldName } from 'types/schema';

import createFieldPayload from '../../Fields/createFieldPayload';
import * as FieldData from '../../Fields/data';
import { SelectionState, flipSelectionState } from '../../Fields/SelectFieldType';
import { ViewNameOrError, disolveViewNameOrError } from '../common/viewNameOrError';
import NewKanbanViewRender from './render';


interface OwnProps {
  viewNameOrError: ViewNameOrError;
  allFields: Column[];
  selectedTitleField?: Column;
  titleFields: Column[];
  selectedCategoryField?: Column;
  categoryFields: Column[];
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  onClose(): void;
}

interface DispatchProps {
  onViewNameChange(viewName: string): void;
  onCreateColumn(payload: CreateFieldPayload): void;
  onTitleFieldChange(selectedTitleField: Column): void;
  onCategoryFieldChange(selectedCategoryField: Column): void;
  onCreateNewCalendarView(payload: CreateKanbanViewPayload): void;
  onViewPrivateStateChange(isPrivate: boolean): void;
  onViewLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
}

interface StateProps {
  viewLoadingState: LoadingState;
  permissions?: string[];
}

type Props
  = OwnProps
  & DispatchProps
  & StateProps
  ;

interface State {
  titleAnchorEl?: HTMLElement;
  titleFieldName?: string;
  titleFieldNameErrorMessage?: string;
  titleFieldData: FieldData.FieldData;
  categoryAnchorEl?: HTMLElement;
  categorySelectionState: SelectionState;
  categoryFieldName?: string;
  categoryFieldNameErrorMessage?: string;
  categoryFieldData: FieldData.FieldData;
  categoryFieldTypeFilterQuery?: string;
}

class NewKanbanView extends React.Component<Props, State> {
  public state: State = {
    titleFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleLineText),
    categoryFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleSelect),
    categorySelectionState: SelectionState.SELECTED_FIELD,
  };

  public render(): JSX.Element {
    const { viewNameOrError, ...props } = this.props;
    const { errorMessage, viewName } = disolveViewNameOrError(viewNameOrError);

    return (
      <NewKanbanViewRender
        {...props}
        {...this.state}
        viewName={viewName}
        isPrivate={this.props.isPrivate}
        isLocked={this.props.isLocked}
        isDefault={this.props.isDefault}
        viewNameErrorMessage={errorMessage}
        onPrivateStateChange={this.props.onViewPrivateStateChange}
        onLockStateChange={this.props.onViewLockStateChange}
        onNewTitleFieldButtonClick={this.onNewTitleFieldButtonClick}
        onNewTitleFieldClose={this.onNewTitleFieldClose}
        onTitleFieldNameChange={this.onTitleFieldNameChange}
        onTitleDataUpdate={this.onTitleDataUpdate}
        onTitleFieldCreated={this.onTitleFieldCreated}
        onNewCategoryFieldButtonClick={this.onNewCategoryFieldButtonClick}
        onNewCategoryFieldClose={this.onNewCategoryFieldClose}
        onCategoryFieldTypeFilterQueryChange={this.onCategoryFieldTypeFilterQueryChange}
        onCategoryFieldTypeSelected={this.onCategoryFieldTypeSelected}
        onToggleSelectCategoryFieldType={this.onToggleSelectCategoryFieldType}
        onCategoryFieldNameChange={this.onCategoryFieldNameChange}
        onCategoryDataUpdate={this.onCategoryDataUpdate}
        onCategoryFieldCreated={this.onCategoryFieldCreated}
        onCreateNewCalendarView={this.onCreateNewCalendarView}
        viewLoadingState={this.props.viewLoadingState}
      />
    );
  }

  private onNewTitleFieldButtonClick = (titleAnchorEl: HTMLElement): void => {
    this.setState({ titleAnchorEl });
  };

  private onNewTitleFieldClose = (): void => {
    this.setState({ titleAnchorEl: undefined, titleFieldNameErrorMessage: undefined });
  };

  private onTitleFieldNameChange = (titleFieldName: string): void => {
    this.setState({ titleFieldName, titleFieldNameErrorMessage: undefined });
  };

  private onTitleDataUpdate = (titleFieldData: FieldData.FieldData): void => {
    this.setState({ titleFieldData, titleFieldNameErrorMessage: undefined });
  };

  private onTitleFieldCreated = (): void => {
    if (!this.state.titleFieldName) {
      this.setState({
        titleFieldName: undefined,
        titleFieldNameErrorMessage: 'Please provide a field name.',
      });
      return;
    }

    const isDuplicateFieldName = hasDuplicateFieldName(
      this.props.allFields,
      this.state.titleFieldName,
    );

    if (isDuplicateFieldName) {
      this.setState({
        titleFieldNameErrorMessage: 'Field should have a unique name.',
      });
      return;
    }

    const payload = createFieldPayload(this.state.titleFieldName, this.state.titleFieldData);

    this.props.onCreateColumn(payload);

    this.setState({
      titleFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleLineText),
      titleAnchorEl: undefined,
      titleFieldName: undefined,
      titleFieldNameErrorMessage: undefined,
    });
  };

  private onNewCategoryFieldButtonClick = (categoryAnchorEl: HTMLElement): void => {
    this.setState({ categoryAnchorEl });
  };

  private onNewCategoryFieldClose = (): void => {
    this.setState({ categoryAnchorEl: undefined, categoryFieldNameErrorMessage: undefined });
  };

  private onCategoryFieldTypeFilterQueryChange = (categoryFieldTypeFilterQuery: string): void => {
    this.setState({ categoryFieldTypeFilterQuery });
  };

  private onCategoryFieldTypeSelected = (fieldType: FieldData.FieldType): void => {
    this.setState({
      categoryFieldData: FieldData.getDefaultFieldData(fieldType),
      categorySelectionState: flipSelectionState(this.state.categorySelectionState),
    });
  };

  private onToggleSelectCategoryFieldType = (): void => {
    const categorySelectionState = flipSelectionState(this.state.categorySelectionState);

    this.setState({ categorySelectionState });
  };

  private onCategoryFieldNameChange = (categoryFieldName: string): void => {
    this.setState({ categoryFieldName, categoryFieldNameErrorMessage: undefined });
  };

  private onCategoryDataUpdate = (categoryFieldData: FieldData.FieldData): void => {
    this.setState({ categoryFieldData, categoryFieldNameErrorMessage: undefined });
  };

  private onCategoryFieldCreated = (): void => {
    if (!this.state.categoryFieldName) {
      this.setState({
        categoryFieldName: undefined,
        categoryFieldNameErrorMessage: 'Please provide a field name.',
      });
      return;
    }

    const isDuplicateFieldName = hasDuplicateFieldName(
      this.props.allFields,
      this.state.categoryFieldName,
    );

    if (isDuplicateFieldName) {
      this.setState({
        categoryFieldNameErrorMessage: 'Field should have a unique name.',
      });
      return;
    }

    const payload = createFieldPayload(this.state.categoryFieldName, this.state.categoryFieldData);

    this.props.onCreateColumn(payload);

    this.setState({
      categoryFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleSelect),
      categoryAnchorEl: undefined,
      categoryFieldName: undefined,
      categoryFieldNameErrorMessage: undefined,
    });
  };

  private onCreateNewCalendarView = (): void => {
    const { selectedCategoryField, viewNameOrError } = this.props;
    const primaryField = this.props.titleFields.find(field => field.isPrimary);

    const { viewName, hasError } = disolveViewNameOrError(viewNameOrError);

    if (hasError && viewName === '') {
      return;
    }

    if (!primaryField || !selectedCategoryField) {
      return;
    }

    const payload: CreateKanbanViewPayload = {
      title: viewName,
      type: CollectionViewTypes.kanban,
      titleFieldID: primaryField.id,
      categoryFieldID: `${selectedCategoryField.id?.includes('fields.') ? selectedCategoryField.id : 'fields.' + selectedCategoryField.id}`,
      isPrivate: this.props.isPrivate,
      isLocked: this.props.isLocked,
      isDefault: this.props.isDefault,
    };

    this.props.onCreateNewCalendarView(payload);
  };
}

const mapDispatchToProps: DispatchProps = {
  onViewNameChange: Actions.newViewName,
  onCreateColumn: CollectionsActions.createField,
  onTitleFieldChange: Actions.selectTitleField,
  onCategoryFieldChange: Actions.selectCategoryField,
  onCreateNewCalendarView: createView.request,
  onViewPrivateStateChange: Actions.newViewPrivateStatus,
  onViewLockStateChange: Actions.newViewLockStatus,
  onDefaultStateChange: Actions.newViewDefaultStatus,
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  viewLoadingState: state.collections.views.loadingState,
  // @ts-ignore
  isPrivate: state.modals.isPrivate,
  isLocked: state.modals.isLocked,
  isDefault: state.modals.isDefault,
  permissions: getWorkspacePermissions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewKanbanView);
