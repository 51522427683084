import * as React from 'react';

import { SvgIcon } from 'types/common';
import { findDOMNodeByClassName } from 'utilities/dom-helpers';

import LockColumnIcon from '../LockColumnIcon';
import PrimaryColumnIcon from '../PrimaryColumnIcon/PrimaryColumnIcon';
import {
  ColumnContainer,
  ColumnIconWrapper,
  ColumnLabelContainer,
  DisplayName,
  DropdownMenuIcon,
  FilterIcon,
  MenuButtonWrapper,
  SortAscendingIcon,
  SortDescendingIcon,
  PinnedIcon,
} from './FieldColumnHeader.style';

export enum SortStatus {
  Ascending = 'asc',
  Descending = 'desc',
  None = 'none',
}

export interface Props extends MenuProps, Partial<SortProps> {
  displayName: string;
  isFilterActive?: boolean;
  suppressMenu?: boolean;
  suppressSorting?: boolean;
  ColumnIcon?: SvgIcon | null;
  hideColumnIcon?: boolean;
  isLocked: boolean;
  isPinned: boolean;
  isPrimary: boolean;
  onSortClick(event: React.MouseEvent | React.TouchEvent): void;
}

export default function FieldColumnHeaderRender(props: Props): JSX.Element {
  const { sort, displayName, ColumnIcon, hideColumnIcon = false, suppressMenu, suppressSorting } = props;
  const onSortClick = !suppressSorting ? props.onSortClick : undefined;

  const handleClick = (e: React.MouseEvent | React.TouchEvent): void => {
    const header = e.currentTarget.closest('.ag-header-cell');
    const fieldModalButton = findDOMNodeByClassName(e.target, 'col-menu-icon-wrapper');
    if (!header?.className.includes('moving') && !fieldModalButton) {
      onSortClick && onSortClick(e);
    }
  };

  return (
    <ColumnContainer
      onTouchEnd={handleClick}
      onMouseUp={handleClick}
    >
      <ColumnLabelContainer
        role="presentation"
      >
        {props.isPrimary && <PrimaryColumnIcon/>}
        {ColumnIcon && !hideColumnIcon && <ColumnIconWrapper iconOnly={!displayName.length}><ColumnIcon /></ColumnIconWrapper>}

        <DisplayName role="columnheader">{displayName}</DisplayName>

        {props.isFilterActive && <FilterIcon />}
        {!suppressSorting && sort && <SortIcon sort={sort} />}
      </ColumnLabelContainer>

      {props.isPinned && <PinnedIcon />}

      {props.isLocked && <LockColumnIcon />}

      {!suppressMenu &&
        <MenuIcon
          menuButtonRef={props.menuButtonRef}
          openMenuClick={props.openMenuClick}
        />
      }
    </ColumnContainer>
  );
}

export interface MenuProps {
  menuButtonRef: React.RefObject<HTMLSpanElement>;

  openMenuClick(): void;
}

// TODO When we update to the latest version of styled-components,
// it will allow us to replace innerRef with ref and pass refObject.
// Typings in the current version doesn't allow us to do that
// https://hubsync.atlassian.net/browse/HR-100
export function MenuIcon(props: MenuProps): JSX.Element {
  return (
    <MenuButtonWrapper>
      <span className="col-menu-icon-wrapper"
        ref={props.menuButtonRef}
        onClick={props.openMenuClick}
      >
        <DropdownMenuIcon />
      </span>
    </MenuButtonWrapper>
  );
}

export interface SortProps {
  sort: SortStatus;
}

export function SortIcon(props: SortProps): JSX.Element | null {
  switch (props.sort) {
    case SortStatus.Ascending:
      return <SortAscendingIcon />;

    case SortStatus.Descending:
      return <SortDescendingIcon />;

    case SortStatus.None:
      return null;
  }
}
