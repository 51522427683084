import { createAsyncThunk } from '@reduxjs/toolkit';

import { State } from 'reducers';
import { CommonNode } from 'types/response';
import { removeHTMLTag } from 'utilities/format';

import {
  ORGANIZER_STATUS_COL_ID,
  STATUS_PENDING,
} from '../constants';
import {
  getGridData,
  getColumnClientId,
  getNextTick,
} from '../helpers';
import * as services from '../services';
import { pollingBatch } from './organizerCreate';
import { saveToPolling } from './organizerCreate';

export * from './engagementCreate';
export * from './organizerCreate';
export * from './workspaceCreate';
export * from './updateRecords';

/**
 *
 */
export const checkProcessesInRecords = createAsyncThunk(
  `CHECK_PENDING_RECORDS`,
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as State;
      const { columns, records } = getGridData(state);
      const isPollingRunning = !!getNextTick(state);
      const clientIdColumn = columns.find(getColumnClientId);

      /**
       * checking any pending process
       * Only support workspace create process and organizer process
       * @TODO Doesn't support Engagement letter, gotta find a way to check the process status
       */
      const { pendingOrg } = records.reduce((acc, record, index) => {
        const { fields } = record;
        const hasPendingOrg = fields?.[ORGANIZER_STATUS_COL_ID] === STATUS_PENDING;
        if (hasPendingOrg) {
          acc.pendingOrg.push(record);
        }

        return acc;
      }, { pendingOrg: [] as CommonNode[] });

      const clientIds = pendingOrg.map((record) =>
        removeHTMLTag(record.fields?.[clientIdColumn?._id ?? '' ] as string),
      ) as string[];

      const workspacesReponse = await services.validClientId(clientIds);
      const clients = workspacesReponse.body.workspaces.map(({ _id, meta: { clientId } }) => ({ _id, clientId }));
      const workspaceIds = clients.map(({ _id })=>_id);

      dispatch(saveToPolling(workspaceIds));
      if (!isPollingRunning && workspaceIds.length) {
        pollingBatch(getState, dispatch, 0, clients);
      }
    } catch (error) {
      console.error('ERROR CHECKING PENDING STATUS: ', error);
    }
  },
);
