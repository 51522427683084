import * as constants from 'data/assureSign/constants';
import { action, ActionsUnion } from 'types/reduxHelpers';

export interface CancelEnvelopePayload {
  envelopeId: string;
  remarks: string;
}

export interface PreCancelEnvelopePayload {
  nodeId: string;
}

export interface ResendEnvelopePayload {
  envelopeId: string;
  signerEmail: string;
}

export interface PreResendEnvelopePayload {
  nodeId: string;
}

// ------------------------------------
// Actions
// ------------------------------------

export const Actions = {
  preCancelEnvelope: action<typeof constants.PRE_CANCEL_ENVELOPE, PreCancelEnvelopePayload>(constants.PRE_CANCEL_ENVELOPE),
  cancelEnvelope: action<typeof constants.CANCEL_ENVELOPE, CancelEnvelopePayload>(constants.CANCEL_ENVELOPE),
  preResendEnvelope: action<typeof constants.PRE_RESEND_ENVELOPE, PreResendEnvelopePayload>(constants.PRE_RESEND_ENVELOPE),
  resendEnvelope: action<typeof constants.RESEND_ENVELOPE, ResendEnvelopePayload>(constants.RESEND_ENVELOPE),
};


export type ActionsType = ActionsUnion<typeof Actions>;
