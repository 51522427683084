import * as React from 'react';
import TextRenderer from '../components/TextRenderer';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererProps } from '../Fields.types';

/**
 * This Cell renderer display, plain strings(no markup) and dates
 *
 * @param props
 * @returns
 */
export function StatusRenderer(props: FieldRendererProps): JSX.Element {
  const { value } = props;

  return (
    <TextRenderer>
      {value}
    </TextRenderer>
  );
}

export default wrapCellRenderer(StatusRenderer);
