import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

import IconButton, { Color as IconButtonColor, Size } from '../../IconButton';
import Typography, { Variant } from '../../Typography';

interface ChildrenProps {
  className: string;
}

interface OwnProps {
  children: string | React.ReactElement<ChildrenProps>;
  className?: string;
  titleClassName?: string;
  ellipsisOverflow?: boolean;
  onClose(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function Header(props: Props): JSX.Element {
  let className = props.classes.wrapper;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const IconButtonModalStyle = () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  };

  return (
    <div className={className}>
      <Typography
        variant={Variant.SubHeading}
        ellipsisOverflow={props.ellipsisOverflow}
        className={props.titleClassName}
      >
        {props.children}
      </Typography>
      <IconButton id="btnCloseHeader"
        icon={svgIcons.Close}
        color={IconButtonColor.BlueyGrey}
        style={IconButtonModalStyle()}
        size={Size.Medium}
        onClick={props.onClose}
      />
    </div>
  );
}

type ClassKey = 'wrapper';

const styles = withStyles<ClassKey>({
  wrapper: {
    padding: '22px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.iceBlue}`,
  },
});

export default styles(Header);
