import './UserDashboard.styles.scss';
import * as React from 'react';
import Dashboard from '@hubsync/dashboard';
import { getAuthToken } from 'utilities/getAuthHeaders';
import { getApiV2Url, getFileProviderApiEndpoint } from 'env';
import getFileIcon from 'styles/fileExtension';
import { getFileExtension } from 'utilities/files';
import { useDispatch, useSelector } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { bookmarks as bookmarksSelector } from '../../data/bookmarks/bookmarks.selectors';
import { deleteBookmark } from '../../data/bookmarks/actions';

export const UserDashboard: React.FC = (props) => {
  const [token, setToken] = React.useState('');
  const dispatch = useDispatch();
  const dashboardConfig = useSelector((state: ReduxState) => state.app.tenant?.thirdParty?.dashboard);
  const bookmarks = useSelector(bookmarksSelector);

  React.useEffect(() => {
    const getToken = async () => setToken(await getAuthToken());
    getToken();
  }, []);

  const handleDeleteBookmark = (bookmarkID: string) => {
    dispatch(deleteBookmark({ bookmarkID }));
  };

  const pdfWindow = React.useMemo(() => {
    if (window.pdfFileWindow) return window.pdfFileWindow;
  }, [window.pdfFileWindow]);
  const functionObject = React.useMemo(() => {
    return {
      getFileIcon,
      getFileExtension,
      pdfWindow,
    };
  }, []);

  return (
    <React.Fragment>
      {token !== '' && (
        <Dashboard
          authToken={token}
          endpoint={getApiV2Url()}
          fileProviderApiEndpoint = {getFileProviderApiEndpoint()}
          utility={functionObject}
          dashboardProps={props}
          dashboardTenantConfig = {dashboardConfig}
          bookmarks={bookmarks}
          handleDeleteBookmark={handleDeleteBookmark}
        />
      )}
    </React.Fragment>
  );
};
