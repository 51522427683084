enum ViewNameOrErrorType {
  HAS_ERROR = 'HAS_ERROR',
  HAS_VIEW_NAME = 'HAS_VIEW_NAME',
}

interface HasError {
  type: ViewNameOrErrorType.HAS_ERROR;
  viewName: string;
  errorMessage: string;
}

interface HasViewName {
  type: ViewNameOrErrorType.HAS_VIEW_NAME;
  viewName: string;
}

export type ViewNameOrError
  = HasViewName
  | HasError
  ;

export function getViewNameOrError(existingViewNames: string[], viewName: string): ViewNameOrError {
  if (viewName === '') {
    return { type: ViewNameOrErrorType.HAS_ERROR, viewName, errorMessage: 'Please provide a view name.' };
  }

  if (existingViewNames.includes(viewName)) {
    return { type: ViewNameOrErrorType.HAS_ERROR, viewName, errorMessage: 'View name already exsists. Please enter a unique view name.' };
  }

  return { type: ViewNameOrErrorType.HAS_VIEW_NAME, viewName };
}

interface ViewNameOrErrorParts {
  viewName: string;
  errorMessage: string;
  hasError: boolean;
}

export function disolveViewNameOrError(viewNameOrError: ViewNameOrError): ViewNameOrErrorParts {
  switch (viewNameOrError.type) {
    case ViewNameOrErrorType.HAS_ERROR: {
      return {
        viewName: viewNameOrError.viewName,
        errorMessage: viewNameOrError.errorMessage,
        hasError: true,
      };
    }

    case ViewNameOrErrorType.HAS_VIEW_NAME: {
      return {
        viewName: viewNameOrError.viewName,
        errorMessage: '',
        hasError: false,
      };
    }
  }
}
