import { SelectedRows, SET_SELECTED_ROWS } from './selectedRow.actions';

export interface SelectedRowsState {
  selectedRowCount: number;
}

export const initialState: SelectedRowsState = {
  selectedRowCount: 0,
};

export default (state = initialState, action: SelectedRows) => {
  switch (action.type) {
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRowCount: action.selectedRowCount,
      };
    default:
      return state;
  }
};
