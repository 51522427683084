import { EMPTY_TITLE } from '../../AgGrid/utils';
import { enableRipple } from '@syncfusion/ej2-base';
import {
  RichTextEditor,
  Link,
  HtmlEditor,
} from '@syncfusion/ej2-richtexteditor';
import { filtersSelector } from 'data/grid-options/gridOptions.selector';

import { getGridRowMaxTextLines, getGridRowClassText } from '../../AgGrid/utils';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import BaseRenderer from '../BaseRendererPrototype';
import { FieldsTypes as FieldRendererTypes } from '../FieldsTypes';
import GenericTextRenderer from '../GenericTextRenderer';

enableRipple(true);
RichTextEditor.Inject(Link, HtmlEditor);

function MultiLineOfTextRenderer() {
  return true;
}

MultiLineOfTextRenderer.prototype = Object.create(BaseRenderer.prototype);

MultiLineOfTextRenderer.prototype.init = function(params) {
  const { type, fieldType, node, isGrid, column } = params;
  let { value } = params;
  value = value ? `${value}` : '';

  this.defaultRTE = new RichTextEditor({
    readonly: true,
    inlineMode: {
      enable: true,
      onSelection: true,
    },
    format: {
      width: 'auto',
    },
    fontFamily: {
      width: 'auto',
    },
  });

  value = value || ' ';
  if (typeof value === 'object') {
    value = value.toString();
  }

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    this.element = document.createElement('span');
    this.element.classList.add(getColumnHeaderClass(fieldType));
    this.element.innerHTML = value;
  }

  const lines = (isGrid && node) ? getGridRowMaxTextLines(node.rowHeight) : 1;
  this.element = document.createElement('div');
  this.element.id = `${node.id}-${column.colId}`;
  this.element.style = `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

  const valueElement = document.createElement('div');
  valueElement.classList.add(getGridRowClassText(node.rowHeight, 'multi'));
  valueElement.style = GenericTextRenderer(lines, params.color || null,
    params.fontSize || null,
    params.width || null);

  valueElement.innerHTML = valueElement.innerHTML = node?.group && value == EMPTY_TITLE ? '' : value;


  this.element.append(valueElement);

  this.eGridCell = params.eGridCell;
  const clickBarrier = document.createElement('div');
  clickBarrier.classList.add('hb-click-barrier');
  this.element.appendChild(clickBarrier);
  this.valueElement = valueElement;
  this.defaultRTE.appendTo(valueElement);

  if (this.eGridCell) {
    this.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && value?.toLowerCase()?.includes(currentQuickSearch.toLowerCase())) {
      this.eGridCell.classList.add('highlighted-cell');
    }
  }

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

MultiLineOfTextRenderer.prototype.getGui = function() {
  return this.element;
};

MultiLineOfTextRenderer.prototype.refresh = function() {
  return false;
};

MultiLineOfTextRenderer.prototype.destroy = function() {
  return true;
};

export default MultiLineOfTextRenderer;
