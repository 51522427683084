import { Dictionary } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import { createStructuredSelector } from 'reselect';

// import { accountsById } from 'data/accounts/selectors';
import { State } from 'reducers';
import { AccountNode } from 'types/response/accountNode';

import ChoiceEditor from '../choice/ChoiceEditor';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import AccountChip from '../../AccountChip/AccountChip';
import { getActiveWorkspaceUsers } from 'data/accounts/selectors';

interface OwnProps extends FieldEditorProps {
  allowMultiple?: boolean;
  isGrid?: boolean;
}

interface StateProps {
  accounts: Dictionary<AccountNode> | null;
}

const selectors = (state: State) => {
  const accounts = {};
  getActiveWorkspaceUsers(state).forEach((account: AccountNode) => {
    accounts[account.id] = Object.assign({}, account);
  });
  return { accounts };
};

const enhance = compose<StateProps, OwnProps>(
  wrapCellEditor({ stopEditingAfterChange: true }),
  connect(selectors, null, null, { forwardRef: true }),
);

type Props = OwnProps & StateProps;

const AccountEditor = React.forwardRef(
  ({ accounts, ...props }: Props, ref: React.RefObject<HTMLElement>): JSX.Element | null => {
    if (accounts === null) {
      return null;
    }

    let extraProps = {};
    if (!props.isGrid) {
      extraProps = {
        renderSelectedChoice: (choice, props) => <AccountChip accountId={choice.id} {...props}/>,
      };
    }

    return (
      <ChoiceEditor.OriginalComponent
        id={`dropdownAccount`}
        {...props}
        ref={ref}
        choices={accounts}
        choiceOrder={Object.keys(accounts)}
        renderChoice={(choice, readOnly, lock, itemProps) =>
          <AccountChip accountId={choice.id} {...itemProps}/>
        }
        {...extraProps}
        matchChoice={(query, { displayName }) =>
          displayName?.toLowerCase().includes(query.toLowerCase())
        }
      />
    );
  },
);

export default enhance(AccountEditor);
