import { push } from 'connected-react-router';

class WorkspaceEntity {
  created(data) {
    const store = window.appStore;
    const state = store.getState();
    const currentUserId = state.users.user.id;
    const workspace = data.item;

    if (
      workspace.createdBy === currentUserId &&
      // if this workspace wasn't created automatically(yeah, that case exists)
      workspace.meta?.mode !== 'auto'
    ) {
      // redirect to recently created workspace
      store.dispatch(push(`/workspaces/${workspace._id}/files`));
    }
  }
}

export default new WorkspaceEntity();
