import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { dark, lightGrey, slateTwo } from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey = 'root' | 'label' | 'bullet' | 'chip' | 'chipBullet' | 'addChoice';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
  },
  label: {
    color: dark,
    fontFamily,
    fontSize: '14px',
    fontWeight: 'normal',
    letterSpacing: '0.22px',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: '2px',
  },
  bullet: {
    width: '9px',
    height: '9px',
    borderRadius: '10px',
    flexShrink: 0,
    backgroundColor: lightGrey,
    marginRight: '6px',
  },
  chipBullet: {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: lightGrey,
    marginRight: '6px',
  },
  chip: {
    'paddingLeft': '10px',
    '& > span': {
      'paddingLeft': 0,
    },
  },
  addChoice: {
    marginRight: '5px',
    fontSize: '16px',
    color: slateTwo,
    lineHeight: '1',
  },
};
