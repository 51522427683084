import { SummarizeInput } from '../types';
import * as _ from 'lodash';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'components/Fields/data';

function summarize(input: SummarizeInput) {
  const minDate = _.min(input.data.map(dateString => (new Date(dateString))));
  return moment(minDate).format(DEFAULT_DATE_FORMAT);
}

export default summarize;
