import { createAsyncThunk } from '@reduxjs/toolkit';
import { Analytics } from './types';
import { State } from 'reducers';

import * as services from './services';

export const fetchAnalytics = createAsyncThunk(
  `FETCH_ANALYTICS`,
  async (_, { getState }) => {
    const state = getState() as State;
    const currentWorkspace = state.workspaces.current;

    if (!currentWorkspace) {
      return undefined;
    }

    const response = await services.fetchAnalytics(currentWorkspace);
    return response.body as unknown as Analytics;
  },
);
