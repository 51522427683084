import { isEmpty } from 'lodash';
import { EMAIL_REGEXP } from '../constants';

// Validate a field using JSON schema.
export function ValidateField(params) {
  const messages = [];

  // Skip read-only fields.
  if (params.schema.readOnly) {
    return messages;
  }

  // Check masking.
  if (params.schema.masked) {
    messages.push('Field is masked.');
  }

  // Check required field values.
  if (params.schema.required) {
    if (!params.value) {
      messages.push('Field is required.');
    }
  }

  // Check lengths.
  if (params.schema.length && params.value) {
    if (params.value.length !== params.schema.length) {
      messages.push(`Field is not expected length (${params.schema.length}).`);
    }
  }
  if (params.schema.minLength && params.value) {
    if (params.value.length < params.schema.minLength) {
      messages.push(`Field is below minimum length (${params.schema.minLength}).`);
    }
  }
  if (params.schema.maxLength) {
    if (params.value > params.schema.maxLength) {
      messages.push(`Field is above maximum length (${params.schema.maximum}).`);
    }
  }

  // Check field values.
  if (params.schema.equalTo) {
    if (params.value !== params.schema.equalTo) {
      messages.push(`Field is not equal to allowed value (${params.schema.equalTo}).`);
    }
  }
  if (params.schema.minimum) {
    if (params.value < params.schema.minimum) {
      messages.push(`Field is below minimum (${params.schema.minimum}).`);
    }
  }
  if (params.schema.maximum) {
    if (params.value > params.schema.maximum) {
      messages.push(`Field is above maximum (${params.schema.maximum}).`);
    }
  }
  if (params.schema.notEqualTo) {
    if (params.value === params.schema.notEqualTo) {
      messages.push(`Field is equal to blocked value (${params.schema.notEqualTo}).`);
    }
  }
  if (params.schema.notOneOf) {
    if (params.schema.notOneOf.includes(params.value)) {
      messages.push(`Field is one of blocked value (${params.schema.oneOf}).`);
    }
  }
  if (params.schema.oneOf) {
    if (params.schema.oneOf.includes(params.value)) {
      messages.push(`Field is not one of allowed value (${params.schema.oneOf}).`);
    }
  }

  // Check relative field values.
  if (params.schema.equalToField) {
    if (params.node) {
      if (params.value !== params.node.data[params.schema.equalToField]) {
        messages.push(`Field is not equal to relative value (${params.schema.equalToField}).`);
      }
    } else {
      messages.push(`"Equal to" field does not exist (${params.schema.equalToField}).`);
    }
  }
  if (params.schema.greaterThanField) {
    if (params.node) {
      if (params.value <= params.node.data[params.schema.greaterThanField]) {
        messages.push(`Field is not greater than relative value (${params.schema.greaterThanField}).`);
      }
    } else {
      messages.push(`"Greater than" field does not exist (${params.schema.greaterThanField}).`);
    }
  }
  if (params.schema.greaterThanOrEqualToField) {
    if (params.node) {
      if (params.value < params.node.data[params.schema.greaterThanOrEqualToField]) {
        messages.push(`Field is not greater than or equal to relative value (${params.schema.greaterThanOrEqualToField}).`);
      }
    } else {
      messages.push(`"Greater than or equal to" field does not exist (${params.schema.greaterThanOrEqualToField}).`);
    }
  }
  if (params.schema.lessThanField) {
    if (params.node) {
      if (params.value >= params.node.data[params.schema.lessThanField]) {
        messages.push(`Field is not less than relative value (${params.schema.lessThanField}).`);
      }
    } else {
      messages.push(`"Less than" field does not exist (${params.schema.lessThanField}).`);
    }
  }
  if (params.schema.lessThanOrEqualToField) {
    if (params.node) {
      if (params.value > params.node.data[params.schema.lessThanOrEqualToField]) {
        messages.push(`Field is not less than or equal to relative value (${params.schema.lessThanOrEqualToField}).`);
      }
    } else {
      messages.push(`"Less than or equal to" field does not exist (${params.schema.lessThanOrEqualToField}).`);
    }
  }
  if (params.schema.notEqualToField) {
    if (params.node) {
      if (params.value !== params.node.data[params.schema.notEqualToField]) {
        messages.push(`Field is equal to invalid relative value (${params.schema.notEqualToField}).`);
      }
    } else {
      messages.push(`"Not equal to" field does not exist (${params.schema.notEqualToField}).`);
    }
  }

  // Check pattern.
  if (params.schema.pattern) {
    if (!(new RegExp(params.schema.pattern).test(params.value))) {
      messages.push(`Value does not satisfy pattern (${params.schema.pattern}).`);
    }
  }

  if (params?.schema?.fieldType === 'email') {
    const name = Number(params?.fieldName);
    if (name) {
      const value = params?.node?.data?.fields?.[name] || '';
      if (value && !value.match(EMAIL_REGEXP)) {
        messages.push(`Invalid email address`);
      }
    }
  }

  // Check root tag URLs.
  if (params.schema.rootTagURLs) {
    const matched = params.schema.rootTagURLs.some((rootTagURL) => {
      if (params.value.startsWith(rootTagURL)) {
        return true;
      }

      return false;
    });

    if (!matched) {
      messages.push(`Value has invalid root tag URL (${params.schema.rootTagURLs}).`);
    }
  }

  // Check choices.
  if (params.schema.choices && params.value && Array.isArray(params.value)) {
    if (params.value.some((id) => !params.schema.choices[id])) {
      messages.push(`Value is not a valid choice (${params.schema.choices})`);
    }
  } else if (params.schema.choices && params.value) {
    if (params.schema.choices[params.value] === undefined) {
      messages.push(`Value is not a valid choice (${params.schema.choices})`);
    }
  }

  // Run custom validation function.
  if (params.schema.validationFunction) {
    messages.push(...params.schema.validationFunction(params));
  }

  return messages;
}

// Validate an entire node using JSON schema.
export function ValidateNode(params) {
  let messages = {};

  // Loop through properties, checking validity.
  const properties = {
    ...params.schema.properties,
    ...params.schema.properties?.fields?.properties || {},
  };

  Object.keys(properties).forEach((fieldID) => {
    const fieldSchema = properties[fieldID];
    if (fieldSchema.type === 'object' && fieldSchema.properties) {
      // Recurse.
      messages = Object.assign(messages, ValidateNode({
        node: params.node,
        prefix: `${fieldID}.`,
        schema: fieldSchema,
      }));
    } else {
      // Parse field value.
      fieldID = `${params.prefix}${fieldID}`;
      const fieldValue = params?.node?.data?.[fieldID];

      const fieldErrors = ValidateField({
        fieldName: fieldID,
        node: params.node,
        schema: fieldSchema,
        value: fieldValue,
      });

      if (!isEmpty(fieldErrors)) {
        messages[fieldID] = fieldErrors;
      }
    }
  });

  if (!isEmpty(messages)) {
    console.debug('Validation errors', JSON.stringify(messages)); // eslint-disable-line
  }
  return messages;
}
