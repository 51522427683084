import {
  BlobNode,
  BookmarkableNode,
  CommonNode,
  isBlobNode,
  isBookmarkableNode,
} from '.';

export interface DocumentNode extends BlobNode, BookmarkableNode, CommonNode {
  folderID?: string; // Parent folder ID.
  isFolder?: boolean; // Whether this represents a folder.
  published?: boolean; // Whether the document is published, making it accessible to clients.
  fieldID?: string;
  primaryFieldID?: string;
}

export function isDocumentNode(node: CommonNode): node is DocumentNode {
  const functions = [isBlobNode, isBookmarkableNode];

  return functions.every((fn) => fn(node));
}

export interface DocumentMinimalType {
  apiURI: string;
}
