import { SchemaProperty } from '../../../types/schema';
import { AttachmentsState } from '../../attachments/reducer';

export type SummarizeInput = {
  type: string;
  fieldId: string;
  dataNotFormatted: any[];
  data: any[];
  column: SchemaProperty | undefined;
  attachments?: AttachmentsState;
};

export enum SummaryTypes {
  none='none',
  empty='empty',
  filled='filled',
  unique='unique',
  percentEmpty='percentEmpty',
  percentFilled='percentFilled',
  percentUnique='percentUnique',
  sum='sum',
  average='average',
  median='median',
  min='min',
  max='max',
  range='range',
  totalAttachmentSize='totalAttachmentSize',
  unchecked='unchecked',
  checked='checked',
  percentUnchecked='percentUnchecked',
  percentChecked='percentChecked',
  earliestDate='earliestDate',
  latestDate='latestDate',
  dateRangeDays='dateRangeDays',
  dateRangeMonths='dateRangeMonths',
}

const summaryTypeLabelMap = {
  [SummaryTypes.none]: 'None',
  [SummaryTypes.empty]: 'Empty',
  [SummaryTypes.filled]: 'Filled',
  [SummaryTypes.unique]: 'Unique',
  [SummaryTypes.percentEmpty]: 'Percent Empty',
  [SummaryTypes.percentFilled]: 'Percent Filled',
  [SummaryTypes.percentUnique]: 'Percent Unique',
  [SummaryTypes.sum]: 'Sum',
  [SummaryTypes.average]: 'Average',
  [SummaryTypes.median]: 'Median',
  [SummaryTypes.min]: 'Min',
  [SummaryTypes.max]: 'Max',
  [SummaryTypes.range]: 'Range',
  [SummaryTypes.totalAttachmentSize]: 'Total Attachment Size',
  [SummaryTypes.unchecked]: 'Unchecked',
  [SummaryTypes.checked]: 'Checked',
  [SummaryTypes.percentUnchecked]: 'Percent Unchecked',
  [SummaryTypes.percentChecked]: 'Percent Checked',
  [SummaryTypes.earliestDate]: 'Earliest Date',
  [SummaryTypes.latestDate]: 'Latest Date',
  [SummaryTypes.dateRangeDays]: 'Date Range (days)',
  [SummaryTypes.dateRangeMonths]: 'Date Range (months)',
};
export { summaryTypeLabelMap };

export interface SummaryOption {
  type: SummaryTypes;
  label: string;
  displayLabel?: string;
}
