
export class CustomError extends Error {
  static type = 'CustomError';

  constructor(type, message='') {
    super();
    this.name = type;
    this.message = message;
  }
}
export class UserSessionError extends Error {
  static type = 'UserSessionError';
  details: string;

  constructor(details = '') {
    super();
    this.name = UserSessionError.type;
    this.message = 'Failed to get the session from user';
    this.details = details;

    console.error(`${this.message} - ${this.details}`);
  }
}

export class TenantConfigError extends Error {
  static type = 'TenantConfigError';
}
