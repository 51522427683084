import { ButtonRendererFieldProps } from '../types';

interface URLData {
  url: string;
}

export const openURL = (data: ButtonRendererFieldProps | ButtonRendererFieldProps) => {
  const { additionalData } = data;
  if (additionalData) {
    const data: URLData = JSON.parse(additionalData);
    const url = !data.url.match(/^https?:\/\//i) ? `http://${data.url}` : data.url;
    return window.open(url, '_blank');
  }
};
