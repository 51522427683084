import React from 'react';
import Barcode from 'react-barcode';
import wrapCellRenderer from '../../components/wrapCellRenderer.tsx';

import './style.scss';

const BarcodeRenderer = (field) => {
  if (!field.value) {
    return (
      <div className="barcode__formatter">
      </div>
    );
  }

  return (
    <div className="barcode__formatter">
      <Barcode
        font="OCR-B"
        fontSize={16}
        format="CODE128"
        height={20}
        margin={8}
        value={field.value}
        width={2}
      />
    </div>
  );
};

export default wrapCellRenderer(BarcodeRenderer);
