import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';
import { fontFamily } from 'styles/fonts';

type ClassKey =
  'nodeViewWrapper'
  | 'messagesWrapper'
  | 'messagesInnerWrapper'
  | 'nodeViewContent'
  | 'headerWrapper'
  | 'headerInnerWrapper'
  | 'arrowIcon'
  | 'arrowIconToLeft'
  | 'arrowIconToRight'
  | 'backgroundColor'
  | 'iconButton'
  | 'leftHeaderWRapper'
  | 'windowTitle'
  | 'snackBar'
  | 'rootDialog'
;

export const styles: Record<ClassKey, CSSProperties> = {
  nodeViewWrapper: {
    'display': 'flex',
    'overflow': 'hidden',
    'minHeight': '510px',
    '& .messages-label-container': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },

  nodeViewContent: {
    width: '510px',
    overflow: 'hidden',
    paddingBottom: '70px',
  },

  messagesWrapper: {
    'position': 'relative',
    'width': '320px',
    'overflowY': 'auto',
    'backgroundColor': colors.whiteSmoke,
  },

  headerWrapper: {
    'position': 'relative',
    '& > button': {
      'visibility': 'hidden',
    },
  },

  messagesInnerWrapper: {
    position: 'absolute',
    top: '81px',
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },

  headerInnerWrapper: {
    width: '100%',
  },

  arrowIcon: {
    display: 'inline-block',
  },

  arrowIconToLeft: {
    position: 'absolute',
    top: '22px',
    right: '26px',
    color: colors.lightBlueGrey,
  },

  arrowIconToRight: {
    position: 'absolute',
    top: '23px',
    right: '60px',
    color: colors.lightBlueGrey,
  },

  backgroundColor: {
    backgroundColor: colors.whiteSmoke,
  },

  iconButton: {
    'borderRadius': '50%',
    'width': '24px',
    'height': '24px',
    '&:hover': {
      'color': colors.slateTwo,
      'backgroundColor': colors.blackTransparent,
    },
  },
  leftHeaderWRapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  windowTitle: {
    fontFamily,
    color: colors.charocalGrey,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '21px',
    marginLeft: '10px',
    maxWidth: '340px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  snackBar: {
    '& > div': {
      color: colors.charocalGrey,
      backgroundColor: colors.white,
      borderRadius: 10,
    },
  },
  rootDialog: {
    backgroundColor: 'transparent',
  },
};
