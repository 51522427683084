import { Dictionary, every } from 'lodash';
import { BookmarkMeta } from 'data/bookmarks/types';
import { Reminder as ReminderType } from 'data/reminders/reminders';
import { AccountNode } from '../response/accountNode';
interface MultiChoiceValue {
  [position: string]: boolean;
}

export interface Timestamp {
  scheduled?: [];
  timestamp: number;
}

export type FieldValue
  = string
  | number
  | boolean
  | string[]
  | MultiChoiceValue
  | Timestamp
  ;

export interface Fields {
  [columnId: string]: FieldValue;
}

interface ViewRowOrder {
  [viewId: string]: number;
}

export interface CommonNode {
  // IDs.
  _id?: string; // Persistent node ID.
  id: string; // Persistent node ID.
  apiURI: string; // API URI, for CRUD operations.
  downloadURI?: string; // URI to link to if downloading.
  inlineURI?: string; // URI to link to if opening in a new tab.
  linkURI?: string; // Default URI to link to.
  uri: string; // URI, specifying object's position in the hierarchy.

  // API properties.
  checkOutSupported?: boolean; // Whether the node supports items being checked out.
  copiedFrom?: string; // URI where the node was copied from.
  createdBy: string; // ID of the creator.
  createdDate: number; // Date the node was created.
  defaultViewID?: string; // ID of the default view.
  deletedBy?: string; // ID of the deleter.
  deletedDate?: number; // Date the node was deleted.
  externalAccessSupported?: boolean; // Whether the node allows external user access.
  fields?: Fields; // Metadata field values.
  frozenBy?: string; // ID of the freezer.
  frozenDate?: number; // Date the node was frozen.
  highwayHash?: string; // Computed highway hash for quick comparison.
  historyBy?: string; // ID of the account responsible for the last history action.
  historyDate?: number; // Date of the last history action.
  historyMessage: string; // Message explaining the last change in the node's history.
  iconClass?: string; // Class name of the icon for this node.
  iconURL?: string; // Link to the icon for this node.
  isDefault?: boolean; // Whether the node was created from default data.
  isDeleted?: boolean; // Whether the node has been deleted.
  isFrozen?: boolean; // Whether the node has been frozen.
  isMoved?: boolean; // Whether the node has been moved.
  isOnLegalHold?: boolean; // Whether the node is on legal hold.
  legalHoldBy?: string; // ID of the legal holder.
  legalHoldDate?: number; // Date the node was put on legal hold.
  modifiedBy: string; // ID of the last modifier.
  modifiedDate: number; // Date the node was last modified.
  modifiedAt?: number; // Date the node was last modified.
  movedBy?: string; // ID of the mover.
  movedDate?: number; // Date the node was moved.
  movedFrom?: string; // URI where the node was moved from.
  movedTo?: string; // URI where the node was moved to.
  version?: number; // Version of the node.
  versionsSupported?: boolean; // Whether the node supports version history.
  viewRowOrder?: ViewRowOrder; // Explicit row order, per view.
  viewTypes?: string[]; // View types supported by the node.
  title?: string;
  modifiedByUser?: AccountNode;

  // Virtual API properties.
  folderEntriesCount?: number; // Number of entries in the folder.
  permissions?: string[]; // Permissions for the account working with the node.
  primaryDescendantCollection?: string; // Name of the primary descendant collection.
  primaryDescendantCollectionCount?: number; // Count of primary descendant collection.
  primaryDescendantPluralLabel?: string; // Label to use for plural items in the primary descendant collection.
  primaryDescendantSingularLabel?: string; // Label to use for singular items in the primary descendant collection.
  sharedWith?: string[]; // IDs of accounts with read permissions on the nodes.

  // Client injected properties
  agGridId?: string; // ID used by AgGrid to identify the node
  rowIndex?: number; // contains natural row index based on order returned from the backend.
  fieldId?: string;

  acl?: object;

  bookmarkMeta?: BookmarkMeta;
  bulkReminders?: Array<ReminderType>;
}

export function getId(node: CommonNode): string {
  return node.id;
}

export interface BlobNode {
  blobHighwayHash?: string; // Computed BLOB highway hash for quick comparison.
  fileExtension?: string; // File extension.
  fileName: string; // File name of the document.
  hasEditor?: boolean; // Whether the document has an editor.
  hasPreview?: boolean; // Whether the document has a preview.
  hashes?: Dictionary<string>; // Hashes of the file.
  mediaMajorType?: string; // Media (MIME) major type.
  mediaSubType?: string; // Media (MIME) subtype.
  mediaType?: string; // Media (MIME) type.
  numberOfPages?: number; // Number of pages within the file.
  size?: number; // Size of the file in bytes.
}

export interface BookmarkableNode {
  isBookmarked?: boolean; // Whether the node is in the account's bookmarks.
}

export interface ImageNode {
  height?: number; // Height of the preview.
  width?: number; // Width of the preview.
}

export interface IndexableNode {
  index: number;
}

export enum PreviewBehavior {
  Download = 'download', // Download downloads the file.
  ModalPreview = 'modalpreview', // ModalPreview opens a preview in a modal on the current page.
  OpenInNewTab = 'openinnewtab', // OpenInNewTab opens the file in a new tab.
}

// Type Guards
// TODO: When we upgrade to TS 3.0, replace `any` with `unknown`

export function isCommonNode(node: any): node is CommonNode {
  if (typeof node !== 'object') {
    return false;
  }

  const schema: InterfaceTypes<CommonNode> = {
    id: 'string',
    apiURI: 'string',
    uri: 'string',
    createdBy: 'string',
    createdDate: 'number',
    historyMessage: 'string',
    modifiedBy: 'string',
    modifiedDate: 'number',
    modifiedAt: 'number',
  };

  return every(schema, (type, key) => key in node && typeof node[key] === type);
}

export function isBlobNode(node: any): node is BlobNode {
  if (typeof node !== 'object') {
    return false;
  }

  const hasFileName = 'fileName' in node;

  if (!hasFileName) {
    return false;
  }

  return typeof node.fileName === 'string';
}

export function isBookmarkableNode(node: any): node is BookmarkableNode {
  return typeof node === 'object';
}

type InterfaceTypes<Interface> = {
  [key in keyof Interface]: 'string' | 'number';
};
