import { combineReducers } from 'redux';

import filterModel from './filterModel.reducer';
import sortModel from './sortModel.reducer';
import quickFilters, { QuickFilterState } from './quickFilter.reducer';
import groupBy, { GroupByState } from './groupBy/groupBy.reducer';
import { FilterModel, SortModelState } from '../../types/gridOptions';
import selectedRows, { SelectedRowsState } from './selectedRow.reducer';

export interface State {
  filterModel: FilterModel;
  quickFilters: QuickFilterState;
  sortModel: SortModelState;
  groupBy: GroupByState;
  selectedRows: SelectedRowsState;
}

const reducer = combineReducers({
  filterModel,
  sortModel,
  quickFilters,
  groupBy,
  selectedRows,
});

export default reducer;
