import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { styles } from 'components/Modals/ModalTextField.style';
import SelfSelectingField from 'components/SelfSelectingField';
import { Omit } from 'utility-types';

import ModalUrlPreviewMode from './PreviewMode/PreviewMode';

type FieldProps = Omit<TextFieldProps,
  'inputRef' | 'error' | 'helperText' | 'fullWidth' | 'InputProps' | 'rows' | 'variant'>;

type StyleProps = WithStyles<typeof styles>;

interface OwnProps {
  errorMessage?: string;
  autoSelect?: boolean;
  readOnly?: boolean;
  lock?: boolean;
}

type Props = FieldProps & StyleProps & OwnProps;


enum Mode {
    Preview,
    Edit,
}

interface State {
    mode: Mode;
    autoFocus: boolean;
}

function getValue(props: Props): string | undefined {
  return (typeof props.value === 'string') ? props.value : '';
}

class ModalUrlField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { mode: Mode.Preview, autoFocus: false };
  }

  render() {
    const { autoSelect, errorMessage, classes, lock, ...fieldProps } = this.props;
    const hasError = !!errorMessage;
    const Field = autoSelect ? SelfSelectingField : TextField;
    const { formControlRoot, ...inputClasses } = classes;

    if (this.state.mode === Mode.Preview) {
      return (
        <ModalUrlPreviewMode
          lock={lock}
          value={getValue(this.props)}
          onClick={() => this.setState({ mode: Mode.Edit })}
          onFocus={() => this.setState({ mode: Mode.Edit, autoFocus: true })}
        />
      );
    }

    return (
      <Field
        error={hasError}
        fullWidth
        helperText={errorMessage}
        classes={{ root: formControlRoot }}
        InputProps={{
          disableUnderline: true,
          classes: inputClasses,
        }}
        lock={lock}
        rows={2}
        {...fieldProps}
        disabled={fieldProps.readOnly}
        autoFocus={this.state.autoFocus}
        onBlur={(event) => {
          this.setState({ mode: Mode.Preview, autoFocus: false });
          this.props.onBlur && this.props.onBlur(event);
        }}
      />
    );
  }
}

export default withStyles(styles)(ModalUrlField);
