import { getSocketUrl } from 'env';
import { getAuthToken } from '../../utilities/getAuthHeaders';
import _ from 'lodash';
import handlers from './handlers';
let ws;
let sessionOver = false;

async function wsConnect(onOpen) {
  if (ws) {
    ws.onerror = ws.onopen = ws.onclose = null;
    ws.close();
  }
  const token = await getAuthToken();
  ws = new WebSocket(`${getSocketUrl()}?token=${token}`);
  ws.onerror = function() {
    // eslint-disable-next-line no-console
    console.log('WebSocket error');
  };
  ws.onopen = function() {
    // eslint-disable-next-line no-console
    console.log('WebSocket connection established');
    onOpen();
  };
  ws.onclose = function() {
    if (sessionOver) {
      // eslint-disable-next-line no-console
      console.log('WebSocket connection closed');
      ws = null;
    } else {
      wsConnect(onOpen);
    }
  };
  ws.onmessage = function(message) {
    const dataString = _.get(message, 'data');
    if (dataString) {
      const data = JSON.parse(dataString);
      const picked = _.pick(data, ['entity', 'type', 'record']);
      if (picked) {
        const { entity, type, record } = picked;
        const resolverClass = handlers[entity];
        if (resolverClass) {
          const resolverAction = resolverClass[type]?.bind?.(resolverClass);
          if (resolverAction) {
            resolverAction(record);
          }
        }
      }
    }
  };
}

async function wsClose() {
  if (ws) {
    sessionOver = true;
    ws.close();
  }
}
export { wsConnect, wsClose };
