import * as React from 'react';
import { useDispatch } from 'react-redux';

import Confirm from 'components/Modals/Confirm';
import Modal from 'components/Modals/Modal';
import * as ModalActions from 'data/modals/actions';
import { createOrganizerProcess } from 'data/workspaceCreate/actions';

interface Props {
  recordIds: string[];
}

function OrganizerRequireModal({ recordIds }: Props) {
  const dispatch = useDispatch();

  const onClose = () =>
    dispatch(ModalActions.closeModal());

  const onConfirm = () =>
    dispatch(createOrganizerProcess({ ids: recordIds }));

  return (
    <Modal onClose={onClose}>
      <Confirm
        title={'Organizer Requires a Workspace'}
        message={'Click OK to create workspace(s) and/or organizer(s).'}
        close={onClose}
        onConfirm={onConfirm}
        confirmLabel={'OK'}
      />
    </Modal>
  );
}

export default OrganizerRequireModal;
