import * as React from 'react';

import Rename from 'components/Modals/Rename';
import { closeModal } from 'data/modals/actions';
import { deleteView, updateView } from 'data/views/views.actions';
import { views } from 'data/views/views.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { connect } from 'react-redux';
import { State as RootState } from 'reducers';
import { ViewNode } from 'types/response/viewNode';

interface StateProps {
  existingViewTitles: string[];
  user: any;
  permissions: string[];
}

interface DispatchProps {
  updateView: typeof updateView.request;
  deleteView: typeof deleteView.request;
  close: () => void;
}

interface OwnProps {
  view: ViewNode;
}

type Props = StateProps & DispatchProps & OwnProps;

export class EditView extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { view, existingViewTitles, close, permissions } = this.props;

    return (
      <Rename
        currentValue={view.title}
        isPrivate={view.isPrivate}
        isLocked={view.isLocked}
        isDefault={view.isDefault}
        canBePrivate={view.createdBy === this.props.user.id && !view.isDefault}
        existingValues={existingViewTitles}
        close={close}
        onUpdate={this.onUpdateNode}
        onDelete={view.chart ? undefined : this.onDeleteNode}
        windowTitle="Edit View"
        valueTitle="view"
        submitButton="Update"
        permissions={permissions}
      />
    );
  };

  private onDeleteNode = (): void => {
    const { deleteView, view } = this.props;
    deleteView({ id: view.id });
  };

  private onUpdateNode = (title: string, isPrivate?: boolean, isLocked?: boolean, isDefault?: boolean): void => {
    const { updateView, view } = this.props;
    updateView({
      id: view.id,
      title,
      isPrivate: isPrivate,
      isLocked: isLocked,
      isDefault: isDefault,
    });
  };
}

const emptyViewNodes: ViewNode[] = [];

const mapStateToProps = (state: RootState): StateProps => {
  const allViews = views(state) || emptyViewNodes;
  const existingViewTitles: string[] = allViews
    .map(view => view.title);
  return {
    existingViewTitles,
    user: state.users.user,
    permissions: getWorkspacePermissions(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  updateView: updateView.request,
  deleteView: deleteView.request,
  close: closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditView);
