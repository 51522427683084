import AccountRenderer from 'components/CellRenderers/account/AccountRenderer.js';
import BooleanRenderer from 'components/CellRenderers/boolean/BooleanRenderer.js';
import DateTimeRenderer from 'components/CellRenderers/datetime/DateTimeRenderer.js';
import IdRenderer from 'components/CellRenderers/id/IdRenderer';
import MultiSelectRenderer from 'components/CellRenderers/multiselect/MultiSelectRenderer.js';
import NumberRenderer from 'components/CellRenderers/number/NumberRenderer.js';
import PhoneRenderer from 'components/CellRenderers/phone/PhoneRenderer.js';
import RatingRenderer from 'components/CellRenderers/rating/RatingRenderer.js';
import SingleLineOfTextRenderer from 'components/CellRenderers/singlelineoftext/SingleLineOfTextRenderer.js';
import SingleSelectRender from 'components/CellRenderers/singleselect/SingleSelectRenderer.js';
import UrlRenderer from 'components/CellRenderers/url/UrlRenderer.js';
import MultiLineOfTextRenderer from 'components/CellRenderers/multilinetext/MultiLineOfTextRenderer.js';

import { FieldType } from '../../types/response/fieldNode';
import AttachmentRenderer from './attachment/AttachmentRenderer';

export const renderers = {
  IdRenderer,
};

export default (fieldType) => {
  const fallback = SingleLineOfTextRenderer;
  switch (fieldType) {
    case FieldType.Singlelineoftext:
    case FieldType.Staticformula:
      return SingleLineOfTextRenderer;
    case FieldType.Boolean: {
      return BooleanRenderer;
    }

    case FieldType.Float:
    case FieldType.Integer:
    case FieldType.Currency:
    case FieldType.Percent: {
      return NumberRenderer;
    }

    case FieldType.Phone:
      return PhoneRenderer;

    case FieldType.Date:
    case FieldType.DateTime: {
      return DateTimeRenderer;
    }

    case FieldType.Rating: {
      return RatingRenderer;
    }

    case FieldType.Url:
    case FieldType.Email: {
      return UrlRenderer;
    }

    // post MVP:
    case FieldType.Timespan:
    case FieldType.Computed:
    case FieldType.Lookup:
    case FieldType.SearchReference:
    case FieldType.Location: {
      return fallback;
    }

    case FieldType.Singlechoice: {
      return SingleSelectRender;
    }

    case FieldType.Multiplechoice: {
      return MultiSelectRenderer;
    }

    case FieldType.Attachment: {
      return AttachmentRenderer;
    }

    case FieldType.Account: {
      return AccountRenderer;
    }
    case FieldType.Button: {
      return null;
    }
    case FieldType.Status: {
      return null;
    }

    /**
     * it skips the multi line text for reader user
     * so, it's not needed to specify this renderer here
     */
    case FieldType.Multilinetext: {
      return MultiLineOfTextRenderer;
    }

    default: {
      console.warn(`Column type ${fieldType} doesn't have renderer`);
      // return fallback;
      return undefined;
    }
  }
};
