import { getApiUrl } from '../../env';

// ------------------------------------
// GLOBAL CONSTANTS
// ------------------------------------
export const ROOT_URL = getApiUrl();
export const API_VERSION = '/api/v1';
export const LICENSE_KEY = 'HubSync_HubSync_Core_Multiple_Applications_1_Devs__3_January_2021_[v2]_MTYwOTYzMjAwMDAwMA==de2ed2f57fb61001235d3810cf568bb9';

// APP CONSTANTS
export const FETCH_TENANT_DATA_SUCCESS = 'FETCH_TENANT_DATA_SUCCESS';
export const FETCH_TENANT_DATA_REQUEST = 'FETCH_TENANT_DATA_REQUEST';
export const FETCH_TENANT_DATA_ERR = 'FETCH_TENANT_DATA_ERR';
export const FETCH_NAVIGATION_TREE_SUCCESS = 'FETCH_NAVIGATION_TREE_SUCCESS';
export const FETCH_NAVIGATION_TREE_REQUEST = 'FETCH_NAVIGATION_TREE_REQUEST';
export const FETCH_NAVIGATION_TREE_ERROR = 'FETCH_NAVIGATION_TREE_ERROR';
export const FETCH_CURRENT_USER_ERR = 'FETCH_CURRENT_USER_ERR';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';

// Action creators
export function fetchTenantDataSuccess(data) {
  return {
    type: FETCH_TENANT_DATA_SUCCESS,
    tenant: data,
  };
}

export function fetchTenantData() {
  return {
    type: FETCH_TENANT_DATA_REQUEST,
  };
}

export function fetchTenantDataError(message: string) {
  return {
    type: FETCH_TENANT_DATA_ERR,
    payload: message,
  };
}
