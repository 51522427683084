import React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import getFileIcon from 'styles/fileExtension';
import { PreviewBehavior } from 'types/response';
import { AttachmentNode } from 'types/response/attachmentNode';
import { TenantNode } from 'types/response/tenantNode';

import { Chip } from './AttachmentsEditor.style';
import PreviewBehaviorComponent from './PreviewBehavior';
import { ThumbnailAttachmentItem } from './ThumbnailAttachmentItem';

interface OwnProps {
  attachment: AttachmentNode;
  previewBehavior: PreviewBehavior;
  onDelete(): void;
  onClick: () => void;
  permissions: string[];
  tenant?: TenantNode;
  readOnly?: boolean;
  hideDelete?: boolean;
  showPreview?: boolean;
  rowHeight?: GRID_ROW_HEIGHT;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function AttachmentItem(props: Props): JSX.Element {
  const { attachment, classes, previewBehavior, hideDelete, showPreview, rowHeight = 0, permissions, readOnly, onClick, tenant } = props;
  const { title, fileExtension, thumbnail } = attachment;
  const previewFeatureEnable = tenant?.thirdParty?.filestack?.enable;
  const Icon = getFileIcon(fileExtension);

  function onDelete(event: React.MouseEvent): void {
    event.preventDefault();
    props.onDelete();
  }

  if (showPreview) {
    return (
      <ThumbnailAttachmentItem
        classes={classes}
        fileExtension={fileExtension}
        hideDelete={hideDelete}
        permissions={permissions}
        readOnly={readOnly}
        rowHeight={rowHeight}
        thumbnail={thumbnail}
        onDelete={onDelete}
        onClick={onClick}
      />
    );
  } else {
    const itemConent = (
      <Chip
        label={
          <div className={classes.label}>
            <Icon className={classes.icon} />
            <span className={classes.text}>{title}</span>
          </div>
        }
        onDelete={!props.readOnly && props.permissions.includes('files:delete') ? onDelete : undefined}
        hideDelete={hideDelete}
      />
    );

    return previewFeatureEnable
      ? (
        <div onClick={onClick}>
          {itemConent}
        </div>
      )
      : (
        <PreviewBehaviorComponent
          previewBehavior={previewBehavior}
          attachment={attachment}
        >
          {itemConent}
        </PreviewBehaviorComponent>
      );
  }
}

type ClassKey
  = 'label'
  | 'icon'
  | 'iconPreview'
  | 'text'
  | 'hidden'
  | 'deleteIcon'
  ;

const styles = withStyles<ClassKey>({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '16px',
    minWidth: '16px',
    height: '16px',
    marginRight: '5px',
  },
  iconPreview: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    marginRight: '5px',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hidden: {
    display: 'none',
  },
  deleteIcon: {
    color: '#505F79',
    position: 'absolute',
    top: '2px',
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
});

export default styles(AttachmentItem);
