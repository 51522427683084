import { css } from 'styled-components';
import * as colors from 'styles/colors';

export default css`
.ag-theme-material .ag-menu {
  background-color: ${colors.white}; // colors.white
  .ag-menu-list {
    .ag-menu-option {
      font-size: 0.8125rem;
      height: 1.8125rem;
      color: ${colors.darkSlateBlue}; // dark-slate-blue
      cursor: pointer;
      padding: 0 0 0 0.3rem;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .ag-menu-option-text {
        flex-grow: 1;
      }

      .ag-menu-option-icon {
        width: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        
        & > .icon-container {
          height: auto;
          margin-top: 0.2rem;
        }
        & > .ag-icon {
          margin-top: 0;
        }
      }
    }
  }
}

/* Context menu */
.ag-theme-material .ag-menu .ag-menu-option-active {
  background-color: ${colors.lightBlueTransparent};
}
`;
