import { createStyles } from '@material-ui/core';

import * as Colors from 'styles/colors';

export const filterInputStyles = () =>
  createStyles({
    root: {
      padding: '0 8px',
      borderRadius: 2,
      backgroundColor: Colors.athensGrey,
      fontSize: 11,
    },
    input: {
      padding: '6px 0 6px',
    },
    focused: {
      borderColor: Colors.hubsyncBlueSemiTransparent,
      borderStyle: 'solid',
      borderWidth: 1,
    },
  });
