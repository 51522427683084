import * as React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';

interface RenderItemProps<T> {
  key: string;
  style: any;
  measure: () => void;
  item: T;
  index: number;
  parentScroll?: boolean;
}

interface Props<T> {
  className?: string;
  items: T[];
  zoom?: number;
  scrollToIndex?: number | null;
  onScroll?: (props: { clientHeight: number; scrollHeight: number; scrollTop: number }) => void;
  renderItem: (props: RenderItemProps<T>) => React.ReactNode;
  onResize?: (props: { height: number; width: number }) => void;
  onRowsRendered?: (props: { startIndex: number; stopIndex: number }) => void;
}

const DEFAULT_HEIGHT = 200;
const OVERSCAN_ROW_COUNT = 5;

export default class VirtualList extends React.PureComponent<Props<any>> {
  private cache: CellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: DEFAULT_HEIGHT,
  });

  private listRef: any = React.createRef();

  renderRow = ({ key, index, parent, style }) => {
    const { items, renderItem } = this.props;
    // we need to pass this prop to see whether we have scrolling in the list
    // and add or not to add padding for elements in this case
    const parentScroll = this.listRef.current.Grid._verticalScrollBarSize !== 0;
    return (
      <CellMeasurer
        cache={this.cache}
        key={key}
        rowIndex={index}
        parent={parent}>
        {({ measure }) => renderItem({ key, style, measure, item: items[index], index, parentScroll })}
      </CellMeasurer>
    );
  };

  scrollToRow = (index: number) => {
    // https://github.com/bvaughn/react-virtualized/issues/995
    this.listRef.current.scrollToRow(index);
    setTimeout(() => this.listRef.current.scrollToRow(index));
  };

  getOffsetForRow = (index: number) => {
    return this.listRef.current.getOffsetForRow({ alignment: 'start', index });
  };

  scrollToPosition = (scrollTop: number) => {
    return this.listRef.current.scrollToPosition(scrollTop);
  };

  forceUpdateList = () => {
    this.cache.clearAll();
    this.listRef.current.recomputeRowHeights(0);
  };

  handleResize = (props: { height: number; width: number }) => {
    this.forceUpdateList();
    this.props.onResize && this.props.onResize(props);
  };

  render() {
    const { items } = this.props;

    return (
      <AutoSizer onResize={this.handleResize}>
        {({ height, width }) => {
          return (
            <List
              {...this.props}
              ref={this.listRef}
              rowCount={items.length}
              rowRenderer={this.renderRow}
              height={height}
              width={width}
              estimatedRowSize={DEFAULT_HEIGHT}
              overscanRowCount={OVERSCAN_ROW_COUNT}
              rowHeight={this.cache.rowHeight}
              scrollToAlignment="start"
            />
          );
        }}
      </AutoSizer>
    );
  }
}
