import React, { useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';

import AccountChip from '../AccountChip';
import { styles } from './styles';


// Borrowed from Material UI
function renderInput(inputProps) {
  const { InputProps, classes, ref, onBlur, onFocus, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
        onBlur,
        onFocus,
      }}
      {...other}
      autoFocus
      style={{
        maxHeight: '102px',
        overflowY: 'auto',
      }}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  type,
  classes,
  handleClick,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  if (type === 'account') {
    return (
      <div
        className={isHighlighted ? classes.activeItem : classes.item}
        onClick={() => handleClick(suggestion.id)}
      >
        <AccountChip accountId={suggestion.id} showName />
        {suggestion.email && (
          <span className={classes.suggestionEmail}>{suggestion.email}</span>
        )}
      </div>
    );
  }

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      style={{ fontWeight: isSelected ? 500 : 400 }}
      component="div"
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
  type: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
};

function getSuggestions(suggestions, value, allowCreation = false) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  const filteredSuggestions = inputLength === 0
    ? []
    : [...suggestions.filter(suggestion => {
      const keep = count < 3 && (suggestion.label?.slice(0, inputLength).toLowerCase() === inputValue || suggestion.name?.slice(0, inputLength) === inputValue);

      if (keep) {
        count += 1;
      }

      return keep;
    }),
    ];

  if (inputLength > 0 && allowCreation) {
    return [
      ...filteredSuggestions,
      { id: inputValue, label: inputValue, type: 'add' },
    ];
  }

  return filteredSuggestions;
}

function DownshiftMultiple(props) {
  const { classes } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState(props.selectedItems);
  const [initialValueChanged, setInitialValueChanged] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState<null | string>(null);

  useEffect(() => {
    if (initialValueChanged) {
      props.onChange(selectedItem);
    }
  }, [selectedItem, initialValueChanged]);

  useEffect(() => {
    props.onActiveItem?.(activeItem);
  }, [activeItem]);

  function handleKeyDown(event) {
    event.stopPropagation();
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      if (activeItem) {
        setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        setInitialValueChanged(true);
        setActiveItem(null);
        return;
      }
      setActiveItem(selectedItem[selectedItem.length - 1]);
    }
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
    if (activeItem) {
      setActiveItem(null);
    }
    event.stopPropagation();
  }

  function handleChange(item) {
    if (!item) return;
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    setSelectedItem(newSelectedItem);
    setInitialValueChanged(true);
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    setInitialValueChanged(true);
    if (activeItem === item) {
      setActiveItem(null);
    }
  };

  const handleClick = (item) => {
    setInputValue('');
    setSelectedItem([...selectedItem, item]);
    setInitialValueChanged(true);
  };

  const onFocus = () => {
    props.onFocus?.();
  };

  const onBlur = () => {
    props.onBlur?.();
  };

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            onFocus,
            onBlur,
            InputProps: getInputProps({
              startAdornment: selectedItem.map((item) =>
                props.renderSelectedItem({
                  item,
                  classes: props.classes,
                  deleteItem: handleDelete(item),
                }),
              ),
              onChange: handleInputChange,
              onKeyDown: handleKeyDown,
              placeholder: props.placeholder,
            }),
          })}
          {isOpen ? (
            <Paper className={classes.paper} square>
              {getSuggestions(
                props.suggestions,
                inputValue2,
                props.allowCreation,
              ).map((suggestion, index) =>
                (props.renderSuggestion || renderSuggestion)({
                  suggestion,
                  index,
                  itemProps: getItemProps({ item: suggestion.id }),
                  highlightedIndex,
                  selectedItem: selectedItem2,
                  type: props.type,
                  classes: props.classes,
                  handleClick,
                }),
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  type: PropTypes.string,
  renderSelectedItem: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  allowCreation: PropTypes.bool,
  onActiveItem: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

function IntegrationDownshift(props) {
  return (
    <div className={props.classes.root}>
      <DownshiftMultiple classes={props.classes} {...props} />
    </div>
  );
}

export default withStyles(styles)(IntegrationDownshift);
