import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import { TenantNode } from 'types/response/tenantNode';

import Logo from './Logo';
import styles, { ClassKey } from './styles';

interface ChildrenProps {
  className: string;
}

interface OwnProps {
  tenant?: TenantNode;
  children?: string | React.ReactElement<ChildrenProps>;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function HeaderBar(props: Props): JSX.Element {
  const { classes, tenant } = props;
  const containLogout = window.location.href.includes('logout');
  return (
    <div className={`${classes.wrapper} app-header-bar`}>
      <div className={classes.group}>
        <Logo logoUrl={tenant && tenant.theme && tenant.theme.logoURL} />
      </div>
      {!containLogout ? <div className={classes.group}>
        <div className={classes.iconWrapper}>
          <span className={classes.labelUserGuest}>Guest Access</span>
        </div>
      </div> : <></>}
    </div>
  );
}

export default styles(HeaderBar);
