import * as React from 'react';
import MuiPopover, { PopoverProps as MuiPopoverProps } from '@material-ui/core/Popover';

export interface PopoverRenderParams {
  openPopover: React.MouseEventHandler;
  closePopover: () => void;
  opened: boolean;
  className?: string;
  hideBackdrop?: boolean;
}

interface PopoverProps extends Partial<MuiPopoverProps> {
  children: (params: PopoverRenderParams) => React.ReactNode;
  renderTrigger: (params: PopoverRenderParams) => React.ReactNode;
  onPopoverClose?: () => void;
  close?: boolean;
}

interface PopoverState {
  anchorEl: HTMLElement | null;
}

const defaultMuiPopoverProps: Partial<MuiPopoverProps> = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
};

class Popover extends React.Component<PopoverProps, PopoverState> {
  state = {
    anchorEl: null,
  };

  public open = (e: HTMLElement) => {
    this.setState({ anchorEl: e });
  };

  public closePopover = () => {
    this.setState({ anchorEl: null });
    if (this.props.onPopoverClose) {
      this.props.onPopoverClose();
    }
  };

  componentDidUpdate(prevProps: PopoverProps): void {
    if (prevProps.close !== this.props.close && this.props.close) {
      this.closePopover();
    }
  }

  public render(): React.ReactNode {
    const { children, renderTrigger, onPopoverClose, ...props } = this.props;
    const { anchorEl } = this.state;
    const renderParams = {
      openPopover: this.openPopover,
      closePopover: this.closePopover,
      opened: !!anchorEl,
    };
    return (
      <>
        {renderTrigger(renderParams)}
        <MuiPopover
          {...defaultMuiPopoverProps}
          {...props}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.closePopover}
        >
          {children(renderParams)}
        </MuiPopover>
      </>
    );
  }

  private openPopover = (e): void => {
    this.setState({ anchorEl: e.currentTarget });
  };
}

export default Popover;
