import * as React from 'react';
import { useState } from 'react';
import TextField from '../../TextField';
import * as Data from '../../data';

interface Props {
  label: string;
  defaultNumber?: number | string;
  onValueChange: (value?: number | string) => void;
}

export default (props: Props) => {
  const [defaultNumber, setDefaultNumber] = useState(Data.maybeNumberToString(props.defaultNumber));

  return (
    <TextField
      id="defaultNumber"
      label={props.label}
      placeholder="Default number"
      value={defaultNumber}
      onChange={event => {
        const value = event.target.value;
        setDefaultNumber(value);
        if (value.trim().length === 0) {
          props.onValueChange('');
        }
        if (typeof Data.parseNumber(event.target.value) === 'number') {
          props.onValueChange(Data.parseNumber(event.target.value));
        }
      }}
      fullWidth
    />
  );
};
