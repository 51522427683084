import { typeIcon } from '../../../pages/Files/Renderers/CellRenderers/IconRenderer';

export default function clockIcon() {
  const iconWrapper = document.createElement('span');
  iconWrapper.className = 'datepicker-notify-icon-wrapper';
  const icon = typeIcon['clockIcon'].cloneNode(true);
  icon.style = `
    height: 14px;
  `;
  iconWrapper.append(icon);
  return iconWrapper;
}
