type FontSize
  = 'normal'
  | 'big'
  | 'bold'
  | 'bigBold'
  ;

type FontSizeFn = () => string;

export const fonts: Record<FontSize, FontSizeFn> = {
  normal: () => `
    font-size: 14px;
  `,
  big: () => `
    font-size: 16px;
  `,
  bold: () => `
    font-weight: bold;
  `,
  bigBold: () => `
    ${fonts.big()}
    ${fonts.bold()}
  `,
};

export const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
