import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { paperPropsStyle, userMenuStyles } from 'components/HeaderBar/UserMenu.style';
import { useLocation } from 'react-router-dom';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { rgba } from 'styles/utils/colorUtils';
import { AccountNode } from 'types/response/accountNode';

import Typography, { Variant } from '../Typography';
import ProfileMenu from './ProfileMenu';

interface OwnProps {
  user: AccountNode;
  anchorEl?: HTMLElement;
  onOpen(anchorEl: HTMLElement): void;
  onClose(): void;
}

type Props
  = OwnProps
  & WithStyles<typeof userMenuStyles>
  ;

function UserMenu(props: Props): JSX.Element {
  const { classes, anchorEl, user } = props;
  const location = useLocation();
  const {
    backgroundColor = rgba(Colors.black, 0.04),
    displayName = 'No Display Name',
    givenName,
  } = user;
  const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };
  const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const { currentTarget } = event;
    props.onOpen(currentTarget);
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    // Close menu when location changes
    setIsOpen(false);
  }, [location]);

  return (
    <div id="btnUserMenu" className={classes.wrapper} onClick={onClick}>
      {givenName &&
        <Avatar
          style={{ backgroundColor }}
        >
          <Typography variant={Variant.ProfileInitials}>
            {getInitials(user)}
          </Typography>
        </Avatar>
      }
      <Typography variant={Variant.ProfileName} className={classes.profileName}>
        {displayName}
      </Typography>
      <svgIcons.Dropdown className={classes.menuIcon} />
      <Popover
        id="userMenu"
        anchorEl={anchorEl}
        open={isOpen}
        PaperProps={paperPropsStyle}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={props.onClose}
      >
        <ProfileMenu onClose={props.onClose} />
      </Popover>
    </div>
  );
}

export default withStyles(userMenuStyles)(UserMenu);

function getInitials(user: AccountNode): string {
  const { givenName = '', surname = '' } = user;

  return `${givenName.charAt(0)}${surname.charAt(0)}`;
}
