import React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { AttachmentData } from 'components/Fields/data';
import Switch from 'components/Switch';
import Typography, { Variant } from 'components/Typography';
import * as Colors from 'styles/colors';


interface Props extends WithStyles<ClassKey> {
  data: AttachmentData;
  allowShowPreview?: boolean;
  onDataUpdate(data: AttachmentData): void;
}

function Attachment(props: Props): JSX.Element {
  const { allowShowPreview, classes, data, onDataUpdate } = props;

  function onChecked(): void {
    onDataUpdate({ ...data, showPreview: !data.showPreview });
  }

  return (
    <React.Fragment>
      <Typography variant={Variant.Label} className={classes.description}>
        Attachments allow you to add images, documents, or other files which can then be viewed or downloaded.
      </Typography>
      {allowShowPreview &&
        <div className={classes.toggle}>
          <Switch
            label="Show attachment preview"
            selected={data.showPreview}
            onClick={onChecked}
            labelRight
          />
        </div>
      }
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'toggle'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  toggle: { marginTop: 8 },
});

const AttachmentStyle = styles(Attachment);

export default AttachmentStyle;
