import * as React from 'react';
import { IHeaderParams } from 'ag-grid-community/dist/lib/headerRendering/header/headerComp';
import { getColumnTypeIcon } from 'components/AddColumnHeader/FieldTypesIcons';
import * as Data from 'components/Fields/data';
import { mapDataFieldTypeIntoDbFieldType } from './FieldMapper';

class ColumnHeader extends React.Component<IHeaderParams> {
  private ref;

  public render(): JSX.Element {
    const column = this.props.column.getColDef();
    const fieldType = mapDataFieldTypeIntoDbFieldType(column.type as Data.FieldType);
    const Icon = getColumnTypeIcon(fieldType);
    return (
      <div className="ag-cell-label-container" role="presentation">
        {column.colId && (
          <React.Fragment>
            <span ref={ref => this.ref = ref} className="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" onClick={this.showMenu}>
              <span className="ag-icon ag-icon-menu" unselectable="on"></span>
            </span>
          </React.Fragment>
        )}
        <div className="ag-header-cell-label" role="presentation" unselectable="on">
          {column.colId && <Icon />}
          <span className="ag-header-cell-text" role="columnheader" unselectable="on">{column.headerName}</span>
        </div>
      </div>
    );
  }

  private showMenu = () => {
    this.props.showColumnMenu(this.ref);
  };
}

export default ColumnHeader;
