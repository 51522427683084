import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import svgIcons from 'styles/svgIcons';
import { querySearchStyles } from 'components/NavigationPane/QuerySearch.style';

interface Props extends WithStyles<typeof querySearchStyles> {
  query?: string;
  handleQuery(event: React.ChangeEvent<HTMLInputElement>): void;
}

export function QuerySearch(props: Props): JSX.Element {
  return (
    <div className={props.classes.box}>
      <svgIcons.Search className={props.classes.icon} />
      <input
        placeholder="Search"
        value={props.query || ''}
        onChange={props.handleQuery}
        className={props.classes.input}
      />
    </div>
  );
}

export default withStyles(querySearchStyles)(QuerySearch);
