import * as React from 'react';

import DocumentPreview from 'components/DocumentPreview/DocumentPreview';
import { PrivateRoute, DashboardRoute } from 'components/Route/PrivateRoute';
import { OrganizationUsers } from 'pages/OrganizationUsers';
import TilesViewPage from 'pages/TilesViewPagePagination';
import WorkspacePage from 'pages/WorkspacePage';
import { UserDashboard } from 'pages/UserDashboard';
import { Redirect, Route, Switch } from 'react-router-dom';
import { stripDomain } from 'utilities/createUrl';

import Collection from './containers/Collection/Loadable';
import AuthPage from './pages/Auth/Auth';

interface Props {
  homepageURI: string;
}

class Routes extends React.Component<Props> {
  renderDefaultRootRedirect = () => {
    return <Redirect to={stripDomain(this.props.homepageURI)} />;
  };

  render() {
    const Node = Collection;

    return (
      <Switch>
        <Route exact path="/" render={this.renderDefaultRootRedirect} />
        <Route exact path="/auth/reset-password" component={AuthPage} />

        <Route exact path="*/applications/" component={Collection} />
        <Route exact path="*/applications/([0-9A-Za-z]+)" component={Node} />
        <Route
          exact
          path="/workspaces/:workspaceID/*/attachments/:fileID"
          component={DocumentPreview}
        />

        <Route exact path="*/batchjobs/" component={Collection} />
        <Route exact path="*/batchjobs/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/bookmarks/" component={Collection} />
        <Route exact path="*/bookmarks/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/cards/" component={Collection} />
        <Route exact path="*/cards/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/conversations/" component={Collection} />
        <Route exact path="*/conversations/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/dashboards/" component={Collection} />
        <Route exact path="*/dashboards/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/dashboardlayouts/" component={Collection} />
        <Route
          exact
          path="*/dashboardlayouts/([0-9A-Za-z]+)"
          component={Node}
        />
        <Route
          exact
          path="/workspaces/:workspaceID/files/:fileID"
          component={DocumentPreview}
        />

        <Route exact path="*/fields/" component={Collection} />
        <Route exact path="*/fields/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/folders/" component={Collection} />
        <Route exact path="*/folders/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/forms/" component={Collection} />
        <Route exact path="*/forms/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/groups/" component={Collection} />
        <Route exact path="*/groups/([0-9A-Za-z]+)" component={Node} />

        <Route exact path="*/instances/" component={Collection} />
        <Route exact path="*/instances/([0-9A-Za-z]+)" component={Node} />

        <Route exact path="*/kpis/" component={Collection} />
        <Route exact path="*/kpis/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/pages/" component={Collection} />
        <Route exact path="*/personalizedviews/" component={Collection} />
        <Route
          exact
          path="*/personalizedviews/([0-9A-Za-z]+)"
          component={Node}
        />
        <Route exact path="*/renderings/" component={Collection} />
        <Route exact path="*/renderings/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/roles/" component={Collection} />
        <Route exact path="*/roles/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/stages/" component={Collection} />
        <Route exact path="*/stages/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/subscriptions/" component={Collection} />
        <Route exact path="*/subscriptions/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/tags/" component={Collection} />
        <Route exact path="*/tags/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/taxonomies/" component={Collection} />
        <Route exact path="*/taxonomies/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/templates/" component={Collection} />
        <Route exact path="*/templates/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/variations/" component={Collection} />
        <Route exact path="*/variations/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/webhooks/" component={Collection} />
        <Route exact path="*/webhooks/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="*/workflows/" component={Collection} />
        <Route exact path="*/workflows/([0-9A-Za-z]+)" component={Node} />
        <Route exact path="/workspaces/" component={TilesViewPage} />
        <DashboardRoute
          exact
          path="/dashboard/"
          component={UserDashboard}
          redirectPath={'/workspaces'}
        />
        <PrivateRoute
          exact
          path="/manage-organization/"
          roles='HubSync Admin'
          component={OrganizationUsers}
          redirectPath={'/workspaces'}
        />
        <Route path="/workspaces/([0-9A-Za-z]+)" component={WorkspacePage} />
        <Route path="/workspaces" component={WorkspacePage} />

        <Redirect to="/" />

      </Switch>
    );
  }
}

export default Routes;
