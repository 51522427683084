import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ModalTextField from 'components/Modals/ModalTextField';
import { NavLink } from 'react-router-dom';
import svgIcons from '../../styles/svgIcons';
import Button, { Variant as ButtonVariants } from 'components/Button';
import authService from '../../services/AuthService';
import IconButton, { Color, Size } from '../../components/IconButton';
import { AppMessages } from '../../constants';

type Inputs = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const { control, handleSubmit, errors, formState } = useForm({ mode: 'onChange' });
  const { isDirty, isValid } = formState;

  const [sendedTo, setSendedTo] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  const handleOnSubmit: SubmitHandler<Inputs> = async (data) => {
    const { email } = data;
    setIsLoading(true);

    try {
      const forgotPasswordRequest = await authService.forgotPasswordRequest(email);
      if (forgotPasswordRequest.status === 200) {
        setSendedTo(email);
      }
    } catch (error) {
      // @TODO: maybe we can show this error in a toast notification
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  const errorMarkup = (
    <div className="auth-email-error-container">
      <svgIcons.Limited /> <span className="auth-email-error">{errors?.email?.message}</span>
    </div>
  );

  return (
    <>
      {sendedTo ? (
        <>
          <div className="auth-welcome-container">
            <h3>Email sent!</h3>
          </div>
          <div className="auth-code-sent">
            {tenantConfig.emailSentIcon? <img src={tenantConfig.emailSentIcon} alt="HubSync" /> : <svgIcons.EmailSent /> }
            <p>If a HubSync account exists for</p>
            <p><span>{sendedTo}</span>,</p>
            <p>an e-mail will be sent with further instructions.</p>
          </div>
        </>
      ) : (
        <>
          <div className="auth-welcome-container">
            <h3>Forgot Password?</h3>
          </div>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="auth-input-container auth-input-container-hide-input-error">
              <label htmlFor="email">Email</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: 'The email is required.' },
                  pattern: { value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: AppMessages.LOGIN_INVALID_EMAIL },
                }}
                render={props =>
                  <ModalTextField
                    value={props.value}
                    onInput={() => {
                      setIsEmailFocused(true);
                    }}
                    onChange={props.onChange}
                    onBlur={() => {
                      setIsEmailFocused(false);
                    }}
                    placeholder="johnsmith@hubsync.com"
                    errorMessage={(errors.email && !isEmailFocused) && errorMarkup}
                    InputProps={{
                      className: 'auth-input-text-field',
                      startAdornment: <IconButton icon={svgIcons.Email} color={Color.DarkGray} size={Size.Large} />,
                    }}
                  />
                }
              />
            </div>
            <div className="auth-action-container">
              <div className={'auth-action-btn'+ (tenantConfig.clientName || '')}>
                <Button
                  type="submit"
                  label="Continue"
                  variant={ButtonVariants.Primary}
                  fullWidth
                  disabled={!isDirty || !isValid || isLoading}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </form>
        </>
      )}
      <div className="auth-sign-in-btn">
        <NavLink to={`/login`}>
          <button>Back to Log In</button>
        </NavLink>
      </div>
    </>
  );
};

export default ForgotPassword;
