import Popover, { PopoverProps } from '@material-ui/core/Popover';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as fromActions from 'data/collections/collections.actions';
import { UpdateFieldPayload } from 'data/collections/collections.reducer';
import { allColumnsNamesSelector } from 'data/collections/collections.selectors';
import { columnsVMSelector } from 'data/collections/view-config/viewConfig.selectors';
import { Column, ColumnVM } from 'types/schema';
import { State as ReduxState } from 'reducers';

import RenameField from '../Fields/RenameField';
import { getUserDefinedFieldKey } from 'utilities/collections';

const defaultPopoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

interface OwnProps {
  anchorEl: HTMLElement | null;
  field: Column;
  onClosePopper(): void;
}

interface DispatchProps {
  updateField(payload: UpdateFieldPayload): void;
}

interface StateProps {
  allColumnsNamesSelector: (ignoreId: string) => string[];
  columnsVMSelector: ColumnVM[];
}

type Props
  = OwnProps
  & DispatchProps
  & StateProps
  ;

interface State {
  fieldName?: string;
  errorMessage?: string;
}

export class AddColumnHeaderModal extends React.PureComponent<Props, State> {
  public state: State = {
    fieldName: this.props.field.name,
  };

  public render(): JSX.Element {
    return (
      <Popover
        id="renameColumn"
        {...defaultPopoverProps}
        open={true}
        anchorEl={this.props.anchorEl}
        onClose={this.closePopper}
      >
        <RenameField
          fieldName={this.state.fieldName}
          errorMessage={this.state.errorMessage}
          onFieldNameChange={this.onFieldNameChange}
          onCancel={this.closePopper}
          onSave={this.onSave}
        />
      </Popover>
    );
  }

  private closePopper = (): void => {
    this.setState({});
    this.props.onClosePopper();
  };

  private onFieldNameChange = (fieldName: string): void => {
    this.setState({ fieldName, errorMessage: undefined });
  };

  private onSave = (): void => {
    if (!this.state.fieldName) {
      this.setState({
        fieldName: undefined,
        errorMessage: 'Please provide a name.',
      });
      return;
    }

    if (this.doesFileNameExists(this.state.fieldName)) {
      this.setState({
        errorMessage: 'Name already exists. Please enter unique name.',
      });
      return;
    }

    const payload: UpdateFieldPayload = {
      fieldId: getUserDefinedFieldKey(this.props.field.id),
      title: this.state.fieldName,
    };

    this.props.updateField(payload);
    this.closePopper();
  };

  private doesFileNameExists = (fileName: string): boolean => {
    const { allColumnsNamesSelector, field } = this.props;
    const columnNames = allColumnsNamesSelector(field.id);
    return columnNames.some(name => name.toLowerCase() === fileName.toLowerCase());
  };
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  allColumnsNamesSelector,
  columnsVMSelector,
});

const mapDispatchToProps: DispatchProps = {
  updateField: fromActions.Actions.updateField,
};

export default connect<StateProps, DispatchProps, OwnProps>(selectors, mapDispatchToProps)(AddColumnHeaderModal);
