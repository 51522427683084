import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { styles as SearchBarStyles } from 'components/SearchBar/SearchBar.style';
import SelfSelectingInput from 'components/SelfSelectingInput';
import * as fromActions from 'data/grid-options/filterModel.actions';
import moment from 'moment';
import {
  RegularFilter,
  FilterType,
  SingleChoiceFilter,
  MultipleChoiceFilter,
  BooleanFilter, DateFilter, NumberFilter, TextFilter,
} from 'types/gridOptions';
import { FieldType } from 'types/response/fieldNode';
import { FilterValue } from 'types/response/query';
import { Column } from 'types/schema';

import Checkbox from '../../../Checkbox';
import { parseValue, precisionToStep } from '../../../NodeFieldData/number/NumberEditor.utils';
import { DatePicker } from '../../../ui/DatePicker';
import ChoiceFilterOptionInput, { ChoiceFilterOptionInputComponent } from './ChoiceFilterOptionInput';
import FilterInput from './FilterInput';


interface OwnProps {
  index: number;
  filter: RegularFilter;
  column: Column;
  isLastFilter: boolean;
  updateFilter: (payload: fromActions.UpdateFilterPayload) => void;
  onlyComponent?: boolean;
  schemaColumns?: {
    [id: string]: Column;
  };
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

export class FilteringOptionInput extends React.PureComponent<Props> {
  private timeout = 0;
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  state = {
    textFilterValue: '',
  };

  public componentDidUpdate = (prevProps: Props): void => {
    if (prevProps.filter.operator !== this.props.filter.operator) {
      setTimeout(() => {
        this.setFocus();
      }, 500);
    }
  };

  public componentDidMount = (): void => {
    if (this.props.filter.type === FilterType.Text) {
      this.setState({
        textFilterValue: this.props.filter.filter,
      });
    }
  };

  public render(): React.ReactNode {
    const { filter, column } = this.props;
    switch (filter.type) {
      case FilterType.Singlechoice:
      case FilterType.Multiplechoice:
        return this.renderChoiceFilterOptionInput(filter);
      case FilterType.Boolean:
        return this.renderCheckBoxValueInput(filter);
      case FilterType.Date:
        return this.renderDateInput(filter);
      case FilterType.Number: {
        // TODO Remove this check against Integer column type

        // when precision attribute is removed from all Integer fields in the backend
        const precision = column.fieldType !== FieldType.Integer && column.precision !== undefined ?
          column.precision :
          0;

        if (column.fieldType === FieldType.Rating) {
          return this.renderRatingInput(filter, precision);
        }

        return this.renderNumberInput(filter, precision);
      }
      case FilterType.Text:
        return this.renderTextInput(filter);
    }
  }

  private setFocus(): void {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  private renderChoiceFilterOptionInput(filter: SingleChoiceFilter | MultipleChoiceFilter): React.ReactNode {
    if (this.props.onlyComponent === true) {
      return <ChoiceFilterOptionInputComponent
        filter={filter}
        accounts={[]}
        isLoading={false}
        schemaColumns={this.props.schemaColumns}
        onChange={(e) => {
          const { value } = e.target;
          const values = Array.isArray(value) ? value : [value];
          this.composeFilterUpdate(values);
        }}
      />;
    }

    return (
      <ChoiceFilterOptionInput
        filter={filter}
        onChange={(e) => {
          const { value } = e.target;
          const values = Array.isArray(value) ? value : [value];
          this.composeFilterUpdate(values);
        }}
      />
    );
  }

  private renderCheckBoxValueInput(filter: BooleanFilter): React.ReactNode {
    const update = this.composeFilterUpdate;

    function onClick(): void {
      update(!filter.filter);
    }

    return (
      <div className={this.props.classes.booleanWrapper}>
        <Checkbox
          selected={filter.filter || undefined}
          onClick={onClick}
        />
      </div>
    );
  }

  private onChangeDate = (format) => (date) => {
    if (date) {
      const formattedDate: string = moment(date).format(format);
      this.composeFilterUpdate(formattedDate);
    }
  };

  private renderDateInput(filter: DateFilter): React.ReactNode {
    const format = filter.dateFormat;
    const eventChangeDate = this.onChangeDate(format);

    return (
      <DatePicker
        placeholder={format}
        value={filter.filter || ''}
        format={format}
        onDayChange={eventChangeDate}
        onInputChange={eventChangeDate}
        component={FilterInput}
        inputProps={{
          fullWidth: true,
          disableUnderline: true,
        }}
      />
    );
  }

  private renderNumberInput(filter: NumberFilter, precision: number): React.ReactNode {
    return (
      <SelfSelectingInput
        autoFocus={this.props.isLastFilter}
        placeholder="Enter number"
        inputRef={this.inputRef}
        className={this.props.classes.inputSmall}
        type="text"
        value={filter.filter === null ? '' : filter.filter}
        step={precisionToStep(precision)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const parsedValue = event.target.value;
          this.composeFilterUpdate(parsedValue);
        }}
      />
    );
  }

  private renderRatingInput(filter: NumberFilter, precision: number): React.ReactNode {
    return (
      <SelfSelectingInput
        autoFocus={this.props.isLastFilter}
        placeholder="Enter number"
        inputRef={this.inputRef}
        className={this.props.classes.inputSmall}
        type="number"
        value={filter.filter === null ? '' : filter.filter}
        step={1}
        min={1}
        max={5}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const parsedValue = parseValue(event.target.value, precision, true);
          if (parsedValue && (parsedValue < 1 || parsedValue > 5)) return;
          this.composeFilterUpdate(parsedValue);
        }}
      />
    );
  }

  private renderTextInput(filter: TextFilter): React.ReactNode {
    return (
      <SelfSelectingInput
        autoFocus={this.props.isLastFilter}
        placeholder="Enter text"
        inputRef={this.inputRef}
        className={this.props.classes.inputSmall}
        value={this.state.textFilterValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.composeFilterUpdate(event.target.value)}
      />
    );
  }

  private composeFilterUpdate = (value: FilterValue | FilterValue[] | null) => {
    let timer = 0;
    if (this.props.filter.type === FilterType.Text) {
      this.setState({ textFilterValue: value });
      timer = 500;
    }

    const { index, updateFilter } = this.props;
    const payload: fromActions.UpdateFilterPayload = {
      index,
      value,
    };

    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      updateFilter(payload);
    }, timer);
  };
}

export type ClassKey = 'booleanWrapper' | 'inputSmall';

const styles = withStyles<ClassKey>({
  booleanWrapper: { width: 25, margin: '0 auto' },
  inputSmall: { ...SearchBarStyles.inputSmall, padding: '8px 10px' },
});

export default styles(FilteringOptionInput);
