import styled from 'styled-components';

const WithEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1px;
`;

export {
  WithEllipsis,
};
