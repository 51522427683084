import Describer from './Describers/Describer';
import WorkspaceActivityDescriber from './Describers/WorkspaceActivityDescriber';
import DatabaseActivityDescriber from './Describers/DatabaseActivityDescriber';
import TaskdbActivityDescriber from './Describers/TaskdbActivityDescriber';
import ItemActivityDescriber from './Describers/ItemActivityDescriber';
import TaskActivityDescriber from './Describers/TaskActivityDescriber';
import FieldActivityDescriber from './Describers/FieldActivityDescriber';
import MessageActivityDescriber from './Describers/MessageActivityDescriber';
import ChecklistActivityDescriber from './Describers/ChecklistActivityDescriber';
import FileActivityDescriber from './Describers/FileActivityDescriber';
import ViewActivityDescriber from './Describers/ViewActivityDescriber';

export enum ActivityType {
  Workspace= 'Workspace',
  Database= 'Database',
  Databasetasks= 'Databasetasks',
  Itemdatabases= 'Itemdatabases',
  Itemtasks = 'Itemtasks',
  Field= 'Field',
  Message= 'Message',
  Checklist= 'Checklist',
  File= 'File',
  View= 'View',
}

const typeDescriber = {
  [ActivityType.Workspace]: WorkspaceActivityDescriber,
  [ActivityType.Database]: DatabaseActivityDescriber,
  [ActivityType.Databasetasks]: TaskdbActivityDescriber,
  [ActivityType.Itemdatabases]: ItemActivityDescriber,
  [ActivityType.Itemtasks]: TaskActivityDescriber,
  [ActivityType.Field]: FieldActivityDescriber,
  [ActivityType.Message]: MessageActivityDescriber,
  [ActivityType.Checklist]: ChecklistActivityDescriber,
  [ActivityType.File]: FileActivityDescriber,
  [ActivityType.View]: ViewActivityDescriber,
};

export function getDescriber(model) {
  return typeDescriber[model] || Describer;
}
