import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'root' | 'section' | 'backButton' | 'favoriteButton' | 'date' | 'time';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white',
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
    height: '48px',
    paddingRight: '16px',
    zIndex: 1,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    margin: '0 5px',
  },
  favoriteButton: {
    marginLeft: '8px',
  },
  date: {
    marginLeft: '35px',
  },
  time: {
    marginLeft: '9px',
  },
};
