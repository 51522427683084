// @ts-nocheck
import { getViewNameOrError, ViewNameOrError } from 'components/Modals/common/viewNameOrError';
import { CommonNode } from 'types/response';
import { DatabaseNode } from 'types/response/databaseNode';
import { DocumentMinimalType, DocumentNode } from 'types/response/documentNode';
import { FieldType } from 'types/response/fieldNode';
import { ViewNode } from 'types/response/viewNode';
import { WorkspaceNode } from 'types/response/workspaceNode';
import { Breadcrumb, Choice, CollectionTypes, CollectionTypeWithTitle, Column, NodeWithTitle } from 'types/schema';

import AgGridApi from '../../components/AgGrid/AgGridApi';
import { OpenWorkSpaceInfoModal, RenameWorkspaceWithMeta } from './actions';

// Overall State

export type State
  = NoModal
  | NewCalendarViewModal
  | NewGridViewModal
  | NewKanbanViewModal
  | NodeViewModal
  | EditViewModal
  | DeleteAttachmentModal
  | DeleteFolderModal
  | DeleteNodeModal
  | DeleteItemsModal
  | DeleteGridColumnModal
  | DeleteViewModal
  | DeleteSheetModal
  | DuplicateDatabaseModal
  | RenameBreadcrumbModal
  | RenameCollectionItemModal
  | RenameDocumentModal
  | RenameKanbanColumnModal
  | DeleteKanbanColumnModal
  | CreateCollectionItemModal
  | MoveCopyDocumentModal
  | InfoModal
  | SetNodeData
  | OpenAccountModal
  | CancelEnvelopeModal
  | ResendEnvelopeModal
  | OpenLockPermission
  | OpenWorkSpaceInfoModal
  | RenameWorkspaceWithMeta
  ;

// Type Constants

export enum ModalType {
  ACCOUNT = 'ACCOUNT',
  CANCEL_ENVELOPE = 'CANCEL_ENVELOPE',
  CANCEL_ENVELOPE_PERMISSION = 'CANCEL_ENVELOPE_PERMISSION',
  CREATE_COLLECTION_ITEM = 'CREATE_COLLECTION_ITEM',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  DELETE_FOLDER = 'DELETE_FOLDER',
  DELETE_GRID_COLUMN = 'DELETE_GRID_COLUMN',
  DELETE_ITEMS = 'DELETE_ITEMS',
  DELETE_KANBAN_COLUMN = 'DELETE_KANBAN_COLUMN',
  DELETE_NODE = 'DELETE_NODE',
  DELETE_SHEET = 'DELETE_SHEET',
  DELETE_VIEW = 'DELETE_VIEW',
  DUPLICATE_DATABASE = 'DUPLICATE_DATABASE',
  EDIT_VIEW = 'EDIT_VIEW',
  INFO = 'INFO',
  LOCK_PERMISSION = 'LOCK_PERMISSION',
  MOVE_COPY_DOCUMENT = 'MOVE_COPY_DOCUMENT',
  NEW_CALENDAR_VIEW_MODAL = 'NEW_CALENDAR_VIEW_MODAL',
  NEW_GRID_VIEW_MODAL = 'NEW_GRID_VIEW_MODAL',
  NEW_KANBAN_VIEW_MODAL = 'NEW_KANBAN_VIEW_MODAL',
  NODE_VIEW = 'NODE_VIEW',
  NO_MODAL = 'NO_MODAL',
  ORGANIZER_REQUIRES_WORKSPACE = 'ORGANIZER_REQUIRES_WORKSPACE',
  RENAME_BREADCRUMB = 'RENAME_BREADCRUMB',
  RENAME_COLLECTION_ITEM = 'RENAME_COLLECTION_ITEM',
  RENAME_DOCUMENT = 'RENAME_DOCUMENT',
  RENAME_KANBAN_COLUMN = 'RENAME_KANBAN_COLUMN',
  RESEND_ENVELOPE = 'RESEND_ENVELOPE',
  RESEND_ENVELOPE_PERMISSION = 'RESEND_ENVELOPE_PERMISSION',
  SET_NODE_DATA = 'modals/state/SET_NODE_DATA',
  OPEN_WORKSPACE_INFO_MODAL = 'OPEN_WORKSPACE_INFO_MODAL',
  RENAME_WORKSPACE_WITH_META = 'RENAME_WORKSPACE_WITH_META',
}

// Interfaces

interface OpenAccountModal {
  type: ModalType.ACCOUNT;
  tab?: string;
}

export interface NoModal {
  type: ModalType.NO_MODAL;
}

interface NewCalendarViewModal {
  type: ModalType.NEW_CALENDAR_VIEW_MODAL;
  existingViewNames: string[];
  viewNameOrError: ViewNameOrError;
  allFields: Column[];
  selectedTitleField?: Column;
  titleFields: Column[];
  selectedDateField?: Column;
  dateFields: Column[];
}

interface NewGridViewModal {
  type: ModalType.NEW_GRID_VIEW_MODAL;
  apiURI: string;
  existingViewNames: string[];
  viewNameOrError: ViewNameOrError;
  isPrivate?: boolean;
}

interface NewKanbanViewModal {
  type: ModalType.NEW_KANBAN_VIEW_MODAL;
  existingViewNames: string[];
  viewNameOrError: ViewNameOrError;
  allFields: Column[];
  selectedTitleField?: Column;
  titleFields: Column[];
  selectedCategoryField?: Column;
  categoryFields: Column[];
  isPrivate?: boolean;
}

interface NodeViewModal {
  type: ModalType.NODE_VIEW;
  node: Partial<CommonNode>;
  windowTitle?: string;
  enableSwitcher?: boolean;
  isPrivate?: boolean;
}

interface EditViewModal {
  type: ModalType.EDIT_VIEW;
  view: ViewNode;
}

export interface DeleteAttachmentModal {
  type: ModalType.DELETE_ATTACHMENT;
  uri: string;
  id: string;
  otherModal: State;
}

interface DeleteFolderModal {
  type: ModalType.DELETE_FOLDER;
  breadcrumb: Breadcrumb;
}

interface DeleteNodeModal {
  type: ModalType.DELETE_NODE;
  node: NodeWithTitle;
  collectionType: CollectionTypeWithTitle;
}

interface DeleteItemsModal {
  type: ModalType.DELETE_ITEMS;
  ids: string[];
  collectionType: CollectionTypes;
  agGridApi?: AgGridApi<CommonNode>;
}

interface DeleteGridColumnModal {
  type: ModalType.DELETE_GRID_COLUMN;
  columnId: string;
}

interface CancelEnvelopePermissionModal {
  type: ModalType.CANCEL_ENVELOPE_PERMISSION;
}

interface CancelEnvelopeModal {
  type: ModalType.CANCEL_ENVELOPE;
  envelopeId: string;
}

interface ResendEnvelopePermissionModal {
  type: ModalType.RESEND_ENVELOPE_PERMISSION;
}

interface ResendEnvelopeModal {
  type: ModalType.RESEND_ENVELOPE;
  envelopeId: string;
  signerEmail: string;
}

interface DeleteViewModal {
  type: ModalType.DELETE_VIEW;
  viewId: string;
}

interface DeleteSheetModal {
  type: ModalType.DELETE_SHEET;
  sheetId: string;
}

interface DuplicateDatabaseModal {
  type: ModalType.DUPLICATE_DATABASE;
  database: DatabaseNode;
  workspace: WorkspaceNode;
  availableWorkspaces: WorkspaceNode[];
  shouldDuplicateRecords: boolean;
  shouldDuplicateComments: boolean;
  collectionType: CollectionTypes;
}

interface RenameBreadcrumbModal {
  type: ModalType.RENAME_BREADCRUMB;
  breadcrumb: Breadcrumb;
}

interface RenameCollectionItemModal {
  type: ModalType.RENAME_COLLECTION_ITEM;
  collectionType: CollectionTypeWithTitle;
  node: NodeWithTitle;
}

interface RenameDocumentModal {
  type: ModalType.RENAME_DOCUMENT;
  document: DocumentNode;
}

interface RenameKanbanColumnModal {
  type: ModalType.RENAME_KANBAN_COLUMN;
  categoryFieldId: string;
  choiceId: string;
  choices: Record<string, Choice>;
}

interface DeleteKanbanColumnModal {
  type: ModalType.DELETE_KANBAN_COLUMN;
  categoryField: Column;
  choiceId: string;
}

interface CreateCollectionItemModal {
  type: ModalType.CREATE_COLLECTION_ITEM;
  collectionType: CollectionTypes;
}

interface MoveCopyDocumentModal {
  type: ModalType.MOVE_COPY_DOCUMENT;
  selectedItems: DocumentMinimalType[];
}

interface InfoModal {
  type: ModalType.INFO;
  title?: string;
  message?: string;
  confirmLabel?: string;
}

interface LockPermissionModal {
  type: ModalType.LOCK_PERMISSION;
}

// Constructors

export function noModal(): NoModal {
  return { type: ModalType.NO_MODAL };
}

export function newCalendarViewModal(
  existingViewNames: string[],
  newViewName: string,
  fields: Column[],
): NewCalendarViewModal {
  const { titleFields, dateFields } = getCalendarFields(fields);

  return {
    type: ModalType.NEW_CALENDAR_VIEW_MODAL,
    existingViewNames,
    viewNameOrError: getViewNameOrError(existingViewNames, newViewName),
    allFields: fields,
    selectedTitleField: titleFields[0],
    titleFields,
    selectedDateField: dateFields[0],
    dateFields,
  };
}

export interface SetNodeData {
  type: ModalType.SET_NODE_DATA;
  node: Node;
}


export function newGridViewModal(apiURI: string, existingViewNames: string[], newViewName: string): NewGridViewModal {
  const viewNameOrError = getViewNameOrError(existingViewNames, newViewName);

  return { type: ModalType.NEW_GRID_VIEW_MODAL, apiURI, existingViewNames, viewNameOrError };
}

export function newKanbanViewModal(
  existingViewNames: string[],
  newViewName: string,
  fields: Column[],
): NewKanbanViewModal {
  const { titleFields, categoryFields } = getKanbanFields(fields);

  return {
    type: ModalType.NEW_KANBAN_VIEW_MODAL,
    existingViewNames,
    viewNameOrError: getViewNameOrError(existingViewNames, newViewName),
    allFields: fields,
    selectedTitleField: titleFields[0],
    titleFields,
    selectedCategoryField: categoryFields[0],
    categoryFields,
  };
}

export const nodeViewModal = (node: Partial<CommonNode>, windowTitle?: string, enableSwitcher?: boolean): NodeViewModal =>
  ({ type: ModalType.NODE_VIEW, node, windowTitle, enableSwitcher });

export const editViewModal = (view: ViewNode): EditViewModal => ({ type: ModalType.EDIT_VIEW, view });

export function deleteAttachmentModal(id: string, uri: string, otherModal: State): DeleteAttachmentModal {
  return { type: ModalType.DELETE_ATTACHMENT, id, uri, otherModal };
}

export function deleteFolderModal(breadcrumb: Breadcrumb): DeleteFolderModal {
  return { type: ModalType.DELETE_FOLDER, breadcrumb };
}

export function deleteNodeModal(node: NodeWithTitle, collectionType: CollectionTypeWithTitle): DeleteNodeModal {
  return { type: ModalType.DELETE_NODE, node, collectionType };
}

export function deleteItemsModal(ids: string[], collectionType: CollectionTypes, agGridApi?: any): DeleteItemsModal {
  return { type: ModalType.DELETE_ITEMS, ids, collectionType, agGridApi };
}

export function deleteGridColumnModal(columnId: string): DeleteGridColumnModal {
  return { type: ModalType.DELETE_GRID_COLUMN, columnId };
}

export function cancelEnvelopeModal(envelopeId: string): CancelEnvelopeModal {
  return { type: ModalType.CANCEL_ENVELOPE, envelopeId };
}

export function cancelEnvelopePermissionModal(): CancelEnvelopePermissionModal {
  return { type: ModalType.CANCEL_ENVELOPE_PERMISSION };
}

export function resendEnvelopeModal(envelopeId: string, signerEmail: string): ResendEnvelopeModal {
  return { type: ModalType.RESEND_ENVELOPE, envelopeId, signerEmail };
}

export function resendEnvelopePermissionModal(): ResendEnvelopePermissionModal {
  return { type: ModalType.RESEND_ENVELOPE_PERMISSION };
}

export function deleteViewModal(viewId: string): DeleteViewModal {
  return { type: ModalType.DELETE_VIEW, viewId };
}

export function deleteSheetModal(sheetId: string): DeleteSheetModal {
  return { type: ModalType.DELETE_SHEET, sheetId };
}

export function lockPermisionModal(): LockPermissionModal {
  return { type: ModalType.LOCK_PERMISSION };
}

export function organizerRequiresModal(ids: string[]) {
  return { type: ModalType.ORGANIZER_REQUIRES_WORKSPACE, ids };
}

export function openWorkspaceInfoModal(workspaceInfo: any): OpenWorkspaceInfoModal {
  return { type: ModalType.OPEN_WORKSPACE_INFO_MODAL, workspaceInfo };
}

export function renameWorkspaceWithMeta(currentNode: NodeWithTitle): RenameWorkspaceWithMeta {
  return { type: ModalType.RENAME_WORKSPACE_WITH_META, currentNode };
}

export function openDuplicateDatabase(
  database: DatabaseNode,
  workspace: WorkspaceNode,
  availableWorkspaces: WorkspaceNode[],
  collectionType: CollectionTypes,
): DuplicateDatabaseModal {
  return {
    type: ModalType.DUPLICATE_DATABASE,
    database,
    workspace,
    availableWorkspaces,
    shouldDuplicateRecords: true,
    shouldDuplicateComments: false,
    collectionType,
  };
}

export function openRenameBreadcrumbModal(breadcrumb: Breadcrumb): RenameBreadcrumbModal {
  return { type: ModalType.RENAME_BREADCRUMB, breadcrumb };
}

export function renameCollectionItemModal(
  collectionType: CollectionTypeWithTitle,
  node: NodeWithTitle,
): RenameCollectionItemModal {
  return { type: ModalType.RENAME_COLLECTION_ITEM, collectionType, node };
}

export function renameDocumentModal(document: DocumentNode): RenameDocumentModal {
  return ({
    type: ModalType.RENAME_DOCUMENT,
    document,
  });
}

export function renameKanbanColumnModal(
  categoryFieldId: string,
  choiceId: string,
  choices: Record<string, Choice>,
): RenameKanbanColumnModal {
  return { type: ModalType.RENAME_KANBAN_COLUMN, categoryFieldId, choiceId, choices };
}

export function deleteKanbanColumnModal(categoryField: Column, choiceId: string): DeleteKanbanColumnModal {
  return { type: ModalType.DELETE_KANBAN_COLUMN, categoryField, choiceId };
}

export function createCollectionItemModal(collectionType: CollectionTypes): CreateCollectionItemModal {
  return { type: ModalType.CREATE_COLLECTION_ITEM, collectionType };
}

export function moveCopyDocumentModal(selectedItems: DocumentMinimalType[]): MoveCopyDocumentModal {
  return ({ type: ModalType.MOVE_COPY_DOCUMENT, selectedItems });
}

export function infoModal(title?: string, message?: string, confirmLabel?: string): InfoModal {
  return ({ type: ModalType.INFO, title, message, confirmLabel });
}

export function openAccountModal(tab?: string): OpenAccountModal {
  return ({ type: ModalType.ACCOUNT, tab: tab });
}

// Switchers

export function newViewName(state: State, viewName: string): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.ACCOUNT:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: newViewName(state.otherModal, viewName) };
    }

    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL: {
      const viewNameOrError = getViewNameOrError(state.existingViewNames, viewName);
      return { ...state, viewNameOrError };
    }
  }
}

export function changeSelectedWorkspace(workspace: WorkspaceNode, state: State): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: changeSelectedWorkspace(workspace, state.otherModal) };
    }

    case ModalType.DUPLICATE_DATABASE: {
      return { ...state, workspace };
    }
  }
}

export function addNewField(state: State, field: Column): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;


    case ModalType.NODE_VIEW: {
      return {
        ...state,
        node: {
          ...state.node,
          fields: {
            ...state.node.fields,
            [field._id]: field?.default,
          },
        },
      };
    }

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: addNewField(state.otherModal, field) };
    }

    case ModalType.NEW_CALENDAR_VIEW_MODAL: {
      return addFieldToNewCalendarViewModal(state, field);
    }

    case ModalType.NEW_KANBAN_VIEW_MODAL: {
      return addFieldToNewKanbanViewModal(state, field);
    }
  }
}

export function changeShouldDuplicateRecords(state: State): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: changeShouldDuplicateRecords(state.otherModal) };
    }

    case ModalType.DUPLICATE_DATABASE: {
      return { ...state, shouldDuplicateComments: state.shouldDuplicateRecords? false: state.shouldDuplicateComments, shouldDuplicateRecords: !state.shouldDuplicateRecords };
    }
  }
}

export function changeShouldDuplicateComments(state: State): State {
  switch (state.type) {
    case ModalType.DUPLICATE_DATABASE: {
      return { ...state, shouldDuplicateComments: !state.shouldDuplicateComments };
    }
  }
}

export function selectCategoryField(state: State, selectedCategoryField: Column): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: selectCategoryField(state.otherModal, selectedCategoryField) };
    }

    case ModalType.NEW_KANBAN_VIEW_MODAL: {
      return { ...state, selectedCategoryField };
    }
  }
}

export function selectDateField(state: State, selectedDateField: Column): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: selectDateField(state.otherModal, selectedDateField) };
    }

    case ModalType.NEW_CALENDAR_VIEW_MODAL: {
      return { ...state, selectedDateField };
    }
  }
}

export function selectTitleField(state: State, selectedTitleField: Column): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return { ...state, otherModal: selectTitleField(state.otherModal, selectedTitleField) };
    }

    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL: {
      return { ...state, selectedTitleField };
    }
  }
}

export function returnToPreviousModal(state: State): State {
  switch (state.type) {
    case ModalType.NO_MODAL:
    case ModalType.NEW_CALENDAR_VIEW_MODAL:
    case ModalType.NEW_GRID_VIEW_MODAL:
    case ModalType.NEW_KANBAN_VIEW_MODAL:
    case ModalType.NODE_VIEW:
    case ModalType.EDIT_VIEW:
    case ModalType.DELETE_FOLDER:
    case ModalType.DELETE_NODE:
    case ModalType.DELETE_ITEMS:
    case ModalType.DELETE_GRID_COLUMN:
    case ModalType.DELETE_VIEW:
    case ModalType.DELETE_SHEET:
    case ModalType.DUPLICATE_DATABASE:
    case ModalType.RENAME_BREADCRUMB:
    case ModalType.RENAME_COLLECTION_ITEM:
    case ModalType.RENAME_DOCUMENT:
    case ModalType.RENAME_KANBAN_COLUMN:
    case ModalType.CREATE_COLLECTION_ITEM:
    case ModalType.MOVE_COPY_DOCUMENT:
    case ModalType.DELETE_KANBAN_COLUMN:
    case ModalType.INFO:
      return state;

    case ModalType.DELETE_ATTACHMENT: {
      return state.otherModal;
    }
  }
}

// Helpers

interface CalendarFields {
  titleFields: Column[];
  dateFields: Column[];
}

const baseCalendarFields: CalendarFields = { titleFields: [], dateFields: [] };

function getCalendarFields(fields: Column[]): CalendarFields {
  return fields.reduce((set, field) => {
    switch (field.fieldType) {
      case FieldType.Singlelineoftext: {
        return { ...set, titleFields: [...set.titleFields, field] };
      }

      case FieldType.Date:
      case FieldType.DateTime: {
        return { ...set, dateFields: [...set.dateFields, field] };
      }

      default: {
        return set;
      }
    }
  }, baseCalendarFields);
}

function addFieldToNewCalendarViewModal(state: NewCalendarViewModal, field: Column): NewCalendarViewModal {
  const titleField = (field.fieldType === FieldType.Singlelineoftext) ? field : undefined;
  const dateField = [FieldType.Date, FieldType.DateTime].includes(field.fieldType) ? field : undefined;

  return {
    ...state,
    allFields: [...state.allFields, field],
    selectedTitleField: titleField || state.selectedTitleField,
    titleFields: (titleField) ? [...state.titleFields, titleField] : state.titleFields,
    selectedDateField: dateField || state.selectedDateField,
    dateFields: (dateField) ? [...state.dateFields, dateField] : state.dateFields,
  };
}

interface KanbanFields {
  titleFields: Column[];
  categoryFields: Column[];
}

const baseKanbanFields: KanbanFields = { titleFields: [], categoryFields: [] };

function getKanbanFields(fields: Column[]): KanbanFields {
  return fields.reduce((set, field) => {
    if (field.allowMultiple || ['createdBy', 'modifiedBy'].includes(field.id)) {
      return set;
    }

    switch (field.fieldType) {
      case FieldType.Singlelineoftext: {
        return { ...set, titleFields: [...set.titleFields, field] };
      }

      case FieldType.Account:
      case FieldType.Singlechoice: {
        return { ...set, categoryFields: [...set.categoryFields, field] };
      }

      default: {
        return set;
      }
    }
  }, baseKanbanFields);
}

function addFieldToNewKanbanViewModal(state: NewKanbanViewModal, field: Column): NewKanbanViewModal {
  const titleField = (field.fieldType === FieldType.Singlelineoftext) ? field : undefined;
  const categoryField = (!field.allowMultiple && [FieldType.Account, FieldType.Singlechoice].includes(field.fieldType)) ? field : undefined;

  return {
    ...state,
    allFields: [...state.allFields, field],
    selectedTitleField: titleField || state.selectedTitleField,
    titleFields: (titleField) ? [...state.titleFields, titleField] : state.titleFields,
    selectedCategoryField: categoryField || state.selectedCategoryField,
    categoryFields: (categoryField) ? [...state.categoryFields, categoryField] : state.categoryFields,
  };
}

export function SetNodeData(node: Node, state: State): State {
  switch (state.type) {
    case ModalType.SET_NODE_DATA: {
      return { ...state, node };
    }
    default: {
      return { ...state };
    }
  }
}
