import { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import ActionButtons, { OwnProps as ButtonsProps } from './ActionButtons';
import { FieldData, FieldType } from './data';
import FieldNameInput from './FieldNameInput';
import SelectFieldType, { SelectionState } from './SelectFieldType';
import styles, { ClassKey } from './styles';

interface OwnProps extends ButtonsProps {
  fieldName?: string;
  errorMessage?: string;
  fieldTypes: FieldType[];
  selectedFieldData: FieldData;
  selectionState: SelectionState;
  allowMultipleCollaborators?: boolean;
  fieldTypeFilterQuery?: string;
  onFieldNameChange(newFieldName: string): void;
  onDataUpdate(data: FieldData): void;
  onFieldTypeFilterQueryChange(query: string): void;
  onFieldTypeSelected(fieldType: FieldType): void;
  onToggleSelectFieldType(): void;
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

function CreateNewField(props: Props): JSX.Element {
  const {
    allowMultipleCollaborators,
    classes,
    fieldTypes,
    fieldTypeFilterQuery,
    selectedFieldData,
    selectionState,
    fieldName,
    errorMessage,
  } = props;

  return (
    <div className={classes.wrapper}>
      <FieldNameInput
        fieldName={fieldName}
        errorMessage={errorMessage}
        onChange={props.onFieldNameChange}
      />
      <SelectFieldType
        fieldTypes={fieldTypes}
        fieldTypeFilterQuery={fieldTypeFilterQuery}
        selectedFieldData={selectedFieldData}
        selectionState={selectionState}
        allowMultipleCollaborators={allowMultipleCollaborators}
        onDataUpdate={props.onDataUpdate}
        onFieldTypeFilterQueryChange={props.onFieldTypeFilterQueryChange}
        onFieldTypeSelected={props.onFieldTypeSelected}
        onToggleSelectFieldType={props.onToggleSelectFieldType}
        isCreate={true}
      />
      <ActionButtons
        onCancel={props.onCancel}
        onSave={props.onSave}
      />
    </div>
  );
}

export default styles(CreateNewField);
