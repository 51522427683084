import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { profileMenuStyle } from 'components/HeaderBar/ProfileMenu.style';
import Typography, { Variant } from 'components/Typography';
import { Role } from 'data/accounts/types';
import { openAccountModal } from 'data/modals/actions';
import { User } from 'data/users/users.types';
import { getOrigin, getTenantConfig } from 'env';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { State as ReduxState } from 'reducers';
import svgIcons from 'styles/svgIcons';
import { isSSO } from 'utilities/common';

const tenantConfig = getTenantConfig() as any;

interface OwnProps {
  onClose(): void;
}

interface DispatchProps {
  openAccountModal(tab?: string): void;
}

interface StateProps {
  user?: User;
}

type Props = OwnProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof profileMenuStyle>;

function ProfileMenu(props: Props): JSX.Element {
  const isTenantAdmin = props.user?.roles?.some(
    (role) => (role as Role).name === 'HubSync Admin'
  );
  return (
    <div>
      <div className={props.classes.wrapper}>
        {tenantConfig.enabledFeatures.accountSettings && <Account {...props} />}
        {tenantConfig.enabledFeatures.hubsyncAdministration && isTenantAdmin && (
          <HubsyncAdministration {...props} />
        )}
        {tenantConfig.enabledFeatures.helpAndSupport && (
          <HelpAndSupport {...props} />
        )}
        <NeedHelp {...props} />
      </div>
      <div className={props.classes.wrapper} style={{ borderTop: '0.03rem solid #E3E3E3' }}>
        <SignOut {...props} />
      </div>
    </div>
  );
}

function Account(props: Props): JSX.Element {
  return (
    <React.Fragment>
      <span className={props.classes.subheader}>
        <Subheader title="Account settings" />
      </span>
      <AccountSettings {...props} />
    </React.Fragment>
  );
}

function AccountSettings(props: Props): JSX.Element {
  const { user } = props;
  const permissions: Array<string> | undefined = user?.permissions;
  const GIcon = svgIcons.Settings;
  const NIcon = svgIcons.HeaderNotifications;
  const { icon, menuItem, itemWrapper } = props.classes;
  return (
    <div>
      <ListItem
        id="profileMenuGeneral"
        className={itemWrapper}
        onClick={() => openAccount(props)}
        button
      >
        <GIcon className={icon} />
        <span className={menuItem}>General</span>
      </ListItem>

      { permissions && permissions.includes('notifications:list') && <ListItem
        id="profileMenuNotifications"
        className={itemWrapper}
        onClick={() => openAccount(props, 'notifications')}
        button
      >
        <NIcon className={icon} />
        <span className={menuItem}>Notifications</span>
      </ListItem>}
    </div>
  );
}

function openAccount(props: Props, tab?: string): void {
  props.openAccountModal(tab);
  props.onClose();
}

function HubsyncAdministration(props: Props): JSX.Element {
  return (
    <div>
      <span className={props.classes.subheader}>
        <Subheader title="HubSync" />
      </span>
      <Administration {...props} />
    </div>
  );
}

function Administration(props: Props): JSX.Element {
  const { icon, menuItem, itemWrapper } = props.classes;
  const OrgIcon = svgIcons.Organization;
  const history = useHistory();

  // TODO: close menu when navigating to manage organization
  return (
    <ListItem
      id="profileMenuAdministration"
      className={itemWrapper}
      onClick={() => history.push('/manage-organization')}
      button
    >
      <OrgIcon className={icon} />
      <span className={menuItem}>Administration</span>
    </ListItem>
  );
}

function HelpAndSupport(props: Props): JSX.Element {
  return (
    <div style={{ marginBottom: 16 }}>
      <span className={props.classes.subheader}>
        <Subheader title="Help &amp; Support" />
      </span>
      <HelpCenterAndSupport {...props} />
    </div>
  );
}

function HelpCenterAndSupport(props: Props): JSX.Element {
  const { icon, menuItem, itemWrapper } = props.classes;
  const HelpIcon = svgIcons.InfoFilled;
  const SupportIcon = svgIcons.Support;
  return (
    <>
      <a href="mailto:e-sign@bakertilly.com">
        <ListItem id="profileMenuHelpCenter" className={itemWrapper}>
          <HelpIcon className={icon} />
          <span className={menuItem}>Help Center</span>
        </ListItem>
      </a>

      <a href="mailto:e-sign@bakertilly.com">
        <ListItem id="profileMenuSupportRequest" className={itemWrapper}>
          <SupportIcon className={icon} />
          <span className={menuItem}>Support Request</span>
        </ListItem>
      </a>
    </>
  );
}

function NeedHelp(props: Props): JSX.Element {
  const { icon, menuItem, itemWrapper } = props.classes;
  const NeedHelpIcon = svgIcons.NeedHelp;
  const needHelpLink = window.appStore.getState().app.tenant.apiEndpoints.zendeskTargetURL;
  return (
    <ListItem
      id="profileMenuNeedHelp"
      className={itemWrapper}
      component="a"
      href={needHelpLink}
      target='_blank'
      rel="noopener noreferrer"
    >
      <NeedHelpIcon className={icon} />
      <span className={menuItem}>Need Help?</span>
    </ListItem>
  );
}

function SignOut(props: Props): JSX.Element {
  const userEmail = props.user?.email;
  const { icon, menuItem, itemWrapper } = props.classes;
  const LogOutIcon = svgIcons.LogOut;
  const isSSOUser = isSSO(window.appStore?.getState().app?.tenant, userEmail || '');

  return (
    <ListItem
      id="profileMenuSignOut"
      className={itemWrapper}
      component="a"
      href={`${getOrigin()}/logout?reason=click${isSSOUser ? '&ssoLogout' : ''}`}
    >
      <LogOutIcon className={icon} />
      <span className={menuItem}>Sign Out</span>
    </ListItem>
  );
}

interface SubheaderProps {
  title: string;
}

function Subheader(props: SubheaderProps): JSX.Element {
  return <Typography variant={Variant.ListSubheader}>{props.title}</Typography>;
}

const mapDispatchToProps: DispatchProps = {
  openAccountModal,
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    user: state.users.user,
  };
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileMenuStyle),
);

export default enhance(ProfileMenu);
