import * as React from 'react';
import { useEffect } from 'react';

import ModalTextField from 'components/Modals/ModalTextField';
import keys from 'keycode-js';

import GridTextEditor from '../components/GridTextEditor';
import wrapCellEditor from '../components/wrapCellEditor';
import { FieldEditorProps } from '../Fields.types';
import { parseValue, precisionToStep } from './NumberEditor.utils';

interface NumberEditorProps extends FieldEditorProps {
  allowNegative: boolean;
  precision: number;
}

const getInputProps = ({ allowNegative, precision }) => {
  const inputProps: any = {
    step: precisionToStep(precision),
  };

  if (!allowNegative) {
    inputProps.min = 0;
  }

  return inputProps;
};

const NumberEditor = React.forwardRef(
  (
    props: NumberEditorProps,
    ref: React.RefObject<HTMLInputElement>,
  ): JSX.Element => {
    const {
      allowNegative,
      precision,
      value,
      onChange,
      eGridCell,
      onStopEditing,
      onBlur,
      keyPress = 0,
      lock,
      permissions,
    } = props;
    const inputProps = getInputProps({ allowNegative, precision });
    const canEdit = permissions?.includes('items:update');
    const handleChange = (e) => {
      const { value } = e.target;
      if (canEdit) onChange(parseValue(value, precision, allowNegative) || '');
    };
    const handleKeyDown = (event) => {
      const { key } = event;
      const ignoreValues = ['e', 'E'];
      const ignoreDot = '.';
      if (ignoreValues.includes(key)) {
        event.preventDefault();
      }
      if (precision === 0 && key === ignoreDot) {
        event.preventDefault();
      }
    };

    const parsedValue = parseValue(value, precision, allowNegative);
    if (eGridCell) {
      useEffect(() => {
        if (props.charPress && /^\d+$/.test(props.charPress)) {
          if (canEdit) onChange(props.charPress);
        }

        if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
          if (canEdit) onChange('');
        }
      }, []);

      return (
        <GridTextEditor
          id={`txt${props.id}`}
          inputRef={ref}
          {...inputProps}
          value={parsedValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type="number"
          onBlur={onStopEditing}
        />
      );
    } else {
      return (
        <ModalTextField
          {...inputProps}
          id={`txt${props.id}`}
          value={parsedValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          autoSelect
          type="number"
          disabled={props.readOnly || !canEdit}
          lock={lock}
        />
      );
    }
  },
);

export default wrapCellEditor()(NumberEditor);
