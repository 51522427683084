import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { State } from 'reducers';
import { getRequest } from '../../utilities/httpRequests';
import { handleError } from '../../utilities/saga';
import { normalizeURL } from 'utilities/format';
import { fetchNode, FetchNodeRequest, FetchNodeSuccessPayload } from 'data/nodes/actions';
import { getCollectionTypeFromUrl } from 'utilities/createUrl';
import { CollectionTypes } from 'types/schema';
import { getType } from 'typesafe-actions';

function* getNode(action: FetchNodeRequest) {
  // Debounce logic to prevent concurrent calls
  yield delay(100);

  const state: State = yield select();

  try {
    const result = yield call(getRequest, normalizeURL(action.payload), state);
    const collectionType = getCollectionTypeFromUrl(action.payload);
    if (collectionType) {
      const successPayload: FetchNodeSuccessPayload = collectionType === CollectionTypes.documents
        ? {
          collectionType,
          node: result.body.node,
        }
        : {
          collectionType,
          node: result.body.node,
        };
      yield put(fetchNode.success(successPayload));
    } else if (result.body && result.body.node && typeof result.body.node.body === 'string') {
      yield put(fetchNode.success({
        collectionType: CollectionTypes.pages,
        node: result.body.node.body,
      }));
    } else {
      yield put(fetchNode.failure({ name: 'Error', message: `Unknown collection type: ${collectionType}` }));
    }
  } catch (e) {
    yield call(handleError, e);
    yield put(fetchNode.failure(e));
  }
}

export default function* nodeSagas() {
  yield takeLatest(getType(fetchNode.request), getNode);
}
