import { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import svgIcons from 'styles/svgIcons';
import { useHistory } from 'react-router-dom';
// import { AccountNode } from 'types/response/accountNode';
import { TenantNode } from 'types/response/tenantNode';

import Logo from './Logo';
import styles, { ClassKey } from './styles';
import UserMenu from './UserMenu';
import Notifications from 'components/Notifications/Notifications';
import { getTenantConfig } from 'env';
const tenantConfig = getTenantConfig() as any;

export interface OwnProps {
  tenant: TenantNode;
  // user?: AccountNode;
  user: any;
  isNavigationMenuOpen?: boolean;
  anchorEl?: HTMLElement;
  notificationsAnchorEl?: HTMLElement;
  onToggleNavigationMenu(): void;
  onOpenUserMenu(anchorEl: HTMLElement): void;
  onCloseUserMenu(): void;
  onOpenNotificationsMenu(notificationsAnchorEl: HTMLElement): void;
  onCloseNotificationsMenu(): void;
}

type Props = OwnProps & WithStyles<ClassKey>;

const isDashboardEnable = (tenantConfig, tenant: TenantNode, user): boolean => {
  const enableFeature = tenantConfig.enabledFeatures.dashboard ? true : false;
  const trustedDomains = tenant.authProvider?.identityProviders?.length
    ? tenant.authProvider?.identityProviders[0].trustedDomains
    : [];
  let enable = false;
  if (trustedDomains && trustedDomains.length === 0) {
    enable = true;
  } else {
    const emailDomain = user.email.split('@')[1];
    const matchDomain = trustedDomains.find((domain) => domain === emailDomain);
    enable = enableFeature && !!matchDomain;
  }

  return enable;
};

function HeaderBar(props: Props): JSX.Element {
  const {
    anchorEl,
    notificationsAnchorEl,
    classes,
    isNavigationMenuOpen,
    tenant,
    user,
  } = props;
  const history = useHistory();
  const iconWrapper = cn({
    [classes.iconWrapper]: true,
    [classes.iconWrapperPushed]: isNavigationMenuOpen,
  });

  const goToDashboard = (): any => {
    const path = `/dashboard`;
    history.push(path);
  };

  return (
    <div className={`${classes.wrapper} app-header-bar`}>
      <div className={classes.group}>
        <div
          id="btnNavigationMenu"
          className={iconWrapper}
          onClick={props.onToggleNavigationMenu}
        >
          <svgIcons.HeaderMenu className={classes.icon} />
        </div>
        <Logo logoUrl={tenant.theme && tenant.theme.logoURL} />
      </div>
      <div className={classes.group}>
        {tenantConfig.enabledFeatures.search && (
          <div className={classes.iconWrapper}>
            <svgIcons.HeaderSearch className={classes.icon} />
          </div>
        )}
        {user && user.id && (
          <React.Fragment>
            {isDashboardEnable(tenantConfig, tenant, user) && (
              <div
                id="btnDashboard"
                className={classes.dashboardIconWrapper}
                onClick={goToDashboard}
              >
                <svgIcons.Dashboard className={classes.dashboardIcon} />
              </div>
            )}
            {tenantConfig.enabledFeatures.notifications && user.permissions.includes('notifications:list') && (
              <Notifications
                anchorEl={notificationsAnchorEl}
                onOpen={props.onOpenNotificationsMenu}
                onClose={props.onCloseNotificationsMenu}
              />
            )}
            <UserMenu
              user={user}
              anchorEl={anchorEl}
              onOpen={props.onOpenUserMenu}
              onClose={props.onCloseUserMenu}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default styles(HeaderBar);
