import * as _ from 'lodash';
import moment from 'moment';
import { CommonNode, FieldValue, Timestamp } from 'types/response';
import {
  BooleanFilter,
  ColumnsMapById,
  DateFilter,
  FilterModel,
  FilterType,
  MultipleChoiceFilter,
  NumberFilter,
  Operator,
  SingleChoiceFilter,
  TextFilter,
} from 'types/gridOptions';
import { DEFAULT_DATE_FORMAT } from 'components/Fields/data';
import { isStringArray } from 'components/NodeFieldData/filters/helpers';
import { FieldType } from 'types/response/fieldNode';
import { getNodeFieldValue, fieldTypeWithMarkup } from 'utilities/collections';
import { removeHTMLTag } from 'utilities/format';
import { Column } from 'types/schema';
import { AccountNode } from 'types/response/accountNode';
import { AttachmentsState } from 'data/attachments/reducer';
import { AttachmentNode } from 'types/response/attachmentNode';
import { isDocumentNode } from 'types/response/documentNode';
import { getFileSizeValue } from 'components/NodeFieldData/Fields.valueGetters';
import { getStringValue } from 'components/NodeFieldData/Fields.valueFormatters';

export const filterNodes = (
  nodes: CommonNode[],
  filterModel: FilterModel,
  columns: ColumnsMapById,
  visibleFieldIds: string[],
  accountsById: Record<string, AccountNode> | null,
  attachments: AttachmentsState,
): CommonNode[] =>
  nodes.filter(doesFilterPass(filterModel, columns, visibleFieldIds, accountsById || {}, attachments));

const doesFilterPass = (
  filterModel: FilterModel,
  columns: ColumnsMapById,
  visibleFieldIds: string[],
  accountsById: Record<string, AccountNode>,
  attachments: AttachmentsState,
) => (node: CommonNode): boolean => {
  const attachmentsById = attachments.byItemId[node.id] && attachments.byItemId[node.id].byId;

  // Check regular filters
  for (const filter of filterModel.regularFilters) {
    const value: FieldValue | undefined = getValueFromNode(node, filter.columnId);

    switch (filter.type) {
      case FilterType.Text:
        if (!doesTextFilterPass(value, filter, columns[filter.columnId], attachmentsById)) return false;
        break;
      case FilterType.Number:
        if (!doesNumberFilterPass(value, filter)) return false;
        break;
      case FilterType.Boolean:
        if (!doesBooleanFilterPass(value, filter)) return false;
        break;
      case FilterType.Date:
        if (!doesDateFilterPass(value, filter)) return false;
        break;
      case FilterType.Singlechoice:
        if (!doesSingleChoiceFilterPass(value, filter)) return false;
        break;
      case FilterType.Multiplechoice:
        if (!doesMultipleChoiceFilterPass(value, filter)) return false;
        break;
    }
  }

  // Check search filters
  for (const colId of Object.keys(filterModel.searchFilters)) {
    const value: FieldValue | undefined = getValueFromNode(node, colId);

    if (columns[colId] && !doesSearchFilterPass(
      value,
      filterModel.searchFilters[colId],
      columns[colId],
      accountsById,
      attachmentsById,
    )) {
      return false;
    }
  }

  // Check quicksearch filter
  for (const token of filterModel.quickSearch.split(/\s/).filter(token => token)) {
    let tokenPasses = false;

    for (const colId of visibleFieldIds) {
      const value: FieldValue | undefined = getValueFromNode(node, colId);

      if (columns[colId] && doesSearchFilterPass(
        value,
        token,
        columns[colId],
        accountsById,
        attachmentsById,
      )) {
        tokenPasses = true;
        break;
      }
    }

    if (!tokenPasses) return false;
  }

  return true;
};

export const getValueFromNode = (node: CommonNode, columnId: string): FieldValue | undefined =>
  isDocumentNode(node) && columnId === 'size'
    ? getFileSizeValue(node)
    : getNodeFieldValue(node, columnId);

const doesTextFilterPass = (
  value: FieldValue | undefined,
  filter: TextFilter,
  column: Column,
  attachmentsById: Record<string, AttachmentNode>,
): boolean => {
  const dataValue = typeof value !== 'string'
    ? getStringValue(value || '', column, {}, attachmentsById).toLowerCase()
    : value.toLowerCase();
  // @TODO filter values with markup not using regexp, it's an expensive task
  const rawText = fieldTypeWithMarkup(column.fieldType)
    ? removeHTMLTag(dataValue)
    : dataValue;
  const filterValue = filter.filter.toLowerCase();
  return textFilterComparator(filter, rawText, filterValue);
};

export const textFilterComparator = (filter, dataValue, filterValue) => {
  switch (filter.operator) {
    case Operator.Equals:
      return dataValue === filterValue;
    case Operator.NotEquals:
      return dataValue !== filterValue;
    case Operator.StartsWith:
      return dataValue.startsWith(filterValue);
    case Operator.EndsWith:
      return dataValue.endsWith(filterValue);
    case Operator.Contains:
      return dataValue.includes(filterValue);
    case Operator.NotContains:
      return !dataValue.includes(filterValue);
    case Operator.Empty:
      return !dataValue;
    case Operator.NotEmpty:
      return !!dataValue;
    case Operator.GreaterThan:
    case Operator.GreaterThanOrEquals:
    case Operator.HasAllOf:
    case Operator.HasAnyOf:
    case Operator.HasNoneOf:
    case Operator.InRange:
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.IsExactly:
    case Operator.IsNoneOf:
    case Operator.IsNot:
    case Operator.LessThan:
    case Operator.LessThanOrEquals:
    case Operator.NotInRange:
      return true;
  }
};

const doesNumberFilterPass = (value: FieldValue | undefined, filter: NumberFilter): boolean => {
  if (filter.filter === null) return true;

  switch (filter.operator) {
    case Operator.Equals:
      return value === filter.filter;
    case Operator.NotEquals:
      return value !== filter.filter;
    case Operator.LessThan:
      return !!value && value < filter.filter;
    case Operator.LessThanOrEquals:
      return !!value && value <= filter.filter;
    case Operator.GreaterThan:
      return !!value && value > filter.filter;
    case Operator.GreaterThanOrEquals:
      return !!value && value >= filter.filter;
    case Operator.Contains:
    case Operator.Empty:
    case Operator.EndsWith:
    case Operator.HasAllOf:
    case Operator.HasAnyOf:
    case Operator.HasNoneOf:
    case Operator.InRange:
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.IsExactly:
    case Operator.IsNoneOf:
    case Operator.IsNot:
    case Operator.NotContains:
    case Operator.NotEmpty:
    case Operator.NotInRange:
    case Operator.StartsWith:
      return true;
  }
};

const doesBooleanFilterPass = (value: FieldValue | undefined, filter: BooleanFilter): boolean => {
  switch (filter.operator) {
    case Operator.Equals:
      return filter.filter ? !!value : !value; // Empty values must match false
    case Operator.Contains:
    case Operator.Empty:
    case Operator.EndsWith:
    case Operator.GreaterThan:
    case Operator.GreaterThanOrEquals:
    case Operator.HasAllOf:
    case Operator.HasAnyOf:
    case Operator.HasNoneOf:
    case Operator.InRange:
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.IsExactly:
    case Operator.IsNoneOf:
    case Operator.IsNot:
    case Operator.LessThan:
    case Operator.LessThanOrEquals:
    case Operator.NotContains:
    case Operator.NotEmpty:
    case Operator.NotEquals:
    case Operator.NotInRange:
    case Operator.StartsWith:
      return true;
  }
};

const doesDateFilterPass = (value: FieldValue | undefined, filter: DateFilter): boolean => {
  if (filter.filter === null) return true;

  if (value && typeof value === 'object' && (value as Timestamp).timestamp) {
    value = new Date((value as Timestamp).timestamp).getTime();
  }

  if (typeof value !== 'number') return false;

  const format = filter.dateFormat || DEFAULT_DATE_FORMAT;
  const filteringDate = moment(filter.filter, format, true);
  const valueDate = moment(value);

  if (!filteringDate.isValid()) return true;
  return dateFilterComparator(filter, valueDate, filteringDate);
};

export const dateFilterComparator = (filter, valueDate, filteringDate) => {
  switch (filter.operator) {
    case Operator.Equals:
      return valueDate.isSame(filteringDate, 'day');
    case Operator.NotEquals:
      return !valueDate.isSame(filteringDate, 'day');
    case Operator.LessThan:
      return valueDate.isBefore(filteringDate, 'day');
    case Operator.GreaterThan:
      return valueDate.isAfter(filteringDate, 'day');
    case Operator.Contains:
    case Operator.Empty:
    case Operator.EndsWith:
    case Operator.GreaterThanOrEquals:
    case Operator.HasAllOf:
    case Operator.HasAnyOf:
    case Operator.HasNoneOf:
    case Operator.InRange:
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.IsExactly:
    case Operator.IsNoneOf:
    case Operator.IsNot:
    case Operator.LessThanOrEquals:
    case Operator.NotContains:
    case Operator.NotEmpty:
    case Operator.NotInRange:
    case Operator.StartsWith:
      return true;
  }
};

const doesSingleChoiceFilterPass = (value: FieldValue | undefined, filter: SingleChoiceFilter): boolean => {
  return singleChoiceComparator(value, filter);
};

export const singleChoiceComparator = (value: FieldValue | undefined, filter: SingleChoiceFilter): boolean => {
  switch (filter.operator) {
    case Operator.Is:
      return filter.filter.length === 0 || value === filter.filter[0];
    case Operator.IsNot:
      return filter.filter.length === 0 || value !== filter.filter[0];
    case Operator.IsAnyOf:
      return typeof value === 'string' && filter.filter.indexOf(value) !== -1;
    case Operator.IsNoneOf:
      return typeof value !== 'string' || filter.filter.indexOf(value) === -1;
    case Operator.Empty:
      return !value;
    case Operator.NotEmpty:
      return !!value;
    case Operator.Contains:
    case Operator.EndsWith:
    case Operator.Equals:
    case Operator.GreaterThan:
    case Operator.GreaterThanOrEquals:
    case Operator.HasAllOf:
    case Operator.HasAnyOf:
    case Operator.HasNoneOf:
    case Operator.InRange:
    case Operator.IsExactly:
    case Operator.LessThan:
    case Operator.LessThanOrEquals:
    case Operator.NotContains:
    case Operator.NotEquals:
    case Operator.NotInRange:
    case Operator.StartsWith:
      return true;
  }
};

const doesMultipleChoiceFilterPass = (value: FieldValue | undefined, filter: MultipleChoiceFilter): boolean => {
  const dataValue = value || [];

  if (!isStringArray(dataValue)) return false;

  switch (filter.operator) {
    case Operator.IsExactly:
      return filter.filter.length === 0 || _.isEqual(dataValue, filter.filter);
    case Operator.HasAllOf:
      return filter.filter.every(item => dataValue.includes(item));
    case Operator.HasAnyOf:
      return filter.filter.some(item => dataValue.includes(item));
    case Operator.HasNoneOf:
      return !filter.filter.some(item => dataValue.includes(item));
    case Operator.Empty:
      return dataValue.length === 0;
    case Operator.NotEmpty:
      return dataValue.length > 0;
    case Operator.Equals:
    case Operator.Contains:
    case Operator.EndsWith:
    case Operator.GreaterThan:
    case Operator.GreaterThanOrEquals:
    case Operator.InRange:
    case Operator.Is:
    case Operator.IsAnyOf:
    case Operator.IsNoneOf:
    case Operator.IsNot:
    case Operator.LessThan:
    case Operator.LessThanOrEquals:
    case Operator.NotContains:
    case Operator.NotEquals:
    case Operator.NotInRange:
    case Operator.StartsWith:
      return true;
  }
};

const doesSearchFilterPass = (
  value: FieldValue | undefined,
  filter: string,
  column: Column,
  accountsById: Record<string, AccountNode>,
  attachmentsById: Record<string, AttachmentNode>,
): boolean => {
  const filterValue = column.fieldType === FieldType.Currency && column.currencySymbol
    ? filter.replace(column.currencySymbol, '')
    : filter;
  const fieldValue = value || '';
  return getStringValue(
    // @TODO filter values with markup not using regexp, it's an expensive task
    fieldTypeWithMarkup(column.fieldType)
      ? removeHTMLTag(fieldValue as string)
      : fieldValue,
    column,
    accountsById,
    attachmentsById,
  ).toLowerCase().includes(filterValue.toLowerCase());
};
