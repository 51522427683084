import * as request from 'superagent';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import createTree from 'pages/Files/Utils/createTree';
import { getFileExtension } from 'utilities/files';
import { normalizeURL } from 'utilities/format';
import { ToastType } from 'components/Toast/types';
import { toast } from 'react-toastify';
import { renderToast } from 'components/Toast/NotificationToast';
import { AppMessages } from '../../../constants';

let AuthorizationToken = '';

export async function getFileLinkWithId(fileLinkId: string) {
  const url = `/public/file-links/${fileLinkId}?api_version=2`;
  const response = await request
    .get(normalizeURL(url));
  return _.get(response, 'body');
}

export async function getFileLinkToken(fileLinkId: string, password?: string) {
  const url = `/public/file-links/${fileLinkId}/token?api_version=2`;
  const body = password ? { password } : {};
  const response = await request
    .post(normalizeURL(url))
    .send(body);
  return _.get(response, 'body');
}

export function setToken(token: string) {
  AuthorizationToken = token;
}

export function getSharedFilesToken(): string | undefined {
  return AuthorizationToken;
}

export let files: any[] = [];
let cachedWorkspaceID = '';

export async function getSharedFile(workspaceID, fileID) {
  const url = `/public/workspaces/${workspaceID}/shared-files/get/${fileID}?api_version=2&preview=show`;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${getSharedFilesToken()}`,
  };
  const response = await request
    .get(normalizeURL(url))
    .set(headers);
  return _.get(response, 'body');
}

export async function fetchFiles(workspaceID: string, parentID?: string) {
  if (files.length === 0 || cachedWorkspaceID !== workspaceID) {
    const url = `/public/workspaces/${workspaceID}/shared-files?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const response = await request
      .get(normalizeURL(url))
      .set(headers);
    files = _.get(response, 'body');

    cachedWorkspaceID = workspaceID;
  }

  return filterFiles(parentID);
}

export function mapFileResponse(_workspaceID: string | null) {
  files = files.map(file => {
    if (file['isFolder']) {
      file['meta'] = {
        ...file['meta'],
        size: files.filter((filterFile) => {
          return filterFile.parentID === file._id;
        }).length,
      };
    }

    return file;
  });
}

export async function downloadFiles(files: any[], workspaceID, sharedFilesID?: string) {
  const url = `/public/workspaces/${workspaceID}/shared-files/download?api_version=2`;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${getSharedFilesToken()}`,
  };
  const response = await request
    .post(normalizeURL(url))
    .set(headers)
    .send(files);
  return _.get(response, 'body');
}

export async function uploadFiles(files: any, workspaceID: string, parentID: string, onFileUploadComplete, sharedFilesID?: string): Promise<any[]> {
  const filesCreated: any[] = [];
  for (let i = 0; i < files.length; i += 1 ) {
    // UPLOAD FILE
    const file = files[i];
    const upload = await uploadFile(file, workspaceID);

    // CREATE FILE REFERENCE
    const fileReference = {
      title: file.name,
      fileType: file.type,
      fileExtension: getFileExtension(file.name),
      parentID,
      fileKey: upload?.fileName,
      meta: { size: file.size },
    };
    const createFileResponseData = await createFile(workspaceID, fileReference);
    filesCreated.push(createFileResponseData);
    onFileUploadComplete({ ...createFileResponseData, uniqueKey: file.uniqueKey });
  }

  const filesId = filesCreated.map(file => file._id);

  await sendUploadEmailAlert(filesId, workspaceID, sharedFilesID);

  return filesCreated;
}

export async function uploadFile(file: any, workspaceID) {
  try {
    // 1. SIGN THE URL
    const fileType = file['type'];
    const key = `${workspaceID}/files/${uuidv4()}-${file['name']}`;
    const url = `/public/workspaces/${workspaceID}/shared-files/sign-url?api_version=2&contentType=${encodeURIComponent(fileType)}&key=${encodeURIComponent(key)}`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const response = await request
      .get(normalizeURL(url))
      .set(headers);
    const { signedUrl, fileName } = _.get(response, 'body.data');

    // 2. UPLOAD THE FILE
    await fetch(
      new Request(signedUrl, {
        method: 'PUT',
        body: file,
        headers: new Headers({
          'Content-Type': fileType,
        }),
      }),
    );
    return { fileName };
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}

export async function sendUploadEmailAlert(files: string[], workspaceID: string, sharedFilesID?: string) {
  const url = `public/workspaces/${workspaceID}/shared-files/notify_upload?api_version=2`;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${getSharedFilesToken()}`,
  };
  await request
    .post(normalizeURL(url))
    .set(headers)
    .send({ files, sharedFilesID });
}

export async function createFile(workspaceID, fileReference) {
  try {
    const url = `/public/workspaces/${workspaceID}/shared-files?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const createFileResponse = await request
      .post(normalizeURL(url))
      .set(headers)
      .send(fileReference);
    const fileResponse = _.get(createFileResponse, 'body');
    files.push(fileResponse);
    return fileResponse;
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}

export async function patchFile(workspaceID, id, values) {
  try {
    const url = `/public/workspaces/${workspaceID}/shared-files/${id}?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const patchFileResponse = await request
      .patch(normalizeURL(url))
      .set(headers)
      .send(values);
    const fileResponse = _.get(patchFileResponse, 'body');
    // @ts-ignore
    files = files.map(file => (file._id === id ? fileResponse : file));
    return fileResponse;
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}

export async function deleteFiles(workspaceID, ids) {
  try {
    const url = `/public/workspaces/${workspaceID}/shared-files/bulk-delete?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    await request
      .post(normalizeURL(url))
      .set(headers)
      .send(ids);

    const map = ids.reduce((acm, id) => {
      acm[id] = true;
      return acm;
    }, {});
    // @ts-ignore
    files = files.filter(file => !map[file._id]);
    return files;
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}

export function filterFolders() {
  // @ts-ignore
  const list = files.filter(file => file.isFolder);
  return [{
    _id: '',
    title: 'All Files',
    nodes: createTree(_.cloneDeep(list)),
    uri: '',
  }];
}

export function findParentDirectory(parentID: string) {
  const list = files.find(file => file._id === parentID);
  return list || {
    title: 'All Files',
  };
}

export function findItem(id: string) {
  return files?.find(file => file._id === id);
}

export function filterFiles(parentID?: string) {
  mapFileResponse(null);
  // @ts-ignore
  let compare = (file) => !file.parentID;
  if (parentID) {
    compare = (file) => file.parentID === parentID;
  }
  return _.sortBy(files.filter(compare), ['isFolder', function(o) {
    return o.title && o.title.toLowerCase();
  }]);
}


export async function copyFiles(workspaceID, parentID, values) {
  try {
    const url = `/public/workspaces/${workspaceID}/shared-files/copy?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const copyFilesResponse = await request
      .post(normalizeURL(url))
      .set(headers)
      .send({ files: values, parentID });
    const filesResponse = _.get(copyFilesResponse, 'body');
    files.push(...filesResponse);
    return files;
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}

export async function moveFiles(workspaceID, data) {
  try {
    const url = `/public/workspaces/${workspaceID}/shared-files/move?api_version=2`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${getSharedFilesToken()}`,
    };
    const copyFilesResponse = await request
      .post(normalizeURL(url))
      .set(headers)
      .send(data);
    const filesResponse = _.get(copyFilesResponse, 'body');
    // @ts-ignore
    files = files.map(file => {
      return filesResponse.find(f => f._id === file._id) || file;
    });

    mapFileResponse(null);
  } catch (error) {
    handleSharedFilesServiceError(error);
  }
}


interface ToastError { message: string; buttonLabel?: string; onButtonClick?: () => void }

export function handleSharedFilesServiceError(err: any) {
  let type: ToastType = 'error';
  let toastError: ToastError = { message: '' };
  const hasHandledError = !!err?.response?.body?.message;
  if (!hasHandledError) {
    toastError = { buttonLabel: 'Refresh', onButtonClick: () => window.location.reload(), message: AppMessages.GENERIC_SHARED_FILES_ERROR_MESSAGE };
  } else {
    toastError = { message: err?.response?.body?.message };
    if (err?.response?.statusCode === 409) {
      type = 'warn';
    }
    if (err?.response?.statusCode === 401) {
      toastError = { buttonLabel: 'Refresh', onButtonClick: () => window.location.reload(), message: AppMessages.FILES_SESSION_ERROR_MESSAGE };
    }
  }

  toast((props) => renderToast({ closeToast: props.closeToast, type, ...toastError }), {
    bodyClassName: 'notification-toastify-body',
    progressClassName: 'notification-toastify-body-progress',
    className: `notification-toastify nt-warn`,
    hideProgressBar: true,
    closeButton: false,
  });

  // Logging error in console
  console.error(err);
  throw err;
}
