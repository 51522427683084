import { get, post, patch, deleteReq } from 'utilities/httpRequests';
import { getApiV2Url } from 'env';

/**
 * Generates URL for all users by workspace
 *
 * @param workspaceID
 * @returns endpoint URL for users
 */
const getWorkspaceUserEndpoint = (workspaceID) =>
  `${getApiV2Url()}/workspaces/${workspaceID}/users`;

/** */
export const fetchAllUsers = async () => {
  const endpoint = `${getApiV2Url()}/users/list`;
  const response = await get(endpoint);
  return response;
};

/** */
export const fetchUsersByWorkspace = async (workspaceID) => {
  const endpoint = getWorkspaceUserEndpoint(workspaceID);
  const response = await get(endpoint);
  return response;
};

/** */
export const patchUser = async (userIds, role, workspaceID) => {
  const url = `${getWorkspaceUserEndpoint(workspaceID)}/role`;
  const data = { userIds, role };
  const response = await patch({ url, data });

  return response.body;
};

export const removeWorkspaceUser = async (userID, workspaceID) => {
  const url = `${getWorkspaceUserEndpoint(workspaceID)}/${userID}`;
  const response = await deleteReq( url );
  return response.body;
};

export const removeWorkspaceUsers = async (userIds: string[], workspaceID: string) => {
  const url = `${getWorkspaceUserEndpoint(workspaceID)}`;
  const response = await post({ url, data: { userIds } });
  return response.body;
};

/** */
export const sendInviteToUsers = async (role, email, message, workspaceID) => {
  const data = {
    workspaceID,
    role,
    email,
    message,
    appUrl: window.origin,
    tenant: window.location.host,
    displayName: `${email} (pending)`,
    firstName: 'Guest',
    lastName: 'User',
  };
  const url = `${getWorkspaceUserEndpoint(workspaceID)}/invite`;
  const response = await post({ url, data });

  return response;
};

export const resendInviteUser = async (userId, email, workspaceID) => {
  const data = {
    email,
  };
  const url = `${getWorkspaceUserEndpoint(workspaceID)}/${userId}/resend-invitation`;
  const response = await post({ url, data });

  return response;
};
