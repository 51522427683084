import produce from 'immer';
import { getType, ActionType } from 'typesafe-actions';
import { uuidv4 } from 'utilities/common';

import { actions } from './fileUpload.actions';
import {
  FileUploadState,
  ByNameState,
  FileUploadProcessState,
} from './fileUpload.types';

export const initialState: FileUploadState = {
  byFileName: {},
  attachmentsToUpload: [],
  attachmentsUploadInProgress: [],
  attachmentsUploaded: [],
  uploadInProgress: false,
};

const initialByNameState: ByNameState = {
  state: FileUploadProcessState.SendingMetadata,
};

type Action = ActionType<typeof actions>;

const byName = (state = initialByNameState, action: Action) => {
  switch (action.type) {
    case getType(actions.startUploadFile): {
      state.state = FileUploadProcessState.SendingMetadata;
      state.progress = {
        total: 0,
        loaded: 0,
      };
      return state;
    }
    case getType(actions.progress): {
      state.progress = {
        total: action.payload.total,
        loaded: action.payload.loaded,
      };
      return state;
    }
    case getType(actions.sendFileBlob.request): {
      state.state = FileUploadProcessState.SendingBlob;
      state.progress = {
        total: 0,
        loaded: 0,
      };
      return state;
    }
    default: {
      return state;
    }
  }
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.startUploadFile):
    case getType(actions.progress):
    case getType(actions.sendFileBlob.request): {
      const { title } = action.payload;

      return produce(state, draft => {
        draft.byFileName[title] = byName({ ...draft.byFileName[title] }, action);
      });
    }
    case getType(actions.finishUploadFile): {
      const { title } = action.payload;

      return produce(state, draft => {
        delete draft.byFileName[title];
      });
    }
    case getType(actions.tempPersistAttachments): {
      return produce(state, draft => {
        if (action.payload.length === 0) {
          draft.attachmentsToUpload = [];
        } else {
          // @ts-ignore
          draft.attachmentsToUpload = [...draft.attachmentsToUpload, ...action.payload];
        }
      });
    }
    case getType(actions.uploadAttachments): {
      const files = action.payload.map(payload => {
        const file = payload.file;
        file['uniqueKey'] = `${uuidv4()}-${file['name']}`;
        return file;
      });
      return produce(state, draft => {
        // @ts-ignore
        draft.attachmentsUploadInProgress = files;
        draft.attachmentsUploaded = [];
        draft.uploadInProgress = true;
      });
    }
    case getType(actions.attachmentUploadSuccess): {
      return produce(state, draft => {
        const { file } = action.payload;
        // @ts-ignore
        draft.attachmentsUploaded = [...draft.attachmentsUploaded, file];
      });
    }
    case getType(actions.finishUploadAttachments): {
      return produce(state, draft => {
        draft.uploadInProgress = !(draft.attachmentsUploadInProgress.length == draft.attachmentsUploaded.length);
      });
    }
    case getType(actions.deleteTempAttachment): {
      return produce(state, draft => {
        const elementToBeDeleted = draft.attachmentsToUpload.filter(x => x['colId'] === action.payload.colId)[action.payload.index];
        const indexOfElementToBeDeleted = draft.attachmentsToUpload.findIndex(x => x['file']['name'] === elementToBeDeleted['file']['name']);
        if (indexOfElementToBeDeleted > -1) {
          draft.attachmentsToUpload.splice(indexOfElementToBeDeleted, 1);
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
