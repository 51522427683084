import * as React from 'react';
import ErrorContent from 'components/ErrorBoundary/ErrorContent';

interface Props {
  children: React.ReactNode;
  url: string;
}

interface State {
  hasError: boolean;
}

export default class RouteErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.url !== prevProps.url) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContent/>
      );
    }
    return this.props.children;
  }
}
