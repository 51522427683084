import * as React from 'react';
import { ActivityFeedGroup as ActivityFeedGroupType } from 'data/activityFeed/types';
import { ActivityItemsWrapper, Heading, HeadingText, HeadingTile, Wrapper } from './ActivityFeedGroup.style';
import { ActivityFeedItem } from './ActivityFeedItem';

interface Props {
  id: number;
  group: ActivityFeedGroupType;
}

export default class ActivityFeedGroup extends React.PureComponent<Props> {
  render() {
    const { group: { activities, eventDateStr }, id } = this.props;
    return (
      <Wrapper id={`activityFeedGroup_${id}`}>
        <Heading>
          <HeadingTile/>
          <HeadingText id={`activityFeedGroup_${id}_headingText`}>{eventDateStr}</HeadingText>
        </Heading>
        <ActivityItemsWrapper>
          {activities && activities.map((activity, index) => {
            return (
              <ActivityFeedItem id={`activityFeedItem_${id}_${index}`} key={activity._id} item={activity} />
            );
          })
          }
        </ActivityItemsWrapper>
      </Wrapper>
    );
  }
}
