import * as qs from 'qs';
import { getURLTokens } from 'utilities/authentication';

export const createBlobSrc = (uri: string) => {
  const query = qs.stringify({
    blob: '',
    ...getURLTokens(),
  });
  return `${uri}?${query}`;
};

const getScreenResolution = (width?: number) => {
  const requestedWidth = (width ? width : window.screen.width) * window.devicePixelRatio;
  // if width is requested create a box with equal width and height
  const requestedHeight = width ? requestedWidth : window.screen.height * window.devicePixelRatio;
  return `@${requestedWidth}x${requestedHeight}`;
};

export const createPreviewSrc = (docUri: string, page: number, width?: number) => {
  return createBlobSrc(`${docUri}/previews/.${page}/resolutions/${getScreenResolution(width)}`);
};

// zoom in percents
export const maxZoom = 500;
export const minZoom = 5;
const zoomStep = 10;
const zoomStepBelowTen = 1;

export const zoomIn = (zoom: number) => {
  const step = zoom < zoomStep ? zoomStepBelowTen : zoomStep;
  return zoom + step;
};
export const zoomOut = (zoom: number) => {
  const step = zoom <= zoomStep ? zoomStepBelowTen : zoomStep;
  return zoom - step;
};
