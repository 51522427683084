import * as React from 'react';
import { PopoverProps, PopoverOrigin } from '@material-ui/core/Popover';
import ContextMenuBody from './ContextMenuBody';

import { Popover } from '../Popover';
import { SvgIcon } from 'types/common';
import IconButton, { Color, Size } from 'components/IconButton';
import svgIcons from 'styles/svgIcons';

export interface MenuItem {
  id: string;
  title: string;
  action: () => void;
  Icon: SvgIcon;
  keepMenuOpenAfterClick?: boolean;
}

interface Props {
  id?: string;
  className?: string;
  menuItems: MenuItem[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  size?: Size;
  onToggleMenu?: (isShowing: boolean) => void;
}

interface State {
  showMenu: boolean;
}

class ContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
    this.props.onToggleMenu?.(!showMenu);
  };

  handleClick = () => {
    this.toggleMenu();
  };

  public render = (): React.ReactNode => {
    const { showMenu } = this.state;
    const { id, menuItems, anchorOrigin, transformOrigin, size = Size.Large } = this.props;
    const popoverProps: Partial<PopoverProps> = {
      anchorOrigin: anchorOrigin || { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: transformOrigin || { vertical: 'top', horizontal: 'left' },
    };

    if (!menuItems.length) {
      return null;
    }

    return (
      <>
        <Popover
          id={`${id}_popover`}
          content={
            <ContextMenuBody
              menuItems={menuItems}
              close={this.toggleMenu}
            />
          }
          isOpen={showMenu}
          onClose={this.toggleMenu}
          popoverProps={popoverProps}
        >
          <IconButton
            id={`${id}_popoverButton`}
            className={this.props.className}
            icon={svgIcons.MenuDots}
            color={Color.DarkGray}
            size={size}
            selected={showMenu}
            onClick={this.handleClick}
          />
        </Popover>
      </>
    );
  };
}

export default ContextMenu;
