import * as React from 'react';

import Switch from '@material-ui/core/Switch';
import LoadingIndicator from 'components/LoadingIndicator';
import { CARD_TOTAL_WIDTH } from 'components/TilesView/TileItem.style';
import TilesList from 'components/TilesView/TilesList';
import TilesViewBar from 'components/TilesView/TilesViewBar';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import { fetchCollectionRequest } from 'data/collections/collections.actions.new';
import {
  getCurrentNodeForTileView,
  getLoadingForTileView,
} from 'data/collections/collections.selectors';
import { getAllPaginatedData } from 'data/workspaces/workspaces.selectors';
import { setCurrentDatabase } from 'data/databases/databases.actions';
import LoadingState from 'data/LoadingState';
import { openCreateCollectionItemModal } from 'data/modals/actions';
import { setCurrentTaskDatabase } from 'data/taskdbs/taskdbs.actions';
import { setCurrentWorkspace } from 'data/workspaces/workspaces.actions';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { isLoadingLocking } from 'data/ui/loading/loading.seletors';
import { isEqual, sortBy } from 'lodash/fp';
import {
  PARENT_MARGINS,
  Wrapper,
  PaginationContainer,
  DisplayInfo,
  NotFound,
  NotFoundText,
  SwitchContainer,
} from 'pages/TilesViewPage.style';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { CollectionTypes, NodeWithTitle } from 'types/schema';
import {
  getPluralCollectionName,
  getSingularCollectionName,
} from 'utilities/collections';
import {
  createWorkspaceUrlPaginated,
  createBookmarksUrl,
  getCollectionTypeFromUrl,
} from 'utilities/createUrl';
import loadingAction from 'data/ui/loading/actions';
import UltimatePaginationMaterialUi from '../components/Pagination/Pagination';
import { PaginatedData } from 'types/response/workspaceNode';
import { AppMessages } from '../constants';
import { bookmarkBeingSaved } from 'data/bookmarks/bookmarks.selectors';

interface StateProps {
  paginatedData: PaginatedData;
  isLoading: boolean;
  loadingState: LoadingState;
  current: string;
  permissions: string[];
  appPermissions: string[];
  bookmarkBeingSaved: boolean;
}

interface DispatchProps {
  fetchCollection: typeof fetchCollectionRequest;
  setCurrentWorkspace: typeof setCurrentWorkspace;
  setCurrentDatabase: typeof setCurrentDatabase;
  setCurrentTaskDatabase: typeof setCurrentTaskDatabase;
  openCreateCollectionItemModal: typeof openCreateCollectionItemModal;
  endLockingLoader: () => void;
}

interface InjectedProps {
  collectionType?: CollectionTypes;
}

interface NodeBundle {
  favorites: NodeWithTitle[];
  nonFavorites: NodeWithTitle[];
}

interface State {
  query?: string;
  originalNodes: NodeBundle;
  filteredNodes: NodeBundle;
  cardsPerRow: number;
  favoritesEnable: boolean;
  page: number;
  favoritePage: number;
}

type Props = StateProps & DispatchProps & InjectedProps & URLInjectedProps;

class TilesViewPage extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    const { paginatedData } = props;
    const tileNodes = {
      favorites: Object.values(paginatedData.favoriteNodes) as NodeWithTitle[],
      nonFavorites: Object.values(paginatedData.nodes) as NodeWithTitle[],
    } as NodeBundle;

    this.state = {
      originalNodes: tileNodes,
      filteredNodes: tileNodes,
      cardsPerRow: 1,
      favoritesEnable: false,
      page: 1,
      favoritePage: 1,
    };

    window.addEventListener('resize', this.updateCardsPerRow);
    if (
      tileNodes.favorites.length ||
      (tileNodes.nonFavorites.length && !this.state.query)
    ) {
      this.props.fetchCollection(this.getPaginatedData(true));
      this.props.fetchCollection(this.getPaginatedData(false));
    }
  }

  public componentDidMount(): void {
    this.updateCollection();
    this.updateCardsPerRow();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { paginatedData, bookmarkBeingSaved } = this.props;

    if (
      (prevProps.bookmarkBeingSaved && !bookmarkBeingSaved) ||
      (paginatedData.isDeleting === LoadingState.Loaded &&
        prevProps.paginatedData.isDeleting === LoadingState.Loading)
    ) {
      this.props.fetchCollection(this.getPaginatedData(true));
      this.props.fetchCollection(this.getPaginatedData(false));
    }

    if (!isEqual(paginatedData, prevProps.paginatedData)) {
      const tileNodes = {
        favorites: Object.values(
          paginatedData.favoriteNodes,
        ) as NodeWithTitle[],
        nonFavorites: Object.values(paginatedData.nodes) as NodeWithTitle[],
      } as NodeBundle;

      const { query } = this.state;

      if (query) {
        this.setState({
          originalNodes: tileNodes,
          filteredNodes: filterByQuery(query, tileNodes),
        });
      } else {
        this.setState({ originalNodes: tileNodes, filteredNodes: tileNodes });
      }
    }

    this.updateCollection();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateCardsPerRow);
    this.props.endLockingLoader();
  }

  public render(): JSX.Element | null {
    const {
      loadingState,
      collectionType,
      permissions,
      appPermissions,
      paginatedData,
      fetchCollection,
    } = this.props;
    const { filteredNodes, query, cardsPerRow, favoritesEnable } = this.state;

    if (!collectionType) {
      return null;
    }

    const TabContainer = ({ children }) => {
      return <div>{children}</div>;
    };

    const singularCollectionName = getSingularCollectionName(collectionType);
    const pluralCollectionName = getPluralCollectionName(collectionType);
    const includeDescendantsCount =
      collectionType === CollectionTypes.databases ||
      collectionType === CollectionTypes.taskdbs;
    // Optional relabeling for some of the collections that include descendants count:
    const descendantsLabel =
      collectionType === CollectionTypes.databases ||
      collectionType === CollectionTypes.taskdbs
        ? 'record'
        : undefined;

    const favoritesOrdered = sortBy('modifiedAt')(
      filteredNodes.favorites,
    ).reverse();
    const nonFavoritesOrdered = sortBy('modifiedAt')(
      filteredNodes.nonFavorites,
    ).reverse();

    const handleChange = () => {
      this.setState({ favoritesEnable: !this.state.favoritesEnable });
    };

    const handleChangeNonFavoritePage = (page) => {
      this.setState({ page }, () => {
        fetchCollection(this.getPaginatedData(true));
      });
    };

    const handleChangeFavoritePage = (favoritePage) => {
      this.setState({ favoritePage }, () => {
        fetchCollection(this.getPaginatedData(false));
      });
    };

    const onQueryChanges = (query: string): void => {
      this.setState({ query: query, page: 1, favoritePage: 1 }, () => {
        fetchCollection(this.getPaginatedData(true));
        fetchCollection(this.getPaginatedData(false));
      });
    };
    const getStartRecordDisplay = (page) => {
      return page === 1 ? 1 : page * 100 - 99;
    };

    const getFinishRecordDisplay = (page, totalPages, length) => {
      return page === totalPages ? length : page * 100;
    };

    return (
      <Wrapper>
        <TilesViewBar
          collectionType={collectionType}
          collectionName={pluralCollectionName}
          itemName={singularCollectionName}
          query={query}
          onAddNew={this.onAddNew}
          onQueryChanges={onQueryChanges}
          permissions={
            collectionType === CollectionTypes.workspaces
              ? appPermissions
              : permissions
          }
          omitLoader={true}
        />
        <SwitchContainer>
          <Switch
            checked={favoritesEnable}
            onChange={handleChange}
            color="primary"
          />
          <span className="label-switch">Show Favorites Only</span>
        </SwitchContainer>
        {loadingState === (LoadingState.Loading as LoadingState) && (
          <LoadingIndicator />
        )}
        {loadingState !== (LoadingState.Loading as LoadingState) && (
          <>
            {favoritesEnable && (
              <TabContainer>
                {!favoritesOrdered.length && (
                  <NotFound>
                    <NotFoundText>
                      {AppMessages.NOT_FOUND_TEXT_FAVORITE}
                    </NotFoundText>
                  </NotFound>
                )}
                {favoritesOrdered.length > 0 && (
                  <>
                    <TilesList
                      id="favoritesList"
                      collectionType={collectionType}
                      collectionName={pluralCollectionName}
                      heading={''}
                      nodes={favoritesOrdered}
                      cardsPerRow={cardsPerRow}
                      includeDescendantsCount={includeDescendantsCount}
                      descendantsLabel={descendantsLabel}
                      permissions={permissions}
                      appPermissions={appPermissions}
                    />
                    <PaginationContainer>
                      <UltimatePaginationMaterialUi
                        totalPages={paginatedData.totalPagesFavorites || 1}
                        currentPage={this.state.favoritePage || 1}
                        onChange={handleChangeFavoritePage}
                      />
                      <DisplayInfo>
                        Displaying{' '}
                        {getStartRecordDisplay(this.state.favoritePage)} to{' '}
                        {getFinishRecordDisplay(
                          this.state.favoritePage,
                          paginatedData.totalPagesFavorites,
                          paginatedData.totalFavorites,
                        )}{' '}
                        of {paginatedData.totalFavorites}
                      </DisplayInfo>
                    </PaginationContainer>
                  </>
                )}
              </TabContainer>
            )}
            {!favoritesEnable && (
              <TabContainer>
                {!nonFavoritesOrdered.length && (
                  <NotFound>
                    <NotFoundText>{AppMessages.NOT_FOUND_TEXT}</NotFoundText>
                  </NotFound>
                )}
                {nonFavoritesOrdered.length > 0 && (
                  <>
                    <TilesList
                      id="databasesList"
                      collectionType={collectionType}
                      collectionName={pluralCollectionName}
                      heading={''}
                      nodes={nonFavoritesOrdered}
                      cardsPerRow={cardsPerRow}
                      includeDescendantsCount={includeDescendantsCount}
                      descendantsLabel={descendantsLabel}
                      permissions={permissions}
                      appPermissions={appPermissions}
                    />
                    <PaginationContainer>
                      <UltimatePaginationMaterialUi
                        totalPages={paginatedData.totalPages || 1}
                        currentPage={this.state.page || 1}
                        onChange={handleChangeNonFavoritePage}
                      />
                      <DisplayInfo>
                          Displaying {getStartRecordDisplay(this.state.page)} to{' '}
                        {getFinishRecordDisplay(
                          this.state.page,
                          paginatedData.totalPages,
                          paginatedData.total,
                        )}{' '}
                          of {paginatedData.total}
                      </DisplayInfo>
                    </PaginationContainer>
                  </>
                )}
              </TabContainer>
            )}
          </>
        )}
      </Wrapper>
    );
  }

  private onAddNew = (): void => {
    const { collectionType, openCreateCollectionItemModal } = this.props;
    if (collectionType) {
      openCreateCollectionItemModal(collectionType);
    }
  };

  private updateCardsPerRow = (): void => {
    const cardsPerRow: number = Math.floor(
      (window.innerWidth - PARENT_MARGINS) / CARD_TOTAL_WIDTH,
    );
    this.setState({ cardsPerRow });
  };

  private getPaginatedData = (isWorkspace: boolean): any => {
    return {
      url: isWorkspace ? createWorkspaceUrlPaginated() : createBookmarksUrl(),
      page: isWorkspace ? this.state.page: this.state.favoritePage,
      query: encodeURIComponent(this.state.query || ''),
    };
  };

  private updateCollection = (): void => {
    const {
      collectionType,
      loadingState,
      fetchCollection,
      current,
      setCurrentWorkspace,
    } = this.props;

    if (collectionType && loadingState === LoadingState.Unloaded) {
      switch (collectionType) {
        case CollectionTypes.workspaces:
          fetchCollection(this.getPaginatedData(true));
          fetchCollection(this.getPaginatedData(false));
          break;
      }
    }

    if (current) {
      switch (collectionType) {
        case CollectionTypes.workspaces:
          setCurrentWorkspace(null);
          break;
      }
    }
  };
}

const propsInterceptor = (props: URLInjectedProps): InjectedProps => ({
  collectionType: getCollectionTypeFromUrl(props.location.pathname),
});

const selectors = createStructuredSelector<
  ReduxState,
  URLInjectedProps,
  StateProps
>({
  paginatedData: getAllPaginatedData,
  loadingState: getLoadingForTileView,
  isLoading: isLoadingLocking(),
  current: getCurrentNodeForTileView,
  permissions: getWorkspacePermissions,
  appPermissions: (state: ReduxState) => state.users.user.permissions || [],
  bookmarkBeingSaved: bookmarkBeingSaved,
});

const mapDispatchToProps: DispatchProps = {
  fetchCollection: fetchCollectionRequest,
  setCurrentWorkspace,
  setCurrentDatabase,
  setCurrentTaskDatabase,
  openCreateCollectionItemModal,
  endLockingLoader: loadingAction.endLockingLoader,
};

const enhance = compose(
  withURLParams,
  withProps(propsInterceptor),
  connect(selectors, mapDispatchToProps),
);

export default enhance(TilesViewPage);

function filterByQuery(query: string, nodes: NodeBundle): NodeBundle {
  return {
    favorites: nodes.favorites,
    nonFavorites: nodes.nonFavorites,
  };
}
