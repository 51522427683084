import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'wrapper';

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 176px)',
    flexDirection: 'column',
    width: '1053px',
    maxWidth: '100%',
    margin: '0 auto',
  },
};
