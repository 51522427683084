import * as Colors from 'styles/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'list' | 'searchBox' | 'item';

export const styles: Record<ClassKey, CSSProperties> = {
  list: {
    padding: 0,
    maxHeight: '50vh',
    overflowY: 'auto',
    borderTop: `1px solid ${Colors.lightBlueGrey}`,
  },
  searchBox: {
    margin: '10px 15px',
    width: 'auto',
  },
  item: {
    padding: '3px 15px',
  },
};
