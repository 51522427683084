import * as React from 'react';
import { EditorState } from 'draft-js';
import EditorButton from './EditorButton';
import ListIcon from '@material-ui/icons/List';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

export const blockStylesButtons = [
  { id: 'btnUL', icon: <ListIcon />, label: 'UL', style: 'unordered-list-item' },
  { id: 'btnOL', icon: <FormatListNumberedIcon />, style: 'ordered-list-item' },
];

interface OwnProps {
  id?: string;
  editorState: EditorState;
  onToggle(style: string): void;
}

class BlockStylesControls extends React.Component<OwnProps> {
  public render(): JSX.Element {
    const { id, editorState, onToggle } = this.props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className="RichEditor-controls">
        {blockStylesButtons.map(type => {
          return (
            <EditorButton
              id={`${id}_${type.id}`}
              key={type.style}
              icon={type.icon}
              style={type.style}
              active={type.style === blockType}
              onToggle={onToggle}
            />
          );
        })}
      </div>
    );
  }
}

export default BlockStylesControls;
