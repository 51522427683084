import { CSSProperties } from '@material-ui/core/styles/withStyles';

type ClassKey = 'root' | 'rootColumn' | 'listOverflowItemsGroupRow' | 'rowItem' | 'hiddenOptions' | 'choiceWrapper' | 'wrap';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  rootColumn: {
    flexDirection: 'column',
  },
  listOverflowItemsGroupRow: {
    alignContent: 'center',
  },
  rowItem: {
    'margin': '0 3px',
    '&:first-child': {
      marginLeft: '0',
    },
    '&:last-child': {
      marginRight: '0',
    },
  },
  hiddenOptions: {
    opacity: 0,
    display: 'flex',
    height: '1px',
  },
  choiceWrapper: {
    // @ts-ignore
    flexDirection: 'row !important',
  },
};
