import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ChipsWrapper } from 'components/NodeFieldData/components/ChipsWrapper';
import styled from 'styled-components';
import { hubsyncBlue, charocalGrey, lightBlueGrey, slateTwo } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

type ClassKey = 'multipleChoiceList' | 'choice' | 'addIcon';

export const styles: Record<ClassKey, CSSProperties> = {
  multipleChoiceList: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  choice: {
    marginBottom: '5px',
  },
  addIcon: {
    marginLeft: '-7px',
    marginRight: '-7px',
  },
};

export const DropdownIcon = styled(svgIcons.Dropdown)`
  color: ${charocalGrey};
  flex-shrink: 0;
  height: 14px;
`;

export interface FormWrapperProps {
  lock?: boolean;
  reader?: boolean;
  admin?: boolean;
}

export const FormWrapper = styled(ChipsWrapper)<FormWrapperProps>`
  margin: 0;
  padding: 0;
`;

export const FormSelectWrapper = styled(FormWrapper)`
  &:focus {
    border: 1px solid ${hubsyncBlue};
  }
  align-items: center;
  background-color: white;
  border: 1px solid ${lightBlueGrey};
  color: ${slateTwo};
  cursor: pointer;
  display: flex;
  flex-direction: ${ ({ lock=false, admin=false }) => (lock && !admin) ? 'row': 'row-reverse' };
  font-size: 13px;
  justify-content: space-between;
  letter-spacing: 0.22px;
  padding: 0 10px;
  pointer-events:${ ({ reader=false }) => reader ? 'none': 'auto' };
`;

export const FormMultiSelectWrapper = styled(FormWrapper)`
  &:focus {
    border: 1px solid ${hubsyncBlue};
  }
  align-items: center;
  background-color: white;
  border: 1px solid ${lightBlueGrey};
  color: ${slateTwo};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: flex-start;
  letter-spacing: 0.22px;
  padding: 4px 30px 0px 10px;
  position: relative;
`;

export const MultiSelectDropdownIcon = styled(svgIcons.Dropdown)`
  color: ${charocalGrey};
  cursor: pointer;
  flex-shrink: 0;
  height: 14px;
  margin-left: 10px;
  position: absolute;
  right: 11px;
`;
