import './TaxOrganizer.styles.scss';
import React, { useState, useEffect } from 'react';
import Organizer from '@hubsync/tax-organizer';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { State } from 'reducers';
import { notifications } from 'data/ui/notifications/notifications.actions';
import { getFileProviderApiEndpoint, getTaxOrganizerApiEndpoint } from 'env';
import { getCurrentWorkspaceId } from 'data/workspaces/workspaces.selectors';
import { getAuthToken } from 'utilities/getAuthHeaders';
import { DEFAULT_FILE_ALLOWED_EXTENSION_LIST } from '../../constants';
import { getAllowedExtensionsString } from '../../utilities/common';

const TaxOrganizerPage = (props: RouteComponentProps): React.ReactElement => {
  // TODO: Use in the future an env variable configured in fargate-ops (per tenant can change)
  const DEFAULT_FOLDER_UPLOADS = '/To Baker Tilly/Tax organize - source docs/';
  const { history, location, match } = props;
  const [token, setToken] = useState<string>('');
  const displayName = useSelector((state: State) => state.users.user.displayName);
  const dispatch = useDispatch();
  const fileProviderBaseUrl = getFileProviderApiEndpoint();
  const taxOrganizerApiBaseUrl = getTaxOrganizerApiEndpoint();
  const workspaceId: string = useSelector(
    (state: State) => getCurrentWorkspaceId(state),
  );
  const tenant = window.appStore.getState().app.tenant;

  useEffect(() => {
    if (!token) {
      const getToken = async () => setToken(await getAuthToken());
      getToken();
    }
  }, []);

  const onNotification = (message: string, type = 'info') => {
    const toast = notifications[type] || notifications.info;
    dispatch(toast({ message }));
  };

  return (
    <section className='tax-organizer-wrapper'>
      <Organizer
        history={history}
        location={location}
        match={match}
        authToken={token}
        workspaceId={workspaceId}
        name={displayName}
        onToast={onNotification}
        fileProviderBaseUrl={fileProviderBaseUrl}
        fileAllowedExtensionList={getAllowedExtensionsString(tenant?.thirdParty?.fileAllowedExtensionList || DEFAULT_FILE_ALLOWED_EXTENSION_LIST)}
        taxOrganizerApiBaseUrl={taxOrganizerApiBaseUrl}
        folderUploads={DEFAULT_FOLDER_UPLOADS}
      />
    </section>
  );
};

export default TaxOrganizerPage;
