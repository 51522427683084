import styled from 'styled-components';
import { charocalGrey, brownishGrey } from 'styles/colors';

export const Wrapper = styled.div`
  display: table;
  font-size: 14px;
  margin: -13px 0;
`;

export const PropertyWrapper = styled.div`
  display: table-row;
`;

export const PropertyContent = styled.div`
  display: table-cell;
  height: 40px;
  vertical-align: middle;
  color: ${charocalGrey};
`;

export const PropertyContentInner = styled.div`
  display: flex;
  align-items: center;
  line-height: 39px;
  color: ${brownishGrey};
`;

export const PropertyName = styled(PropertyContent)`
  padding-right: 16px;
  color: ${brownishGrey};
  line-height: 39px;
  font-weight: 500;
  white-space: nowrap;
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
`;
