import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Button, { Size, Variant as ButtonVariant } from '../Button';

export interface OwnProps {
  onCancel(): void;
  onSave(): void;
}

type Props = OwnProps & WithStyles<ClassKey>;

function ActionButtons(props: Props): JSX.Element {
  return (
    <div className={props.classes.wrapper}>
      <Button
        id="btnCancelAction"
        label="Cancel"
        variant={ButtonVariant.SecondaryLink}
        size={Size.Small}
        onClick={props.onCancel}
      />
      <div className={props.classes.saveButton}>
        <Button
          id="btnSaveAction"
          label="Save"
          variant={ButtonVariant.PrimaryLink}
          size={Size.Small}
          onClick={props.onSave}
        />
      </div>
    </div>
  );
}

type ClassKey = 'wrapper' | 'saveButton';

const styles = withStyles<ClassKey>({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    marginTop: 15,
  },
  saveButton: {
    marginLeft: 13,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
});

export default styles(ActionButtons);
