import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import * as _ from 'lodash';

import { CommonNode } from '../types/response';

interface NodeValidationInputs<Node extends CommonNode> {
  node: Node;
  schema: JSONSchema7;
  prefix?: string;
}

export function validateNode<Node extends CommonNode>(inputs: NodeValidationInputs<Node>): _.Dictionary<string[]> {
  return _.reduce(inputs.schema.properties, (errors, schema, fieldId) => {
    if (typeof schema === 'boolean') {
      return errors;
    }

    if (schema.type === 'object') {
      return {
        ...errors,
        ...validateNode({
          node: inputs.node,
          schema,
          prefix: `${fieldId}.`,
        }),
      };
    }

    const nextFieldId = (inputs.prefix) ? `${inputs.prefix}${fieldId}` : fieldId;

    const fieldErrors = validateField({
      node: inputs.node,
      schema,
      value: _.get(inputs.node, nextFieldId),
    });

    if (fieldErrors.length === 0) {
      return errors;
    }

    return { ...errors, [nextFieldId]: _.uniq(fieldErrors) };
  }, {});
}

interface FieldValidationInputs<Node extends CommonNode> {
  node: Node;
  schema: JSONSchema7;
  value?: Node[keyof Node];
}

function validateField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  if (inputs.schema.readOnly) {
    return [];
  }

  return flatMapFunctions<FieldValidationInputs<Node>, string>([
    validateMasking,
    validateRequired,
    validateLengths,
    validateEquality,
    validateOrdinality,
    validateMembership,
    validateRelativeFields,
    validatePattern,
    validateRootTagUrls,
    validateChoices,
    validateWithFunction,
  ], inputs);
}

function validateMasking<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Masked isn't part of the JSON Schema
  // TODO: Find equivalent in JSON Schema
  if (inputs.schema.masked) {
    return ['Field is masked.'];
  }

  return [];
}

function validateRequired<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // TODO: Required should be an array of required properties on an object
  // It should be a requirement at the property level
  if (inputs.schema.required) {
    if (!inputs.value) {
      return ['Field is required.'];
    }
  }

  return [];
}

function validateLengths<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  return flatMapFunctions([
    validateExactLength,
    validateMinLength,
    validateMaxLength,
  ], inputs);
}

function validateExactLength<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Length isn't part of the JSON Schema
  // TODO: This should be written in terms of `maxLength` and `minLength` for
  // strings and `maxItems` and `minItems` for arrays
  const length: number | undefined = inputs.schema.length;

  if (length) {
    if (typeof inputs.value !== 'string' && !Array.isArray(inputs.value)) {
      return ['Field is not a string or an array.'];
    }

    if (length !== inputs.value.length) {
      return [`Field is not expected length (${length}).`];
    }
  }

  return [];
}

function validateMinLength<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // TODO: This should be written in terms of `minLength` for strings and
  // `minItems` for arrays
  if (inputs.schema.minLength) {
    if (typeof inputs.value !== 'string' && !Array.isArray(inputs.value)) {
      return ['Field is not a string or an array.'];
    }

    if (inputs.schema.minLength > inputs.value.length) {
      return [`Field is below minimum length (${inputs.schema.minLength}).`];
    }
  }

  return [];
}

function validateMaxLength<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // TODO: This should be written in terms of `maxLength` for strings and
  // `maxItems` for arrays
  if (inputs.schema.maxLength) {
    if (typeof inputs.value !== 'string' && !Array.isArray(inputs.value)) {
      return ['Field is not a string or an array.'];
    }

    if (inputs.schema.maxLength < inputs.value.length) {
      return [`Field is above maximum length (${inputs.schema.maxLength}).`];
    }
  }

  return [];
}

function validateEquality<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  return flatMapFunctions([validateEqualTo, validateNotEqualTo], inputs);
}

function validateEqualTo<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Equal To isn't part of the JSON Schema
  // TODO: This should be written in terms of `const`
  const equalTo: Node[keyof Node] | undefined = inputs.schema.equalTo;

  if (equalTo) {
    if (inputs.value !== equalTo) {
      return [`Field is not equal to allowed value (${equalTo}).`];
    }
  }

  return [];
}

function validateNotEqualTo<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Not Equal To isn't part of the JSON Schema
  // TODO: This should be written in terms of `not.const`
  const notEqualTo: Node[keyof Node] | undefined = inputs.schema.notEqualTo;

  if (notEqualTo) {
    if (inputs.value === notEqualTo) {
      return [`Field is equal to blocked value (${notEqualTo}).`];
    }
  }

  return [];
}

function validateOrdinality<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  return flatMapFunctions([validateMinimum, validateMaximum], inputs);
}

function validateMinimum<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  if (inputs.schema.minimum) {
    if (typeof inputs.value !== 'number' && typeof inputs.value !== 'string') {
      return ['Field is not a number or string.'];
    }

    if (inputs.schema.minimum > inputs.value) {
      return [`Field is below minimum (${inputs.schema.minimum}).`];
    }
  }

  return [];
}

function validateMaximum<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  if (inputs.schema.maximum) {
    if (typeof inputs.value !== 'number' && typeof inputs.value !== 'string') {
      return ['Field is not a number or string.'];
    }

    if (inputs.schema.maximum < inputs.value) {
      return [`Field is above maximum (${inputs.schema.maximum}).`];
    }
  }

  return [];
}

function validateMembership<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  return flatMapFunctions([validateOneOf, validateNotOneOf], inputs);
}

function validateOneOf<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // TODO: `oneOf` is implemented with an arrays of schemas
  if (inputs.schema.oneOf && inputs.value) {
    if (!inputs.schema.oneOf.includes(inputs.value)) {
      return [`Field is not one of allowed value (${inputs.schema.oneOf}).`];
    }
  }

  return [];
}

function validateNotOneOf<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Not One Of isn't part of the JSON Schema
  // TODO: should be implemented in terms of `not.oneOf` and `oneOf` is
  // implemented with an arrays of schemas
  const notOneOf: JSONSchema7Definition[] | undefined = inputs.schema.notOneOf;

  if (notOneOf && inputs.value) {
    if (notOneOf.includes(inputs.value)) {
      return [`Field is one of blocked value (${notOneOf}).`];
    }
  }

  return [];
}

function validateRelativeFields<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // TODO: should be implemented using relative JSON pointers
  return flatMapFunctions([
    validateEqualToField,
    validateNotEqualToField,
    validateGreaterThanField,
    validateGreaterThanOrEqualToField,
    validateLessThanField,
    validateLessThanOrEqualToField,
  ], inputs);
}

function validateEqualToField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Equal To Field isn't part of the JSON Schema
  const equalTo: string | undefined = inputs.schema.equalToField;

  if (equalTo) {
    if (equalTo in inputs.node) {
      if (inputs.value === inputs.node[equalTo]) {
        return [];
      }

      return [`Field is not equal to relative value (${equalTo}).`];
    }

    return [`"Equal to" field does not exist (${equalTo}).`];
  }

  return [];
}

function validateNotEqualToField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Not Equal To Field isn't part of the JSON Schema
  const notEqualTo: string | undefined = inputs.schema.notEqualToField;

  if (notEqualTo) {
    if (notEqualTo in inputs.node) {
      if (inputs.value === inputs.node[notEqualTo]) {
        return [];
      }

      return [`Field is equal to invalid relative value (${notEqualTo}).`];
    }

    return [`"Not equal to" field does not exist (${notEqualTo}).`];
  }

  return [];
}

function validateGreaterThanField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Greater Than Field isn't part of the JSON Schema
  const greaterThan: string | undefined = inputs.schema.greaterThanField;

  if (greaterThan) {
    if (greaterThan in inputs.node) {
      if (inputs.value && inputs.value > inputs.node[greaterThan]) {
        return [];
      }

      return [`Field is not greater than relative value (${greaterThan}).`];
    }

    return [`"Greater than" field does not exist (${greaterThan}).`];
  }

  return [];
}

function validateGreaterThanOrEqualToField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Greater Than Or Equal To Field isn't part of the JSON Schema
  const greaterThanOrEqualTo: string | undefined = inputs.schema.greaterThanOrEqualToField;

  if (greaterThanOrEqualTo) {
    if (greaterThanOrEqualTo in inputs.node) {
      if (inputs.value && inputs.value >= inputs.node[greaterThanOrEqualTo]) {
        return [];
      }

      return [`Field is not greater than or equal to relative value (${greaterThanOrEqualTo}).`];
    }

    return [`"Greater than or equal to" field does not exist (${greaterThanOrEqualTo}).`];
  }

  return [];
}

function validateLessThanField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Less Than Field isn't part of the JSON Schema
  const lessThan: string | undefined = inputs.schema.lessThanField;

  if (lessThan) {
    if (lessThan in inputs.node) {
      if (inputs.value && inputs.value < inputs.node[lessThan]) {
        return [];
      }

      return [`Field is not less than relative value (${lessThan}).`];
    }

    return [`"Less than" field does not exist (${lessThan}).`];
  }

  return [];
}

function validateLessThanOrEqualToField<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Less Than Or Equal To Field isn't part of the JSON Schema
  const lessThanOrEqualTo: string | undefined = inputs.schema.lessThanOrEqualToField;

  if (lessThanOrEqualTo) {
    if (lessThanOrEqualTo in inputs.node) {
      if (inputs.value && inputs.value <= inputs.node[lessThanOrEqualTo]) {
        return [];
      }

      return [`Field is not less than or equal to relative value (${lessThanOrEqualTo}).`];
    }

    return [`"Less than or equal to" field does not exist (${lessThanOrEqualTo}).`];
  }

  return [];
}

function validatePattern<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  if (inputs.schema.pattern) {
    const regexp = new RegExp(inputs.schema.pattern);

    if (!inputs.value) {
      return ['Value is missing'];
    }

    if (typeof inputs.value !== 'string') {
      return ['Value is not a string'];
    }

    if (!regexp.test(inputs.value)) {
      return [`Value does not satisfy pattern (${inputs.schema.pattern}).`];
    }
  }

  return [];
}

function validateRootTagUrls<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Root Tag URLs isn't part of the JSON Schema
  // TODO: should be implemented with `anyOf` and `pattern`
  const rootTagURLs: string[] | undefined = inputs.schema.rootTagURLs;

  if (rootTagURLs) {
    const value = inputs.value;

    if (!value) {
      return ['Value is missing'];
    }

    if (typeof value !== 'string') {
      return ['Value is not a string'];
    }

    const matched = rootTagURLs.some(value.startsWith);

    if (!matched) {
      return [`Value has invalid root tag URL (${rootTagURLs}).`];
    }
  }

  return [];
}

function validateChoices<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Choices isn't part of the JSON Schema
  const choices: _.Dictionary<string> | undefined = inputs.schema.choices;

  if (choices && inputs.value) {
    if (Array.isArray(inputs.value)) {
      if (inputs.value.some((id) => !choices[id])) {
        return [`Value is not a valid choice (${choices})`];
      }
    } else if (typeof inputs.value === 'string') {
      if (choices[inputs.value] === undefined) {
        return [`Value is not a valid choice (${choices})`];
      }
    } else {
      return ['Value is not a string or an array of strings'];
    }
  }

  return [];
}

type ValidationFn<Node extends CommonNode> = (inputs: FieldValidationInputs<Node>) => string[];

function validateWithFunction<Node extends CommonNode>(inputs: FieldValidationInputs<Node>): string[] {
  // @ts-ignore
  // Validation Function isn't part of the JSON Schema
  const fn: ValidationFn<Node> | undefined = inputs.schema.validationFunction;

  if (fn) {
    return fn(inputs);
  }

  return [];
}

function flatMapFunctions<A, B>(fns: ((value: A) => B[])[], input: A): B[] {
  return fns.reduce((prev, fn) => [...prev, ...fn(input)], []);
}

export function validateEmail(email): boolean {
  const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  return emailRegex.test(email);
}

export function validateURL(url: string): boolean {
  // eslint-disable-next-line
  const re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return re.test(String(url).toLowerCase());
}
