import DatabaseEntity from './entities/database';
import ItemEntity from './entities/item';
import MessageEntity from './entities/message';
import FieldEntity from './entities/Field';
import ViewEntity from './entities/view';
import BookmarkEntity from './entities/bookmark';
import WorkspaceEntity from './entities/workspace';
import ImportEntity from './entities/import';
import userEntity from './entities/user';
import roleEntity from './entities/role';
import notificationEntity from './entities/notification';
import fileEntity from './entities/file';

const handlers = {
  'Database': DatabaseEntity,
  'Item': ItemEntity,
  'Field': FieldEntity,
  'Message': MessageEntity,
  'Import': ImportEntity,
  'View': ViewEntity,
  'Bookmark': BookmarkEntity,
  'Workspace': WorkspaceEntity,
  'User': userEntity,
  'Role': roleEntity,
  'Notification': notificationEntity,
  'File': fileEntity,
};

export default handlers;
