import * as _ from 'lodash/fp';

import * as Accounts from '../../data/accounts/state';
import { State } from '../../reducers';
import { AccountNode } from '../../types/response/accountNode';

function root(state: State): Accounts.State {
  return state.app.accounts;
}

export const accounts = _.pipe(root, Accounts.getAccounts);
export const accountsById = _.pipe(root, Accounts.getIndexedAccounts);
export const isAccountsLoading = _.pipe(root, Accounts.isLoading);

export const getActiveWorkspaceUsers = (state: State) => {
  return state.account?.workspaceUsers?.filter?.(user => user?.status === 'active') || [];
};

interface WithAccountId {
  accountId: string;
}

export function getAccountById<Props extends WithAccountId>(state: State, props: Props): AccountNode | null {
  const indexedAccounts = accountsById(state);

  if (indexedAccounts === null) {
    return null;
  }

  const account = indexedAccounts[props.accountId];

  if (account === undefined) {
    return null;
  }

  return account;
}

export function getCurrentUsers(state: State) {
  return state.account.workspaceUsers.slice();
}
