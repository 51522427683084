import * as React from 'react';

interface Props {
  body: string;
}

export default class ExternalHtml extends React.Component<Props> {
  setExternalHtml = (el: HTMLDivElement | null): void => {
    if (el !== null) {
      setExternalHtml(this.props.body, el);
    }
  };

  render(): JSX.Element {
    return (
      <div className="external-html" ref={this.setExternalHtml} />
    );
  }
}

function setExternalHtml(html: string, el: Node): void {
  const range = document.createRange();
  range.selectNodeContents(el);
  range.deleteContents();
  el.appendChild(range.createContextualFragment(html));
}
