import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import Checkbox from '../../Checkbox';

export const checkboxCheckedHtml = renderToStaticMarkup(
  <Checkbox selected />,
);

export const checkboxUncheckedHtml = renderToStaticMarkup(
  <Checkbox />,
);
