import * as _ from 'lodash/fp';
import * as React from 'react';
import styled from 'styled-components';

import { toPrecision } from './NumberEditor.utils';
import TextRenderer from '../components/TextRenderer';
import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererTypes, FieldRendererProps } from '../Fields.types';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';

interface NumberRendererProps extends FieldRendererProps {
  precision?: number;
  format?: (value: string) => string;
}

const identity: (value: string) => string = _.identity;

const NumberRenderer = ({ value, fieldType, format = identity, type, precision, isGroup }: NumberRendererProps) => {
  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    return (
      <span
        className={getColumnHeaderClass(fieldType)}
        title={value}
      />
    );
  }

  const parsedValue = toPrecision(value, precision);
  return (
    <Renderer isGroup={isGroup}>
      {parsedValue !== null ? format(parsedValue) : ''}
    </Renderer>
  );
};

export default wrapCellRenderer(NumberRenderer);

const Renderer = styled<{ isGroup?: boolean }>(({ isGroup, ...rest }) => <TextRenderer {...rest} />)`
   ${props => !props.isGroup ? 'text-align: right' : ''};
`;
