import { delay } from 'redux-saga';
import { put, fork, takeLatest } from 'redux-saga/effects';
import { ActionType, isActionOf } from 'typesafe-actions';

import { batchJobs, batchJobCheck } from 'data/batchJobs/batchJobs.actions';
import { createAPIHandler } from 'utilities/saga';

function* watchBatchJob(action: ActionType<typeof batchJobs.request>) {
  yield delay(action.payload.retryAfterSeconds);
  yield createAPIHandler({
    actions: batchJobCheck,
    buildUrl: (action) => `${action.payload.uri}`,
    * onSuccess(payload) {
      if (payload.status.code === 202) {
        yield put(batchJobs.request(action.payload));
      } else if (payload.status.code === 200) {
        yield put(batchJobs.success());
      }
    },
    * onFailure() {
      yield put(batchJobs.failure());
    },
  });
}

function* batchJobSaga() {
  yield takeLatest(isActionOf(batchJobs.request), watchBatchJob);
}

export default function* () {
  yield fork(batchJobSaga);
}
