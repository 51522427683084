import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';

import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';

interface OwnProps extends React.HTMLAttributes<SVGElement> {
  isActive?: boolean;
  isFilled?: boolean;
}

type Props =
  & OwnProps
  & WithStyles<ClassKey>
  ;

function Star(props: Props): JSX.Element {
  const { classes, className, isActive, isFilled, ...componentProps } = props;

  const Component = (isFilled) ? svgIcons.StarFilled : svgIcons.StarLined;

  const componentClassName = cn({
    [classes.star]: true,
    [classes.active]: isActive,
    [className || '']: className,
  });

  return (
    <Component
      {...componentProps}
      className={componentClassName}
    />
  );
}

type ClassKey =
  | 'star'
  | 'active'
  ;

const styles = withStyles<ClassKey>({
  star: {
    'height': '13px',
    'color': Colors.offYellow,
    'opacity': 0.5,
    'marginRight': '2px',
    '&:last-child': { marginRight: 0 },
  },
  active: { opacity: 1 },
});

export default styles(Star);
