import { confirmOrganizerProcess } from 'data/workspaceCreate/actions';

export const createOrganizerMasterDataBase = (props) => {
  const { dispatch, data, isGrid } = props;
  if (isGrid) {
    dispatch(confirmOrganizerProcess({ ids: [data.id] }));
  }

  return {};
};
