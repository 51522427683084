import * as React from 'react';

import * as ModalActions from 'data/modals/actions';
import { connect } from 'react-redux';


import Confirm from '../Confirm';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
}

export type Props = DispatchProps;

const ResendEnvelopePermission = (props: Props): JSX.Element => {
  return (
    <Confirm
      title="Only Administrators can resend signatures."
      close={props.close}
      onConfirm={props.close}
      confirmLabel="OK"
      cancelBtn={false}
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
};

export default connect<null, DispatchProps>(null, mapDispatchToProps)(
  ResendEnvelopePermission,
);
