import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Button, { ButtonProps } from 'components/Button';

interface OwnProps {
  leftButtons: ButtonProps[];
  rightButtons: ButtonProps[];
}

type Props
  = OwnProps
  & WithStyles<ClassKey>
  ;

const CustomActions = (props: Props): JSX.Element => (
  <div className={props.classes.wrapper}>
    <div className={props.classes.buttons}>
      {props.leftButtons.map((props, index) => <Button id={`btnCustomActionLeft_${index}`} key={`left-button-${index}`} {...props} />)}
    </div>
    <div className={props.classes.buttons}>
      {props.rightButtons.map((props, index) => <Button id={`btnCustomActionRight_${index}`} key={`right-button-${index}`} {...props} />)}
    </div>
  </div>
);

type ClassKey = 'wrapper' | 'buttons';

const styles = withStyles<ClassKey>({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '18px 30px 30px 30px',
  },
  buttons: {
    'display': 'flex',
    '& button ~ button': {
      marginLeft: '10px',
    },
  },
});

export default styles(CustomActions);
