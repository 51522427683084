import { createAsyncAction, createAction, ActionType, createStandardAction } from 'typesafe-actions';

import { ViewNode } from '../../types/response/viewNode';
import {
  CreateViewPayload,
  DeleteViewPayload, PatchViewPayload,
  UpdateViewPayload,
} from './types';

export const createView = createAsyncAction(
  'views/CREATE_REQUEST',
  'views/CREATE_SUCCESS',
  'views/CREATE_FAILURE',
)<CreateViewPayload, ViewNode, Error>();

export const updateView = createAsyncAction(
  'views/UPDATE_REQUEST',
  'views/UPDATE_SUCCESS',
  'views/UPDATE_FAILURE',
)<UpdateViewPayload, ViewNode, Error>();

export const patchView = createAsyncAction(
  'views/PATCH_REQUEST',
  'views/PATCH_SUCCESS',
  'views/PATCH_FAILURE',
)<PatchViewPayload, ViewNode, Error>();

export const deleteView = createAsyncAction(
  'views/DELETE_REQUEST',
  'views/DELETE_SUCCESS',
  'views/DELETE_FAILURE',
)<DeleteViewPayload, DeleteViewPayload, Error>();

export const changeViewsOrder = createAsyncAction(
  'views/CHANGE_ORDER_REQUEST',
  'views/CHANGE_ORDER_SUCCESS',
  'views/CHANGE_ORDER_FAILURE',
)<string[], string[], Error>();

export const setViewOrder = createStandardAction('views/SET_ORDER')<string[] | null>();

export const selectView = createAction('views/SELECT', resolve =>
  (viewId?: string) => resolve(viewId),
);

export const setDefaultView = createAction('views/SET_DEFAULT', resolve =>
  (viewId?: string) => resolve(viewId),
);

export const patchCustomOrderAction = createAction('views/PATCH_CUSTOM_ORDER', resolve =>
  () => resolve(),
);

export const selectChartView = createAction('views/SELECT_CHART_VIEW', resolve =>
  (viewId?: string) => resolve(viewId),
);

export const actions = {
  selectView,
  changeViewsOrder,
};

export type Actions = ActionType<typeof actions>;
