import React, { Component } from 'react';
import Logo from 'icons/hubsync-logout-logo.svg';

import './OutagePage.style.scss';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const MaintenanceImage = require('../../assets/images/new-icons/maintenance.png');

export default class OutagePage extends Component {
  render() {
    return (
      <div className='outage-wrapper'><img
        src={ Logo}
        alt={'Logo'}
        className='outage-logo'
      />
      <br />

      <img
        src = { MaintenanceImage}
        alt = {'Maintenance'}
        className='outage-image'
      />

      <h1 className='outage-title'>We’ll be back soon!</h1>
      <span className='outage-subtitle'> {`We're performing maintenance at the moment. We'll be back online soon. `}</span>
      </div>

    );
  }
}
