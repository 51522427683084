import _ from 'lodash';
import { updateBookmark, deleteBookmark } from 'data/bookmarks/actions';

class BookmarkEntity {
  updateByRecordID(data) {
    const store = window.appStore;
    const state = store.getState();
    const {
      item,
    } = _.pick(data, ['eventBy', 'item']);
    const recordID = _.get(item, '_id');
    if (recordID) {
      const currentBookmark = Object.values(state.bookmarks.byId).find(bookmark => bookmark.recordID === recordID);
      if (currentBookmark) {
        store.dispatch(updateBookmark({ ...currentBookmark, title: item.title }));
      }
    }
  }
  deletedByRecordID(data) {
    const store = window.appStore;
    const state = store.getState();
    const {
      item,
    } = _.pick(data, ['eventBy', 'item']);
    const recordID = _.get(item, '_id');
    if (recordID) {
      const currentBookmark = Object.values(state.bookmarks.byId).find(bookmark => bookmark.recordID === recordID);
      if (currentBookmark) {
        store.dispatch(deleteBookmark({ bookmarkID: currentBookmark._id }));
      }
    }
  }
}

export default new BookmarkEntity();
