import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modalActions from 'data/modals/actions';

import { deleteBookmark, saveBookmark } from '../../data/bookmarks/actions';
import { copyCollectionRequest } from '../../data/collections/collections.actions.new';
import { Bookmark, CollectionTypes, CollectionTypeWithTitle, NodeWithTitle } from '../../types/schema';
import { getBookmarkByUri, getBookmarkByMeta } from '../../data/bookmarks/bookmarks.selectors';
import { DatabaseNode } from '../../types/response/databaseNode';
import { WorkspaceNode } from '../../types/response/workspaceNode';
import { archiveWorkspace, unarchiveWorkspace } from '../../data/workspaces/workspaces.actions';
import getMenuItems from './getMenuItems';
import ContextMenu, { MenuItem } from 'components/ContextMenu/ContextMenu';
import { getAllWorkspaces } from 'data/workspaces/workspaces.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { BookmarkMeta } from 'data/bookmarks/types';

interface OwnProps {
  currentNode: NodeWithTitle;
  collectionType: CollectionTypeWithTitle;
  withFavorite?: boolean;
}

interface StateProps {
  availableWorkspaces: WorkspaceNode[];
  getBookmarkByUri: (bookmarkURI: string) => Bookmark | undefined;
  getBookmarkByMeta: (bookmarkMeta?: BookmarkMeta) => Bookmark;
  permissions: string[];
}

interface DispatchProps {
  archiveWorkspace: typeof archiveWorkspace.request;
  unarchiveWorkspace: typeof unarchiveWorkspace.request;
  copyCollection: typeof copyCollectionRequest;
  saveBookmark: ({ bookmarkMeta: BookmarkMeta }) => void;
  deleteBookmark: ({ bookmarkID: string }) => void;
  openDeleteConfirm: typeof modalActions.openDeleteNodeModal;
  openRenameCollectionItemModal: typeof modalActions.openRenameCollectionItemModal;
  openDuplicateDatabases(database: DatabaseNode, availableWorkspaces: WorkspaceNode[]): void;
  openWorkspaceInfoModal: any;
  onRenameWorkspaceWithMeta: (currentNode: NodeWithTitle) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class NodeContextMenu extends React.PureComponent<Props> {
  public render = (): React.ReactNode => {
    const { currentNode, withFavorite, getBookmarkByMeta } = this.props;
    const menuItems: MenuItem[] = getMenuItems({
      onAddAsFavorite: this.handleFavoriteClick,
      isFavorite: !!getBookmarkByMeta(currentNode.bookmarkMeta),
      onEditTitle: this.handleRenameClick,
      onArchiveClick: this.handleArchiveClick,
      isArchived: !!currentNode.isFrozen,
      onDeleteClick: this.handleDeleteClick,
      onDuplicate: this.handleDuplicate,
      permissions: currentNode.permissions || this.props.permissions,
      collectionType: this.props.collectionType,
      withFavorite,
      isInformation: this.handleWorkspaceInformation,
      isMeta: currentNode.meta?.clientId,
      onRenameWorkspaceWithMeta: this.handleRenameWorkspaceWithMeta,
    });

    if (!menuItems.length) {
      return null;
    }

    return (
      <ContextMenu
        id="nodeContextMenu"
        menuItems={menuItems}
      />
    );
  };

  private handleFavoriteClick = (): void => {
    const { currentNode, getBookmarkByMeta, deleteBookmark, saveBookmark } = this.props;
    const bookmark = getBookmarkByMeta(currentNode.bookmarkMeta);

    if (bookmark) {
      deleteBookmark({ bookmarkID: bookmark.id });
    } else {
      saveBookmark({
        bookmarkMeta: currentNode.bookmarkMeta,
      });
    }
  };

  private handleRenameClick = (): void => {
    const { openRenameCollectionItemModal, collectionType, currentNode } = this.props;
    openRenameCollectionItemModal(collectionType, currentNode);
  };

  private handleRenameWorkspaceWithMeta = (): void => {
    const { currentNode } = this.props;
    this.props.onRenameWorkspaceWithMeta(currentNode);
  };

  private handleArchiveClick = (): void => {
    const { currentNode, unarchiveWorkspace, archiveWorkspace } = this.props;

    currentNode.isFrozen
      ? unarchiveWorkspace(currentNode.id)
      : archiveWorkspace(currentNode.id);
  };

  private handleDeleteClick = (): void => {
    const { currentNode, collectionType, openDeleteConfirm } = this.props;
    openDeleteConfirm(currentNode, collectionType);
  };

  private handleDuplicate = (): void => {
    const { availableWorkspaces, currentNode, collectionType } = this.props;
    if (currentNode.apiURI) {
      if (collectionType === CollectionTypes.databases || collectionType === CollectionTypes.taskdbs) {
        this.props.openDuplicateDatabases(currentNode as DatabaseNode, availableWorkspaces);
      } else {
        this.props.copyCollection(currentNode.apiURI, collectionType);
      }
    }
  };

  private handleWorkspaceInformation = (): void => {
    this.props.openWorkspaceInfoModal(this.props.currentNode?.meta);
  };
}

const mapDispatchToProps: DispatchProps = {
  archiveWorkspace: archiveWorkspace.request,
  unarchiveWorkspace: unarchiveWorkspace.request,
  copyCollection: copyCollectionRequest,
  saveBookmark: saveBookmark,
  deleteBookmark: deleteBookmark,
  openDeleteConfirm: modalActions.openDeleteNodeModal,
  openDuplicateDatabases: modalActions.openDuplicateDatabase,
  openRenameCollectionItemModal: modalActions.openRenameCollectionItemModal,
  openWorkspaceInfoModal: modalActions.openWorkspaceInfoModal,
  onRenameWorkspaceWithMeta: modalActions.onReNameWorkspaceWithMeta,
};

const selectors = createStructuredSelector({
  availableWorkspaces: getAllWorkspaces,
  getBookmarkByUri,
  getBookmarkByMeta: getBookmarkByMeta,
  permissions: getWorkspacePermissions,
});

export default connect(selectors, mapDispatchToProps)(NodeContextMenu);
