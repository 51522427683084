import * as React from 'react';
import { DragLayer } from 'react-dnd';
import { WithStyles, withStyles } from '@material-ui/core';

import CardWrapperDragPreview from 'components/CalendarView/ExpansionPanel/CardWrapperDragPreview';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import DragItemTypes from './DragItemTypes';
import DragItemProps from './DragItemProps';


interface XYCord {
  x: number;
  y: number;
}

interface Props extends WithStyles<typeof styles> {
  item: DragItemProps;
  itemType: DragItemTypes;
  initialOffset: XYCord;
  currentOffset: XYCord;
}

function CustomDragLayer(props: Props): JSX.Element {
  const { initialOffset, currentOffset, classes, item } = props;

  return (
    <div className={classes.root}>
      {item && <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem(props)}</div>}
    </div>
  );
}

function renderItem(props: Props): React.ReactNode {
  switch (props.itemType) {
    case DragItemTypes.CALENDAR_EVENT:
      return <CardWrapperDragPreview event={props.item.event} />;
    default:
      return null;
  }
}

function getItemStyles(initialOffset: XYCord | null, currentOffset: XYCord | null): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

type ClassKey = 'root';

const styles: Record<ClassKey, CSSProperties> = {
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 99999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
};

export default DragLayer(collect)(withStyles(styles)(CustomDragLayer));
