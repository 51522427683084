import { CSSProperties } from '@material-ui/core/styles/withStyles';

import * as Colors from 'styles/colors';

type ClassKey = 'root' | 'uploadIcon';

export const styles: Record<ClassKey, CSSProperties> = {
  root: {
    'cursor': 'pointer',
    'width': '100%',
    'flex': 1,
    'border': `2px dashed ${Colors.lightBlueGrey}`,
    'borderRadius': '4px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'transition': 'all 0.3s',
    '&:hover': {
      borderColor: Colors.lightGreyBlue,
    },
  },
  uploadIcon: {
    width: '40px',
    color: Colors.lightBlueGrey,
    marginBottom: '8px',
  },
};
