import { Dictionary } from 'lodash';

import { CommonNode } from '.';

export enum EventIcon {
  analytics = 'analytics',
  dashboard = 'dashboard',
  workspace = 'workspace',
  database = 'database',
  message = 'message',
  itemdatabases = 'itemdatabases',
  checklist = 'checklist',
  field = 'field',
  people = 'people',
  repository = 'repository',
  task = 'task',
  databasetasks = 'databasetasks',
  itemtasks = 'itemtasks',
  file = 'file',
  view = 'view'
}

export interface ActivityfeedNode extends CommonNode {
  accountId: string;             // Account ID.
  activities?: WorkflowEvent[];  // Activities.
}

export interface EventMessageToken {
  label: string;        // Label for the token.
  uri?: string | null;  // URI for the token.
}

export interface WorkflowEvent {
  aggregationCount?: number;             // Number of aggregated events.
  aggregationURI?: string;               // URI to aggregate events within.
  eventBy?: string;                      // Originator of the event.
  eventColor?: string;                   // Color associated with the event.
  eventDate: number;                     // Time of the event.
  eventFamily?: string;                  // Family for the event.
  eventType?: string;                    // Type of the event.
  nodeID?: string;                       // ID of the node on which the event occurred.
  nodeType?: string;                     // Type of the node on which the event occurred.
  nodeURI?: string;                      // URI of the node on which the event occurred.
  updatedFields?: Dictionary<string>;    // Map of updated fields.
  eventAttributes?: {
    messages?: EventMessageToken[];    // Message describing the event.
    primaryField?: string;
    node?: any;
    database?: any;
    item?: any;
    sheet?: any;
    tenant?: any;
    workspace?: any;
  };
}
