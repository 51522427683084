import React, { useState } from 'react';
import Button, { Variant as ButtonVariant } from 'components/Button';
import Logo from '../../assets/images/auth/hubsync-logo-white.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFAGeneralStyles.index.style';
import { BACK_TO_CODE_VERIFICATION, SIGN_IN_MODES } from 'pages/Auth/SignIn';

interface StateProps {
  user: User;
  mode: SIGN_IN_MODES;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
  // @ts-ignore state
  mode: (state: ReduxState) => state.mode || '',
});

const ResetMFA = (props) => {
  const { classes, onConfirmClicked, backToMFA } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);

  const tenant = window.appStore.getState().app.tenant;

  const selectedMethod = () => {
    onConfirmClicked('reset_mfa_confirmed');
  };

  const backToCodeVerification = () => {
    onConfirmClicked(BACK_TO_CODE_VERIFICATION);
  };

  const goBackToMFA = () => {
    backToMFA();
  };

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={'auth-container'+ (tenantConfig.clientName || '')}>
        <div className="auth-form-container">
          <div className="auth-logo">
            <img src={tenantConfig.logoURL ?? Logo} alt={'Hubsync'} />
          </div>
          <div className={classes.mfaFormContainer}>
            <div className={classes.topContent}>
              <h3>Multi-Factor Authentication</h3>
              <p className={classes.helperText}>
                Update your multi-factor authentication settings or enroll in a
                new method in three easy steps:
              </p>
            </div>
            <div>
              <div className={classes.textField}>
                <div className={classes.radioButtonContainer}>
                  <label className={classes.textLabel}>
                    1. Confirm your email.
                  </label>
                  <br />
                  <label className={classes.textLabel}>
                    2. Confirm your identity.
                  </label>
                  <br />
                  <label className={classes.textLabel}>
                    3. Update your multi-factor authentication method.
                  </label>
                </div>
              </div>
              <div>
                <Button
                  id="verify"
                  className={ tenantConfig.clientName ? classes.verifyActionButtonbakertilly : classes.verifyActionButton }
                  label="Continue"
                  isLoading={wrongCode}
                  variant={tenantConfig.clientName ? ButtonVariant.Secondary : ButtonVariant.Primary}
                  onClick={() => {
                    setIsLoading(true);
                    setWrongCode(false);
                    selectedMethod();
                  }}
                  disabled={isLoading}
                />

                <Button
                  id="verify"
                  className={classes.backtActionButtonReset}
                  label="Back"
                  isLoading={isLoading}
                  variant={ButtonVariant.Secondary}
                  onClick={() => {
                    setIsLoading(true);
                    setWrongCode(false);
                    backToCodeVerification();
                  }}
                  disabled={isLoading}
                />
              </div>

              <div className={classes.textResetContainer}>
                <a
                  href={tenant.apiEndpoints.zendeskTargetURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Need Help?</span>
                </a>
              </div>
            </div>
          </div>
          <Button
            id="needHelp"
            className={classes.backToMFAButton}
            label="Change MFA Method Selection"
            variant={ButtonVariant.PrimaryLink}
            onClick={() => {
              goBackToMFA();
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(ResetMFA));
