import { fork, takeLatest, call, put } from 'redux-saga/effects';

import { importFiles } from 'data/importFiles/importFile.actions';
import { createAPIHandler, RequestType, successToast } from 'utilities/saga';
import { batchJobs } from 'data/batchJobs/batchJobs.actions';
import { createImportItemsUri } from 'utilities/createUrl';

function* importFilesRequest() {
  yield createAPIHandler({
    actions: importFiles,
    requestType: RequestType.Post,
    takeFn: takeLatest,
    buildHeaders: (action) => ({ 'Content-Type': action.payload.contentType }),
    buildData: (action) => action.payload.data,
    buildUrl: (action) => {
      return createImportItemsUri(action.payload.uri);
    },
    * onSuccess(payload) {
      yield call(successToast, 'Start importing files');
      yield put(importFiles.success());
      yield put(batchJobs.request(payload));
    },
  });
}

export default function* () {
  yield fork(importFilesRequest);
}
