import * as React from 'react';
import moment from 'moment';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { styles } from 'components/TilesView/TileItem.style';
import AccountChip from 'components/AccountChip/AccountChip';
import FavoriteButton from 'components/FavoriteButton/FavoriteButton';
import { withURLParams } from 'containers/withURLParams';
import NodeContextMenu from 'components/NodeContextMenu/NodeContextMenu';

import { getCollectionTypeFromUrl, stripDomain } from 'utilities/createUrl';
import Typography, { Variant } from 'components/Typography';
import svgIcons from 'styles/svgIcons';
import {
  CollectionTypes,
  CollectionTypeWithTitle,
  NodeWithTitle,
} from 'types/schema';
import { Title, toolTipStyle } from './TilesList.style';
import { replaceAmpCodeBySymbol } from 'utilities/text';
interface OwnProps {
  id?: string;
  node: NodeWithTitle;
  includeDescendantsCount?: boolean;
  descendantsLabel?: string;
  permissions: string[];
  appPermissions: string[];
  collectionType: CollectionTypes;
  collectionName: string;
}

type Props = OwnProps &
  WithStyles<typeof styles> &
  RouteComponentProps<string[]>;

/*
    Constant to check the workspace length name in order
    to render the workspace name in the tile
  */
const TITLE_LENGTH = 18;

export class TileItem extends React.PureComponent<Props> {
  public render = (): JSX.Element | null => {
    const {
      classes,
      node,
      location,
      collectionName: collectionNameProp,
      id,
      permissions,
      appPermissions,
    } = this.props;
    const { bookmarkMeta } = node;
    const collectionType = getCollectionTypeFromUrl(location.pathname);
    const validCollectionType: CollectionTypeWithTitle | false =
      (collectionType === CollectionTypes.workspaces ||
        collectionType === CollectionTypes.databases ||
        collectionType === CollectionTypes.taskdbs) &&
      collectionType;

    if (!validCollectionType) {
      return null;
    }
    if (bookmarkMeta) {
      bookmarkMeta.title = node.meta?.clientName
        ? node.meta.clientName
        : node.title;
    }

    const collectionName = collectionNameProp.toLowerCase();

    return (
      <Card id={`${id}_card`} className={classes.root}>
        {this.renderNavLink(
          <React.Fragment>
            <CardHeader
              id={`${id}_cardHeader`}
              className={classes.headerRoot}
              classes={{
                root: classes.headerRoot,
                action: classes.headerAction,
              }}
              title={this.renderTitle()}
              action={
                <div
                  id={`${id}_cardContextMenu`}
                  className={classes.actions}
                  onClick={(event) => event.preventDefault()}
                >
                  {permissions.includes(`${collectionName}:update`) &&
                  (
                    <NodeContextMenu
                      currentNode={{
                        ...node,
                        permissions,
                      }}
                      collectionType={validCollectionType}
                    />
                  )}
                  {appPermissions.includes(`bookmarks:create`) && (
                    <FavoriteButton
                      id={`${id}_btnFavorite`}
                      bookmarkMeta={bookmarkMeta}
                    />
                  )}
                </div>
              }
            />
            {this.renderTileContent(collectionType)}
          </React.Fragment>,
        )}
      </Card>
    );
  };

  private clientId = (): string => {
    const { node } = this.props;
    const clientId = node.meta?.['clientId'];
    return clientId ? `Client ID: ${clientId}` : '';
  };

  private clientName = (): string => {
    const { node } = this.props;
    const clientName = node.meta?.['clientName'];
    return clientName ? `${clientName}` : `${node.title}`;
  };

  private tittleWithTooltip = (): JSX.Element => {
    if (this.clientName().length < TITLE_LENGTH) {
      return (
        <Tooltip
          title={<h1 style={toolTipStyle}>{replaceAmpCodeBySymbol(this.clientName())}</h1>}
          placement="top"
        >
          <Title>{replaceAmpCodeBySymbol(this.clientName())}</Title>
        </Tooltip>
      );
    } else {
      return <Title>{replaceAmpCodeBySymbol(this.clientName())}</Title>;
    }
  };

  private renderTitle = (): JSX.Element => (
    <React.Fragment>
      <div className={this.props.classes.headerTitle}>
        <Box>{this.tittleWithTooltip()}</Box>
      </div>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
      >
        <Typography variant={Variant.CardText}>{this.clientId()}</Typography>
      </Box>
    </React.Fragment>
  );
  private renderTileContent = (
    collectionType: CollectionTypes | undefined,
  ): JSX.Element => {
    const { classes, node, id } = this.props;
    const { modifiedAt, modifiedByUser } = node;
    const isDatabase = node.apiURI.match(
      /\/(databases|taskdbs)\/[a-zA-Z0-9]+$/g,
    );
    const idPrefix = `${id}_${collectionType}`;

    return (
      <CardContent id={`${idPrefix}_cardContent`} className={classes.content}>
        <Box id={`${idPrefix}_modifiedBy`} className={classes.field} mt={5}>
          <Typography variant={Variant.CardText} className={classes.fieldLabel}>
            Last Modified By
          </Typography>
          <br />
          <AccountChip user={modifiedByUser?.[0]} />
        </Box>

        <Box id={`${idPrefix}_modifiedAt`} className={classes.field} ml="22px">
          <Typography variant={Variant.CardText}>
            {moment(modifiedAt).format('MM/DD/YY')}
          </Typography>
          <Typography variant={Variant.CardText} className={classes.timeText}>
            {moment(modifiedAt).format('hh:mmA')}
          </Typography>
        </Box>

        {isDatabase && this.renderDescendantsCount(idPrefix)}

        {/* {isDatabase && (
          <div className={classes.authorChipInitials}>
            <AccountChip initials={true} accountId={modifiedBy} />
          </div>
        )} */}
      </CardContent>
    );
  };

  private renderNavLink = (children: React.ReactNode) => {
    const { node: { linkURI, uri }, id, classes } = this.props;

    const databaseLink = linkURI && linkURI.replace('files', 'databases');

    // Dyanmo config to redirect to databases instead of files
    const shouldUseDabaseLink = window.appStore?.getState().app?.tenant?.maintenance?.clientConfigs?.disableFilesTab ?? false;

    const finalLink = shouldUseDabaseLink ? databaseLink : linkURI;
    return (
      <Link id={`${id}_navLink`} to={stripDomain(finalLink || uri)} className={classes.rootLink}>
        {children}
      </Link>
    );
  };

  private renderDescendantsCount = (idPrefix: string): JSX.Element | null => {
    const { node, descendantsLabel, classes } = this.props;
    const itemsCount = node['itemsCount'] || 0;

    const singularUnitLabel =
      descendantsLabel || node.primaryDescendantSingularLabel || 'element';
    const pluralUnitLabel = `${singularUnitLabel}s`;
    const unitLabel =
      node.primaryDescendantCollectionCount === 1
        ? singularUnitLabel
        : pluralUnitLabel;

    return (
      <div id={`${idPrefix}_descendantsCount`} className={classes.itemCount}>
        <svgIcons.Records className={classes.itemCountIcon} />
        <Typography variant={Variant.CardText}>
          {`${itemsCount} ${unitLabel}`}
        </Typography>
      </div>
    );
  };
}

const enhance = compose<Props, OwnProps>(withURLParams, withStyles(styles));

export default enhance(TileItem);

type EmptyTileItemProps = WithStyles<typeof styles>;

export const EmptyTileItem = withStyles(styles)(
  ({ classes }: EmptyTileItemProps): JSX.Element => (
    <div id="emptyTileItem" className={classes.emptyTile} />
  ),
);
