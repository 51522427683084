export default function starFilledSVG() {
  const starFilled = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  starFilled.setAttribute('height', '12px');
  starFilled.setAttribute('width', '12px');
  starFilled.setAttribute('viewBox', '0 0 12 12');

  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
  title.innerText = 'background';

  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('fill', 'none');
  rect.setAttribute('height', '14');
  rect.setAttribute('width', '14');
  rect.setAttribute('y', '-1');
  rect.setAttribute('x', '-1');
  g.appendChild(title);
  g.appendChild(rect);
  starFilled.appendChild(g);

  const g2 = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  const g3 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g3.setAttribute('fill-rule', 'evenodd');
  g3.setAttribute('fill', 'none');


  const g4 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g3.appendChild(g4);

  const g5 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g5.setAttribute('fill', 'currentcolor');
  g4.append(g5);

  const g6 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g5.appendChild(g6);

  const g7 = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g6.appendChild(g7);

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttributeNS(null, 'd', 'm5.534594,10.495072l-2.149576,1.130099c-0.488845,0.257001 -1.093472,0.069055 -1.350473,-0.41979c-0.102339,-0.19466 -0.137654,-0.417628 -0.100477,-0.634386l0.410533,-2.393588c0.055638,-0.324394 -0.051911,-0.655395 -0.287597,-0.885132l-1.739043,-1.695148c-0.395484,-0.385502 -0.403576,-1.018615 -0.018075,-1.414098c0.153509,-0.157484 0.354651,-0.259971 0.572288,-0.291596l2.403299,-0.349219c0.32571,-0.047329 0.607276,-0.251898 0.752938,-0.547042l1.074788,-2.177758c0.244422,-0.495254 0.844048,-0.698593 1.339302,-0.454171c0.197212,0.097331 0.35684,0.256958 0.45417,0.454171l1.074788,2.177758c0.145662,0.295144 0.427228,0.499713 0.752938,0.547042l2.403299,0.349219c0.546545,0.079418 0.925226,0.586861 0.845809,1.133406c-0.031625,0.217637 -0.134112,0.418779 -0.291596,0.572288l-1.739043,1.695148c-0.235686,0.229737 -0.343235,0.560738 -0.287597,0.885132l0.410533,2.393588c0.093361,0.544336 -0.272227,1.061292 -0.816564,1.154653c-0.216757,0.037177 -0.439725,0.001862 -0.634386,-0.100477l-2.149576,-1.130099c-0.291324,-0.153158 -0.639358,-0.153158 -0.930682,0z');
  g7.appendChild(path);

  g2.appendChild(g3);
  starFilled.appendChild(g2);

  return starFilled;
}
