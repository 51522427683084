import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button, { Variant as ButtonVariant } from 'components/Button';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import {
  createChecklist,
  fetchChecklists,
  resetChecklists,
  updateChecklist,
} from 'data/collections/collections.actions.new';
import * as types from 'data/collections/collections.types';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import svgIcons from 'styles/svgIcons';

import Checklist from './Checklist';
import { styles } from './Checklist.style';

export enum ChecklistStates {
  BROWSE = 'browse',
  CREATE = 'create'
}

interface OwnProps {
  itemId: string;
  permissions?: string[];
}

interface State {
  checklistState: ChecklistStates;
  checklist: types.Checklist;
}

interface StateProps {
  checklists: types.Checklist[];
}

interface DispatchProps {
  resetChecklists: () => void;
  fetchChecklists: (itemId: string) => void;
  createChecklist: (checklist: types.Checklist, itemId: string) => void;
  updateChecklist: (checklist: types.Checklist, itemId: string) => void;
}

const initialState: State = {
  checklistState: ChecklistStates.BROWSE,
  checklist: {
    id: '',
    name: '',
    items: [],
  },
};

type Props = OwnProps & WithStyles<typeof styles> & StateProps & DispatchProps & URLInjectedProps;

export class ChecklistContainer extends React.Component<Props> {
  private checklistNameMaxLength = 100;
  state = { ...initialState };

  public componentDidMount(): void {
    this.props.fetchChecklists(this.props.itemId);
  }

  public componentWillUnmount(): void {
    this.props.resetChecklists();
  }

  public render(): JSX.Element | null {
    let wrapperClassname = this.props.classes.checklistsWrapper;
    if (this.props.checklists.length && this.state.checklistState === ChecklistStates.BROWSE) {
      wrapperClassname += ` ${this.props.classes.marginBottom0}`;
    }

    if (!this.props.checklists.length && !(this.props.permissions || []).includes('checklists:create')) {
      return null;
    }

    return (
      <div className={wrapperClassname}>
        {(this.props.permissions || []).includes('checklists:create') && this.state.checklistState === ChecklistStates.BROWSE && this.props.checklists.length === 0 && (
          <Button
            id="btnAddChecklist"
            label="Add Checklist"
            variant={ButtonVariant.BackgroundLink}
            icon={svgIcons.Add}
            onClick={this.addChecklist}
            style={{ marginLeft: 175 }}
          />
        )}

        {this.props.checklists.map((checklist, index) => {
          return (
            <Checklist
              key={index}
              itemId={this.props.itemId}
              checklist={checklist}
              addChecklist={this.addChecklist}
              permissions={this.props.permissions}
            />
          );
        })}

        {this.state.checklistState === ChecklistStates.CREATE && (
          <div>
            <div className={`${this.props.classes.rowWithSpace} ${this.props.classes.fixedHeight} ${this.props.classes.checklist} ${this.props.classes.activeItem} ${(this.state.checklist.name.length > this.checklistNameMaxLength ? this.props.classes.checklistMaxLenth : '')}`}>
              <div className={`${this.props.classes.rowWithSpace}`}>
                <div className={this.props.classes.row}>
                  <svgIcons.Checklist className={this.props.classes.checklistIcon} />
                  <div className={this.props.classes.textFieldWrapper}>
                    <TextField
                      value={this.state.checklist.name}
                      fullWidth={true}
                      className={this.props.classes.textFieldRoot}
                      inputProps={{ className: this.props.classes.textField }}
                      onChange={this.onChange}
                      onKeyDown={this.onKeyDown}
                      onBlur={this.onBlur}
                      onInput = {(e) =>{
                        if (e?.target['value'].toString().length > this.checklistNameMaxLength+1) {
                          e.target['value'] = e?.target['value'].slice(0, this.checklistNameMaxLength);
                        }
                      }}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.checklist.name.length > this.checklistNameMaxLength && (
              <div className={`${this.props.classes.checklistAlert}`}>
                <span>Maximum {this.checklistNameMaxLength} characteres are allowed.</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  private onBlur = (): void => {
    this.setState({ checklistState: ChecklistStates.BROWSE, checklist: { ...initialState.checklist } });
  };

  private onKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.saveChecklist();
        return;
      case 'Escape':
        this.setState({ checklistState: ChecklistStates.BROWSE, checklist: { ...initialState.checklist } });
        event.stopPropagation();
        return;
      default: break;
    }
  };

  private addChecklist = (): void => {
    this.setState({
      checklistState: ChecklistStates.CREATE,
      checklist: {
        ...this.state.checklist,
        id: new Date().toISOString(),
      },
    });
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      checklist: {
        ...this.state.checklist,
        name: event.target.value,
      },
    });
  };

  private saveChecklist = (): void => {
    setTimeout(() => {
      const name = this.state.checklist.name.trim();
      if (name.length < 1 || name.length > this.checklistNameMaxLength) return;
      this.props.createChecklist(this.state.checklist, this.props.itemId);
      this.setState({ ...initialState });
    }, 100);
  };
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    checklists: state.collections.checklists,
  };
};

const mapDispatchToProps: DispatchProps = {
  resetChecklists,
  fetchChecklists,
  createChecklist,
  updateChecklist,
};

export default connect(mapStateToProps, mapDispatchToProps)(withURLParams((withStyles(styles)(ChecklistContainer))));
