import { FieldType } from 'types/response/fieldNode';

export function getIconClass(columnType: FieldType): string {
  const prefix = 'ag-header-icon-';
  const hashIcon = 'number';
  const dateIcon = 'date';
  const textIcon = 'text';
  const columnTypeToIconMap = {
    [FieldType.Account]: 'person',
    [FieldType.Attachment]: 'attachment',
    [FieldType.Float]: hashIcon,
    [FieldType.Integer]: hashIcon,
    [FieldType.Barcode]: 'barcode',
    [FieldType.Boolean]: 'checkbox',
    [FieldType.Checklist]: 'single-select',
    [FieldType.Singlechoice]: 'single-select',
    [FieldType.Multiplechoice]: 'multiple-select',
    [FieldType.Currency]: 'currency',
    [FieldType.Date]: dateIcon,
    [FieldType.DateTime]: dateIcon,
    [FieldType.Email]: 'email',
    [FieldType.FileExtension]: 'empty', // TODO this column have no icon in current design.
    [FieldType.FileName]: textIcon,
    [FieldType.Multilinetext]: 'multiline-text',
    [FieldType.Singlelineoftext]: textIcon,
    [FieldType.Percent]: 'percent',
    [FieldType.Phone]: 'phone',
    [FieldType.Button]: 'button',
    [FieldType.Rating]: 'rating',
    [FieldType.Tag]: textIcon,
    [FieldType.Url]: 'url',
  };

  return `${prefix}${columnTypeToIconMap[columnType]}` || 'icon-exclamation-circle';
}

export function getColumnHeaderClass(type: FieldType): string {
  return `collection-icon ag-header-icon ${getIconClass(type)}`;
}
