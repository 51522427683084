import Dialog from '@material-ui/core/Dialog';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { selecteItemNodeSelector } from 'data/ui/gridSelection/gridSelection.selectors';
import { isModalOpened } from 'data/ui/modal/modal.seletors';
import { ModalNames } from 'data/ui/modal/modal.types';
import { closeModal } from 'data/ui/modal/modal.actions';
import { BaseDialogContent } from 'components/Modals/styles';
import AccountChip from 'components/AccountChip';
import DialogTitle from 'components/Modals/DialogTitle';
import Property from 'components/PropertiesModal/Property';
import { State } from 'reducers';
import svgIcons from 'styles/svgIcons';
import { DocumentNode } from 'types/response/documentNode';
import { getFormattedFileSize } from 'utilities/files';

import { Wrapper } from './PropertiesModal.style';
import getFileIcon from 'styles/fileExtension';

interface StateProps {
  document?: DocumentNode;
  isOpened: boolean;
}

interface DispatchProps {
  closeModal: () => void;
}

type Props
  = StateProps
  & DispatchProps
  & WithStyles<ClassKey>
  ;

function renderDate(date?: number): string {
  if (!date) {
    return '';
  }

  return moment(date).format('MM/DD/YYYY h:mm a');
}

function PropertiesModal(props: Props): JSX.Element | null {
  const { classes, closeModal, document, isOpened } = props;

  if (!document) {
    return null;
  }

  const Icon = getFileIcon(document.fileExtension);

  return (
    <Dialog
      open={isOpened}
      onClose={closeModal}
    >
      <DialogTitle onClose={closeModal}>Properties</DialogTitle>
      <BaseDialogContent>
        <Wrapper>
          <Property name="Name">
            {document.fileName}
          </Property>
          <Property name="Type">
            {document.isFolder
              ? <svgIcons.FolderNew className={classes.icon} />
              : <Icon className={classes.icon} />
            }
            {document.isFolder ? 'Folder' : document.fileExtension}
          </Property>
          {typeof document.size === 'number' &&
          <Property name="Size">
            {getFormattedFileSize(document.size)}
          </Property>
          }
          <Property name="Location">
            <svgIcons.FolderNew className={classes.icon} />
            Files
          </Property>
          <Property name="Author">
            <AccountChip
              initials
              showName
              accountId={document.createdBy}/>
          </Property>
          <Property name="Last Modified By">
            <AccountChip
              initials
              showName
              accountId={document.createdBy}/>
          </Property>
          <Property name="Created">
            {renderDate(document.createdDate)}
          </Property>
          <Property name="Last Modified">
            {renderDate(document.modifiedDate)}
          </Property>
        </Wrapper>
      </BaseDialogContent>
    </Dialog>
  );
}

const mapStateToProps = createStructuredSelector<State, StateProps>({
  document: selecteItemNodeSelector,
  isOpened: isModalOpened(ModalNames.properties),
});

const actionCreators: DispatchProps = {
  closeModal: () => closeModal(ModalNames.properties),
};

type ClassKey = 'icon';

const styles = withStyles<ClassKey>({
  icon: {
    height: '20px',
    width: '20px',
    marginRight: '8px',
  },
});

const enhance = compose<Props, {}>(
  styles,
  connect(mapStateToProps, actionCreators),
);

export default enhance(PropertiesModal);
