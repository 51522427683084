import action from 'utilities/action-creator';
import produce, { DraftArray } from 'immer';
import { takeLatest, put } from 'redux-saga/effects';
import { get, post, patch, deleteReq } from 'utilities/httpRequests';
import moment from 'moment';
import { actions as unseenCountActions } from 'data/notifications/unseen-count';

/**
 * CONSTANTS
 */
const FETCH_REQUEST = 'NOTIFICATIONS/FETCH';
const SET_FETCH_ERROR = 'NOTIFICATIONS/SET_FETCH_ERROR';
const PUSH_ITEMS = 'NOTIFICATIONS/PUSH_ITEMS';
const SET_IS_LOADING = 'NOTIFICATIONS/SET_IS_LOADING';
const SET_PAGINATED_VALUES = 'NOTIFICATIONS/SET_PAGINATED_VALUES';
const SET_ACTIVE_FILTER = 'NOTIFICATIONS/SET_ACTIVE_FILTER';
const SEEN_ALL_REQUEST = 'NOTIFICATIONS/SEEN_ALL_REQUEST';
const MARK_NOTIFICATIONS_AS_SEEN = 'NOTIFICATIONS/MARK_NOTIFICATIONS_AS_SEEN';
const RESET_ITEMS = 'NOTIFICATIONS/RESET_ITEMS';
const SEEN_REQUEST = 'NOTIFICATIONS/SEEN_REQUEST';
const UN_SEEN_REQUEST = 'NOTIFICATIONS/UN_SEEN_REQUEST';
const MARK_NOTIFICATION_AS_SEEN = 'NOTIFICATIONS/MARK_NOTIFICATION_AS_SEEN';
const MARK_NOTIFICATION_AS_UN_SEEN = 'NOTIFICATIONS/MARK_NOTIFICATION_AS_UN_SEEN';
const REMOVE_ITEM = 'NOTIFICATIONS/REMOVE_ITEM';
const RESET = 'NOTIFICATIONS/RESET';
const DELETE_REQUEST = 'NOTIFICATIONS/DELETE_REQUEST';
const DELETE_ALL_REQUEST = 'NOTIFICATIONS/DELETE_ALL_REQUEST';
const DELETE_ALL_ERROR = 'NOTIFICATIONS/DELETE_ALL_ERROR';

const DEFAULT_PAGE_NUMBER = 0;
const ITEMS_PER_PAGE = 10;

export enum filterType {
  all = 'all',
  unread = 'unread',
  mentions = 'mentions',
  reminders = 'reminders',
  files = 'files',
}

/**
 * TYPES
 */
export interface Notification {
  _id: string;
  seenAt: number;
  createdAt: number;
  type: string;
  references: any;
}

export interface State {
  items: DraftArray<Notification[]>;
  pageNumber: number | undefined;
  hasNextPage: boolean;
  activeFilter: string;
  isLoading: boolean;
  fetchError: Error | undefined;
  deleteAllError: Error | undefined;
}

export const initialState: State = {
  items: [],
  isLoading: false,
  fetchError: undefined,
  pageNumber: 0,
  hasNextPage: false,
  activeFilter: filterType.all,
  deleteAllError: undefined,
};

/**
 * REDUCER
 */
export const reducer = (state = initialState, action): State => {
  switch (action.type) {
    case PUSH_ITEMS: {
      return produce(state, draft => {
        const items = action.payload as DraftArray<Array<Notification>>;
        draft.items = [...draft.items, ...items];
      });
    }
    case SET_FETCH_ERROR: {
      return produce(state, draft => {
        draft.fetchError = action.payload;
      });
    }
    case SET_IS_LOADING: {
      return produce(state, draft => {
        draft.isLoading = action.payload;
      });
    }
    case SET_PAGINATED_VALUES: {
      return produce(state, draft => {
        draft.pageNumber = action.payload.pageNumber;
        draft.hasNextPage = action.payload.hasNextPage;
      });
    }
    case SET_ACTIVE_FILTER: {
      return produce(state, draft => {
        draft.activeFilter = action.payload;
      });
    }
    case MARK_NOTIFICATIONS_AS_SEEN: {
      return produce(state, draft => {
        draft.items.forEach((item: Notification) => {
          item.seenAt = moment().unix();
        });
      });
    }
    case MARK_NOTIFICATION_AS_SEEN: {
      return produce(state, draft => {
        const itemIndex = draft.items.findIndex((item: Notification) => (item._id === action.payload));
        if (itemIndex !== -1) {
          (draft.items[itemIndex] as Notification).seenAt = moment().unix();
        }
      });
    }
    case MARK_NOTIFICATION_AS_UN_SEEN: {
      return produce(state, draft => {
        const itemIndex = draft.items.findIndex((item: Notification) => (item._id === action.payload));
        if (itemIndex !== -1) {
          delete (draft.items[itemIndex] as Notification).seenAt;
        }
      });
    }
    case REMOVE_ITEM: {
      return produce(state, draft => {
        const itemIndex = draft.items.findIndex((item: Notification) => (item._id === action.payload));
        if (itemIndex !== -1) {
          draft.items.splice(itemIndex, 1);
        }
      });
    }
    case DELETE_ALL_ERROR: {
      return produce(state, draft => {
        draft.deleteAllError = action.payload;
      });
    }
    case RESET_ITEMS: {
      return produce(state, draft => {
        draft.items = [];
      });
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

/**
 * ACTIONS
 */
export const actions = {
  fetch: (payload?: { pageNumber?: number; pageSize?: number }) => action(FETCH_REQUEST, { payload }),
  setFetchError: (error) => action(SET_FETCH_ERROR, { payload: error }),
  pushItems: (notifications) => action(PUSH_ITEMS, { payload: notifications }),
  setIsLoading: (isLoading) => action(SET_IS_LOADING, { payload: isLoading }),
  setPaginatedValues: (pageNumber, hasNextPage) => action(SET_PAGINATED_VALUES, { payload: { pageNumber, hasNextPage } }),
  setActiveFilter: (payload) => action(SET_ACTIVE_FILTER, { payload }),
  seenAllRequest: () => action(SEEN_ALL_REQUEST),
  markNotificationsAsSeen: () => action(MARK_NOTIFICATIONS_AS_SEEN),
  resetItems: () => action(RESET_ITEMS),
  resetNotifications: () => action(RESET),
  seenRequest: (id) => action(SEEN_REQUEST, { payload: id }),
  unSeenRequest: (id) => action(UN_SEEN_REQUEST, { payload: id }),
  markNotificationAsSeen: (id) => action(MARK_NOTIFICATION_AS_SEEN, { payload: id }),
  markNotificationAsUnSeen: (id) => action(MARK_NOTIFICATION_AS_UN_SEEN, { payload: id }),
  removeItem: (id) => action(REMOVE_ITEM, { payload: id }),
  delete: (id) => action(DELETE_REQUEST, { payload: id }),
  deleteAll: () => action(DELETE_ALL_REQUEST),
  setDeleteAllError: (payload) => action(DELETE_ALL_ERROR, { payload }),
};

/**
 * SAGAS
*/
export const sagas = {
  * request(action) {
    yield put(actions.setIsLoading(true));
    try {
      const response = yield (get(buildURLFromPayload(action.payload)));
      const { items, pageNumber, hasNextPage } = response.body;
      yield put(actions.pushItems(items));
      yield put(actions.setPaginatedValues(pageNumber, hasNextPage));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * seenAllRequest(_action) {
    try {
      yield (post({ url: '/notifications/seen-all?api_version=2', data: {} }));
      yield put(actions.markNotificationsAsSeen());
      yield put(unseenCountActions.setCount(0));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * seenRequest(action) {
    try {
      yield (patch({ url: `/notifications/${action.payload}/seen?api_version=2`, data: {} }));
      yield put(actions.markNotificationAsSeen(action.payload));
      yield put(unseenCountActions.inc(-1));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * unSeenRequest(action) {
    try {
      yield (patch({ url: `/notifications/${action.payload}/unseen?api_version=2`, data: {} }));
      yield put(actions.markNotificationAsUnSeen(action.payload));
      yield put(unseenCountActions.inc(1));
    } catch (error) {
      yield put(actions.setFetchError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * deleteRequest(action) {
    try {
      // RESET loading state
      yield put(actions.setIsLoading(true));

      // REQUEST to delete single notification
      yield (deleteReq(`/notifications/${action.payload}?api_version=2`));

      // REMOVE notification from state on success
      yield put(actions.removeItem(action.payload));
    } catch (error) {
      yield put(actions.setDeleteAllError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
  * deleteAllRequest() {
    try {
      // RESET
      yield put(actions.setDeleteAllError(undefined));
      yield put(actions.setIsLoading(true));

      // REQUEST
      yield (deleteReq('/notifications?api_version=2'));

      // SET DATA
      yield put(actions.resetItems());
      yield put(unseenCountActions.setCount(0));
    } catch (error) {
      yield put(actions.setDeleteAllError(error));
    } finally {
      yield put(actions.setIsLoading(false));
    }
  },
};

export const watcher = function* w() {
  yield takeLatest(FETCH_REQUEST, sagas.request);
  yield takeLatest(SEEN_ALL_REQUEST, sagas.seenAllRequest);
  yield takeLatest(SEEN_REQUEST, sagas.seenRequest);
  yield takeLatest(UN_SEEN_REQUEST, sagas.unSeenRequest);
  yield takeLatest(DELETE_ALL_REQUEST, sagas.deleteAllRequest);
  yield takeLatest(DELETE_REQUEST, sagas.deleteRequest);
};


function buildURLFromPayload(payload) {
  if (payload) {
    const { pageNumber=DEFAULT_PAGE_NUMBER, pageSize=ITEMS_PER_PAGE } = payload;

    return `/notifications?api_version=2&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
  return `/notifications?api_version=2&pageNumber=${DEFAULT_PAGE_NUMBER}&pageSize=${ITEMS_PER_PAGE}`;
}
