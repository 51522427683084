import * as actions from './actions';
import * as types from './types';
import * as _ from 'lodash';
import LoadingState from '../LoadingState';

// eslint-disable-next-line @typescript-eslint/no-var-requires
let moment = require('moment');
if (moment.default) {
  moment = moment.default;
}

export const defaultChannel: types.ChannelState = {
  messages: [],
  messagesCount: 0,
  messagesGroupedByDay: [],
};

export const initialState: types.MessagesState = {
  currentChannelId: '',
  channels: {},
  messages: [],
  messagesCount: 0,
  messagesGroupedByDay: [],
  loadingState: LoadingState.Unloaded,
  error: '',
};

export default (state: types.MessagesState = initialState, action) => {
  switch (action.type) {
    case actions.setCurrentChannel.type:
      return {
        ...state,
        currentChannelId: action.payload,
        channels: {
          ...state.channels,
          [action.payload]: { ...defaultChannel },
        },
      };
    case actions.getMessages.pending.type:
      return {
        ...state,
        loadingState: LoadingState.Loading,
      };
    case actions.getMessages.fulfilled.type:
      return {
        ...state,
        loadingState: LoadingState.Loaded,
      };
    case actions.getMessages.rejected.type:
      return {
        ...state,
        loadingState: LoadingState.Error,
      };
    case actions.prependMessage.type:
      return {
        ...state,
        channels: {
          ...state.channels,
          [state.currentChannelId]: {
            ...state.channels[state.currentChannelId],
            messages: [...action.payload, ...(state.channels?.[state.currentChannelId]?.messages || [])],
            messagesGroupedByDay: groupMessagesByDay([...action.payload, ...(state.channels?.[state.currentChannelId]?.messages || [])]),
          },
        },
      };
    case actions.appendMessage.type: {
      const channel = state.channels[action.payload.channelId] || { ...defaultChannel };
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.payload.channelId]: {
            ...channel,
            messages: [...channel.messages, action.payload],
            messagesGroupedByDay: groupMessagesByDay([...channel.messages, action.payload]),
          },
        },
      };
    }
    case actions.setMessages.type: {
      return {
        ...state,
        channels: {
          ...state.channels,
          [state.currentChannelId]: {
            ...state.channels[state.currentChannelId],
            messages: action.payload,
            messagesGroupedByDay: groupMessagesByDay(action.payload),
          },
        },
      };
    }
    case actions.setMessageCount.type:
      return {
        ...state,
        channels: {
          ...state.channels,
          [state.currentChannelId]: {
            ...state.channels[state.currentChannelId],
            messagesCount: action.payload,
          },
        },
      };
    case actions.setMessageError.type:
      return {
        ...state,
        error: action.error,
      };
    case actions.resetMessages.type:
      return initialState;
    default:
      return state;
  }
};

const groupMessagesByDay = (messages: types.Message[]): types.Message[][] => {
  const groupedMessages = _.groupBy(messages, (item: types.Message) => moment(item.createdOn, 'YYYY-MM-DD').format('MMMM DD, YYYY')) as unknown as [];
  return Object.values(groupedMessages);
};
