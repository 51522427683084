// ------------------------------------
// COLLECTION CONSTANTS
// ------------------------------------
export const CLEAN_COLLECTION = 'CLEAN_COLLECTION';
export const CREATE_NODE_ERROR = 'CREATE_NODE_ERROR';
export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
export const CREATE_NODE_REQUEST = 'CREATE_NODE_REQUEST';
export const DELETE_NODE_ERROR = 'DELETE_NODE_ERROR';
export const DELETE_NODE_REQUEST = 'DELETE_NODE_REQUEST';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DUPLICATE_NODE = 'DUPLICATE_NODE';
export const CREATE_FIELD = 'CREATE_FIELD';
export const DUPLICATE_FIELD = 'DUPLICATE_FIELD';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SAVE_FIELD_SUCCESS = 'SAVE_FIELD_SUCCESS';
export const SAVE_FIELD_ERROR = 'SAVE_FIELD_ERROR';
export const DELETE_FIELD = 'DELETE_FIELD';
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS';
export const COPY_NODE_REQUEST = 'COPY_NODE_REQUEST';
export const COPY_NODE_SUCCESS = 'COPY_NODE_SUCCESS';
export const COPY_NODE_ERROR = 'COPY_NODE_ERROR';
export const FETCH_CHECKLISTS = 'FETCH_CHECKLISTS';
export const SET_CHECKLISTS = 'SET_CHECKLISTS';
export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const APPEND_CHECKLIST = 'APPEND_CHECKLIST';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const CREATE_CHECKLIST_ITEM = 'CREATE_CHECKLIST_ITEM';
export const DELETE_CHECKLIST_ITEM = 'DELETE_CHECKLIST_ITEM';
export const UPDATE_CHECKLIST_ITEM = 'UPDATE_CHECKLIST_ITEM';
export const SET_CHECKLIST_ERROR = 'SET_CHECKLIST_ERROR';
export const RESET_CHECKLISTS = 'RESET_CHECKLISTS';
export const UPDATE_RECORDS = 'UPDATE_RECORDS';
export const UPDATE_PRIMARY_FIELD = 'UPDATE_PRIMARY_FIELD';
export const SET_FIELD_SUMMARY = 'SET_FIELD_SUMMARY';
export const SET_ID_RENDERER_PARAMS = 'SET_ID_RENDERER_PARAMS';
export const PATCH_FIELD_SUMMARY = 'PATCH_FIELD_SUMMARY';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const UPDATE_ITEMS_FIELD_BY_ID = 'UPDATE_ITEMS_FIELD_BY_ID';
export const SET_CURRENT_VIEW_ID = 'SET_CURRENT_VIEW_ID';
export const UNDO_ITEM = 'UNDO_ITEM';
export const REDO_ITEM = 'REDO_ITEM';
export const SET_ITEM_HISTORY = 'SET_ITEM_HISTORY';
export const REMOVE_FIELD_REVISIONS = 'REMOVE_FIELD_REVISIONS';
export const START_DRAG_ITEM = 'START_DRAG_ITEM';
export const STOP_DRAG_ITEM = 'STOP_DRAG_ITEM';
export const LOCK_COLUMN = 'LOCK_COLUMN';
export const LOAD_ITEMS_PAGINATE = 'LOAD_ITEMS_PAGINATE';
