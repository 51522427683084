import * as React from 'react';
import { connect } from 'react-redux';

import * as ModalActions from 'data/modals/actions';
import * as SheetActions from 'data/sheets/sheets.actions';

import Confirm from '../Confirm';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
  deleteSheet: typeof SheetActions.deleteSheet.request;
}

interface OwnProps {
  sheetId: string;
}

export type Props = OwnProps & DispatchProps;

const DeleteSheet = (props: Props): JSX.Element => {
  const handleConfirm = (): void => {
    props.deleteSheet({ id: props.sheetId });
  };

  return (
    <Confirm
      title="Are you sure you want to delete this sheet?"
      close={props.close}
      onConfirm={handleConfirm}
      confirmLabel="Delete"
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  deleteSheet: SheetActions.deleteSheet.request,
};

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(DeleteSheet);
