import _ from 'lodash';
import { slate } from 'styles/colors';
import { GRID_ROW_HEIGHT } from 'styles/constants';
import { allowToModify } from 'utilities/permissions';

import { filtersSelector } from '../../../data/grid-options/gridOptions.selector';
import { typeIcon } from '../../../pages/Files/Renderers/CellRenderers/IconRenderer';
import BaseRenderer from '../BaseRendererPrototype';
import GenericTextRenderer from '../GenericTextRenderer';


const GET_ATTACHMENTS_KEY = 'attachments.byItemId';

function AttachmentRenderer() {
  return true;
}

AttachmentRenderer.prototype = Object.create(BaseRenderer.prototype);

const getAttachmentIds = (attachments, column) => {
  const attachmentId = [];
  if (attachments && attachments.byId) {
    Object.keys(attachments.byId)
    // ordering based on sort index
      .sort((fileId1, fileId2) => {
        if (attachments && attachments.byId && column) {
          const attachment1 = attachments.byId[fileId1];
          const attachment2 = attachments.byId[fileId2];

          return attachment1?.sort - attachment2?.sort;
        }

        return 0;
      })
      .forEach((id) => {
        if (attachments && attachments.byId && column) {
          const attachment = attachments.byId[id];
          const attachmentReference = attachment['references'] || {};
          const attachmentFieldID = `${
            column && attachmentReference['fieldID']
          }`;
          const colId = column.getColDef().colId?.replace('fields.', '');
          if (attachmentFieldID === colId) {
            attachmentId.push(attachment._id);
          }
        }
      });
  }

  return attachmentId;
};

AttachmentRenderer.prototype.createElement = function(params) {
  const { node, data, column, showPreview } = params;
  const element = document.createElement('div');
  const store = window.appStore;
  element.id = `${node.id}-${column.colId}`;
  const attachments = _.get(
    store?.getState(),
    `${GET_ATTACHMENTS_KEY}.${data.id}`,
  );
  const attachmentIds = getAttachmentIds(attachments, column);
  if (!attachmentIds.length) {
    return element;
  }
  const lines = 1; // This value will always be 1
  element.classList.add('cell-attachment-item');
  element.classList.toggle('row-height-taller', node.rowHeight > GRID_ROW_HEIGHT.BASE);

  if (attachmentIds && attachmentIds.length > 0) {
    let found = false;
    const currentQuickSearch = filtersSelector(window.appStore.getState())
      .quickSearch;

    const getValueWrapper = () => {
      const valueWrapper = document.createElement('div');
      valueWrapper.style = `
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.5rem;
        margin: 3px 4px 5px;
        background-color: #eaeef4;
        color: #546e7a;
        border-radius: 4px;
        user-select: none;
        white-space: nowrap;
        padding-left: 6px;
        padding-right: 6px;
      `;
      return valueWrapper;
    };

    const getSizeStyles = (size) => {
      return `
        min-width: auto;
        height: ${size}px;
      `;
    };

    if (showPreview) {
      const item = attachments.byId[attachmentIds[0]];
      if (item) {
        const valueWrapper = getValueWrapper();
        const thumbnailHeight = node.rowHeight - 5;
        valueWrapper.style.height = `${thumbnailHeight}px`;
        const thumbnailStyle = getSizeStyles(thumbnailHeight);

        if (item?.thumbnail) {
          const thumbnail = document.createElement('img');
          thumbnail.src = item.thumbnail;
          thumbnail.style = `
            object-fit: cover;
            object-position: center;
            display: inline-block;
            min-width: auto;
            ${thumbnailStyle};
          `;
          valueWrapper.append(thumbnail);
        } else {
          const fileExtension = item.fileExtension.toLowerCase();
          const icon = (typeIcon[fileExtension]
            ? typeIcon[fileExtension]
            : typeIcon['unknown']).cloneNode(true);
          icon.style = thumbnailStyle;
          valueWrapper.append(icon);
        }

        this.bindPreviewCarousel(valueWrapper, store);
        element.append(valueWrapper);
        element.style.justifyContent = 'center';
      }
    } else {
      for (let valueIndex = 0; valueIndex < attachmentIds.length; valueIndex++) {
        const item = attachments.byId[attachmentIds[valueIndex]];
        if (!item) {
          continue;
        }

        const valueWrapper = getValueWrapper();
        const valueElement = document.createElement('div');
        valueElement.style = GenericTextRenderer(lines, slate, '12px');
        valueElement.innerHTML = item.title;

        if (
          !found &&
          currentQuickSearch &&
          item.title.toLowerCase().includes(currentQuickSearch.toLowerCase())
        ) {
          found = true;
        }

        const fileExtension = item.fileExtension.toLowerCase();
        const icon = typeIcon[fileExtension]
          ? typeIcon[fileExtension].cloneNode(true)
          : typeIcon['unknown'].cloneNode(true);
        icon.style = `
          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-right: 5px;
        `;
        valueWrapper.append(icon);
        valueWrapper.append(valueElement);
        this.bindPreviewCarousel(valueWrapper, store, valueIndex);

        element.append(valueWrapper);
      }
    }

    if (params.eGridCell) {
      params.eGridCell.classList.remove('highlighted-cell');
      if (found) {
        params.eGridCell.classList.add('highlighted-cell');
      }
    }
  } else {
    const emptyElement = document.createElement('div');
    emptyElement.style = `
      width: 100%;
    `;
    element.append(emptyElement);
  }

  return element;
};

AttachmentRenderer.prototype.render = function(params) {
  const store = window.appStore;
  const parseNodeId = parseInt(params.node.id);
  if (!parseNodeId || parseNodeId < 0) return;
  if (!store.getState().attachments.isLoading) {
    const element = this.createElement(params);
    this.element = element;
    if (params.node.group) {
      this.handleGroup(params, this.element);
    }
  } else {
    this.unsubscribe = store.subscribe(() => {
      if (!store.getState().attachments.isLoading) {
        const element = this.createElement(params);
        params.eGridCell.appendChild(element);
        this.element = element;
        if (params.node.group) {
          this.handleGroup(params, this.element);
        }
        this.unsubscribe();
      }
    });
  }
};

AttachmentRenderer.prototype.init = function(params) {
  this.params = params;
  this.eGridCell = params.eGridCell;
  this.render(params);
};

/**
 * Open preview carousel in renderer mode when user clicks one attachment
 *
 * @param {HTMLElement} ele - DOM element to attach click event
 * @param {Object} store - Redux store
 */
AttachmentRenderer.prototype.bindPreviewCarousel =
function(ele, store, index=0) {
  const { node } = this.params;

  ele.addEventListener('click', () => {
    const { app } = store.getState();
    const { thirdParty } = app.tenant || {};
    const previewEnabled = thirdParty?.filestack?.enable;

    if (previewEnabled) {
      const { colDef, column } = this.params;
      const { permissions, _id } = colDef?.refData;
      const canModify = allowToModify(column, permissions);
      store.dispatch({
        type: 'attachments/OPEN_PREVIEW_ATTACHMENTS',
        payload: {
          index,
          node: node.data,
          enableEdit: canModify,
          fieldId: _id,
        },
      });
    }
  }, false);
};


AttachmentRenderer.prototype.getGui = function() {
  return this.element;
};

AttachmentRenderer.prototype.refresh = function(params) {
  return false;
};

AttachmentRenderer.prototype.destroy = function() {
  return true;
};

export default AttachmentRenderer;
