import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import Button, { Size, Variant } from 'components/Button';

interface Props {
  label: string;
  children?: React.ReactNode;
  calculateHeight?: boolean;
}

interface State {
  anchorElement: HTMLElement | null;
  style: {
    maxHeight: string;
  };
}

class PopupMenuButton extends React.Component<Props, State> {
  public state: State = {
    anchorElement: null,
    style: {
      maxHeight: '',
    },
  };

  public componentDidUpdate = (): void => {
    if (this.props.calculateHeight && this.state.anchorElement) {
      const clientRect = this.state.anchorElement.getBoundingClientRect();
      const maxHeight = `${window.innerHeight - clientRect.top}px`;
      if (this.state.style.maxHeight !== maxHeight) {
        this.setState({
          style: {
            maxHeight,
          },
        });
      }
    }
  };

  public render = (): JSX.Element => {
    const { label, children } = this.props;
    const { anchorElement } = this.state;
    return (
      <>
        <Button
          label={label}
          variant={Variant.PrimaryLink}
          size={Size.Small}
          onClick={this.handleOpen}
        />
        <Menu
          open={!!anchorElement}
          onClose={this.handleClose}
          anchorEl={anchorElement}
          onClick={this.handleClose}
          PaperProps={{
            style: this.state.style,
          }}
        >
          {children}
        </Menu>
      </>
    );
  };

  private handleOpen = (e) => {
    this.setState({ anchorElement: e.currentTarget });
  };
  private handleClose = () => {
    this.setState({ anchorElement: null });
  };
}

export default PopupMenuButton;
