import { action, ActionsUnion } from 'types/reduxHelpers';

export const CHANGE_QUICK_FILTERS_VISIBILITY = 'CHANGE_QUICK_FILTERS_VISIBILITY';
export type CHANGE_QUICK_FILTERS_VISIBILITY = typeof CHANGE_QUICK_FILTERS_VISIBILITY;


export const Actions = {
  changeQuickFiltersVisibility: action<typeof CHANGE_QUICK_FILTERS_VISIBILITY>(CHANGE_QUICK_FILTERS_VISIBILITY),
};

export type Actions = ActionsUnion<typeof Actions>;
