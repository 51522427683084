import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { SortEnd } from 'react-sortable-hoc';

import {
  ButtonBar,
  CloseButton,
  Dropdown,
  DropdownColumnWrapper,
  DropdownContent,
  DropdownFooter,
  DropdownHeader,
  DropdownListItemLabel,
  DropdownListItemRemove,
  DropdownListItemText,
  NoItemsText,
} from '../../TableHeaderTools.style';
import * as fromActions from 'data/grid-options/sortModel.actions';
import { ChangeSortColumnPayload, ChangeSortOperatorPayload } from 'data/grid-options/sortModel.reducer';
import { columnsVMSelector } from 'data/collections/view-config/viewConfig.selectors';
import { schemaColumnsMap } from 'data/collections/collections.selectors';

import svgIcons from 'styles/svgIcons';
import { Column, ColumnVM } from 'types/schema';
import { SortModel, SortModelState, SortOperator } from 'types/gridOptions';

import { SortableList, SortableListItem } from 'components/TableHeaderTools/Sortable';
import { ArrowIcon, DropdownWrapper, SortSwitchOption, SortSwitchWrapper } from './SortingOption.style';
import Popover from 'components/ui/Popover';
import Button, { Variant } from 'components/Button';
import PopupMenuButton from 'components/TableHeaderTools/PopupMenuButton';
import { State as ReduxState } from 'reducers';
import SearchBar, { Size as SearchBarSize } from 'components/SearchBar/SearchBar';

interface StateProps {
  columnsVM: ColumnVM[];
  schemaColumns: {
    [id: string]: Column;
  };
  sortModel: SortModelState;
}

interface DispatchProps {
  addSort: (payload: SortModel) => void;
  changeSortColumn: (payload: ChangeSortColumnPayload) => void;
  changeSortOperator: (payload: ChangeSortOperatorPayload) => void;
  deleteSort: (payload: string) => void;
  changeSortOrder: (payload: SortEnd) => void;
  clearSort: () => void;
}

type Props = StateProps & DispatchProps;

interface State {
  fieldSearchValue: string;
}

export class SortingOption extends React.PureComponent<Props, State> {
  state = {
    fieldSearchValue: '',
  };

  addSort = (column: ColumnVM) => {
    if (this.props.sortModel.allIds.includes(column.id)) return;
    const payload: SortModel = {
      colId: column.id,
      sort: SortOperator.Asc,
    };
    this.props.addSort(payload);
    this.setState({ fieldSearchValue: '' });
  };

  changeSortColumn = (newColumn: ColumnVM, oldColumnKey: string) => {
    if (this.props.sortModel.allIds.includes(newColumn.id)) return;
    this.props.changeSortColumn({
      newColumnId: newColumn.id,
      oldColumnKey,
    });
  };

  changeDirection = (key: string, sortOperator: SortOperator) => () => {
    const { sortModel } = this.props;
    if (sortModel.byId[key].sort !== sortOperator) {
      const payload = {
        columnToChangeKey: key,
        newOperator: sortOperator,
      };
      this.props.changeSortOperator(payload);
    }
  };

  renderSortLabel = (idx: number): string => {
    return (idx === 0) ? 'Sort by ' : 'then by ';
  };

  renderSortDirectionSwitch(item: string) {
    const { sortModel } = this.props;
    const sortItem = sortModel.byId[item];
    return (
      <>
        <DropdownListItemText>
          from
        </DropdownListItemText>
        <SortSwitchWrapper>
          <SortSwitchOption
            active={sortItem.sort === SortOperator.Asc}
            onClick={this.changeDirection(item, SortOperator.Asc)}
          >
            A <ArrowIcon/> Z
          </SortSwitchOption>
          <SortSwitchOption
            active={sortItem.sort === SortOperator.Desc}
            onClick={this.changeDirection(item, SortOperator.Desc)}
          >
            Z <ArrowIcon/> A
          </SortSwitchOption>
        </SortSwitchWrapper>
      </>
    );
  }

  renderSortListItem = (item: string, index) => {
    const { sortModel, columnsVM, schemaColumns } = this.props;
    const sortList = sortModel.allIds;
    const idx = sortList.findIndex(id => id === item);
    const column = schemaColumns[item];

    if (!column) {
      return null;
    }

    return (
      <SortableListItem key={item} lockAxis="y" lockToContainerEdges index={index} className="sortable-list-item">
        <DropdownListItemRemove onClick={() => this.props.deleteSort(item)} />
        <DropdownListItemLabel>
          {this.renderSortLabel(idx)}
        </DropdownListItemLabel>
        <DropdownColumnWrapper>
          <Dropdown
            id={`dropdownSorting_${index}`}
            calculateHeight
            label={column.name}
            menuItems={columnsVM
              .filter(column => column.name.toLowerCase().includes(this.state.fieldSearchValue.toLowerCase()))
              .map((column, colIndex) => (
                <MenuItem
                  id={`dropdownSorting_${index}_item_${colIndex}`}
                  key={column.id}
                  onClick={() => {
                    if (sortModel.allIds.indexOf(column.id) >= 0) {
                      return;
                    }
                    this.setState({ fieldSearchValue: '' });
                    this.changeSortColumn(column, item);
                  }}
                >
                  {column.name}
                </MenuItem>
              ))}
            menuItemsSearch={
              <SearchBar
                className="hubsync-menu-searchbar"
                placeholder="Find a Field"
                value={this.state.fieldSearchValue}
                size={SearchBarSize.Small}
                onChange={this.onFieldSearch}
                autoFocus
              />
            }
          />
        </DropdownColumnWrapper>
        {this.renderSortDirectionSwitch(item)}
      </SortableListItem>
    );
  };

  renderDropdown = ({ closePopover }) => {
    const { sortModel, columnsVM } = this.props;
    const sortedIds = sortModel.allIds;
    return (
      <DropdownWrapper>
        <DropdownHeader>
          <CloseButton style={{ marginLeft: 'auto' }} onClick={closePopover}/>
        </DropdownHeader>
        <DropdownContent>
          {isEmpty(sortedIds)
            ? <NoItemsText>No sorts applied to this view</NoItemsText>
            : (
              <SortableList lockAxis="y" lockToContainerEdges useDragHandle onSortEnd={this.props.changeSortOrder}>
                {sortedIds.map(this.renderSortListItem)}
              </SortableList>
            )
          }
        </DropdownContent>
        <DropdownFooter>
          <PopupMenuButton label="Add Field to Sort By" calculateHeight>
            <SearchBar
              className="hubsync-menu-searchbar"
              placeholder="Find a Field"
              value={this.state.fieldSearchValue}
              size={SearchBarSize.Small}
              onChange={this.onFieldSearch}
              autoFocus
            />
            {columnsVM
              .filter(column => column.name && column.name.toLowerCase().includes(this.state.fieldSearchValue.toLowerCase()))
              .map(column => (
                <MenuItem
                  key={column.id}
                  onClick={() => this.addSort(column)}
                >
                  {column.name}
                </MenuItem>
              ))}
          </PopupMenuButton>
        </DropdownFooter>
        <ButtonBar>
        </ButtonBar>
      </DropdownWrapper>
    );
  };

  render() {
    const { sortModel } = this.props;
    const sortedList = sortModel.allIds;
    const isSortApplied = sortedList.length > 0;
    const buttonLabel = `Sort${isSortApplied
      ? `ed by ${sortedList.length} field${sortedList.length > 1 ? 's' : ''}`
      : ''}`;

    return (
      <Popover
        id="sortingOption"
        renderTrigger={({ openPopover, opened }) => (
          <Button
            id="btnSortingOption"
            label={buttonLabel}
            variant={Variant.TertiaryLink}
            selected={opened || isSortApplied}
            onClick={openPopover}
            icon={svgIcons.Sort}
          />
        )}
      >
        {this.renderDropdown}
      </Popover>
    );
  }

  private onFieldSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ fieldSearchValue: event.target.value });
  };
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  columnsVM: columnsVMSelector(state),
  schemaColumns: schemaColumnsMap(state),
  sortModel: state.gridOptions.sortModel,
});

const mapDispatchToProps = (dispatch: Dispatch<fromActions.Actions>): DispatchProps => ({
  addSort: (payload: SortModel) => dispatch(fromActions.Actions.addSort(payload)),
  changeSortColumn: (payload: ChangeSortColumnPayload) => dispatch(fromActions.Actions.changeSortColumn(payload)),
  changeSortOperator: (payload: ChangeSortOperatorPayload) => dispatch(fromActions.Actions.changeSortOperator(payload)),
  deleteSort: (payload: string) => dispatch(fromActions.Actions.deleteSort(payload)),
  clearSort: () => dispatch(fromActions.Actions.setSortModel([])),
  changeSortOrder: (payload: SortEnd) => dispatch(fromActions.Actions.changeSortOrder(payload)),
});

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(SortingOption);
