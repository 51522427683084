import styled from 'styled-components';

import * as Colors from 'styles/colors';

import * as ActivityFeedItemStyled from './ActivityFeedItem.style';

const Wrapper = styled.div`

  ${ActivityFeedItemStyled.Wrapper} ${ActivityFeedItemStyled.Tail} {
    bottom: 0;
  }

  ${ActivityFeedItemStyled.Wrapper} ${ActivityFeedItemStyled.Tail} {
    top: 0;
  }

  :first-child {
    margin-top: 1.5rem;

    ${ActivityFeedItemStyled.Wrapper} {
      :first-child ${ActivityFeedItemStyled.Tail} {
        top: 50%;
      }

    }
  }

  :last-child {
    ${ActivityFeedItemStyled.Wrapper} {
      :last-child ${ActivityFeedItemStyled.Tail} {
        bottom: 50%;
        top: auto;
      }
    }
  }

  :only-child {
    ${ActivityFeedItemStyled.Wrapper} {
      :only-child ${ActivityFeedItemStyled.Tail} {
        display: none;
      }

    }
  }

`;

const HeadingText = styled.span`
  margin: 0 .625rem;
`;

const Heading = styled.h6`
  font-size: 11px;
  font-weight: 500;
  color: ${Colors.blueyGrey};
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  position: relative;

    &:before {
      margin-left: 50px;
    }

    &:before, &:after {
      content: '';
      border-bottom: 1px solid ${Colors.lightBlueGrey};
      flex-grow: 1;
    }

    ${Wrapper}:first-child & {
      padding-top: 0;
      &:before {
        margin-left: 0;
        padding-left: 50px;
      }
    }

`;

const HeadingTile = styled.div`
  height: 100%;
  width: 1px;
  background: ${Colors.lightBlueGrey};
  position: absolute;
  left: ${ActivityFeedItemStyled.ICON_WIDTH_HEIGHT / 2}px;

   ${Wrapper}:first-child & {
      height: 0;
   }
`;

const ActivityItemsWrapper = styled.div``;

export {
  Wrapper,
  Heading,
  HeadingText,
  HeadingTile,
  ActivityItemsWrapper,
};
