import { ActionType, getType } from 'typesafe-actions';
import * as actions from './documents.actions';
import { DirectoryType } from 'data/documents/documents.types';
import { HistoryGroup } from 'data/ui/documentPreview/documentPreview.types';
import { DocumentNode } from 'types/response/documentNode';
import { PreviewNode } from 'types/response/previewNode';
import LoadingState from 'data/LoadingState';

export interface DocumentsState {
  directoriesLoading: boolean;
  directories: DirectoryType[];
  document: DocumentNode | null;
  documentLoading: boolean;
  previews: PreviewNode[];
  previewsLoadState: LoadingState;
  history: HistoryGroup[];
  historyLoadState: LoadingState;
}

export const initialState: DocumentsState = {
  directoriesLoading: false,
  directories: [],
  document: null,
  documentLoading: false,
  previews: [],
  previewsLoadState: LoadingState.Unloaded,
  history: [],
  historyLoadState: LoadingState.Unloaded,
};

type Action = ActionType<typeof actions>;

function reducer(state = initialState, action: Action): DocumentsState {
  switch (action.type) {
    case getType(actions.fetchDirectories.request): {
      return { ...state, directoriesLoading: true };
    }

    case getType(actions.appendDirectory): {
      return { ...state, directories: [...state.directories, action.payload] };
    }

    case getType(actions.fetchDirectories.success): {
      const directories: DirectoryType[] = action.payload;
      return { ...state, directories, directoriesLoading: false };
    }

    case getType(actions.fetchDirectories.failure): {
      return { ...state, directoriesLoading: false };
    }

    case getType(actions.setDocumentForPreview):
      return { ...state, documentLoading: false, document: action.payload };

    case getType(actions.loadDocument.request):
      return { ...state, documentLoading: true };

    case getType(actions.loadDocument.success):
      return { ...state, documentLoading: false, document: action.payload };

    case getType(actions.loadPreviews.request):
      return { ...state, previewsLoadState: LoadingState.Loading };

    case getType(actions.loadPreviews.success):
      return { ...state, previewsLoadState: LoadingState.Loaded, previews: action.payload };

    case getType(actions.loadPreviews.failure):
      return { ...state, previewsLoadState: LoadingState.Error, previews: [] };

    case getType(actions.loadHistory.request):
      return { ...state, historyLoadState: LoadingState.Loading };

    case getType(actions.loadHistory.success):
      return { ...state, historyLoadState: LoadingState.Loaded, history: action.payload };

    case getType(actions.loadHistory.failure):
      return { ...state, historyLoadState: LoadingState.Error, history: [] };

    case getType(actions.clearDocumentWithPreviews):
      return {
        ...state,
        documentLoading: false,
        document: null,
        previewsLoadState: LoadingState.Unloaded,
        previews: [],
        historyLoadState: LoadingState.Unloaded,
        history: [],
      };

    default: {
      return state;
    }
  }
}

export default reducer;
