import moment from 'moment';

import { DateFormat, TimeFormat } from '../../Fields/data';

interface GetFormatParams {
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
  includeTime?: boolean;
}

export const getFormat = (params: GetFormatParams) => {
  const dateFormat = params.dateFormat || DateFormat.US;
  const timeFormat = params.timeFormat || TimeFormat.Twelve;
  const includeTime = !!params.includeTime;

  if (!includeTime) return dateFormat;

  let format = `${dateFormat} ${timeFormat}:mm`;

  if (timeFormat === TimeFormat.Twelve) {
    format = `${format}A`;
  }

  return format;
};

// preventing returning invalid Date instance
export const parseDate = (value) => {
  if (typeof value === 'string' && value === `${parseInt(value)}`) {
    value = parseInt(value);
  }
  const date = value && new Date(value);
  return Number.isNaN(+date)
    ? undefined
    : date;
};

export const getFormattedDate = (value: string, params: GetFormatParams) => {
  const date = parseDate(value);
  if (date) {
    const format = getFormat(params);
    return moment(date).utc(true).format(format);
  }

  return '';
};
