import { notifications } from 'data/ui/notifications/notifications.actions';
import { actions } from 'data/users/users.actions';
import { constant } from 'lodash/fp';
import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { post } from 'utilities/httpRequests';
import { createAPIHandler, RequestType } from 'utilities/saga';
import moment from 'moment';

import { AppMessages } from '../../constants';


const USERS_URL = `/users/me?api_version=2`;

function* fetchUser() {
  yield createAPIHandler({
    actions: actions.fetchUser,
    buildUrl: constant(USERS_URL),
    requestType: RequestType.Get,
    successPayloadMapper: (response) => response.body,
    * onFailure() {
      window.location.href = '/logout';
    },
  });
}

function* updateUser() {
  yield createAPIHandler({
    actions: actions.updateUser,
    buildUrl: (action) => `/users/me?api_version=2`,
    requestType: RequestType.Patch,
    buildData: (action) => action.payload.data,
    successPayloadMapper: (response) => response.body,
    * onSuccess() {
      yield put(
        notifications.success({
          message: AppMessages.NOTIFICATION_PROFILE_UPDATED,
        }),
      );
    },
    * onFailure() {
      yield put(
        notifications.error({
          message: AppMessages.NOTIFICATION_PROFILE_EXISTING_USER_EMAIL,
        }),
      );
    },
  });
}

function* changeUserPassword() {
  yield createAPIHandler({
    actions: actions.changePassword,
    buildUrl: (_action) => `/users/me/change-password?api_version=2`,
    requestType: RequestType.Post,
    buildData: (action) => action.payload.data,
    successPayloadMapper: (response) => response.body,
    * onSuccess() {
      yield put(notifications.success({ message: AppMessages.NOTIFICATION_PASSWORD_UPDATED }));
    },
    * onFailure() {
      yield put(
        notifications.error({ message: AppMessages.NOTIFICATION_PASSWORD_UPDATED_ERROR }),
      );
    },
  });
}

function* changeUserPreference() {
  yield createAPIHandler({
    actions: actions.changeUserSendMessagePreference,
    buildUrl: (action) => `/users/me?api_version=2`,
    requestType: RequestType.Patch,
    buildData: (action) => action.payload.data,
    successPayloadMapper: (response) => response.body,
  });
}

function* changeModifyFieldPreference() {
  yield createAPIHandler({
    actions: actions.changeUserModifyFieldModalPreference,
    buildUrl: (action) => `/users/me?api_version=2`,
    requestType: RequestType.Patch,
    buildData: (action) => ({ settings: action.payload }),
    successPayloadMapper: (response) => response.body,
  });
}

function* deleteUser() {
  yield createAPIHandler({
    actions: actions.deleteUser,
    buildUrl: (action) => `/users/me?api_version=2`,
    requestType: RequestType.Delete,
    successPayloadMapper: (response) => response.body,
    * onSuccess() {
      window.location.href = '/logout';
    },
    * onFailure() {
      yield put(notifications.error({ message: 'Operation failed.' }));
    },
  });
}

function* changeUserEmail(action) {
  try {
    yield post({
      url: `/users/me/change-email?api_version=2`,
      data: action.payload.data,
    });
    yield put(
      notifications.success({ message: 'Email is updated successfully.' }),
    );
  } catch (exception) {
    if (exception?.response?.body?.message) {
      yield put(
        notifications.error({ message: exception.response.body.message }),
      );
      return;
    }
    yield put(notifications.error({ message: 'Failed to update email.' }));
  }
}

function* updateUserTermsAndCondition() {
  yield createAPIHandler({
    actions: actions.updateUserTermsAndCondition,
    buildUrl: () => `/users/me?api_version=2`,
    buildData: () => {
      return { 'termsAndConditions': {
        'accepted': true,
        'date': moment().valueOf(),
      } };
    },
    requestType: RequestType.Patch,
    successPayloadMapper: (response) => response.body,
    * onSuccess() {
      window.location.href = '/workspaces';
    },
    * onFailure() {
      yield put(notifications.error({ message: 'Operation failed.' }));
    },
  });
}

function* updateUserMFAMethod() {
  yield createAPIHandler({
    actions: actions.updateUserMFAMethod,
    buildUrl: () => `/users/me?api_version=2`,
    buildData: (action) => {
      return { 'MFA': { 'method': action.payload.data.method } };
    },
    requestType: RequestType.Patch,
    successPayloadMapper: (response) => response.body,
    * onSuccess() {
      window.location.href = '/workspaces';
    },
    * onFailure() {
      yield put(notifications.error({ message: 'Operation failed.' }));
    },
  });
}

export default function* watchSaga() {
  yield all([
    fork(fetchUser),
    fork(updateUser),
    fork(changeUserPassword),
    fork(deleteUser),
    fork(changeUserPreference),
    fork(changeModifyFieldPreference),
    fork(updateUserTermsAndCondition),
    fork(updateUserMFAMethod),
    takeLatest(actions.changeUserEmail.request, changeUserEmail),
  ]);
}
