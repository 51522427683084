
import { get } from 'utilities/httpRequests';

/** */
export const fetchAllWorkspaces = async (query) => {
  const path = `/workspaces/paginated/all-workspaces`;
  const queryTotal = `api_version=2&search=${query}`;
  const url = `${path}?${queryTotal}`;
  const response = await get(url);
  console.debug('notes response', response);
  return response;
};

