import * as React from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { Variant } from 'components/Button';
import { styles } from 'components/Modals/CreateCollectionItem/CreateCollectionItem.style';
import ModalContent from 'components/Modals/Modal/Content';
import CustomActions from 'components/Modals/Modal/CustomActions';
import ModalHeader from 'components/Modals/Modal/Header';
import ModalTextField from 'components/Modals/ModalTextField';
import ModalAttachmentsEditor from 'components/NodeFieldData/attachment/ModalAttachmentsEditor';
import Select from 'components/Select';
import { URLInjectedProps, withURLParams } from 'containers/withURLParams';
import {
  createCollectionItemImportRequest,
  createCollectionItemRequest,
} from 'data/collections/collections.actions.new';
import {
  CreateCollectionItemRequestPayload, CreateCollectionItemWithAttachmentRequestPayload,
} from 'data/collections/collections.reducer';
import { importDatabase, ImportDatabase, ImportDatabaseOptions } from 'data/databases/databases.actions';
import { getAllDatabases } from 'data/databases/databases.selectors';
import { ImportedDatabaseState } from 'data/databases/databases.types';
import LoadingState from 'data/LoadingState';
import * as ModalActions from 'data/modals/actions';
import { getAllTaskDatabases } from 'data/taskdbs/taskdbs.selectors';
import { getAllWorkspaces, getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import * as _ from 'lodash/fp';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { FileAttachment } from 'types/common';
import { TenantNode } from 'types/response/tenantNode';
import { CollectionTypes, Status } from 'types/schema';
import { getSingularCollectionName } from 'utilities/collections';
import { MediaTypeByExtension } from 'utilities/files';

import { parseURI } from '../../../utilities/parseURI';
import CreateClientWorkspaceForm from './createClientWorkspaceForm';


interface OwnProps {
  collectionType: CollectionTypes;
}

export interface StateProps {
  workspaceTitles: string[];
  databaseTitles: string[];
  taskDatabaseTitles: string[];
  permissions: string[];
  importLoadingState?: LoadingState;
  importedDatabase?: ImportedDatabaseState | null;
  tenant?: TenantNode;
}

export interface DispatchProps {
  close: () => void;
  createCollectionItem: (payload: CreateCollectionItemRequestPayload, flag: CollectionTypes) => void;
  createCollectionItemWithAttachment: (payload: CreateCollectionItemWithAttachmentRequestPayload, flag: CollectionTypes) => void;
  importDatabase: (options: ImportDatabaseOptions) => ImportDatabase;
}

type Props =
  & OwnProps
  & StateProps
  & DispatchProps
  & URLInjectedProps
  & WithStyles<typeof styles>
  ;

interface State {
  status?: Status;
  title: string;
  description: string;
  attachment?: FileAttachment;
  titleErrorMessage: string;
}

class CreateCollectionItem extends React.Component<Props, State> {
  public state: State = {
    title: '',
    status: Status.active,
    description: '',
    titleErrorMessage: '',
  };

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.importLoadingState === LoadingState.Loading && this.props.importLoadingState === LoadingState.Loaded) {
      this.props.close();
      const fileName = this.props.importedDatabase && this.props.importedDatabase.id;
      const title = this.props.importedDatabase && this.props.importedDatabase.title;
      const originalFilename = this.props.importedDatabase && this.props.importedDatabase.originalFilename;
      this.props.history.push(`${this.props.location.pathname}/${fileName}/import?title=${title}&originalFilename=${originalFilename}`);
    }
  }

  public render(): JSX.Element {
    const { collectionType, classes, importLoadingState } = this.props;
    const { status, title, titleErrorMessage, description, attachment } = this.state;
    const hasErrors = !!this.state.titleErrorMessage;
    const collectionName = getSingularCollectionName(collectionType);
    const hasFileUpload = collectionType === CollectionTypes.databases;
    const displayClientForm = (collectionType === CollectionTypes.workspaces) &&
      (status === Status.client);

    return (
      <React.Fragment>
        <ModalHeader onClose={this.props.close} titleClassName={classes.header}>
          <div>
            {`New ${collectionName}`}
            <Select
              placeholder="Select Status"
              value={status}
              values={this.computeOptions()}
              getLabel={_.capitalize}
              getSelectValue={_.identity}
              onChange={this.handleStatusChange}
            />
          </div>
        </ModalHeader>
        <ModalContent className={classes.content}>
          {
            displayClientForm
              ? <CreateClientWorkspaceForm
                onClose={this.props.close}
              />
              : <>
                <ModalTextField
                  id="txtTitle"
                  className={classes.field}
                  placeholder={`${collectionName} Name`}
                  value={title}
                  onChange={this.handleTitleChange}
                  errorMessage={titleErrorMessage}
                  autoSelect
                  autoFocus
                />
                <ModalTextField
                  id="txtDescription"
                  className={classes.field}
                  placeholder={`Enter a ${_.toLower(collectionName)} description`}
                  value={description}
                  onChange={this.handleDescriptionChange}
                  multiline
                  autoSelect
                />
                {hasFileUpload &&
                  <div className={classes.field}>
                    <ModalAttachmentsEditor
                      attachments={attachment ? [attachment.file.name] : []}
                      onAdd={this.handleFileAdd}
                      onDelete={this.handleFileDelete}
                      acceptTypes={IMPORT_FILE_TYPES}
                      limit={1}
                      permissions={this.props.permissions}
                      collectionType={CollectionTypes.databases}
                      tenant={this.props.tenant}
                    />
                  </div>
                }
              </>
          }
        </ModalContent>
        {
          !displayClientForm &&
          (<CustomActions
            leftButtons={[{
              label: 'Cancel',
              variant: Variant.SecondaryLink,
              onClick: this.props.close,
            }]}
            rightButtons={[{
              label: 'Create',
              variant: Variant.Primary,
              disabled: hasErrors || !title || importLoadingState === LoadingState.Loading,
              onClick: this.handleCreate,
            }]}
          />)
        }
      </React.Fragment>
    );
  }

  private computeOptions(): string[] {
    return Object.values(Status).filter(item => this.displayClientOption(item));
  }

  private displayClientOption(opt: string): boolean {
    const { collectionType } = this.props;
    return !(collectionType === CollectionTypes.workspaces)
      ? !(opt === Status.client)
      : true;
  }


  private getExistingTitles = (): string[] => {
    const { collectionType, workspaceTitles, databaseTitles, taskDatabaseTitles } = this.props;
    switch (collectionType) {
      case CollectionTypes.workspaces:
        return workspaceTitles;
      case CollectionTypes.databases:
        return databaseTitles;
      case CollectionTypes.taskdbs:
        return taskDatabaseTitles;
      default:
        return [];
    }
  };

  private handleStatusChange = (status: Status): void => {
    this.setState({ status });
  };

  private handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const title = event.target.value.trimStart().replace(/ +(?= )/g, '');
    this.setState({ title });
    this.validateName(title.trim());
  };

  private validateName = (title: string): void => {
    if (title === '') {
      this.setState({ titleErrorMessage: 'Please provide a name.' });
    } else if (this.getExistingTitles().includes(title)) {
      this.setState({ titleErrorMessage: `${title} already exists. Please enter unique name.` });
    } else {
      this.setState({ titleErrorMessage: '' });
    }
  };

  private handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ description: event.target.value });

  private handleFileAdd = (attachments: FileAttachment[]): void => this.setState({ attachment: attachments[0] });

  private handleFileDelete = (): void => this.setState({ attachment: undefined });

  private handleCreate = (): void => {
    const { title, description, status, attachment } = this.state;
    const { collectionType, createCollectionItem, createCollectionItemWithAttachment, location, close } = this.props;
    const hasErrors = !!this.state.titleErrorMessage;
    const trimmedTitle = title.trim();

    if (!hasErrors) {
      if (attachment && CollectionTypes.databases) {
        this.props.importDatabase({
          url: location.pathname,
          data: {
            title: trimmedTitle,
            description,
            status: status || Status.active,
            originalFilename: attachment.file.name,
          },
          attachment,
        });
        return;
      }
      const payload: CreateCollectionItemRequestPayload = {
        url: location.pathname,
        data: {
          title: trimmedTitle,
          description,
          status: status || Status.active,
        },
      };
      const { dbSlug } = parseURI(location.pathname);
      if (dbSlug === 'databases' || dbSlug === 'taskdbs') {
        payload.url = location.pathname.replace('taskdbs', 'databases');
        payload.data = {
          ...payload.data,
          type: dbSlug === 'databases' ? 'database' : 'task',
        };
      }

      if (attachment) {
        createCollectionItemWithAttachment({
          ...payload,
          fileAttachment: attachment,
        }, collectionType);
      } else {
        createCollectionItem(payload, collectionType);
      }
      close();
    }
  };
}

const IMPORT_FILE_TYPES = `
  ${MediaTypeByExtension.csv},
  ${MediaTypeByExtension.xls},
  ${MediaTypeByExtension.xlsx},
`;

const mapStateToProps = (state: ReduxState): StateProps => {
  const workspaces = getAllWorkspaces(state) || [];
  const databases = getAllDatabases(state) || [];
  const taskDatabases = getAllTaskDatabases(state) || [];
  const tenant = state.app.tenant;

  return {
    workspaceTitles: workspaces.map(node => node.title),
    databaseTitles: databases.map(node => node.title),
    taskDatabaseTitles: taskDatabases.map(node => node.title),
    permissions: getWorkspacePermissions(state),
    importLoadingState: state.databases.importLoadingState,
    importedDatabase: state.databases.importedDatabase,
    tenant,
  };
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
  createCollectionItem: createCollectionItemRequest,
  createCollectionItemWithAttachment: createCollectionItemImportRequest,
  importDatabase,
};

export const StyledCreateCollectionItem = withStyles(styles)(CreateCollectionItem);

export default withURLParams(connect(mapStateToProps, mapDispatchToProps)(StyledCreateCollectionItem));
