import { createAsyncThunk } from '@reduxjs/toolkit';
import { Bookmark } from 'types/schema';
import { BookmarkMeta } from './types';

import * as helpers from './helpers';
import * as services from './services';

const MODULE_NAME = `bookmarks`;

export const getAllBookmarks = createAsyncThunk<
  { bookmarks: Bookmark[]; permissions: string[] }
>(
  `${MODULE_NAME}/GET_ALL`,
  async (_, { getState }) => {
    const response = await services.fetchBookmarks();
    return helpers.parseBookmarksResponse(response, getState());
  },
);

export const saveBookmark = createAsyncThunk<
  void,
  { bookmarkMeta?: BookmarkMeta }
>(
  `${MODULE_NAME}/SAVE`,
  async ( { bookmarkMeta }, { dispatch }) => {
    await services.postBookmark(bookmarkMeta);
    dispatch(getAllBookmarks());
  },
);

export const updateBookmark = (bookmarkMeta: BookmarkMeta) => {
  return {
    type: `${MODULE_NAME}/UPDATE`,
    bookmarkMeta,
  };
};

export const deleteBookmark = createAsyncThunk<
  { bookmarkID: string; recordID?: string },
  { bookmarkID: string; recordID?: string }
>(
  `${MODULE_NAME}/DELETE`,
  async ({ bookmarkID }) =>
    await services.deleteBookmark(bookmarkID),
);
