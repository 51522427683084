import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import SignIn from './SignIn';

import Logo from '../../assets/images/auth/hubsync-logo-white.svg';
import '../../styles/components/auth.scss';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { EmailSentVerification, IdentifyVerified, LinkExpired } from '../MFA/';
import CheckConfirmIdentity from 'pages/MFA/CheckConfirmIdentity';


const Auth: React.FC = () => {
  const { path } = useRouteMatch();

  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={'auth-container'+ (tenantConfig.clientName || '')}>
        <div className="auth-form-container show-overflow">
          <div className="auth-logo">
            <img src={tenantConfig.logoURL ?? Logo} alt={'Hubsync'} />
          </div>
          <div className="auth-form-content">
            <Switch>
              <Redirect
                exact
                from={`${path}`}
                to={`${path}login`}
              />
              <Route
                path={`${path}login`}
                render={() => <SignIn />}
              />
              <Route
                path={`${path}auth/forgot-password`}
                render={() => <ForgotPassword />}
              />

              <Route
                path={`${path}auth/reset-password`}
                render={() => <ResetPassword />}
              />
              <Route
                path={`${path}auth/email-sent`}
                render={() => <EmailSentVerification />}
              />
              <Route
                path={`${path}auth/identity-verified`}
                render={() => <IdentifyVerified />}
              />

              <Route
                path={`${path}auth/link-expired`}
                render={() => <LinkExpired />}
              />

              <Route
                path={`${path}auth/verify/:userID`}
                render={() => <CheckConfirmIdentity />}
              />

              <Redirect
                from={'*'}
                to={`${path}login`}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
