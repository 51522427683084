import * as React from 'react';
import {
  PageNumber,
  ThumbnailFrame,
  ThumbnailPageImgWrapper,
  ThumbnailWrapper,
} from 'components/DocumentPreview/DocumentPreview.style';

interface Props {
  src: string;
  width: number;
  height: number;
  style: any;
  pageNumber: number;
  current: boolean;
  parentScroll: boolean;
  measure: () => void;
  onClick: () => void;
}

export default class ThumbnailPage extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const { style, src, width, height, pageNumber, current, measure, parentScroll, onClick } = this.props;
    return (
      <ThumbnailWrapper style={style} parentScroll={parentScroll}>
        <ThumbnailFrame current={current}>
          <ThumbnailPageImgWrapper
            onClick={onClick}
            style={{ paddingBottom: `${100 * height / width}%` }}
          >
            <img src={src} onLoad={measure} alt="" style={{ objectFit: 'contain', height: '100%' }} />
          </ThumbnailPageImgWrapper>
        </ThumbnailFrame>
        <PageNumber>{pageNumber}</PageNumber>
      </ThumbnailWrapper>
    );
  }
}
