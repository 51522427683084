type Rgb = [number, number, number];

const hexToRgb = (hex: string): Rgb => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);

  if (!result) {
    throw new Error(`hexToRgb: invalid color "${hex}" provided`);
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return [r, g, b];
};

const rgbToHex = (rgb: Rgb) => {
  const hexValues = rgb.map(value => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  });
  return '#' + hexValues.join('');
};

export const rgba = (hex: string, alpha: number): string => {
  const [r, g, b] = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const alphaBlend = (background: string, foreground: string, alpha: number) => {
  const normalize = value => value / 255;
  const denormalize = value => Math.min(
    Math.floor(value * 255),
    255,
  );

  const [bR, bG, bB] = hexToRgb(background).map(normalize);
  const [fR, fG, fB] = hexToRgb(foreground).map(normalize);

  const targetRgb = [
    (1 - alpha) * bR + alpha * fR,
    (1 - alpha) * bG + alpha * fG,
    (1 - alpha) * bB + alpha * fB,
  ].map(denormalize);

  // @ts-ignore
  return rgbToHex(targetRgb);
};
