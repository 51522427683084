import { stringify } from 'qs';
import { DirectoryType, NavigationTree } from 'data/documents/documents.types';
import { createAPIHandler, errorToast, RequestType } from 'utilities/saga';
import { normalizeURL } from 'utilities/format';
import {
  copyDocument,
  documentPrintError,
  fetchDirectories,
  loadDocument,
  loadHistory,
  loadPreviews,
  moveDocument,
} from 'data/documents/documents.actions';
import { ActionType, isActionOf } from 'typesafe-actions';
import { compose, path } from 'lodash/fp';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { location as locationSelector } from 'data/app/selectors';
import { Location } from 'history';
import { push } from 'connected-react-router';
import { parseURL, stripUrlsLastItem } from 'utilities/queryParams';
import { transformHistoryResponse } from 'data/ui/documentPreview/documentPreview.helpers';

const mapTreeToDirectories = (treeNode: NavigationTree): DirectoryType[] => {
  const directories: DirectoryType[] = [];

  if (treeNode && treeNode.nodes) {
    treeNode.nodes.forEach(childNode => {
      const directory: DirectoryType = {
        _id: parseURL(childNode.uri).documents,
        title: childNode.title,
        nodes: mapTreeToDirectories(childNode),
        uri: childNode.uri,
      };
      directories.push(directory);
    });
  }

  return directories;
};

export function* moveDocumentSaga() {
  yield createAPIHandler({
    actions: moveDocument,
    buildUrl(action: ActionType<typeof moveDocument.request>) {
      return normalizeURL(action.payload.documentURI);
    },
    buildData(action: ActionType<typeof moveDocument.request>) {
      return { folderID: action.payload.folderID };
    },
    requestType: RequestType.Patch,
    * onSuccess(_, action: ActionType<typeof moveDocument.request>) {
      const { pathname }: Location = yield select(locationSelector);
      const query = stringify({ folderID: action.payload.folderID });
      yield put(push(`${pathname}?${query}`));
    },
  });
}

export function* copyDocumentSaga() {
  yield createAPIHandler({
    actions: copyDocument,
    buildUrl: (action: ActionType<typeof copyDocument.request>) => {
      const { documentURI, folderID } = action.payload;
      const sourceUrl = normalizeURL(documentURI);
      const destinationBaseUrl = stripUrlsLastItem(sourceUrl);
      const query = stringify({ source: sourceUrl, folderID });
      return `${destinationBaseUrl}:copy?${query}`;
    },
    requestType: RequestType.Post,
    * onSuccess(_, action: ActionType<typeof moveDocument.request>) {
      const { pathname }: Location = yield select(locationSelector);
      const query = stringify({ folderID: action.payload.folderID });
      yield put(push(`${pathname}?${query}`));
    },
  });
}

export function* fetchDirectoriesSaga() {
  yield createAPIHandler({
    actions: fetchDirectories,
    buildUrl(action: ActionType<typeof fetchDirectories.request>) {
      const repositoryUri = action.payload;
      const query = stringify({
        sections: 'navigationTree',
        tree: 'documents',
        foldersOnly: 'true',
      });
      return `${repositoryUri}?${query}`;
    },
    successPayloadMapper: compose(
      mapTreeToDirectories,
      path('body.navigationTree'),
    ),
  });
}

export function* documentPrintStatus() {
  yield takeLatest(isActionOf(documentPrintError), function* (action: ActionType<typeof documentPrintError>) {
    yield call(errorToast, 'Unable to print document.');
  });
}

function* loadDocumenSaga() {
  yield createAPIHandler({
    actions: loadDocument,
    requestType: RequestType.Get,
    buildUrl: (action: ActionType<typeof loadDocument.request>) => action.payload,
    successPayloadMapper: response => response.body.node,
  });
}

export function* loadPreviewsSaga() {
  yield createAPIHandler({
    actions: loadPreviews,
    requestType: RequestType.Get,
    buildUrl: (action) => `${action.payload}/previews`,
    successPayloadMapper: path(['body', 'viewport', 'nodes']),
    successMetaMapper: (_, action) => action.meta,
  });
}

export function* loadHistorySaga() {
  yield createAPIHandler({
    actions: loadHistory,
    requestType: RequestType.Get,
    buildUrl: (action) => `${action.payload}/history`,
    successPayloadMapper: (response) => response.body.viewport.nodes
      ? transformHistoryResponse(response.body.viewport.nodes)
      : [],
    successMetaMapper: (_, action) => action.meta,
  });
}

export default function* documentsSaga() {
  yield all([
    fork(fetchDirectoriesSaga),
    fork(moveDocumentSaga),
    fork(copyDocumentSaga),
    fork(loadDocumenSaga),
    fork(loadPreviewsSaga),
    fork(loadHistorySaga),
    fork(documentPrintStatus),
  ]);
}
