import * as React from 'react';

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import LoadingState from 'data/LoadingState';
import { Column } from 'types/schema';

import CreateNewField from '../../Fields/CreateNewField';
import * as FieldData from '../../Fields/data';
import { SelectionState } from '../../Fields/SelectFieldType';
import Switch from '../../Switch';
import ModalActions from '../Modal/Actions';
import ModalContent from '../Modal/Content';
import ModalHeader from '../Modal/Header';
import ModalTextField from '../ModalTextField';
import NewFieldSelect from '../NewFieldSelect';

interface Props extends WithStyles<ClassKey> {
  viewLoadingState: LoadingState;
  viewName: string;
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  permissions?: string[];
  onPrivateStateChange(isPrivate: boolean): void;
  onLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
  viewNameErrorMessage?: string;
  titleAnchorEl?: HTMLElement;
  titleFieldName?: string;
  titleFieldNameErrorMessage?: string;
  selectedTitleField?: Column;
  titleFields: Column[];
  titleFieldData: FieldData.FieldData;
  categoryAnchorEl?: HTMLElement;
  categorySelectionState: SelectionState;
  categoryFieldName?: string;
  categoryFieldNameErrorMessage?: string;
  selectedCategoryField?: Column;
  categoryFields: Column[];
  categoryFieldData: FieldData.FieldData;
  categoryFieldTypeFilterQuery?: string;
  onViewNameChange(viewName: string): void;
  onNewTitleFieldButtonClick(anchorEl: HTMLElement): void;
  onNewTitleFieldClose(): void;
  onTitleFieldChange(field: Column): void;
  onTitleFieldNameChange(newFieldName: string): void;
  onTitleDataUpdate(data: FieldData.FieldData): void;
  onTitleFieldCreated(): void;
  onNewCategoryFieldButtonClick(anchorEl: HTMLElement): void;
  onNewCategoryFieldClose(): void;
  onCategoryFieldChange(field: Column): void;
  onCategoryFieldTypeFilterQueryChange(query: string): void;
  onCategoryFieldTypeSelected(fieldType: FieldData.FieldType): void;
  onToggleSelectCategoryFieldType(): void;
  onCategoryFieldNameChange(newFieldName: string): void;
  onCategoryDataUpdate(data: FieldData.FieldData): void;
  onCategoryFieldCreated(): void;
  onClose(): void;
  onCreateNewCalendarView(): void;
}

function NewCalendarView(props: Props): JSX.Element {
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onViewNameChange(event.currentTarget.value);
  }

  const { selectedCategoryField, selectedTitleField, viewNameErrorMessage, permissions } = props;
  const canLockView = permissions?.includes('views:lock');
  const canCreateField = permissions?.includes('fields:create');
  const submitDisabled = !!viewNameErrorMessage || !selectedTitleField || !selectedCategoryField || props.viewLoadingState === LoadingState.Loading;

  const fieldTypes = (): FieldData.FieldType[] => {
    return [FieldData.FieldType.SingleSelect, FieldData.FieldType.Person].filter(fieldType => {
      if (props.categoryFieldTypeFilterQuery?.length) {
        return fieldType.toLowerCase().includes(props.categoryFieldTypeFilterQuery.toLowerCase());
      }

      return true;
    });
  };

  return (
    <React.Fragment>
      <ModalHeader onClose={props.onClose}>Choose a Grouping Field</ModalHeader>
      <ModalContent>
        <ModalTextField
          value={props.viewName}
          errorMessage={viewNameErrorMessage}
          onChange={onChange}
          className={props.classes.viewNameTextField}
          autoSelect
          autoFocus
        />
        <Switch
          label="Personal view"
          selected={props.isPrivate}
          onClick={() => props.onPrivateStateChange(!props.isPrivate)}
          labelRight
          className="modal-view-switch"
          disabled={props.isLocked || props.isDefault}
        />
        {canLockView && (
          <>
            <Switch
              label="Lock view"
              selected={props.isLocked}
              onClick={() => props.onLockStateChange(!props.isLocked)}
              labelRight
              className="modal-view-switch"
              disabled={props.isPrivate}
            />
            <Switch
              label="Default view"
              selected={props.isDefault}
              onClick={() => props.onDefaultStateChange(!props.isDefault)}
              className={'modal-view-switch'}
              labelRight
              disabled={props.isPrivate}
            />
          </>
        )}
        <NewFieldSelect
          buttonLabel="Create New Category Field"
          selectLabel="GROUP FIELD BY"
          anchorEl={props.categoryAnchorEl}
          selectedField={selectedCategoryField}
          fields={props.categoryFields}
          onNewFieldButtonClick={props.onNewCategoryFieldButtonClick}
          onNewFieldClose={props.onNewCategoryFieldClose}
          onFieldChange={props.onCategoryFieldChange}
          showAddFieldBtn={canCreateField && canLockView}
        >
          <CreateNewField
            fieldTypes={fieldTypes()}
            selectionState={props.categorySelectionState}
            fieldName={props.categoryFieldName}
            errorMessage={props.categoryFieldNameErrorMessage}
            selectedFieldData={props.categoryFieldData}
            fieldTypeFilterQuery={props.categoryFieldTypeFilterQuery}
            onFieldTypeFilterQueryChange={props.onCategoryFieldTypeFilterQueryChange}
            onFieldTypeSelected={props.onCategoryFieldTypeSelected}
            onToggleSelectFieldType={props.onToggleSelectCategoryFieldType}
            onFieldNameChange={props.onCategoryFieldNameChange}
            onDataUpdate={props.onCategoryDataUpdate}
            onCancel={props.onNewCategoryFieldClose}
            onSave={props.onCategoryFieldCreated}
          />
        </NewFieldSelect>
      </ModalContent>
      <ModalActions
        submitLabel="Done"
        submitDisabled={submitDisabled}
        onCancel={props.onClose}
        onSubmit={props.onCreateNewCalendarView}
      />
    </React.Fragment>
  );
}

type ClassKey = 'viewNameTextField';

const styles = withStyles<ClassKey>({
  viewNameTextField: { marginBottom: '25px' },
});

export default styles(NewCalendarView);
