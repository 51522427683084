import { filtersSelector } from 'data/grid-options/gridOptions.selector';
import * as _ from 'lodash/fp';

import { EMPTY_TITLE } from '../../AgGrid/utils';
import { getColumnHeaderClass } from '../../DataGrid/columns/helpers';
import { FieldRendererTypes } from '../../NodeFieldData';
import { parseValueFormatted } from '../../NodeFieldData/number/NumberEditor.utils';
import BaseRenderer from '../BaseRendererPrototype';
import { createSingleLineOfTextElement } from '../singlelineoftext/SingleLineOfTextRenderer';

function NumberRenderer() {
  return true;
}

NumberRenderer.prototype = Object.create(BaseRenderer.prototype);

NumberRenderer.prototype.init = function(params) {
  const { value, fieldType, format = _.identity, type, precision, allowNegative } = params;

  const wrapper = document.createElement('div');
  wrapper.id = `${params.node.id}-${params.column.colId}`;
  wrapper.style = `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  `;

  if (type === FieldRendererTypes.short || type === FieldRendererTypes.createCard) {
    const span = document.createElement('span');
    span.setAttribute('class', getColumnHeaderClass(fieldType));
    span.setAttribute('title', value);
    wrapper.appendChild(span);
    this.element = wrapper;
    return;
  }

  const parsedValue = value === EMPTY_TITLE ? EMPTY_TITLE: parseValueFormatted(value, precision, allowNegative);

  const { valueElement, element } = createSingleLineOfTextElement({ ...params, value: parsedValue && parsedValue !== EMPTY_TITLE ? format(parsedValue) : '' });
  if (valueElement) this.valueElement = valueElement;
  this.element = element;

  if (params.eGridCell) {
    params.eGridCell.classList.remove('highlighted-cell');
    const currentQuickSearch = filtersSelector(window.appStore.getState()).quickSearch;
    if (currentQuickSearch && parsedValue?.toString()?.includes(currentQuickSearch)) {
      params.eGridCell.classList.add('highlighted-cell');
    }
  }

  if (params.node.group) {
    this.handleGroup(params, this.element);
  }
};

NumberRenderer.prototype.getGui = function() {
  return this.element;
};

NumberRenderer.prototype.refresh = function(params) {
  return false;
};

NumberRenderer.prototype.destroy = function() {
  return true;
};

export default NumberRenderer;
