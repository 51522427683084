import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getAllUsers } from '../../data/accounts/actions';
import { getAccountById, isAccountsLoading } from '../../data/accounts/selectors';
import { State } from '../../reducers';
import { AccountNode } from '../../types/response/accountNode';

interface OwnProps {
  accountId: string;
  children(account: AccountNode): React.ReactNode;
}

interface StateProps {
  account: AccountNode | null;
  isLoading: boolean;
}

interface DispatchProps {
  fetchAccounts(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

export class AccountProvider extends React.Component<Props> {
  static accountsLoaded = false;

  public componentDidMount(): void {
    const { account, isLoading } = this.props;

    if (account === null && !isLoading && !AccountProvider.accountsLoaded) {
      this.props.fetchAccounts();
      AccountProvider.accountsLoaded = true;
    }
  }

  public render(): React.ReactNode {
    const { account } = this.props;

    if (account === null) {
      return null;
    }

    return this.props.children(account);
  }
}

const selectors = createStructuredSelector<State, OwnProps, StateProps>({
  account: getAccountById,
  isLoading: isAccountsLoading,
});

const mapDispatchToProps: DispatchProps = {
  fetchAccounts: getAllUsers,
};

export default connect<StateProps, DispatchProps>(selectors, mapDispatchToProps)(AccountProvider);

