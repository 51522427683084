import * as React from 'react';

import * as ModalActions from 'data/modals/actions';
import { connect } from 'react-redux';

import Confirm from '../Confirm';

interface DispatchProps {
  close: typeof ModalActions.closeModal;
}
interface OwnProps {
  closeDialog?: () => void;
}

export type Props = OwnProps & DispatchProps;

const LockPermission = (props: Props): JSX.Element => {
  const { close, closeDialog } = props;

  return (
    <Confirm
      title="This cell is in a locked column, and can be only edited by Administrators."
      close={closeDialog || close}
      onConfirm={closeDialog || close}
      confirmLabel="OK"
      cancelBtn={false}
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  close: ModalActions.closeModal,
};

export default connect<null, DispatchProps>(null, mapDispatchToProps)(
  LockPermission,
);
