import * as React from 'react';

import { Column } from 'types/schema';
import { UpdateFieldPayload } from 'data/collections/collections.reducer';

import Confirm from '../Confirm';
import { getUserDefinedFieldKey } from 'utilities/collections';

interface Props {
  categoryField: Column;
  choiceId: string;
  onUpdateField(payload: UpdateFieldPayload): void;
  onClose(): void;
}

export default function DeleteKanbanColumn(props: Props): JSX.Element | null {
  const { categoryField, choiceId } = props;

  function handleConfirm(): void {
    const { [choiceId]: choice, ...updatedChoices } = categoryField.choices || {};
    const choiceIdIndex = categoryField.choiceOrder && categoryField.choiceOrder.findIndex(id => id === choiceId);
    const updatedChoiceOrder = [...categoryField.choiceOrder || []];

    if (choiceIdIndex) {
      updatedChoiceOrder.splice(choiceIdIndex, 1);
    }

    const payload: UpdateFieldPayload = {
      fieldId: getUserDefinedFieldKey(categoryField.id),
      choices: updatedChoices,
      choiceOrder: updatedChoiceOrder,
    };

    props.onUpdateField(payload);
  }

  if (!categoryField.choices || !categoryField.choices[choiceId]) {
    props.onClose();
    return null;
  }

  return (
    <Confirm
      title={`Are you sure you want to delete the "${categoryField.choices[choiceId].label}" option?`}
      message="It will be removed from all records that have it assigned."
      close={props.onClose}
      onConfirm={handleConfirm}
      confirmLabel="Delete"
    />
  );
}
