import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey =
  'editorWrapper'
  | 'buttonWrapper'
  | 'readerWrapper'
  | 'mentionWrapper'
  | 'linkyWrapper'
  | 'relativeWrapper'
  | 'mentionRow'
  | 'mentionRowFocused'
  | 'mentionName'
  | 'mentionSign'
  | 'mentionIcon'
  | 'reader'
  | 'smallToolbarIcons'
  | 'buttonSeparator'
  | 'hidePlaceholder'
  | 'sendButton'
  | 'editorFocused'
  | 'teamMentionName'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  editorWrapper: {
    '& .DraftEditor-root': {
      'minHeight': '50px',
      'color': colors.dark,
      'fontSize': '16px',
      'borderRadius': '2px',
      'border': `2px solid ${colors.darkGreyTransparent}`,
      'overflow': 'hidden',
      'position': 'relative',
      // 'backgroundColor': colors.white,
      '& .DraftEditor-editorContainer': {
        'height': '100%',
        '& .public-DraftEditor-content': {
          'overflowY': 'auto',
          'wordBreak': 'break-word',
          'height': '100%',
          'maxHeight': '230px',
          'padding': '16px 55px 16px 16px',
        },
      },
      '& .public-DraftEditorPlaceholder-root': {
        'position': 'absolute',
        'top': '16px',
        'left': '16px',
        'zIndex': -1,
      },
    },
    '& blockquote': {
      'borderLeft': '5px solid #eee',
      'color': '#666',
      'fontStyle': 'italic',
      'margin': 0,
      'padding': '10px 20px',
    },
    '& .public-DraftEditorPlaceholder-inner': {
      'opacity': .38,
    },
    '& ol': {
      'margin': 0,
    },
    '& ul': {
      'margin': 0,
    },
    '& a[class^="draftJsMentionPlugin__mention"]': {
      'padding': '2px',
      'display': 'inline-block',
      'borderRadius': '4px',
      'color': colors.hubsyncBlue,
      'backgroundColor': colors.hubsyncBlueTransparent,
    },
    '& span[class*="draftJsMentionPlugin__mention"]': {
      backgroundColor: colors.aliceBlue,
      color: colors.hubsyncBlue,
      padding: '2px',
      borderRadius: '4px',
      display: 'inline-block',
    },
    '& span::selection': {
      'backgroundColor': colors.blueTransparent,
    },
  },

  relativeWrapper: {
    position: 'relative',
  },

  readerWrapper: {
    'wordBreak': 'break-word',
    '& blockquote': {
      'borderLeft': '5px solid #eee',
      'color': colors.dark,
      'fontStyle': 'italic',
      'margin': '10px 0',
      'padding': '10px 20px',
    },
  },

  buttonWrapper: {
    'display': 'flex',
    'marginBottom': '10px',
  },

  mentionWrapper: {
    padding: '2px',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: colors.aliceBlue,
    color: colors.hubsyncBlue,
  },
  linkyWrapper: {
    padding: '2px',
    display: 'inline-block',
    borderRadius: '4px',
    color: colors.hubsyncBlue,
    textDecoration: 'underline',
  },

  mentionRow: {
    display: 'flex',
    margin: '5px 0',
    padding: '5px 10px',
  },

  mentionRowFocused: {
    backgroundColor: colors.hubsyncBlueTransparent,
  },

  mentionName: {
    fontSize: '14px',
    marginTop: '7px',
    marginLeft: '10px',
  },

  teamMentionName: {
    fontSize: '14px',
  },

  mentionIcon: {
    '&:before': {
      content: '"\\e424"',
    },
  },

  reader: {
    '& .e-richtexteditor .e-rte-content': {
      background: 'transparent',
    },
    '& .e-richtexteditor:not(.e-rte-toolbar-enabled)': {
      border: 'none',
    },
  },

  smallToolbarIcons: {
    '& .e-toolbar-item': {
      'margin': '0!important',
      'padding': '0!important',
      'min-width': '30px!important',
      'width': '30px!important',
      '.e-btn-icon.e-icons': {
        'font-size': '14px!important',
      },
    },
  },

  mentionSign: {
    'fontSize': '12px',
    'color': colors.slate,
    'marginRight': '10px',
    'display': 'inline-block',
    'height': '24px',
    'width': '21px',
    '& > svg': {
      'marginTop': '4px',
      'width': '16px',
      'height': '16px',
    },
  },

  buttonSeparator: {
    'display': 'inline-block',
    'width': '1px',
    'height': '24px',
    'marginLeft': '5px',
    'marginRight': '8px',
    'backgroundColor': colors.slate,
  },
  hidePlaceholder: {
    '& .public-DraftEditorPlaceholder-root': {
      'display': 'none',
    },
  },

  sendButton: {
    'position': 'absolute',
    'top': 0,
    'right': '10px',
    'bottom': 0,
    'margin': 'auto',
    'zIndex': 1000,
    'width': '40px',
    'height': '40px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'paddingLeft': '2px',
    '&:hover': {
      backgroundColor: colors.skyBlue,
    },
    '& svg': {
      height: '22px',
      color: colors.hubsyncBlue,
    },
    '&:disabled:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '&:disabled > svg': {
      color: colors.lightGrayishBlue,
    },
  },

  editorFocused: {
    '& .DraftEditor-root': {
      borderColor: colors.hubsyncBlue40Transparent,
    },
  },
};
