import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import ToolWrapper from 'components/DocumentPreview/Tools/ToolWrapper';
import { iceBlue, lightBlueGrey } from 'styles/colors';
import svgIcons from 'styles/svgIcons';

const ToolsBox = styled(ToolWrapper)`
  background: rgba(0, 0, 0, 0.83);
  border-radius: 4px;
  padding: 5px 25px;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
`;

export const PreviewToolsBar = styled(ToolsBox)`
  display: flex;
  bottom: 24px;
`;

export const ToolsGroup = styled.div`
  display: flex;
  align-items: center;
  color: ${lightBlueGrey};

  &:not(:last-child) {
    margin-right: 24px;
  }

  svg {
    color: ${lightBlueGrey};
    opacity: 0.83;
  }
`;

export const ToolButton = styled(IconButton)`
  &&:disabled {
    opacity: 0.5;
  }
`;

export const PaginationIconPrev = styled(svgIcons.Pagination)`
  transform: rotate(-90deg);
  height: 20px;
`;

export const PaginationIconNext = styled(svgIcons.Pagination)`
  transform: rotate(90deg);
  height: 20px;
`;

export const ZoomInIcon = styled(svgIcons.ZoomIn)`
  height: 22px;
`;

export const ZoomOutIcon = styled(svgIcons.ZoomOut)`
  height: 22px;
`;

export const FullscreenIcon = styled(svgIcons.Fullscreen)`
  height: 20px;
`;

export const FullscreenExitIcon = styled(svgIcons.FullscreenExit)`
  height: 20px;
`;

export const PageOfPages = styled.div`
  font-size: 13px;
  min-width: 50px;
  text-align: center;
`;

export const FullscreenBoxWrapper = styled(ToolsBox)`
  padding-top: 21px;
  padding-bottom: 21px;
  color: ${iceBlue};
  font-size: 14px;
  display: inline-block;
  top: 25px;
`;

export const BorderedText = styled.span`
  padding: 6px 10px;
  border: 1px solid ${iceBlue};
  border-radius: 2px;
  margin: 0 3px;
`;


