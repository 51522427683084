import React, { useEffect, useState } from 'react';

import withStyles, {
  CSSProperties,
  WithStyles,
} from '@material-ui/core/styles/withStyles';
import Button, { Variant as ButtonVariant } from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modals/Modal';
import Content from 'components/Modals/Modal/Content';
import { getApiV2Url } from 'env';
import { useSelector } from 'react-redux';
import { getRequest } from 'utilities/httpRequests';

import { AssignRoles } from '.';
import { IAssigns } from './AssignRoles';
import { BoxItem } from './RoleBox';

export interface MultiUserAssignProps extends WithStyles<typeof styles> {
  onClose?: () => void;
  onDone: (assigns?: IAssigns) => void;
}

function MultiUserAssign({ onClose, onDone, classes }: MultiUserAssignProps) {
  const state = useSelector((state) => state);
  const [noAccessItems, setNoAccessItems] = useState<BoxItem[]>([]);
  const [assigns, setAssigns] = useState<IAssigns>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isUnmounted = false;
    getRequest(`${getApiV2Url()}/workspaces?api_version=2`, state).then(
      (response) => {
        if (isUnmounted) return;

        const allWorkspaces = response.body.map(({ _id, title, meta }) => ({
          id: _id,
          title,
          meta,
        }));

        setNoAccessItems(allWorkspaces);
        setLoading(false);
      },
    );

    return () => {
      isUnmounted = true;
    };
  }, []);

  const handleOnClose = () => {
    if (onClose instanceof Function) onClose();
  };
  const handleOnDone = async () => {
    if (onDone instanceof Function) {
      await onDone(assigns);
    }
  };

  const handleAssignChange = (data: IAssigns) => {
    setAssigns(data);
  };

  return (
    <Modal onClose={handleOnClose}>
      <Content>
        {loading && <LoadingIndicator />}
        {!loading && (
          <>
            <AssignRoles
              title="New User workspaces"
              noAccessBoxItems={noAccessItems}
              readerBoxItems={[]}
              editorBoxItems={[]}
              adminBoxItems={[]}
              onAssignChange={handleAssignChange}
            />
            <div className={classes.field}>
              <div
                className={`${classes.rowWithSpaceInBetween} ${classes.relative}`}
              >
                <Button
                  id="btnCancelInvite"
                  label="Back to invite"
                  onClick={onClose}
                  variant={ButtonVariant.SecondaryLink}
                />
                <Button
                  id="btnSendInvite"
                  label="Send Invite"
                  onClick={handleOnDone}
                  variant={ButtonVariant.Primary}
                  disabled={
                    assigns?.Reader.length === 0 &&
                    assigns?.Editor.length === 0 &&
                    assigns?.Administrator.length === 0
                  }
                />
              </div>
            </div>
          </>
        )}
      </Content>
    </Modal>
  );
}
const styles: Record<
  'field' | 'rowWithSpaceInBetween' | 'relative',
  CSSProperties
> = {
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  rowWithSpaceInBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.25rem',
  },
  relative: {
    position: 'relative',
  },
};

export default withStyles(styles)(MultiUserAssign);
