import { createStandardAction, ActionType } from 'typesafe-actions';

export const SHOW_GROUP_PANEL = 'groupBy/SHOW_GROUP_PANEL';
export const HIDE_GROUP_PANEL = 'groupBy/HIDE_GROUP_PANEL';

export const changeRowGroupPanelShow = createStandardAction('groupBy/SET_ROW_GROUP_PANEL_SHOW')();

interface ShowGroupPanel {
  type: typeof SHOW_GROUP_PANEL;
}

export const showGroupPanel = (): ShowGroupPanel => {
  return {
    type: SHOW_GROUP_PANEL,
  };
};

interface HideGroupPanel {
  type: typeof HIDE_GROUP_PANEL;
}

export const hideGroupPanel = (): HideGroupPanel => {
  return {
    type: HIDE_GROUP_PANEL,
  };
};

export const actions = {
  changeRowGroupPanelShow,
  showGroupPanel,
  hideGroupPanel,
};

export type Actions = ActionType<typeof actions>;
