import * as React from 'react';
import { EMAIL_REGEXP } from '../../constants';
import {
  AgGridEvent,
  BodyScrollEvent,
  CellClickedEvent,
  CellDoubleClickedEvent,
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellRange,
  CellRangeParams,
  ColDef,
  ColGroupDef,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetContextMenuItems,
  GetMainMenuItems,
  GridReadyEvent,
  NewColumnsLoadedEvent,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  RangeSelectionChangedEvent,
  RowDragEvent,
  RowNode,
  RowSelectedEvent,
  SortChangedEvent,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { AG_GRID_STATES } from 'components/DataGrid/enums/agGridStates';
import { Revision, setRowIndexMap, UpdateNodeRequestPayload } from 'data/collections/collections.actions.new';
import { GridColumnState, ItemHistory } from 'data/collections/collections.reducer';
import { actions as viewConfigActions } from 'data/collections/view-config/viewConfig.actions';
import { getTenantConfig } from 'env';
import { JSONSchema7 } from 'json-schema';
import * as KeyCode from 'keycode-js';
import { uniqueId } from 'lodash';
import * as _ from 'lodash/fp';
import moment from 'moment';

import { DEBOUNCE_MILLIS } from '../../constants';
import { BASE_ROW_HEIGHT } from '../../styles/constants';
import { FilterModel, SortModelState } from '../../types/gridOptions';
import { CommonNode } from '../../types/response';
import { GroupRow, Schema } from '../../types/schema';
import { validateNode } from '../../utilities/validation';
import { ADD_NEW_FIELD_ID, ROW_NUMBER_ID, SELECTABLE_ROW } from '../DataGrid/columns/constants';
import AgGridApi, { GridContext } from './AgGridApi';
import AggregationStatusBarComponent from './components/StatusBar/AggregationStatusBarComponent';
import { removeHTMLTag } from '../../utilities/format';
import { checkProcessesInRecords, stopPolling } from 'data/workspaceCreate/actions';


// import ColumnSummary from 'components/ColumnSummary';
// Styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import './style.scss';
import { hubsyncBlue } from 'styles/colors';

import 'ag-grid-enterprise';
// import { isDB } from 'utilities/parseURI';
import { StoreSubscriber } from '../CellRenderers/StoreSubscriber';
import ColumnSummaryGridApi from './ColumnSummaryGridApi';

import { createPopper } from '@popperjs/core';

import { FieldType } from '../../types/response/fieldNode';
import { getUrlsLastItem } from '../../utilities/queryParams';
import { TenantConfig } from '../../types/tenantConfig';
import loadingAction from '../../data/ui/loading/actions';
import { connect } from 'react-redux';
import { notifications } from 'data/ui/notifications/notifications.actions';

// TODO: Find type for params
type RuleFn = (params) => boolean;

export interface Props<Node extends CommonNode> {
  nodes: Node[];
  columnDefs: (ColDef | ColGroupDef)[];
  columnsState: GridColumnState[];
  rowHeight: number;
  floatingFilter: boolean;
  schema: Schema;
  itemsReady?: boolean;
  agGridProps: Partial<AgGridReactProps>;
  rowClassRules: { [cssClassName: string]: string | RuleFn };
  context?: GridContext;
  getContextMenuItems: GetContextMenuItems;
  getMainMenuItems: GetMainMenuItems;
  onGridReady: (agGridApi?: AgGridApi<Node>) => void;
  columnSummaryGridOnReady: (api?: ColumnSummaryGridApi) => void;
  rowSelectionEnabled?: boolean;
  filterModel: FilterModel;
  sortModel: SortModelState;
  setRowIndexMap: typeof setRowIndexMap;
  customRowOrder: Record<string, number>;
  setCustomRowPosition: typeof viewConfigActions.setCustomRowPosition;
  deleteCustomRowPosition: typeof viewConfigActions.deleteCustomRowPosition;
  onColumnsChange(payload: GridColumnState[]): void;
  onFilterChanged(event: FilterChangedEvent): void;
  onNewColumnsLoaded(event: NewColumnsLoadedEvent): void;
  onRangeSelectionChanged?(event: RangeSelectionChangedEvent): void;
  onSortChanged(event: SortChangedEvent): void;
  onUpdateNode(payload: UpdateNodeRequestPayload): void;
  displayTotalRowCount?: boolean;
  agGridScroll(event: BodyScrollEvent): void;
  setSelectedRowCount(options: number): void;
  selectedRowCount: number;
  permissions: string[];
  // suppressRowTransform: boolean;
  openNodeModal(node: Partial<CommonNode>, windowTitle?: string, enableSwitcher?: boolean): void;
  chart?: any;
  rowIndexMap?: Record<string, number>;
  viewId?: string;
  resetFilterAndSort: () => void;
  onCustomRowOrderChange?: (rowIdIndexMap: Record<string, number>) => void;
  processCellForClipboard?(params: ProcessCellForExportParams): any;
  processCellFromClipboard?(params: ProcessCellForExportParams): any;
  handleCellEditingStarted?: (event: CellEditingStartedEvent) => void;
  handleCellEditingStopped?: (event: CellEditingStoppedEvent) => void;
  openLockPermissionsModal?: () => void;
  agGridState?: string;
  itemHistory: ItemHistory;
  redoItem: () => void;
  undoItem: () => void;
  lastUpdatedItem: {
    id: string;
    version: number;
  };
  notLockingLoading?: boolean;
}

interface DispatchProps {
  endLockingLoader: () => void;
  checkProcessesInRecords: () => void;
  stopPolling: () => void;
  warningToast: typeof notifications.warn;
}

interface FocusedCell {
  rowIndex: number | null;
  colKey: string | null;
}

enum ItemKeyboardMode {
  UNDO = 'undo',
  REDO = 'redo'
}

const tenantConfig: TenantConfig = getTenantConfig();

type OwnProps = Props<CommonNode> & DispatchProps;
class AgGrid<Node extends CommonNode> extends React.PureComponent<OwnProps> {
  agGridApi?: AgGridApi<Node>;
  columnSummaryGridApi?: ColumnSummaryGridApi;
  gridKey = uniqueId('grid-');
  mounted = false;
  selectionTimeout = 0;
  resizeTimeout = 0;
  agGridAutoColumnId = 'ag-Grid-AutoColumn';
  focusedCell: FocusedCell = {
    rowIndex: null,
    colKey: null,
  };
  popper;
  triggerRef = React.createRef();
  popupRef = React.createRef();

  state = {
    modalTitle: '',
    agGridState: AG_GRID_STATES.BROWSE,
  };
  private customDoubleClickedFieldTypes = [FieldType.Multilinetext];

  public componentDidMount(): void {
    this.mounted = true;
    this.handleChartMode();
    StoreSubscriber.init();
    this.setSuppressRowDrag();
  }

  public render(): JSX.Element {
    const rowClass = this.props.rowSelectionEnabled ? SELECTABLE_ROW : undefined;
    const suppressRowDrag = !this.isSortModelEmpty();
    const displayTotalRowCount = this.props.displayTotalRowCount;

    const topOptions = {
      alignedGrids: [] as any,
      suppressRowClickSelection: false,
    };
    const bottomOptions = {
      alignedGrids: [] as any,
      suppressHorizontalScroll: true,
    };

    topOptions.alignedGrids.push(bottomOptions);
    bottomOptions.alignedGrids.push(topOptions);

    return (
      <>
        <AgGridReact
          getChildCount={getChildCount}
          onColumnResized={this.onColumnsResized}
          headerHeight={BASE_ROW_HEIGHT}
          groupHeaderHeight={BASE_ROW_HEIGHT}
          floatingFiltersHeight={BASE_ROW_HEIGHT}
          pivotHeaderHeight={BASE_ROW_HEIGHT}
          pivotGroupHeaderHeight={BASE_ROW_HEIGHT}
          onGridReady={this.onGridReady}
          onNewColumnsLoaded={this.props.onNewColumnsLoaded}
          onRangeSelectionChanged={this.props.onRangeSelectionChanged}
          defaultColDef={{ valueSetter: this.valueSetter }}
          columnDefs={this.getColumnDefs()}
          getContextMenuItems={this.props.getContextMenuItems}
          rowHeight={this.props.rowHeight}
          allowContextMenuWithControlKey
          getRowNodeId={getRowNodeId}
          groupUseEntireRow={false}
          onRowDataUpdated={(RowDataUpdatedEvent) => {
            if (this.props.itemsReady) {
              this.props.endLockingLoader();
            }
          }}
          tabToNextCell={(params) => {
            const nextCellSingleClickEdit = params.nextCellPosition.column['colDef'].singleClickEdit;
            if (nextCellSingleClickEdit) {
              setTimeout(() => {
                this.agGridApi?.gridApiInstance().startEditingCell({
                  rowIndex: params.nextCellPosition.rowIndex,
                  colKey: params.nextCellPosition.column.getColId(),
                });
              }, 0);
            } else {
              // when in edit mode, tab will move to next cell also in edit mode, need to prevent that
              setTimeout(() => {
                this.agGridApi?.stopEditing();
              }, 0);
            }
            return {
              rowIndex: params.nextCellPosition.rowIndex,
              column: params.nextCellPosition.column,
              rowPinned: undefined,
            };
          }}
          context={this.props.context}
          rowDragManaged={true}
          onDragStopped={this.handleColumnMove}
          suppressMoveWhenRowDragging={true}
          suppressDragLeaveHidesColumns
          key={this.gridKey}
          enableFillHandle={true}
          enterMovesDownAfterEdit={true}
          rememberGroupStateWhenNewData={true}
          rowClassRules={this.props.rowClassRules}
          rowClass={rowClass}
          rowGroupPanelShow="always"
          rowData={this.props.nodes}
          cacheBlockSize={10000}
          blockLoadDebounceMillis={DEBOUNCE_MILLIS}
          suppressNoRowsOverlay
          suppressPropertyNamesCheck={true}
          // suppressRowTransform={this.props.suppressRowTransform}
          suppressScrollOnNewData
          suppressSetColumnStateEvents
          suppressKeyboardEvent={(params) => {
            if (params.event.code === 'Space' && !params.editing) {
              this.props.openNodeModal(params.node.data, '', true);
              return true;
            }
            return false;
          }}
          suppressRowClickSelection
          onCellFocused={(e) => {
            if (e.column && (e.column.getColId() === 'row_number_id' || e.column.getColId() === '_new')) {
              e.api.clearRangeSelection();
            }

            if (e.column && e.column.getColDef() && e.column.getColDef().showRowGroup) {
              if (this?.agGridApi?.isGroupMode()) {
                this.setRowIndexMap();
              }
              e.api.clearRangeSelection();
              return;
            }

            if (e.column && e.column.getColDef() && e.column.getColDef().editable && e.column.getColDef()?.refData?.fieldType === FieldType.Attachment) {
              e.api.startEditingCell({
                rowIndex: e.rowIndex,
                colKey: e.column,
              });
            }
          }}
          deltaRowDataMode
          onCellContextMenu={this.onCellContextMenu}
          onFilterChanged={this.props.onFilterChanged}
          onSortChanged={this.props.onSortChanged}
          onColumnVisible={this.handleColumnsVisibilityChanged}
          onColumnRowGroupChanged={this.handleColumnRowGroupChanged}
          onColumnPinned={this.handleColumnPinned.bind(this)}
          onCellEditingStarted={this.handleCellEditingStarted}
          onCellEditingStopped={this.handleCellEditingStopped}
          getMainMenuItems={this.props.getMainMenuItems}
          floatingFilter={this.props.floatingFilter}
          onRowDragEnd={this.onRowDragEnd}
          suppressRowDrag={suppressRowDrag}
          postProcessPopup={this.postProcessPopup}
          suppressMakeColumnVisibleAfterUnGroup
          onRowSelected={this.onRowSelected}
          frameworkComponents={{
            agregationStatusBarComponent: AggregationStatusBarComponent,
          }}

          statusBar={{
            statusPanels: [
              {
                statusPanel: 'agAggregationComponent',
                statusPanelParams: {
                  aggFuncs: ['count'],
                },
              },
              {
                statusPanel: 'agregationStatusBarComponent',
                align: 'right',
              },
              ...displayTotalRowCount ? [{
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
              }] : [],
            ],
          }}
          {...this.props.agGridProps}
          onBodyScroll={this.props.agGridScroll}
          enableCharts
          suppressAggFuncInHeader={true}
          enableRangeSelection
          autoGroupColumnDef={this.getAutoColumnDef()}
          onRowGroupOpened={this.onRowGroupOpened}
          onFirstDataRendered={(event) => {
            this.props.checkProcessesInRecords();
            this.handleChart();
          }}
          onModelUpdated={(event) => {
            if (event.keepRenderedRows === undefined) {
              this.handleChart();
            }
          }}
          onChartCreated={this.onChartCreated}
          gridOptions={topOptions}
          animateRows={true}
          enableMultiRowDragging={true}
          processCellForClipboard={this.props.processCellForClipboard}
          processCellFromClipboard={this.props.processCellFromClipboard}
          onCellClicked={this.onCellClicked}
          onCellDoubleClicked={this.onCellDoubleClicked}
          onCellKeyDown={this.onCellKeyDown}
        />
        {/* @ts-ignore */}
        <div ref={this.popupRef}></div>
      </>
    );
  }

  private handleColumnMove = (event) => {
    const parentElement = event?.target?.parentElement;
    if (event.type === 'dragStopped' && parentElement?.classList?.contains('ag-header-cell')) {
      const { columnApi } = event;
      const newColumnState = columnApi.getColumnState() as GridColumnState[];
      this.props.onColumnsChange(newColumnState);
    }
  };

  private onCellKeyDown = (event) => {
    const keyCode = event.event.keyCode;
    const commandOrControl = event.event.metaKey || event.event.ctrlKey;
    if ((commandOrControl && event.event.shiftKey && keyCode === KeyCode.KEY_Z) || (commandOrControl && keyCode === KeyCode.KEY_Y)) {
      if (this.props.itemHistory.redo.length) {
        this.focusItem(ItemKeyboardMode.REDO);
        this.props.redoItem();
      }
      return;
    }
    if (commandOrControl && keyCode === KeyCode.KEY_Z && this.props.itemHistory.undo.length) {
      this.focusItem(ItemKeyboardMode.UNDO);
      this.props.undoItem();
    }
  };

  private focusItem = (mode: ItemKeyboardMode) => {
    const revision: Revision = this.props.itemHistory[mode][this.props.itemHistory[mode].length - 1];
    const itemId = getUrlsLastItem(revision.url);
    const itemIndex = this.props.nodes.findIndex(node => node.id == itemId);
    if (itemIndex >= 0) {
      this.agGridApi?.clearSelection();
      this.agGridApi?.setFocusedCell(itemIndex, `fields.${revision.fieldId}`);
    }
  };

  private onCellClicked = (event: CellClickedEvent): void => {
    const body = document.getElementsByTagName('body');
    if (body[0]) {
      const last = body[0].lastChild as HTMLDivElement;
      if (last?.id.includes('Link_Quick_Popup_')) {
        last.parentElement?.removeChild(last);
      }
    }

    const focusedCell = {
      rowIndex: event.rowIndex,
      colKey: event.column.getColId(),
    };
    // @ts-ignore
    if ((event.colDef.columnType === FieldType.Singlechoice || event.colDef.columnType === FieldType.Multiplechoice || event.colDef.columnType === FieldType.Account) && event.colDef.editable && _.isEqual(focusedCell, this.focusedCell)) {
      event.api.startEditingCell(focusedCell);
    }
    this.focusedCell = { ...focusedCell };
  };

  private onCellDoubleClicked = ({ colDef }: CellDoubleClickedEvent): void => {
    const canUpdateItems = this.props.permissions.includes('items:update');
    const fieldType = colDef.refData?.fieldType;
    const hasCustomDoubleClicked = fieldType && this.customDoubleClickedFieldTypes.includes(fieldType as FieldType);
    if (colDef.refData?.lock && !colDef.editable && canUpdateItems && !hasCustomDoubleClicked) {
      this.props.openLockPermissionsModal?.();
    }
  };

  private onChartCreated = (): void => {
    const canvas = document.querySelector('.ag-charts-canvas');
    canvas?.addEventListener('click', this.chartClickHandler);
  };

  private chartClickHandler = () => {
    if (!this.agGridApi) return;
    const [chart] = this.agGridApi.getChartModels();
    if (!chart) return;
    const tooltip = document.querySelector('.ag-chart-tooltip-visible');
    const content = tooltip?.querySelector('.ag-chart-tooltip-content');
    const groupLevelText = content?.textContent?.split(':');
    if (!groupLevelText) return;
    const groupLevels = groupLevelText[0]?.split('-');
    const groupName = groupLevels[groupLevels.length - 1]?.trim();
    const startIndex = chart.cellRange.rowStartIndex || 0;
    const endIndex = chart.cellRange.rowEndIndex || 0;
    if (!endIndex) return;
    for (let index = startIndex; index <= endIndex; index++) {
      const node = this.agGridApi.getDisplayedRowAtIndex(index);
      if (!node.expanded && `${node.key}`.trim() === groupName) {
        node.setExpanded(true);
        break;
      }
    }
  };

  private handleChart = (event?): void => {
    if (!this.agGridApi) return;
    if (!this.props.chart) {
      this.agGridApi.destroyAllActiveCharts();
      return;
    }
    if (this.agGridApi.getChartModels().length) return;
    this.agGridApi.setColumnDefs([]);
    this.agGridApi.setColumnDefs(this.getColumnDefs());
    this.createChart({ rowStartIndex: 0, rowEndIndex: this.agGridApi.getDisplayedRowCount() });
  };

  private hasExpandedLeafGroup = (): boolean | undefined => {
    if (!this.agGridApi) return;
    let expandedGroup = false;
    const displayedNodeCount = this.agGridApi.getDisplayedRowCount();
    for (let index = 0; index < displayedNodeCount; index++) {
      const node = this.agGridApi.getDisplayedRowAtIndex(index);
      if (node.leafGroup && node.expanded) {
        expandedGroup = node.leafGroup;
        break;
      }
    }
    return expandedGroup;
  };

  private onRowGroupOpened = (event: any): void => {
    if (!this.agGridApi) return;
    if (!tenantConfig?.enabledFeatures?.orderInGrouping) {
      this.agGridApi?.setSuppressRowDrag(true);
    }

    if (this.hasExpandedLeafGroup()) {
      this.agGridApi.destroyAllActiveCharts();
      return;
    }
    this.agGridApi.destroyAllActiveCharts();
    let rowStartIndex = event.node.expanded ? event.rowIndex : 0;
    let rowEndIndex = event.node.expanded ? event.rowIndex + event.node.childrenAfterGroup.length : this.agGridApi.getDisplayedRowCount();
    if (!event.node.expanded && !event.node.leafGroup) {
      const expandedNode = event.node.parent.childrenAfterGroup.find((node: RowNode) => node.expanded);
      if (expandedNode) {
        rowStartIndex = expandedNode.rowIndex;
        rowEndIndex = expandedNode.rowIndex + expandedNode.childrenAfterGroup.length;
      }
    } else if (event.node.leafGroup && event.node.parent.id !== 'ROOT_NODE_ID') {
      rowStartIndex = event.node.parent.rowIndex;
      rowEndIndex = event.node.parent.rowIndex + event.node.parent.childrenAfterGroup.length;
    }
    this.createChart({ rowStartIndex, rowEndIndex });
  };

  private createChart = (dataParams) => {
    if (!this.agGridApi || !this.props.chart) return;
    const createRangeChartParams = {
      cellRange: {
        rowStartIndex: dataParams.rowStartIndex,
        rowEndIndex: dataParams.rowEndIndex,
        columns: [this.agGridAutoColumnId, this.props.chart.field],
      },
      chartType: this.props.chart.type,
      suppressChartRanges: true,
      processChartOptions: (chartParams) => {
        const options = chartParams.options;
        options.legend.enabled = false;
        options.seriesDefaults.fill.colors = [hubsyncBlue];
        options.seriesDefaults.stroke.colors = [hubsyncBlue];
        options.padding.top = 0;
        options.padding.bottom = 0;
        if (options.xAxis) {
          options.xAxis.label.fontSize = 10;
          return options;
        }
      },
    };
    this.agGridApi.clearSelection();
    this.agGridApi.createRangeChart(createRangeChartParams);
  };

  private getAutoColumnDef = (): ColDef | undefined => {
    if (!this.props.chart) {
      return {
        cellRendererParams: {
          suppressDoubleClickExpand: true,
          suppressCount: true,
          checkbox: false,
          minWidth: 250,
        },
      };
    }
    return {
      field: this.props.chart.autoField,
      cellRendererParams: {
        suppressCount: true,
      },
      headerName: 'Group',
      minWidth: 250,
    };
  };

  private getColumnDefs = (): ColDef[] => {
    const columnDefs = [...this.props.columnDefs as any].map(columnDef => {
      if (this.props.chart && this.props.chart.field === columnDef.colId) {
        columnDef.aggFunc = this.props.chart.aggregation;
      } else if (this.props.chart) {
        columnDef.enableRowGroup = false;
      }

      const fieldType = (columnDef.refData && columnDef.refData.fieldType) || '';
      const addComparator = [FieldType.Singlechoice, FieldType.Multiplechoice, FieldType.Account].includes(fieldType);

      if (fieldType === FieldType.Date) {
        columnDef.comparator = function(valueA, valueB) {
          valueA = valueA ? moment(valueA).unix() : 0;
          valueB = valueB ? moment(valueB).unix() : 0;
          if (valueA == valueB) {
            return 0;
          } else {
            return (valueA > valueB) ? 1 : -1;
          }
        };
      }

      if (fieldType === FieldType.Email || fieldType === FieldType.Singlelineoftext || fieldType === FieldType.Multilinetext) {
        columnDef.comparator = function(valueA, valueB) {
          valueA = removeHTMLTag(valueA?.toLowerCase()) || '';
          valueB = removeHTMLTag(valueB?.toLowerCase()) || '';

          if (valueA == valueB) {
            return 0;
          } else {
            return (valueA > valueB) ? 1 : -1;
          }
        };
      }

      if (!addComparator) return columnDef;

      if (fieldType === FieldType.Account) {
        return {
          ...columnDef,
          comparator: (valueA, valueB) => {
            try {
              let valuesA: any[] = [];
              let valuesB: any[] = [];
              if (!Array.isArray(valueA)) {
                valuesA = [valueA];
              } else {
                valuesA = [...valueA];
              }
              if (!Array.isArray(valueB)) {
                valuesB = [valueB];
              } else {
                valuesB = [...valueB];
              }
              const accountsById = columnDef.refData.accountsById;
              const valueAChoices = valuesA.map(value => accountsById[value] && accountsById[value].displayName.toLowerCase()).join('');
              const valueBChoices = valuesB.map(value => accountsById[value] && accountsById[value].displayName.toLowerCase()).join('');
              // @ts-ignore
              return valueAChoices - valueBChoices;
            } catch (error) {
              console.error('error', error);
            }
          },
        };
      }
      return {
        ...columnDef,
        comparator: (valueA, valueB) => {
          try {
            let valuesA: any[] = [];
            let valuesB: any[] = [];
            if (!Array.isArray(valueA)) {
              valuesA = [valueA];
              valuesB = [valueB];
            }
            const choices = columnDef.refData.choices;
            const valueAChoices = valuesA.map(value => choices[value] && choices[value].label.toLowerCase()).join('');
            const valueBChoices = valuesB.map(value => choices[value] && choices[value].label.toLowerCase()).join('');
            // @ts-ignore
            return valueAChoices - valueBChoices;
          } catch (error) {
            console.error('error', error);
          }
        },
      };
    });
    return columnDefs;
  };

  private postProcessPopup = (event: PostProcessPopupParams): void => {
    this.popper = createPopper(
      // @ts-ignore
      event.eventSource,
      event.ePopup,
      {
        placement: 'auto-end',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              boundary: document.querySelector('body'),
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: ({ placement }) => {
                if (placement === 'top') {
                  return [0, 40];
                } else {
                  return [];
                }
              },
            },
          },
        ],
      },
    );
    setTimeout(() => {
      const clientRect = event.ePopup.getBoundingClientRect();
      if (clientRect.top + clientRect.height > window.innerHeight) {
        event.ePopup.style.bottom = '0';
      }
    }, 0);
  };

  public componentDidUpdate(prevProps: OwnProps): void {
    if (!this.agGridApi) {
      return;
    }

    const { rowHeight } = this.props;

    if (prevProps.rowHeight !== rowHeight) {
      this.agGridApi.resetRowHeights();
      this.agGridApi.redrawRows();
    }
    // let filterChanged = false;
    let sortingChanged = false;

    if (!_.isEqual(prevProps.filterModel.searchFilters, this.props.filterModel.searchFilters)) {
      setTimeout(() => {
        if (this.agGridApi) {
          this.agGridApi.filter(this.props.filterModel);
        }
      }, 0);
      if (this.agGridApi.isGroupMode()) {
        this.setRowIndexMap();
      }
      // filterChanged = true;
    }

    if (!_.isEqual(prevProps.filterModel.regularFilters, this.props.filterModel.regularFilters)) {
      if (this.agGridApi.isGroupMode()) {
        this.setRowIndexMap();
      }
      // filterChanged = true;
    }

    if (!_.isEqual(prevProps.sortModel, this.props.sortModel)) {
      this.agGridApi.sort(this.props.sortModel);
      if (this.agGridApi.isGroupMode()) {
        this.setRowIndexMap();
      }
      sortingChanged = true;

      setTimeout(() => {
        this.agGridApi?.refreshCells([ROW_NUMBER_ID]);
      }, 100);
    }

    if (prevProps.selectedRowCount > 0 && this.props.selectedRowCount === 0) {
      this.agGridApi.clearSelection();
    }

    // THIS COMPARISON IS NOT ACCURATE, ITS DONE ONLY TO HANDLE THE INITIALIZATION CASE
    // CAUTION: IS GROUP MODE IS NOT BEING UPDATED ON VIEW SWITCH
    if (this.agGridApi && this.agGridApi.isGroupMode()) {
      if (this.props.rowIndexMap && !Object.keys(this.props.rowIndexMap).length) {
        this.setRowIndexMap();
      }
    }

    if (prevProps.viewId !== this.props.viewId) {
      setTimeout(() => {
        if (this.agGridApi) {
          this.props.setRowIndexMap(this.agGridApi.getGroupingRowIndexMap());
          this.agGridApi.refreshCells([ROW_NUMBER_ID]);
        }
      }, 100);
    }

    if (prevProps.nodes.length !== this.props.nodes.length) {
      if (this?.agGridApi?.isGroupMode()) {
        this.setRowIndexMap();
        setTimeout(() => {
          this.agGridApi &&this.agGridApi.refreshCells([ROW_NUMBER_ID]);
        }, 100);
      }
    }

    if (sortingChanged) {
      const isSorted = this.props.sortModel.allIds.length > 0;
      this.agGridApi?.setSuppressRowDrag(isSorted);
      this.agGridApi?.refreshCells(['row_number_id']);
    }

    if (!_.isEqual(prevProps.lastUpdatedItem, this.props.lastUpdatedItem)) {
      const item = this.agGridApi?.gridApiInstance().getRowNode(this.props.lastUpdatedItem.id);
      if (item) {
        this.agGridApi.gridApiInstance().refreshCells({ columns: [ROW_NUMBER_ID], rowNodes: [item], force: true });
      }
    }

    this.handleChartMode();
  }

  public componentWillUnmount(): void {
    this.props.endLockingLoader();
    this.props.stopPolling();
    this.mounted = false;
    this.props.onGridReady();
    if (this.props.chart) document.body.classList.remove('ag-grid-chart-mode');
    StoreSubscriber.destroy();
  }

  private handleChartMode = () => {
    if (this.props.chart) {
      document.body.classList.add('ag-grid-chart-mode');
      return;
    }
    document.body.classList.remove('ag-grid-chart-mode');
  };

  private isFilterModelEmpty = (): boolean => {
    const { regularFilters, searchFilters, quickSearch } = this.props.filterModel;
    return _.isEmpty(regularFilters) && _.isEmpty(searchFilters) && _.isEmpty(quickSearch);
  };

  private isSortModelEmpty = (): boolean => this.props.sortModel.allIds.length === 0;

  private setSuppressRowDrag = () => {
    setTimeout(() => {
      this.agGridApi?.setSuppressRowDrag(!this.isSortModelEmpty());
    }, 0);
  };

  private handleColumnsStateChanged = (event: AgGridEvent): void => {
    const { columnApi } = event;
    const newColumnState = columnApi.getColumnState();
    if (this.mounted) {
      if (newColumnState.find(column => column.colId === ADD_NEW_FIELD_ID) &&
        newColumnState[newColumnState.length - 1].colId !== ADD_NEW_FIELD_ID) {
        // columnApi.setColumnState(this.props.columnsState as ColumnState[]);
        // this.columnSummaryGridColumnApi && this.columnSummaryGridColumnApi.setColumnState(this.props.columnsState as ColumnState[]);
        return;
      }
      const columnState = columnApi.getColumnState() as GridColumnState[];
      this.props.onColumnsChange(columnState);
    }
  };

  private handleColumnPinned(event: ColumnPinnedEvent) {
    const { columnApi } = event;
    const columnState = columnApi.getColumnState() as GridColumnState[];
    this.props.onColumnsChange(columnState);
  }

  private handleColumnsVisibilityChanged = (event: ColumnVisibleEvent): void => this.handleColumnsStateChanged(event);

  private handleColumnRowGroupChanged = (event: ColumnRowGroupChangedEvent): void => {
    this.handleColumnsStateChanged(event);

    if (this.agGridApi && this.agGridApi.isGroupMode()) {
      this.setRowIndexMap();
    }
  };

  private setRowIndexMap = (): void => {
    // @ts-ignore
    const groupingRowIndexMap = this.agGridApi.getGroupingRowIndexMap();
    if (!_.isEmpty(groupingRowIndexMap)) {
      this.props.setRowIndexMap(groupingRowIndexMap);
    }
  };

  private onRowSelected = (event: RowSelectedEvent): void => {
    clearTimeout(this.selectionTimeout);
    this.selectionTimeout = window.setTimeout(() => {
      this.props.setSelectedRowCount(event.api.getSelectedNodes().length);
    }, 10);
  };

  private onCellContextMenu = (event: CellContextMenuEvent): void => {
    const cellRanges = event.api.getCellRanges();

    const selectedRange = getSelectedRange(cellRanges, event.rowIndex);

    event.api.clearRangeSelection();

    if (selectedRange) {
      event.api.addCellRange(selectedRange);
    }
  };

  private onColumnsResized = (event: ColumnResizedEvent): void => {
    const { finished, columns, columnApi } = event;

    if (finished && columns) {
      const columnState = columnApi.getColumnState() as GridColumnState[];
      this.props.onColumnsChange(columnState);
    }

    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      if (this.agGridApi) {
        this.agGridApi.filter(this.props.filterModel);
      }
    }, 100);
  };

  private onGridReady = (event: GridReadyEvent): void => {
    if (this.mounted) {
      this.agGridApi = new AgGridApi<Node>({
        gridApi: event.api,
        columnApi: event.columnApi,
      });

      this.agGridApi.resetRowHeights();
      this.agGridApi.filter(this.props.filterModel);
      this.agGridApi.sort(this.props.sortModel);

      this.props.onGridReady(this.agGridApi);
    }
  };

  private columnSummaryGridOnReady = (event: GridReadyEvent): void => {
    if (this.mounted) {
      this.columnSummaryGridApi = new ColumnSummaryGridApi(event.api);
      this.props.columnSummaryGridOnReady(this.columnSummaryGridApi);
    }
  };

  private handleCellEditingStarted = (event: CellEditingStartedEvent): void => {
    const editors = event.api.getCellEditorInstances();


    if (editors.length > 0) {
      editors[0].getFrameworkComponentInstance?.()?.componentRef?.current?.focusIn?.();
      editors[0].getFrameworkComponentInstance?.()?.componentRef?.current?.focus?.();
      editors[0].getFrameworkComponentInstance?.()?.componentRef?.current?.click?.();
    }
    this.props.handleCellEditingStarted && this.props.handleCellEditingStarted(event);
  };

  private handleCellEditingStopped = (event: CellEditingStoppedEvent): void => {
    this.props.handleCellEditingStopped?.(event);
  };

  private onRowDragEnd = (event: RowDragEvent): void => {
    if (this.state.agGridState === AG_GRID_STATES.DRAG_STARTED) {
      return;
    }

    const { node: movingNode, overNode, api } = event;

    if (movingNode == overNode) {
      return;
    }

    let toPosition;
    if (event.overIndex == -1) {
      // -1 signifies dropping at the bottom of the grid
      toPosition = this.props.nodes[this.props.nodes.length - 1].rowIndex! + 1;
    } else {
      // Use Ag-grid's algorithm (see ClientSideRowModel.prototype.highlightRowAtPixel)
      // to determine if the highlight between the rows is above or below:
      //    var rowTop = rowNodeAtPixelNow.rowTop, rowHeight = rowNodeAtPixelNow.rowHeight;
      //    var highlight = pixel - rowTop < rowHeight / 2 ? 'above' : 'below';
      if (event.y - overNode.rowTop! < overNode.rowHeight! / 2) {
        // Highlight is above the cell
        toPosition = this.props.nodes[event.overIndex].rowIndex!;
      } else {
        // Highlight is below the cell
        if (event.overIndex + 1 < this.props.nodes.length) {
          toPosition = this.props.nodes[event.overIndex + 1].rowIndex!;
        } else {
          // Below the last row of the grid
          toPosition = this.props.nodes[this.props.nodes.length - 1].rowIndex! + 1;
        }
      }
    }

    const { setCustomRowPosition } = this.props;
    const selectedNodes = api.getSelectedRows();
    const movingNodeData: CommonNode = movingNode.data;

    let nodeIds: string[];
    if (selectedNodes?.length && selectedNodes.indexOf(movingNodeData) != -1) {
      // AgGrid gives us the selected nodes sorted by id, even if they are not displayed in that order
      // in the grid.  Reorder the selected nodes to the order in which they're displayed.
      const displayOrderedNodes = selectedNodes.sort((node1, node2) => node1.rowIndex - node2.rowIndex);

      nodeIds = displayOrderedNodes.map(node => node.id);
    } else {
      nodeIds = [movingNodeData.id];
    }

    setCustomRowPosition({ nodeIds: nodeIds, position: toPosition });

    event.api.refreshCells({ columns: [ROW_NUMBER_ID], force: true });
  };

  /**
   * Custom value setter for fields
   * Allows to set field values by path.
   * Example:
   * colDef.field = 'fields.title'
   * newValue = 'hello'
   * params.data = {}
   * will result in params.data = { fields: {title: 'hello'}}
   *
   * Data is saved through a redux action dispatch
   *
   * @param {ValueSetterParams} params
   * @returns {boolean}
   */
  private valueSetter = (params: ValueSetterParams): boolean => {
    // Ignore this event if the API is not available
    if (!this.agGridApi) return false;

    // remove all spaces when the fieldType is Email
    const fieldType = (params?.colDef?.refData?.fieldType) || '';
    if (fieldType === FieldType.Email) {
      const trimValue = params.newValue.trim();
      if ( trimValue !== '' && !new RegExp(EMAIL_REGEXP).test(trimValue)) {
        const message = `${trimValue} is not a valid email address`;
        this.props.warningToast({ message });
        return false;
      }
      params.newValue = params.newValue.trim();
    }

    const ignoreEvent = ignoreCellValueChangedEvent(
      params.newValue,
      params.oldValue,
      params.column.getColId(),
    );

    if (ignoreEvent) return false;

    // Validate the field and check if the row is valid.
    const validationMessages = validateNode<Node>({
      node: params.node.data,
      prefix: '',
      schema: this.props.schema as unknown as JSONSchema7,
    });

    const isValid = _.every(_.isEmpty, validationMessages);

    // If the row is valid, save.
    if (isValid) {
      setTimeout(() => {
        // Save current focused cell to refocus after update (needed when the cell is updated through TAB key)
        const focusedCell = this.agGridApi && this.agGridApi.getFocusedCell();

        // Dispatch redux action
        const key = params.column.getColId().split('fields.')[1];
        const patchData = { fields: { [key]: params.newValue } };
        patchData['version'] = patchData['version'] ?? params.node.data.version;
        this.props.onUpdateNode({
          url: params.node.data.apiURI,
          data: patchData,
          isFullObject: false,
        });

        // Re-focus cell
        if (this.agGridApi && focusedCell) {
          this.agGridApi.focusCell(focusedCell.rowIndex, focusedCell.colId);
        }
      }, 0);
    } else {
      this.agGridApi.redrawRows([params.node]);
    }

    return false;
  };
}


const mapDispatchToProps: DispatchProps = {
  endLockingLoader: loadingAction.endLockingLoader,
  checkProcessesInRecords,
  stopPolling,
  warningToast: notifications.warn,
};

export default connect(null, mapDispatchToProps)(AgGrid);

function getChildCount(dataItem?: GroupRow): number {
  return dataItem ? dataItem.count : 0;
}

function getRowNodeId<Node extends CommonNode>(data: Node): string {
  return data.agGridId || data.id;
}

function ignoreCellValueChangedEvent(newValue: any, oldValue: any, colId: string): boolean {
  const valueHasNotChanged = newValue === oldValue;

  const ignoreFields = ['isValid', 'validationMessages', 'documentreference'];
  const checkingIgnoredField = ignoreFields.includes(colId);

  return valueHasNotChanged || checkingIgnoredField;
}

function getSelectedRange(cellRanges: CellRange[] | null = [], rowIndex: number): CellRangeParams | null {
  // For some reason, cellRanges may be null
  if (!cellRanges) {
    return null;
  }

  const range = cellRanges.find((range) =>
    range.startRow && range.endRow && range.startRow.rowIndex <= rowIndex && range.endRow.rowIndex >= rowIndex);

  if (!range || !range.startRow || !range.endRow) {
    return null;
  }

  return {
    columnStart: range.startColumn,
    columns: range.columns,
    rowStartIndex: range.startRow.rowIndex,
    rowEndIndex: range.endRow.rowIndex,
  };
}
