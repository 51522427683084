import * as React from 'react';
import { useState, useEffect } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import TextField from 'components/Fields/TextField';
import { MergeObjects } from 'utilities/types';
import { validateURL } from 'utilities/validation';

import { OptionalComponentProps } from '../data';

type Props = MergeObjects<OptionalComponentProps, WithStyles<ClassKey>>;

function OpenURL(props: Props): JSX.Element {
  const { buttonFieldCallback, classes } = props;
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | null>(null);
  const onTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setUrl(url);
    validateURL(url) ? setError(null) : setError('Invalid URL');
  };

  useEffect(() => {
    const valid = !!url && !error;
    const additionalData = { url };
    buttonFieldCallback(valid, JSON.stringify(additionalData));
  }, [url]);

  return (
    <TextField
      error={error !== null}
      helperText={error}
      className={classes.textField}
      id="openUrl"
      label="URL"
      placeholder="Enter a valid URL"
      value={url}
      onChange={onTextChanged}
      fullWidth
    />
  );
}

type ClassKey
  = 'textField'
  ;

const styles = withStyles<ClassKey>({
  textField: { marginTop: 3 },
});

export default styles(OpenURL);
