import * as React from 'react';

import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react/lib/interfaces';
import { FieldValue } from 'types/response';

interface Params {
  stopEditingAfterChange: boolean;
  isPopup: boolean;
}

const defaultParams: Params = {
  stopEditingAfterChange: false,
  isPopup: false,
};

type ParamsCallback = (props: any) => Partial<Params>;

interface State {
  value: FieldValue;
}

const wrapCellEditor = (params?: Partial<Params> | ParamsCallback) => Editor => {
  return class CellEditor extends React.PureComponent<ICellEditorParams, State> implements ICellEditorReactComp {
    static OriginalComponent = Editor;

    private componentRef: React.RefObject<HTMLElement> = React.createRef();

    constructor(props: ICellEditorParams) {
      super(props);
      this.state = {
        value: props.value,
      };
    }

    get params() {
      const userDefinedParams = typeof params === 'function'
        ? params(this.props)
        : params;

      return { ...defaultParams, ...userDefinedParams };
    }

    public isPopup = (): boolean => this.params.isPopup;

    public getValue = (): FieldValue => this.state.value;

    public afterGuiAttached = (): void => {
      this.componentRef.current?.focus?.();
      this.componentRef.current?.click?.();

      if (this.props.eGridCell) {
        const container = this.props.eGridCell.children?.[0] as HTMLDivElement;
        if (container) {
          container.id = `${this.props.colDef.colId?.replace('.', '-')}-${this.props.node.id}`;
        }
      }
    };

    public render = (): JSX.Element => {
      const { value } = this.state;
      return (
        <Editor
          {...this.props}
          ref={this.componentRef}
          onStopEditing={this.stopEditing}
          value={value}
          onChange={this.handleValueChange}
          isGrid
        />
      );
    };

    private stopEditing = () => {
      const { api } = this.props;
      if (api) {
        api.stopEditing(false);
      }
    };

    private handleValueChange = (value: FieldValue): void => {
      this.setState({ value }, () => {
        if (this.params.stopEditingAfterChange) {
          this.stopEditing();
        }
      });
    };
  };
};

export default wrapCellEditor;
