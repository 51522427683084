import { createAsyncThunk } from '@reduxjs/toolkit';

import * as constants from './constants';
import * as services from './services';
import { PreviewBehavior } from '../../types/response';
import { TenantNode } from 'types/response/tenantNode';

// Navigation tree action creators
export function fetchNavigationTree() {
  return {
    type: constants.FETCH_NAVIGATION_TREE_REQUEST,
  };
}
export function fetchNavigationTreeError(payload) {
  return {
    type: constants.FETCH_NAVIGATION_TREE_ERROR,
    payload,
  };
}
export function fetchNavigationTreeSuccess(navigationTree) {
  return {
    type: constants.FETCH_NAVIGATION_TREE_SUCCESS,
    navigationTree,
  };
}

// Constants

export const PREVIEW_BEHAVIOR_UPDATED = 'PREVIEW_BEHAVIOR_UPDATED';

// Action Interfaces

interface PreviewBehaviorUpdated {
  type: 'PREVIEW_BEHAVIOR_UPDATED';
  previewBehavior: PreviewBehavior;
}

export type Action
  = PreviewBehaviorUpdated
  ;

// Action Constructors

export function previewBehaviorUpdated(previewBehavior: PreviewBehavior): PreviewBehaviorUpdated {
  return { type: PREVIEW_BEHAVIOR_UPDATED, previewBehavior };
}

/**
 *
 */
export const loadTenantConfig = createAsyncThunk(
  'APP/LOAD_TENANT_CONFIG',
  async (_, { dispatch }) => {
    try {
      const tenant = await services.fetchTenantConfig();
      services.configAWSAmplify(tenant);
      dispatch(constants.fetchTenantDataSuccess(tenant));

      return tenant as TenantNode;
    } catch (error) {
      console.error(error);
    }
  },
);
