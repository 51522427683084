import { ColDef } from 'ag-grid-community';
import { getAgGridColumnDefs } from 'components/DataGrid/columns';
import { accountsById } from 'data/accounts/selectors';
import { location, previewBehavior } from 'data/app/selectors';
import { AttachmentsState } from 'data/attachments/reducer';
import { attachments } from 'data/attachments/attachments.selectors';
import { CollectionFields, Collections } from 'data/collections/collections.reducer';
import {
  collectionFields,
  collections,
  getHideColumnIconsPropForDatabase,
  schemaColumnsMap,
} from 'data/collections/collections.selectors';
import { selectedView } from 'data/views/views.selectors';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { Location } from 'history';
import { State } from 'reducers';
import { createSelector } from 'reselect';
import { ColumnsMapById } from 'types/gridOptions';
import { PreviewBehavior } from 'types/response';
import { AccountNode } from 'types/response/accountNode';
import { TenantNode } from 'types/response/tenantNode';
import { ViewNode } from 'types/response/viewNode';
import { CollectionTypes } from 'types/schema';
import { getCollectionTypeFromUrl } from 'utilities/createUrl';
import { parseURL } from 'utilities/queryParams';

const permissions = (state: State): string[] => getWorkspacePermissions(state);

const tenant = (state: State): TenantNode | undefined => state.app.tenant;

export const columnDefsSelector: (state: State) => ColDef[] = createSelector(
  schemaColumnsMap,
  location,
  selectedView,
  collections,
  collectionFields,
  getHideColumnIconsPropForDatabase,
  previewBehavior,
  accountsById,
  attachments,
  permissions,
  tenant,
  (
    columns: ColumnsMapById,
    location: Location | null,
    view: ViewNode,
    collections: Collections,
    collectionFields: CollectionFields,
    hideColumnIcons: boolean,
    previewBehavior: PreviewBehavior,
    accountsById: Record<string, AccountNode> | null,
    attachments: AttachmentsState,
    permissions: string[],
    tenant?: TenantNode,
  ) => {
    const pathname = location ? location.pathname : '';
    const {
      [CollectionTypes.repositories]: repositoryId,
      [CollectionTypes.databases]: databaseId,
      [CollectionTypes.taskdbs]: taskDatabaseId,
    } = parseURL(pathname);
    const collectionType = getCollectionTypeFromUrl(pathname);
    const rowSelectionEnabled = (repositoryId || databaseId || taskDatabaseId) && collectionType !== CollectionTypes.history;
    const expandRecordEnabled = (databaseId || taskDatabaseId) && collectionType !== CollectionTypes.history;
    const {
      query,
      groupByFieldIDs = [],
      columnWidths = {},
      leftPinnedFieldIDs = [],
      rightPinnedFieldIDs = [],
    } = view;

    return getAgGridColumnDefs(columns, {
      expandRecordEnabled,
      withCheckbox: rowSelectionEnabled,
      fieldIDs: (query && query.fieldIDs) || (document.URL.includes('accounts') && ['fields.displayName', 'fields.primaryEmail', 'fields.role']) || [],
      groupByFieldIDs,
      permissions,
      previewBehavior,
      hideColumnIcons,
      columnWidths,
      leftPinnedFieldIDs,
      rightPinnedFieldIDs,
      accountsById: accountsById || undefined,
      attachments,
      tenant,
    });
  },
);
