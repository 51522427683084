import { combineReducers } from 'redux';

import fileUpload, { initialState as fileUploadIS } from 'data/ui/fileUpload/fileUpload.reducer';
import gridSelection, { initialState as gridSelectionIS } from 'data/ui/gridSelection/gridSelection.reducer';
import modal, { UiModalState, initialState as modalIS } from 'data/ui/modal/modal.reducer';
import loading, { UiLoadingState, initialState as loadingIS } from 'data/ui/loading/loading.reducer';
import { FileUploadState } from 'data/ui/fileUpload/fileUpload.types';
import { GridSelectionState } from 'data/ui/gridSelection/gridSelection.types';

export interface UiState {
  fileUpload: FileUploadState;
  gridSelection: GridSelectionState;
  modal: UiModalState;
  loading: UiLoadingState;
}

export const initialState: UiState = {
  fileUpload: fileUploadIS,
  gridSelection: gridSelectionIS,
  modal: modalIS,
  loading: loadingIS,
};

const uiReducer = combineReducers({
  fileUpload,
  gridSelection,
  modal,
  loading,
});

export default uiReducer;
