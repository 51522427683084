import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import MuiInput, { InputProps } from '@material-ui/core/Input';
import Menu from '@material-ui/core/Menu';
import MuiSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';
import styled from 'styled-components';

import {
  athensGrey,
  brownishGrey,
  lightBlueGrey,
  slate,
  slateTwo,
} from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import IconButton, { Color, Size } from 'components/IconButton';

interface OwnProps {
  id?: string;
  menuItems: React.ReactNode;
  label?: string;
  menuItemsSearch?: React.ReactNode;
  calculateHeight?: boolean;
}

interface Props extends OwnProps {
  className: string;
}

interface State {
  anchorElement?: HTMLElement;
  style: {
    maxHeight: string;
  };
}

class DropdownMenu extends React.Component<Props, State> {
  public state: State = {
    style: {
      maxHeight: '',
    },
  };

  public componentDidUpdate = (): void => {
    if (this.props.calculateHeight && this.state.anchorElement) {
      const clientRect = this.state.anchorElement.getBoundingClientRect();
      const maxHeight = `${window.innerHeight - clientRect.top}px`;
      if (this.state.style.maxHeight !== maxHeight) {
        this.setState({
          style: {
            maxHeight,
          },
        });
      }
    }
  };

  public render(): JSX.Element {
    const { id, label, className, menuItems, menuItemsSearch } = this.props;

    return (
      <React.Fragment>
        <DropdownButton id={`${id}_button`} onClick={this.onOpen} className={className}>
          {label}
        </DropdownButton>
        <Menu
          id={`${id}_menu`}
          open={!!this.state.anchorElement}
          onClose={this.onClose}
          anchorEl={this.state.anchorElement}
          onClick={this.onClose}
          PaperProps={{
            style: this.state.style,
          }}
        >
          {menuItemsSearch}
          {menuItems}
        </Menu>
      </React.Fragment>
    );
  }

  private onOpen = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorElement: event.currentTarget });
  };

  private onClose = (): void => {
    this.setState({ anchorElement: undefined });
  };
}

const DropdownButton = styled<ButtonProps>(Button)`
  &, &:hover {
    text-decoration: none;
  }
`;

// TODO: replace buttons with dropdown icons with dropdown + isOpen props
function Button({ children, ...props }: ButtonProps): JSX.Element {
  return (
    <MuiButton {...props}>
      {children}
      <DropdownIcon color="inherit" fontSize="inherit" />
    </MuiButton>
  );
}

const DropdownIcon = styled<SvgIconProps>(ArrowDropDown)`
  font-size: 1.5em;
  margin-left: auto;
`;

export const Dropdown = styled<OwnProps>(DropdownMenu)`
  margin: 0 2px;
  justify-content: space-between;
  background-color: ${athensGrey};
  font-size: 11px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: normal;
  min-height: 25px;
`;

export const ButtonBar = styled.div`
  margin: 8px -8px -8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// Custom switch styling

export const SwitchLabel = styled.div`
  flex: 1 0 0;
  display: flex;
`;

export const DropdownWrapper = styled.div`
  color: ${brownishGrey};
  padding: 10px;
  font-size: 11px;
`;

export const DropdownHeader = styled.div`
  padding: 10px 10px 17px;
  margin: -10px -10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SearchDropdownHeader = styled(DropdownHeader)`
  padding-bottom: 10px;
`;

export const DropdownFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 13px;
  }
`;

export const DropdownContent = styled.div`
  padding-bottom: 15px;
`;

const DropdownListStyled = styled.div`
  margin: 0 -10px;
  padding: 0 10px;
  max-height: 50vh;
  overflow: auto;
  @media screen and (max-width: 720px) {
    max-height: 72vh;
  }
`;

export const DropdownList = ({ children }) => <DropdownListStyled className={'styled-DropdownList'}>{children}</DropdownListStyled>;

export const DropdownListItem = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2px;
  z-index: 9999;
`;

export const DropdownColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 80px;
  text-overflow: ellipsis;
`;

export const DropdownListItemRemove = styled(svgIcons.Remove)`
  height: 1em;
  color: ${lightBlueGrey};
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: ${slate};
  }
`;

export const DropdownListItemLabel = styled.div`
  width: 65px;
  margin: 0 10px;
`;

export const DropdownListItemText = styled.div`
  margin: 0 10px;
`;

export const NoItemsText = styled.div`
  padding: 8px 0
`;

export const RightSideWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: stretch;
`;


type CloseButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>;

export const CloseButton: React.FC<CloseButtonProps> = (props: CloseButtonProps): JSX.Element => (
  <IconButton icon={svgIcons.Close} color={Color.DarkGray} size={Size.ExtraSmall} {...props} />
);

type SelectClassKey = 'root' | 'select';

const selectStyles: Record<SelectClassKey, CSSProperties> = {
  root: {
    borderRadius: 2,
    backgroundColor: athensGrey,
    color: slateTwo,
    fontSize: 11,
    padding: 0,
  },
  select: {
    minHeight: 25,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
};

// @ts-ignore
export const Select = withStyles(selectStyles)(MuiSelect);

const SearchIcon = styled(svgIcons.Search)`
  height: 1em;
  margin-right: 7px;
  margin-bottom: 5px;
`;

const inputStyles = {
  root: {
    '&:before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
    },
    'flex': 1,
    'alignItems': 'center',
    'color': slateTwo,
    'fontSize': 11,
  },
  input: {
    '&::placeholder': {
      color: 'inherit',
    },
    'padding': '0 0 5px',
  },
};

function Input(props: InputProps) {
  return <MuiInput {...props} startAdornment={<SearchIcon />} />;
}

export const SearchInput = withStyles(inputStyles)(Input);
