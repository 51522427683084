import * as React from 'react';
import RootRef from '@material-ui/core/RootRef';
import { filteringOptionStyles } from 'components/TableHeaderTools/modules/FilteringOption/FilteringOption.style';
import Popover, { PopoverRenderParams } from 'components/ui/Popover';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { FiltersDropdownStyled } from 'components/TableHeaderTools/modules/FilteringOption/FiltersDropdown';
import svgIcons from 'styles/svgIcons';
import Button, { Variant } from 'components/Button';
import { Column, ColumnVM } from 'types/schema';
import { RegularFilter } from '../../../types/gridOptions';
import { AddFilterPayload, UpdateFilterPayload } from '../../../data/grid-options/filterModel.actions';

interface StateProps {
  nonEmptyFiltersCount: number;
}

interface OwnProps {
  showFilterBarSwitchEnabled?: boolean;
  columnSchema: { [id: string]: Column };
  columns: ColumnVM[];
  filters: RegularFilter[];
  addFilter: (filter: AddFilterPayload) => void;
  deleteFilter: (index: number) => void;
  updateFilter: (payload: UpdateFilterPayload) => void;
  changeQuickFiltersVisibility: () => void;
  quickFiltersBarEnabled: boolean;
}

type Props = WithStyles<typeof filteringOptionStyles> & StateProps & OwnProps;

export class FilteringOption extends React.PureComponent<Props> {
  private popoverRef: React.RefObject<Popover> = React.createRef();
  private triggerRef: React.RefObject<HTMLElement> = React.createRef();

  public open = () => {
    if (this.popoverRef.current && this.triggerRef.current) {
      this.popoverRef.current.open(this.triggerRef.current);
    }
  };

  public render(): JSX.Element {
    const { classes, nonEmptyFiltersCount } = this.props;
    const buttonLabel = `Filter${nonEmptyFiltersCount
      ? `ed by ${nonEmptyFiltersCount} field${nonEmptyFiltersCount > 1 ? 's' : ''}`
      : ''}`;

    return (
      <Popover
        id="filteringOption"
        ref={this.popoverRef}
        open={true}
        anchorEl={this.triggerRef.current}
        renderTrigger={({ openPopover, opened }) => (
          <RootRef rootRef={this.triggerRef}>
            <Button
              id="btnFilteringOption"
              label={buttonLabel}
              variant={Variant.TertiaryLink}
              selected={opened || !!nonEmptyFiltersCount}
              onClick={openPopover}
              icon={svgIcons.Filter}
            />
          </RootRef>
        )}
        PaperProps={{
          classes: {
            root: classes.popoverPaper,
          },
        }}
      >
        {this.renderDropdown}
      </Popover>
    );
  }

  private renderDropdown = ({ closePopover }: PopoverRenderParams) => {
    return (
      <FiltersDropdownStyled
        closePopover={closePopover}
        showFilterBarSwitchEnabled={this.props.showFilterBarSwitchEnabled}
        addFilter={this.props.addFilter}
        updateFilter={this.props.updateFilter}
        deleteFilter={this.props.deleteFilter}
        changeQuickFiltersVisibility={this.props.changeQuickFiltersVisibility}
        quickFiltersBarEnabled={this.props.quickFiltersBarEnabled}
        filters={this.props.filters}
        schemaColumns={this.props.columnSchema}
        columnsVM={this.props.columns}
        onlyComponent
      />
    );
  };
}

export const FilteringOptionStyled = withStyles(filteringOptionStyles)(FilteringOption);
