import * as React from 'react';
import { EditorState } from 'draft-js';
import EditorButton from './EditorButton';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';

export const inlineStylesButtons = [
  { id: 'btnBold', icon: <FormatBoldIcon />, style: 'BOLD' },
  { id: 'btnItalic', icon: <FormatItalicIcon />, style: 'ITALIC' },
  { id: 'btnUnderline', icon: <FormatUnderlinedIcon/>, style: 'UNDERLINE' },
  { id: 'btnStrikeThrough', icon: <StrikethroughSIcon />, style: 'STRIKETHROUGH' },
];

interface OwnProps {
  id?: string;
  editorState: EditorState;
  onToggle(style: string): void;
}

class InlineStyles extends React.Component<OwnProps> {
  public render(): JSX.Element {
    const { id, editorState, onToggle } = this.props;
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
      <div className="RichEditor-controls">
        {inlineStylesButtons.map(type =>
          <EditorButton
            id={`${id}_${type.id}`}
            key={type.style}
            icon={type.icon}
            style={type.style}
            active={currentStyle.has(type.style)}
            onToggle={onToggle}
          />,
        )}
      </div>
    );
  }
}

export default InlineStyles;
