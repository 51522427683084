import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { debounce } from 'lodash';
function useDebounceState<T>(
  initialState: T,
  wait = 1000,
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initialState);

  const debouncedCallback = useCallback(
    debounce((newState: T) => {
      setState(newState);
    }, wait),
    [],
  );

  const setDebouncedState = (value: T) => {
    debouncedCallback(value);
  };

  return [state, setDebouncedState];
}

export default useDebounceState;
