import * as types from './types';

export function filterMessages(messageId, messageContent, messages) {
  const messageIndex = messages.findIndex(msg => msg.id === messageId);
  const newMessage = {
    ...messages[messageIndex],
    body: { ...messageContent },
    status: types.MessageStatuses.UPDATED,
  };
  return [
    ...messages.slice(0, messageIndex),
    newMessage,
    ...messages.slice(messageIndex + 1),
  ];
}

export function createMessagePayload(messageBody, user, state) {
  const { workspaces, messages, sheets, databases, taskDatabases } = state;
  const { current } = workspaces;
  const { currentChannelId } = messages;

  const location = window.location;
  const inMessages = location.href.endsWith('/messages');
  const currentWorkspace = workspaces.byId[current || ''];

  const payload = {
    channelId: currentChannelId,
    databaseId: (!inMessages && databases.current) || '',
    taskDatabaseId: (!inMessages && taskDatabases.current) || '',
    sheetId: (!inMessages && sheets?.ids?.[0]) || '',
    workspaceID: current,
    workspaceTitle: (currentWorkspace?.['title']) || '',
    workspaceURL: (currentWorkspace?.['uri']) || '',
    messageBody: messageBody,
    url: document.URL,
  };

  return payload;
}
