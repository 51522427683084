import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './DatePickerNotify.scss';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import ModalTextField from 'components/Modals/ModalTextField';
import svgIcons from 'styles/svgIcons';
import { AccountNode } from '../../../../types/response/accountNode';
import { WorkspaceNode } from '../../../../types/response/workspaceNode';

import Autocomplete from 'components/Autocomplete/Autocomplete';
import AccountChip from '../../../AccountChip';
import IconButton, { Color as IconButtonColor, Size } from '../../../IconButton';

interface StateProps {
  userEmail: string;
  workspaceUsers: AccountNode[];
  workspaceTitle: string;
}

interface OwnProps {
  input?: JSX.Element;
  notifier?: Notification[];
  onChange?: (value: Notification[], hideDayPicker?: boolean) => void;
  isScheduledOnChange?: () => void;
  clearDate?: () => void;
}

export interface State {
  notifications: Notification[];
  remindType: RemindTypes;
  remindUsers: string[];
}

export interface Notification {
  _id?: string;
  isScheduled?: boolean;
  email?: string;
  qty?: number;
  unit?: string;
  eventType?: string;
  remindType?: string;
  remindUsers?: string[];
  createdBy?: string;
  message?: string;
  version?: number;
  timestamp?: number;
  references?: { [key: string]: string };
}

/**
 * Scheduler units
 */
export enum Units {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

/**
 * Scheduler event types
 */
export enum EventTypes {
  Later = 'later',
  Advance = 'in advance',
}

export enum RemindTypes {
  None = 'none',
  Me = 'me',
  Team = 'team',
  OtherUsers = 'other_users',
  SharedUsers = 'shared_users',
}

const remindTypes = ['None', 'Me', 'Team', 'Other Users'];

type Props = StateProps & OwnProps;

export class DatePickerNotify extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      notifications: this.props.notifier || [],
      remindType: RemindTypes.None,
      remindUsers: [],
    };
  }

  private renderUnits = (isPlural: boolean): JSX.Element[] => {
    return Object.keys(Units).map(key => {
      return <MenuItem className="date-picker-notify-select-item" key={key} value={key.toLowerCase()}>{Units[key]}{isPlural ? 's' : ''} in advance</MenuItem>;
    });
  };

  private renderEventTypes = (): JSX.Element[] => {
    return Object.keys(EventTypes).map(type => {
      return <MenuItem key={type} value={type.toLowerCase()}>{EventTypes[type]}</MenuItem>;
    });
  };

  private getUserNotification = (): Notification => {
    const notification = Object.values(this.state.notifications).filter(item => item.email === this.props.userEmail);
    if (notification.length > 0) {
      return notification[0];
    }

    return {
      email: this.props.userEmail,
      isScheduled: false,
    };
  };

  private updateState = (notifications: Notification[], cb?: () => void): void => {
    this.setState({
      notifications: notifications,
    }, cb ? cb : undefined);
  };

  private getStateValue = (key: string): string | boolean | number | undefined => {
    if (this.state.notifications) {
      return this.getUserNotification()[key];
    }

    return undefined;
  };

  private updateField = (key: string, value: string | boolean | number, cb?: () => void): void => {
    const notification = this.state.notifications.slice().filter(item => item.email === this.props.userEmail);
    if (!notification || notification.length < 1) {
      this.setState({
        notifications: [...this.state.notifications, {
          email: this.props.userEmail,
          eventType: 'advance',
          isScheduled: true,
          unit: 'day',
          qty: 1,
          remindType: this.state.remindType.toLowerCase(),
          remindUsers: this.state.remindUsers,
          [key]: value,
        }],
      });
    } else {
      this.setState({
        notifications: this.state.notifications.slice().map(item => {
          if (item.email === this.props.userEmail) {
            return {
              ...item,
              eventType: 'advance',
              remindType: this.state.remindType.toLowerCase(),
              remindUsers: this.state.remindUsers,
              [key]: value,
            };
          }
          return item;
        }),
      }, cb ? cb : undefined);
    }
  };

  private clearNotify = (): void => {
    this.updateState(this.state.notifications.slice().filter(item => item.email !== this.props.userEmail),
      () => {
        this.onSave(false);
      });
    this.props.clearDate && this.props.clearDate();
    this.props.isScheduledOnChange && this.props.isScheduledOnChange();
  };

  private toggleNotifier = (): void => {
    this.updateField('isScheduled', this.state.remindType !== RemindTypes.None, () => this.onSave(false));
    this.props.isScheduledOnChange && this.props.isScheduledOnChange();
    if (!this.state.notifications.length) {
      this.updateField('qty', '1');
      this.updateField('unit', 'day');
      setTimeout(() => this.onSave(false), 0);
    }
  };

  private onQtyChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    event.stopPropagation();
    this.updateField('qty', parseInt(event.target.value as string || '1'), () => this.onSave(false));
  };

  private onUnitChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    this.updateField('unit', event.target.value as string, () => this.onSave(false));
  };

  private onEventTypeChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    this.updateField('eventType', event.target.value as string);
  };

  private onRemindChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ remindType: event.target.value as RemindTypes }, () => {
      this.updateField('remindType', this.state.remindType);
      this.toggleNotifier();
    });
  };

  private hasReminder = () => {
    const remindType = this.getStateValue('remindType');
    return !(!remindType || remindType === RemindTypes.None);
  };

  private onSave = (hideDayPicker?: boolean): void => {
    if (this.props.onChange) {
      this.props.onChange(this.state.notifications, hideDayPicker);
    }
  };

  private onUserSelect = (value): void => {
    this.setState({ remindUsers: value, remindType: RemindTypes.OtherUsers }, () => {
      this.updateField('remindUsers', value, () => this.onSave(false));
    });
  };

  public render() {
    return (
      <div className="calendar-notifier">
        <div className="calendar-notifier-switch">
          <div className="calendar-notifier-label">
            <svgIcons.Clock height="1em" />
            <span>Remind</span>
          </div>
          <Select
            value={this.getStateValue('remindType') || RemindTypes.None}
            onChange={this.onRemindChange}
            disableUnderline
            className="date-picker-notify-select"
          >
            {remindTypes.map(remindType => <MenuItem className="date-picker-notify-select-item" key={remindType} value={RemindTypes[remindType.replace(/\s/, '')]}>{remindType}</MenuItem>)}
          </Select>
        </div>
        {this.getStateValue('remindType') === RemindTypes.Team && (
          <div className="calendar-notifier-remind-type">{this.props.workspaceTitle} users will be reminded</div>
        )}
        {this.hasReminder() && (
          <div>
            <div className="calendar-notifier-scheduler">
              <div>
                <ModalTextField
                  value={this.getStateValue('qty') || 1}
                  onChange={this.onQtyChange}
                  required={true}
                  type="number"
                  className="calendar-notifier-text-field"
                />
              </div>
              <div>
                <Select
                  value={this.getStateValue('unit') || 'day'}
                  onChange={this.onUnitChange}
                  disableUnderline
                  className="date-picker-notify-select"
                >
                  {this.renderUnits((this.getStateValue('qty') || 1) !== 1)}
                </Select>
              </div>
            </div>
          </div>
        )}
        {this.getStateValue('remindType') === RemindTypes.OtherUsers && (
          <div className="date-picker-autocomplete-container">
            <Autocomplete
              type="account"
              placeholder="Find users to remind..."
              suggestions={this.props.workspaceUsers.map(user => {
                return {
                  id: user.id,
                  label: user.displayName,
                };
              })}
              onChange={this.onUserSelect}
              selectedItems={this.getStateValue('remindUsers') || []}
              renderSelectedItem={props => {
                return (
                  <div className={props.classes.selectedItem} key={props.item}>
                    <AccountChip accountId={props.item} showName />
                    <IconButton
                      icon={svgIcons.Remove}
                      color={IconButtonColor.LightGray}
                      size={Size.Small}
                      onClick={props.deleteItem}
                    />
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const currentWorkspace = state.workspaces.current && state.workspaces.byId[state.workspaces.current] as WorkspaceNode;
  return {
    userEmail: (state.app.user && state.app.user.primaryEmail) || '',
    workspaceUsers: state.account.workspaceUsers,
    workspaceTitle: (currentWorkspace && currentWorkspace.title) || '',
  };
};

export default connect(mapStateToProps)(DatePickerNotify);
