import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  blueGrey,
  greyTransparent,
  hubsyncBlue,
  lightBlueGrey,
  slateTwo,
  vividAzure,
} from 'styles/colors';

type ClassKey =
  | 'chip'
  | 'inviteUserSuggestionAdd'
  | 'activeItem'
  | 'invalidSuggestion'
  | 'inviteUserTooltip'
  | 'inviteSelect'
  | 'invalidChip'
  | 'inputFocused';

export const styles: Record<ClassKey, CSSProperties> = {
  inviteSelect: {
    'minHeight': '40px',
    'height': 'auto',
    'borderRadius': '2px',
    'backgroundColor': greyTransparent,
    'paddingTop': 7,
    'paddingLeft': 0,
    'fontSize': '14px',
    'color': slateTwo,
    'resize': 'none',
    'border': `1px solid ${lightBlueGrey}`,
    'display': 'flex',
    'flexWrap': 'wrap',
    'width': '320px',
    'marginRight': '16px',
    '& .account-chip-icon_wrapper': {
      width: '30px',
      height: '30px',
    },
    '& .account-chip-account_name': {
      'display': 'flex',
      'flex-direction': 'column',
      'align-self': 'flex-start',
    },
    '& .account-chip-account_email': {
      'font-size': '11px',
      'color': blueGrey,
    },
  },

  chip: {
    'height': '25px',
    'backgroundColor': '#ebeced',
    'borderRadius': 15,
    'padding': '6px 10px',
    'color': '#505F79',
    'fontFamily': 'Roboto',
    'fontSize': '11px',
    'letterSpacing': '0.22px',
    'lineHeight': '13px',
    'marginBottom': 6,
    'marginLeft': 6,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'paddingRight': '6px',
    '& button': {
      marginLeft: 4,
    },
  },
  invalidChip: {
    'position': 'relative',
    'border': '1px solid #E8384F',
    'backgroundColor': 'rgba(232,56,79,0.1)',
    '& > button': {
      background: 'none',
    },
    '&:hover .invalidEmailAddress': {
      display: 'block',
    },
  },
  inviteUserSuggestionAdd: {
    'height': '50px',
    'cursor': 'pointer',
    'padding': '5px 10px',
    'alignItems': 'center',
    'display': 'flex',
    'color': hubsyncBlue,
    'fontSize': '13px',
    '& > button': {
      marginRight: '6px',
    },
  },
  inviteUserTooltip: {
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'center',
    width: '195px',
    height: '46px',
    alignItems: 'center',
    color: slateTwo,
  },
  activeItem: {
    border: `1px solid ${hubsyncBlue}`,
  },
  invalidSuggestion: {
    cursor: 'not-allowed',
  },
  inputFocused: {
    border: `1px solid ${vividAzure}`,
  },
};
