import Popover from '@material-ui/core/Popover';
import * as React from 'react';

import { FieldEditorProps } from '../Fields.types';

interface RenderChildrenParams {
  open: () => void;
  close: () => void;
}

type RenderChildren = (params: RenderChildrenParams) => React.ReactNode;

interface EditorPopupProps {
  editor: React.ComponentType<FieldEditorProps>;
  children: RenderChildren;
}

interface EditorPopupState {
  anchorEl: HTMLElement | null;
}

class EditorPopup extends React.Component<EditorPopupProps, EditorPopupState> {
  state = {
    anchorEl: null,
  };
  wrapperRef = React.createRef<HTMLDivElement>();
  open = () => {
    this.setState({ anchorEl: this.wrapperRef.current });
  };
  close = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { editor: Editor, children: renderChildren, ...props } = this.props;
    const { anchorEl } = this.state;
    return (
      <div ref={this.wrapperRef}>
        {renderChildren({ open: this.open, close: this.close })}
        <Popover
          id="editorPopup"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.close}
        >
          <Editor {...props as FieldEditorProps} onStopEditing={this.close}/>
        </Popover>
      </div>
    );
  }
}

export default EditorPopup;
