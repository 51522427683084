import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

export default withStyles(() => ({
  popper: {
    opacity: 1,
    marginTop: '-2px',
  },
  tooltip: {
    boxShadow: '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    backgroundColor: '#ffffff',
    maxWidth: 'none',
    zIndex: 9999,
  },
}))(Tooltip);


export const GreyTooltip = withStyles(() => ({
  popper: {
    opacity: 1,
    marginTop: '-2px',
  },
  tooltip: {
    boxShadow: '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    backgroundColor: '#90a3ae',
    padding: '10px',
    maxWidth: 'none',
    zIndex: 9999,
  },
}))(Tooltip);

export const TitleTooltip = withStyles(() => ({
  popper: {
    opacity: 1,
    marginTop: '-2px',
  },
  tooltip: {
    boxShadow: '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    backgroundColor: '#616161',
    color: '#FFF',
    fontSize: '1rem',
    padding: '10px',
    maxWidth: '760px',
    zIndex: 9999,
  },
}))(Tooltip);
