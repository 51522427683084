export interface State {
  nodes: Nodes;
}

interface Nodes {
  node?: Node;
}

interface Node {
  id: string;
  body: string;
}

function root(state: State): Nodes {
  return state.nodes;
}

function node(state: State): Node | undefined {
  return root(state).node;
}

export function nodeBody(state: State): string | undefined {
  const maybeNode = node(state);

  return maybeNode && maybeNode.body;
}
