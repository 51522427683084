import { get, post, deleteReq } from 'utilities/httpRequests';

const MY_BOOKMARKS_URL = `/bookmarks`;

export const fetchBookmarks = async () =>
  get(`${MY_BOOKMARKS_URL}?api_version=2`);


export const postBookmark = async (bookmarkMeta) =>
  post({ url: `${MY_BOOKMARKS_URL}?api_version=2`, data: bookmarkMeta });

export const deleteBookmark = async (bookmarkID) =>
  deleteReq(`${MY_BOOKMARKS_URL}/${bookmarkID}?api_version=2`);
