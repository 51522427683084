import { Dictionary } from 'lodash';
import * as _ from 'lodash/fp';
import { FieldValue, PreviewBehavior } from 'types/response';
import { FieldType } from 'types/response/fieldNode';
import { Choice, Column } from 'types/schema';

import { DateFormat, TimeFormat } from '../Fields/data';

interface CommonMetadata {
  default?: FieldValue;
  fieldType: FieldType;
  permissions?: string[];
}

interface AccountMetadata extends CommonMetadata {
  allowMultiple?: boolean;
}

interface ChoiceMetadata extends CommonMetadata {
  allowMultiple: boolean;
  choices: Dictionary<Choice>;
  choiceOrder: string[];
  allowGridAddingOptions?: boolean;
}

interface IntegerMetadata extends CommonMetadata, NumberFieldMetadata {
  precision: number;
}

type FloatMetadata = CommonMetadata & NumberFieldMetadata;

interface FormattedNumberMetadata extends CommonMetadata, NumberFieldMetadata {
  format(value: string): string;
}

interface ChecklistMetadata extends CommonMetadata {
  checklistNames?: Dictionary<string>;
  checklistValues?: Dictionary<boolean>;
  checklistOrder?: string[];
}

interface DateMetadata extends CommonMetadata {
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
  includeTime?: boolean;
}

export interface AttachmentMetadata extends CommonMetadata {
  fieldType: FieldType.Attachment;
  previewBehavior: PreviewBehavior;
  showPreview?: boolean;
}

export interface IndexMetadata {
  expandRecordEnabled: boolean;
}

export interface ButtonMetadata {
  action: string;
  label: string;
  additionalData?: string;
}

export type FieldMetadata
  = CommonMetadata
  | AccountMetadata
  | ChoiceMetadata
  | IntegerMetadata
  | FloatMetadata
  | FormattedNumberMetadata
  | ChecklistMetadata
  | DateMetadata
  | AttachmentMetadata
  | ButtonMetadata
  ;

export default function getFieldMetadata(
  column: Column,
  previewBehavior: PreviewBehavior = PreviewBehavior.ModalPreview,
): FieldMetadata {
  const common: CommonMetadata = {
    default: column.default,
    fieldType: column.fieldType,
    permissions: column.permissions,
  };

  switch (column.fieldType) {
    case FieldType.Account: {
      return {
        ...common,
        allowMultiple: column.allowMultiple,
      };
    }
    case FieldType.Multiplechoice:
    case FieldType.Singlechoice:
    case FieldType.Dropdown: {
      const choices = column.choices || {};
      return {
        ...common,
        choices,
        choiceOrder: column.choiceOrder || Object.keys(choices),
        allowMultiple: column.fieldType === FieldType.Multiplechoice,
        allowGridAddingOptions: column.allowGridAddingOptions || undefined,
      };
    }
    case FieldType.Integer: {
      return {
        ...common,
        ...numberFieldMetadata(column),
        precision: 0,
      };
    }
    case FieldType.Float: {
      return {
        ...common,
        ...numberFieldMetadata(column),
      };
    }
    case FieldType.Currency: {
      const currencySymbol = column.currencySymbol || '';

      return {
        ...common,
        ...numberFieldMetadata(column),
        format: _.curry(formatCurrency)(currencySymbol),
      };
    }
    case FieldType.Percent: {
      return {
        ...common,
        ...numberFieldMetadata(column),
        format: _.curry(format)('%'),
      };
    }
    case FieldType.Checklist: {
      const { checklistNames, checklistValues, checklistOrder } = column;

      return {
        ...common,
        checklistNames,
        checklistValues,
        checklistOrder,
      };
    }
    case FieldType.Date:
    case FieldType.DateTime: {
      const { dateFormat, timeFormat, includeTime } = column;
      return { ...common, dateFormat, timeFormat, includeTime };
    }
    case FieldType.Attachment: {
      return {
        ...common,
        previewBehavior,
        showPreview: column.showPreview ?? false,
      };
    }
    case FieldType.Button: {
      return {
        ...common,
        label: column.label,
        action: column.action,
        additionalData: column.additionalData ?? '',
      };
    }
    default: {
      return common;
    }
  }
}

interface NumberFieldMetadata {
  allowNegative: boolean;
  precision?: number;
}

function numberFieldMetadata(column: Column): NumberFieldMetadata {
  return {
    allowNegative: !!column.allowNegative,
    precision: column.precision,
  };
}

function format(suffix: string, value: string): string {
  return `${value}${suffix}`;
}

function formatCurrency(suffix: string, value: string): string {
  return `${suffix}${value}`;
}
