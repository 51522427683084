import React, { useEffect, useState } from 'react';
import IdentifyVerified from './IdentifyVerified';
import LinkExpired from './LinkExpired';

import authService from '../../services/AuthService';
import { useLocation, useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface VerifyParams {
    userID: string;
}

enum CodeVerified {
  VALIDATING = 'validating',
  VALID = 'valid',
  WRONG = 'wrong',
}

export default function CheckConfirmIdentity() {
  const query = useQuery();

  const params: VerifyParams = useParams();

  const user = params.userID || '';
  const hash = query.get('a') || '';

  const [isCodeVerified, setIsCodeVerified] = useState<string>(CodeVerified.VALIDATING);
  const [isLoading, setIsLoading] = useState(true);

  const checkCode = async () => {
    try {
      const response = await authService.confirmEmail(user, hash);
      if (response.status === 200) {
        setIsCodeVerified(CodeVerified.VALID);
      }
    } catch (error) {
      if (error.response.body.message.includes('Verified') ) {
        setIsCodeVerified(CodeVerified.VALID);
      } else {
        setIsCodeVerified(CodeVerified.WRONG);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkCode();
  }, []);

  return (
    <div>
      { isLoading || isCodeVerified === CodeVerified.VALIDATING ? (<LoadingIndicator /> ) : isCodeVerified === CodeVerified.VALID ? (<IdentifyVerified />) : isCodeVerified === CodeVerified.WRONG ? (<LinkExpired />): (null) }
    </div>
  );
}
