import { ActionType, getType } from 'typesafe-actions';
import produce from 'immer';
import { actions as WorkspaceActions } from 'data/workspaces/workspaces.actions';
import { actions as DatabaseActions, setLoadingStateTrue } from 'data/databases/databases.actions';
import { DatabasesState } from 'data/databases/databases.types';
import { groupById } from 'utilities/collections';
import { CollectionTypes } from 'types/schema';
import { actions as CollectionActions } from 'data/collections/collections.actions.new';
import { DatabaseNode } from 'types/response/databaseNode';
import { getCollectionTypeFromUrl } from 'utilities/createUrl';
import LoadingState from 'data/LoadingState';
import * as constants from './databases.constants';

export const initialState: DatabasesState = {
  current: null,
  loadingState: LoadingState.Unloaded,
  byId: {},
  ids: [],
  importLoadingState: LoadingState.Unloaded,
  importedDatabase: null,
  socket: null,
};

type Actions = ActionType<
  typeof DatabaseActions
  | typeof WorkspaceActions.setCurrentWorkspace
  | typeof CollectionActions>;

const reducer = (state: DatabasesState = initialState, action: Actions) => {
  switch (action.type) {
    case getType(CollectionActions.fetchCollectionRequest): {
      return produce(state, draft => {
        if (getCollectionTypeFromUrl(action.payload.url) === CollectionTypes.databases) {
          draft.loadingState = LoadingState.Loading;
        }
      });
    }
    case getType(CollectionActions.fetchCollectionFailure): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.databases) {
          draft.loadingState = LoadingState.Error;
        }
      });
    }
    case getType(CollectionActions.fetchCollectionSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.databases) {
          draft.loadingState = LoadingState.Loaded;
          const { byId, ids } = groupById(action.payload.nodes.filter(node => node['type'] === 'database'));
          draft.byId = byId;
          draft.ids = ids;
        }
      });
    }
    case getType(CollectionActions.copyCollectionSuccess):
    case getType(CollectionActions.createCollectionItemImportSuccess):
    case getType(CollectionActions.createCollectionItemSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.databases) {
          // @ts-ignore
          draft.byId[action.payload.id] = action.payload as DatabaseNode;
          // @ts-ignore
          draft.ids.push(action.payload.id);
        }
      });
    }
    case getType(DatabaseActions.updateDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.databases) {
          if (state.ids.indexOf(action.payload.id) > -1) {
            // @ts-ignore
            draft.byId[action.payload.id] = {
              ...draft.byId[action.payload.id],
              ...action.payload,
            };
          }
        }
      });
    }
    case getType(DatabaseActions.deleteDatabaseSuccess): {
      return produce(state, draft => {
        if (action.meta === CollectionTypes.databases) {
          delete draft.byId[action.payload.id];
          draft.ids.splice(draft.ids.findIndex(id => id === action.payload.id), 1);
        }
      });
    }
    case getType(DatabaseActions.setCurrentDatabase): {
      return produce(state, draft => {
        draft.current = action.payload;
      });
    }
    case getType(WorkspaceActions.setCurrentWorkspace): {
      return produce(state, draft => {
        draft.current = null;
        draft.byId = {};
        draft.ids = [];
        if (draft.loadingState !== LoadingState.Loading) {
          draft.loadingState = LoadingState.Unloaded;
        }
      });
    }
    case constants.IMPORT_DATABASE:
    case constants.FETCH_IMPORTED_DATABASE:
    case constants.SAVE_IMPORTED_DATABASE:
      return {
        ...state,
        importLoadingState: LoadingState.Loading,
      };
    case constants.IMPORT_DATABASE_SUCCESS:
      return {
        ...state,
        importedDatabase: action.importedDatabase,
        importLoadingState: LoadingState.Loaded,
      };
    case constants.SAVE_IMPORTED_DATABASE_SUCCESS:
      return {
        ...state,
        importLoadingState: LoadingState.Loaded,
        importedDatabase: {
          ...state.importedDatabase,
          id: action.id,
        },
      };
    case constants.IMPORT_DATABASE_ERROR:
      return {
        ...state,
        importLoadingState: LoadingState.Error,
      };
    case constants.CREATE_SOCKET_CONNECTION: {
      const socket = new WebSocket(action.socketUrl);
      return {
        ...state,
        socket,
      };
    }
    case constants.DESTROY_SOCKET_CONNECTION: {
      return {
        ...state,
        socket: null,
      };
    }
    case getType(setLoadingStateTrue): {
      return produce(state, draft => {
        draft.loadingState = LoadingState.Loading;
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
