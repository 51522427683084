import * as React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { copyDocument, MoveCopyDocumentPayload, moveDocument } from 'data/documents/documents.actions';
import { getDirectories, isDirectoriesLoading } from 'data/documents/documents.selectors';
import { DirectoryType } from 'data/documents/documents.types';
import { closeModal } from 'data/modals/actions';
import { State as ReduxState } from 'reducers';
import { stripUrlsLastItem } from 'utilities/queryParams';
import { DocumentMinimalType } from 'types/response/documentNode';

import MoveCopyDocumentRender from './render';
import { deepFilter } from './utils';

interface OwnProps {
  selectedItems: DocumentMinimalType[];
}

interface StateProps {
  loading: boolean;
  directories: DirectoryType[];
}

interface DispatchProps {
  copyDocument: (payload: MoveCopyDocumentPayload) => void;
  moveDocument: (payload: MoveCopyDocumentPayload) => void;
  close: () => void;
}

interface State {
  directories: DirectoryType[];
  opened: { [id: string]: boolean };
  selected: DirectoryType | null;
  query: string;
}

type Props =
  & OwnProps
  & StateProps
  & DispatchProps
  ;

const addRootDirectory = (documentUri: string | null, directories: DirectoryType[]): DirectoryType[] =>
  documentUri ? [{
    _id: '',
    title: 'All Files',
    nodes: directories,
    uri: stripUrlsLastItem(documentUri),
  }] : [];

class MoveCopyDocument extends React.Component<Props, State> {
  public state: State = {
    selected: null,
    opened: {},
    query: '',
    directories: this.props.directories,
  };

  private filterDirectories = debounce(query => {
    const directories = query
      ? deepFilter(query, this.props.directories)
      : this.props.directories;
    this.setState({ directories });
  }, 300);

  public componentDidUpdate = (prevProps: Props): void => {
    if (prevProps.directories !== this.props.directories) {
      this.setState({
        directories: this.props.directories,
        query: '',
        opened: {},
      });
    }
  };

  public render = (): JSX.Element => {
    return (
      <MoveCopyDocumentRender
        {...this.props}
        {...this.state}
        onDirectoryClick={this.handleDirectoryClick}
        onQueryChange={this.handleQuery}
        onMove={this.handleMoveDocument}
        onCopy={this.handleCopyDocument}
        onClose={this.props.close}
      />
    );
  };

  private handleDirectoryClick = (directory: DirectoryType) => {
    const { opened } = this.state;

    this.setState({
      opened: { ...opened, [directory._id]: !opened[directory._id] },
      selected: directory,
    });
  };

  private handleCopyDocument = (): void => {
    const { selectedItems, close, copyDocument } = this.props;
    const { selected } = this.state;

    if (!selected) {
      return;
    }

    for (const item of selectedItems) {
      const payload: MoveCopyDocumentPayload = {
        documentURI: item['apiURI'],
        folderID: selected._id,
        folderURI: selected.uri,
      };
      copyDocument(payload);
    }
    close();
  };

  private handleMoveDocument = (): void => {
    const { selectedItems, close, moveDocument } = this.props;
    const { selected } = this.state;

    if (!selected) {
      return;
    }

    for (const item of selectedItems) {
      const payload: MoveCopyDocumentPayload = {
        documentURI: item['apiURI'],
        folderID: selected._id,
        folderURI: selected.uri,
      };
      moveDocument(payload);
    }
    close();
  };

  private handleQuery = (query: string): void => {
    this.setState({ query });
    this.filterDirectories(query);
  };
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  loading: isDirectoriesLoading(state),
  directories: addRootDirectory(ownProps.selectedItems[0].apiURI, getDirectories(state)),
});

const actionCreators: DispatchProps = {
  copyDocument: copyDocument.request,
  moveDocument: moveDocument.request,
  close: closeModal,
};

export default connect(mapStateToProps, actionCreators)(MoveCopyDocument);
