import { CSSProperties } from '@material-ui/core/styles/withStyles';
type ClassKey =
  | 'checkedRoleIcon'
  | 'field'
  | 'fieldLabel'
  | 'customSelectMenuItemText'
  | 'customSelectMenuItem'
  | 'customSelectMenu'
  | 'customSelectButton'
  | 'rowWithSpaceInBetween'
  | 'relative'
  | 'customSelectWrapper';

export const styles: Record<ClassKey, CSSProperties> = {
  rowWithSpaceInBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  relative: {
    position: 'relative',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  fieldLabel: {
    'display': 'flex',
    'alignItems': 'flex-end',
    'marginBottom': '10px',
    '& > span': {
      textTransform: 'initial',
      fontSize: '12px',
    },
  },
  checkedRoleIcon: {
    padding: '8px',
    width: '32px',
  },
  customSelectButton: {
    height: '40px',
    color: '#505f79',
    border: '1px solid #cfd8dc',
    padding: '0 8px',
    minWidth: '150px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    marginRight: 0,
  },
  customSelectMenuItemText: {
    'padding': '8px 24px 8px 0px',
    '& > p': {
      color: '#546E7A',
      fontFamily: 'Roboto',
      fontSize: '11px',
      letterSpacing: '0.22px',
      lineHeight: '13px',
    },
    '& > span': {
      color: '#37474F',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '21px',
      marginBottom: '4px',
    },
  },
  customSelectMenuItem: {
    height: '54px',
    padding: '0px !important',
    borderBottom: '1px solid #E9EDEF',
    position: 'relative',
  },
  customSelectMenu: {
    '& ul': {
      padding: '0px !important',
    },
  },
  customSelectWrapper: {
    'margin': '0px',
    'border': '0px',
    'display': 'inline-flex',
    'padding': '0px',
    'minWidth': '0px',
    'flexDirection': 'column',
    'verticalAlign': 'top',
    'position': 'relative',
    '& > svg': {
      width: '16px',
      position: 'absolute',
      right: '8px',
      top: '8px',
      pointerEvents: 'none',
    },
  },
};
