import * as React from 'react';

import { render } from 'react-dom';
import { SvgIcon } from 'types/common';

export function getSvgIconNode(Icon: SvgIcon): HTMLElement {
  const node = document.createElement('span');
  node.className = 'icon-container';
  render(<Icon height="1em" />, node);

  return node;
}
