import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { CollectionTypes } from 'types/schema';
import { parseURL } from 'utilities/queryParams';
import { DatabaseNode } from 'types/response/databaseNode';
import { State } from 'reducers';
import { getAllDatabasesById } from 'data/databases/databases.selectors';
import { connect } from 'react-redux';
import { stripDomain } from 'utilities/createUrl';

interface StateProps {
  databases: Record<string, DatabaseNode>;
}

type Props = RouteComponentProps<string[]> & StateProps;

class DatabasePage extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { location, databases } = this.props;
    const {
      [CollectionTypes.databases]: urlDatabaseId,
    } = parseURL(location.pathname);
    const currentDatabase = databases[urlDatabaseId];
    const redirectURL = currentDatabase && currentDatabase.linkURI
      ? stripDomain(currentDatabase.linkURI)
      : `${location.pathname}/${CollectionTypes.sheets}/default/${CollectionTypes.items}`;

    return (
      <Redirect to={redirectURL}/>
    );
  };
}

const mapStateToProps = (state: State): StateProps => ({
  databases: getAllDatabasesById(state),
});

export default connect(mapStateToProps, null)(DatabasePage);
