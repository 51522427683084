import * as React from 'react';
import * as keys from 'keycode-js';

const ALWAYS_SUPPRESS = [
  keys.KEY_HOME,
  keys.KEY_END,
  keys.KEY_RIGHT,
  keys.KEY_LEFT,
  keys.KEY_UP,
  keys.KEY_DOWN,
];

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  suppress?: number[];
  handleKeyDown?: (e: any) => void;
}

// Wrapper to suppress some keyboard events to prevent loosing focus on press HOME/END, etc
// Event listener is added via addEventListener, since ag-grid doesn't handle events througth react synthetic events
class FieldEditorWrapper extends React.Component<Props> {
  wrapperRef = React.createRef<HTMLDivElement>();
  suppressCodes = [
    ...ALWAYS_SUPPRESS,
    ...this.props.suppress || [],
  ];
  handleKeydown = e => {
    if (this.props.handleKeyDown) {
      this.props.handleKeyDown(e);
    } else {
      if (this.suppressCodes.includes(e.keyCode)) {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    }
  };
  componentDidMount() {
    if (this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('keydown', this.handleKeydown);
    }
  }
  componentWillUnmount() {
    if (this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('keydown', this.handleKeydown);
    }
  }
  render() {
    const { suppress, ...props } = this.props;
    return <div {...props} ref={this.wrapperRef} />;
  }
}

export default FieldEditorWrapper;
