import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey
  = 'wrapper'
  | 'rowTools'
  | 'expandRecordButton'
  | 'insertRowAboveButton'
  | 'insertRowBelowButton'
  | 'noCreatePermission'
  | 'insertRowButtonContainer'
  ;

export const indexRendererStyles: Record<ClassKey, CSSProperties> = {
  wrapper: { textAlign: 'center' },
  rowTools: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '25%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  expandRecordButton: {
  },
  insertRowAboveButton: {
    backgroundColor: colors.lightBlueGrey,
    color: colors.white,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '-1px',
    width: '13px',
    height: '13px',
    borderRadius: 0,
  },
  insertRowBelowButton: {
    backgroundColor: colors.lightBlueGrey,
    color: colors.white,
    width: '13px',
    height: '13px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '-1px',
    borderRadius: 0,
  },
  noCreatePermission: {
    '& $insertRowAboveButton': {
      visibility: 'hidden',
    },
    '& $insertRowBelowButton': {
      visibility: 'hidden',
    },
  },
  insertRowButtonContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '-1px',
    width: '13px',
    height: '100%',
  },
};
