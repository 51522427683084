import { withStyles } from '@material-ui/core';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as _ from 'lodash/fp';
import * as React from 'react';

import { SvgIcon } from 'types/common';
import { TreeNode } from 'types/response/http/getNode';

import Title from './Title';
import { nodeStyles } from 'components/NavigationPane/Node/Node.style';

interface Props extends WithStyles<typeof nodeStyles> {
  title: string;
  nodes?: TreeNode[];
  uri?: string;
  Icon: SvgIcon;
  renderItem(node: TreeNode, index: number): JSX.Element;
}

interface State {
  isOpen: boolean;
  listHeight?: number;
}

export class Node extends React.Component<Props, State> {
  public state: State = { isOpen: true };
  public listRef: React.RefObject<HTMLUListElement> = React.createRef();

  public componentDidMount(): void {
    const { current: listElement } = this.listRef;

    if (listElement) {
      const { height } = listElement.getBoundingClientRect();
      this.setListHeight(height);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const { current: listElement } = this.listRef;
    const { isOpen } = this.state;

    if (isOpen && listElement && !_.isEqual(prevProps.nodes, this.props.nodes)) {
      this.setListHeight(undefined, () => {
        const { height } = listElement.getBoundingClientRect();
        this.setListHeight(height);
      });
    }
  }

  public render(): JSX.Element {
    const { classes, title, nodes, uri, renderItem, Icon } = this.props;
    const { isOpen, listHeight } = this.state;

    const listClassName = cn(classes.list, {
      [classes.listClose]: !isOpen,
    });

    const style: CSSProperties = listHeight ? { height: listHeight } : {};

    return (
      <li className={classes.node}>
        <Title
          title={title}
          isOpen={isOpen}
          nodes={nodes}
          uri={uri}
          Icon={Icon}
          toggleOpen={this.toggleOpen}
        />
        <ul className={listClassName} ref={this.listRef} style={style}>
          {nodes && nodes.map(renderItem)}
        </ul>
      </li>
    );
  }

  private toggleOpen = (): void => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  private setListHeight = (height?: number, callback?: () => void): void => {
    this.setState({ listHeight: height }, callback);
  };
}

export default withStyles(nodeStyles)(Node);
