import * as React from 'react';
import { PropertyWrapper, PropertyName, PropertyContent, PropertyContentInner } from 'components/PropertiesModal/PropertiesModal.style';

interface Props {
  name: string;
  children: React.ReactNode;
}

const Property = ({ name, children }: Props) => (
  <PropertyWrapper>
    <PropertyName id={name}>{name}</PropertyName>
    <PropertyContent>
      <PropertyContentInner id={`${name.replace(' ', '')}_container`}>
        {children}
      </PropertyContentInner>
    </PropertyContent>
  </PropertyWrapper>
);

export default Property;
