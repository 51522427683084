import * as React from 'react';


import Confirm from 'components/Modals/Confirm';
import Modal from 'components/Modals/Modal';
import { Actions as AssureSignActions } from 'data/assureSign/assureSign.actions';
import * as ModalActions from 'data/modals/actions';
import { connect } from 'react-redux';

const constants = {
  TITLE: 'Cancel Envelope',
  MESSAGE: 'Are you sure you want to cancel the signature? If you proceed, this action cannot be undone.',
  CLOSE_LABEL: 'Close',
  CONFIRM_LABEL: 'Confirm',
  DEFAULT_REMARKS: 'Cancelled in HubSync',
};

interface DispatchProps {
  closeModal: typeof ModalActions.closeModal;
  cancelEnvelope: typeof AssureSignActions.cancelEnvelope;
}

interface OwnProps {
  envelopeID: string;
}

export type Props = OwnProps & DispatchProps;

const CancelEnvelopeModal = (props: Props): JSX.Element => {
  const { closeModal, cancelEnvelope, envelopeID } = props;
  const { TITLE, MESSAGE, CONFIRM_LABEL, DEFAULT_REMARKS } = constants;

  const handleConfirm = (): void => {
    cancelEnvelope( { envelopeId: envelopeID, remarks: DEFAULT_REMARKS });
    closeModal();
  };


  return (
    <Modal id="cancelEnvelopeMessageModal" onClose={closeModal}>
      <Confirm
        title={TITLE}
        message={MESSAGE}
        close={closeModal}
        onConfirm={handleConfirm}
        confirmLabel={CONFIRM_LABEL}
      />
    </Modal>
  );
};

const mapDispatchToProps: DispatchProps = {
  closeModal: ModalActions.closeModal,
  cancelEnvelope: AssureSignActions.cancelEnvelope,
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps,
)(CancelEnvelopeModal);
