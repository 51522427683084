import * as React from 'react';

import MuiMenu, { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export interface MenuRenderTriggerParams {
  openMenu: React.MouseEventHandler;
  opened: boolean;
}

export interface MenuProps extends Partial<MuiMenuProps> {
  renderTrigger: (params: MenuRenderTriggerParams) => React.ReactNode;
  children: React.ReactNode;
}

interface MenuState {
  anchorElement: HTMLElement | null;
}

const defaultMenuProps: Partial<MuiMenuProps> = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
};

class Menu extends React.Component<MenuProps, MenuState> {
  static Item = MenuItem;

  state = {
    anchorElement: null,
  };

  handleOpen = (e) => {
    this.setState({ anchorElement: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorElement: null });
  };

  render() {
    const { renderTrigger, children, ...props } = this.props;
    const { anchorElement } = this.state;
    const isOpen = !!anchorElement;

    return (
      <>
        {renderTrigger({ openMenu: this.handleOpen, opened: isOpen })}
        <MuiMenu
          {...defaultMenuProps}
          {...props}
          open={isOpen}
          onClose={this.handleClose}
          anchorEl={anchorElement}
          onClick={this.handleClose}
          getContentAnchorEl={undefined}
        >
          {children}
        </MuiMenu>
      </>
    );
  }
}

export default Menu;
