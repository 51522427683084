import React, { useState } from 'react';

import {
  ListItemText,
  Menu,
  MenuItem,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button, { Variant as ButtonVariant } from 'components/Button';
import Typography, { Variant as TypographyVariant } from 'components/Typography';
import { ReactComponent as CheckIcon } from 'icons/check-blue.svg';

import { styles } from './RolePicker.styles';

export type Role = {
  title: string;
  content: string;
  value: string;
};

const roles: Array<Role> = [
  {
    title: 'HubSync Admin',
    content: 'Full Organization and Workspace management',
    value: 'tenant-admin',
  },
  {
    title: 'Organization User',
    content: 'Access rights will be set up in the next step',
    value: 'default-role',
  },
];

type RolePickerProps = WithStyles<typeof styles> & {
  onRoleChanged?: (role: typeof roles[0]) => void;
};

function RolePicker({
  onRoleChanged,
  ...props
}: RolePickerProps): React.ReactElement<RolePickerProps> {
  const [role, setRole] = useState(0);
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();

  const handleOnRoleChanged = (index: number): void => {
    setRole(index);
    setAnchorEl(undefined);
    if (onRoleChanged instanceof Function) {
      const selectedRole = roles[index];
      onRoleChanged(selectedRole);
    }
  };

  return (
    <div className={props.classes.field}>
      <div className={props.classes.fieldLabel}>
        <Typography variant={TypographyVariant.FieldLabel}>Role</Typography>
      </div>
      <div
        className={`${props.classes.rowWithSpaceInBetween} ${props.classes.relative}`}
      >
        <div className={props.classes.customSelectWrapper}>
          <Button
            id="btnSelectRole"
            className={props.classes.customSelectButton}
            aria-controls="role-select-menu"
            aria-haspopup="true"
            variant={ButtonVariant.DropDown}
            color="primary"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            label={roles[role].title}
          />
          <ArrowDropDownIcon />
        </div>
        <Menu
          id="role-select-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(undefined);
          }}
          className={props.classes.customSelectMenu}
        >
          {roles.map((element, index) => (
            <MenuItem
              key={index}
              selected={index === role}
              onClick={() => {
                handleOnRoleChanged(index);
              }}
              className={props.classes.customSelectMenuItem}
            >
              <div className={props.classes.checkedRoleIcon}>
                {index === role && <CheckIcon />}
              </div>
              <ListItemText
                className={props.classes.customSelectMenuItemText}
                primary={element.title}
                secondary={element.content}
              />
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
}

export default withStyles(styles)(RolePicker);
