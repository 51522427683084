import tinyColor from 'tinycolor2';

import { rgba } from './utils/colorUtils';

export { default as deprecatedGrey } from '@material-ui/core/colors/grey';

// Deprecated old design palette

export const deprecatedButtonPrimary = '#00aeef';
export const deprecatedFileChipBackground = '#eaeef5';
export const deprecatedDefaultChipColor = '#f0f0f0';

export const deprecatedButtonPrimaryTransparent = rgba(deprecatedButtonPrimary, 0.05);

// New design pallete

export const hubsyncBlue = '#0065FF';
export const hubsyncBlueTransparent = rgba(hubsyncBlue, 0.3);
export const hubsyncBlueSemiTransparent = rgba(hubsyncBlue, 0.5);
export const hubsyncBlue40Transparent = rgba(hubsyncBlue, 0.4);
export const lightBlueSemiTransparent = 'rgba(0, 97, 213, 0.04)';
export const skyBlue = '#E6F0FF';
export const darkBlue = '#003497';
export const muiFocusColor = 'rgba(0, 101, 255, .5)';

export const black = '#000';
export const blackGrey = 'rgba(0, 0, 0, .08)';
export const blackTransparent = 'rgba(0, 0, 0, .1)';
export const white = '#fff';

export const whiteThree = '#ededed';
export const iceBlue = '#e9edef';
export const lightGrey = '#ebecf0';
export const slate = '#546e7a';
export const slateTwo = 'rgba(0, 0, 0, 0.54)';
export const lightBlueGrey = '#cfd8dc';
export const charocalGrey = '#37474f';
export const dark = '#253238';
export const lightGreyBlue = '#b0bec5';
export const blueyGreyDark = '#78909c';
export const disabledGrey = '#939393';
export const darkSlateBlue = '#172b4d';
export const whiteSmoke = '#f5f5f5';

export const lightBlueGreySemiTransparent = 'rgba(55, 71, 79, 0.8)';
export const lightBlueTransparent = 'rgba(0, 174, 239, 0.05)';
export const blueTransparent = 'rgba(0,101,255,0.1)';
export const greyTransparent = 'rgba(233, 237, 239, 0.3)';
export const darkGreyTransparent = 'rgba(84, 110, 122, 0.38)';
export const patternsBlue = rgba('#E9EDEF', 0.4);

export const brownishGrey = '#707070';
export const brightBlue = '#0065FF';
export const ghostBlue = '#f2f8fe';
export const ghostGrey = '#CBD1D8';
export const athensGrey = '#F4F5F7';
export const selago = '#F6F9FE';
export const jungleGreen = '#33B679';
export const ghostWhite = '#F9F9FB';

// Color Pallette

export const vividAzure = '#0061D5';
export const petrol = '#00695C';
export const brightSkyBlue = '#00b0ff';
export const turquoise = '#00bfa5';
export const aquamarine = '#1de9b6';
export const tealish = '#26A69A';
export const algaeGreen = '#26D870';
export const bluish = '#2892D7';
export const midGreen = '#4CAF50';
export const brightCyan = '#53D8FB';
export const slateGrey = '#5A5766';
export const blueGrey = '#607D8B';
export const purplishBlue = '#651FFF';
export const purpleBlue = '#3F51B5';
export const lightGreen = '#35c5ab';
export const brighterPurple = '#998ED9';
export const lightGreenBlue = '#71e591';
export const lightGrayishBlue = '#BDC7CC';
export const purpley = '#7C4DFF';
export const blueyGrey = 'rgba(0, 0, 0, 0.54)';
export const mediumGray = '#979797';
export const darkGrey = '#97A0AF';
export const brightViolet = '#AA00FF';
export const brick = '#b71c1c';
export const cerise = '#C60F7B';
export const darkPink = '#D8315B';
export const heliotrope = '#E040Fb';
export const tomato = '#e53935';
export const tomatoFaded = '#fdf4f7';
export const neonPink = '#F90093';
export const offYellow = '#FDD835';
export const pinkyRed = '#ff1744';
export const redOrange = '#ff3d00';
export const pinkishOrange = '#FF6E40';
export const pumpkinOrange = '#ff6f00';
export const butterscotch = '#ffab40';
export const ebb = '#E9E3E3';
export const aliceBlue = '#E8F5FA';
export const lightRed = '#f44336';
export const lightOrange = '#F09300';
export const purple = '#7A6FF0';
export const selectedGrey = '#E7EAF6';
export const lightGreyA = '#A19F9D';
export const lightGreyB = '#CFD8DC';
export const inputIcon = '#727272';

// Bakertilly Colors

export const greenBaker = '#D1EC51';

export const colorPalette = [
  vividAzure,
  petrol,
  brightSkyBlue,
  turquoise,
  aquamarine,
  tealish,
  algaeGreen,
  bluish,
  midGreen,
  brightCyan,
  slateGrey,
  blueGrey,
  purplishBlue,
  lightGreenBlue,
  purpley,
  blueyGrey,
  mediumGray,
  brightViolet,
  brick,
  cerise,
  darkPink,
  heliotrope,
  tomato,
  neonPink,
  offYellow,
  pinkyRed,
  redOrange,
  pinkishOrange,
  pumpkinOrange,
  butterscotch,
];

export function getColorByIndex(index: number): string {
  return colorPalette[index % colorPalette.length];
}

export function darken(color: string, amount: number): string {
  return tinyColor(color).darken(amount * 100).toHexString();
}
