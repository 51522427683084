import * as React from 'react';
import { connect } from 'react-redux';

import * as Actions from 'data/modals/actions';
import { getAllDatabases } from 'data/databases/databases.selectors';
import { State } from 'reducers';

import { DatabaseNode } from 'types/response/databaseNode';
import { WorkspaceNode } from 'types/response/workspaceNode';
import { CollectionTypes } from 'types/schema';

import DuplicateDatabaseRender from './render';

interface OwnProps {
  database: DatabaseNode;
  workspace: WorkspaceNode;
  availableWorkspaces: WorkspaceNode[];
  shouldDuplicateRecords: boolean;
  shouldDuplicateComments?: boolean;
  collectionType: CollectionTypes;
  onClose(): void;
}

interface StateProps {
  databases: DatabaseNode[];
}

interface DispatchProps {
  duplicateDatabase(database: DatabaseNode, databases: DatabaseNode[], workspace: WorkspaceNode, shouldDuplicateRecords: boolean, shouldDuplicateComments: boolean, collectionType: CollectionTypes): void;
  onWorkspaceChange(workspace: WorkspaceNode): void;
  onShouldDuplicateRecordsChange(): void;
  onShouldDuplicateCommentsChange(): void;
}

type Props
  = OwnProps
  & StateProps
  & DispatchProps
  ;

function DuplicateDatabase(props: Props): JSX.Element {
  function onDuplicate(): void {
    props.duplicateDatabase(
      props.database,
      props.databases,
      props.workspace,
      props.shouldDuplicateRecords,
      props.shouldDuplicateComments || false,
      props.collectionType,
    );
  }

  return (
    <DuplicateDatabaseRender
      {...props}
      onDuplicate={onDuplicate}
    />
  );
}

const mapStateToProps = (state: State): StateProps => ({
  databases: getAllDatabases(state),
});

const mapDispatchToProps: DispatchProps = {
  onWorkspaceChange: Actions.changeSelectedWorkspace,
  onShouldDuplicateRecordsChange: Actions.changeShouldDuplicateRecords,
  onShouldDuplicateCommentsChange: Actions.changeShouldDuplicateComments,
  duplicateDatabase: Actions.duplicateDatabase,
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(DuplicateDatabase);
