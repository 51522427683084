import { GroupByState } from 'data/grid-options/groupBy/groupBy.reducer';
import { columnsStateSelector } from 'data/collections/view-config/viewConfig.selectors';
import { sortBy, isNil } from 'lodash';
import { State } from 'reducers';
import { GridColumnState } from 'data/collections/collections.reducer';

export const groupByState = (state: State): GroupByState => state.gridOptions.groupBy;

export const getRowGroupCols = (state): GridColumnState[] => {
  return sortBy(columnsStateSelector(state)
    // @ts-ignore
    .filter(c => (!isNil(c.rowGroupIndex) && c.rowGroupIndex >= 0)), ['rowGroupIndex']);
};

export const rowGroupPanelShow = (state) => groupByState(state).rowGroupPanelShow;
