import * as React from 'react';
import { closeModal } from 'data/modals/actions';
import { connect } from 'react-redux';
import { updateNode } from 'data/collections/collections.actions.new';
import { DocumentNode } from 'types/response/documentNode';
import Rename from 'components/Modals/Rename';

interface DispatchProps {
  updateNode: typeof updateNode.request;
  close: () => void;
}

interface OwnProps {
  document: DocumentNode;
}

type Props = DispatchProps & OwnProps;

export class RenameDocument extends React.Component<Props> {
  public render = (): JSX.Element => {
    const { close, document } = this.props;
    return (
      <Rename
        currentValue={document.fileName}
        existingValues={[]}
        close={close}
        onUpdate={this.handleUpdate}
        windowTitle={document.isFolder ? 'Rename Folder' : 'Rename File'}
        valueTitle="name"
      />
    );
  };

  private handleUpdate = (newValue: string): void => {
    const { updateNode, document, close } = this.props;
    updateNode({
      url: document.apiURI,
      data: { fileName: newValue },
      isFullObject: false,
    });
    close();
  };
}

const mapDispatchToProps: DispatchProps = {
  updateNode: updateNode.request,
  close: closeModal,
};

export default connect(null, mapDispatchToProps)(RenameDocument);
