import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import * as React from 'react';
import { Omit } from 'utility-types';

import * as Styles from 'styles';
import * as Colors from 'styles/colors';
import { rgba } from 'styles/utils/colorUtils';

import SelfSelectingInput, { Props as InputProps } from '../../SelfSelectingInput';
import Typography, { Variant } from '../../Typography';

interface OwnProps {
  fieldName?: string;
  errorMessage?: string;
  onChange(fieldName?: string): void;
}

type Props
  = OwnProps
  & Omit<InputProps, 'onChange' | 'value'>
  & WithStyles<ClassKey>
  ;

function FieldNameInput(props: Props): JSX.Element {
  const {
    classes,
    errorMessage,
    onChange,
    fieldName = '',
    ...inputProps
  } = props;

  function onInputChange(event: React.FormEvent<HTMLInputElement>): void {
    const { value } = event.currentTarget;
    onChange(value || undefined);
  }

  const inputClassName = cn({
    [classes.input]: true,
    [classes.errorInput]: errorMessage,
  });

  return (
    <React.Fragment>
      <Typography
        variant={Variant.Input}
        className={inputClassName}
      >
        <SelfSelectingInput
          {...inputProps}
          value={fieldName}
          onChange={onInputChange}
          autoFocus
          autoSelect
        />
      </Typography>
      {errorMessage &&
        <Typography
          variant={Variant.Input}
          className={classes.error}
        >
          {errorMessage}
        </Typography>
      }
    </React.Fragment>
  );
}

type ClassKey
  = 'input'
  | 'error'
  | 'errorInput'
  ;

const styles = withStyles<ClassKey>({
  input: {
    ...Styles.focus,
    width: '100%',
    border: `1px solid ${Colors.lightBlueGrey}`,
    borderRadius: 2,
    padding: '7px 9px',
  },
  error: { color: Colors.tomato },
  errorInput: {
    backgroundColor: rgba(Colors.tomato, 0.1),
    border: `1px solid ${Colors.tomato}`,
    outline: 'none',
  },
});

export default styles(FieldNameInput);
