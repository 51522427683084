import * as React from 'react';
import { filter } from 'lodash/fp';

import wrapCellRenderer from '../components/wrapCellRenderer';
import { FieldRendererProps } from '../Fields.types';

interface ChecklistRendererProps extends FieldRendererProps {
  checklistNames: { [key: string]: string };
  checklistValues: { [key: string]: boolean };
  checklistOrder: string[];
}

class ChecklistRenderer extends React.Component<ChecklistRendererProps> {
  getTotalItemsLength = (): number => {
    const { checklistOrder = [] } = this.props;
    return checklistOrder.length;
  };

  getCompletedItemsLength = (): number => {
    const names: {[key: string]: string} = this.props.value || {};
    const values = Object.values(names);
    return filter(Boolean, values).length;
  };

  render() {
    const { isGrid, startEditing } = this.props;
    const totalItems = this.getTotalItemsLength();
    const completedItems = this.getCompletedItemsLength();

    const handleClick = isGrid
      ? undefined
      : startEditing;

    return (
      <div onClick={handleClick}>
        {`${completedItems}/${totalItems} completed`}
      </div>
    );
  }
}

export default wrapCellRenderer(ChecklistRenderer);
