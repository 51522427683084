import * as React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import svgIcons from 'styles/svgIcons';

import Typography, { Variant } from '../Typography';

export const LockedFieldTooltip = withStyles(() => ({
  tooltip: {
    boxShadow: '0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2)',
    backgroundColor: '#ffffff',
    maxWidth: 'none',
    marginLeft: -16,
    zIndex: 9999,
  },
}))(Tooltip);

const IconWrapper = styled('span')`
display: inline-block;
margin-top: 0.25rem;
  svg {
    width: 0.833rem;
    height: 0.917rem;
  }
`;

export default function LockColumnIcon(): JSX.Element {
  return (
    <LockedFieldTooltip
      interactive
      placement="bottom-start"
      title={
        <div>
          <div>
            <Typography variant={Variant.ToolTipText}>
                Only Administrators can edit locked columns
            </Typography>
          </div>
        </div>
      }
    >
      <IconWrapper><svgIcons.LockColumn /></IconWrapper>
    </LockedFieldTooltip>
  );
}
