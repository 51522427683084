import React from 'react';
import EmailIcon from '../../assets/images/mfa/email_sent.svg';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { createStructuredSelector } from 'reselect';
import { updateUserMFAMethod } from 'data/users/users.actions';
import { User } from 'data/users/users.types';
import { withStyles } from '@material-ui/core';
import { styles } from './MFA.index.style';

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUserMFAMethod: (payload: { data: { method: string } }) => void;
}

const selectors = createStructuredSelector<ReduxState, StateProps>({
  user: (state: ReduxState) => state.users.user || {},
});

const EmailSentVerification = (props) => {
  const { classes, emailSent, goLogin } = props;
  const tenantConfig = window.appStore?.getState().app?.tenant?.email ?? '';

  return (
    <>
      <div className={classes.mfaFormContainer}>
        <div className={classes.topContent}>
          <h3>Email sent</h3>
        </div>
        <div className={classes.bodyContent}>
          <div className={classes.imageBodyCenter}>
            {tenantConfig.emailSentIcon? <img src={tenantConfig.emailSentIcon} alt="HubSync" /> : <img src={EmailIcon} alt="HubSync" /> }
          </div>
          <p className={classes.textBodyCenter}>
              Confirmation email was sent to <span>{emailSent}</span>
          </p>
        </div>
      </div>
      <hr className={classes.lineSeparator}/>
      <div className={classes.footerContent}>
        <a onClick={goLogin}><span>Back to Log In</span></a>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  updateUserMFAMethod: updateUserMFAMethod.request,
};

export default connect(
  selectors,
  mapDispatchToProps,
)(withStyles(styles)(EmailSentVerification));
