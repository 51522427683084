import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './Editor.style';
import SendIcon from '@material-ui/icons/Send';
import IconButton, { Color, Size } from 'components/IconButton';
import { HtmlEditor, Inject, RichTextEditorComponent, Toolbar, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import _debounce from 'lodash/debounce';

const TextEditor = (props) => {
  const [rteObj, setRTEObj] = useState<RichTextEditorComponent | null>(null);
  const [mentions, setMentions] = useState({});
  const isReader = props.mode === 'reader';
  const toolbarSettings = {
    enable: !isReader,
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'UnorderedList',
      'OrderedList',
      {
        template: `
          <button class="e-tbar-btn e-control e-btn e-lib e-icon-btn" type="button" tabindex="-1" aria-label="Mention" style="width: auto;">
            <span class="e-btn-icon e-icons ${props.classes.mentionIcon}">
            </span>
          </button>`,
        undo: true,
        click: ()=>{
          rteObj?.executeCommand?.('insertHTML', '<span class="atwho-query">@</span>');
          if (rteObj?.contentModule?.getEditPanel) {
            // @ts-ignore
            $(rteObj.contentModule.getEditPanel()).click();
          }
        },
        tooltipText: 'Add Mention',
      },
    ],
  };

  function onCreated() {
    const config = {
      at: '@',
      data: props.suggestions,
      displayTpl: '<li> ${name}</li>',
      insertTpl: '<a class="mention" href="#" data-id="${id}">@${name}</a>',
      limit: 200,
      callbacks: {
        'beforeInsert': function(value) {
          const userId = value.match(/(?<=data-id=")(.*)(?=")/gm)?.[0];
          if (userId) setMentions({ ...mentions, [userId]: true });
          value = value.replace(/data-id="(.*)"/, '');
          return value;
        },
      },
    };

    if (rteObj?.contentModule?.getEditPanel) {
      const textArea = rteObj.contentModule.getEditPanel();
      // @ts-ignore
      $(textArea).atwho(config);
      toggleReader();
    }
  }

  const onChange = _debounce(function() {
    if (rteObj && props.onChange) {
      props.onChange(getMessage());
    }
  }, 150);

  function getMessage() {
    const html = rteObj?.contentModule?.getEditPanel ? rteObj.contentModule.getEditPanel().innerHTML : rteObj?.value;
    const currentMentions: Array<object> = [];
    for (const mention in mentions) {
      if (mention) {
        const user = props.suggestions.find(user => user?.id === mention);
        if (user && html?.includes(user.name)) {
          currentMentions.push(user);
        }
      }
    }
    return { html, mentions: currentMentions };
  }

  function sendMessage() {
    const text = rteObj?.contentModule?.getEditPanel?.()?.textContent?.replace(/[\u00A0-\u9999<>&| ]/g, '');
    if (text) {
      const message = getMessage();
      props.sendMessage(message);
    }
    clearEditor();
  }

  function clearEditor() {
    if (rteObj?.contentModule.getEditPanel) {
      rteObj.contentModule.getEditPanel().innerHTML = '<p></p>';
      rteObj.value = '<p></p>';
    }
    setMentions({});
  }

  function onKeyboard(event: React.KeyboardEvent) {
    if (
      (!event.shiftKey && event.key === 'Enter' && props.resetOnEnter) ||
      (event.ctrlKey && event.key === 'Enter')
    ) {
      sendMessage();
    }
    onChange();
  }

  function toggleReader() {
    if (rteObj) {
      rteObj.readonly = isReader || props.readOnly;
      rteObj.enabled = !props.readOnly;
    }
  }

  useEffect(()=> {
    toggleReader();
  }, [props.mode, props.readOnly]);

  return (
    <div id={props.id} className="messages-richtexteditor">
      <div className={props.classes.relativeWrapper}>
        <div className={`${isReader ? props.classes.reader : ''} ${props.smallToolbarIcons ? props.classes.smallToolbarIcons : ''}`} onKeyDown={onKeyboard}>
          <RichTextEditorComponent
            height={isReader?'auto':150 }
            actionComplete={onChange}
            beforeSanitizeHtml={onChange}
            enableResize={!isReader}
            showCharCount={!isReader}
            ref={(richtexteditor) => {
              setRTEObj(richtexteditor);
            }}
            created={onCreated}
            toolbarSettings={toolbarSettings}
            readOnly={isReader}
            disabled={isReader}
            value={props.value}
            onBlur={onChange}>
            <Inject services={[Toolbar, HtmlEditor, Count, QuickToolbar]}/>
          </RichTextEditorComponent>
          {props.showSendButton &&
            <IconButton
              icon={SendIcon}
              color={Color.DarkGray}
              size={Size.Large}
              className={props.classes.sendButton}
              onClick={sendMessage}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TextEditor);
