import Amplify from 'aws-amplify';
import request from 'superagent';

import { getApiGatewayIdentityEndpoint } from 'env';
import { TenantNode } from 'types/response/tenantNode';
import { TenantConfigError } from 'utilities/errors';

/** */
export const fetchTenantConfig = async (): Promise<TenantNode> => {
  const domain = getApiGatewayIdentityEndpoint();
  const { body } = await request(`${domain}/tenant/config`);

  return body;
};

/** */
export const configAWSAmplify = (tenant: TenantNode): void => {
  const location = window?.location ?? {};
  const origin = location.origin ?? '';
  const hostname = location.host ?? '';
  const { authProvider, maintenance } = tenant;

  if (!authProvider) {
    throw new TenantConfigError('Missing authProvider');
  }

  Amplify.configure({
    Auth: {
      identityPoolId: authProvider.identityPoolId,
      region: authProvider.region,
      userPoolId: authProvider.userPoolId,
      userPoolWebClientId: authProvider.userPoolClientId,
      clientMetadata: { hostname },
    },
    oauth: {
      domain: authProvider.domain,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: origin,
      // during sso logout, cognito will redirect to this URL (needs to be set as a cognito sign out URL)
      redirectSignOut: `${origin}/logout`,
      responseType: 'token',
    },

    // Necessary for UserMigration, It may be USER_PASSWORD_AUTH or USER_SRP_AUTH
    // Custom auth enables MFA
    authenticationFlowType: maintenance?.userMigration
      ? 'USER_SRP_AUTH'
      : 'CUSTOM_AUTH',
  });
};
