import * as React from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { ColumnApi } from 'ag-grid-community';
import IconButton, { Color as IconButtonColor, Size as IconButtonSize } from 'components/IconButton';
import {
  DropdownIcon,
  FormMultiSelectWrapper,
  FormSelectWrapper,
  MultiSelectDropdownIcon,
  styles,
} from 'components/NodeFieldData/choice/ChoiceEditor.style';
import ChoiceItem from 'components/NodeFieldData/choice/ChoiceItem/ChoiceItem';
import { ChipsWrapperDropdown } from 'components/NodeFieldData/components/ChipsWrapper';
import GridCellFloatingWrapper from 'components/NodeFieldData/components/GridCellFloatingWrapper';
import wrapCellEditor from 'components/NodeFieldData/components/wrapCellEditor';
import { FieldEditorProps } from 'components/NodeFieldData/Fields.types';
import { Actions as CollectionActions } from 'data/collections/collections.actions';
import * as keys from 'keycode-js';
import _ from 'lodash';
import { difference, without } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as Colors from 'styles/colors';
import svgIcons from 'styles/svgIcons';
import { Choice } from 'types/schema';
import { coerseArray } from 'utilities/common';

import ListSelectPopup from './ListSelectPopup';

import { isNullEmptyBlankOrUndefined } from '../../NodeFieldData/filters/helpers';

export interface RenderChoiceProps {
  onDelete?: () => void;
  className?: string;
}
interface DispatchProps {
  updateField: typeof CollectionActions.updateField;
}

interface OwnProps {
  allowMultiple?: boolean;
  renderChoice?: (choice: Choice, readOnly?: boolean, lock?: boolean, props?: RenderChoiceProps) => React.ReactNode;
  renderSelectedChoice?: (choice: Choice, readOnly?: boolean, lock?: boolean, props?: RenderChoiceProps) => React.ReactNode;
  matchChoice?: (query: string, choice: Choice) => boolean;
  choices: Record<string, Choice>;
  choiceOrder: string[];
  isGrid?: boolean;
  columnApi?: ColumnApi;
  includeEmpty?: boolean;
  allowGridAddingOptions?: boolean;
}

export type ChoiceEditorProps = OwnProps & DispatchProps & FieldEditorProps & WithStyles<typeof styles>;

interface ChoiceEditorState {
  open: boolean;
}

function renderChoiceDefault(choice: Choice, readOnly?: boolean, lock?: boolean, props?: RenderChoiceProps): JSX.Element {
  return <ChoiceItem choice={choice} readonly={readOnly} lock={lock} {...props} />;
}

function matchChoiceDefault(query: string, choice: Choice): boolean {
  return choice.label.toLowerCase().includes(query.toLowerCase());
}

class ChoiceEditor extends React.Component<ChoiceEditorProps, ChoiceEditorState> {
  public state: ChoiceEditorState = {
    open: false,
  };

  private wrapper: HTMLDivElement | null = null;
  private renderChoice = this.props.renderChoice || renderChoiceDefault;
  private renderSelectedChoice = this.props.renderSelectedChoice || this.props.renderChoice || renderChoiceDefault;
  private matchChoice = this.props.matchChoice || matchChoiceDefault;

  componentDidMount() {
    const { isGrid, keyPress = 0 } = this.props;

    if (isGrid) {
      if ([keys.KEY_BACK_SPACE, keys.KEY_DELETE].includes(keyPress)) {
        this.props.onChange([], true);
        this.props.stopEditing(true);
        this.props.node.setDataValue(this.props.colDef.colId, null);
      }

      // @ts-ignore
      if ((!this.props.api?.focusController?.keyboardFocusActive && !this.props.allowMultiple) || (this.props.keyPress === keys.KEY_RETURN || this.props.keyPress === keys.KEY_ENTER)) {
        setTimeout(() => {
          this.setState({
            open: true,
          });
        }, 50);
      }

      document.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  componentWillUnmount() {
    if (this.props.isGrid) {
      document.removeEventListener('keydown', this.handleKeyPress, false);
    }
  }

  handleKeyPress = (event) => {
    if (event.keyCode === keys.KEY_RETURN || event.keyCode === keys.KEY_ENTER) {
      if (this.props.isGrid) {
        setTimeout(() => {
          this.setState({
            open: true,
          });
        }, 50);
      }
    }
  };

  public render = (): JSX.Element => {
    const { classes, allowMultiple, choices, eGridCell, value, readOnly, lock } = this.props;
    const remainingChoices = this.getChoicesList();
    const renderedList = this.renderList(remainingChoices);

    const canUpdateItems = this.props?.permissions?.includes('items:update');
    const canLockItems = this.props?.permissions?.includes('items:lock');


    if (allowMultiple && eGridCell) {
      const allowGridAddingOptions = this.props.colDef.refData.allowGridAddingOptions;
      return (

        <>
          <GridCellFloatingWrapper
            anchorEl={eGridCell}
            innerRef={wrapper => this.wrapper = wrapper}
          >
            {coerseArray(value).map(this.renderMultipleChoiceItem)}
            {(!!remainingChoices.length || allowGridAddingOptions) && (
              <IconButton
                icon={svgIcons.Add}
                color={IconButtonColor.DarkGray}
                size={IconButtonSize.Large}
                onClick={this.openList}
                className={classes.addIcon}
              />
            )}
          </GridCellFloatingWrapper>
          {renderedList}
        </>
      );
    } else if (allowMultiple) {
      return (
        <>
          <FormMultiSelectWrapper
            onClick={this.openList}
            innerRef={wrapper => this.wrapper = wrapper}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.keyCode === keys.KEY_SPACE) {
                e.preventDefault();
                e.stopPropagation();
                this.openList();
              }
            }}
          >
            {coerseArray(value).map(this.renderMultipleChoiceItem)}
            {((canLockItems || canUpdateItems) || (canUpdateItems && !lock)) && (canUpdateItems && <MultiSelectDropdownIcon
              onClick={this.openList}
            />)}
          </FormMultiSelectWrapper>
          {renderedList}
        </>
      );
    } else if (eGridCell) {
      const choice = value && Object.values(choices).find(choice => choice.label === value);
      return (
        <>
          <ChipsWrapperDropdown
            onClick={this.openList}
            innerRef={wrapper => this.wrapper = wrapper}
          >
            <DropdownIcon />
            {choice && this.renderChoice(choice, readOnly, lock)}
            {!choice && value && choices[value] && this.renderChoice(choices[value], readOnly, lock)}
          </ChipsWrapperDropdown>
          {renderedList}
        </>
      );
    } else {
      const choice = value && Object.values(choices).find(choice => choice.label === value);
      return (
        <>
          <FormSelectWrapper
            lock={lock}
            reader={!canUpdateItems}
            admin={canLockItems}
            onClick={this.openList}
            innerRef={wrapper => this.wrapper = wrapper}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.keyCode === keys.KEY_SPACE) {
                e.preventDefault();
                e.stopPropagation();
                this.openList();
              }
            }}
          >
            {(!lock && (canLockItems || canUpdateItems)) && <DropdownIcon />}
            {choice && this.renderSelectedChoice(choice, lock)}
            {!choice && value && choices[value] && this.renderSelectedChoice(choices[value], readOnly, lock)}

          </FormSelectWrapper>
          {renderedList}
        </>
      );
    }
  };

  private getChoicesList() {
    const { choices, value, allowMultiple, choiceOrder } = this.props;
    let choicesToReturn = choiceOrder;

    if (allowMultiple) {
      choicesToReturn = difference(
        choiceOrder,
        coerseArray(value),
      );
    }

    return choicesToReturn
      .map(id => ({ id, choice: choices[id] }));
  }

  private openList = () => {
    if (this.props.readOnly) return;
    this.setState({ open: true });
  };

  private closeList = (e?: any) => {
    this.setState({ open: false });
    if (e && e.keyCode === keys.KEY_ESCAPE) {
      setTimeout(() => {
        this.props.api && this.props.api.stopEditing();
      }, 50);
    }

    const isGroupMode = this.props.columnApi?.getRowGroupColumns().length;
    if (isGroupMode) {
      setTimeout(() => {
        this.props.api?.stopEditing();
        this.props.api?.clearFocusedCell();
        this.props.api?.clearRangeSelection();
      }, 50);
    }
  };

  private handleSelect = selected => {
    const { allowMultiple, onChange } = this.props;
    let value = selected;

    if (allowMultiple) {
      const values = coerseArray(this.props.value);
      value = [...values, selected];
    }

    if (this.props.isGrid) {
      if (this.props?.data?.['fields']?.[this.props.colDef.colId.replace('fields.', '')] !== value) {
        this.props.node.setDataValue(this.props.colDef.colId, value);
      }
      this.props.stopEditing(true);
    } else {
      onChange(value, true, this.props.data);
    }

    this.closeList();
  };

  private handleDelete = id => () => {
    const { value, onChange } = this.props;
    setTimeout(() => {
      onChange(without([id], coerseArray(value)), true);
    }, 0);
    this.closeList();
  };

  private renderMultipleChoiceItem = (id, i) => {
    const { choices, classes, readOnly, lock } = this.props;

    if (!choices[id]) return null;
    const { label } = choices[id];
    return (
      <React.Fragment key={`${id}${i}`}>
        {!isNullEmptyBlankOrUndefined(label) && (
          this.renderChoice(choices[id], readOnly, lock, { onDelete: this.handleDelete(id), className: classes.choice })
        )}
      </React.Fragment>
    );
  };


  private isItemSelected = (itemValue): boolean => {
    return coerseArray(this.props.value).indexOf(itemValue) >= 0;
  };

  private renderItem = (item) => {
    const { lock, readOnly } = this.props;
    return this.renderChoice(item.choice, readOnly, lock);
  };

  private newOptionOnClick = (option) => {
    let fieldId = 0;
    if (this.props['column']) {
      fieldId = this.props['column'].getColId().replace('fields.', '');
    } else if (this.props['fieldId']) {
      fieldId = this.props['fieldId'].replace('fields.', '');
    }

    const { updateField, choices, choiceOrder } = this.props;
    let newOrder = `${_.max(choiceOrder.map(ch => parseInt(ch, 10))) + 1}`;
    if (isNaN(parseInt(newOrder))) {
      newOrder = `${(choiceOrder?.length || 0) + 1}`;
    }
    const newChoices: Record<string, Choice> = {
      ...choices,
      [newOrder]: { label: option.choice['byQuery'], color: Colors.getColorByIndex(parseInt(newOrder)) },
    };
    const newChoicesOrder = [...choiceOrder, newOrder];
    updateField({
      fieldId: `${fieldId}`,
      choices: newChoices,
      choiceOrder: newChoicesOrder,
    });
    return newOrder;
  };

  private renderList(choices) {
    const { id, allowMultiple, colDef, includeEmpty } = this.props;
    const { open } = this.state;
    const addNewOptions: boolean = colDef?.refData?.allowGridAddingOptions || this.props.allowGridAddingOptions || undefined;

    return (
      <Popover
        id={`${id}`}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={this.wrapper}
        open={open}
        onClose={this.closeList}
        transitionDuration={0}
      >
        <ListSelectPopup
          id={`dropdown${id}`}
          items={choices.filter(choice => choice.choice)}
          renderItem={this.renderItem}
          matchItem={(query, item) => this.matchChoice(query, item.choice)}
          onSelect={this.handleSelect}
          mapItemToValue={choice => choice.id}
          includeEmpty={typeof includeEmpty === 'boolean' ? includeEmpty : !allowMultiple}
          isItemSelected={this.isItemSelected}
          addNewOptions={addNewOptions}
          onKeyDown={(e) => {
            if (e.keyCode === keys.KEY_TAB) {
              e.preventDefault();
              e.stopPropagation();
              this.props.api && this.props.api.tabToNextCell();
            } else if (e.keyCode === keys.KEY_ESCAPE) {
              e.preventDefault();
              e.stopPropagation();
              this.props.api && this.props.api.stopEditing();
            }
          }}
          newOptionOnClick={this.newOptionOnClick}
        />
      </Popover>
    );
  }
}

const mapDispatchToProps: DispatchProps = {
  updateField: CollectionActions.updateField,
};

const StyledChoiceEditor = withStyles(styles)(ChoiceEditor);

export { StyledChoiceEditor as ChoiceEditor };

const enhance = compose(
  wrapCellEditor({ stopEditingAfterChange: false }),
  withStyles(styles),
  connect(null, mapDispatchToProps),
);
export default enhance(ChoiceEditor);
