import { createStandardAction } from 'typesafe-actions';
import { FilterType, Operator } from '../../types/gridOptions';
import { DateFormat } from 'components/Fields/data';
import { FilterValue } from 'types/response/query';
import { AgGridFilterModel } from 'components/AgGrid/AgGridApi';

export enum ActionType {
  ADD_FILTER = 'ADD_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  DELETE_FILTER = 'DELETE_FILTER',
  RESET_FILTER_MODEL = 'RESET_FILTER_MODEL',
  SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS',
  SET_QUICK_SEARCH_QUERY = 'SET_QUICK_SEARCH_QUERY',
}

export interface AddFilterPayload {
  columnId: string;
  type: FilterType;
  dateFormat?: DateFormat;
}

export interface UpdateFilterPayload {
  index: number;
  columnId?: string;
  operator?: Operator;
  type?: FilterType;
  value?: FilterValue | FilterValue[] | null; // null value means "don't filter" for some filter types
  dateFormat?: DateFormat;
}

export const addFilter = createStandardAction(ActionType.ADD_FILTER)<AddFilterPayload>();
export const updateFilter = createStandardAction(ActionType.UPDATE_FILTER)<UpdateFilterPayload>();
export const deleteFilter = createStandardAction(ActionType.DELETE_FILTER)<number>();
export const resetFilterModel = createStandardAction(ActionType.RESET_FILTER_MODEL)<void>();
export const setSearchFilters = createStandardAction(ActionType.SET_SEARCH_FILTERS)<AgGridFilterModel>();
export const setQuickSearchQuery = createStandardAction(ActionType.SET_QUICK_SEARCH_QUERY)<string>();

export type AddFilterAction = ReturnType<typeof addFilter>;
export type UpdateFilterAction = ReturnType<typeof updateFilter>;
export type DeleteFilterAction = ReturnType<typeof deleteFilter>;
export type ResetFilterModelAction = ReturnType<typeof resetFilterModel>;
export type SetSearchFiltersAction = ReturnType<typeof setSearchFilters>;
export type SetQuickSearchQueryAction = ReturnType<typeof setQuickSearchQuery>;

export type Action =
  AddFilterAction
  | UpdateFilterAction
  | DeleteFilterAction
  | ResetFilterModelAction
  | SetSearchFiltersAction
  | SetQuickSearchQueryAction;
