import * as React from 'react';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import TableHeaderTools from 'components/TableHeaderTools';
import { FilteringOption, FilteringOptionStyled } from 'pages/People/components/FilteringOption';
import { SortingOption } from 'components/TableHeaderTools/modules/SortingOption/SortingOption';
import SearchOption from 'components/Toolbar/SearchOption/SearchOption';
import Button, { Variant } from 'components/Button';
import 'styles/containers/grid-toolbar.scss';
import svgIcons from 'styles/svgIcons';
import { Column, ColumnVM } from 'types/schema';
import { RegularFilter, SortModel, SortModelState, SortOperator } from 'types/gridOptions';
import { AddFilterPayload, UpdateFilterPayload } from 'data/grid-options/filterModel.actions';
import { ChangeSortColumnPayload, ChangeSortOperatorPayload } from 'data/grid-options/sortModel.reducer';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { constructFilter, getDefaultOperatorForFilterType } from 'components/NodeFieldData/filters/helpers';
import ExportOption from '../../components/TableHeaderTools/modules/ExportOption';
import { FieldType } from 'types/response/fieldNode';
import StateUtils from 'utilities/state-utils';
import { SortEnd } from 'react-sortable-hoc';
import { ExportType } from '../../components/AgGrid/AgGridApi';
import * as ColID from '../DataGrid/columns/constants';

interface OwnProps {
  columnSchema: {
    [id: string]: Column;
  };
  columns: ColumnVM[];
  changeQuickFiltersVisibility: () => void;
  quickFiltersBarEnabled: boolean;
  grid: { api: GridApi; columnApi: ColumnApi };
  controlledSelectedRowsCount?: boolean;
  selectedRowsCount?: number;
  resetFilters?: boolean;
  onExport?: (type: ExportType) => void;
}

type Props = OwnProps;

const GridToolbar: React.FunctionComponent<Props> = (props: Props) => {
  const { onExport } = props;
  const filterRef = React.useRef<FilteringOption>();
  const [quickSearchQuery, setSearchQuery] = useState('');
  const [close, setClose] = useState(false);
  const [filters, setFilters] = useState<RegularFilter[]>([]);
  const [sortModelState, setSortModelState] = useState<SortModelState>({ byId: {}, allIds: [] });
  const [addGridEvents, setGridEventState] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  React.useEffect(() => {
    if (!addGridEvents) {
      setGridEventState(true);
      props.grid.api.addEventListener('sortChanged', onSortChanged);
      if (!props.controlledSelectedRowsCount) {
        props.grid.api.addEventListener('selectionChanged', onSelectionChanged);
      }
    }
    return () => {
      props.grid.api.removeEventListener('sortChanged', onSortChanged);
      if (!props.controlledSelectedRowsCount) {
        props.grid.api.removeEventListener('selectionChanged', onSelectionChanged);
      }
    };
  }, []);

  React.useEffect(() => {
    if (props.controlledSelectedRowsCount) {
      setSelectedRowsCount(props.selectedRowsCount || 0);
    }
  }, [props.controlledSelectedRowsCount, props.selectedRowsCount]);

  React.useEffect(() => {
    if (props.resetFilters) {
      resetFilters();
    }
  }, [props.resetFilters]);

  React.useEffect(() => {
    props.grid.api?.refreshCells?.({ force: true, columns: [ColID.ROW_NUMBER_ID] });
  }, [sortModelState, filters]);

  const onSelectionChanged = (event) => {
    const rowCount = event.api.getSelectedNodes().length;
    setSelectedRowsCount(rowCount);
  };

  const onQuickQueryChanges = (query) => {
    props.grid.api.setQuickFilter(query);
    setSearchQuery(query);
    setClose(false);
  };

  const addFilter = (payload: AddFilterPayload): void => {
    const filter = constructFilter(
      payload.columnId,
      payload.type,
      getDefaultOperatorForFilterType(payload.type),
      undefined,
      payload.dateFormat,
    );
    setFilters([...filters, filter]);
  };

  const updateFilter = (payload: UpdateFilterPayload): void => {
    const currentFilter = filters[payload.index];
    const column = props.columnSchema[payload.columnId || currentFilter.columnId];
    const updatedFilter = constructFilter(
      payload.columnId || currentFilter.columnId,
      payload.type || currentFilter.type,
      payload.operator || currentFilter.operator,
      column?.fieldType === FieldType.Singlechoice
        ? (payload.value || [])
        : column.fieldType === FieldType.Date
          ? payload.value
          : payload.columnId && payload.columnId !== currentFilter.columnId
            ? currentFilter.filter
            : payload.value,
      payload.dateFormat,
    );

    setFilters([...filters.slice(0, payload.index), updatedFilter, ...filters.slice(payload.index + 1)]);

    if (currentFilter) {
      let changedFieldType = false;
      if (payload.columnId && currentFilter.columnId !== payload.columnId) {
        changedFieldType = true;
        const filterInstance = props.grid.api.getFilterInstance(payload.columnId);
        filterInstance.setModel({ value: payload.value || updatedFilter.filter, filter: { ...updatedFilter } });
      }

      const filterInstance = props.grid.api.getFilterInstance(currentFilter.columnId);
      filterInstance.setModel({ value: changedFieldType ? '' : payload.value || updatedFilter.filter, filter: { ...updatedFilter } });

      props.grid.api.onFilterChanged();
    }
  };

  const deleteFilter = (index: number): void => {
    const filter = filters[index];
    if (filter) {
      props.grid.api.destroyFilter(filter.columnId);
    }
    setFilters([...filters.slice(0, index), ...filters.slice(index + 1)]);
  };

  const resetFilters = () => {
    window.resetFloatingFilter = true;

    const filterModel = cloneDeep(props.grid.api.getFilterModel());

    for (const key in filterModel) {
      if (key) {
        filterModel[key] = {
          ...filterModel[key],
          value: '',
        };
      }
    }

    props.grid.api.setFilterModel(filterModel);

    filters.forEach((_, index) => {
      deleteFilter(index);
    });

    window.resetFloatingFilter = false;
    setSearchQuery('');
    setClose(true);
  };

  const addSort = (payload: SortModel) => {
    const sortModel = {
      byId: {
        ...sortModelState.byId,
        [payload.colId]: payload,
      },
      allIds: [...sortModelState.allIds, payload.colId],
    };
    setSortModelState(sortModel);
    setAgGridSort(sortModel);
  };

  const setAgGridSort = (sortModel: SortModelState) => {
    props.grid.api.setSortModel(Object.values(sortModel.byId));
    props.grid.api.refreshHeader();
  };

  const changeSortColumn = async (payload: ChangeSortColumnPayload) => {
    const clonedSortModel = cloneDeep(sortModelState);
    const newColumnSort = cloneDeep(clonedSortModel.byId[payload.oldColumnKey]);
    newColumnSort.colId = payload.newColumnId;
    delete clonedSortModel.byId[payload.oldColumnKey];
    const allIds = clonedSortModel.allIds.filter(id => id !== payload.oldColumnKey);
    const sortModel = {
      byId: {
        ...clonedSortModel.byId,
        [payload.newColumnId]: newColumnSort,
      },
      allIds: [...allIds, payload.newColumnId],
    };
    setSortModelState(sortModel);
    setAgGridSort(sortModel);
  };

  const changeSortOperator = (payload: ChangeSortOperatorPayload) => {
    const sortModel = {
      byId: {
        ...sortModelState.byId,
        [payload.columnToChangeKey]: {
          colId: payload.columnToChangeKey,
          sort: payload.newOperator,
        },
      },
      allIds: sortModelState.allIds,
    };
    setSortModelState(sortModel);
    setAgGridSort(sortModel);
  };

  const deleteSort = async (colId: string) => {
    const byId = Object.assign({}, sortModelState.byId);
    delete byId[colId];
    const sortModel = { byId, allIds: sortModelState.allIds.filter(id => id !== colId) };
    setAgGridSort(sortModel);
    setSortModelState(cloneDeep(sortModel));
  };

  const changeSortOrder = (payload: SortEnd) => {
    const allIds = sortModelState.allIds.slice();
    const id = allIds[payload.oldIndex];
    allIds.splice(payload.oldIndex, 1);
    allIds.splice(payload.newIndex, 0, id);
    const sortModel = { allIds, byId: {} };
    allIds.forEach(id => {
      sortModel.byId[id] = sortModelState.byId[id];
    });
    setSortModelState(cloneDeep(sortModel));
    setAgGridSort(cloneDeep(sortModel));
  };

  const clearSort = () => {
    setSortModelState({ allIds: [], byId: {} });
    props.grid.api.setSortModel([]);
  };

  const onSortChanged = async () => {
    await StateUtils.wait(0);
    const agGridSortModel = props.grid.api.getSortModel();
    if (!agGridSortModel.length) {
      setSortModelState({ allIds: [], byId: {} });
      return;
    }

    const updatedSortModelState: SortModelState = cloneDeep(sortModelState);
    agGridSortModel.forEach(model => {
      updatedSortModelState.byId[model.colId] = { sort: model.sort as SortOperator, colId: model.colId };
      if (!sortModelState.allIds.includes(model.colId)) {
        updatedSortModelState.allIds.push(model.colId);
      }
    });
    setSortModelState(updatedSortModelState);

    props.grid.api?.refreshCells?.({ force: true, columns: [ColID.ROW_NUMBER_ID] });
  };

  const deselectAllRows = () => {
    props.grid.api.deselectAll();
  };

  return (
    <TableHeaderTools rightSideOptions={<></>}>
      <SearchOption
        placeholder="Search in Database"
        query={quickSearchQuery}
        onQueryChanges={onQuickQueryChanges}
        close={close}
      />
      <FilteringOptionStyled
        innerRef={filterRef}
        showFilterBarSwitchEnabled
        nonEmptyFiltersCount={filters.length}
        columnSchema={props.columnSchema}
        columns={props.columns}
        filters={filters}
        addFilter={addFilter}
        deleteFilter={deleteFilter}
        updateFilter={updateFilter}
        changeQuickFiltersVisibility={props.changeQuickFiltersVisibility}
        quickFiltersBarEnabled={props.quickFiltersBarEnabled}
      />
      <SortingOption
        sortModel={sortModelState}
        schemaColumns={props.columnSchema}
        columnsVM={props.columns}
        addSort={addSort}
        changeSortColumn={changeSortColumn}
        changeSortOperator={changeSortOperator}
        deleteSort={deleteSort}
        changeSortOrder={changeSortOrder}
        clearSort={clearSort}
      />
      {selectedRowsCount > 0 && (
        <Button
          id="btnClearSelectedRows"
          label={`${selectedRowsCount} Selected`}
          variant={Variant.TertiaryLink}
          selected
          icon={svgIcons.Close}
          onClick={deselectAllRows}
          className="grid-toolbar-selected-row-count"
        />
      )}
      {onExport && <ExportOption onExport={onExport} />}
    </TableHeaderTools>
  );
};

export default GridToolbar;
