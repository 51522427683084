import * as React from 'react';
import * as cn from 'classnames';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { WithStyles, withStyles } from '@material-ui/core';
import { Omit } from 'utility-types';
import ListOverflowItems, { ListOverflowItemsProps } from 'components/ListOverflow/ListOverflowItems';
import { styles } from './ListOverflow.style';

type InheritedProps = Omit<ListOverflowItemsProps, 'width' | 'height'> & WithStyles<typeof styles>;

export interface ListOverflowProps extends InheritedProps {
  className?: string;
  style?: object;
}

/**
 * Renders items, which fits container and "more items" placeholder for rest items
 * If parent container have fixed width use ListOverflowItems directly
 */
function ListOverflow({ className, style, classes, ...props }: ListOverflowProps) {
  return (
    <AutoSizer
      style={style}
      className={cn(classes.autosizer, className)}
    >
      {({ width, height }) => <ListOverflowItems width={width} height={height} {...props} />}
    </AutoSizer>
  );
}

export default withStyles(styles)(ListOverflow);
