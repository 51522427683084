import * as fromActions from './quickFilter.actions';
import * as fromCollectionActions from 'data/collections/collections.actions';
import * as constants from 'data/collections/constants';

export type QuickFilterState = boolean;

type Actions = fromActions.Actions | fromCollectionActions.ActionsType;

const reducer = (state: QuickFilterState = true, action: Actions): QuickFilterState => {
  switch (action.type) {
    case constants.CLEAN_COLLECTION:
      return true;
    case fromActions.CHANGE_QUICK_FILTERS_VISIBILITY: {
      return !state;
    }
  }
  return state;
};

export default reducer;
