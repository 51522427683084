import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as colors from 'styles/colors';

const StyledLink = styled(Link)`
  color: ${colors.dark};

  &:hover {
    color: ${colors.hubsyncBlue};
}
`;

export default StyledLink;
