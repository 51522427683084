import { CommonNode } from '..';
import { Breadcrumb, CommonResponse } from '.';
import { Collection } from 'types/response/http/getCollection';
import { BookmarkMeta } from 'data/bookmarks/types';

export interface TreeNode {
  fileName?: string;  // Filename of the node.
  id?: string;        // ID of the node.
  isFolder?: boolean; // Whether the node represents a folder.
  nodes?: TreeNode[]; // Child nodes.
  nodeType?: string;  // Type of the node.
  title?: string;     // Title of the node.
  type?: string;      // type of the branch (Collection or Node).
  uri?: string;       // URI of the node.
  bookmarkMeta?: BookmarkMeta;
}

// Ensure that this function returns a unique key per tree node
export function getKey(node: TreeNode, index: number): string | number {
  return node.id || node.title || node.uri || node.fileName || index;
}

interface ACL {
  breakInheritance: boolean;            // Whether this ACL breaks inheritance.
  entries: { [key: string]: boolean }; // Map from type, account/group ID, and permission ID to ACL entry.
}

export interface GetNodeResponse<Node extends CommonNode> extends CommonResponse {
  acl?: ACL;                        // Access control list
  breadcrumbs?: Breadcrumb[];      // Breadcrumbs.
  collections: Collection<Node>[]; // Child collections below the node. Type of collection nodes is unknown for now.
  navigationTree: TreeNode;        // Navigation Tree for the node.
  node: Node;                      // Returned node
}
