import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { hubsyncBlue, iceBlue } from 'styles/colors';

type ClassKey =
  'field'
  | 'inlineField'
  | 'fieldLabel'
  | 'errorFooter'
  | 'addFieldRoot'
  | 'addFieldIcon'
  | 'addFieldLabel'
  | 'actionButtons'
  | 'inlineEditorContainer'
  | 'divider'
  | 'fieldWrapper'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  inlineField: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '30px',
  },
  fieldLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
  inlineEditorContainer: {
    maxWidth: '150px',
  },
  errorFooter: {
    color: 'red',
  },
  addFieldRoot: {
    cursor: 'pointer',
  },
  addFieldIcon: {
    color: hubsyncBlue,
    width: '10px',
    marginRight: '15px',
  },
  addFieldLabel: {
    color: hubsyncBlue,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 30px',
  },
  divider: {
    width: '100%',
    height: '1px',
    minHeight: '1px',
    backgroundColor: iceBlue,
  },
  fieldWrapper: {
    '& > div': {
      minHeight: '40px',
    },
  },
};
