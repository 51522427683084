import { createAsyncAction, createStandardAction, ActionType } from 'typesafe-actions';

import { WorkspaceNode } from 'types/response/workspaceNode';
import { NodeWithTitle } from 'types/schema';
import { Role } from '../accounts/types';


export const UPDATE_WORKSPACE_ROLES = 'workspaces/UPDATE_WORKSPACE_ROLES';
export const UPDATE_WORKSPACE = 'workspaces/UPDATE_WORKSPACE_NAME';

export const fetchWorkspace = createAsyncAction(
  'workspaces/FETCH_BY_ID_REQUEST',
  'workspaces/FETCH_BY_ID_SUCCESS',
  'workspaces/FETCH_BY_ID_FAILURE',
)<void, WorkspaceNode, Error>();

export const setCurrentWorkspace = createStandardAction('workspaces/SET_CURRENT')<string | null>();

export const archiveWorkspace = createAsyncAction(
  'workspaces/ARCHIVE_REQUEST',
  'workspaces/ARCHIVE_SUCCESS',
  'workspaces/ARCHIVE_FAILURE',
)<string, WorkspaceNode, Error>();

export const unarchiveWorkspace = createAsyncAction(
  'workspaces/UNARCHIVE_REQUEST',
  'workspaces/UNARCHIVE_SUCCESS',
  'workspaces/UNARCHIVE_FAILURE',
)<string, WorkspaceNode, Error>();

export const deleteWorkspace = createAsyncAction(
  'workspaces/DELETE_REQUEST',
  'workspaces/DELETE_SUCCESS',
  'workspaces/DELETE_FAILURE',
)<string, string, Error>();

export const createMultipleWorkspaces = createAsyncAction(
  'workspaces/MULTIPLE_CREATION_REQUEST',
  'workspaces/MULTIPLE_CREATION_SUCCESS',
  'workspaces/MULTIPLE_CREATION_FAILURE',
)<string, string, Error>();

interface UpdateWorkspaceRoles {
  type: typeof UPDATE_WORKSPACE_ROLES;
  workspaceId: string;
  roles: Role[];
}
interface UpdateWorkspace {
  type: typeof UPDATE_WORKSPACE;
  workspaceId: string;
  workspace: NodeWithTitle;
}

export const updateWorkspaceRoles = (workspaceId: string, roles: Role[]): UpdateWorkspaceRoles => {
  return {
    type: UPDATE_WORKSPACE_ROLES,
    workspaceId,
    roles,
  };
};

export const updateWorkspace = (workspaceId: string, workspace: NodeWithTitle): UpdateWorkspace => {
  return {
    type: UPDATE_WORKSPACE,
    workspaceId,
    workspace,
  };
};

export const actions = {
  fetchWorkspace,
  setCurrentWorkspace,
  archiveWorkspace,
  unarchiveWorkspace,
  deleteWorkspace,
  updateWorkspaceRoles,
  createMultipleWorkspaces,
  updateWorkspace,
};

export type Actions = ActionType<typeof actions>;
