import { createAction, createStandardAction } from 'typesafe-actions';
import { CollectionTypes } from 'types/schema';
import { DatabaseNode } from 'types/response/databaseNode';
import * as constants from './databases.constants';
import { FileAttachment } from 'types/common';
import LoadingState from 'data/LoadingState';
import { ImportedDatabaseState } from './databases.types';

export interface UpdateDatabaseRequestPayload {
  url: string;
  data: Partial<DatabaseNode>;
  isFullObject?: boolean;
}

export const updateDatabaseRequest = createAction(
  'databases/UPDATE_REQUEST',
  resolve => (payload: UpdateDatabaseRequestPayload, flag: CollectionTypes) => resolve(payload, flag),
);

export const updateDatabaseSuccess = createAction(
  'databases/UPDATE_SUCCESS',
  resolve => (payload: DatabaseNode, flag: CollectionTypes) => resolve(payload, flag),
);
export const updateDatabaseFailure = createAction(
  'databases/UPDATE_FAILURE',
  resolve => (payload: Error, flag: CollectionTypes) => resolve(payload, flag),
);

export const deleteDatabaseRequest = createAction(
  'databases/DELETE_REQUEST',
  resolve => (payload: { uri: string; id: string }, flag: CollectionTypes) => resolve(payload, flag),
);
export const deleteDatabaseSuccess = createAction(
  'databases/DELETE_SUCCESS',
  resolve => (payload: DatabaseNode, flag: CollectionTypes) => resolve(payload, flag),
);
export const deleteDatabaseFailure = createAction(
  'databases/DELETE_FAILURE',
  resolve => (payload: Error, flag: CollectionTypes) => resolve(payload, flag),
);

export const setCurrentDatabase = createStandardAction('databases/SET_CURRENT')<string | null>();

export interface ImportDatabaseOptions {
  url: string;
  data: {
    title: string;
    status: string;
    description: string;
    originalFilename: string;
  };
  attachment: FileAttachment;
}

export interface ImportDatabase extends ImportDatabaseOptions {
  type: typeof constants.IMPORT_DATABASE;
}

export const importDatabase = (options: ImportDatabaseOptions): ImportDatabase => {
  return {
    type: constants.IMPORT_DATABASE,
    ...options,
  };
};

export interface ImportDatabaseSuccessOptions {
  loadingState: LoadingState;
  importedDatabase: ImportedDatabaseState;
}

export interface ImportDatabaseSuccess extends ImportDatabaseSuccessOptions {
  type: typeof constants.IMPORT_DATABASE_SUCCESS;
}

export const importDatabaseSuccess = (options: ImportDatabaseSuccessOptions): ImportDatabaseSuccess => {
  return {
    type: constants.IMPORT_DATABASE_SUCCESS,
    ...options,
  };
};

export interface ImportDatabaseError {
  type: typeof constants.IMPORT_DATABASE_ERROR;
}

export const importDatabaseError = (): ImportDatabaseError => {
  return {
    type: constants.IMPORT_DATABASE_ERROR,
  };
};

export interface FetchImportedDatabaseOptions {
  fileName: string;
  title: string;
  originalFilename: string;
}

export interface FetchImportedDatabase extends FetchImportedDatabaseOptions {
  type: typeof constants.FETCH_IMPORTED_DATABASE;
}

export const fetchImportedDatabase = (options: FetchImportedDatabaseOptions): FetchImportedDatabase => {
  return {
    type: constants.FETCH_IMPORTED_DATABASE,
    fileName: options.fileName,
    title: options.title,
    originalFilename: options.originalFilename,
  };
};

export interface ImportedField {
  fieldName: string;
  fieldType: string;
  prevOrder: number;
}

export interface SaveImportedDatabaseOptions {
  id: string;
  title: string;
  fields: ImportedField[];
}

export interface SaveImportedDatabase extends SaveImportedDatabaseOptions {
  type: typeof constants.SAVE_IMPORTED_DATABASE;
}

export const saveImportedDatabase = (options: SaveImportedDatabaseOptions): SaveImportedDatabase => {
  return {
    type: constants.SAVE_IMPORTED_DATABASE,
    id: options.id,
    title: options.title,
    fields: options.fields,
  };
};

export interface SaveImportedDatabaseSuccess {
  type: typeof constants.SAVE_IMPORTED_DATABASE_SUCCESS;
  id: string;
}

export const saveImportedDatabaseSuccess = (id: string): SaveImportedDatabaseSuccess => {
  return {
    type: constants.SAVE_IMPORTED_DATABASE_SUCCESS,
    id,
  };
};

export interface CreateSocketConnection {
  type: typeof constants.CREATE_SOCKET_CONNECTION;
  socketUrl: string;
}

export const createSocketConnection = (socketUrl: string): CreateSocketConnection => {
  return {
    type: constants.CREATE_SOCKET_CONNECTION,
    socketUrl,
  };
};

export interface DestroySocketConnection {
  type: typeof constants.DESTROY_SOCKET_CONNECTION;
}

export const destroySocketConnection = (): DestroySocketConnection => {
  return {
    type: constants.DESTROY_SOCKET_CONNECTION,
  };
};
export const setLoadingStateTrue = createStandardAction('databases/SET_LOADING_STATE_TRUE')();

export const actions = {
  updateDatabaseRequest,
  updateDatabaseSuccess,
  updateDatabaseFailure,
  deleteDatabaseRequest,
  deleteDatabaseSuccess,
  deleteDatabaseFailure,
  setCurrentDatabase,
  importDatabase,
  importDatabaseSuccess,
  fetchImportedDatabase,
  saveImportedDatabase,
  saveImportedDatabaseSuccess,
  importDatabaseError,
  createSocketConnection,
  destroySocketConnection,
  setLoadingStateTrue,
};
