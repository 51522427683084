import produce from 'immer';

import * as Actions from './actions';
import { AnalyticsState } from './types';

export const initialState: AnalyticsState = {
  isFetchLoading: false,
  analytics: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.fetchAnalytics.pending.type: {
      return produce(state, (draft) => {
        draft.isFetchLoading = true;
      });
    }
    case Actions.fetchAnalytics.fulfilled.type: {
      return {
        ...state,
        isFetchLoading: false,
        analytics: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
