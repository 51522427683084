import { createStyles, Theme } from '@material-ui/core';

export const filtersDropdownStyles = (theme: Theme) =>
  createStyles({
    filtersContent: {
      paddingBottom: theme.spacing.unit * 2,
    },
    inputWrapper: {
      maxHeight: '25px',
    },
  });
