import * as React from 'react';

interface OwnProps {
  id?: string;
  onFocus?: () => void;
  autoSelect?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export type Props = React.InputHTMLAttributes<HTMLInputElement> & OwnProps;

class SelfSelectingInput extends React.Component<Props> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  public componentDidMount(): void {
    if (this.props.autoFocus) {
      // Timeout needed for not losing focus from input component in modal when popover is closed
      // This seems to be the minimum value for it to work fine
      setTimeout(() => {
        this.setFocus();
      }, 500);
    }
  }

  public render(): JSX.Element {
    const { autoFocus, inputRef, ...props } = this.props;

    return (
      <input
        {...props}
        onFocus={this.onFocus}
        onDoubleClick = {this.handleDoubleClick.bind(this)}
        ref={this.isControlled() ? inputRef : this.inputRef}
      />
    );
  }

  private handleDoubleClick(e: React.MouseEvent) {
    const input = this.getInput();
    if (input) {
      // input.selectionStart = input.value.length;
      // input.selectionEnd = input.value.length;
      if (typeof input.selectionStart == 'number') {
        input.selectionStart = input.selectionEnd = input.value.length;
      }
    }
  }

  private getInput = (): HTMLInputElement | null => {
    return (this.props.inputRef && this.props.inputRef.current) || this.inputRef.current;
  };

  private isControlled = (): boolean => {
    return this.props.inputRef !== undefined;
  };

  private onFocus = (): void => {
    const input = this.getInput();
    if (input && input.type !== 'number') {
      input.selectionStart = input.value.length;
      input.selectionEnd = input.value.length;
    }


    if (this.props.autoSelect && this.props.value && this.props.value.toString().length > 1) {
      this.selectText();
    }

    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus();
    }
  };

  private selectText = (): void => {
    const input = this.getInput();
    if (input) {
      input.select();
    }
  };

  private setFocus = (): void => {
    const input = this.getInput();
    if (input) {
      input.focus();
    }
  };
}

export default SelfSelectingInput;
