import { CollectionViews } from 'data/collections/collections.reducer';
import * as OrderedCollectionViews from 'data/collections/orderedCollectionViews';
import { CollectionViewTypes } from 'types/schema';

import { State } from '../../reducers';
import { ChartViewNode } from '../../types/response/chartViewNode';
import { ViewNode } from '../../types/response/viewNode';

export const defaultViewMock = {
  title: 'Default View',
  type: CollectionViewTypes.grid,
} as ViewNode;

export const selectedView = (state: State): ViewNode =>
  viewById(state, state.collections.selectedViewId) || defaultViewMock;

export const selectedChartView = (state: State): ChartViewNode | null =>
  chartViewById(state, state.collections.selectedChartViewId) || null;

function collectionViews(state: State): CollectionViews {
  return state.collections.views;
}

export function currentViewIdSelector(state: State): string {
  return state.collections.selectedViewId || '';
}

export function views(state: State): ViewNode[] | null {
  const { nodes } = collectionViews(state);
  return OrderedCollectionViews.views(nodes);
}

export const chartViewById = (state: State, viewId?: string): ChartViewNode | null => {
  // todo implement
  return null;
};

export const viewById = (state: State, viewId?: string): ViewNode | null => {
  const maybeViews = OrderedCollectionViews.views(collectionViews(state).nodes);

  if (maybeViews === null) {
    return null;
  }

  if (viewId === undefined) {
    return firstDefaultOrSyntheticView(maybeViews);
  }

  return findViewById(viewId, maybeViews);
};

export const currentDefaultView = (state: State): ViewNode | null => {
  const maybeViews = OrderedCollectionViews.views(collectionViews(state).nodes);
  return maybeViews ? findDefaultView(maybeViews) : null;
};

function firstDefaultOrSyntheticView(views: ViewNode[]): ViewNode | null {
  const [maybeFirstView] = views.filter(view => view.isDefault || view.synthetic);

  if (maybeFirstView === undefined) {
    return null;
  }

  return maybeFirstView;
}

function findViewById(id: string, views: ViewNode[]): ViewNode | null {
  const maybeView = views.find((v) => v.id === id);

  if (maybeView === undefined) {
    return null;
  }

  return maybeView;
}

function findDefaultView(views: ViewNode[]): ViewNode | null {
  const maybeView = views.find((v) => v.isDefault === true);
  return maybeView ?? null;
}
