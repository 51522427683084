import * as jwtDecode from 'jwt-decode';
import { Dictionary } from 'lodash';

import { isInDevelopment } from '../env';

export interface AuthData {
  token: string;
  xsrf: string;
}

/**
 * If the user is on a shared link, it has guest access
 */
export function isGuest() {
  return window.location.pathname.startsWith('/shared-files');
}

export function getAuthDataFromStorage(): Partial<AuthData> {
  return {
    token: localStorage.getItem('id_token') || undefined,
    xsrf: localStorage.getItem('xsrf_state') || undefined,
  };
}

export function hasAuthData(): boolean {
  return ['id_token', 'xsrf_state'].every(localstorageHasItem);
}

function localstorageHasItem(item: string): boolean {
  return localStorage.getItem(item) !== null;
}

interface UrlTokens {
  jwt: string;
  state: string;
}

export function getURLTokens(): Partial<UrlTokens> {
  const { token, xsrf } = getAuthDataFromStorage();
  return {
    jwt: token,
    state: xsrf,
  };
}

export function isAuthenticated({ token, xsrf }: Partial<AuthData>): boolean {
  if (!token || !xsrf) {
    return false;
  }

  const jwt = decodeJwt(token);

  if (!jwt) {
    return false;
  }

  const maybeExp = getExpFromJwt(jwt);

  if (!maybeExp) {
    return false;
  }

  return isJwtExpired(maybeExp);
}

export function redirectToLogin(): void {
  if (isInDevelopment()) {
    console.warn('Token and XSRF cookies are expired, please renew.');
  } else {
    window.location.href = `${window.location.origin}/login`;
  }
}

function decodeJwt(token: string): Dictionary<unknown> | null {
  try {
    // TODO: If we eventually use decoders, use it here
    // @ts-ignore
    return jwtDecode<Dictionary<unknown>>(token);
  } catch (error) {
    return null;
  }
}

function isJwtExpired(exp: number): boolean {
  // Server returns time in seconds. JS uses milliseconds.
  return exp * 1000 > new Date().getTime();
}

function getExpFromJwt(token: Dictionary<unknown>): number | null {
  if (!token.exp) {
    return null;
  }

  if (typeof token.exp !== 'number') {
    return null;
  }

  return token.exp;
}
