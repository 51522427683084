import * as React from 'react';

import { Actions as CollectionsActions } from 'data/collections/collections.actions';
import { CreateFieldPayload } from 'data/collections/collections.reducer';
import LoadingState from 'data/LoadingState';
import * as Actions from 'data/modals/actions';
import { CreateCalendarViewPayload } from 'data/views/types';
import { createView } from 'data/views/views.actions';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { connect } from 'react-redux';
import { State as ReduxState } from 'reducers';
import { CollectionViewTypes, Column, hasDuplicateFieldName } from 'types/schema';

import createFieldPayload from '../../Fields/createFieldPayload';
import * as FieldData from '../../Fields/data';
import { ViewNameOrError, disolveViewNameOrError } from '../common/viewNameOrError';
import NewCalendarViewRender from './render';


interface OwnProps {
  viewNameOrError: ViewNameOrError;
  allFields: Column[];
  selectedTitleField?: Column;
  titleFields: Column[];
  selectedDateField?: Column;
  dateFields: Column[];
  isPrivate?: boolean;
  isLocked?: boolean;
  isDefault?: boolean;
  permissions?: string[];
  onClose(): void;
}

interface DispatchProps {
  onViewNameChange(viewName: string): void;
  onCreateColumn(payload: CreateFieldPayload): void;
  onTitleFieldChange(selectedTitleField: Column): void;
  onDateFieldChange(selectedDateField: Column): void;
  onCreateNewCalendarView(payload: CreateCalendarViewPayload): void;
  onViewPrivateStateChange(isPrivate: boolean): void;
  onViewLockStateChange(isLocked: boolean): void;
  onDefaultStateChange(isDefault: boolean): void;
}

interface StateProps {
  viewLoadingState: LoadingState;
}

type Props
  = OwnProps
  & DispatchProps
  & StateProps
  ;

interface State {
  titleAnchorEl?: HTMLElement;
  titleFieldName?: string;
  titleFieldNameErrorMessage?: string;
  titleFieldData: FieldData.FieldData;
  dateAnchorEl?: HTMLElement;
  dateFieldName?: string;
  dateFieldNameErrorMessage?: string;
  dateFieldData: FieldData.FieldData;
}

class NewCalendarView extends React.Component<Props, State> {
  public state: State = {
    titleFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleLineText),
    dateFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.Date),
  };

  public render(): JSX.Element {
    const { viewNameOrError, ...props } = this.props;
    const { errorMessage, viewName } = disolveViewNameOrError(viewNameOrError);

    return (
      <NewCalendarViewRender
        {...props}
        {...this.state}
        viewName={viewName}
        isPrivate={this.props.isPrivate}
        isLocked={this.props.isLocked}
        isDefault={this.props.isDefault}
        permissions={this.props.permissions}
        viewNameErrorMessage={errorMessage}
        onPrivateStateChange={this.props.onViewPrivateStateChange}
        onLockStateChange={this.props.onViewLockStateChange}
        onDefaultStateChange={this.props.onDefaultStateChange}
        onNewTitleFieldButtonClick={this.onNewTitleFieldButtonClick}
        onNewTitleFieldClose={this.onNewTitleFieldClose}
        onTitleFieldNameChange={this.onTitleFieldNameChange}
        onTitleDataUpdate={this.onTitleDataUpdate}
        onTitleFieldCreated={this.onTitleFieldCreated}
        onNewDateFieldButtonClick={this.onNewDateFieldButtonClick}
        onNewDateFieldClose={this.onNewDateFieldClose}
        onDateFieldNameChange={this.onDateFieldNameChange}
        onDateDataUpdate={this.onDateDataUpdate}
        onDateFieldCreated={this.onDateFieldCreated}
        onCreateNewCalendarView={this.onCreateNewCalendarView}
        viewLoadingState={this.props.viewLoadingState}
      />
    );
  }

  private onNewTitleFieldButtonClick = (titleAnchorEl: HTMLElement): void => {
    this.setState({ titleAnchorEl });
  };

  private onNewTitleFieldClose = (): void => {
    this.setState({ titleAnchorEl: undefined, titleFieldNameErrorMessage: undefined });
  };

  private onTitleFieldNameChange = (titleFieldName: string): void => {
    this.setState({ titleFieldName, titleFieldNameErrorMessage: undefined });
  };

  private onTitleDataUpdate = (titleFieldData: FieldData.FieldData): void => {
    this.setState({ titleFieldData, titleFieldNameErrorMessage: undefined });
  };

  private onTitleFieldCreated = (): void => {
    if (!this.state.titleFieldName) {
      this.setState({
        titleFieldName: undefined,
        titleFieldNameErrorMessage: 'Please provide a field name.',
      });
      return;
    }

    const isDuplicateFieldName = hasDuplicateFieldName(
      this.props.allFields,
      this.state.titleFieldName,
    );

    if (isDuplicateFieldName) {
      this.setState({
        titleFieldNameErrorMessage: 'Field should have a unique name.',
      });
      return;
    }

    const payload = createFieldPayload(this.state.titleFieldName, this.state.titleFieldData);

    this.props.onCreateColumn(payload);

    this.setState({
      titleFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.SingleLineText),
      titleAnchorEl: undefined,
      titleFieldName: undefined,
      titleFieldNameErrorMessage: undefined,
    });
  };

  private onNewDateFieldButtonClick = (dateAnchorEl: HTMLElement): void => {
    this.setState({ dateAnchorEl });
  };

  private onNewDateFieldClose = (): void => {
    this.setState({ dateAnchorEl: undefined, dateFieldNameErrorMessage: undefined });
  };

  private onDateFieldNameChange = (dateFieldName: string): void => {
    this.setState({ dateFieldName, dateFieldNameErrorMessage: undefined });
  };

  private onDateDataUpdate = (dateFieldData: FieldData.FieldData): void => {
    this.setState({ dateFieldData, dateFieldNameErrorMessage: undefined });
  };

  private onDateFieldCreated = (): void => {
    if (!this.state.dateFieldName) {
      this.setState({
        dateFieldName: undefined,
        dateFieldNameErrorMessage: 'Please provide a field name.',
      });
      return;
    }

    const isDuplicateFieldName = hasDuplicateFieldName(
      this.props.allFields,
      this.state.dateFieldName,
    );

    if (isDuplicateFieldName) {
      this.setState({
        dateFieldNameErrorMessage: 'Field should have a unique name.',
      });
      return;
    }

    const payload = createFieldPayload(this.state.dateFieldName, this.state.dateFieldData);

    this.props.onCreateColumn(payload);

    this.setState({
      dateFieldData: FieldData.getDefaultFieldData(FieldData.FieldType.Date),
      dateAnchorEl: undefined,
      dateFieldName: undefined,
      dateFieldNameErrorMessage: undefined,
    });
  };

  private onCreateNewCalendarView = (): void => {
    const { selectedDateField, viewNameOrError } = this.props;
    const primaryField = this.props.titleFields.find(field => field.isPrimary);

    const { viewName, hasError } = disolveViewNameOrError(viewNameOrError);

    if (hasError && viewName === '') {
      return;
    }

    if (!primaryField || !selectedDateField) {
      return;
    }

    const payload: CreateCalendarViewPayload = {
      title: viewName,
      type: CollectionViewTypes.calendar,
      titleFieldID: primaryField.id,
      dateFieldID: selectedDateField.id,
      isPrivate: this.props.isPrivate,
      isLocked: this.props.isLocked,
      isDefault: this.props.isDefault,
    };

    this.props.onCreateNewCalendarView(payload);
  };
}

const mapDispatchToProps: DispatchProps = {
  onViewNameChange: Actions.newViewName,
  onCreateColumn: CollectionsActions.createField,
  onTitleFieldChange: Actions.selectTitleField,
  onDateFieldChange: Actions.selectDateField,
  onCreateNewCalendarView: createView.request,
  onViewPrivateStateChange: Actions.newViewPrivateStatus,
  onViewLockStateChange: Actions.newViewLockStatus,
  onDefaultStateChange: Actions.newViewDefaultStatus,
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  viewLoadingState: state.collections.views.loadingState,
  // @ts-ignore
  isPrivate: state.modals.isPrivate,
  isLocked: state.modals.isLocked,
  isDefault: state.modals.isDefault,
  permissions: getWorkspacePermissions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCalendarView);
