import * as React from 'react';
import styled from 'styled-components';

import FielEditorWrapper from './FieldEditorWrapper';
import SelfSelectingInput, { Props } from '../../SelfSelectingInput';

class GridTextEditor extends React.PureComponent<Props> {
  public render = (): JSX.Element => {
    const { value, ...props } = this.props;
    return (
      <Wrapper>
        <SelfSelectingInput
          {...props}
          value={value === null ? '' : value}
        />
      </Wrapper>
    );
  };
}

export default GridTextEditor;

const Wrapper = styled(FielEditorWrapper)`
  width: 100%;
  height: 100%;
  input {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
