import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

import * as Colors from 'styles/colors';

import { PhoneData } from '../../data';

interface Props extends WithStyles<ClassKey> {
  data: PhoneData;
  onDataUpdate(data: PhoneData): void;
}

function Phone(props: Props): JSX.Element {
  return (
    <React.Fragment>
    </React.Fragment>
  );
}

type ClassKey
  = 'description'
  | 'textField'
  ;

const styles = withStyles<ClassKey>({
  description: { color: Colors.blueyGrey },
  textField: { marginTop: 8 },
});

export default styles(Phone);
