import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { ActionType, isActionOf } from 'typesafe-actions';
import { prop, path } from 'lodash/fp';

import { createAPIHandler, RequestType } from 'utilities/saga';
import {
  createCollectionItemFailure,
  createCollectionItemImportFailure,
  createCollectionItemImportRequest,
  createCollectionItemImportSuccess,
  createCollectionItemRequest,
  createCollectionItemSuccess,
} from './collections.actions.new';
import { importFiles } from 'data/importFiles/importFile.actions';
import { postRequest } from 'utilities/httpRequests';
import { CollectionTypes } from 'types/schema';
import { fetchNavigationTree } from 'data/app/actions';
import { State } from 'reducers';
import { mapDatabaseResponse, mapWorkspaceResponse } from './collections.mapper';

export function* createCollectionItemSaga() {
  yield createAPIHandler({
    actions: {
      request: createCollectionItemRequest,
      success: createCollectionItemSuccess,
      failure: createCollectionItemFailure,
    },
    requestType: RequestType.Post,
    buildUrl: (action) => {
      return action.payload.url + '?api_version=2';
    },
    buildData: prop('payload.data'),
    successPayloadMapper: (response, action) => {
      if (action.meta === CollectionTypes.databases || action.meta === CollectionTypes.taskdbs) {
        return mapDatabaseResponse(response);
      } else if (action.meta === CollectionTypes.workspaces) {
        return mapWorkspaceResponse(response);
      }
      return path(['body', 'node']);
    },
    successMetaMapper: (_, action) => action.meta,
    * onSuccess(payload, action, _, meta) {
      if (meta === CollectionTypes.workspaces) {
        yield put(fetchNavigationTree());
      }
    },
  });
}

export function* createCollectionItemImportSaga() {
  yield takeLatest(isActionOf(createCollectionItemImportRequest),
    function* (action: ActionType<typeof createCollectionItemImportRequest>) {
      try {
        const state: State = yield select();
        const { data, url, fileAttachment } = action.payload;
        const params = { data, url };
        const { body: { node } } = yield call(postRequest, params, state);

        yield put(importFiles.request({
          data: fileAttachment.file,
          contentType: fileAttachment.contentType,
          uri: node.uri,
        }));

        // TODO wait for a success/error action from import
        yield take([isActionOf(importFiles.success)]);

        yield put(createCollectionItemImportSuccess(node, action.meta));
      } catch (error) {
        yield put(createCollectionItemImportFailure(error, action.meta));
      }
    });
}

