import * as React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'components/Checkbox';
import { withStyles, WithStyles } from '@material-ui/core';
import { sendMessage } from 'data/messages/actions';
import { MessageBody, Suggestions } from 'data/messages/types';
import { User } from 'data/users/users.types';
import { getWorkspacePermissions } from 'data/workspaces/workspaces.selectors';
import { State as StoreState } from 'reducers';
import { styles } from './MessageEditor.styles';
import TextEditor from 'components/TextEditor/newEditor';
import { EditorState, convertToRaw } from 'draft-js';
import { PluginEditor } from 'draft-js-mention-plugin';
import { changeUserSendMessagePreference } from '../../data/users/users.actions';
import { MAX_MESSAGE_LENGTH } from './constants';

export enum SendMessageStates {
  NO_VALUE = 'no_value',
  WITH_VALUE = 'with_value',
  TOO_LARGE = 'too_large',
}

interface State {
  version: number;
  editorState: EditorState;
  messageBody: MessageBody;
  sendMessageState: SendMessageStates;
}

interface OwnProps {
  user: User;
  initialState?: State;
  suggestions?: Suggestions[];
  className?: string;
  onChangeHandler?: (messageState: SendMessageStates) => void;
  permissions: string[];
}

interface DispatchProps {
  sendMessage: (messageBody: MessageBody) => void;
  changeUserPreference: (payload: any) => void;
}

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

export class MessageEditor extends React.Component<Props> {
  private editorRef: React.RefObject<PluginEditor> = React.createRef();

  state: State = {
    version: 1,
    editorState: EditorState.createEmpty(),
    messageBody: {
      text: '',
      json: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
    sendMessageState: SendMessageStates.NO_VALUE,
    ...(this.props.initialState || {}),
  };

  public render(): JSX.Element {
    const { classes, suggestions, permissions } = this.props;
    const className = this.props.className || '';
    const isReadOnly = !permissions.includes('messages:create');

    return (
      <div className={`${classes.wrapper} ${className}`}>
        <TextEditor
          version={this.state.version}
          resetOnEnter={this.props.user?.settings?.sendOnEnter}
          sendMessage={this.sendMessage}
          suggestions={suggestions}
          showSendButton={!isReadOnly}
          readOnly={isReadOnly}
        />
        <div id="errorMessage" className={classes.error}>
          {this.state.sendMessageState === SendMessageStates.TOO_LARGE &&
            `Maximum ${MAX_MESSAGE_LENGTH} characters are allowed.`}
        </div>
        <div className={classes.actions}>
          <div
            className={`messages-label-container ${classes.labelsContainer}`}
          >
            <span className={classes.labelShift}>
              {this.props.user?.settings?.sendOnEnter
                ? 'Shift + Enter for new line'
                : ''}
            </span>
            <div className={classes.checkboxContainer}>
              <Checkbox
                id="chkEnterToSend"
                onClick={this.toggleSendOnEnter}
                selected={this.props.user?.settings?.sendOnEnter}
                className={
                  this.props.user?.settings?.sendOnEnter ? '' : classes.checkbox
                }
              />
              <span className={classes.label}>Enter to send</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onChange = (editorState: EditorState): void => {
    const messageBody = {
      text: editorState.getCurrentContent().getPlainText(),
      json: convertToRaw(editorState.getCurrentContent()),
    };
    this.setState(
      {
        editorState,
        messageBody,
        sendMessageState:
          messageBody.text.trim().length === 0
            ? SendMessageStates.NO_VALUE
            : messageBody.text.length > MAX_MESSAGE_LENGTH
              ? SendMessageStates.TOO_LARGE
              : SendMessageStates.WITH_VALUE,
      },
      () => {
        this.props.onChangeHandler?.(this.state.sendMessageState);
      },
    );
  };

  private sendMessage = (message): void => {
    if (message?.html) {
      this.props.sendMessage(message);
    } else {
      if (this.state.sendMessageState !== SendMessageStates.WITH_VALUE) return;
      this.props.sendMessage(this.state.messageBody);
      this.resetMessageState();
    }
  };

  private resetMessageState = (): void => {
    this.setState(
      {
        version: this.state.version + 1,
        editorState: EditorState.createEmpty(),
        messageBody: {
          text: '',
          json: convertToRaw(EditorState.createEmpty().getCurrentContent()),
        },
        sendMessageState: SendMessageStates.NO_VALUE,
      },
      () => {
        this.props.onChangeHandler?.(this.state.sendMessageState);
      },
    );
  };

  private toggleSendOnEnter = (): void => {
    this.props.changeUserPreference({
      data: {
        settings: {
          ...(this.props.user.settings || {}),
          sendOnEnter: !this.props.user.settings?.sendOnEnter,
        },
      },
    });

    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };
}

export const StyledMessageEditor = withStyles(styles)(MessageEditor);

const mapStateToProps = (state: StoreState) => ({
  permissions: getWorkspacePermissions(state),
});

const mapDispatchToProps: DispatchProps = {
  sendMessage,
  changeUserPreference: changeUserSendMessagePreference.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(StyledMessageEditor);
