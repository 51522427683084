import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { styles } from './EditorButton.style';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface OwnProps {
  id?: string;
  icon: React.ReactElement<SvgIconProps>;
  style: string;
  active: boolean;
  onToggle(style: string): void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class EditorButton extends React.Component<Props> {
  private onToggle = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.props.onToggle(this.props.style);
  };

  public render() {
    const { active, classes, id, icon } = this.props;
    let className = classes.editorButton;
    if (active) {
      className += ` ${classes.editorActiveButton}`;
    }
    return (
      <span id={id} className={className} onMouseDown={this.onToggle}>
        {icon}
      </span>
    );
  }
}

export default withStyles(styles)(EditorButton);
