import { ColDef, ColGroupDef } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { GRID_ROW_HEIGHT, ROW_HEIGHT_CLASS_MAP } from 'styles/constants';

import { CommonNode } from '../../types/response';
import { Group, Viewport } from '../../types/response/http/getCollection';

export interface GroupResult<T extends CommonNode> {
  nodes: T[] | Group<T>[];
  totalCount: number;
}

export const EMPTY_TITLE = '(Empty)';

function injectGroupStartCount<Node extends CommonNode>(
  startIndex: number,
  groups: Group<Node>[],
): number {
  let nextIndex: number = startIndex;

  groups.forEach((group) => {
    if (!group.groups) {
      group.firstNodeIndex = nextIndex;
      nextIndex = nextIndex + (group.count ? group.count : 0);
    } else {
      nextIndex = injectGroupStartCount(nextIndex, group.groups);
    }
  });

  return nextIndex;
}

export function addRowIndexToNodes<Node extends CommonNode>(
  start: number,
  nodes: Node[],
): Node[] {
  return nodes.map((node, index) => ({
    ...node,
    rowIndex: start + index,
  }));
}

function getGroupByPath<Node extends CommonNode>(
  path: string[],
  groups: Group<Node>[],
): Group<Node> {
  let group;
  path.forEach((k) => {
    group = (group ? group.groups : groups).find((g) =>
      isEqual(g.value || EMPTY_TITLE, k),
    );
  });

  return group;
}

export function getGroupsResult<T extends CommonNode>(
  groupPath: string[],
  startRow: number,
  endRow: number,
  viewport: Viewport<T>,
): GroupResult<T> {
  let nodes: T[] | Group<T>[] = [];

  injectGroupStartCount(0, viewport.groups || []);

  const group = groupPath.length
    ? getGroupByPath(groupPath, viewport.groups || [])
    : viewport;

  if (group.groups) {
    nodes = group.groups.map((gr) => ({
      value: gr.value === undefined ? EMPTY_TITLE : gr.value,
      count: gr.count,
      firstNodeIndex: gr.firstNodeIndex,
    }));
  } else if (group.nodes) {
    nodes = addRowIndexToNodes(startRow, group.nodes);
  }

  nodes = nodes.slice(startRow, endRow);

  return { nodes, totalCount: nodes.length };
}

/**
 * Returns precalculated number of lines by row height
 *
 * @param {number} rowHeight
 * @returns {number}
 */
export function getGridRowMaxTextLines(rowHeight: number): number {
  switch (rowHeight) {
    case GRID_ROW_HEIGHT.BASE:
      return 1;
    case GRID_ROW_HEIGHT.MEDIUM:
      return 3;
    case GRID_ROW_HEIGHT.TALL:
      return 5;
  }

  return 1;
}

/**
 * Returns precalculated class row height for single/multi line text
 *
 * @param {number} rowHeight
 * @returns {string}
 */
export function getGridRowClassText(rowHeight: number, type = 'single'): string {
  return `${type}-${ROW_HEIGHT_CLASS_MAP[rowHeight]}-overflow`;
}

/**
 * Returns precalculated class row height for multiselect
 *
 * @param {number} rowHeight
 * @returns {string}
 */
export function getGridRowWrapLines(rowHeight: number): string {
  switch (rowHeight) {
    case GRID_ROW_HEIGHT.BASE:
      return 'single-choice-item';
    case GRID_ROW_HEIGHT.MEDIUM:
      return 'multiple-choice-item-medium';
    case GRID_ROW_HEIGHT.TALL:
      return 'multiple-choice-item-tall';
  }

  return 'single-choice-item';
}

export function isColGroupDef(
  colDef: ColDef | ColGroupDef,
): colDef is ColGroupDef {
  return 'children' in colDef;
}

/*
  This function returns the last text node used by the Rich Text Editor.
  This piece of code is used to set the cursor to the end.

  https://www.syncfusion.com/forums/149970/set-cursor-to-end-of-text-in-richtexteditor
*/
export function getTextNodesUnder(document, node: ChildNode| null | undefined): Array<ChildNode> {
  let nodes: Array<ChildNode> = [];

  if (!node) return [];

  for (let newNode = node?.firstChild; newNode; newNode = newNode.nextSibling) {
    if (newNode.nodeType === Node.TEXT_NODE) {
      nodes.push(newNode);
    } else {
      nodes = nodes.concat(getTextNodesUnder(document, newNode));
    }
  }
  return nodes;
}

export function removeHTMLTagsFromText(text = ''): string {
  const dummyDiv = document.createElement('div');
  dummyDiv.innerHTML = text;
  return dummyDiv.innerText;
}
