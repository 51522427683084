import { InputClassKey, InputProps as MUIInputProps } from '@material-ui/core/Input';
import { InputLabelProps as MUIInputLabelProps } from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Omit } from 'utility-types';

import * as Colors from 'styles/colors';

type InputProps = Omit<MUIInputProps, 'classes' | 'disableUnderline'>;
type InputLabelProps = Omit<MUIInputLabelProps, 'classes' | 'shrink'>;

type OmittedTextFieldProps
  = 'onChange'
  | 'select'
  | 'value'
  | 'classes'
  | 'variant'
  ;

export enum Variant {
  Regular,
  Small,
}

interface OwnProps<Value> extends Omit<TextFieldProps, OmittedTextFieldProps> {
  variant?: Variant;
  value?: Value;
  values: Value[];
  InputProps?: InputProps;
  InputLabelProps?: InputLabelProps;
  getLabel(value: Value): string;
  getSelectValue(value: Value): string;
  onChange(newValue: Value): void;
}

type Props<Value>
  = OwnProps<Value>
  & WithStyles<ClassKey>
  ;

export class Select<Value> extends React.Component<Props<Value>> {
  static defaultProps = {
    variant: Variant.Regular,
  };

  public render(): JSX.Element {
    const {
      classes,
      value,
      values,
      InputProps,
      InputLabelProps,
      onChange,
      getLabel,
      getSelectValue,
      placeholder,
      ...textFieldProps
    } = this.props;

    return (
      <MuiTextField
        {...textFieldProps}
        value={value ? getSelectValue(value) : ''}
        InputProps={{ ...InputProps, classes: this.extractInputClasses(), disableUnderline: true }}
        InputLabelProps={{ ...InputLabelProps, shrink: true }}
        onChange={this.onChange}
        select
        SelectProps={{
          displayEmpty: true,
        }}
        variant="standard"
      >
        {placeholder &&
          <MenuItem key="placeholder-item" value="" disabled>
            {placeholder}
          </MenuItem>
        }
        {values.map(this.renderItem)}
      </MuiTextField>
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const possibleValue = this.props.values.find((value) => {
      return event.target.value === this.props.getSelectValue(value);
    });

    if (possibleValue) {
      this.props.onChange(possibleValue);
    }
  };

  private renderItem = (value: Value): JSX.Element => {
    const selectValue = this.props.getSelectValue(value);
    const label = this.props.getLabel(value);

    return (
      <MenuItem key={selectValue} value={selectValue}>
        {label}
      </MenuItem>
    );
  };

  private extractInputClasses = (): Partial<Record<InputClassKey, string>> => {
    const { variant, classes } = this.props;
    const { regularVariantInput, smallVariantInput, ...rest } = classes;
    const input: string = variant === Variant.Small ? smallVariantInput : regularVariantInput;
    return {
      input,
      ...rest,
    };
  };
}

type RelevantInputClassKey
  = 'root'
  | 'error'
  | 'focused'
  ;
type OwnInputClassKey
  = 'regularVariantInput'
  | 'smallVariantInput'
  ;
type ClassKey = Extract<InputClassKey, RelevantInputClassKey> | OwnInputClassKey;

const styles = withStyles<ClassKey>({
  root: {
    minWidth: '150px',
    backgroundColor: Colors.white,
    padding: '0 8px',
    color: Colors.slateTwo,
    border: `1px solid ${Colors.lightBlueGrey}`,
    borderRadius: 2,
  },
  error: {
    borderColor: Colors.tomato,
  },
  focused: {
    borderColor: Colors.hubsyncBlue,
  },
  regularVariantInput: {
    fontSize: '13px',
    fontWeight: 'normal',
    letterSpacing: 0.22,
    lineHeight: '30px',
    height: '30px',
    padding: '0px 22px 0px 2px',
  },
  smallVariantInput: {
    fontSize: '11px',
    letterSpacing: 0.18,
    lineHeight: '25px',
    height: '25px',
    padding: 0,
  },
});

const Styled = styles(Select);

export default function StyledSelect<Value>(props: OwnProps<Value>): JSX.Element {
  return <Styled {...props} />;
}
