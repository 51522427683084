import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { first, path } from 'lodash/fp';
import { ActionType, isActionOf } from 'typesafe-actions';
import { push } from 'connected-react-router';
import produce from 'immer';
import { createAPIHandler, RequestType } from 'utilities/saga';
import { createDatabasesUrl, createSheetsUrl } from 'utilities/createUrl';
import { getNextName } from 'utilities/views';

import { Node } from 'types/schema';

import { location as locationSelector } from 'data/app/selectors';
import { currentSheet, sheets, sheetTitles } from 'data/sheets/sheets.selectors';

import { actions, createSheetRequest } from './sheets.actions';
import { State } from 'reducers';

// @ts-ignore
const getLocation = (state: State): Location => locationSelector(state) || {
  pathname: '',
  search: '',
  hash: '',
  state: null,
};

function* selectSheetSaga() {
  yield takeEvery(
    isActionOf(actions.selectSheet),
    function* (action: ActionType<typeof actions.selectSheet>) {
      const state: State = yield select();
      const location = getLocation(state);
      const sheet = currentSheet(state);

      const id = action.payload.id || action.payload['_id'];
      if (sheet && id !== sheet.id) {
        const newLocation = produce(location, draft => {
          // @ts-ignore
          draft.pathname = createSheetsUrl(location, action.payload.id);
          draft.search = '';
        });

        yield put(push(newLocation));
      }
    });
}

function* createSheetRequestSaga() {
  yield createAPIHandler({
    actions: actions.createSheetRequest,
    requestType: RequestType.Post,
    // @ts-ignore
    buildUrl: (_, state) => createSheetsUrl(getLocation(state)),
    buildData: (action) => ({
      title: action.payload.title,
    }),
    successPayloadMapper: (response) => ({
      sheet: path(['body', 'node'], response),
    }),
    * onSuccess(payload, _, state) {
      // @ts-ignore
      const pathname = createSheetsUrl(getLocation(state), payload.sheet.id);

      yield put(push({ pathname }));
    },
  });
}

function* createSheetSaga() {
  yield takeEvery(
    isActionOf(actions.createSheet), function* () {
      const state: State = yield select();
      const names = sheetTitles(state);

      const name = getNextName(names, 'Sheet');

      const payload = {
        title: name,
      };

      yield put(createSheetRequest.request(payload));
    });
}

function* renameSheetSaga() {
  yield createAPIHandler({
    actions: actions.renameSheet,
    requestType: RequestType.Patch,
    // @ts-ignore
    buildUrl: (action, state) => createSheetsUrl(getLocation(state), action.payload.id),
    buildData: action => ({
      title: action.payload.title,
    }),
    successPayloadMapper: path(['body', 'node']),
  });
}

function* deleteSheetSaga() {
  yield createAPIHandler({
    actions: actions.deleteSheet,
    requestType: RequestType.Delete,
    // @ts-ignore
    buildUrl: (action, state) => createSheetsUrl(getLocation(state), action.payload.id),
    successPayloadMapper: (_, action) => action.payload.id,
    * onSuccess(payload, action, state) {
      const firstSheet = first(sheets(state)) as Node;

      yield put(push({
        // @ts-ignore
        pathname: createSheetsUrl(getLocation(state), firstSheet.id),
      }));
    },
  });
}

function* reorderSheetsSaga() {
  yield createAPIHandler({
    actions: actions.reorderSheets,
    requestType: RequestType.Patch,
    // @ts-ignore
    buildUrl: (_, state) => createDatabasesUrl(getLocation(state), true),
    buildData: action => ({
      sheetIDOrder: action.payload.sheetIdOrder,
    }),
  });
}

export default function* watchSaga() {
  yield all([
    fork(selectSheetSaga),
    fork(createSheetRequestSaga),
    fork(createSheetSaga),
    fork(renameSheetSaga),
    fork(deleteSheetSaga),
    fork(reorderSheetsSaga),
  ]);
}
