import { DocumentsState } from 'data/documents/documents.reducer';
import { State } from 'reducers';

const root = (state: State): DocumentsState => state.documents;

export const getDirectories = (state: State) => root(state).directories;
export const isDirectoriesLoading = (state: State) => root(state).directoriesLoading;
export const getDocument = (state: State) => root(state).document;
export const isDocumentLoading = (state: State) => root(state).documentLoading;
export const getPreviews = (state: State) => root(state).previews;
export const getPreviewsLoadState = (state: State) => root(state).previewsLoadState;
export const getHistory = (state: State) => root(state).history;
export const getHistoryLoadState = (state: State) => root(state).historyLoadState;
